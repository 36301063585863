import React from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import Overlay from '../overlays';
import ImageWithCaption from './image_with_caption';
import { uiStore } from '../../managers/globalState';
import GlobeIcon from '../../../www/assets/images/globe_icon.min.svg';
import '../../../www/assets/css/about_overlay.css';

const imageProps = {
	url: 'assets/images/1349_en_about_image.jpg',
	alt: 'earth',
	captionText: 'صورة من قمر سومي إن بي بي (SUOMI NPP) من تاريخ 6 سبتمبر, 2017 م. حيث يمكن رؤية إعصار إيرما في وسط الصورة وإعصار خوسيه من فوقه.'
};

const About = ({ isMobile }) => (
	// const { hideExternalLinks: hideExternalLinksFromURL } = uiStore.stateSnapshot;
	// const hideExternalLinks = hideExternalLinksFromURL === 'true';
	//TODO MERGE Handle this new hide external links thing if we want, kiosk?
	<div className={'container ' + (isMobile ? 'py-3' : 'py-5')}>
		<div className='row'>
		<div className={`${false ? '' : 'col-12 col-lg-8 pb-4'}`}>
		<p className='body-content'>
				تقوم منصة عن الأرض الآن بمراقبة العلامات الحيوية لكوكبنا، مثل ارتفاع مستوى سطح البحر وتركيز ثاني أكسيد الكربون في الغلاف الجوي و طبقة الأوزون في القطب الجنوبي. تتبع حركة المياه في جميع أنحاء العالم باستخدام خريطة الجاذبية من الأقمار الصناعية (GRACE) التابعة لناسا. رصد الثورات البركانية وحرائق الغابات باستخدام أول أكسيد الكربون. التحقق من المواقع الأكثر سخونة و المواقع الأبرد على سطح الأرض باستخدام خريطة درجة حرارة السطح العالمية.
					<br />
					للحصول على نظرة عامة على الميزات ، تحقق من هذا
					{' '}
					<a className='body-link' href='https://climate.nasa.gov/climate_resources/215/earth-now-tutorial/' target='_blank' rel='noopener'>الفيديو التعليمي</a>
				</p>
				<h3 className='pt-3'>المنصات الموصى بها</h3>
				<table className='platforms-table'>
					<tbody>
						<tr>
							<td><b>ماك أو إس إكس +10.12.6</b></td>
							<td>سفاري +11، كروم +66، فايرفوكس +59.0.2</td>
						</tr>
						<tr>
							<td><b>آيفون آي أو إس +11.2.5</b></td>
							<td>سفاري +11، كروم +66، فايرفوكس +59.0.2</td>
						</tr>
						<tr>
							<td><b>آيباد</b></td>
							<td>آيباد إير 2، آيباد برو</td>
						</tr>
						<tr>
							<td><b>ويندوز +7</b></td>
							<td>كروم +66، فايرفوكس +59.0.2، إيدج +41</td>
						</tr>
						<tr>
							<td><b>أندرويد</b></td>
							<td>مارشميلو +6.0.1، كروم +66، فايرفوكس +60.0.2</td>
						</tr>
						<tr>
									<td><b>وحدة المعالجة المركزية</b></td>
									<td>كمبيوتر انتل اي٧+، هاتف رباعي النواة</td>
								</tr>
								<tr>
									<td><b>الذاكرة</b></td>
									<td>كمبيوتر 8 جيجابايت+، هاتف 2 جيجابايت+</td>
								</tr>
								<tr>
									<td><b>معالج الرسوميات</b></td>
									<td>كمبيوتر متوسط المدى+، هاتف أدرينو +530</td>
								</tr>
					</tbody>
				</table>
			</div>
			<div id='sidebar' className='col-12 col-lg-4'>
				<div className='side-nav mb-4'>
					<h2 className='pb-3'>تعرف على المزيد</h2>
					<p className='pb-2'>
						<a className='link' href='https://climate.nasa.gov/nasa_science/missions/?page=0&per_page=40&order=title+asc&search=&category=32' target='_blank' rel='noopener'>البعثات الفضائية في المستقبل ›</a>
					</p>
					<p className='pb-2'>
						<a className='link' href='http://jpl.nasa.gov/' target='_blank' rel='noopener'>عن مختبر الدفع النفاث (JPL) ›</a>
					</p>
					<p className='pb-2'>
						<a className='link' href='http://climate.nasa.gov/' target='_blank' rel='noopener'>موقع ناسا لتغير المناخ ›</a>
					</p>
				</div>
				<div className='side-nav mb-4'>
					<h2 className='pb-3'>التغذية الراجعة</h2>
					<p className='pb-2'>
						<a className='link' href='https://www.jpl.nasa.gov/contact-jpl' target='_blank' rel='noopener'>التغذية الراجعة لمختبر الدفع النفاث (JPL) ›</a>
					</p>
				</div>
				<div className='side-nav pb-2'>
					<h2 className='pb-3'>أحدث الموارد</h2>
					<ul>
						<li className='pb-3'>
							<a className='link row' href='https://climate.nasa.gov/climate_resources/186/video-ozonewatch-2018/' target='_top'>
								<img className='thumbnail col px-0 pl-2 no-drag' alt='Video: Ozonewatch 2018' src='https://climate.nasa.gov/system/resources/list_images/186_ozone_dec31_1979-2018_print-320x240.jpg' />
								<span className='col px-0 ml-2'>فيديو: ساعة الأوزون 2018 م</span>
							</a>
						</li>
						<li className='pb-3'>
							<a className='link row' href='https://climate.nasa.gov/climate_resources/184/earth-360-video-the-call-of-science/' target='_blank' rel='noopener'>
								<img className='thumbnail col px-0 pl-2 no-drag' alt='Earth 360 Video: The Call of Science' src='https://climate.nasa.gov/system/resources/list_images/184_list_call_of_science.jpg' />
								<span className='col px-0 ml-2'>فيديو الأرض 360: نداء العلم</span>
							</a>
						</li>
						<li>
							<a className='link row' href='https://climate.nasa.gov/climate_resources/167/video-for-15-years-grace-tracked-freshwater-movements-around-the-world/' target='_blank' rel='noopener'>
								<img className='thumbnail col px-0 pl-2 no-drag' alt='Video: For 15 years, GRACE tracked freshwater movements around the world' src='https://climate.nasa.gov/system/resources/list_images/167_Screen-Shot-2018-05-16-at-12.37.30-PM.jpg' />
								<span className='col px-0 ml-2'>
								فيديو: لمدة 15 عامًا ، تتبع القمر الصناعي لتغطية حقل الجاذبية واختبار المناخ لحركات المياه العذبة في جميع أنحاء العالم
								</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
);

const TITLE_TEXT = 'عن الأرض الآن';
const AboutOverlay = ({ closeOverlay, isWindows }) => {
	const overlayComponents = (
		<>
			<Overlay.Header
				onClose={closeOverlay}
				title={TITLE_TEXT}
				Icon={GlobeIcon}
		/>

			<div className='content-mask scrollable'>
				<ImageWithCaption {...imageProps} />
				<About />
			</div>
		</>
	);
	return (
		<Overlay type='about'>
			{isWindows ?
				<SimpleBar style={{ height: '100%' }}>
					{overlayComponents}
				</SimpleBar> :
				overlayComponents}
		</Overlay>
	);
};

export default AboutOverlay;
