import { useEffect, useRef, useState } from 'react';

import { Engine } from 'pioneer';
import { WMTSComponent } from 'pioneer-scripts';

import { Config } from '../../config/config';
import globalRefs, { setGlobalRef } from '../../managers/globalRefs';

/**
 * A component that loads the pioneer engine and scene.
 *
 * @returns {React.Component}
 */
const PioneerMaster = ({ children }) => {
	const rootRef = useRef();
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		if (rootRef.current && !isLoaded) {
			// Check whether pioneer is already set up.
			const { pioneer: existingPioneer } = globalRefs;

			if (existingPioneer) {
				return;
			}

			// Create engine and main scene.
			const pioneer = new Engine(rootRef.current);
			const scene = pioneer.addScene('main');

			// Create cam and viewport.
			const viewport = pioneer.addViewport();
			const viewportDiv = viewport.getDiv();
			viewportDiv.style.width = '100%';
			viewportDiv.style.height = '100%';
			const cameraEntity = scene.addEntity('camera');
			const camera = cameraEntity.addComponent('camera');
			viewport.setCamera(camera);

			// Register components.
			pioneer.registerComponentType('wmts', WMTSComponent);

			// Set static and dynamic configs.
			pioneer
				.getDownloader()
				.setReplacement('STATIC_ASSETS_URL', Config.staticAssetsUrl);
			pioneer
				.getDownloader()
				.setReplacement('DYNAMIC_ASSETS_URL', Config.dynamicAssetsUrl);

			// Set max texture size.
			const smallTextureSize = 512;
			const largeTextureSize = 2048;
			const minScreenSize = Math.min(window.innerWidth, window.innerHeight);
			const maxTextureSize = minScreenSize > smallTextureSize ? largeTextureSize : smallTextureSize;
			pioneer.getConfig()?.setValue('maxTextureSize', maxTextureSize);

			cameraEntity.addComponent('lightSource');

			// Set pioneer reference in global state.
			setGlobalRef('pioneer', pioneer);
			window.pioneer = pioneer;

			setIsLoaded(true);
		}

		// No cleanup needed as this component is only mounted once and we store the pioneer reference in global state.
	}, [isLoaded]);

	return (
		<>
			<div className='pioneer' ref={rootRef} />
			{isLoaded && children}
		</>

	);
};

export default PioneerMaster;
