/** @module pioneer */
import {
	AtmosphereComponent,
	BaseComponent,
	CameraComponent,
	ComponentRef,
	CubeMap,
	Entity,
	EntityRef,
	FastMap,
	Geometry,
	LatLonAlt,
	MaterialUtils,
	MathUtils,
	Quaternion,
	SpheroidComponent,
	THREE,
	TextureLOD,
	ThreeJsHelper,
	Vector3
} from '../../internal';

/**
 * The spheroid LOD component.
 */
export class SpheroidLODComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * Texture URLs for the various textures.
		 * @type {FastMap<string, string>}
		 * @private
		 */
		this._textureUrls = new FastMap();

		/**
		 * The LOD objects (one per face and one per texture type) that determines the texture.
		 * @type {FastMap<string, TextureLOD[]>}
		 * @private
		 */
		this._textureLODs = new FastMap();

		/**
		 * The pixel sizes used for the LOD textures.
		 * @type {FastMap<string, number[]>}
		 * @private
		 */
		this._textureSizes = new FastMap();

		/**
		 * The mapping used for the mesh and textures.
		 * @type {string}
		 * @private
		 */
		this._mapping = 'cylinder';

		/**
		 * The longitudinal rotation in radians of the spheroid.
		 * @type {number}
		 * @private
		 */
		this._longitudinalRotation = 0;

		/**
		 * The number of faces, determined by the mapping.
		 * @type {number}
		 * @private
		 */
		this._numFaces = 1;

		/**
		 * The lower left coordinate of the bounds.
		 * @type {LatLonAlt}
		 * @private
		 */
		this._lowerLeftBounds = new LatLonAlt(-MathUtils.halfPi, -MathUtils.pi, 0);

		/**
		 * The upper right coordinate of the bounds.
		 * @type {LatLonAlt}
		 * @private
		 */
		this._upperRightBounds = new LatLonAlt(+MathUtils.halfPi, MathUtils.pi, 0);

		/**
		 * The layout used for the cubemap mapping.
		 * @type {Vector3[][]}
		 */
		this._cubeMapFaceFrames = CubeMap._defaultCubeMapFaceFrames;

		/**
		 * The entities uses for shadows. Derived from the shadow entity names.
		 * @type {EntityRef[]}
		 * @private
		 */
		this._shadowEntities = [];

		/**
		 * The features that are enabled.
		 * @type {Set<string>}
		 * @private
		 */
		this._features = new Set();

		/**
		 * The flag that if true, uses compressed textures.
		 * @type {boolean}
		 * @private
		 */
		this._useCompression = false;

		/**
		 * A reference to the atmosphere component.
		 * @type {ComponentRef<AtmosphereComponent>}
		 * @private
		 */
		this._atmosphereComponentRef = new ComponentRef(this.getEntity().getScene());
		this._atmosphereComponentRef.setByType(this.getEntity().getName(), 'atmosphere');

		/**
		 * A reference to the spheroid component.
		 * @type {ComponentRef<SpheroidComponent>}
		 * @private
		 */
		this._spheroidComponentRef = new ComponentRef(this.getEntity().getScene());
		this._spheroidComponentRef.setByType(this.getEntity().getName(), 'spheroid');
		this._spheroidComponentRef.setRefChangedCallback(this._spheroidRefChangedCallback.bind(this));

		// Bind the callbacks to this.
		this._spheroidChangedCallback = this._spheroidChangedCallback.bind(this);

		// Lets the base component to check for valid orientation when determining whether this is visible.
		this.__setUsesEntityOrientation(true);
	}

	/**
	 * Gets the list of texture names as a new array.
	 * @returns {string[]}
	 */
	getTextureNames() {
		/** @type {string[]} */
		const names = [];
		for (let i = 0; i < this._textureUrls.size; i++) {
			names.push(this._textureUrls.getAt(i).key);
		}
		return names;
	}

	/**
	 * Gets the texture URL for the given name. Returns undefined if it doesn't exist.
	 * @param {string} name
	 * @returns {string}
	 */
	getTextureUrl(name) {
		return this._textureUrls.get(name);
	}

	/**
	 * Gets the level-of-detail sizes of the named texture. Defaults to [16, 512, 4096].
	 * @param {string} name
	 * @returns {number[]}
	 */
	getTextureSizes(name) {
		return this._textureSizes.get(name);
	}

	/**
	 * Sets the texture URL for the given name.
	 * @param {string} name
	 * @param {string} url
	 * @param {number[]} [sizes=[4, 512, 4096]]
	 */
	setTexture(name, url, sizes = [4, 512, 4096]) {
		// Activate feature
		if (SpheroidLODComponent._textureToFeature.has(name)) {
			this.setFeature(SpheroidLODComponent._textureToFeature.get(name), url !== '');
		}

		this._textureUrls.set(name, url);
		this._textureSizes.set(name, [...sizes]);
		let textureLODs = this._textureLODs.get(name);
		// If there is no texture LOD yet, add it.
		if (textureLODs === undefined) {
			textureLODs = [];
			const materials = this.getThreeJsMaterials();
			for (let i = 0, l = this._numFaces; i < l; i++) {
				const textureLOD = new TextureLOD(this);
				if (materials.length > 0) {
					const uniform = materials[i].uniforms[name + 'Texture'];
					if (uniform !== undefined) {
						textureLOD.setUniform(uniform);
					}
				}
				textureLODs.push(textureLOD);
			}
			this._textureLODs.set(name, textureLODs);
		}
		for (let i = 0, l = this._numFaces; i < l; i++) {
			textureLODs[i].setUrl(url.replace('$FACE', i.toString()));
			textureLODs[i].setSizes(sizes);
		}
	}

	/**
	 * Forces the texture to be at the texture size. If size is undefined, it does normal LOD levels.
	 * @param {string} name
	 * @param {number} size
	 */
	forceTextureSize(name, size) {
		const textureLODs = this._textureLODs.get(name);
		if (textureLODs === undefined) {
			throw new Error('No texture named "' + name + '" has been defined.');
		}
		for (let i = 0; i < textureLODs.length; i++) {
			textureLODs[i].setForcedSize(size);
		}
	}

	/**
	 * Removes the texture from the material.
	 * @param {string} name
	 */
	unsetTexture(name) {
		// Deactivate feature
		if (SpheroidLODComponent._textureToFeature.has(name)) {
			this.setFeature(SpheroidLODComponent._textureToFeature.get(name), false);
		}

		this._textureUrls.delete(name);
		this._textureLODs.delete(name);
		this._textureSizes.delete(name);
		const materials = this.getThreeJsMaterials();
		for (let i = 0, l = materials.length; i < l; i++) {
			const uniform = materials[i].uniforms[name + 'Texture'];
			if (uniform !== undefined) {
				ThreeJsHelper.destroyTexture(uniform.value);
				uniform.value = null;
			}
		}
	}

	/**
	 * Gets the texture level-of-detail's current size.
	 * @param {string} name
	 * @param {number} face
	 * @returns {number}
	 */
	getTextureCurrentSize(name, face) {
		const textureLODs = this._textureLODs.get(name);
		if (textureLODs !== undefined && textureLODs[face] !== undefined) {
			return textureLODs[face].getCurrentSize();
		}
		return undefined;
	}

	/**
	 * Returns true if the feature is enabled. All features disabled by default.
	 * @param {string} feature
	 * @returns {boolean}
	 */
	isFeatureEnabled(feature) {
		return this._features.has(feature);
	}

	/**
	 * Enables or disables a feature, one of 'normalMap', 'nightMap', 'decalMap', 'specularMap', 'shadowRings', 'shadowEntities', 'noShading'.
	 * @param {string} feature - the feature to enable or disable
	 * @param {boolean} enable - turn on or off the feature in the shader
	 */
	setFeature(feature, enable) {
		const materials = this.getThreeJsMaterials();
		if (enable && !this._features.has(feature)) {
			this._features.add(feature);
			for (let i = 0, l = materials.length; i < l; i++) {
				const material = materials[i];
				material.defines[feature] = true;
				material.needsUpdate = true;
			}
			if (feature === 'normalMap') {
				this._updateMeshes();
			}
		}
		else if (!enable && this._features.has(feature)) {
			this._features.delete(feature);
			for (let i = 0, l = materials.length; i < l; i++) {
				const material = materials[i];
				delete material.defines[feature];
				material.needsUpdate = true;
			}
			if (feature === 'normalMap') {
				this._updateMeshes();
			}
		}
	}

	/**
	 * Gets the mapping used. Defaults to 'cylinder'.
	 * @returns {string}
	 */
	getMapping() {
		return this._mapping;
	}

	/**
	 * Sets the mapping used. It can be 'cylinder' or 'cube'.
	 * @param {string} mapping - the mapping to use
	 */
	setMapping(mapping) {
		// Determine the number of faces.
		const oldNumFaces = this._numFaces;
		if (mapping === 'cylinder') {
			this._numFaces = 1;
		}
		else if (mapping === 'cube') {
			this._numFaces = 6;
		}
		else {
			throw new Error('Invalid mapping type.');
		}

		// If there are a different number of faces, redo the texture LODs.
		if (this._numFaces !== oldNumFaces) {
			for (let i = 0; i < this._textureLODs.size; i++) {
				const name = this._textureLODs.getAt(i).key;
				const textureLODs = [];
				for (let face = 0, l = this._numFaces; face < l; face++) {
					const textureLOD = new TextureLOD(this);
					textureLOD.setUrl(this._textureUrls.get(name).replace('$FACE', face.toString()));
					textureLOD.setSizes(this._textureSizes.get(name));
					textureLODs.push(textureLOD);
				}
				this._textureLODs.set(name, textureLODs);
			}
		}

		// Set the mapping.
		this._mapping = mapping;

		// Reset the resources, since the mapping changed.
		this.resetResources();
	}

	/**
	 * Sets the lower left and upper right coordinates of the bounds.
	 * @param {LatLonAlt} lowerLeft
	 * @param {LatLonAlt} upperRight
	 */
	setBounds(lowerLeft, upperRight) {
		this._lowerLeftBounds.copy(lowerLeft);
		this._upperRightBounds.copy(upperRight);

		// Reset the resources, since the bounds changed.
		this.resetResources();
	}

	/**
	 * Sets how the 6 cubemaps will be laid out. It takes an 6-array of a 3-array of strings, like [['+x', '-y', '+z'], ['-x', '+y', '-z'], ...].
	 * The first component is u, the second component is v, and the third component is outward.
	 * @param {string[][]} layout
	 */
	setCubeMapLayout(layout) {
		// Set the vectors up correctly.
		this._cubeMapFaceFrames = [];
		for (let i = 0; i < 6; i++) {
			this._cubeMapFaceFrames.push([]);
			for (let j = 0; j < 3; j++) {
				let v = null;
				switch (layout[i][j]) {
					case '+x':
						v = Vector3.XAxis; break;
					case '-x':
						v = Vector3.XAxisNeg; break;
					case '+y':
						v = Vector3.YAxis; break;
					case '-y':
						v = Vector3.YAxisNeg; break;
					case '+z':
						v = Vector3.ZAxis; break;
					case '-z':
						v = Vector3.ZAxisNeg; break;
					default:
						throw new Error('Invalid cubemap layout component.');
				}
				this._cubeMapFaceFrames[i][j] = v;
			}
		}
		// Reset the resources, since the layout changed.
		this.resetResources();
	}

	/**
	 * Gets the number of shadow entities. Can be used to enumerate the shadow entities.
	 * @returns {number}
	 */
	getNumShadowEntities() {
		return this._shadowEntities.length;
	}

	/**
	 * Returns the shadow entity or its name at the index.
	 * @param {number} index
	 * @returns {string | undefined}
	 */
	getShadowEntity(index) {
		return this._shadowEntities[index]?.getName();
	}

	/**
	 * Sets the shadow entities. Each element can be either the name of an entity or an entity itself.
	 * @param {string[]} shadowEntities
	 */
	setShadowEntities(shadowEntities) {
		this._shadowEntities = [];
		for (const shadowEntity of shadowEntities) {
			this._shadowEntities.push(new EntityRef(this.getEntity().getScene(), shadowEntity));
		}
		const shadowEntitiesEnabled = (shadowEntities.length > 0);
		for (let i = 0, l = this.getThreeJsMaterials().length; i < l; i++) {
			ThreeJsHelper.setDefine(this.getThreeJsMaterials()[i], 'shadowEntities', shadowEntitiesEnabled);
		}
	}

	/**
	 * Gets the flag that if true, uses compressed textures.
	 * @returns {boolean}
	 */
	getUseCompression() {
		return this._useCompression;
	}

	/**
	 * Sets the flag that if true, uses compressed textures.
	 * @param {boolean} useCompression
	 */
	setUseCompression(useCompression) {
		this._useCompression = useCompression;
	}

	/**
	 * Gets the longitudinal rotation in radians of the spheroid.
	 * @returns {number}
	 */
	getLongitudeRotation() {
		return this._longitudinalRotation;
	}

	/**
	 * Sets the longitudinal rotation in radians of the spheroid.
	 * @param {number} rotation
	 */
	setLongitudeRotation(rotation) {
		this._longitudinalRotation = rotation;
	}

	/**
	 * Gets a new promise that resolves when the component is loaded.
	 * @returns {Promise<void>}
	 * @override
	 */
	getLoadedPromise() {
		const promises = [super.getLoadedPromise()];
		for (let i = 0; i < this._textureLODs.size; i++) {
			const textureLODs = this._textureLODs.getAt(i).value;
			for (let i = 0, l = textureLODs.length; i < l; i++) {
				promises.push(textureLODs[i].getLoadedPromise());
			}
		}
		return Promise.all(promises).then();
	}

	/**
	 * Sets the reference to use for the spheroid component, by name or the type index.
	 * @param {string | number} nameOrTypeIndex
	 */
	setSpheroidReference(nameOrTypeIndex) {
		if (typeof nameOrTypeIndex === 'string') {
			this._spheroidComponentRef.setByName(this.getEntity().getName(), nameOrTypeIndex);
		}
		else {
			this._spheroidComponentRef.setByType(this.getEntity().getName(), 'spheroid', nameOrTypeIndex);
		}
	}

	/**
	 * Cleans up the component.
	 * @override
	 * @package
	 */
	__destroy() {
		// Remove the spheroid changed callback.
		const spheroidComponent = this._spheroidComponentRef.get();
		if (spheroidComponent !== null) {
			spheroidComponent.removeChangedCallback(this._spheroidChangedCallback);
		}

		super.__destroy();
	}

	/**
	 * Updates the camera-non-specific parts of the component.
	 * @override
	 * @package
	 */
	__update() {
		// Set the texture LOD target size.
		for (let i = 0; i < this._textureLODs.size; i++) {
			const textureLODs = this._textureLODs.getAt(i).value;
			for (let i = 0, l = textureLODs.length; i < l; i++) {
				textureLODs[i].update();
			}
		}

		// Update the spheroid component reference.
		this._spheroidComponentRef.update();
	}

	/**
	 * Prepares the component for render.
	 * @param {CameraComponent} camera
	 * @override
	 * @package
	 */
	__prepareForRender(camera) {
		// Set the orientation and position to the entity's orientation and camera-space position.
		const objects = this.getThreeJsObjects();
		const longitudinalRotation = Quaternion.pool.get();
		longitudinalRotation.setFromAxisAngle(Vector3.ZAxis, this._longitudinalRotation);
		for (let i = 0; i < objects.length; i++) {
			ThreeJsHelper.setOrientationToEntity(objects[i], this.getEntity(), longitudinalRotation);
			ThreeJsHelper.setPositionToEntity(objects[i], this.getEntity(), camera);
		}
		Quaternion.pool.release(longitudinalRotation);

		// Get the atmosphere.
		const atmosphere = this._atmosphereComponentRef.get();

		// Setup the regular uniforms.
		MaterialUtils.setUniforms(this.getThreeJsMaterials(), camera, this.getEntity(), this._shadowEntities, atmosphere, true);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	__loadResources() {
		const textureLODPromises = [];
		for (let i = 0; i < this._numFaces; i++) {
			// Create the material.
			const material = MaterialUtils.get();
			this.getThreeJsMaterials().push(material);
			for (const feature of this._features) {
				material.defines[feature] = true;
				material.needsUpdate = true;
			}

			// Create the object.
			const object = ThreeJsHelper.createMeshObject(this, material, [
				{ name: 'position', dimensions: 3 },
				{ name: 'normal', dimensions: 3 },
				{ name: 'uv', dimensions: 2 }], false);
			this.getThreeJsObjects().push(object);

			// Make it used in the dynamic environment map.
			ThreeJsHelper.useInDynEnvMap(object, true);

			// Set the texture LODs uniforms.
			for (let j = 0; j < this._textureLODs.size; j++) {
				const name = this._textureLODs.getAt(j).key;
				const uniform = material.uniforms[name + 'Texture'];
				if (uniform !== undefined) {
					const textureLOD = this._textureLODs.get(name)[i];
					textureLOD.setUniform(uniform);
					textureLOD.update();
					textureLODPromises.push(textureLOD.getLoadedPromise());
				}
			}
		}

		return Promise.all(textureLODPromises).then(() => {
			this._updateMeshes();
		});
	}

	/**
	 * Destroys the triangle meshes and LOD objects.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		// Destroy al of the objects and materials and textures.
		ThreeJsHelper.destroyAllObjectsAndMaterials(this);

		// Set the texture LODs uniforms to null.
		for (let i = 0; i < this._textureLODs.size; i++) {
			const name = this._textureLODs.getAt(i).key;
			for (let face = 0; face < this._numFaces; face++) {
				this._textureLODs.get(name)[face].setUniform(null);
			}
		}
	}

	/**
	 * Updates the ThreeJS mesh with the latest parameters.
	 * @private
	 */
	_updateMeshes() {
		// No triangle meshes or spheroid, so do nothing.
		if (this.getThreeJsObjects().length === 0) {
			return;
		}
		const spheroidComponent = this._spheroidComponentRef.get();
		if (spheroidComponent === null) {
			return;
		}

		if (this._mapping === 'cylinder') {
			const latDistance = this._upperRightBounds.lat - this._lowerLeftBounds.lat;
			const lonDistance = this._upperRightBounds.lon - this._lowerLeftBounds.lon;
			const numLatVerts = Math.max(Math.ceil(100 * latDistance / MathUtils.pi), 4);
			const numLonVerts = Math.max(Math.ceil(200 * lonDistance / MathUtils.twoPi), 4);
			const latStep = latDistance / (numLatVerts - 1);
			const lonStep = lonDistance / (numLonVerts - 1);
			const numVerts = numLonVerts * numLatVerts;

			const meshPositions = new Float32Array(numVerts * 3);
			const meshNormals = new Float32Array(numVerts * 3);
			const meshUVs = new Float32Array(numVerts * 2);
			const meshIndices = new Uint16Array((numLonVerts - 1) * (numLatVerts - 1) * 6);

			const xyz = Vector3.pool.get();
			const lla = LatLonAlt.pool.get();
			for (let latI = 0; latI < numLatVerts; latI++) {
				for (let lonI = 0; lonI < numLonVerts; lonI++) {
					lla.lat = this._lowerLeftBounds.lat + latI * latStep;
					lla.lon = this._lowerLeftBounds.lon + lonI * lonStep;
					lla.alt = 0;

					const vertexI = latI * numLonVerts + lonI;
					spheroidComponent.xyzFromLLA(xyz, lla);
					meshPositions[vertexI * 3 + 0] = xyz.x;
					meshPositions[vertexI * 3 + 1] = xyz.y;
					meshPositions[vertexI * 3 + 2] = xyz.z;
					spheroidComponent.upFromLLA(xyz, lla);
					meshNormals[vertexI * 3 + 0] = xyz.x;
					meshNormals[vertexI * 3 + 1] = xyz.y;
					meshNormals[vertexI * 3 + 2] = xyz.z;
					meshUVs[vertexI * 2 + 0] = lonI / (numLonVerts - 1);
					meshUVs[vertexI * 2 + 1] = 1.0 - latI / (numLatVerts - 1);

					if (latI + 1 < numLatVerts && lonI + 1 < numLonVerts) {
						const triangleI = latI * (numLonVerts - 1) + lonI;
						meshIndices[triangleI * 6 + 0] = numLonVerts * (latI + 0) + (lonI + 0);
						meshIndices[triangleI * 6 + 1] = numLonVerts * (latI + 1) + (lonI + 1);
						meshIndices[triangleI * 6 + 2] = numLonVerts * (latI + 1) + (lonI + 0);
						meshIndices[triangleI * 6 + 3] = numLonVerts * (latI + 0) + (lonI + 0);
						meshIndices[triangleI * 6 + 4] = numLonVerts * (latI + 0) + (lonI + 1);
						meshIndices[triangleI * 6 + 5] = numLonVerts * (latI + 1) + (lonI + 1);
					}
				}
			}
			LatLonAlt.pool.release(lla);
			Vector3.pool.release(xyz);

			const mesh = /** @type {THREE.Mesh<THREE.BufferGeometry>} */ (this.getThreeJsObjects()[0]);
			ThreeJsHelper.setVertices(mesh.geometry, 'position', meshPositions);
			ThreeJsHelper.setVertices(mesh.geometry, 'normal', meshNormals);
			ThreeJsHelper.setVertices(mesh.geometry, 'uv', meshUVs);
			ThreeJsHelper.setIndices(mesh.geometry, meshIndices);
			if (this._features.has('normalMap')) {
				ThreeJsHelper.computeTangents(mesh.geometry);
			}
		}
		else if (this._mapping === 'cube') {
			const numGridLines = 50;

			const xyz = Vector3.pool.get();
			const lla = LatLonAlt.pool.get();
			for (let i = 0; i < 6; i++) {
				const meshPositions = new Float32Array(numGridLines * numGridLines * 3);
				const meshNormals = new Float32Array(numGridLines * numGridLines * 3);
				const meshUVs = new Float32Array(numGridLines * numGridLines * 2);
				const meshIndices = new Uint16Array((numGridLines - 1) * (numGridLines - 1) * 6);

				// Check the handed-ness of the cubemap vectors.
				let flipped = false;
				const zVector = Vector3.pool.get();
				zVector.cross(this._cubeMapFaceFrames[i][0], this._cubeMapFaceFrames[i][1]);
				if (zVector.x !== this._cubeMapFaceFrames[i][2].x || zVector.y !== this._cubeMapFaceFrames[i][2].y || zVector.z !== this._cubeMapFaceFrames[i][2].z) {
					flipped = true;
				}
				Vector3.pool.release(zVector);

				// Create the vertices, normals, and uvs.
				let vertexI = 0;
				let triangleI = 0;
				for (let k = 0; k < numGridLines; k++) {
					for (let j = 0; j < numGridLines; j++) {
						xyz.mult(this._cubeMapFaceFrames[i][0], (2 * j) / (numGridLines - 1) - 1);
						xyz.addMult(xyz, this._cubeMapFaceFrames[i][1], (2 * k) / (numGridLines - 1) - 1);
						xyz.addMult(xyz, this._cubeMapFaceFrames[i][2], 1.0);
						xyz.normalize(xyz);

						// Convert to lla as if xyz was on a sphere, then using it as a geodetic lla.
						Geometry.getLLAFromXYZOnSphere(lla, xyz, 0);
						lla.alt = 0;

						// Set the position.
						spheroidComponent.xyzFromLLA(xyz, lla);
						meshPositions[vertexI * 3 + 0] = xyz.x;
						meshPositions[vertexI * 3 + 1] = xyz.y;
						meshPositions[vertexI * 3 + 2] = xyz.z;

						// Set the normals.
						spheroidComponent.upFromLLA(xyz, lla);
						meshNormals[vertexI * 3 + 0] = xyz.x;
						meshNormals[vertexI * 3 + 1] = xyz.y;
						meshNormals[vertexI * 3 + 2] = xyz.z;

						// Set the UVs.
						meshUVs[vertexI * 2 + 0] = j / (numGridLines - 1);
						meshUVs[vertexI * 2 + 1] = 1.0 - k / (numGridLines - 1);

						// Set the indices.
						if (j < numGridLines - 1 && k < numGridLines - 1) {
							meshIndices[triangleI * 6 + 0] = (k + 0) * numGridLines + (j + 0);
							meshIndices[triangleI * 6 + 3] = (k + 1) * numGridLines + (j + 1);
							if (!flipped) {
								meshIndices[triangleI * 6 + 1] = (k + 0) * numGridLines + (j + 1);
								meshIndices[triangleI * 6 + 2] = (k + 1) * numGridLines + (j + 1);
								meshIndices[triangleI * 6 + 4] = (k + 1) * numGridLines + (j + 0);
								meshIndices[triangleI * 6 + 5] = (k + 0) * numGridLines + (j + 0);
							}
							else {
								meshIndices[triangleI * 6 + 1] = (k + 1) * numGridLines + (j + 1);
								meshIndices[triangleI * 6 + 2] = (k + 0) * numGridLines + (j + 1);
								meshIndices[triangleI * 6 + 4] = (k + 0) * numGridLines + (j + 0);
								meshIndices[triangleI * 6 + 5] = (k + 1) * numGridLines + (j + 0);
							}
							triangleI += 1;
						}

						vertexI += 1;
					}
				}

				const mesh = /** @type {THREE.Mesh<THREE.BufferGeometry>} */ (this.getThreeJsObjects()[i]);
				ThreeJsHelper.setVertices(mesh.geometry, 'position', meshPositions);
				ThreeJsHelper.setVertices(mesh.geometry, 'normal', meshNormals);
				ThreeJsHelper.setVertices(mesh.geometry, 'uv', meshUVs);
				ThreeJsHelper.setIndices(mesh.geometry, meshIndices);
				if (this._features.has('normalMap')) {
					ThreeJsHelper.computeTangents(mesh.geometry);
				}
			}
			LatLonAlt.pool.release(lla);
			Vector3.pool.release(xyz);
		}
	}

	/**
	 * Callback called when the spheroid reference is found or lost.
	 * @param {SpheroidComponent} oldRef
	 * @param {SpheroidComponent} newRef
	 * @private
	 */
	_spheroidRefChangedCallback(oldRef, newRef) {
		if (oldRef !== null) {
			oldRef.removeChangedCallback(this._spheroidChangedCallback);
		}
		if (newRef !== null) {
			newRef.addChangedCallback(this._spheroidChangedCallback);
		}
		this._spheroidChangedCallback();
	}

	/**
	 * Callback to be called when the spheroid component changed.
	 * @private
	 */
	_spheroidChangedCallback() {
		// Set the radii uniforms.
		const spheroidComponent = this._spheroidComponentRef.get();
		if (spheroidComponent !== null) {
			this.__setRadius(Math.max(spheroidComponent.getEquatorialRadius(), spheroidComponent.getPolarRadius()));
		}
		else {
			this.__setRadius(0);
		}
		this.resetResources();
	}
}

/**
 * Maps texture names to features to activate/deactivate automatically.
 * @type {Map<string, string>}
 */
SpheroidLODComponent._textureToFeature = new Map([
	['normal', 'normalMap'],
	['night', 'nightMap'],
	['decal', 'decalMap'],
	['specular', 'specularMap']
]);
