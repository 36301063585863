/** @module pioneer */
import {
	Capabilities,
	Config,
	Downloader,
	ThreeJsKTXLoader,
	TextureLoader,
	THREE
} from './internal';

/**
 * The compressed texture loader. Responsible for loading all compressed textures.
 * If `useCompression` is used for any of the functions, the appropriate extension is retrieved from Capabilities,
 * and it plus the '.ktx' extension replace the extension of the original url. See {@link Capabilities} for more detail.
 */
export class TextureLoaderCompressed extends TextureLoader {
	/**
	 * Constructor.
	 * @param {Downloader} downloader - The download manager.
	 * @param {Config} config - The config manager.
	 * @param {THREE.WebGLRenderer} renderer
	 */
	constructor(downloader, config, renderer) {
		super(downloader, renderer);

		/**
		 * The config.
		 * @type {Config}
		 * @private
		 */
		this._config = config;

		/**
		 * A ThreeJs texture loader for use by the load function.
		 * @type {ThreeJsKTXLoader}
		 * @private
		 */
		this._threeJsKTXLoader = new ThreeJsKTXLoader();

		// Configure the loaders.
		this._threeJsKTXLoader.setCrossOrigin(this._crossOrigin);
		this._threeJsKTXLoader.setPath(this._path);
	}

	/**
	 * Loads a texture and calls the callbacks when it is done. The signature is to be compatible with THREE.TextureLoader.
	 * @param {string} url - The url of the texture to load.
	 * @param {(texture: THREE.Texture) => any} onLoad - The callback that is called when the texture is loaded.
	 * @param {(event: ProgressEvent<EventTarget>, request: XMLHttpRequest) => any} onProgress - The callback that is called when there is progress loading the texture.
	 * @param {(message: string) => any} onError - The callback that is called when there is an error loading the texture.
	 * @param {number} [downloadPriority] - The priority of the download. Greater is higher priority.
	 * @param {boolean} [useMipMaps] - Whether or not to use mipmap textures. If true, only power-of-two textures are allowed.
	 * @returns {THREE.Texture}
	 * @override
	 */
	load(url, onLoad, onProgress, onError, downloadPriority, useMipMaps) {
		// If it's a normal, just do regular texture.
		if (url.match(/_n\.[^.]+$/) !== null) {
			return super.load(url, onLoad, onProgress, onError, downloadPriority, useMipMaps);
		}

		// Process the URL
		url = this._downloader.processUrl(url);

		// Handle preloaded cases
		let texture = null;
		if (url === 'white') {
			texture = this._white;
		}
		else if (url === 'black') {
			texture = this._black;
		}
		else if (url === 'clear') {
			texture = this._clear;
		}
		else if (url === 'pink') {
			texture = this._pink;
		}
		if (texture !== null) {
			onLoad(texture);
		}
		else {
			// Replace the url with the .ktx version.
			url = url.replace(/\.(\w+)$/, '-' + Capabilities.__getCompressedTextureExtension() + '.ktx');
			// Load the texture.
			this._threeJsKTXLoader.load(url, (texture) => {
				texture.flipY = false;
				texture.needsUpdate = true;
				if (useMipMaps === false) { // If undefined or true, it'll use mipmaps.
					texture.minFilter = THREE.LinearFilter;
					texture.generateMipmaps = false;
				}
				// @ts-ignore
				texture.ktxFormat = texture.format; // This is needed because GLTFLoader forces the texture format to RGB/A. See https://github.com/mrdoob/three.js/issues/15763
				onLoad(texture);
			}, undefined, (event) => {
				onError(event.message);
			});
		}

		return texture;
	}
}
