/* eslint-disable prefer-arrow-callback */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import globalRefs from '../managers/globalRefs';
import { videoStore } from '../managers/globalState';

const VideoDetail = () => {
	const { getManager } = globalRefs;
	const { videoParam } = useParams();
	const currentVideoFromParam = getManager('video').getVideo(videoParam);

	const { type } = currentVideoFromParam || {};
	const offsetViewport = type === 'modal' || type === 'static';

	// useEffect for setting global state on mount and unmount.
	useEffect(() => {
		const { getManager, pioneer } = globalRefs;
		const { type } = currentVideoFromParam || {};

		if (currentVideoFromParam) {
			const isVideoTexture = type === 'global' || type === 'geoLocated';

			getManager('video').loadVideo(currentVideoFromParam)
				.then(() => {
					videoStore.setGlobalState({ currentVideo: currentVideoFromParam, isVideoTexture });
				});
		}

		// On currentVideo change / unmount disable the earth patch.
		return () => {
			if (type === 'geoLocated') {
				pioneer.get('main', 'earth').getComponent('patch')?.setEnabled?.(false);
			}
		};
	}, [currentVideoFromParam]);

	// useEffect to unload video on unmount.
	useEffect(() => {
		const { getManager } = globalRefs;
		return () => {
			getManager('video').unloadVideo();
		};
	}, []);


	// useEffect for setting viewport offset according to isVideoTexture.
	useEffect(() => {
		const { getManager } = globalRefs;
		const offset = offsetViewport ? 18 : 0;
		getManager('camera').offsetPioneerViewportLeft(offset);

		// Return a function to reset the offset.
		return () => {
			getManager('camera').offsetPioneerViewportLeft(0);
		};
	}, [offsetViewport]);

	return null;
};

export default VideoDetail;
