/**
 * Some test data for the event preview.
 */


export const testAnimatedEventGlobal = {
	external_id: 'eo_event_animated_global_test',
	title: 'Preview Test Animated Global Event',
	date: '2024-07-03',
	description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem veniam ipsum repellat, tempora animi veritatis dolore. Minus, quis nihil vero dolorem molestias eaque optio et consectetur rerum voluptates eligendi aperiam modi ut vel omnis repudiandae, laboriosam est, fugit dolor. Voluptas, quibusdam. Ut maiores nulla, suscipit dicta illum pariatur quas saepe.',
	imagecategory: 'climate, animated',
	list_image_src: '$DATAAREA/eo/24/0703_thumbnail.png',
	image_src: 'assets/video/atmo_river_dated_1080p30.mp4',
	formatted_date: 'Jul 03, 2024',
	placementMethod: 'global',
	offset: [0, 90]
};

export const testAnimatedGeoLocated = {
	external_id: 'eo_event_animated_geoLocated_test',
	title: 'Preview Test Animated GeoLocated Event',
	date: '2024-07-01',
	description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, architecto! Dolore provident tempore, ea, praesentium saepe eos non aliquid ex ullam exercitationem quo voluptate ut hic laudantium eveniet, illo veniam mollitia doloribus natus aperiam voluptatum. Veritatis quibusdam dolore voluptate eveniet!',
	imagecategory: 'climate, animated',
	center: '48.70036461765667, -113.85399823575553',
	list_image_src: '$DATAAREA/eo/24/0701_thumbnail.png',
	image_src: 'assets/video/CA_Central_Valley.mp4',
	formatted_date: 'Jul 01, 2024',
	placementMethod: 'geoLocated',
	north_boundary: 38.253,
	east_boundary: -118.46,
	south_boundary: 34.727464,
	west_boundary: -120.9983
};

export const testStaticGeoLocated = {
	external_id: 'eo_event_static_geoLocated_test',
	title: 'Preview Test Static GeoLocated Event',
	date: '2023-01-06',
	description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Harum accusantium error, omnis unde, dolor iste, qui at quos possimus aut ab. Consequuntur in repellat incidunt veritatis obcaecati explicabo, ea quis facilis! Animi ea nam sapiente magnam odit dolore perferendis omnis, porro aperiam at magni cupiditate!',
	imagecategory: 'storms,water,land',
	center: '41.189499999999995, -128.75324999999998',
	list_image_src: '$DATAAREA/eo/23/0106_thumbnail.png',
	image_src: '$DATAAREA/eo/23/0106.png',
	formatted_date: 'Jan 06, 2023',
	north_boundary: 59.3895,
	east_boundary: -111.82812922705313,
	south_boundary: 22.9895,
	west_boundary: -145.67837077294683
};
