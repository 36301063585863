import { ShaderChunkLogDepth } from './log_depth';

export const ConnectedSpriteShader = {
	uniforms: {
		projectionMatrix: 'highp mat4',
		vAxis: 'vec3',
		color: 'vec4',
		colorTexture: 'sampler2D',
		width1: 'float',
		width2: 'float',
		textureYOffset: 'float',
		repeatAmount: 'float',

		...ShaderChunkLogDepth.Uniforms
	},
	properties: {
		side: 'double',
		transparent: true,
		depthWrite: false,
		blending: 'normal'
	},
	vertex: {
		extensions: [
			'EXT_frag_depth'
		],
		code: `
			attribute vec3 position;

			uniform mat4 modelMatrix;
			uniform mat4 viewMatrix;
			uniform mat4 projectionMatrix;

			uniform vec3 vAxis;
			uniform float width1;
			uniform float width2;

			varying vec2 vPosition;
			varying float vU;

			${ShaderChunkLogDepth.VertexHead}

			void main() {

				// Get the horizontal axis.
				float width = mix(width1, width2, position.z);
				vec3 viewVAxis = (viewMatrix * vec4(vAxis, 0)).xyz;
				vec2 viewHAxisXZ = normalize(cross(vec3(0, 1, 0), viewVAxis)).xz;
				vec4 viewHAxis = vec4(viewHAxisXZ.x, 0, viewHAxisXZ.y, 0);

				// Get the view position.
				vec4 modelPosition = modelMatrix * vec4(0, 0, 0, 1) + vec4(vAxis, 0) * position.z;
				vec4 viewPosition = viewMatrix * modelPosition;

				// Set the final projected position.
				gl_Position = projectionMatrix * viewPosition + projectionMatrix * viewHAxis * width * position.x;

				// Setup a vPosition and vU for use in the fragment shader.
				vPosition = vec2(0.5 * (position.x + 1.0), position.z);
				#ifdef PIXEL_BASED
					vPosition.y *= abs(gl_Position.w);
				#endif
				vU = position.z;

				${ShaderChunkLogDepth.Vertex}
			}`
	},
	fragment: {
		extensions: [
			'EXT_frag_depth'
		],
		code: `
			precision highp float;

			uniform vec4 color;
			uniform sampler2D colorTexture;
			uniform float width1;
			uniform float width2;
			uniform float textureYOffset;
			uniform float repeatAmount;

			varying vec2 vPosition;
			varying float vU;

			${ShaderChunkLogDepth.FragmentHead}

			void main() {

				// If we're using pixel-based, we need to undo the perspective divide that happened.
				#ifdef PIXEL_BASED
					vec2 uv = vec2(vPosition.x, vPosition.y * gl_FragCoord.w);
				#else
					vec2 uv = vPosition;
				#endif

				// Get a correct use that uses the repeat amount and y offset.
				// There's a complicated formula, because the widths may be different and the shape of the sprite may be a trapezoid.
				float f = width2 * vU / (width1 * (1.0 - vU) + width2 * vU);
				float uFactor = step(vU, uv.x);
				uv.x = (1.0 - uFactor) * uv.x * f / vU + uFactor * (1.0 + (uv.x - 1.0) * (1.0 - f) / (1.0 - vU));
				uv.y = uv.y * repeatAmount + textureYOffset;

				// Apply the texture and color.
				gl_FragColor = color * texture2D(colorTexture, uv);

				${ShaderChunkLogDepth.Fragment}
			}`
	}
};
