/** @module pioneer */
import {
	BaseComponent,
	BaseController,
	ComponentRef,
	Entity,
	Quaternion,
	SpheroidComponent,
	Vector3
} from '../../internal';

/**
 * @typedef {Object} HasGetGroundPosition
 * @property {(outPosition: Vector3, outHeightDir: Vector3, position: Vector3) => void} getGroundPosition
 */

/**
 * A controller that clamps an entty to the ground.
 */
export class GroundClampController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The component to clamp to.
		 * @type {ComponentRef<BaseComponent & HasGetGroundPosition>}
		 * @private
		 */
		this._groundComponentRef = new ComponentRef(this.getEntity().getScene());

		/**
		 * The offset in the up direction.
		 * @type {number}
		 * @private
		 */
		this._distanceFromGround = 0;

		/**
		 * The frame-up vector for use in clamping correctly to slanted surfaces.
		 * @type {Vector3}
		 * @private
		 */
		this._up = new Vector3(0, 0, 1);
		this._up.freeze();

		/**
		 * The flag that enables clamping only if the entity is below the terrain.
		 * @type {boolean}
		 * @private
		 */
		this._clampOnlyIfBelow = false;

		// Let the base controller know that this changes the position.
		this.addModifiedState('position');
	}

	/**
	 * Sets the component to clamp to. Defaults to the nearest ancestor spheroid or null.
	 * @param {string} entityName
	 * @param {string} componentType
	 * @param {number} [componentTypeIndex]
	 */
	setGroundComponentRef(entityName, componentType, componentTypeIndex) {
		this.removeDependentState(this._groundComponentRef.getEntityName(), 'orientation');
		this._groundComponentRef.setByType(entityName, componentType, componentTypeIndex);
		this.addDependentState(entityName, 'orientation');
	}

	/**
	 * Gets the distance in the up direction from the ground. Defaults to 0.
	 * @returns {number}
	 */
	getDistanceFromGround() {
		return this._distanceFromGround;
	}

	/**
	 * Sets the distance in the up direction from the ground. Negative is below the ground. Defaults to 0.
	 * @param {number} distanceFromGround
	 */
	setDistanceFromGround(distanceFromGround) {
		this._distanceFromGround = distanceFromGround;
	}

	/**
	 * Gets the frame-up vector for use in clamping correctly to slanted surfaces. Defaults to the z-axis vector.
	 * @returns {Vector3}
	 */
	getUp() {
		return this._up;
	}

	/**
	 * Sets the frame-up vector for use in clamping correctly to slanted surfaces. Defaults to the z-axis vector.
	 * @param {Vector3} up
	 */
	setUp(up) {
		this._up.thaw();
		this._up.copy(up);
		this._up.freeze();
	}

	/**
	 * Gets the flag that enables clamping only if the entity is below the terrain. Defaults to false.
	 * @returns {boolean}
	 */
	getClampOnlyIfBelow() {
		return this._clampOnlyIfBelow;
	}

	/**
	 * Sets the flag that enables clamping only if the entity is below the terrain. Defaults to false.
	 * @param {boolean} clampOnlyIfBelow
	 */
	setClampOnlyIfBelow(clampOnlyIfBelow) {
		this._clampOnlyIfBelow = clampOnlyIfBelow;
	}

	/**
	 * Updates a position for the given time.
	 * @param {Vector3} position
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updatePositionAtTime(position, time) {
		// Get the entity parent.
		const entityParentNameAtTime = this.getEntity().getParentAtTime(time);
		const entityParentAtTime = this.getEntity().getScene().getEntity(entityParentNameAtTime);
		if (entityParentAtTime === null) {
			return;
		}
		// Get the ground component, or get the nearest ancestor with a spheroid.
		let groundComponent = this._groundComponentRef.get();
		if (groundComponent === null) {
			let parent = this.getEntity().getParent();
			while (parent !== null) {
				groundComponent = parent.getComponentByClass(SpheroidComponent);
				if (groundComponent !== null) {
					break;
				}
				parent = parent.getParent();
			}
			// No valid ground component, do nothing.
			if (groundComponent === null || groundComponent.getGroundPosition === undefined) {
				return;
			}
		}
		const groundComponentEntity = groundComponent.getEntity();
		// Get the position of the entity in the component entity's frame-space.
		const entityPosition = Vector3.pool.get();
		const groundPosition = Vector3.pool.get();
		const heightDir = Vector3.pool.get();
		const groundOrientationAtTime = Quaternion.pool.get();
		groundComponentEntity.getOrientationAtTime(groundOrientationAtTime, time);
		entityParentAtTime.getPositionRelativeToEntity(entityPosition, position, groundComponentEntity, time);
		entityPosition.rotateInverse(groundOrientationAtTime, entityPosition);
		// Get the ground position and height direction at the given position.
		groundComponent.getGroundPosition(groundPosition, heightDir, entityPosition);
		if (!groundPosition.isNaN()) {
			// Get the offset in the height direction for the distanceToGround.
			const entityUp = Vector3.pool.get();
			const entityOrientationAtTime = Quaternion.pool.get();
			this.getEntity().getOrientationAtTime(entityOrientationAtTime, time);
			entityUp.rotate(entityOrientationAtTime, this._up);
			Quaternion.pool.release(entityOrientationAtTime);
			entityUp.rotateInverse(groundOrientationAtTime, entityUp);
			const cosUpAndGroundUp = Math.abs(entityUp.dot(heightDir));
			Vector3.pool.release(entityUp);
			const heightOffset = Math.min(this._distanceFromGround / cosUpAndGroundUp, this._distanceFromGround + this.getEntity().getExtentsRadius());
			// Set up the entity position.
			groundPosition.addMult(groundPosition, heightDir, heightOffset);
			if (!this._clampOnlyIfBelow || entityPosition.dot(heightDir) - groundPosition.dot(heightDir) < 0) {
				// Get the position back into the entity-space of the parent.
				entityPosition.rotate(groundOrientationAtTime, groundPosition);
				groundComponentEntity.getPositionRelativeToEntity(position, entityPosition, entityParentAtTime, time);
			}
		}
		// Clean up.
		Vector3.pool.release(entityPosition);
		Vector3.pool.release(groundPosition);
		Vector3.pool.release(heightDir);
		Quaternion.pool.release(groundOrientationAtTime);
	}

	/**
	 * Updates the controller.
	 * @override
	 * @internal
	 */
	__update() {
		if (this.getEntity().getParent() === null) {
			return;
		}
		// Get the ground component, or get the nearest ancestor with a spheroid.
		let groundComponent = this._groundComponentRef.get();
		if (groundComponent === null) {
			let parent = this.getEntity().getParent();
			while (parent !== null) {
				groundComponent = parent.getComponentByClass(SpheroidComponent);
				if (groundComponent !== null) {
					break;
				}
				parent = parent.getParent();
			}
			// No valid ground component, do nothing.
			if (groundComponent === null || groundComponent.getGroundPosition === undefined) {
				return;
			}
		}
		const groundComponentEntity = groundComponent.getEntity();
		// Get the position of the entity in the component entity's frame-space.
		const entityPosition = Vector3.pool.get();
		const groundPosition = Vector3.pool.get();
		const heightDir = Vector3.pool.get();
		this.getEntity().getPositionRelativeToEntity(entityPosition, Vector3.Zero, groundComponentEntity);
		entityPosition.rotateInverse(groundComponentEntity.getOrientation(), entityPosition);
		// Get the ground position and height direction at the given position.
		groundComponent.getGroundPosition(groundPosition, heightDir, entityPosition);
		if (!groundPosition.isNaN()) {
			// Get the offset in the height direction for the distanceToGround.
			const entityUp = Vector3.pool.get();
			entityUp.rotate(this.getEntity().getOrientation(), this._up);
			entityUp.rotateInverse(groundComponentEntity.getOrientation(), entityUp);
			const cosUpAndGroundUp = Math.abs(entityUp.dot(heightDir));
			Vector3.pool.release(entityUp);
			const heightOffset = Math.min(this._distanceFromGround / cosUpAndGroundUp, this._distanceFromGround + this.getEntity().getExtentsRadius());
			// Set up the entity position.
			groundPosition.addMult(groundPosition, heightDir, heightOffset);
			if (!this._clampOnlyIfBelow || entityPosition.dot(heightDir) - groundPosition.dot(heightDir) < 0) {
				// Get the position back into the entity-space of the parent.
				entityPosition.rotate(groundComponentEntity.getOrientation(), groundPosition);
				groundComponentEntity.getPositionRelativeToEntity(entityPosition, entityPosition, this.getEntity().getParent());
				// Set the position.
				this.getEntity().setPosition(entityPosition);
			}
		}
		// Clean up.
		Vector3.pool.release(entityPosition);
		Vector3.pool.release(groundPosition);
		Vector3.pool.release(heightDir);
	}
}
