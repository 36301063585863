import '../../www/assets/css/thumbnail_loader.css';

/**
 * Simple thumbnail loader component
 */
const ThumbnailLoader = ({ loaderText }) => (
	<div className='thumbnail-loader-container'>
		<div className='thumbnail-loader' />
		{loaderText && <span className='thumbnail-loader-text'>{loaderText}</span>}
	</div>
);

export default ThumbnailLoader;
