/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';
import proj4 from 'proj4';

/**
 * The capabilities XML file.
 * @private
 */
class Capabilities {
	/**
	 * The constructor.
	 * @param {string} endPoint
	 * @param {Pioneer.Engine} engine
	 * @param {number} downloadPriority
	 */
	constructor(endPoint, engine, downloadPriority) {
		/**
		 * The end point for the WMTS protocol.
		 * @type {string}
		 * @private
		 */
		this._endPoint = endPoint;

		/**
		 * The XML content of the capabilities.
		 * @type {Document}
		 * @private
		 */
		this._capabilitiesXML = null;

		/**
		 * The list of layers as a map from titles to identifiers.
		 * @type {Map<string, string>}
		 * @private
		 */
		this._layers = new Map();

		/**
		 * A promise that resolves when the capabilities is ready to be used.
		 * @type {Promise<void>}
		 * @private
		 */
		this._readyPromise = null;

		// Get the capabilities.
		this._readyPromise = engine.getDownloader().download(this._endPoint + '/1.0.0/WMTSCapabilities.xml', false, downloadPriority).then((download) => {
			if (typeof download.content === 'string') {
				this._capabilitiesXML = new DOMParser().parseFromString(download.content, 'application/xml');
			}

			// Populate the layers.
			if (this._capabilitiesXML !== null) {
				const layerElems = this._capabilitiesXML.querySelectorAll('Contents > Layer');
				for (const layerElem of layerElems) {
					const title = layerElem.querySelector('*|Title').textContent;
					const identifier = layerElem.querySelector('*|Identifier').textContent;
					this._layers.set(title, identifier);
				}
			}
		});
	}

	/**
	 * Gets the promise that resolves when it is ready to be used.
	 * @returns {Promise<void>}
	 */
	get readyPromise() {
		return this._readyPromise;
	}

	/**
	 * Gets the end point.
	 * @returns {string}
	 */
	get endPoint() {
		return this._endPoint;
	}

	/**
	 * Gets the list of layers as a map from titles to identifiers.
	 * @returns {Map<string, string>}
	 */
	get layers() {
		return this._layers;
	}

	/**
	 * Gets a layer from its identifier. Returns null if the identifier is invalid.
	 * @param {string} identifier
	 * @returns {Layer}
	 */
	getLayer(identifier) {
		let layer = null;
		if (this._capabilitiesXML !== null) {
			const layerElems = this._capabilitiesXML.querySelectorAll('Contents > Layer');
			for (const layerElem of layerElems) {
				const layerIdentifier = layerElem.querySelector('*|Identifier').textContent;
				if (identifier === layerIdentifier) {
					layer = new Layer(layerElem, this._endPoint + '/1.0.0');
				}
			}
		}
		return layer;
	}

	/**
	 * Gets a tile matrix set from its identifier. Returns null if the identifier is invalid.
	 * @param {string} identifier
	 * @returns {TileMatrixSet}
	 */
	getTileMatrixSet(identifier) {
		let tileMatrixSet = null;
		if (this._capabilitiesXML !== null) {
			const tileMatrixSetElems = this._capabilitiesXML.querySelectorAll('Contents > TileMatrixSet');
			for (const tileMatrixSetElem of tileMatrixSetElems) {
				const tileMatrixSetIdentifier = tileMatrixSetElem.querySelector('*|Identifier').textContent;
				if (identifier === tileMatrixSetIdentifier) {
					tileMatrixSet = new TileMatrixSet(tileMatrixSetElem);
				}
			}
		}
		return tileMatrixSet;
	}
}

/**
 * A capabilities layer.
 * @internal
 */
class Layer {
	/**
	 * Constructor.
	 * @param {Element} elem
	 * @param {string} baseUrl
	 */
	constructor(elem, baseUrl) {
		/**
		 * The base URL
		 * @type {string}
		 * @private
		 */
		this._baseUrl = baseUrl;

		/**
		 * The title.
		 * @type {string}
		 * @private
		 */
		this._title = elem.querySelector('*|Title').textContent;

		/**
		 * The WMTS layer identifier.
		 * @type {string}
		 * @private
		 */
		this._identifier = elem.querySelector('*|Identifier').textContent;

		/**
		 * The mapping of styles from title to identifier.
		 * @type {Map<string, string>}
		 * @private
		 */
		this._styles = new Map();

		/**
		 * The style that is the default.
		 * @type {string}
		 * @private
		 */
		this._defaultStyle = '';

		/**
		 * The bounding box of the layer.
		 * @type {Pioneer.Rect}
		 * @private
		 */
		this._boundingBox = new Pioneer.Rect();

		/**
		 * The possible extra dimensions of the layer, along with its default.
		 * @type {Map<string, string>}
		 * @private
		 */
		this._dimensions = new Map();

		/**
		 * The tile matrix sets.
		 * @type {Set<string>}
		 * @private
		 */
		this._tileMatrixSets = new Set();

		/**
		 * The WMTS layer url.
		 * @type {string}
		 * @private
		 */
		this._url = '';

		// Get the styles.
		const styleElems = elem.querySelectorAll('Style');
		for (const styleElem of styleElems) {
			const title = styleElem.querySelector('*|Title').textContent;
			const identifier = styleElem.querySelector('*|Identifier').textContent;
			this._styles.set(title, identifier);

			if (styleElem.getAttribute('isDefault') === 'true') {
				this._defaultStyle = identifier;
			}
		}

		// Get the bounding box and crs.
		let boundingBoxElem = elem.querySelector('*|BoundingBox');
		if (boundingBoxElem === null) {
			boundingBoxElem = elem.querySelector('*|WGS84BoundingBox');
		}
		if (boundingBoxElem === null) {
			throw new Error('Layer "' + this._identifier + '" missing a BoundingBox or a WGS84BoundingBox tag.');
		}
		const lowerCornerArray = boundingBoxElem.querySelector('*|LowerCorner').textContent.split(' ');
		const upperCornerArray = boundingBoxElem.querySelector('*|UpperCorner').textContent.split(' ');
		this._boundingBox.origin.set(Number.parseFloat(lowerCornerArray[0]), Number.parseFloat(lowerCornerArray[1]));
		this._boundingBox.size.set(Number.parseFloat(upperCornerArray[0]) - this._boundingBox.origin.x, Number.parseFloat(upperCornerArray[1]) - this._boundingBox.origin.y);

		// Get the extra dimensions of the layer.
		const dimensionElems = elem.querySelectorAll('Dimension');
		for (const dimensionElem of dimensionElems) {
			const identifier = dimensionElem.querySelector('*|Identifier').textContent;
			const defaultValue = dimensionElem.querySelector('Default').textContent;
			this._dimensions.set(identifier, defaultValue);
		}

		// Get the tile matrix sets.
		const tileMatrixSetLinkElems = elem.querySelectorAll('TileMatrixSetLink');
		for (const tileMatrixSetLinkElem of tileMatrixSetLinkElems) {
			const tileMatrixSet = tileMatrixSetLinkElem.querySelector('TileMatrixSet').textContent;
			this._tileMatrixSets.add(tileMatrixSet);
		}

		// Get the url.
		const resourceURLElem = elem.querySelector('ResourceURL');
		this._url = resourceURLElem.getAttribute('template');
		// If it is relative, prepend the base url.
		if (!/^(?:[a-z]+:)?\/\//i.test(this._url)) {
			this._url = this._baseUrl + this._url;
		}
	}

	/**
	 * Gets the title.
	 * @returns {string}
	 */
	get title() {
		return this._title;
	}

	/**
	 * Gets the identifiers.
	 * @returns {string}
	 */
	get identifier() {
		return this._identifier;
	}

	/**
	 * Gets the mapping of style titles to identifiers.
	 * @returns {Map<string, string>}
	 */
	get styles() {
		return this._styles;
	}

	/**
	 * Gets the default style.
	 * @returns {string}
	 */
	get defaultStyle() {
		return this._defaultStyle;
	}

	/**
	 * Gets the bounding box.
	 * @returns {Pioneer.Rect}
	 */
	get boundingBox() {
		return this._boundingBox;
	}

	/**
	 * Gets the dimension options as a mapping of the identifier to its default.
	 * @return {Map<string, string>}
	 */
	get dimensions() {
		return this._dimensions;
	}

	/**
	 * Gets the tile matrix sets associated with this layer.
	 * @return {Set<string>}
	 */
	get tileMatrixSets() {
		return this._tileMatrixSets;
	}

	/**
	 * Gets the url template for the layer.
	 * @returns {string}
	 */
	get url() {
		return this._url;
	}
}

/**
 * A tile matrix set.
 * @internal
 */
class TileMatrixSet {
	/**
	 * Constructor.
	 * @param {Element} elem
	 */
	constructor(elem) {
		/**
		 * The WMTS layer identifier.
		 * @type {string}
		 * @private
		 */
		this._identifier = elem.querySelector('*|Identifier').textContent;

		/**
		 * The CRS of the tile matrix set.
		 * @type {string}
		 * @private
		 */
		this._crs = elem.querySelector('*|SupportedCRS').textContent;

		/**
		 * The bounding box of the tile matrix set.
		 * @type {Pioneer.Rect}
		 * @private
		 */
		this._boundingBox = new Pioneer.Rect();

		/**
		 * The number of levels that the matrix has.
		 * @type {Pioneer.Vector2[]}
		 * @private
		 */
		this._numTiles = [];

		/**
		 * A promise that resolves when it is ready to be used.
		 * @type {Promise<void>}
		 * @private
		 */
		this._readyPromise = null;

		// Check if it has proper power-of-two levels and set the number of tiles for each tile matrix.
		let scalePrev;
		let level = 0;
		for (const node of elem.querySelectorAll('TileMatrix')) {
			const scaleElem = node.querySelector('ScaleDenominator');
			if (!scaleElem) {
				throw new Error('ScaleDenominator tag not found for TileMatrix ' + level + '.');
			}
			const scale = parseFloat(scaleElem.innerHTML);
			if (scalePrev !== undefined && Math.abs(scale * 2 - scalePrev) / scale > 0.01) {
				throw new Error('ScaleDenominator for TileMatrix ' + level + ' must be half of the next higher level, but is not.');
			}
			scalePrev = scale;
			this._numTiles[level] = new Pioneer.Vector2(
				parseFloat(node.querySelector('MatrixWidth').innerHTML),
				parseFloat(node.querySelector('MatrixHeight').innerHTML));
			level += 1;
		}

		// Get the number of levels from the iteration above
		this._numLevels = level;

		// Calculate the bounding box.
		// It uses the ScaleDenominator, TileWidth/Height, and MatrixWidth/Height of level 0
		// to calculate the bounding box size. Since the calculation yields a number in
		// km / matrix and we need it in CRS units, we have to get the CRS unitsPerMeter value
		// in order to get the bounding box size in CRS units.
		const tileMatrix0Elem = elem.querySelector('TileMatrix');
		const scaleDenominator = Number.parseFloat(tileMatrix0Elem.querySelector('ScaleDenominator').textContent);
		const pixelsPerTile = new Pioneer.Vector2(Number.parseFloat(tileMatrix0Elem.querySelector('TileWidth').textContent), Number.parseFloat(tileMatrix0Elem.querySelector('TileHeight').textContent));
		const kmPerPixel = scaleDenominator * 0.28e-6;
		const kmPerTile = new Pioneer.Vector2(kmPerPixel * pixelsPerTile.x, kmPerPixel * pixelsPerTile.y);
		const kmPerMatrix = new Pioneer.Vector2(kmPerTile.x * this._numTiles[0].x, kmPerTile.y * this._numTiles[0].y);
		// Get the bounding box origin.
		const topLeftCornerArray = tileMatrix0Elem.querySelector('TopLeftCorner').textContent.split(' ');
		this._boundingBox.origin.set(Number.parseFloat(topLeftCornerArray[0]), Number.parseFloat(topLeftCornerArray[1]));
		// Get the CRS to convert km/matrix to CRS units/matrix.
		this._readyPromise = TileMatrixSet.setCRS(this._crs).then((crs) => {
			this._crs = crs;
			let unitsPerKm = 1e3;
			const proj = proj4.defs(this._crs);
			if (proj.to_meter) {
				unitsPerKm = 1000 / proj.to_meter;
			}
			else if (proj.units === 'degrees') {
				unitsPerKm = 0.00898315284;
			}
			else if (proj.units === 'feet' || proj.units === 'us feet') {
				unitsPerKm = 3280.83333335;
			}

			// Set the size, rounding off extra digits to prevent slight overflows.
			this._boundingBox.size.x = Math.round(kmPerMatrix.x * unitsPerKm * 1e6) / 1e6;
			this._boundingBox.size.y = Math.round(kmPerMatrix.y * unitsPerKm * 1e6) / 1e6;
			this._boundingBox.origin.y = this._boundingBox.origin.y - this._boundingBox.size.y;
		});
	}

	/**
	 * Gets a promise that resolves when it is ready to be used.
	 * @returns {Promise<void>}
	 */
	get readyPromise() {
		return this._readyPromise;
	}

	/**
	 * Gets the identifier.
	 * @returns {string}
	 */
	get identifier() {
		return this._identifier;
	}

	/**
	 * Gets the bounding box in CRS coordinates.
	 * @returns {Pioneer.Rect}
	 */
	get boundingBox() {
		return this._boundingBox;
	}

	/**
	 * Gets the number of levels.
	 * @returns {number}
	 */
	get numLevels() {
		return this._numLevels;
	}

	/**
	 * Gets the number of tiles in each direction at the given level.
	 * @param {number} level
	 * @returns {Pioneer.Vector2}
	 */
	getNumTiles(level) {
		return this._numTiles[level];
	}

	/**
	 * Converts CRS units to an XYZ.
	 * @param {Pioneer.Vector3} outXYZ
	 * @param {Pioneer.Vector2} crsUnits
	 * @param {Pioneer.SpheroidComponent} spheroid
	 */
	crsUnitsToXYZ(outXYZ, crsUnits, spheroid) {
		const lla = Pioneer.LatLonAlt.pool.get();
		// Clamp the x and y to the bounding box so that we don't get crazy values.
		const x = Pioneer.MathUtils.clamp(crsUnits.x, this._boundingBox.origin.x, this._boundingBox.origin.x + this._boundingBox.size.x);
		const y = Pioneer.MathUtils.clamp(crsUnits.y, this._boundingBox.origin.y, this._boundingBox.origin.y + this._boundingBox.size.y);
		// Convert it to regular geodetic latitude and longitude.
		const ll = proj4(this._crs, 'WGS84', [x, y]);
		lla.set(Pioneer.MathUtils.degToRad(ll[1]), Pioneer.MathUtils.degToRad(ll[0]), 0);
		// Convert it to xyz.
		spheroid.xyzFromLLA(outXYZ, lla);
		Pioneer.LatLonAlt.pool.release(lla);
	}

	/**
	 * Defines a projection in proj4 from the crs. Returns the string to be used on subsequent proj4 calls.
	 * @param {string} crs
	 * @returns {Promise<string>}
	 */
	static async setCRS(crs) {
		if (crs.startsWith('urn:ogc:def:crs:')) {
			crs = crs.substring('urn:ogc:def:crs:'.length);
		}
		// Try to get it locally first.
		let proj4Text = crsToWKT.get(crs);
		if (proj4Text === undefined && crs.startsWith('EPSG::')) {
			const url = 'https://epsg.io/' + crs.substring('EPSG::'.length) + '.proj4';
			const result = await fetch(url);
			proj4Text = await result.text();
		}
		proj4.defs(crs, proj4Text);
		return crs;
	}
}

const crsToWKT = /** @type {Map<string, string | any>} */(new Map());
// Some common ones to start us off.
crsToWKT.set('EPSG::104903', 'GEOGCS["GCS_Moon_2000",DATUM["D_Moon_2000",SPHEROID["Moon_2000_IAU_IAG",1737400.0,0.0]],PRIMEM["Reference_Meridian",0.0],UNIT["Degree",0.0174532925199433]]');
crsToWKT.set('EPSG::104905', 'GEOGCS["GCS_Mars_2000",DATUM["D_Mars_2000",SPHEROID["Mars_2000_IAU_IAG",3396190.0,169.8944472236118]],PRIMEM["Reference_Meridian",0.0],UNIT["Degree",0.0174532925199433]]');
crsToWKT.set('EPSG::4326', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs');
crsToWKT.set('EPSG::3857', '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext +no_defs');
crsToWKT.set('OGC:1.3:84', proj4.defs('WGS84'));
crsToWKT.set('OGC:1.3:CRS84', proj4.defs('WGS84'));
crsToWKT.set('OGC:2:84', proj4.defs('WGS84'));

export {
	Capabilities,
	Layer,
	TileMatrixSet
};
