import * as Pioneer from 'pioneer';
import { Entity } from './entity';

/**
 * Functions for enabling and disabling special features on entities.
 */
export class Features {
	/**
	 * Enables or disables the featureType of the entityName.
	 * @param {Pioneer.Scene} scene
	 * @param {string} entityName
	 * @param {string} featureType
	 * @param {boolean} enable
	 */
	static setEnabled(scene, entityName, featureType, enable) {
		const featureSetEnabledMap = entityFeatureSetEnabledMap[entityName];
		if (!featureSetEnabledMap) {
			throw new Error(`Attempted to access unavailable feature ${featureType} for ${entityName}.`);
		}
		const setEnabledFunction = featureSetEnabledMap[featureType];
		if (!setEnabledFunction) {
			throw new Error(`Attempted to access unavailable feature ${featureType} for ${entityName}.`);
		}
		setEnabledFunction(scene, enable);
	}

	/**
	 * Returns true if the featureType of the entityName is enabled.
	 * @param {Pioneer.Scene} scene
	 * @param {string} entityName
	 * @param {string} featureType
	 * @returns {boolean}
	 */
	static isEnabled(scene, entityName, featureType) {
		const featureIsEnabledMap = entityFeatureIsEnabledMap[entityName];
		if (!featureIsEnabledMap) {
			throw new Error(`Attempted to access unavailable feature ${featureType} for ${entityName}.`);
		}
		const isEnabledFunction = featureIsEnabledMap[featureType];
		if (!isEnabledFunction) {
			throw new Error(`Attempted to access unavailable feature ${featureType} for ${entityName}.`);
		}
		return isEnabledFunction(scene);
	}

	/**
	 * Enables or disables Jupiter's auroras.
	 * @param {Pioneer.Scene} scene
	 * @param {boolean} enable
	 */
	static setEnabledJupiterAuroras(scene, enable) {
		if (enable) {
			Features._createJupiterModelComponent(scene, 'auroras', 'auroras');
		}
		else if (enable === false) {
			scene.getEntity('jupiter').removeComponent('auroras');
		}
	}

	/**
	 * Returns true if Jupiter's auroras are enabled.
	 * @param {Pioneer.Scene} scene
	 * @returns {boolean}
	 */
	static isEnabledJupiterAuroras(scene) {
		return scene.getEntity('jupiter').getComponent('auroras') !== null;
	}

	/**
	 * Enables or disables Jupiter's magnetosphere.
	 * @param {Pioneer.Scene} scene
	 * @param {boolean} enable
	 */
	static setEnabledJupiterMagnetosphere(scene, enable) {
		if (enable) {
			Features._createJupiterModelComponent(scene, 'magnetosphere', 'magnetosphere');
		}
		else {
			scene.getEntity('jupiter').removeComponent('magnetosphere');
		}
	}

	/**
	 * Returns true if Jupiter's magnetosphere is enabled.
	 * @param {Pioneer.Scene} scene
	 * @returns {boolean}
	 */
	static isEnabledJupiterMagnetosphere(scene) {
		return scene.getEntity('jupiter').getComponent('magnetosphere') !== null;
	}

	/**
	 * Enables or disables Jupiter's radiation belt.
	 * @param {Pioneer.Scene} scene
	 * @param {boolean} enable
	 */
	static setEnabledJupiterRadiationBelt(scene, enable) {
		if (enable) {
			Features._createJupiterModelComponent(scene, 'radiation_belt_1', 'radbelt1');
			Features._createJupiterModelComponent(scene, 'radiation_belt_2', 'radbelt2');
			Features._createJupiterModelComponent(scene, 'radiation_belt_3', 'radbelt3');
			Features._createJupiterModelComponent(scene, 'radiation_belt_4', 'radbelt4');
		}
		else {
			const entity = scene.getEntity('jupiter');
			entity.removeComponent('radiation_belt_1');
			entity.removeComponent('radiation_belt_2');
			entity.removeComponent('radiation_belt_3');
			entity.removeComponent('radiation_belt_4');
		}
	}

	/**
	 * Returns true if Jupiter's radiation belt is enabled.
	 * @param {Pioneer.Scene} scene
	 * @returns {boolean}
	 */
	static isEnabledJupiterRadiationBelt(scene) {
		const entity = scene.getEntity('jupiter');
		return entity.getComponent('radiation_belt_1') !== null
				&& entity.getComponent('radiation_belt_2') !== null
				&& entity.getComponent('radiation_belt_3') !== null
				&& entity.getComponent('radiation_belt_4') !== null;
	}

	/**
	 * Enables or disables Saturn's magnetosphere.
	 * @param {Pioneer.Scene} scene
	 * @param {boolean} enable
	 */
	static setEnabledSaturnMagnetosphere(scene, enable) {
		if (enable) {
			Entity.createFromOptions('saturn_magnetosphere', {
				radius: 5027500,
				parents: [
					[Number.NEGATIVE_INFINITY, 'saturn']
				],
				model: {
					url: '$STATIC_ASSETS_URL/models/saturn/magnetosphere/saturn_magnetosphere.gltf',
					scale: 1078.27002124,
					rotate: [
						{ x: 90 }
					]
				},
				controllers: [{
					type: 'fixed',
					position: Pioneer.Vector3.Zero
				}, {
					type: 'align',
					primary: {
						type: 'align',
						target: 'saturn',
						axis: Pioneer.Vector3.ZAxis,
						targetAxis: Pioneer.Vector3.ZAxis
					},
					secondary: {
						type: 'point',
						target: 'sun',
						axis: Pioneer.Vector3.XAxis
					}
				}],
				postCreateFunction: (entity) => {
					entity.setCanOcclude(false);
					const thinModel = entity.addComponentByClass(Pioneer.ModelComponent);
					thinModel.setUrl('$STATIC_ASSETS_URL/models/saturn/magnetosphere_thin/saturn_magnetosphere_thin.gltf');
					thinModel.setScale(1078.27002124);
					thinModel.setRotation(new Pioneer.Quaternion(Math.sqrt(0.5), Math.sqrt(0.5), 0, 0));
					thinModel.setPixelRadiusVisibleInterval(new Pioneer.Interval(4000, Number.POSITIVE_INFINITY));
					thinModel.setResourcesLoadedCallback(() => {
						thinModel.getThreeJsObjects()[0].renderOrder = -1;
					});
					const thickModel = entity.getComponentByClass(Pioneer.ModelComponent);
					thickModel.setPixelRadiusVisibleInterval(new Pioneer.Interval(0, 4000));
					thickModel.setResourcesLoadedCallback(() => {
						thickModel.getThreeJsObjects()[0].renderOrder = -1;
					});
				}
			}, scene);
		}
		else {
			scene.removeEntity('saturn_magnetosphere');
		}
	}

	/**
	 * Returns true if Saturn's magnetosphere is enabled.
	 * @param {Pioneer.Scene} scene
	 * @returns {boolean}
	 */
	static isEnabledSaturnMagnetosphere(scene) {
		return scene.getEntity('saturn_magnetosphere') !== null;
	}

	/**
	 * Creates a Jupiter model. Returns the component created.
	 * @param {Pioneer.Scene} scene - the scene where the entity is.
	 * @param {string} name - the folder and component name.
	 * @param {string} fileName - the name of the file inbetween the jupiter_ and .gltf.
	 * @private
	 */
	static _createJupiterModelComponent(scene, name, fileName) {
		const entity = scene.getEntity('jupiter');
		const model = entity.addComponentByClass(Pioneer.ModelComponent, name);
		model.setUrl(`$STATIC_ASSETS_URL/models/${'jupiter'}/${name}/${'jupiter'}_${fileName}.gltf`);
		// Scale it, since the gltf uses a 100 km polar diameter model.
		model.setScale(1337.08);
		// Rotate the model to be in the right orientation.
		const rotation1 = new Pioneer.Quaternion(Math.SQRT1_2, Math.SQRT1_2, 0, 0); // 90 degrees around x-axis
		const rotation2 = new Pioneer.Quaternion(Math.SQRT1_2, 0, 0, Math.SQRT1_2); // 90 degrees around z-axis
		rotation1.mult(rotation2, rotation1);
		model.setRotation(rotation1);
	}
}

/**
 * A entityName to featureType to setEnabled function map.
 * @type {Record<string, Record<string, (scene: Pioneer.Scene, enable: boolean) => void>>}
 * @private
 */
const entityFeatureSetEnabledMap = {
	jupiter: {
		auroras: Features.setEnabledJupiterAuroras,
		magnetosphere: Features.setEnabledJupiterMagnetosphere,
		radiationBelt: Features.setEnabledJupiterRadiationBelt
	},
	saturn: {
		magnetosphere: Features.setEnabledSaturnMagnetosphere
	}
};

/**
 * A entityName to featureType to isEnabled function map.
 * @type {Record<string, Record<string, (scene: Pioneer.Scene) => boolean>>}
 * @private
 */
const entityFeatureIsEnabledMap = {
	jupiter: {
		auroras: Features.isEnabledJupiterAuroras,
		magnetosphere: Features.isEnabledJupiterMagnetosphere,
		radiationBelt: Features.isEnabledJupiterRadiationBelt
	},
	saturn: {
		magnetosphere: Features.isEnabledSaturnMagnetosphere
	}
};
