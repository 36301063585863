/** @module pioneer */

/**
 * This is a class that dynamically makes an object frozen or thawed.
 * When it is frozen, it cannot be modified.
 * To use this, an object must extend this and then for every method that modifies the object, call throwIfFrozen().
 */
export class Freezable {
	/**
	 * Constructor. Starts out thawed.
	 */
	constructor() {
		this._frozen = false;
	}

	/**
	 * Throws a TypeError if it is frozen.
	 */
	throwIfFrozen() {
		if (this._frozen) {
			throw new TypeError('The object is frozen.');
		}
	}

	/**
	 * Returns true if it is frozen.
	 * @returns {boolean}
	 */
	isFrozen() {
		return this._frozen;
	}

	/**
	 * Freezes the object.
	 */
	freeze() {
		this._frozen = true;
	}

	/**
	 * Thaws (unfreezes) the object.
	 */
	thaw() {
		this._frozen = false;
	}
}
