/** @module pioneer */
import {
	Scene
} from '../internal';

/**
 * @typedef RefAble
 * @property {function(): boolean} isEnabled
 * @property {function(): boolean} isDestroyed
 */

/**
 * A reference to an object.
 * @template {RefAble} Type
 */
export class BaseRef {
	/**
	 * Constructor.
	 * @param {Scene} scene
	 */
	constructor(scene) {
		/**
		 * The scene where this reference will search.
		 * @type {Scene}
		 * @protected
		 */
		this._scene = scene;

		/**
		 * The entity.
		 * @type {Type | null}
		 * @protected
		 */
		this._ref = null;

		/**
		 * The callback to be called when the reference changes.
		 * @type {((oldRef: Type | null, newRef: Type | null) => any) | null}
		 * @private
		 */
		this._refChangedCallback = null;
	}

	/**
	 * Gets the reference.
	 * @returns {Type | null}
	 */
	get() {
		this.update();
		return this._ref;
	}

	/**
	 * Sets a callback to be called when the reference changes.
	 * @param {((oldRef: Type | null, newRef: Type | null) => any) | null} refChangedCallback
	 */
	setRefChangedCallback(refChangedCallback) {
		this._refChangedCallback = refChangedCallback;
	}

	/**
	 * Updates the reference.
	 * @abstract
	 */
	update() {
	}

	/**
	 * Updates the ref, calling the callback if necessary.
	 * @param {Type | null} newRef
	 * @protected
	 */
	_setRef(newRef) {
		// Make sure the new ref is enabled, otherwise discard it.
		if (newRef !== null && !newRef.isEnabled()) {
			newRef = null;
		}
		// If they are different,
		if (this._ref !== newRef) {
			const oldRef = this._ref;
			// Set the ref.
			this._ref = newRef;
			// Call the callback.
			if (this._refChangedCallback !== null) {
				this._refChangedCallback(oldRef, this._ref);
			}
		}
	}
}
