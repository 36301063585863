import React from 'react';
import { compareObjs } from '../helpers/tools';
import ArrowRight from '../../www/assets/images/arrow-nav-chevron.svg';
import '../../www/assets/css/select.css';

class Select extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			element: null,
			options: [],
			selected: null
		};

		this.onChange = this.onChange.bind(this);
	}

	static getDerivedStateFromProps(nextProps, state) {
		const { options, defaultValue } = nextProps;

		// Update states from props
		const newState = Object.entries(nextProps).reduce((obj, [key, value]) => {
			if (key in state && state[key] !== nextProps[key]) {
				if (key !== 'options') {
					obj[key] = value;
				}
			}
			return obj;
		}, {});

		// Check if options have changed
		if (
			options && (
				(options.length !== state.options.length)
				|| options.some((element, index) => !compareObjs(element, state.options[index]))
			)
		) {
			newState.options = options;
		}

		// Set selected value
		// 1. Initially
		// 2. On changing options
		if (
			(state.selected === null && defaultValue !== undefined)
			|| newState.options
		) {
			newState.selected = defaultValue;
		}

		return newState;
	}

	/**
	 * Handle selection. Set selected value and bubble up as needed.
	 * @param {Event} event - Fired event from an element
	 */
	onChange(index, text) {
		this.setState({ selected: index }, () => {
			const { onChange } = this.props;
			if (onChange) {
				onChange(this.state.selected, text);
			}
		});
	}


	/**
	 * Render element that can toggle show/hide
	 * @returns {ReactElement}
	 */
	_renderToggle() {
		const { id, className, subClassName, title, icon, arrowAfter, isOpen } = this.props;
		const { element, options, selected } = this.state;
		const addClass = className ? ' ' + className : '';
		const addSubClass = subClassName ? ' ' + subClassName : '';
		const content = element || (
			<ul className='details'>
				{options.map((option, index) => (
					<li
						key={index}
						value={option.value}
						onClick={() => {
							this.onChange(index, option.text);
						}}
						className={selected === option.value ? 'selected' : ''}
					>
						<div className='option-content'>
							{option.text}
							{option.category === 'modal' || option.category === 'static' ? <ArrowRight className='li-item-arrow' /> : null}
						</div>

					</li>
				))}
			</ul>
		);

		const iconElement = icon
			? <span className='icon'>{icon}</span>
			: null;

		return (
			<details id={id} className={'select' + addClass} open={isOpen || false}>
				<summary className={'no-select flexbox-container' + addSubClass}>
					{iconElement}
					<div className='text'>{title}</div>
					{
						arrowAfter
						&& <div className='arrow-down' />
					}
				</summary>
				{content}
			</details>
		);
	}

	/**
	 * Render default element
	 * @returns {ReactElement}
	 */
	_renderDefault() {
		const { id, className } = this.props;
		const { options, selected } = this.state;
		const addClass = className ? ' ' + className : '';

		return (
			<select id={id} className={'no-select select' + addClass} value={selected} onChange={this.onChange}>
				{options.map((option, index) => (
					<option
						key={index}
						value={option.value}
					>
						{option.text}
					</option>
				))}
			</select>
		);
	}

	render() {
		const { mode } = this.props;

		switch (mode) {
			case 'toggle':
				return this._renderToggle();
			default:
				return this._renderDefault();
		}
	}
}

export default Select;
