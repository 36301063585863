/* eslint-disable camelcase */
import * as Pioneer from 'pioneer';
import { Entity, Placemarks, SceneHelpers } from 'pioneer-scripts';
import PLACEMARK_DATA from '../data/placemark_ar';
import DATA_READER_DATA from '../data/value_definitions_ar'; // importing the Arabic units
import { SPACECRAFT_ATMOSPHERE, SPACECRAFT_SEA, SPACECRAFT_LAND, TRAIL_COLORS } from '../data/spacecraft_data_ar';
import { eventsStore, poiStore, gmapStore, layersStore, lightStore, datasetStore, spacecraftStore, appStore, uiStore, cameraStore, spoutStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';
import { filterEvents, getLimitedLatestEvents } from '../helpers/processEvents';
import Loading, { hideLoading } from '../components/loading';
import { filterPoI,getLimitedPois } from '../helpers/processPoI';
import { filterGmap, getLimitedGmaps } from '../helpers/processGmap';
import ar from '../languages/ar';
import ENTITY_INFO from '../data/entity_info.json';


class SceneManager {
	/**
	 * Constructor
	 * @param {object} pioneer Pioneer engine
	 * @private
	 */
	constructor(pioneer) {
		// Set pioneer and scene.
		this._pioneer = pioneer;
		this._scene = pioneer.get('main');

		// TODO: router ref.
		// this._router = router;

		this._operatingSC = null;
		this._futureSC = null;

		// Options are fixed variables that should not be changed (frozen)
		this._options = Object.freeze({
			eventPlacemarks: {
				limit: Infinity,
				idPrefix: 'event_'
			},
			poiPlacemarks: {
				limit: Infinity,
				idPrefix: 'poi_'
			},
			gmapPlacemarks: {
				limit: Infinity,
				idPrefix: 'gmap_'
			}
		});

		this._displayingEvents = [];
		this._displayingPois = [];
		this._displayingGmaps= [];
		this._vitalsign = '';
		this._groupId = 0;
		this._loadingItems = [];
		this._dataValueObject = null;
		this.groundTracksEntity = null;
		this._trailWidth = 2;

		this._enabledSC = null;

		// Bind handlers.
		this._onEventsStateUpdate = this._onEventsStateUpdate.bind(this);
		this._onPoIStateUpdate = this._onPoIStateUpdate.bind(this);
		this._onGmapStateUpdate = this._onGmapStateUpdate.bind(this);
		this._onLightStateUpdate = this._onLightStateUpdate.bind(this);
		this._onCityNamesStateUpdate = this._onCityNamesStateUpdate.bind(this);
		this._onGroundTrackStateUpdate = this._onGroundTrackStateUpdate.bind(this);
		this._updateGroundTracksTiming = this._updateGroundTracksTiming.bind(this);

		this.setTrailWidths = this.setTrailWidths.bind(this);
		this._createSpacecraftEntities = this._createSpacecraftEntities.bind(this);

	}

	/**
	 * Initialize the scene
	 * The goal is to make the earth interactive as soon as possible.
	 */
	init() {
		// Create celestials
		Entity.create('observable_universe', this._scene, { skybox: false });
		Entity.create('milky_way', this._scene, { starfield: true });

		// Subscribe to global state changes.
		this._subscribeToStates();

		// First we need a minimal sun and earth.

		// Create sun but don't set textures or call postCreateFunction yet.
		const originalSunOptions = Entity._entities.get('sun');
		const postSunCreateFunction = originalSunOptions.postCreateFunction;
		const sunOptions = JSON.parse(JSON.stringify(originalSunOptions));
		sunOptions.postCreateFunction = null;
    sunOptions.label = ar['sun']
		const sun = Entity.createFromOptions('sun', sunOptions, this._scene);
		const sunSpheroid = sun.get('spheroidLOD');
		const sunColorTextureUrl = sunSpheroid.getTextureUrl('color');
		sunSpheroid.setTexture('color', sunSpheroid.getTextureUrl('color'), [512]);
    let that = this;
    Object.keys(ENTITY_INFO).map((v,i) => {
      const p = ENTITY_INFO[v]
      if(p.stellar && p.id != "sun" && p.id != "pluto"){
        that.createPlanetEntity(p)
      }
    })
    
    //For Pluto all of these need to get made, afaict pluto relies on the barycenter, and the barycenter has references to charon so those need to get made also... 
		// const plutoBary = Entity.create('134340_pluto_barycenter', this._scene);
		const charon = Entity.create('charon', this._scene);

    this.createEntityWithLabel('134340_pluto')
    this.createEntityWithLabel('134340_pluto_barycenter')
		const earth = Entity.create('earth', this._scene);
		earth.get('atmosphere').setEnabled(false);
		// Create the Pick controller for Earth
		earth.addController('pick', 'dataReader');
		this.createEntityWithLabel('earth')
		// Create the patch component and make sure it's disabled.
		const patchComponent = earth.addComponent('spheroidLOD', 'patch');
		const moon = Entity.create('moon', this._scene);
		patchComponent.setForceLoaded(true);
		patchComponent.setEnabled(false);
		
		const moonSpheroid = moon.get('spheroidLOD');
		//moonSpheroid.setTexture('color', moonSpheroid.getTextureUrl('color'), [4, 16]);
		// moonSpheroid.setTexture('normal', moonSpheroid.getTextureUrl('normal'), [4]);

		// Force load the earth spheroid.
		const earthSpheroidComponent = earth.getComponentByType('spheroidLOD');
		earthSpheroidComponent.setForceLoaded(true);

		// Create the patch spheroid and set it as the reference for the patch component.
		const patchSpheroid = earth.addComponent('spheroid', 'patchSpheroid');
		const earthSpheroid = earth.get('spheroid', 0);
		patchSpheroid.setEquatorialRadius(earthSpheroid._equatorialRadius + 2);
		patchSpheroid.setPolarRadius(earthSpheroid._polarRadius + 2);
		patchComponent.setSpheroidReference('patchSpheroid');

	Entity.register({
		sc_saudisat_4: {
			groups: ['earth', 'spacecraft'],
			clickable: true,
			occlusionRadius: 0.00198,
			extentsRadius: 0.0015,
			label: ar['sc_saudisat_4'],
			parents: [[23030.92908476, 'earth']],
			trail: {
				length: 5653.2
			},
			model: {
				url: 'assets/ce2s2_models/SudiSatup4_12.glb',
				shadowEntities: ['earth', 'moon'],
	  scale: [0.0002, 0.0002, 0.0002]
			},
			controllers: [
				{
					type: 'orbitalElements',
					name: 'sc_saudisat_4',
		eccentricity: 0.0048577,
		semiMajorAxis : 7924.41,
		meanAngularMotion: 14.7630988 * 2 * Math.PI / 3600 / 24,
		meanAnomalyAtEpoch: 255.4208 * (Math.PI / 180) ,
		inclination: 97.6455 * (Math.PI / 180),
		argumentOfPeriapsis: 105.2381 * (Math.PI / 180),
		longitudeOfAscendingNode: 178.1483 * (Math.PI / 180),
		coverage: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY]
				},
				{
					type: 'align',
					primary: {
						type: 'point',
						target: 'earth',
						axis: Pioneer.Vector3.YAxis
					}
				}
			]

		}
	});


	// Once we've loaded the first earth textures, we can then initialize the remaining scene.
	const initRemainingScene = () => {
		// Create city placemarks.
		this.createCityPlacemarks();

		// Create celestials
		Entity.create('observable_universe', this._scene);
		Entity.create('milky_way', this._scene);

		// Create moon
		const moon = Entity.create('moon', this._scene);
		// const moonSpheroid = moon.get('spheroidLOD');
		// moonSpheroid.setTexture('color', moonSpheroid.getTextureUrl('color'), [4]);
		// moonSpheroid.unsetTexture('normal'); // Normals not necessary from this distance.

		// Trigger sun texture and run postCreateFunction.
		sunSpheroid.setTexture('color', sunColorTextureUrl, [4]);
		postSunCreateFunction(sun);

				// Setup compare objects
				const scientist = Entity.create('scientist', this._scene);
				scientist.setEnabled(false);
				const bus = Entity.create('school_bus', this._scene);
				bus.setEnabled(false);

			Entity.register(
				{
					astronaut: {
						groups: ['comparison'],
						radius: 0.000835,
						label: 'Saudi Astronaut',
						parents: [],
						model: {
							url: 'assets/ce2s2_models/astronaut/ast_without_face.glb',
							rotate: [{ x: 90 }],
							
						},
						controllers: [
							{
								type: 'fixed',
								position: Pioneer.Vector3.Zero,
								orientation: Pioneer.Quaternion.Identity
							}
						]
					}
				}
			);
			const astronaut = Entity.create('astronaut', this._scene);
			astronaut.setEnabled(false);

			Entity.register(
				{
					camel: {
						groups: ['comparison'],
						radius: 0.000835,
						label: 'Arabic Camel',
						parents: [],
						model: {
							url: 'assets/ce2s2_models/camel_May_12.glb',
							rotate: [{ x: 90},{z: 90}],
							useCompressedTextures: false
						},
						controllers: [
							{
								type: 'fixed',
								position: Pioneer.Vector3.Zero,
								orientation: Pioneer.Quaternion.Identity
							}
						]
					}
				}
			);
			const Camel = Entity.create('camel', this._scene);
			Camel.setEnabled(false);

			// Create spacecraft entities.
			const createSpacecraft = spacecraftList => {
				this._createSpacecraftEntities(spacecraftList);
				const { getManager } = globalRefs;
				getManager('label').buildLabels();

				// Set spacecraftLoaded to true.
				spacecraftStore.setGlobalState({ spacecraftLoaded: true });
			};
			// Check if we have a spacecraft list yet (it's almost certain to be there)
			const { spacecraftList } = spacecraftStore.stateSnapshot;
			// If so, create them.
			if (spacecraftList) {
				createSpacecraft(spacecraftList);
			} else {
				// If not, subscribe to spacecraftList state, so they'll be created when it's available.
				const unsubscribeToSpacecraftList = spacecraftStore.subscribeTo('spacecraftList', spacecraftList => {
					createSpacecraft(spacecraftList);
					unsubscribeToSpacecraftList();
				});
			}
		};

		/**
		 * On first load, we need to wait until the initial textures for the earth have loaded.
		 * Once the promise has resolved, we can initRemainingScene and unsubscribe from the isTextureLoaded.
		 */
		const unsubscribeToSplashScreen = uiStore.subscribeTo('showSplashScreen', showSplashScreen => {
			if (!showSplashScreen) {
				// Init the rest of the scene.
				initRemainingScene();
				// Unsubscribe from isLoaded so this is not called again.
				unsubscribeToSplashScreen();
			}
		});
	}


	setTrailWidths(width = this._trailWidth) {
		this._trailWidth = width;

		// Return if we dont yet have spacecraft entities (ie. spacecraft list not yet loaded).
		if (!this._operatingSC) {
			return;
		}

		this._operatingSC.forEach(sc => {
			const trailComponent = this._scene.get(sc, 'trail');
			trailComponent?.setWidths(0, this._trailWidth);
		});
	}


	/**
	 * Attach callbacks to global states.
	 *
	 * Note: as the scene manager is never unmounted, we do not need to unsubscribe.
	 */
	_subscribeToStates() {
		// Subscribe to enabledSC state.
		spacecraftStore.subscribeTo('enabledSC', this.setEnabledSC.bind(this));

		// Subscribe to events state.
		eventsStore.subscribeAll(this._onEventsStateUpdate);
		poiStore.subscribeAll(this._onPoIStateUpdate);
		gmapStore.subscribeAll(this._onGmapStateUpdate);
		// Subscribe to light state.
		lightStore.subscribeTo('fill', this._onLightStateUpdate);

		// Subscribe to city names and ground tracks state.
		layersStore.subscribeTo('cityNames', this._onCityNamesStateUpdate);
		layersStore.subscribeTo('groundTracks', this._onGroundTrackStateUpdate);
		layersStore.subscribeTo('groundTrackStartTime', this._updateGroundTracksTiming);
		// Subscribe to texture loading state (show texture loading is done manually)
		datasetStore.subscribeTo('isTextureLoaded', isTextureLoaded => isTextureLoaded && hideLoading('texture'));

		// Subscribe to trailWidth query.
		appStore.subscribeTo('queries', queries => {
			queries?.trailWidth && this.setTrailWidths(queries.trailWidth);
		});
	}

  createPlanetEntity(planet){
		const p = this.createEntityWithLabel(planet.id);
		const planetSpheroid = p.get('spheroidLOD');
		const texture_arr =["neptune", "uranus"].includes(planet.id)?256 :512  ;
		if (planet.id != "venus")
		{planetSpheroid.setTexture('color', planetSpheroid.getTextureUrl('color'), [texture_arr]);
		}
	    else
		{
		planetSpheroid.setTexture('color', '$STATIC_ASSETS_URL/maps/venus/surface_$SIZE_$FACE.png', [texture_arr]);
		 }
 }
	/**
	 * Create spacecraft entities.
	 */
	_createSpacecraftEntities(spacecraftList) {
		// Build operating and future spacecraft arrays.
		this._operatingSC = [];
		this._futureSC = [];

		spacecraftList.forEach(({ constellationIds, categories }) => {
			if (categories?.includes('operating')) {
				this._operatingSC.push(...constellationIds);
			} else if (categories?.includes('planned')) {
				this._futureSC.push(...constellationIds);
			}
		});



		// Make sure operating and future SCs are unique.
		this._operatingSC = [...new Set(this._operatingSC)];
		this._futureSC = [...new Set(this._futureSC)];


		// Create the entities.
    let that = this;
		this._operatingSC.forEach(sc => that.createEntityWithLabel(sc));
		this._futureSC.forEach(sc =>  that.createEntityWithLabel(sc));

		// Set the trail widths.
		this.setTrailWidths();

		// Set the trail colors.
		// Atmosphere trails.
		const atmosphereTrailColor = new Pioneer.Color(...TRAIL_COLORS.ATMOSPHERE);
		SPACECRAFT_ATMOSPHERE.forEach(spacecraftId => {
			const trailComponent = this._scene.get(spacecraftId, 'trail');
			trailComponent?.setColor(atmosphereTrailColor);
		});

		// Land trails.
		const landTrailColor = new Pioneer.Color(...TRAIL_COLORS.LAND);
		SPACECRAFT_LAND.forEach(spacecraftId => {
			const trailComponent = this._scene.get(spacecraftId, 'trail');
			trailComponent?.setColor(landTrailColor);
		});

		// Sea trails.
		const seaTrailColor = new Pioneer.Color(...TRAIL_COLORS.SEA);
		SPACECRAFT_SEA.forEach(spacecraftId => {
			const trailComponent = this._scene.get(spacecraftId, 'trail');
			trailComponent?.setColor(seaTrailColor);
		});

		// #1595 - These sc_iss lines can be removed at a later date when camera orientations for spacecraft children is implemented)
		this._scene.get('sc_iss_oco_3', 'fixed').setOrientation(new Pioneer.Quaternion(Math.sqrt(0.5), -Math.sqrt(0.5), 0, 0));
		this._scene.get('sc_iss_oco_3', 'div').setFadeWhenCloseToCamera(false);
		this._scene.get('sc_iss_oco_3').setCanOcclude(false);

		this._scene.get('sc_iss_ecostress', 'fixed').setOrientation(new Pioneer.Quaternion(Math.sqrt(0.5), -Math.sqrt(0.5), 0, 0));
		this._scene.get('sc_iss_ecostress', 'div').setFadeWhenCloseToCamera(false);
		this._scene.get('sc_iss_ecostress').setCanOcclude(false);

		this._scene.get('sc_iss_emit', 'div').setFadeWhenCloseToCamera(false);
		this._scene.get('sc_iss_emit').setCanOcclude(false);

		// Check operating spacecraft to make sure they're in coverage.
		this._operatingSC = this._operatingSC.filter(spacecraftId => {
			const spacecraftEntity = this._scene.get(spacecraftId);
			const dynamoController = spacecraftEntity.getControllerByType('dynamo');
			// If there's no dynamo controller, it's probably an instrument.
			if (!dynamoController) {
				return true;
			}

			const isInCoverage = dynamoController.getCoverage().contains(this._pioneer.getTime());
			// If the spacecraft is not in coverage, it shouldn't be in this list - destroy it.
			if (!isInCoverage) {
				spacecraftEntity.__destroy();
			}

			return isInCoverage;
		});

		// Manual overrides for future spacecraft
		this._futureSC.forEach(sc => {
			const entity = this._scene.get(sc);
			entity.clearParentingTableEntries();
			entity.addParentingTableEntry(Number.NEGATIVE_INFINITY, 'earth');
			const oeController = entity.getControllerByClass(Pioneer.OrbitalElementsController);

			if (oeController !== null) {
				// Assumes future s/c have orbital element controllers
				oeController.setCoverage(new Pioneer.Interval(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY));
			} else {
				const dynamoController = entity.getControllerByClass(Pioneer.DynamoController) ?? undefined;
				const oeController = entity.addControllerByClass(Pioneer.OrbitalElementsController, undefined, dynamoController);
				const oe = new Pioneer.OrbitalElements();
				oe.epoch = this._scene.getEngine().getTime();
				// Use NISAR data for any s/c that doesn't have oeController
				oe.eccentricity = 0;
				oe.semiMajorAxis = 7118;
				oe.meanAngularMotion = 0.0011382582;
				oe.meanAnomalyAtEpoch = 0;
				oe.setOrbitOrientationFromElements(Math.PI / 180 * ((Math.random() * 180) - 90), 0, 0);
				oeController.addOrbitalElements(oe.epoch, oe);
				oeController.setCoverage(new Pioneer.Interval(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY));
			}
		});


		if (this._enabledSC !== null) {
			this.setEnabledSC(this._enabledSC);
		}
	}

  createEntityWithLabel(sc){
    let options = Entity._entities.get(sc);
    if(options){
      const ar_label = ar[sc]
      if(ar_label){
        options['label'] = ar_label
      } else {
        console.error("FIX: No translation in ar.js for ", sc)
      }
      let _e= Entity.createFromOptions(sc,options,this._scene)
	   if (sc=="134340_pluto_barycenter")
	   {let entity = this._scene.get(sc);
	   const entityEl = entity.get('div');
	   const div = entityEl.getDiv();
	
	   div.classList.remove('pioneer-label-div');}
	   return _e;
    } else {
      console.error("Don't know how to create: ", sc)
      return null
    }
  }

	/**
	 * Handles a change of the enabledSC global state.
	 * @param {Array<string>|string|Boolean} ids
	 */
	setEnabledSC(ids) {
		// Check whether we have booleans.
		const showAllOperating = ids === 'operating';
		const showAll = ids === true;

		/**
		 * It's possible we dont yet have the spacecraft entities built.
		 * In that case, We need to set a local variable to be used upon their creation.
		 */
		if (!this._operatingSC) {
			this._enabledSC = ids;
			return;
		}

		this._operatingSC?.forEach(spacecraftId => {
			const spacecraftEntity = this._scene.get(spacecraftId);

			if (spacecraftEntity) {
				spacecraftEntity.setEnabled((Array.isArray(ids) && ids.includes(spacecraftId)) || showAllOperating || showAll);
			}
		});

		this._futureSC?.forEach(spacecraftId => {
			const spacecraftEntity = this._scene.get(spacecraftId);

			if (spacecraftEntity) {
				// Unless ids is an array, we want to hide all future spacecrafts.
				spacecraftEntity.setEnabled((Array.isArray(ids) && ids.includes(spacecraftId)) || showAll);
			}
		});
	}

	/**
	 * Returns whether the spacecraft is a future spacecraft
	 * @param {string} spacecraftId
	 * @returns {boolean} Whether the spacecraft is a future spacecraft
	 */
	isFutureSC(spacecraftId) {
		return this._futureSC?.includes(spacecraftId);
	}

	/**
	 * Handles a change of the light global state
	 * @param {Boolean} newState
	 */
	_onLightStateUpdate(newState) {
		const { spout, spoutGlobe } = spoutStore.stateSnapshot;

		if (spout === true && spoutGlobe === true) {
			const colorValue = newState ? 1 : 0.05;
			const color = new Pioneer.Color(colorValue, colorValue, colorValue);
			this._scene.setAmbientLightColor(color);
		} else {
			const color = new Pioneer.Color(newState ? 1 : 0, newState ? 1 : 0, newState ? 1 : 0);
			for (let i = 0; i < this._pioneer.getNumViewports(); i++) {
				const lightSource = /** @type {Pioneer.LightSourceComponent} */(this._pioneer.getViewport(i).getCamera().getEntity().getComponentByType('lightSource'));
				if (lightSource !== null) {
					lightSource.setColor(color);
				}
			}
		}
	}

	/**
	 * Handles a change of the city names global state
	 * @param {Boolean} newState
	 */
	_onCityNamesStateUpdate(newState) {
		const placemarks = this._scene.get('placemarks');
		if (!placemarks || !placemarks.getNumChildren()) { return; }

		for (let i = 0; i < placemarks.getNumChildren(); i++) {
			placemarks.getChild(i).setEnabled(newState);
		}
	}

	/**
	 * Handles a change of the ground tracks global state
	 * @param {Boolean} newState
	 */
	_onGroundTrackStateUpdate(newState) {
		const { getManager } = globalRefs;
		const { spacecraftId: currentSpacecraftId } = spacecraftStore.stateSnapshot;
		const { currentDataset } = datasetStore.stateSnapshot;
		const _8_hours = 28800;
		const _24_hours = 86400;
		const originalStartTime = this._pioneer.getTime() - _8_hours;

		if (!newState) {
			this.groundTracksEntity.getControllerByType(Pioneer.CoverageController)?.setEnabled(false);
		}

		// Create child entity for spacecraft to act as ground tracks
		let hasGroundTracksEntity = false;
		// If on a vital sign, use the spacecraft from mission ids, of if on spacecraft view, use spacecraftId
		const spacecraftId = currentSpacecraftId || currentDataset?.missionIds?.[0];
		const spacecraft = this._scene.get(spacecraftId);

		if (newState && spacecraft) {
			// Set ground tracks entity to be the appropriate child of the spacecraft
			for (let i = 0; i < spacecraft.getNumChildren(); i++) {
				if (spacecraft.getChild(i).getName() === `groundTracksEntity_${spacecraftId}`) {
					hasGroundTracksEntity = true;
					this.groundTracksEntity = spacecraft.getChild(i);
				}
			}

			if (!hasGroundTracksEntity) {
				this.groundTracksEntity = this._scene.addEntity(`groundTracksEntity_${spacecraftId}`);
			}

			// Configure ground tracks entity
			this.groundTracksEntity.setParent(spacecraft);
			const fixedController = this.groundTracksEntity.addController('fixed');
			fixedController.setPosition(Pioneer.Vector3.Zero);
			fixedController.setOrientation(Pioneer.Quaternion.Identity);

			// Clamp ground tracks entity to the ground to clamp trail to ground
			const groundClampController = this.groundTracksEntity.addController('groundClamp');
			groundClampController.setDistanceFromGround(10);

			const groundTracks = this.groundTracksEntity.addComponent('trail');
			groundTracks.setColor(new Pioneer.Color(1, 1, 1, 1));
			groundTracks.setWidths(1, 3);

			// Fade trail as it reaches its endpoint
			groundTracks.setAlphaFade(0);
			groundTracks.setRelativeToEntity('earth');
			groundTracks.setRelativeToEntityOrientation(true);
			groundTracks.setIgnoreDistance(true);

			groundTracks.setRelativeStartTime(false);
			groundTracks.setStartTime(originalStartTime);

			const coverageController = this.groundTracksEntity.addControllerByClass(Pioneer.CoverageController);
			coverageController.setUpdateFunction(entity => {
				// Check for tracks in case something deleted them.
				const tracks = entity.getComponentByClass(Pioneer.TrailComponent);
				if (tracks === null) {
					return;
				}

				// Ground tracks are at 24 hours - show track lines all around the globe
				if (this._pioneer.getTime() - originalStartTime >= _24_hours) {
					groundTracks.setStartTime(this._pioneer.getTime() - _24_hours);
				} else {
					// Set ground tracks back to 8 hours behind
					if (getManager('time').getTimeRate() < 0) {
						groundTracks.setStartTime(this._pioneer.getTime() - _8_hours);
					} else {
						groundTracks.setStartTime(originalStartTime);
					}
				}
			});
		}

		this.groundTracksEntity.setEnabled(newState);
	}

	/**
	 * Ensure the ground track start time is accurate to 'now'
	 * @param {number} time
	 */
	_updateGroundTracksTiming(time) {
		const { spacecraftId: currentSpacecraftId } = spacecraftStore.stateSnapshot;
		const groundTracks = this._scene.get(`groundTracksEntity_${currentSpacecraftId}`, 'trail');
		const etTime = Pioneer.TimeUtils.unixToEt(time.valueOf() * 0.001);
		const _8_hours = 28800;

		groundTracks && groundTracks.setStartTime(etTime - _8_hours);
	}

	/**
	 * Handles a change of events global state.
	 *
	 * Events shown on the globe must always be filtered (otherwise there's too many) to prevent UI slowdown.
	 * @param {object} newState
	 */
	_onEventsStateUpdate(newState) {
		const { categoryIndex, eventsVisibleOnGlobe, showLatest, events, yearIndex, eventYears, currentEvent, mediaIndex } = newState;
		// Always turn off all events first.
		this.displayEventPlacemarks(false);

		if (eventsVisibleOnGlobe && eventYears) {
			// Filter events.
			const eventsYear = eventYears?.[yearIndex] || eventYears?.[0];
			const allEventsFromYear = events?.[eventsYear];

			if (Array.isArray(allEventsFromYear)) {
				const filteredEvents = filterEvents(allEventsFromYear, categoryIndex, mediaIndex);

				 this.displayEventPlacemarks(true, filteredEvents);
			}
		} else if (showLatest) {
			// Make sure we filter out the current event.
			const latestEvents = getLimitedLatestEvents().filter(event => event.id !== currentEvent?.id);
			latestEvents &&  this.displayEventPlacemarks(true, latestEvents);
		}
	}

	/**
	 * Handles a change of poi global state.
	 *
	 * PoI shown on the globe must always be filtered (otherwise there's too many) to prevent UI slowdown.
	 * @param {object} newState
	 */
	 _onPoIStateUpdate(newState) {
		const { yearIndex, categoryIndex, showOnGlobePoI, showLatestPoI, pois, poiYears } = newState;
		// console.log("_onPoIStateUpdate", showOnGlobePoI, showLatestPoI)
		// Always turn off all poi first.
		this.displayCustomPlacemarks(false, true);
		if (showOnGlobePoI) {
			const { idPrefix } = this._options.poiPlacemarks;
			const filteredPoI = filterPoI(pois, poiYears, yearIndex, categoryIndex);
			const filteredPoIIds = filteredPoI.map(poi => `poi_${poi.id}`);

			let showPoITitle = true;
			if (this._router?._history?.location?.pathname) {
				const { pathname } = this._router._history.location;
				showPoITitle = pathname.indexOf('poi') >= 0 || pathname.indexOf('spacecraft') >= 0;
			}

			this.displayCustomPlacemarks(!showPoITitle, true, filteredPoIIds);
		} else if (showLatestPoI) {
			const poiList = getLimitedPois();
			this.displayCustomPlacemarks(true, true, poiList);
		}
	}

	/**
	 * Handles a change of gmap global state.
	 *
	 * Gmap shown on the globe must always be filtered (otherwise there's too many) to prevent UI slowdown.
	 * @param {object} newState
	 */
	 _onGmapStateUpdate(newState) {
		const { yearIndex, categoryIndex, showOnGlobeGmap, showLatestGmap, gmaps, gmap_cats, gmapYears } = newState;
		// Always turn off all gmap first.
		this.displayCustomPlacemarks(false);
		if (showOnGlobeGmap) {
			const { idPrefix } = this._options.gmapPlacemarks;
			const filteredGmap = filterGmap(gmaps, gmapYears, yearIndex, categoryIndex);
			const filteredGmapIds = filteredGmap.map(gmap => `gmap_${gmap.id}`);

			let showGmapTitle = true;
			if (this._router?._history?.location?.pathname) {
				const { pathname } = this._router._history.location;
				showGmapTitle = pathname.indexOf('gmap') >= 0 || pathname.indexOf('spacecraft') >= 0;
			}

			this.displayCustomPlacemarks(!showGmapTitle, false, filteredGmapIds);
		} else if (showLatestGmap) {
			//This doesn't currently do much since all the GMAPs have the same center placemarks are just overlapping
			const gmapList = getLimitedGmaps();
			this.displayCustomPlacemarks(true, false, gmapList);
		}
	}

	createCityPlacemarks() {
		const { cityNames } = layersStore.stateSnapshot;
		const earth = this._scene.get('earth');
		const placemarks = this._scene.get('placemarks');
		if (!placemarks) {
			const group = this._scene.addEntity('placemarks');
			group.setParent(earth);
			group.setCanOcclude(false);
			group.setOcclusionRadius(earth.getOcclusionRadius());
			const fixed = group.addController('fixed');
			fixed.setPosition(Pioneer.Vector3.Zero);
			fixed.setOrientation(Pioneer.Quaternion.Identity);
			group.addController('rotateByEntityOrientation');

			for (const id in PLACEMARK_DATA) {
				const data = PLACEMARK_DATA[id];
				const altitude = 10;
				const entity = Placemarks.addPlacemark(id, id, earth.getComponentByClass(Pioneer.SpheroidComponent), data.latLng[0], data.latLng[1], altitude);
			entity.setEnabled(cityNames);

				entity.setParent(group); // Put placemark into group
				const div = entity.get('div').getDiv();
			entity.get('div').setAlignment(new Pioneer.Vector2(0.5, 0.5));
				div.id = id;
				div.classList.add('placemark');
			div.classList.remove('pioneer-label-div');
				div.innerHTML = '<div class="pin"><img class="no-drag" src="assets/images/map_marker.png"></div>' + '<div class="content">' + data.text + '</div>';
			}
		}
	}

	/**
	 * Create an event placemark is does not exist
	 * @param {object} event
	 * @returns {object} event
	 */
	singleEventPlacemark(event) {
		const { id, boundaries, title, urlParam } = event || {};
		const { north, south, east, west } = boundaries || {};
		// console.log("singleEventPlacemark")
		if (!id || !boundaries) {
			return;
		}

		const earth = this._scene.get('earth');
		let group = this._scene.getEntity('events');

		if (!group) {
			group = this._scene.addEntity('events');
			group.setParent(earth);
			group.setCanOcclude(false);
			group.setExtentsRadius(earth.getExtentsRadius());
			const fixed = group.addController('fixed');
			fixed.setPosition(Pioneer.Vector3.Zero);
			fixed.setOrientation(Pioneer.Quaternion.Identity);
			group.addController('rotateByEntityOrientation');
		}

		let entity = this._scene.get(id);
		if (!entity) {
			const lat = (south + north) / 2;
			const lon = (west + east) / 2;
			entity = Placemarks.addPlacemark(id, '', earth.getComponentByClass(Pioneer.SpheroidComponent), lat, lon, 0);
			const entityEl = entity.get('div');

			// Put placemark into group.
			entity.setParent(group);

			const div = entityEl.getDiv();
			entityEl.setAlignment(new Pioneer.Vector2(0.5, 0.5));
			div.id = `eventPlacemark_${id}`;
			div.classList.add('pin', 'event-pin');
			div.classList.remove('pioneer-label-div');
			div.title = title;
			div.classList.add('placemark');
			div.innerHTML = '<div class="pin"><img class="no-drag" src="assets/images/map_marker_events.svg"></div>';
			div.addEventListener('click', () => {
				// Navigate to the event.
				const { getManager } = globalRefs;
				getManager('route').navigateToEvent(urlParam);
			});
		}

		return entity;
	}

	/**
	 * Show / Hide event placemarks.
	 *
	 * @param {boolean} value
	 * @param {Array} eventList - optional list (otherwise all event placemarks)
	 */
	displayEventPlacemarks(value, eventList = this._displayingEvents) {
		const { spout } = spoutStore.stateSnapshot;

		if (!eventList) { return; }

		const cameraEntity = this._scene.get('camera');
		const cameraParent = cameraEntity?.getParent()?.getName();

		// If showing placemarks, store the this._displayingEvents = [] so we can easily hide them later.
		if (value) {
			this._displayingEvents = [...eventList];
		}

		const handleEntity = (entity, value) => {
			if (!entity) {
				return;
			}

			// Set entity enabled.
			entity.setEnabled(value);

			// Set placemark enabled.
			const placemarkDiv = entity.get('div');
			placemarkDiv.setEnabled(value);

			// If Spout is on, update visibility of spout labels as well as regular placemarks
			if (spout) {
				const entityLabel = entity.get('label');
				entityLabel?.setEnabled(value);
			}
		};

		eventList.forEach(async event => {
			const entity = this.singleEventPlacemark(event);

			if (!entity) {
				return;
			}

			// If the camera is parented to the entity, we don't want to enable/disable it until the camera has finished transitioning.
			if (entity.getName() === cameraParent) {
				await SceneHelpers.waitTillEntitiesInPlace(this._scene, ['earth', event.id]);

				const { isCameraTransitioning } = cameraStore.stateSnapshot;

				if (isCameraTransitioning) {
					// Wait for the camera transition to finish.
					const transitionUnsubscribe = cameraStore.subscribeTo('isCameraTransitioning', isCameraTransitioning => {
						if (isCameraTransitioning === false) {
							transitionUnsubscribe();
							handleEntity(entity, value);
						}
					});
				} else {
					handleEntity(entity, value);
				}
			} else {
				handleEntity(entity, value);
			}
		});
	}

	singleCustomPlacemark(item, is_poi) {	
		const { id, center, title, external_id } = item || {};
		// console.log("singleCustomPlacemark", item)

		if (!external_id || !center) { return; }

		const earth = this._scene.get('earth');
		let group = this._scene.getEntity('custom_placemarks');

		if (!group) {
			group = this._scene.addEntity('custom_placemarks');
			group.setParent(earth);
			group.setCanOcclude(false);
			group.setExtentsRadius(earth.getExtentsRadius());
			const fixed = group.addController('fixed');
			fixed.setPosition(Pioneer.Vector3.Zero);
			fixed.setOrientation(Pioneer.Quaternion.Identity);
			group.addController('rotateByEntityOrientation');
		}

		let entity = this._scene.get(external_id);
		if (!entity) {
			const center_arr = center.split(',')
			const lat = Number(center_arr[0]);
			const lon = Number(center_arr[1]);
			entity = Placemarks.addPlacemark(external_id, '', earth.getComponentByClass(Pioneer.SpheroidComponent), lat, lon, 0);
			const entityEl = entity.get('div');
			// Put placemark into group.
			entity.setParent(group);

			const div = entityEl.getDiv();
			entityEl.setAlignment(new Pioneer.Vector2(0.5, 0.5));
			div.id = 'eventPlacemark_' + external_id;
			div.classList.add('pin','eventPin','placemark','custom_placemark');
			div.classList.remove('pioneer-label-div');
			div.title = title;
			div.classList.add('placemark');
			if(is_poi){
				//div.innerHTML = '<div class="pin"><img class="no-drag" src="assets/images/map_marker_poi.svg"></div>';
				div.addEventListener('click', () => {
					const { getManager } = globalRefs;
					getManager('route').navigateToPoi(external_id);
				});
			} else {
				//div.innerHTML = '<div class="pin"><img class="no-drag" src="assets/images/map_marker_gmap.svg"></div>';
				div.addEventListener('click', () => {
					const { getManager } = globalRefs;
					getManager('route').navigateToGmap(external_id);
				});
			}
	
		}

		return entity;
	}

	singleGmapPlacemark(eventId = null) {		
			if (!eventId) { return; }

			if (!this._gmapIds.includes(eventId)) {
				this._gmapIds.push(eventId);
			}

			const earth = this._scene.get('earth');
			let group = this._scene.getEntity('events');

			if (!group) {
				group = this._scene.addEntity('events');
				group.setParent(earth);
				group.setCanOcclude(false);
				group.setExtentsRadius(earth.getExtentsRadius());
				const fixed = group.addController('fixed');
				fixed.setPosition(Pioneer.Vector3.Zero);
				fixed.setOrientation(Pioneer.Quaternion.Identity);
				group.addController('rotateByEntityOrientation');
			}

			let entity = this._scene.get(eventId);

			if (!entity) {
				const { getGmapInfo } = get('content');
				const event = getGmapInfo(eventId);

				if (!event || !event.center) {
					return null;
				}

				const lat = Number(event.center.split(',')[0]);
				const lon = Number(event.center.split(',')[1]);
				entity = Placemarks.addPlacemark(eventId, '', earth, lat, lon, 0, true, true);
				const entityEl = entity.get('div');
				// Put placemark into group.
				entity.setParent(group);

				const div = entityEl.getDiv();
				div.id = 'eventPlacemark_' + event.id;
				// div.classList.add('pin');
				// div.classList.add('eventPin');
				div.classList.add('placemark');
				// div.innerHTML = '<div class="pin"><img class="no-drag" src="assets/images/map_marker_gmap.svg"></div>';
				div.addEventListener('click', () => {
					gmapStore.setGlobalState({ showOnGlobeGmap: false, showLatestGmap: false });
					this._router.goToGmap(eventId);
				});
			}

			return entity;
	}
	

	displayCustomPlacemarks(value, is_poi, list) {
		const { spout } = spoutStore.stateSnapshot;
		if (!list) { 
			list = is_poi ? this._displayingPois : this._displayingGmaps
		}

		const cameraEntity = this._scene.get('camera');
		const cameraParent = cameraEntity?.getParent()?.getName();

		// If showing placemarks, store the this._displayingEvents = [] so we can easily hide them later.
		if (value) {
			if(is_poi){
				this._displayingPois = [...list]
			} else {
				this._displayingGmaps = [...list]
			}
		}

		const handleEntity = (entity, value) => {
			if (!entity) {
				return;
			}

			// Set entity enabled.
			entity.setEnabled(value);

			// Set placemark enabled.
			const placemarkDiv = entity.get('div');
			placemarkDiv.setEnabled(value);

			// If Spout is on, update visibility of spout labels as well as regular placemarks
			if (spout) {
				const entityLabel = entity.get('label');
				entityLabel?.setEnabled(value);
			}
		};

		list.forEach(async item => {
			const entity = this.singleCustomPlacemark(item, is_poi);

			if (!entity) {
				return;
			}

			// If the camera is parented to the entity, we don't want to enable/disable it until the camera has finished transitioning.
			if (entity.getName() === cameraParent) {
				await SceneHelpers.waitTillEntitiesInPlace(this._scene, ['earth', item.external_id]);

				const { isCameraTransitioning } = cameraStore.stateSnapshot;

				if (isCameraTransitioning) {
					// Wait for the camera transition to finish.
					const transitionUnsubscribe = cameraStore.subscribeTo('isCameraTransitioning', isCameraTransitioning => {
						if (isCameraTransitioning === false) {
							transitionUnsubscribe();
							handleEntity(entity, value);
						}
					});
				} else {
					handleEntity(entity, value);
				}
			} else {
				handleEntity(entity, value);
			}
		})
	}
}

export default SceneManager;
