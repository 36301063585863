/** @module pioneer */
import {
	BaseController,
	Entity,
	Quaternion,
	Vector3
} from '../../internal';

/**
 * A controller that rotates an entity's position and/or orientation by a rotation quaternion.
 */
export class RotateController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The rotation.
		 * @type {Quaternion}
		 * @private
		 */
		this._rotation = new Quaternion();
		this._rotation.freeze();

		/**
		 * A flag that determines whether the controller is rotating the position of the entity.
		 * @type {boolean}
		 * @private
		 */
		this._rotatingPosition = true;

		/**
		 * A flag that determines whether the controller is rotating the orientation of the entity.
		 * @type {boolean}
		 * @private
		 */
		this._rotatingOrientation = true;

		// Let the base controller know that this changes these states.
		this.addModifiedState('position');
		this.addModifiedState('velocity');
		this.addModifiedState('orientation');
		this.addModifiedState('angularVelocity');
	}

	/**
	 * Gets the rotation. Defaults to Quaterion(1, 0, 0, 0).
	 * @returns {Quaternion}
	 */
	getRotation() {
		return this._rotation;
	}

	/**
	 * Sets the rotation.
	 * @param {Quaternion} rotation
	 */
	setRotation(rotation) {
		this._rotation.thaw();
		this._rotation.copy(rotation);
		this._rotation.freeze();
	}

	/**
	 * Returns whether the controller is rotating the position of the entity. Defaults to true.
	 * @returns {boolean}
	 */
	isRotatingPosition() {
		return this._rotatingPosition;
	}

	/**
	 * Sets whether the controller is rotating the position of the entity.
	 * @param {boolean} rotatingPosition
	 */
	setRotatingPosition(rotatingPosition) {
		this._rotatingPosition = rotatingPosition;
		if (rotatingPosition) {
			this.addModifiedState('position');
			this.addModifiedState('velocity');
		}
		else {
			this.removeModifiedState('position');
			this.removeModifiedState('velocity');
		}
	}

	/**
	 * Returns true if the controller is rotating the orientation of the entity. Defaults to true.
	 * @returns {boolean}
	 */
	isRotatingOrientation() {
		return this._rotatingOrientation;
	}

	/**
	 * Sets whether the controller is rotating the orientation of the entity.
	 * @param {boolean} rotatingOrientation
	 */
	setRotatingOrientation(rotatingOrientation) {
		this._rotatingOrientation = rotatingOrientation;
		if (rotatingOrientation) {
			this.addModifiedState('orientation');
			this.addModifiedState('angularVelocity');
		}
		else {
			this.removeModifiedState('orientation');
			this.removeModifiedState('angularVelocity');
		}
	}

	/**
	 * Updates a position for the given time.
	 * @param {Vector3} position
	 * @param {number} _time
	 * @override
	 * @internal
	 */
	__updatePositionAtTime(position, _time) {
		if (this._rotatingPosition) {
			position.rotate(this._rotation, position);
		}
	}

	/**
	 * Updates a velocity for the given time.
	 * @param {Vector3} velocity
	 * @param {number} _time
	 * @override
	 * @internal
	 */
	__updateVelocityAtTime(velocity, _time) {
		if (this._rotatingPosition) {
			velocity.rotate(this._rotation, velocity);
		}
	}

	/**
	 * Updates given orientation for the given time.
	 * @param {Quaternion} orientation - The orientation to update.
	 * @param {number} _time - The time to check.
	 * @override
	 * @internal
	 */
	__updateOrientationAtTime(orientation, _time) {
		if (this._rotatingOrientation) {
			orientation.mult(this._rotation, orientation);
		}
	}

	/**
	 * Updates the controller.
	 * @override
	 * @internal
	 */
	__update() {
		if (this._rotatingPosition) {
			const newPosition = Vector3.pool.get();
			newPosition.rotate(this._rotation, this.getEntity().getPosition());
			this.getEntity().setPosition(newPosition);
			Vector3.pool.release(newPosition);
			const newVelocity = Vector3.pool.get();
			newVelocity.rotate(this._rotation, this.getEntity().getVelocity());
			this.getEntity().setVelocity(newVelocity);
			Vector3.pool.release(newVelocity);
		}
		if (this._rotatingOrientation) {
			const newOrientation = Quaternion.pool.get();
			newOrientation.mult(this._rotation, this.getEntity().getOrientation());
			this.getEntity().setOrientation(newOrientation);
			Quaternion.pool.release(newOrientation);
			const newAngularVelocity = Vector3.pool.get();
			newAngularVelocity.rotate(this._rotation, this.getEntity().getAngularVelocity());
			this.getEntity().setAngularVelocity(newAngularVelocity);
			Vector3.pool.release(newAngularVelocity);
		}
	}
}
