/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';

/**
 * A component that renders a colored torus aligned in the x-y plane with an inner and outer radius.
 */
export class TorusComponent extends Pioneer.BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Pioneer.Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The color.
		 * @type {Pioneer.Color}
		 * @private
		 */
		this._color = new Pioneer.Color(1, 1, 1, 1);
		this._color.freeze();

		/**
		 * The inner radius.
		 * @type {number}
		 * @private
		 */
		this._innerRadius = 1;

		/**
		 * The outer radius.
		 * @type {number}
		 * @private
		 */
		this._outerRadius = 10;

		/**
		 * The distance interval over which the component is visible.
		 * @type {Pioneer.Interval}
		 * @private
		 */
		this._visibleDistanceInterval = new Pioneer.Interval(0, Number.POSITIVE_INFINITY);
		this._visibleDistanceInterval.freeze();

		/**
		 * The alpha multiplier determined by fading.
		 * @type {number}
		 * @private
		 */
		this._alphaMultiplier = 1.0;

		this.__setRadius(this._outerRadius);
		this.__setUsesEntityOrientation(true);
	}

	/**
	 * Gets the color of the annulus. Defaults to white.
	 * @returns {Pioneer.Color}
	 */
	getColor() {
		return this._color;
	}

	/**
	 * Sets the color of the annulus. The alpha value should be provided at the component's maximum visibility.
	 * @param {Pioneer.Color} color
	 */
	setColor(color) {
		this._color.thaw();
		this._color.copy(color);
		this._color.freeze();
		Pioneer.ThreeJsHelper.setUniformColorRGBA(this.getThreeJsMaterials()[0], 'color', this._color, this._alphaMultiplier);
	}

	/**
	 * Gets the inner radius.
	 * @returns {number}
	 */
	getInnerRadius() {
		return this._innerRadius;
	}

	/**
	 * Sets the inner radius. Defaults to 1.
	 * @param {number} size
	 */
	setInnerRadius(size) {
		this._innerRadius = size;
		this.resetResources();
	}

	/**
	 * Gets the outer radius.
	 * @returns {number}
	 */
	getOuterRadius() {
		return this._outerRadius;
	}

	/**
	 * Sets the outer radius. Defaults to 10.
	 * @param {number} radius
	 */
	setOuterRadius(radius) {
		this._outerRadius = radius;
		this.__setRadius(this._outerRadius);
		this.resetResources();
	}

	/**
	 * Gets the distance interval over which the component is visible.
	 * @returns {Pioneer.Interval}
	 */
	getVisibleDistanceInterval() {
		return this._visibleDistanceInterval;
	}

	/**
	 * Sets the distance interval over which the component is visible. Defaults to [0, infinity).
	 * @param {Pioneer.Interval} distanceInterval
	 */
	setVisibleDistanceInterval(distanceInterval) {
		this._visibleDistanceInterval.thaw();
		this._visibleDistanceInterval.copy(distanceInterval);
		this._visibleDistanceInterval.freeze();
	}

	/**
	 * Prepare the component for rendering.
	 * @param {Pioneer.CameraComponent} camera
	 * @override
	 * @package
	 */
	__prepareForRender(camera) {
		// Set the orientation to the entity's orientation.
		Pioneer.ThreeJsHelper.setOrientationToEntity(this.getThreeJsObjects()[0], this.getEntity());

		// Set the Three.js object position the entity's camera-space position.
		Pioneer.ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects()[0], this.getEntity(), camera);

		// Make the torus fade if the camera is outside a visible distance interval.
		const cameraSpacePosition = this.getEntity().getCameraSpacePosition(camera);
		const distanceToObject = cameraSpacePosition.magnitude();
		const diffFadeDistance = (this._visibleDistanceInterval.max - this._visibleDistanceInterval.min) * 0.5;
		const minFadeDistance = Math.min(this._visibleDistanceInterval.min * 0.5, diffFadeDistance);
		const maxFadeDistance = Math.min(this._visibleDistanceInterval.max * 0.5, diffFadeDistance);
		if (distanceToObject < this._visibleDistanceInterval.min + minFadeDistance) {
			this._alphaMultiplier = Math.max(0, (distanceToObject - this._visibleDistanceInterval.min) / minFadeDistance);
		}
		else if (distanceToObject > this._visibleDistanceInterval.max - maxFadeDistance) {
			this._alphaMultiplier = Math.max(0, (this._visibleDistanceInterval.max - distanceToObject) / maxFadeDistance);
		}
		else {
			this._alphaMultiplier = 1.0;
		}
		Pioneer.ThreeJsHelper.setUniformColorRGBA(this.getThreeJsMaterials()[0], 'color', this._color, this._alphaMultiplier);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	__loadResources() {
		// Create the material.
		const material = this.getEntity().getScene().getEngine().getMaterialManager().getPreloaded('basic_alpha');
		this.getThreeJsMaterials().push(material);

		// Set the uniforms.
		Pioneer.ThreeJsHelper.setUniformColorRGBA(material, 'color', this._color, this._alphaMultiplier);

		// Create the mesh object.
		const object = Pioneer.ThreeJsHelper.createMeshObject(this, material, [{ name: 'position', dimensions: 3 }], false);
		this.getThreeJsObjects().push(object);

		// Create the mesh geometry.
		this._createMesh();

		// Return it as loaded.
		return Promise.resolve();
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		Pioneer.ThreeJsHelper.destroyAllObjectsAndMaterials(this);
	}

	/**
	 * Creates the mesh.
	 * @private
	 */
	_createMesh() {
		// Constants to use for a good number of segments.
		const numberOfCirclePoints = 100;
		const numberOfTorusPoints = 20;

		// The arrays.
		const positions = new Float32Array(numberOfCirclePoints * numberOfTorusPoints * 3);
		const indices = new Uint16Array(numberOfCirclePoints * numberOfTorusPoints * 6);

		// Saved variables for quicker calculations.
		const middleRadius = (this._outerRadius + this._innerRadius) / 2;
		const diffRadius = (this._outerRadius - this._innerRadius) / 2;

		// Go around the main circle.
		for (let i = 0; i < numberOfCirclePoints; i++) {
			const angleI = i / numberOfCirclePoints * Pioneer.MathUtils.twoPi;
			const xI = Math.cos(angleI);
			const yI = Math.sin(angleI);

			// Go around a circle segment of the main circle.
			for (let j = 0; j < numberOfTorusPoints; j++) {
				const angleJ = j / numberOfTorusPoints * Pioneer.MathUtils.twoPi;
				const rJ = Math.cos(angleJ);
				const zJ = Math.sin(angleJ);

				// Set the position.
				const positionsIndex = (i * numberOfTorusPoints + j) * 3;
				positions[positionsIndex + 0] = xI * (middleRadius + diffRadius * rJ);
				positions[positionsIndex + 1] = yI * (middleRadius + diffRadius * rJ);
				positions[positionsIndex + 2] = diffRadius * zJ;

				// Set the indices of the quad.
				const indicesIndex = (i * numberOfTorusPoints + j) * 6;
				const iPlus1 = (i + 1) % numberOfCirclePoints;
				const jPlus1 = (j + 1) % numberOfTorusPoints;
				indices[indicesIndex + 0] = i * numberOfTorusPoints + j;
				indices[indicesIndex + 1] = iPlus1 * numberOfTorusPoints + j;
				indices[indicesIndex + 2] = i * numberOfTorusPoints + jPlus1;
				indices[indicesIndex + 3] = iPlus1 * numberOfTorusPoints + jPlus1;
				indices[indicesIndex + 4] = i * numberOfTorusPoints + jPlus1;
				indices[indicesIndex + 5] = iPlus1 * numberOfTorusPoints + j;
			}
		}

		// Apply the positions and indices.
		const geometry = /** @type {Pioneer.THREE.Mesh} */(this.getThreeJsObjects()[0]).geometry;
		Pioneer.ThreeJsHelper.setVertices(geometry, 'position', positions);
		Pioneer.ThreeJsHelper.setIndices(geometry, indices);
	}
}
