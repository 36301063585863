/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	DynamicEnvironmentMapComponent,
	Entity,
	EntityRef,
	FastMap,
	Interval,
	MaterialUtils,
	MathUtils,
	Quaternion,
	THREE,
	ThreeJsGLTFLoader,
	ThreeJsHelper,
	Vector3
} from '../../internal';

/**
 * A model, usually imported from a GLTF file.
 */
export class ModelComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The url of the model.
		 * @type {string}
		 * @private
		 */
		this._url = '';

		/**
		 * The entities uses for shadows. Derived from the shadow entity names.
		 * @type {EntityRef[]}
		 * @private
		 */
		this._shadowEntities = [];

		/**
		 * The translation to apply to the model when rendering it.
		 * @type {Vector3}
		 * @private
		 */
		this._translation = new Vector3();
		this._translation.freeze();

		/**
		 * The rotation to apply to the model when rendering it.
		 * @type {Quaternion}
		 * @private
		 */
		this._rotation = new Quaternion();
		this._rotation.freeze();

		/**
		 * The mapping of file names to urls when loading GLTF references.
		 * @type {Map<string, string>}
		 * @private
		 */
		this._urlReferenceMap = new Map();

		/**
		 * The Three.js animation clips, keyed by name.
		 * @type {Map<string, THREE.AnimationClip>}
		 * @private
		 */
		this._threeJsAnimationClips = new Map();

		/**
		 * A mapping of names to objects of objects that should be hidden.
		 * @type {FastMap<string, THREE.Object3D>}
		 * @private
		 */
		this._hiddenObjects = new FastMap();

		/**
		 * The scale to apply to the model.
		 * @type {Vector3}
		 * @private
		 */
		this._scale = new Vector3(0.001, 0.001, 0.001);
		this._scale.freeze();

		/**
		 * The size of the actual model without any scaling. Defaults to NaN since it doesn't
		 * know the actual model radius until the model is loaded.
		 * @type {number}
		 * @private
		 */
		this._modelRadius = NaN;

		/**
		 * The url for the environment cube map.
		 * @type {string}
		 * @private
		 */
		this._environmentCubemapUrl = '';

		/**
		 * The url for the cylindrical cube map.
		 * @type {string}
		 * @private
		 */
		this._environmentCylindricalUrl = '';

		/**
		 * The environment cube map.
		 * @type {THREE.Texture}
		 * @private
		 */
		this._environmentCubemap = null;

		/**
		 * The intensity of the environment.
		 * @type {number}
		 * @private
		 */
		this._environmentIntensity = 0.5;

		/**
		 * The dynamic environment map texture, if used.
		 * @type {DynamicEnvironmentMapComponent}
		 * @private
		 */
		this._dynamicEnvironmentMapComponent = null;

		/**
		 * The pixel radius interval over which the model is visible. It will fade to nothing for 50% outside of these bounds.
		 * @type {Interval | undefined}
		 */
		this._pixelRadiusVisibleInterval = undefined;

		/**
		 * The flag that if true, uses compressed textures.
		 * @type {boolean}
		 * @private
		 */
		this._useCompressedTextures = false;

		/**
		 * A bound function for use in callbacks.
		 * @type {function():void}
		 * @private
		 */
		this._onConfigChanged = this._onConfigChanged.bind(this);

		this.__setRadius(this.getEntity().getExtentsRadius());
	}

	/**
	 * Returns the url of the model file.
	 * @returns {string}
	 */
	getUrl() {
		return this._url;
	}

	/**
	 * Sets the url of the model file.
	 * @param {string} url
	 */
	setUrl(url) {
		if (this._url !== '') {
			this.getEntity().getScene().getEngine().getDownloader().cancel(this._url);
		}
		this.resetResources();
		this._modelRadius = NaN;
		this._loading = false;
		this._url = url;
	}

	/**
	 * Sets an object to be hidden on the model.
	 * @param {string} name - The object name
	 * @param {boolean} hidden - Whether or not it should be hidden
	 */
	setHiddenObject(name, hidden) {
		if (hidden && !this._hiddenObjects.has(name)) {
			this._hiddenObjects.set(name, null);
		}
		else if (!hidden && this._hiddenObjects.has(name)) {
			this._hiddenObjects.delete(name);
		}
	}

	/**
	 * Gets the translation of the model.
	 * @returns {Vector3} translation
	 */
	getTranslation() {
		return this._translation;
	}

	/**
	 * Sets the translation of the model. It defaults to the zero vector.
	 * @param {Vector3} translation
	 */
	setTranslation(translation) {
		this._translation.thaw();
		this._translation = translation;
		this._translation.freeze();
	}

	/**
	 * Gets the scale of the model.
	 * @returns {Vector3} scale
	 */
	getScale() {
		return this._scale;
	}

	/**
	 * Sets the scale of the model. It defaults to 0.001, since most models are in meters, not kilometers.
	 * @param {Vector3 | number} scale
	 */
	setScale(scale) {
		this._scale.thaw();
		if (typeof scale === 'number') {
			this._scale.set(scale, scale, scale);
		}
		else {
			this._scale.copy(scale);
		}
		this._scale.freeze();
		ThreeJsHelper.setScale(this.getThreeJsObjects()[0], this._scale);
		if (!isNaN(this._modelRadius)) {
			this.__setRadius(Math.max(this._scale.x, this._scale.y, this._scale.z) * this._modelRadius);
		}
		else {
			this.__setRadius(this.getEntity().getExtentsRadius());
		}
	}

	/**
	 * Gets the rotation applied to the model. Defaults to the identity rotation.
	 * @returns {Quaternion}
	 */
	getRotation() {
		return this._rotation;
	}

	/**
	 * Sets the rotation applied to the modeol.
	 * @param {Quaternion} rotation
	 */
	setRotation(rotation) {
		this._rotation.thaw();
		this._rotation.copy(rotation);
		this._rotation.freeze();
	}

	/**
	 * Gets the url of the environment cubemap applied to the model during loading
	 * @returns {string}
	 */
	getEnvironmentCubemapUrl() {
		return this._environmentCubemapUrl;
	}

	/**
	 * Sets the url of the environment cubemap applied to the model during loading
	 * @param {string} url - the url is used by TextureLoader.loadCubeTexture and follows its rules for the $FACE variable.
	 */
	setEnvironmentCubemapUrl(url) {
		this._environmentCubemapUrl = url;
	}

	/**
	 * Gets the intensity multiplier applied to environment lighting
	 * @returns {number}
	 */
	getEnvironmentIntensity() {
		return this._environmentIntensity;
	}

	/**
	 * Sets the intensity multiplier applied to environment lighting
	 * @param {number} environmentIntensity
	 */
	setEnvironmentIntensity(environmentIntensity) {
		this._environmentIntensity = environmentIntensity;
		for (let i = 0, l = this.getThreeJsMaterials().length; i < l; i++) {
			ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[i], 'environmentIntensity', this._environmentIntensity);
		}
	}

	/**
	 * Gets the url of the cylindrical cubemap applied to the model during loading
	 * @returns {string}
	 */
	getEnvironmentCylindricalUrl() {
		return this._environmentCylindricalUrl;
	}

	/**
	 * Sets the url of the cylindrical cubemap applied to the model during loading
	 * @param {string} url - url of the image to use
	 */
	setEnvironmentCylindricalUrl(url) {
		this._environmentCylindricalUrl = url;
	}

	/**
	 * Sets the pixel radius interval over which the model is visible.
	 * It will fade to nothing for 5% outside of these bounds.
	 * The material must already have transparency enabled for it to work.
	 * Defaults to [0, +infinity].
	 * @param {Interval | undefined} interval
	 */
	setPixelRadiusVisibleInterval(interval) {
		if (interval !== undefined) {
			if (this._pixelRadiusVisibleInterval === undefined) {
				this._pixelRadiusVisibleInterval = new Interval(interval.min, interval.max);
			}
			else {
				this._pixelRadiusVisibleInterval.copy(interval);
			}
		}
		else {
			this._pixelRadiusVisibleInterval = undefined;
			for (const material of this.getThreeJsMaterials()) {
				ThreeJsHelper.setUniformNumber(material, 'alphaMultiplier', 1);
			}
			this.getThreeJsObjects()[0].visible = true;
		}
	}

	/**
	 * Sets whether or not the dynamic environment map is enabled.
	 * @param {DynamicEnvironmentMapComponent} dynamicEnvironmentMapComponent
	 */
	setDynamicEnvironmentMapComponent(dynamicEnvironmentMapComponent) {
		this._dynamicEnvironmentMapComponent = dynamicEnvironmentMapComponent;
		for (let i = 0, l = this.getThreeJsMaterials().length; i < l; i++) {
			const material = this.getThreeJsMaterials()[i];
			if (material.uniforms['dynEnvTexture'] !== undefined) {
				ThreeJsHelper.setDefine(material, 'dynEnvMap', true);
				ThreeJsHelper.setUniformTexture(material, 'dynEnvTexture', this._dynamicEnvironmentMapComponent.getTexture());
				ThreeJsHelper.setUniformNumber(material, 'dynEnvFaceSize', dynamicEnvironmentMapComponent.getFaceSize());
			}
		}
		// Disable the static environment cube map.
		if (this._environmentCubemap !== null) {
			ThreeJsHelper.destroyTexture(this._environmentCubemap);
			this._environmentCubemap = null;
		}
	}

	/**
	 * Gets the flag that if true, uses compressed textures.
	 * @returns {boolean}
	 */
	getUseCompressedTextures() {
		return this._useCompressedTextures;
	}

	/**
	 * Sets the flag that if true, uses compressed textures.
	 * @param {boolean} useCompressedTextures
	 */
	setUseCompressedTextures(useCompressedTextures) {
		this._useCompressedTextures = useCompressedTextures;
	}

	/**
	 * Sets the mapping of file names to urls when loading GLTF references.
	 * @param {Map<string, string>} urlReferenceMap;
	 */
	setURLReferenceMap(urlReferenceMap) {
		this._urlReferenceMap.clear();
		for (const entry of urlReferenceMap) {
			this._urlReferenceMap.set(entry[0], entry[1]);
		}
	}

	/**
	 * Gets the Three.js animation clip of the given name. Returns null if the clip doesn't exist.
	 * @param {string} name
	 * @returns {THREE.AnimationClip}
	 */
	getAnimationClip(name) {
		return this._threeJsAnimationClips.get(name) || null;
	}

	/**
	 * Gets a material by name.
	 * @param {string} name
	 * @returns {THREE.ShaderMaterial | null}
	 */
	getMaterial(name) {
		const materials = this.getThreeJsMaterials();
		for (let i = 0, l = materials.length; i < l; i++) {
			if (materials[i].name === name) {
				return materials[i];
			}
		}
		return null;
	}

	/**
	 * Replaces a material with a new material.
	 * @param {string} name
	 * @param {THREE.ShaderMaterial} newMaterial
	 */
	updateMaterial(name, newMaterial) {
		// Find the material by name and replace it in the materials list.
		const materials = this.getThreeJsMaterials();
		let foundMaterial;
		for (let i = 0; i < materials.length; i++) {
			const material = materials[i];
			if (material.name === name) {
				foundMaterial = material;
				materials.splice(i, 1);
				materials.push(newMaterial);
				break;
			}
		}
		if (!foundMaterial) {
			throw new Error(`No material with the name ${name} was found in ${this}.`);
		}
		// Replace the material in every object that has it.
		for (let i = 0, l = this.getThreeJsObjects().length; i < l; i++) {
			const object = this.getThreeJsObjects()[i];
			if (object instanceof THREE.Mesh) {
				if (object.material === foundMaterial) {
					object.material = newMaterial;
					object.material.name = name;
				}
			}
		}
	}

	/**
	 * Gets the number of shadow entities. Can be used to enumerate the shadow entities.
	 * @returns {number}
	 */
	getNumShadowEntities() {
		return this._shadowEntities.length;
	}

	/**
	 * Returns the shadow entity or its name at the index.
	 * @param {number} index
	 * @returns {string | undefined}
	 */
	getShadowEntity(index) {
		return this._shadowEntities[index]?.getName();
	}

	/**
	 * Sets the shadow entities. Each element can be either the name of an entity or an entity itself.
	 * @param {string[]} shadowEntities
	 */
	setShadowEntities(shadowEntities) {
		this._shadowEntities = [];
		for (const shadowEntity of shadowEntities) {
			this._shadowEntities.push(new EntityRef(this.getEntity().getScene(), shadowEntity));
		}
		const shadowEntitiesEnabled = (shadowEntities.length > 0);
		for (let i = 0, l = this.getThreeJsMaterials().length; i < l; i++) {
			ThreeJsHelper.setDefine(this.getThreeJsMaterials()[i], 'shadowEntities', shadowEntitiesEnabled);
		}
	}

	/**
	 * Updates the camera-dependent parts of the component.
	 * @param {CameraComponent} camera
	 * @override
	 */
	__prepareForRender(camera) {
		// Make any objects hidden that should be hidden.
		for (let i = 0, l = this._hiddenObjects.size; i < l; i++) {
			const entry = this._hiddenObjects.getAt(i);
			if (entry.value === null) {
				entry.value = this.getThreeJsObjectByName(entry.key);
			}
			if (entry.value !== null) {
				entry.value.visible = false;
			}
		}

		// Set the alpha multiplier based on conditions.
		if (this._pixelRadiusVisibleInterval !== undefined) {
			const pixelRadius = this.getEntity().getPixelSpaceExtentsRadius(camera);
			const alphaMultiplier = MathUtils.clamp01(Math.min(
				1 + 2 * (pixelRadius - this._pixelRadiusVisibleInterval.min) / this._pixelRadiusVisibleInterval.min,
				isFinite(this._pixelRadiusVisibleInterval.max) ? (1 - 2 * (pixelRadius - this._pixelRadiusVisibleInterval.max) / this._pixelRadiusVisibleInterval.max) : 1));
			for (const material of this.getThreeJsMaterials()) {
				ThreeJsHelper.setUniformNumber(material, 'alphaMultiplier', alphaMultiplier);
			}
			if (alphaMultiplier === 0) {
				this.getThreeJsObjects()[0].visible = false;
			}
		}

		// Apply the position to the ThreeJS object.
		ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects()[0], this.getEntity(), camera, this._translation, true);

		// Apply the rotation and orientation to the ThreeJS object.
		ThreeJsHelper.setOrientationToEntity(this.getThreeJsObjects()[0], this.getEntity(), this._rotation);

		// Setup the regular uniforms.
		MaterialUtils.setUniforms(this.getThreeJsMaterials(), camera, this.getEntity(), this._shadowEntities, null, false);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	async __loadResources() {
		const engine = this.getEntity().getScene().getEngine();

		const binary = this._url.startsWith('blob:') || this._url.endsWith('.glb');
		try {
			const download = await engine.getDownloader().download(this._url, binary, -this.getEntity().getLeastCameraDepth());

			// If we're no longer loading or the download was canceled, do nothing.
			if (this.getLoadState() !== 'loading' || download.status === 'cancelled') {
				return;
			}
			// If the download failed, error.
			else if (download.status === 'failed') {
				throw new Error('Failed to load model component file "' + download.url + '": ' + download.errorMessage);
			}
			// If the download isn't the right format, error.
			else if (!download.actualUrl.endsWith('.gltf') && !download.actualUrl.endsWith('.glb') && !download.actualUrl.startsWith('blob:')) {
				throw new Error('Unknown model format.');
			}

			// Create and setup the Three.js loading manager needed by the ThreeJsGLTFLoader.
			const manager = new THREE.LoadingManager();
			// Use the standard texture loaders only if there are no replacement URLs for the references.
			if (this._urlReferenceMap.size === 0) {
				manager.addHandler(/.$/, this._useCompressedTextures ? engine.getTextureLoaderCompressed() : engine.getTextureLoader());
			}
			// If there is a reference map, replace every reference in the GLTF with the mapped URL.
			manager.setURLModifier((url) => {
				const urlFileName = url.substring(url.lastIndexOf('/') + 1);
				if (this._urlReferenceMap.has(urlFileName)) {
					return this._urlReferenceMap.get(urlFileName);
				}
				return url;
			});

			// Load and parse the rest of the model.
			const loader = new ThreeJsGLTFLoader(manager);
			await new Promise((resolve, reject) => {
				loader.parse(download.content, THREE.LoaderUtils.extractUrlBase(download.actualUrl), async (gltf) => {
					// Save the root for use below.
					const root = gltf.scene;

					// Populate the Three.js objects and materials lists. Even if we're no longer loading, this makes it easier to clean.
					this._populateThreeJsObjectsAndMaterials(gltf.scene);

					// If we're no longer loading clean up and return.
					if (this.getLoadState() !== 'loading') {
						ThreeJsHelper.destroyAllObjectsAndMaterials(this);
					}

					// Cleans up some of the extra objects.
					this._clean();

					// Set the initial properties of the root object.
					ThreeJsHelper.setupObject(this, root);

					// Set the scale of the model.
					ThreeJsHelper.setScale(root, this._scale);

					// Set the radius based on the bounding box.
					const boundingBox = new THREE.Box3().setFromObject(root);
					this._modelRadius = Math.max(boundingBox.min.length(), boundingBox.max.length());
					this.__setRadius(Math.max(this._scale.x, this._scale.y, this._scale.z) * this._modelRadius);

					// Save the animation clips.
					for (let i = 0; i < gltf.animations.length; i++) {
						this._threeJsAnimationClips.set(gltf.animations[i].name, gltf.animations[i]);
					}

					// Load the environment map if it does have a standard material. Dynamic has priority over static.
					if (this._dynamicEnvironmentMapComponent === null && this._environmentCubemap === null) {
						// Cubemap has priority over cylindrical
						if (this._environmentCubemapUrl !== '') {
							await engine.getTextureLoader().loadCubeTexture(this._environmentCubemapUrl, -this.getEntity().getLeastCameraDepth(), true).then((cubeTexture) => {
								this._environmentCubemap = engine.getTextureLoader().generateEnvMap(cubeTexture);
							});
						}
						else if (this._environmentCylindricalUrl !== '') {
							const texture = await ThreeJsHelper.loadTexture(this, this._environmentCubemapUrl, true, false);
							this._environmentCubemap = engine.getTextureLoader().generateEnvMap(texture);
						}
					}

					// Update the materials to be the Pioneer compatible materials.
					this._updateMaterials();

					// If the config's gamma value changes, set the callback to update the model.
					this.getEntity().getScene().getEngine().getConfig().addEventListener('gammaCorrection', this._onConfigChanged);

					resolve();
				}, (error) => {
					reject(new Error(`Error loading gltf: ${error}`));
				});
			});
		}
		catch (error) {
			if (error instanceof Error) {
				error.message = `While loading model "${this._url}": ${error.message}`;
			}
			throw error;
		}
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		// Clear the hidden object references.
		for (let i = 0, l = this._hiddenObjects.size; i < l; i++) {
			this._hiddenObjects.getAt(i).value = null;
		}
		// Remove the gamma correction event listener.
		this.getEntity().getScene().getEngine().getConfig().removeEventListener('gammaCorrection', this._onConfigChanged);
		// Clear the objects and materials.
		ThreeJsHelper.destroyAllObjectsAndMaterials(this);
	}

	/**
	 * Populates the Three.js object list with all objects, including the children.
	 * @param {THREE.Object3D} object
	 * @private
	 */
	_populateThreeJsObjectsAndMaterials(object) {
		// Add the object to the list.
		this.getThreeJsObjects().push(object);
		// If the object's materials haven't already been added to the list, add them.
		if (object instanceof THREE.Mesh) {
			/** @type {THREE.ShaderMaterial | THREE.ShaderMaterial[]} */
			let materials = object.material;
			if (!Array.isArray(materials)) {
				materials = [materials];
			}
			for (const material of materials) {
				let alreadyAdded = false;
				for (let i = 0, l = this.getThreeJsMaterials().length; i < l; i++) {
					if (this.getThreeJsMaterials()[i] === material) {
						alreadyAdded = true;
						break;
					}
				}
				if (!alreadyAdded) {
					this.getThreeJsMaterials().push(material);
				}
			}
		}
		// Go through the children.
		for (let i = 0; i < object.children.length; i++) {
			this._populateThreeJsObjectsAndMaterials(object.children[i]);
		}
	}

	/**
	 * Clean up a number of objects that aren't needed.
	 * @private
	 */
	_clean() {
		// Remove lamps and hemis.
		const removeRegex = /_(lamp|hemi)/i;
		for (let i = 0; i < this.getThreeJsObjects().length; i++) {
			const object = this.getThreeJsObjects()[i];
			if (object.name.match(removeRegex)) {
				if (object.parent) {
					object.parent.remove(object);
				}
				this.getThreeJsObjects().splice(i, 1);
				i--;
			}
		}

		// Make invisible any object with a name starting with '_root' or having a material named 'transparent'.
		// Only remove its attributes so that it doesn't break things too much.
		for (let i = 0, l = this.getThreeJsObjects().length; i < l; i++) {
			let shouldMakeInvisible = false;
			const object = this.getThreeJsObjects()[i];
			if (object instanceof THREE.Mesh && object.geometry instanceof THREE.BufferGeometry) {
				if (object.name.startsWith('_root')) {
					shouldMakeInvisible = true;
				}
				if (object instanceof THREE.Mesh && object.material instanceof THREE.Material && object.material.name === 'transparent') {
					shouldMakeInvisible = true;
				}
				if (shouldMakeInvisible) {
					object.geometry.deleteAttribute('position');
					object.geometry.deleteAttribute('normal');
				}
			}
		}
	}

	/**
	 * Manage pioneer config changes
	 * @private
	 */
	_onConfigChanged() {
		this._updateMaterials();
	}

	/**
	 * Updates the existing object materials to be ones compatible with Pioneer. Creates tangents if necessary.
	 * @private
	 */
	_updateMaterials() {
		for (let i = 0, l = this.getThreeJsMaterials().length; i < l; i++) {
			const oldMaterial = this.getThreeJsMaterials()[i];
			if (!(oldMaterial instanceof THREE.MeshStandardMaterial)) {
				continue;
			}
			const newMaterial = this._getNewPioneerMaterial(oldMaterial);
			this.getThreeJsMaterials()[i] = newMaterial;

			// Check if the new material requires a tangent attribute.
			let needsTangents = false;
			if (newMaterial.defines['normalMap']) {
				needsTangents = true;
			}

			// Go through each object and replace the materials, computing the tangents if necessary.
			for (const object of this.getThreeJsObjects()) {
				if (object instanceof THREE.Mesh) {
					let hasOldMaterial = false;
					if (Array.isArray(object.material)) {
						for (let j = 0, k = object.material.length; j < k; j++) {
							if (object.material[j] === oldMaterial) {
								object.material[j] = newMaterial;
								hasOldMaterial = true;
							}
						}
					}
					else if (object.material === oldMaterial) {
						object.material = newMaterial;
						hasOldMaterial = true;
					}

					// If the object's geometry doesn't have a tangent attribute defined, create it manually from the positions and uvs.
					if (hasOldMaterial && needsTangents && object.geometry instanceof THREE.BufferGeometry && object.geometry.getAttribute('tangent') === undefined) {
						ThreeJsHelper.computeTangents(object.geometry);
					}
				}
			}
			ThreeJsHelper.destroyMaterial(oldMaterial, true);
		}
	}

	/**
	 * Use the Three.JS material as a basis to create a new Pioneer-compatible material.
	 * @param {THREE.MeshStandardMaterial} material
	 * @returns {THREE.ShaderMaterial}
	 * @private
	 */
	_getNewPioneerMaterial(material) {
		const newMaterial = MaterialUtils.getPBR();
		newMaterial.name = material.name;
		newMaterial.transparent = material.transparent;
		newMaterial.depthWrite = material.depthWrite;
		newMaterial.side = material.side;

		if (material.map !== null) {
			// Retrieve KTX compressed format from if available
			// @ts-ignore
			const ktxFormat = material.map.ktxFormat;
			if (ktxFormat !== undefined) {
				material.map.format = ktxFormat;
			}
			newMaterial.uniforms['colorTexture'].value = material.map;
			newMaterial.defines['colorMap'] = true;
			material.map = null;
		}

		if (material.roughnessMap) {
			newMaterial.uniforms['roughnessTexture'].value = material.roughnessMap;
			newMaterial.defines['roughnessMap'] = true;
			material.roughnessMap = null;
		}

		if (material.metalnessMap) {
			newMaterial.uniforms['metalnessTexture'].value = material.metalnessMap;
			newMaterial.defines['metalnessMap'] = true;
			material.metalnessMap = null;
		}

		if (material.normalMap !== null) {
			newMaterial.uniforms['normalTexture'].value = material.normalMap;
			newMaterial.uniforms['normalScale'].value = material.normalScale;
			newMaterial.defines['normalMap'] = true;
			material.normalMap = null;
		}

		// Update the emissivity.
		newMaterial.uniforms['emissiveColor'].value.copy(material.emissive);
		newMaterial.uniforms['emissiveColor'].value.multiplyScalar(material.emissiveIntensity);
		if (material.emissiveMap !== null) {
			newMaterial.uniforms['emissiveTexture'].value = material.emissiveMap;
			newMaterial.defines['emissiveMap'] = true;
			material.emissiveMap = null;
		}

		newMaterial.uniforms['color'].value = material.color;
		newMaterial.uniforms['roughness'].value = material.roughness;
		newMaterial.uniforms['metalness'].value = material.metalness;

		// Apply environment map
		let textureSideLength = 0;
		if (this._dynamicEnvironmentMapComponent !== null) {
			newMaterial.defines['dynEnvMap'] = true;
			newMaterial.uniforms['dynEnvTexture'].value = this._dynamicEnvironmentMapComponent.getTexture();
			newMaterial.uniforms['dynEnvFaceSize'].value = this._dynamicEnvironmentMapComponent.getFaceSize();
		}
		else if (this._environmentCubemap !== null) {
			newMaterial.defines['envMap'] = true;
			newMaterial.defines['envIsCubeUV'] = true;

			newMaterial.uniforms['envTexture'].value = this._environmentCubemap;
			textureSideLength = this._environmentCubemap.image.height;
			newMaterial.uniforms['maxMipLevel'].value = Math.log(textureSideLength) * Math.LOG2E;
		}
		newMaterial.uniforms['environmentIntensity'].value = this._environmentIntensity;

		// Apply gamma correction.
		newMaterial.uniforms['gammaCorrectionFactor'].value = this.getEntity().getScene().getEngine().getConfig().getValue('gammaCorrection');

		// Apply shadow entity define.
		if (this._shadowEntities.length > 0) {
			newMaterial.defines['shadowEntities'] = true;
		}

		// Trigger the update in Three.js.
		newMaterial.needsUpdate = true;

		return newMaterial;
	}
}
