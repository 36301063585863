import { useCallback, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { useParams } from 'react-router-dom';

import DatePicker from './date_picker';
import Dropdown from './dropdown';
import DateRange from './date_range';

import StepBackwardSolidSVG from '../../www/assets/images/step_backward_solid.svg';
import CalendarAltSolidSVG from '../../www/assets/images/calendar_alt_solid.svg';
import PauseSolidSVG from '../../www/assets/images/pause_solid.svg';
import PlaySolidSVG from '../../www/assets/images/play_solid.svg';
import StepForwardSolidSVG from '../../www/assets/images/step_forward_solid.svg';
import ar from '../languages/ar';

import { datasetStore, appStore, uiStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';

import '../../www/assets/css/dataset_tools.css';
import { getDatasetFromVitalsData } from '../helpers/tools';


const AnimateButton = () => {
	const { queries } = useSnapshot(appStore.state);
	const { validData } = useSnapshot(uiStore.state);

	const onClick = useCallback(() => {
		const { setQuery } = globalRefs;
		const { animating, ...otherQueries } = queries;
		const isAnimating = queries.animating === 'true';
		setQuery({
			...otherQueries,
			...(!isAnimating && { animating: 'true' })
		});
	}, [queries]);

	return (
		<div className='d-none d-lg-block mb-lg-5 mr-lg-3 mr-lg-5 animate-button-container'>
			<button className={`animate no-select ${validData ? '' : 'inactive'}`} onClick={onClick}>
				{`${queries.animating === 'true' ? ar.exit_timelapse : ar.create_timelapse}`}
			</button>
		</div>
	);
};

const DatasetControls = ({ toggleShowDatePicker, showDatePicker }) => {
	const { getManager } = globalRefs;
	const { isMobile } = useSnapshot(appStore.state);
	const { isDatasetPaused } = useSnapshot(datasetStore.state);

	const { prevDataset, nextDataset, setPaused } = getManager('dataset');

	return (
		<>
			<div className='controls'>
				<button className='controls-btn previous no-select' type='button' onClick={() => prevDataset()}>
					<StepBackwardSolidSVG />
				</button>
				<button className='controls-btn playpause no-select' type='button' onClick={() => setPaused(!isDatasetPaused)}>
					{ isDatasetPaused ? <PlaySolidSVG /> : <PauseSolidSVG /> }
				</button>
				<button className='controls-btn next no-select' type='button' onClick={() => nextDataset()}>
					<StepForwardSolidSVG />
				</button>
				{
					!isMobile
					&& (
						<button className={`controls-btn calendar-toggle no-select ${showDatePicker ? 'active' : ''}`} type='button' id='calendar-toggle' onClick={toggleShowDatePicker}>
							<CalendarAltSolidSVG />
						</button>
					)
				}
			</div>
			{!isMobile && <DateRange />}
		</>
	);
};
const DatasetTools = () => {
	const { datasetHasAnimation, currentDataset } = useSnapshot(datasetStore.state);
	const { isMobile } = useSnapshot(appStore.state);
	const [showDatePicker, setShowDatePicker] = useState(false);
	const { controlsMenu } = useSnapshot(uiStore.state);

	useEffect(() => {
		setShowDatePicker(false);
	}, [currentDataset?.id]);

	const disableContents = controlsMenu === 'time' || controlsMenu === 'settings';
	return currentDataset && (
		<div id='dataset-tools' className={disableContents ? 'disabled' : ''}>
			{!isMobile && (
				<>
					<DatasetDropdown />
					<AnimateButton />
					<div className={datasetHasAnimation ? 'separator' : ''} />
				</>
			)}
			{datasetHasAnimation && <DatasetControls toggleShowDatePicker={() => setShowDatePicker(!showDatePicker)} showDatePicker={showDatePicker} />}
			{!isMobile && showDatePicker && <DatePicker close={() => setShowDatePicker(false)} />}
		</div>
	);
};

const DatasetDropdown = () => {
	const { getManager } = globalRefs;
	const { currentDataset } = useSnapshot(datasetStore.state);
	const { isMobile } = useSnapshot(appStore.state);
	const { videoParam, datasetParam, eventPreviewParam } = useParams();

	const { datasetGroup, vitalData } = getDatasetFromVitalsData(currentDataset?.externalId);
	const { datasets = [] } = datasetGroup || {};
	const videoDropdownEntries = vitalData?.value ? getManager('video').getVideoDropdownEntries(vitalData.value) : [];
	const allDropDownEntries = [...datasets, ...videoDropdownEntries];

	const showDropdown = !isMobile && currentDataset && allDropDownEntries.length > 1 && (videoParam || datasetParam);
	const showAnimateButton = !isMobile && !eventPreviewParam;
	const onDropdownItemSelect = selectedIndex => {
		const { urlParam, isVideo } = allDropDownEntries[selectedIndex] || {};
		if (!urlParam) {
			return;
		}

		const { getManager } = globalRefs;
		const routeManager = getManager('route');

		if (isVideo) {
			routeManager.navigateToVideo(urlParam);
		} else {
			routeManager.navigateToDataset({ datasetParam: urlParam });
		}
	};

	return showDropdown && (

		<div id='dataset-dropdown'>
			<Dropdown
				itemIndex={allDropDownEntries.findIndex(({ urlParam }) => urlParam === datasetParam || urlParam === videoParam)}
				onItemClick={onDropdownItemSelect}
				itemList={allDropDownEntries.map(({ id, menuTitle }) => ({ id, text: menuTitle }))}
				boldLabel={false}
			/>
		</div>
	);
};

export default DatasetTools;
