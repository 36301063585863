/* eslint-disable camelcase */
import { LatLonAlt, MathUtils } from 'pioneer';
import { SceneHelpers } from 'pioneer-scripts';

import { eventsStore } from '../managers/globalState';
import { PREVIEW_PATH_LIVE, EVENTS_LATEST_LIMIT, EVENT_CATEGORIES, EVENT_MEDIA } from '../config/constants';
import { Config } from '../config/config';
import globalRefs from '../managers/globalRefs';

const updateYearIndex = newYearIndex => {
	eventsStore.setGlobalState({ yearIndex: newYearIndex });
};

const updateCategoryIndex = newCategoryIndex => {
	eventsStore.setGlobalState({ categoryIndex: newCategoryIndex });
};

const updateMediaIndex = newMediaIndex => {
	eventsStore.setGlobalState({ mediaIndex: newMediaIndex });
};

const filterEvents = (allEvents, categoryIndex = 0, mediaIndex = 0) => allEvents.filter(event => {
	const { categories, isAnimated } = event || {};
	if (!categories) {
		return false;
	}

	const media = Array.from(EVENT_MEDIA.keys())[mediaIndex];

	const passesFilter = media === 'all'
		|| (media === 'static' && !isAnimated)
		|| (media === 'animated' && isAnimated);

	const category = Array.from(EVENT_CATEGORIES.keys())[categoryIndex];
	const categoryFilter = category === 'all' || (categories.includes(category));

	return categoryFilter && passesFilter;
});

// Sorts events by date.
const sortEvents = events => {
	// console.log("sortEvents", events)
	// Sort events by date.
	events.sort((a, b) => {
		try {
			// converting YYYY-MM-DD to MMDDYYYY. Example: 2023-01-06 to 01062023 for sorting
			const aSplit = a.date.split('-');
			const bSplit = b.date.split('-');
			const aDate = aSplit[1] + aSplit[2] + aSplit[0];
			const bDate = bSplit[1] + bSplit[2] + bSplit[0];

			if (aDate < bDate) {
				return 1;
			}

			return -1;
		} catch (e) {
			return 0;
		}
	});

	return events;
};

/**
 * Determine the type (either global or geoLocated).
 * @param {object} data
 * @param {string} data.placementMethod
 * @param {number} data.lat
 * @param {number} data.lon
 * @param {number} data.north_boundary
 * @param {number} data.south_boundary
 * @param {number} data.east_boundary
 * @param {number} data.west_boundary
 */
const determineType = ({
	placementMethod,
	lat, lon,
	north_boundary, south_boundary, east_boundary, west_boundary
}) => {
	/**
	 * Logic:
	 * If placementMethod is not provided:
	 * - if boundaries are provided, use geoLocated.
	 * - if offset is provided, use global.
	 * - otherwise, warn and use global.
	 * If placementMethod is provided:
	 * - if it's geoLocated but no boundaries are provided, error.
	 * - if it's global but no offset is provided, no probs.
	 */
	const hasBoundaries =
		typeof north_boundary === 'number'
		&& typeof south_boundary === 'number'
		&& typeof east_boundary === 'number'
		&& typeof west_boundary === 'number';
	const hasOffset = typeof lat === 'number' && typeof lon === 'number';

	if (!placementMethod) {
		if (hasBoundaries) {
			return { type: 'geoLocated' };
		}
		if (hasOffset) {
			return { type: 'global' };
		}
		return { type: 'global', warning: 'No placement method provided. Defaulting to global.' };
	}

	if (placementMethod === 'geoLocated' && !hasBoundaries) {
		return { error: 'Event preview data must include boundaries for geoLocated events.' };
	}

	return { type: placementMethod };
};

/**
 * Parses the event data into a format if animated and requires loading in the video manager.
 * We can also apply validation checks and return false if it fails.
 * @param {object} data
 * @param {object} param1
 * @param {boolean} param1.isTest
 * @param {boolean} param1.isPreview
 * @param {string} param1.previewPath
 * @returns {object|boolean}
 */
const parseEvent = (data = {}, { isTest = false, isPreview = false, previewEventFolder = '' } = {}) => {
	const {
		title,
		external_id,
		placementMethod,
		date,
		formatted_date,
		description,
		offset,
		north_boundary,
		south_boundary,
		east_boundary,
		west_boundary,
		center: centerStr,
		image_src,
		list_image_src,
		list_image_anim_src,
		imagecategory,
		zoom_factor,
		distance_from_event,
		images,
		animation_speed
	} = data;

	// Destructure the lat lon offsets.
	const [lat, lon] = offset || [0, 0];

	// Determine the type (either global or geoLocated).
	const { type, error, warning } = determineType({ placementMethod, lat, lon, north_boundary, south_boundary, east_boundary, west_boundary });

	if (error) {
		console.error(`Event Preview:: ${error}`);
		return false;
	}
	if (warning) {
		console.warn(`Event Preview:: ${warning}`);
	}

	// Determine if it's animated.
	const isAnimated = imagecategory.indexOf("animated") > -1;

	// Determine the src with the correct path.
	const REPLACE_STR = '$DATAAREA';
	const processSrc = image_src.includes(REPLACE_STR);

	const folder = 'earth';
	const livePreview = isPreview && !isTest;
	const srcEnd = image_src.split(REPLACE_STR).pop();
	const srcFolder = `/${folder}${srcEnd.charAt(0) === '/' ? '' : '/'}`;
	const previewFolder = previewEventFolder ? `/${previewEventFolder}/` : '';
	const previewFile = srcEnd.split('/').pop();

	// Apply source replace logic.
	let src = image_src;
	if (processSrc) {
		if (livePreview) {
			src = `${PREVIEW_PATH_LIVE}${previewFolder}${previewFile}`;
		} else {
			src = `${Config.dynamicAssetsUrl}${srcFolder}${srcEnd}`;
		}
	}

	// Process thumb srcs if they exist.
	const processThumbSrc = list_image_src?.includes(REPLACE_STR);
	const processThumbAnimSrc = list_image_anim_src?.includes(REPLACE_STR);
	const thumbSrc = processThumbSrc && list_image_src.replace(REPLACE_STR, folder);
	const thumbAnimSrc = processThumbAnimSrc && list_image_anim_src?.replace(REPLACE_STR, folder);

	// Parse the center numbers (although this is a simple calculation with the existing boundaries)
	const center = centerStr?.split(',').map(parseFloat);

	const eventData = {
		title,
		id: external_id,
		type,
		list_image_src,
		src,
		thumbSrc,
		thumbAnimSrc,
		isAnimated,
		date,
		formatted_date,
		description,
		categories: imagecategory?.split(','),
		...type === 'global' && { offset: { lat: MathUtils.degToRad(lat), lon: MathUtils.degToRad(lon) } },
		...type === 'geoLocated' && { boundaries: { north: north_boundary, south: south_boundary, east: east_boundary, west: west_boundary, center } },
		zoom_factor,
		distance_from_event,
		images,
		animation_speed
	};

	return eventData;
};

/**
 * Returns the year from the given old event parameter.
 * @param {string} eventParam format -> event/eo_event_221209
 * @returns {object}
 */
const getYearFromOldEventParam = eventParam => {
	if (typeof eventParam !== 'string') {
		return null;
	}

	const oldEventStr = 'event/';
	const oldEvent = eventParam.startsWith(oldEventStr);
	if (!oldEvent) {
		return null;
	}

	const oldEventName = oldEvent && eventParam.split(oldEventStr)[1];
	const oldEventYear = oldEventName?.split('_').pop().slice(0, 2);

	return oldEventName && oldEventYear
		? {
			oldEventName,
			oldEventYear: `20${oldEventYear}`
		}
		: null;
};

/**
 * Parses the event slug into a path format.
 * @param {string} eventSlug
 * @returns {string}
 */
const parseEventSlug = eventSlug => eventSlug.replaceAll('-', '/');

const getEventFromEventParam = eventParam => {
	const { events } = eventsStore.stateSnapshot;

	for (const yearEvents of Object.values(events)) {
		const event = yearEvents.find(event => event.urlParam === eventParam);
		if (event) {
			return event;
		}
	}

	return null;
};

/**
 * Returns the year from the given event parameter.
 * @param {string} eventParam
 * @returns {string}
 */
const getYearFromEventParam = eventParam => {
	const { events } = eventsStore.stateSnapshot;

	for (const year of Object.keys(events)) {
		const event = events[year].find(event => event.urlParam === eventParam);
		if (event) {
			return year;
		}
	}

	return null;
};

const getCategoryFromEventParam = eventParam => {
	const { events, categoryIndex } = eventsStore.stateSnapshot;

	const categoryParams = Array.from(EVENT_CATEGORIES.keys());
	const currentCategory = categoryParams[categoryIndex];

	for (const year of Object.keys(events)) {
		const event = events[year].find(event => event.urlParam === eventParam);
		if (event?.categories) {
			// Prioritize returning the current category if valid.
			if (event.categories.includes(currentCategory)) {
				return currentCategory;
			}
			// Otherwise find the first category that is in the categoryParams.
			const firstFoundCategory = event.categories.find(category => categoryParams.find(categoryParam => category.includes(categoryParam)));
			return firstFoundCategory ?? 'all';
		}
	}

	return null;
};

/**
 * Returns the event ID from the given event parameter.
 * @param {string} eventParam
 */
const getEventIdFromParam = eventParam => eventParam;

/**
 * Returns the event parameter from the given event title and year.
 * @param {object} event
 * @param {string} event.title
 * @param {Array<string>} existingEventUrls
 * @returns {string}
 */
const getEventParamFromEvent = ({id }, existingEventUrls) => {
	// console.log("getEventParamFromEvent", id)

	return id;
};

/**
	 * Enables the most recent event placemarks.
	 * @param {number} number
	 */
const getLimitedLatestEvents = (limit = EVENTS_LATEST_LIMIT) => {
	const { latestEvents } = eventsStore.stateSnapshot;

	return latestEvents?.filter((event, i) => i < limit);
};


/**
 * Load the texture of the given event.
 * @param {object} eventData
 */
const loadGeoLocatedStatic = async eventData => {
	const { pioneer } = globalRefs;
	const { src, boundaries: { north, south, east, west }, list_image_src,images,animation_speed } = eventData || {};

	const lowerLeftLLA = new LatLonAlt();
	const upperRightLLA = new LatLonAlt();
	const earth = pioneer.get('main', 'earth');
	const configUrl = Config.baseCe2s2Url;
	let srcPath = src ? `${configUrl}${src}` : `${configUrl}${list_image_src})`
	const patchComponent = earth.getComponent('patch');
	const earthSpheroidComponent = earth.getComponentByType('spheroidLOD');
	// Hide data patch.
	patchComponent.setVisible(false);

	// Set patch texture.
	patchComponent.setMapping('cylinder');

	// Handle animation 
	if (typeof animation_speed === 'number' && animation_speed > 0) {		
		if (Array.isArray(images) && images.length > 0) {
			const firstImageSrc = `${configUrl}${images[0].image_src}`; // First image
			window.eventIntervalIdx = 0;
	
			window.eventInterval = setInterval(() => {
				window.eventIntervalIdx = (window.eventIntervalIdx + 1) % images.length; // Cycle through images
				const currentImage = images[window.eventIntervalIdx];
	
				// Set image
				patchComponent.setTexture('color', `${configUrl}${currentImage.image_src}`);
				// Update the global state with the new date
				eventsStore.setGlobalState({ animatedDate: currentImage.date });
			}, animation_speed);
		} else {
			console.error("loadGeoLocatedStatic - Images array is empty.");
		}
	}

	patchComponent.setTexture('color', srcPath);

	console.log("North",north)
	console.log("South",south)
	console.log("East",east)
	console.log("West",west)

    // Boundary Adjustment Configuration for texture
	const valueForBoundary = 0;
	const northSouthDown = 0.111;

	let southBoundary = parseFloat(south);
	let northBoundary = parseFloat(north);
	let westBoundary = parseFloat(west);
	let eastBoundary = parseFloat(east);

	// Adjust boundaries if animation_speed is valid
	if (typeof animation_speed === 'number' && animation_speed > 0) {
		southBoundary -= northSouthDown + valueForBoundary;
		northBoundary -= northSouthDown - valueForBoundary;
		westBoundary -= valueForBoundary;
		eastBoundary += valueForBoundary;
	}

	// Set boundaries
	lowerLeftLLA.set( MathUtils.degToRad(southBoundary),MathUtils.degToRad(westBoundary),0,true);
	upperRightLLA.set(MathUtils.degToRad(northBoundary),MathUtils.degToRad(eastBoundary),0,true);

	// Apply the new bounds
	patchComponent.setBounds(lowerLeftLLA, upperRightLLA);
	// Enable the patch.
	patchComponent.forceTextureSize('color', 0);
	patchComponent.setEnabled(true);

	// Wait until patch is loaded.
	await SceneHelpers.waitTillEntitiesInPlace(pioneer.get('main'), ['earth']);
	await pioneer.waitUntilNextFrame();
	await earthSpheroidComponent.getLoadedPromise();
	await patchComponent.getLoadedPromise();

	// Show data patch.
	patchComponent.setVisible(true);
};


export {
	updateYearIndex,
	filterEvents,
	updateCategoryIndex,
	updateMediaIndex,
	sortEvents,
	parseEvent,
	parseEventSlug,
	getYearFromOldEventParam,
	getEventFromEventParam,
	getYearFromEventParam,
	getCategoryFromEventParam,
	getEventIdFromParam,
	getEventParamFromEvent,
	getLimitedLatestEvents,
	loadGeoLocatedStatic
};
