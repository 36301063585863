/** @module pioneer */
import {
	BaseController,
	Entity,
	EntityRef,
	Quaternion,
	Vector3
} from '../../internal';

/** A controller that rotates the position and orientation by the parent's orientation.
 *  Great for objects that are connected to their parents or landers.
 *  It needs to added as the last controller to work. */
export class RotateByEntityOrientationController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The entity whose orientation will be used.
		 * @type {EntityRef}
		 * @private
		 */
		this._entityRef = new EntityRef(this.getEntity().getScene());

		/**
		 * A flag that determines whether the controller is rotating the position of the entity.
		 * @type {boolean}
		 * @private
		 */
		this._rotatingPosition = true;

		/**
		 * A flag that determines whether the controller is rotating the orientation of the entity.
		 * @type {boolean}
		 * @private
		 */
		this._rotatingOrientation = true;

		// Let the base controller know that this changes these states.
		this.addModifiedState('position');
		this.addModifiedState('velocity');
		this.addModifiedState('orientation');
		this.addModifiedState('angularVelocity');
	}

	/**
	 * Sets the entity whose orientation will be used. If the name is '', the current parent will be used.
	 * @param {string} name
	 */
	setEntityForOrientation(name) {
		// Remove the dependency on the previous entity's orientation.
		if (this._entityRef.getName() !== '') {
			this.addDependentState(this._entityRef.getName(), 'orientation');
		}

		// Set the entity reference.
		this._entityRef.setName(name);

		// Add the dependency on the new entity's orientation.
		this.addDependentState(name, 'orientation');
	}

	/**
	 * Returns whether the controller is rotating the position of the entity. Defaults to true.
	 * @returns {boolean}
	 */
	isRotatingPosition() {
		return this._rotatingPosition;
	}

	/**
	 * Sets whether the controller is rotating the position of the entity.
	 * @param {boolean} rotatingPosition
	 */
	setRotatingPosition(rotatingPosition) {
		this._rotatingPosition = rotatingPosition;
		if (rotatingPosition) {
			this.addModifiedState('position');
			this.addModifiedState('velocity');
		}
		else {
			this.removeModifiedState('position');
			this.removeModifiedState('velocity');
		}
	}

	/**
	 * Returns true if the controller is rotating the orientation of the entity. Defaults to true.
	 * @returns {boolean}
	 */
	isRotatingOrientation() {
		return this._rotatingOrientation;
	}

	/**
	 * Sets whether the controller is rotating the orientation of the entity.
	 * @param {boolean} rotatingOrientation
	 */
	setRotatingOrientation(rotatingOrientation) {
		this._rotatingOrientation = rotatingOrientation;
		if (rotatingOrientation) {
			this.addModifiedState('orientation');
			this.addModifiedState('angularVelocity');
		}
		else {
			this.removeModifiedState('orientation');
			this.removeModifiedState('angularVelocity');
		}
	}

	/**
	 * Updates the position to be rotated by the parent orientation.
	 * @param {Vector3} position
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updatePositionAtTime(position, time) {
		if (this._rotatingPosition) {
			const entity = this._entityRef.getName() !== '' ? this._entityRef.get() : this.getEntity().getScene().getEntity(this.getEntity().getParentAtTime(time));
			if (entity !== null) {
				const entityOrientation = Quaternion.pool.get();
				entity.getOrientationAtTime(entityOrientation, time);
				position.rotate(entityOrientation, position);
				Quaternion.pool.release(entityOrientation);
			}
		}
	}

	/**
	 * Updates the velocity to be rotated by the parent orientation.
	 * @param {Vector3} velocity
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updateVelocityAtTime(velocity, time) {
		if (this._rotatingPosition) {
			const entity = this._entityRef.getName() !== '' ? this._entityRef.get() : this.getEntity().getScene().getEntity(this.getEntity().getParentAtTime(time));
			if (entity !== null) {
				const entityOrientation = Quaternion.pool.get();
				entity.getOrientationAtTime(entityOrientation, time);
				velocity.rotate(entityOrientation, velocity);
				Quaternion.pool.release(entityOrientation);
			}
		}
	}

	/**
	 * If the orientation is fixed, updates the orientation to the fixed orientation.
	 * @param {Quaternion} orientation
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updateOrientationAtTime(orientation, time) {
		if (this._rotatingOrientation) {
			const entity = this._entityRef.getName() !== '' ? this._entityRef.get() : this.getEntity().getScene().getEntity(this.getEntity().getParentAtTime(time));
			if (entity !== null) {
				const entityOrientation = Quaternion.pool.get();
				entity.getOrientationAtTime(entityOrientation, time);
				orientation.mult(entityOrientation, orientation);
				Quaternion.pool.release(entityOrientation);
			}
		}
	}

	/**
	 * Updates the controller.
	 * @override
	 * @internal
	 */
	__update() {
		const entity = this._entityRef.getName() !== '' ? this._entityRef.get() : this.getEntity().getParent();
		if (entity !== null) {
			if (this._rotatingPosition) {
				const position = Vector3.pool.get();
				position.rotate(entity.getOrientation(), this.getEntity().getPosition());
				this.getEntity().setPosition(position);
				Vector3.pool.release(position);
				const velocity = Vector3.pool.get();
				velocity.rotate(entity.getOrientation(), this.getEntity().getVelocity());
				this.getEntity().setVelocity(velocity);
				Vector3.pool.release(velocity);
			}
			if (this._rotatingOrientation) {
				const orientation = Quaternion.pool.get();
				orientation.mult(entity.getOrientation(), this.getEntity().getOrientation());
				this.getEntity().setOrientation(orientation);
				Quaternion.pool.release(orientation);
				const angularVelocity = Vector3.pool.get();
				angularVelocity.rotate(entity.getOrientation(), this.getEntity().getAngularVelocity());
				this.getEntity().setAngularVelocity(angularVelocity);
				Vector3.pool.release(angularVelocity);
			}
		}
	}
}
