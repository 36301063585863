/** @module pioneer */
import {
	BaseComponent,
	BaseController,
	CameraComponent,
	Collection,
	CollectionItem,
	Color,
	ComponentRef,
	DependencyGraph,
	Engine,
	Entity,
	LightSourceComponent,
	THREE
} from '../internal';

/**
 * The scene which contains all entities.
 * @extends {CollectionItem<Engine>}
 */
export class Scene extends CollectionItem {
	/**
	 * Constructs the scene.
	 * @param {string} type - the type of the scene (always 'scene');
	 * @param {string} name - the name of the scene
	 * @param {Engine} engine - the Pioneer engine
	 */
	constructor(type, name, engine) {
		super(type, name, engine);

		/**
		 * The collection of entities.
		 * @type {Collection<Entity, Scene>}
		 * @private
		*/
		this._entities = new Collection(this, new Map([['entity', Entity]]));

		/**
		 * The controller dependency graph.
		 * @type {DependencyGraph<BaseController>}
		 * @private
		 */
		this._controllerDependencyGraph = new DependencyGraph();

		/**
		 * The ambient light color. The amount of light when there are no light sources.
		 * @type {Color}
		 * @private
		 */
		this._ambientLightColor = new Color(0.02, 0.02, 0.02);
		this._ambientLightColor.freeze();

		/**
		 * The list of light sources.
		 * @type {Array<ComponentRef<LightSourceComponent>>}
		 * @private
		 */
		this._lightSources = [];

		/**
		 * The ThreeJS scene.
		 * @type {THREE.Scene}
		 * @private
		 */
		this._threeJsScene = null;

		// Initialize Three.JS
		this._threeJsScene = new THREE.Scene();

		// Setup the controller dependency graph functions.
		this._controllerDependencyGraph.setUpdateItemCallback((controller) => {
			if (controller.getEntity().isEnabled() && controller.getCoverage().contains(controller.getEntity().getScene().getEngine().getTime()) && controller.isEnabled()) {
				controller.__update();
			}
		});
		this._controllerDependencyGraph.setCompareItemCallback((a, b) => {
			if (a === b) {
				return false;
			}
			if (!a.getEntity().isEnabled() || !b.getEntity().isEnabled() || !a.isEnabled() || !b.isEnabled()) {
				return false;
			}
			const time = a.getEntity().getScene().getEngine().getTime();
			if (!a.getCoverage().contains(time) || !b.getCoverage().contains(time)) {
				return false;
			}

			// Ensure that later controllers in an entity are dependent on earlier controllers.
			if (a.getEntity() === b.getEntity()) {
				const entity = a.getEntity();
				for (let i = 0, l = entity.getNumControllers(); i < l; i++) {
					const controller = entity.getController(i);
					if (b === controller) {
						return true;
					}
					if (a === controller) {
						break;
					}
				}
			}

			// Children's controllers are dependent on parent's controllers for position and velocity.
			if (a.getEntity().getParent() === b.getEntity()) {
				if ((a.hasModifiedState('position') && b.hasModifiedState('position')) || (a.hasModifiedState('velocity') && b.hasModifiedState('velocity'))) {
					return true;
				}
				// A special case for 'parent' as a shortcut to whatever the parent is, since it happens so often.
				for (const modifiedState of b.__modifiedStates) {
					if (a.hasDependentState('parent', modifiedState)) {
						return true;
					}
				}
			}

			// Check if a's dependent states are in b's modified states.
			for (const modifiedState of b.__modifiedStates) {
				if (a.hasDependentState(b.getEntity().getName(), modifiedState)) {
					return true;
				}
			}

			return false;
		});
	}

	/**
	 * Returns the engine.
	 * @returns {Engine}
	 */
	getEngine() {
		return this.__getCollectionParent();
	}

	/**
	 * Gets an entity by name.
	 * @param {string|number} nameOrIndex - the name or index of the entity
	 * @returns {Entity}
	 */
	getEntity(nameOrIndex) {
		return this._entities.get(nameOrIndex);
	}

	/**
	 * Gets the number of entities.
	 * @returns {number}
	*/
	getNumEntities() {
		return this._entities.size;
	}

	/**
	 * Adds an entity.
	 * @param {string} name - the name of the entity to be added
	 * @returns {Entity}
	 */
	addEntity(name) {
		return this._entities.add('entity', name);
	}

	/**
	 * Removes an entity.
	 * @param {Entity|string|number} entityOrNameOrIndex - the entity, name, or index to be removed
	 */
	removeEntity(entityOrNameOrIndex) {
		this._entities.remove(entityOrNameOrIndex);
	}

	/**
	 * Moves an entity to another scene.
	 * @param {Entity|string|number} entityOrNameOrIndex - the entity, name, or index to be moved
	 * @param {Scene} scene - the other scene to which the entity will be moved
	 */
	moveEntity(entityOrNameOrIndex, scene) {
		const entity = this._entities.get(entityOrNameOrIndex);
		this._entities.move(entityOrNameOrIndex, scene._entities);
		// Update the controller dependency graph for both.
		for (let i = 0, l = entity.getNumControllers(); i < l; i++) {
			this._controllerDependencyGraph.removeItem(entity.getController(i));
			scene._controllerDependencyGraph.addItem(entity.getController(i));
		}
		// Update any light sources.
		for (let i = 0, l = entity.getNumComponents(); i < l; i++) {
			const component = entity.getComponent(i);
			if (component.getType() === 'lightSource' && component.getLoadState() === 'loaded') {
				for (let j = 0, k = this._lightSources.length; j < k; j++) {
					if (this._lightSources[j].getEntityName() === entity.getName()
						&& this._lightSources[j].getComponentTypeIndex() === component.getTypeIndex()) {
						this.__removeLightSource(entity.getName(), component.getTypeIndex());
						this.__addLightSource(entity.getName(), component.getTypeIndex());
						break;
					}
				}
			}
		}
	}

	/**
	 * Gets the entity, component, or controller described in the parameters. If the component/controller type is omitted, it will return the entity. It is a shortcut function to make things easier on the user. It returns undefined if it is not found.
	 * @param {string} entityNameOrIndex - the name or index of the entity
	 * @param {string?} componentOrControllerType - the type of the component or controller [optional]
	 * @param {number?} componentOrControllerTypeIndex - the index of the type, in case there are more than one of the same type [optional]
	 * @returns {Entity|BaseComponent|BaseController}
	 */
	get(entityNameOrIndex, componentOrControllerType = undefined, componentOrControllerTypeIndex = 0) {
		const entity = this._entities.get(entityNameOrIndex);
		if (componentOrControllerType === undefined || entity === null) {
			return entity;
		}
		return entity.get(componentOrControllerType, componentOrControllerTypeIndex);
	}

	/**
	 * Gets the controller dependency graph.
	 * @returns {DependencyGraph<BaseController>}
	 */
	getControllerDependencyGraph() {
		return this._controllerDependencyGraph;
	}

	/**
	 * Gets the ambient light color. Defaults to RGB(0.02, 0.02, 0.02).
	 * @returns {Color}
	 */
	getAmbientLightColor() {
		return this._ambientLightColor;
	}

	/**
	 * Sets the ambient light color.
	 * @param {Color} color
	 */
	setAmbientLightColor(color) {
		this._ambientLightColor.thaw();
		this._ambientLightColor.copy(color);
		this._ambientLightColor.freeze();
	}

	/**
	 * Adds a light source. Does nothing if it already is added. It should only be called by the LightSourceComponent.
	 * @param {string} entityName - The name of the entity that has the light source.
	 * @param {number} typeIndex - If there is more than one light source component on an entity, this specifies which one.
	 * @internal
	 */
	__addLightSource(entityName, typeIndex = 0) {
		// If there is already a light source of the given entity name and type index, do nothing.
		for (let i = 0, l = this._lightSources.length; i < l; i++) {
			const otherLightSource = this._lightSources[i];
			if (otherLightSource.getEntityName() === entityName
				&& otherLightSource.getComponentTypeIndex() === typeIndex) {
				throw new Error(`Light source on ${entityName} with type index ${typeIndex} already added.`);
			}
		}
		// Add the new light source.
		const lightSource = /** @type {ComponentRef<LightSourceComponent>} */(new ComponentRef(this));
		lightSource.setByType(entityName, 'lightSource', typeIndex);
		this._lightSources.push(lightSource);
	}

	/**
	 * Removes a light source. Does nothing if it already is removed. It should only be called by the LightSourceComponent.
	 * @param {string} entityName - The name of the entity that has the light source.
	 * @param {number} typeIndex - If there is more than one light source component on an entity, this specifies which one.
	 * @internal
	 */
	__removeLightSource(entityName, typeIndex = 0) {
		for (let i = 0, l = this._lightSources.length; i < l; i++) {
			const otherLightSource = this._lightSources[i];
			if (otherLightSource.getEntityName() === entityName
				&& otherLightSource.getComponentTypeIndex() === typeIndex) {
				// Remove the light source.
				this._lightSources.splice(i, 1);
				return;
			}
		}
		throw new Error(`Light source on ${entityName} with type index ${typeIndex} not found.`);
	}

	/**
	 * Gets the light source at index i.
	 * @param {number} i
	 * @returns {LightSourceComponent}
	 */
	getLightSource(i) {
		if (0 <= i && i < this._lightSources.length) {
			return this._lightSources[i].get();
		}
		return null;
	}

	/**
	 * Gets the number of light sources.
	 * @returns {number}
	 */
	getNumLightSources() {
		return this._lightSources.length;
	}

	/**
	 * Returns a new promise that resolves when every entity is loaded.
	 * @returns {Promise<void>}
	 */
	getLoadedPromise() {
		const promises = [];
		for (let i = 0, l = this._entities.size; i < l; i++) {
			promises.push(this._entities.get(i).getLoadedPromise());
		}
		return Promise.all(promises).then();
	}

	/**
	 * Cleans up the scene.
	 * @override
	 * @internal
	 */
	__destroy() {
		super.__destroy();

		this._entities.__destroy();
	}

	/**
	 * Returns the ThreeJS scene so that components can use it.
	 * @returns {THREE.Scene}
	 */
	getThreeJsScene() {
		return this._threeJsScene;
	}

	/**
	 * Updates the scene. Updates parents, is-in-coverages, and calls update on all entities' controllers using the dependency graph.
	 * @internal
	 */
	__update() {
		const currentTime = this.getEngine().getTime();
		for (let i = this._entities.size - 1; i >= 0; i--) {
			const entity = this._entities.get(i);
			entity.__updateLastState();
			entity.__updateParent(currentTime);
			entity.__updateIsInCoverages(currentTime);
		}

		this._controllerDependencyGraph.update();
	}

	/**
	 * Updates the camera-non-specific visual parts of the entities.
	 * @internal
	 */
	__updateVisuals() {
		for (let i = this._entities.size - 1; i >= 0; i--) {
			const entity = this._entities.get(i);
			if (entity.isInPositionCoverage() && entity.isEnabled()) {
				this._entities.get(i).__updateVisuals();
			}
		}
	}

	/**
	 * Removes the camera from any camera-dependent variables. Called by camera on clean up.
	 * @param {CameraComponent} camera
	 * @internal	*/
	__removeCameraDependents(camera) {
		for (let i = this._entities.size - 1; i >= 0; i--) {
			this._entities.get(i).__removeCameraDependents(camera);
		}
	}
}
