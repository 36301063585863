import React from 'react';

import FooterNav from './footer_nav';

import { datasetStore, videoStore, eventsStore, spacecraftStore, previewStore, poiStore, gmapStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';
import VITALS_DATA from '../data/vitals_data';
import ar from '../languages/ar';
import '../../www/assets/css/vital_signs.css';

import { getLimitedLatestEvents } from '../helpers/processEvents';

/**
 * TODO: This component can be simplified into a function and merged with footer nav.
 */
class VitalSigns extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedClass: 'selected'
		};

		// Binds
		this._renderItems = this._renderItems.bind(this);
		this._handlePoISelection = this._handlePoISelection.bind(this);
		this._handleGmapSelection = this._handleGmapSelection.bind(this);
		this.getRelatedDatasetsList = this.getRelatedDatasetsList.bind(this);
		// this.scrollToItem = this.scrollToItem.bind(this);
		this.language = ar;
	}

	componentDidMount() {
		this.vsUnsubscribe = datasetStore.subscribeTo('currentVS', () => this.forceUpdate());
		this.currentEventUnsubscribe = eventsStore.subscribeTo('currentEvent', () => this.forceUpdate());
		this.currentSpacecraftUnsubscribe = spacecraftStore.subscribeTo('currentSpacecraft', () => this.forceUpdate());
		this.videosUnsubscribe = videoStore.subscribeTo('currentVideo', currentVideo => {
			this.setState({ selectedClass: currentVideo?.type === 'global' ? '' : 'selected' });
		});
		this.eventPreviewUnsubscribe = previewStore.subscribeTo('previewEventData', () => this.forceUpdate());
	}

	componentWillUnmount() {
		typeof this.vsUnsubscribe === 'function' && this.vsUnsubscribe();
		typeof this.currentEventUnsubscribe === 'function' && this.currentEventUnsubscribe();
		typeof this.currentSpacecraftUnsubscribe === 'function' && this.currentSpacecraftUnsubscribe();
		typeof this.videosUnsubscribe === 'function' && this.videosUnsubscribe();
		typeof this.eventPreviewUnsubscribe === 'function' && this.eventPreviewUnsubscribe();
	}

	_renderItems() {
		const { latestEvents, currentEvent } = eventsStore.stateSnapshot;
		const { spacecraftId } = spacecraftStore.stateSnapshot;
		const { previewEventData } = previewStore.stateSnapshot;

		if (previewEventData) {
			return null;
		}

		if (spacecraftId) {
			// Todo: merge this.getRelatedDatasetsList with content manager's getRelatedDatasets - Task #3187.
			const relatedDatesets = this.getRelatedDatasetsList(spacecraftId);
			// Todo: No datasets currently available looks quite ugly. Perhaps displaying nothing would be better?
			// Is it really relevent to tell the user when there are not any related datasets?
			if (relatedDatesets.length === 0) {
				return ([
					<li
						key='none'
						data-value='none'
					>
						{this.language.no_datasets}
					</li>
				]);
			}
			return relatedDatesets.map(dataset => {
				const { vitalSignParam, datasetParam, title } = dataset;
				return (
					<li
						key={`related-${datasetParam}`}
						onClick={() => this._handleVitalClick(vitalSignParam, datasetParam)}
					>
						<a className='list-value'>
							{title}
						</a>
					</li>
				);
			});
		} else if (currentEvent && latestEvents) {
			const limitedLatestEvents = getLimitedLatestEvents();

			// If there are no latest events, return a single empty list item.
			if (!limitedLatestEvents?.length) {
				return ([
					<li
						key='none'
						data-value='none'
					/>
				]);
			}

			// If there are latest events, return a list of the latest events.
			return limitedLatestEvents.map(({ urlParam, title, id }) => (
				<li
					key={id}
					className={currentEvent.urlParam === urlParam ? 'selected' : ''}
					onClick={() => this._handleEventClick(urlParam)}
				>
					<a className='list-value'>{title}</a>
				</li>
			));
		} else {
			const { getManager } = globalRefs;
			const { vitalSignId } = getManager('content');
			const { isSatellitesNow } = spacecraftStore.stateSnapshot;
			const vitalValue = isSatellitesNow ? 'satellites' : vitalSignId;

			// filter out the vital signs that should go into the footer
			const filteredList = [...VITALS_DATA.filter(value => !value.hideFromFooter) || []];
			const { selectedClass } = this.state;

			return filteredList.map(({ id, value, title }) => (
				<li
					key={id}
					onClick={() => this._handleVitalClick(value)}
					className={vitalValue === value ? selectedClass : ''}
				>
					<a className='list-value'>
						{title}
					</a>
				</li>
			));
		}
	}

	_handleVitalClick(vitalSignParam, datasetParam) {
		if (!vitalSignParam) {
			return;
		}
		const { getManager } = globalRefs;
		getManager('route').navigateToDataset({ vitalSignParam, datasetParam });
	}

	_handleEventClick(eventParam) {
		if (!eventParam) {
			return;
		}
		const { getManager } = globalRefs;
		getManager('route').navigateToEvent(eventParam);
	}

	_handlePoISelection(poi) {
		const { getManager } = globalRefs;
		const poiId = poi?.currentTarget?.dataset?.value;
		if (poiId) {
			getManager('route').goToPoI(poiId);
		}
	}

	_handleGmapSelection(gmap) {
		const { getManager } = globalRefs;
		const gmapId = gmap?.currentTarget?.dataset?.value;
		if (gmapId) {
			getManager('route').goToGmap(gmapId);
		}
	}

	getRelatedVSList(missionName) {
		const { vitalSignsListRef } = this.props;
		const relatedDatasets = [];
		vitalSignsListRef.map(element => {
			for (let i = 0; i < element.dataset_groupings.length; i++) {
				for (let j = 0; j < element.dataset_groupings[i].datasets_sorted[0].missions.length; j++) {
					if (element.dataset_groupings[i].datasets_sorted[0].missions[j].external_id === missionName) {
						const dsGrouping = element.dataset_groupings[i];
						dsGrouping.vitalSign = element.value;
						dsGrouping.altid = i;
						dsGrouping.titleIndex = j;
						relatedDatasets.push(dsGrouping);
						break;
					}
				}
			}
		});
		return relatedDatasets;
	}

	getRelatedDatasetsList(missionId) {
		const relatedDatasets = [];

		VITALS_DATA.forEach(vitalData => {
			vitalData.datasetGroups.forEach(group => {
				const { missionIds, urlParam } = group.datasets[0];

				if (missionIds.includes(missionId)) {
					// { id, vitalSignId, altid, title }
					relatedDatasets.push({
						vitalSignParam: vitalData.value,
						datasetParam: urlParam,
						title: group.title
					});
				}
			});
		});

		return relatedDatasets;
	}

	getFooterTitle() {
		const { currentEvent } = eventsStore.stateSnapshot;
		const { currentSpacecraft } = spacecraftStore.stateSnapshot;
		const { previewEventData } = previewStore.stateSnapshot;

		if (currentSpacecraft) {
			return ar.dataset;
		}

		if (currentEvent) {
			return ar.latest_events;
		}

		if (previewEventData) {
			return ar.event_preview;
		}

		return '';
	}

	render() {
		const footerTitle = this.getFooterTitle();

		return (
			<div id='vital-signs'>
				{footerTitle && (
					<div className='title'>
						<div>
							{' '}
							{footerTitle}
							{' '}
						</div>
					</div>
				)}
				<FooterNav content={this._renderItems()} />
			</div>
		);
	}
}

export default VitalSigns;
