import React, { useCallback } from 'react';
import { useSnapshot } from 'valtio';

import { datasetStore, eventsStore, spacecraftStore, videoStore, previewStore, poiStore, gmapStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';

import '../../www/assets/css/latest_event.css';


const LatestEvent = () => {
	const { latestEvents, currentEvent } = useSnapshot(eventsStore.state);
	const { currentVideo } = useSnapshot(videoStore.state);
	const { currentPoi } = useSnapshot(poiStore.state);
	const { currentGmap } = useSnapshot(gmapStore.state);
	const { previewEventData } = useSnapshot(previewStore.state);
	const { isVisibleEarth } = useSnapshot(datasetStore.state);
	const { isSatellitesNow, currentSpacecraft } = useSnapshot(spacecraftStore.state);

	const latestEvent = latestEvents?.[0] ?? null;
	const { title, urlParam } = latestEvent || {};

	const showLatestEvent = (isVisibleEarth || isSatellitesNow)
	&& !currentEvent && !currentSpacecraft && !currentVideo && !previewEventData && latestEvent && !currentPoi && !currentGmap;

	const clickHandle = useCallback(() => {
		const { getManager } = globalRefs;
		// Navigate to event.
		getManager('route').navigateToEvent(urlParam);
	}, [urlParam]);

	const text = `آخر كارثة طبيعية : ${title} `;

	return showLatestEvent && (
		<div className='latest-event-container d-flex justify-content-center' onClick={clickHandle}>
			<h3 className='latest-event'>{text}</h3>
		</div>
	);
};

export default LatestEvent;
