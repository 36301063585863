import { useEffect } from 'react';

import { appStore, layersStore, kioskStore, lightStore, spacecraftStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';


const SatellitesNow = () => {
	useEffect(() => {
		const { getManager } = globalRefs;
		// Set isSatellitesNow in global state.
		spacecraftStore.setGlobalState({ isSatellitesNow: true });

		// Turn off fill light on mount.
		lightStore.setGlobalState({ fill: false });

		// Set vital sign in the content manager.
		getManager('content').setVitalSign('satellites');

		return () => {
			const { queries: { lighting: lightingFromURL } } = appStore.stateSnapshot;
			const { inAutoplay } = kioskStore.stateSnapshot;
			spacecraftStore.setGlobalState({ isSatellitesNow: false });

			// Turn on fill light on unmount if no lighting param in URL
			!inAutoplay && lightStore.setGlobalState({ fill: lightingFromURL ? lightingFromURL === 'flood' : true });

			// Make sure blue marble set to false when leaving.
			layersStore.setGlobalState({ blueMarble: false });
		};
	}, []);

	return null;
};

export default SatellitesNow;
