/** @module pioneer */

/**
 * A generic multi-level tile class that supports loading, unloading, activating, and deactivating of tiles of a subclass.
 * @template {Tile<TileType>} TileType
 * @private
 */
export class Tile {
	/**
	 * Constructor.
	 * @param {TileType | null} parent
	 */
	constructor(parent) {
		/**
		 * The parent tile.
		 * @type {TileType | null}
		 * @private
		 */
		this._parent = parent;

		/**
		 * The child tiles.
		 * @type {TileType[]}
		 * @private
		 */
		this._children = [];

		/**
		 * True if the tile has been activated.
		 * @type {boolean}
		 * @private
		 */
		this._active = false;

		/**
		 * True if the tile has been loaded.
		 * @type {boolean}
		 * @private
		 */
		this._loaded = false;

		/**
		 * True if the tile has been destroyed.
		 * @type {boolean}
		 * @private
		 */
		this._destroyed = false;

		/**
		 * True if this is in a transition (loading, splitting, joining, etc).
		 * @type {boolean}
		 * @private
		 */
		this._transitioning = false;
	}

	/**
	 * Gets the parent tile.
	 * @returns {TileType | null}
	 */
	getParent() {
		return this._parent;
	}

	/**
	 * Forces a tile to load. Useful for the root node that needs to start the process.
	 * @returns {Promise<void>}
	 */
	async forceLoad() {
		this._transitioning = true;
		if (!this._destroyed) {
			await this.load();
		}
		this._loaded = true;
		if (!this._destroyed) {
			await this.activate();
		}
		this._active = true;
		this._transitioning = false;
	}

	/**
	 * Checks the tile to see if needs to be split or join. Returns true if it is transitioning.
	 * @returns {boolean}
	 */
	check() {
		if (!this._destroyed && !this._transitioning) {
			if (this._children.length === 0 && this.checkSplit()) {
				this._split();
			}
			else if (this._children.length > 0 && this.checkJoin()) {
				this._join();
			}
		}

		return this._transitioning;
	}

	/**
	 * Gets the children.
	 * @returns {TileType[]}
	 */
	get children() {
		return this._children;
	}

	/**
	 * Returns a new Tile or null if the tile could not be created.
	 * @param {TileType} _parent
	 * @param {number} _row - 0 or 1
	 * @param {number} _col - 0 or 1
	 * @returns {TileType}
	 */
	createNewTile(_parent, _row, _col) {
		return null;
	}

	/**
	 * Returns true if this tile should be split.
	 * @abstract
	 * @returns {boolean}
	 */
	checkSplit() {
		return false;
	}

	/**
	 * Returns true if this tile should join its children.
	 * @abstract
	 * @returns {boolean}
	 */
	checkJoin() {
		return false;
	}

	/**
	 * Asynchronously loads the tile so that it may be used.
	 * @abstract
	 * @returns {Promise<void | void[]>}
	 */
	async load() {
	}

	/**
	 * Asynchronously unloads the tile.
	 * @abstract
	 * @returns {Promise<void>}
	 */
	async unload() {
	}

	/**
	 * Asynchronously activates the tile.
	 * @abstract
	 * @returns {Promise<void>}
	 */
	async activate() {
	}

	/**
	 * Asynchronously deactivates the tile.
	 * @abstract
	 * @returns {Promise<void>}
	 */
	async deactivate() {
	}

	/**
	 * Destroys the tile and all of its children.
	 */
	destroy() {
		this._destroyed = true;
		for (let i = 0; i < this._children.length; i++) {
			this._children[i].destroy();
		}
		if (!this._transitioning) {
			if (this._active) {
				this.deactivate().then(() => {
					this.unload();
				}).catch((error) => {
					throw error;
				});
			}
			else if (this._loaded) {
				this.unload();
			}
		}
	}

	/**
	 * Splits a tile all the way down and only loads the leaf tiles.
	 * @private
	 */
	_split() {
		/** @type {Array<Promise<void>>} */
		const loadedPromises = [];
		/** @type {TileType[]} */
		const newTiles = [];

		// Create and load all of the tiles.
		this._splitAndLoad(loadedPromises, newTiles);

		// Wait till all of the tiles are loaded, then activate them.
		Promise.all(loadedPromises).then(() => {
			/** @type {Array<Promise<void>>} */
			const activatedPromises = [];
			for (let i = 0; i < newTiles.length; i++) {
				if (newTiles[i]._loaded && !newTiles[i]._destroyed) {
					activatedPromises.push(newTiles[i].activate().then(async () => {
						if (this._destroyed) {
							return this.deactivate().then(() => {
								this.unload();
							}).catch((error) => {
								throw error;
							});
						}
						newTiles[i]._active = true;
						newTiles[i]._transitioning = false;
					}).catch((error) => {
						throw error;
					}));
				}
				else {
					newTiles[i]._transitioning = false;
				}
			}
			if (this._active) {
				activatedPromises.push(this.deactivate().then(() => {
					this._active = false;
				}).catch((error) => {
					throw error;
				}));
			}
			return activatedPromises;
		// Wait till all of the tiles are activated and this is deactivated, then unload this.
		}).then(() => {
			if (this._loaded) {
				this.unload().then(() => {
					this._loaded = false;
					this._transitioning = false;
				}).catch((error) => {
					throw error;
				});
			}
			else {
				this._transitioning = false;
			}
		}).catch(() => {
			// The load of one or more tiles failed, so we abort this split.
			const promises = [];
			for (let i = 0; i < newTiles.length; i++) {
				if (newTiles[i]._active) {
					promises.push(newTiles[i].deactivate().then(() => newTiles[i].unload()));
				}
				else if (newTiles[i]._loaded) {
					promises.push(newTiles[i].unload());
				}
			}
			if (!this._loaded) {
				promises.push(this.load().then(() => this.activate()));
			}
			else if (!this._active) {
				promises.push(this.activate());
			}
			return Promise.all(promises);
		});
	}

	/**
	 * Joins a tile, deactivating, unloading, and removing all children.
	 * @private
	 */
	_join() {
		// First mark all of the descendant tiles as transitioning,
		// and if any were already transitioning, we don't do the join.
		if (this._checkIfDescendantsAreTransitioning()) {
			return;
		}

		// Make this and all descendant tiles transitioning.
		/** @type {TileType[]} */
		const tilesToUnload = [];
		this._transitioning = true;
		this._markDescendantsAsTransitioning(tilesToUnload);

		this.load().then(async () => {
			if (this._destroyed) {
				return this.unload();
			}
			this._loaded = true;
		}).then(() => {
			const promises = [];
			for (let i = 0; i < tilesToUnload.length; i++) {
				promises.push(tilesToUnload[i].deactivate().then(() => {
					tilesToUnload[i]._active = false;
				}).catch((error) => {
					throw error;
				}));
			}
			if (!this._destroyed) {
				promises.push(this.activate().then(async () => {
					if (this._destroyed) {
						return this.deactivate().then(() => {
							this.unload();
						}).catch((error) => {
							throw error;
						});
					}
					this._active = true;
				}).catch((error) => {
					throw error;
				}));
			}
			return Promise.all(promises);
		}).then(() => {
			const promises = [];
			for (let i = 0; i < tilesToUnload.length; i++) {
				promises.push(tilesToUnload[i].unload().then(() => {
					tilesToUnload[i]._loaded = false;
				}).catch((error) => {
					throw error;
				}));
			}
			return Promise.all(promises);
		}).then(() => {
			this._children = [];
			this._transitioning = false;
		}).catch((error) => {
			throw error;
		});
	}

	/**
	 * Creates and loads all of the required tiles.
	 * @param {Array<Promise<void>>} loadedPromises - the list of tiles that were loaded
	 * @param {TileType[]} newTiles - the list of tiles that were created (and possibly loaded)
	 * @private
	 */
	_splitAndLoad(loadedPromises, newTiles) {
		// Create and load all of the tiles.
		this._transitioning = true;
		for (let row = 0; row < 2; row++) {
			for (let col = 0; col < 2; col++) {
				// Construct the tile.
				// @ts-ignore - because it can't convert 'this' to 'TileType'.
				const tile = this.createNewTile(this, row, col);
				if (tile !== null) {
					this._children.push(tile);
					// Check to see if this tile needs to be split as well.
					if (tile.checkSplit()) {
						tile._splitAndLoad(loadedPromises, newTiles);
					}
					// Otherwise, load the tile.
					else {
						tile._transitioning = true;
						loadedPromises.push(tile.load().then(async () => {
							if (tile._destroyed) {
								return tile.unload();
							}
							tile._loaded = true;
						}));
					}
					newTiles.push(tile);
				}
			}
		}
	}

	/**
	 * Returns true if any descendant tiles are transitioning. Used by join to ensure that all joined tiles are not already transitioning.
	 * @returns {boolean}
	 * @private
	 */
	_checkIfDescendantsAreTransitioning() {
		for (let i = 0; i < this._children.length; i++) {
			const tile = this._children[i];
			if (tile._transitioning) {
				return true;
			}
			else {
				if (tile._checkIfDescendantsAreTransitioning()) {
					return true;
				}
			}
		}
		return false;
	}

	/**
	 * Marks all descendant tiles as transitioning. Assumes the _checkIfDescendantsAreTransitioning function has already been called.
	 * Used by join to ensure that nothing happens to the descendants during the join process.
	 * Also stores the active tiles for later deactivating and unloading.
	 * @param {TileType[]} tilesToUnload
	 * @private
	 */
	_markDescendantsAsTransitioning(tilesToUnload) {
		for (let i = 0; i < this._children.length; i++) {
			const tile = this._children[i];
			tile._transitioning = true;
			if (tile._active) {
				tilesToUnload.push(tile);
			}
			tile._markDescendantsAsTransitioning(tilesToUnload);
		}
	}
}
