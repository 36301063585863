/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	Color,
	Entity,
	MathUtils,
	Quaternion,
	ShaderChunkLogDepth,
	THREE,
	ThreeJsHelper,
	Vector2,
	Vector3
} from '../../internal';

/**
 * A 2D sprite in the X-Y plane relative to an entity.
 */
export class SpriteComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The url for the texture.
		 * @type {string}
		 * @private
		 */
		this._textureUrl = '';

		/**
		 * The size of the sprite. If a component is NaN, it uses the aspect ratio of the texture to determine that component's size.
		 * @type {Vector2}
		 * @private
		 */
		this._size = new Vector2(1, Number.NaN);
		this._size.freeze();

		/**
		 * The units of the size. It can be 'pixels' or 'km'.
		 * @type {string}
		 * @private
		 */
		this._sizeUnits = 'km';

		/**
		 * Each pixel in the texture is multiplied by this value.
		 * @type {Color}
		 * @private
		 */
		this._colorMultiplier = new Color(1, 1, 1, 1);
		this._colorMultiplier.freeze();

		/**
		 * The alignment of the sprite along the x-y plane.
		 * @type {Vector2}
		 * @private
		 */
		this._alignment = new Vector2(0.5, 0.5);
		this._alignment.freeze();

		/**
		 * The distance at which the sprite begins to fade (50% less than this number and it is completely gone).
		 * @type {number}
		 * @private
		 */
		this._fadeDistance = 0;

		/**
		 * A flag that determines if the sprite has transparent pixels.
		 * @type {boolean}
		 * @private
		 */
		this._transparent = false;

		/**
		 * A flag that determines if the sprite has blending mode.
		 * @type {string}
		 * @private
		 */
		this._blending = 'normal';

		/**
		 * A flag that determines if the sprite is a billboard (always facing the camera).
		 * @type {boolean}
		 * @private
		 */
		this._billboard = false;

		/**
		 * The render ordering of the sprite. Lesser numbers are rendered behind greater numbers.
		 * @type {number}
		 * @private
		 */
		this._renderOrder = 0;
	}

	/**
	 * Gets the url of the texture.
	 * @returns {string}
	 */
	getTextureUrl() {
		return this._textureUrl;
	}

	/**
	 * Sets the url of the texture.
	 * @param {string} url
	 */
	setTextureUrl(url) {
		this._textureUrl = url;
		this.resetResources();
	}

	/**
	 * Gets the size of the sprite.
	 * @return {Vector2}
	 */
	getSize() {
		return this._size;
	}

	/**
	 * Sets the size of the sprite. If a component is NaN, it uses the aspect ratio of the texture to determine that component's size.
	 * @param {Vector2} size
	 */
	setSize(size) {
		this._size.thaw();
		this._size.copy(size);
		this._size.freeze();
		this._updateSizeUniform();
	}

	/**
	 * Gets the units of the size. It can be 'pixels' or 'km'. Defaults to 'km'.
	 * @returns {string}
	 */
	getSizeUnits() {
		return this._sizeUnits;
	}

	/**
	 * Sets the units of the size. It can be 'pixels' or 'km'.
	 * @param {string} sizeUnits
	 */
	setSizeUnits(sizeUnits) {
		this._sizeUnits = sizeUnits;
		this._updateSizeUniform();
	}

	/**
	 * Gets the color multiplier of the sprite. Each pixel in the texture is multiplied by this color.
	 * @returns {Color}
	 */
	getColorMultiplier() {
		return this._colorMultiplier;
	}

	/**
	 * Sets the color multiplier of the sprite. Each pixel in the texture is multiplied by this color.
	 * @param {Color} colorMultiplier
	 */
	setColorMultiplier(colorMultiplier) {
		this._colorMultiplier.thaw();
		this._colorMultiplier.copy(colorMultiplier);
		this._colorMultiplier.freeze();
		ThreeJsHelper.setUniformColorRGBA(this.getThreeJsMaterials()[0], 'colorMultiplier', colorMultiplier);
	}

	/**
	 * Gets the sprite alignment on the X-Y plane. Defaults to being center aligned on both axes.
	 * @returns {Vector2}
	 */
	getAlignment() {
		return this._alignment;
	}

	/**
	 * Sets the alignment.
	 * @param {Vector2} alignment - the alignment to set
	 */
	setAlignment(alignment) {
		this._alignment.thaw();
		this._alignment.copy(alignment);
		this._alignment.freeze();
		ThreeJsHelper.setUniformVector2(this.getThreeJsMaterials()[0], 'origin', this._alignment);
	}

	/**
	 * Gets the distance at which the sprite begins to fade (50% less than this number and it is completely gone).
	 * @returns {number}
	 */
	getFadeDistance() {
		return this._fadeDistance;
	}

	/**
	 * Sets the distance at which the sprite begins to fade (50% less than this number and it is completely gone). Defaults to 0.
	 * @param {number} fadeDistance
	 */
	setFadeDistance(fadeDistance) {
		this._fadeDistance = fadeDistance;
	}

	/**
	 * Returns true if the sprite is a billboard (always facing the camera). Defaults to false.
	 * @returns {boolean}
	 */
	isBillboard() {
		return this._billboard;
	}

	/**
	 * Sets whether the sprite is a billboard (always facing the camera).
	 * @param {boolean} billboard
	 */
	setBillboard(billboard) {
		this._billboard = billboard;
	}

	/**
	 * Gets the transparency of the sprite. Determines whether or not the sprite has alpha values other than 0 or 1.
	 * @returns {boolean}
	 */
	getTransparent() {
		return this._transparent;
	}

	/**
	 * Sets whether or not the sprite has alpha values other than 0 or 1. Defaults to false.
	 * @param {boolean} transparent
	 */
	setTransparent(transparent) {
		this._transparent = transparent;
		ThreeJsHelper.setTransparent(this.getThreeJsMaterials()[0], this._transparent);
	}

	/**
	 * Sets blending mode. Mode is one of 'normal', 'additive', 'subtractive', 'multliply', 'custom', or 'none'. Defaults to 'normal'.
	 * @param {string} blending
	 */
	setBlending(blending) {
		this._blending = blending;
		ThreeJsHelper.setBlending(this.getThreeJsMaterials()[0], this._blending);
	}

	/**
	 * Gets the render ordering of the sprite. Lesser numbers are rendered behind greater numbers.
	 * @returns {number}
	 */
	getRenderOrder() {
		return this._renderOrder;
	}

	/**
	 * Sets the render ordering of the sprite. Lesser numbers are rendered behind greater numbers. Defaults to 0.
	 * @param {number} renderOrder
	 */
	setRenderOrder(renderOrder) {
		this._renderOrder = renderOrder;
		ThreeJsHelper.setRenderOrder(this.getThreeJsObjects()[0], this._renderOrder);
	}

	/**
	 * Updates the camera-dependent parts of the component.
	 * @param {CameraComponent} camera - the camera being used in the render
	 * @override
	 */
	__prepareForRender(camera) {
		// If the size units are in pixels, adjust the size accordingly.
		if (this._sizeUnits === 'pixels') {
			const size = Vector2.pool.get();
			const sizeMultiplier = this.getEntity().getExtentsRadius() / this.getEntity().getPixelSpaceExtentsRadius(camera);
			size.mult(this._size, sizeMultiplier);
			ThreeJsHelper.setUniformVector2(this.getThreeJsMaterials()[0], 'size', size);
			Vector2.pool.release(size);
		}

		// If it is a billboard, set the orientation to always face the camera.
		if (this.getThreeJsObjects().length > 0) {
			if (this._billboard) {
				ThreeJsHelper.setOrientationToBillboard(this.getThreeJsObjects()[0], this.getEntity(), camera);
			}
			else {
				ThreeJsHelper.setOrientationToEntity(this.getThreeJsObjects()[0], this.getEntity());
			}
		}

		// Set the alpha fade distance multiplier.
		if (this._fadeDistance > 0) {
			const posInSpriteFrame = Vector3.pool.get();
			const orientation = Quaternion.pool.get();
			const threeJsOrientation = this.getThreeJsObjects()[0].quaternion;
			orientation.copyFromThreeJs(threeJsOrientation);
			posInSpriteFrame.rotateInverse(this.getEntity().getOrientation(), this.getEntity().getCameraSpacePosition(camera));
			ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'alphaFadeMultiplier',
				MathUtils.lerp(0, 1, MathUtils.clamp01(2 * (Math.abs(posInSpriteFrame.z) / this._fadeDistance - 1) + 1)));
			Quaternion.pool.release(orientation);
			Vector3.pool.release(posInSpriteFrame);
		}

		// Set the Three.js object position the entity's camera-space position.
		ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects()[0], this.getEntity(), camera);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	async __loadResources() {
		// Load the texture.
		const texture = await ThreeJsHelper.loadTexture(this, this._textureUrl, false, false);

		// Check if the component has since stopped loading.
		if (this.getLoadState() !== 'loading') {
			ThreeJsHelper.destroyTexture(texture);
			return;
		}

		// Create the Three.js object.
		if (SpriteComponent._useCount === 0) {
			// Create the shared geometry as a square 0, 0 to 1, 1.
			SpriteComponent._threeJsGeometry = ThreeJsHelper.createGeometry([{ name: 'position', dimensions: 3 }, { name: 'uv', dimensions: 2 }], false);
			ThreeJsHelper.setVertices(SpriteComponent._threeJsGeometry, 'position', new Float32Array([0, 0, 0, 1, 0, 0, 1, 1, 0, 0, 1, 0]));
			ThreeJsHelper.setVertices(SpriteComponent._threeJsGeometry, 'uv', new Float32Array([0, 1, 1, 1, 1, 0, 0, 0]));
			ThreeJsHelper.setIndices(SpriteComponent._threeJsGeometry, new Uint16Array([0, 1, 2, 2, 3, 0]));

			// Create the shared material.
			SpriteComponent._threeJsMaterial = new THREE.ShaderMaterial({
				uniforms: {
					colorMultiplier: new THREE.Uniform(new THREE.Vector4(1.0, 1.0, 1.0, 1.0)),
					alphaFadeMultiplier: new THREE.Uniform(1),
					size: new THREE.Uniform(new THREE.Vector2(1.0, 1.0)),
					colorTexture: new THREE.Uniform(null),
					origin: new THREE.Uniform(new THREE.Vector2(0, 0)),

					...ShaderChunkLogDepth.ThreeUniforms
				},
				vertexShader: `
					uniform vec2 size;
					uniform vec2 origin;
					varying vec2 fUV;

					${ShaderChunkLogDepth.VertexHead}

					void main() {
						vec4 viewPosition = modelViewMatrix * vec4((position.x - origin.x) * size.x, (position.y - origin.y) * size.y, 0.0, 1.0);
						gl_Position = projectionMatrix * viewPosition;
						fUV = uv;

						${ShaderChunkLogDepth.Vertex}
					}`,
				fragmentShader: `
					precision highp float;

					uniform vec4 colorMultiplier;
					uniform float alphaFadeMultiplier;
					uniform sampler2D colorTexture;
					varying vec2 fUV;

					${ShaderChunkLogDepth.FragmentHead}

					void main(void) {
						gl_FragColor = texture2D(colorTexture, fUV);
						gl_FragColor *= colorMultiplier;
						gl_FragColor.a *= alphaFadeMultiplier;

						${ShaderChunkLogDepth.Fragment}
					}`,
				side: THREE.DoubleSide
			});
			ThreeJsHelper.setupLogDepthBuffering(SpriteComponent._threeJsMaterial);
		}
		SpriteComponent._useCount += 1;

		// Create the material.
		const material = SpriteComponent._threeJsMaterial.clone();
		this.getThreeJsMaterials().push(material);
		ThreeJsHelper.setTransparent(material, this._transparent);
		ThreeJsHelper.setBlending(material, this._blending);
		ThreeJsHelper.setUniformColorRGBA(material, 'colorMultiplier', this._colorMultiplier);
		ThreeJsHelper.setUniformNumber(material, 'alphaFadeMultiplier', 1);
		ThreeJsHelper.setUniformVector2(material, 'origin', this._alignment);
		ThreeJsHelper.setUniformTexture(material, 'colorTexture', texture);

		// Create the object.
		const object = ThreeJsHelper.createMeshObjectGivenGeometry(this, material, SpriteComponent._threeJsGeometry);
		this.getThreeJsObjects().push(object);
		ThreeJsHelper.setRenderOrder(object, this._renderOrder);

		this._updateSizeUniform();
	}

	/**
	 * Unloads the resources.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		// Destroy the material.
		ThreeJsHelper.destroyMaterial(this.getThreeJsMaterials()[0], true);
		// Remove the object from the scene.
		const object = this.getThreeJsObjects()[0];
		if (object.parent !== undefined) {
			object.parent.remove(object);
		}
		// If there are no more sprites, destroy the geometry and material.
		SpriteComponent._useCount -= 1;
		if (SpriteComponent._useCount === 0) {
			ThreeJsHelper.destroyGeometry(SpriteComponent._threeJsGeometry);
			ThreeJsHelper.destroyMaterial(SpriteComponent._threeJsMaterial, true);
		}
	}

	/**
	 * Updates the size of the sprite given the size of this component and the texture size.
	 * @private
	 */
	_updateSizeUniform() {
		if (this.getThreeJsMaterials().length > 0) {
			/** @type {THREE.Texture} */
			const texture = this.getThreeJsMaterials()[0].uniforms['colorTexture'].value;
			if (texture !== null) {
				const textureAspectRatio = texture.image.width / texture.image.height;
				const size = Vector2.pool.get();
				if (Number.isNaN(this._size.x)) {
					size.set(this._size.y * textureAspectRatio, this._size.y);
				}
				else if (Number.isNaN(this._size.y)) {
					size.set(this._size.x, this._size.x / textureAspectRatio);
				}
				else {
					size.set(this._size.x, this._size.y);
				}
				ThreeJsHelper.setUniformVector2(this.getThreeJsMaterials()[0], 'size', size);
				Vector2.pool.release(size);

				if (this._sizeUnits === 'km') {
					this.__setRadius(Math.max(size.x, size.y));
				}
				else { // pixels
					this.__setRadius(Number.POSITIVE_INFINITY);
				}
			}
		}
		else {
			if (this._sizeUnits === 'km') {
				if (Number.isNaN(this._size.x)) {
					this.__setRadius(this._size.y);
				}
				else if (Number.isNaN(this._size.y)) {
					this.__setRadius(this._size.y);
				}
				else {
					this.__setRadius(Math.max(this._size.x, this._size.y));
				}
			}
			else {
				this.__setRadius(Number.POSITIVE_INFINITY);
			}
		}
	}
}

/**
 * A global shared material, copied by sprites.
 * @type {THREE.ShaderMaterial}
 */
SpriteComponent._threeJsMaterial = null;

/**
 * A global shared geometry unit square with a corner at (0, 0), copied by sprites.
 * @type {THREE.BufferGeometry}
 */
SpriteComponent._threeJsGeometry = null;

/**
 * The count for the number of sprites used.
 * @type {number}
 */
SpriteComponent._useCount = 0;
