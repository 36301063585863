import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { eventsStore } from '../managers/globalState';
import { updateCategoryIndex } from '../helpers/processEvents';

import { EVENT_CATEGORIES } from '../config/constants';
import { useSnapshot } from 'valtio';


const EventCategory = () => {
	const { eventCategoryParam } = useParams();
	const { eventYears, yearIndex, events } = useSnapshot(eventsStore.state);
	const currentYear = eventYears && eventYears[yearIndex];
	const yearEvents = currentYear && events?.[currentYear];

	// useEffect to synchronize yearIndex with eventYearParam if necessary.
	useEffect(() => {
		if (!yearEvents) {
			return;
		}

		// Determine the new category index, or default to 0.
		const categories = Array.from(EVENT_CATEGORIES.keys());
		const newCategoryIndex = categories.includes(eventCategoryParam) ? categories.indexOf(eventCategoryParam) : 0;
		updateCategoryIndex(newCategoryIndex);
	}, [eventCategoryParam, yearEvents]);

	return <Outlet />;
};

export default EventCategory;
