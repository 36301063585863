import * as Pioneer from 'pioneer';
import { SceneHelpers } from 'pioneer-scripts';
import { datasetStore, layersStore, uiStore } from './globalState';
import globalRefs from './globalRefs';
import { dateToUTCString, getDatasetFromVitalsData, downloadXML } from '../helpers/tools';

class TileManager {
	/**
	 * Constructor
	 * @param {object} pioneer Pioneer engine
	 * @param {object} scene Pioneer main scene
	 * @param {string} uniqueId uniqueId for tile components
	 * @private
	 */
	constructor(uniqueId) {
		!uniqueId && console.error('Unique ID for TileManager is required');

		const { pioneer } = globalRefs;
		this.scene = pioneer.get('main');

		this.id = uniqueId;

		this.maxSet = '250m';
		this.animationLevel = 2;
		this.pausedLevel = Infinity;

		this.colormapEntries = null;

		this.wmtsDateMap = {};

		// Array of functions with boolean returns. This can be extended in other classes
		this.fallbacks = [];

		this.hide = this.hide.bind(this);
		this.preload = this.preload.bind(this);
		this.updateWMTS = this.updateWMTS.bind(this);
		this.createWMTS = this.createWMTS.bind(this);
		this.enableWMTS = this.enableWMTS.bind(this);
		this.updateIndividualWMTS = this.updateIndividualWMTS.bind(this);

		datasetStore.subscribeAll(this.updateWMTS);
	}

	/**
	 * Updates WMTS max level on a dataset store update
	 */
	updateWMTS() {
		const { getManager } = globalRefs;
		const { animations } = getManager('dataset');

		const { currentDataset } = datasetStore.stateSnapshot;
		const { externalId } = currentDataset || {};

		// dataset is changed
		if (externalId !== this.id || !animations) {
			this.hide(true);
			return;
		}

		this.preload();
	}

	updateIndividualWMTS(visible, index) {
		this.hide();
		this.earth = this.scene.get('earth');
		this.earth.getComponentByType('atmosphere')?.setEnabled(false);
		this.earth.getComponentByType('spheroidLOD')?.setEnabled(false);

		const key = `${this.id}_${index}`;
		const wmts = this.createWMTS(key, index);

		const keys = Object.keys(this.wmtsDateMap);
		for (let i = 0; i < keys.length; i += 1) {
			this.earth.getComponent(keys[i])?.setEnabled(false);
		}

		this.enableWMTS(wmts, visible);

		if (visible && wmts) {
			const { pioneer } = globalRefs;
			datasetStore.setGlobalState({ isTextureLoaded: false });
			SceneHelpers.waitTillEntitiesInPlace(this.scene, ['earth'])
				.then(() => pioneer.waitUntilNextFrame())
				.then(() => {
					// Set isTextureLoaded global state to false.
					datasetStore.setGlobalState({ isTextureLoaded: true });
					const { currentDataset } = datasetStore.stateSnapshot;
					const { externalId } = currentDataset || {};

					// in case dataset is changed
					externalId !== this.id && this.destroy();
				});
		}
	}

	hide(all = false) {
		const { getManager } = globalRefs;
		const { animations } = getManager('dataset');

		if (!animations) {
			return;
		}

		this.earth = this.scene.get('earth');
		if (all) {
			this.earth.getComponent(this.id)?.setVisible(false);
		}

		for (const i in animations) {
			this.earth.getComponent(`${this.id}_${i}`)?.setVisible(false);
		}
	}

	/**
	 * Set visibility of wmts. Only check for fallbacks if trying to enable wmts
	 * @param {object} wmts wmts component
	 * @param {boolean} enable enable or not
	 * @returns
	 */
	enableWMTS(wmts, enable) {
		if (!wmts) {
			return;
		}

		wmts.setVisible(enable);

		// check snapshot when trying to enable wmts
		if (enable) {
			this.checkSnapshot(wmts);
		}
	}

	/**
	 * Creating json for color map entries
	 */
	async createColormapEntries() {
		this.colormapEntries = null;

		if (this.colormapEntries || !this.colormapURL) {
			return;
		}

		try {
			const xml = await downloadXML(this.colormapURL);
			if (!xml) {
				return;
			}

			const colorMaps = xml.getElementsByTagName('ColorMaps')[0]?.children;
			for (let i = 0; i < colorMaps.length; i += 1) {
				for (let j = 0; j < colorMaps[i].children.length; j += 1) {
					const legend = colorMaps[i].getElementsByTagName('Legend')[0]?.children;
					for (let k = 0; k < legend.length; k += 1) {
						const tooltip = legend[k].getAttribute('tooltip');
						const rgb = legend[k].getAttribute('rgb');
						if (tooltip !== 'No Data') {
							this.colormapEntries = this.colormapEntries || {};

							const [red, green, blue] = rgb.split(',');
							const values = tooltip.split(' ');
							this.colormapEntries[red] = this.colormapEntries[red] || {};
							this.colormapEntries[red][green] = this.colormapEntries[red][green] || {};
							for (let h = 0; h < values.length; h += 1) {
								if (!isNaN(values[h])) {
									this.colormapEntries[red][green][blue] = parseFloat(values[h]);
									break;
								}
							}
						}
					}
				}
			}
		} catch (e) {
			console.warn('Warning in createColormapEntries: ', e);
		}
	}

	/**
	 * Calculate color data specific for this WMTS info
	 * @returns {Number} - value
	 */
	calculateDataFromColor() {
		const color = this.calculateColorFromRaycasting();

		// if there is no color or color is pure black
		let red = parseInt(color.r);
		let green = parseInt(color.g);
		let blue = parseInt(color.b);
		if (!color || red + green + blue === 0 || !this.colormapEntries) {
			return null;
		}

		if (this.colormapEntries[red]?.[green]?.[blue] !== undefined) {
			return this.colormapEntries[red][green][blue];
		}

		// if exact color match is not in color map entries, try 5 points up and down
		// also clamp between 0 and 255 values
		const wiggleRoom = 5;
		const direction = [-1, 1];

		for (let i = 0; i < direction.length; i += 1) {
			// find available red channel
			for (let j = 0; j < wiggleRoom; j += 1) {
				red = Math.min(255, Math.max(0, parseInt(color.r) + j * direction[i]));
				if (this.colormapEntries[red]) {
					break;
				}
			}

			// red channel is not found
			if (!this.colormapEntries[red]) {
				return null;
			}

			// find available green channel
			for (let j = 0; j < wiggleRoom; j += 1) {
				green = Math.min(255, Math.max(0, parseInt(color.g) + j * direction[i]));
				if (this.colormapEntries[red][green]) {
					break;
				}
			}

			// green channel is not found
			if (!this.colormapEntries[red][green]) {
				return null;
			}

			// find available blue channel
			for (let j = 0; j < wiggleRoom; j += 1) {
				blue = Math.min(255, Math.max(0, parseInt(color.b) + j * direction[i]));
				if (this.colormapEntries[red][green][blue] !== undefined) {
					return this.colormapEntries[red][green][blue];
				}
			}
		}

		return null;
	}

	/**
	 * Uses the ray casting from the current mouse position to read the color
	 * @returns {Array} - [red, green, blue]
	 */
	calculateColorFromRaycasting() {
		const cameraEntity = this.scene.get('camera');
		const earth = this.scene.getEntity('earth');
		const cameraComponent = cameraEntity.getComponentByClass(Pioneer.CameraComponent);

		// Get the cursor position into camera-space (as a normalize direction), which is what getRaycast requires.
		const normalSpacePosition = new Pioneer.Vector3();
		const pickDirection = new Pioneer.Vector3();
		const pixelSpaceCursorPosition = cameraComponent.getViewport().getEngine().getInput().getCursorPosition();
		cameraComponent.getViewport().getNormalSpacePositionFromPixelSpacePosition(normalSpacePosition, pixelSpaceCursorPosition);
		cameraComponent.getCameraSpacePositionFromNormalSpacePosition(pickDirection, normalSpacePosition);
		pickDirection.normalize(pickDirection);

		const results = cameraComponent.getRaycastColor(Pioneer.Vector3.Zero, pickDirection, earth);
		const color = results?.[0].color;
		return color;
	}

	preload() {
		const { getManager } = globalRefs;
		const { animations } = getManager('dataset');

		if (!animations || !Array.isArray(animations)) {
			return false;
		}

		for (let i = 0; i < animations.length; i += 1) {
			// gathering / setting up options
			const { layerId, type } = animations[i];

			if (type === 'wmts' && layerId) {
				const key = `${this.id}_${i}`;
				this.createWMTS(key, i);
			}
		}

		return true;
	}

	createWMTS(key, index) {
		const { getManager } = globalRefs;
		const { animations } = getManager('dataset');
		if (!animations?.[index]) {
			return null;
		}

		const data = {
			layerId: null,
			endpoint: null,
			minLevel: null,
			maxLevel: null,
			maxSet: null,
			time: null
		};

		const { currentDataset } = datasetStore.state;
		const { externalId } = currentDataset || {};
		const { vitalData } = getDatasetFromVitalsData(externalId);
		const { dateObject, layerId } = animations[index];

		data.time = dateObject;
		data.layerId = layerId;

		for (let i = 0; i < vitalData?.datasetGroups?.length; i += 1) {
			const { datasets } = vitalData.datasetGroups[i];
			for (let j = 0; j < datasets.length; j += 1) {
				if (datasets[j].layerId === layerId) {
					this.dataset = datasets[j];
					this.dataset.maxSet = this.dataset.maxSet || this.maxSet;
					this.dataset.minLevel = this.dataset.minLevel || this.animationLevel;
					this.dataset.maxLevel = this.dataset.maxLevel || Infinity;
					this.dataset.time = dateToUTCString(data.time);

					data.endpoint = this.dataset.endpoint;
					data.maxSet = this.dataset.maxSet;
					data.minLevel = this.dataset.minLevel;
					data.maxLevel = this.dataset.maxLevel;

					this.colormapURL = datasets[j].colormap;
				}
			}
		}

		this.earth = this.scene.get('earth');
		let wmts = this.earth.getComponent(key);

		// if index already exists, check it that index holds the proper date
		if (wmts && this.wmtsDateMap[dateToUTCString(data.time)] !== key) {
			this.wmtsDateMap[dateToUTCString(data.time)] = null;
			this.earth.removeComponent(key);
			wmts = null;
		}

		if (!wmts) {
			wmts = this.earth.addComponent('wmts', key);

			wmts.setLayer(data.layerId);
			wmts.setEndPoint(data.endpoint);
			wmts.setMinLevel(data.minLevel);
			wmts.setMaxLevel(data.maxLevel);
			wmts.setTileMatrixSet(data.maxSet);
			wmts.setDimensionValue('Time', dateToUTCString(data.time));

			this.wmtsDateMap[dateToUTCString(data.time)] = key;

			this.createColormapEntries();
		}

		return wmts;
	}

	/**
	 * Check if snapshot is available. Otherwise try fallbacks if given and than blue marble
	 * @param {object} wmts  current wmts component
	 * @returns
	 */
	async checkSnapshot(wmts) {
		if (!this.dataset?.template || !this.dataset?.time) {
			return;
		}

		uiStore.setGlobalState({ validData: true });
		const { template, time } = this.dataset;

		const url = template.replace('{YYYY-MM-DD}', time);

		let invalid = false;
		try {
			const response = await fetch(url, { method: 'HEAD' });
			invalid = response?.status !== 200;
		} catch (e) {
			invalid = true;
		}


		// if snapshot is accessible, stop here
		if (!invalid) {
			return;
		}

		const { currentDataset } = datasetStore.stateSnapshot;
		const { externalId } = currentDataset || {};

		// dataset already changed
		if (externalId !== this.id) {
			return;
		}

		for (let i = 0; i < this.fallbacks.length; i += 1) {
			if (typeof this.fallbacks[i] === 'function') {
				// fallback is successful
				if (this.fallbacks[i]()) {
					uiStore.setGlobalState({ validData: false });
					wmts.setVisible(false);
					return;
				}
			}
		}

		// if there is no fallback or none of them succeeded, go to blue marble
		layersStore.setGlobalState({ blueMarble: true });
		wmts.setVisible(false);
		uiStore.setGlobalState({ validData: false });
	}

	destroy() {
		const keys = Object.keys(this.wmtsDateMap);
		for (let i = 0; i < keys.length; i += 1) {
			const key = keys[i];
			const id = this.wmtsDateMap[key];

			if (id) {
				this.wmtsDateMap[key] = null;
				this.earth.removeComponent(id);
			}
		}
	}
}

export default TileManager;
