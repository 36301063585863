/** @module pioneer */
import {
	BaseComponent,
	Color,
	Entity
} from '../../internal';

/**
 * A light source. Does not produce any actual light per se, but is used by other shaders when referenced. Shaders can use the 'lightSourceColor' and 'lightSourcePos' uniforms, and call setUniforms() to use the light source.
 */
export class LightSourceComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The color of the light. Alpha is not used.
		 * @type {Color}
		 * @private
		 */
		this._color = new Color(1, 1, 1);
		this._color.freeze();

		/**
		 * The absolute magnitude of the light.
		 * @type {number}
		 * @private
		 */
		this._absoluteMagnitude = 1;

		// Make the light source work everywhere.
		this.__setRadius(Number.POSITIVE_INFINITY);
	}

	/**
	 * Gets the color. Defaults to white.
	 * @returns {Color}
	 */
	getColor() {
		return this._color;
	}

	/**
	 * Sets the color.
	 * @param {Color} color - the color to set
	 */
	setColor(color) {
		this._color.thaw();
		this._color.copy(color);
		this._color.freeze();
	}

	/**
	 * Gets the absolute magnitude. Defaults to 1.
	 * @returns {number}
	 */
	getAbsoluteMagnitude() {
		return this._absoluteMagnitude;
	}

	/**
	 * Sets the absolute magnitude.
	 * @param {number} absoluteMagnitude
	 */
	setAbsoluteMagnitude(absoluteMagnitude) {
		this._absoluteMagnitude = absoluteMagnitude;
	}

	/**
	 * Loads the light source, adding it to the scene.
	 * @returns {Promise<void | void[]>}
	 * @override
	 * @protected
	 */
	__loadResources() {
		this.getEntity().getScene().__addLightSource(this.getEntity().getName(), this.getTypeIndex());
		return Promise.resolve();
	}

	/**
	 * Unloads the light source, removing it from the scene.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		this.getEntity().getScene().__removeLightSource(this.getEntity().getName(), this.getTypeIndex());
	}

	/**
	 * Sets the type index. Overriden to ensure that the scene has the correct type index.
	 * @param {number} typeIndex
	 * @override
	 * @internal
	 */
	__setTypeIndex(typeIndex) {
		if (this.getLoadState() === 'loaded') {
			this.getEntity().getScene().__removeLightSource(this.getEntity().getName(), this.getTypeIndex());
		}
		super.__setTypeIndex(typeIndex);
		if (this.getLoadState() === 'loaded') {
			this.getEntity().getScene().__addLightSource(this.getEntity().getName(), this.getTypeIndex());
		}
	}
}
