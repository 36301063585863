import { useState, useEffect } from 'react';

import throttle from 'lodash.throttle';

const MIN_ITEMS_PER_ROW = 2;
const MAX_ITEMS_PER_ROW = Infinity;
const CHUNK_PADDING = 1;
const DEF_ITEM_SIZE = 200;
const DEF_ROW_PADDING = '2%';

/**
 * Calculates items per row given an item size and the screen width
 * @param {number} itemSize in pixels
 * @param {number} rowPadding left/right padding in pixels or percent (string with sign)
 * @returns {number}
 */
const calcItemsPerRow = (itemSize, rowPadding) => {
	let width = window.innerWidth
	|| document.documentElement.clientWidth
	|| document.body.clientWidth;

	if (typeof rowPadding === 'string' && rowPadding.includes('%')) {
		width -= (parseFloat(rowPadding) / 100) * width * 2;
	} else if (typeof rowPadding === 'number') {
		width -= rowPadding * 2;
	}

	// round down to always keep within the screen width
	const numItemsPerRow = Math.floor(width / itemSize);

	return Math.max(Math.min(numItemsPerRow, MAX_ITEMS_PER_ROW), MIN_ITEMS_PER_ROW);
};

/**
 * determines chunk size by calculating itemsPerColumn and multiplying by itemsPerRow
 * @param {number} itemSize pixels
 * @param {number} itemsPerRow
 * @returns {number}
 */
const calcChunkSize = (itemSize, itemsPerRow) => {
	const height = window.innerHeight
	|| document.documentElement.clientHeight
	|| document.body.clientHeight;

	// round up and plus CHUNK_PADDING as we want to over estimate the chunk size to force a scroll
	const numItemsPerColumn = Math.ceil(height / itemSize) + CHUNK_PADDING;

	return itemsPerRow * numItemsPerColumn;
};

/**
 * Given a certain item size, this hook dynamically returns the itemsPerRow and chunkSize
 * after screen resize events. Throttling the handler prevents it firing unnecessarily often
 * @param {number} itemSize pixels
 * @param {number} rowPadding left/right padding in pixels or percent (string with sign)
 * @returns {Array}
 */
export default function useGridDimensions(itemSize = DEF_ITEM_SIZE, rowPadding = DEF_ROW_PADDING) {
	const [itemsPerRow, setItemsPerRow] = useState(calcItemsPerRow(itemSize, rowPadding));

	const [chunkSize] = useState(() => calcChunkSize(itemSize, itemsPerRow));

	useEffect(() => {
		// create throttled handler
		const onResizeThrottled = throttle(() => {
			const newItemsPerRow = calcItemsPerRow(itemSize, rowPadding);
			setItemsPerRow(newItemsPerRow);
		}, 200);
		// set resize listener
		window.addEventListener('resize', onResizeThrottled);

		return () => {
			// remove resize listener
			window.removeEventListener('resize', onResizeThrottled);
		};
	}, [itemSize]);

	return [itemsPerRow, chunkSize];
}
