import React, { useCallback, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import Image from './image';
import { isLanguageCelcius } from '../helpers/tools';

import '../../www/assets/css/color_bar.css';
import DataValueRect from '../../www/assets/images/data_value_rect.svg';
import ar from '../languages/ar';
import { datareadStore, datasetStore, appStore, videoStore, uiStore } from '../managers/globalState';
import ColorBarHtml, { PrecipitationColorBar, SingleHtmlColorBar } from './color_bar_html';

/**
 * Calculates the color reactangle style / positioning.
 * @param {number|null} percent
 * @param {boolean} isDoubleColorBar
 * @param {boolean} isUpper
 */
const setColorRectStyle = (percent, isDoubleColorBar = false, isUpper = true) => {
	const isValid = percent !== null;
	let colorRectStyle = null;

	if (isValid) {
		const colorBarPercent = isDoubleColorBar ? 60.9 : 71;
		const horizontalOffsetValue = isDoubleColorBar ? 25 : 13;
		const verticalOffsetValue = isDoubleColorBar ?
			(isUpper ? 15 : 76) :
			13;

		// percentage between 18% and 82% to fit actual color bar in color bar image.
		// Math.round(number * 100)/100 is used to trim the value to two decimal places.
    // * -1 to flip right to left
    //13 to 83
		const leftValue = horizontalOffsetValue + (Math.round(percent * colorBarPercent * 100) / 100) ;

		colorRectStyle = {
			display: 'table',
			position: 'absolute',
			left: `${leftValue.toString()}%`,
			top: `${verticalOffsetValue.toString()}px`
		};
	}

	// Set global store.
	datareadStore.setGlobalState({ colorRectStyle });
};

const ColorBarImage = ({ showDataRect = true }) => {
	// Get global state info.
	const { colorRectStyle, isCelcius } = useSnapshot(datareadStore.state);
	const { isVideoTexture, currentVideo } = useSnapshot(videoStore.state);
	const { colorBarSrc: videoColorBarSrc } = currentVideo || {};

	const { currentDataset } = useSnapshot(datasetStore.state);

	// Define variables and color bar source.
	let temperature = currentDataset?.temperatureData;

	let colorBarIndex = temperature && isCelcius ? 1 : 0;

	let colorBarSrc = currentDataset?.colorBarSrc?.[colorBarIndex] ?
		`assets/images/arabic_colorbars/${currentDataset.colorBarSrc[colorBarIndex]}`
		: null;

	if (isVideoTexture) {
		temperature = videoColorBarSrc?.includes('surfaceDayTemp_bar.png');
		colorBarIndex = temperature && isCelcius ? 1 : 0;
		colorBarSrc = currentVideo?.colorBarSrc?.[colorBarIndex] ?
			`assets/images/arabic_colorbars/${currentVideo.colorBarSrc[temperature ? colorBarIndex : 0]}`
			: null;
	}

	const colorBarText = temperature ? ar['toggle_units'] : '';
	const clickable = temperature;

	const showDataReadRect = showDataRect && colorRectStyle && !isVideoTexture;

	useEffect(() => {
		const celsius = isLanguageCelcius();
		datareadStore.setGlobalState({ isCelcius: celsius });
	}, []);

	const onClick = useCallback(() => {
		const newIsCelcius = !isCelcius;

		datareadStore.setGlobalState({ isCelcius: newIsCelcius });
	}, [isCelcius]);

	const colorBarShowing = (currentDataset || isVideoTexture) && colorBarSrc !== null && colorBarSrc !== undefined;
	datareadStore.setGlobalState({ isColorBarShowing: colorBarShowing });

	return colorBarShowing ?
		(
			<div className={`color-bar ${clickable ? 'clickable' : ''}`} onClick={clickable ? onClick : null}>
				{ showDataReadRect && <span style={colorRectStyle}><DataValueRect /></span> }
				<Image key={colorBarSrc} className='img-fluid no-drag' src={colorBarSrc} alt='Color bar' />
				{ colorBarText && <button className='toggle-units'>{colorBarText}</button> }
			</div>
		)
		: null;
};

/**
 * Decides whether to renderr the standard ColorBar (now named ColorBarImage), or the ColorBarHtml
 * Todo:
 * see if we can reduce the number of renders.
 * reduce prop drilling of showDataRect?
 *
 */
const ColorBar = props => {
	const { noContainer } = props;
	const { currentDataset } = useSnapshot(datasetStore.state);
	const { currentVideo } = useSnapshot(videoStore.state);
	const { isDateTooltipOpen } = useSnapshot(uiStore.state);
	const { isMobile } = useSnapshot(appStore.state);

	if (!currentDataset) {
		return null;
	}

	const bottomMargin = isMobile ? ('spacing-bottom' + (isDateTooltipOpen ? '-expanded' : '')) : '';
	const renderColorBar = () => {
		const { externalId, htmlColorBar, singleHtmlColorBar } = currentDataset;

		// Unique component for precipitation color bar.
		if (externalId === 'precipitationToday' && !currentVideo) {
			return <PrecipitationColorBar {...props} />;
		}

		if (htmlColorBar || singleHtmlColorBar) {
			return (
				<div className={`legend-html-container ${noContainer ? bottomMargin : ''}`}>
					{htmlColorBar && <ColorBarHtml {...props} />}
					{singleHtmlColorBar && <SingleHtmlColorBar {...props} />}
				</div>
			);
		}

		return <ColorBarImage {...props} />;
	};

	return (
		noContainer
			? renderColorBar() :
			<div id='legend-container' className={`legend-container ${bottomMargin}`}>
				{renderColorBar()}
			</div>
	);
};

export default ColorBar;
export { setColorRectStyle };
