import ar from '../languages/ar';
/**
 * Exported default variables.
 */

/**
 * PAGE URLS
 */
const VITAL_SIGNS_PAGE = '/vital-signs';
const VIDEOS_PAGE = `${VITAL_SIGNS_PAGE}/videos`;
const SPACECRAFT_PAGE = '/satellites';
const EVENTS_PAGE = '/events';
const GMAP_PAGE = '/gmap';
const POI_PAGE = '/poi';
const HOME_PAGE = '/';
const STELLAR_PAGE = '/stellar'

/**
 * EVENTS
 */
const EVENT_CATEGORIES = new Map([
	['all', 'كل الأنواع'],
	['fires', 'الحرائق'],
	['storms', 'العواصف'],
	['ice', 'الثلج'],
	['water', 'الماء'],
	['land', 'الأرض'],
	['oceans', 'المحيطات'],
    ['bigevents', 'الأحداث الكبيرة']
]);
const POI_CATEGORIES = [
	['all', 'كل الأنواع'],
	['religious', 'دينية'],
	['tourism', 'سياحية'],
	['geographical', 'جغرافية'],
	['agricultural', 'زراعية'],
	['3dimages', 'صور ثلاثية الأبعاد']
];
const GMAP_CATEGORIES = [
	['all', 'كل الأنواع'],
	['geographical', 'جغرافية']
];
const EVENT_MEDIA = new Map([['all', 'كل الوسائط'], ['static', 'ثابتة'], ['animated', 'متحركة']]);
// const EVENTS_YEAR_INDEX = null; // to trigger initial state update
// const EVENTS_CATEGORY_INDEX = 0;
// const EVENTS_SHOW_ON_GLOBE = false;
const EVENTS_SHOW_LATEST = true;
const EVENTS_LATEST_LIMIT = 3;
const LIGHT_STATUS = true;
const CITY_NAME_STATUS = false;
const GROUND_TRACK_STATUS = false;

const POI_YEAR_INDEX = 0;
const POI_CATEGORY_INDEX = 0;
const POI_SHOW_LATEST = true;
const POI_LATEST_LIMIT = 3;

const GMAP_YEAR_INDEX = 0;
const GMAP_CATEGORY_INDEX = 0;
const GMAP_SHOW_LATEST = true;
const GMAP_LATEST_LIMIT = 3;

/**
 * EVENT PREVIEWS
 */
const PREVIEW_PATH_LIVE = 'https://blackhawk3.jpl.nasa.gov/assets/dynamic/earth_events';

/**
 * MISSIONS
 */
const MISSION_CATEGORIES = [['All Types', 'كل الأنواع'], ['Atmosphere', 'البعثات الفضائية'], ['Sea', 'البحر'], ['Land', 'الأرض']];
const MISSION_TIME_GROUPS = [['Current', 'الحالية'], ['Future', 'المستقبلية']];
const MISSION_TIME_GROUP_CATEGORIES = {
	Current: 'operating',
	Future: 'planned'
};
const MISSION_TIMES = {
	CURRENT: 'الوقت في المدار: ',
	FUTURE: 'الإطلاق المتوقع: '
};
const MISSIONS_TIME_GROUP_INDEX = 0;
const MISSIONS_CATEGORY_INDEX = 0;

/**
 * VITALS
 */
// const VITAL_CATEGORIES = ['المؤشرات الرئیسیة'];
const VITAL_CATEGORIES = [['Key indicators', 'المؤشرات الرئیسیة'], ['Data Animations & Graphics', 'الرسوم المتحركة للبيانات والرسوم البيانية']];
const VITALS_CATEGORY_INDEX = 0;

/**
 * VISIBLE EARTH
 */

// Max number of frames we can have displaying WMTS tiling.
const MAX_WMTS_FRAMES = 7;
// Max num of times we should check WMTS fallbacks, blackness and images, before resorting to viirs cubemaps.
const MAX_DEEP_CHECKS = 14;

// Unknown: how optimal is this server to return images after the request.
// If we really want to get the fastest result, we can save every single image on our end but it might be too much.
const WMTS_MAX_LEVEL = Infinity;
const WMTS_MAX_LEVEL_AUTOPLAY = 4;


/**
 * SPOUT
 */
const SPOUT_ENABLE = false;
const SPOUT_RENDER_WIDTH = 2048;
const SPOUT_PROJECT_TO_GLOBE = false;
const SPOUT_FONT_SIZE = 24;
const SPOUT_LON_ANGLE_OFFSET = 0;
const SPOUT_ALIGN_TO_NORTH_POLE = false;


const POI_SHOW_ON_GLOBE = false;

const GMAP_SHOW_ON_GLOBE = false;

export {
	VITAL_SIGNS_PAGE,
	VIDEOS_PAGE,
	SPACECRAFT_PAGE,
	EVENTS_PAGE,
	HOME_PAGE,

	EVENT_CATEGORIES,
	EVENT_MEDIA,
	EVENTS_SHOW_LATEST,
	EVENTS_LATEST_LIMIT,

	LIGHT_STATUS,
	CITY_NAME_STATUS,
	GROUND_TRACK_STATUS,

	PREVIEW_PATH_LIVE,

	MISSION_CATEGORIES,
	MISSION_TIME_GROUPS,
	MISSION_TIME_GROUP_CATEGORIES,
	MISSION_TIMES,
	MISSIONS_TIME_GROUP_INDEX,
	MISSIONS_CATEGORY_INDEX,

	VITAL_CATEGORIES,
	VITALS_CATEGORY_INDEX,

	MAX_WMTS_FRAMES,
	MAX_DEEP_CHECKS,
	WMTS_MAX_LEVEL,
	WMTS_MAX_LEVEL_AUTOPLAY,

	SPOUT_ENABLE,
	SPOUT_RENDER_WIDTH,
	SPOUT_PROJECT_TO_GLOBE,
	SPOUT_FONT_SIZE,
	SPOUT_LON_ANGLE_OFFSET,
	SPOUT_ALIGN_TO_NORTH_POLE,
	POI_SHOW_ON_GLOBE,
	POI_CATEGORIES,
	POI_YEAR_INDEX,
	POI_CATEGORY_INDEX,
	POI_SHOW_LATEST,
	POI_LATEST_LIMIT,

	GMAP_SHOW_ON_GLOBE,
	GMAP_CATEGORIES,
	GMAP_YEAR_INDEX,
	GMAP_CATEGORY_INDEX,
	GMAP_SHOW_LATEST,
	GMAP_LATEST_LIMIT,
	GMAP_PAGE,
	POI_PAGE,
	STELLAR_PAGE
};
