/* eslint-disable react/jsx-no-bind */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { useSnapshot } from 'valtio';

import { appStore, uiStore } from '../../managers/globalState';
import globalRefs from '../../managers/globalRefs';

import Overlay from '../overlays';
import GridList from '../grid_list';
import Filter from '../filter';

import LayerGroupSolidSVG from '../../../www/assets/images/layers.svg';
import ArrowUpDown from '../../../www/assets/images/arrow-nav-filled.svg';

import useDimensions from '../../hooks/useDimensions';

import '../../../www/assets/css/overlays/vitals.css';

import {
	VITAL_CATEGORIES,
	VITALS_CATEGORY_INDEX
} from '../../config/constants';
import VIDEOS_DATA, { TYPE_TITLES } from '../../data/videos_data';
import VITALS_DATA from '../../data/vitals_data';

/**
 * Constant vars
 */
const THUMB_WIDTH = 343;
const THUMB_HEIGHT = 337;
const TITLE_TEXT = 'العلامات الحيوية';
const Category_AR = [VITAL_CATEGORIES[0][1], VITAL_CATEGORIES[1][1]];


// TODO: While this is a valid way to declare the nested vital row object,
// it is prefered to keep our method of declaration consistent with other components for example footer.js
const NestedVitalRow = props => {
	const { nested, title, onItemClick, overlayData, scrollableRef, isExpanded } = props;
	const [open, setOpen] = useState(isExpanded || false);

	return (
		<div className='vital-mobile-row flex-grid-item fadein' style={{ padding: '15px' }}>
			<h6
				className='vital-title'
				onClick={() => {
					setOpen(!open);
				}}
			>
				{title}
				<ArrowUpDown className={open ? 'vital-arrow-up' : 'vital-arrow-down'} />
			</h6>
			{open && (
				<GridList
					rowClassName='vital-mobile-row nested'
					maxPerRow={1}
					dataList={overlayData}
					itemPadding={[10, 10]}
					itemComponent={<VitalItem isOpen={open} nested={nested} onItemClick={onItemClick} />}
					scrollableRef={scrollableRef}
					nested
				/>
			)}
		</div>
	);
};

/**
 * Individual VitalItem component to represent each vital in the grid
 */
const VitalItem = props => {
	const { isOpen, nested, sphereMapThumbSrc, title, type, value, urlParam, description, shortDescription, datasetGroups, onItemClick } = props;
	const { isMobile } = useSnapshot(appStore.state);
	const [open, setOpen] = useState(isOpen);

	// get dimensions hook
	const [ref] = useDimensions(true);

	const clickArg = value || urlParam;

	// click handler
	const onClick = useCallback((vitalSignParam, urlParam) => e => {
		onItemClick(vitalSignParam, urlParam);
	}, [onItemClick]);

	// calculate vars
	const imgSrcUrl = `assets/images/graphics/${sphereMapThumbSrc}`;
	const imgClasses = value === 'satellites' ? 'thumbnail' : 'thumbnail-dataset';
	const topContent = () => (
		// \u00a0 is a jsx friendly &nbsp;
		<span className='vital-dataset-title'>
			{datasetGroups?.length > 0 ? `${datasetGroups[0].title}\u00a0›` : title}
		</span>
	);

	// create dataset content if we having groupings
	const datasetContent = () => {
		if (datasetGroups?.length > 1) {
			return (
				<div className='vital-datasets'>
					{
						datasetGroups.map((group, i) => {
							if (i === 0) {
								return null;
							}
							const { urlParam: datasetParam } = group.datasets?.[0] || {};
							// \u00a0 is a jsx friendly &nbsp;
							return (
								<span
									key={`key-${group.id}`}
									className='vital-dataset-subtitle'
									onClick={onClick(value, datasetParam)}
								>
									{`${group.title}\u00a0›`}
								</span>
							);
						})
					}
				</div>
			);
		}
		return null;
	};

	const descriptionText = shortDescription || description || '';

	if (isMobile) {
		return (
			<div>
				{
					!nested && (
						<h6
							className='vital-title'
							onClick={() => {
								setOpen(!open);
								onClick(clickArg);
							}}
						>
							{title}
							{datasetGroups?.length > 0 && <ArrowUpDown className={open ? 'vital-arrow-up' : 'vital-arrow-down'} />}
						</h6>
					)
				}

				{
					open && (
						<div style={{ padding: '10px 0px 10px 20px' }}>
							<div className='row-open clickable'>
								<div onClick={onClick(clickArg)}>
									{topContent()}
									<p ref={ref} className='vital-description'>{descriptionText}</p>
								</div>
								<img
									className={imgClasses}
									width={`${THUMB_WIDTH}px`}
									height={`${THUMB_HEIGHT}px`}
									src={imgSrcUrl}
									alt={title}
									onClick={onClick(clickArg)}
								/>
							</div>
							{datasetContent()}
						</div>
					)
				}
			</div>
		);
	}

	return (
		<div>
			<div className='vital-thumbnail-container'>
				<img
					className={imgClasses}
					width={`${THUMB_WIDTH}px`}
					height={`${THUMB_HEIGHT}px`}
					src={imgSrcUrl}
					alt={title}
					onClick={onClick(clickArg)}
				/>
			</div>
			<h6
				className='vital-title hover'
				onClick={onClick(clickArg)}
			>
				{TYPE_TITLES[type] ? TYPE_TITLES[type].main : title}

			</h6>
			<div className='vital-description-container' onClick={onClick(clickArg)}>
				{topContent()}
				<p ref={ref} className='vital-description'>{descriptionText}</p>
			</div>
			{datasetContent()}
		</div>
	);
};

/**
 * Vitals Overlay component
 */
const VitalsOverlay = ({ closeOverlay }) => {
	const mainRef = useRef();
	const scrollableNodeRef = React.createRef();

	const { videoMobileBackTo } = useSnapshot(uiStore.state);
	const { isMobile, isWindows } = useSnapshot(appStore.state);

	// category state and update
	const [categoryIndex, setCategoryIndex] = useState(VITALS_CATEGORY_INDEX);
	const updateCategory = useCallback(e => {
		const newCategory = e.currentTarget.getAttribute('data-filter');

		const newCategoryIndex = Category_AR.indexOf(newCategory);
		if (newCategoryIndex !== categoryIndex) {
			setCategoryIndex(newCategoryIndex);
		}
	}, [categoryIndex]);

	// Handle case where we want to load the DG&A tab from the video overlay back button on mobile.
	const setDGATab = videoMobileBackTo === 'vitals';
	useEffect(() => {
		if (setDGATab) {
			setCategoryIndex(1);
		}
	}, [setDGATab]);

	const overlayData = categoryIndex === 0 ? VITALS_DATA : VIDEOS_DATA;

	// Navigate to vital handler.
	//  TODO: This has become a bit of a mess. Needs refactoring to make less confusing.
	const navFunction = useCallback((vitalSignParam, datasetParam) => {
		closeOverlay();

		const { getManager } = globalRefs;
		const vitalSignsTab = categoryIndex === 0;

		if (vitalSignsTab) {
			getManager('route').navigateToDataset({ vitalSignParam, datasetParam });
		} else {
			const videoParam = vitalSignParam || datasetParam;
			const { type } = getManager('video').getVideo(videoParam);
			const backToVSmenu = type === 'modal' || type === 'static';

			getManager('route').navigateToVideo(videoParam);

			if (backToVSmenu) {
				uiStore.setGlobalState({ videoMobileBackTo: 'vitals' });
			}
		}
	}, [closeOverlay, categoryIndex]);

	const nested = categoryIndex === 1;

	// Nest videos with titles under respective categories
	const nestedCategories = {
		'الرسوم المتحركة العالمية': ['الرسوم المتحركة العالمية', 'فيديو جغرافي'],
		'فيديو, مخططات ورسوم بيانية': ['فيديو','رسم بياني']
	};

	// compose overlay
  let filteredOverlayData = overlayData.filter((v) => {return !v.hideFromOverlay})
  // console.log("overlayData", overlayData, filteredOverlayData)

	return (
		<Overlay type='vitals' ref={mainRef}>
			<Overlay.Header
				back
				onClose={closeOverlay}
				title={TITLE_TEXT}
				Icon={LayerGroupSolidSVG}
			>
				<Filter
					type='categories'
					allItems={Category_AR}
					selectedIndex={categoryIndex}
					updateItem={updateCategory}
				/>
			</Overlay.Header>
      
			<div className='content-mask'>
				{nested && isMobile
					? (
						<div className='content flex-grid-scrollable'>
							{Object.keys(nestedCategories).map(category => (
								<div key={category}>
									<NestedVitalRow
										title={category}
										categories={nestedCategories}
										overlayData={overlayData.filter(dataset => {
											const videoType = TYPE_TITLES[dataset.type].main;
											return nestedCategories[category].includes(videoType);
										})}
										nested={nested}
										isExpanded={setDGATab && category === 'Videos, Charts & Graphics'}
										category={category}
										onItemClick={navFunction}
										scrollableRef={mainRef}
									/>
								</div>
							))}
						</div>
					) :
						<GridList
							rowClassName={isMobile ? 'vital-mobile-row' : 'flex-grid-row'}
							maxPerRow={isMobile ? 1 : 1000}
							dataList={filteredOverlayData}
							itemSize={300}
							itemPadding={[30, 30]}
							itemComponent={<VitalItem onItemClick={navFunction} />}
							scrollableRef={isWindows ? scrollableNodeRef : mainRef}
						/>}
			</div>
		</Overlay>
	);

	// compose overlay
	return (
		<Overlay type='vitals' ref={mainRef}>
			{isWindows ?
				<SimpleBar scrollableNodeProps={{ ref: scrollableNodeRef }} style={{ height: '100%' }}>
					{overlayComponents}
				</SimpleBar> :
				overlayComponents}
		</Overlay>
	);
};

export default VitalsOverlay;
