/** @module pioneer */
import {
	BaseComponent,
	BaseController,
	Capabilities,
	Collection,
	Config,
	Downloader,
	Entity,
	FPS,
	Input,
	MaterialManager,
	Scene,
	TextureLoader,
	TextureLoaderCompressed,
	THREE,
	Types,
	Vector2,
	Version,
	Viewport
} from './internal';

/**
 * @callback ComponentConstructor
 * @param {string} type - The type of the component.
 * @param {string} name
 * @param {Entity} parent
 * @returns {BaseComponent}
 */

/**
 * @callback ControllerConstructor
 * @param {string} type
 * @param {string} name
 * @param {Entity} parent
 * @returns {BaseController}
 */

/**
 * The main driver of the Pioneer engine.
 */
export class Engine {
	/**
	 * Constructs the Pioneer engine.
	 * @param {HTMLDivElement} rootDiv - A &lt;div&gt; element. The canvas and Pioneer UI will go inside of it.
	 */
	constructor(rootDiv) {
		// Make sure the root div is an actual div element.
		if (!(rootDiv instanceof HTMLDivElement)) {
			throw new Error('The root div param is not an actual div element.');
		}

		/**
		 * The root &lt;div&gt; element.
		 * @type {HTMLDivElement}
		 * @private
		 */
		this._rootDiv = rootDiv;

		/**
		 * The canvas created by the engine inside the root div.
		 * @type {HTMLCanvasElement}
		 * @private
		 */
		this._canvas = null;

		/**
		 * The current size of the render area as a Vector2.
		 * @type {Vector2}
		 * @private
		 */
		this._renderSize = new Vector2();
		this._renderSize.freeze();

		/**
		 * The input object.
		 * @type {Input}
		 * @private
		 */
		this._input = new Input(this);

		/**
		 * The config.
		 * @type {Config}
		 * @private
		 */
		this._config = new Config();

		/**
		 * The downloader object.
		 * @type {Downloader}
		 * @private
		 */
		this._downloader = new Downloader();

		/**
		 * The texture loader used by all components for the loading of textures.
		 * @type {TextureLoader}
		 * @private
		 */
		this._textureLoader = null;

		/**
		 * The compressed texture loader used by all components for the loading of textures.
		 * @type {TextureLoaderCompressed}
		 * @private
		 */
		this._textureLoaderCompressed = null;

		/**
		 * The shader manager.
		 * @type {MaterialManager}
		 * @private
		 */
		this._materialManager = null;

		/**
		 * The collection of scenes.
		 * @type {Collection<Scene, Engine>}
		 * @private
		 */
		this._scenes = new Collection(this, new Map([['scene', Scene]]));

		/**
		 * The collection of viewports.
		 * @type {Collection<Viewport, Engine>}
		 * @private
		 */
		this._viewports = new Collection(this, new Map([['viewport', Viewport]]));

		/**
		 * The real-world time last frame. Used to determine the in-app time.
		 * @type {number}
		 * @private
		 */
		this._lastAppTime = Date.now();

		/**
		 * The time in seconds it took to complete the last frame.
		 * @type {number}
		 * @private
		 */
		this._realDeltaTime = 0;

		/**
		 * An FPS calculator.
		 * @type {FPS}
		 * @private
		 */
		this._fps = new FPS();

		/**
		 * A limit for the FPS.
		 * @type {number}
		 * @private
		 */
		this._fpsLimit = Number.POSITIVE_INFINITY;

		/**
		 * The in-app time in ET seconds.
		 * @type {number}
		 * @private
		 */
		this._time = 0;

		/**
		 * The in-app time rate in seconds per second.
		 * @type {number}
		 * @private
		 */
		this._timeRate = 0;

		/**
		 * The list of callbacks to be called at the end of the next frame.
		 * If they are not recurring, they will be removed after they are processed.
		 * @type {{ callback: () => any, recurring: boolean }[]}
		 * @private
		 */
		this._callbacks = [];

		/**
		 * A list of callbacks to remove in the next frame.
		 * @type {(() => any)[]}
		 * @private
		 */
		this._callbacksToRemove = [];

		/**
		 * This is used in requestAnimationFrame because bind (or ()=>{}) creates garbage.
		 * @type {(this: Engine) => any}
		 * @private
		 */
		this._thisLoop = this._loop.bind(this);

		/**
		 * The ThreeJS renderer.
		 * @type {THREE.WebGLRenderer}
		 * @private
		 */
		this._threeJsRenderer = null;

		/**
		 * The div element where the viewports will go.
		 * @type {HTMLDivElement}
		 * @private
		 */
		this._viewportDiv = null;

		// Add styling to the root div to make sure it will work with Pioneer.
		if (this._rootDiv.style.position !== 'relative' && this._rootDiv.style.position !== 'absolute') {
			this._rootDiv.style.position = 'relative';
		}
		this._rootDiv.style.userSelect = 'none';
		this._rootDiv.style.webkitUserSelect = 'none';
		this._rootDiv.style.touchAction = 'none';

		// Create the canvas and attach it to the root div.
		this._canvas = document.createElement('canvas');
		this._canvas.style.position = 'absolute';
		this._canvas.style.left = '0px';
		this._canvas.style.top = '0px';
		this._canvas.style.width = '100%';
		this._canvas.style.height = '100%';
		this._rootDiv.appendChild(this._canvas);

		// Create the viewport div for the viewports and attach it to the root div.
		this._viewportDiv = document.createElement('div');
		this._viewportDiv.style.position = 'absolute';
		this._viewportDiv.style.left = '0px';
		this._viewportDiv.style.top = '0px';
		this._viewportDiv.style.width = '100%';
		this._viewportDiv.style.height = '100%';
		this._viewportDiv.style.overflow = 'hidden';
		this._rootDiv.appendChild(this._viewportDiv);

		try {
			// Create ThreeJS renderer, using the canvas as a reference.
			this._threeJsRenderer = new THREE.WebGLRenderer({
				canvas: this._canvas,
				antialias: true
			});
			this._threeJsRenderer.setScissorTest(true);

			// Make sure the renderer is using the device pixel ratio for extra sharpness.
			this._threeJsRenderer.setPixelRatio(window.devicePixelRatio);

			// Create the texture loaders.
			this._textureLoader = new TextureLoader(this._downloader, this._threeJsRenderer);
			this._textureLoaderCompressed = new TextureLoaderCompressed(this._downloader, this._config, this._threeJsRenderer);

			// Disable the cache so that things don't get loaded indefinitely.
			THREE.Cache.enabled = false;

			// REMOVE LATER: Fix for GLSL warning about GL_ARB_gpu_shader5 (ThreeJS bug: https://github.com/mrdoob/three.js/issues/9716).
			const origGetShaderInfoLog = this._threeJsRenderer.getContext().getShaderInfoLog.bind(this._threeJsRenderer.getContext());
			this._threeJsRenderer.getContext().getShaderInfoLog = (shader) => {
				const t = origGetShaderInfoLog(shader);
				if (t.includes('GL_ARB_gpu_shader5')) {
					return '';
				}
				else {
					return t;
				}
			};

			// Add the WebGL context to the capabilities.
			Capabilities.__setContext(this._threeJsRenderer.getContext());

			// Create the material manager.
			this._materialManager = new MaterialManager(this._downloader);
		}
		catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
			throw error;
		}

		// Start looping.
		requestAnimationFrame(this._thisLoop);
	}

	// VERSION

	/**
	 * Gets the Pioneer version.
	 * @returns {string}
	 */
	getVersion() {
		return Version;
	}

	// CANVAS AND CONTAINER DIVS

	/**
	 * Returns the root div that was passed in the constructor.
	 * @returns {HTMLDivElement}
	 */
	getRootDiv() {
		return this._rootDiv;
	}

	/**
	 * Gets the size of the rendering area.
	 * @returns {Vector2}
	 */
	getRenderSize() {
		return this._renderSize;
	}

	/**
	 * Returns the canvas used for the engine.
	 * @returns {HTMLCanvasElement}
	 */
	getCanvas() {
		return this._canvas;
	}

	/**
	 * Returns the div used for the UI.
	 * @returns {HTMLDivElement}
	 */
	getViewportDiv() {
		return this._viewportDiv;
	}

	// SUB-SYSTEMS

	/**
	 * Returns the config.
	 * @returns {Config}
	 */
	getConfig() {
		return this._config;
	}

	/**
	 * Returns the input manager.
	 * @returns {Input}
	 */
	getInput() {
		return this._input;
	}

	/**
	 * Returns the downloader.
	 * @returns {Downloader}
	 */
	getDownloader() {
		return this._downloader;
	}

	/**
	 * Gets the texture loader.
	 * @returns {TextureLoader}
	 */
	getTextureLoader() {
		return this._textureLoader;
	}

	/**
	 * Gets the texture loader.
	 * @returns {TextureLoaderCompressed}
	 */
	getTextureLoaderCompressed() {
		return this._textureLoaderCompressed;
	}

	/**
	 * Get the material manager.
	 * @returns {MaterialManager}
	 */
	getMaterialManager() {
		return this._materialManager;
	}

	// SCENES

	/**
	 * Gets a scene by name.
	 * @param {string|number} nameOrIndex - The name or zero-based index of the scene.
	 * @returns {Scene}
	 */
	getScene(nameOrIndex) {
		return this._scenes.get(nameOrIndex);
	}

	/**
	 * Gets the number of scenes.
	 * @returns {number}
	 */
	getNumScenes() {
		return this._scenes.size;
	}

	/**
	 * Adds a scene.
	 * @param {string} name - The name of the scene to be added.
	 * @returns {Scene}
	 */
	addScene(name) {
		return this._scenes.add('scene', name);
	}

	/**
	 * Removes a scene.
	 * @param {Scene|string|number} sceneOrNameOrIndex - The scene, name, or index to be removed.
	 */
	removeScene(sceneOrNameOrIndex) {
		this._scenes.remove(sceneOrNameOrIndex);
	}

	/**
	 * Gets the scene, entity, component, or controller described in the parameters.
	 * @param {string} sceneNameOrIndex - The name of the scene.
	 * @param {string} [entityNameOrIndex] - The name of the entity.
	 * @param {string} [componentOrControllerType] - The type of the component or controller.
	 * @param {number} [componentOrControllerTypeIndex = 0] - The zero-based index of the component or controller of the specified type, in case there are more than one of the same type.
	 * @returns {Scene|Entity|BaseComponent|BaseController}
	 */
	get(sceneNameOrIndex, entityNameOrIndex = undefined, componentOrControllerType = undefined, componentOrControllerTypeIndex = 0) {
		const scene = this._scenes.get(sceneNameOrIndex);
		if (entityNameOrIndex === undefined || scene === null) {
			return scene;
		}
		return scene.get(entityNameOrIndex, componentOrControllerType, componentOrControllerTypeIndex);
	}

	// VIEWPORTS

	/**
	 * Returns the viewport at the index.
	 * @param {string|number} nameOrIndex - The name or zero-based index of the viewport to get.
	 * @returns {Viewport}
	 */
	getViewport(nameOrIndex) {
		return this._viewports.get(nameOrIndex);
	}

	/**
	 * Returns the number of viewports.
	 * @returns {number}
	 */
	getNumViewports() {
		return this._viewports.size;
	}

	/**
	 * Adds a viewport.
	 * @param {string} [name=''] - A optional name to give the viewport.
	 * @returns {Viewport}
	 */
	addViewport(name = '') {
		return this._viewports.add('viewport', name);
	}

	/**
	 * Removes a viewport.
	 * @param {Viewport|string|number} viewportOrNameOrIndex
	 */
	removeViewport(viewportOrNameOrIndex) {
		this._viewports.remove(viewportOrNameOrIndex);
	}

	// TIME

	/**
	 * Gets the in-app time in seconds since the J2000 epoch, which is at 2000-01-01T11:58:55.816 UTC. See {@link https://en.wikipedia.org/wiki/Epoch_(astronomy)#Julian_years_and_J2000}.
	 * @returns {number}
	 */
	getTime() {
		return this._time;
	}

	/**
	 * Sets the in-app time.
	 * @param {number} time - The time in seconds since the J2000 epoch.
	 */
	setTime(time) {
		this._time = time;
	}

	/**
	 * Gets the in-app time rate in seconds per real-time second.
	 * @returns {number}
	 */
	getTimeRate() {
		return this._timeRate;
	}

	/**
	 * Sets the in-app time rate.
	 * @param {number} timeRate - The time rate in seconds per real-time second.
	 */
	setTimeRate(timeRate) {
		this._timeRate = timeRate;
	}

	/**
	 * Gets the average FPS over the last number of frames.
	 * @returns {number}
	 */
	getFPS() {
		return this._fps.getFPS();
	}

	/**
	 * Gets a limit for the FPS.
	 * @returns {number}
	 */
	getFPSLimit() {
		return this._fpsLimit;
	}

	/**
	 * Sets a limit for the FPS.
	 * @param {number} limit
	 */
	setFPSLimit(limit) {
		this._fpsLimit = limit;
	}

	/**
	 * Gets the time in seconds it took to complete the last frame.
	 * @returns {number}
	 */
	getDeltaTime() {
		return this._realDeltaTime;
	}

	// COMPONENT REGISTRATION

	/**
	 * Returns true if a component of the type is already registered.
	 * @param {string} type
	 */
	isComponentTypeRegistered(type) {
		return Types.Components.has(type);
	}

	/**
	 * Registers a component type.
	 * @param {string} type - The name of the component that will be used with the addComponent function.
	 * @param {typeof BaseComponent} typeConstructor - The component class to register.
	 */
	registerComponentType(type, typeConstructor) {
		if (Types.Components.has(type)) {
			throw new Error('Already registered component type \'' + type + '\'.');
		}
		Types.Components.set(type, typeConstructor);
	}

	/**
	 * Unregisters a component type.
	 * @param {string} type - The name of the component to unregister.
	 */
	unregisterComponentType(type) {
		Types.Components.delete(type);
	}

	/**
	 * Returns true if a controller of the type is already registered.
	 * @param {string} type
	 */
	isControllerTypeRegistered(type) {
		return Types.Controllers.has(type);
	}

	/**
	 * Registers a controller type.
	 * @param {string} type - The name of the controller that will be used with the addController function.
	 * @param {typeof BaseController} typeConstructor - The controller class to register.
	 */
	registerControllerType(type, typeConstructor) {
		if (Types.Controllers.has(type)) {
			throw new Error('Already registered controller type \'' + type + '\'.');
		}
		Types.Controllers.set(type, typeConstructor);
	}

	/**
	 * Unregisters a controller type.
	 * @param {string} type - The name of the controller to unregister.
	 */
	unregisterControllerType(type) {
		Types.Controllers.delete(type);
	}

	// CALLBACKS AND PROMISES

	/**
	 * Returns a promise that resolves at the end of the current frame.
	 * It is useful for letting controllers and components do a single update.
	 * @returns {Promise<void>}
	 */
	waitUntilNextFrame() {
		return new Promise((resolve) => {
			this.addCallback(() => {
				resolve();
			}, false);
		});
	}

	/**
	 * Adds a callback to be called at the end of the next frame.
	 * @param {() => any} callback - The callback to be called.
	 * @param {boolean} recurring - If true it will run at the end of every frame. If not, it will only run once at the end of the next frame.
	 */
	addCallback(callback, recurring) {
		this._callbacks.push({
			callback: callback,
			recurring: recurring
		});
	}

	/**
	 * Removes a callback added via `addCallback()`. If there were multiple, only the first found will be removed.
	 * @param {() => any} callback - The callback to be removed.
	 */
	removeCallback(callback) {
		this._callbacksToRemove.push(callback);
	}

	// INTERNALS

	/**
	 * Returns the ThreeJS renderer for use by viewports and components.
	 * @returns {THREE.WebGLRenderer}
	 * @internal
	 */
	__getThreeJsRenderer() {
		return this._threeJsRenderer;
	}

	/**
	 * The main loop. The requestAnimationFrame call calls this at the start of every frame.
	 * @private
	 */
	_loop() {
		try {
			// Update the time according to the time rate and the real-world elapsed time.
			const thisAppTime = Date.now();
			this._realDeltaTime = (thisAppTime - this._lastAppTime) / 1000.0;

			// If not enough time has elapsed, don't run the loop.
			if (this._realDeltaTime < 1.0 / this._fpsLimit) {
				requestAnimationFrame(this._thisLoop);
				return;
			}

			this._lastAppTime = thisAppTime;
			this._fps.update(this._realDeltaTime);
			this._time += this._timeRate * this._realDeltaTime;

			// Update the size of the divs to fill the box when the root div has changed.
			if (this._renderSize.x !== this._rootDiv.clientWidth || this._renderSize.y !== this._rootDiv.clientHeight) {
				this._renderSize.thaw();
				this._renderSize.set(this._rootDiv.clientWidth, this._rootDiv.clientHeight);
				this._renderSize.freeze();
				this._threeJsRenderer.setSize(this._renderSize.x, this._renderSize.y, false);
			}

			// Update the scene.
			for (let i = 0; i < this._scenes.size; i++) {
				this._scenes.get(i).__update();
			}

			// Update the viewport-dependent variables.
			for (let i = 0; i < this._viewports.size; i++) {
				this._viewports.get(i).__updateViewportVariables();
			}

			// Update the camera-non-specific visual parts of the entities in each scene.
			for (let i = 0; i < this._scenes.size; i++) {
				this._scenes.get(i).__updateVisuals();
			}

			// Fill the screen with black.
			this._threeJsRenderer.setViewport(0, 0, this._renderSize.x, this._renderSize.y);
			this._threeJsRenderer.setScissor(0, 0, this._renderSize.x, this._renderSize.y);
			this._threeJsRenderer.clear();

			// Draw the viewports.
			for (let i = 0; i < this._viewports.size; i++) {
				this._viewports.get(i).__render();
			}

			// Remove any callbacks requested.
			for (let i = 0, l = this._callbacksToRemove.length; i < l; i++) {
				for (let j = 0, m = this._callbacks.length; j < m; j++) {
					if (this._callbacks[j].callback === this._callbacksToRemove[i]) {
						this._callbacks.splice(j, 1);
						break;
					}
				}
			}
			this._callbacksToRemove = [];

			// Call the callbacks.
			for (let i = 0; i < this._callbacks.length; i++) {
				this._callbacks[i].callback();
				if (!this._callbacks[i].recurring) {
					this._callbacksToRemove.push(this._callbacks[i].callback);
				}
			}

			// Make sure the input is ready for the next frame.
			this._input.__resetStatesForNextFrame();
		}
		catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
			throw error;
		}

		// Tell the browser to give us another frame.
		requestAnimationFrame(this._thisLoop);
	}
}
