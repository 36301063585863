import { ShaderChunkLogDepth } from './log_depth';

export const SpriteShader = {
	uniforms: {
		modelViewMatrix: 'mat4',
		projectionMatrix: 'highp mat4',

		pixelBased: 'float',
		color: 'vec4',
		colorTexture: 'sampler2D',

		...ShaderChunkLogDepth.Uniforms
	},
	properties: {
		transparent: true,
		depthWrite: false,
		side: 'double',
		blending: 'normal'
	},
	vertex: {
		extensions: [
			'EXT_frag_depth'
		],
		code: `
			attribute vec3 position;
			attribute vec2 uv;

			uniform mat4 modelViewMatrix;
			uniform mat4 projectionMatrix;
			uniform float pixelBased;

			varying vec2 vUV;

			${ShaderChunkLogDepth.VertexHead}

			void main() {
				gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
				if (pixelBased != 0.0) {
					gl_Position /= abs(gl_Position.w);
				}
				vUV = uv;

				${ShaderChunkLogDepth.Vertex}
			}
			`
	},
	fragment: {
		extensions: [
			'EXT_frag_depth'
		],
		code: `
			precision highp float;

			uniform vec4 color;
			uniform sampler2D colorTexture;

			varying vec2 vUV;

			${ShaderChunkLogDepth.FragmentHead}

			void main() {
				gl_FragColor = texture2D(colorTexture, vUV) * color;

				${ShaderChunkLogDepth.Fragment}
			}
			`
	}
};
