/** @module pioneer */
import {
	Capabilities,
	Downloader,
	ShaderFix,
	THREE,
	ThreeJsHelper
} from './internal';

// Preloaded shaders.
import { BasicShader } from './shaders/basic';
import { BasicAlphaShader } from './shaders/basic_alpha';
import { ConnectedSpriteShader } from './shaders/connected_sprite';
import { LineShader } from './shaders/line';
import { PlumesShader } from './shaders/plumes';
import { SpriteShader } from './shaders/sprite';
import { SpriteParticlesShader } from './shaders/sprite_particles';
import { TrailShader } from './shaders/trail';

/**
 * A generic resource.
 * @template Type
 */
class Resource {
	/**
	 * Constructs the class.
	 * @param {Type} value
	 */
	constructor(value) {
		/**
		 * The value of the resource.
		 * @type {Type}
		 */
		this.value = value;

		/**
		 * How many of this resource are currently being used.
		 * @type {number}
		 */
		this.useCount = 0;
	}
}

/**
 * Material manager. Constructs materials from nodes.
 */
export class MaterialManager {
	/**
	 * @param {Downloader} downloader
	 */
	constructor(downloader) {
		/**
		 * The downloader.
		 * @type {Downloader}
		 * @private
		 */
		this._downloader = downloader;

		/**
		 * A mapping from urls to materials.
		 * @type {Map<string, Resource<THREE.RawShaderMaterial>>}
		 * @private
		 */
		this._cache = new Map();

		/**
		 * A mapping from cloned materials to the urls.
		 * @type {Map<THREE.RawShaderMaterial, string>}
		 * @private
		 */
		this._clonedMaterials = new Map();

		/**
		 * A mapping from urls to promises that resolve to materials.
		 * @type {Map<string, Promise<Resource<THREE.RawShaderMaterial>>>}
		 * @private
		 */
		this._promises = new Map();

		// Preload node types that are required for the built-in components.
		this._preload();
	}

	/**
	 * Gets a material.
	 * @param {string} url
	 * @param {number} priority
	 * @returns {Promise<THREE.RawShaderMaterial>}
	 */
	async get(url, priority) {
		// If it is already loaded, return a resolved promise.
		let resource = this._cache.get(url);

		// If it is loading right now, return the existing promise.
		if (resource === undefined) {
			const promise = this._promises.get(url);
			if (promise !== undefined) {
				resource = await promise;
			}
		}

		// Load the material.
		if (resource === undefined) {
			const download = await this._downloader.download(url, false, priority);
			if (download.status !== 'completed' || typeof download.content !== 'string') {
				throw new Error('Failed to download material "' + url + '".');
			}
			const obj = JSON.parse(download.content);
			resource = new Resource(await this._load(url, obj));
			this._cache.set(url, resource);
		}

		// Increment the use count and return it.
		resource.useCount += 1;
		return this._clone(resource.value, url);
	}

	/**
	 * Gets a pre-loaded material without using promises.
	 * @param {string} url
	 * @returns {THREE.RawShaderMaterial}
	 */
	getPreloaded(url) {
		// If it is already loaded, return a resolved promise.
		const resource = this._cache.get(url);
		if (resource === undefined) {
			throw new Error('Invalid pre-loaded material "' + url + '".');
		}
		resource.useCount += 1;
		return this._clone(resource.value, url);
	}

	/**
	 * Releases a material, unloading it if necessary.
	 * @param {THREE.RawShaderMaterial} material
	 */
	release(material) {
		// Get the url from the material.
		const url = this._clonedMaterials.get(material);
		if (url === undefined) {
			return;
		}
		// Remove the cloned material.
		this._clonedMaterials.delete(material);
		// Get the resource from the url.
		const resource = this._cache.get(url);
		if (resource) {
			resource.useCount -= 1;
			// If it isn't be used by anyone, unload the original material.
			if (resource.useCount === 0) {
				ThreeJsHelper.destroyMaterial(resource.value, true);
				this._cache.delete(url);
			}
		}
	}

	/**
	 * Preloads a given list of shaders for quick access in the engine.
	 * @private
	 */
	_preload() {
		// Declare the preloaded shaders.
		/** @type {Map<string, any>} */
		const shaders = new Map();
		shaders.set('basic', BasicShader);
		shaders.set('basic_alpha', BasicAlphaShader);
		shaders.set('connected_sprite', ConnectedSpriteShader);
		shaders.set('line', LineShader);
		shaders.set('plumes', PlumesShader);
		shaders.set('sprite', SpriteShader);
		shaders.set('sprite_particles', SpriteParticlesShader);
		shaders.set('trail', TrailShader);

		// Load them.
		for (const [name, json] of shaders) {
			const material = this._load(name, json);
			const resource = new Resource(material);
			resource.useCount += 1;
			this._cache.set(name, resource);
		}
	}

	/**
	 * Loads a shader from JSON.
	 * @param {string} url
	 * @param {any} json
	 * @returns {THREE.RawShaderMaterial}
	 * @private
	 */
	_load(url, json) {
		try {
			// Generate ThreeJS uniform objects
			/** @type {Object<string, THREE.Uniform>} */
			const uniforms = {};
			if (json.uniforms) {
				for (const [name, type] of Object.entries(json.uniforms)) {
					// Don't add the Three.js pre-defined uniforms, because they would overwrite them when rendering.
					if (['modelMatrix', 'modelViewMatrix', 'projectionMatrix', 'viewMatrix', 'normalMatrix', 'cameraPosition'].includes(name)) {
						continue;
					}
					uniforms[name] = new THREE.Uniform(this._getUniformValueFromType(type));
				}
			}

			// Process some properties.
			let transparent = false;
			let depthWrite = true;
			let side = /** @type {THREE.Side} */(THREE.FrontSide);
			let blending = /** @type {THREE.Blending} */(THREE.NoBlending);
			if (json.properties) {
				if (json.properties.transparent === true) {
					transparent = true;
				}
				if (json.properties.depthWrite === false) {
					depthWrite = false;
				}
				switch (json.properties.side) {
					case 'front': side = THREE.FrontSide; break;
					case 'back': side = THREE.BackSide; break;
					case 'double': side = THREE.DoubleSide; break;
				}
				switch (json.properties.blending) {
					case 'normal': blending = THREE.NormalBlending; break;
					case 'additive': blending = THREE.AdditiveBlending; break;
					case 'subtractive': blending = THREE.SubtractiveBlending; break;
					case 'multiply': blending = THREE.MultiplyBlending; break;
					case 'custom': blending = THREE.CustomBlending; break;
				}
			}

			// Check for required code.
			if (typeof json.vertex !== 'object' || typeof json.vertex.code !== 'string') {
				throw new Error('Missing vertex stage code.');
			}
			if (typeof json.fragment !== 'object' || typeof json.fragment.code !== 'string') {
				throw new Error('Missing fragment stage code.');
			}

			// Set the extension code.
			if (Array.isArray(json.vertex.extensions)) {
				let extensionCode = '';
				for (const extension of json.vertex.extensions) {
					if (Capabilities.hasGLExtension(extension)) {
						extensionCode += '#extension GL_' + extension + ': enable\n';
					}
				}
				for (const extension of json.vertex.extensions) {
					if (Capabilities.hasGLExtension(extension)) {
						extensionCode += '#define L_' + extension + ' true\n';
					}
					if (Capabilities.isWebGL2() && extension === 'EXT_frag_depth') {
						extensionCode += '#define L_' + extension + ' true\n';
					}
				}
				json.vertex.code = extensionCode + json.vertex.code;
			}
			if (Array.isArray(json.fragment.extensions)) {
				let extensionCode = '';
				for (const extension of json.fragment.extensions) {
					if (Capabilities.hasGLExtension(extension)) {
						extensionCode += '#extension GL_' + extension + ': enable\n';
					}
				}
				for (const extension of json.fragment.extensions) {
					if (Capabilities.hasGLExtension(extension)) {
						extensionCode += '#define L_' + extension + ' true\n';
					}
				}
				json.fragment.code = extensionCode + json.fragment.code;
			}

			// Create the material.
			const material = new THREE.RawShaderMaterial({
				uniforms,
				vertexShader: json.vertex.code,
				fragmentShader: json.fragment.code,
				transparent: transparent,
				depthWrite: depthWrite,
				side: side,
				blending: blending,
				glslVersion: THREE.GLSL3
			});
			ShaderFix.fix(material);
			material.needsUpdate = true;

			// Return the loaded material.
			return material;
		}
		catch (e) {
			if (e instanceof Error) {
				e.message = `While processing material "${url}": ${e.message}`;
			}
			throw e;
		}
	}

	/**
	 * Gets a uniform value from the type.
	 * @param {string} type
	 * @returns {any}
	 * @private
	 */
	_getUniformValueFromType(type) {
		type = type.replace(/.* /, '');
		switch (type) {
			case 'int':
			case 'float':
				return 0;
			case 'ivec2':
			case 'vec2':
				return new THREE.Vector2(0, 0);
			case 'ivec3':
			case 'vec3':
				return new THREE.Vector3(0, 0, 0);
			case 'ivec4':
			case 'vec4':
				return new THREE.Vector4(0, 0, 0, 0);
			case 'mat3':
				return new THREE.Matrix3();
			case 'mat4':
				return new THREE.Matrix4();
			case 'sampler2D':
				return null;
			case 'samplerCube':
				return null;
		}
		if (type.endsWith(']')) {
			const indexOfOpenBracket = type.indexOf('[');
			const indexOfCloseBracket = type.indexOf(']');
			const baseType = type.substring(0, indexOfOpenBracket);
			const numElements = Number.parseInt(type.substring(indexOfOpenBracket + 1, indexOfCloseBracket));
			const array = [];
			for (let i = 0; i < numElements; i++) {
				array.push(this._getUniformValueFromType(baseType));
			}
			return array;
		}
		throw new Error('Unrecognized type: ' + type + '.');
	}

	/**
	 * Clones a material.
	 * @param {THREE.RawShaderMaterial} material
	 * @param {string} url
	 * @returns {THREE.RawShaderMaterial}
	 * @private
	 */
	_clone(material, url) {
		// Use the built-in Three.js material clone.
		const newMaterial = material.clone();

		// Manually clone array uniforms of Three.js objects, since Three.js doesn't do this (see https://github.com/mrdoob/three.js/issues/16080).
		for (const [name, uniform] of Object.entries(material.uniforms)) {
			const value = uniform.value;
			if (Array.isArray(value)) {
				const value0 = value[0];
				if (value0 && (value0.isColor || value0.isMatrix3 || value0.isMatrix4
					|| value0.isVector2 || value0.isVector3 || value0.isVector4
					|| value0.isTexture)) {
					material.uniforms[name].value = [];
					for (let i = 0; i < value.length; i++) {
						material.uniforms[name].value[i] = value[i].clone();
					}
				}
			}
		}

		// Make sure the new material is updated.
		newMaterial.needsUpdate = true;

		// Add to the cloned materials list so that it can be found during the release.
		this._clonedMaterials.set(newMaterial, url);

		return newMaterial;
	}
}
