/** @module pioneer */
import {
	AER,
	Freezable,
	MathUtils,
	Pool,
	Quaternion,
	THREE
} from '../internal';

/** A 3-dimensional vector */
export class Vector3 extends Freezable {
	/**
	 * Pool for temporary variables.
	 * @returns {Pool<Vector3>}
	 */
	static get pool() {
		return _pool;
	}

	/**
	 * NaN vector
	 * @returns {Vector3}
	 */
	static get NaN() {
		return _nan;
	}

	/**
	 * Zero vector
	 * @returns {Vector3}
	 */
	static get Zero() {
		return _zero;
	}

	/**
	 * Unit x-axis vector
	 * @returns {Vector3}
	 */
	static get XAxis() {
		return _xAxis;
	}

	/**
	 * Unit y-axis vector
	 * @returns {Vector3}
	 */
	static get YAxis() {
		return _yAxis;
	}

	/**
	 * Unit z-axis vector
	 * @returns {Vector3}
	 */
	static get ZAxis() {
		return _zAxis;
	}

	/**
	 * Unit negative x-axis vector
	 * @returns {Vector3}
	 */
	static get XAxisNeg() {
		return _xAxisNeg;
	}

	/**
	 * Unit negative y-axis vector
	 * @returns {Vector3}
	 */
	static get YAxisNeg() {
		return _yAxisNeg;
	}

	/**
	 * Unit negative z-axis vector
	 * @returns {Vector3}
	 */
	static get ZAxisNeg() {
		return _zAxisNeg;
	}

	/**
	 * Returns the xyz equivalent of azimuth (rotation from x-axis about z-axis), elevation (from x-y plane), and range.
	 * @param {AER} aer
	 * @returns {Vector3}
	 */
	static fromAER(aer) {
		const v = new Vector3();
		v.setFromAER(aer);
		return v;
	}

	/**
	 * Constructor.
	 * @param {number} x
	 * @param {number} y
	 * @param {number} z
	 */
	constructor(x = 0, y = 0, z = 0) {
		super();

		/**
		 * @type {number}
		 * @private
		 */
		this._x = x;
		/**
		 * @type {number}
		 * @private
		 */
		this._y = y;
		/**
		 * @type {number}
		 * @private
		 */
		this._z = z;
	}

	/**
	 * Gets the x component.
	 * @returns {number}
	 */
	get x() {
		return this._x;
	}

	/**
	 * Sets the x component.
	 * @param {number} x
	 */
	set x(x) {
		this.throwIfFrozen();
		this._x = x;
	}

	/**
	 * Gets the y component.
	 * @returns {number}
	 */
	get y() {
		return this._y;
	}

	/**
	 * Sets the y component.
	 * @param {number} y
	 */
	set y(y) {
		this.throwIfFrozen();
		this._y = y;
	}

	/**
	 * Gets the z component.
	 * @returns {number}
	 */
	get z() {
		return this._z;
	}

	/**
	 * Sets the z component.
	 * @param {number} z
	 */
	set z(z) {
		this.throwIfFrozen();
		this._z = z;
	}

	/**
	 * Returns a nicely formed string.
	 * @override
	 * @returns {string}
	 */
	toString() {
		return '[' + this._x + ', ' + this._y + ', ' + this._z + ']';
	}

	/**
	 * Returns true if this equals a.
	 * @param {Vector3} a
	 * @returns {boolean}
	 */
	equals(a) {
		return this._x === a._x && this._y === a._y && this._z === a._z;
	}

	/**
	 * Returns true if all components are zero.
	 * @returns {boolean}
	 */
	isZero() {
		return this._x === 0 && this._y === 0 && this._z === 0;
	}

	/**
	 * Returns true if any component in the vector is NaN.
	 * @returns {boolean}
	 */
	isNaN() {
		return (!(this._x <= 0) && !(this._x > 0)) || (!(this._y <= 0) && !(this._y > 0)) || (!(this._z <= 0) && !(this._z > 0));
	}

	/**
	 * Sets this to a.
	 * @param {Vector3} a
	 */
	copy(a) {
		this.throwIfFrozen();
		this._x = a._x;
		this._y = a._y;
		this._z = a._z;
	}

	/**
	 * Sets this to a as a ThreeJs vector.
	 * @param {THREE.Vector3} a
	 */
	copyFromThreeJs(a) {
		this.throwIfFrozen();
		this._x = a.x;
		this._y = a.y;
		this._z = a.z;
	}

	/**
	 * Sets this to the parameters.
	 * @param {number} x
	 * @param {number} y
	 * @param {number} z
	 */
	set(x, y, z) {
		this.throwIfFrozen();
		this._x = x;
		this._y = y;
		this._z = z;
	}

	/**
	 * Sets this to the xyz equivalent of azimuth (rotation from x-axis about z-axis), elevation (from x-y plane), and range.
	 * @param {AER} aer
	 */
	setFromAER(aer) {
		this.throwIfFrozen();
		const cosElevation = Math.cos(aer.elevation);
		this._x = aer.range * cosElevation * Math.cos(aer.azimuth);
		this._y = aer.range * cosElevation * Math.sin(aer.azimuth);
		this._z = aer.range * Math.sin(aer.elevation);
	}

	/**
	 * Sets this to the negative of a.
	 * @param {Vector3} a
	 */
	neg(a) {
		this.throwIfFrozen();
		this._x = -a._x;
		this._y = -a._y;
		this._z = -a._z;
	}

	/**
	 * Sets this to a + b.
	 * @param {Vector3} a
	 * @param {Vector3} b
	 */
	add(a, b) {
		this.throwIfFrozen();
		this._x = a._x + b._x;
		this._y = a._y + b._y;
		this._z = a._z + b._z;
	}

	/**
	 * Sets this to a - b.
	 * @param {Vector3} a
	 * @param {Vector3} b
	 */
	sub(a, b) {
		this.throwIfFrozen();
		this._x = a._x - b._x;
		this._y = a._y - b._y;
		this._z = a._z - b._z;
	}

	/**
	 * Sets this to a * b, where b is a number.
	 * @param {Vector3} a
	 * @param {number} b
	 */
	mult(a, b) {
		this.throwIfFrozen();
		this._x = a._x * b;
		this._y = a._y * b;
		this._z = a._z * b;
	}

	/**
	 * Sets this to a + b * c, where c is a number.
	 * @param {Vector3} a
	 * @param {Vector3} b
	 * @param {number} c
	 */
	addMult(a, b, c) {
		this.throwIfFrozen();
		this._x = a._x + b._x * c;
		this._y = a._y + b._y * c;
		this._z = a._z + b._z * c;
	}

	/**
	 * Sets this to a / b, where b is a number.
	 * @param {Vector3} a
	 * @param {number} b
	 */
	div(a, b) {
		this.throwIfFrozen();
		this._x = a._x / b;
		this._y = a._y / b;
		this._z = a._z / b;
	}

	/**
	 * Sets this to a * b, component-wise multiplication.
	 * @param {Vector3} a
	 * @param {Vector3} b
	 */
	scale(a, b) {
		this.throwIfFrozen();
		this._x = a._x * b._x;
		this._y = a._y * b._y;
		this._z = a._z * b._z;
	}

	/**
	 * Sets this to a / b, component-wise division.
	 * @param {Vector3} a
	 * @param {Vector3} b
	 */
	scaleInv(a, b) {
		this.throwIfFrozen();
		this._x = a._x / b._x;
		this._y = a._y / b._y;
		this._z = a._z / b._z;
	}

	/**
	 * Returns the dot product of this and a.
	 * @param {Vector3} a
	 * @returns {number}
	 */
	dot(a) {
		return this._x * a._x + this._y * a._y + this._z * a._z;
	}

	/**
	 * Sets this to the cross product of a and b.
	 * @param {Vector3} a
	 * @param {Vector3} b
	 */
	cross(a, b) {
		this.throwIfFrozen();
		const x = a._y * b._z - a._z * b._y;
		const y = a._z * b._x - a._x * b._z;
		const z = a._x * b._y - a._y * b._x;
		this._x = x;
		this._y = y;
		this._z = z;
	}

	/**
	 * Returns the squared length of this vector.
	 * @returns {number}
	 */
	magnitudeSqr() {
		return this._x * this._x + this._y * this._y + this._z * this._z;
	}

	/**
	 * Returns the length of this vector.
	 * @returns {number}
	 */
	magnitude() {
		return Math.sqrt(this.magnitudeSqr());
	}

	/**
	 * Returns the length of just the x and y components. Useful in calculations involving spheres.
	 * @returns {number}
	 */
	magnitudeXY() {
		return Math.sqrt(this._x * this._x + this._y * this._y);
	}

	/**
	 * Sets this to a with a magnitude of 1.0.
	 * @param {Vector3} a
	 */
	normalize(a) {
		this.throwIfFrozen();
		const magnitude = a.magnitude();
		if (magnitude > 0) {
			this._x = a._x / magnitude;
			this._y = a._y / magnitude;
			this._z = a._z / magnitude;
		}
		else {
			this.copy(a);
		}
	}

	/**
	 * Sets this to a with a given magnitude.
	 * @param {Vector3} a
	 * @param {number} magnitude
	 */
	setMagnitude(a, magnitude) {
		this.throwIfFrozen();
		this.normalize(a);
		this._x *= magnitude;
		this._y *= magnitude;
		this._z *= magnitude;
	}

	/**
	 * Returns the distance between this and a.
	 * @param {Vector3} a
	 * @returns {number}
	 */
	distance(a) {
		const x = this._x - a._x;
		const y = this._y - a._y;
		const z = this._z - a._z;
		return Math.sqrt(x * x + y * y + z * z);
	}

	/**
	 * Returns the angle in radians between this and a. If this or a are zero, returns NaN.
	 * @param {Vector3} a
	 * @returns {number}
	 */
	angle(a) {
		const magnitudes = this.magnitude() * a.magnitude();
		if (magnitudes > 0) {
			return Math.acos(MathUtils.clamp(this.dot(a) / magnitudes, -1.0, 1.0));
		}
		else {
			return Number.NaN;
		}
	}

	/**
	 * Returns the angle in radians from this to a if they were projected on the plane formed by axis. It can be plus or minus, based on the right-hand rule rotation around axis.
	 * @param {Vector3} a
	 * @param {Vector3} axis
	 * @returns {number}
	 */
	angleAroundAxis(a, axis) {
		const thisP = Vector3.pool.get();
		const aP = Vector3.pool.get();

		// Project the vectors.
		thisP.addMult(this, axis, -this.dot(axis));
		aP.addMult(a, axis, -a.dot(axis));

		// Get the angle.
		let angle = thisP.angle(aP);

		// Determine the sign of the angle.
		thisP.cross(thisP, aP);
		if (thisP.dot(axis) < 0) {
			angle *= -1;
		}

		Vector3.pool.release(thisP);
		Vector3.pool.release(aP);
		return angle;
	}

	/**
	 * Sets this to a, clamped between min and max, component-wise.
	 * @param {Vector3} a
	 * @param {Vector3} min
	 * @param {Vector3} max
	 */
	clamp(a, min, max) {
		this.throwIfFrozen();
		this._x = MathUtils.clamp(a._x, min._x, max._x);
		this._y = MathUtils.clamp(a._y, min._y, max._y);
		this._z = MathUtils.clamp(a._z, min._z, max._z);
	}

	/**
	 * Sets this to the lerp between a and b, where u is the lerp parameter, and it may be clamped between a and b.
	 * @param {Vector3} a - the value when u = 0
	 * @param {Vector3} b - the value when u = 1
	 * @param {number} u - the lerp factor
	 */
	lerp(a, b, u) {
		this.throwIfFrozen();
		this._x = MathUtils.lerp(a._x, b._x, u);
		this._y = MathUtils.lerp(a._y, b._y, u);
		this._z = MathUtils.lerp(a._z, b._z, u);
	}

	/**
	 * Sets this to the slerp between a and b, where u is the lerp parameter, and it may be clamped between a and b.
	 * @param {Vector3} a - the value when u = 0
	 * @param {Vector3} b - the value when u = 1
	 * @param {number} u - the lerp factor
	 */
	slerp(a, b, u) {
		this.throwIfFrozen();
		const aMag = a.magnitude();
		const bMag = b.magnitude();
		if (aMag > 0.0 && bMag > 0.0) {
			const angle = Math.acos(MathUtils.clamp(a.dot(b) / (aMag * bMag), -1, +1));
			if (Math.abs(angle) > 0.01745327777) { // If less than one degree, just lerp
				const sinAngleInv = 1 / Math.sin(angle);
				const aFactor = Math.sin((1 - u) * angle) * sinAngleInv;
				const bFactor = Math.sin(u * angle) * sinAngleInv;
				this.set(a.x * aFactor / aMag + b.x * bFactor / bMag, a.y * aFactor / aMag + b.y * bFactor / bMag, a.z * aFactor / aMag + b.z * bFactor / bMag);
				this.mult(this, (1 - u) * aMag + u * bMag);
			}
			else {
				this.lerp(a, b, u);
			}
		}
		else {
			this.lerp(a, b, u);
		}
	}

	/**
	 * Sets this to the result of the vector b rotated by the quaternion a.
	 * @param {Quaternion} a
	 * @param {Vector3} b
	 */
	rotate(a, b) {
		this.throwIfFrozen();
		const tx = a.w * b._x + a.y * b._z - a.z * b._y;
		const ty = a.w * b._y + a.z * b._x - a.x * b._z;
		const tz = a.w * b._z + a.x * b._y - a.y * b._x;
		const tw = -a.x * b._x - a.y * b._y - a.z * b._z;
		this._x = tx * a.w - tw * a.x - ty * a.z + tz * a.y;
		this._y = ty * a.w - tw * a.y - tz * a.x + tx * a.z;
		this._z = tz * a.w - tw * a.z - tx * a.y + ty * a.x;
	}

	/**
	 * Sets this to the result of the vector b rotated by the inverse of quaternion a.
	 * @param {Quaternion} a
	 * @param {Vector3} b
	 */
	rotateInverse(a, b) {
		this.throwIfFrozen();
		const tx = a.w * b._x - a.y * b._z + a.z * b._y;
		const ty = a.w * b._y - a.z * b._x + a.x * b._z;
		const tz = a.w * b._z - a.x * b._y + a.y * b._x;
		const tw = a.x * b._x + a.y * b._y + a.z * b._z;
		this._x = tx * a.w + tw * a.x + ty * a.z - tz * a.y;
		this._y = ty * a.w + tw * a.y + tz * a.x - tx * a.z;
		this._z = tz * a.w + tw * a.z + tx * a.y - ty * a.x;
	}

	/**
	 * Sets this to a vector normal to a, in the plane of 'a cross b', such that 'this dot b' is positive.
	 * @param {Vector3} a
	 * @param {Vector3} b
	 */
	setNormalTo(a, b) {
		const x = b._x * (a._y * a._y + a._z * a._z) - a._x * (a._y * b._y + a._z * b._z);
		const y = b._y * (a._z * a._z + a._x * a._x) - a._y * (a._z * b._z + a._x * b._x);
		const z = b._z * (a._x * a._x + a._y * a._y) - a._z * (a._x * b._x + a._y * b._y);
		this._x = x;
		this._y = y;
		this._z = z;
		this.normalize(this);
	}
}

/**
 * @type {Pool<Vector3>}
 */
const _pool = new Pool(Vector3);

const _zero = new Vector3();
_zero.freeze();

const _xAxis = new Vector3(1, 0, 0);
_xAxis.freeze();

const _yAxis = new Vector3(0, 1, 0);
_yAxis.freeze();

const _zAxis = new Vector3(0, 0, 1);
_zAxis.freeze();

const _xAxisNeg = new Vector3(-1, 0, 0);
_xAxis.freeze();

const _yAxisNeg = new Vector3(0, -1, 0);
_yAxis.freeze();

const _zAxisNeg = new Vector3(0, 0, -1);
_zAxis.freeze();

const _nan = new Vector3(Number.NaN, Number.NaN, Number.NaN);
_nan.freeze();
