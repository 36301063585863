/** @module pioneer */

/**
 * Waits until the testFunc returns true.
 * @param {() => boolean} testFunc - The testing function. Returning true stops the wait.
 * @param {number} testInterval - The interval in seconds to wait between tests.
 * @param {number} timeout - The time at which point the promise rejects.
 */
export async function waitUntil(testFunc, testInterval, timeout) {
	return new Promise((resolve, reject) => {
		let timeSoFar = 0;
		const intervalCheck = setInterval(() => {
			// Do the test.
			if (testFunc()) {
				clearInterval(intervalCheck);
				resolve();
			}
			// Increase the time we've waited.
			timeSoFar += testInterval;
			// If we've hit the timeout, reject.
			if (timeSoFar >= timeout) {
				clearInterval(intervalCheck);
				reject(new Error());
			}
		}, testInterval * 1000.0);
	});
}
