import screenfull from 'screenfull';
import { useEffect, useCallback, useState } from 'react';
import { useSnapshot } from 'valtio';

import { uiStore, videoStore } from '../managers/globalState';

import LightToggle from './light_toggle';
import SettingsMenu from './settings_menu';

import FullScreenButtonOnSVG from '../../www/assets/images/full_screen_button_on.svg';
import FullScreenButtonOffSVG from '../../www/assets/images/full_screen_button_off.svg';
import SpeedButton from './speed_button';

import '../../www/assets/css/controls_bar.css';
import '../../www/assets/css/date_time.css';
import { resetCamera } from './master/CameraMaster';

const Controls = () => {
	const { controlsMenu, hideFullScreenToggle: hideFullScreenFromURL } = useSnapshot(uiStore.state);
	const { currentVideo } = useSnapshot(videoStore.state);

	const [isFullscreen, setIsFullscreen] = useState(screenfull.isFullscreen || false);

	const timeControlsVisible = controlsMenu === 'time';
	const settingsVisible = controlsMenu === 'settings';
	const showFullScreenToggle = hideFullScreenFromURL !== 'true';

	// useEffect to detct fullscreen
	useEffect(() => {
		if (!screenfull.isEnabled) {
			return;
		}

		// Fullscreen change handler.
		const onFullscreenChange = () => {
			// Update the local state.
			setIsFullscreen(screenfull.isFullscreen);

			// Reset the camera.
			resetCamera();
		};

		screenfull.on('change', onFullscreenChange);

		// Listen to F11 key event to detect manual key fullscreen
		const keyUpHandler = event => {
			if (event.code === 'F11') {
				screenfull.toggle();
			}
		};

		document.addEventListener('keyup', keyUpHandler);

		// Cleanup screenfull change and keyup event listener.
		return () => {
			screenfull.off('change', onFullscreenChange);
			document.removeEventListener('keyup', keyUpHandler);
		};
	}, []);

	const toggleTimeControls = useCallback(() => {
		uiStore.setGlobalState({ controlsMenu: timeControlsVisible ? null : 'time' });
	}, [timeControlsVisible]);

	const toggleSettings = useCallback(() => {
		uiStore.setGlobalState({ controlsMenu: settingsVisible ? null : 'settings' });
	}, [settingsVisible]);

	const fullScreenSvg = isFullscreen ? <FullScreenButtonOffSVG /> : <FullScreenButtonOnSVG />;
	const showSpeedButton = !currentVideo;

	return (
		<div id='controls-bar' className='app-tools d-none d-lg-flex'>
			<ul className='mb-0 d-flex justify-content-around container-fluid'>
				{ showSpeedButton
					&& (
						<li>
							<div id='speed-button-container' onClick={toggleTimeControls} title='Satellite Speed'>
								<SpeedButton enabled={timeControlsVisible} />
							</div>
						</li>
					)}
				<li>
					<SettingsMenu toggleSettings={toggleSettings} />
				</li>
				<li>
					<LightToggle />
				</li>
				<li>
					{
						showFullScreenToggle && screenfull.isEnabled
						&& (
							<button type='button' aria-label='toggle-fullscreen' className={'toggle-fullscreen' + (isFullscreen ? '' : ' secondary')} value='toggle' onClick={() => screenfull.isEnabled && screenfull.toggle()}>
								{fullScreenSvg}
							</button>
						)
					}
				</li>
			</ul>
		</div>
	);
};

export default Controls;
