import React from 'react';
import '../../www/assets/css/geology_map_legend.css';
class GeologyMapLegend extends React.Component {
	constructor(props) {
		super(props);
  }
  render(){
    const current = this.props.current
    if(current && current.legend){
      const legend = current.legend;
      return <div className='geo-legend'>
                <p style={{fontSize:'20px',textAlign:'center'}}>{legend.title}</p>
                <table className='leg-table' >
                    <tbody>
                    {legend.data &&  legend.data.map((l,i)=> 
                        <tr key={i}>
                            <td className='leg-td' style={{background: l.color ,padding: '0em 1em'}}>{l.letter ?? ""}</td>
                            <td className='leg-td' style={{paddingLeft:'0.5em',fontSize:'20px'}}>{l.text}</td>
                        </tr>
                        
                        )
                    }
                    </tbody>
                </table>
            </div>
    } else {
      console.log("legend not defined: ", current)
      return null
    }
  }
}
export default GeologyMapLegend;
