import { useEffect, useState } from 'react';

import ContentManager from '../../managers/content_manager';
import TimeManager from '../../managers/time_manager';
import SceneManager from '../../managers/scene_manager';
import VideoManager from '../../managers/video_manager';
import CameraManager from '../../managers/camera_manager';
import LabelManager from '../../managers/label_manager';
import RouteManager from '../../managers/route_manager';
import DatasetManager from '../../managers/dataset_manager';
import ComparisonManager from '../../managers/comparison_manager';
import TileManager from '../../managers/tile_manager';
import VisibleEarthManager from '../../managers/visible_earth_manager';

import VITALS_DATA from '../../data/vitals_data';
import globalRefs, { setGlobalRef } from '../../managers/globalRefs';

/**
 * Managers are created once when the app loads.
 * We can't simply import/export and manager object as most of them are reliant on
 * other things (eg. Pioneer engine).
 * Once all the managers are created, we can put a getManager reference in global state
 * to let us access them from everywhere
 */

// Managers.
const managers = {};

// Manager types.
const managerTypes = {
	time: TimeManager,
	content: ContentManager,
	scene: SceneManager,
	comparison: ComparisonManager,
	label: LabelManager,
	visibleEarth: VisibleEarthManager,
	video: VideoManager,
	route: RouteManager,
	dataset: DatasetManager,
	camera: CameraManager
};

// Add and init manager.
const addManager = (type, ...args) => {
	const TypeConstructor = managerTypes[type];

	if (managers[type] === undefined) {
		managers[type] = new TypeConstructor(...args);
		managers[type].init?.();
	}
	return managers[type];
};

// Get manager.
const getManager = type => managers[type];


// Tile manager
const tileManagers = {};

// Get tile manager.
const getTileManager = type => tileManagers[type];

/**
 * ManagerMaster component
 *
 * The managers need pioneer to be loaded before they can be created.
 *
 * The managers each have a constructor and an init method, which should
 * remain synchronous.
 * When adding the managers, we need to make sure we dont try to access
 * other managers from either the constructor or the init.
 * In the init, we can set up subscriptions and event listeners.
 *
 * Once all the managers are created, we can call postInit methods on them.
 * The postInit methods will set states and can reference other managers.
 *
 */
const ManagerMaster = ({ children }) => {
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		// Make sure pioneer is loaded.
		if (!isLoaded) {
			// Add tile managers separatly since they require unique id such as sea surface WMTS manager
			for (let i = 0; i < VITALS_DATA.length; i += 1) {
				const { datasetGroups } = VITALS_DATA[i];
				for (let j = 0; j < datasetGroups.length; j += 1) {
					const { datasets } = datasetGroups[j];
					for (let k = 0; k < datasets.length; k += 1) {
						const { tilableTexture, externalId } = datasets[k];
						if (tilableTexture) {
							tileManagers[externalId] = new TileManager(externalId);
						}
					}
				}
			}

			// Add getTileManager reference to global state.
			setGlobalRef('getTileManager', getTileManager);

			const { pioneer } = globalRefs;

			// Add managers.
			Object.keys(managerTypes).forEach(type => addManager(type, pioneer));

			// Add getManager reference to global state.
			setGlobalRef('getManager', getManager);
			window.getManager = getManager;

			setIsLoaded(true);
		}
	}, [isLoaded]);

	// Return children if isLoaded is true.
	return isLoaded ? children : null;
};

export default ManagerMaster;
