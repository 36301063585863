import { useState, useEffect } from 'react';

import throttle from 'lodash.throttle';


export default function useScrolledToEnd(elementRef) {
	const [reachedEnd, setReachedEnd] = useState(false);

	useEffect(() => {
		const { current: element } = elementRef;
		if (element) {
			// create throttled handler
			const onScrollThrottled = throttle(() => {
				const padding = 200;
				const scrollTopThreshold = element.scrollHeight - element.clientHeight - padding;
				setReachedEnd(element.scrollTop > scrollTopThreshold);
			}, 300);
			// set resize listener
			element.addEventListener('scroll', onScrollThrottled);

			return () => {
			// remove resize listener
				element.removeEventListener('scroll', onScrollThrottled);
			};
		}
	});

	return reachedEnd;
}
