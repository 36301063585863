/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';

/** A controller that zooms the camera to fit a list of entities within view. */
export class ZoomFitController extends Pioneer.BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Pioneer.Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The list of entities and their factors.
		 * @type {Pioneer.FastMap<string, { ref: Pioneer.EntityRef }>}
		 * @private
		 */
		this._entities = new Pioneer.FastMap();

		/**
		 * The flag that determines if the fit radius will take into account the point of view of the camera for a tighter fit.
		 * @type {boolean}
		 * @private
		 */
		this._tightFit = false;

		/**
		 * The flag that determines if the camera will zoom out only, or both in and out.
		 * @type {boolean}
		 * @private
		 */
		this._zoomOutOnly = false;

		/**
		 * The edge size as a fraction of the minimum of the viewport width and height.
		 * @type {number}
		 * @private
		 */
		this._edgeSize = 0;

		this.addModifiedState('position');
	}

	/**
	 * Adds an entity with mult and add factors.
	 * @param {string} entityName - The entity whose position to use.
	 */
	addEntity(entityName) {
		// Set the ref.
		const ref = new Pioneer.EntityRef(this.getEntity().getScene());
		ref.setName(entityName);

		// Add it to the list.
		this._entities.set(entityName, { ref });

		// Mark that this is dependent on the position of the entity.
		this.addDependentState(entityName, 'position');
	}

	/**
	 * Removes an entity.
	 * @param {string} entityName
	 */
	removeEntity(entityName) {
		// Remove it from the list.
		this._entities.delete(entityName);

		// Mark that this is no longer dependent on the position of the entity.
		this.removeDependentState(entityName, 'position');
	}

	/**
	 * Sets the flag that determines if the fit radius will take into account the point of view of the camera for a tighter fit.
	 * @param {boolean} tightFit
	 */
	setTightFit(tightFit) {
		this._tightFit = tightFit;
	}

	/**
	 * Sets the flag that determines if the camera will zoom out only, or both in and out.
	 * @param {boolean} zoomOutOnly
	 */
	setZoomOutOnly(zoomOutOnly) {
		this._zoomOutOnly = zoomOutOnly;
	}

	/**
	 * Sets the edge size as a fraction of the minimum of the viewport width and height.
	 * @param {number} edgeSize
	 */
	setEdgeSize(edgeSize) {
		this._edgeSize = edgeSize;
	}

	/**
	 * Updates the position.
	 * @override
	 * @package
	 */
	__update() {
		// Get the sin and tan field of view variables for distance calculations.
		let tanHalfFov = 1;
		const camera = this.getEntity().getComponentByClass(Pioneer.CameraComponent);
		if (camera !== null) {
			const fieldOfView = Math.min(camera.getHorizontalFieldOfView(), camera.getVerticalFieldOfView());
			if (this._tightFit) {
				tanHalfFov = Math.tan(fieldOfView / 2.0);
			}
			tanHalfFov = Math.tan(fieldOfView / 2.0);
		}

		// Get the direction upon which we'll be zooming.
		// It will be the unit vector of the camera's position, or the camera's backward if the position vector is 0.
		const direction = Pioneer.Vector3.pool.get();
		direction.normalize(this.getEntity().getPosition());
		if (direction.magnitudeSqr() === 0) {
			this.getEntity().getOrientation().getAxis(direction, 1);
			direction.neg(direction);
		}
		if (direction.isNaN()) {
			direction.copy(Pioneer.Vector3.YAxisNeg);
		}

		// Get the edge factor that widens the view appropriately depending on the field of view.
		const factor = (1 + this._edgeSize / (0.5 - this._edgeSize)) / tanHalfFov;

		// Get the distance the camera should be at to see everything.
		let maxDistance = 0;
		const position = Pioneer.Vector3.pool.get();
		for (let i = 0, l = this._entities.size; i < l; i++) {
			const entry = this._entities.getAt(i).value;

			// Get the entity.
			const entity = entry.ref.get();
			if (entity === null) {
				continue;
			}

			// Get the position of the entity relative to the current parent.
			entity.getPositionRelativeToEntity(position, Pioneer.Vector3.Zero, this.getEntity().getParent());

			// Get the extents radius of the entity.
			const radius = entity.getExtentsRadius();

			// Get the distance that we should be away from this entity.
			let distance = 0;
			if (this._tightFit) {
				// Get the position part along the direction.
				const distanceAlongDirection = position.dot(direction);

				// Get the position part along the plane normal to the direction.
				position.addMult(position, direction, -distanceAlongDirection);
				const distanceAlongPane = position.magnitude();

				// Get the distance to zoom back for this object.
				distance = distanceAlongDirection + factor * distanceAlongPane + radius * Math.sqrt(factor * factor + 1);
			}
			else {
				// It's not using the tight-fit flag, so we're just going to use the distances for the (dist + radius)
				//   and do the distance calculation after the loop.
				distance = position.magnitude() + radius;
			}

			// Max out the distance needed to see all of the entities.
			maxDistance = Math.max(maxDistance, distance);
		}
		Pioneer.Vector3.pool.release(position);

		// If it's not a tight fit, the maxDistance is just the max (dist + radius) all entities, so apply the equation to get the distance to zoom back.
		if (!this._tightFit) {
			maxDistance = maxDistance * Math.sqrt(factor * factor + 1);
		}

		// If we're either zooming out or we can zoom in,
		if (!this._zoomOutOnly || maxDistance > this.getEntity().getPosition().magnitude()) {
			// Add the distance along the backward vector to the position.
			direction.mult(direction, maxDistance);

			// Set the position.
			this.getEntity().setPosition(direction);
		}
		Pioneer.Vector3.pool.release(direction);
	}
}
