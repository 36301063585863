import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnapshot } from 'valtio';
import CalendarAltSolidSVG from '../../www/assets/images/calendar_alt_solid.svg';
import CloseButton from './close_button';
import DatePicker from './date_picker';
import DetailPanelLeft from './detail_panel_left';
import DetailPanelRight from './detail_panel_right';
import Tabs from './tabs';
import Select from './select';
import SCZoomButton from './sc_zoom_button';
import { getDatasetFromVitalsData, getSpacecraftInstrumentData } from '../helpers/tools';
import ar from '../languages/ar';

import '../../www/assets/css/detail_overlay.css';
import { compareStore, eventsStore, datasetStore, videoStore, layersStore, uiStore, spacecraftStore, previewStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';

const onCloseButtonClick = () => {
	window.scroll(0, 0);
	uiStore.setGlobalState({ isDetailPanelOpen: false, videoMobileBackTo: null });
};

const SatellitesNowContent = () => {
	const { blueMarble } = layersStore.stateSnapshot;

	const onCloudFreeToggle = useCallback(() => {
		// Set blue marble global state.
		layersStore.setGlobalState({ blueMarble: !blueMarble });

		// Trigger on close button click.
		onCloseButtonClick();
	}, [blueMarble]);

	return (
		<div className='section'>

			<div
				className='mobile-zoom-button' onClick={onCloudFreeToggle}
			>
				<span>Cloud-free Earth</span>
				<input className='checkbox' type='checkbox' defaultChecked={blueMarble} />
				<span className='pseudo-checkbox' />
			</div>
		</div>
	);
};

const VitalSignContent = ({ displayData }) => {
	// Global state.
	const { videoMobileBackTo } = useSnapshot(uiStore.state);
	const { currentDataset, isVisibleEarth } = useSnapshot(datasetStore.state);
	const { isSatellitesNow } = useSnapshot(spacecraftStore.state);
	const { currentVideo } = useSnapshot(videoStore.state);


	// Variables.
	const { getManager } = globalRefs;
	const { category, subtitle } = displayData;
	const { externalId: datasetId, urlParam: datasetUrlParam } = currentDataset || {};
	const { urlParam: videoUrlParam } = currentVideo || {};
	const { isLatest, isLatestSeven } = getManager('dataset');

	// Alt dataset dropdown
	const additionalDatasetsAndVideos = useMemo(() => {
		const { getManager } = globalRefs;
		const { datasetGroup, vitalData } = getDatasetFromVitalsData(datasetId) || {};
		return datasetGroup
			? [...datasetGroup.datasets, ...getManager('video').getVideoDropdownEntries(vitalData?.value)]
			: [];
	}, [datasetId]);

	const selectedDataset = additionalDatasetsAndVideos.findIndex(dataset => {
		if (currentVideo) {
			return dataset.id === videoUrlParam;
		} else {
			return dataset.urlParam === datasetUrlParam;
		}
	});

	const actionButtonText = isVisibleEarth && !isSatellitesNow ? 'Satellites Now' : 'Visible Earth';
	const actionButtonId = `${actionButtonText.toLowerCase().replace(' ', '-')}`;

	const isVitalSign = category === 'Vital Signs';

	const goToLatest = () => {
		onCloseButtonClick();
		getManager('dataset').resetToLatest();
	};

	const goToLatestSeven = () => {
		onCloseButtonClick();
		getManager('dataset').playLatestSeven();
	};

	const actionButtonOnClick = useCallback(() => {
		const { getManager } = globalRefs;
		onCloseButtonClick();
		getManager('route').navigateToDataset({ vitalSignParam: isVisibleEarth && !isSatellitesNow ? 'satellites' : 'visible-earth' });
	}, [isVisibleEarth, isSatellitesNow]);

	const navigateToAltDataset = useCallback(e => {
		const { isVideo, urlParam } = additionalDatasetsAndVideos[e];
		const { getManager } = globalRefs;
		const routeManager = getManager('route');

		onCloseButtonClick();
		if (isVideo) {
			const { type } = getManager('video').getVideo(urlParam);
			const backToDetailOverlay = type === 'modal' || type === 'static';

			routeManager.navigateToVideo(urlParam);

			if (backToDetailOverlay) {
				uiStore.setGlobalState({ videoMobileBackTo: 'detail' });
			}
		} else {
			routeManager.navigateToDataset({ datasetParam: urlParam });
		}
	}, [additionalDatasetsAndVideos]);

	return (
		<>
			{ isVitalSign
			&& (
				<>
					{ subtitle && <div className='tab-subtitle'>{subtitle}</div> }
					<div className='section'>
						<div className={'section' + (isLatest ? ' selected' : '')} onClick={goToLatest}>
							Latest Dataset
						</div>
						<div className={'section' + (isLatestSeven ? ' selected' : '')} onClick={goToLatestSeven}>
							Latest 7 Datasets
						</div>
					</div>
					<div className='section'>
						<Select
							mode='toggle'
							title='Choose Dates'
							className='no-arrow'
							arrowAfter
							icon={<CalendarAltSolidSVG />}
							element={<DatePicker />}
						/>
					</div>
				</>
			) }
			{ isSatellitesNow && <SatellitesNowContent /> }
			{ (isVisibleEarth || isSatellitesNow)
				&& (
					<div className='section'>
						<button
							data-value={actionButtonId}
							id={`${actionButtonId}-button`}
							className='action-button'
							onClick={actionButtonOnClick}
						>
							{actionButtonText}
						</button>
					</div>
				)}
			{ additionalDatasetsAndVideos.length > 1
				&& (
					<div className='section'>
						<Select
							mode='toggle'
							isOpen={videoMobileBackTo === 'detail'}
							title='مجموعة بيانات إضافية والمزيد'
							className='no-arrow'
							arrowAfter
							defaultValue={selectedDataset}
							onChange={navigateToAltDataset}
							options={additionalDatasetsAndVideos.map((menuItem, index) => ({
								value: index,
								text: menuItem.menuTitle,
								category: menuItem.category
							}))}
						/>
					</div>
				)}
		</>
	);
};

const SpacecraftContent = () => {
	// Global state.
	const { spacecraftId, isZoomedOut } = useSnapshot(spacecraftStore.state);
	const { compareObjName } = useSnapshot(compareStore.state);

	// Variables.
	const { getManager } = globalRefs;
	const { compareList } = getManager('comparison');
	const relatedDatasets = getManager('content').getRelatedDatasets(spacecraftId);
	const { parentOfInstrument: isInstrument } = getSpacecraftInstrumentData(spacecraftId) || {};

	const showCompareDropdown = !isZoomedOut && !isInstrument;
	const selectedIndex = compareList.includes(compareObjName) ? compareList.indexOf(compareObjName) : compareList.length - 1;

	// Mobile compare change handler.
	const handleMobileCompare = useCallback(selectedIndex => {
		const { getManager } = globalRefs;
		getManager('comparison').setCompareEntity(compareList[selectedIndex]);
		onCloseButtonClick();
	}, [compareList]);

	return (
		<>
			<div className='section'>
				<SCZoomButton isMobile />
			</div>
			{
				showCompareDropdown
			&& (
				<div className='section'>
					<Select
						mode='toggle'
						title='مقارنة الحجم'
						defaultValue={selectedIndex}
						onChange={handleMobileCompare}
						options={compareList.map((compareObjName, index) => ({
							value: index,
							text: compareObjName
						}))}
					/>
				</div>
			)
			}
			{
				relatedDatasets.length !== 0
			&& (
				<div className='section'>
					<DetailPanelRight />
				</div>
			)
			}
		</>
	);
};

const OptionsTabContent = ({ displayData }) => {
	const { currentDataset } = useSnapshot(datasetStore.state);
	const { currentSpacecraft } = useSnapshot(spacecraftStore.state);

	return (
		<div className='content-container'>
			{currentSpacecraft ? <SpacecraftContent /> : (currentDataset && <VitalSignContent displayData={displayData} />)}
		</div>
	);
};


const MobileDetailOverlay = () => {
	// Local state.
	const [displayData, setDisplayData] = useState(null);

	// Global state.
	const { isDetailPanelOpen, videoMobileBackTo } = useSnapshot(uiStore.state);
	const { currentEvent } = useSnapshot(eventsStore.state);
	const { previewEventData } = useSnapshot(previewStore.state);
	const { currentDataset, currentIndex } = useSnapshot(datasetStore.state);
	const { currentVideo, isVideoTexture } = useSnapshot(videoStore.state);
	const { currentSpacecraft, isSatellitesNow } = useSnapshot(spacecraftStore.state);

	// useEffect to pause/unpause animation when the detail panel is opened/closed.
	useEffect(() => {
		const { datasetHasAnimation } = datasetStore.stateSnapshot;
		const shouldPause = isDetailPanelOpen && datasetHasAnimation;
		const { getManager } = globalRefs;

		getManager('dataset').setPaused(shouldPause);
	}, [isDetailPanelOpen]);

	// useEffect to update the state when the currentDataset changes.
	useEffect(() => {
		const { getManager } = globalRefs;
		const contentManager = getManager('content');
		let newDisplayData;

		if (currentEvent) {
			newDisplayData = contentManager.getEventDisplayData();
		} else if (previewEventData) {
			newDisplayData = contentManager.getEventDisplayData({ preview: true });
		} else if (currentSpacecraft) {
			newDisplayData = contentManager.getSpacecraftDisplayData();
		} else if (currentVideo && isVideoTexture) {
			newDisplayData = contentManager.getVideoDisplayData();
		} else if (isSatellitesNow) {
			newDisplayData = contentManager.getSatellitesNowDisplayData();
		} else if (currentDataset) {
			newDisplayData = contentManager.getDatasetDisplayData();
		}

		// set the displayData state.
		newDisplayData && setDisplayData(newDisplayData);
	}, [
		isSatellitesNow,
		currentDataset,
		currentEvent,
		previewEventData,
		currentSpacecraft,
		currentVideo,
		isVideoTexture,
		currentIndex
	]);

	const activeTab = videoMobileBackTo === 'detail' ? 1 : 0;

	return (
		isDetailPanelOpen && (
			<div className='overlay detail-overlay scrollable'>
				<div className='header'>
					<div className='title'>{displayData?.title}</div>
					<CloseButton responsive onClick={onCloseButtonClick} />
				</div>
				<Tabs activeIndex={activeTab}>
					<div label={ar.info1}>
						<DetailPanelLeft />
					</div>
					{
						!currentEvent
						&& (
							<div label='خيارات'>
								<OptionsTabContent displayData={displayData} />
							</div>
						)

					}
				</Tabs>
			</div>
		)

	);
};

export default MobileDetailOverlay;
