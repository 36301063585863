import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import EventsOverlay from './events_overlay';
import PoIOverlay from './poi_overlay';
import GmapOverlay from './gmap_overlay';
import VitalsOverlay from './vitals_overlay';
import SettingsOverlay from './settings_overlay';
import MissionsOverlay from './missions_overlay';
import MobileDetailOverlay from '../mobile_detail_overlay';
import AboutOverlay from './about_overlay';
import ChartOverlay from './chart_overlay';
import { OverlayHeader, OverlayNoResults ,OverlayComingSoon} from './sub_components';

import '../../../www/assets/css/overlays/base_overlay.css';
import { eventsStore } from '../../managers/globalState';

/**
 * The job of this overlays index file is to import and organize the overlay-specific components into sub-components
 * (read more here: https://reactjs.org/docs/thinking-in-react.html)
 *
 * Each individual overlay can then be composed of these sub-components
 * as recommended in the docs: https://reactjs.org/docs/composition-vs-inheritance.html
 *
 * Choosing whether a component should be a overlay sub-component is dependent upon whether it is used exclusively in the overlay
 * or whether the component could be incorporated into other parts of the app.
 * For example, the overlay Header is used exclusively for the overlay, whereas the Dropdown could be used putside the overlay component.
 */

/**
 * Exported function to determine which overlay to render according to the passed type argument
 * @param {string} type
 * @param {object} props
 * @returns {Component|null}
 */
export const getOverlay = (type, props) => {
	
	//reset animatedDate 
	// TODO: Check if this is the best place; it works for now.
	eventsStore.setGlobalState({ animatedDate: null });
	//reset eventInterval if exists
	if(window.eventInterval){
		clearInterval(window.eventInterval)
	}
	

	const overlays = {
		events: <EventsOverlay {...props} />,
		poi: <PoIOverlay {...props} />,
		gmap: <GmapOverlay {...props} />,
		vitals: <VitalsOverlay {...props} />,
		settings: <SettingsOverlay {...props} />,
		missions: <MissionsOverlay {...props} />,
		mobile: <MobileDetailOverlay {...props} />,
		about: <AboutOverlay {...props} />,
		chart: <ChartOverlay {...props} />
	};

	return overlays[type] || null;
};


/**
 * Overlay component
 */
const Overlay = forwardRef((props, ref) => (
	<div ref={ref} className={`overlay ${props.type}-overlay`}>
		{ props.children }
	</div>
));

/**
 * Overlay prop types
 */
Overlay.propTypes = {
	children: PropTypes.node.isRequired
};

/**
 * Overlay Sub-component organization
 */
Overlay.Header = OverlayHeader;
Overlay.NoResults = OverlayNoResults;
Overlay.ComingSoon = OverlayComingSoon;
export default Overlay;
