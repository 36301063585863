/* eslint-disable camelcase */
import { useCallback, useState } from 'react';
import { useSnapshot } from 'valtio';

import CloseButton from './close_button';

import { datasetStore, eventsStore, spacecraftStore, videoStore, uiStore,poiStore, gmapStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';
import VITALS_DATA from '../data/vitals_data';

import '../../www/assets/css/side_menu.css';
import SpeedButton from './speed_button';

import SpacecraftSVG from '../../www/assets/images/spacecraft.svg';
import LayerGroupSolidSVG from '../../www/assets/images/layer_group_solid.svg';
import TimeRateControls from './time_rate_controls';
import ar from '../languages/ar';


const MenuIcon = ({ showMissions }) => (
	<div className='side-icon'>
		{ showMissions ? <SpacecraftSVG /> : <LayerGroupSolidSVG /> }
	</div>
);

const SideMenu = () => {
	const [isOpen, setIsOpen] = useState(false);
	const { controlsMenu } = useSnapshot(uiStore.state);
	const { getManager } = globalRefs;
	const { currentVS } = useSnapshot(datasetStore.state);
	const { spacecraftList, currentSpacecraft, spacecraftId, isSatellitesNow } = useSnapshot(spacecraftStore.state);
	const { currentEvent, eventsVisibleOnGlobe } = useSnapshot(eventsStore.state);
	const { currentVideo } = useSnapshot(videoStore.state);

	const showMissions = ((currentSpacecraft || isSatellitesNow) && spacecraftList);
	const showVitals = Boolean(currentVS);
	const onEvents = currentEvent || eventsVisibleOnGlobe;

	const enabled = !onEvents && (showMissions || showVitals);
	const toggleTimeControls = () => {
		uiStore.setGlobalState({ controlsMenu: controlsMenu === 'time' ? null : 'time' });
	};

	const onClick = useCallback(valueOrId => () => {
		const { getManager } = globalRefs;
		setIsOpen(false);
		if (showMissions) {
			getManager('route').navigateToSpacecraft(valueOrId);
		} else {
			getManager('route').navigateToDataset({ vitalSignParam: valueOrId });
		}
	}, [showMissions]);


	if (!enabled) {
		return null;
	}

	const filteredList = showMissions ?
		spacecraftList.filter(mission => mission.categories?.includes('operating') || mission.categories?.includes('planned')) :
		[...VITALS_DATA];

	const showSpeedButton = !currentVideo;

	if (!isOpen) {
		return (
			<div className='side-btn-container'>
				<div className='side-btn' onClick={() => setIsOpen(true)}><MenuIcon showMissions={showMissions} /></div>
				{showSpeedButton && <div className='side-btn' id='side-speed-btn' onClick={() => toggleTimeControls()}><SpeedButton /></div>}
				<TimeRateControls />
			</div>
		);
	}

	return (
		<div className='side-overlay scrollable'>
			<div className='side-content'>
				<header className='filter-bar'>
					<MenuIcon showMissions={showMissions} />
					<h2
						className='grid-title'
						id={showMissions ? 'missions-title' : 'vital-signs-title'}
					>
						{ showMissions ? ar.missions : ar.vital_signs }
					</h2>
					<CloseButton responsive onClick={() => setIsOpen(false)} />
				</header>
				<ul className='list-unstyled item-list'>
					{
						filteredList.map(({ constellationIds, value: vitalSignValue, title: displayTitle } = {}) => {
							let entityInfo = {};
							if (spacecraftId) {
								entityInfo = getManager('content').getEntityInfo(spacecraftId);
							}
							const matchingTitle = entityInfo?.name?.includes(displayTitle) || displayTitle.includes(entityInfo?.name);
							const matchingCurrentSpacecraft = matchingTitle && spacecraftId === entityInfo?.id;

							const selected = showMissions ? matchingCurrentSpacecraft : (!currentVideo && currentVS.value === vitalSignValue);
							return (
								<li
									key={vitalSignValue || displayTitle}
									className={`nav-item${selected ? ' selected' : ''}`}
									onClick={onClick(vitalSignValue || constellationIds[0])}
								>
									{displayTitle}
								</li>
							);
						})
					}
				</ul>
			</div>
		</div>
	);
};

export default SideMenu;
