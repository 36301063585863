import React from 'react';

class Tab extends React.PureComponent {
	constructor(props) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		const { index, onClick } = this.props;
		onClick(index);
	}

	render() {
		const { activeIndex, label, index } = this.props;

		return (
			<li
				className={'tab-label' + (activeIndex === index ? ' active' : '')}
				onClick={this.onClick}
			>
				{label}
			</li>
		);
	}
}

export default Tab;
