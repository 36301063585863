/** @module pioneer */
import {
	Color,
	Downloader,
	THREE,
	ThreeJsHelper
} from './internal';

/**
 * The texture loader. Responsible for loading all textures.
 */
export class TextureLoader extends THREE.Loader {
	/**
	 * Constructor.
	 * @param {Downloader} downloader - The download manager.
	 * @param {THREE.WebGLRenderer} renderer
	 */
	constructor(downloader, renderer) {
		super();

		/**
		 * The downloader.
		 * @type {Downloader}
		 * @protected
		 */
		this._downloader = downloader;

		/**
		 * The threejs renderer.
		 * @type {THREE.WebGLRenderer}
		 * @protected
		 */
		this._renderer = renderer;

		/**
		 * The cross origin attribute value use in loads.
		 * @type {string}
		 * @protected
		 */
		this._crossOrigin = 'anonymous';

		/**
		 * The default downloadPriority value when it isn't specified as a param in the load() function.
		 * This is required because the GLTFLoader doesn't allow for custom load calls.
		 * @type {number}
		 * @private
		 */
		this._defaultDownloadPriority = 0;

		/**
		 * The default useMipPaps flag when it isn't specified as a param in the load() function.
		 * This is required because the GLTFLoader doesn't allow for custom load calls.
		 * @type {boolean}
		 * @private
		 */
		this._defaultUseMipMaps = true;

		/**
		 * The path value used in loads.
		 * @type {string}
		 * @protected
		 */
		this._path = undefined;

		/**
		 * A pre-loaded 1x1 white texture.
		 * @type {THREE.Texture}
		 * @protected
		 */
		this._white = null;

		/**
		 * A pre-loaded 1x1 black texture.
		 * @type {THREE.Texture}
		 * @protected
		 */
		this._black = null;

		/**
		 * A pre-loaded 1x1 clear texture.
		 * @type {THREE.Texture}
		 * @protected
		 */
		this._clear = null;

		/**
		 * A pre-loaded 1x1 pink texture.
		 * @type {THREE.Texture}
		 * @protected
		 */
		this._pink = null;

		/**
		 * A pre-loaded 1x1 gray texture.
		 * @type {THREE.Texture}
		 * @protected
		 */
		this._gray = null;

		/**
		 * A ThreeJs texture loader for use by the load function.
		 * @type {THREE.TextureLoader}
		 * @protected
		 */
		this._threeJsTextureLoader = new THREE.TextureLoader();

		// Configure the loaders.
		this._threeJsTextureLoader.setCrossOrigin(this._crossOrigin);
		this._threeJsTextureLoader.setPath(this._path);

		// Setup the static pre-loaded textures.
		this._white = TextureLoader._newTexture(new Color(1, 1, 1, 1));
		this._black = TextureLoader._newTexture(new Color(0, 0, 0, 1));
		this._clear = TextureLoader._newTexture(new Color(0, 0, 0, 0));
		this._pink = TextureLoader._newTexture(new Color(1, 105 / 255, 180 / 255, 1));
		this._gray = TextureLoader._newTexture(new Color(0.4, 0.4, 0.4, 1));

		// Add this texture loader to the ThreeJs url handler. Complex loaders like GLTFLoader will use this.
		THREE.DefaultLoadingManager.addHandler(/.$/i, this);
	}

	/**
	 * Sets the default download priority value for when none is specified in the load() function.
	 * @param {number} downloadPriority
	 */
	setDefaultDownloadPriority(downloadPriority) {
		this._defaultDownloadPriority = downloadPriority;
	}

	/**
	 * Sets the default useMipMaps flag for when none is specified in the load() function.
	 * @param {boolean} useMipMaps
	 */
	setDefaultUseMipMaps(useMipMaps) {
		this._defaultUseMipMaps = useMipMaps;
	}

	/**
	 * Returns a promise that resolves when the cube texture is loaded and rejects if there is an error.
	 * @param {string} url - The url of the texture to load. Every instance of $FACE is replaced by one of ['posx', 'negx', 'posy', 'negy', 'posz', 'negz'].
	 * @param {number} downloadPriority - The priority of the download. Greater is higher priority.
	 * @param {boolean} useMipMaps - Whether or not to use mipmap textures. If true, only power-of-two textures are allowed.
	 * @returns {Promise<THREE.CubeTexture>}
	 */
	async loadCubeTexture(url, downloadPriority, useMipMaps) {
		const cubeTexture = new THREE.CubeTexture();
		const promises = [];
		for (let i = 0; i < 6; i++) {
			const newUrl = url.replace('$FACE', _cubeFaceNames[i]);
			promises.push(new Promise((resolve, reject) => {
				this.load(newUrl, (texture) => {
					resolve(texture);
				}, undefined, (message) => {
					reject(new Error(`Failed to load ${url}: ${message}`));
				}, downloadPriority, useMipMaps);
			}).then((texture) => {
				cubeTexture.images[i] = texture.image;
			}));
		}
		return Promise.all(promises).then(() => {
			cubeTexture.needsUpdate = true;
			return cubeTexture;
		});
	}

	/**
	 * Generates an environment map with proper cube UV.
	 * @param {THREE.Texture|THREE.CubeTexture} texture
	 * @returns {THREE.Texture}
	 */
	generateEnvMap(texture) {
		let cubemap = texture;
		// Transforms equirectangular texture to cubemap texture if it is not already a cubemap
		if (!(texture instanceof THREE.CubeTexture)) {
			const options = {
				depthBuffer: false,
				stencilBuffer: false,
				generateMipmaps: true,
				minFilter: THREE.LinearMipMapLinearFilter,
				magFilter: THREE.LinearFilter
			};
			cubemap = new THREE.WebGLCubeRenderTarget(512, options).fromEquirectangularTexture(this._renderer, texture).texture;
		}

		const pmremGenerator = new THREE.PMREMGenerator(this._renderer);
		pmremGenerator.compileEquirectangularShader();
		const envMap = pmremGenerator.fromEquirectangular(cubemap).texture;

		return envMap;
	}

	/**
	 * Loads a texture and calls the callbacks when it is done. The signature is to be compatible with THREE.TextureLoader.
	 * @param {string} url - The url of the texture to load.
	 * @param {(texture: THREE.Texture) => any} onLoad - The callback that is called when the texture is loaded.
	 * @param {(event: ProgressEvent<EventTarget>, request: XMLHttpRequest) => any} _onProgress - The callback that is called when there is progress loading the texture. It is currently unused.
	 * @param {(message: string) => any} onError - The callback that is called when there is an error loading the texture.
	 * @param {number} [downloadPriority] - The priority for the download. Greater is higher priority.
	 * @param {boolean} [useMipMaps] - Whether or not to use mipmap textures. If true, only power-of-two textures are allowed.
	 * @returns {THREE.Texture}
	 */
	load(url, onLoad, _onProgress, onError, downloadPriority, useMipMaps) {

		// Set the default downloadPriority value in case it wasn't specified as a param.
		if (downloadPriority === undefined) {
			downloadPriority = this._defaultDownloadPriority;
		}

		// Set the default download useMipMaps flag in case it wasn't specified as a param.
		if (useMipMaps === undefined) {
			useMipMaps = this._defaultUseMipMaps;
		}

		// Process the URL
		url = this._downloader.processUrl(url);

		// Handle preloaded cases
		let texture = /** @type {THREE.Texture} */(null);
		if (url === 'white') {
			texture = this._white;
		}
		else if (url === 'black') {
			texture = this._black;
		}
		else if (url === 'clear') {
			texture = this._clear;
		}
		else if (url === 'pink') {
			texture = this._pink;
		}
		else if (url === 'gray') {
			texture = this._gray;
		}
		if (texture !== null) {
			onLoad(texture);
		}
		else {
			// Detect video types from url
			if (url.includes('.mp4')) {
				// Controls through videoTexture.image
				const videoElement = document.createElement('video');
				videoElement.src = url;
				videoElement.muted = true;
				videoElement.playsInline = true;
				videoElement.loop = false;
				videoElement.crossOrigin = 'anonymous';

				texture = new THREE.VideoTexture(videoElement);
				texture.format = THREE.RGBAFormat;
				texture.flipY = false;
				texture.needsUpdate = true;

				// Download the video, using the given callbacks.
				this._downloader.download(url, true, downloadPriority).then((download) => {
					videoElement.src = URL.createObjectURL(new Blob([download.content], { type: download.mimeType }));
					videoElement.onerror = (event) => {
						ThreeJsHelper.destroyTexture(texture);
						onError(event.toString());
					};
					videoElement.oncanplaythrough = () => {
						onLoad(texture);
					};
					videoElement.load();
				});
			}
			else {

				// Create the texture.
				const imgElement = document.createElement('img');
				texture = new THREE.Texture(imgElement);
				texture.format = THREE.RGBAFormat;
				texture.flipY = false;
				texture.needsUpdate = true;

				texture.minFilter = useMipMaps ? THREE.LinearMipMapLinearFilter : THREE.LinearFilter;
				texture.generateMipmaps = useMipMaps;

				// Download the image, calling the given callbacks.
				this._downloader.download(url, true, downloadPriority).then((download) => {
					// Set the img src.
					imgElement.src = URL.createObjectURL(new Blob([download.content], { type: download.mimeType }));
					imgElement.crossOrigin = 'anonymous';

					imgElement.onerror = (event) => {
						ThreeJsHelper.destroyTexture(texture);
						onError(event.toString());
					};
					imgElement.onload = () => {
						onLoad(texture);
					};
				});
			}
		}

		return texture;
	}

	/**
	 * Gets the cross origin attribute value used in loads. Present to be compatible with THREE.TextureLoader.
	 * @returns {string}
	 * @internal
	 */
	// @ts-ignore
	get crossOrigin() {
		return this._crossOrigin;
	}

	/**
	 * Sets the cross origin attribute value used in loads. Present to be compatible with THREE.TextureLoader.
	 * @param {string} crossOrigin - The cross origin attribute value.
	 * @override
	 * @internal
	 */
	set crossOrigin(crossOrigin) {
		this._crossOrigin = crossOrigin;
		if (this._threeJsTextureLoader) {
			this._threeJsTextureLoader.setCrossOrigin(this._crossOrigin);
		}
	}

	/**
	 * Set method for the cross origin attribute. Present to be compatible with THREE.TextureLoader.
	 * @param {string} crossOrigin - The cross origin attribute value.
	 * @returns {this}
	 * @override
	 * @internal
	 */
	setCrossOrigin(crossOrigin) {
		this.crossOrigin = crossOrigin;
		return this;
	}

	/**
	 * Gets the path value used in loads. Present to be compatible with THREE.TextureLoader.
	 * @returns {string}
	 * @internal
	 */
	// @ts-ignore
	get path() {
		return this._path;
	}

	/**
	 * Sets the path value used in loads. Present to be compatible with THREE.TextureLoader.
	 * @param {string} path - The path value.
	 * @override
	 * @internal
	 */
	set path(path) {
		this._path = path;
		if (this._threeJsTextureLoader) {
			this._threeJsTextureLoader.setPath(this._path);
		}
	}

	/**
	 * Set method for the path value. Present to be compatible with THREE.TextureLoader.
	 * @param {string} path - The path value.
	 * @returns {this}
	 * @override
	 * @internal
	 */
	setPath(path) {
		this.path = path;
		return this;
	}

	/**
	 * Returns a new 1x1 pixel THREE.Texture from the color.
	 * @param {Color} color - The color of the single pixel.
	 * @returns {THREE.Texture}
	 * @protected
	 */
	static _newTexture(color) {
		const canvas = document.createElement('canvas');
		canvas.width = 1;
		canvas.height = 1;
		const context = canvas.getContext('2d');
		context.fillStyle = 'rgba(' + (color.r * 255) + ',' + (color.g * 255) + ',' + (color.b * 255) + ',' + (color.a * 255) + ')';
		context.fillRect(0, 0, 1, 1);
		return new THREE.CanvasTexture(canvas);
	}
}

/**
 * The cube face names for cube map texture loading.
 * @type {string[]}
 */
const _cubeFaceNames = [
	'posx',
	'negx',
	'posy',
	'negy',
	'posz',
	'negz'];
