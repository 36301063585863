import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnapshot } from 'valtio';

import { spacecraftStore, appStore, uiStore } from '../managers/globalState';

import ZoomOutSVG from '../../www/assets/images/zoom_out.svg';
import ZoomInSVG from '../../www/assets/images/zoom_in.svg';
import '../../www/assets/css/sc_zoom_button.css';
import ar from '../languages/ar';


/**
 * SCZoomButton is a leaf node that allows the toggling of the isZoomedOut global state.
 * @param {boolean} {isMobile}
 */
const SCZoomButton = ({ isMobile = false }) => {
	// Get reactive global state.
	const { isZoomedOut, currentSpacecraft } = useSnapshot(spacecraftStore.state);

	// Declare onClick handler.
	const onClick = useCallback(() => {
		const { isMobile } = appStore.stateSnapshot;
		const { isDetailPanelOpen } = uiStore.stateSnapshot;
		const newIsZoomedOut = !isZoomedOut;

		spacecraftStore.setGlobalState({ isZoomedOut: newIsZoomedOut });
		isMobile && uiStore.setGlobalState({ isDetailPanelOpen: !isDetailPanelOpen });
	}, [isZoomedOut]);

	// Don't render anything if we're not on a spacecraft.
	if (!currentSpacecraft) {
		return null;
	}

	return isMobile
		? (
			<div className='mobile-zoom-button' onClick={onClick}>
				<span>{ar.satellite_zoom}</span>
				<input className='checkbox' type='checkbox' checked={!isZoomedOut} readOnly />
				<span className='pseudo-checkbox' />
			</div>
		) :
		(
			<div onClick={onClick} className='zoom-button d-flex justify-content-center align-items-center'>
				{!isZoomedOut ? <ZoomOutSVG /> : <ZoomInSVG />}
			</div>
		);
};

/**
 * Prop types.
 * Note this will need updating once isMobile is in the global state.
 */
SCZoomButton.propTypes = {
	isMobile: PropTypes.bool
};

export default SCZoomButton;
