/** @module pioneer */
import {
	AtmosphereComponent,
	CameraComponent,
	Color,
	Entity,
	EntityRef,
	MaterialUtilsPhong,
	MaterialUtilsStandard,
	MathUtils,
	RingsComponent,
	THREE,
	Vector3
} from '../internal';

/** Material utilities. */
export class MaterialUtils {
	/**
	 * Sets the light source uniforms. lightPosition is in camera-space.
	 * @param {THREE.ShaderMaterial[]} materials
	 * @param {Entity} entity
	 * @param {CameraComponent} camera
	 */
	static setLightSourceUniforms(materials, entity, camera) {
		const scene = entity.getScene();
		const lightSourceColor = Color.pool.get();
		let lightSourceCount = 0;
		for (let i = 0, l = scene.getNumLightSources(); i < Math.min(l, 5); i++) {
			// Set the lightPosition and lightColor uniform.
			const lightSource = scene.getLightSource(i);
			if (lightSource !== null && lightSource !== entity.getComponentByType('lightSource')) {
				// Position
				const lightSourcePosition = lightSource.getEntity().getCameraSpacePosition(camera);
				// Flux
				// const lightSourcePositionRelEntity = Vector3.pool.get();
				// lightSource.getEntity().getPositionRelativeToEntity(lightSourcePositionRelEntity, Vector3.Zero, entity);
				// const flux = 4.0 * Math.pow(2.51188643151, 46.4205043102 - lightSource.getAbsoluteMagnitude()) / lightSourcePositionRelEntity.magnitudeSqr();
				// Vector3.pool.release(lightSourcePositionRelEntity);
				const flux = 1.0; // Note: The above accurately calculates the flux, but farther planets are too dark. Just setting it to one for now.
				// Color
				lightSourceColor.mult(lightSource.getColor(), flux);
				// Radius. Make the radius for the camera light infinite so that it doesn't cast any shadows.
				const lightRadius = lightSource.getEntity().getComponentByType('camera') === null
					? lightSource.getEntity().getOcclusionRadius()
					: -1.0;
				for (let j = 0, m = materials.length; j < m; j++) {
					if (materials[j].uniforms['lightPositions'] !== undefined) {
						materials[j].uniforms['lightPositions'].value[lightSourceCount].set(lightSourcePosition.x, lightSourcePosition.y, lightSourcePosition.z);
						materials[j].uniforms['lightColors'].value[lightSourceCount].set(lightSourceColor.r, lightSourceColor.g, lightSourceColor.b);
						materials[j].uniforms['lightRadii'].value[lightSourceCount] = lightRadius;
					}
				}
				lightSourceCount += 1;
			}
		}
		for (let j = 0, m = materials.length; j < m; j++) {
			if (materials[j].uniforms['numLights'] !== undefined) {
				materials[j].uniforms['numLights'].value = lightSourceCount;
			}
		}
		Color.pool.release(lightSourceColor);
	}

	/**
	 * Sets the materials' uniforms from the camera, entity, and light source.
	 * @param {THREE.ShaderMaterial[]} materials
	 * @param {CameraComponent} camera
	 * @param {Entity} entity
	 * @param {EntityRef[]} shadowEntities
	 * @param {AtmosphereComponent} atmosphere
	 * @param {boolean} isSpheroid
	 */
	static setUniforms(materials, camera, entity, shadowEntities, atmosphere, isSpheroid) {
		// Get the time.
		const time = entity.getScene().getEngine().getTime();

		// Get the ambient color.
		const ambientLightColor = entity.getScene().getAmbientLightColor();

		// Set the lightPosition and lightColor uniform.
		MaterialUtils.setLightSourceUniforms(materials, entity, camera);

		for (let i = 0, l = materials.length; i < l; i++) {
			/** @type {THREE.ShaderMaterial} */
			const material = materials[i];
			const uniforms = material.uniforms;

			if (material instanceof THREE.RawShaderMaterial) {
				if (uniforms['time'] !== undefined) {
					// Wrap it to the nearest hour, since the full time can't fit into a GLSL float variable.
					uniforms['time'].value = MathUtils.wrap(time, 0.0, 3600.0);
				}
			}
			else { // Regular ShaderMaterial from the MaterialUtils.
				// Set the entity position uniform.
				const position = entity.getCameraSpacePosition(camera);
				uniforms['entityPos'].value.set(position.x, position.y, position.z);

				// Set the uniforms that don't depend on a light source.
				uniforms['ambientLightColor'].value.setRGB(ambientLightColor.r, ambientLightColor.g, ambientLightColor.b);

				// For each shadow entity, apply the params.
				if (shadowEntities !== undefined && shadowEntities.length > 0) {
					let validShadowEntities = 0;
					for (let j = 0; j < shadowEntities.length; j++) {
						const shadowEntity = shadowEntities[j].get();
						if (shadowEntity !== null) {
							const position = shadowEntity.getCameraSpacePosition(camera);
							uniforms['shadowEntityPositions'].value[validShadowEntities].set(position.x, position.y, position.z);
							uniforms['shadowEntityRadii'].value[validShadowEntities] = shadowEntity.getOcclusionRadius();

							const atmosphereComponent = /** @type {AtmosphereComponent} */(shadowEntity.get('atmosphere'));
							if (atmosphereComponent !== null) {
								const sunsetColor = atmosphereComponent.getSunsetColor();
								uniforms['shadowEntitySunsetColors'].value[validShadowEntities].set(sunsetColor.r, sunsetColor.g, sunsetColor.b);
								uniforms['shadowEntitySunsetIntensity'].value[validShadowEntities] = atmosphereComponent.getSunsetIntensity();
							}
							else {
								uniforms['shadowEntitySunsetIntensity'].value[validShadowEntities] = 0.0;
							}
							validShadowEntities += 1;
						}
						else {
							uniforms['shadowEntityRadii'].value[validShadowEntities] = 0;
							uniforms['shadowEntitySunsetIntensity'].value[validShadowEntities] = 0.0;
						}
					}

					uniforms['numShadowEntities'].value = validShadowEntities;
				}

				if (materials[i].defines['shadowRings']) {
					const ringsComponent = /** @type {RingsComponent} */(entity.get('rings'));
					if (ringsComponent !== null) {
						const normal = Vector3.pool.get();
						entity.getOrientation().getAxis(normal, 2);
						uniforms['shadowRingsInnerRadius'].value = ringsComponent.getInnerRadius();
						uniforms['shadowRingsOuterRadius'].value = ringsComponent.getOuterRadius();
						uniforms['shadowRingsTexture'].value = ringsComponent.getTopTexture();
						uniforms['shadowRingsNormal'].value.set(normal.x, normal.y, normal.z);
						Vector3.pool.release(normal);
					}
				}
			}

			// Atmospheres
			if (atmosphere !== null && atmosphere.getLoadState() === 'loaded' && !atmosphere.isExcludedFromCamera(camera) && uniforms['atmospherePosition'] !== undefined) {
				const spheroid = atmosphere.getSpheroid();
				const atmospherePosition = atmosphere.getEntity().getCameraSpacePosition(camera);
				const atmosphereOrientation = atmosphere.getEntity().getOrientation();
				const atmosphereColor = atmosphere.getColor();
				const atmosphereSunsetColor = atmosphere.getSunsetColor();
				uniforms['atmospherePosition'].value.set(atmospherePosition.x, atmospherePosition.y, atmospherePosition.z);
				uniforms['atmosphereOrientation'].value.set(atmosphereOrientation.x, atmosphereOrientation.y, atmosphereOrientation.z, atmosphereOrientation.w);
				if (spheroid !== null) {
					uniforms['atmosphereEquatorialRadius'].value = spheroid.getEquatorialRadius();
					uniforms['atmospherePolarRadius'].value = spheroid.getPolarRadius();
				}
				else {
					uniforms['atmosphereEquatorialRadius'].value = 0;
					uniforms['atmospherePolarRadius'].value = 0;
				}
				uniforms['atmosphereDensity'].value = atmosphere.getDensity();
				uniforms['atmosphereScaleHeight'].value = atmosphere.getScaleHeight();
				uniforms['atmosphereEmissivity'].value = atmosphere.getEmissivity();
				uniforms['atmosphereColor'].value.set(atmosphereColor.r, atmosphereColor.g, atmosphereColor.b);
				uniforms['atmosphereSunsetColor'].value.set(atmosphereSunsetColor.r, atmosphereSunsetColor.g, atmosphereSunsetColor.b);
				uniforms['atmosphereSunsetIntensity'].value = atmosphere.getSunsetIntensity();
				uniforms['atmosphereGroundIsSpheroid'].value = isSpheroid ? 1 : 0;
				if (material.defines['atmosphere'] === undefined) {
					material.defines['atmosphere'] = true;
					material.needsUpdate = true;
				}
			}
			else if (material.defines['atmosphere'] === true) {
				delete material.defines['atmosphere'];
				material.needsUpdate = true;
			}
		}
	}
}

/**
 * Returns a specular/phong material.
 * @returns {THREE.ShaderMaterial}
 */
MaterialUtils.get = MaterialUtilsPhong.get;

/**
 * Returns a PBR material.
 * @returns {THREE.ShaderMaterial}
 */
MaterialUtils.getPBR = MaterialUtilsStandard.get;
