/** @module pioneer */
import {
	BaseController,
	Entity,
	EntityRef
} from '../../internal';

/**
 * A controller that sets the parent every frame. Not normally needed except when another controller sets the parent,
 * such as the transition controller.
 */
export class SetParentController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The parent to transition to.
		 * @type {EntityRef}
		 * @private
		 */
		this._parent = new EntityRef(this.getEntity().getScene());
	}

	/**
	 * Gets the parent to set.
	 * @returns {string}
	 */
	getParent() {
		return this._parent.getName();
	}

	/**
	 * Sets the parent to set.
	 * @param {string} parent
	 */
	setParent(parent) {
		if (this._parent.getName() !== '') {
			this.removeDependentState(this._parent.getName(), 'position');
		}
		this._parent.setName(parent);
		if (this._parent.getName() !== '') {
			this.addDependentState(this._parent.getName(), 'position');
		}
	}

	/**
	 * Updates the controller.
	 * @override
	 * @internal
	 */
	__update() {
		const parent = this._parent.get();
		this.getEntity().setParent(parent);
	}
}
