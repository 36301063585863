/* eslint-disable react/jsx-one-expression-per-line */
import { useEffect } from 'react';
import { useSnapshot } from 'valtio';

import { appStore, cameraStore, eventsStore, kioskStore, layersStore, lightStore, spacecraftStore, uiStore } from '../managers/globalState';
import globalRefs, { setGlobalRef } from '../managers/globalRefs';

import { formatCountdownTime } from '../helpers/tools';

import '../../www/assets/css/kiosk_mode.css';
import { SPACECRAFT_WITH_INSTRUMENTS } from '../data/spacecraft_data_ar';
import VITALS_DATA from '../data/vitals_data';

const INTERVAL_TIME = 100;
const SLIDE_COMMAND_TIMEOUT = 30000;
const END_MESSAGE_MIN_DISPLAY_TIME = 1000;
let SESSION_TIME = null;
let INACTIVITY_TIME = null;

const getSpacecraftId = spacecraftInfo => spacecraftInfo.constellationIds[0] || '';

// Filter out instruments and anything else here.
const filterFunction = spacecraftInfo => {
	for (const instrumentList of Object.values(SPACECRAFT_WITH_INSTRUMENTS)) {
		if (instrumentList.find(({ instrumentId }) => instrumentId === getSpacecraftId(spacecraftInfo))) {
			return false;
		}
	}

	// Demo debug to only show Cygnss 7.
	// if (spacecraftId.includes('cygnss_6')) {
	// 	return true;
	// }
	// return false;

	return true;
};

const AUTOPLAY_SLIDES = [
	{
		name: 'random_spacecraft',
		duration: 12000,
		command: () => new Promise(resolve => {
			// We need to further filter out spacecraft that are not in the spacecraftList.
			const { spacecraftList } = spacecraftStore.stateSnapshot;
			const filteredSpacecraftIds = spacecraftList.filter(filterFunction);

			const randomIndex = Math.floor(Math.random() * filteredSpacecraftIds.length);
			const randomSpacecraftId = getSpacecraftId(filteredSpacecraftIds[randomIndex]);

			if (!randomSpacecraftId) {
				resolve();
				return;
			}

			console.log(`Autoplay:: Slide 1 - Going to Random Spacecraft - ${randomSpacecraftId}...`);

			const { getManager } = globalRefs;
			getManager('route').navigateToSpacecraft(randomSpacecraftId);

			// Wait for the camera transition to finish.
			const transitionUnsubscribe = cameraStore.subscribeTo('isCameraTransitioning', isCameraTransitioning => {
				if (isCameraTransitioning === false) {
					transitionUnsubscribe();
					resolve();
				}
			});
		})
	},
	{
		name: 'compare_to_bus',
		duration: 8000,
		command: () => new Promise(resolve => {
			// It's important to make sure we're on a spacecraft.
			const { spacecraftId } = spacecraftStore.stateSnapshot;

			if (!spacecraftId) {
				resolve();
				return;
			}
			console.log(`Autoplay:: Slide 2 - Comparing ${spacecraftId} to a School Bus...`);

			const { getManager } = globalRefs;

			const compareSuccess = getManager('comparison').setCompareEntity('School Bus');
			if (!compareSuccess) {
				console.warn('Error comparing to School Bus');
				resolve();
				return;
			}

			// Wait for the camera transition to finish.
			const transitionUnsubscribe = cameraStore.subscribeTo('isCameraTransitioning', isCameraTransitioning => {
				if (isCameraTransitioning === false) {
					transitionUnsubscribe();
					resolve();
				}
			});
		})
	},
	{
		name: 'random_vitalSign',
		duration: 10000,
		command: () => new Promise(resolve => {
			const filteredVitalList = VITALS_DATA.filter(({ value }) => value !== 'satellites');
			const randomIndex = Math.floor(Math.random() * filteredVitalList.length);
			const { value: vitalSignParam } = filteredVitalList[randomIndex];
			const { getManager } = globalRefs;

			if (!vitalSignParam) {
				resolve();
				return;
			}

			console.log(`Autoplay:: Slide 3 - Going to Random Vital Sign - ${vitalSignParam}...`);

			getManager('route').navigateToDataset({ vitalSignParam });

			// Wait for the camera transition to finish.
			const transitionUnsubscribe = cameraStore.subscribeTo('isCameraTransitioning', isCameraTransitioning => {
				if (isCameraTransitioning === false) {
					transitionUnsubscribe();
					resolve();
				}
			});
		})
	}
];

const stopInactivityTimer = () => {
	const { inactivityTimeout } = kioskStore.stateSnapshot;
	clearTimeout(inactivityTimeout);
};

const startInactivityTimer = () => {
	// Clear previous inactivity timeout.
	stopInactivityTimer();

	console.log('Autoplay:: Activity detected. Starting inactivity timer...');

	const inactivityTimeout = setTimeout(() => {
		const { inAutoplay } = kioskStore.stateSnapshot;
		!inAutoplay && startAutoplay();
	}, INACTIVITY_TIME);

	kioskStore.setGlobalState({ inactivityTimeout });
};

const showEndMessage = duration => {
	kioskStore.setGlobalState({ showSessionEndMessage: true });

	setTimeout(() => {
		kioskStore.setGlobalState({ showSessionEndMessage: false });
	}, duration);
};

const endSession = () => {
	console.log('Kiosk:: Session ended.');
	// Clear the session interval.
	const { sessionInterval, forceRestart } = kioskStore.stateSnapshot;
	clearInterval(sessionInterval);

	if (forceRestart) {
		// Do a kiosk reset to home, wait an additional second.
		resetSession(END_MESSAGE_MIN_DISPLAY_TIME);
	} else {
		// Show session ended message withouot resetting app, wait an additional second.
		showEndMessage(END_MESSAGE_MIN_DISPLAY_TIME);
	}

	// Reset the global state.
	kioskStore.setGlobalState({
		userInSession: false,
		sessionInterval: null,
		sessionStartTime: null,
		formattedSessionTime: null
	});
};

const startSession = (reset = true) => {
	const sessionStartTime = Date.now();
	console.log(`Kiosk:: Session started at ${(new Date()).toLocaleTimeString()}`);

	if (reset) {
		// Set the pioneer time.
		const { getManager } = globalRefs;
		getManager('time').setTime(sessionStartTime);

		// Do a kiosk session reset.
		resetSession();

		// Set lighting to url lighting
		const { queries: { lighting: lightingFromURL } } = appStore.stateSnapshot;
		lightingFromURL && lightStore.setGlobalState({ fill: lightingFromURL === 'flood' });
	}

	// Start inactivity timer if autoplay is enabled.
	INACTIVITY_TIME && startInactivityTimer();

	// Set the initial formatted time to the max session time.
	const formattedTime = formatCountdownTime(SESSION_TIME, SESSION_TIME);

	// Make sure to clear any previous session interval.
	const { sessionInterval: prevSessionInterval } = kioskStore.stateSnapshot;
	clearInterval(prevSessionInterval);

	// Set the session interval.
	const sessionInterval = setInterval(() => {
		const { sessionStartTime, isKioskResetting } = kioskStore.stateSnapshot;

		/**
		 * If the session is loading or paused, extend the start time by the interval.
		 * (effectively pausing the session timer).
		 */
		if (isKioskResetting) {
			kioskStore.setGlobalState({ sessionStartTime: sessionStartTime + INTERVAL_TIME });
		}

		const currentTime = Date.now();
		const sessionDuration = currentTime - sessionStartTime;
		const timeRemaining = SESSION_TIME - sessionDuration;

		const formattedTime = formatCountdownTime(SESSION_TIME, timeRemaining);
		kioskStore.setGlobalState({ formattedSessionTime: formattedTime });

		// If the session duration is greater than 10 minutes, end the session.
		if (sessionDuration > SESSION_TIME) {
			endSession();
		}
	}, INTERVAL_TIME);

	kioskStore.setGlobalState({
		userInSession: true,
		sessionInterval,
		sessionStartTime,
		formattedSessionTime: formattedTime
	});
};

const clearAutoplayTimeouts = () => {
	const { nextAutoplaySlideTimeout, autoplayCommandTimeout } = globalRefs;
	clearTimeout(nextAutoplaySlideTimeout);
	clearTimeout(autoplayCommandTimeout);
};

// Reset time to default time (also resets the clock to now).
const resetTimeAndRate = () => {
	const { getManager } = globalRefs;
	const realtimeRate = false;
	const resetTimeToNow = true;

	getManager('time').setTimeAndRate(realtimeRate, resetTimeToNow);
};

const resetSession = (additionalWait = 0) => {
	const { getManager } = globalRefs;
	const { isCameraTransitioning } = cameraStore.stateSnapshot;

	// Set the isKioskResetting state.
	kioskStore.setGlobalState({ isKioskResetting: true });

	// Hide event and city placemarks, ground tracks
	layersStore.setGlobalState({ cityNames: false, groundTracks: false });
	eventsStore.setGlobalState({ eventsVisibleOnGlobe: false });

	// Close detail panels
	uiStore.setGlobalState({ isDetailPanelOpen: false, overlayType: null, controlsMenu: null });

	// Reset the kiosk route (back to home with correct queries)
	getManager('route').kioskReset();

	showEndMessage(additionalWait);

	// Create the complete reset function.
	const completeReset = () => {
		// Reset time to current time and default rate.
		resetTimeAndRate();

		// Set isKioskResetting to false after additionalWait.
		setTimeout(() => {
			// Set the isKioskResetting state.
			kioskStore.setGlobalState({ isKioskResetting: false });
		}, additionalWait);
	};


	// If we're transitioning, wait for the camera transition to finish.
	if (isCameraTransitioning) {
		const transitionUnsubscribe = cameraStore.subscribeTo('isCameraTransitioning', isCameraTransitioning => {
			if (isCameraTransitioning === false) {
				transitionUnsubscribe();
				completeReset();
			}
		});
	} else {
		// Otherwise, just complete the reset.
		completeReset();
	}
};

const endAutoplay = () => {
	console.log('Autoplay:: Autoplay ended.');
	// Clear the autoplay timeouts.
	clearAutoplayTimeouts();

	kioskStore.setGlobalState({
		inAutoplay: false,
		autoplayCurrentSlide: null
	});
};


const nextAutoplaySlide = () => {
	// Clear the autoplay timeouts.
	clearAutoplayTimeouts();

	// Set next slide state.
	const { autoplayCurrentSlide } = kioskStore.stateSnapshot;
	const startFromBeginning = autoplayCurrentSlide === null || autoplayCurrentSlide === AUTOPLAY_SLIDES.length - 1;
	const nextIndex = startFromBeginning ? 0 : autoplayCurrentSlide + 1;
	kioskStore.setGlobalState({ autoplayCurrentSlide: nextIndex });

	// If the index is zero (ie. first slide), resetTimeAndRate.
	nextIndex === 0 && resetTimeAndRate();

	// Create a timeout to go to the next slide if the command takes too long.
	const commandTimeoutPromise = new Promise(resolve => {
		const autoplayCommandTimeout = setTimeout(() => {
			console.warn('Autoplay command timed out.');
			resolve();
		}, SLIDE_COMMAND_TIMEOUT);
		setGlobalRef('autoplayCommandTimeout', autoplayCommandTimeout);
	}).then(() => nextAutoplaySlide());

	// Run the command, wait for it to finish, then set the timeout for the next slide.
	const { duration, command } = AUTOPLAY_SLIDES[nextIndex];
	console.log('Autoplay:: Next Slide...');

	const commandPromise = command()
		.then(() => {
			const { inAutoplay } = kioskStore.stateSnapshot;

			if (inAutoplay) {
				// Set the timeout for the next slide.
				const nextAutoplaySlideTimeout = setTimeout(() => {
					nextAutoplaySlide();
				}, duration);

				// Set the nextAutoplaySlideTimeout global ref.
				setGlobalRef('nextAutoplaySlideTimeout', nextAutoplaySlideTimeout);
			}
		});


	// Race the command and the timeout.
	Promise.race([commandPromise, commandTimeoutPromise])
		.catch(error => {
			console.warn('Autoplay::', error);
			nextAutoplaySlide();
		});
};

const resetAutoplay = () => {
	// Close overlays
	uiStore.setGlobalState({ overlayType: null, controlsMenu: null });

	// Turn off city markers & ground tracks
	layersStore.setGlobalState({ cityNames: false, groundTracks: false });

	// Turn on lighting
	lightStore.setGlobalState({ fill: true });
};

const startAutoplay = () => {
	console.log('Autoplay:: Autoplay started.');

	resetAutoplay();

	/**
	 * With the maxInactivityTime being greater than the maxSessionTime,
	 * we should technically never be in-session when autoplay is triggered.
	 */
	nextAutoplaySlide();

	kioskStore.setGlobalState({ inAutoplay: true });
};

const pauseAutoplay = () => {
	console.log('Autoplay:: Autoplay Paused.');
	// Clear the autoplay timeouts.
	clearAutoplayTimeouts();
};

const SessionLoader = () => <span className='session-loading'>Starting experience...</span>;

const Autoplay = () => {
	const { inAutoplay } = useSnapshot(kioskStore.state);

	// useEffect to set up pointer down and visibility change handlers.
	useEffect(() => {
		const onPointerDown = () => {
			const { inAutoplay } = kioskStore.stateSnapshot;
			if (inAutoplay) {
				// End autoplay.
				endAutoplay();
				// Start a new session.
				startSession(false);
			} else {
				startInactivityTimer();
			}
		};

		// Add the pointer down event listener.
		document.addEventListener('pointerdown', onPointerDown);

		return () => {
			// Clean up event listeners.
			document.removeEventListener('pointerdown', onPointerDown);
			// End autoplay.
			endAutoplay();
		};
	}, []);

	// useEffect to add visibility change event listener when inAutoplay changes.
	useEffect(() => {
		if (!inAutoplay) {
			return;
		}

		// Add the visibility change event listener.
		const onVisibilityChange = () => {
			if (document.visibilityState === 'hidden') {
				console.log('Autoplay:: Browser Tab Not in Focus - Pausing Autoplay...');
				pauseAutoplay();
			} else {
				console.log('Autoplay:: Browser Tab Back in Focus - Resuming Autoplay...');
				// Start it again.
				startAutoplay();
			}
		};

		document.addEventListener('visibilitychange', onVisibilityChange);

		return () => {
			// Clean up event listeners.
			document.removeEventListener('visibilitychange', onVisibilityChange);
		};
	}, [inAutoplay]);

	return inAutoplay && <button className='autoplay-button'>TOUCH TO START</button>;
};


const SessionEnded = () => {
	const { inAutoplay, forceRestart, showSessionEndMessage } = useSnapshot(kioskStore.state);

	// Use showSessionEndMessage when forceRestart=false bc there is no reset if no force restart
	return !inAutoplay && (
		<div className='session-ended-container'>
			<span className={`${showSessionEndMessage ? '' : 'hidden'}`}>Your session time is up</span>
			<div className='session-ended-button-container'>
				<button className={`${showSessionEndMessage ? 'hidden' : ''}`} onClick={() => startSession(!forceRestart)}>START OVER</button>
				{!forceRestart && <button className={`${showSessionEndMessage ? 'hidden' : ''}`} onClick={() => startSession(forceRestart)}>CONTINUE</button>}
			</div>
		</div>
	);
};

const InSession = () => {
	const { formattedSessionTime, isKioskResetting } = useSnapshot(kioskStore.state);
	return (
		<>
			<span className='time-left'>Time left: {formattedSessionTime}</span>
			{isKioskResetting && <SessionLoader />}
		</>
	);
};

const KioskMode = () => {
	const { isSceneReady } = useSnapshot(appStore.state);
	const { userInSession, inAutoplay, isKioskResetting, forceRestart, maxSessionTime, maxInactivityTime } = useSnapshot(kioskStore.state);

	useEffect(() => {
		if (maxSessionTime) {
			SESSION_TIME = maxSessionTime * 60000;
		}
		if (maxInactivityTime) {
			INACTIVITY_TIME = maxInactivityTime * 60000;
		}
	}, [maxSessionTime, maxInactivityTime]);

	// When scene is ready, start session.
	useEffect(() => {
		if (isSceneReady) {
			startSession();
		}
	}, [isSceneReady]);

	// On unmount, end session.
	useEffect(() => () => {
		endSession();
	}, []);


	// Determine if the kiosk is locked.
	const locked = SESSION_TIME && !userInSession && !inAutoplay;

	// Allow user to click anywhere to start session.
	const onClick = () => {
		if (locked && !isKioskResetting && forceRestart) {
			startSession();
		}
	};

	return (isSceneReady
		&& (
			<div className={`kiosk-container${locked ? ' locked' : ''}`} onClick={onClick}>
				{SESSION_TIME && (userInSession ? <InSession /> : <SessionEnded />)}
				{INACTIVITY_TIME && <Autoplay />}
			</div>
		)
	);
};

export default KioskMode;
