/* eslint-disable camelcase */
import { Entity } from 'pioneer-scripts';

import { getDateFromUTCDateStr, betweenDateRange, getDatasetFromVitalsData, formattedTimeSinceDate } from '../helpers/tools';
import { datasetStore, eventsStore, videoStore, spacecraftStore, previewStore, poiStore, gmapStore, stellarStore } from './globalState';
import globalRefs from './globalRefs';

import VITALS_DATA from '../data/vitals_data';

import ENTITY_INFO from '../data/entity_info.json';
import { MISSION_TIMES} from '../config/constants';
import { SATELLITES_NOW_DATA } from '../data/spacecraft_data_ar';

import { updateYear, getAllYears } from '../helpers/processEvents';
import { Config } from '../config/config';


import ar from '../languages/ar';
import en from '../languages/en';

import moment from 'moment-timezone';
import momenti from 'moment-hijri'; 

class Content {
	/**
	 * Constructor
	 * @param {object} pioneer Pioneer engine
	 * @private
	 */
	constructor(pioneer) {
		// Set pioneer and scene.
		this._pioneer = pioneer;
		this.language = ar;
		// Custom class names
		this._customClass = {
			scientist: 'comparison',
			school_bus: 'comparison',
			astronaut: 'comparison', 
			camel: 'comparison'
		};
		this._scene = pioneer.get('main');

		// Custom entity items for specific cases
		this._customEntityInfo = {
			milky_way: {
				name: 'Milky Way',
				id: 'milky_way',
				adjective: 'Outer Solar',
				category: 'Galaxy',
				keywords: ['milky way'],
				weight: 0
			},
			observable_universe: {
				name: 'Observable Universe',
				id: 'observable_universe',
				adjective: 'Outer Solar',
				category: 'Universe',
				keywords: ['observable universe'],
				weight: 0
			},
			scientist: {
				name: ar.Scientist,
				category: 'Comparison Object'
			},
			school_bus: {
				name: ar.School_Bus,
				category: 'Comparison Object'
			},
			astronaut: {
				name: ar.Astronaut,
				category: 'Comparison Object'
			}, camel: {
				name: ar.Camel,
				category: 'Comparison Object'
			},
			none: {
				name: ar.none,
				category: 'Comparison Object'
			}
		};

		/**
		 * Current vital sign data object.
		 * @type {object}
		 * @public
		 */
		this.vitalData = null;

		/**
		 * Current vital sign name id.
		 * @type {string}
		 * @public
		 */
		this.vitalSignId = null;

		/**
		 * Current vital sign index.
		 * @type {number}
		 * @public
		 */
		this.vsIndex = null;

		/**
		 * Whether current vital sign is satellites Now.
		 * @type {boolean}
		 * @public
		 */
		this.isSatellitesNow = null;
	}

	/**
	 * Gets entity info from the custom entity info, or just the entity list.
	 * @param {string} id
	 * @returns {object}
	 */
	getEntityInfo(id) {
		return this._customEntityInfo[id] ?? ENTITY_INFO[id];
	}

	/**
	 * Gets the entity's id from name.
	 * @param {string} name
	 * @returns {string|null}
	 */
	getCustomEntityId(name) {
		for (const key in this._customEntityInfo) {
			if (this._customEntityInfo[key].name === name) {
				return key;
			}
		}
		return null;
	}

	get earthSpheroidFeatures() {
		return [...Entity._entities.get('earth').spheroidLOD.features];
	}

	/**
	 * Gets the vital data by the passed vital sign name id
	 * @param {string} vitalSignId
	 * @returns {object}
	 */
	getVitalDataByName(vitalSignId) {
		return VITALS_DATA.find(vitalDataItem => vitalDataItem.value === vitalSignId);
	}

	/**
	 * Gets the vital data index by the passed vital sign id
	 * @param {string} vitalSignId
	 * @returns {number}
	 */
	getVSIndex(vitalSignId) {
		return VITALS_DATA.findIndex(vital => vital.value === vitalSignId);
	}

	/**
	 * Get mission from mission list by spacecraft name or id
	 * @param {string} spacecraftNameOrId
	 * @returns {object}
	 */
	getMissionInfo(spacecraftNameOrId) {
		const { spacecraftList } = spacecraftStore.stateSnapshot;
		return spacecraftList.find(({ external_id, spacecraftId }) => external_id === spacecraftNameOrId || spacecraftId === spacecraftNameOrId);
	}

	removeExternalLinks(text) {
		let noLinkText = null;
		if (text?.includes('<a')) {
			// Remove <a> tag from text
			noLinkText = text.replace(/<\/?a[^>]*>/g, '');
		}
		return noLinkText || text;
	}

	/**
	 * Sets the current vital sign.
	 *
	 * This should be set on route change.
	 * @param {string} vitalSignId
	 * @returns {object}
	 */
	setVitalSign(vitalSignId) {
		// Return if already set.
		if (this.vitalSignId === vitalSignId) {
			return;
		}

		this.vitalSignId = vitalSignId;
		this.vsIndex = this.getVSIndex(this.vitalSignId);
		this.vitalData = VITALS_DATA[this.vsIndex];

		// Update the global state.
		this.vitalData && datasetStore.setGlobalState({ currentVS: { ...this.vitalData } });

		return this.vitalData;
	}

	/**
	 * Returns an array of strings that represent maps and title of datasets related to the missionId.
	 * @param string missionId
	 */
	getRelatedDatasets(missionId) {
		const { getManager } = globalRefs;

		const relatedDatasets = [];
		VITALS_DATA.forEach(vitalData => {
			vitalData.datasetGroups.forEach((dsGroup, index) => {
				// With multiple datasets, they come from the same satellite, so we only need look at the first dataset.
				const dataset = dsGroup.datasets[0];
				const { externalId, title, missionIds, urlParam } = dataset;
				const related = missionIds.includes(missionId);

				if (related) {
					relatedDatasets.push({
						id: externalId,
						title,
						src: getManager('dataset').getCylThumbSrc(dataset),
						urlParam
					});
				}
			});
		});

		return relatedDatasets;
	}

	getEventDisplayData({ preview = false } = {}) {
		const { currentEvent } = eventsStore.stateSnapshot;
		const { previewEventData } = previewStore.stateSnapshot;
		const eventData = preview ? previewEventData : currentEvent;

		const { title, formatted_date, description } = eventData || {};

		return {
			category: ar.events,
			title,
			subtitle: formatted_date,
			descriptions: [
				{
					title: '',
					text: description
				}
			]
		};
	}

	getPoiDisplayData() {
		const { currentPoi} = poiStore.stateSnapshot;

		const { title, formatted_date, description } = currentPoi || {};

		return {
			category: ar.poi,
			title,
			subtitle: formatted_date,
			descriptions: [
				{
					title: '',
					text: description
				}
			]
		};
	}

	getGmapDisplayData() {
		const { currentGmap} = gmapStore.stateSnapshot;

		const { title, formatted_date, description } = currentGmap || {};

		return {
			category: ar.gmap,
			title,
			subtitle: formatted_date,
			descriptions: [
				{
					title: '',
					text: description
				}
			]
		};
	}

	getStellarDisplayData() {
		const { currentStellar} = stellarStore.stateSnapshot;

		const {info,id } = currentStellar || {};

		return {
			category: ar.stellar_category,
			title:  ar[id],
			subtitle: '',
			descriptions: [
				{
					title: '',
					text: info
				}
			]
		};
	}



	getSpacecraftDisplayData() {
		const { currentSpacecraft } = spacecraftStore.stateSnapshot;
		const {
			title,
			time_in_orbit: subtitle,
			date: launchDate,
			launch_vehicle: launchVehicle,
			launch_location: launchLocation,
			categories,
			description
		} = currentSpacecraft;

		const data = {
			category: ar.missions,
			title,
			subtitle: '',
			descriptions: []
		};

		if (categories) {
			if (categories.includes('planned')) {
				if (launchDate) {
					data.subtitle = `${MISSION_TIMES.FUTURE} ${launchDate}`;
				}
			} else {
				if (subtitle) {
					const missionLaunchDate = new Date(launchDate);
					data.subtitle = `${MISSION_TIMES.CURRENT} ${launchDate && !isNaN(missionLaunchDate) ? formattedTimeSinceDate(missionLaunchDate) : subtitle}`;
				}
			}
		}
		if (launchVehicle) {
			data.descriptions.push({
				title: ar.launch_vehicle,
				text: launchVehicle
			});
		}
		if (launchLocation) {
			data.descriptions.push({
				title: ar.launch_location,
				text: launchLocation
			});
		}
		if (description) {
			data.descriptions.push({
				title: ar.about + ' ' + title,
				html: description
			});
		}

		return data;
	}

	getVideoDisplayData() {
		const { getManager } = globalRefs;
		const { currentVideo } = videoStore.stateSnapshot;

		const videoData = getManager('video').getVideoLeftPanelContent(currentVideo);

		return videoData;
	}

	getDatasetDisplayData() {
		const { currentVS } = datasetStore.stateSnapshot;
		if(currentVS == null) return; //TODO Merge figure out why this is null
		const { isSatellitesNow } = spacecraftStore.stateSnapshot;
		const { title, description, shortDescription } = currentVS;

		const data = {
			category: '',
			title,
			subtitle: '',
			descriptions: [
				{
					title: ar.about + ' ' + title,
					text: description || shortDescription
				}
			]
		};

		if (!isSatellitesNow) {
			const { title = '', description = '' } = this.getDynamicData() || {};
			data.category = ar.vital_signs;
			data.subtitle = title;
			data.descriptions.push({
				title: ar.about_this_dataset,
				text: description
			});
		}

		return data;
	}

	getSatellitesNowDisplayData() {
		const { title, description } = SATELLITES_NOW_DATA;

		return {
			category: '',
			title,
			subtitle: '',
			descriptions: [
				{
					title: ar.about + ' ' + title,
					text: description
				}
			]
		};
	}

	/**
	 * Get current data on globe dataset, including legacy datasets, based on dataset dates
	 * @returns {object}
	 */
	getDynamicData() {
		const { getManager } = globalRefs;
		const { animations } = getManager('dataset');
		const { currentIndex, currentDataset } = datasetStore.stateSnapshot;
		const { datasetGroup } = getDatasetFromVitalsData(currentDataset.externalId);
		const legacyDatasets = datasetGroup?.legacyDatasets?.filter(({ externalId }) => externalId === currentDataset.externalId);

		// Check if latest tileset date is within legacy date ranges before returning default subtitle
		if (legacyDatasets) {
			const latestTilesetDate = getDateFromUTCDateStr(currentDataset?.latestTileset.date);
			const animationDate = animations?.[currentIndex]?.date && getDateFromUTCDateStr(animations[currentIndex].date);

			const currentDate = animationDate || latestTilesetDate;
			for (const legacyDataset of legacyDatasets) {
				const { start, end } = legacyDataset.dateRange;
				const startDate = start ? getDateFromUTCDateStr(start) : null;
				const endDate = end ? getDateFromUTCDateStr(end) : null;

				const isBetweenRange = betweenDateRange(currentDate, startDate, endDate);

				if (isBetweenRange) {
					return legacyDataset;
				}
			}
		}
		return currentDataset || null;
	}

	/**
	 * Returns whether the entity is clickable.
	 * @param {string} id
	 * @returns {boolean}
	 */
	isClickable(id) {
		return ENTITY_INFO[id]?.clickable || false;
	}

	getPlanetInfo(id){
		return this._entityList[id].info;
	}

	getColorBarInfo(vsIndex, altid, colorBarIndex = 0) {
		let colorBarSrc = '';
		let colorBarText = '';
		if (vsIndex < 2) {
			return {
				colorBarSrc,
				colorBarText
			};
		}
		const currentVS = this._vitalList[vsIndex];
		colorBarSrc = currentVS.dataset_groupings[altid].datasets_sorted[0].colorbar_urls[0];
		if (vsIndex === 2) {
			colorBarSrc = currentVS.dataset_groupings[altid].datasets_sorted[0].colorbar_urls[colorBarIndex];
			colorBarText = this.language.toggle_units;
		}
		return {
			colorBarSrc,
			colorBarText
		};
	}

}

export default Content;
