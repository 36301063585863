/** @module pioneer */
import {
	BaseComponent,
	Capabilities,
	Color,
	DynamicEnvironmentMapComponent,
	Entity,
	Geometry,
	Interval,
	Quaternion,
	THREE,
	ThreeJsEffectComposer,
	ThreeJsOutlinePass,
	ThreeJsRenderPass,
	ThreeJsUnrealBloomPass,
	Vector2,
	Vector3,
	Viewport
} from '../../internal';

/**
 * Camera component. This is typically attached to a {@link Viewport}. The +y axis is forward, +x is right, and +z is up.
 */
export class CameraComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The Three.js renderer.
		 * @type {THREE.WebGLRenderer}
		 * @protected
		 */
		this._threeJsRenderer = this.getEntity().getScene().getEngine().__getThreeJsRenderer();

		/**
		 * The viewport that is using this camera component.
		 * @type {Viewport}
		 * @private
		 */
		this._viewport = null;

		/**
		 * The three js scene.
		 * @type {THREE.Scene}
		 * @private
		 */
		this._threeJsScene = entity.getScene().getThreeJsScene();

		/**
		 * The field of view in radians.
		 * @type {number}
		 * @private
		 */
		this._fieldOfView = 1.0471975512;

		/**
		 * The near clipping plane distance in km.
		 * @type {number}
		 * @private
		 */
		this._nearDistance = undefined;

		/**
		 * Whether or not to invert the depth buffer.
		 * @type {number}
		 * @private
		 */
		this._invertDepth = 0;

		/**
		 * The near clipping plane distance in km.
		 * @type {number}
		 * @private
		 */
		this._autoNearDistance = 0.0;

		/**
		 * The z-buffer mid-point distance in km. Only used when frag-depth buffering isn't available.
		 * @type {number}
		 * @private
		 */
		this._midDistance = undefined;

		/**
		 * The automatically calculated z-buffer mid-point distance in km. Only used when frag-depth buffering isn't available and _midDistance is unefined.
		 * @type {number}
		 * @private
		 */
		this._autoMidDistance = 0.0;

		/**
		 * The aspect ratio given by the viewport.
		 * @type {number}
		 * @private
		 */
		this._aspectRatio = 1.0;

		/**
		 * The render size of the previous frame, used for comparing with this frame.
		 * @type {Vector2}
		 * @private
		 */
		this._renderSize = new Vector2();

		/**
		 * The list of entities which will occlude things like labels. Only things that are big enough on screen will be here.
		 * @type {Entity[]}
		 * @private
		 */
		this._occludingEntities = [];

		/**
		 * The three js camera.
		 * @type {THREE.PerspectiveCamera}
		 * @private
		 */
		this._threeJsCamera = new THREE.PerspectiveCamera(75, this._aspectRatio, 0.1, 1000);

		/**
		 * The Three.js render pass composer.
		 * @type {ThreeJsEffectComposer}
		 * @private
		 */
		this._threeJsComposer = null;

		/**
		 * The Three.js outline pass.
		 * @type {ThreeJsOutlinePass}
		 * @private
		 */
		this._outlinePass = new ThreeJsOutlinePass(undefined, this._threeJsScene, this._threeJsCamera, []);
		this._outlinePass.enabled = false;

		/**
		 * The Three.js unreal bloom pass.
		 * @type {ThreeJsUnrealBloomPass}
		 * @private
		 */
		this._threeJsUnrealBloomPass = new ThreeJsUnrealBloomPass(undefined, 0, 0, 0);
		this._threeJsUnrealBloomPass.enabled = false;

		// Setup the pass composer.
		this.__setupEffectComposer();
	}

	// FIELDS OF VIEW

	/**
	 * Gets the viewport that is using this camera component.
	 * @returns {Viewport}
	 */
	getViewport() {
		return this._viewport;
	}

	/**
	 * Returns the field of view in radians. Defaults to PI / 3. The field of view is applied to the viewport axis (horizontal or vertical) that has the greatest length.
	 * @returns {number}
	 */
	getFieldOfView() {
		return this._fieldOfView;
	}

	/**
	 * Get the field of view in the horizontal direction. It will be less than or equal to the general field of view.
	 * @returns {number}
	 */
	getHorizontalFieldOfView() {
		if (this._aspectRatio >= 1) {
			return this._fieldOfView;
		}
		else {
			return 2.0 * Math.atan(Math.tan(this._fieldOfView / 2.0) * this._aspectRatio);
		}
	}

	/**
	 * Get the field of view in the vertical direction. It will be less than or equal to the general field of view.
	 * @returns {number}
	 */
	getVerticalFieldOfView() {
		if (this._aspectRatio > 1) {
			return 2.0 * Math.atan(Math.tan(this._fieldOfView / 2.0) / this._aspectRatio);
		}
		else {
			return this._fieldOfView;
		}
	}

	/**
	 * Sets the field of view in radians.
	 * @param {number} fieldOfView
	 */
	setFieldOfView(fieldOfView) {
		this._fieldOfView = fieldOfView;
	}

	// LOD-DEPTH BUFFERING VARIABLES

	/**
	 * Returns 1 if the depth is inverted, 0 otherwise.
	 * @returns {number}
	 */
	getInvertDepth() {
		return this._invertDepth;
	}

	/**
	 * Sets whether or not the depth is inverted. Use 1 to invert the depth, 0 otherwise.
	 * @param {number} invertDepth
	 */
	setInvertDepth(invertDepth) {
		this._invertDepth = invertDepth;
	}

	/**
	 * Returns the distance in km at which nothing closer gets rendered.
	 * @returns {number}
	 */
	getNearDistance() {
		return this._nearDistance;
	}

	/**
	 * Sets the distance in km at which nothing closer gets rendered. Set this to be greater for enhanced depth precision. If it is set to undefined, then it is auto set to 1% of the distance to the parent. The default is undefined.
	 * @param {number} nearDistance
	 */
	setNearDistance(nearDistance) {
		this._nearDistance = nearDistance;
	}

	/**
	 * Returns the auto near distance in km.
	 * @returns {number}
	 */
	getAutoNearDistance() {
		return this._autoNearDistance;
	}

	/**
	 * Returns the z-buffer mid-point distance in km. Only used when frag-depth buffering isn't available.
	 * @returns {number}
	 */
	getMidDistance() {
		return this._midDistance;
	}

	/**
	 * Sets the z-buffer mid-point distance in km. Only used when frag-depth buffering isn't available. If it is set to undefined, then it is auto set to the distance to the parent + 2 times the parent's radius. The default is undefined.
	 * @param {number} midDistance
	 */
	setMidDistance(midDistance) {
		this._midDistance = midDistance;
	}

	/**
	 * Returns the z-buffer mid-point distance in km. Only used when frag-depth buffering isn't available.
	 * @returns {number}
	 */
	getAutoMidDistance() {
		return this._autoMidDistance;
	}

	// CAMERA/NORMAL-SPACE CONVERSIONS

	/**
	 * Gets the normal-space position from a camera-space position.
	 * @param {Vector3} outNormalSpacePosition
	 * @param {Vector3} cameraSpacePosition
	 */
	getNormalSpacePositionFromCameraSpacePosition(outNormalSpacePosition, cameraSpacePosition) {
		const orientation = Quaternion.pool.get();
		orientation.copy(this.getEntity().getOrientation());
		orientation.inverse(orientation);
		const rotatedCameraSpacePosition = Vector3.pool.get();
		rotatedCameraSpacePosition.rotate(orientation, cameraSpacePosition);
		Quaternion.pool.release(orientation);

		outNormalSpacePosition.x = this._threeJsCamera.projectionMatrix.elements[0] * rotatedCameraSpacePosition.x / rotatedCameraSpacePosition.y;
		outNormalSpacePosition.y = this._threeJsCamera.projectionMatrix.elements[9] * rotatedCameraSpacePosition.z / rotatedCameraSpacePosition.y;
		outNormalSpacePosition.z = this._threeJsCamera.projectionMatrix.elements[6] + this._threeJsCamera.projectionMatrix.elements[14] / rotatedCameraSpacePosition.y;
		Vector3.pool.release(rotatedCameraSpacePosition);
	}

	/**
	 * Gets the normal-space size (fraction of viewport) from a radius of a sphere and a distance to the camera.
	 * @param {number} radius - radius of object
	 * @param {number} distanceInCameraYDir - distance to camera of object along the camera's y axis.
	 * @returns {number}
	 */
	getNormalSpaceRadiusFromRadius(radius, distanceInCameraYDir) {
		return radius / Math.abs(distanceInCameraYDir) / Math.tan(this._fieldOfView / 2);
	}

	/**
	 * Gets the camera-space position from a normal-space position.
	 * @param {Vector3} outCameraSpacePosition
	 * @param {Vector3} normalSpacePosition
	 */
	getCameraSpacePositionFromNormalSpacePosition(outCameraSpacePosition, normalSpacePosition) {
		const rotatedCameraSpacePosition = Vector3.pool.get();
		rotatedCameraSpacePosition.y = this._threeJsCamera.projectionMatrix.elements[14] / (normalSpacePosition.z - this._threeJsCamera.projectionMatrix.elements[6]);
		rotatedCameraSpacePosition.x = normalSpacePosition.x * rotatedCameraSpacePosition.y / this._threeJsCamera.projectionMatrix.elements[0];
		rotatedCameraSpacePosition.z = normalSpacePosition.y * rotatedCameraSpacePosition.y / this._threeJsCamera.projectionMatrix.elements[9];

		outCameraSpacePosition.rotate(this.getEntity().getOrientation(), rotatedCameraSpacePosition);
		Vector3.pool.release(rotatedCameraSpacePosition);
	}

	/**
	 * Gets the camera/world-space size (fraction of viewport) from a radius of a sphere and a distance to the camera.
	 * @param {number} radius - normal-space radius of object
	 * @param {number} distanceInCameraYDir - distance to camera of object along the camera's y axis.
	 * @returns {number}
	 */
	getRadiusFromNormalSpaceRadius(radius, distanceInCameraYDir) {
		return radius * Math.abs(distanceInCameraYDir) * Math.tan(this._fieldOfView / 2);
	}

	/**
	 * Gets the position of the intersection of a camera-space ray with an entity.
	 * The returned positions are in the frame-space of component's entity.
	 * @param {Vector3} cameraSpaceOrigin
	 * @param {Vector3} cameraSpaceDirection
	 * @param {Entity} entity
	 * @returns {{ component: BaseComponent, object: THREE.Object3D, frameSpacePosition: Vector3 }[]}
	 */
	getRaycast(cameraSpaceOrigin, cameraSpaceDirection, entity) {
		const raycaster = new THREE.Raycaster();
		const origin = new THREE.Vector3(cameraSpaceOrigin.x, cameraSpaceOrigin.y, cameraSpaceOrigin.z);
		const direction = new THREE.Vector3(cameraSpaceDirection.x, cameraSpaceDirection.y, cameraSpaceDirection.z);
		raycaster.set(origin, direction);
		const results = [];
		for (let componentI = 0, l = entity.getNumComponents(); componentI < l; componentI++) {
			const component = entity.getComponent(componentI);
			const intersects = raycaster.intersectObjects(component.getThreeJsObjects());
			for (let intersectsI = 0, l = intersects.length; intersectsI < l; intersectsI++) {
				const intersect = intersects[intersectsI];
				// Sometimes there are duplicates so remove them.
				if (intersectsI > 0
					&& intersect.object === intersects[intersectsI - 1].object
					&& intersect.face.a === intersects[intersectsI - 1].face.a
					&& intersect.face.b === intersects[intersectsI - 1].face.b
					&& intersect.face.c === intersects[intersectsI - 1].face.c) {
					continue;
				}
				const object = intersect.object;
				const frameSpacePosition = new Vector3();
				frameSpacePosition.copyFromThreeJs(intersect.point);
				this.getEntity().getPositionRelativeToEntity(frameSpacePosition, frameSpacePosition, component.getEntity());
				frameSpacePosition.rotateInverse(component.getEntity().getOrientation(), frameSpacePosition);
				results.push({
					component,
					object,
					frameSpacePosition
				});
			}
		}
		return results;
	}

	/**
	 * Gets the color at the intersection of the camera-space ray with a component's texture.
	 * @param {Vector3} cameraSpaceOrigin
	 * @param {Vector3} cameraSpaceDirection
	 * @param {Entity} entity
	 * @returns {{ component: BaseComponent, object: THREE.Object3D, textureName: string, color: Color }[]}
	 */
	getRaycastColor(cameraSpaceOrigin, cameraSpaceDirection, entity) {
		const raycaster = new THREE.Raycaster();
		const origin = new THREE.Vector3(cameraSpaceOrigin.x, cameraSpaceOrigin.y, cameraSpaceOrigin.z);
		const direction = new THREE.Vector3(cameraSpaceDirection.x, cameraSpaceDirection.y, cameraSpaceDirection.z);
		raycaster.set(origin, direction);
		const results = [];
		for (let componentI = 0, l = entity.getNumComponents(); componentI < l; componentI++) {
			const component = entity.getComponent(componentI);
			const intersects = raycaster.intersectObjects(component.getThreeJsObjects());
			for (let intersectsI = 0, l = intersects.length; intersectsI < l; intersectsI++) {
				const intersect = intersects[intersectsI];
				// Sometimes there are duplicates so remove them.
				if (intersectsI > 0
					&& intersect.object === intersects[intersectsI - 1].object
					&& intersect.face.a === intersects[intersectsI - 1].face.a
					&& intersect.face.b === intersects[intersectsI - 1].face.b
					&& intersect.face.c === intersects[intersectsI - 1].face.c) {
					continue;
				}
				// Get the object, material, and texture given the intersection.
				const object = intersect.object;
				if (!(object instanceof THREE.Mesh)) {
					continue;
				}
				let material = /** @type THREE.Mesh */(intersect.object).material;
				if (Array.isArray(material)) {
					material = material[intersect.face.materialIndex];
				}
				if (!(material instanceof THREE.ShaderMaterial)) {
					continue;
				}
				for (const [uniformName, uniform] of Object.entries(material.uniforms)) {
					const texture = uniform.value;
					if (!(texture instanceof THREE.Texture)) {
						continue;
					}
					const textureName = uniformName;
					// Since we can't read from a loaded texture, we load it to a canvas.
					const canvas = document.createElement('canvas');
					canvas.width = texture.image.width;
					canvas.height = texture.image.height;
					const context = canvas.getContext('2d');
					try { // It may fail if it is undrawable to a texture.
						context.drawImage(texture.image, 0, 0);
					}
					catch {
						continue;
					}
					const imageData = context.getImageData(0, 0, texture.image.width, texture.image.height);
					// Get the pixel color from the image data.
					const uv = intersect.uv;
					const numbersPerPixel = texture.format === THREE.RGBAFormat ? 4 : 3;
					const pixelIndex = (Math.floor(uv.y * imageData.height) * imageData.width + Math.floor(uv.x * imageData.width)) * numbersPerPixel;
					const color = new Color();
					color.set(imageData.data[pixelIndex + 0], imageData.data[pixelIndex + 1], imageData.data[pixelIndex + 2], numbersPerPixel === 4 ? imageData.data[pixelIndex + 3] : 1.0);
					results.push({
						component,
						object,
						textureName,
						color
					});
				}
			}
		}
		return results;
	}

	// OCCLUSION

	/**
	 * Returns true if the camera-space position is occluded by any of the occluding entities.
	 * @param {Vector3} cameraSpacePosition
	 * @returns {boolean}
	 */
	isPositionOccluded(cameraSpacePosition) {
		for (let i = 0; i < this._occludingEntities.length; i++) {
			if (this._occludingEntities[i].isOccludingPosition(this, cameraSpacePosition)) {
				return true;
			}
		}
		return false;
	}

	/**
	 * Find the nearest entity that intersects the ray form the camera to the camera-space position, or null if there is no such intersection.
	 * @param {Vector3} cameraSpacePosition
	 * @returns {Entity}
	 */
	getNearestIntersectingEntity(cameraSpacePosition) {
		let minDistance = Number.POSITIVE_INFINITY;
		let minEntity = null;
		const interval = Interval.pool.get();
		for (let i = 0; i < this._occludingEntities.length; i++) {
			const entityCameraSpacePosition = this._occludingEntities[i].getCameraSpacePosition(this);
			Geometry.getLineSphereIntersectionWithLineStartAtOrigin(interval, cameraSpacePosition, entityCameraSpacePosition, this._occludingEntities[i].getOcclusionRadius());
			if (!Number.isNaN(interval.min) && interval.min >= 0) {
				const distance = interval.min * entityCameraSpacePosition.magnitude();
				if (distance < minDistance) {
					minDistance = distance;
					minEntity = this._occludingEntities[i];
				}
			}
		}
		Interval.pool.release(interval);
		return minEntity;
	}

	// POST-PROCESSING

	/**
	 * Sets the bloom value.
	 * @param {number} strength
	 */
	setBloom(strength) {
		this._threeJsUnrealBloomPass.enabled = (strength > 0);
		this._threeJsUnrealBloomPass.strength = strength;
	}

	/**
	 * Sets the outline pass. Works with any component that has implemented getThreeJsObjects(). If component is null, it is disabled.
	 * @param {Color} color
	 * @param {BaseComponent} component
	 * @param {string} [subObjectName='']
	 */
	setOutline(color, component, subObjectName) {
		this._outlinePass.enabled = (component !== null);
		if (component !== null) {
			if (subObjectName !== undefined) {
				const subObject = component.getThreeJsObjectByName(subObjectName);
				if (subObject === null) {
					throw new Error(`Could not set outline on component ${component} sub-object ${subObjectName}.`);
				}
				this._outlinePass.selectedObjects = [subObject];
			}
			else {
				const objects = component.getThreeJsObjects();
				this._outlinePass.selectedObjects = [];
				for (let i = 0, l = objects.length; i < l; i++) {
					if (objects[i].parent === this._threeJsScene) {
						this._outlinePass.selectedObjects.push(objects[i]);
					}
				}
			}
			this._outlinePass.visibleEdgeColor.setRGB(color.r, color.g, color.b);
			this._outlinePass.hiddenEdgeColor.setRGB(color.r / 4, color.g / 4, color.b / 4);
		}
		this.__setupEffectComposer();
	}

	// INTERNALS

	/**
	 * Add an entity to the list of occluding entities. This is called by the entity during its prepareForRender, and used by occlusion and intersection functions.
	 * @param {Entity} entity
	 * @internal
	 */
	__addToOccludingEntities(entity) {
		// Check to see if it already exists.
		for (let i = 0; i < this._occludingEntities.length; i++) {
			if (this._occludingEntities[i] === entity) {
				return;
			}
		}
		// If not, add it.
		this._occludingEntities.push(entity);
	}

	/**
	 * Cleans up the component.
	 * @override
	 * @internal
	 */
	__destroy() {
		super.__destroy();

		// Remove any camera-dependent variables in the scene that reference this camera.
		this.getEntity().getScene().__removeCameraDependents(this);

		// If it has a viewport, unlink it.
		if (this._viewport !== null) {
			this._viewport.setCamera(null);
		}
	}

	/**
	 * Prepares the camera-dependent variables and those of its connected entities.
	 * @internal
	 */
	__updateCameraVariablesForConnectedScene() {
		// Update the aspect ratio.
		const renderSize = this._viewport.getBounds().size;
		if (!renderSize.equals(this._renderSize)) {
			const aspectRatio = renderSize.x / renderSize.y;
			if (this._aspectRatio !== aspectRatio) {
				this._aspectRatio = aspectRatio;
			}

			// Set the resolution of the render passes.
			this._threeJsComposer.setSize(renderSize.x, renderSize.y);
			this._renderSize.copy(renderSize);
		}

		// Set the auto mid-point distance, if necessary.
		if (this._midDistance === undefined) {
			if (this.getEntity().getParent() !== null) {
				this._autoMidDistance = this.getEntity().getPosition().magnitude() + this.getEntity().getParent().getExtentsRadius() * 10.0;
			}
		}
		else {
			this._autoMidDistance = this._midDistance;
		}

		// Set the near-point distance, if necessary.
		if (this._nearDistance === undefined) {
			if (this.getEntity().getParent() !== null) {
				this._autoNearDistance = Math.max(0.00001, (this.getEntity().getPosition().magnitude() - this.getEntity().getParent().getExtentsRadius()) * 0.01);
			}
			else {
				this._autoNearDistance = Math.max(0.00001, this.getEntity().getPosition().magnitude() * 0.01);
			}
		}
		else {
			this._autoNearDistance = this._nearDistance;
		}

		// Update the projection matrix.
		this._updateProjectionMatrix();

		// Update the camera-dependent variables of entities in the scene that are connected to the camera.
		this.getEntity().__updateCameraVariables(this, null, false, 0);

		// Remove any objects that are too small visually from the occluding list.
		for (let i = 0; i < this._occludingEntities.length; i++) {
			if (this._occludingEntities[i].getPixelSpaceOcclusionRadius(this) < 1 || !this._occludingEntities[i].isEnabled() || !this._occludingEntities[i].canOcclude() || this.getEntity().getScene().get(this._occludingEntities[i].getName()) === null) {
				this._occludingEntities.splice(i, 1);
				i--;
			}
		}
	}

	/**
	 * Prepares the camera for rendering.
	 * @override
	 */
	__prepareForRender() {
		// Prepare each entity in the scene for rendering using this.
		const scene = this.getEntity().getScene();
		for (let i = 0; i < scene.getNumEntities(); i++) {
			const entity = scene.getEntity(i);
			if (entity.isEnabled()) {
				entity.__prepareForRender(this);
			}
		}

		// If there is a dynamic environment map component, render it first.
		const dynEnvMapComponent = /** @type {DynamicEnvironmentMapComponent} */(this.getEntity().get('dynEnvMap'));
		if (dynEnvMapComponent !== null) {
			dynEnvMapComponent.__render();
		}
	}

	/**
	 * Renders the camera. Called by Viewport.
	 * @internal
	 */
	__render() {
		// Set the camera's orientation.
		const orientation = this.getEntity().getOrientation();
		CameraComponent._tempThreeJsQuaternion.set(orientation.x, orientation.y, orientation.z, orientation.w);
		this._threeJsCamera.setRotationFromQuaternion(CameraComponent._tempThreeJsQuaternion);

		// Do the render!
		this._threeJsComposer.render();
	}

	/**
	 * Sets the viewport that is using this camera component. Only used by Viewport itself.
	 * @param {Viewport} viewport
	 * @internal
	 */
	__setViewport(viewport) {
		// Unlink from any previous other viewport.
		if (this._viewport !== null && this._viewport !== viewport) {
			this._viewport.setCamera(null);
		}

		this._viewport = viewport;
	}

	/**
	 * Updates the render targets and sets up the effect composer. Can be called multiple times when conditions change.
	 * @private
	 */
	__setupEffectComposer() {
		// Create the render target.
		const size = new THREE.Vector2();
		this._threeJsRenderer.getSize(size);
		const renderTargetOptions = {
			samples: (Capabilities.isWebGL2() && !this._outlinePass.enabled) ? 4 : 0
		};
		const renderTarget = new THREE.WebGLRenderTarget(size.x, size.y, renderTargetOptions);
		if (this._threeJsComposer !== null) {
			this._threeJsComposer.reset(renderTarget);
		}
		else {
			// Create the Three.js effect composer.
			this._threeJsComposer = new ThreeJsEffectComposer(this._threeJsRenderer, renderTarget);

			// Add the render passes.
			const renderPass = new ThreeJsRenderPass(this._threeJsScene, this._threeJsCamera);
			this._threeJsComposer.addPass(renderPass);
			this._threeJsComposer.addPass(this._outlinePass);
			this._threeJsComposer.addPass(this._threeJsUnrealBloomPass);
		}
	}

	/**
	 * Updates the projection.
	 * @private
	 */
	_updateProjectionMatrix() {
		if (this._fieldOfView > 0 && this._fieldOfView < 180 && this._aspectRatio > 0) {
			const tanHalfFov = Math.tan(this._fieldOfView / 2);
			let sx = 0;
			let sz = 0;
			if (this._aspectRatio >= 1) {
				sx = 1 / tanHalfFov;
				sz = this._aspectRatio / tanHalfFov;
			}
			else {
				sx = 1 / (tanHalfFov * this._aspectRatio);
				sz = 1 / tanHalfFov;
			}
			const f1 = 1.0 - Number.EPSILON;
			const f2 = this._autoNearDistance * (Number.EPSILON - 2.0);
			const projectionMatrix = new THREE.Matrix4();
			projectionMatrix.set(
				sx, 0, 0, 0,
				0, 0, sz, 0,
				0, f1, 0, f2,
				0, 1, 0, 0);
			this._threeJsCamera.projectionMatrix = projectionMatrix;
			const projectionMatrixInverse = new THREE.Matrix4();
			projectionMatrixInverse.set(
				1 / sx, 0, 0, 0,
				0, 0, 0, 1,
				0, 1 / sz, 0, 0,
				0, 0, 1 / f2, -f1 / f2);
			this._threeJsCamera.projectionMatrixInverse = projectionMatrixInverse;
		}
	}

	/**
	 * Required by BaseComponent, does nothing.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	__loadResources() {
		return Promise.resolve();
	}

	/**
	 * Required by BaseComponent, does nothing.
	 * @override
	 * @protected
	 */
	__unloadResources() {
	}
}
