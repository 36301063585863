import { SPACECRAFT_CONSTELLATIONS, SPACECRAFT_WITH_INSTRUMENTS } from '../data/spacecraft_data_ar';
import VIDEOS_DATA from '../data/videos_data';
import VITALS_DATA from '../data/vitals_data';
import globalRefs from '../managers/globalRefs';

const msPerDay = 24 * 60 * 60 * 1000;
const daysPerYear = 365.25;
/**
 * Check if an object is empty.
 * @param {Object} obj
 * @returns {boolean}
 */
const isEmptyObj = obj => !obj || Object.keys(obj).length === 0;

/**
 * Compare 2 single-level objects.
 * @param {Object} a
 * @param {Object} b
 * @return {boolean} Objects are equal or not.
 */
const compareObjs = (a, b) => {
	// If either object doesn't exist
	if (!a || !b) {
		return false;
	}

	// Create arrays of property names
	const aProps = Object.getOwnPropertyNames(a);
	const bProps = Object.getOwnPropertyNames(b);

	if (aProps.length !== bProps.length) {
		return false;
	}

	for (let i = aProps.length - 1; i >= 0; i--) {
		const key = aProps[i];
		if (a[key] !== b[key]) {
			return false;
		}
	}

	return true;
};

/**
 * Formats local date to local date string
 * @param {Date} localDate
 * @param {object} options - optional ;)
 * @returns {string}
 */
const formatDate = (localDate, options = {}) => localDate.toLocaleDateString('ar-SA', {
	day: 'numeric',
	month: 'short',
	year: 'numeric',
	...options
});

/**
 * Formats local date to local time string
 * @param {Date} localDate
 * @param {object} options - optional ;)
 * @returns {string}
 */
const formatTime = (localDate, options = {}) => localDate.toLocaleTimeString('ar-SA', options);


const getSecMinHours = incomingTime => {
	let sec = Math.round(incomingTime / 1000);
	let min = Math.floor(sec / 60);
	const hour = Math.floor(min / 60);

	sec %= 60;
	min %= 60;

	return { sec, min, hour };
};

/**
 * Format time into hh:mm:ss for session timer
 * @param {number} incomingTime
 */
const formatCountdownTime = (maxSessionTime, incomingTime) => {
	// One hour in milliseconds
	const oneHour = 60 * 60 * 1000;
	const time = getSecMinHours(incomingTime);

	// Pad the front with '0' until it hits 2 digits
	time.hour = time.hour.toString().padStart(2, '0');
	time.min = time.min.toString().padStart(2, '0');
	time.sec = time.sec.toString().padStart(2, '0');

	return `${maxSessionTime > oneHour ? time.hour + ':' : ''}${time.min || '00'}:${time.sec}`;
};

/**
 * Converts date ISO string to milliseconds (since midnight 01 January, 1970 UTC)
 * @param {string} dateISO
 * @returns {number}
 */
const dateISOtoMs = dateISO => new Date(dateISO).valueOf();

/**
 * Subtracts a certain number of days from a date
 * @param {Date} date
 * @param {number} numDays
 * @returns {Date}
 */
const subtractDaysFromDate = (date, numDays = 1) => {
	// javascript's Date automatically takes months / negative days into account.
	date.setUTCDate(date.getUTCDate() - numDays);
	return date;
};

/**
 * Subtracts a certain number of months from a date
 * @param {Date} date
 * @param {number} numMonths
 * @returns {Date}
 */
const subtractMonthsFromDate = (date, numMonths = 1) => {
	// javascript's Date automatically takes months / negative days into account.
	date.setUTCMonth(date.getUTCMonth() - numMonths);
	return date;
};

/**
 * Returns if a date is between a range
 * @param {Date} date
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {boolean}
 */
const betweenDateRange = (date, startDate, endDate) => {
	const dateMS = date.getTime();
	const startMS = startDate?.getTime() || 0;
	const endMS = endDate?.getTime() || Infinity;

	return startMS <= dateMS && dateMS <= endMS;
};

/**
 * Returns the dataset path prefix for a specific date.
 * @param {Date} date
 */
const calcPrefixFromDate = date => {
	const year2digits = String(date.getUTCFullYear()).slice(-2);
	const paddedMonth = String(date.getUTCMonth() + 1).padStart(2, '0');
	const paddedDate = String(date.getUTCDate()).padStart(2, '0');

	return `${year2digits}/${paddedMonth}${paddedDate}_`;
};

/**
 * Converts a local date to noon UTC.
 * @param {Date} localDate
 * @returns {Date}
 */
const localDateToNoonUTC = localDate => {
	const dateStrUTC = localDate.toISOString().split('T')[0];
	return new Date(`${dateStrUTC}T12:00:00.000Z`);
};

/**
 * Creates a Date from a UTC date string by adding local time
 * @param {string} utcDateStr
 * @returns {Date}
 */
const getDateFromUTCDateStr = utcDateStr => {
	if (!utcDateStr) {
		console.warn(utcDateStr, 'is not valid');
		return null;
	}

	const inputDateUTC = utcDateStr.split('T')[0];
	const inputDate = new Date(utcDateStr);
	// Check to see if input date is valid.
	if (isNaN(inputDate.valueOf())) {
		return null;
	}

	const nowDate = new Date();

	// If now is before the input date, we can use the current time, otherwise noon UTC.
	const timeUTC = nowDate.valueOf() < inputDate.valueOf() ?
		nowDate.toISOString().split('T')[1] :
		'12:00:00.000Z';

	return new Date(`${inputDateUTC}T${timeUTC}`);
};


/**
 * Uses current date and time with input date string in the format YYYY-MM-DD
 * to create a local Date.
 * @param {string} localDateStr format YYYY-MM-DD
 * @returns {Date}
 */
const getDateFromLocalDateStr = localDateStr => {
	const inputDate = new Date(localDateStr);
	// Check to see if input date is valid.
	if (isNaN(inputDate.valueOf())) {
		return null;
	}

	const inputDateUTC = inputDate.toISOString().split('T')[0];
	const nowDate = new Date();

	// If now is before the input date, we can use the current time, otherwise noon UTC.
	const timeUTC = nowDate.valueOf() < inputDate.valueOf() ?
		nowDate.toISOString().split('T')[1] :
		'12:00:00.000Z';

	return new Date(`${inputDateUTC}T${timeUTC}`);
};


/**
 * Makes an exact clone of a date
 * @param {Date} date
 * @returns {Date}
 */
const cloneDate = date => new Date(date);

/**
 * Calculates the month difference between two dates.
 * @param {Date} dateFrom
 * @param {Date} dateTo
 * @returns {number}
 */
const monthDifference = (dateFrom, dateTo) => dateTo.getUTCMonth() - dateFrom.getUTCMonth()
   + (12 * (dateTo.getUTCFullYear() - dateFrom.getUTCFullYear()));

/**
 * Calculates the day difference between two dates.
 * @param {Date} dateFrom
 * @param {Date} dateTo
 * @returns {number}
 */
const dayDifference = (dateFrom, dateTo) => Math.round((dateTo.valueOf() - dateFrom.valueOf()) / msPerDay);

/**
 * Formats the time difference between two dates in years and days in the format "X year(s) : Y day(s)"
 *
 * @param {Date} startDate - The starting date.
 * @param {Date} [endDate=Date.now()] - The ending date. Defaults to the current date if not provided.
 * @return {string} The formatted time difference in the format "X year(s) : Y day(s)" or "X year(s)" or "Y day(s)".
 */
const formattedTimeSinceDate = (startDate, endDate = Date.now()) => {
	const timeDiff = Math.abs(endDate - new Date(startDate)) / msPerDay;
	const yearDiff = Math.floor(Math.abs(timeDiff) / daysPerYear);
	const dayDiff = Math.floor(timeDiff % daysPerYear);

	let text = '';
	if (yearDiff <= 10  && yearDiff > 2) {
		text += `${yearDiff} سنوات ميلادية و`;
	} else if (yearDiff > 10) {
		text += `${yearDiff} سنة ميلادية و`;
	} else if(yearDiff == 2) {
		text += `سنتان ميلادية و `;
	} else if (yearDiff == 1) {
		text += `سنة ميلادية و `;
	}
	if (dayDiff > 10) {
		text += `${dayDiff} يوم `;
	} else if (dayDiff <= 10 && dayDiff > 2) {
		text += `${dayDiff} أيام`;
	} else if(dayDiff == 2) {
		text += `يومان`;
	} else if(dayDiff == 1) {
		text += `يوم`;
	}

	return text || '0 days';
};

/**
 * Converts a date to a UTC string in the format YYYY-MM-DD
 * @param {Date}
 * @returns {string}
 */
const dateToUTCString = date => date.toISOString().split('T')[0];


/**
 * Converts a local date to a string in the format YYYY-MM-DD
 * Timezone is taken into account as it's a local date.
 * @param {Date}
 * @returns {string}
 */
const localDateToString = date => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;


/**
 * Steps backwards in time from the start date to retrieve an array of dates.
 * Skips any missing dates.
 * Start and end dates are assumed to be UTC.
 * @param {object} params
 * @param {string} params.frequency
 * @param {Date} params.startDate string in format "YYYY-MM-DD"
 * @param {Date} params.endDate
 * @param {Array<string>} params.missingDates
 * @param {number} howMany
 * @returns {Array<Date>}
 */
const getLatestDates = ({ frequency, startDate, endDate, missingDates = [] }, howMany = 7) => {
	// Date of latest data starts at the endDate (usually today).
	const startDateMs = startDate.valueOf();
	const latestDate = cloneDate(endDate);
	const monthly = frequency === 'monthly';

	if (howMany < 0) {
		return [];
	}

	const latestDates = [...Array(howMany).keys()].map(num => {
		// Convert local date to UTC string to match missingDates UTC strings.
		let latestDateUTCStr = dateToUTCString(latestDate);

		// Check whether current date is missing (and we're not before the start). Go back a day/month if so.
		while (missingDates.includes(latestDateUTCStr) && latestDate.valueOf() >= startDateMs) {
			if (monthly) {
				subtractMonthsFromDate(latestDate, 1);
			} else {
				subtractDaysFromDate(latestDate, 1);
			}

			latestDateUTCStr = dateToUTCString(latestDate);
		}

		// Make sure we're not going earlier than the start date.
		if (latestDate.valueOf() < startDateMs) {
			return false;
		}

		// Make a copy to allow subtraction before return.
		const currentDate = cloneDate(latestDate);

		// Take off a day/month for the next loop.
		if (monthly) {
			subtractMonthsFromDate(latestDate, 1);
		} else {
			subtractDaysFromDate(latestDate, 1);
		}

		return currentDate;
	}).filter(date => Boolean(date));

	// Reverse the array so the latest date is the last item
	return latestDates.reverse();
};

/**
 * Get the default dataset param for a given vital value.
 * @param {string} vitalValue
 * @returns {string}
 */
const getDefaultDatasetParamFromVital = vitalValue => {
	const { datasetGroups } = VITALS_DATA.find(({ value }) => value === vitalValue) || {};
	const { datasets } = datasetGroups?.[0] || {};
	return datasets?.[0]?.urlParam;
};

/**
 * Get dataset, datasetGroup, and vitalData from datasetId.
 *
 * @param {string} datasetId
 * @returns {object}
 */
const getDatasetFromVitalsData = datasetIdOrUrl => {
	let dataset;
	let datasetGroup;

	// Find the dataset.
	const vitalData = VITALS_DATA.find(vitalData => {
		const { datasetGroups } = vitalData;
		datasetGroup = datasetGroups.find(group => {
			const { datasets } = group;
			dataset = datasets.find(({ externalId, urlParam }) => externalId === datasetIdOrUrl || urlParam === datasetIdOrUrl);
			return dataset !== undefined;
		});
		return datasetGroup !== undefined;
	});

	return {
		dataset,
		datasetGroup,
		vitalData
	};
};

/**
 * Returns the related dataset id and vital sign id, given a video param.
 * @param {string} param
 * @returns {string}
 */
const getRelatedIdsFromVideoParam = param => {
	if (!param) {
		return null;
	}

	for (const video of VIDEOS_DATA) {
		if (video.urlParam === param) {
			const relatedVital = VITALS_DATA.find(({ value }) => value === video.related);
			const { externalId: relatedDatasetId } = relatedVital?.datasetGroups[0]?.datasets?.[0] || {};
			if (relatedDatasetId) {
				return {
					relatedDatasetId,
					relatedVitalSignId: relatedVital.value
				};
			}
		}
	}

	return null;
};

/**
 * Returns the dataset id given a url param.
 * @param {string} param
 * @returns {string}
 */
const getDatasetIdFromParam = (param, isVisibleEarth = false) => {
	if (isVisibleEarth && !param?.includes('terra') && !param?.includes('aqua')) {
		return 'viirsToday';
	}
	const { dataset } = getDatasetFromVitalsData(param) || {};

	return dataset?.externalId;
};

/**
 * Returns the vital param given a dataset param.
 * @param {string} datasetParam
 * @returns {string}
 */
const getVitalParamFromDatasetParam = datasetParam => {
	const { vitalData } = getDatasetFromVitalsData(datasetParam) || {};
	return vitalData?.value;
};


/**
 * Returns the spacecraft id given a url param
 * Example: returns sc_iss_ecostress given iss-ecostress
 * @param {string} param
 * @returns {string}
 */
const getSpacecraftIdFromParam = param => (param ? `sc_${param.replaceAll('-', '_')}` : null);


/**
 * Return spacecraft url param give an id
 * Example: returns iss-ecostress given sc_iss_ecostress
 * @param {string} id
 * @returns {string}
 */
const getSpacecraftParamFromId = id => id.replace('sc_', '').replaceAll('_', '-');

/**
 * Check whether the spacecraftApiName has at least one associated spacecraft id using the id map.
 * @param {string} spacecraftApiName
 * @returns {boolean}
 */
const hasAssociatedSpacecraftId = spacecraftApiName =>  Object.keys(SPACECRAFT_CONSTELLATIONS).some(constellationName =>  spacecraftApiName.includes(constellationName))

/**
 * Whether the spacecraftId is included in one of the constellation arrays.
 * @param {string} spacecraftId
 */
const isListedSpacecraft = spacecraftId => Object.values(SPACECRAFT_CONSTELLATIONS).some(constellation => constellation.includes(spacecraftId));

/**
 * Get's spacecraft's constellation name from a spacecraft id.
 * @param {string} spacecraftId
 */
const getConstellationNameFromId = spacecraftId => {
	const constellations = Object.keys(SPACECRAFT_CONSTELLATIONS).filter(constellationName => SPACECRAFT_CONSTELLATIONS[constellationName].includes(spacecraftId));

	if (constellations.length === 1) {
		return constellations[0];
	}
	// If there are multiple constellations, we need to find the one with the spacecraftId at the lowest index.
	if (constellations.length > 1) {
		const constellationIndexes = constellations.map(constellationName => SPACECRAFT_CONSTELLATIONS[constellationName].indexOf(spacecraftId));
		// Get the index of the lowest index.
		const lowestIndex = constellationIndexes.indexOf(Math.min(...constellationIndexes));

		return constellations[lowestIndex];
	}

	// If there are no constellations, return null.
	return null;
};

/**
 * Gets a list of the spacecraft's ids from name using the id map.
 * @param {string} spacecraftName
 * @returns {Array<string>}
 */
const getSpacecraftConstellation = spacecraftName => {
	const constellationName = Object.keys(SPACECRAFT_CONSTELLATIONS).find(name => spacecraftName.includes(name));

	if (!constellationName) {
		console.warn(`No constellation found for ${spacecraftName}. Add it to SPACECRAFT_CONSTELLATIONS.`);
	}

	return SPACECRAFT_CONSTELLATIONS[constellationName] ?? [];
};

/**
 * Checks whether a spacecraftId is:
 * - an instrument eg. sc_iss_emit
 * - has instrument(s) eg. sc_iss
 * @param {string} spacecraftId
 * @returns {object}
 */
const getSpacecraftInstrumentData = spacecraftId => {
	for (const parentOfInstrument of Object.keys(SPACECRAFT_WITH_INSTRUMENTS)) {
		const childInstruments = SPACECRAFT_WITH_INSTRUMENTS[parentOfInstrument];
		const instrumentData = childInstruments.find(({ instrumentId }) => instrumentId === spacecraftId);
		if (parentOfInstrument === spacecraftId) {
			// It is a parent, return the children instruments.
			return { childInstruments };
		}
		if (instrumentData) {
			// It is an instrument, return the parent id.
			return { parentOfInstrument };
		}
	}

	return null;
};

/**
 * Equivalent of useParam() hook from react-router-dom (usable outside react components).
 */
const getParams = () => {
	const { router } = globalRefs;
	const { matches } = router.state || {};
	const { params } = matches?.length ? matches[matches.length - 1] : {};

	return params ?? {};
};

/**
 * Current time in case we need to add it to the end of url to make sure it does not use browser caching
 */
const getUrlTime = () => Date.now() / (1000 * 60 * 30);

/**
 * Makes sure we can add commas and a maximum number of decimal places without float inaccuracies
 * @param {number} number
 * @param {number} dps
 * @returns {string}
 */
const formatNumber = (number, dps = 0) => number.toLocaleString(undefined, { maximumFractionDigits: dps });

/**
 * Identifies a default isCelcius boolean based on the navigator language string.
 * Anything within America (en-US) will default to fahrenheit (isCelcius = false).
 * Otherwise isCelcius is true.
 * @returns {boolean}
 */
const isLanguageCelcius = () => {
	// A list of language strings that use F by default (https://worldpopulationreview.com/country-rankings/countries-that-use-fahrenheit)
	const excluded = ['en-US'];

	const { language } = window?.navigator || {};
	return !excluded.includes(language);
};

/**
 * Data dependency error.
 * @param {string} dataName
 * @param {string} funcName
 */
const dataDependencyError = (dataName, funcName) => {
	throw Error(`Data dependency error: Please make sure ${dataName} is set in the global state before called function, ${funcName}`);
};

/**
 * creates a base64 svg img source placeholder to prevent page reflow when loading images of known dimensions
 * article here: https://css-tricks.com/preventing-content-reflow-from-lazy-loaded-images/
 */
const placeholderSrc = (width, height) => `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;

/**
 * Download a XML file.
 * @returns {object}
 */
const downloadXML = fileURL => fetch(fileURL)
	.then(response => response.text())
	.then(str => new window.DOMParser().parseFromString(str, 'text/xml'))
	.then(data => data)
	.catch(e => e);

/**
 * Download a JSON file.
 * @returns {object}
 */
const downloadJSON = fileURL => fetch(fileURL).then(response => response.json()).catch(e => e);


/**
 * Return "webp" if webp file url exists. Otherwise return "png"
 * @param {string} fileURL - url for webp file
 * @returns {Promise}
 */
const getWebpOrPngExt = fileURL => {
	if (!fileURL.includes('.webp')) {
		console.warn(`${fileURL} should be webp url`);
		return 'png';
	}

	return fetch(fileURL).then(response => (response?.ok ? 'webp' : 'png')).catch(() => 'png');
};

export {
	isEmptyObj,
	getWebpOrPngExt,
	compareObjs,
	downloadXML,
	downloadJSON,
	formatDate,
	getSecMinHours,
	formatCountdownTime,
	formatTime,
	dateISOtoMs,
	subtractDaysFromDate,
	subtractMonthsFromDate,
	betweenDateRange,
	calcPrefixFromDate,
	localDateToNoonUTC,
	getDateFromUTCDateStr,
	cloneDate,
	monthDifference,
	dayDifference,
	formattedTimeSinceDate,
	dateToUTCString,
	localDateToString,
	getDateFromLocalDateStr,
	getLatestDates,
	getDefaultDatasetParamFromVital,
	getDatasetFromVitalsData,
	getRelatedIdsFromVideoParam,
	getDatasetIdFromParam,
	getVitalParamFromDatasetParam,
	getSpacecraftIdFromParam,
	getSpacecraftParamFromId,
	hasAssociatedSpacecraftId,
	isListedSpacecraft,
	getConstellationNameFromId,
	getSpacecraftConstellation,
	getSpacecraftInstrumentData,
	getParams,
	getUrlTime,
	formatNumber,
	isLanguageCelcius,
	dataDependencyError,
	placeholderSrc
};
