/** @module pioneer */
import {
	Engine,
	Vector2,
	Viewport
} from './internal';

/**
 * A single touch on the device by the user.
 */
class Touch {
	/**
	 * The constructor.
	 */
	constructor() {
		/**
		 * The browser-given identifier of the touch. Each identifier is unique. 0 is always the mouse left-click.
		 * @type {number}
		 */
		this.identifier = 0;

		/**
		 * The position relative to the root div where the touch initially occurred.
		 * @type {Vector2}
		 */
		this.pressedPosition = new Vector2();

		/**
		 * The duration so-far of the touch.
		 * @type {number}
		 */
		this.pressedTime = 0;

		/**
		 * The position relative to the root div where the touch was moved to last frame.
		 * @type {Vector2}
		 */
		this.lastFramePosition = new Vector2();

		/**
		 * The current position relative to the root div of the touch.
		 * @type {Vector2}
		 */
		this.thisFramePosition = new Vector2();
	}
}

/**
 * The input system. Handles all the dragging, rotating, zooming, keyboard, touch, etc.
 * @hideconstructor
 */
export class Input {
	/**
	 * Constructs the input manager.
	 * @param {Engine} engine - The Pioneer engine.
	 */
	constructor(engine) {
		/**
		 * The engine.
		 * @type {Engine}
		 * @private
		 */
		this._engine = engine;

		/**
		 * The active viewport. The viewport is activated when a left-click or touch happens.
		 * @type {Viewport}
		 * @private
		 */
		this._activeViewport = null;

		/**
		 * The amount that the cursor was tragged in the last frame.
		 * @type {Vector2}
		 * @private
		 */
		this._draggedOffset = new Vector2();
		this._draggedOffset.freeze();

		/**
		 * The amount that was zoomed in the last frame.
		 * @type {number}
		 * @private
		 */
		this._zoomedOffset = 0;

		/**
		 * The amount that was rotated in the last frame.
		 * @type {number}
		 * @private
		 */
		this._rotatedOffset = 0;

		/**
		 * Is shift pressed?
		 * @type {boolean}
		 * @private
		 */
		this._shiftPressed = false;

		/**
		 * The keys that are pressed.
		 * @type {Set<string>}
		 * @private
		 */
		this._keysPressed = new Set();

		/**
		 * The modifiers keys that are pressed.
		 * @type {Set<string>}
		 * @private
		 */
		this._modifierKeysPressed = new Set();

		/**
		 * The maximum time in seconds before it is considered a drag.
		 * @type {number}
		 * @private
		 */
		this._maxSelectTime = 0.5;

		/**
		 * Minimum distance to move the press before it is considered a drag.
		 * @type {number}
		 * @private
		 */
		this._maxSelectDistance = 5;

		/**
		 * The position of the cursor.
		 * @type {Vector2}
		 * @private
		 */
		this._cursorPosition = new Vector2();

		/**
		 * Did the user select something last frame?
		 * @type {boolean}
		 * @private
		 */
		this._selected = false;

		/**
		 * If selected, this is the position of the selection.
		 * @type {Vector2}
		 * @private
		 */
		this._selectedPosition = new Vector2();
		this._selectedPosition.freeze();

		/**
		 * The current touches.
		 * @type {Touch[]}
		 * @private
		 */
		this._touches = [];

		// Setup the event listeners.
		window.addEventListener('blur', () => {
			this._touches = [];
			this._keysPressed.clear();
			this._modifierKeysPressed.clear();
			this._shiftPressed = false;
		});
		window.addEventListener('keydown', event => {
			// Don't process key down events if the user is focused on writing text.
			if (event.target instanceof HTMLElement && ['INPUT', 'SELECT', 'TEXTAREA'].includes(event.target.tagName)) {
				return;
			}
			if (event.key === 'Shift') {
				this._shiftPressed = true;
			}
			// If a modifier key is hit that isn't shift, clear all pressed keys.
			else if (modifierKeys.has(event.key)) {
				this._modifierKeysPressed.add(event.key.toLowerCase());
				this._keysPressed.clear();
				this._shiftPressed = false;
			}
			else if (this._modifierKeysPressed.size === 0) {
				this._keysPressed.add(event.key.toLowerCase());
			}
		});
		window.addEventListener('keyup', event => {
			if (event.key === 'Shift') {
				this._shiftPressed = false;
			}
			else if (modifierKeys.has(event.key)) {
				this._modifierKeysPressed.delete(event.key.toLowerCase());
			}
			else {
				this._keysPressed.delete(event.key.toLowerCase());
			}
		});
		this._engine.getRootDiv().addEventListener('mousedown', event => {
			if (event.button === 0) {
				// Unfocus any other elements on the page.
				if (document.activeElement instanceof HTMLElement) {
					document.activeElement.blur();
				}

				// If this is the first touch.
				if (this._touches.length === 0) {
					const rootDivBounds = this._engine.getRootDiv().getBoundingClientRect();

					// Check for an existing touch with the same identifier.
					let touch = null;
					for (let j = 0; j < this._touches.length; j++) {
						if (this._touches[j].identifier === 0) {
							touch = this._touches[j];
						}
					}
					if (touch === null) {
						touch = new Touch();
					}

					// Create the new touch.
					touch.identifier = 0;
					touch.pressedPosition.set(event.clientX - rootDivBounds.left, event.clientY - rootDivBounds.top);
					touch.pressedTime = Date.now();
					touch.lastFramePosition.copy(touch.pressedPosition);
					touch.thisFramePosition.copy(touch.pressedPosition);
					this._touches.push(touch);

					// Updates active viewport to the one clicked
					this._updateActiveViewport();
				}
			}
		});
		window.addEventListener('mousemove', event => {
			const rootDivBounds = this._engine.getRootDiv().getBoundingClientRect();
			if (this._touches.length === 1 && this._touches[0].identifier === 0) {
				const touch = this._touches[0];
				touch.thisFramePosition.set(event.clientX - rootDivBounds.left, event.clientY - rootDivBounds.top);

				// Update the dragged offset and the latest touch position.
				this._draggedOffset.thaw();
				this._draggedOffset.sub(touch.thisFramePosition, touch.lastFramePosition);

				// If the cursor hasn't moved enough, there is no drag.
				const pressedPositionDistance = Math.max(Math.abs(touch.thisFramePosition.x - touch.pressedPosition.x), Math.abs(touch.thisFramePosition.y - touch.pressedPosition.y));
				if ((Date.now() - touch.pressedTime) / 1000 <= this._maxSelectTime && pressedPositionDistance <= this._maxSelectDistance) {
					this._draggedOffset.set(0, 0);
				}
				this._draggedOffset.freeze();
			}
			if (this._touches.length <= 1) {
				this._cursorPosition.set(event.clientX - rootDivBounds.left, event.clientY - rootDivBounds.top);
			}
		});
		window.addEventListener('mouseup', event => {
			if (event.button === 0) {
				if (this._touches.length === 1 && this._touches[0].identifier === 0) {
					const rootDivBounds = this._engine.getRootDiv().getBoundingClientRect();
					const touch = this._touches[0];
					touch.thisFramePosition.set(event.clientX - rootDivBounds.left, event.clientY - rootDivBounds.top);
					const pressedPositionDistance = Math.max(Math.abs(touch.thisFramePosition.x - touch.pressedPosition.x), Math.abs(touch.thisFramePosition.y - touch.pressedPosition.y));
					if ((Date.now() - touch.pressedTime) / 1000 <= this._maxSelectTime && pressedPositionDistance <= this._maxSelectDistance) {
						this._selected = true;
						this._selectedPosition.thaw();
						this._selectedPosition.copy(touch.thisFramePosition);
						this._selectedPosition.freeze();
					}
					this._touches.splice(0, 1);
				}
			}
		});
		this._engine.getRootDiv().addEventListener('wheel', event => {
			if (event.deltaY) {
				this._zoomedOffset += event.deltaY * 0.1;
			}
			event.preventDefault();
		}, { passive: false });
		this._engine.getRootDiv().addEventListener('touchstart', event => {
			const rootDivBounds = this._engine.getRootDiv().getBoundingClientRect();
			for (let i = 0; i < event.changedTouches.length; i++) {
				const touchEvent = event.changedTouches[i];

				// Check for an existing touch with the same identifier.
				let touch = null;
				for (let j = 0; j < this._touches.length; j++) {
					if (this._touches[j].identifier === touchEvent.identifier) {
						touch = this._touches[j];
					}
				}
				if (touch === null) {
					touch = new Touch();
				}

				// Create the new touch.
				touch.identifier = touchEvent.identifier;
				touch.pressedPosition.thaw();
				touch.pressedPosition.set(touchEvent.pageX - window.pageXOffset - rootDivBounds.left, touchEvent.pageY - window.pageYOffset - rootDivBounds.top);
				touch.pressedPosition.freeze();
				touch.pressedTime = Date.now();
				touch.lastFramePosition.copy(touch.pressedPosition);
				touch.thisFramePosition.copy(touch.pressedPosition);
				this._touches.push(touch);
				if (this._touches.length === 1) {
					this._cursorPosition.copy(touch.thisFramePosition);
				}

				// Updates active viewport to the one touched
				this._updateActiveViewport();
			}
		}, { passive: false });
		window.addEventListener('touchmove', event => {
			const rootDivBounds = this._engine.getRootDiv().getBoundingClientRect();
			for (let i = 0; i < event.changedTouches.length; i++) {
				const touchEvent = event.changedTouches[i];

				for (let j = 0; j < this._touches.length; j++) {
					const touch = this._touches[j];
					if (touch.identifier === touchEvent.identifier) {
						touch.thisFramePosition.set(touchEvent.pageX - window.pageXOffset - rootDivBounds.left, touchEvent.pageY - window.pageYOffset - rootDivBounds.top);
						if (this._touches.length === 1) {
							this._draggedOffset.thaw();
							this._draggedOffset.sub(touch.thisFramePosition, touch.lastFramePosition);
							// If the cursor hasn't moved enough, there is no drag.
							const pressedPositionDistance = Math.max(Math.abs(touch.thisFramePosition.x - touch.pressedPosition.x), Math.abs(touch.thisFramePosition.y - touch.pressedPosition.y));
							if ((Date.now() - touch.pressedTime) / 1000 <= this._maxSelectTime && pressedPositionDistance <= this._maxSelectDistance) {
								this._draggedOffset.set(0, 0);
							}
							this._draggedOffset.freeze();
							this._cursorPosition.copy(touch.thisFramePosition);
						}
						else if (this._touches.length === 2) {
							const touchDiff = Vector2.pool.get();
							touchDiff.sub(this._touches[i].thisFramePosition, this._touches[1 - i].thisFramePosition);
							const touchOffset = Vector2.pool.get();
							touchOffset.sub(this._touches[i].thisFramePosition, this._touches[i].lastFramePosition);
							const dot = touchOffset.dot(touchDiff) / touchDiff.magnitude() / touchOffset.magnitude();
							const cross = touchOffset.cross(touchDiff) / touchDiff.magnitude() / touchOffset.magnitude();
							if (dot < -0.7) {
								this._zoomedOffset += -(dot + 0.7) * touchOffset.magnitude();
							}
							else if (dot > 0.7) {
								this._zoomedOffset += -(dot - 0.7) * touchOffset.magnitude();
							}
							else if (Math.abs(cross) > 0.3) {
								this._rotatedOffset += cross * touchOffset.magnitude();
							}

							Vector2.pool.release(touchOffset);
							Vector2.pool.release(touchDiff);

							// If the touch hasn't moved enough, there is no zoom.
							const pressedPositionDistance = Math.max(Math.abs(touch.thisFramePosition.x - touch.pressedPosition.x), Math.abs(touch.thisFramePosition.y - touch.pressedPosition.y));
							if ((Date.now() - touch.pressedTime) / 1000 <= this._maxSelectTime && pressedPositionDistance <= this._maxSelectDistance) {
								this._zoomedOffset = 0;
								this._rotatedOffset = 0;
							}
						}
					}
				}
			}
		});
		window.addEventListener('touchend', event => {
			const rootDivBounds = this._engine.getRootDiv().getBoundingClientRect();
			for (let i = 0; i < event.changedTouches.length; i++) {
				const touchEvent = event.changedTouches[i];
				for (let j = 0; j < this._touches.length; j++) {
					const touch = this._touches[j];
					if (touch.identifier === touchEvent.identifier) {
						touch.thisFramePosition.set(touchEvent.pageX - window.pageXOffset - rootDivBounds.left, touchEvent.pageY - window.pageYOffset - rootDivBounds.top);
						if (this._touches.length === 1) {
							const pressedPositionDistance = Math.max(Math.abs(touch.thisFramePosition.x - touch.pressedPosition.x), Math.abs(touch.thisFramePosition.y - touch.pressedPosition.y));
							if ((Date.now() - touch.pressedTime) / 1000 <= this._maxSelectTime && pressedPositionDistance <= this._maxSelectDistance) {
								this._selected = true;
								this._selectedPosition.thaw();
								this._selectedPosition.copy(touch.thisFramePosition);
								this._selectedPosition.freeze();
							}
						}
						this._touches.splice(j, 1);
					}
				}
			}
		});
		window.addEventListener('touchcancel', () => {
		});
	}

	/**
	 * Return the current active viewport.
	 * @returns {Viewport}
	 */
	getActiveViewport() {
		return this._activeViewport;
	}

	/**
	 * Gets how much the user dragged this frame.
	 * @returns {Vector2}
	 */
	getDraggedOffset() {
		return this._draggedOffset;
	}

	/**
	 * Returns how much the user zoomed this frame.
	 * @returns {number}
	 */
	getZoomedOffset() {
		return this._zoomedOffset;
	}

	/**
	 * Returns how much the user rotated this frame.
	 * @returns {number}
	 */
	getRotatedOffset() {
		return this._rotatedOffset;
	}

	/**
	 * Returns true if the shift key is down this frame.
	 * @returns {boolean}
	 */
	isShiftPressed() {
		return this._shiftPressed;
	}

	/**
	 * Returns true if the key is pressed.
	 * @param {string} key - The key (in lower-case) to query.
	 * @returns {boolean}
	 */
	isKeyPressed(key) {
		return this._keysPressed.has(key);
	}

	/**
	 * Did the user click (or tap) last frame?
	 * @returns {boolean}
	 */
	isSelected() {
		return this._selected;
	}

	/**
	 * Gets the position when the user last selected, relative to the root div.
	 * @returns {Vector2}
	 */
	getSelectedPosition() {
		return this._selectedPosition;
	}

	/**
	 * Gets the position of the cursor or the last touch.
	 * @returns {Vector2}
	 */
	getCursorPosition() {
		return this._cursorPosition;
	}

	/**
	 * Manually set the active viewport. Called by a viewport on construction if there is no active viewport.
	 * @param {Viewport} viewport - The viewport to set.
	 * @internal
	 */
	__setActiveViewport(viewport) {
		this._activeViewport = viewport;
	}

	/**
	 * Resets all of the values for the next frame. Called by Engine only.
	 * @internal
	 */
	__resetStatesForNextFrame() {
		for (let i = 0; i < this._touches.length; i++) {
			this._touches[i].lastFramePosition.copy(this._touches[i].thisFramePosition);
		}

		this._selected = false;
		this._altSelected = false;
		this._draggedOffset.thaw();
		this._draggedOffset.set(0, 0);
		this._draggedOffset.freeze();
		this._zoomedOffset = 0;
		this._rotatedOffset = 0;
	}

	/**
	 * Automatically updates the active viewport. Called by a mouse down or touch start event.
	 * @private
	 */
	_updateActiveViewport() {
		this._activeViewport = null;
		for (let i = this._engine.getNumViewports() - 1; i >= 0; i--) {
			const pixelBounds = this._engine.getViewport(i).getBounds();
			if (this._engine.getViewport(i).isEnabled() && pixelBounds.contains(this._touches[0].pressedPosition)) {
				this._activeViewport = this._engine.getViewport(i);
				break;
			}
		}
	}
}

/**
 * All modifier keys in Javascript, from https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values.
 */
const modifierKeys = new Set(['Alt', 'AltGraph', 'CapsLock', 'Control', 'Fn', 'FnLock', 'Hyper', 'Meta', 'NumLock', 'ScrollLock', 'Shift', 'Super', 'Symbol', 'SymbolLock']);
