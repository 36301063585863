/* eslint-disable react/jsx-no-bind */
import { useState, memo } from 'react';
import PropTypes from 'prop-types';
import Arrows from '../../www/assets/images/arrows_select_box.svg';
import '../../www/assets/css/dropup.css';

const DropUp = ({ itemList = [], activeItem, onItemClick, customPlaceholder = null, customResetIndex = -1 }) => {
	const activeIndex = itemList.indexOf(activeItem);

	const [isVisible, setIsVisible] = useState(false);

	// Determine the longest text character length
	const textLengths = itemList.map(text => text.length);
	const longestValue = Math.max(...textLengths, customPlaceholder?.length);
	const titleText = activeIndex !== customResetIndex ? activeItem : customPlaceholder;

	return (
		<button
			className='dropup'
			style={{ width: `${44 + (longestValue * 10)}px` }}
			onClick={() => setIsVisible(!isVisible)}
			onBlur={() => setIsVisible(false)}
		>
			<div className='main'>
				{titleText}
				<div className='arrow-wrapper'><Arrows className='svg' /></div>
			</div>
			<ul className={`dropup-list-items-container ${isVisible ? 'visible' : ''}`}>
				{itemList.map((currItem, currIndex) => (
					(
						<li
							className={`dropup-list-item ${currIndex} ${currItem === activeItem ? 'active' : ''}`}
							key={`${currItem.replaceAll(' ', '_').toLowerCase()}${currIndex}`}
							onClick={e => {
								const selectedOptionText = e.target.innerText;
								onItemClick(selectedOptionText);
							}}
						>
							{currItem}
						</li>
					)
				))}
			</ul>
		</button>
	);
};

DropUp.propTypes = {
	itemList: PropTypes.arrayOf(PropTypes.string).isRequired,
	activeItem: PropTypes.string,
	onItemClick: PropTypes.func.isRequired,
	customPlaceholder: PropTypes.string,
	customResetIndex: PropTypes.number
};

export default memo(DropUp);
