/** @module pioneer-scripts */
import { Entity } from '../entity';
import * as Pioneer from 'pioneer';

Entity.register({
	sc_biosentinel: {
		groups: ['sun', 'spacecraft'],
		occlusionRadius: 0.0002,
		extentsRadius: 0.0005,
		label: 'BioSentinel',
		parents: [
			[721866289, 'earth'],
			[722273637, 'moon'],
			[722923565, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_biosentinel/biosentinel.gltf',
			shadowEntities: ['moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_biosentinel/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_biosentinel/moon/orb'
		}, {
			type: 'dynamo',
			url: 'sc_biosentinel/sun/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				target: 'sun',
				axis: Pioneer.Vector3.ZAxis,
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_kepler_space_telescope: {
		groups: ['sun', 'spacecraft', 'telescope'],
		occlusionRadius: 0.002350,
		extentsRadius: 0.00400,
		label: 'Kepler',
		parents: [
			[289679042.1855, 'earth'],
			[290348743, 'sun'],
			[595512069.183, '']
		],
		trail: {
			length: 32190048
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_kepler/Kepler.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_kepler/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_kepler/sun/orb'
		}, {
			type: 'align',
			primary: {
				type: 'align',
				target: 'sun',
				// This number is from 290.666... RA 44.5 DEC, the center of the Kepler cone.
				targetAxis: new Pioneer.Vector3(0.25176480336, -0.66735243742, 0.7009092643),
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.XAxisNeg
			}
		}]
	},
	sc_mariner_2: {
		groups: ['sun', 'spacecraft'],
		occlusionRadius: 0.002,
		extentsRadius: 0.0025000,
		label: 'Mariner 2',
		parents: [
			[-1178599240.703784, 'sun'],
			[409233667.18358755, '']
		],
		trail: {
			length: 29411352.0
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_mariner_2',
			dataType: 'pos'
		}]
	},
	sc_parker_solar_probe: {
		groups: ['sun', 'spacecraft'],
		occlusionRadius: 0.0015,
		extentsRadius: 0.003,
		label: 'Parker Solar Probe',
		parents: [
			[587333783.3431, 'earth'],
			[587454078, 'sun']
		],
		trail: {
			length: 12942631.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_parker_solar_probe/PSP.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_parker_solar_probe/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_parker_solar_probe/sun/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.ZAxisNeg
			}
		}]
	},
	sc_spitzer: {
		groups: ['sun', 'spacecraft'],
		occlusionRadius: 0.0085,
		extentsRadius: 0.004,
		label: 'Spitzer',
		parents: [
			[115064804, 'earth'],
			[115493678, 'sun'],
			[633614469, '']
		],
		trail: {
			length: 32167331.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_spitzer/SPITZER.gltf',
			rotate: [
				{ z: -90 },
				{ x: -90 }
			],
			shadowEntities: ['venus', 'mercury']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_spitzer/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_spitzer/sun/orb'
		}, {
			type: 'fixed',
			orientation: new Pioneer.Quaternion(0.5258297992951877, 0.8139480432783324, -0.1400557906856776, -0.20341086625833524),
			coverage: [115064804, 174548493]
		}, {
			type: 'dynamo',
			url: 'sc_spitzer/quat'
		}]
	},
	sc_stereo_ahead: {
		groups: ['sun', 'spacecraft'],
		occlusionRadius: 0.003,
		extentsRadius: 0.003000,
		label: 'STEREO Ahead',
		parents: [
			[215097110, 'earth'],
			[221418192, 'sun']
		],
		trail: {
			length: 29809031.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_stereo_ahead/stereo_a.gltf',
			rotate: [
				{ x: 90 }
			],
			shadowEntities: ['venus', 'mercury']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_stereo_ahead/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_stereo_ahead/sun/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_stereo_ahead',
				axis: Pioneer.Vector3.YAxisNeg
			}
		}]
	},
	sc_stereo_behind: {
		groups: ['sun', 'spacecraft'],
		occlusionRadius: 0.003,
		extentsRadius: 0.003000,
		label: 'STEREO Behind',
		parents: [
			[215097110, 'earth'],
			[224468337, 'sun'],
			[527860868.182, '']
		],
		trail: {
			length: 33473699.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_stereo_behind/stereo_b.gltf',
			rotate: [
				{ x: 90 }
			],
			shadowEntities: ['venus', 'mercury']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_stereo_behind/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_stereo_behind/sun/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_stereo_behind',
				axis: Pioneer.Vector3.YAxisNeg
			}
		}]
	},
	sc_ulysses: {
		groups: ['sun', 'spacecraft'],
		occlusionRadius: 0.002,
		extentsRadius: 0.002,
		label: 'Ulysses',
		parents: [
			[-291488100, 'sun'],
			[268142464.18410408, '']
		],
		trail: {
			length: 140294739.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_ulysses/ulysses.gltf',
			shadowEntities: ['venus', 'mercury']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_ulysses',
			dataType: 'pos'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_ulysses',
				axis: Pioneer.Vector3.YAxisNeg
			}
		}]
	},
	sc_wmap: {
		groups: ['sun', 'spacecraft'],
		occlusionRadius: 0.0026,
		extentsRadius: 0.0026,
		label: 'WMAP',
		parents: [
			[339422466.184, 'sun'],
			[360158466.184, '']
		],
		trail: {
			length: 60 * 60 * 24 * 365
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_wmap',
			dataType: 'pos'
		}]
	}
});
