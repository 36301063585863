/** @module pioneer */

/**
 * A item within the Collection. This is meant to be subclassed.
 * @template CollectionParentType
 * @abstract
 */
export class CollectionItem {
	/**
	 * Constructs an item.
	 * @constructor
	 * @param {string} type - the type of the item
	 * @param {string} name - the name of the item
	 * @param {CollectionParentType} collectionParent - the object that contains the collection
	 */
	constructor(type, name, collectionParent) {
		/**
		 * The type of the item.
		 * @type {string}
		 * @private
		 */
		this._type = type;

		/**
		 * The index of the item.
		 * @type {number}
		 * @private
		 */
		this._index = 0;

		/**
		 * The type index of the item.
		 * @type {number}
		 * @private
		 */
		this._typeIndex = 0;

		/**
		 * The name of the item.
		 * @type {string}
		 * @private
		 */
		this._name = name;

		/**
		 * The object that contains the collection.
		 * @type {CollectionParentType}
		 * @private
		 */
		this._collectionParent = collectionParent;
	}

	/**
	 * Gets the type.
	 * @returns {string}
	 */
	getType() {
		return this._type;
	}

	/**
	 * Gets the index.
	 * @returns {number}
	 */
	getIndex() {
		return this._index;
	}

	/**
	 * Sets the index. Only to be used internally by Collection.
	 * @param {number} index
	 * @internal
	 */
	__setIndex(index) {
		this._index = index;
	}

	/**
	 * Gets the type index.
	 * @returns {number}
	 */
	getTypeIndex() {
		return this._typeIndex;
	}

	/**
	 * Sets the type index. Only to be used internally by Collection.
	 * @param {number} typeIndex
	 * @internal
	 */
	__setTypeIndex(typeIndex) {
		this._typeIndex = typeIndex;
	}

	/**
	 * Gets the name.
	 * @returns {string}
	 */
	getName() {
		return this._name;
	}

	/**
	 * Gets the object that contains the collection. Only to be used internally by the class that contains this.
	 * @returns {CollectionParentType}
	 */
	__getCollectionParent() {
		return this._collectionParent;
	}

	/**
	 * Sets the object that contains the collection. Only to be used internally by the class that contains this.
	 * @param {CollectionParentType} collectionParent
	 */
	__setCollectionParent(collectionParent) {
		this._collectionParent = collectionParent;
	}

	/**
	 * Does whatever is necessary to clean up the resources used by the item.
	 * @abstract
	 */
	__destroy() {
	}
}

/**
 * The type constructor that takes a type, name, and its parent.
 * @template {CollectionItem<CollectionParentType>} ItemType
 * @template CollectionParentType
 * @typedef {new (type: string, name: string, parent: CollectionParentType) => ItemType} TypeConstructor
 */

/**
 * An ordered and named list of items. Every item constructor must be of the form Type(name, parent).
 * @template {CollectionItem<CollectionParentType>} ItemType
 * @template CollectionParentType
 */
export class Collection {
	/**
	 * Constructs the collection.
	 * @constructor
	 * @param {CollectionParentType} parent - the object that contains this collection
	 * @param {Map<string, TypeConstructor<ItemType, CollectionParentType>>} types - the mapping from type names to type constructors
	 */
	constructor(parent, types) {
		/**
		 * The parent of this collection that the items can refer to.
		 * @type {CollectionParentType}
		 * @private
		 */
		this._parent = parent;

		/**
		 * The mapping from type names to type constructors.
		 * @type {Map<string, TypeConstructor<ItemType, CollectionParentType>>}
		 * @private
		 */
		this._types = types;

		/**
		 * The array of items to manage.
		 * @type {ItemType[]}
		 * @private
		 */
		this._items = [];

		/**
		 * The mapping from names to items.
		 * @type {Map<string, ItemType>}
		 * @private
		 */
		this._itemsByName = new Map();

		/**
		 * The mapping from type constructors to items in arrays.
		 * @type {Map<TypeConstructor<ItemType, CollectionParentType>, ItemType[]>}
		 * @private
		 */
		this._itemsByType = new Map();
	}

	/**
	 * Gets the item from either the item, name, or an index. It returns null if the item is not found.
	 * @param {ItemType|string|number} itemOrNameOrIndex - the item, its name, or its index to get
	 * @returns {ItemType | null}
	 */
	get(itemOrNameOrIndex) {
		const index = this._getByItemOrNameOrIndex(itemOrNameOrIndex);
		return index !== undefined ? this._items[index] : null;
	}

	/**
	 * Gets the index'th item of the given type. The index is base 0. Returns null if none is found.
	 * @param {string} typeName
	 * @param {number} [index=0]
	 * @returns {ItemType | null}
	 */
	getByType(typeName, index = 0) {
		const type = this._types.get(typeName);
		if (type === undefined) {
			return null;
		}
		const itemsOfType = this._itemsByType.get(type);
		if (itemsOfType === undefined) {
			return null;
		}
		if (index < 0 || index >= itemsOfType.length) {
			return null;
		}
		return itemsOfType[index];
	}

	/**
	 * Gets the index'th item of the given type. The index is base 0. Returns null if none is found.
	 * @template {ItemType} Class
	 * @param {TypeConstructor<Class, CollectionParentType>} ClassConstructor
	 * @param {number} [index=0]
	 * @returns {Class | null}
	 */
	getByClass(ClassConstructor, index = 0) {
		const typesList = this._itemsByType.get(ClassConstructor);
		if (typesList !== undefined && index >= 0 && index < typesList.length) {
			return /** @type {Class | null} */(typesList[index]);
		}
		return null;
	}

	/**
	 * Create an item using with the given name and return it.
	 * @param {string} type - the type of the item to be created
	 * @param {string} [name=''] - the name of the item to be created
	 * @param {ItemType} [beforeItem] - insert the item before this item
	 * @returns {ItemType}
	 */
	add(type, name = '', beforeItem) {
		// Check if the name already exists in the scene.
		if (name !== '' && this._itemsByName.has(name)) {
			throw new Error(`Already added "${name}".`);
		}
		const TypeConstructor = this._types.get(type);
		if (type === undefined) {
			throw new Error(`Type "${type}" not found.`);
		}
		let item;
		try {
			item = new TypeConstructor(type, name, this._parent);
		}
		catch (error) {
			if (error instanceof Error) {
				error.message = `While adding "${name}" of type "${type}": ${error.message}`;
			}
			throw error;
		}
		// Add the item to the lists.
		this._addToLists(name, item, beforeItem);
		// Return the newly created item.
		return item;
	}

	/**
	 * Create an item using with the given name and return it.
	 * @template {ItemType} Class
	 * @param {TypeConstructor<Class, CollectionParentType>} ClassConstructor
	 * @param {string} [name=''] - the name of the item to be created
	 * @param {ItemType} [beforeItem] - insert the item before this item
	 * @returns {Class}
	 */
	addByClass(ClassConstructor, name = '', beforeItem) {
		// Check if the name already exists in the scene.
		if (name !== '' && this._itemsByName.has(name)) {
			throw new Error(`Already added "${name}".`);
		}
		try {
			// Get the string type associated with the class, if there is one.
			let type = '';
			for (const entry of this._types.entries()) {
				if (entry[1] === ClassConstructor) {
					type = entry[0];
					break;
				}
			}
			// Create the new item.
			const item = new ClassConstructor(type, name, this._parent);
			// Add the item to the lists.
			this._addToLists(name, item, beforeItem);
			// Return the newly created item.
			return item;
		}
		catch (error) {
			if (error instanceof Error) {
				error.message = `While adding "${name}": ${error.message}`;
			}
			throw error;
		}
	}

	/**
	 * Moves an item to another collection of the same type.
	 * @param {ItemType|string|number} itemOrNameOrIndex - the item, its name, or its index to be moved
	 * @param {Collection<ItemType, CollectionParentType>} newCollection
	 * @param {ItemType} [beforeItem] - insert the item before this item in the new scene.
	 */
	move(itemOrNameOrIndex, newCollection, beforeItem) {
		// Get the index of the item.
		const index = this._getByItemOrNameOrIndex(itemOrNameOrIndex);
		// If the item isn't found, do nothing.
		if (index === undefined) {
			throw new Error('While moving item, the item is not found.');
		}
		// Get the item and name.
		const item = this._items[index];
		const name = item.getName();
		// Check if the name already exists in the new scene.
		if (name !== '' && newCollection._itemsByName.has(name)) {
			throw new Error(`Already added "${name}".`);
		}
		// Remove the item from this list.
		this._removeFromLists(index, name, item);
		// Add the item to the new list.
		newCollection._addToLists(name, item, beforeItem);
		// Update the parent.
		item.__setCollectionParent(newCollection._parent);
	}

	/**
	 * Remove and destroy an item.
	 * @param {ItemType|string|number} itemOrNameOrIndex - the item, its name, or its index to be removed
	 */
	remove(itemOrNameOrIndex) {
		// Get the index of the item.
		const index = this._getByItemOrNameOrIndex(itemOrNameOrIndex);
		// If the item isn't found, do nothing.
		if (index === undefined) {
			return;
		}
		// Get the item and name.
		const item = this._items[index];
		const name = item.getName();
		// Remove the item from the lists.
		this._removeFromLists(index, name, item);
		// Call the destroy function.
		item.__destroy();
	}

	/**
	 * Removes and destroys all of the items.
	 */
	clear() {
		for (let i = this._items.length - 1; i >= 0; i--) {
			this._items[i].__destroy();
		}
		this._items = [];
		this._itemsByName.clear();
		this._itemsByType.clear();
	}

	/**
	 * Gets the number of items.
	 * @returns {number}
	 */
	get size() {
		return this._items.length;
	}

	/**
	 * Adds an item to the lists.
	 * @param {string} name
	 * @param {ItemType} item
	 * @param {ItemType | undefined} beforeItem
	 * @private
	 */
	_addToLists(name, item, beforeItem) {
		// Add the item to the lists.
		const index = beforeItem !== undefined ? beforeItem.getIndex() : this._items.length;
		this._items.splice(index, 0, item);
		if (name !== '') {
			this._itemsByName.set(name, item);
		}
		this._updateIndices(index, item);
	}

	/**
	 * Removes an item from the lists.
	 * @param {number} index
	 * @param {string} name
	 * @param {ItemType} item
	 * @private
	 */
	_removeFromLists(index, name, item) {
		// Remove the item from the lists.
		this._items.splice(index, 1);
		if (name !== '') {
			this._itemsByName.delete(name);
		}
		this._updateIndices(index, item);
	}

	/**
	 * Update indices and type indices.
	 * @param {number} startingIndex
	 * @param {ItemType} item
	 * @private
	 */
	_updateIndices(startingIndex, item) {
		// Update the indices.
		for (let i = startingIndex; i < this._items.length; i++) {
			this._items[i].__setIndex(i);
		}
		// Update the type indices for this type.
		const type = /** @type {TypeConstructor<ItemType, CollectionParentType>} */(item.constructor);
		const typesList = this._itemsByType.set(type, []).get(type);
		let typeIndex = item.getTypeIndex();
		for (let i = 0; i < this._items.length; i++) {
			if (this._items[i].constructor === type) {
				this._items[i].__setTypeIndex(typeIndex);
				typesList.push(this._items[i]);
				typeIndex += 1;
			}
		}
		if (typesList.length === 0) {
			this._itemsByType.delete(type);
		}
	}

	__destroy() {
		for (let i = this._items.length - 1; i >= 0; i--) {
			this._items[i].__destroy();
		}
	}

	/**
	 * Gets the index of an item by item, name, or index.
	 * @param {ItemType|string|number} itemOrNameOrIndex
	 * @returns {number | undefined}
	 * @private
	 */
	_getByItemOrNameOrIndex(itemOrNameOrIndex) {
		if (typeof itemOrNameOrIndex === 'number') {
			if (itemOrNameOrIndex >= 0 && itemOrNameOrIndex < this._items.length) {
				return itemOrNameOrIndex;
			}
		}
		else { // item or name
			let item;
			if (typeof itemOrNameOrIndex === 'string') {
				item = this._itemsByName.get(itemOrNameOrIndex);
			}
			else {
				item = itemOrNameOrIndex;
			}
			for (let i = this._items.length - 1; i >= 0; i--) {
				if (this._items[i] === item) {
					return i;
				}
			}
		}
		return undefined;
	}
}
