/** @module pioneer */

import { Sort } from './internal';

/**
 * A download object for every download that is returned to the user.
 */
export class Download {
	/**
	 * The constructor.
	 * @param {string} url
	 * @param {string} actualUrl
	 * @param {boolean} binary
	 */
	constructor(url, actualUrl, binary) {
		/**
		 * The unprocessed url given by the user.
		 * @type {string}
		 */
		this.url = url;

		/**
		 * The processed url actually sent to the browser.
		 * @type {string}
		 */
		this.actualUrl = actualUrl;

		/**
		 * The progress from 0 to 1.
		 * @type {number}
		 */
		this.progress = 0;

		/**
		 * The total content size in bytes.
		 * @type {number}
		 */
		this.totalBytes = 0;

		/**
		 * The content of the completed download.
		 * @type {string | ArrayBuffer | undefined}
		 */
		this.content = undefined;

		/**
		 * Whether or not the content is binary.
		 * @type {boolean}
		 */
		this.binary = binary;

		/**
		 * The mime type of the download.
		 * @type {string}
		 */
		this.mimeType = '';

		/**
		 * The status of the download. It can be 'downloading', 'completed', 'cancelled', or 'failed'
		 * @type {'downloading' | 'completed' | 'cancelled' | 'failed'}
		 */
		this.status = 'downloading';

		/**
		 * The error message, if there is one.
		 * @type {string}
		 */
		this.errorMessage = '';
	}
}

/**
 * A download object for every download that is used by the Downloader.
 * @private
 */
class DownloadData {
	/**
	 * The constructor.
	 * @param {string} url
	 * @param {string} actualUrl
	 * @param {boolean} binary
	 */
	constructor(url, actualUrl, binary) {
		/**
		 * The download object to be returned to the user.
		 * @type {Download}
		 */
		this.download = new Download(url, actualUrl, binary);

		/**
		 * The promise that is returned when completed or cancelled.
		 * @type {Promise<Download> | null}
		 */
		this.promise = null;

		/**
		 * The resolve function of the promise.
		 * @type {(download: Download) => void}
		 */
		this.resolve = null;

		/**
		 * The list of callbacks to call when the progress changes.
		 * @type {((download: Download) => any)[]}
		 */
		this.progressCallbacks = [];

		/**
		 * The XMLHttpRequest object.
		 * @type {XMLHttpRequest | null}
		 */
		this.request = null;
	}
}

/**
 * A class that downloads assets. It can preprocess URLs via replacement keywords.
 */
export class Downloader {
	/**
	 * Constructor. Called by Engine.
	 */
	constructor() {
		/**
		 * The mapping of strings to replacements when downloading urls.
		 * @type {Map<string, string>}
		 * @private
		 */
		this._replacements = new Map();

		/**
		 * The current set of downloads, mapped from url to Download object.
		 * @type {Map<string, DownloadData>}
		 * @private
		 */
		this._currentDownloads = new Map();

		/**
		 * The download queue, sorted by priority.
		 * @type {[number, DownloadData][]}
		 * @private
		 */
		this._downloadQueue = [];

		/**
		 * The maximum number of current downloads.
		 * @type {number}
		 */
		this._maxCurrentDownloads = 20;
	}

	/**
	 * Gets the replacement for a given name, or undefined if no replacement was found.
	 * @param {string} name - The name to be replaced.
	 * @returns {string | undefined}
	 */
	getReplacement(name) {
		return this._replacements.get(name);
	}

	/**
	 * Sets a replacement so that when a URL contains <em>$name</em>, it will be replaced with the replacement.
	 * @param {string} name - The name (without the $) to be replaced.
	 * @param {string} replacement - The replacement string.
	 */
	setReplacement(name, replacement) {
		this._replacements.set(name, replacement);
	}

	/**
	 * Cancels a download for the given url. If there are multiple downloads of the same url, they are all cancelled.
	 * @param {string} url - The url of the download to cancel.
	 */
	cancel(url) {
		const actualUrl = this.processUrl(url);

		// If we're currently downloading, call the abort function.
		if (this._currentDownloads.has(actualUrl)) {
			const downloadData = this._currentDownloads.get(actualUrl);
			if (downloadData !== undefined && downloadData.request !== null) {
				downloadData.request.abort();
			}
		}
		else {

			// If we're in the queue still, there's no request, so artificially end the download as if there was.
			const queueIndex = this._downloadQueue.findIndex(([_, downloadData]) => downloadData.download.actualUrl === actualUrl);
			if (queueIndex !== -1) {

				const downloadData = this._downloadQueue[queueIndex][1];
				downloadData.download.content = undefined;
				downloadData.download.status = 'cancelled';
				this._downloadQueue.splice(queueIndex, 1);
				downloadData.resolve(downloadData.download);
			}
		}
	}

	/**
	 * Process url parameters, replacing the <code>$name</code> variables with their corresponding replacements.
	 * @param {string} url - The url to process.
	 * @returns {string}
	 */
	processUrl(url) {
		let processedUrl = url;
		for (const [name, replacement] of this._replacements) {
			processedUrl = processedUrl.replace('$' + name, replacement);
		}
		return processedUrl;
	}

	/**
	 * Downloads a file and returns a promise that resolves with a Download object.
	 * If the download is cancelled, the status will be 'cancelled'.
	 * If the download has failed, the status will be 'failed' and the errorMessage will be the error.
	 * If the download has completed, the status will be 'completed'.
	 * It replaces <code>$name</code> variables with their replacements.
	 * @param {string} url - The url to download.
	 * @param {boolean} binary - If true, the Download.content is an ArrayBuffer and is otherwise a string.
	 * @param {number} priority - The priority of the download. A greater number is higher priority.
	 * @param {(download: Download) => any} [progressCallback] - The callback to be called periodically to report the progress of the download.
	 * @returns {Promise<Download>}
	 */
	download(url, binary, priority, progressCallback) {
		// Process the urls, doing the replacements.
		const actualUrl = this.processUrl(url);

		// If the download already currently running, just return its promise.
		let downloadData = this._currentDownloads.get(actualUrl);
		if (downloadData !== undefined && downloadData.promise !== null) {
			if (progressCallback) {
				downloadData.progressCallbacks.push(progressCallback);
			}
			return downloadData.promise;
		}

		// If the download is in the queue, return its promise.
		const queueIndex = this._downloadQueue.findIndex(([_, downloadData]) => downloadData.download.actualUrl === actualUrl);
		if (queueIndex !== -1) {
			downloadData = this._downloadQueue[queueIndex][1];
			if (progressCallback) {
				downloadData.progressCallbacks.push(progressCallback);
			}
			// If this is a greater priority that what was specified before, we need to reinsert it into the better place in the queue.
			if (priority > this._downloadQueue[queueIndex][0]) {
				this._downloadQueue.splice(queueIndex, 1);
				Sort.add([priority, downloadData], this._downloadQueue, (a, b) => a[0] > b[0], (a, b) => a[0] === b[0]);
			}
			return downloadData.promise;
		}

		// Create the download object.
		downloadData = new DownloadData(url, actualUrl, binary);

		// Set the promise.
		downloadData.promise = new Promise((resolve) => {

			// Save the resolve function for later calling.
			downloadData.resolve = resolve;

			// Add to the download queue and sort it.
			Sort.add([priority, downloadData], this._downloadQueue, (a, b) => a[0] > b[0], (a, b) => a[0] === b[0]);

			// Check the download queue to see if we can start this request right away.
			this.checkDownloadQueue();
		});

		return downloadData.promise;
	}

	/**
	 * Does an actual request.
	 * @param {DownloadData} downloadData
	 * @private
	 */
	doRequest(downloadData) {

		// Create the XMLHttpRequest.
		const request = new XMLHttpRequest();

		// Load listener.
		request.addEventListener('load', () => {
			if (200 <= request.status && request.status <= 299) {
				downloadData.download.content = request.response;
				downloadData.download.mimeType = request.getResponseHeader('Content-Type');
				downloadData.download.status = 'completed';
			}
			else {
				downloadData.download.content = undefined;
				downloadData.download.status = 'failed';
			}
			this._currentDownloads.delete(downloadData.download.actualUrl);
			this.checkDownloadQueue();
			downloadData.resolve(downloadData.download);
		});

		// Progress listener.
		request.addEventListener('progress', (event) => {
			downloadData.download.progress = event.lengthComputable ? (event.loaded / event.total) : 0;
			downloadData.download.totalBytes = event.lengthComputable ? event.total : 0;
			for (const progressCallback of downloadData.progressCallbacks) {
				progressCallback(downloadData.download);
			}
		});

		// Abort listener.
		request.addEventListener('abort', () => {
			downloadData.download.content = undefined;
			downloadData.download.status = 'cancelled';
			this._currentDownloads.delete(downloadData.download.actualUrl);
			this.checkDownloadQueue();
			downloadData.resolve(downloadData.download);
		});

		// Error listener.
		request.addEventListener('error', () => {
			downloadData.download.content = undefined;
			downloadData.download.status = 'failed';
			downloadData.download.errorMessage = request.statusText;
			this._currentDownloads.delete(downloadData.download.actualUrl);
			this.checkDownloadQueue();
			downloadData.resolve(downloadData.download);
		});

		if (downloadData.download.binary) {
			request.responseType = 'arraybuffer';
		}
		else {
			request.responseType = 'text';
		}

		downloadData.request = request;
		request.open('GET', downloadData.download.actualUrl);
		request.send();
	}

	/**
	 * Checks the download queue for another download and starts it, if needed.
	 * @private
	 */
	checkDownloadQueue() {
		if (this._currentDownloads.size < this._maxCurrentDownloads && this._downloadQueue.length > 0) {
			const [_, downloadData] = this._downloadQueue.shift();
			this._currentDownloads.set(downloadData.download.actualUrl, downloadData);
			this.doRequest(downloadData);
		}
	}
}
