/** @module pioneer */
import {
	BaseController,
	CameraComponent,
	Entity,
	Geometry,
	Interval,
	LatLonAlt,
	MathUtils,
	Quaternion,
	Vector3
} from '../../internal';

/**
 * An orbit camera controller orbiting around a specific axis.
 */
export class OrbitController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The sensitivity for dragging.
		 * @type {number}
		 * @private
		 */
		this._dragSensitivity = 0.01;

		/**
		 * The smoothness of the dragging. Zero means no smoothness.
		 * @type {number}
		 * @private
		 */
		this._dragSmoothness = 0.8;

		/**
		 * The current value applied every frame to the yaw axis rotation.
		 * @type {number}
		 * @private
		 */
		this._yawChangeSmoothedValue = 0.0;

		/**
		 * The current value applied every frame to the pitch axis rotation.
		 * @type {number}
		 * @private
		 */
		this._pitchChangeSmoothedValue = 0.0;

		/**
		 * The axis around which to yaw. It can be 'none', 'x-axis', 'y-axis', 'z-axis', or 'position'.
		 * @type {string}
		 * @private
		 */
		this._yawAxisType = 'none';

		/**
		 * The yaw axis reference entity.
		 * @type {Entity}
		 * @private
		 */
		this._yawAxisEntity = null;

		/**
		 * The axis around which to pitch. It can be 'none', 'x-axis', 'y-axis', 'z-axis', or 'position'.
		 * @type {string}
		 * @private
		 */
		this._pitchAxisType = 'none';

		/**
		 * The pitch axis reference entity.
		 * @type {Entity}
		 * @private
		 */
		this._pitchAxisEntity = null;

		/**
		 * When true, the entity will slow as it gets closer to its parent's occlusion radius.
		 * @type {boolean}
		 * @private
		 */
		this._slowWhenCloseToParent = false;

		/**
		 * The limits of the yaw angle.
		 * @type {Interval}
		 * @private
		 */
		this._yawAngleLimits = new Interval(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY);
		this._yawAngleLimits.freeze();

		/**
		 * The limits of the pitch angle.
		 * @type {Interval}
		 * @private
		 */
		this._pitchAngleLimits = new Interval(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY);
		this._pitchAngleLimits.freeze();

		// Let the base controller know that this changes the position and orientation.
		this.addModifiedState('position');
		this.addModifiedState('orientation');
	}

	/**
	 * Gets the drag sensitivity. Defaults to 0.01.
	 * @returns {number}
	 */
	getDragSensitivity() {
		return this._dragSensitivity;
	}

	/**
	 * Sets the drag sensitivity.
	 * @param {number} dragSensitivity
	 */
	setDragSensitivity(dragSensitivity) {
		this._dragSensitivity = dragSensitivity;
	}

	/**
	 * Gets the drag smoothness. Defaults to 0.8.
	 * @returns {number}
	 */
	getDragSmoothness() {
		return this._dragSmoothness;
	}

	/**
	 * Sets the drag smoothness, between 0 and 1.
	 * @param {number} dragSmoothness
	 */
	setDragSmoothness(dragSmoothness) {
		this._dragSmoothness = dragSmoothness;
	}

	/**
	 * Gets the axis around which to yaw. It defaults to 'none'.
	 * @returns {string}
	 */
	getYawAxisType() {
		return this._yawAxisType;
	}

	/**
	 * Sets the axis around which to yaw. It can be 'none', 'x-axis', 'y-axis', 'z-axis', or 'position'.
	 * @param {string} yawAxisType
	 */
	setYawAxisType(yawAxisType) {
		if (this._yawAxisType === yawAxisType) {
			return;
		}
		if (this._yawAxisEntity !== null) {
			if (['x-axis', 'y-axis', 'z-axis'].includes(this._yawAxisType)) {
				this.removeDependentState(this._yawAxisEntity.getName(), 'orientation');
			}
			else if (this._yawAxisType === 'position') {
				this.removeDependentState(this._yawAxisEntity.getName(), 'position');
			}
		}
		this._yawAxisType = yawAxisType;
		if (this._yawAxisEntity !== null) {
			if (['x-axis', 'y-axis', 'z-axis'].includes(this._yawAxisType)) {
				this.addDependentState(this._yawAxisEntity.getName(), 'orientation');
			}
			else if (this._yawAxisType === 'position') {
				this.addDependentState(this._yawAxisEntity.getName(), 'position');
			}
		}
	}

	/**
	 * Gets the axis around which to pitch. It defaults to 'none'.
	 * @returns {string}
	 */
	getPitchAxisType() {
		return this._pitchAxisType;
	}

	/**
	 * Sets the axis around which to pitch. It can be 'none', 'x-axis', 'y-axis', 'z-axis', or 'position'.
	 * @param {string} pitchAxisType
	 */
	setPitchAxisType(pitchAxisType) {
		if (this._pitchAxisType === pitchAxisType) {
			return;
		}
		if (this._pitchAxisEntity !== null) {
			if (['x-axis', 'y-axis', 'z-axis'].includes(this._pitchAxisType)) {
				this.removeDependentState(this._pitchAxisEntity.getName(), 'orientation');
			}
			else if (this._pitchAxisType === 'position') {
				this.removeDependentState(this._pitchAxisEntity.getName(), 'position');
			}
		}
		this._pitchAxisType = pitchAxisType;
		if (this._pitchAxisEntity !== null) {
			if (['x-axis', 'y-axis', 'z-axis'].includes(this._pitchAxisType)) {
				this.addDependentState(this._pitchAxisEntity.getName(), 'orientation');
			}
			else if (this._pitchAxisType === 'position') {
				this.addDependentState(this._pitchAxisEntity.getName(), 'position');
			}
		}
	}

	/**
	 * Gets the yaw axis reference entity. Defaults to this entity's parent.
	 * @returns {Entity}
	 */
	getYawAxisEntity() {
		return this._yawAxisEntity;
	}

	/**
	 * Sets the yaw axis reference entity.
	 * @param {Entity} yawAxisEntity
	 */
	setYawAxisEntity(yawAxisEntity) {
		if (this._yawAxisEntity === yawAxisEntity) {
			return;
		}
		if (this._yawAxisEntity !== null) {
			if (['x-axis', 'y-axis', 'z-axis'].includes(this._yawAxisType)) {
				this.removeDependentState(this._yawAxisEntity.getName(), 'orientation');
			}
			else if (this._yawAxisType === 'position') {
				this.removeDependentState(this._yawAxisEntity.getName(), 'position');
			}
		}
		this._yawAxisEntity = yawAxisEntity;
		if (this._yawAxisEntity !== null) {
			if (['x-axis', 'y-axis', 'z-axis'].includes(this._yawAxisType)) {
				this.addDependentState(this._yawAxisEntity.getName(), 'orientation');
			}
			else if (this._yawAxisType === 'position') {
				this.addDependentState(this._yawAxisEntity.getName(), 'position');
			}
		}
	}

	/**
	 * Gets the pitch axis reference entity. Defaults to this entity's parent.
	 * @returns {Entity}
	 */
	getPitchAxisEntity() {
		return this._pitchAxisEntity;
	}

	/**
	 * Sets the pitch axis reference entity.
	 * @param {Entity} pitchAxisEntity
	 */
	setPitchAxisEntity(pitchAxisEntity) {
		if (this._pitchAxisEntity === pitchAxisEntity) {
			return;
		}
		if (this._pitchAxisEntity !== null) {
			if (['x-axis', 'y-axis', 'z-axis'].includes(this._pitchAxisType)) {
				this.removeDependentState(this._pitchAxisEntity.getName(), 'orientation');
			}
			else if (this._pitchAxisType === 'position') {
				this.removeDependentState(this._pitchAxisEntity.getName(), 'position');
			}
		}
		this._pitchAxisEntity = pitchAxisEntity;
		if (this._pitchAxisEntity !== null) {
			if (['x-axis', 'y-axis', 'z-axis'].includes(this._pitchAxisType)) {
				this.addDependentState(this._pitchAxisEntity.getName(), 'orientation');
			}
			else if (this._pitchAxisType === 'position') {
				this.addDependentState(this._pitchAxisEntity.getName(), 'position');
			}
		}
	}

	/**
	 * Gets the limits of the yaw angle.
	 * @returns {Interval}
	 */
	getYawAngleLimits() {
		return this._yawAngleLimits;
	}

	/**
	 * Sets the limits of the yaw angle.
	 * @param {Interval} yawAngleLimits
	 */
	setYawAngleLimits(yawAngleLimits) {
		this._yawAngleLimits.thaw();
		this._yawAngleLimits.copy(yawAngleLimits);
		this._yawAngleLimits.freeze();
	}

	/**
	 * Gets the limits of the pitch angle.
	 * @returns {Interval}
	 */
	getPitchAngleLimits() {
		return this._pitchAngleLimits;
	}

	/**
	 * Sets the limits of the pitch angle.
	 * @param {Interval} pitchAngleLimits
	 */
	setPitchAngleLimits(pitchAngleLimits) {
		this._pitchAngleLimits.thaw();
		this._pitchAngleLimits.copy(pitchAngleLimits);
		this._pitchAngleLimits.freeze();
	}

	/**
	 * Returns whether the entity will slow as it gets closer to its parent's occlusion radius.
	 * @returns {boolean}
	 */
	isSlowWhenCloseToParent() {
		return this._slowWhenCloseToParent;
	}

	/**
	 * Sets whether the entity will slow as it gets closer to its parent's occlusion radius.
	 * @param {boolean} enabled
	 */
	slowWhenCloseToParent(enabled) {
		this._slowWhenCloseToParent = enabled;
	}

	/**
	 * Updates the entity's position and orientation.
	 * @override
	 * @internal
	 */
	__update() {
		// There is no pivot, so don't do anything.
		if (this.getEntity().getParent() === null) {
			return;
		}

		// Set the position and orientation if they have never been set before.
		if (this.getEntity().getOrientation().isNaN()) {
			this.getEntity().setOrientation(Quaternion.Identity);
		}
		if (this.getEntity().getPosition().isNaN()) {
			this.getEntity().setPosition(new Vector3(0, -1, 0));
		}

		// Get the orbit multiplier for if 'x' or 'shift' is pressed.
		const input = this.getEntity().getScene().getEngine().getInput();
		let orbitMultiplier = 1;
		if (input.isKeyPressed('x')) {
			orbitMultiplier = 0.05;
		}
		if (input.isShiftPressed()) {
			orbitMultiplier = 5;
		}
		// Factor in the field of view of the camera.
		const camera = /** @type {CameraComponent} */(this.getEntity().getComponentByType('camera'));
		if (camera !== null) {
			orbitMultiplier *= Math.min(1, camera.getFieldOfView());
		}

		// Get the azimuth and elevation change from the input.
		let yawChange = 0;
		let pitchChange = 0;
		const viewport = input.getActiveViewport();
		if (viewport !== null) {
			const camera = viewport.getCamera();
			if (camera !== null && camera.getEntity() === this.getEntity()) {
				// Add mouse/touch movement.
				const draggedOffset = input.getDraggedOffset();
				yawChange = -draggedOffset.x * this._dragSensitivity * orbitMultiplier;
				pitchChange = draggedOffset.y * this._dragSensitivity * orbitMultiplier;

				// Add key movement.
				if (input.isKeyPressed('d')) {
					yawChange += this._dragSensitivity * orbitMultiplier;
				}
				if (input.isKeyPressed('a')) {
					yawChange -= this._dragSensitivity * orbitMultiplier;
				}
				if (input.isKeyPressed('e')) {
					pitchChange -= this._dragSensitivity * orbitMultiplier;
				}
				if (input.isKeyPressed('q')) {
					pitchChange += this._dragSensitivity * orbitMultiplier;
				}
			}
		}

		// Apply smoothing.
		this._yawChangeSmoothedValue = MathUtils.lerp(yawChange, this._yawChangeSmoothedValue, this._dragSmoothness);
		this._pitchChangeSmoothedValue = MathUtils.lerp(pitchChange, this._pitchChangeSmoothedValue, this._dragSmoothness);
		if (Math.abs(this._yawChangeSmoothedValue) < 0.0001 * orbitMultiplier) {
			this._yawChangeSmoothedValue = 0;
		}
		if (Math.abs(this._pitchChangeSmoothedValue) < 0.0001 * orbitMultiplier) {
			this._pitchChangeSmoothedValue = 0;
		}

		// Get the yaw axis.
		const yawAxis = Vector3.pool.get();
		// If the yaw axis entity is null, set it to the parent.
		if (this._yawAxisEntity === null) {
			this._yawAxisEntity = this.getEntity().getParent();
		}
		if (this._yawAxisType === 'x-axis' && this._yawAxisEntity !== null) { // Use the x-axis of the reference entity.
			this._yawAxisEntity.getOrientation().getAxis(yawAxis, 0);
		}
		else if (this._yawAxisType === 'y-axis' && this._yawAxisEntity !== null) { // Use the y-axis of the reference entity.
			this._yawAxisEntity.getOrientation().getAxis(yawAxis, 1);
		}
		else if (this._yawAxisType === 'z-axis' && this._yawAxisEntity !== null) { // Use the z-axis of the reference entity.
			this._yawAxisEntity.getOrientation().getAxis(yawAxis, 2);
		}
		else if (this._yawAxisType === 'position' && this._yawAxisEntity !== null) { // Use the position of the reference entity.
			yawAxis.normalize(this._yawAxisEntity.getPosition());
		}
		else {
			this.getEntity().getOrientation().getAxis(yawAxis, 2); // Use the entity's z-axis.
		}

		// If the yaw axis isn't ready, just work as if the yaw axis type is 'none'.
		if (yawAxis.isNaN() || yawAxis.isZero()) {
			this.getEntity().getOrientation().getAxis(yawAxis, 2);
		}

		// Get the pitch axis.
		const pitchAxis = Vector3.pool.get();
		// If the pitch axis entity is null, set it to the parent.
		if (this._pitchAxisEntity === null) {
			this._pitchAxisEntity = this.getEntity().getParent();
		}
		if (this._pitchAxisType === 'x-axis' && this._pitchAxisEntity !== null) { // Use the x-axis of the reference entity.
			this._pitchAxisEntity.getOrientation().getAxis(pitchAxis, 0);
		}
		else if (this._pitchAxisType === 'y-axis' && this._pitchAxisEntity !== null) { // Use the y-axis of the reference entity.
			this._pitchAxisEntity.getOrientation().getAxis(pitchAxis, 1);
		}
		else if (this._pitchAxisType === 'z-axis' && this._pitchAxisEntity !== null) { // Use the z-axis of the reference entity.
			this._pitchAxisEntity.getOrientation().getAxis(pitchAxis, 2);
		}
		else if (this._pitchAxisType === 'position' && this._pitchAxisEntity !== null) { // Use the position of the reference entity.
			pitchAxis.normalize(this._pitchAxisEntity.getPosition());
		}
		else {
			this.getEntity().getOrientation().getAxis(pitchAxis, 0); // Use the entity's x-axis.
		}

		// If the pitch axis isn't ready, just work as if the pitch axis type is 'none'.
		if (pitchAxis.isNaN() || pitchAxis.isZero()) {
			this.getEntity().getOrientation().getAxis(pitchAxis, 0);
		}

		// Make the pitch axis orthonormal to the yaw axis.
		pitchAxis.setNormalTo(yawAxis, pitchAxis);

		// Get the axes as a quaternion frame.
		const axisFrame = Quaternion.pool.get();
		axisFrame.setFromAxes(pitchAxis, undefined, yawAxis);
		Vector3.pool.release(pitchAxis);
		Vector3.pool.release(yawAxis);

		// Get the current position angles relative to the axes.
		const position = Vector3.pool.get();
		position.rotateInverse(axisFrame, this.getEntity().getPosition());

		// Calculate the slow factor.
		let slowFactor = 1.0;
		if (this._slowWhenCloseToParent) {
			const radius = this.getEntity().getParent().getOcclusionRadius();
			slowFactor = MathUtils.clamp((position.magnitude() - radius) / radius, 0.001, 1.0);
		}

		// Add in the pitch and yaw changes.
		const lla = LatLonAlt.pool.get();
		Geometry.getLLAFromXYZOnSphere(lla, position, 0);
		lla.lat += this._pitchChangeSmoothedValue * slowFactor;
		lla.lon += this._yawChangeSmoothedValue * slowFactor;

		// Set upper limits for pitch so that it doesn't go beyond the yaw axis.
		if (lla.lat < 0.0001 - MathUtils.halfPi) {
			lla.lat = 0.0001 - MathUtils.halfPi;
		}
		if (lla.lat > MathUtils.halfPi - 0.0001) {
			lla.lat = MathUtils.halfPi - 0.0001;
		}

		// Apply pitch and yaw limits.
		if (lla.lat < this._pitchAngleLimits.min) {
			lla.lat = this._pitchAngleLimits.min;
		}
		if (lla.lat > this._pitchAngleLimits.max) {
			lla.lat = this._pitchAngleLimits.max;
		}
		if (lla.lon < this._yawAngleLimits.min) {
			lla.lon = this._yawAngleLimits.min;
		}
		if (lla.lon > this._yawAngleLimits.max) {
			lla.lon = this._yawAngleLimits.max;
		}

		// Set the position from the new LLA and clean up.
		Geometry.getXYZFromLLAOnSphere(position, lla, 0);
		LatLonAlt.pool.release(lla);
		position.rotate(axisFrame, position);
		Quaternion.pool.release(axisFrame);
		this.getEntity().setPosition(position);
		Vector3.pool.release(position);
	}
}
