/** @module pioneer */
import {
	Freezable,
	Pool,
	THREE,
	Vector3
} from '../internal';

/** A mathematical quaternion */
export class Quaternion extends Freezable {
	/**
	 * Pool for temporary variables.
	 * @returns {Pool<Quaternion>}
	 */
	static get pool() {
		return _pool;
	}

	/**
	 * NaN quaternion
	 * @returns {Quaternion}
	 */
	static get NaN() {
		return _nan;
	}

	/**
	 * Returns the identity quaternion.
	 * @returns {Quaternion}
	 */
	static get Identity() {
		return _identity;
	}

	/**
	 * Returns a quaternion from the angle-axis rotation.
	 * @param {Vector3} axis - must be normalized
	 * @param {number} angle - in radians
	 * @returns {Quaternion}
	 */
	static fromAxisAngle(axis, angle) {
		const q = new Quaternion();
		q.setFromAxisAngle(axis, angle);
		return q;
	}

	/**
	 * Returns a quaternion representing a rotate frame from the given at least two axes, the other being undefined. The given axes must be orthonormal.
	 * @param {Vector3|undefined} xAxis
	 * @param {Vector3|undefined} yAxis
	 * @param {Vector3|undefined} zAxis
	 * @returns {Quaternion}
	 */
	static fromAxes(xAxis, yAxis, zAxis) {
		const q = new Quaternion();
		q.setFromAxes(xAxis, yAxis, zAxis);
		return q;
	}

	/**
	 * Returns a quaternion from Euler angles.
	 * @param {number} pitch - the angle around the +x axis to rotate
	 * @param {number} roll - the angle around the +y axis to rotate
	 * @param {number} yaw - the angle around the +z axis to rotate
	 * @returns {Quaternion}
	 */
	static fromEuler(pitch, roll, yaw) {
		const q = new Quaternion();
		q.setFromEuler(pitch, roll, yaw);
		return q;
	}

	/**
	 * Returns a quaternion rotation that would rotate the fromVector to the toVector. The two vectors must be normalized.
	 * @param {Vector3} fromVector
	 * @param {Vector3} toVector
	 * @returns {Quaternion}
	 */
	static fromVectorFromTo(fromVector, toVector) {
		const q = new Quaternion();
		q.setFromVectorFromTo(fromVector, toVector);
		return q;
	}

	/**
	 * Returns a quaternion rotation with the given z axis, and arbitrary x and y axes.
	 * @param {Vector3} axis - the axis to set
	 * @param {number} which - the index of the axis in the quaternion: 0 for x, 1 for y, 2 for z
	 * @returns {Quaternion}
	 */
	static fromFromAxis(axis, which) {
		const q = new Quaternion();
		q.setFromAxis(axis, which);
		return q;
	}

	/**
	 * Constructor. Defaults to identity.
	 * @param {number} w
	 * @param {number} x
	 * @param {number} y
	 * @param {number} z
	 */
	constructor(w = 1, x = 0, y = 0, z = 0) {
		super();

		/**
		 * @type {number}
		 * @private
		 */
		this._w = w;
		/**
		 * @type {number}
		 * @private
		 */
		this._x = x;
		/**
		 * @type {number}
		 * @private
		 */
		this._y = y;
		/**
		 * @type {number}
		 * @private
		 */
		this._z = z;
	}

	/**
	 * Gets the w component.
	 * @returns {number}
	 */
	get w() {
		return this._w;
	}

	/**
	 * Sets the w component.
	 * @param {number} w
	 */
	set w(w) {
		this.throwIfFrozen();
		this._w = w;
	}

	/**
	 * Gets the x component.
	 * @returns {number}
	 */
	get x() {
		return this._x;
	}

	/**
	 * Sets the x component.
	 * @param {number} x
	 */
	set x(x) {
		this.throwIfFrozen();
		this._x = x;
	}

	/**
	 * Gets the y component.
	 * @returns {number}
	 */
	get y() {
		return this._y;
	}

	/**
	 * Sets the y component.
	 * @param {number} y
	 */
	set y(y) {
		this.throwIfFrozen();
		this._y = y;
	}

	/**
	 * Gets the z component.
	 * @returns {number}
	 */
	get z() {
		return this._z;
	}

	/**
	 * Sets the z component.
	 * @param {number} z
	 */
	set z(z) {
		this.throwIfFrozen();
		this._z = z;
	}

	/**
	 * Gets the rotation angle.
	 * @returns {number}
	 */
	getAngle() {
		return Math.acos(this._w) * 2.0;
	}

	/**
	 * Sets this to a.
	 * @param {Quaternion} a - the source quaternion
	 */
	copy(a) {
		this.throwIfFrozen();
		this._w = a._w;
		this._x = a._x;
		this._y = a._y;
		this._z = a._z;
	}

	/**
	 * Sets this to a as a ThreeJs quaternion.
	 * @param {THREE.Quaternion} a
	 */
	copyFromThreeJs(a) {
		this.throwIfFrozen();
		this._w = a.w;
		this._x = a.x;
		this._y = a.y;
		this._z = a.z;
	}

	/**
	 * Sets this to the parameters.
	 * @param {number} w
	 * @param {number} x
	 * @param {number} y
	 * @param {number} z
	 */
	set(w, x, y, z) {
		this.throwIfFrozen();
		this._w = w;
		this._x = x;
		this._y = y;
		this._z = z;
	}

	/**
	 * Sets this to the angle-axis rotation.
	 * @param {Vector3} axis - must be normalized
	 * @param {number} angle - in radians
	 */
	setFromAxisAngle(axis, angle) {
		this.throwIfFrozen();
		const sinHalfAngle = Math.sin(angle / 2.0);
		this._w = Math.cos(angle / 2.0);
		this._x = sinHalfAngle * axis.x;
		this._y = sinHalfAngle * axis.y;
		this._z = sinHalfAngle * axis.z;
	}

	/**
	 * Sets this to a quaternion representing a rotate frame from the given at least two axes, the other being undefined. The given axes must be orthonormal.
	 * @param {Vector3|undefined} xAxis
	 * @param {Vector3|undefined} yAxis
	 * @param {Vector3|undefined} zAxis
	 */
	setFromAxes(xAxis, yAxis, zAxis) {
		this.throwIfFrozen();
		let missingAxis;
		if (xAxis === undefined) {
			xAxis = Vector3.pool.get();
			missingAxis = xAxis;
			xAxis.cross(yAxis, zAxis);
		}
		else if (yAxis === undefined) {
			yAxis = Vector3.pool.get();
			missingAxis = yAxis;
			yAxis.cross(zAxis, xAxis);
		}
		else if (zAxis === undefined) {
			zAxis = Vector3.pool.get();
			missingAxis = zAxis;
			zAxis.cross(xAxis, yAxis);
		}
		const tr = xAxis.x + yAxis.y + zAxis.z;
		if (tr > 0) {
			const S = Math.sqrt(tr + 1.0) * 2; // S = 4 * this._w
			this._w = 0.25 * S;
			this._x = (yAxis.z - zAxis.y) / S;
			this._y = (zAxis.x - xAxis.z) / S;
			this._z = (xAxis.y - yAxis.x) / S;
		}
		else if ((xAxis.x > yAxis.y) && (xAxis.x > zAxis.z)) {
			const S = Math.sqrt(1.0 + xAxis.x - yAxis.y - zAxis.z) * 2; // S = 4 * this._x
			this._w = (yAxis.z - zAxis.y) / S;
			this._x = 0.25 * S;
			this._y = (yAxis.x + xAxis.y) / S;
			this._z = (zAxis.x + xAxis.z) / S;
		}
		else if (yAxis.y > zAxis.z) {
			const S = Math.sqrt(1.0 + yAxis.y - zAxis.z - xAxis.x) * 2; // S = 4 * this._y
			this._w = (zAxis.x - xAxis.z) / S;
			this._x = (yAxis.x + xAxis.y) / S;
			this._y = 0.25 * S;
			this._z = (zAxis.y + yAxis.z) / S;
		}
		else {
			const S = Math.sqrt(1.0 + zAxis.z - xAxis.x - yAxis.y) * 2; // S = 4 * this._z
			this._w = (xAxis.y - yAxis.x) / S;
			this._x = (zAxis.x + xAxis.z) / S;
			this._y = (zAxis.y + yAxis.z) / S;
			this._z = 0.25 * S;
		}
		Vector3.pool.release(missingAxis);
	}

	/**
	 * Sets this to the euler angles. Use right-hand rule for rotation directions.
	 * @param {number} pitch - the angle around the +x axis to rotate
	 * @param {number} roll - the angle around the +y axis to rotate
	 * @param {number} yaw - the angle around the +z axis to rotate
	 */
	setFromEuler(pitch, roll, yaw) {
		this.throwIfFrozen();
		const halfX = pitch * 0.5;
		const halfY = roll * 0.5;
		const halfZ = yaw * 0.5;
		const cosHalfX = Math.cos(halfX);
		const sinHalfX = Math.sin(halfX);
		const cosHalfY = Math.cos(halfY);
		const sinHalfY = Math.sin(halfY);
		const cosHalfZ = Math.cos(halfZ);
		const sinHalfZ = Math.sin(halfZ);
		this._w = cosHalfX * cosHalfY * cosHalfZ + sinHalfX * sinHalfY * sinHalfZ;
		this._x = sinHalfX * cosHalfY * cosHalfZ - cosHalfX * sinHalfY * sinHalfZ;
		this._y = cosHalfX * sinHalfY * cosHalfZ + sinHalfX * cosHalfY * sinHalfZ;
		this._z = cosHalfX * cosHalfY * sinHalfZ - sinHalfX * sinHalfY * cosHalfZ;
	}

	/**
	 * Sets this to the quaternion rotation that would rotate the fromVector to the toVector. The two vectors must be normalized.
	 * @param {Vector3} fromVector
	 * @param {Vector3} toVector
	 */
	setFromVectorFromTo(fromVector, toVector) {
		const axis = Vector3.pool.get();
		const dot = fromVector.dot(toVector);
		if (dot >= 1.0) {
			this.set(1, 0, 0, 0);
		}
		else if (dot <= -1.0) {
			this.set(0, 1, 0, 0);
		}
		else {
			axis.cross(fromVector, toVector);
			axis.normalize(axis);
			const angle = Math.acos(dot);
			this.setFromAxisAngle(axis, angle);
		}
		Vector3.pool.release(axis);
	}

	/**
	 * Sets this to a quaternion rotation with the given z axis, and arbitrary x and y axes.
	 * @param {Vector3} axis - the axis to set
	 * @param {number} which - the index of the axis in the quaternion: 0 for x, 1 for y, 2 for z
	 */
	setFromAxis(axis, which) {
		const axis0 = Vector3.pool.get();
		const axis1 = Vector3.pool.get();
		axis0.normalize(axis);
		axis1.cross(axis0, Vector3.XAxis);
		if (axis1.isZero()) {
			axis1.cross(axis0, Vector3.YAxis);
		}
		axis1.normalize(axis1);
		if (which === 0) {
			this.setFromAxes(axis0, axis1, undefined);
		}
		else if (which === 1) {
			this.setFromAxes(undefined, axis0, axis1);
		}
		else if (which === 2) {
			this.setFromAxes(axis1, undefined, axis0);
		}
		Vector3.pool.release(axis0);
		Vector3.pool.release(axis1);
	}

	/**
	 * Returns a nicely formed string.
	 * @override
	 * @returns {string}
	 */
	toString() {
		return '[' + this._w + ', ' + this._x + ', ' + this._y + ', ' + this._z + ']';
	}

	/**
	 * Returns true if the quaternion is NaN (just checks w component).
	 * @returns {boolean}
	 */
	isNaN() {
		return (!(this._w <= 0) && !(this._w > 0));
	}

	/**
	 * Returns the magnitude of the quaternion. It should be 1 if you're using it right.
	 * @returns {number}
	 */
	magnitude() {
		return Math.sqrt(this._w * this._w + this._x * this._x + this._y * this._y + this._z * this._z);
	}

	/**
	 * Sets this to a with a magnitude of 1.0.
	 * @param {Quaternion} a
	 */
	normalize(a) {
		this.throwIfFrozen();
		const magnitude = a.magnitude();
		if (magnitude > 0) {
			this._w = a._w / magnitude;
			this._x = a._x / magnitude;
			this._y = a._y / magnitude;
			this._z = a._z / magnitude;
		}
	}

	/**
	 * Sets this to the inverse of quaternion a.
	 * @param {Quaternion} a
	 */
	inverse(a) {
		this.throwIfFrozen();
		this._w = a._w;
		this._x = -a._x;
		this._y = -a._y;
		this._z = -a._z;
	}

	/**
	 * Sets this to quaternion a * quaternion b.
	 * @param {Quaternion} a
	 * @param {Quaternion} b
	 */
	mult(a, b) {
		this.throwIfFrozen();
		const r = Quaternion.pool.get();
		r._w = a._w * b._w - a._x * b._x - a._y * b._y - a._z * b._z;
		r._x = a._w * b._x + a._x * b._w + a._y * b._z - a._z * b._y;
		r._y = a._w * b._y - a._x * b._z + a._y * b._w + a._z * b._x;
		r._z = a._w * b._z + a._x * b._y - a._y * b._x + a._z * b._w;
		this.copy(r);
		Quaternion.pool.release(r);
	}

	/**
	 * Sets this to (the inverse of quaternion a) * quaternion b.
	 * @param {Quaternion} a
	 * @param {Quaternion} b
	 */
	multInverseL(a, b) {
		this.throwIfFrozen();
		const r = Quaternion.pool.get();
		r._w = a._w * b._w + a._x * b._x + a._y * b._y + a._z * b._z;
		r._x = a._w * b._x - a._x * b._w - a._y * b._z + a._z * b._y;
		r._y = a._w * b._y + a._x * b._z - a._y * b._w - a._z * b._x;
		r._z = a._w * b._z - a._x * b._y + a._y * b._x - a._z * b._w;
		this.copy(r);
		Quaternion.pool.release(r);
	}

	/**
	 * Sets this to quaternion a * (the inverse of quaternion b).
	 * @param {Quaternion} a
	 * @param {Quaternion} b
	 */
	multInverseR(a, b) {
		this.throwIfFrozen();
		const r = Quaternion.pool.get();
		r._w = +a._w * b._w + a._x * b._x + a._y * b._y + a._z * b._z;
		r._x = -a._w * b._x + a._x * b._w - a._y * b._z + a._z * b._y;
		r._y = -a._w * b._y + a._x * b._z + a._y * b._w - a._z * b._x;
		r._z = -a._w * b._z - a._x * b._y + a._y * b._x + a._z * b._w;
		this.copy(r);
		Quaternion.pool.release(r);
	}

	/**
	 * Sets this to quaternion a, with its rotation angle multiplied by b.
	 * @param {Quaternion} a
	 * @param {number} b
	 */
	scaleAngle(a, b) {
		this.throwIfFrozen();
		const halfAngle = Math.acos(a._w);
		const sinHalfAngle = Math.sin(halfAngle);
		if (sinHalfAngle === 0) {
			this.copy(a);
			return;
		}
		const sinHalfAngleB = Math.sin(halfAngle * b);
		this._w = Math.cos(halfAngle * b);
		this._x = a._x / sinHalfAngle * sinHalfAngleB;
		this._y = a._y / sinHalfAngle * sinHalfAngleB;
		this._z = a._z / sinHalfAngle * sinHalfAngleB;
	}

	/**
	 * Returns the angle in radians between this and quaternion a.
	 * @param {Quaternion} a
	 * @returns {number}
	 */
	angle(a) {
		return Math.acos(this._w * a._w + this._x * a._x + this._y * a._y + this._z * a._z) * 2.0;
	}

	/**
	 * Sets this to be spherically interpolated between a and b by the factor u. The parameter u is not clamped.
	 * @param {Quaternion} a - the quaternion when u = 0
	 * @param {Quaternion} b - the quaternion when u = 1
	 * @param {number} u - the lerp parameter
	 */
	slerp(a, b, u) {
		this.throwIfFrozen();
		let dot = a._w * b._w + a._x * b._x + a._y * b._y + a._z * b._z;
		let f = 1;
		if (dot < 0.0) {
			f = -1;
			dot = -dot;
		}
		if (dot <= 0.9995) {
			const angle = Math.acos(dot);
			const A = f * Math.sin((1.0 - u) * angle) / Math.sin(angle);
			const B = Math.sin(u * angle) / Math.sin(angle);
			this._w = A * a._w + B * b._w;
			this._x = A * a._x + B * b._x;
			this._y = A * a._y + B * b._y;
			this._z = A * a._z + B * b._z;
		}
		else { // too small, so lerp
			const A = f * (1.0 - u);
			const B = u;
			this._w = A * a._w + B * b._w;
			this._x = A * a._x + B * b._x;
			this._y = A * a._y + B * b._y;
			this._z = A * a._z + B * b._z;
			this.normalize(this);
		}
	}

	/**
	 * Sets Vector3 outAxis to an axis (x = 0, y = 1, z = 2) of the quaternion. If the axis is undefined, it returns the axis of rotation.
	 * @param {Vector3} outAxis
	 * @param {number} axis
	 */
	getAxis(outAxis, axis) {
		if (axis === undefined) {
			outAxis.set(this._x, this._y, this._z);
			outAxis.normalize(outAxis);
		}
		else if (axis === 0) {
			outAxis.x = this._w * this._w + this._x * this._x - this._y * this._y - this._z * this._z;
			outAxis.y = 2.0 * this._w * this._z + 2.0 * this._x * this._y;
			outAxis.z = 2.0 * this._x * this._z - 2.0 * this._w * this._y;
		}
		else if (axis === 1) {
			outAxis.x = 2.0 * this._y * this._x - 2.0 * this._w * this._z;
			outAxis.y = this._w * this._w - this._x * this._x + this._y * this._y - this._z * this._z;
			outAxis.z = 2.0 * this._x * this._w + 2.0 * this._y * this._z;
		}
		else if (axis === 2) {
			outAxis.x = 2.0 * this._y * this._w + 2.0 * this._z * this._x;
			outAxis.y = 2.0 * this._z * this._y - 2.0 * this._w * this._x;
			outAxis.z = this._w * this._w - this._x * this._x - this._y * this._y + this._z * this._z;
		}
	}
}

/**
 * @type {Pool<Quaternion>}
 */
const _pool = new Pool(Quaternion);

const _identity = new Quaternion();
_identity.freeze();

const _nan = new Quaternion(Number.NaN, Number.NaN, Number.NaN, Number.NaN);
_nan.freeze();
