/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';

/**
 * Helpful utilities for scenes.
 * @hideconstructor
 */
export class Animation {

	/**
	 * Makes an animation that makes visible and invisible a subobjects at different times.
	 * Each keyframe is a [time, visible] pair.
	 * @param {Pioneer.ModelComponent} model
	 * @param {string} subobject
	 * @param {boolean} visibilityAtNegInf
	 * @param {[number, boolean][]} keyframes
	 * @returns {Pioneer.CoverageController}
	 */
	static makeSubobjectVisibleAnimation(model, subobject, visibilityAtNegInf, keyframes) {
		// Sort the keyframes by time, just in case they aren't.
		keyframes.sort((a, b) => a[0] - b[0]);

		// Get the coverage controller or create one if it doesn't already exist.
		const entity = model.getEntity();
		const coverageController = entity.addControllerByClass(Pioneer.CoverageController);
		coverageController.setCoverage(new Pioneer.Interval(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY));
		coverageController.setUpdateFunction((entity) => {
			const time = entity.getScene().getEngine().getTime();
			const index = Pioneer.Sort.getIndex(time, keyframes, (a, time) => a[0] < time);
			if (index === 0) { // At first keyframe time or before.
				model.setHiddenObject(subobject, !visibilityAtNegInf);
			}
			else {
				model.setHiddenObject(subobject, !keyframes[index - 1][1]);
			}
		});

		return coverageController;
	}

	/**
	 * Makes an animation that rotates an entity's joints at different times.
	 * Each keyframe is a [time, rotation] pair.
	 * @param {Pioneer.ModelComponent} model
	 * @param {string} joint
	 * @param {'x' | 'y' | 'z'} axis
	 * @param {[number, number][]} keyframes
	 */
	static makeJointRotationAnimation(model, joint, axis, keyframes) {
		// Sort the keyframes by time, just in case they aren't.
		keyframes.sort((a, b) => a[0] - b[0]);

		// Get the coverage controller or create one if it doesn't already exist.
		const entity = model.getEntity();
		const coverageController = entity.addControllerByClass(Pioneer.CoverageController);
		coverageController.setCoverage(new Pioneer.Interval(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY));
		coverageController.setUpdateFunction((entity) => {
			const obj = model.getThreeJsObjectByName(joint);
			if (obj !== null) {
				const time = entity.getScene().getEngine().getTime();
				const index = Pioneer.Sort.getIndex(time, keyframes, (a, time) => a[0] < time);
				if (index === 0) { // At first keyframe time or before.
					obj.rotation[axis] = keyframes[0][1];
				}
				else if (index === keyframes.length) { // After last keyframe time.
					obj.rotation[axis] = keyframes[keyframes.length - 1][1];
				}
				else {
					const prevKeyframe = keyframes[index - 1];
					const nextKeyframe = keyframes[index];
					const u = (time - prevKeyframe[0]) / (nextKeyframe[0] - prevKeyframe[0]);
					obj.rotation[axis] = Pioneer.MathUtils.lerp(prevKeyframe[1], nextKeyframe[1], u);
				}
			}
		});

		return coverageController;
	}
}
