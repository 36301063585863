/**
 * Filter component
 *
 * Depending on the type prop, composese Dropdown or CategoryList filter.
 */

import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from './dropdown';
import CategoryList from './category_list';


const Filter = ({ type, allItems, updateItem, selectedIndex, ...props }) => {
	// Add ids to the items if they dont have any (indexes work but should be avoided really -> https://robinpokorny.com/blog/index-as-a-key-is-an-anti-pattern/)
	const allItemsWithIds = allItems.map((item, index) => {
		if (typeof item === 'string') {
			return {
				id: index,
				text: item
			};
		}

		return {
			id: item.id ?? index,
			text: item.text
		};
	});

	return (
		<>
			{type === 'dropdown' && <Dropdown itemList={allItemsWithIds} itemIndex={selectedIndex} onItemClick={updateItem} {...props} />}
			{type === 'categories' && <CategoryList itemList={allItemsWithIds} itemIndex={selectedIndex} onItemClick={updateItem} {...props} />}
			{/* todo future: add search filter component */}
		</>
	);
};

Filter.propTypes = {
	style: PropTypes.shape({}),
	type: PropTypes.string.isRequired,
	allItems: PropTypes.arrayOf(PropTypes.string).isRequired,
	updateItem: PropTypes.func.isRequired,
	selectedIndex: PropTypes.number.isRequired
};

export default Filter;
