/* eslint-disable react/jsx-no-bind */
import { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnapshot } from 'valtio';

import CloseButton from './close_button';
import SwitchRounded from './switch_rounded';

import { layersStore, spacecraftStore, uiStore, eventsStore, poiStore, gmapStore} from '../managers/globalState';

import GearIcon from '../../www/assets/images/gear.svg';

import '../../www/assets/css/settings_menu.css';
import { getOverlay } from './overlays';
import en from '../languages/en';
import ar from '../languages/ar';


const GearMenu = ({ onClose, gearBtnRef }) => {
	const { cityNames, groundTracks, blueMarble } = useSnapshot(layersStore.state);
	const { enabledSC, isSatellitesNow } = spacecraftStore.stateSnapshot;
	const { hideFullScreenToggle } = uiStore.stateSnapshot;

	const gearMenuRef = useRef(null);

	const showGroundTracksToggle = Array.isArray(enabledSC) && enabledSC.length > 0;

	// Set light fill toggle.
	const cityNamesOnChange = () => {
		layersStore.setGlobalState({ cityNames: !cityNames });
	};

	const groundTracksOnChange = () => {
		layersStore.setGlobalState({ groundTracks: !groundTracks });
	};

	const onCloudFreeToggle = useCallback(() => {
		layersStore.setGlobalState({ blueMarble: !blueMarble });
	}, [blueMarble]);

	// Align settings ::before depending on position of the settings gear icon and box
	useEffect(() => {
		const settingsGearLeft = gearBtnRef.current?.getBoundingClientRect().left || 0;
		const settingsBoxLeft = gearMenuRef.current?.getBoundingClientRect().left || 0;
		document.documentElement.setAttribute('style', `--customSettingsLeftMargin: ${(settingsGearLeft - settingsBoxLeft) + 4}px`);
	}, [gearBtnRef, hideFullScreenToggle]);

	// Add the outside click listener on mount and remove it at dismount
	useEffect(() => {
		// Close the menu if we ever click outside of it - ignore if gear icon is clicked, that toggle behavior is handled in SettingsMenu
		const handleOutsideClick = e => {
			const gearIconClicked = gearBtnRef.current.contains(e.target);
			const gearMenuClicked = gearMenuRef.current.contains(e.target);

			if (gearMenuRef && gearMenuRef.current && !gearMenuClicked && !gearIconClicked) {
				if (typeof onClose === 'function') { onClose(); }
			}
		};

		document.addEventListener('click', handleOutsideClick);

		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, [onClose, gearBtnRef]);

	return (

	// {this.language.settings} 	{this.language.city_names}
		<div className='gear-menu' ref={gearMenuRef}>
			<span className='gear-menu-title'>الإعدادات</span>
			<CloseButton size='small' onClick={onClose} />
			<div className='gear-menu-item'>
				<span>{ar.city_names}</span>
				<SwitchRounded checked={cityNames} onChange={cityNamesOnChange} />
			</div>

			{
				isSatellitesNow
				&& (
					<div className='gear-menu-item'>
						<span>{ar.Cloud_free_Earth}</span>
						<SwitchRounded
							checked={blueMarble} onChange={onCloudFreeToggle}
						/>
					</div>
				)
			}

			{
				showGroundTracksToggle
				&& (
					<div className='gear-menu-item'>
						<span>{ar.display_ground_tracks}</span>
						<SwitchRounded checked={groundTracks} onChange={groundTracksOnChange} />
					</div>
				)
			}
		</div>
	);
};

GearMenu.propTypes = {
	onClose: PropTypes.func
};


const SettingsMenu = ({ toggleSettings }) => {
	const gearBtnRef = useRef();

	const { controlsMenu } = useSnapshot(uiStore.state);

	const settingsVisible = controlsMenu === 'settings';

	return (
		<>
			<div className='gear-btn' onClick={toggleSettings} ref={gearBtnRef}>
				<GearIcon className={`gear-icon ${settingsVisible ? 'active' : ''}`} />
			</div>
			{ settingsVisible && <GearMenu onClose={toggleSettings} gearBtnRef={gearBtnRef} /> }
		</>
	);
};

SettingsMenu.propTypes = {
	toggleTimeControls: PropTypes.func
};

export default SettingsMenu;
