/** @module pioneer */
import {
	Freezable,
	Pool,
	Vector2
} from '../internal';

/** A class for handling an axis-aligned rectangle. */
export class Rect extends Freezable {
	/**
	 * Pool for temporary variables.
	 * @returns {Pool<Rect>}
	 */
	static get pool() {
		return _pool;
	}

	/**
	 * Constructor.
	 * @param {number} x - origin x
	 * @param {number} y - origin y
	 * @param {number} w - size x
	 * @param {number} h - size y
	 */
	constructor(x = 0, y = 0, w = 0, h = 0) {
		super();

		/**
		 * origin
		 * @type {Vector2}
		 * @private
		 */
		this._origin = new Vector2(x, y);

		/**
		 * size
		 * @type {Vector2}
		 * @private
		 */
		this._size = new Vector2(w, h);
	}

	/**
	 * Freezes the object.
	 * @override
	 */
	freeze() {
		this._origin.freeze();
		this._size.freeze();
	}

	/**
	 * Thaws (unfreezes) the object.
	 * @override
	 */
	thaw() {
		this._origin.thaw();
		this._size.thaw();
	}

	/**
	 * Gets the origin.
	 * @returns {Vector2}
	 */
	get origin() {
		return this._origin;
	}

	/**
	 * Gets the size.
	 * @returns {Vector2}
	 */
	get size() {
		return this._size;
	}

	/**
	 * Returns a nicely formed string.
	 * @override
	 * @returns {string}
	 */
	toString() {
		return '[Origin: (' + this._origin.x + ', ' + this._origin.y + '), Size: (' + this._size.x + ', ' + this._size.y + ')]';
	}

	/**
	 * Sets this to a.
	 * @param {Rect} a
	 */
	copy(a) {
		this._origin.copy(a._origin);
		this._size.copy(a._size);
	}

	/**
	 * Sets this to the parameters.
	* @param {number} x - origin x
	* @param {number} y - origin y
	* @param {number} w - size x
	* @param {number} h - size y */
	set(x, y, w, h) {
		this._origin.set(x, y);
		this._size.set(w, h);
	}

	/**
	 * Returns true if the position is within this.
	 * @param {Vector2} position
	 * @returns {boolean}
	 */
	contains(position) {
		return this._origin.x <= position.x && position.x < this._origin.x + this._size.x && this._origin.y <= position.y && position.y < this._origin.y + this._size.y;
	}

	/**
	 * Returns true if the rect intersects with this.
	 * @param {Rect} rect
	 * @returns {boolean}
	 */
	intersects(rect) {
		return this._origin.x + this._size.x > rect._origin.x && rect._origin.x + rect._size.x > this._origin.x
			&& this._origin.y + this._size.y > rect._origin.y && rect._origin.y + rect._size.y > this._origin.y;
	}
}

/**
 * @type {Pool<Rect>}
 */
const _pool = new Pool(Rect);
