/** @module pioneer */
import {
	BaseRef,
	// eslint-disable-next-line no-unused-vars
	Entity,
	Scene
} from '../internal';

/**
 * A reference to an entity.
 * @extends {BaseRef<Entity>}
 */
export class EntityRef extends BaseRef {
	/**
	 * Constructor.
	 * @param {Scene} scene
	 * @param {string} [name]
	 */
	constructor(scene, name) {
		super(scene);

		/**
		 * The name of the entity to reference.
		 * @type {string}
		 * @private
		 */
		this._name = name !== undefined ? name : '';
	}

	/**
	 * Gets the name of the reference.
	 * @returns {string}
	 */
	getName() {
		return this._name;
	}

	/**
	 * Sets the name of the reference.
	 * @param {string} name
	 */
	setName(name) {
		this._name = name;
	}

	/**
	 * Updates the reference.
	 * @override
	 */
	update() {
		// If there's no name set, make sure the ref is null.
		if (this._name === '') {
			if (this._ref !== null) {
				this._setRef(null);
			}
		}
		// If there is a name, make sure the ref is valid and correct.
		else {
			if (this._ref === null || this._ref.isDestroyed() || !this._ref.isEnabled() || this._ref.getName() !== this._name) {
				// Get the new ref.
				const ref = this._scene.getEntity(this._name);
				// Set the ref.
				this._setRef(ref);
			}
		}
	}
}
