/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import DropdownArrow from '../../www/assets/images/chevron_dropdown.svg';
import ar from '../languages/ar';
import '../../www/assets/css/dropdown.css';

/**
 * Dropdown is a stateless leaf node.
 * Without local state, when passing the onItemClick, it's important that the parent triggers the re-render onChange
 */
const Dropdown = ({ title = null, itemList, itemIndex = 0, onItemClick, customPlaceholder = '', style = {}, boldLabel = true }) => {
	const value = itemList[itemIndex] ? itemList[itemIndex]?.text : customPlaceholder;
	const label = value.includes('All') ? ar.all : value;

	return (
		<div className='dropdown' style={style}>
			<div className='label-group'>
				<label>
					{title && `${title}:`}
					{ boldLabel ? <strong>{label}</strong> : label }
				</label>
				<DropdownArrow />
			</div>
			<select
				value={value}
				onChange={e => {
					const index = itemList.findIndex(({ text }) => text === e.target.value);
					onItemClick(index, e.target.value);
				}}
			>
				{
					customPlaceholder
				&& <option disabled hidden value={value}>{value}</option>
				}
				{ itemList.map(({ id, text }) => (text ? (<option key={id.toString()} value={text}>{text}</option>) : null)) }
			</select>
		</div>
	);
};

Dropdown.propTypes = {
	style: PropTypes.shape({}),
	itemList: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		text: PropTypes.string
	})).isRequired,
	itemIndex: PropTypes.number,
	className: PropTypes.string,
	onItemClick: PropTypes.func.isRequired
};

export default Dropdown;
