const ar = {
	animate_this_data: 'تحریك البیانات',
	about: 'المزيد',
	about_this_dataset: 'عن مجموعة البیانات',
	about_this_video: 'عن هذا الفيديو',
	about_Satellites_Now: 'عن الأقمار الصناعية الآن',
	vital_signs: 'العلامات الحیویة',
	home: 'الصفحة الرئیسیة',
	missions: 'البعثات الفضائیة',
	more: 'المزيد عن',
	vital_signs_desc: 'أحدث البيانات ، بما في ذلك القياسات العالمية لثاني أكسيد الكربون ، درجة حرارة الهواء ، رطوبة التربة وأكثر',
	vital_signs_link: 'العلامات الحیویة',
	missions_description: ' - عرض المواقع الحالية للأقمار الصناعية التابعة لوكالة ناسا الفضائية والتي تهتم بمراقبة ورصد كوكب الأرض. أو إختر من قائمة البعثات الفضائية المتاحة',
	missions_link: 'البعثات الفضائیة',
	satellites_now_link: 'الأقمار الصناعية الآن',
	more_link: 'عن الأرض الآن',
	time_in_orbit: 'الوقت في المدار',
	satellite_time_rate: 'معدل وقت القمر الصناعي',
	hr_per_sec: 'ساعة/ثانیة ',
	min_per_sec: 'دقیقة/ثانیة',
	sec_per_sec: 'ثانیة/ثانیة',
	real_time: 'الوقت اللحظي',
	latest: 'أحدث',
	dataset: 'مجموعة البیانات',
	datasets: 'مجموعات البیانات',
	latest_seven: 'أحدث ٧',
	start_time: 'وقت البدایة',
	end_time: 'وقت النھایة',
	apply: 'تطبیق',
	cancel: 'إلغاء',
	range: 'نطاق',
	launch_location: 'موقع الإطلاق',
	launch_vehicle: 'مركبة الإطلاق',
	toggle_units: 'تبدیل الوحدات',
	done: 'تم',
	clear: 'مسح',
	all: 'الكل',
	atmosphere: 'الغلاف الجوي',
	land: 'أرض',
	sea: 'بحر',
	key_indicators: 'المؤشرات الرئیسیة',
	video_tutorial: 'فیدیو تعلیمي',
	sc_aqua: 'أكوا',
	sc_aura: 'أورا',
	sc_gpm: 'جي بي إم',
	sc_grace_fo1: 'غريس-فولو أون١',
	sc_grace_fo2: 'غريس-فولو أون٢',
	sc_icesat_2: 'آيس سات-٢',
	sc_jason_3: 'جيسون-٣',
	sc_suomi_npp: 'سومي إن بي بي',
	sc_oco_2: 'أو سي أو-٢',
	sc_raincube: 'رين كيوب',
	sc_smap: 'سماب',
	sc_terra: 'تيرا',
	sc_iss: 'محطة الفضاء الدولية',
	sc_sentinel_6: 'سينتنيل-٦ مايكل فريليتش',
	sc_foo: 'فو',
	sc_saudisat_1c: 'سعودي سات- ١سي',
	//
	sc_cygnss_1: 'سايغنس-١',
	sc_cygnss_2: 'سايغنس-٢',
	sc_cygnss_3: 'سايغنس-٣',
	sc_cygnss_4: 'سايغنس-٤',
	sc_cygnss_5: 'سايغنس-٥',
	sc_cygnss_6: 'سايغنس-٦',
	sc_cygnss_7: 'سايغنس-٧',
	sc_cygnss_8: 'سايغنس-٨',
	sc_cloudsat: 'كلاودسات',
	sc_landsat_7: 'لاندسات-٧',
	sc_landsat_8: 'لاندسات-٨',
	sc_landsat_9: 'لاندسات-٩',
	sc_grifex: 'جريفيكس',
	sc_calipso: 'كاليبسو',
	sc_saudisat_4: 'سعودي سات ٤',
	sc_swot: 'سوت',
	sc_iss_ecostress: 'إيكوسترس',
	sc_iss_oco_3: ' أو سي أو-٣',
	sc_iss_emit: 'إيمت',
	sc_nisar: 'نايسار',
	sc_pace: 'بيس',
    sc_tempo: 'تيمبو',
    sc_tropics_03: 'تروبيكس-٣',
    sc_tropics_01: 'تروبيكس-١',
    sc_tropics_05: 'تروبيكس-٥',
    sc_tropics_06: 'تروبيكس-٦',
    sc_tropics_07: 'تروبيكس-٧',
    sc_prefire_2: 'بريفاير-٢',
    sc_prefire_1: 'بريفاير-١',
	//
	moon: 'القمر',
	sun: 'الشمس',
	mars:'المريخ',
	"134340_pluto":'بلوتو',
	"134340_pluto_barycenter":" ",
	mercury:'عطارد',
	venus:'الزهرة',
	jupiter:'المشتري',
	saturn:'زحل',
	neptune:'نبتون',
	uranus:'أورانوس',
	loading: 'جار التحميل',
	no_datasets: 'لا توجد مجموعات بيانات متاحة حاليا',
	more_like: 'المزید',
	earth_now: 'الأرض الآن',
	choose_dates: 'اختر التواريخ',
	options: 'خيارات',
	info: 'معلومات أكثر',
	full_menu: 'قائمة كاملة',
	satellite_zoom: 'تكبير القمر الصناعي',
	lighting: 'إضاءة',
	climate_home: 'الصفحة الرئيسية للتغيرات المناخية للأرض',
	light_on: 'تشغيل',
	light_off: 'إيقاف',
	visible_earth: 'الأرض المرئية',
	more_datasets: 'المزيد من مجموعات البيانات',
	events: 'الكوارث الطبيعية',
	poi: 'أبرز المعالم',
	gmap: 'مشاريع المركز',
	settings: 'الإعدادات',
	city_names: 'عرض أسماء المدن',
	display_ground_tracks: 'عرض المسارات الأرضية',
	fahrenheit: 'ف°',
	celsius: 'م°',
	compare_size: 'مقارنة الحجم',
	none: 'عدم المقارنة',
	Scientist: 'عالمة',
	School_Bus: 'باص مدرسة',
	Astronaut: 'رائد فضاء',
	Camel:'الجمل العربي',
	Cloud_free_Earth: 'أرض خالية من السحب',
	Starting_experience: '...بداية التجربة',
	Touch_to_start: 'المس للبدء',
	FEATURED: 'مخصوص',
	Main_Menu: 'القائمة الرئيسية',
	Dataset_Information: 'معلومات عن مجموعة البيانات',
	ok: 'حسناً',
	off: 'مغلق',
	on: 'مفتوح',
	info1: 'معلومات',
	observed: 'ملاحظ',
	data_observed: 'البيانات التي تمت ملاحظتها',
	data_unavailable: 'البيانات غير متاحة مؤقتًا',
	date_tooltip: 'تم التقاط صور الأقمار الصناعية المعروضة على الكرة الأرضية في هذا التاريخ. قد تتم معالجة بعض مجموعات البيانات بشكل أبطأ، حسب النوع، بينما يتم تحديث مجموعات أخرى يوميًا',
	create_timelapse: 'إنشاء فاصل زمني',
	exit_timelapse: 'الخروج من تصوير الفاصل الزمني',
	stellar_category: 'الفئة النجمية',
	Methane:'التحليل الطيفي',
	Sand_Movement:'تحليل حركة الرمال  ',
	Earth_Deformation:'المراقبة المتقدمة لسطح الأرض',
	Ce2s2_Projects:' مشاريع المركز',
	No_result:'لا يوجد نتائج',
	coming_soon:'قريبا',
	viewOnGlobe: 'عرض على الأرض',
	earth:"الأرض",
	year: "السنة",
	all_years: 'كل السنوات',
	category: "الفئة",
	media: "الوسائط",
	time: 'الوقت',
	latest_events: 'أحدث الكوارث',
	event_preview: 'معاينة الكارثة',
	loading_video: 'تحميل الفيديو',
	loading_model: 'تحميل المجسمات ثلاثية الابعاد',
	loading_data: 'تحميل البيانات',
	sand_movement_des:'مشروع تحليل حركة الرمال يشمل دراسة حركة الرمال وتحديد أنماط الحركة والسلوكيات لأنواع مختلفة من الكثبان الرملية، باستخدام صور الأقمار الصناعية،  و مقارنة نتائج  البيانات الفضائية مع العمل الميداني على الأرض، وقياسات الليدار، وتوقعات نماذج الطقس لحركة الرمال. يهدف هذا المشروع إلى تقديم نظرة حول التغيرات الزمنية للكثبان الرملية، وتوفير معلومات قيمة لمراقبة البيئة و إدارة الموارد المستدامة وتخطيط استخدام الأراضي والحد من الآثار السلبية المحتملة لحركات الكثبان الرملية على السكان المحليين والنظام البيئي.'

};

export default ar;
