/** @module pioneer */
import {
	BaseController,
	Entity,
	MathUtils,
	Quaternion,
	Vector3
} from '../../internal';

/**
 * A roll camera controller.
 */
export class RollController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The sensitivity for rolling.
		 * @type {number}
		 * @private
		 */
		this._rollSensitivity = 0.01;

		/**
		 * The smoothness of the rolling. Zero means no smoothness.
		 * @type {number}
		 * @private
		 */
		this._rollSmoothness = 0.8;

		/**
		 * The current value applied every frame to the roll axis rotation.
		 * @type {number}
		 * @private
		 */
		this._rollAngleSmoothedValue = 0.0;

		// Let the base controller know that this changes the orientation.
		this.addModifiedState('orientation');
	}

	/**
	 * Gets the roll sensitivity. Defaults to 0.01.
	 * @returns {number}
	 */
	getRollSensitivity() {
		return this._rollSensitivity;
	}

	/**
	 * Sets the roll sensitivity.
	 * @param {number} rollSensitivity
	 */
	setRollSensitivity(rollSensitivity) {
		this._rollSensitivity = rollSensitivity;
	}

	/**
	 * Gets the roll smoothness. Defaults to 0.8.
	 * @returns {number}
	 */
	getRollSmoothness() {
		return this._rollSmoothness;
	}

	/**
	 * Sets the roll smoothness, between 0 and 1.
	 * @param {number} rollSmoothness
	 */
	setRollSmoothness(rollSmoothness) {
		this._rollSmoothness = rollSmoothness;
	}

	/**
	 * Updates the entity's position and orientation.
	 * @override
	 * @internal
	 */
	__update() {
		// Set the position and orientation if they have never been set before.
		if (this.getEntity().getOrientation().isNaN()) {
			this.getEntity().setOrientation(Quaternion.Identity);
		}

		// Get the azimuth, elevation, and roll from input.
		let rollAngle = 0;
		const input = this.getEntity().getScene().getEngine().getInput();
		const viewport = input.getActiveViewport();
		if (viewport !== null) {
			const camera = viewport.getCamera();
			if (camera !== null && camera.getEntity() === this.getEntity()) {
				let rollMultiplier = 1;
				if (input.isKeyPressed('x')) {
					rollMultiplier = 0.05;
				}
				if (input.isShiftPressed()) {
					rollMultiplier = 5;
				}

				// Do touch rotate movement.
				const rotatedOffset = input.getRotatedOffset();
				if (rotatedOffset !== 0) {
					rollAngle += MathUtils.clamp(rotatedOffset * this._rollSensitivity * rollMultiplier, -0.1, +0.1);
				}

				// Add key movement.
				if (input.isKeyPressed('c')) {
					rollAngle += this._rollSensitivity * rollMultiplier;
				}
				if (input.isKeyPressed('z')) {
					rollAngle -= this._rollSensitivity * rollMultiplier;
				}
			}
		}

		// Apply smoothing.
		this._rollAngleSmoothedValue = MathUtils.lerp(rollAngle, this._rollAngleSmoothedValue, this._rollSmoothness);
		if (Math.abs(this._rollAngleSmoothedValue) < 0.0001) {
			this._rollAngleSmoothedValue = 0;
		}

		// Apply the roll.
		if (this._rollAngleSmoothedValue !== 0) {
			// Rotate the orientation by the forward (y) axis.
			const newOrientation = Quaternion.pool.get();
			const rotation = Quaternion.pool.get();
			rotation.setFromAxisAngle(Vector3.YAxis, this._rollAngleSmoothedValue);
			newOrientation.mult(this.getEntity().getOrientation(), rotation);
			this.getEntity().setOrientation(newOrientation);
			Quaternion.pool.release(newOrientation);
			Quaternion.pool.release(rotation);
		}
	}
}
