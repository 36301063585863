/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	Color,
	Entity,
	EntityRef,
	LightSourceComponent,
	MathUtils,
	ShaderChunkLogDepth,
	ShaderFix,
	THREE,
	ThreeJsHelper,
	Vector3
} from '../../internal';

/**
 * A comet coma and tail.
 */
export class CometTailComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The light source.
		 * @type {EntityRef}
		 * @private
		 */
		this._lightSource = new EntityRef(this.getEntity().getScene());

		/**
		 * The length of the particle stream.
		 * @type {number}
		 * @private
		 */
		this._timeLength = 6e5;

		/**
		 * The number of particles.
		 * @type {number}
		 * @private
		 */
		this._numberOfParticles = 200;

		/**
		 * The color of the particles.
		 * @type {Color}
		 * @private
		 */
		this._color = new Color();
		this._color.freeze();

		/**
		 * The force multiplier of the star.
		 * @type {number}
		 * @private
		 */
		this._starAccelerationMultiplier = 1.0;

		/**
		 * The time of particle 0. All values in originTimeArray are relative to this.
		 * @type {number}
		 * @private
		 */
		this._timeOfParticle0 = 0;
	}

	/**
	 * Returns the light source's name.
	 * @return {string}
	 */
	getLightSource() {
		return this._lightSource.getName();
	}

	/**
	 * Sets the light source's name.
	 * @param {string} lightSource
	 */
	setLightSource(lightSource) {
		this._lightSource.setName(lightSource);
	}

	/**
	 * Gets the length in seconds of the particle stream.
	 * @returns {number}
	 */
	getTimeLength() {
		return this._timeLength;
	}

	/**
	 * Sets the length of the particle stream. Defaults to 6e5.
	 * @param {number} length
	 */
	setTimeLength(length) {
		this._timeLength = length;
		this.resetResources();
	}

	/**
	 * Gets the acceleration multiplier of the star.
	 * @returns {number}
	 */
	getStarAccelerationMultiplier() {
		return this._starAccelerationMultiplier;
	}

	/**
	 * Sets the acceleration multiplier of the star. Defaults to 1.
	 * @param {number} starAccelerationMultiplier
	 */
	setStarAccelerationMultiplier(starAccelerationMultiplier) {
		this._starAccelerationMultiplier = starAccelerationMultiplier;
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'starAccelerationMultiplier', this._starAccelerationMultiplier);
	}

	/**
	 * Gets the number of particles.
	 * @returns {number}
	 */
	getNumberOfParticles() {
		return this._numberOfParticles;
	}

	/**
	 * Sets the number of particles. Defaults to 100.
	 * @param {number} numberOfParticles
	 */
	setNumberOfParticles(numberOfParticles) {
		this._numberOfParticles = numberOfParticles;
		this.resetResources();
	}

	/**
	 * Gets the color of the particles.
	 * @returns {Color}
	 */
	getColor() {
		return this._color;
	}

	/**
	 * Sets the color of the particles. Defaults to white.
	 * @param {Color} color
	 */
	setColor(color) {
		this._color.thaw();
		this._color.copy(color);
		this._color.freeze();
		ThreeJsHelper.setUniformColorRGBA(this.getThreeJsMaterials()[0], 'color', this._color);
	}

	/**
	 * Updates the particles.
	 * @override
	 */
	__update() {
		// Update the radius given the time length.
		this.__setRadius(Math.max(this.getEntity().getExtentsRadius(), this._timeLength * this.getEntity().getVelocity().magnitude()));

		// If not loaded, do nothing.
		if (this.getLoadState() !== 'loaded') {
			return;
		}

		// Check if any particles have gone outside the time bounds and recreate them if so.
		const time = this.getEntity().getScene().getEngine().getTime();
		const originTimeAttribute = /** @type THREE.Mesh */(this.getThreeJsObjects()[0]).geometry.attributes['originTime'];
		const originTimeArray = /** @type {Float32Array} */(originTimeAttribute.array);
		for (let i = 0, l = this._numberOfParticles; i < l; i++) {
			const particleTime = originTimeArray[i] + this._timeOfParticle0;
			if (particleTime < time - this._timeLength || particleTime > time) {
				this._newParticle(i, MathUtils.wrap(particleTime, time - this._timeLength, time));
			}
		}

		// Update the uniform with the time. If there is a single particle, it's a coma, so don't update the time.
		if (this._numberOfParticles > 1) {
			ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'time', time - this._timeOfParticle0);
		}
		else {
			ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'time', 0);
		}

		// Get the light source for the updates below.
		const lightSource = this._lightSource.get();
		if (lightSource !== null) {
			// Update the star absolute magnitude.
			const lightSourceComponent = /** @type {LightSourceComponent} */(lightSource.getComponentByType('lightSource'));
			if (lightSourceComponent !== null) {
				ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'starAbsoluteMagnitude', lightSourceComponent.getAbsoluteMagnitude());
			}

			// Update the position entity uniform.
			const position = Vector3.pool.get();
			this.getEntity().getPositionRelativeToEntity(position, Vector3.Zero, lightSource);
			ThreeJsHelper.setUniformVector3(this.getThreeJsMaterials()[0], 'positionOfEntity', position);
			Vector3.pool.release(position);
		}
	}

	/**
	 * Prepares the component for rendering.
	 * @param {CameraComponent} camera
	 * @override
	 * @internal
	 */
	__prepareForRender(camera) {
		// Set the position of the ThreeJs object.
		ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects(), this.getEntity(), camera);

		// Set the position-in-camera as well.
		const cameraSpacePosition = this.getEntity().getCameraSpacePosition(camera);
		ThreeJsHelper.setUniformVector3(this.getThreeJsMaterials()[0], 'positionInCamera', cameraSpacePosition);

		if (cameraSpacePosition.magnitude() < 4e4) {
			this.getThreeJsObjects()[0].visible = false;
		}
		else {
			const u = (cameraSpacePosition.magnitude() - 4e4) / 4e5;
			this.getThreeJsMaterials()[0].uniforms['color'].value.w = this._color.a * MathUtils.clamp01(u);
			this.getThreeJsObjects()[0].visible = true;
		}
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	async __loadResources() {
		// Setup the Three.js material.
		const threeJsMaterial = new THREE.RawShaderMaterial({
			uniforms: {
				time: new THREE.Uniform(0),
				timeLength: new THREE.Uniform(0),
				positionInCamera: new THREE.Uniform(new THREE.Vector3()),
				starAbsoluteMagnitude: new THREE.Uniform(0),
				starAccelerationMultiplier: new THREE.Uniform(0),
				positionOfEntity: new THREE.Uniform(new THREE.Vector3()),
				color: new THREE.Uniform(new THREE.Vector4()),

				...ShaderChunkLogDepth.ThreeUniforms
			},
			vertexShader: CometTailComponent.vertexShader,
			fragmentShader: CometTailComponent.fragmentShader,
			transparent: true,
			depthWrite: false,
			blending: THREE.AdditiveBlending,
			side: THREE.DoubleSide
		});
		ShaderFix.fix(threeJsMaterial);
		this.getThreeJsMaterials().push(threeJsMaterial);

		// Setup the attribute arrays.
		const positionArray = new Float32Array([-1, -1, 0, 1, -1, 0, 1, 1, 0, -1, 1, 0]);
		const indexArray = new Uint16Array([0, 1, 2, 2, 3, 0]);
		const originTimeArray = new Float32Array(1 * this._numberOfParticles);
		const originPositionArray = new Float32Array(3 * this._numberOfParticles);
		const accelerationMultiplier = new Float32Array(1 * this._numberOfParticles);

		// Setup the Three.js geometry.
		const threeJsGeometry = new THREE.InstancedBufferGeometry();
		threeJsGeometry.setAttribute('position', new THREE.BufferAttribute(positionArray, 3));
		threeJsGeometry.setAttribute('originTime', new THREE.InstancedBufferAttribute(originTimeArray, 1));
		threeJsGeometry.setAttribute('originPosition', new THREE.InstancedBufferAttribute(originPositionArray, 3));
		threeJsGeometry.setAttribute('accelerationMultiplier', new THREE.InstancedBufferAttribute(accelerationMultiplier, 1));
		threeJsGeometry.setIndex(new THREE.BufferAttribute(indexArray, 1));
		threeJsGeometry.instanceCount = this._numberOfParticles;

		// Setup the Three.js object.
		const threeJsObject = /** @type {THREE.Mesh<THREE.InstancedBufferGeometry, THREE.ShaderMaterial>} */ (ThreeJsHelper.createMeshObjectGivenGeometry(this, threeJsMaterial, threeJsGeometry));
		this.getThreeJsObjects().push(threeJsObject);

		// Setup the arrays.
		const time = this.getEntity().getScene().getEngine().getTime();
		for (let i = 0, l = this._numberOfParticles; i < l; i++) {
			this._newParticle(i, time - (i / l) * this._timeLength);
		}

		// Set the uniforms.
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'timeLength', this._timeLength);
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'starAccelerationMultiplier', this._starAccelerationMultiplier);
		ThreeJsHelper.setUniformColorRGBA(this.getThreeJsMaterials()[0], 'color', this._color);
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		ThreeJsHelper.destroyAllObjectsAndMaterials(this);
	}

	/**
	 * Sets up a new particle at the comet.
	 * @param {number} i - the particle number
	 * @param {number} time - the time to instantiate
	 * @private
	 */
	_newParticle(i, time) {
		// Set the origin time.
		const originTime = time;
		const originTimeAttribute = /** @type THREE.Mesh */(this.getThreeJsObjects()[0]).geometry.attributes['originTime'];
		const originTimeArray = /** @type {Float32Array} */(originTimeAttribute.array);
		if (i > 0) {
			originTimeArray[i] = originTime - this._timeOfParticle0;
		}
		else {
			// Adjust the rest of the particle times to be relative to the new particle 0.
			for (let j = 1, l = this._numberOfParticles; j < l; j++) {
				originTimeArray[j] += this._timeOfParticle0 - originTime;
			}
			this._timeOfParticle0 = originTime;
			originTimeArray[0] = 0;
		}
		originTimeAttribute.needsUpdate = true;

		// Set the origin position.
		const originPosition = Vector3.pool.get();
		const lightSource = this._lightSource.get();
		if (lightSource !== null) {
			this.getEntity().getPositionRelativeToEntity(originPosition, Vector3.Zero, lightSource, originTime);
		}
		const originPositionAttribute = /** @type THREE.Mesh */(this.getThreeJsObjects()[0]).geometry.attributes['originPosition'];
		const originPositionArray = /** @type {Float32Array} */(originPositionAttribute.array);
		originPositionArray[i * 3 + 0] = originPosition.x;
		originPositionArray[i * 3 + 1] = originPosition.y;
		originPositionArray[i * 3 + 2] = originPosition.z;
		originPositionAttribute.needsUpdate = true;
		Vector3.pool.release(originPosition);

		// Set the acceleration factor.
		const accelerationMultiplierAttribute = /** @type THREE.Mesh */(this.getThreeJsObjects()[0]).geometry.attributes['accelerationMultiplier'];
		const accelerationMultiplierArray = /** @type {Float32Array} */(accelerationMultiplierAttribute.array);
		accelerationMultiplierArray[i] = 0 + 1 * Math.random();
		accelerationMultiplierAttribute.needsUpdate = true;
	}
}

CometTailComponent.vertexShader = `
	attribute vec3 position;
	attribute float originTime;
	attribute vec3 originPosition;
	attribute vec3 originStarPosition;
	attribute vec3 originExternalAcceleration;
	attribute float accelerationMultiplier;

	uniform float time;
	uniform float timeLength;
	uniform vec3 positionInCamera;
	uniform float starAbsoluteMagnitude;
	uniform float starAccelerationMultiplier;
	uniform vec3 positionOfEntity;
	uniform mat4 viewMatrix;
	uniform mat4 modelViewMatrix;
	uniform mat4 projectionMatrix;

	varying vec2 vPosition;
	varying float vAlpha;

	${ShaderChunkLogDepth.VertexHead}

	void main() {
		// Get the position of the center point of the quad in model space.
		float deltaTime = time - originTime;
		vec3 externalAcceleration = starAccelerationMultiplier * accelerationMultiplier * normalize(originPosition) * 5.0e7 * pow(2.51188643151, 20.0 - starAbsoluteMagnitude) / dot(originPosition, originPosition);
		vec3 modelPosition = externalAcceleration * pow(deltaTime, 1.5);

		// Get a general expansion scale in all directions.
		float expansion = 0.2 * max(1.0e5, length(modelPosition));

		// Get the stretch direction in view space.
		vec3 modelStretch = modelPosition;
		vec3 cameraDirection = normalize(positionInCamera);
		modelStretch = modelStretch - dot(cameraDirection, modelStretch) * cameraDirection;
		vec4 viewStretch = viewMatrix * vec4(modelStretch, 1.0);

		// Get the stretch amounts in the x and y directions.
		vec2 stretch2d = vec2(max(expansion, 2.0 * length(viewStretch.xz)), expansion);

		// Do the stretch calculation on the vertex position in view space.
		// It translates it, rotates it, stretches it, and unrotates it.
		vec2 translate = 0.5 * normalize(vec2(viewStretch.xz));
		float angle = length(viewStretch.xz) > 0.0 ? atan(viewStretch.z, viewStretch.x) : 0.0;
		float cosAngle = cos(angle);
		float sinAngle = sin(angle);
		float stretchedX = (stretch2d.x * cosAngle * cosAngle + stretch2d.y * sinAngle * sinAngle) * 0.5 * (position.x + translate.x) + (stretch2d.x - stretch2d.y) * sinAngle * cosAngle * 0.5 * (position.y + translate.y);
		float stretchedY = (stretch2d.x - stretch2d.y) * sinAngle * cosAngle * 0.5 * (position.x + translate.x) + (stretch2d.x * sinAngle * sinAngle + stretch2d.y * cosAngle * cosAngle) * 0.5 * (position.y + translate.y);

		// Get the position in view space and then in normalized space.
		vec4 viewPosition = vec4(stretchedX, 0.0, stretchedY, 0.0) + modelViewMatrix * vec4(modelPosition, 1.0);
		gl_Position = projectionMatrix * viewPosition;
		gl_Position.w = viewPosition.y;

		// Set the varying variables for adjusting te
		vPosition = vec2(cosAngle * position.x + sinAngle * position.y, -sinAngle * position.x + cosAngle * position.y);
		vAlpha = sqrt(1.0 - sqrt(deltaTime / timeLength));

		// Make the gas fade far from the star.
		vAlpha *= min(1.0, 1.0 - length(originPosition) / 7.0e8);

		${ShaderChunkLogDepth.Vertex}
	}`;

CometTailComponent.fragmentShader = `
	precision highp float;

	uniform vec4 color;

	varying vec2 vPosition;
	varying float vAlpha;

	${ShaderChunkLogDepth.FragmentHead}

	void main(void) {
		// Set the color to be a circle tinted by the color.
		gl_FragColor = vec4(color.rgb, 0.5 * color.a) * max(0.0, 1.0 - dot(vPosition, vPosition)) * vAlpha;

		${ShaderChunkLogDepth.Fragment}
	}`;
