/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	Color,
	Entity,
	OrbitalElements,
	SpriteParticles,
	ThreeJsHelper,
	Vector3
} from '../../internal';

/**
 * @typedef LoadFunctionReturnValue
 * @property {OrbitalElements[]} orbitalElements
 * @property {Color[]} colors
 * @property {number[]} scales
 */

/**
 * @callback LoadFunction
 * @returns {LoadFunctionReturnValue}
 */

/**
 * A particle cloud with orbital parameters.
 */
export class OrbitalParticlesComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The size of each particle.
		 * @type {number}
		 * @private
		 */
		this._scaleOfParticles = 1.0;

		/**
		 * The function that loads the particle arrays.
		 * @type {LoadFunction}
		 * @private
		 */
		this._loadFunction = null;

		/**
		 * The orbital elements of each particle.
		 * @type {OrbitalElements[]}
		 * @private
		 */
		this._orbitalElementsList = [];

		/**
		 * The positions of the particles as an array.
		 * @type {Float32Array}
		 * @private
		 */
		this._offsetArray = new Float32Array(0);

		/**
		 * The sprite particles object.
		 * @type {SpriteParticles}
		 * @private
		 */
		this._spriteParticles = null;

		// Set the radius of the component to zero. It will be set to the furthest out particle.
		this.__setRadius(this.getEntity().getExtentsRadius() * 100);
	}

	/**
	 * Gets the scale of the particles flowing out.
	 * @returns {number}
	 */
	getScaleOfParticles() {
		return this._scaleOfParticles;
	}

	/**
	 * Sets the scale of the particles flowing out. Defaults to 1.0.
	 * @param {number} scaleOfParticles
	 */
	setScaleOfParticles(scaleOfParticles) {
		this._scaleOfParticles = scaleOfParticles;
		this.resetResources();
	}

	/**
	 * Sets the function that loads the particle arrays. It should return a { orbitalElements: OrbitalElements[], colors: Color[], scales: number[] } object.
	 * @param {LoadFunction} loadFunction
	 */
	setLoadFunction(loadFunction) {
		this._loadFunction = loadFunction;
		this.resetResources();
	}

	/**
	 * Updates the component.
	 * @override
	 * @internal
	 */
	__update() {
		// Do nothing if there's no particles.
		if (this._spriteParticles === null) {
			return;
		}
		// Update the particle positions from the orbital elements.
		const position = Vector3.pool.get();
		const velocity = Vector3.pool.get();
		const time = this.getEntity().getScene().getEngine().getTime();
		for (let i = 0, l = this._orbitalElementsList.length; i < l; i++) {
			this._orbitalElementsList[i].project(position, velocity, time);
			this._offsetArray[i * 3 + 0] = position.x;
			this._offsetArray[i * 3 + 1] = position.y;
			this._offsetArray[i * 3 + 2] = position.z;
		}
		Vector3.pool.release(position);
		Vector3.pool.release(velocity);
		this._spriteParticles.setParticleOffsets(this._offsetArray);
	}

	/**
	 * Prepares the component for rendering.
	 * @param {CameraComponent} camera
	 * @override
	 * @internal
	 */
	__prepareForRender(camera) {
		this._spriteParticles.prepareForRender(camera);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	async __loadResources() {
		// Create the sprite particles.
		this._spriteParticles = new SpriteParticles(this);
		// Push the object and material to the list.
		this.getThreeJsMaterials().push(this._spriteParticles.getThreeJsMaterial());
		this.getThreeJsObjects().push(this._spriteParticles.getThreeJsObject());
		// Initialize the particles.
		await this._initializeParticles();
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		ThreeJsHelper.destroyAllObjectsAndMaterials(this);
		this._spriteParticles = null;
	}

	/**
	 * Initializes the particles. Resets them if they were already initialized.
	 * @private
	 */
	async _initializeParticles() {
		if (this._loadFunction === null) {
			return;
		}
		// Load the orbital elements.
		const returnValue = await this._loadFunction();
		const numParticles = returnValue.orbitalElements.length;
		// Set the orbital elements.
		this._orbitalElementsList = [];
		let maxParticleDistance = 0;
		for (let i = 0, l = numParticles; i < l; i++) {
			// Copy them over so they can't be changed outside of the class.
			const orbitalElements = new OrbitalElements();
			orbitalElements.copy(returnValue.orbitalElements[i]);
			this._orbitalElementsList.push(orbitalElements);
			// Set the orbit apoapsis as the furthest distance the particle will go.
			const apoapsis = orbitalElements.semiMajorAxis * (1 + orbitalElements.eccentricity);
			maxParticleDistance = Math.max(maxParticleDistance, apoapsis);
		}
		// Set the radius.
		this.__setRadius(maxParticleDistance);
		// Initialize the offset array. It will be dynamic over time.
		this._offsetArray = new Float32Array(numParticles * 3);
		// Create and set the color and scale arrays, since they are static over time.
		const colorArray = new Float32Array(numParticles * 4);
		const scaleArray = new Float32Array(numParticles * 1);
		for (let i = 0, l = numParticles; i < l; i++) {
			colorArray[i * 4 + 0] = returnValue.colors[i].r;
			colorArray[i * 4 + 1] = returnValue.colors[i].g;
			colorArray[i * 4 + 2] = returnValue.colors[i].b;
			colorArray[i * 4 + 3] = returnValue.colors[i].a;
			scaleArray[i * 1 + 0] = returnValue.scales[i] * this._scaleOfParticles;
		}
		this._spriteParticles.setParticleColors(colorArray);
		this._spriteParticles.setParticleScales(scaleArray);
	}
}
