/** @module pioneer */
import {
	CollectionItem,
	Entity
} from '../internal';

/**
 * The base class for all components and controllers.
 * @extends {CollectionItem<Entity>}
 */
export class EntityItem extends CollectionItem {
	/**
	 * Constructor.
	 * @param {string} type - The type of the entity item.
	 * @param {string} name - The name of the entity item.
	 * @param {Entity} entity - The containing entity.
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * Flag that indicates whether the entity item is enabled or disabled.
		 * @type {boolean}
		 * @private
		*/
		this._enabled = true;

		/**
		 * Flag that indicates whether the item has been destroyed.
		 * @type {boolean}
		 * @private
		 */
		this._destroyed = false;
	}

	/**
	 * Returns the entity that contains the entity item.
	 * @returns {Entity}
	 */
	getEntity() {
		return this.__getCollectionParent();
	}

	/**
	 * Checks if the entity item has been destroyed.
	 * @returns {boolean}
	 */
	isDestroyed() {
		return this._destroyed;
	}

	/**
	 * Destroys the entity item's resources.
	 * @override
	 * @abstract
	 */
	__destroy() {
		this._destroyed = true;
		super.__destroy();
	}

	/**
	 * Returns true if the entity item is enabled.
	 * @returns {boolean}
	 */
	isEnabled() {
		return this._enabled;
	}

	/**
	 * Sets whether the entity item is enabled.
	 * @param {boolean} enabled
	 */
	setEnabled(enabled) {
		this._enabled = enabled;
	}

	/**
	 * Returns a new promise that resolves when the component is loaded.
	 * @returns {Promise<void>}
	 */
	getLoadedPromise() {
		return Promise.resolve();
	}

	/**
	 * Updates the entity item.
	 * @abstract
	 */
	__update() {
	}
}
