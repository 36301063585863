/** @module pioneer-scripts */
import { Entity } from '../entity';
import * as Pioneer from 'pioneer';

Entity.register({
	aegaeon: {
		groups: ['saturn', 'moons', 'minor', 'ring moonlet'],
		radius: 0.12,
		label: 'Aegaeon',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.12, 0.12, 0.12],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'aegaeon/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	aegir: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3.0,
		label: 'Aegir',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [3, 3, 3],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'aegir/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	albiorix: {
		groups: ['saturn', 'moons', 'minor', 'gallic'],
		radius: 16.0,
		label: 'Albiorix',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [16, 16, 16],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'albiorix/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	alvaldi: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3,
		label: 'Alvaldi',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 3,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'alvaldi/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	angrboda: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'Angrboda',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 1.5,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'angrboda/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	anthe: {
		groups: ['saturn', 'moons', 'minor', 'alkyonides'],
		radius: 0.9,
		label: 'Anthe',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.9, 0.9, 0.9],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'anthe/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	atlas: {
		groups: ['saturn', 'moons', 'minor', 'ring shepherd'],
		radius: 15.1,
		label: 'Atlas',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [15.1, 15.1, 15.1],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'atlas/saturn/orb'
		}, {
			type: 'dynamo',
			url: 'atlas/ori'
		}]
	},
	bebhionn: {
		groups: ['saturn', 'moons', 'minor', 'gallic'],
		radius: 3.0,
		label: 'Bebhionn',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [3, 3, 3],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'bebhionn/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	beli: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'Beli',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 1.5,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'beli/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	bergelmir: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3.0,
		label: 'Bergelmir',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [3, 3, 3],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'bergelmir/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	bestla: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3.5,
		label: 'Bestla',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [3.5, 3.5, 3.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'bestla/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	calypso: {
		groups: ['saturn', 'moons', 'minor', 'trojan'],
		radius: 10.7,
		label: 'Calypso',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [10.7, 10.7, 10.7],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'calypso/saturn/orb'
		}, {
			type: 'dynamo',
			url: 'calypso/ori'
		}]
	},
	daphnis: {
		groups: ['saturn', 'moons', 'minor', 'ring shepherd'],
		radius: 3.8,
		label: 'Daphnis',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [3.8, 3.8, 3.8],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'daphnis/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	eggther: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3,
		label: 'Eggther',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 3,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'eggther/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	epimetheus: {
		groups: ['saturn', 'moons', 'minor', 'co-orbital'],
		radius: 58.1,
		label: 'Epimetheus',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [58.1, 58.1, 58.1],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'epimetheus/saturn/orb'
		}, {
			type: 'dynamo',
			url: 'epimetheus/ori'
		}]
	},
	erriapus: {
		groups: ['saturn', 'moons', 'minor', 'gallic'],
		radius: 5.0,
		label: 'Erriapus',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [5, 5, 5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'erriapus/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	farbauti: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.5,
		label: 'Farbauti',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [2.5, 2.5, 2.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'farbauti/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	fenrir: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'Fenrir',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [2, 2, 2],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'fenrir/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	fornjot: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3.0,
		label: 'Fornjot',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [3, 3, 3],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'fornjot/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	geirrod: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2,
		label: 'Geirrod',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 2,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'geirrod/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	gerd: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2,
		label: 'Gerd',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 2,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'gerd/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	greip: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3.0,
		label: 'Greip',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [3, 3, 3],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'greip/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	gridr: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2,
		label: 'Gridr',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 2,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'gridr/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	gunnlod: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2,
		label: 'Gunnlod',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 2,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'gunnlod/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	hati: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3.0,
		label: 'Hati',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [3, 3, 3],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'hati/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	helene: {
		groups: ['saturn', 'moons', 'minor', 'trojan'],
		radius: 17.6,
		label: 'Helene',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [17.6, 17.6, 17.6],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'helene/saturn/orb'
		}, {
			type: 'dynamo',
			url: 'helene/ori'
		}]
	},
	hyrrokkin: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3.0,
		label: 'Hyrrokkin',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [3, 3, 3],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'hyrrokkin/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	ijiraq: {
		groups: ['saturn', 'moons', 'minor', 'inuit'],
		radius: 6.0,
		label: 'Ijiraq',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [6, 6, 6],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'ijiraq/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	janus: {
		groups: ['saturn', 'moons', 'minor', 'co-orbital'],
		radius: 89.5,
		label: 'Janus',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [89.5, 89.5, 89.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'janus/saturn/orb'
		}, {
			type: 'dynamo',
			url: 'janus/ori'
		}]
	},
	jarnsaxa: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3.0,
		label: 'Jarnsaxa',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [3, 3, 3],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'jarnsaxa/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	kari: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3.0,
		label: 'Kari',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [3, 3, 3],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'kari/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	kiviuq: {
		groups: ['saturn', 'moons', 'minor', 'inuit'],
		radius: 8.0,
		label: 'Kiviuq',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [8, 8, 8],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'kiviuq/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	loge: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3.0,
		label: 'Loge',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [3, 3, 3],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'loge/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	methone: {
		groups: ['saturn', 'moons', 'minor', 'alkyonides'],
		radius: 1.6,
		label: 'Methone',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [1.6, 1.6, 1.6],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'methone/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	mundilfari: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3.5,
		label: 'Mundilfari',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [3.5, 3.5, 3.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'mundilfari/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	narvi: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3.5,
		label: 'Narvi',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [3.5, 3.5, 3.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'narvi/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	paaliaq: {
		groups: ['saturn', 'moons', 'minor', 'inuit'],
		radius: 11.0,
		label: 'Paaliaq',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [11, 11, 11],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'paaliaq/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	pallene: {
		groups: ['saturn', 'moons', 'minor', 'alkyonides'],
		radius: 2.5,
		label: 'Pallene',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [2.5, 2.5, 2.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'pallene/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	pan: {
		groups: ['saturn', 'moons', 'minor', 'ring shepherd'],
		radius: 14.1,
		label: 'Pan',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [14.1, 14.1, 14.1],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'pan/saturn/orb'
		}, {
			type: 'dynamo',
			url: 'pan/ori'
		}]
	},
	pandora: {
		groups: ['saturn', 'moons', 'minor', 'ring shepherd'],
		radius: 40.7,
		label: 'Pandora',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [40.7, 40.7, 40.7],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'pandora/saturn/orb'
		}, {
			type: 'dynamo',
			url: 'pandora/ori'
		}]
	},
	phoebe: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 106.5,
		label: 'Phoebe',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/phoebe/phoebe.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: 'phoebe/saturn/orb'
		}, {
			type: 'dynamo',
			url: 'phoebe/ori'
		}]
	},
	polydeuces: {
		groups: ['saturn', 'moons', 'minor', 'trojan'],
		radius: 1.3,
		label: 'Polydeuces',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [1.3, 1.3, 1.3],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'polydeuces/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	prometheus: {
		groups: ['saturn', 'moons', 'minor', 'ring shepherd'],
		radius: 43.1,
		label: 'Prometheus',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [43.1, 43.1, 43.1],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'prometheus/saturn/orb'
		}, {
			type: 'dynamo',
			url: 'prometheus/ori'
		}]
	},
	s_2004_s_7: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3,
		label: 'S/2004 S 7',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 3,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_7/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_12: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.5,
		label: 'S/2004 S 12',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 2.5,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_12/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_13: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3,
		label: 'S/2004 S 13',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 3,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_13/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_17: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2,
		label: 'S/2004 S 17',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 2,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_17/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_21: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2004 S 21',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 1.5,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_21/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_24: {
		groups: ['saturn', 'moons', 'minor', 'gallic'],
		radius: 1.5,
		label: 'S/2004 S 24',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 1.5,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_24/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_28: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2,
		label: 'S/2004 S 28',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 2,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_28/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_31: {
		groups: ['saturn', 'moons', 'minor', 'inuit'],
		radius: 2,
		label: 'S/2004 S 31',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 2,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_31/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_36: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2004 S 36',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 1.5,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_36/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_37: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2,
		label: 'S/2004 S 37',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 2,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_37/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_39: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2004 S 39',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 1.5,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_39/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2006_s_1: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.5,
		label: 'S/2006 S 1',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 2.5,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2006_s_1/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2006_s_3: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3,
		label: 'S/2006 S 3',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 3,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2006_s_3/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2007_s_2: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3,
		label: 'S/2007 S 2',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 3,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2007_s_2/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2007_s_3: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.5,
		label: 'S/2007 S 3',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 2.5,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2007_s_3/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2009_s_1: {
		groups: ['saturn', 'moons', 'propeller moonlet'],
		radius: 0.150,
		label: 'S/2009 S 1',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 0.150,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}],
		postCreateFunction: (entity) => {
			// Add the controller for going around sun from earth to venus.
			const oeController = entity.addControllerByClass(Pioneer.OrbitalElementsController, undefined, entity.getControllerByClass(Pioneer.FixedController));
			const oe = new Pioneer.OrbitalElements();
			oe.epoch = 0;
			oe.eccentricity = 0;
			oe.semiMajorAxis = 1.17e5;
			oe.meanAngularMotion = 1.5472777e-4;
			oe.meanAnomalyAtEpoch = -1.5;
			oe.orbitOrientation.set(-0.9397445462795919, 0.02005900203250964, -0.052689008288728606, -0.33719681579275607);
			oeController.addOrbitalElements(-1e100, oe);
			oeController.addOrbitalElements(+1e100, oe);
		}
	},
	s_2019_s_1: {
		groups: ['saturn', 'moons', 'minor', 'inuit'],
		radius: 3,
		label: 'S/2019 S 1',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 3,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_1/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	saturn_lviii: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2,
		label: 'Saturn LVIII',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 2,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'saturn_lviii/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	saturn_lx: {
		groups: ['saturn', 'moons', 'minor', 'inuit'],
		radius: 2,
		label: 'Saturn LX',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 2,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'saturn_lx/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	saturn_lxiv: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'Saturn LXIV',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 1.5,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'saturn_lxiv/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	siarnaq: {
		groups: ['saturn', 'moons', 'minor', 'inuit'],
		radius: 20.0,
		label: 'Siarnaq',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [20, 20, 20],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'siarnaq/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	skathi: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 4.0,
		label: 'Skathi',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [4, 4, 4],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'skathi/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	skoll: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3.0,
		label: 'Skoll',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [3, 3, 3],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'skoll/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	skrymir: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2,
		label: 'Skrymir',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 2,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'skrymir/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	surtur: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3.0,
		label: 'Surtur',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [3, 3, 3],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'surtur/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	suttungr: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3.5,
		label: 'Suttungr',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [3.5, 3.5, 3.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'suttungr/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	tarqeq: {
		groups: ['saturn', 'moons', 'minor', 'inuit'],
		radius: 3.0,
		label: 'Tarqeq',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [3, 3, 3],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'tarqeq/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	tarvos: {
		groups: ['saturn', 'moons', 'minor', 'gallic'],
		radius: 7.5,
		label: 'Tarvos',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [7.5, 7.5, 7.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'tarvos/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	telesto: {
		groups: ['saturn', 'moons', 'minor', 'trojan'],
		radius: 12.4,
		label: 'Telesto',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [12.4, 12.4, 12.4],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'telesto/saturn/orb'
		}, {
			type: 'dynamo',
			url: 'telesto/ori'
		}]
	},
	thiazzi: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2,
		label: 'Thiazzi',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 2,
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'thiazzi/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	thrymr: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 3.5,
		label: 'Thrymr',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [3.5, 3.5, 3.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'thrymr/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	ymir: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 9.0,
		label: 'Ymir',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [9, 9, 9],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'ymir/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_40: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2004 S 40',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 66061440,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_40/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_41: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2004 S 41',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 79022304,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_41/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_42: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2004 S 42',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 79998624,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_42/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_43: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2004 S 43',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 84678912,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_43/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_44: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.5,
		label: 'S/2004 S 44',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 88660224,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [2.5, 2.5, 2.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_44/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_45: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2004 S 45',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 89743680,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_45/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_46: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2004 S 46',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 95694912,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_46/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_47: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2004 S 47',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 65879136,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_47/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_48: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2004 S 48',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 107343360,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_48/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_49: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2004 S 49',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 109231200,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_49/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_50: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2004 S 50',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 108902016,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_50/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_51: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2004 S 51',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 131278752,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_51/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_52: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2004 S 52',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 141175872,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_52/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2004_s_53: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2004 S 53',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 115986816,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2004_s_53/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2005_s_4: {
		groups: ['saturn', 'moons', 'minor', 'inuit'],
		radius: 2.5,
		label: 'S/2005 S 4',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 38899008,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [2.5, 2.5, 2.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2005_s_4/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2005_s_5: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2005 S 5',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 101763648,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2005_s_5/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2006_s_10: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2006 S 10',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 84943296,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2006_s_10/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2006_s_11: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2006 S 11',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 90052992,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2006_s_11/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2006_s_12: {
		groups: ['saturn', 'moons', 'minor', 'gallic'],
		radius: 2.0,
		label: 'S/2006 S 12',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 89428320,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2006_s_12/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2006_s_13: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2006 S 13',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 91638432,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2006_s_13/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2006_s_14: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2006 S 14',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 99591552,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2006_s_14/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2006_s_15: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2006 S 15',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 104886144,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2006_s_15/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2006_s_16: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2006 S 16',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 104329728,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2006_s_16/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2006_s_17: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2006 S 17',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 109259712,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2006_s_17/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2006_s_18: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2006 S 18',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 112181760,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2006_s_18/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2006_s_19: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2006 S 19',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 120038112,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2006_s_19/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2006_s_20: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.5,
		label: 'S/2006 S 20',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 49012128,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [2.5, 2.5, 2.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2006_s_20/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2006_s_9: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2006 S 9',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 55977696,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2006_s_9/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2007_s_5: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2007 S 5',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 64530432,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2007_s_5/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2007_s_6: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2007 S 6',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 82036800,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2007_s_6/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2007_s_7: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2007 S 7',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 65170656,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2007_s_7/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2007_s_8: {
		groups: ['saturn', 'moons', 'minor', 'gallic'],
		radius: 2.0,
		label: 'S/2007 S 8',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 72308160,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2007_s_8/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2007_s_9: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2007 S 9',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 93145248,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2007_s_9/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_10: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2019 S 10',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 97030656,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_10/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_11: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2019 S 11',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 96336000,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_11/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_12: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2019 S 12',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 98396640,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_12/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_13: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2019 S 13',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 98921088,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_13/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_14: {
		groups: ['saturn', 'moons', 'minor', 'inuit'],
		radius: 2.0,
		label: 'S/2019 S 14',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 77167296,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_14/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_15: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2019 S 15',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 100357056,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_15/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_16: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2019 S 16',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 115877088,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_16/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_17: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2019 S 17',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 111576096,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_17/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_18: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2019 S 18',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 114657984,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_18/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_19: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2019 S 19',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 113879520,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_19/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_2: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2019 S 2',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 69104448,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_2/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_20: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2019 S 20',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 118838880,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_20/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_21: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2019 S 21',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 141378048,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_21/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_3: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2019 S 3',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 72380736,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_3/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_4: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2019 S 4',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 78128064,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_4/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_5: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2019 S 5',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 85568832,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_5/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_6: {
		groups: ['saturn', 'moons', 'minor', 'inuit'],
		radius: 2.0,
		label: 'S/2019 S 6',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 79202880,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_6/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_7: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2019 S 7',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 93337056,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_7/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_8: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2019 S 8',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 94061952,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_8/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2019_s_9: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2019 S 9',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 94444704,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2019_s_9/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2020_s_1: {
		groups: ['saturn', 'moons', 'minor', 'inuit'],
		radius: 2.0,
		label: 'S/2020 S 1',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 38975040,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2020_s_1/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2020_s_10: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2020 S 10',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 131951808,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2020_s_10/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2020_s_2: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2020 S 2',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 77552640,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2020_s_2/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2020_s_3: {
		groups: ['saturn', 'moons', 'minor', 'inuit'],
		radius: 1.5,
		label: 'S/2020 S 3',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 78450336,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2020_s_3/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2020_s_4: {
		groups: ['saturn', 'moons', 'minor', 'gallic'],
		radius: 1.5,
		label: 'S/2020 S 4',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 80085888,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2020_s_4/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2020_s_5: {
		groups: ['saturn', 'moons', 'minor', 'inuit'],
		radius: 1.5,
		label: 'S/2020 S 5',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 80687232,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2020_s_5/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2020_s_6: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2020 S 6',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 100989504,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2020_s_6/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2020_s_7: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2020 S 7',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 74450880,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2020_s_7/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2020_s_8: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 1.5,
		label: 'S/2020 S 8',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 106109568,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [1.5, 1.5, 1.5],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2020_s_8/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	s_2020_s_9: {
		groups: ['saturn', 'moons', 'minor', 'norse'],
		radius: 2.0,
		label: 'S/2020 S 9',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: 132621408,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [2.0, 2.0, 2.0],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 's_2020_s_9/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	}
});
