/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';

/**
 * The Shadow Cone component.
 * */
export class ShadowConeComponent extends Pioneer.BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Pioneer.Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The color of the grid.
		 * @type {Pioneer.Color}
		 * @private
		 */
		this._color = new Pioneer.Color(1, 1, 1, 1);
		this._color.freeze();

		/**
		 * The other entity to use as the source of the cone.
		 * @type {Pioneer.EntityRef}
		 * @private
		 */
		this._sourceEntity = new Pioneer.EntityRef(this.getEntity().getScene());

		/**
		 * The shadow type to use the umbra or penumbra.
		 * @type {'umbra' | 'penumbra'}
		 * @private
		 */
		this._shadowType = 'umbra';

		/**
		 * The target entity for the shadow to extend to.
		 * @type {Pioneer.EntityRef}
		 * @private
		 */
		this._targetEntity = new Pioneer.EntityRef(this.getEntity().getScene());

		/**
		 * The distance interval over which the component is visible.
		 * @type {Pioneer.Interval}
		 * @private
		 */
		this._visibleDistanceInterval = new Pioneer.Interval(0, Number.POSITIVE_INFINITY);
		this._visibleDistanceInterval.freeze();

		/**
		 * The alpha multiplier determined by fading.
		 * @type {number}
		 * @private
		 */
		this._alphaMultiplier = 1.0;

		/**
		 * The number of vertices on the circle.
		 * @type {number}
		 * @private
		 */
		this._numberOfCirclePoints = 20;

		this.__setRadius(Number.POSITIVE_INFINITY);
	}

	/**
	 * Gets the color of the grid. Defaults to white.
	 * @returns {Pioneer.Color}
	 */
	getColor() {
		return this._color;
	}

	/**
	 * Sets the color of the cone. Defaults to white.
	 * @param {Pioneer.Color} color
	 */
	setColor(color) {
		this._color.thaw();
		this._color.copy(color);
		this._color.freeze();
		Pioneer.ThreeJsHelper.setUniformColorRGBA(this.getThreeJsMaterials()[0], 'color', this._color, this._alphaMultiplier);
	}

	/**
	 * Gets the other entity to use as the source of the cone.
	 * @returns {string}
	 */
	getSourceEntity() {
		return this._sourceEntity.getName();
	}

	/**
	 * Sets the other entity to use as the source of the cone.
	 * @param {string} name
	 */
	setSourceEntity(name) {
		this._sourceEntity.setName(name);
	}

	/**
	 * Gets the target entity for the shadow to extend to.
	 * @returns {string}
	 */
	getTargetEntity() {
		return this._targetEntity.getName();
	}

	/**
	 * Sets the target entity for the shadow to extend to.
	 * @param {string} name
	 */
	setTargetEntity(name) {
		this._targetEntity.setName(name);
	}

	/**
	 * Gets the shadow type to use the umbra or penumbra. Defaults to 'umbra'.
	 * @returns {'umbra' | 'penumbra'}
	 */
	getShadowType() {
		return this._shadowType;
	}

	/**
	 * Sets the shadow type to use the umbra or penumbra. Defaults to 'umbra'.
	 * @param {'umbra' | 'penumbra'} shadowType
	 */
	setShadowType(shadowType) {
		this._shadowType = shadowType;
		this.resetResources();
	}

	/**
	 * Gets the distance interval over which the component is visible. Defaults to [0, infinity).
	 * @returns {Pioneer.Interval}
	 */
	getVisibleDistanceInterval() {
		return this._visibleDistanceInterval;
	}

	/**
	 * Sets the distance interval over which the component is visible. Defaults to [0, infinity).
	 * @param {Pioneer.Interval} distanceInterval
	 */
	setVisibleDistanceInterval(distanceInterval) {
		this._visibleDistanceInterval.thaw();
		this._visibleDistanceInterval.copy(distanceInterval);
		this._visibleDistanceInterval.freeze();
	}

	/**
	 * Prepare the component for rendering.
	 * @param {Pioneer.CameraComponent} camera
	 * @override
	 * @package
	 */
	__prepareForRender(camera) {

		// Update the mesh to reflect the cone.
		this._updateMesh();

		// Set the Three.js object position the entity's camera-space position.
		Pioneer.ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects()[0], this.getEntity(), camera);

		// Make the cone fade if the camera is outside a visible distance interval.
		const cameraSpacePosition = this.getEntity().getCameraSpacePosition(camera);
		const distanceToObject = cameraSpacePosition.magnitude();
		const diffFadeDistance = (this._visibleDistanceInterval.max - this._visibleDistanceInterval.min) * 0.5;
		const minFadeDistance = Math.min(this._visibleDistanceInterval.min * 0.5, diffFadeDistance);
		const maxFadeDistance = Math.min(this._visibleDistanceInterval.max * 0.5, diffFadeDistance);
		if (distanceToObject < this._visibleDistanceInterval.min + minFadeDistance) {
			this._alphaMultiplier = Math.max(0, (distanceToObject - this._visibleDistanceInterval.min) / minFadeDistance);
		}
		else if (distanceToObject > this._visibleDistanceInterval.max - maxFadeDistance) {
			this._alphaMultiplier = Math.max(0, (this._visibleDistanceInterval.max - distanceToObject) / maxFadeDistance);
		}
		else {
			this._alphaMultiplier = 1.0;
		}
		Pioneer.ThreeJsHelper.setUniformColorRGBA(this.getThreeJsMaterials()[0], 'color', this._color, this._alphaMultiplier);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	__loadResources() {

		// Create the material.
		const material = this.getEntity().getScene().getEngine().getMaterialManager().getPreloaded('basic_alpha');
		this.getThreeJsMaterials().push(material);

		// Set the uniforms.
		Pioneer.ThreeJsHelper.setUniformColorRGBA(material, 'color', this._color, this._alphaMultiplier);

		// Create the mesh object.
		const object = Pioneer.ThreeJsHelper.createMeshObject(this, material, [{ name: 'position', dimensions: 3 }], false);
		this.getThreeJsObjects().push(object);

		// Create the mesh geometry.
		this._createMesh();

		// Return it as loaded.
		return Promise.resolve();
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		Pioneer.ThreeJsHelper.destroyAllObjectsAndMaterials(this);
	}

	/**
	 * Creates the mesh.
	 * @private
	 */
	_createMesh() {

		// The arrays.
		const positions = new Float32Array(this._numberOfCirclePoints * 2 * 3);
		const indices = new Uint16Array(this._numberOfCirclePoints * 6);

		// Go around the circle and set the indices.
		for (let i = 0; i < this._numberOfCirclePoints; i++) {
			const iPlus1 = (i + 1) % this._numberOfCirclePoints;
			indices[i * 6 + 0] = i;
			indices[i * 6 + 1] = iPlus1;
			indices[i * 6 + 2] = iPlus1 + this._numberOfCirclePoints;
			indices[i * 6 + 3] = iPlus1 + this._numberOfCirclePoints;
			indices[i * 6 + 4] = i + this._numberOfCirclePoints;
			indices[i * 6 + 5] = i;
		}

		// Apply the positions and indices.
		const geometry = /** @type {Pioneer.THREE.Mesh} */(this.getThreeJsObjects()[0]).geometry;
		Pioneer.ThreeJsHelper.setVertices(geometry, 'position', positions);
		Pioneer.ThreeJsHelper.setIndices(geometry, indices);
	}

	/**
	 * Updates the mesh positions and colors.
	 * @private
	 */
	_updateMesh() {

		// Get the source entity, and do nothing if not found.
		const sourceEntity = this._sourceEntity.get();
		if (sourceEntity === null) {
			return;
		}

		// Get the target entity (it may be null).
		const targetEntity = this._targetEntity.get();

		// Get the source and this radius.
		const sourceRadius = sourceEntity.getExtentsRadius();
		const thisRadius = this.getEntity().getExtentsRadius();

		// Get the distance and axis from the sourceEntity to this entity.
		const axis = Pioneer.Vector3.pool.get();
		this.getEntity().getPositionRelativeToEntity(axis, Pioneer.Vector3.Zero, sourceEntity);
		const distanceToSource = axis.magnitude();
		axis.normalize(axis);
		if (axis.isNaN()) {
			return;
		}

		// Get the distance between this entity and the target entity.
		let distanceToTarget = thisRadius * 10;
		if (targetEntity !== null) {
			const targetAxis = Pioneer.Vector3.pool.get();
			targetEntity.getPositionRelativeToEntity(targetAxis, Pioneer.Vector3.Zero, this.getEntity());
			distanceToTarget = targetAxis.magnitude();
			Pioneer.Vector3.pool.release(targetAxis);
		}

		// Get the attribute array to modify.
		const attribute = /** @type {Pioneer.THREE.Mesh} */(this.getThreeJsObjects()[0]).geometry.getAttribute('position');
		const array = attribute.array;

		// Calculate the different offsets and radii of the cone.
		let radius0;
		let radius1;
		let offset0;
		let offset1;
		if (this._shadowType === 'umbra') {
			const cosAngleOfTangent = (sourceRadius - thisRadius) / distanceToSource;
			const sinAngleOfTangent = Math.sqrt(1 - cosAngleOfTangent * cosAngleOfTangent);
			const offsetFocus = thisRadius / cosAngleOfTangent;
			const radiusFactor = cosAngleOfTangent / sinAngleOfTangent;
			offset0 = thisRadius * cosAngleOfTangent;
			offset1 = Math.min(distanceToTarget, offsetFocus);
			radius0 = radiusFactor * (offsetFocus - offset0);
			radius1 = radiusFactor * (offsetFocus - offset1);
		}
		else { // 'penumbra'
			const cosAngleOfTangent = (sourceRadius + thisRadius) / distanceToSource;
			const sinAngleOfTangent = Math.sqrt(1 - cosAngleOfTangent * cosAngleOfTangent);
			const offsetFocus = -thisRadius / cosAngleOfTangent;
			const radiusFactor = cosAngleOfTangent / sinAngleOfTangent;
			offset0 = -thisRadius * cosAngleOfTangent;
			offset1 = distanceToTarget;
			radius0 = -radiusFactor * (offsetFocus - offset0);
			radius1 = -radiusFactor * (offsetFocus - offset1);
		}

		// If this entity has a spheroid, it might cause clipping issues with the cone, so increase the radius0 by a small amount.
		if (this.getEntity().getComponentByClass(Pioneer.SpheroidComponent) !== null) {
			radius0 *= 1.02;
		}

		// Go around the circle.
		for (let i = 0; i < this._numberOfCirclePoints; i++) {
			const angleI = i / this._numberOfCirclePoints * Pioneer.MathUtils.twoPi;
			const xI = Math.cos(angleI);
			const yI = Math.sin(angleI);

			// Set the position.
			array[i * 3 + 0] = radius0 * xI;
			array[i * 3 + 1] = radius0 * yI;
			array[i * 3 + 2] = offset0;
			array[(i + this._numberOfCirclePoints) * 3 + 0] = radius1 * xI;
			array[(i + this._numberOfCirclePoints) * 3 + 1] = radius1 * yI;
			array[(i + this._numberOfCirclePoints) * 3 + 2] = offset1;
		}

		// Mark that the attribute changed.
		attribute.needsUpdate = true;

		// Set the Three.js object orientation to have the z-axis be the axis.
		const orientation = Pioneer.Quaternion.pool.get();
		const rotation = Pioneer.Quaternion.pool.get();
		const forward = Pioneer.Vector3.pool.get();
		orientation.copyFromThreeJs(this.getThreeJsObjects()[0].quaternion);
		orientation.getAxis(forward, 2);
		rotation.setFromVectorFromTo(forward, axis);
		orientation.mult(rotation, orientation);
		Pioneer.ThreeJsHelper.setOrientation(this.getThreeJsObjects()[0], orientation);
		Pioneer.Quaternion.pool.release(orientation);
		Pioneer.Vector3.pool.release(forward);
		Pioneer.Quaternion.pool.release(rotation);
		Pioneer.Vector3.pool.release(axis);

	}
}
