/* eslint-disable array-element-newline */
import ENTITY_INFO from '../data/entity_info.json';
import ar from '../languages/ar';

// Launched on Nov (11 => index in the array is 10) 20, 1998
const time = (Date.now() - new Date(1998, 10, 20)) / (1000 * 60 * 60 * 24);
const timeSentinel6 = (Date.now() - new Date(2020, 10, 10)) / (1000 * 60 * 60 * 24);

const SPACECRAFT_CONSTELLATIONS = {
	Aqua: ['sc_aqua'],
	Aura: ['sc_aura'],
	CALIPSO: ['sc_calipso'],
	CloudSat: ['sc_cloudsat'],
	CYGNSS: ['sc_cygnss_1', 'sc_cygnss_2', 'sc_cygnss_3', 'sc_cygnss_4', 'sc_cygnss_5', 'sc_cygnss_7', 'sc_cygnss_8'],
	GPM: ['sc_gpm'],
	GRACE: ['sc_grace_fo1', 'sc_grace_fo2'],
	GRIFEX: ['sc_grifex'],
	HyspIRI: ['sc_hyspiri'],
	ICESat: ['sc_icesat_2'],
	ISS: ['sc_iss', 'sc_iss_oco_3', 'sc_iss_ecostress', 'sc_iss_emit'],
	Jason: ['sc_jason_3'],
	Landsat7: ['sc_landsat_7'],
	Landsat8: ['sc_landsat_8'],
	Landsat9: ['sc_landsat_9'],
	NISAR: ['sc_nisar'],
	'OCO-2': ['sc_oco_2'],
	'OCO-3': ['sc_iss_oco_3', 'sc_iss', 'sc_iss_ecostress', 'sc_iss_emit'],
	ECOSTRESS: ['sc_iss_ecostress', 'sc_iss', 'sc_iss_oco_3', 'sc_iss_emit'],
	EMIT: ['sc_iss_emit', 'sc_iss', 'sc_iss_oco_3', 'sc_iss_ecostress'],
	PACE: ['sc_pace'],
	'PREFIRE-1': ['sc_prefire_1', 'sc_prefire_2'],
	'PREFIRE-2': ['sc_prefire_2', 'sc_prefire_1'],
	Sentinel: ['sc_sentinel_6'],
	SMAP: ['sc_smap'],
	Suomi_NPP: ['sc_suomi_npp'],
	SWOT: ['sc_swot'],
	TEMPO: ['sc_tempo'],
	Terra: ['sc_terra'],
	'TROPICS-01': ['sc_tropics_01', 'sc_tropics_03', 'sc_tropics_05', 'sc_tropics_06', 'sc_tropics_07'],
	'TROPICS-03': ['sc_tropics_03', 'sc_tropics_01', 'sc_tropics_05', 'sc_tropics_06', 'sc_tropics_07'],
	'TROPICS-05': ['sc_tropics_05', 'sc_tropics_01', 'sc_tropics_03', 'sc_tropics_06', 'sc_tropics_07'],
	'TROPICS-06': ['sc_tropics_06', 'sc_tropics_01', 'sc_tropics_03', 'sc_tropics_05', 'sc_tropics_07'],
	'TROPICS-07': ['sc_tropics_07', 'sc_tropics_01', 'sc_tropics_03', 'sc_tropics_05', 'sc_tropics_06'],
	'sc_saudisat_4': ['sc_saudisat_4']
};

// Create SPACECRAFT_WITH_INSTRUMENTS from entity info.
const SPACECRAFT_WITH_INSTRUMENTS = Object.keys(ENTITY_INFO).reduce((acc, spacecraftId) => {

	const { instrumentParent, stylizedName } = ENTITY_INFO[spacecraftId];

	if (instrumentParent && stylizedName) {
		acc[instrumentParent] = [
			...(acc[instrumentParent] || []),
			{ instrumentId: spacecraftId, stylizedName }
		];
	}
	return acc;
}, {});

const SPACECRAFT_GEOSTATIONARY = ['sc_tempo'];

const SPACECRAFT_LAND = [
	'sc_grace_fo1',
	'sc_grace_fo2',
	'sc_icesat_2',
	'sc_landsat_7',
	'sc_landsat_8',
	'sc_landsat_9',
	'sc_smap',
	'sc_terra'
];
const SPACECRAFT_SEA = ['sc_jason_3', 'sc_pace', 'sc_sentinel_6', 'sc_swot'];

const SPACECRAFT_ATMOSPHERE = [
	'sc_aqua',
	'sc_aura',
	'sc_calipso',
	'sc_cloudsat',
	'sc_cygnss_1',
	'sc_cygnss_2',
	'sc_cygnss_3',
	'sc_cygnss_4',
	'sc_cygnss_5',
	'sc_cygnss_6',
	'sc_cygnss_7',
	'sc_cygnss_8',
	'sc_gpm',
	'sc_oco_2',
	'sc_prefire_1',
	'sc_prefire_2',
	'sc_suomi_npp',
	'sc_tempo',
	'sc_tropics_01',
	'sc_tropics_03',
	'sc_tropics_05',
	'sc_tropics_06',
	'sc_tropics_07'
];

const TRAIL_COLORS = {
	ATMOSPHERE: [0.76, 0.44, 0.18, 0.5],
	LAND: [0.22, 0.64, 0.22, 0.5],
	SEA: [0.3, 0.53, 0.84, 0.5]
};

const SPACECRAFT_DATA = {
	ISS: {
		absolutePath: true,
		thumb: 'assets/images/iss_320x240.jpg',
		url: 'https://www.nasa.gov/mission_pages/station/main/index.html',
		id: 1374,
		target: '_blank',
		categories: ['operating', 'land', 'earth_now'],
		time_in_orbit: Math.floor(Math.abs(time) / 365.25) + ' years : ' + Math.floor(time % 365.25) + ' day' + (Math.floor(time % 365.25) > 1 ? 's' : ''),
		description: 'The International Space Station (ISS) is a space station, or a habitable artificial satellite, in low Earth orbit. Its first component was launched into orbit in 1998, with the first long-term residents arriving in November 2000. It has been inhabited continuously since that date. The last pressurized module was fitted in 2011, and an experimental inflatable space habitat was added in 2016. The station is expected to operate until 2030. Development and assembly of the station continues, with several new elements scheduled for launch in 2019. The ISS is the largest human-made body in low Earth orbit and can often be seen with the naked eye from Earth. The ISS consists of pressurized habitation modules, structural trusses, solar arrays, radiators, docking ports, experiment bays and robotic arms. ISS components have been launched by Russian Proton and Soyuz rockets and American Space Shuttles. The ISS program is a joint project between five participating space agencies: NASA (United States), Roscosmos (Russia), JAXA (Japan), ESA (Europe), and CSA (Canada). The ownership and use of the space station is established by intergovernmental treaties and agreements.</br></br>The ISS serves as a microgravity and space environment research laboratory in which crew members conduct experiments in biology, human biology, physics, astronomy, meteorology, and other fields. The station is suited for the testing of spacecraft systems and equipment required for missions to the Moon and Mars. The ISS maintains an orbit with an altitude of between 330 and 435 km (205 and 270 mi) by means of reboost maneuvers using the engines of the Zvezda module or visiting spacecraft. It circles the Earth in roughly 92 minutes and completes 15.5 orbits per day.',
		title: 'International Space Station',
		external_id: 'ISS',
		date: 'November 20, 1998',
		launch_location: 'Baikonur Cosmodrome, Kazhakstan',
		launch_vehicle: 'Russian Proton rocket'
	},
	Sentinel6: {
		absolutePath: true,
		thumb: 'assets/images/sentinel_6_320x240.jpg',
		url: 'https://www.nasa.gov/mission_pages/station/main/index.html',
		id: 1375,
		target: '_blank',
		categories: ['operating', 'sea', 'earth_now'],
		time_in_orbit: Math.floor(Math.abs(timeSentinel6) / 365.25) + ' years : ' + Math.floor(timeSentinel6 % 365.25) + ' day' + (Math.floor(timeSentinel6 % 365.25) > 1 ? 's' : ''),
		description: 'The first of two identical satellites to be launched five years apart, Sentinel-6 Michael Freilich will make high precision ocean altimetry measurements to continue the work previously done by Jason-1, Jason-2/OSTM, and the Jason-3 missions.\n\nA secondary objective is to collect high resolution vertical profiles of temperature and water vapor through the Radio Occultation instrument. This is used to help numerical weather predictions.',
		title: 'Sentinel-6 Michael Freilich',
		external_id: 'Sentinel6',
		date: 'November 10, 2020',
		launch_location: 'Vandenberg Air Force Base',
		launch_vehicle: ''
	},
	// Foo: {
		// absolutePath: true,
		// thumb: 'assets/images/iss_320x240.jpg',
		// url: 'https://www.nasa.gov/mission_pages/station/main/index.html',
		// id: 1376,
		// target: '_blank',
		// categories: ['operating', 'land', 'earth_now'],
		// time_in_orbit: '900 days',
		// description: 'Ut leo. Mauris sollicitudin fermentum libero. Vestibulum purus quam, scelerisque ut, mollis sed, nonummy id, metus. Proin magna.',
		// title: 'Foo',
		// external_id: 'foo',
		// date: 'May 3, 2019',
		// launch_location: '',
		// launch_vehicle: ''
	// },
	sc_saudisat_4: {
		absolutePath: true,
		clickable: true,
		thumb: 'assets/images/iss_320x240.jpg',
		url: 'https://www.nasa.gov/mission_pages/station/main/index.html',
		id: 1,
		target: '_blank',
		categories: ['operating', 'land', 'earth_now'],
		time_in_orbit: '900 days',
		description: 'Ut leo. Mauris sollicitudin fermentum libero. Vestibulum purus quam, scelerisque ut, mollis sed, nonummy id, metus. Proin magna.',
		title: 'SAUDISAT 4',
		external_id: 'sc_saudisat_4',
		date: 'May 3, 2019',
		launch_location: '',
		launch_vehicle: ''
	}
	
};

const SATELLITES_NOW_DATA = {
	value: 'satellites',
	title: 'الأقمار الصناعية الآن',
	description: 'وكالة ناسا لديها أكثر من أربع وعشرين قمراً صناعياً لدراسة الأرض. ومشروع الأرض الآن ”Earth Now“ يقدم العديد من الأقمار الصناعية التي تركز على العلامات الحيوية لكوكبنا. انقر على أي قمر صناعي لعرض نموذج ثلاثي الأبعاد له، أو شاهد أحدث البيانات في قائمة العلامات الحيوية.\n',
	sphereMapThumbSrc: 'sat_now.png',
	datasetGroups: []
};

export {
	SPACECRAFT_CONSTELLATIONS,
	SPACECRAFT_WITH_INSTRUMENTS,
	SATELLITES_NOW_DATA,
	SPACECRAFT_DATA,
	SPACECRAFT_LAND,
	SPACECRAFT_SEA,
	SPACECRAFT_ATMOSPHERE,
	SPACECRAFT_GEOSTATIONARY,
	TRAIL_COLORS
};
