import { useSnapshot } from 'valtio';
import React from 'react';

import { getOverlay } from '../components/overlays';
import Header from '../components/header';
import Loading from '../components/loading';
import DateLabel from '../components/date_label';
import Footer from '../components/footer';
import LatestEvent from '../components/latest_event';
import EventDropdowns from '../components/event_dropdowns';
import SideMenu from '../components/side_menu';
import MobileDetailOverlay from '../components/mobile_detail_overlay';
import Modal from '../components/modal';
import VideoControls from '../components/video_controls';
import DataReadout from '../components/data_readout';
import BackButton from '../components/back_button';
import ColorBar from '../components/color_bar';
import {stellarStore} from '../managers/globalState';
import KioskMode from '../layouts/KioskLayout';
import AnimationDate from '../components/animation_date';


import { eventsStore, videoStore, datasetStore, kioskStore, appStore, uiStore } from '../managers/globalState';

const isIphone = navigator.userAgent.match(/iPhone/i);

const MobileComponents = () => (
	<>
		<MobileDetailOverlay />
		<SideMenu />
		<EventDropdowns />
	</>
);

const HideableComponents = () => {
	const { isMobile } = useSnapshot(appStore.state);
    const{currentStellar}=useSnapshot(stellarStore.state);
	const { eventsVisibleOnGlobe,animatedDate } = useSnapshot(eventsStore.state);
	
	const showEventDropdowns = !isMobile && eventsVisibleOnGlobe;

	return (


		<>
			<Header />
			{currentStellar?null:<LatestEvent />}
			{isMobile && <MobileComponents />}
			{ animatedDate && <AnimationDate date={animatedDate}/> }
			<BackButton />
			<ColorBar />
			{showEventDropdowns && <EventDropdowns />}
			<Footer />
		</>
	);
};

/**
 * This component is dedicated to organizing the UI into it's various sections.
 * To reduce and simplify the code, functional components and react hooks:
 * https://reactjs.org/docs/hooks-reference.html
 */
export default function UILayout() {
	const { isVideoTexture } = useSnapshot(videoStore.state);
	const { datasetHasAnimation } = useSnapshot(datasetStore.state);
	const { maxSessionTime, maxInactivityTime } = useSnapshot(kioskStore.state);
	const { queries, isMobile } = useSnapshot(appStore.state);
	const { hideUI } = queries || {};
	const showUI = hideUI !== 'true';

	// overlayType state.
	const { overlayType } = useSnapshot(uiStore.state);

	const classes = `ui${datasetHasAnimation ? ' animating' : ''}${isIphone ? ' move-up' : ''}${isVideoTexture ? ' video-animating' : ''}${showUI ? '' : ' hide-ui'}`;

	return (
		<>
			<div id='UI' className={classes}>
				<React.StrictMode>
					{/* Hideable components */}
					{showUI && <HideableComponents />}

					{/* Components to always show */}
					<VideoControls />
					<Loading />
					<DataReadout />
					<Modal />
					{!isMobile && <DateLabel />}

					{overlayType && getOverlay(overlayType, { closeOverlay: () => uiStore.setGlobalState({ overlayType: null, videoMobileBackTo: null }) })}
				</React.StrictMode>
			</div>
			{Boolean(maxSessionTime || maxInactivityTime) && <KioskMode />}
		</>
	);
}
