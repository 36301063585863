/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	Color,
	MathUtils,
	Quaternion,
	SpoutComponent,
	THREE,
	ThreeJsHelper,
	Vector2,
	Vector3
} from '../internal';

/**
 * A helper class for drawing lines. It creates the objects and materials, but they are 'owned' by the component.
 */
export class LineMesh {
	/**
	 * Constructor.
	 * @param {BaseComponent} component
	 */
	constructor(component) {
		/**
		 * The component that uses this.
		 * @type {BaseComponent}
		 * @private
		 */
		this._component = component;

		/**
		 * A global multiplier for the alpha channel.
		 * @type {number}
		 * @private
		 */
		this._alphaMultiplier = 1.0;

		/**
		 * The position that the line points are relative to.
		 * @type {Vector3}
		 * @private
		 */
		this._position = new Vector3();

		/**
		 * The orientation that the line points are relative to.
		 * @type {Quaternion}
		 * @private
		 */
		this._orientation = new Quaternion();

		/**
		 * The scale that all points are multiplied by.
		 * @type {number}
		 * @private
		 */
		this._scale = 1;

		/**
		 * The line dash gap length.
		 * @type {number}
		 * @private
		 */
		this._dashGapLength = 0;

		/**
		 * The line dash length.
		 * @type {number}
		 * @private
		 */
		this._dashLength = 1;

		/**
		 * The width of the glow from full to clear along the edge of the lines.
		 * @type {number}
		 * @private
		 */
		this._glowWidth = 0;

		/**
		 * The Three.js objects. There needs to be a copy here since there may be multiple line meshes in the component.
		 * @type {Array<THREE.Mesh<THREE.BufferGeometry, THREE.ShaderMaterial | THREE.ShaderMaterial[]>>}
		 * @private
		 */
		this._threeJsObjects = [];

		/**
		 * The Three.js material.
		 * @type {THREE.ShaderMaterial}
		 * @private
		 */
		this._threeJsMaterial = null;

		// Create the material.
		this._threeJsMaterial = component.getEntity().getScene().getEngine().getMaterialManager().getPreloaded('line');
		component.getThreeJsMaterials().push(this._threeJsMaterial);
		this._threeJsMaterial.uniforms['alphaMultiplier'].value = this._alphaMultiplier;
		this._threeJsMaterial.uniforms['dashLength'].value = this._dashLength;
		this._threeJsMaterial.uniforms['dashGapLength'].value = this._dashGapLength;
		this._threeJsMaterial.uniforms['glowWidth'].value = this._glowWidth;
	}

	/**
	 * Sets the positions. Each two positions is a line segment.
	 * @param {Vector3[]} positions
	 */
	setPositions(positions) {
		if (positions.length % 2 !== 0) {
			throw new Error('Number of positions in the LineMesh must be even.');
		}
		// Adjust the number of vertices. * 2 is because the line has width.
		this._adjustVerticesInGeometries(positions.length * 2);
		const positionPrev = Vector3.pool.get();
		const positionNext = Vector3.pool.get();
		const tangent = Vector3.pool.get();
		const normal = Vector3.pool.get();
		const segment = Vector3.pool.get();
		// Go through each geometry, setting the vertices. Each object holds up to _verticesPerGeometry vertices.
		let dashOffset = 0;
		for (let objectIndex = 0; objectIndex < this._threeJsObjects.length; objectIndex++) {
			const attribute = /** @type {THREE.InterleavedBufferAttribute} */(this._threeJsObjects[objectIndex].geometry.getAttribute('position'));
			const array = attribute.array;
			for (let i = 0; i < array.length / (2 * LineMesh._floatsPerVertex); i++) {
				const offset = i + objectIndex * (LineMesh._verticesPerGeometry / 2);
				array[i * 2 * LineMesh._floatsPerVertex + 0] = positions[offset].x;
				array[i * 2 * LineMesh._floatsPerVertex + 1] = positions[offset].y;
				array[i * 2 * LineMesh._floatsPerVertex + 2] = positions[offset].z;
				array[(i * 2 + 1) * LineMesh._floatsPerVertex + 0] = positions[offset].x;
				array[(i * 2 + 1) * LineMesh._floatsPerVertex + 1] = positions[offset].y;
				array[(i * 2 + 1) * LineMesh._floatsPerVertex + 2] = positions[offset].z;
				// Get the positionPrev and positionNext.
				if (offset % 2 === 0) {
					if (positions[offset].equals(positions[MathUtils.wrap(offset - 1, 0, positions.length)])) {
						positionPrev.copy(positions[MathUtils.wrap(offset - 2, 0, positions.length)]);
						positionNext.copy(positions[offset + 1]);
					}
					else { // Disconnected segments, so just use the current position as the previous position.
						positionPrev.copy(positions[offset]);
						positionNext.copy(positions[offset + 1]);
						dashOffset = 0;
					}
				}
				else if (offset % 2 === 1) {
					if (positions[offset].equals(positions[MathUtils.wrap(offset + 1, 0, positions.length)])) {
						positionPrev.copy(positions[offset - 1]);
						positionNext.copy(positions[MathUtils.wrap(offset + 2, 0, positions.length)]);
					}
					else { // Disconnected segments, so just use the current position as the next position.
						positionPrev.copy(positions[offset - 1]);
						positionNext.copy(positions[offset]);
					}
					segment.sub(positions[offset], positions[offset - 1]);
					dashOffset += segment.magnitude();
				}
				array[i * 2 * LineMesh._floatsPerVertex + 3] = positionPrev.x;
				array[i * 2 * LineMesh._floatsPerVertex + 4] = positionPrev.y;
				array[i * 2 * LineMesh._floatsPerVertex + 5] = positionPrev.z;
				array[(i * 2 + 1) * LineMesh._floatsPerVertex + 3] = positionPrev.x;
				array[(i * 2 + 1) * LineMesh._floatsPerVertex + 4] = positionPrev.y;
				array[(i * 2 + 1) * LineMesh._floatsPerVertex + 5] = positionPrev.z;
				array[i * 2 * LineMesh._floatsPerVertex + 6] = positionNext.x;
				array[i * 2 * LineMesh._floatsPerVertex + 7] = positionNext.y;
				array[i * 2 * LineMesh._floatsPerVertex + 8] = positionNext.z;
				array[(i * 2 + 1) * LineMesh._floatsPerVertex + 6] = positionNext.x;
				array[(i * 2 + 1) * LineMesh._floatsPerVertex + 7] = positionNext.y;
				array[(i * 2 + 1) * LineMesh._floatsPerVertex + 8] = positionNext.z;

				array[i * 2 * LineMesh._floatsPerVertex + 14] = dashOffset;
				array[(i * 2 + 1) * LineMesh._floatsPerVertex + 14] = dashOffset;
			}
			attribute.data.needsUpdate = true;
		}
		Vector3.pool.release(tangent);
		Vector3.pool.release(normal);
		Vector3.pool.release(positionPrev);
		Vector3.pool.release(positionNext);
		Vector3.pool.release(segment);
	}

	/**
	 * Sets the colors. Each two colors is a line segment.
	 * @param {Color[]} colors
	 */
	setColors(colors) {
		if (colors.length % 2 !== 0) {
			throw new Error('Number of colors in the LineMesh must be even.');
		}
		// Adjust the number of vertices. * 2 is because the line has width.
		this._adjustVerticesInGeometries(colors.length * 2);
		for (let objectIndex = 0; objectIndex < this._threeJsObjects.length; objectIndex++) {
			const attribute = /** @type {THREE.InterleavedBufferAttribute} */(this._threeJsObjects[objectIndex].geometry.getAttribute('color'));
			const array = attribute.array;
			for (let i = 0; i < array.length / (2 * LineMesh._floatsPerVertex); i++) {
				const offset = i + objectIndex * (LineMesh._verticesPerGeometry / 2);
				array[i * 2 * LineMesh._floatsPerVertex + 9] = colors[offset].r;
				array[i * 2 * LineMesh._floatsPerVertex + 10] = colors[offset].g;
				array[i * 2 * LineMesh._floatsPerVertex + 11] = colors[offset].b;
				array[i * 2 * LineMesh._floatsPerVertex + 12] = colors[offset].a;
				array[(i * 2 + 1) * LineMesh._floatsPerVertex + 9] = colors[offset].r;
				array[(i * 2 + 1) * LineMesh._floatsPerVertex + 10] = colors[offset].g;
				array[(i * 2 + 1) * LineMesh._floatsPerVertex + 11] = colors[offset].b;
				array[(i * 2 + 1) * LineMesh._floatsPerVertex + 12] = colors[offset].a;
			}
			attribute.data.needsUpdate = true;
		}
	}

	/**
	 * Sets the positions. Each two positions is a line segment.
	 * @param {number[]|number} widths
	 */
	setWidths(widths) {
		if (typeof widths === 'number') {
			for (let objectIndex = 0; objectIndex < this._threeJsObjects.length; objectIndex++) {
				const attribute = /** @type {THREE.InterleavedBufferAttribute} */(this._threeJsObjects[objectIndex].geometry.getAttribute('width'));
				const array = attribute.array;
				for (let i = 0; i < array.length / (2 * LineMesh._floatsPerVertex); i++) {
					array[i * 2 * LineMesh._floatsPerVertex + 13] = widths;
					array[(i * 2 + 1) * LineMesh._floatsPerVertex + 13] = -widths;
				}
				attribute.data.needsUpdate = true;
			}
		}
		else {
			if (widths.length % 2 !== 0) {
				throw new Error('Number of widths in the LineMesh must be even.');
			}
			// Adjust the number of vertices. * 2 is because the line has width.
			this._adjustVerticesInGeometries(widths.length * 2);
			for (let objectIndex = 0; objectIndex < this._threeJsObjects.length; objectIndex++) {
				const attribute = /** @type {THREE.InterleavedBufferAttribute} */(this._threeJsObjects[objectIndex].geometry.getAttribute('width'));
				const array = attribute.array;
				for (let i = 0; i < array.length / (2 * LineMesh._floatsPerVertex); i++) {
					const offset = i + objectIndex * (LineMesh._verticesPerGeometry / 2);
					array[i * 2 * LineMesh._floatsPerVertex + 13] = widths[offset];
					array[(i * 2 + 1) * LineMesh._floatsPerVertex + 13] = -widths[offset];
				}
				attribute.data.needsUpdate = true;
			}
		}
	}

	/**
	 * Sets the line dash and gap length.
	 * @param {number} dashLength
	 * @param {number} dashGapLength
	 */
	setDashLength(dashLength, dashGapLength) {
		this._dashLength = dashLength;
		this._dashGapLength = dashGapLength;
		ThreeJsHelper.setUniformNumber(this._threeJsMaterial, 'dashLength', this._dashLength);
		ThreeJsHelper.setUniformNumber(this._threeJsMaterial, 'dashGapLength', this._dashGapLength);
	}

	/**
	 * Set the width of the glow from full to clear along the edge of the lines.
	 * @param {number} glowWidth
	 */
	setGlowWidth(glowWidth) {
		this._glowWidth = glowWidth;
		ThreeJsHelper.setUniformNumber(this._threeJsMaterial, 'glowWidth', this._glowWidth);
	}

	/**
	 * Sets the scale.
	 * @param {number} scale
	 */
	setScale(scale) {
		this._scale = scale;
		ThreeJsHelper.setScale(this._threeJsObjects, scale);
	}

	/**
	 * Gets the alpha multiplier. This value is multiplied into all line segments as an additional alpha control.
	 * @returns {number}
	 */
	getAlphaMultiplier() {
		return this._alphaMultiplier;
	}

	/**
	 * Sets the alpha multiplier. This value is multiplied into all line segments as an additional alpha control.
	 * @param {number} alphaMultiplier - from 0 to 1
	 */
	setAlphaMultiplier(alphaMultiplier) {
		this._alphaMultiplier = alphaMultiplier;
		ThreeJsHelper.setUniformNumber(this._threeJsMaterial, 'alphaMultiplier', this._alphaMultiplier);
	}

	/**
	 * Prepares the line mesh for rendering. It should be called by every component that uses it.
	 * @param {CameraComponent} camera
	 */
	prepareForRender(camera) {
		const viewportSize = Vector2.pool.get();

		// If the camera is a Spout camera, make the lines thicker and use Spout for the render size.
		if (camera.getType() === 'spout') {
			const spoutComponent = /** @type {SpoutComponent} */(camera);
			viewportSize.set(spoutComponent.getRenderWidth() * 0.1, spoutComponent.getRenderWidth() * 0.5 * 0.1);
		}
		// Otherwise use the viewport size.
		else {
			viewportSize.copy(camera.getViewport().getBounds().size);
		}

		// Set the pixel render size.
		ThreeJsHelper.setUniformVector2(this._threeJsMaterial, 'viewportSize', viewportSize);
		Vector2.pool.release(viewportSize);
	}

	/**
	 * Updates the number of Three.js objects and vertices if they have changed.
	 * @param {number} numVertices
	 * @private
	 */
	_adjustVerticesInGeometries(numVertices) {
		// The number of objects and meshes we'll be needing.
		// Each object can hold up to _verticesPerGeometry vertices,
		//   due to WebGL 1.0 limitations on the the bit size of index buffers (max 16 bits).
		const numThreeJsObjects = Math.ceil(numVertices / LineMesh._verticesPerGeometry);
		// Remove any excess objects that are no longer needed because there are now less vertices than before.
		while (this._threeJsObjects.length > numThreeJsObjects) {
			const objectToRemove = this._threeJsObjects[this._threeJsObjects.length - 1];
			ThreeJsHelper.destroyObject(objectToRemove);
			this._threeJsObjects.splice(this._threeJsObjects.length - 1, 1);
			// Remove it from the base component's list.
			for (let i = 0, l = this._component.getThreeJsObjects().length; i < l; i++) {
				if (this._component.getThreeJsObjects()[i] === objectToRemove) {
					this._component.getThreeJsObjects().splice(i, 1);
					break;
				}
			}
		}
		// Add any new objects that are needed.
		if (this._threeJsObjects.length < numThreeJsObjects) {
			// Update the current last geometry to have full vertices.
			if (this._threeJsObjects.length > 0) {
				this._setupThreeJsGeometry(this._threeJsObjects[this._threeJsObjects.length - 1].geometry, LineMesh._verticesPerGeometry);
			}
			// Add the new objects.
			for (let i = this._threeJsObjects.length; i < numThreeJsObjects; i++) {
				const objectToAdd = ThreeJsHelper.createMeshObject(this._component, this._threeJsMaterial, [], false);
				this._threeJsObjects.push(objectToAdd);
				this._component.getThreeJsObjects().push(objectToAdd);
				const numVerticesInGeometry = Math.min(numVertices - i * LineMesh._verticesPerGeometry, LineMesh._verticesPerGeometry);
				this._setupThreeJsGeometry(objectToAdd.geometry, numVerticesInGeometry);
				ThreeJsHelper.setPosition(objectToAdd, this._position);
				ThreeJsHelper.setOrientation(objectToAdd, this._orientation);
				ThreeJsHelper.setScale(objectToAdd, this._scale);
				objectToAdd.frustumCulled = false;
			}
		}
		// There were no new objects needed, so just adjust the vertices of the last mesh.
		else if (numThreeJsObjects > 0) {
			// If we've got a different number of vertices, we need to update the Three.js geometry.
			const geometry = this._threeJsObjects[numThreeJsObjects - 1].geometry;
			const numVerticesInLastGeometry = numVertices - (numThreeJsObjects - 1) * LineMesh._verticesPerGeometry;
			if (geometry.getAttribute('position').array.length !== numVerticesInLastGeometry * LineMesh._floatsPerVertex) {
				this._setupThreeJsGeometry(geometry, numVerticesInLastGeometry);
			}
		}
	}

	/**
	 * A helper function to setup the Three.js geometry when a new object is created.
	 * @param {THREE.BufferGeometry} geometry
	 * @param {number} numVertices - The number of vertices to create.
	 * @private
	 */
	_setupThreeJsGeometry(geometry, numVertices) {
		// Setup the interleaved vertex buffer.
		const vertices = new Float32Array(numVertices * LineMesh._floatsPerVertex);
		const buffer = new THREE.InterleavedBuffer(vertices, LineMesh._floatsPerVertex);
		geometry.setAttribute('position', new THREE.InterleavedBufferAttribute(buffer, 3, 0, false));
		geometry.setAttribute('positionPrev', new THREE.InterleavedBufferAttribute(buffer, 3, 3, false));
		geometry.setAttribute('positionNext', new THREE.InterleavedBufferAttribute(buffer, 3, 6, false));
		geometry.setAttribute('color', new THREE.InterleavedBufferAttribute(buffer, 4, 9, false));
		geometry.setAttribute('width', new THREE.InterleavedBufferAttribute(buffer, 1, 13, false));
		geometry.setAttribute('dashOffset', new THREE.InterleavedBufferAttribute(buffer, 1, 14, false));

		// Setup the index buffer.
		const meshIndices = new Uint16Array(numVertices * 6 / 4);
		for (let j = 0; j < numVertices / 4; j++) {
			meshIndices[j * 6 + 0] = j * 4;
			meshIndices[j * 6 + 1] = j * 4 + 2;
			meshIndices[j * 6 + 2] = j * 4 + 3;
			meshIndices[j * 6 + 3] = j * 4;
			meshIndices[j * 6 + 4] = j * 4 + 3;
			meshIndices[j * 6 + 5] = j * 4 + 1;
		}
		geometry.setIndex(new THREE.BufferAttribute(meshIndices, 1));
	}
}

LineMesh._floatsPerVertex = 3 + 3 + 3 + 4 + 1 + 1;
LineMesh._verticesPerGeometry = 65536;
