/** @module pioneer */

/**
 * A dependency graph. The nodes are the items and the arrows are determined by the comparison function.
 * @template ItemType
 */
export class DependencyGraph {
	constructor() {
		/**
		 * The callback to be called for each item in the dependency graph.
		 * @type {(item: ItemType) => any}
		 * @private
		 */
		this._updateItemCallback = null;

		/**
		 * The callback to ber called when comparing items. True means item a is dependent on item b.
		 * @type {(a: ItemType, b: ItemType) => boolean}
		 * @private
		 */
		this._compareItemCallback = null;

		/**
		 * The list of items in the dependency graph.
		 * @type {Map<ItemType, Node<ItemType>>}
		 * @private
		 */
		this._nodes = new Map();

		/**
		 * The sorted nodes.
		 * @type {Array<Node<ItemType>>}
		 * @private
		 */
		this._sortedNodes = [];

		/**
		 * A boolean flag that determines of the dependency graph needs sorting.
		 * @type {boolean}
		 * @private
		 */
		this._needsSorting = false;
	}

	/**
	 * Sets the callback to be called for each item in the dependency graph.
	 * @param {(item: ItemType) => any} updateItemCallback
	 */
	setUpdateItemCallback(updateItemCallback) {
		this._updateItemCallback = updateItemCallback;
	}

	/**
	 * Sets the callback to be called for each item in the dependency graph.
	 * @param {(a: ItemType, b: ItemType) => boolean} compareItemCallback
	 */
	setCompareItemCallback(compareItemCallback) {
		this._compareItemCallback = compareItemCallback;
	}

	/**
	 * Adds an item.
	 * @param {ItemType} item
	 */
	addItem(item) {
		/** @type {Node<ItemType>} */
		const node = new Node(item);
		this._nodes.set(item, node);
		this._needsSorting = true;
	}

	/**
	 * Removes an item.
	 * @param {ItemType} item
	 */
	removeItem(item) {
		if (this._nodes.delete(item)) {
			this._needsSorting = true;
		}
	}

	/**
	 * Signals that the graph needs sorting.
	 */
	needsSorting() {
		this._needsSorting = true;
	}

	/**
	 * Sorts the dependency graph and then iterates through through the dependency graph, calling the callback on each item.
	 */
	update() {
		// Do the sorting if we need to do it.
		if (this._needsSorting) {
			// Reset all of the marks.
			for (const node of this._nodes.values()) {
				node.permanentMark = false;
				node.temporaryMark = false;
			}
			this._sortedNodes = [];

			// Sort the items using the depth-first algorithm.
			const iterator = this._nodes.values();
			do {
				const result = iterator.next();
				if (result.done) {
					break;
				}
				const keepGoing = this._visit(result.value);
				if (!keepGoing) {
					break;
				}
			} while (true);

			// It's sorted!
			this._needsSorting = false;
		}

		// Do the update on the items.
		for (let i = 0, l = this._sortedNodes.length; i < l; i++) {
			this._updateItemCallback(this._sortedNodes[i].item);
		}
	}

	/**
	 * The visit function in the depth-first sort algorithm.
	 * @param {Node<ItemType>} node
	 * @returns {boolean}
	 * @private
	 */
	_visit(node) {
		if (node.permanentMark) {
			return true;
		}
		if (node.temporaryMark) {
			throw new Error('Dependency cycle in graph: ' + node.item);
		}
		node.temporaryMark = true;
		for (const aNode of this._nodes.values()) {
			let nodeDependsOn = false;
			if (this._compareItemCallback) {
				nodeDependsOn = this._compareItemCallback(node.item, aNode.item);
			}
			if (nodeDependsOn) {
				try {
					this._visit(aNode);
				}
				catch (error) {
					if (error instanceof Error) {
						error.message = `${error.message} ← ${node.item}`;
					}
					throw error;
				}
			}
		}
		node.temporaryMark = false;
		node.permanentMark = true;
		this._sortedNodes.push(node);
		return true;
	}
}

/**
 * The node in the graph.
 * @template ItemType
 * @private
 */
class Node {
	/**
	 * Constructor.
	 * @param {ItemType} item
	 */
	constructor(item) {
		/**
		 * The item.
		 * @type {ItemType}
		 */
		this.item = item;

		/**
		 * A mark to help with depth-first topological sorting algorithm.
		 * @type {boolean}
		 */
		this.permanentMark = false;

		/**
		 * A mark to help with depth-first topological sorting algorithm.
		 * @type {boolean}
		 */
		this.temporaryMark = false;
	}
}
