import { useEffect, useRef } from 'react';
import * as Pioneer from 'pioneer';

import globalRefs from '../../managers/globalRefs';
import { useSnapshot } from 'valtio';
import { appStore, layersStore, spoutStore } from '../../managers/globalState';
import PLACEMARK_DATA from '../../data/placemarks';

const TARGET_ENTITY_NAME = 'earth';
const GLOBE_DISTANCE = 12000;

const enableSpout = () => {
	const { pioneer } = globalRefs;
	const scene = pioneer.get('main');

	const {
		spoutRenderWidth: RENDER_WIDTH,
		spoutGlobe: IS_GLOBE,
		spoutLonAngleOffset: LON_ANGLE_OFFSET,
		spoutAlignToNorthPole: ALIGN_TO_NORTH_POLE
	} = spoutStore.stateSnapshot;

	// Setup the viewport as a invisible.
	const spoutViewport = pioneer.getViewport('spoutViewport') || pioneer.addViewport('spoutViewport');
	spoutViewport.getDiv().style.display = 'none';
	spoutViewport.getDiv().style.width = '100%';
	spoutViewport.getDiv().style.height = '100%';

	// Enable this to see the spout render.
	// spoutViewport.getDiv().style.display = '';
	// spoutViewport.getDiv().style.height = '50%';

	// Get the entity that will be the center for the globe spout.
	const targetEntity = IS_GLOBE === true ? scene.getEntity(TARGET_ENTITY_NAME) : null;

	// Setup the Spout camera entity. If it's a globe, put a new entity at the target. Otherwise, just use the existing camera entity.
	let spoutCamera;
	if (IS_GLOBE === true) {
		spoutCamera = scene.getEntity('spoutCamera') || scene.addEntity('spoutCamera');
		spoutCamera.clearControllers();
		spoutCamera.setParent(targetEntity);
		spoutCamera.setPosition(Pioneer.Vector3.Zero);
		spoutCamera.setOrientation(Pioneer.Quaternion.Identity);
		const align = spoutCamera.addControllerByClass(Pioneer.AlignController);
		align.setPrimaryAlignType('align');
		align.setPrimaryAxis(Pioneer.Vector3.ZAxis);
		align.setPrimaryTargetAxis(Pioneer.Vector3.ZAxis);
		if (ALIGN_TO_NORTH_POLE) {
			align.setPrimaryTargetEntity(TARGET_ENTITY_NAME);
		} else {
			align.setPrimaryTargetEntity('camera');
		}
		const xAxis = new Pioneer.Vector3();
		Pioneer.Geometry.getXYZFromLLAOnSphere(xAxis, new Pioneer.LatLonAlt(0, Pioneer.MathUtils.degToRad(180 + LON_ANGLE_OFFSET), 1), 1);
		align.setSecondaryAlignType('align');
		align.setSecondaryAxis(Pioneer.Vector3.XAxis);
		align.setSecondaryTargetEntity('camera');
		align.setSecondaryTargetAxis(xAxis);
	} else {
		spoutCamera = scene.getEntity('camera');
	}

	// Add Spout component.
	const spoutComponent = spoutCamera.getComponentByClass(Pioneer.SpoutComponent) ?? spoutCamera.addComponentByClass(Pioneer.SpoutComponent);
	spoutViewport.setCamera(spoutComponent); // This causes 2 computeBoundingSphere errors.
	spoutComponent.setRenderWidth(RENDER_WIDTH);

	if (IS_GLOBE === true) {
		spoutComponent.setForGlobe(true, GLOBE_DISTANCE || targetEntity.getExtentsRadius() * 2);
		spoutComponent.setNearDistance(targetEntity.getExtentsRadius() * 0.5);
		targetEntity.get('atmosphere')?.setExcludedFromCamera(spoutComponent, true);
	}
};

const setSpoutLabels = () => {
	const { pioneer } = globalRefs;
	const scene = pioneer.get('main');
	const { spoutFontSize: FONT_SIZE, spoutGlobe } = spoutStore.stateSnapshot;

	// Turn divs into labels.
	for (let i = 0, l = scene.getNumEntities(); i < l; i++) {
		const entity = scene.getEntity(i);
		const divComponent = entity.get('div');

		// Convert divs to labels because divs only render on HTML, not in WebGL.
		if (divComponent instanceof Pioneer.DivComponent) {
			const isEventPlacemark = divComponent.getDiv().id.includes('eventPlacemark');
			const isCityPlacemark = Object.keys(PLACEMARK_DATA).includes(divComponent.getDiv().id);
			const label = entity.getComponentByClass(Pioneer.LabelComponent) ?? entity.addComponentByClass(Pioneer.LabelComponent);
			const labelText = divComponent.getDiv().innerText;

			if (isCityPlacemark) {
				const sprite = entity.getComponentByClass(Pioneer.SpriteComponent) ?? entity.addComponentByClass(Pioneer.SpriteComponent, `${Object.keys(PLACEMARK_DATA)[i]}`);
				sprite.setBillboard(true);
				sprite.setTextureUrl('assets/images/map_marker.png');
				sprite.setAlignment(new Pioneer.Vector2(0.5, 1.5));

				// Removes the black background of the map marker svg
				sprite.setTransparent(true);
				sprite.setSizeUnits('km');
				const spriteSpize = new Pioneer.Vector2(spoutGlobe === true ? 500 : 100, spoutGlobe === true ? 400 : 300);
				sprite.setSize(spriteSpize);

				// Don't show giant sprites in regular screen
				sprite.setExcludedFromCamera(scene.get('camera', 'camera'), true);
			}

			const eventPlacemarkRed = new Pioneer.Color(0.86, 0.2, 0.27, 1);

			label.setText(isEventPlacemark ? '•' : labelText || '');
			label.setColor(isEventPlacemark ? eventPlacemarkRed : new Pioneer.Color(1, 1, 1, 1));
			label.setFontSize(isEventPlacemark ? FONT_SIZE * 1.25 : FONT_SIZE);
			label.setAlignment(new Pioneer.Vector2(isCityPlacemark ? 0.2 : 0, isEventPlacemark ? 0.65 : 0.5));
			label.setPixelOffset(new Pioneer.Vector2(-FONT_SIZE / 4, -1));
			label.setExcludedFromCamera(scene.get('camera', 'camera'), true);

			// Have regular divs so you can click on the labels
			divComponent.getDiv().style.setProperty('--spoutFontSize', `${isEventPlacemark ? FONT_SIZE * 2.5 : FONT_SIZE}px`);
		}
	}
};


/**
 * SpoutMaster component.
 *
 * Currently, spout is a one-way street. It's enabled when the scene is ready and then it's never disabled.
 */
const SpoutMaster = () => {
	const { pioneer } = globalRefs;
	const scene = pioneer.get('main');

	const { isSceneReady } = useSnapshot(appStore.state);
	const { spout, spoutGlobe } = useSnapshot(spoutStore.state);
	const { cityNames } = useSnapshot(layersStore.state);
	const spoutEnabled = useRef(false);

	useEffect(() => {
		// Block city names from being visible through the globe
		if (cityNames === true) {
			scene.getEntity('placemarks')?.setCanOcclude(true);
		}
	}, [scene, cityNames]);

	// useEffect to enable spout on when scene is ready.
	useEffect(() => {
		// Make sure spout is only enabled once.
		if (isSceneReady && spout === true && !spoutEnabled.current) {
			spoutEnabled.current = true;
			enableSpout();
			setSpoutLabels();

			// If project on globe, set ambient color
			if (spoutGlobe === true) {
				scene.setAmbientLightColor(new Pioneer.Color(1, 1, 1));
			}
		}
	}, [scene, isSceneReady, spout, spoutGlobe]);
};


export default SpoutMaster;
