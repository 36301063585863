import React from 'react';
import moment from 'moment-hijri';
import Popover from 'react-tiny-popover';

class HijriDateChooser extends React.Component {
	/**
	 * Constructs the component.
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);
		const selected = this.props.selected ? moment.utc(this.formatDate(this.props.selected), 'YYYY-MM-DD') : moment();
		const selected_val = selected.locale('en').format('iYYYY/iMM/iDD');
		this.state = {
			current: selected,
			selected,
			selected_val,
			open: false
		};

		this.arabicDayNames = [
			'ح',
			'ن',
			'ث',
			'ر',
			'خ',
			'ج',
			'س'
		];
	}

	formatDate(date) {
		return date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : '';
	}

	validDate(date) {
		if (date) {
			if (Object.prototype.toString.call(date) === '[object Date]') {
				if (isNaN(date.getTime())) {
					return false;
				} else {
					return true;
				}
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	toggleOpen(to) {
		let open;
		if (typeof (to) === 'boolean') {
			open = to;
		} else {
			open = !this.state.open;
		}
		this.setState({ open, current: moment(this.state.selected) });
	}

	prevMonth(e) {
    e.stopPropagation();
		this.setState({ current: this.state.current.subtract(1, 'iMonth') });
	}

	nextMonth(e) {
    e.stopPropagation();
		this.setState({ current: this.state.current.add(1, 'iMonth') });
	}

	selectDay(e) {
		e.preventDefault();
    e.stopPropagation();
		const data = e.currentTarget.dataset;
		const selected = moment.utc(data.endate);
		this.setState({ selected, open: false, selected_val: data.ardate }, () => {
			if (this.props.on_change) {
				this.props.on_change(selected, this.props.input_name == 'ar-startInput');
			}
		});
	}

	getDays() {
		const days = [];
		const first_day = this.state.current.clone().startOf('iMonth');
		const first_day_of_week = parseInt(first_day.locale('en').format('d')) + 1;
		const days_in_month = first_day.locale('en').iDaysInMonth();
		const current_day = first_day.clone();
		const min_date = this.validDate(this.props.min) ? moment(this.formatDate(this.props.min), 'YYYY-MM-DD') : moment.utc('1900-01-01', 'YYYY-MM-DD');
		const max_date = this.validDate(this.props.min) ? moment(this.formatDate(this.props.max), 'YYYY-MM-DD') : moment.utc('3000-01-01', 'YYYY-MM-DD');

		const now = moment();
		for (let i = 1; i <= days_in_month; i++) {
			const is_today = current_day.isSame(now, 'day');
			const is_valid = current_day.isAfter(min_date, 'day') && current_day.isBefore(max_date, 'day');
			const is_selected = current_day.isSame(this.state.selected, 'day');
			// first day is special cuz pushed in to place
			const day = (
				<a
					key={`d_${current_day.format('iD')}`} data-endate={current_day.locale('en').format('YYYY-MM-DD')} data-ardate={current_day.locale('en').format('iYYYY/iMM/iDD')} className={`day ${i == 1 ? 'calendar-span-' + first_day_of_week : ''} ${is_today ? 'today' : ''} ${is_valid ? 'valid' : 'invalid'} ${is_selected ? 'selected' : ''}`}
					onClick={e => { if (is_valid) { this.selectDay(e); } }}
				>
					{i == 1 ? (<span className='day-block-inner'><span className='number'>{i}</span></span>) : <span className='number'>{i}</span>}
				</a>
			);

			days.push(day);
			current_day.add(1, 'day');
		}

		return days;
	}

	getCalendar() {
		const days = this.getDays();
		const loc = 'ar-SA';
		return (
			<section className='hijri-date-chooser'>
				<header className='controls'>
					<button className='prev' onClick={(e) => { this.prevMonth(e); }}>prev</button>
					<div className='title'>{this.state.current.locale(loc).format('iMMMM') + ' (' + this.state.current.locale(loc).format('iMM') + ') ' + this.state.current.locale(loc).format('iYYYY')}</div>
					<button className='next' onClick={(e) => { this.nextMonth(e); }}>next</button>
				</header>

				<div className='days-of-week'>
					{this.arabicDayNames.map((v, i) => <div className='dotw' key={`dotw_${i}`}>{v}</div>)}
				</div>
				<div className='days-of-month'>
					{this.getDays()}
				</div>
			</section>
		);
	}

	updateDate(e) {
		console.log('do stuff with new date');
	}

	render() {
		return (
			<div>
				<Popover isOpen={this.state.open} content={this.getCalendar()} position={['top']} onClickOutside={() => this.toggleOpen(false)} containerClassName={`popover_${this.props.input_name}`}>
					
						<div  onClick={() => this.toggleOpen()}>
							<input id={this.props.input_name} value={this.state.selected_val} onChange={this.updateDate} />
							<button type='button'>
								<div className='image' />
							</button>
						</div>
					
				</Popover>
			</div>
		);
	}
}

export default HijriDateChooser;
