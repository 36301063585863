import React, { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';

import { eventsStore, datasetStore, videoStore, uiStore, appStore, spacecraftStore, previewStore, poiStore, gmapStore, stellarStore} from '../managers/globalState';
import globalRefs from '../managers/globalRefs';

import LeftContent from './left-content';


const DetailPanelLeft = () => {
	const { getManager } = globalRefs;
	const { isDetailPanelOpen, hideExternalLinks: hideExternalLinksFromURL } = useSnapshot(uiStore.state);
	const { isMobile } = useSnapshot(appStore.state);
	const { currentEvent, eventsVisibleOnGlobe } = useSnapshot(eventsStore.state);
	const { currentPoi, showOnGlobePoI } = useSnapshot(poiStore.state);
	const { currentGmap, showOnGlobeGmap } = useSnapshot(gmapStore.state);
	const { currentStellar} = useSnapshot(stellarStore.state);
	const { previewEventData } = useSnapshot(previewStore.state);
	const { currentDataset, currentVS, currentIndex } = useSnapshot(datasetStore.state);
	const { currentVideo, isVideoTexture } = useSnapshot(videoStore.state);
	const { currentSpacecraft, isSatellitesNow } = useSnapshot(spacecraftStore.state);

	const hideExternalLinks = hideExternalLinksFromURL === 'true';

	const [displayData, setDisplayData] = useState(null);

	// useEffect to update the state when the currentDataset changes.
	useEffect(() => {
		const { getManager } = globalRefs;
		const contentManager = getManager('content');
		// console.log("DetailPanelLeft", currentStellar)
		let displayData;
		if (currentPoi){
			displayData = contentManager.getPoiDisplayData()
		} else if (currentGmap) {
			displayData = contentManager.getGmapDisplayData()
		}  else if (currentStellar) {
			displayData = contentManager.getStellarDisplayData()
		} else if (currentEvent) {
			displayData = contentManager.getEventDisplayData();
		} else if (previewEventData) {
			displayData = contentManager.getEventDisplayData({ preview: true });
		} else if (currentSpacecraft) {
			displayData = contentManager.getSpacecraftDisplayData();
		} else if (currentVideo && isVideoTexture) {
			displayData = contentManager.getVideoDisplayData();
		} else if (isSatellitesNow) {
			displayData = contentManager.getSatellitesNowDisplayData();
		} else if (currentDataset) {
			displayData = contentManager.getDatasetDisplayData();
		} 

		// set the displayData state.
		displayData && setDisplayData(displayData);
	}, [
		isSatellitesNow,
		currentVS,
		currentDataset,
		currentEvent,
		previewEventData,
		currentSpacecraft,
		currentVideo,
		isVideoTexture,
		currentIndex,
		currentPoi,
		currentGmap,
		currentStellar
	]);

	const { category, title, subtitle, descriptions } = displayData || {};
	const markersVisible = eventsVisibleOnGlobe || showOnGlobeGmap || showOnGlobePoI
	return (
		<div
			className='left-content'
			style={ markersVisible ? { marginBottom: 10 } : {}}
		>
			<div className={isDetailPanelOpen ? 'pl-0 pl-lg-4' : `${isMobile ? '' : 'pl-3 mb-3'} mb-lg-0 pb-lg-3`}>
				{ displayData && <LeftContent category={category} title={title} subtitle={subtitle} /> }

				<div className='info scrollable'>
					{descriptions?.map((description, index) => (
						<React.Fragment key={index}>
							<h3 className='section-title pt-lg-3'>
								{description.title}
							</h3>
							{isVideoTexture && isMobile && (
								<div className='tab-subtitle'>{description.subtitle}</div>
							)}
							{description.html
								? (
									<div
										className='text pb-1'
										dangerouslySetInnerHTML={{
											__html: hideExternalLinks
												? getManager('content').removeExternalLinks(description.html) :
												description.html
										}}
									/>
								)
								: (
									<div
										className='text pb-1'
										dangerouslySetInnerHTML={{
											__html: hideExternalLinks
												? getManager('content').removeExternalLinks(description.text) :
												description.text
										}}
									/>
								)}
						</React.Fragment>
					))}
				</div>
			</div>
		</div>
	);
};

export default DetailPanelLeft;
