/** @module pioneer */
import {
	BaseController,
	Entity,
	Interval
} from '../../internal';

/**
 * A controller that calls enter and functions based on a coverage.
 */
export class CoverageController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The time interval over which the coverage is active.
		 * @type {Interval}
		 * @private
		 */
		this.coverage = new Interval();

		/**
		 * The function called when the time enters the coverage.
		 * @type {((entity: Entity) => any) | undefined}
		 * @private
		 */
		this.enterFunction = undefined;

		/**
		 * The function called when the time exits the coverage.
		 * @type {((entity: Entity) => any) | undefined}
		 * @private
		 */
		this.exitFunction = undefined;

		/**
		 * The function called when the time is inside the coverage, every frame.
		 * @type {((entity: Entity) => any) | undefined}
		 * @private
		 */
		this.updateFunction = undefined;

		/**
		 * Flag determining if the coverage is active or not.
		 * @type {boolean}
		 * @private
		 */
		this.active = false;

		/**
		 * The last real time the update function ran.
		 * @type {number}
		 * @private
		 */
		this.lastUpdateTime = Number.NEGATIVE_INFINITY;

		/**
		 * How often to run the update function, in milliseconds. 0 means run every frame.
		 * @type {number}
		 * @private
		 */
		this.updateInterval = 0;
	}

	/**
	 * Sets the coverage.
	 * @param {Interval} coverage
	 * @override
	 */
	setCoverage(coverage) {
		this.coverage.copy(coverage);
	}

	/**
	 * Sets the enter function. This function will be triggered once when the time enters the coverage.
	 * @param {((entity: Entity) => any) | undefined} enterFunction
	 */
	setEnterFunction(enterFunction) {
		this.enterFunction = enterFunction;
	}

	/**
	 * Sets the exit function. This function will be triggered once when the time exits the coverage.
	 * @param {((entity: Entity) => any) | undefined} exitFunction
	 */
	setExitFunction(exitFunction) {
		this.exitFunction = exitFunction;
	}

	/**
	 * Sets the update function. This function will be triggered every frame that the time is within the coverage.
	 * @param {((entity: Entity) => any) | undefined} updateFunction
	 */
	setUpdateFunction(updateFunction) {
		this.updateFunction = updateFunction;
	}

	/**
	 * Sets how often to run the update function, in seconds. The default 0 means run every frame.
	 * @param {number} updateInterval
	 */
	setUpdateInterval(updateInterval) {
		this.updateInterval = updateInterval * 1000;
	}

	/**
	 * Updates the position and orientation if they are fixed.
	 * @override
	 * @internal
	 */
	__update() {
		const time = this.getEntity().getScene().getEngine().getTime();

		// If this is the first coverage controller, we'll trigger all of the exit functions() necessary.
		if (this.getTypeIndex() === 0) {
			for (let i = 0; ; i++) {
				const coverageController = this.getEntity().getControllerByClass(CoverageController, i);
				if (coverageController === null) {
					break;
				}
				const newActive = coverageController.coverage.contains(time);
				if (coverageController.active && !newActive) {
					if (coverageController.exitFunction) {
						coverageController.exitFunction(this.getEntity());
					}
				}
			}
		}

		// Now call the enter function.
		const newActive = this.coverage.contains(time);
		if (!this.active && newActive) {
			if (this.enterFunction) {
				this.enterFunction(this.getEntity());
			}
		}

		// Call the update function.
		if (newActive && this.updateFunction) {
			const now = Date.now();
			if (now - this.lastUpdateTime >= this.updateInterval) {
				this.lastUpdateTime = now;
				this.updateFunction(this.getEntity());
			}
		}
		this.active = newActive;
	}
}
