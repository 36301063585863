/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';

/**
 * @typedef Options
 * @property {string[]} [groups]
 * @property {string} [label]
 * @property {string} [labelFadeEntity] - The entity that when this gets close to it, the label fades.
 * @property {number} [occlusionRadius]
 * @property {number} [extentsRadius]
 * @property {number} [radius] - This applies to occlusion and extents radii.
 * @property {number} [systemRadius] - The radius within which all children reside.
 * @property {[number, string][]} parents
 * @property {string[]} [dependents]
 * @property {string} [lightSource]
 * @property {TrailOptions} [trail]
 * @property {SpheroidOptions} [spheroid]
 * @property {SpheroidLODOptions} [spheroidLOD]
 * @property {CMTSOptions} [cmts]
 * @property {ModelOptions} [model]
 * @property {CometOptions} [comet]
 * @property {ControllersOptions[]} [controllers]
 * @property {(entity: Pioneer.Entity, extraOptions?: ExtraOptions) => void} [postCreateFunction]
 */

/**
 * @typedef ExtraOptions
 * @property {string} [namePrefix] - A prefix that will be prepended to the name of the entity.
 * @property {string} [nameSuffix] - A suffix that will be appended to the name of the entity.
 * @property {boolean} [milkyWaySprite] - Whether or not to use the milky way sprite.
 * @property {boolean} [skybox] - A skybox that is used instead of the stars.
 * @property {number} [skyboxResolution] - The resolution of the skybox.
 * @property {boolean} [starfield] - A starfield that is used instead of the skybox.
 * @property {boolean} [heliosphere] - Whether or not the sun has a heliosphere.
 * @property {boolean} [clouds] - Whether the earth has clouds or not.
 * @private
 */

/**
 * @typedef {FixedOptions | SpinOptions | DynamoOptions | AnimdataOptions | AlignOptions | RotateByEntityOrientation | CoverageOptions | CustomControllerOptions | OrbitalElementsOptions} ControllersOptions
 */

/**
 * @typedef TrailOptions
 * @property {string} [name]
 * @property {number | undefined} length
 * @property {[number, number, number, number]} [color]
 * @property {string} [relativeTo]
 * @property {boolean} [updatePointPositions]
 * @property {[number, number, number][]} [lengthCoverages]
 */

/**
 * @typedef SpheroidOptions
 * @property {string} [name]
 * @property {number} equatorialRadius
 * @property {number} polarRadius
 * @property {boolean} planetographic
 */

/**
 * @typedef SpheroidLODOptions
 * @property {string} [name]
 * @property {string[]} [features]
 * @property {Object.<string, SpheroidTextureOptions>} [textures]
 * @property {string[]} [shadowEntities]
 */

/**
 * @typedef SpheroidTextureOptions
 * @property {string} url
 * @property {number[]} sizes
 */

/**
 * @typedef CMTSOptions
 * @property {string} [name]
 * @property {Object.<string, string>} textures
 * @property {string[]} [shadowEntities]
 * @property {number} [maxLevel]
 */

/**
 * @typedef ModelOptions
 * @property {string} [name]
 * @property {string} url
 * @property {ModelRotateOptions[]} [rotate]
 * @property {[number, number, number] | number} [scale]
 * @property {string[]} [shadowEntities]
 * @property {ModelEnvironmentMapOptions} [environmentMap]
 * @property {boolean} [useCompressedTextures]
 */

/**
 * @typedef ModelRotateOptions
 * @property {number} [x]
 * @property {number} [y]
 * @property {number} [z]
 */

/**
 * @typedef ModelEnvironmentMapOptions
 * @property {string} [cubemap]
 * @property {string} [cylindrical]
 */

/**
 * @typedef CometOptions
 * @property {string} [name]
 * @property {number} [timeLength]
 */

/**
 * @typedef FixedOptions
 * @property {'fixed'} type
 * @property {string} [name]
 * @property {Pioneer.Vector3} [position]
 * @property {Pioneer.Quaternion} [orientation]
 * @property {string} [relativeToEntity]
 * @property {Pioneer.LatLonAlt} [llaOnSpheroid]
 * @property {string} [llaOnSpheroidEntity]
 * @property {[number, number]} [coverage]
 */

/**
 * @typedef SpinOptions
 * @property {'spin'} type
 * @property {string} [name]
 * @property {Pioneer.Vector3} axis
 * @property {boolean} [axisInFrameSpace]
 * @property {number} periodInHours
 * @property {number} [relativeToTime]
 * @property {[number, number]} [coverage]
 */

/**
 * @typedef DynamoOptions
 * @property {'dynamo'} type
 * @property {string} [name]
 * @property {string} url
 * @property {boolean} [parentIsBarycenter]
 * @property {boolean} [customUrl]
 * @property {[number, number]} [coverage]
 */

/**
 * @typedef AnimdataOptions
 * @property {'animdata'} type
 * @property {string} [name]
 * @property {string} url
 * @property {string} dataType
 * @property {[number, number]} [coverage]
 */

/**
 * @typedef AlignOptions
 * @property {'align'} type
 * @property {string} [name]
 * @property {AlignAxisOptions} primary
 * @property {AlignAxisOptions} [secondary]
 * @property {[number, number]} [coverage]
 */

/**
 * @typedef AlignAxisOptions
 * @property {string} type
 * @property {string} target
 * @property {Pioneer.Vector3} axis
 * @property {Pioneer.Vector3} [targetAxis]
 */

/**
 * @typedef RotateByEntityOrientation
 * @property {'rotateByEntityOrientation'} type
 * @property {string} [name]
 * @property {string} [entityForOrientation]
 * @property {boolean} [rotatingOrientation]
 * @property {boolean} [rotatingPosition]
 * @property {[number, number]} [coverage]
 */

/**
 * @typedef OrbitalElementsOptions
 * @property {'orbitalElements'} type
 * @property {string} [name]
 * @property {number} [epoch]
 * @property {number} eccentricity Can be calculated from `e = 1 - 2 / (apoapsis / periapsis + 1)`.
 * @property {number} semiMajorAxis Can be calculated from `a = periapsis / (1 - e)`. In km.
 * @property {number} meanAngularMotion Can be calculated from `2 * PI / period`. In rad / s.
 * @property {number} meanAnomalyAtEpoch The mean angle at the epoch time. In rad.
 * @property {Pioneer.Quaternion} [orbitOrientation]
 * @property {number} [inclination]
 * @property {number} [longitudeOfAscendingNode]
 * @property {number} [argumentOfPeriapsis]
 * @property {[number, number]} [coverage]
 */

/**
 * @typedef CoverageOptions
 * @property {'coverage'} type
 * @property {string} [name]
 * @property {[number, number]} coverage
 * @property {(entity: Pioneer.Entity) => void} [enter]
 * @property {(entity: Pioneer.Entity) => void} [exit]
 * @property {(entity: Pioneer.Entity) => void} [update]
 * @property {number} [updateInterval]
 */

/**
 * @typedef CustomControllerOptions
 * @property {'custom'} type
 * @property {(entity: Pioneer.Entity) => Pioneer.BaseController} func
 * @property {[number, number]} [coverage]
 */

/**
  * Helpful functions for creating entities.
 * @hideconstructor
  */
export class Entity {
	/**
	 * Every script that imports this needs to register its "entity name -> options" object so that the create script can use it.
	 * @param {Object<string, Options>} entities
	 */
	static register(entities) {
		for (const name in entities) {
			if (Object.prototype.hasOwnProperty.call(entities, name)) {
				this._entities.set(name, entities[name]);
			}
		}
	}

	/**
	 * Creates an entity.
	 * @param {string} name
	 * @param {Pioneer.Scene} scene
	 * @param {ExtraOptions} [extraOptions]
	 * @returns {Pioneer.Entity}
	 */
	static create(name, scene, extraOptions) {
		// Get the options for the entity's name.
		const options = Entity._entities.get(name);
		if (options === undefined) {
			throw new Error('Could not find the options for the entity with name ' + name);
		}

		return this.createFromOptions(name, options, scene, extraOptions);
	}

	/**
	 * Create an entity from the given options.
	 * @param {string} name
	 * @param {Options} options
	 * @param {Pioneer.Scene} scene
	 * @param {ExtraOptions} [extraOptions]
	 * @returns {Pioneer.Entity}
	 */
	static createFromOptions(name, options, scene, extraOptions) {
		// Create the actual name given the prefix and suffix options.
		let actualName = name;
		if (extraOptions) {
			if (extraOptions.namePrefix) {
				actualName = extraOptions.namePrefix + actualName;
			}
			if (extraOptions.nameSuffix) {
				actualName = actualName + extraOptions.nameSuffix;
			}
		}

		// Check if entity already exists.
		let entity = scene.getEntity(actualName);
		if (entity !== null) {
			return entity;
		}

		// Create the entity.
		entity = scene.addEntity(actualName);

		// Process the options.
		try {
			if (options.radius) {
				entity.setOcclusionRadius(options.radius);
				entity.setExtentsRadius(options.radius);
			}
			if (options.occlusionRadius) {
				entity.setOcclusionRadius(options.occlusionRadius);
			}
			if (options.extentsRadius) {
				entity.setExtentsRadius(options.extentsRadius);
			}

			// Add the parenting table.
			for (const [startTime, parentName] of options.parents) {
				entity.addParentingTableEntry(startTime, parentName);
			}

			if (options.label) {
				const component = entity.addComponentByClass(Pioneer.DivComponent);
				const div = component.getDiv();
				div.innerHTML = options.label;
				div.className = 'pioneer-label-div';
			}

			if (options.labelFadeEntity) {
				const divComponent = entity.getComponentByClass(Pioneer.DivComponent);
				if (divComponent === null) {
					throw new Error('There is no label.');
				}
				divComponent.setFadeWhenCloseToEntity(options.labelFadeEntity);
			}

			if (options.trail) {
				const component = entity.addComponentByClass(Pioneer.TrailComponent, options.trail.name);
				component.setStartTime(options.trail.length);
				if (options.trail.color) {
					component.setColor(new Pioneer.Color(options.trail.color[0], options.trail.color[1], options.trail.color[2], options.trail.color[3]));
				}
				else {
					component.setColor(new Pioneer.Color(1, 1, 1, 0.5));
				}
				if (options.trail.relativeTo) {
					component.setRelativeToEntity(options.trail.relativeTo);
				}
				if (options.trail.updatePointPositions) {
					component.setUpdatePointPositions(true);
				}
				if (options.trail.lengthCoverages) {
					const trailLength = options.trail.length;
					for (let i = 0, l = options.trail.lengthCoverages.length; i < l; i++) {
						const lengthCoverage = options.trail.lengthCoverages[i];
						const coverageController = entity.addControllerByClass(Pioneer.CoverageController, `trail_length_coverage.${i}`, entity.getController(0) ?? undefined);
						coverageController.setCoverage(new Pioneer.Interval(lengthCoverage[1], lengthCoverage[2]));
						coverageController.setEnterFunction((entity) => {
							const trail = entity.getComponentByClass(Pioneer.TrailComponent);
							if (trail !== null) {
								trail.setStartTime(lengthCoverage[0]);
							}
						});
						coverageController.setExitFunction((entity) => {
							const trail = entity.getComponentByClass(Pioneer.TrailComponent);
							if (trail !== null) {
								trail.setStartTime(trailLength);
							}
						});
					}
				}
			}

			if (options.spheroid) {
				const component = entity.addComponentByClass(Pioneer.SpheroidComponent, options.spheroid.name);
				component.setEquatorialRadius(options.spheroid.equatorialRadius);
				component.setPolarRadius(options.spheroid.polarRadius);
				component.setPlanetographic(options.spheroid.planetographic);
			}

			if (options.spheroidLOD) {
				const component = entity.addComponentByClass(Pioneer.SpheroidLODComponent, options.spheroidLOD.name ?? 'basic');
				if (options.spheroidLOD.features) {
					for (let i = 0, l = options.spheroidLOD.features.length; i < l; i++) {
						component.setFeature(options.spheroidLOD.features[i], true);
					}
				}
				if (options.spheroidLOD.textures) {
					component.setMapping('cube');
					for (const name in options.spheroidLOD.textures) {
						component.setTexture(name, '$STATIC_ASSETS_URL/maps/' + options.spheroidLOD.textures[name].url, options.spheroidLOD.textures[name].sizes);
					}
				}
				if (options.spheroidLOD.shadowEntities) {
					component.setShadowEntities(options.spheroidLOD.shadowEntities);
				}
			}

			if (options.cmts) {
				const component = entity.addComponentByClass(Pioneer.CMTSComponent, options.cmts.name);
				if (options.cmts.textures) {
					for (const name in options.cmts.textures) {
						const url = options.cmts.textures[name];
						component.setBaseUrl(name, url);
					}
					component.setMaxLevel(options.cmts.maxLevel || 0);
				}
				if (options.cmts.shadowEntities) {
					component.setShadowEntities(options.cmts.shadowEntities);
				}
			}

			if (options.model) {
				const component = entity.addComponentByClass(Pioneer.ModelComponent, options.model.name);
				component.setUrl(options.model.url);
				if (options.model.rotate) {
					const rotation = new Pioneer.Quaternion();
					rotation.set(1, 0, 0, 0);
					for (let i = 0, l = options.model.rotate.length; i < l; i++) {
						let axis;
						let angle;
						const rotate = options.model.rotate[i];
						if (rotate.x !== undefined) {
							axis = new Pioneer.Vector3(1, 0, 0);
							angle = rotate.x;
						}
						else if (rotate.y !== undefined) {
							axis = new Pioneer.Vector3(0, 1, 0);
							angle = rotate.y;
						}
						else if (rotate.z !== undefined) {
							axis = new Pioneer.Vector3(0, 0, 1);
							angle = rotate.z;
						}
						else {
							throw new Error('Invalid model rotate axis.');
						}
						angle = Pioneer.MathUtils.degToRad(angle);
						const r = new Pioneer.Quaternion();
						r.setFromAxisAngle(axis, angle);
						rotation.mult(r, rotation);
					}
					component.setRotation(rotation);
				}
				if (options.model.scale) {
					const scale = options.model.scale;
					if (Array.isArray(scale)) {
						component.setScale(new Pioneer.Vector3(scale[0], scale[1], scale[2]));
					}
					else {
						component.setScale(scale);
					}
				}
				if (options.model.useCompressedTextures === true) {
					component.setUseCompressedTextures(true);
				}
				if (options.model.shadowEntities) {
					component.setShadowEntities(options.model.shadowEntities);
				}

				if (options.model.environmentMap) {
					if (options.model.environmentMap.cubemap) {
						component.setEnvironmentCubemapUrl(options.model.environmentMap.cubemap);
						component.setEnvironmentCylindricalUrl('');
					}
					else if (options.model.environmentMap.cylindrical) {
						component.setEnvironmentCylindricalUrl(options.model.environmentMap.cylindrical);
						component.setEnvironmentCubemapUrl('');
					}
				}
			}

			if (options.comet) {
				const dustTail = entity.addComponentByClass(Pioneer.CometTailComponent, options.comet.name);
				if (options.comet.timeLength !== undefined) {
					dustTail.setTimeLength(options.comet.timeLength);
				}
				dustTail.setLightSource('sun');

				const gasTail = entity.addComponentByClass(Pioneer.CometTailComponent);
				gasTail.setTimeLength(dustTail.getTimeLength() * 0.5);
				gasTail.setColor(new Pioneer.Color(0.214, 0.235, 0.371, 0.5));
				gasTail.setStarAccelerationMultiplier(10.0);
				gasTail.setLightSource('sun');

				const coma = entity.addComponentByClass(Pioneer.CometTailComponent);
				// coma.setTimeLength(dustTail.getTimeLength() * 0.1);
				coma.setStarAccelerationMultiplier(0);
				coma.setColor(new Pioneer.Color(1, 1, 1, 10));
				coma.setNumberOfParticles(1);
				coma.setLightSource('sun');
			}

			if (options.controllers) {
				for (let i = 0, l = options.controllers.length; i < l; i++) {
					const controllerOptions = options.controllers[i];
					const type = controllerOptions.type;
					let controller;
					if (type === 'fixed') {
						controller = entity.addControllerByClass(Pioneer.FixedController, controllerOptions.name);
						if (controllerOptions.position) {
							controller.setPosition(controllerOptions.position);
						}
						if (controllerOptions.orientation) {
							controller.setOrientation(controllerOptions.orientation);
						}
						if (controllerOptions.relativeToEntity) {
							// Add a rotate by parent orientation to get the fixed position in the J2000 frame.
							const rotateByEntityOrientationController = entity.addControllerByClass(Pioneer.RotateByEntityOrientationController);
							rotateByEntityOrientationController.setEntityForOrientation(controllerOptions.relativeToEntity);
							if (controllerOptions.coverage && controllerOptions.coverage.length === 2) {
								rotateByEntityOrientationController.setCoverage(new Pioneer.Interval(controllerOptions.coverage[0], controllerOptions.coverage[1]));
							}
						}
						if (controllerOptions.llaOnSpheroid) {
							const parentName = controllerOptions.llaOnSpheroidEntity ?? options.parents[0][1];
							const parent = scene.getEntity(parentName);
							if (parent !== null) {
								const spheroid = parent.getComponentByClass(Pioneer.SpheroidComponent);
								if (spheroid === null) {
									throw new Error('Missing spheroid component.');
								}
								if (controllerOptions.coverage && controllerOptions.coverage.length === 2) {
									controller.setCoverage(new Pioneer.Interval(controllerOptions.coverage[0], controllerOptions.coverage[1]));
								}
								// Get the xyz from the lla and set the position.
								const position = new Pioneer.Vector3();
								spheroid.xyzFromLLA(position, controllerOptions.llaOnSpheroid);
								controller.setPosition(position);
								// Get the ori from the lla and set the orientation.
								const orientation = new Pioneer.Quaternion();
								spheroid.orientationFromLLA(orientation, controllerOptions.llaOnSpheroid);
								controller.setOrientation(orientation);
								// Add a rotate by parent orientation to get it in the J2000 frame.
								const rotateByEntityOrientation = entity.addControllerByClass(Pioneer.RotateByEntityOrientationController);
								if (controllerOptions.coverage && controllerOptions.coverage.length === 2) {
									rotateByEntityOrientation.setCoverage(new Pioneer.Interval(controllerOptions.coverage[0], controllerOptions.coverage[1]));
								}
								const groundClamp = entity.addControllerByClass(Pioneer.GroundClampController);
								groundClamp.setGroundComponentRef(parentName, 'cmts');
								if (controllerOptions.coverage && controllerOptions.coverage.length === 2) {
									groundClamp.setCoverage(new Pioneer.Interval(controllerOptions.coverage[0], controllerOptions.coverage[1]));
								}
							}
						}
					}
					else if (type === 'dynamo') {
						controller = entity.addControllerByClass(Pioneer.DynamoController, controllerOptions.name);
						if (!controllerOptions.customUrl) {
							controller.setBaseUrl('$DYNAMIC_ASSETS_URL/dynamo/' + controllerOptions.url);
						}
						else {
							controller.setBaseUrl(controllerOptions.url);
						}
						if (controllerOptions.parentIsBarycenter) {
							controller.setHeaderValue('body', 1);
						}
					}
					else if (type === 'animdata') {
						controller = entity.addControllerByClass(Pioneer.AnimdataController, controllerOptions.name);
						controller.setBaseUrlAndStateType('$ANIMDATA_URL/' + controllerOptions.url, controllerOptions.dataType);
					}
					else if (type === 'align') {
						controller = entity.addControllerByClass(Pioneer.AlignController, controllerOptions.name);
						controller.setPrimaryAlignType(controllerOptions.primary.type);
						controller.setPrimaryTargetEntity(controllerOptions.primary.target);
						controller.setPrimaryAxis(controllerOptions.primary.axis);
						if (controllerOptions.primary.targetAxis) {
							controller.setPrimaryTargetAxis(controllerOptions.primary.targetAxis);
						}
						if (controllerOptions.secondary) {
							controller.setSecondaryAlignType(controllerOptions.secondary.type);
							controller.setSecondaryTargetEntity(controllerOptions.secondary.target);
							controller.setSecondaryAxis(controllerOptions.secondary.axis);
							if (controllerOptions.secondary.targetAxis) {
								controller.setSecondaryTargetAxis(controllerOptions.secondary.targetAxis);
							}
						}
					}
					else if (type === 'spin') {
						controller = entity.addControllerByClass(Pioneer.SpinController, controllerOptions.name);
						controller.setAxis(controllerOptions.axis, controllerOptions.axisInFrameSpace ?? true);
						controller.setRate(Pioneer.MathUtils.twoPi / (controllerOptions.periodInHours * 3600));
						if (controllerOptions.relativeToTime !== undefined) {
							controller.setReferenceTime(controllerOptions.relativeToTime);
						}
					}
					else if (type === 'rotateByEntityOrientation') {
						controller = entity.addControllerByClass(Pioneer.RotateByEntityOrientationController, controllerOptions.name);
						if (controllerOptions.entityForOrientation) {
							controller.setEntityForOrientation(controllerOptions.entityForOrientation);
						}
						if (controllerOptions.rotatingOrientation !== undefined) {
							controller.setRotatingOrientation(controllerOptions.rotatingOrientation);
						}
						if (controllerOptions.rotatingPosition !== undefined) {
							controller.setRotatingPosition(controllerOptions.rotatingPosition);
						}
					}
					else if (type === 'orbitalElements') {
						controller = entity.addControllerByClass(Pioneer.OrbitalElementsController, controllerOptions.name);
						const epoch = controllerOptions.epoch ?? 0;
						const orbitalElements = new Pioneer.OrbitalElements();
						orbitalElements.epoch = epoch;
						orbitalElements.eccentricity = controllerOptions.eccentricity;
						orbitalElements.semiMajorAxis = controllerOptions.semiMajorAxis;
						orbitalElements.meanAngularMotion = controllerOptions.meanAngularMotion;
						orbitalElements.meanAnomalyAtEpoch = controllerOptions.meanAnomalyAtEpoch;
						if (controllerOptions.orbitOrientation !== undefined) {
							orbitalElements.orbitOrientation.copy(controllerOptions.orbitOrientation);
						}
						else {
							if (controllerOptions.inclination === undefined || controllerOptions.longitudeOfAscendingNode === undefined || controllerOptions.argumentOfPeriapsis === undefined) {
								throw new Error('Either orbitOrientation or all of inclination, longitudeOfAscendingNode, and argumentOfPeriapsis must be defined.');
							}
							orbitalElements.setOrbitOrientationFromElements(controllerOptions.inclination, controllerOptions.longitudeOfAscendingNode, controllerOptions.argumentOfPeriapsis);
						}
						controller.addOrbitalElements(epoch, orbitalElements);
					}
					else if (type === 'coverage') {
						controller = entity.addControllerByClass(Pioneer.CoverageController, controllerOptions.name);
						controller.setEnterFunction(controllerOptions.enter);
						controller.setExitFunction(controllerOptions.exit);
						controller.setUpdateFunction(controllerOptions.update);
						if (controllerOptions.updateInterval !== undefined) {
							controller.setUpdateInterval(controllerOptions.updateInterval);
						}
					}
					else if (type === 'custom') {
						controller = controllerOptions.func(entity);
					}
					else {
						throw new Error(`The type "${type}" is unknown.`);
					}
					if (controllerOptions.coverage && controllerOptions.coverage.length === 2) {
						controller.setCoverage(new Pioneer.Interval(controllerOptions.coverage[0], controllerOptions.coverage[1]));
					}
				}
			}

			if (options.postCreateFunction) {
				options.postCreateFunction(entity, extraOptions);
			}
		}
		catch (error) {
			if (entity !== null) {
				scene.removeEntity(entity);
			}
			if (error instanceof Error) {
				error.message = `While creating "${actualName}": ${error.message}`;
			}
			throw error;
		}

		return entity;
	}

	/**
	 * Gets the entity options that are used to create the entity.
	 * @param {string} name
	 * @returns {Options | undefined}
	 */
	static getEntityOptions(name) {
		return Entity._entities.get(name);
	}

	/**
	 * Gets a list of all entity names that are within a given set of groups, which is a comma-separated list of groups that each entity must include.
	 * @param {string} groups
	 * @returns {Set<string>}
	 */
	static getEntityNamesInGroup(groups) {
		const matchingEntityNames = new Set();
		if (groups !== '') {
			const groupsArray = groups.split(',').map((group) => {
				return group.trim();
			});
			for (const [name, options] of Entity._entities) {
				const entityGroups = options.groups;
				if (!entityGroups) { // Entity has has no group.
					continue;
				}
				let matches = true;
				for (const group of groupsArray) {
					if (!entityGroups.includes(group)) {
						matches = false;
					}
				}
				if (!matches) {
					continue;
				}
				matchingEntityNames.add(name);
			}
		}
		else {
			for (const name of Entity._entities.keys()) {
				matchingEntityNames.add(name);
			}
		}
		return matchingEntityNames;
	}

	/**
	 * Gets a list of all groups that exist within the entities.
	 * @returns {Set<string>}
	 */
	static getGroups() {
		const groups = new Set();
		for (const options of this._entities.values()) {
			const entityGroups = options.groups;
			if (entityGroups !== undefined) {
				for (let i = 0; i < entityGroups.length; i++) {
					groups.add(entityGroups[i]);
				}
			}
		}
		return groups;
	}

	/**
	 * Create all of the entities that belong to a group.
	 * @param {string} groups - A comma-separated list of groups that each entity must include.
	 * @param {Pioneer.Scene} scene
	 * @param {ExtraOptions} [extraOptions]
	 */
	static createGroup(groups, scene, extraOptions) {
		const matchingEntityNames = this.getEntityNamesInGroup(groups);
		for (const entityName of matchingEntityNames) {
			this.create(entityName, scene, extraOptions);
		}
	}
}

/**
 * @type {Map<string, Options>}
 */
Entity._entities = new Map();
