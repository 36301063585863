/** @module pioneer */
import {
	BaseController,
	Engine,
	Entity,
	Interval,
	Quaternion,
	Reader,
	Vector3
} from '../../internal';

const eclipJ2000ToJ200Rotation = new Quaternion(0.9791532214288992, 0.2031230389823101, 0, 0);

class State {
	/**
	 * Takes a Reader and populates the values.
	 * @param {Reader} _reader
	 */
	read(_reader) {
	}

	/**
	 * Sets this to an interpolated state between state a and state b with an applied offset.
	 * @param {State} _state0 - the state at the beginning of the interval
	 * @param {State} _state1 - the state at the end of the interval
	 * @param {number} _intervalLength - the length of the interval
	 * @param {number} _u - the factor between 0 and 1
	 */
	interpolate(_state0, _state1, _intervalLength, _u) {
	}
}

/** A state that represents a position and velocity. Used by {@link Animdata}.
 * @private */
class PosState extends State {
	/**
	 * Constructor
	 */
	constructor() {
		super();

		/**
		 * Position in km at the start of this interval.
		 * @type {Vector3}
		 */
		this.position = new Vector3();

		/**
		 * Velocity in km/s at the start of this interval.
		 * @type {Vector3}
		 */
		this.velocity = new Vector3();
	}

	/**
	 * Takes a Reader and populates the values.
	 * @param {Reader} reader
	 * @override
	 */
	read(reader) {
		this.position.y = -reader.readFloat64();
		this.position.z = reader.readFloat64();
		this.position.x = reader.readFloat64();
		this.velocity.y = -reader.readFloat64();
		this.velocity.z = reader.readFloat64();
		this.velocity.x = reader.readFloat64();
		this.position.rotate(eclipJ2000ToJ200Rotation, this.position);
		this.velocity.rotate(eclipJ2000ToJ200Rotation, this.velocity);
	}

	/**
	 * Sets this to an interpolated state between state a and state b with an applied offset.
	 * @param {PosState} state0 - the state at the beginning of the interval
	 * @param {PosState} state1 - the state at the end of the interval
	 * @param {number} intervalLength - the length of the interval
	 * @param {number} u - the factor between 0 and 1
	 * @override
	 */
	interpolate(state0, state1, intervalLength, u) {
		const oneMinusU = 1.0 - u;
		const oneMinusUQuantitySquared = oneMinusU * oneMinusU;
		const uSquared = u * u;
		const a = (1.0 + 2.0 * u) * oneMinusUQuantitySquared;
		const b = u * oneMinusUQuantitySquared;
		const c = uSquared * (3.0 - 2.0 * u);
		const d = uSquared * -oneMinusU;
		this.position.mult(state0.position, a);
		this.position.addMult(this.position, state0.velocity, intervalLength * b);
		this.position.addMult(this.position, state1.position, c);
		this.position.addMult(this.position, state1.velocity, intervalLength * d);
		const sixUSquaredMinusU = 6.0 * (uSquared - u);
		const aV = sixUSquaredMinusU;
		const bV = 3.0 * uSquared - 4.0 * u + 1.0;
		const cV = -sixUSquaredMinusU;
		const dV = 3.0 * uSquared - 2.0 * u;
		this.velocity.mult(state0.position, aV / intervalLength);
		this.velocity.addMult(this.velocity, state0.velocity, bV);
		this.velocity.addMult(this.velocity, state1.position, cV / intervalLength);
		this.velocity.addMult(this.velocity, state1.velocity, dV);
	}
}

/** A state that represents an orientation. Used by {@link Animdata}.
 * @private */
class OriState extends State {
	/**
	 * Constructor
	 */
	constructor() {
		super();

		/**
		 * Orientation in radians at the start of this interval.
		 * @type {Quaternion}
		 */
		this.orientation = new Quaternion();
	}

	/**
	 * Takes a Reader and populates the values.
	 * @param {Reader} reader
	 * @override
	 */
	read(reader) {
		this.orientation.y = reader.readFloat32();
		this.orientation.z = -reader.readFloat32();
		this.orientation.x = -reader.readFloat32();
		this.orientation.w = reader.readFloat32();
		this.orientation.mult(eclipJ2000ToJ200Rotation, this.orientation);
	}

	/**
	 * Sets this to an interpolated state between state a and state b with an applied offset.
	 * @param {OriState} state0 - the state at the beginning of the interval
	 * @param {OriState} state1 - the state at the end of the interval
	 * @param {number} _intervalLength - the length of the interval (not used)
	 * @param {number} u - the factor between 0 and 1
	 * @override
	 */
	interpolate(state0, state1, _intervalLength, u) {
		this.orientation.slerp(state0.orientation, state1.orientation, u);
	}
}

/** Data for a point in time. Used by {@link Animdata}.
 * @private */
class DataPoint {
	/**
	 * Constructor
	 * @param {AnimdataController} controller - The controller.
	 */
	constructor(controller) {
		/**
		 * Interval for this data point. Really only start is used.
		 * @type {Interval}
		 */
		this.interval = new Interval();

		/**
		 * The state at the start of the data point.
		 * @type {State}
		 */
		this.state = controller.__getNewState();
	}

	/**
	 * Read binary data into the data point.
	 * @param {Reader} reader - The reader to read data
	 */
	read(reader) {
		this.interval.min = reader.readFloat64();
		this.interval.max = reader.readFloat64();
		this.state.read(reader);
	}
}

/** The animdata file. Contains list of intervals and states. Used by {@link Animdata}.
 * @private */
class AnimdataFile {
	/**
	 * Constructor. Starts loading the data.
	 * @param {AnimdataController} controller - The controller
	 * @param {number} coverageIndex - The coverage index
	 * @param {number} animdataIndex - The animdata index
	 */
	constructor(controller, coverageIndex, animdataIndex) {
		/**
		 * The interval covered by this animdata. It goes from the beginning of the first data point to the beginning of the last data point.
		 * @type {Interval}
		 */
		this.interval = new Interval();

		/**
		 * The list of data points contained in this animdata.
		 * @type {DataPoint[]}
		 */
		this.dataPoints = [];

		const url = controller.getUrl() + '/' + controller.getStateType() + '_data/' + ('00' + coverageIndex).slice(-3) + '.' + ('000' + (animdataIndex + 1)).slice(-4) + '.animdata';
		/**
		 * The promise that is used to tell when the animdata is loaded.
		 * @type {Promise<void>}
		 */
		this.promise = controller.getEntity().getScene().getEngine().getDownloader().download(url, true, -controller.getEntity().getLeastCameraDepth()).then(async (download) => {
			if (download.status === 'cancelled') {
				return Promise.resolve();
			}
			else if (download.status === 'failed') {
				return Promise.reject(new Error('Failed to load animdata controller file "' + download.url + '": ' + download.errorMessage));
			}
			if (!(download.content instanceof ArrayBuffer)) {
				return Promise.reject(new Error('Failed to load animdata controller file "' + download.url + '": Not a binary file.'));
			}
			const reader = new Reader(download.content);
			this.interval.min = reader.readFloat64();
			this.interval.max = reader.readFloat64();
			const numDataPoints = reader.readInt32();
			for (let dataPointIndex = 0; dataPointIndex < numDataPoints; dataPointIndex++) {
				const dataPoint = new DataPoint(controller);
				dataPoint.read(reader);
				this.dataPoints.push(dataPoint);
			}
		});
	}
}

/** A single animdata file index and the interval it covers. Used by {@link Animdata}.
 * @private */
class FileOverlap {
	/**
	 * Constructor
	 */
	constructor() {
		/**
		 * The file index of this animdata file.
		 * @type {number}
		 */
		this.fileIndex = 0;

		/**
		 * The coverage of this animdata file.
		 * @type {Interval}
		 */
		this.interval = new Interval();
	}
}

/** A list of data point locations in the bucket. Used by {@link Animdata}.
 * @private */
class BucketOverlap {
	/**
	 * Constructor
	 */
	constructor() {
		/**
		 * The animdata file index for this bucket overlap.
		 * @type {number}
		 */
		this.fileIndex = 0;

		/**
		 * Data point indices in this file in this bucket.
		 * @type {number[]}
		 */
		this.dataPointIndices = [];
	}
}

/** A list of bucket overlaps in the animinfo file. Used by {@link Animdata}.
 * @private */
class Bucket {
	/**
	 * Constructor
	 */
	constructor() {
		/**
		 * List of animdata file indices and data point indices for this bucket.
		 * @type {BucketOverlap[]}
		 */
		this.bucketOverlaps = [];
	}
}

/** The animinfo file, containing file overlaps and bucket overlaps. Used by {@link Animdata}.
 * @private */
class AniminfoFile {
	/**
	 * Constructor. Starts loading the data.
	 * @param {AnimdataController} controller - The controller
	 * @param {number} coverageIndex - The coverage index
	 * @param {number} animinfoIndex - The animinfo index
	 */
	constructor(controller, coverageIndex, animinfoIndex) {
		/**
		 * List of files & intervals to which the buckets refer, hashed by fileIndex.
		 * @type {Map.<number,FileOverlap>}
		 */
		this.fileOverlaps = new Map();

		/**
		 * List of buckets in animinfo file.
		 * @type {Bucket[]}
		 */
		this.buckets = [];

		const url = controller.getUrl() + '/' + controller.getStateType() + '_info/' + ('00' + coverageIndex).slice(-3) + '.' + ('000' + animinfoIndex).slice(-4) + '.animinfo';
		/**
		 * The promise that is used to tell when the animinfo is loaded.
		 * @type {Promise<void>}
		 */
		this.promise = controller.getEntity().getScene().getEngine().getDownloader().download(url, true, -controller.getEntity().getLeastCameraDepth()).then(async (download) => {
			if (download.status === 'cancelled') {
				return Promise.resolve();
			}
			else if (download.status === 'failed') {
				return Promise.reject(new Error('Failed to load animdata controller file "' + download.url + '": ' + download.errorMessage));
			}
			if (!(download.content instanceof ArrayBuffer)) {
				return Promise.reject(new Error('Failed to load animdata controller file "' + download.url + '": Not a binary file.'));
			}
			const reader = new Reader(download.content);
			const numFileOverlaps = reader.readInt16();
			for (let fileOverlapIndex = 0; fileOverlapIndex < numFileOverlaps; fileOverlapIndex++) {
				const fileOverlap = new FileOverlap();
				fileOverlap.fileIndex = reader.readInt16();
				fileOverlap.interval.min = reader.readFloat64();
				fileOverlap.interval.max = reader.readFloat64();
				this.fileOverlaps.set(fileOverlap.fileIndex, fileOverlap);
			}
			const numBuckets = reader.readInt16();
			for (let bucketIndex = 0; bucketIndex < numBuckets; bucketIndex++) {
				const bucket = new Bucket();
				const numBucketOverlaps = reader.readInt16();
				for (let bucketOverlapIndex = 0; bucketOverlapIndex < numBucketOverlaps; bucketOverlapIndex++) {
					const bucketOverlap = new BucketOverlap();
					bucketOverlap.fileIndex = reader.readInt16();
					const numDataPointIndices = reader.readInt16();
					for (let dataPointIndex = 0; dataPointIndex < numDataPointIndices; dataPointIndex++) {
						bucketOverlap.dataPointIndices.push(reader.readInt16());
					}
					bucket.bucketOverlaps.push(bucketOverlap);
				}
				this.buckets.push(bucket);
			}
		});
	}
}

/** The bookkeeping information for a given coverage defined in the animdef file. Used by {@link Animdata}.
 * @private */
class Coverage {
	/**
	 * Constructor
	 */
	constructor() {
		/**
		 * The interval that this coverage covers.
		 * @type {Interval}
		 */
		this.interval = new Interval();

		/**
		 * The number of data files.
		 * @type {number}
		 */
		this.numDataFiles = 0;

		/**
		 * The number of buckets.
		 * @type {number}
		 */
		this.numBuckets = 0;

		/**
		 * The size in seconds of each bucket.
		 * @type {number}
		 */
		this.bucketStepSize = 0;

		/**
		 * The number of buckets contained in each animinfo file.
		 * @type {number}
		 */
		this.numBucketsPerAniminfoFile = 0;

		/**
		 * Currently loaded or downloading animinfo files, keyed by index.
		 * @type {Map.<number,AniminfoFile>}
		 */
		this.animinfos = new Map();

		/**
		 * Currently loaded or downloading animdata files, keyed by index.
		 * @type {Map.<number,AnimdataFile>}
		 */
		this.animdatas = new Map();
	}
}

/** The animdef file, containing all of the coverages. Used by {@link Animdata}.
 * @private */
class AnimdefFile {
	/**
	 * Constructor. Starts loading the data.
	 * @param {AnimdataController} controller - The controller
	 */
	constructor(controller) {
		/**
		 * List of coverages defined in animdef file.
		 * @type {Coverage[]}
		 */
		this.coverages = [];

		const url = controller.getUrl() + '/' + controller.getStateType() + '.animdef';
		/**
		 * The promise that is used to tell when the animdef is loaded.
		 * @type {Promise<void>}
		 */
		this.promise = controller.getEntity().getScene().getEngine().getDownloader().download(url, true, -controller.getEntity().getLeastCameraDepth()).then(async (download) => {
			if (download.status === 'cancelled') {
				return Promise.resolve();
			}
			else if (download.status === 'failed') {
				return Promise.reject(new Error('Failed to load animdata controller file "' + download.url + '": ' + download.errorMessage));
			}
			if (!(download.content instanceof ArrayBuffer)) {
				return Promise.reject(new Error('Failed to load animdata controller file "' + download.url + '": Not a binary file.'));
			}
			const reader = new Reader(download.content);
			const numCoverages = reader.readInt16();
			for (let coverageIndex = 0; coverageIndex < numCoverages; coverageIndex++) {
				const coverage = new Coverage();
				coverage.interval.min = reader.readFloat64();
				coverage.interval.max = reader.readFloat64();
				coverage.numDataFiles = reader.readInt16();
				coverage.numBuckets = reader.readInt32();
				coverage.bucketStepSize = reader.readFloat64();
				coverage.numBucketsPerAniminfoFile = reader.readInt16();
				this.coverages.push(coverage);
			}
			controller.__updateCoverage();
		});
	}
}

/** The animdata controller. Downloads animdata files to control the position and orientation of entities. */
export class AnimdataController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The base url used to construct the other urls for the animdef, animinfo, and animdata files.
		 * @type {string}
		 * @private
		 */
		this._baseUrl = '';

		/**
		 * The state, currently either 'pos' or 'ori'.
		 * @type {string}
		 * @private
		 */
		this._stateType = '';

		/**
		 * The animdef file that contains all the other data.
		 * @type {AnimdefFile}
		 * @private
		 */
		this._animdef = null;

		/**
		 * The current data point used for updates.
		 * @type {DataPoint}
		 * @private
		 */
		this._currentDataPoint = null;

		/**
		 * Temporary data point used in calculations.
		 * @type {DataPoint}
		 * @private
		 */
		this._tempDataPoint = null;

		/**
		 * A shortcut for to get to the download method.
		 * @type {Engine}
		 * @private
		 */
		this._engine = this.getEntity().getScene().getEngine();

		/**
		 * The coverage set by setCoverage.
		 * @type {Interval}
		 * @private
		 */
		this._forcedCoverage = new Interval();
		this._forcedCoverage.copy(this.getCoverage());
	}

	/**
	 * Returns the base url used to download the animdata.
	 * @returns {string}
	 */
	getUrl() {
		return this._baseUrl;
	}

	/**
	 * Returns the state type (pos or ori)
	 * @returns {string}
	 */
	getStateType() {
		return this._stateType;
	}

	/**
	 * Sets the base url used to download the animdata.
	 * @param {string} baseUrl
	 * @param {string} stateType
	 */
	setBaseUrlAndStateType(baseUrl, stateType) {
		this._baseUrl = baseUrl;
		this._stateType = stateType;
		this._currentDataPoint = new DataPoint(this);
		this._tempDataPoint = new DataPoint(this);
		this._animdef = new AnimdefFile(this);

		// Let the base controller know that this changes the position or orientation.
		if (this._stateType === 'pos') {
			this.addModifiedState('position');
			this.addModifiedState('velocity');
		}
		else if (this._stateType === 'ori') {
			this.addModifiedState('orientation');
		}
	}

	/**
	 * Sets the time interval over which the controller is valid.
	 * @param {Interval} coverage
	 * @override
	*/
	setCoverage(coverage) {
		this._forcedCoverage.copy(coverage);
		this.__updateCoverage();
	}

	/**
	 * Since animdata has a possibly narrower coverage than the base controller (forced) coverage, this updates the base controller coverage to reflect that.
	 * @internal
	 */
	__updateCoverage() {
		const realCoverage = Interval.pool.get();
		if (this._animdef !== null && this._animdef.coverages.length > 0) {
			realCoverage.copy(this._animdef.coverages[0].interval);
			for (let i = 1; i < this._animdef.coverages.length; i++) {
				realCoverage.union(realCoverage, this._animdef.coverages[i].interval);
			}
			realCoverage.intersection(realCoverage, this._forcedCoverage);
		}
		else {
			realCoverage.copy(this._forcedCoverage);
			realCoverage.max = realCoverage.min;
		}
		super.setCoverage(realCoverage);
		Interval.pool.release(realCoverage);
	}

	/**
	 * If the animdata is pos, updates the position.
	 * @param {Vector3} position
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updatePositionAtTime(position, time) {
		if (this._stateType === 'pos') {
			const state = /** @type {PosState} */(this._tempDataPoint.state);
			if (this._tempDataPoint.interval.min !== time) {
				if (this._getDataPointAtTime(this._tempDataPoint, time)) {
					position.copy(state.position);
				}
			}
			else {
				position.copy(state.position);
			}
		}
	}

	/**
	 * If the animdata is pos, updates the velocity.
	 * @param {Vector3} velocity
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updateVelocityAtTime(velocity, time) {
		if (this._stateType === 'pos') {
			const state = /** @type {PosState} */(this._tempDataPoint.state);
			if (this._tempDataPoint.interval.min !== time) {
				if (this._getDataPointAtTime(this._tempDataPoint, time)) {
					velocity.copy(state.velocity);
				}
			}
			else {
				velocity.copy(state.velocity);
			}
		}
	}

	/**
	 * If the animdata is ori, updates the orientation.
	 * @param {Quaternion} orientation
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updateOrientationAtTime(orientation, time) {
		if (this._stateType === 'ori') {
			const state = /** @type {OriState} */(this._tempDataPoint.state);
			if (this._getDataPointAtTime(this._tempDataPoint, time)) {
				orientation.copy(state.orientation);
			}
		}
	}

	/**
	 * Returns a new promise that resolves when the controller is loaded.
	 * @returns {Promise<void>}
	 * @override
	 */
	getLoadedPromise() {
		return this.downloadDataForInterval(new Interval(this._engine.getTime(), this._engine.getTime()));
	}

	/**
	 * This does whatever is necessary to download the data for the given interval. Returns a promise that will resolve when all the data is loaded.
	 * @param {Interval} interval - The interval over which to download the data.
	 * @returns {Promise<void>}
	 */
	downloadDataForInterval(interval) {
		if (this._animdef === null) {
			return null;
		}
		return this._animdef.promise.then(async () => {
			const animinfoPromises = [];

			// For each coverage...
			for (let coverageIndex = 0; coverageIndex < this._animdef.coverages.length; coverageIndex++) {
				const coverage = this._animdef.coverages[coverageIndex];

				// If the interval is within the coverage,
				if (coverage.interval.intersects(interval)) {
					// Calculate the set of animinfos that would cover the interval.
					const animinfoIndexStart = Math.floor((Math.max(interval.min, coverage.interval.min) - coverage.interval.min) / coverage.bucketStepSize / coverage.numBucketsPerAniminfoFile);
					const animinfoIndexEnd = Math.floor((Math.min(interval.max, coverage.interval.max) - 0.0001 - coverage.interval.min) / coverage.bucketStepSize / coverage.numBucketsPerAniminfoFile);

					// For each animinfo in that set...
					for (let animinfoIndex = animinfoIndexStart; animinfoIndex <= animinfoIndexEnd; animinfoIndex++) {
						// If that animinfo hasn't been loaded yet,
						if (!coverage.animinfos.has(animinfoIndex)) {
							coverage.animinfos.set(animinfoIndex, new AniminfoFile(this, coverageIndex, animinfoIndex));
						}

						const animinfo = coverage.animinfos.get(animinfoIndex);
						animinfoPromises.push(animinfo.promise.then(() => {
							const animdataPromises = [];

							// For each file overlap in the animinfo...
							for (const fileOverlap of animinfo.fileOverlaps.values()) {
								// If the interval overlaps with the file overlap,
								if (fileOverlap.interval.intersects(interval)) {
									const animdataIndex = fileOverlap.fileIndex;

									// If that animdata hasn't been downloaded yet,
									if (!coverage.animdatas.has(animdataIndex)) {
										coverage.animdatas.set(animdataIndex, new AnimdataFile(this, coverageIndex, animdataIndex));
									}

									const animdata = coverage.animdatas.get(animdataIndex);
									animdataPromises.push(animdata.promise);
								}
							}
							return Promise.all(animdataPromises).then();
						}));
					}
					return Promise.all(animinfoPromises).then();
				}
			}
		});
	}

	/**
	 * Returns a new state of the controller type.
	 * @returns {State}
	 * @internal	*/
	__getNewState() {
		if (this._stateType === 'pos') {
			return new PosState();
		}
		else if (this._stateType === 'ori') {
			return new OriState();
		}
		else {
			return null;
		}
	}

	/**
	 * Updates the controller.
	 * @override
	 * @internal
	 */
	__update() {
		if (this._currentDataPoint !== null) {
			if (this._getDataPointAtTime(this._currentDataPoint, this._engine.getTime())) {
				if (this._stateType === 'pos') {
					const state = /** @type {PosState} */(this._currentDataPoint.state);
					this.getEntity().setPosition(state.position);
					this.getEntity().setVelocity(state.velocity);
				}
				if (this._stateType === 'ori') {
					const state = /** @type {OriState} */(this._currentDataPoint.state);
					this.getEntity().setOrientation(state.orientation);
				}
			}
		}
	}

	/**
	 * Sets outDataPoint to the state associated with the time and returns true. If it doesn't exist, it starts the download process for that time and returns false.
	 * @param {DataPoint} outDataPoint - the state to be set
	 * @param {number} time - the time to check
	 * @returns {boolean}
	 * @private
	 */
	_getDataPointAtTime(outDataPoint, time) {
		// For every coverage in the animdef,
		for (let coverageIndex = 0; coverageIndex < this._animdef.coverages.length; coverageIndex++) {
			const coverage = this._animdef.coverages[coverageIndex];

			// If the time is within the coverage,
			if (coverage.interval.contains(time)) {
				// Calculate the animinfo that would cover the time.
				const animinfoIndex = Math.floor((time - coverage.interval.min) / coverage.bucketStepSize / coverage.numBucketsPerAniminfoFile);

				// If that animinfo has been downloaded, else download it.
				if (coverage.animinfos.has(animinfoIndex)) {
					const animinfo = coverage.animinfos.get(animinfoIndex);

					// Calculate the bucket of the animinfo that would cover the time.
					const bucketIndexInAniminfoFile = Math.floor((time - coverage.interval.min) / coverage.bucketStepSize) - animinfoIndex * coverage.numBucketsPerAniminfoFile;
					const bucket = animinfo.buckets[bucketIndexInAniminfoFile];

					if (bucket === undefined) {
						return false;
					}

					// For each bucket overlap in the bucket (should be a very small number)...
					for (let bucketOverlapIndex = 0; bucketOverlapIndex < bucket.bucketOverlaps.length; bucketOverlapIndex++) {
						const bucketOverlap = bucket.bucketOverlaps[bucketOverlapIndex];
						const animdataIndex = bucketOverlap.fileIndex;

						// If the animdata covers the time,
						if (animinfo.fileOverlaps.get(animdataIndex).interval.contains(time)) {
							// If the animdata has been downloaded, else download it.
							if (coverage.animdatas.has(animdataIndex)) {
								const animdata = coverage.animdatas.get(animdataIndex);

								// For each data point index in the bucket overlap...
								for (let dataPointIndexIndex = 0; dataPointIndexIndex < bucketOverlap.dataPointIndices.length; dataPointIndexIndex++) {
									const dataPointIndex = bucketOverlap.dataPointIndices[dataPointIndexIndex];
									const dataPoint = animdata.dataPoints[dataPointIndex];

									if (dataPoint === undefined) {
										return false;
									}

									// If the data point covers the time, return the data point.
									if (dataPoint.interval.contains(time)) {
										const u = (time - dataPoint.interval.min) / dataPoint.interval.length();
										outDataPoint.interval.min = time;
										outDataPoint.interval.max = time;
										outDataPoint.state.interpolate(dataPoint.state, animdata.dataPoints[dataPointIndex + 1].state, dataPoint.interval.length(), u);
										return true;
									}
								}
								return false;
							}
							else {
								coverage.animdatas.set(animdataIndex, new AnimdataFile(this, coverageIndex, animdataIndex));
								return false;
							}
						}
					}
					return false;
				}
				else {
					coverage.animinfos.set(animinfoIndex, new AniminfoFile(this, coverageIndex, animinfoIndex));
					return false;
				}
			}
		}
		return false;
	}
}
