/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { useSnapshot } from 'valtio';

import Overlay from '../overlays';
import SwitchRounded from '../switch_rounded';

import { eventsStore, layersStore, lightStore, spacecraftStore } from '../../managers/globalState';

import SettingSVG from '../../../www/assets/images/gear.svg';

import '../../../www/assets/css/overlays/settings.css';
import ar from '../../languages/ar';

const TITLE_TEXT = ar.settings;

/**
 * Vitals Overlay component
 */
const SettingsOverlay = ({ closeOverlay }) => {
	const { cityNames, groundTracks } = useSnapshot(layersStore.state);
	const { enabledSC } = spacecraftStore.stateSnapshot;
	const { fill } = useSnapshot(lightStore.state);
	const { currentEvent } = useSnapshot(eventsStore.state);

	// Disable light toggle if on an event
	const lightToggleVisible = !currentEvent;

	const showGroundTracksToggle = Array.isArray(enabledSC) && enabledSC.length > 0;

	return (
		<Overlay type='settings'>
			<Overlay.Header
				back
				onClose={closeOverlay}
				title={TITLE_TEXT}
				Icon={SettingSVG}
			/>
			<div className='settings'>
				{
					lightToggleVisible
					&& (
						<div className='item'>
							<span>{ar.lighting}</span>
							<SwitchRounded
								title={fill ? ar.on : ar.off} checked={fill} onChange={e => {
									lightStore.setGlobalState({ fill: e.target.checked });
								}}
							/>
						</div>
					)

				}


				<div className='item'>
					<span>{ar.city_names}</span>
					<SwitchRounded
						title={cityNames ? ar.on : ar.off} checked={cityNames} onChange={e => {
							layersStore.setGlobalState({ cityNames: e.target.checked });
						}}
					/>
				</div>

				{
					showGroundTracksToggle
					&& (
						<div className='item'>
							<span>{ar.display_ground_tracks}</span>
							<SwitchRounded
								title={groundTracks ? ar.on : ar.off} checked={groundTracks} onChange={e => {
									layersStore.setGlobalState({ groundTracks: e.target.checked });
								}}
							/>
						</div>
					)
				}

			</div>
		</Overlay>
	);
};

SettingsOverlay.propTypes = {
	closeOverlay: PropTypes.func.isRequired
};

export default SettingsOverlay;
