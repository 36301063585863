/** @module pioneer */
import {
	BaseController,
	Entity,
	Interval,
	MathUtils,
	ModelComponent,
	THREE
} from '../../internal';

/**
 * A controller animates using the model's keyframed animations.
 */
export class ModelAnimateController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The animations.
		 * @type {Animation[]}
		 * @private
		 */
		this._animations = [];
	}

	/**
	 * Sets the animation to be at the joint on the specified model.
	 * @param {ModelComponent} model - the model of the joint
	 * @param {string} jointName - the joint on which the animation will be played
	 * @param {string} animationName - the name of the animation
	 * @param {Interval} interval - the interval over which the animation will be played
	 */
	setAnimation(model, jointName, animationName, interval) {
		if (model === null) {
			throw new Error('Null model specified.');
		}
		this._animations.push({
			model,
			jointName,
			animationName,
			interval,
			animationClip: null,
			rootObject: null,
			jointObject: null,
			animationMixer: null
		});
	}

	/**
	 * Updates the position and orientation from the keyframes.
	 * @override
	 * @internal
	 */
	__update() {
		const time = this.getEntity().getScene().getEngine().getTime();

		for (let i = 0, l = this._animations.length; i < l; i++) {
			const animation = this._animations[i];
			// If the model component no longer exists, remove the animation.
			if (animation.model.isDestroyed()) {
				this._animations.splice(i, 1);
				i--;
				continue;
			}
			// If the Three.js object doesn't yet exist, it's still loading.
			if (animation.model.getThreeJsObjects()[0] === null) {
				animation.rootObject = null;
				continue;
			}
			// If the model within the model component has been unloaded or reloaded, reset things.
			if (animation.rootObject !== animation.model.getThreeJsObjects()[0]) {
				animation.rootObject = animation.model.getThreeJsObjects()[0];
				animation.animationClip = null;
				animation.jointObject = null;
				animation.animationMixer = null;
			}
			// Get the animation clip if we don't yet have it.
			if (animation.animationClip === null) {
				animation.animationClip = animation.model.getAnimationClip(animation.animationName);
			}
			// Get the joint object if we don't yet have it.
			if (animation.jointObject === null) {
				const subObject = animation.model.getThreeJsObjectByName(animation.jointName);
				if (subObject !== null) {
					animation.jointObject = subObject;
				}
			}
			// If we have both the animation clip and joint object, but not the mixer, create it.
			if (animation.animationMixer === null && animation.animationClip !== null && animation.jointObject !== null) {
				animation.animationMixer = new THREE.AnimationMixer(animation.jointObject);
				animation.animationMixer.clipAction(animation.animationClip).play();
			}

			if (animation.animationMixer !== null) {
				const timeWithinAnimation = MathUtils.clamp01((time - animation.interval.min) / (animation.interval.max - animation.interval.min));
				const keyFrameTime = (animation.animationClip.duration * timeWithinAnimation) * (animation.animationClip.tracks[0].times.length - 1) / animation.animationClip.tracks[0].times.length;
				animation.animationMixer.setTime(keyFrameTime);
				animation.animationMixer.update(0);
			}
		}
	}
}

/**
 * @typedef Animation
 * @property {ModelComponent} model
 * @property {string} jointName
 * @property {string} animationName
 * @property {Interval} interval
 * @property {THREE.Object3D} rootObject
 * @property {THREE.Object3D} jointObject
 * @property {THREE.AnimationClip} animationClip
 * @property {THREE.AnimationMixer } animationMixer
 */
