import React from 'react';
import screenfull from 'screenfull';
import clsx from 'clsx';
import CloseButton from './close_button';

import '../../www/assets/css/time_rate_controls.css';
import MinusButtonSVG from '../../www/assets/images/icon_minus.svg';
import PlusButtonSVG from '../../www/assets/images/icon_plus.svg';
import ar from '../languages/ar';
import { timeStore, uiStore, appStore, eventsStore, datasetStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';
import DateTimeDisplay from './date_time_display';

/**
 * @typedef Props
 * @prop {string} content
 */

class TimeRateControls extends React.Component {
	/**
	 * Constructs the component.
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		this._update = this._update.bind(this);
		this._handleSelection = this._handleSelection.bind(this);
		this.ref = React.createRef();
	}

	componentDidMount() {
		document.addEventListener('click', this.handleOutsideClick);
		this.fRateUnsubscribe = timeStore.subscribeTo('formattedTimeRate', () => this.forceUpdate());
		this.controlsMenuUnsubscribe = uiStore.subscribeTo('controlsMenu', () => this.forceUpdate());
		this.fullscreenUnsubscribe = uiStore.subscribeTo('hideFullScreenToggle', () => this.forceUpdate());
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleOutsideClick);
		typeof this.fRateUnsubscribe === 'function' && this.fRateUnsubscribe();
		typeof this.controlsMenuUnsubscribe === 'function' && this.controlsMenuUnsubscribe();
		typeof this.fullscreenUnsubscribe === 'function' && this.fullscreenUnsubscribe();
	}

	_update(event) {
		if (event.detail) {
			this.setState(event.detail);
		}
	}

	_update(poi) {
		if (poi.detail) {
			this.setState(poi.detail);
		}
	}

	_update(gmap) {
		if (gmap.detail) {
			this.setState(gmap.detail);
		}
	}

	_handleSelection(isRealTime) {
		const { getManager } = globalRefs;
		if (isRealTime) {
			const { datasetHasAnimation } = datasetStore.stateSnapshot;
			if (datasetHasAnimation) {
				getManager('dataset').resetToLatest();
			} else {
				getManager('time').setRealTime();
			}
		} else {
			getManager('time').setTimeAndRate(isRealTime, isRealTime, true);
		}
	}

	handleOutsideClick = event => {
		// Close menu unless menu is already closed or either menu or speed button is the clicked
		if (!this.ref.current || (this.ref.current?.contains(event.target) || document.getElementById('speed-button-container')?.contains(event.target)) || document.getElementById('side-speed-btn')?.contains(event.target)) {
			return;
		}
		this.close();
	};

	close() {
		uiStore.setGlobalState({ controlsMenu: null });
	}

	_handlePoiSelection(poi) {
		const id = poi.currentTarget.id;
		this.setState({ selected: id }, () => {
			const { getManager } = globalRefs;
			if (id === 'real-rate') {
				getManager('time').setRealTime();
			} else {
				getManager('time').setDefaultTime();
			}
		});
	}

	_handleGmapSelection(gmap) {
		const id = gmap.currentTarget.id;
		this.setState({ selected: id }, () => {
			const { getManager } = globalRefs;
			if (id === 'real-rate') {
				getManager('time').setRealTime();
			} else {
				getManager('time').setDefaultTime();
			}
		});
	}

	render() {
		const { formattedTimeRate: { rate, unit } } = timeStore.stateSnapshot;
		const { isRealTime } = timeStore.state;
		const { controlsMenu, hideFullScreenToggle } = uiStore.stateSnapshot;
		const { currentEvent } = eventsStore.stateSnapshot;
		const { isMobile } = appStore.stateSnapshot;
		const timeControlsVisible = controlsMenu === 'time';
		if (!timeControlsVisible) {
			return null;
		}
		const { getManager } = globalRefs;
		const time = getManager('time');
		const currentTimeIndex = time._currentIndex;
		const timeRatesLength = time._rates.length;

		const minusButtonSVG = <MinusButtonSVG />;
		const plusButtonSVG = <PlusButtonSVG />;

		const shiftRight = 134 + (hideFullScreenToggle === 'true' || !screenfull.isEnabled) * -37 + Boolean(currentEvent) * -41;
		return (
			<div className={`time-rate-container ${!isRealTime ? 'active' : ''}`} ref={this.ref} style={!isMobile ? { right: shiftRight + 'px' } : {}}>
				<div className='satellite-speed-header'>
					<div className='satellite-speed-title'>سرعة القمر الصناعي</div>
					<CloseButton size='small' onClick={this.close} />
				</div>
				<div className='speed-toggle'>
					<div className={`toggle-button ${isRealTime ? 'active' : ''}`}>
					<button
							className={`toggle-option ${!isRealTime ? 'active' : ''}`}
							onClick={() => this._handleSelection(false)}
						>
							وقت محدد
						</button>
						<button
							className={`toggle-option ${isRealTime ? 'active' : ''}`}
							onClick={() => this._handleSelection(true)}
						>
							الوقت اللحظي
						</button>
					
						<span className='toggle-slider' />
					</div>
				</div>
				{!isRealTime && (
					<div className='incrementer'>
						<a className={clsx('controls-button', 'mx-1', currentTimeIndex <= 0 && 'disabled')} onClick={time.decreaseRate}>
							{minusButtonSVG}
						</a>
						<div className='speed-display'>
							<span className='speed-number'>{rate}</span>
							<span className='speed-unit'>{unit}</span>
						</div>
						<a className={clsx('controls-button', 'mx-1', currentTimeIndex >= timeRatesLength - 1 && 'disabled')} onClick={time.increaseRate}>
							{plusButtonSVG}
						</a>
					</div>
				)}
				<DateTimeDisplay />
			</div>
		);
	}
}

export default TimeRateControls;
