/** @module pioneer-scripts */
import { Entity } from '../entity';
import { Animation } from '../animation';
import * as Pioneer from 'pioneer';

Entity.register({
	sc_dart: {
		groups: ['small body spacecraft', 'asteroid spacecraft', '65803_didymos', 'dimorphos', 'spacecraft'],
		occlusionRadius: 0.0012,
		extentsRadius: 0.00625,
		label: 'DART',
		parents: [
			[691007069, 'earth'],
			[691418893, 'sun'],
			[717454117, '65803_didymos']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_dart/dart.gltf',
			shadowEntities: ['earth']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_dart/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_dart/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_dart/65803_didymos/pos'
		}, {
			type: 'custom',
			func: (entity) => {
				// The current spice misses Dimorphos, so we cut the spice short and keyframe the rest to make it hit.
				const keyframeController = entity.addControllerByClass(Pioneer.KeyframeController);
				keyframeController.addPositionKeyframe(717503237, // Forced end of spice.
					new Pioneer.Vector3(-10320.163052194115, 13421.106828492655, 5349.381737812169));
				keyframeController.addPositionKeyframe(717503237 + 2890.2383179924736, // Dimorphos impact
					new Pioneer.Vector3(-0.9047084058613565, -0.7147384471771195, -0.30125475602973617));
				return keyframeController;
			}
		}, {
			type: 'align',
			primary: {
				type: 'velocity',
				target: 'sc_dart',
				axis: Pioneer.Vector3.ZAxisNeg
			},
			secondary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			coverage: [Number.NEGATIVE_INFINITY, 717454117]
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'dimorphos',
				axis: Pioneer.Vector3.ZAxisNeg
			},
			secondary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			coverage: [717454117, Number.POSITIVE_INFINITY]
		}]
	},
	sc_dawn: {
		groups: ['small body spacecraft', 'asteroid spacecraft', 'dwarf planet spacecraft', '4_vesta', '1_ceres', 'spacecraft'],
		occlusionRadius: 0.000885,
		extentsRadius: 0.00985,
		label: 'Dawn',
		parents: [
			[244168849.8323595, 'earth'],
			[244461608, 'sun'],
			[288169447, 'mars'],
			[288210177, 'sun'],
			[363182466, '4_vesta'],
			[400075267, 'sun'],
			[476712067, '1_ceres'],
			[594302469.184, '']
		],
		trail: {
			length: 659889.75
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_dawn/model.gltf',
			rotate: [
				{ z: -90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_dawn/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_dawn/sun/1/orb'
		}, {
			type: 'dynamo',
			url: 'sc_dawn/mars/orb'
		}, {
			type: 'dynamo',
			url: 'sc_dawn/sun/2/orb'
		}, {
			type: 'dynamo',
			url: 'sc_dawn/vesta/orb'
		}, {
			type: 'dynamo',
			url: 'sc_dawn/sun/3/orb'
		}, {
			type: 'dynamo',
			url: 'sc_dawn/ceres/orb'
		}, {
			// Beginning of launch has bad spice, so we supplement with fixed.
			type: 'fixed',
			orientation: new Pioneer.Quaternion(0.9999478154504517, 0.008904517167862874, 0.004868284692665544, -0.0011729254143642916),
			coverage: [244168849.8323595, 244171353.18400002]
		}, {
			type: 'dynamo',
			url: 'sc_dawn/ori'
		}]
	},
	sc_deep_impact: {
		groups: ['small body spacecraft', 'comet spacecraft', '9p_tempel_1', '103p_hartley_2', 'spacecraft'],
		occlusionRadius: 0.001650,
		extentsRadius: 0.00300,
		label: 'Deep Impact',
		parents: [
			[158829812.068274, 'earth'],
			[159287744, 'sun'],
			[173560752, '9p_tempel_1'],
			[173923158, 'sun'],
			[251798121, 'earth'],
			[253531474, 'sun'],
			[282984399, 'earth'],
			[285405903, 'sun'],
			[330384030, 'earth'],
			[331534813, 'sun'],
			[342017751, '103p_hartley_2'],
			[342368983, 'sun'],
			[429192067, '']
		],
		trail: {
			length: 47421459.0,
			lengthCoverages: [
				[362406, 173560752, 173923158], // 9/P Tempel flyby
				[1733353, 251798121, 253531474], // Earth flyby 1
				[2421504, 282984399, 285405903], // Earth blyby 2
				[1150783, 330384030, 331534813], // Earth flyby 3
				[351232, 342017751, 342368983] // 103/P Hartley flyby
			]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_deep_impact/deep_impact_wo_impactor.gltf',
			rotate: [
				{ y: -90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_deep_impact/earth/launch/orb'
		}, {
			type: 'dynamo',
			url: 'sc_deep_impact/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_deep_impact/9p_tempel_1/pos'
		}, {
			type: 'dynamo',
			url: 'sc_deep_impact/earth/flyby1/orb'
		}, {
			type: 'dynamo',
			url: 'sc_deep_impact/earth/flyby2/orb'
		}, {
			type: 'dynamo',
			url: 'sc_deep_impact/earth/flyby3/orb'
		}, {
			type: 'dynamo',
			url: 'sc_deep_impact/103p_hartley_2/pos'
		}, {
			// Backup for when quat dynamo doesn't cover.
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}, {
			type: 'dynamo',
			url: 'sc_deep_impact/quat'
		}]
	},
	sc_deep_impact_impactor: {
		groups: ['small body spacecraft', 'comet spacecraft', '9p_tempel_1', 'sc_deep_impact', 'spacecraft'],
		occlusionRadius: 0.001,
		extentsRadius: 0.00100,
		label: 'Deep Impact Impactor',
		parents: [
			[158829812.068274, 'sc_deep_impact'],
			[173642464.18400002, '9p_tempel_1'],
			[173727938.18158135, '']
		],
		trail: {
			length: 27830.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_deep_impact_impactor/deep_impact_impactor.gltf',
			rotate: [
				{ y: -90 }
			]
		},
		controllers: [{
			type: 'custom',
			func: (entity) => {
				// There are spice kernels for the impactor, but they don't release or impact at the right locations.
				//   So we use a keyframe controller here.
				const keyframeController = entity.addControllerByClass(Pioneer.KeyframeController);
				keyframeController.addPositionKeyframe(173642464.18400002, // Release from Deep Impact
					new Pioneer.Vector3(-0.000713, -0.000055, 0),
					'sc_deep_impact', undefined,
					'sc_deep_impact');
				keyframeController.addPositionKeyframe(173656621.40411958, // Push away from Deep Impact
					new Pioneer.Vector3(-123095.24746842826, -48294.70341251187, 61743.99881253781),
					'sc_deep_impact', 173642464.18400002);
				keyframeController.addPositionKeyframe(173727938.18158135, // Impact
					new Pioneer.Vector3(1.580046751199936, 3.178179950746365, -0.628457454223176));
				return keyframeController;
			}
		}, {
			// Start it off with the right direction.
			type: 'fixed',
			position: new Pioneer.Vector3(-0.000713, -0.000055, 0),
			orientation: Pioneer.Quaternion.Identity,
			relativeToEntity: 'sc_deep_impact',
			coverage: [158829812.068274, 173642464.18400002]
		}, {
			// The quat dynamo doesn't quite reach the impact (short 10 seconds) so fix the orientation as a backup.
			type: 'fixed',
			orientation: new Pioneer.Quaternion(0.9060465048532422, 0.3173702681972099, 0.2649483984949708, 0.09032269948692226),
			coverage: [173642464.18400002, Number.POSITIVE_INFINITY]
		}, {
			type: 'dynamo',
			url: 'sc_deep_impact_impactor/quat',
			coverage: [173642464.18400002, Number.POSITIVE_INFINITY]
		}]
	},
	sc_deep_impact_impactor_impact_site: {
		groups: ['small body sites', 'comet sites', '9p_tempel_1', 'sc_deep_impact', 'sc_deep_impact_impactor', 'sites'],
		radius: 0.001,
		label: 'Deep Impact Impactor Impact Site',
		parents: [
			[173727938.18158135, '9p_tempel_1']
		],
		controllers: [{
			type: 'fixed',
			position: new Pioneer.Vector3(3.1153282512332603, -1.2860729555237982, -1.277920399403075),
			orientation: Pioneer.Quaternion.Identity,
			relativeToEntity: '9p_tempel_1',
			coverage: [173727938.18158135, Number.POSITIVE_INFINITY]
		}]
	},
	sc_deep_space_1: {
		groups: ['small body spacecraft', 'asteroid spacecraft', '9969_braille', 'spacecraft'],
		occlusionRadius: 0.00125,
		extentsRadius: 0.005000,
		label: 'Deep Space 1',
		parents: [
			[-37470248, 'earth'],
			[-36628312, 'sun'],
			[-13523799, '9969_braille'],
			[-13496699, 'sun'],
			[54458637, '19p_borrelly'],
			[54476825, 'sun'],
			[61977664.184, '']
		],
		trail: {
			length: 40845609.0,
			lengthCoverages: [
				[27100, -13523799, -13496699],
				[18188, 54458637, 54476825]
			]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_deep_space_1/deep_space_1.gltf',
			rotate: [
				{ x: 90 },
				{ z: 90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_deep_space_1/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_deep_space_1/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_deep_space_1/9969_braille/pos'
		}, {
			type: 'dynamo',
			url: 'sc_deep_space_1/19p_borrelly/pos'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				axis: Pioneer.Vector3.XAxis,
				target: 'earth'
			},
			secondary: {
				type: 'point',
				axis: Pioneer.Vector3.ZAxisNeg,
				target: 'sun'
			}
		}, {
			type: 'dynamo',
			url: 'sc_deep_space_1/quat'
		}]
	},
	sc_near_shoemaker: {
		groups: ['small body spacecraft', 'asteroid spacecraft', '253_mathilde', '433_eros', 'spacecraft'],
		occlusionRadius: 0.002,
		extentsRadius: 0.0034000,
		label: 'NEAR',
		parents: [
			[-122129937, 'sun'],
			[-61397606, 'earth'],
			[-60793811, 'sun'],
			[-79403925, '253_mathilde'],
			[-79210250, 'sun'],
			[-8425610, '433_eros'],
			[36675809.3654, '']
		],
		trail: {
			length: 63919069.0,
			lengthCoverages: [
				[400000, -8425610, 35279032.137],
				[0, 35279032.137, Number.POSITIVE_INFINITY]
			]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_near_shoemaker/near.gltf',
			rotate: [
				{ x: 90 },
				{ z: 135 }
			]
		},
		controllers: [{
			type: 'custom',
			func: (entity) => {
				// Add an OE controller because the dynamo doesn't go back to launch.
				// This data was calculated by the computeOrbit function in Pioneer Play.
				const oeController = entity.addControllerByClass(Pioneer.OrbitalElementsController);
				const oe = new Pioneer.OrbitalElements();
				oe.eccentricity = 0.3722732412046076;
				oe.epoch = -122129937;
				oe.semiMajorAxis = 235420679.8644008;
				oe.orbitOrientation.set(0.25408339907533106, 0.05463135384055627, -0.18986948069810847, 0.9467875272685549);
				oe.meanAngularMotion = 1.0085301888805118e-7;
				oe.meanAnomalyAtEpoch = -0.005941352116228519;
				oeController.addOrbitalElements(-122129937, oe);
				oeController.addOrbitalElements(-113227200, oe);
				oeController.setCoverage(new Pioneer.Interval(-122129937, -113227200));
				return oeController;
			}
		}, {
			type: 'dynamo',
			url: 'sc_near_shoemaker/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_near_shoemaker/earth/flyby/orb'
		}, {
			type: 'dynamo',
			url: 'sc_near_shoemaker/253_mathilde/pos'
		}, {
			type: 'dynamo',
			url: 'sc_near_shoemaker/433_eros/orb'
		}, {
			// Cover the launch period.
			type: 'fixed',
			orientation: new Pioneer.Quaternion(0.5052018803124495, -0.11842169431143575, 0.7652511949887616, -0.3809697770341459),
			coverage: [-122129937, -121953528.049046]
		}, {
			type: 'dynamo',
			url: 'sc_near_shoemaker/quat'
		}, {
			// Add a fixed controller for after it lands.
			type: 'fixed',
			position: new Pioneer.Vector3(0.930389249841349, 4.935129554115763, -4.002004469114965),
			orientation: new Pioneer.Quaternion(0.11222410400554989, 0.7066112547219852, -0.6897721372150081, 0.11099857612696495),
			coverage: [35279032.137, 36675809.3654]
		}, {
			// Get the landing fixed coverage coords in the J2000 frame.
			type: 'rotateByEntityOrientation',
			coverage: [35279032.137, 36675809.3654]
		}]
	},
	sc_near_shoemaker_landing_site: {
		groups: ['433_eros', 'sc_near_shoemaker', 'sites'],
		radius: 0.001,
		systemRadius: 200,
		label: 'NEAR Shoemaker Landing Site',
		parents: [
			[36675809.3654, '433_eros']
		],
		controllers: [{
			type: 'fixed',
			position: new Pioneer.Vector3(0.930389249841349, 4.935129554115763, -4.002004469114965),
			orientation: new Pioneer.Quaternion(0.11222410400554989, 0.7066112547219852, -0.6897721372150081, 0.11099857612696495),
			relativeToEntity: '433_eros',
			coverage: [36675809.3654, Number.POSITIVE_INFINITY]
		}]
	},
	sc_lucy: {
		groups: ['small body spacecraft', 'asteroid spacecraft', '52246_donaldjohanson', '3548_eurybates', '15094_polymele', '11351_leucus', '21900_orus', '617_patroclus', 'menoetius', 'spacecraft'],
		occlusionRadius: 0.002,
		extentsRadius: 0.007125,
		label: 'Lucy',
		parents: [
			[687656642.763, 'earth'],
			[687915086, 'sun'],
			[718960993, 'earth'],
			[719531941, 'sun'],
			[787134972, 'earth'],
			[787532222, 'sun'],
			[798252820, '52246_donaldjohanson'],
			[798584539, 'sun'],
			[870652086, '3548_eurybates'],
			[872642047, 'sun'],
			[872642047, '15094_polymele'],
			[875308504, 'sun'],
			[891166024, '11351_leucus'],
			[894761809, 'sun'],
			[909384911, '21900_orus'],
			[912190135, 'sun'],
			[977590306, 'earth'],
			[978108682, 'sun'],
			[1046169596, '617_patroclus_barycenter'],
			[1047892376, 'sun']
		],
		dependents: [
			'152830_dinkinesh'
		],
		trail: {
			length: 63919069.0,
			lengthCoverages: [
				[719531941 - 718960993, 718960993, 719531941],
				[787532222 - 787134972, 787134972, 787532222],
				[798584539 - 798252820, 798252820, 798584539],
				[872642047 - 870652086, 870652086, 872642047],
				[875308504 - 872642047, 872642047, 875308504],
				[894761809 - 891166024, 891166024, 894761809],
				[912190135 - 909384911, 909384911, 912190135],
				[978108682 - 977590306, 977590306, 978108682],
				[1047892376 - 1046169596, 1046169596, 1047892376]
			]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_lucy/lucy.gltf',
			rotate: [
				{ x: 90 },
				{ z: 90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_lucy/earth/launch/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}, {
			type: 'dynamo',
			url: 'sc_lucy/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_lucy/earth/flyby1/orb'
		}, {
			type: 'dynamo',
			url: 'sc_lucy/earth/flyby2/orb'
		}, {
			type: 'dynamo',
			url: 'sc_lucy/52246_donaldjohanson/pos'
		}, {
			type: 'dynamo',
			url: 'sc_lucy/3548_eurybates/pos'
		}, {
			type: 'dynamo',
			url: 'sc_lucy/15094_polymele/pos'
		}, {
			type: 'dynamo',
			url: 'sc_lucy/11351_leucus/pos'
		}, {
			type: 'dynamo',
			url: 'sc_lucy/21900_orus/pos'
		}, {
			type: 'dynamo',
			url: 'sc_lucy/earth/flyby3/orb'
		}, {
			type: 'dynamo',
			url: 'sc_lucy/617_patroclus/pos'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				axis: Pioneer.Vector3.XAxis,
				target: 'earth'
			},
			secondary: {
				type: 'align',
				axis: Pioneer.Vector3.YAxis,
				target: 'sun',
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}, {
			type: 'dynamo',
			url: 'sc_lucy/quat'
		}]
	},
	sc_new_horizons: {
		groups: ['small body spacecraft', 'dwarf planet spacecraft', 'TNO spacecraft', '134340_pluto', '486958_arrokoth', 'spacecraft'],
		occlusionRadius: 0.00135,
		extentsRadius: 0.0026,
		label: 'New Horizons',
		parents: [
			[190972278.33046317, 'earth'],
			[191055829, 'sun'],
			[225619606, 'jupiter'],
			[226100665, 'sun'],
			[490130161, '134340_pluto'],
			[490167848, 'sun'],
			[598753684, '486958_arrokoth'],
			[600203601, 'sun']
		],
		trail: {
			length: 60 * 60 * 24 * 365 * 3
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_new_horizons/new_horizons.gltf',
			rotate: [
				{ y: 90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_new_horizons/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_new_horizons/sun/1/orb'
		}, {
			type: 'dynamo',
			url: 'sc_new_horizons/jupiter/orb'
		}, {
			type: 'dynamo',
			url: 'sc_new_horizons/sun/2/orb'
		}, {
			type: 'dynamo',
			url: 'sc_new_horizons/pluto/orb'
		}, {
			type: 'dynamo',
			url: 'sc_new_horizons/sun/3/pos'
		}, {
			type: 'dynamo',
			url: 'sc_new_horizons/mu69/pos'
		}, {
			type: 'dynamo',
			url: 'sc_new_horizons/sun/4/pos'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxis
			}
		}, {
			type: 'dynamo',
			url: 'sc_new_horizons/ori/1'
		}, {
			type: 'dynamo',
			url: 'sc_new_horizons/ori/2'
		}]
	},
	sc_rosetta: {
		groups: ['small body spacecraft', 'comet spacecraft', '67p_churyumov_gerasimenko', 'spacecraft'],
		occlusionRadius: 0.001400,
		extentsRadius: 0.016000,
		label: 'Rosetta',
		parents: [
			[131491581.583, 'earth'],
			[131901500, 'sun'],
			[162704887, 'earth'],
			[163831232, 'sun'],
			[225623375, 'mars'],
			[225657862, 'sun'],
			[248111015, 'earth'],
			[248475560, 'sun'],
			[311055929, 'earth'],
			[311664877, 'sun'],
			[452394238, '67p_churyumov_gerasimenko'],
			[528503957.968, '']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_rosetta/rosettaPhilae.gltf',
			rotate: [
				{ x: 180 },
				{ z: 90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_rosetta/earth/launch/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}, {
			type: 'dynamo',
			url: 'sc_rosetta/sun/1/orb'
		}, {
			type: 'dynamo',
			url: 'sc_rosetta/earth/flyby1/orb'
		}, {
			type: 'dynamo',
			url: 'sc_rosetta/sun/2/orb'
		}, {
			type: 'dynamo',
			url: 'sc_rosetta/mars/flyby/orb'
		}, {
			type: 'dynamo',
			url: 'sc_rosetta/sun/3/orb'
		}, {
			type: 'dynamo',
			url: 'sc_rosetta/earth/flyby2/orb'
		}, {
			type: 'dynamo',
			url: 'sc_rosetta/sun/4/orb'
		}, {
			type: 'dynamo',
			url: 'sc_rosetta/earth/flyby3/orb'
		}, {
			type: 'dynamo',
			url: 'sc_rosetta/sun/5/orb'
		}, {
			type: 'dynamo',
			url: 'sc_rosetta/67p_churyumov_gerasimenko/pos'
		}, {
			type: 'dynamo',
			url: 'sc_rosetta/ori'
		}, {
			type: 'coverage',
			coverage: [469053367.183, Number.POSITIVE_INFINITY],
			enter: (entity) => {
				const modelComponent = entity.getComponentByClass(Pioneer.ModelComponent);
				if (modelComponent !== null) {
					modelComponent.setHiddenObject('Philae', true);
					// modelComponent.setHiddenObject('foil_gold_h', true);
				}
			},
			exit: (entity) => {
				const modelComponent = entity.getComponentByClass(Pioneer.ModelComponent);
				if (modelComponent !== null) {
					modelComponent.setHiddenObject('Philae', false);
					// modelComponent.setHiddenObject('foil_gold_h', false);
				}
			}
		}, {
			type: 'custom',
			func: (entity) => {
				const solarPanelAlignLeft = entity.addControllerByClass(Pioneer.AlignController);
				solarPanelAlignLeft.setJoint('panels_01');
				solarPanelAlignLeft.setSecondaryAlignType('point');
				solarPanelAlignLeft.setSecondaryAxis(Pioneer.Vector3.ZAxis);
				solarPanelAlignLeft.setSecondaryTargetEntity('sun');
				return solarPanelAlignLeft;
			}
		}, {
			type: 'custom',
			func: (entity) => {
				const solarPanelAlignRight = entity.addControllerByClass(Pioneer.AlignController);
				solarPanelAlignRight.setJoint('panels_02');
				solarPanelAlignRight.setSecondaryAlignType('point');
				solarPanelAlignRight.setSecondaryAxis(Pioneer.Vector3.ZAxis);
				solarPanelAlignRight.setSecondaryTargetEntity('sun');
				return solarPanelAlignRight;
			}
		}]
	},
	sc_rosetta_impact_site: {
		groups: ['small body sites', 'comet sites', '67p_churyumov_gerasimenko', 'sc_rosetta', 'sites'],
		radius: 0.001,
		label: 'Rosetta Impact Site',
		parents: [
			[528503957.968, '67p_churyumov_gerasimenko']
		],
		controllers: [{
			type: 'fixed',
			position: new Pioneer.Vector3(1.7309310500292525, 0.3509303067271947, 1.1641920075039298),
			orientation: Pioneer.Quaternion.Identity,
			relativeToEntity: '67p_churyumov_gerasimenko',
			coverage: [528503957.968, Number.POSITIVE_INFINITY]
		}]
	},
	sc_osiris_rex: {
		groups: ['small body spacecraft', 'asteroid spacecraft', '101955_bennu', 'spacecraft'],
		occlusionRadius: 0.001600,
		extentsRadius: 0.005,
		label: 'OSIRIS-REx',
		parents: [
			[526676400, 'earth'],
			[527025408, 'sun'],
			[558938468, 'earth'],
			[559919190, 'sun'],
			[591770603, '101955_bennu'],
			[674049669, 'sun'],
			[748358886, 'earth'],
			[749140122, 'sun']
		],
		dependents: ['sc_osiris_rex_src', '99942_apophis'], // These aren't parents and so they are named here.
		trail: {
			length: 102742.5,
			lengthCoverages: [
				[10000000, Number.NEGATIVE_INFINITY, 599748661],
				[672000.0, 599748661, 668095733],
				[10000000, 668095733, Number.POSITIVE_INFINITY]
			]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_osiris_rex_v2/osiris_rex_articulated_panels.gltf',
			rotate: [
				{ x: 90 }
			],
			shadowEntities: ['bennu']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_osiris_rex/earth/launch/orb'
		}, {
			type: 'dynamo',
			url: 'sc_osiris_rex/sun/1/orb'
		}, {
			type: 'dynamo',
			url: 'sc_osiris_rex/earth/flyby/orb'
		}, {
			type: 'dynamo',
			url: 'sc_osiris_rex/sun/2/orb'
		}, {
			type: 'dynamo',
			url: 'sc_osiris_rex/bennu/pos'
		}, {
			type: 'dynamo',
			url: 'sc_osiris_rex/sun/3/orb'
		}, {
			type: 'dynamo',
			url: 'sc_osiris_rex/earth/sample_return/orb'
		}, {
			type: 'dynamo',
			url: 'sc_osiris_rex/sun/4/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth', // Orientated so that the SRC will come off at the right orientation.
				axis: new Pioneer.Vector3(0.175109477645991, 0.45028151394683397, 0.8755473882299549)
			},
			secondary: {
				type: 'align',
				target: 'sun',
				axis: Pioneer.Vector3.XAxis,
				targetAxis: Pioneer.Vector3.ZAxis
			},
			coverage: [658200668.0606446, Number.POSITIVE_INFINITY]
		}, {
			type: 'dynamo',
			url: 'sc_osiris_rex/ori'
		}, {
			type: 'coverage',
			coverage: [748838793, Number.POSITIVE_INFINITY],
			enter: (entity) => {
				const div = entity.getComponentByClass(Pioneer.DivComponent);
				if (div !== null) {
					div.getDiv().innerHTML = div.getDiv().innerHTML.replace('REx', 'APEX');
				}
			},
			exit: (entity) => {
				const div = entity.getComponentByClass(Pioneer.DivComponent);
				if (div !== null) {
					div.getDiv().innerHTML = div.getDiv().innerHTML.replace('APEX', 'REx');
				}
			}
		}, {
			// For the animations, used "The OSIRIS-REx Spacecraft and the Touch and Go Sample Acquisition Mechanism", Kyle Brown 2020.
			type: 'custom',
			func: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				return Animation.makeSubobjectVisibleAnimation(model, 'Tagsam_housing_cover', true, [
					[593036911, false] // Head cover jettisoned.
				]);
			}
		}, {
			type: 'custom',
			func: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				return Animation.makeJointRotationAnimation(model, 'Tagsam_rod_bottom', 'z', [
					[595442726, 0], // First extending.
					[595442726 + 1 * 180, -2.51758508612],
					[595442726 + 2 * 180, -2.51758508612], // Folds back after first extending.
					[595442726 + 3 * 180, 0],
					[656502686 - 220 * 60, 0], // At T-220m it unfurls.
					[656502686 - 220 * 60 + 360, -3.71758508612],
					[656502686 + 2 * 60 + 90, -3.71758508612], // At T+2m it parks.
					[656502686 + 2 * 60 + 90 + 180, 0],
					[656502686 + 10 * 24 * 3600 + 0 * 180, 0], // It stores the head in the SRC module.
					[656502686 + 10 * 24 * 3600 + 2 * 180, -2.73771997401],
					[656502686 + 10 * 24 * 3600 + 4 * 180, 0]
				]);
			}
		}, {
			type: 'custom',
			func: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				return Animation.makeJointRotationAnimation(model, 'Tagsam_rod_top', 'z', [
					[593728111, 0], // Frangibolts fired and TAGSAM is unlocked.
					[593728111 + 2, 0.15],
					[595442726, 0.15], // First extending.
					[595442726 + 1 * 180, 2.73247002363],
					[595442726 + 2 * 180, 2.73247002363], // Folds back after first extending.
					[595442726 + 3 * 180, 0.15],
					[656502686 - 220 * 60, 0.15], // At T-220m it unfurls.
					[656502686 - 220 * 60 + 360, 3.73247002363],
					[656502686 + 2 * 60 + 90, 3.73247002363], // At T+2m it parks.
					[656502686 + 2 * 60 + 90 + 180, 0.15],
					[656502686 + 10 * 24 * 3600 + 0 * 180, 0.15], // It stores the head in the SRC module.
					[656502686 + 10 * 24 * 3600 + 1 * 180, 1.16136826422],
					[656502686 + 10 * 24 * 3600 + 2 * 180, 0.509601235],
					[656502686 + 10 * 24 * 3600 + 3 * 180, 1.16136826422],
					[656502686 + 10 * 24 * 3600 + 4 * 180, 0.15]
				]);
			}
		}, {
			type: 'custom',
			func: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				return Animation.makeJointRotationAnimation(model, 'tagsam_head', 'z', [
					[595442726 + 1 * 180, 0], // First extending.
					[595442726 + 1.5 * 180, 1.57079632679], // Folds back after first extending.
					[595442726 + 2 * 180, 1.57079632679],
					[656502686 - 220 * 60, 0], // At T-220m it unfurls.
					[656502686 - 220 * 60 + 90, 1.57079632679],
					[656502686 + 2 * 60, 1.57079632679], // At T+2m it parks.
					[656502686 + 2 * 60 + 90, 0],
					[656502686 + 10 * 24 * 3600, 0], // It stores the head in the SRC module.
					[656502686 + 10 * 24 * 3600 + 360, 1.01463145268]
				]);
			}
		}, {
			type: 'custom',
			func: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				return Animation.makeSubobjectVisibleAnimation(model, 'tagsam_head', true, [
					[656502686 + 10 * 24 * 3600 + 360, false] // At T+10d, the head is stowed. We don't have the SRC stowage piece, so the head will just disappear.
				]);
			}
		}, {
			type: 'custom',
			func: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				return Animation.makeJointRotationAnimation(model, 'Gimbal_Base_Right', 'z', [
					[656501483 + 0 * 60, -70 * Math.PI / 180],
					[656501483 + 2 * 60, 0],
					[656503242 + 0 * 60, 0],
					[656503242 + 2 * 180, -70 * Math.PI / 180]
				]);
			}
		}, {
			type: 'custom',
			func: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				return Animation.makeJointRotationAnimation(model, 'Gimbal_Base_Left', 'z', [
					[656501483 + 0 * 60, -70 * Math.PI / 180],
					[656501483 + 2 * 60, 0],
					[656503242 + 0 * 60, 0],
					[656503242 + 2 * 180, -70 * Math.PI / 180]
				]);
			}
		}, {
			type: 'custom',
			func: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				return Animation.makeJointRotationAnimation(model, 'Gimbal_Pivot_Right', 'x', [
					[656501483 + 2 * 60, 0],
					[656501483 + 4 * 60, 38 * Math.PI / 180],
					[656503242, 38 * Math.PI / 180],
					[656503242 + 2 * 60, 0]
				]);
			}
		}, {
			type: 'custom',
			func: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				return Animation.makeJointRotationAnimation(model, 'Gimbal_Pivot_Left', 'x', [
					[656501483 + 2 * 60, 0],
					[656501483 + 4 * 60, -38 * Math.PI / 180],
					[656503242, -38 * Math.PI / 180],
					[656503242 + 2 * 60, 0]
				]);
			}
		}, {
			type: 'custom',
			func: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				return Animation.makeSubobjectVisibleAnimation(model, 'heatshield', true, [
					[748824183.985732, false] // At this time, the SRC is released.
				]);
			}
		}]
	},
	sc_osiris_rex_src: {
		groups: ['small body spacecraft', 'asteroid spacecraft', '101955_bennu', 'sample return capsule'],
		occlusionRadius: 0.0008,
		extentsRadius: 0.0005,
		label: 'OSIRIS-REx SRC',
		parents: [
			[748824183.985732, 'sc_osiris_rex'],
			[748832190, 'earth'],
			[748839215, '']
		],
		dependents: ['sc_osiris_rex'],
		trail: {
			length: 360
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_osiris_rex_v2/src/osiris_rex_heatshield.gltf',
			rotate: [
				{ x: 90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_osiris_rex_src/sc_osiris_rex'
		}, {
			type: 'dynamo',
			url: 'sc_osiris_rex_src/earth'
		}, {
			type: 'fixed',
			orientation: new Pioneer.Quaternion(-0.7795827954678449, -0.23701239922750972, 0.5441503490850964, -0.19994045416955505)
		}, {
			type: 'coverage',
			coverage: [748838642, 748839373],
			enter: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setRelativeToEntityOrientation(true);
				}
			},
			exit: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setRelativeToEntityOrientation(false);
				}
			}
		}, {
			type: 'custom',
			func: (entity) => {
				const translate = entity.addControllerByClass(Pioneer.TranslateController);
				translate.setTranslation(new Pioneer.Vector3(0, 0, 0.0012));
				translate.setRelativeToOrientation(true);
				return translate;
			},
			coverage: [748824183.985732, 748839215]
		}],
		postCreateFunction: (entity) => {
			const model = entity.getComponentByClass(Pioneer.ModelComponent);
			model.setTranslation(new Pioneer.Vector3(0, 0, -0.0012));
		}
	},
	sc_philae: {
		groups: ['small body spacecraft', 'comet spacecraft', '67p_churyumov_gerasimenko', 'spacecraft', 'landers'],
		occlusionRadius: 0.0006,
		extentsRadius: 0.001,
		label: 'Philae',
		parents: [
			[469053367.183, '67p_churyumov_gerasimenko'],
			[469078512.324, '']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_philae/philae.gltf',
			rotate: [
				{ x: 180 },
				{ z: 90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_philae/67p_churyumov_gerasimenko/pos'
		}, {
			// The orientation of Rosetta when it is detached.
			type: 'fixed',
			orientation: new Pioneer.Quaternion(0.05763938670269045, 0.04093459828351704, 0.6750586755218195, 0.7343690110337115)
		}, {
			type: 'custom',
			func: (entity) => {
				// Get the lander's origin and model aligned with the spacecraft when they separate.
				const originOffset = new Pioneer.Vector3(0.0021658104318583687, 0.0011575046500869896, 0.00011875498849156656);
				const modelOffset = new Pioneer.Vector3(0.00147, 0, 0);
				const translateController = entity.addControllerByClass(Pioneer.TranslateController);
				translateController.setTranslation(originOffset);
				// Also set the model's translation, so everything aligns.
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				model.setTranslation(modelOffset);
				return translateController;
			},
			coverage: [469053367.183, 469078512.324]
		}]
	},
	sc_philae_landing_site: {
		groups: ['small body sites', 'comet sites', '67p_churyumov_gerasimenko', 'sc_philae', 'sites'],
		radius: 0.001,
		systemRadius: 200,
		label: 'Philae Landing Site',
		parents: [
			[469078512.324, '67p_churyumov_gerasimenko']
		],
		controllers: [{
			type: 'fixed',
			position: new Pioneer.Vector3(2.4452763955965984, -0.12110982508097201, -0.36032099522959377),
			orientation: Pioneer.Quaternion.Identity,
			relativeToEntity: '67p_churyumov_gerasimenko',
			coverage: [469078512.324, Number.POSITIVE_INFINITY]
		}]
	},
	sc_psyche: {
		groups: ['small body spacecraft', 'asteroid spacecraft', '16_psyche', 'spacecraft'],
		occlusionRadius: 0.0031,
		extentsRadius: 0.025,
		label: 'Psyche',
		parents: [
			[750482453, 'earth'],
			[750686758, 'sun'],
			[831828698, 'mars'],
			[832302380, 'sun'],
			[931665741, '16_psyche']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_psyche/psyche.gltf',
			rotate: [
				{ y: -90 },
				{ x: 90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_psyche/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_psyche/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_psyche/mars/orb'
		}, {
			type: 'dynamo',
			url: 'sc_psyche/16_psyche/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.XAxisNeg
			}
		}, {
			type: 'custom',
			func: (entity) => {
				const solarPanelAlignLeft = entity.addControllerByClass(Pioneer.AlignController);
				solarPanelAlignLeft.setJoint('ArrayGimbleLeft');
				solarPanelAlignLeft.setSecondaryAlignType('point');
				solarPanelAlignLeft.setSecondaryAxis(Pioneer.Vector3.YAxis);
				solarPanelAlignLeft.setSecondaryTargetEntity('sun');
				return solarPanelAlignLeft;
			}
		}, {
			type: 'custom',
			func: (entity) => {
				const solarPanelAlignRight = entity.addControllerByClass(Pioneer.AlignController);
				solarPanelAlignRight.setJoint('ArrayGimbleRight');
				solarPanelAlignRight.setSecondaryAlignType('point');
				solarPanelAlignRight.setSecondaryAxis(Pioneer.Vector3.YAxis);
				solarPanelAlignRight.setSecondaryTargetEntity('sun');
				return solarPanelAlignRight;
			}
		}]
	},
	sc_stardust: {
		groups: ['small body spacecraft', 'comet spacecraft', '81p_wild_2', '9p_tempel_1', 'spacecraft'],
		occlusionRadius: 0.00065,
		extentsRadius: 0.003000,
		label: 'Stardust',
		parents: [
			[-28304869.3, 'earth'],
			[-28038699, 'sun'],
			[32627842, 'earth'],
			[33120541, 'sun'],
			[89379733, '5535_annefrank'],
			[89550209, 'sun'],
			[126009572, '81p_wild_2'],
			[126678668, 'sun'],
			[190336290, 'earth'],
			[190866114, 'sun'],
			[284944970, 'earth'],
			[285742028, 'sun'],
			[350896766, '9p_tempel_1'],
			[351068113, 'sun'],
			[354279666, '']
		],
		trail: {
			length: 62659492.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_stardust/stardust_articulated.gltf',
			shadowEntities: ['earth'],
			rotate: [
				{ x: 90 },
				{ z: 90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_stardust/earth/launch/orb'
		}, {
			type: 'dynamo',
			url: 'sc_stardust/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_stardust/earth/flyby1/orb'
		}, {
			type: 'dynamo',
			url: 'sc_stardust/5535_annefrank/pos'
		}, {
			type: 'dynamo',
			url: 'sc_stardust/81p_wild_2/pos'
		}, {
			type: 'dynamo',
			url: 'sc_stardust/earth/flyby2/orb'
		}, {
			type: 'dynamo',
			url: 'sc_stardust/earth/flyby3/orb'
		}, {
			type: 'dynamo',
			url: 'sc_stardust/9p_tempel_1/pos'
		}, {
			type: 'dynamo',
			url: 'sc_stardust/quat'
		}, {
			// Orientation dynamo doesn't quite reach to the end.
			type: 'fixed',
			orientation: new Pioneer.Quaternion(0.382740492391079, -0.1080542417135509, -0.5511677923678098, -0.7335175941913997),
			coverage: [351017063, 354279666]
		}, {
			// Hide the SRC when it is released.
			type: 'coverage',
			coverage: [190576755.185 - 64.40161622339717, Number.POSITIVE_INFINITY],
			enter: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				for (const object of ['Stardust_capsule', 'Stardust_tex_01_c', 'Stardust_tex_02_c', 'Stardust_tex_03_c', 'Stardust_tex_02_c2', 'Stardust_Sample_Collection1']) {
					model.setHiddenObject(object, true);
				}
			},
			exit: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				for (const object of ['Stardust_capsule', 'Stardust_tex_01_c', 'Stardust_tex_02_c', 'Stardust_tex_03_c', 'Stardust_tex_02_c2', 'Stardust_Sample_Collection1']) {
					model.setHiddenObject(object, false);
				}
			}
		}],
		postCreateFunction: (entity) => {
			const model = entity.getComponentByClass(Pioneer.ModelComponent);
			const stardustAnimation = (/** @type {number} */angle, /** @type {number} */timeOffset, /** @type {number} */duration) => {
				return /** @type {[number, number][]} */([
					[4449664 + timeOffset, angle],
					[4449664 + timeOffset + duration, 0],
					[10411264 - timeOffset - duration, 0],
					[10411264 - timeOffset, angle],
					[81864064 + timeOffset, angle],
					[81864064 + timeOffset + duration, 0],
					[92664064 - timeOffset - duration, 0],
					[92664064 - timeOffset, angle],
					[125496064 + timeOffset, angle],
					[125496064 + timeOffset + duration, 0],
					[126363664 - timeOffset - duration, 0],
					[126363664 - timeOffset, angle]
				]);
			};
			Animation.makeJointRotationAnimation(model, 'Stardust_capsule', 'x', stardustAnimation(1.481785, 0, 600));
			Animation.makeJointRotationAnimation(model, 'Stardust_Sample_Collection1', 'x', stardustAnimation(-Math.PI, 610, 300));
			Animation.makeJointRotationAnimation(model, 'Stardust_Sample_Collection2', 'x', stardustAnimation(-Math.PI, 920, 300));
		}
	},
	sc_stardust_src: {
		groups: ['small body spacecraft', 'comet spacecraft', '81p_wild_2', 'earth', 'spacecraft'],
		occlusionRadius: 0.0004,
		extentsRadius: 0.0008,
		label: 'Stardust SRC',
		parents: [
			[190576690.7833838, 'sc_stardust'],
			[190576755.185, 'earth'],
			[190591985.184, '']
		],
		trail: {
			length: 14547.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_stardust_src/stardust_capsule.gltf',
			shadowEntities: ['earth'],
			rotate: [
				{ x: 90 },
				{ z: 90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_stardust_src/earth/pos'
		}, {
			type: 'fixed',
			orientation: new Pioneer.Quaternion(0.5761056269233067, 0.6860897901682099, 0.2627910947477326, 0.3582233199772653)
		}, {
			// Make the SRC trail relative to earth orientation.
			type: 'coverage',
			coverage: [190591100, Number.POSITIVE_INFINITY],
			enter: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setRelativeToEntityOrientation(true);
					trail.setStartTime(1600);
				}
			},
			exit: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setRelativeToEntityOrientation(false);
					trail.setStartTime(18000);
				}
			}
		}, {
			// The spice kernels for the SRC don't release at the right position, so we use a keyframe controller to fill the gap.
			type: 'custom',
			func: (entity) => {
				const keyframeControllerRelease = entity.addControllerByClass(Pioneer.KeyframeController);
				keyframeControllerRelease.addPositionKeyframe(190576755.185 - 64.40161622339717, // Release
					new Pioneer.Vector3(-0.0010057, 0, 0.0001569),
					'sc_stardust', undefined,
					'sc_stardust', undefined);
				keyframeControllerRelease.addPositionKeyframe(190576755.185, // Dynamo starts
					new Pioneer.Vector3(-0.016734488308429718, -0.019199222326278687, -0.00404781848192215),
					'sc_stardust');
				return keyframeControllerRelease;
			}
		}, {
			// The spice kernels for the SRC don't land correctly,
			//   so we use a keyframe controller to fill the gap.
			type: 'custom',
			func: (entity) => {
				const keyframeControllerLand = entity.addControllerByClass(Pioneer.KeyframeController);
				keyframeControllerLand.addPositionKeyframe(190591163, // Spice doesn't look as good after this point.
					new Pioneer.Vector3(-1979.4577357598625, -4478.591383446022, 4136.322858267745),
					'earth'
				);
				keyframeControllerLand.addPositionKeyframe(190591445.184, // Starts more gentle descent with parachute.
					new Pioneer.Vector3(-1947.8360605285134, -4491.082890234251, 4123.1018367103425),
					'earth'
				);
				keyframeControllerLand.addPositionKeyframe(190591985.184, // Lands
					new Pioneer.Vector3(-1918.7633689113482, -4478.864184129612, 4103.897124245734),
					'earth'
				);
				return keyframeControllerLand;
			}
		}, {
			// Get the landing animation above into the J2000 frame.
			type: 'rotateByEntityOrientation',
			coverage: [190591163, 190591985.184]
		}]
	}
});
