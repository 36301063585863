/** @module pioneer */

/**
 * The global configuration. It has event triggering when values change.
 * It has the built-in default keys:
 * <ul>
 * <p>maxTextureSize = 512</p>
 * <p>useTextureCompression = true</p>
 * <p>fontFamily = 'Arial'</p>
 * <p>fontSize = 16</p>
 * <p>gammaCorrection = 1.0</p>
 * </ul>
 */
export class Config {
	/**
	 * Constructor. Called by Engine.
	 */
	constructor() {
		/**
		 * The map of keys to values.
		 * @type {Map<string, string | number | boolean>}
		 * @private
		 */
		this._valueMap = new Map();

		/**
		 * The map of keys to callbacks.
		 * @type {Map<string, ((key: string, value: string | number | boolean) => any)[]>}
		 * @private
		 */
		this._callbacksMap = new Map();

		// Setup some defaults.
		this.setValue('maxTextureSize', 512);
		this.setValue('useTextureCompression', true);
		this.setValue('fontFamily', 'Arial');
		this.setValue('fontSize', 16);
		this.setValue('gammaCorrection', 1.0);
	}

	/**
	 * Gets the value for a given key, or undefined if the key is not found.
	 * @param {string} key - the key of the value to get
	 * @returns {string | number | boolean | undefined}
	 */
	getValue(key) {
		return this._valueMap.get(key);
	}

	/**
	 * Sets a value for the given key. Calls any added event listeners.
	 * @param {string} key - the key to set
	 * @param {string | number | boolean} value - the value to set
	 */
	setValue(key, value) {
		const changed = this._valueMap.get(key) !== value;
		if (changed) {
			this._valueMap.set(key, value);
			const callbacks = this._callbacksMap.get(key);
			if (callbacks) {
				for (let i = 0; i < callbacks.length; i++) {
					callbacks[i](key, value);
				}
			}
		}
	}

	/**
	 * Adds a callback to be called when the value of the key changes.
	 * @param {string} key - the key for the callback
	 * @param {(key: string, value: string | number | boolean) => any} callback - the callback
	 */
	addEventListener(key, callback) {
		let callbacks = this._callbacksMap.get(key);
		if (callbacks === undefined) {
			callbacks = [];
			this._callbacksMap.set(key, callbacks);
		}
		callbacks.push(callback);
	}

	/**
	 * Removes the callback of the key.
	 * @param {string} key - the key for the callback
	 * @param {(key: string, value: string | number | boolean) => any} callback - the callback
	 */
	removeEventListener(key, callback) {
		const callbacks = this._callbacksMap.get(key);
		if (callbacks) {
			for (let i = 0; i < callbacks.length; i++) {
				if (callbacks[i] === callback) {
					callbacks.splice(i, 1);
					break;
				}
			}
		}
	}
}
