/* eslint-disable camelcase */
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import SpacecraftWithInstruments from '../components/spacecraft_with_instruments';

import { spacecraftStore, cameraStore, datasetStore, appStore, videoStore, previewStore, eventsStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';
import { useSnapshot } from 'valtio';
import { getConstellationNameFromId, getSpacecraftIdFromParam, getSpacecraftInstrumentData } from '../helpers/tools';
import { hideLoading } from '../components/loading';

const SpacecraftVisibility = () => {
	const prevEnabledSC = useRef(null);
	const { currentSpacecraft, isSatellitesNow } = useSnapshot(spacecraftStore.state);
	const { currentEvent } = useSnapshot(eventsStore.state);
	const { currentVideo } = useSnapshot(videoStore.state);
	const { previewEventData } = useSnapshot(previewStore.state);
	const { currentDataset, isVisibleEarth } = useSnapshot(datasetStore.state);
	const { isCameraTransitioning } = useSnapshot(cameraStore.state);

	// Show all spacecraft except when on Terra/Aqua Modis dataset
	const showAll = isSatellitesNow || (isVisibleEarth && !currentDataset?.externalId?.includes('modis') && !currentVideo && !previewEventData && !(currentEvent?.type === 'global'));

	// useEffect to determine the spacecraft constellation to show with a current spacecraft.
	useEffect(() => {
		if (currentSpacecraft) {
			// Default spacecraft to show.
			const spacecraftToShow = [...currentSpacecraft.constellationIds];
			// Additional spacecraft to show when transitioning.
			isCameraTransitioning && Array.isArray(prevEnabledSC.current) && spacecraftToShow.push(...prevEnabledSC.current);

			// Remove duplicates.
			const uniqueSpacecraftToShow = spacecraftToShow.length ? [...new Set(spacecraftToShow)] : false;

			spacecraftStore.setGlobalState({ enabledSC: uniqueSpacecraftToShow });
		}
	}, [isCameraTransitioning, currentSpacecraft]);

	// useEffect to set prevEnabledSC when transitioning
	useEffect(() => {
		// Set prevSpacecraft once transitioning.
		if (isCameraTransitioning === false) {
			const { enabledSC } = spacecraftStore.stateSnapshot;
			prevEnabledSC.current = enabledSC;
		}
	}, [isCameraTransitioning]);

	// useEffect to determine the spacecraft to show with a current dataset.
	useEffect(() => {
		if (currentDataset && !currentSpacecraft) {
			const { getManager } = globalRefs;
			const { missionIds: videoRelatedMissions = [] } = currentVideo || {};
			const { missionIds: datasetRelatedMissions = [] } = currentDataset;

			const spacecraftToShow = currentVideo ? [...videoRelatedMissions] : [...datasetRelatedMissions];
			const showSpacecraft = spacecraftToShow.length && !previewEventData;
			// Additional spacecraft to show when transitioning.
			isCameraTransitioning && Array.isArray(prevEnabledSC.current) && spacecraftToShow.push(...prevEnabledSC.current);

			// Came from some future spacecraft.
			const allValue = spacecraftToShow.some(sc => getManager('scene').isFutureSC(sc)) ? true : 'operating';

			// Remove duplicates.
			const uniqueSpacecraftToShow = showAll ? allValue : (showSpacecraft ? [...new Set(spacecraftToShow)] : false);

			spacecraftStore.setGlobalState({ enabledSC: uniqueSpacecraftToShow });
		}
	}, [
		isCameraTransitioning,
		currentDataset,
		currentSpacecraft,
		currentVideo,
		previewEventData,
		showAll
	]);
};

const SpacecraftDetail = () => {
	const { isZoomedOut, spacecraftId } = useSnapshot(spacecraftStore.state);

	// useEffect for grace trails exception.
	useEffect(() => {
		const { pioneer } = globalRefs;
		const scene = pioneer.get('main');

		// Grace trails turned on when zoomed out.
		const gfo1 = scene.get('sc_grace_fo1');
		const gfo2 = scene.get('sc_grace_fo2');

		gfo1 && gfo1.getComponentByType('trail').setEnabled(isZoomedOut);
		gfo2 && gfo2.getComponentByType('trail').setEnabled(isZoomedOut);

		// Make sure trails are always enabled otherwise.
		return () => {
			gfo1 && gfo1.getComponentByType('trail').setEnabled(true);
			gfo2 && gfo2.getComponentByType('trail').setEnabled(true);
		};
	}, [spacecraftId, isZoomedOut]);

	/**
	 * TODO: We need to regularly check (with an interval maybe every second) whether the spacecraft is still in coverage.
	 * If the spacecraft's mission ends while the user is looking at it, it's a very rare case.
	 * But we need to handle it gracefully. Eg. With a modal message and possible redirect.
	 */


	/**
	 * We want to show the SpacecraftWithInstruments if spacecraftId is either:
	 * A parent of instruments (e.g. sc_iss)
	 * An instrument itself (e.g. sc_iss_oco3)
	 */
	// Spacecraft with instruments - ISS instruments OCO-3, Ecostress, EMIT
	const { childInstruments, parentOfInstrument } = getSpacecraftInstrumentData(spacecraftId) || {};
	const showSpacecraftWithInstruments = (childInstruments || parentOfInstrument) && !isZoomedOut;

	return showSpacecraftWithInstruments
		? <SpacecraftWithInstruments />
		: null;
};

/**
 * Wrapper component to make sure SpacecraftDetail component is only rendered after spacecraft are created.
 * If isSceneReady is true, it means we have fetched the spacecraft data AND created the spacecraft in pioneer.
 */
export default function SpacecraftDetailWrapper() {
	const { isSceneReady } = useSnapshot(appStore.state);
	const { spacecraftParam } = useParams();
	const spacecraftId = getSpacecraftIdFromParam(spacecraftParam);

	// useEffect for setting currentSpacecraft in global state.
	useEffect(() => {
		if (isSceneReady) {
			// Set currentSpacecraft.
			const { spacecraftList } = spacecraftStore.stateSnapshot;
			const constellationName = getConstellationNameFromId(spacecraftId);
			const currentSpacecraft = spacecraftList.find(({ external_id }) => external_id.includes(constellationName));

			if (currentSpacecraft) {
				/**
				 * NOTE: It's important to make a shallow copy of the currentSpacecraft object
				 * because if we're going between spacecraft of the same constellation,
				 * the currentSpacecraft object may be the same object reference.
				 */
				spacecraftStore.setGlobalState({
					currentSpacecraft: { ...currentSpacecraft },
					spacecraftId,
					isZoomedOut: false
				});
			}
		}
	}, [isSceneReady, spacecraftId]);

	// useEffect to set currentSpacecraft and hideLoading to null only on unmount.
	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			spacecraftStore.setGlobalState({ currentSpacecraft: null, spacecraftId: null });
			hideLoading('model');
		};
	}, []);

	return isSceneReady ? <SpacecraftDetail /> : null;
}

export { SpacecraftVisibility };
