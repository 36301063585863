/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	Color,
	DynamoController,
	Entity,
	EntityRef,
	Interval,
	MathUtils,
	Quaternion,
	SpoutComponent,
	THREE,
	ThreeJsHelper,
	Vector2,
	Vector3
} from '../../internal';

/**
 * Point used in Trail component.
 * @private
 */
class Point {
	/**
	 * Constructor.
	 */
	constructor() {
		/**
		 * The time of the point.
		 * @type {number}
		 */
		this.time = 0;

		/**
		 * The position of the entity at this time.
		 * @type {Vector3}
		 */
		this.position = new Vector3();

		/**
		 * The velocity of the entity at this time.
		 * @type {Vector3}
		 */
		this.velocity = new Vector3();
	}
}

/**
 * The trail component.
 */
export class TrailComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The color of the trail.
		 * @type {Color}
		 * @private
		 */
		this._color = new Color(1, 1, 1, 1);
		this._color.freeze();

		/**
		 * The alpha value to which the trail fades (_points[0] is this value)
		 * @type {number}
		 * @private
		 */
		this._alphaFade = 0;

		/**
		 * The minimum width for the trail.
		 * @type {number}
		 * @private
		 */
		this._widthMin = 0;

		/**
		 * The maximum width for the trail.
		 * @type {number}
		 * @private
		 */
		this._widthMax = 2;

		/**
		 * A flag the determines whether or not the trail ignores the distance when determining visibility.
		 * @type {boolean}
		 * @private
		 */
		this._ignoreDistance = false;

		/**
		 * The start time. May be absolute or relative.
		 * @type {number | undefined}
		 * @private
		 */
		this._startTime = undefined;

		/**
		 * The end time. May be absolute or relative.
		 * @type {number|undefined}
		 * @private
		 */
		this._endTime = 0;

		/**
		 * Whether the start time is absolute or relative.
		 * @type {boolean}
		 * @private
		 */
		this._relativeStartTime = true;

		/**
		 * Whether the end time is absolute or relative.
		 * @type {boolean}
		 * @private
		 */
		this._relativeEndTime = true;

		/**
		 * The multiplier of the start time to use. Only valid when startTime is relative.
		 * @type {number}
		 * @private
		 */
		this._startTimeMultiplier = 1;

		/**
		 * The multiplier of the end time to use. Only valid when endTime is relative.
		 * @type {number}
		 * @private
		 */
		this._endTimeMultiplier = 1;

		/**
		 * The entity that the trail is relative to. Defaults to entity's parent if null.
		 * @type {EntityRef}
		 * @private
		 */
		this._relativeToEntity = new EntityRef(this.getEntity().getScene());

		/**
		 * Flag if the trail is relative to the parent entity's orientation.
		 * @type {boolean}
		 * @private
		 */
		this._relativeToEntityOrientation = false;

		/**
		 * The maximum angle in radians allowed between segments before they are split.
		 * @type {number}
		 * @private
		 */
		this._angleCurveThreshold = 0.05235987755; // 3 degrees

		/**
		 * The initial time step for following the trail. Undefined means the trail length / the angular curve threshold.
		 * @type {number | undefined}
		 * @private
		 */
		this._initialTimeStep = undefined;

		/**
		 * The circular buffer of points.
		 * @type {Array<Point>}
		 * @private
		 */
		this._points = [];

		/**
		 * The start index for the points array.
		 * @type {number}
		 * @private
		 */
		this._pointsStart = 0;

		/**
		 * The number of items in the points array.
		 * @type {number}
		 * @private
		 */
		this._pointsCount = 0;

		/**
		 * The entity that the trail is relative-to.
		 * @type {EntityRef}
		 * @private
		 */
		this._currentRelativeToEntity = new EntityRef(this.getEntity().getScene());

		/**
		 * The dash length for dashed lines.
		 * @type {number}
		 * @private
		 */
		this._dashLength = 1;

		/**
		 * The dash gap length for dashed lines.
		 * @type {number}
		 * @private
		 */
		this._dashGapLength = 0;

		/**
		 * The glow width for the lines.
		 * @type {number}
		 * @private
		 */
		this._glowWidth = 0;

		/**
		 * An offset time for the dash offset.
		 * @type {number}
		 * @private
		 */
		this._dashOffsetTime = 0;

		/**
		 * The flag whether or not to update the positions of each point periodically.
		 * Normally not used, except in cases like GroundClamped entities on CMTS where
		 * the position can change after a point is put down.
		 * @type {boolean}
		 * @private
		 */
		this._updatePointPositions = false;

		/**
		 * The index in the points array of the last position updated, used to keep track of which
		 * point needs to be updated next.
		 * @type {number}
		 * @private
		 */
		this._updatePointPositionsLastIndex = 0;

		// Make the radius infinite since this should always show.
		this.__setRadius(Number.POSITIVE_INFINITY);
	}

	/**
	 * Gets the color of the trail.
	 * @returns {Color}
	 */
	getColor() {
		return this._color;
	}

	/**
	 * Sets the color of the trail. Defaults to white.
	 * @param {Color} color - The color to set
	 */
	setColor(color) {
		this._color.thaw();
		this._color.copy(color);
		this._color.freeze();
		ThreeJsHelper.setUniformColorRGBA(this.getThreeJsMaterials()[0], 'color', this._color);
	}

	/**
	 * Gets the value to which the trail alpha fades, between 0 and 1. Defaults to 0.
	 * @returns {number}
	 */
	getAlphaFade() {
		return this._alphaFade;
	}

	/**
	 * Sets the min and max widths for the trail.
	 * @param {number} min
	 * @param {number} max
	 */
	setWidths(min, max) {
		this._widthMin = min;
		this._widthMax = max;
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'widthMin', min);
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'widthMax', max);
	}

	/**
	 * Sets the value to which trail alpha fades, between 0 and 1. Defaults to 0.
	 * @param {number} alphaFade - the value to set
	 */
	setAlphaFade(alphaFade) {
		this._alphaFade = alphaFade;
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'alphaFade', alphaFade);
	}

	/**
	 * Sets the line dash and gap length.
	 * @param {number} dashLength
	 * @param {number} dashGapLength
	 */
	setDashLength(dashLength, dashGapLength) {
		this._dashLength = dashLength;
		this._dashGapLength = dashGapLength;
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'dashLength', dashLength);
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'dashGapLength', dashGapLength);
	}

	/**
	 * Sets the glow for the lines.
	 * @param {number} glowWidth
	 */
	setGlowWidth(glowWidth) {
		this._glowWidth = glowWidth;
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'glowWidth', this._glowWidth);
	}

	/**
	 * Gets the start time of the trail. If relative start time is true, this is a positive offset time. Otherwise it is absolute. If it is undefined and relative time is true, it is a dynamic length. Defaults to undefined.
	 * @returns {number | undefined}
	 */
	getStartTime() {
		return this._startTime;
	}

	/**
	 * Sets the start time of the trail. If relative start time is true, this is a positive offset time. Otherwise it is absolute.
	 * If it is undefined and relative start time is true, the length is dynamic. Default is undefined.
	 * @param {number | undefined} value - The value to set
	 */
	setStartTime(value) {
		this._startTime = value;
	}

	/**
	 * Gets the end time of the trail. If relative time is true, this is a positive offset time. Otherwise it is absolute. If it is undefined and relative time is true, it is a dynamic length. Defaults to 0.
	 * @returns {number | undefined}
	 */
	getEndTime() {
		return this._endTime;
	}

	/**
	 * Sets the end time of the trail. If relative end time is true, this is a positive offset time. Otherwise it is absolute.
	 * If it is undefined and relative time is true, the length is dynamic.
	 * @param {number | undefined} value - The value to set
	 */
	setEndTime(value) {
		this._endTime = value;
	}

	/**
	 * Returns true if the trail start time is relative to the current time. Defaults to true.
	 * @returns {boolean}
	 */
	isRelativeStartTime() {
		return this._relativeStartTime;
	}

	/**
	 * Sets if the trail start time is relative to the current time.
	 * @param {boolean} value - The value to set
	 */
	setRelativeStartTime(value) {
		this._relativeStartTime = value;
		this.resetPoints();
	}

	/**
	 * Returns true if the trail end time is relative to the current time. Defaults to true.
	 * @returns {boolean}
	 */
	isRelativeEndTime() {
		return this._relativeEndTime;
	}

	/**
	 * Sets if the trail end time is relative to the current time.
	 * @param {boolean} value - The value to set
	 */
	setRelativeEndTime(value) {
		this._relativeEndTime = value;
		this.resetPoints();
	}

	/**
	 * Gets the multiplier of the start time to use. Only valid when startTime is relative. Defaults to 1.
	 * @returns {number}
	 */
	getStartTimeMultiplier() {
		return this._startTimeMultiplier;
	}

	/**
	 * Sets the multiplier of the start time to use. Only valid when startTime is relative. Defaults to 1.
	 * @param {number} startTimeMultiplier
	 */
	setStartTimeMultiplier(startTimeMultiplier) {
		this._startTimeMultiplier = startTimeMultiplier;
	}

	/**
	 * Gets the multiplier of the end time to use. Only valid when endTime is relative. Defaults to 1.
	 * @returns {number}
	 */
	getEndTimeMultiplier() {
		return this._endTimeMultiplier;
	}

	/**
	 * Sets the multiplier of the end time to use. Only valid when endTime is relative. Defaults to 1.
	 * @param {number} endTimeMultiplier
	 */
	setEndTimeMultiplier(endTimeMultiplier) {
		this._endTimeMultiplier = endTimeMultiplier;
	}

	/**
	 * Gets the entity name that the trail is relative to.
	 * @returns {string}
	 */
	getRelativeToEntity() {
		return this._relativeToEntity.getName();
	}

	/**
	 * Sets the entity name that the trail is relative to. Defaults to the parent if ''.
	 * @param {string} entityName
	 */
	setRelativeToEntity(entityName) {
		this._relativeToEntity.setName(entityName);
		this._currentRelativeToEntity.setName(entityName);
		this.resetPoints();
	}

	/**
	 * Returns true if the trail is relative to the parent entity's orientation. Defaults to false.
	 * @returns {boolean}
	 */
	isRelativeToEntityOrientation() {
		return this._relativeToEntityOrientation;
	}

	/**
	 * Sets if the trail is relative to the parent entity's orientation. Used for landers and ground objects.
	 * @param {boolean} enable
	 */
	setRelativeToEntityOrientation(enable) {
		this._relativeToEntityOrientation = enable;
		if (!enable) {
			ThreeJsHelper.setOrientation(this.getThreeJsObjects(), Quaternion.Identity);
		}
		this.resetPoints();
	}

	/**
	 * Gets the maximum angle in radians allowed between segments before they are split.
	 * @returns {number}
	 */
	getAngleCurveThreshold() {
		return this._angleCurveThreshold;
	}

	/**
	 * Sets the maximum angle in radians allowed between segments before they are split. Defaults to 3 degrees.
	 * @param {number} angleCurveThreshold
	 */
	setAngleCurveThreshold(angleCurveThreshold) {
		this._angleCurveThreshold = angleCurveThreshold;
		this.resetPoints();
	}

	/**
	 * Gets the initial time step for following the trail. Undefined means the trail length / the angular curve threshold.
	 * @returns {number | undefined}
	 */
	getInitialTimeStep() {
		return this._initialTimeStep;
	}

	/**
	 * Sets the initial time step for following the trail. Undefined means the trail length / the angular curve threshold. Defaults to undefined.
	 * @param {number | undefined} initialTimeStep
	 */
	setInitialTimeStep(initialTimeStep) {
		this._initialTimeStep = initialTimeStep;
		this.resetPoints();
	}

	/**
	 * Ignores the distance when determining whether it should show the trail or not. Defaults to false.
	 * @param {boolean} enable
	 */
	setIgnoreDistance(enable) {
		this._ignoreDistance = enable;
	}

	/**
	 * Gets the flag whether or not to update the positions of each point periodically.
	 * Normally not used, except in cases like GroundClamped entities on CMTS where
	 * the position can change after a point is put down.
	 * @returns {boolean}
	 */
	getUpdatePointPositions() {
		return this._updatePointPositions;
	}

	/**
	 * Sets the flag whether or not to update the positions of each point periodically.
	 * Normally not used, except in cases like GroundClamped entities on CMTS where
	 * the position can change after a point is put down.
	 * @param {boolean} updatePointPositions
	 */
	setUpdatePointPositions(updatePointPositions) {
		this._updatePointPositions = updatePointPositions;
	}

	/**
	 * Resets the points on the trail, so that they will be newly added. Useful for when the entity's controllers have bee modified and the whole trail needs to be updated.
	 */
	resetPoints() {
		this._points = [];
		this._pointsCount = 0;
		this._pointsStart = 0;

		if (this.getThreeJsObjects().length > 0) {
			const geometry = (/** @type {THREE.Mesh} */(this.getThreeJsObjects()[0])).geometry;
			const newArray = new Float32Array(0);
			const buffer = new THREE.InterleavedBuffer(newArray, TrailComponent.VERTEX_SIZE);
			geometry.setAttribute('position', new THREE.InterleavedBufferAttribute(buffer, 3, 0, false));
			geometry.setAttribute('positionPrev', new THREE.InterleavedBufferAttribute(buffer, 3, 3, false));
			geometry.setAttribute('positionNext', new THREE.InterleavedBufferAttribute(buffer, 3, 6, false));
			geometry.setAttribute('side', new THREE.InterleavedBufferAttribute(buffer, 1, 9, false));
			geometry.setAttribute('index', new THREE.InterleavedBufferAttribute(buffer, 1, 10, false));
			geometry.setAttribute('dashOffset', new THREE.InterleavedBufferAttribute(buffer, 1, 11, false));

			// Setup the index buffer.
			geometry.setIndex(new THREE.BufferAttribute(new Uint16Array(0), 1));
		}
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	__loadResources() {
		// Create the material.
		const material = this.getEntity().getScene().getEngine().getMaterialManager().getPreloaded('trail');
		this.getThreeJsMaterials().push(material);
		material.uniforms['dashLength'].value = this._dashLength;
		material.uniforms['dashGapLength'].value = this._dashGapLength;
		material.uniforms['indexStart'].value = 0;
		material.uniforms['indexCount'].value = 0;
		material.uniforms['indexLength'].value = 0;
		material.uniforms['color'].value.set(this._color.r, this._color.g, this._color.b, this._color.a);
		material.uniforms['widthMin'].value = this._widthMin;
		material.uniforms['widthMax'].value = this._widthMax;
		material.uniforms['glowWidth'].value = this._glowWidth;
		material.uniforms['alphaFade'].value = this._alphaFade;

		// Create the object.
		const threeJsObject = ThreeJsHelper.createMeshObject(this, material, [
			{ name: 'position', dimensions: 3 },
			{ name: 'positionPrev', dimensions: 3 },
			{ name: 'positionNext', dimensions: 3 },
			{ name: 'side', dimensions: 1 },
			{ name: 'index', dimensions: 1 },
			{ name: 'dashOffset', dimensions: 1 }
		], true);
		this.getThreeJsObjects().push(threeJsObject);

		// Return the resolved promise.
		return Promise.resolve();
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		this.getEntity().getScene().getEngine().getMaterialManager().release(this.getThreeJsMaterials()[0]);
		ThreeJsHelper.destroyAllObjectsAndMaterials(this);
		this._points = [];
		this._pointsCount = 0;
		this._pointsStart = 0;
	}

	/**
	 * Prepare the component for rendering.
	 * @param {CameraComponent} camera
	 * @override
	 * @internal
	 */
	__prepareForRender(camera) {
		// If the parent has changed, we need to reset the points so that they work with the new parent.
		if (this._relativeToEntity.getName() === '' && this.getEntity().getParent() !== null && this._currentRelativeToEntity.get() !== this.getEntity().getParent()) {
			this._currentRelativeToEntity.setName(this.getEntity().getParent().getName());
			this.resetPoints();
		}
		if (this._currentRelativeToEntity === null) {
			return;
		}

		// Update the points and set the calculated positions and colors.
		this._updatePoints();

		// Set the index start, count, and length uniforms.
		const material = this.getThreeJsMaterials()[0];
		material.uniforms['indexStart'].value = this._pointsStart;
		material.uniforms['indexCount'].value = this._pointsCount;
		material.uniforms['indexLength'].value = this._points.length;

		// Set the alpha multiplier based on conditions.
		let alphaMultiplier = 1.0;
		if (!this._ignoreDistance) {
			// Set the alpha multiplier based on the camera distance to the entity.
			const normalizedSizeOfEntity = this.getEntity().getNormalSpaceExtentsRadius(camera);
			alphaMultiplier *= (0.02 - normalizedSizeOfEntity) / 0.02;

			// When the position is too far, there's imprecision "wiggle" in the shader. This fades the trail before the wiggle occurs.
			const camDistOverPosDist = this.getEntity().getCameraSpacePosition(camera).magnitude() / this.getEntity().getPosition().magnitude();
			alphaMultiplier *= MathUtils.clamp01(camDistOverPosDist * 1000);
		}
		ThreeJsHelper.setUniformNumber(material, 'alphaMultiplier', alphaMultiplier);

		// Set the pixel size.
		// If the camera is a Spout camera, make the lines thicker and use Spout for the render size.
		const viewportSize = Vector2.pool.get();
		if (camera instanceof SpoutComponent) {
			viewportSize.set(camera.getRenderWidth() * 0.1, camera.getRenderWidth() * 0.5 * 0.1);
		}
		// Otherwise use the viewport size.
		else {
			viewportSize.copy(camera.getViewport().getBounds().size);
		}
		ThreeJsHelper.setUniformVector2(material, 'viewportSize', viewportSize);
		Vector2.pool.release(viewportSize);

		// Set the position and orientation of the Three.js objects.
		const currentRelativeToEntity = this._currentRelativeToEntity.get();
		if (currentRelativeToEntity !== null) {
			// Set the Three.js object position the relative-to-entity's camera-space position.
			ThreeJsHelper.setPosition(this.getThreeJsObjects(), currentRelativeToEntity.getCameraSpacePosition(camera));

			// If relativeToEntityOrientation is set, set the orientation to that entity.
			if (this._relativeToEntityOrientation) {
				ThreeJsHelper.setOrientation(this.getThreeJsObjects(), currentRelativeToEntity.getOrientation());
			}
		}
	}

	/**
	 * Updates the points array.
	 * @private
	 */
	_updatePoints() {
		const intervalForUpdate = Interval.pool.get();

		// Determine the interval that will be updated.
		this._getIntervalForUpdate(intervalForUpdate);

		// If one of the values is NaN, don't update the trail.
		if (isNaN(intervalForUpdate.min) || isNaN(intervalForUpdate.max)) {
			Interval.pool.release(intervalForUpdate);
			return;
		}

		// Determine the step for the vertices to be added.
		// For a circular trail, 360 degrees total / 3 degrees each = 120 steps.
		const initialTimeStep = this._initialTimeStep ?? (intervalForUpdate.length() * this._angleCurveThreshold / (2 * Math.PI));

		// Get the start and end index ranges.
		const updateRange = Interval.pool.get();
		updateRange.set(Number.POSITIVE_INFINITY, 0);

		// FRONT POINTS

		// Pop off one end point.
		if (this._relativeStartTime && this._pointsCount > 0 && this._points[this._pointsStart].time !== intervalForUpdate.min) {
			this._popFrontPoint(updateRange);
		}

		// Clear off any excess end points.
		while (this._pointsCount > 0 && this._points[this._pointsStart].time < intervalForUpdate.min) {
			this._popFrontPoint(updateRange);
		}

		// Add on any new end points.
		let pointsAddedThisFrame = 0;
		while (this._pointsCount === 0 || this._points[this._pointsStart].time > intervalForUpdate.min) {
			// If we have a ton of points and we're in relative mode, don't add any more.
			if (this._pointsCount >= 16000 && this._relativeStartTime) {
				break;
			}

			// Create new point.
			this._pushFrontPoint(updateRange);
			const nextPoint = this._points[this._pointsStart];

			// Get a starting step.
			let minStep = 1;
			let maxStep = Number.POSITIVE_INFINITY;
			let step = initialTimeStep;
			if (this._pointsCount > 2) {
				step = this._points[(this._pointsStart + 2) % this._points.length].time
					- this._points[(this._pointsStart + 1) % this._points.length].time;
			}

			// Get the next time, taking into account curvature.
			let curvatureCheckCount = 0;
			while (curvatureCheckCount < 20) {
				let nextTime = intervalForUpdate.max;
				if (this._pointsCount > 1) {
					nextTime = this._points[(this._pointsStart + 1) % this._points.length].time - step;
				}
				if (nextTime < intervalForUpdate.min) {
					nextTime = intervalForUpdate.min;
				}

				// Get the next position and/or orientation states.
				nextPoint.time = nextTime;
				this._getPositionAndVelocity(nextPoint.position, nextPoint.velocity, nextPoint.time);

				// Invalid position and/or orientation states, so we can't draw any more trail.
				if (nextPoint.position.isNaN()) {
					break;
				}

				// Get angle for curvature checking.
				let angle = 0;
				if (this._pointsCount >= 2) {
					// If its too small a distance, make the angle NaN so the step increases.
					if (this._points[(this._pointsStart + 1) % this._points.length].position.distance(nextPoint.position) < 0.001) {
						angle = Number.NaN;
					}
					else {
						angle = this._points[(this._pointsStart + 1) % this._points.length].velocity.angle(nextPoint.velocity);
					}
				}

				// Grow or shrink the step depending on the angle.
				if (angle > this._angleCurveThreshold) { // too big of an angle, so shrink the step.
					maxStep = step;
					step = (minStep + step) / 2.0;
				}
				else if (this._pointsCount > 1 && step < initialTimeStep * 10 && (isNaN(angle) || angle < this._angleCurveThreshold / 3) && nextTime !== intervalForUpdate.min) { // too small of an angle, so grow the step.
					minStep = step;
					if (maxStep === Number.POSITIVE_INFINITY) {
						step *= 2;
					}
					else {
						step = (step + maxStep) / 2.0;
					}
				}
				else {
					break;
				}
				curvatureCheckCount++;
			}
			if (nextPoint.position.isNaN()) {
				this._popFrontPoint(updateRange);
				break;
			}
			pointsAddedThisFrame++;
			if (pointsAddedThisFrame >= 4) {
				break;
			}
		}

		// BACK POINTS

		// Pop off one end point.
		if (this._relativeEndTime && this._pointsCount > 0 && this._points[(this._pointsStart + this._pointsCount - 1) % this._points.length].time !== intervalForUpdate.max) {
			this._popBackPoint(updateRange);
		}

		// Clear off any excess end points.
		while (this._pointsCount > 0 && this._points[(this._pointsStart + this._pointsCount - 1) % this._points.length].time > intervalForUpdate.max) {
			this._popBackPoint(updateRange);
		}

		// Add on any new end points.
		pointsAddedThisFrame = 0;
		while (this._pointsCount === 0 || this._points[(this._pointsStart + this._pointsCount - 1) % this._points.length].time < intervalForUpdate.max) {
			// If we have a ton of points and we're in relative mode, don't add any more.
			if (this._pointsCount >= 16000 && this._relativeEndTime) {
				break;
			}

			// Create new point.
			this._pushBackPoint(updateRange);
			const nextPoint = this._points[(this._pointsStart + this._pointsCount - 1) % this._points.length];

			// Get a starting step.
			let minStep = 1;
			let maxStep = Number.POSITIVE_INFINITY;
			let step = initialTimeStep;
			if (this._pointsCount > 2) {
				step = this._points[(this._pointsStart + this._pointsCount - 2) % this._points.length].time
					- this._points[(this._pointsStart + this._pointsCount - 3) % this._points.length].time;
			}

			// Get the next time, taking into account curvature.
			let curvatureCheckCount = 0;
			while (curvatureCheckCount < 20) {
				let nextTime = intervalForUpdate.min;
				if (this._pointsCount > 1) {
					nextTime = this._points[(this._pointsStart + this._pointsCount - 2) % this._points.length].time + step;
				}
				if (nextTime > intervalForUpdate.max) {
					nextTime = intervalForUpdate.max;
				}

				// Get the next position and/or orientation states.
				nextPoint.time = nextTime;
				this._getPositionAndVelocity(nextPoint.position, nextPoint.velocity, nextPoint.time);

				// Invalid position and/or orientation states, so we can't draw any more trail.
				if (nextPoint.position.isNaN()) {
					break;
				}

				// Get angle for curvature checking.
				let angle = 0;
				if (this._pointsCount >= 2) {
					// If its too small a distance, make the angle NaN so the step increases.
					if (this._points[(this._pointsStart + this._pointsCount - 2) % this._points.length].position.distance(nextPoint.position) < 0.001) {
						angle = Number.NaN;
					}
					else {
						angle = this._points[(this._pointsStart + this._pointsCount - 2) % this._points.length].velocity.angle(nextPoint.velocity);
					}
				}

				// Grow or shrink the step depending on the angle.
				if (angle > this._angleCurveThreshold) { // too big of an angle, so shrink the step.
					maxStep = step;
					step = (minStep + step) / 2.0;
				}
				else if (this._pointsCount > 1 && step < initialTimeStep * 10 && (isNaN(angle) || angle < this._angleCurveThreshold / 3) && nextTime !== intervalForUpdate.max) { // too small of an angle, so grow the step.
					minStep = step;
					if (maxStep === Number.POSITIVE_INFINITY) {
						step *= 2;
					}
					else {
						step = (step + maxStep) / 2.0;
					}
				}
				else {
					break;
				}
				curvatureCheckCount++;
			}
			if (nextPoint.position.isNaN()) {
				this._popBackPoint(updateRange);
				break;
			}
			pointsAddedThisFrame++;
			if (pointsAddedThisFrame >= 4) {
				break;
			}
		}
		Interval.pool.release(intervalForUpdate);

		// UPDATE POINT POSITIONS

		// If enabled, go through a few points and update their positions, keeping the times the same.
		// Skip updates that result in NaNs.
		if (this._updatePointPositions && this._pointsCount > 0) {
			for (let i = 0, l = Math.min(this._pointsCount, 5); i < l; i++) {
				this._updatePointPositionsLastIndex = (this._updatePointPositionsLastIndex + 1) % this._pointsCount;
				const pointIndex = (this._pointsStart + this._updatePointPositionsLastIndex) % this._points.length;
				const point = this._points[pointIndex];
				const position = Vector3.pool.get();
				const velocity = Vector3.pool.get();
				this._getPositionAndVelocity(position, velocity, point.time);
				if (!position.isNaN()) {
					point.position.copy(position);
				}
				if (!velocity.isNaN()) {
					point.velocity.copy(velocity);
				}
				Vector3.pool.release(position);
				Vector3.pool.release(velocity);
				updateRange.expandTo(pointIndex);
			}
		}

		// UPDATE THE VERTEX ARRAY

		const geometry = (/** @type {THREE.Mesh} */(this.getThreeJsObjects()[0])).geometry;
		const buffer = /** @type {THREE.InterleavedBufferAttribute} */(geometry.attributes['positionCurr']).data;
		const array = /** @type {Float32Array} */(buffer.array);
		for (let i = updateRange.min, max = Math.min(this._points.length - 1, updateRange.max); i <= max; i++) {
			const thisPosition = this._points[i].position;
			const prevPosition = !isNaN(this._points[(i + this._points.length - 1) % this._points.length].position.x)
				? this._points[(i + this._points.length - 1) % this._points.length].position
				: thisPosition;
			const nextPosition = !isNaN(this._points[(i + 1) % this._points.length].position.x)
				? this._points[(i + 1) % this._points.length].position
				: thisPosition;

			array[i * 2 * TrailComponent.VERTEX_SIZE + 0] = thisPosition.x;
			array[i * 2 * TrailComponent.VERTEX_SIZE + 1] = thisPosition.y;
			array[i * 2 * TrailComponent.VERTEX_SIZE + 2] = thisPosition.z;
			array[(i * 2 + 1) * TrailComponent.VERTEX_SIZE + 0] = thisPosition.x;
			array[(i * 2 + 1) * TrailComponent.VERTEX_SIZE + 1] = thisPosition.y;
			array[(i * 2 + 1) * TrailComponent.VERTEX_SIZE + 2] = thisPosition.z;
			array[i * 2 * TrailComponent.VERTEX_SIZE + 3] = prevPosition.x;
			array[i * 2 * TrailComponent.VERTEX_SIZE + 4] = prevPosition.y;
			array[i * 2 * TrailComponent.VERTEX_SIZE + 5] = prevPosition.z;
			array[(i * 2 + 1) * TrailComponent.VERTEX_SIZE + 3] = prevPosition.x;
			array[(i * 2 + 1) * TrailComponent.VERTEX_SIZE + 4] = prevPosition.y;
			array[(i * 2 + 1) * TrailComponent.VERTEX_SIZE + 5] = prevPosition.z;
			array[i * 2 * TrailComponent.VERTEX_SIZE + 6] = nextPosition.x;
			array[i * 2 * TrailComponent.VERTEX_SIZE + 7] = nextPosition.y;
			array[i * 2 * TrailComponent.VERTEX_SIZE + 8] = nextPosition.z;
			array[(i * 2 + 1) * TrailComponent.VERTEX_SIZE + 6] = nextPosition.x;
			array[(i * 2 + 1) * TrailComponent.VERTEX_SIZE + 7] = nextPosition.y;
			array[(i * 2 + 1) * TrailComponent.VERTEX_SIZE + 8] = nextPosition.z;
			array[i * 2 * TrailComponent.VERTEX_SIZE + 9] = +1;
			array[(i * 2 + 1) * TrailComponent.VERTEX_SIZE + 9] = -1;
			array[i * 2 * TrailComponent.VERTEX_SIZE + 10] = i;
			array[(i * 2 + 1) * TrailComponent.VERTEX_SIZE + 10] = i;
			array[i * 2 * TrailComponent.VERTEX_SIZE + 11] = this._points[i].time - this._dashOffsetTime;
			array[(i * 2 + 1) * TrailComponent.VERTEX_SIZE + 11] = this._points[i].time - this._dashOffsetTime;
		}
		buffer.needsUpdate = true;
		buffer.updateRange.offset = updateRange.max < updateRange.min ? 0 : updateRange.min * 2 * TrailComponent.VERTEX_SIZE;
		buffer.updateRange.count = updateRange.max < updateRange.min ? -1 : (updateRange.length() + 1) * 2 * TrailComponent.VERTEX_SIZE;

		Interval.pool.release(updateRange);
	}

	/**
	 * Gets the interval for updating the points.
	 * @param {Interval} intervalForUpdate
	 * @private
	 */
	_getIntervalForUpdate(intervalForUpdate) {
		const currentTime = (this._relativeStartTime || this._relativeEndTime)
			? this.getEntity().getScene().getEngine().getTime()
			: undefined;
		// Set the interval min from the start time.
		if (this._relativeStartTime) {
			if (this._startTime !== undefined) {
				intervalForUpdate.min = currentTime - this._startTimeMultiplier * this._startTime;
			}
			else { // We're using a dynamic length, dependent on the orbital parameters.
				intervalForUpdate.min = currentTime - this._startTimeMultiplier * this._getAutoLength(currentTime);
			}
		}
		else {
			intervalForUpdate.min = this._startTime;
		}
		// Set the interval max from the end time.
		if (this._relativeEndTime) {
			if (this._endTime !== undefined) {
				intervalForUpdate.max = currentTime + this._endTimeMultiplier * this._endTime;
			}
			else { // We're using a dynamic length, dependent on the position, speed, and orbital parameters.
				intervalForUpdate.max = currentTime + this._endTimeMultiplier * this._getAutoLength(currentTime);
			}
		}
		else {
			intervalForUpdate.max = this._endTime;
		}
		intervalForUpdate.intersection(intervalForUpdate, this.getEntity().getPositionCoverage());
	}

	/**
	 * Pops the front of the positions array.
	 * @param {Interval} updateRange
	 * @private
	 */
	_popFrontPoint(updateRange) {
		if (this._pointsCount > 0) {
			this._resize(this._pointsCount - 1, updateRange);
			updateRange.expandTo(this._pointsStart);
			if (this._pointsCount > 1) {
				updateRange.expandTo((this._pointsStart + 1) % this._points.length);
			}
			this._points[this._pointsStart].position.x = NaN;
			this._pointsStart = (this._pointsStart + 1) % this._points.length;
			this._pointsCount -= 1;
		}
	}

	/**
	 * Pops the back of the positions array.
	 * @param {Interval} updateRange
	 * @private
	 */
	_popBackPoint(updateRange) {
		if (this._pointsCount > 0) {
			this._resize(this._pointsCount - 1, updateRange);
			this._pointsCount -= 1;
			updateRange.expandTo((this._pointsStart + this._pointsCount) % this._points.length);
			if (this._pointsCount > 0) {
				updateRange.expandTo((this._pointsStart + this._pointsCount - 1) % this._points.length);
			}
			this._points[(this._pointsStart + this._pointsCount) % this._points.length].position.x = NaN;
		}
	}

	/**
	 * Pushes a value to the front of the positions array.
	 * @param {Interval} updateRange
	 * @private
	 */
	_pushFrontPoint(updateRange) {
		this._resize(this._pointsCount + 1, updateRange);
		this._pointsStart = (this._pointsStart + this._points.length - 1) % this._points.length;
		updateRange.expandTo(this._pointsStart);
		if (this._pointsCount > 0) {
			updateRange.expandTo((this._pointsStart + 1) % this._points.length);
		}
		this._pointsCount += 1;
	}

	/**
	 * Pushes a value to the back of the positions array.
	 * @param {Interval} updateRange
	 * @private
	 */
	_pushBackPoint(updateRange) {
		this._resize(this._pointsCount + 1, updateRange);
		this._pointsCount += 1;
		updateRange.expandTo((this._pointsStart + this._pointsCount - 1) % this._points.length);
		if (this._pointsCount > 1) {
			updateRange.expandTo((this._pointsStart + this._pointsCount - 2) % this._points.length);
		}
	}

	/**
	 * Pops the front of the positions array.
	 * @param {number} newCount
	 * @param {Interval} updateRange
	 * @private
	 */
	_resize(newCount, updateRange) {
		let resizing = false;
		let newSize = this._points.length;
		if (newCount + 1 > this._points.length
			|| (newCount <= this._points.length / 4 && newCount >= 8)) {
			resizing = true;
		}
		if (resizing) {
			newSize = Math.max(8, MathUtils.ceilPow2(newCount + 1));
			const points = /** @type {Point[]} */([]);
			// Copy over the original array of points, moving everything to start at 0.
			for (let i = 0, l = this._pointsCount; i < l; i++) {
				points.push(this._points[(this._pointsStart + i) % this._points.length]);
			}
			this._pointsStart = 0;
			// Double the size of the array of points.
			for (let i = this._pointsCount, l = newSize; i < l; i++) {
				const point = new Point();
				point.position.x = Number.NaN;
				points.push(point);
			}
			this._points = points;

			// Update the vertex buffer.
			const geometry = (/** @type {THREE.Mesh} */(this.getThreeJsObjects()[0])).geometry;
			const newArray = new Float32Array(this._points.length * 2 * TrailComponent.VERTEX_SIZE);
			const buffer = new THREE.InterleavedBuffer(newArray, TrailComponent.VERTEX_SIZE);
			geometry.setAttribute('positionCurr', new THREE.InterleavedBufferAttribute(buffer, 3, 0, false));
			geometry.setAttribute('positionPrev', new THREE.InterleavedBufferAttribute(buffer, 3, 3, false));
			geometry.setAttribute('positionNext', new THREE.InterleavedBufferAttribute(buffer, 3, 6, false));
			geometry.setAttribute('side', new THREE.InterleavedBufferAttribute(buffer, 1, 9, false));
			geometry.setAttribute('index', new THREE.InterleavedBufferAttribute(buffer, 1, 10, false));
			geometry.setAttribute('dashOffset', new THREE.InterleavedBufferAttribute(buffer, 1, 11, false));

			// Setup the index buffer.
			const indices = new Uint16Array(this._points.length * 6);
			for (let i = 0; i < this._points.length; i++) {
				indices[i * 6 + 0] = (i * 2 + 0) % (this._points.length * 2);
				indices[i * 6 + 1] = (i * 2 + 2) % (this._points.length * 2);
				indices[i * 6 + 2] = (i * 2 + 3) % (this._points.length * 2);
				indices[i * 6 + 3] = (i * 2 + 3) % (this._points.length * 2);
				indices[i * 6 + 4] = (i * 2 + 1) % (this._points.length * 2);
				indices[i * 6 + 5] = (i * 2 + 0) % (this._points.length * 2);
			}
			geometry.setIndex(new THREE.BufferAttribute(indices, 1));

			// Set the dash offset time, since every vertex will be updated anyway.
			this._dashOffsetTime = this._points[this._pointsStart].time;

			updateRange.min = 0;
			updateRange.max = newSize;
		}
	}

	/**
	 * Gets the auto-length of a trail, if the start or end time is undefined.
	 * @param {number} time
	 * @returns {number}
	 * @private
	 */
	_getAutoLength(time) {
		// Find the last dynamo that contains the time.
		let dynamoController = null;
		for (let i = 0; ; i++) {
			const aDynamoController = this.getEntity().getControllerByClass(DynamoController, i);
			if (aDynamoController === null) {
				break;
			}
			if (aDynamoController.getPointType() === 'orb' && aDynamoController.getCoverage().contains(time)) {
				dynamoController = aDynamoController;
			}
		}
		// Get the specific angular momentum and velocity for the calcs.
		const specificAngularMomentum = Vector3.pool.get();
		const velocityAtTime = Vector3.pool.get();
		this.getEntity().getPositionAtTime(specificAngularMomentum, time);
		this.getEntity().getVelocityAtTime(velocityAtTime, time);
		specificAngularMomentum.cross(specificAngularMomentum, velocityAtTime);
		let period = 0;
		if (dynamoController !== null) {
			// Get the dynamo params for the calcs.
			const eccentricity = dynamoController.getEccentricity(time);
			const gravParameter = dynamoController.getHeaderValue('gravitationalParameter1') + dynamoController.getHeaderValue('gravitationalParameter2');
			const specificOrbitalEnergy = -0.5 * (gravParameter * gravParameter / specificAngularMomentum.magnitudeSqr()) * (1 - eccentricity * eccentricity);
			period = 2 * Math.PI * gravParameter / Math.sqrt(8.0 * Math.abs(Math.min(1.0, specificOrbitalEnergy * specificOrbitalEnergy * specificOrbitalEnergy)));
		}
		else {
			period = 2 * Math.PI * specificAngularMomentum.magnitude() / velocityAtTime.magnitudeSqr();
		}
		Vector3.pool.release(specificAngularMomentum);
		Vector3.pool.release(velocityAtTime);
		return period;
	}

	/**
	 * Gets the position and the velocity relative to the relative-to entity at the given time.
	 * @param {Vector3} position
	 * @param {Vector3} velocity
	 * @param {number} time
	 * @private
	 */
	_getPositionAndVelocity(position, velocity, time) {
		const relativeToEntity = this._relativeToEntity.getName() !== '' ? this._relativeToEntity.get() : this.getEntity().getParent();
		if (this._relativeToEntity.getName() === '') { // The relative to entity is the parent at the given time.
			this.getEntity().getPositionAtTime(position, time);
			this.getEntity().getVelocityAtTime(velocity, time);
			const parentOfPositionName = this.getEntity().getParentAtTime(time);
			const currentRelativeToEntity = this._currentRelativeToEntity.get();
			if (currentRelativeToEntity !== null && parentOfPositionName !== '' && parentOfPositionName !== currentRelativeToEntity.getName()) {
				const parentOfPosition = this.getEntity().getScene().getEntity(parentOfPositionName);
				if (parentOfPosition !== null) {
					parentOfPosition.getPositionRelativeToEntity(position, position, currentRelativeToEntity, time);
					parentOfPosition.getVelocityRelativeToEntity(velocity, velocity, currentRelativeToEntity, time);
				}
				else {
					position.copy(Vector3.NaN);
					velocity.copy(Vector3.NaN);
				}
			}
		}
		else if (relativeToEntity !== null) {
			this.getEntity().getPositionRelativeToEntity(position, Vector3.Zero, relativeToEntity, time);
			this.getEntity().getVelocityRelativeToEntity(velocity, Vector3.Zero, relativeToEntity, time);
		}
		else {
			position.copy(Vector3.NaN);
			velocity.copy(Vector3.NaN);
		}
		if (this._relativeToEntityOrientation && relativeToEntity !== null) {
			const relativeToEntityOrientation = Quaternion.pool.get();
			relativeToEntity.getOrientationAtTime(relativeToEntityOrientation, time);
			position.rotateInverse(relativeToEntityOrientation, position);
			velocity.rotateInverse(relativeToEntityOrientation, velocity);
			Quaternion.pool.release(relativeToEntityOrientation);
		}
	}
}

TrailComponent.VERTEX_SIZE = 12;
