/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';

/**
 * A controller that points the entity's frame-space direction given by keyframes.
 */
export class KeyframePointingController extends Pioneer.BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Pioneer.Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The keyframes.
		 * @type {[number, string][]}
		 * @private
		 */
		this._keyframes = [];

		/**
		 * The frame-space direction to use for the pointing.
		 * @type {Pioneer.Vector3}
		 * @private
		 */
		this._direction = new Pioneer.Vector3(1, 0, 0);

		this.addModifiedState('orientation');
	}

	/**
	 * Sets the keyframes. Each keyframe is a [time, value]. Possible keyframe values are any entity name or 'velocity',
	 * with an optional prefix of '-' to indicate the opposite direction.
	 * @param {[number, string][]} keyframes
	 */
	setKeyframes(keyframes) {
		// Remove any previous dependent states.
		for (let i = 0, l = this._keyframes.length; i < l; i++) {
			let value = this._keyframes[i][1];
			if (value.startsWith('-')) {
				value = value.substring(1);
			}
			if (value === 'velocity') {
				this.removeDependentState(this.getEntity().getName(), 'velocity');
			}
			else {
				this.removeDependentState(value, 'position');
			}
		}
		this._keyframes = [];
		for (let i = 0, l = keyframes.length; i < l; i++) {
			this._keyframes.push([keyframes[i][0], keyframes[i][1]]);
			// Add any new dependent states.
			let value = this._keyframes[i][1];
			if (value.startsWith('-')) {
				value = value.substring(1);
			}
			if (value === 'velocity') {
				this.addDependentState(this.getEntity().getName(), 'velocity');
			}
			else {
				this.addDependentState(value, 'position');
			}
		}
		this._keyframes.sort((a, b) => a[0] - b[0]);
	}

	/**
	 * Sets the frame-space direction to use for the pointing.
	 * @param {Pioneer.Vector3} direction
	 */
	setDirection(direction) {
		this._direction.copy(direction);
	}

	/**
	 * Updates given orientation for the given time.
	 * @param {Pioneer.Quaternion} orientation - The orientation to update.
	 * @param {number} time - The time to check.
	 * @override
	 */
	__updateOrientationAtTime(orientation, time) {
		if (orientation.isNaN()) {
			orientation.copy(Pioneer.Quaternion.Identity);
		}
		this._getNewOrientation(orientation, time, orientation);
	}

	/**
	 * Updates the controller.
	 * @override
	 */
	__update() {
		if (this._keyframes.length === 0) {
			return;
		}
		const entity = this.getEntity();
		const time = entity.getScene().getEngine().getTime();
		if (entity.getOrientation().isNaN()) {
			entity.setOrientation(Pioneer.Quaternion.Identity);
		}
		const newOrientation = Pioneer.Quaternion.pool.get();
		this._getNewOrientation(newOrientation, time, entity.getOrientation());
		entity.setOrientation(newOrientation);
		Pioneer.Quaternion.pool.release(newOrientation);
	}

	/**
	 * Gets a new orientation, given the time and an existing orientation.
	 * @param {Pioneer.Quaternion} newOrientation
	 * @param {number} time
	 * @param {Pioneer.Quaternion} oldOrientation
	 * @private
	 */
	_getNewOrientation(newOrientation, time, oldOrientation) {
		const index = Pioneer.Sort.getIndex(time, this._keyframes, (a, time) => a[0] < time);
		let prevIndex = 0;
		let nextIndex = 0;
		if (index === this._keyframes.length) { // After last keyframe time.
			prevIndex = this._keyframes.length - 1;
			nextIndex = this._keyframes.length - 1;
		}
		else if (index > 0) {
			prevIndex = index - 1;
			nextIndex = index;
		}
		const prevKeyframe = this._keyframes[prevIndex];
		const nextKeyframe = this._keyframes[nextIndex];
		const u = (nextKeyframe[0] > prevKeyframe[0])
			? (time - prevKeyframe[0]) / (nextKeyframe[0] - prevKeyframe[0])
			: 0;
		const prevDirection = Pioneer.Vector3.pool.get();
		const nextDirection = Pioneer.Vector3.pool.get();
		this._getDirection(prevDirection, time, prevKeyframe[1]);
		this._getDirection(nextDirection, time, nextKeyframe[1]);
		nextDirection.slerp(prevDirection, nextDirection, u);
		nextDirection.normalize(nextDirection);
		prevDirection.rotate(oldOrientation, this._direction);
		const rotation = Pioneer.Quaternion.pool.get();
		rotation.setFromVectorFromTo(prevDirection, nextDirection);
		newOrientation.mult(rotation, oldOrientation);
		Pioneer.Quaternion.pool.release(rotation);
		Pioneer.Vector3.pool.release(nextDirection);
		Pioneer.Vector3.pool.release(prevDirection);
	}

	/**
	 * Gets a dynamic direction using the type. It can be the name of another entity or 'velocity', and can be prefixed with '-'.
	 * @param {Pioneer.Vector3} outDirection
	 * @param {number} time
	 * @param {string} type
	 * @private
	 */
	_getDirection(outDirection, time, type) {
		const entity = this.getEntity();
		const isNeg = type.startsWith('-');
		if (isNeg) {
			type = type.substring(1);
		}
		if (type.startsWith('velocity')) {
			type = type.substring(8);
			if (type.startsWith(' rel ')) {
				type = type.substring(5);
				const relEntity = entity.getScene().getEntity(type);
				if (relEntity !== null) {
					entity.getVelocityRelativeToEntity(outDirection, Pioneer.Vector3.Zero, relEntity, time);
				}
			}
			else {
				entity.getVelocityAtTime(outDirection, time);
			}
		}
		else {
			const otherEntity = entity.getScene().getEntity(type);
			if (otherEntity !== null) {
				otherEntity.getPositionRelativeToEntity(outDirection, Pioneer.Vector3.Zero, entity, time);
			}
			else {
				outDirection.copy(Pioneer.Vector3.NaN);
			}
		}
		outDirection.normalize(outDirection);
		if (isNeg) {
			outDirection.mult(outDirection, -1);
		}
	}
}
