/** @module pioneer */
import {
	FastIterable
} from '../internal';

/**
 * A pool for generic objects. Great for minimizing garbage collection. For every get call you must call release.
 * @template Type
 * @extends FastIterable<Type>
 */
export class Pool extends FastIterable {
	/**
	 * @typedef PoolData
	 * @property {number} _poolIndex - The index in the pool.
	 * @property {boolean} _poolUsed - If true, the item is in the pool.
	 */

	/**
	 * @typedef {PoolData & Type} PoolItem
	 */

	/**
	 * The constructor.
	 * @param {new () => Type} type - The class that will be instantiated when a new object needs to be allocated. It must be default constructable.
	 */
	constructor(type) {
		super();

		/**
		 * The class type for when new objects are needed.
		 * @type {new () => Type}
		 * @private
		 */
		this._type = type;

		/**
		 * List of objects, free and used.
		 * @type {Array<PoolItem>}
		 * @private
		 */
		this._objects = [];

		/**
		 * List of which objects are free in the objects list. It acts like a stack of indices into the objects list.
		 * @type {number[]}
		 * @private
		 */
		this._free = [];

		/**
		 * Since the free list only grows, this says how long the array is.
		 * @type {number}
		 * @private
		 */
		this._freeLength = 0;

		/**
		 * A constructor function that is called when a new object needs to be created.
		 * @type {function():Type}
		 * @private
		 */
		this._constructorFunction = () => {
			return new this._type();
		};

		/**
		 * A destructor function that is called when an object needs to be destroyed.
		 * @type {() => void}
		 * @private
		 */
		this._destructorFunction = () => {
		};
	}

	/**
	 * Sets a constructor function that is called when a new object needs to be created.
	 * @param {() => Type} constructorFunction
	 */
	setConstructorFunction(constructorFunction) {
		this._constructorFunction = constructorFunction;
	}

	/**
	 * Sets a destructor function that is called when an object needs to be destroyed.
	 * @param {() => void} destructorFunction
	 */
	setDestructorFunction(destructorFunction) {
		this._destructorFunction = destructorFunction;
	}

	/**
	 * Gets an object from the pool.
	 * @returns {Type}
	 */
	get() {
		/** @type {PoolItem} */
		let objectToGet = null;
		if (this._freeLength > 0) {
			objectToGet = this._objects[this._free[this._freeLength - 1]];
			this._freeLength -= 1;
		}
		else {
			// @ts-ignore - Ignoring conversion from Type to PoolItem.
			const poolIndex = this._objects.push(this._constructorFunction()) - 1;
			this._objects[poolIndex]._poolIndex = poolIndex;
			objectToGet = this._objects[poolIndex];
		}
		objectToGet._poolUsed = true;
		// @ts-ignore - Ignoring conversion from PoolItem to Type.
		return objectToGet;
	}

	/**
	 * Releases an object back into the pool.
	 * @param {Type} o - The object to release.
	 */
	release(o) {
		/** @type {PoolItem} */
		// @ts-ignore - Ignoring conversion from Type to PoolItem.
		const poolItem = o;
		if (poolItem._poolIndex !== undefined) {
			if (this._freeLength >= this._free.length) {
				this._free.push(poolItem._poolIndex);
			}
			else {
				this._free[this._freeLength] = poolItem._poolIndex;
			}
			this._freeLength += 1;
			poolItem._poolUsed = false;
		}
	}

	/**
	 * Cleans up memory, removing all unused objects. If the threshold is a number and is greater or equal than the number of used / total,
	 * it will be cleaned.
	 * @param {number} [threshold]
	 */
	clean(threshold) {
		if (threshold === undefined || threshold >= (this._objects.length - this._freeLength) / this._objects.length) {
			for (let freeI = 0; freeI < this._freeLength; freeI++) {
				const indexToRemove = this._free[freeI];
				// @ts-ignore - Ignoring conversion from PoolItem to Type.
				this._destructorFunction(this._objects[indexToRemove]);
				this._objects.splice(indexToRemove, 1);
				for (let i = indexToRemove, l = this._objects.length; i < l; i++) {
					this._objects[i]._poolIndex -= 1;
				}
				for (let i = freeI + 1, l = this._freeLength; i < l; i++) {
					if (this._free[i] > indexToRemove) {
						this._free[i] -= 1;
					}
				}
			}
			this._free = [];
			this._freeLength = 0;
		}
	}

	/**
	 * Returns the whole pool as an array.
	 * @returns {Type[]}
	 */
	getAsArray() {
		// @ts-ignore - Ignoring conversion from PoolItem to Type.
		return this._objects;
	}

	/**
	 * Returns true if the object is in the pool and used.
	 * @param {any} o
	 * @returns {boolean}
	 */
	isUsed(o) {
		return o._poolUsed;
	}

	/**
	 * Returns true if there are any objects used. This is good for debugging to see if there are any get calls without release calls.
	 * @returns {boolean}
	 */
	areAnyUsed() {
		return this._freeLength < this._objects.length;
	}

	/**
	 * Gets the value of the given index.
	 * @param {number} index
	 * @returns {Type}
	 * @override
	 */
	getAt(index) {
		return this._objects[index];
	}

	/**
	 * Gets the number of values.
	 * @returns {number}
	 * @override
	 */
	get size() {
		return this._objects.length;
	}
}
