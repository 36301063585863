import { useEffect } from 'react';
import { useSnapshot } from 'valtio';

import { poiStore, gmapStore, appStore, cameraStore, compareStore, datasetStore, eventsStore, kioskStore, spacecraftStore, videoStore, previewStore, stellarStore } from '../../managers/globalState';
import globalRefs from '../../managers/globalRefs';
import { SPACECRAFT_GEOSTATIONARY } from '../../data/spacecraft_data_ar';
import { SpacecraftVisibility } from '../../pages/SpacecraftDetail';
import useSamePageRefreshCount from '../../hooks/useSamePageRefreshCount';
import { Config } from '../../config/config';


// Create a ref to determine if the camera transition was interrupted.
const transition = { interrupted: false };

// Async function to animate camera.
const animateCamera = async entityId => {
  const { getManager } = globalRefs;

  const { currentEvent } = eventsStore.stateSnapshot;
  const { currentPoi } = poiStore.stateSnapshot;
  const { currentGmap } = gmapStore.stateSnapshot;
  const { currentStellar } = stellarStore.stateSnapshot;
  const { previewEventData } = previewStore.stateSnapshot;
  const { spacecraftId, isZoomedOut } = spacecraftStore.stateSnapshot;
  const { currentVideo } = videoStore.stateSnapshot;
  const { currentDataset } = datasetStore.stateSnapshot;
  const { isCameraTransitioning, globeLat, globeLon } = cameraStore.stateSnapshot;

  // Determine if the event data is preview data.
  const eventData = currentEvent || (previewEventData && !previewEventData.isAnimated);
  const videoData = currentVideo || previewEventData?.isAnimated;
  const camManager = getManager('camera');
  let camTransitionPromise;
  const validLatLon = globeLat !== '' && globeLon !== '' && !isNaN(parseFloat(globeLat)) && !isNaN(parseFloat(globeLon));
  let earth_params = {}
  if (validLatLon) {
    earth_params.latitude = globeLat;
    earth_params.longitude = globeLon;
  } else if (Config.globeLat && Config.globeLon) {
    earth_params.latitude = Config.globeLat;
    earth_params.longitude = Config.globeLon;
  }
  const goToEarth = params => camManager.goToEntity('earth', { ...earth_params, ...params });

  // console.log("CameraMaster::animateCamera", entityId, currentPoi, currentGmap)
  if (entityId) {
    // Animate camera to the entity.
    camTransitionPromise = camManager.goToEntity(entityId);
  } else if (currentPoi) {
    camTransitionPromise = camManager.goToPoiPatch(currentPoi)
  } else if (currentGmap) {
    camTransitionPromise = camManager.goToGmapPatch(currentGmap)
  } else if (currentStellar) {
    camTransitionPromise = camManager.goToEntity(currentStellar.id)
  } else if (eventData) {
    if (eventData.type === 'geoLocated') {
      // Fly to video patch if geolocated.
      camTransitionPromise = camManager.goToPatch(eventData);
    } else {
      // Fly to earth if global.
      camTransitionPromise = goToEarth();
    }
  } else if (spacecraftId) {
    const entityName = isZoomedOut ? 'earth' : spacecraftId;
    // Handle zoomed out geostationary case
    const isGeostationary = isZoomedOut && SPACECRAFT_GEOSTATIONARY.includes(spacecraftId);
    // Animate camera to the entity.
    camTransitionPromise = camManager.goToEntity(entityName, { resetCameraAngle: false, isGeostationary });
  } else if (videoData) {
    if (videoData.type === 'geoLocated') {
      // Fly to video patch if geolocated.
      camTransitionPromise = camManager.goToPatch(videoData);
    } else {
      // Fly to earth if global.
      camTransitionPromise = goToEarth();
    }
  } else if (currentDataset) {
    // If currentDataset, animate cam to earth.
    camTransitionPromise = goToEarth({ resetCameraAngle: true });
  }

  if (camTransitionPromise) {
    // Only set transition.interrupted if we're going to animate the camera. (sometimes everything is null)
    transition.interrupted = isCameraTransitioning === true;

    // Set transition state.
    cameraStore.setGlobalState({ isCameraTransitioning: true });

    // Animate camera.
    await camTransitionPromise.catch(_ => null);

    // Only complete the transition if it wasn't interrupted.
    if (!transition.interrupted) {
      cameraStore.setGlobalState({ isCameraTransitioning: false });
    } else {
      // Reset transition.interrupted.
      transition.interrupted = false;
    }
  }

  return Promise.resolve();
};

const animateCompare = async () => {
  const { pioneer, getManager } = globalRefs;
  const { spacecraftId, isZoomedOut } = spacecraftStore.stateSnapshot;

  if (!spacecraftId || isZoomedOut) {
    return Promise.resolve();
  }

  const { compareObjName } = compareStore.stateSnapshot;
  const compareId = getManager('content').getCustomEntityId(compareObjName);

  const { isCameraTransitioning } = cameraStore.stateSnapshot;
  transition.interrupted = isCameraTransitioning === true;

  const scene = pioneer.get('main');
  const spacecraftEntity = scene.get(spacecraftId);
  const compareEntity = scene.get(compareId);

  cameraStore.setGlobalState({ isCameraTransitioning: true });

  // Animate camera to the compare object.
  const camTransitionPromise = compareEntity ?
    getManager('camera').goToComparison(spacecraftEntity, compareEntity) :
    getManager('camera').goToEntity(spacecraftId);

  await camTransitionPromise.catch(_ => null);

  // Only complete the transition if it wasn't interrupted.
  if (!transition.interrupted) {
    cameraStore.setGlobalState({ isCameraTransitioning: false });
  } else {
    // Reset transition.interrupted.
    transition.interrupted = false;
  }

  return Promise.resolve();
};

/**
 * Simply re-calls animateCamera.
 */
const resetCamera = () => {
  const { isZoomedOut } = spacecraftStore.stateSnapshot;
  if (isZoomedOut) {
    // Reset isZoomedOut to false.
    spacecraftStore.setGlobalState({ isZoomedOut: false });
  } else {
    animateCamera();
  }
};

/**
 * CameraMaster component.
 * Determines where to navigate the camera.
 * In order to animate the camera without errors, it's important that we control which entities are enabled.
 * This component is therefore responsible for enabling/disabling entities.
 */
const CameraMaster = () => {
  const { isTextureLoaded, isVisibleEarth, currentDataset, currentVS } = useSnapshot(datasetStore.state);
  const { currentVideo } = useSnapshot(videoStore.state);
  const { currentPoi } = useSnapshot(poiStore.state);
  const { currentGmap } = useSnapshot(gmapStore.state);
  const { currentStellar } = useSnapshot(stellarStore.state);
  const { previewEventData } = useSnapshot(previewStore.state);
  const { currentSpacecraft, isZoomedOut } = useSnapshot(spacecraftStore.state);
  const { compareObjName } = useSnapshot(compareStore.state);
  const { currentEvent, eventsVisibleOnGlobe } = useSnapshot(eventsStore.state);
  const { isCameraTransitioning } = useSnapshot(cameraStore.state);
  const { isKioskResetting } = useSnapshot(kioskStore.state);

  const samePageRefreshCount = useSamePageRefreshCount();

  // useEffect for when dataset/spacecraft/isZoomedOut/currentEvent/currentVideo changes.
  useEffect(() => {
    const { getManager } = globalRefs;
    const { isAnimating } = getManager('dataset');
    const { validURL } = appStore.stateSnapshot;

    /**
     * There is a unique case on visible earth where the camera should not animate.
     * It's when we have a variety of fallback within the same animation.
     * For instance, we have some WMTS frames and some frames from the aqua modis dataset.
     * When animating between frames, we dont want to reset the camera.
     * There is a special flag, noCameraReset, added in the VE manager to handle this.
     */
    const isAnimatingBetweenVEdataset = currentDataset?.noCameraReset && isAnimating;
    if (validURL && !isAnimatingBetweenVEdataset) {
      // Animate camera.
      /**
       * NOTE:
       * This setTimeout is a temporary bug fix. We need to call animateCamera from
       * subscribed keys instead of from useEffect. See Redmine task #4250.
       */
      setTimeout(() => animateCamera());
    }
  }, [
    currentVS,
    currentPoi,
    currentGmap,
    currentDataset,
    currentSpacecraft,
    currentEvent,
    currentStellar,
    previewEventData,
    currentVideo,
    isZoomedOut,
    eventsVisibleOnGlobe,
    isKioskResetting
  ]);

  // useEffect for when the compareObjName changes.
  useEffect(() => {
    // Animate camera.
    animateCompare();
  }, [compareObjName]);

  // useEffect to set pick controller once camera has finished transitioning.
  useEffect(() => {
    const { getManager } = globalRefs;
    // Determine if pick controller should be set.
    const setPickController = isTextureLoaded && !isVisibleEarth && isCameraTransitioning === false;

    // Set pick controller.
    setPickController && getManager('camera').setPickController();

    return () => {
      getManager('camera').unsetPickController();
    };
  }, [isTextureLoaded, isVisibleEarth, isCameraTransitioning]);

  // useEffect to reset the camera on refresh.
  useEffect(() => {
    if (samePageRefreshCount > 0) {
      resetCamera();
    }
  }, [samePageRefreshCount]);

  return <SpacecraftVisibility />;
};

export default CameraMaster;

export { resetCamera };
