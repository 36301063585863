import React from 'react';
import PropTypes from 'prop-types';

const CategoryList = ({ itemList, itemIndex = 0, onItemClick }) => (
	<ul className='categories scrollable'>
		{itemList.map(({ id, text }, i) => (
			text
				?
				(
					<li
						key={id.toString()}
						className={`category-item ${i === itemIndex ? 'selected' : ''}`}
						data-filter={text}
						data-index={i}
						onClick={onItemClick}
					>
						{text}
					</li>
				)
				: null
		))}
	</ul>
);

CategoryList.propTypes = {
	itemList: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		text: PropTypes.string
	})).isRequired,
	itemIndex: PropTypes.number,
	onItemClick: PropTypes.func.isRequired
};

// React memo and useCallback -> https://dmitripavlutin.com/dont-overuse-react-usecallback/
export default React.memo(CategoryList);
