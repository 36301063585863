/** @module pioneer */
import {
	BaseComponent,
	Engine,
	THREE,
	ThreeJsHelper
} from '../internal';

/**
 * A texture that downloads different resolution textures depending on the level of detail requested.
 * Each texture URL is can use the $SIZE and $FACE replacement strings to specify the different images loaded depending on the LOD and face.
 */
export class TextureLOD {
	/**
	 * Constructor.
	 * @param {BaseComponent} component - the component container
	 */
	constructor(component) {
		/**
		 * The Pioneer engine.
		 * @type {Engine}
		 * @private
		 */
		this._engine = component.getEntity().getScene().getEngine();

		/**
		 * The component container.
		 * @type {BaseComponent}
		 * @private
		 */
		this._component = component;

		/**
		 * The uniform to be set to the texture.
		 * @type {THREE.IUniform}
		 * @private
		 */
		this._uniform = null;

		/**
		 * The url to be used.
		 * @type {string}
		 * @private
		 */
		this._url = '';

		/**
		 * The array of sizes of textures.
		 * @type {number[]}
		 * @private
		 */
		this._sizes = [];

		/**
		 * The current size of the texture.
		 * @type {number}
		 * @private
		 */
		this._currentSize = 0;

		/**
		 * If not undefined, forces the texture to be this size.
		 * @type {number}
		 * @private
		 */
		this._forcedSize = undefined;

		/**
		 * The flag that if true, uses compressed textures.
		 * @type {boolean}
		 * @private
		 */
		this._useCompression = false;

		/**
		 * A promise that resolves when the texture is loaded. Every new texture load creates a new promise.
		 * @type {Promise<void>}
		 * @private
		 */
		this._loadedPromise = Promise.resolve();

		/**
		 * A flag that tells whether this is currently loading a texture.
		 * @type {boolean}
		 * @private
		 */
		this._loading = false;
	}

	/**
	 * Gets the url currently being used.
	 * @returns {string}
	 */
	getUrl() {
		return this._url;
	}

	/**
	 * Sets the url to be used. If can have a $SIZE in the URL to act as replacements for different sizes.
	 * @param {string} url
	 */
	setUrl(url) {
		this._url = url;
		this._currentSize = 0;
	}

	/**
	 * Gets the uniform to be used.
	 * @returns {THREE.IUniform}
	 */
	getUniform() {
		return this._uniform;
	}

	/**
	 * Sets the uniform to be used, or null for no uniform.
	 * @param {THREE.IUniform} uniform
	 */
	setUniform(uniform) {
		this._uniform = uniform;
		this._currentSize = 0;
	}

	/**
	 * Gets the sizes to be loaded.
	 * @returns {number[]}
	 */
	getSizes() {
		return this._sizes.slice();
	}

	/**
	 * Sets the sizes of the textures to be loaded.
	 * @param {number[]} sizes
	 */
	setSizes(sizes) {
		this._sizes = sizes.slice();
		this._currentSize = 0;
	}

	/**
	 * Gets the size of the current texture.
	 * @returns {number}
	 */
	getCurrentSize() {
		return this._currentSize;
	}

	/**
	 * Gets the forced texture size, if defined.
	 * @returns {number}
	 */
	getForcedSize() {
		return this._forcedSize;
	}

	/**
	 * Sets the forced texture size, if defined.
	 * @param {number} size
	 */
	setForcedSize(size) {
		this._forcedSize = size;
	}

	/**
	 * Gets the flag that if true, uses compressed textures.
	 * @returns {boolean}
	 */
	getUseCompression() {
		return this._useCompression;
	}

	/**
	 * Sets the flag that if true, uses compressed textures.
	 * @param {boolean} useCompression
	 */
	setUseCompression(useCompression) {
		this._useCompression = useCompression;
		this._currentSize = 0;
	}

	/**
	 * Returns a promise that resolves when the texture for the current target size is loaded.
	 * @returns {Promise<void>}
	 */
	getLoadedPromise() {
		return this._loadedPromise;
	}

	/**
	 * Starts the downloading of a texture at the current target level.
	 */
	update() {
		// Still loading a texture.
		if (this._loading || this._uniform === null) {
			return;
		}
		// No textures to check or load.
		if (this._url === '' || this._sizes.length === 0 || (this._component.getLoadState() === 'unloaded' && this._forcedSize === undefined)) {
			// Unload any existing texture.
			if (this._uniform.value !== null) {
				ThreeJsHelper.destroyTexture(this._uniform.value);
				this._uniform.value = null;
				this._currentSize = 0;
			}
			return;
		}
		// Figure out which texture should be loaded.
		let targetSize = this._sizes[0];
		if (this._forcedSize !== undefined) {
			targetSize = this._forcedSize;
		}
		else {
			targetSize = this._component.getEntity().getGreatestPixelSpaceExtentsRadius();
		}
		// Get the index of the least size greater than or equal to the target size.
		let sizeIndex = 0;
		for (let i = 0, l = this._sizes.length; i < l; i++) {
			const size = this._sizes[i];
			if (i === l - 1) { // End of list, so choose the highest one.
				sizeIndex = i;
			}
			const maxTextureSize = this._engine.getConfig().getValue('maxTextureSize');
			if (size >= targetSize // If we've past the target or the maxTextureSize config (without a forced size), choose the this one.
				|| (typeof maxTextureSize === 'number' && size >= maxTextureSize && this._forcedSize === undefined)) {
				sizeIndex = i;
				break;
			}
		}
		// If the current size is different than the requested size, load up the new size.
		if (this._currentSize !== this._sizes[sizeIndex]) {
			this._currentSize = this._sizes[sizeIndex];
			this._loadTexture(this._currentSize);
		}
	}

	/**
	 * Loads the texture at the given size.
	 * This is separate from the update function to avoid garbage generation from the this-bound anonymous function after the download.
	 * @param {number} size - The size of the texture to load.
	 * @private
	 */
	_loadTexture(size) {
		const url = this._url.replace('$SIZE', size.toString());
		this._loading = true;
		this._loadedPromise = ThreeJsHelper.loadTextureIntoUniform(this._component, this._uniform, url, true, this._useCompression).then(() => {
			this._loading = false;
		}).catch((error) => {
			throw Error(error);
		});
	}
}
