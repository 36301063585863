/** @module pioneer-scripts */
import { Entity } from '../entity';
import * as Pioneer from 'pioneer';

// NEOs are near-earth objects
// PHAs are potentially hazardous asteroids
// TNOs are trans-neptunian objects

Entity.register({
	'1_ceres': {
		groups: ['dwarf planets'],
		radius: 473.0,
		label: 'Ceres',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		spheroid: {
			equatorialRadius: 482.6,
			polarRadius: 445.6,
			planetographic: false
		},
		spheroidLOD: {
			textures: {
				color: {
					url: '1_ceres/color_$SIZE_$FACE.jpg',
					sizes: [16, 512, 4096]
				}
			}
		},
		controllers: [{
			type: 'dynamo',
			url: '1_ceres/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'101955_bennu': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.246,
		label: 'Bennu',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/101955_bennu/Bennu.gltf',
			rotate: [
				{ x: 83 },
				{ z: -168 }
			],
			scale: 0.0009956
		},
		controllers: [{
			type: 'dynamo',
			url: '101955_bennu/sun/orb'
		}, {
			type: 'dynamo',
			url: '101955_bennu/ori'
		}]
	},
	'11351_leucus': {
		groups: ['asteroids'],
		radius: 17.0775,
		label: 'Leucus',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: 17.0775
		},
		controllers: [{
			type: 'dynamo',
			url: '11351_leucus/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 445.732
		}]
	},
	'12923_zephyr': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 1.03,
		label: 'Zephyr',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [1.03, 1.03, 1.03]
		},
		controllers: [{
			type: 'dynamo',
			url: '12923_zephyr/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 3.891
		}]
	},
	'134340_pluto': {
		groups: ['dwarf planets', 'moons', '134340_pluto_barycenter'],
		radius: 1187.0,
		label: 'Pluto',
		labelFadeEntity: 'sun',
		parents: [
			[Number.NEGATIVE_INFINITY, '134340_pluto_barycenter']
		],
		trail: {
			length: undefined
		},
		spheroid: {
			equatorialRadius: 1187.0,
			polarRadius: 1187.0,
			planetographic: false
		},
		spheroidLOD: {
			textures: {
				color: {
					url: '134340_pluto/color_$SIZE_$FACE.png',
					sizes: [4, 512, 4096]
				}
			}
		},
		controllers: [{
			type: 'dynamo',
			url: '134340_pluto/134340_pluto_barycenter/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				axis: Pioneer.Vector3.XAxis,
				target: 'charon'
			},
			secondary: {
				type: 'velocity',
				axis: Pioneer.Vector3.YAxis,
				target: 'charon'
			}
		}]
	},
	'134340_pluto_barycenter': {
		groups: ['134340_pluto', 'barycenters'],
		occlusionRadius: 0.001,
		extentsRadius: 28000,
		systemRadius: 130153,
		label: 'Barycenter',
		labelFadeEntity: '134340_pluto',
		trail: {
			length: undefined
		},
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		controllers: [{
			type: 'dynamo',
			url: '134340_pluto_barycenter/sun/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}],
		postCreateFunction: (entity) => {
			const divComponent = entity.getComponentByClass(Pioneer.DivComponent);
			divComponent.setFadeWhenCloseToCamera(false);
			entity.setCanOcclude(false);
		}
	},
	'136108_haumea': {
		groups: ['dwarf planets', 'TNOs'],
		radius: 816.0,
		label: 'Haumea',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/136108_haumea/haumea.gltf',
			scale: [1, 1, 1]
		},
		controllers: [{
			type: 'dynamo',
			url: '136108_haumea/sun/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	'136199_eris': {
		groups: ['dwarf planets', 'TNOs'],
		radius: 1163.0,
		label: 'Eris',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		spheroid: {
			equatorialRadius: 1163.0,
			polarRadius: 1163.0,
			planetographic: false
		},
		spheroidLOD: {
			textures: {
				color: {
					url: '136199_eris/color_$SIZE_$FACE.png',
					sizes: [4, 512]
				}
			}
		},
		controllers: [{
			type: 'dynamo',
			url: '136199_eris/sun/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	'136472_makemake': {
		groups: ['dwarf planets', 'TNOs'],
		radius: 715.0,
		label: 'Makemake',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		spheroid: {
			equatorialRadius: 717.0,
			polarRadius: 710.0,
			planetographic: false
		},
		spheroidLOD: {
			textures: {
				color: {
					url: '136472_makemake/color_$SIZE_$FACE.png',
					sizes: [4, 512]
				}
			}
		},
		controllers: [{
			type: 'dynamo',
			url: '136472_makemake/sun/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	'14827_hypnos': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.4535,
		label: 'Hypnos',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.4535, 0.4535, 0.4535]
		},
		controllers: [{
			type: 'dynamo',
			url: '14827_hypnos/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'15094_polymele': {
		groups: ['asteroids'],
		radius: 10.5375,
		label: 'Polymele',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: 10.5375
		},
		controllers: [{
			type: 'dynamo',
			url: '15094_polymele/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 5.8607
		}]
	},
	'1566_icarus': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.805,
		label: 'Icarus',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.805, 0.800, 0.585]
		},
		controllers: [{
			type: 'dynamo',
			url: '1566_icarus/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 2.2726
		}]
	},
	'16_psyche': {
		groups: ['asteroids'],
		radius: 125.0,
		label: '16 Psyche',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/16_psyche/psycheAsteroid.gltf',
			scale: [100, 100, 100],
			rotate: [
				{ x: 90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: '16_psyche/sun/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	'1620_geographos': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 2.5,
		label: 'Geographos',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [2.5, 1.0, 1.05]
		},
		controllers: [{
			type: 'dynamo',
			url: '1620_geographos/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 5.224
		}]
	},
	'162173_ryugu': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.45,
		label: 'Ryugu',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/162173_ryugu/ryugu.gltf',
			rotate: [
				{ x: -90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: 'ssd/162173_ryugu/sun/orb'
		}, {
			type: 'dynamo',
			url: '162173_ryugu/ori'
		}]
	},
	'1862_apollo': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.75,
		label: 'Apollo',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.75, 0.75, 0.75]
		},
		controllers: [{
			type: 'dynamo',
			url: '1862_apollo/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 3.065
		}]
	},
	'1981_midas': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.975,
		label: 'Midas',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.975, 0.975, 0.975]
		},
		controllers: [{
			type: 'dynamo',
			url: '1981_midas/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 5.22
		}]
	},
	'1991_vg': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.00425,
		label: '1991 VG',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.00425, 0.00425, 0.00425]
		},
		controllers: [{
			type: 'dynamo',
			url: '1991_vg/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'1993_hd': {
		groups: ['asteroids', 'asteroid belt'],
		radius: 0.005,
		label: '1993 HD',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '1993_hd/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'1994_cc_a': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.325,
		label: '1994 CC',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.325, 0.325, 0.325]
		},
		controllers: [{
			type: 'dynamo',
			url: '1994_cc_a/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 2.38860
		}]
	},
	// Needs spice
	// '1994_cc_b': {
	// 	groups: ['asteroids', 'NEOs', 'PHAs'],
	// 	radius: 0.025,
	// 	label: '1994 CC B',
	// 	parents: [
	// 		[Number.NEGATIVE_INFINITY, 'sun']
	// 	],
	// 	trail: {
	// 		length: undefined
	// 	},
	// 	model: {
	// 		url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
	// 		scale: [0.025, 0.025, 0.025]
	// 	},
	// 	dynamo: [{
	// 		url: '1994_cc_b/1994_cc_a/orb',
	// 		parent: '1994_cc_a'
	// 	}, {
	// 		url: '1994_cc_b/ori'
	// 	}]
	// },
	// Needs spice
	// '1994_cc_c': {
	// 	groups: ['asteroids', 'NEOs', 'PHAs'],
	// 	radius: 0.00417,
	// 	label: '1994 CC C',
	// 	parents: [
	// 		[Number.NEGATIVE_INFINITY, 'sun']
	// 	],
	// 	trail: {
	// 		length: undefined
	// 	},
	// 	model: {
	// 		url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
	// 		scale: [0.00417, 0.00417, 0.00417]
	// 	},
	// 	dynamo: [{
	// 		url: '1994_cc_c/1994_cc_a/orb',
	// 		parent: '1994_cc_a'
	// 	}, {
	// 		url: '1994_cc_c/ori'
	// 	}]
	// },
	'1996_xb27': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.042,
		label: '1996 XB<sub>27</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.042, 0.042, 0.042]
		},
		controllers: [{
			type: 'dynamo',
			url: '1996_xb27/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 1.195
		}]
	},
	'1998_ky26': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.015,
		label: '1998 KY<sub>26</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.015, 0.015, 0.015]
		},
		controllers: [{
			type: 'dynamo',
			url: '1998_ky26/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 0.1784
		}]
	},
	'1998_ml14': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.5,
		label: '1998 ML<sub>14</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.5, 0.5, 0.5]
		},
		controllers: [{
			type: 'dynamo',
			url: '1998_ml14/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 14.28
		}]
	},
	'1998_qe2': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 1.375,
		label: '1998 QE<sub>2</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [1.375, 1.375, 1.375]
		},
		controllers: [{
			type: 'dynamo',
			url: '1998_qe2/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 4.749
		}]
	},
	'1999_ao10': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.025,
		label: '1999 AO<sub>10</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.025, 0.025, 0.025]
		},
		controllers: [{
			type: 'dynamo',
			url: '1999_ao10/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'1999_cg9': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '1999 CG<sub>9</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '1999_cg9/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'1999_vx25': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '1999 VX<sub>25</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '1999_vx25/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2_pallas': {
		groups: ['asteroids', 'asteroid belt'],
		radius: 291,
		label: 'Pallas',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [291, 278, 250]
		},
		controllers: [{
			type: 'dynamo',
			url: '2_pallas/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 7.8132
		}]
	},
	'2000_ae205': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2000 AE<sub>205</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2000_ae205/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2000_lg6': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2000 LG<sub>6</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2000_lg6/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2000_sg344': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.0185,
		label: '2000 SG<sub>344</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.0185, 0.0185, 0.0185]
		},
		controllers: [{
			type: 'dynamo',
			url: '2000_sg344/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2001_bb16': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2001 BB<sub>16</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2001_bb16/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2001_fr85': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2001 FR<sub>85</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2001_fr85/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2001_gp2': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2001 GP<sub>2</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2001_gp2/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2001_qj142': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2001 QJ<sub>142</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2001_qj142/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2001_sn263_a': {
		groups: ['asteroids', 'NEOs'],
		radius: 1.45,
		label: '(153591) 2001 SN<sub>263</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [1.4, 1.35, 1.45]
		},
		controllers: [{
			type: 'dynamo',
			url: '2001_sn263_a/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 3.423
		}]
	},
	// Needs spice
	// '2001_sn263_b': {
	// 	groups: ['asteroids', 'NEOs'],
	// 	radius: 0.215,
	// 	label: '2001 SN263 B',
	// 	parents: [
	// 		[Number.NEGATIVE_INFINITY, 'sun']
	// 	],
	// 	trail: {
	// 		length: 537840
	// 	},
	// 	model: {
	// 		url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
	// 		scale: [0.215, 0.215, 0.215]
	// 	},
	// 	dynamo: [{
	// 		url: '2001_sn263_b/2001_sn263_a/orb',
	// 		parent: '2001_sn263_a'
	// 	}, {
	// 		url: '2001_sn263_b/ori'
	// 	}]
	// },
	// Needs spice
	// '2001_sn263_c': {
	// 	groups: ['asteroids', 'NEOs'],
	// 	radius: 0.385,
	// 	label: '2001 SN263 C',
	// 	parents: [
	// 		[Number.NEGATIVE_INFINITY, 'sun']
	// 	],
	// 	trail: {
	// 		length: 59270
	// 	},
	// 	model: {
	// 		url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
	// 		scale: [0.385, 0.385, 0.385]
	// 	},
	// 	dynamo: [{
	// 		url: '2001_sn263_c/2001_sn263_a/orb',
	// 		parent: '2001_sn263_a'
	// 	}, {
	// 		url: '2001_sn263_c/ori'
	// 	}]
	// },
	'2003_sm84': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2003 SM<sub>84</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2003_sm84/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2003_uv11': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.13,
		label: '(503941) 2003 UV<sub>11</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.13, 0.13, 0.13]
		},
		controllers: [{
			type: 'dynamo',
			url: '2003_uv11/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 18.25
		}]
	},
	'2003_yn107': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.01,
		label: '2003 YN<sub>107</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.01, 0.01, 0.01]
		},
		controllers: [{
			type: 'dynamo',
			url: '2003_yn107/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2005_er95': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2003 ER<sub>95</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2005_er95/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2005_lc': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2005 LC',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2005_lc/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2005_qp87': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2005 QP<sub>87</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2005_qp87/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2005_yu55': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.18,
		label: '2005 YU<sub>55</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.18, 0.18, 0.18]
		},
		controllers: [{
			type: 'dynamo',
			url: '2005_yu55/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 18
		}]
	},
	'2006_bz147': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2006 BZ<sub>147</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2006_bz147/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2006_jy26': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.00475,
		label: '2006 JY<sub>26</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.00475, 0.00475, 0.00475]
		},
		controllers: [{
			type: 'dynamo',
			url: '2006_jy26/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2006_qq56': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2006 QQ<sub>56</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2006_qq56/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2006_rh120': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.00125,
		label: '2006 RH<sub>120</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.00125, 0.00125, 0.00125]
		},
		controllers: [{
			type: 'dynamo',
			url: '2006_rh120/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 0.04583
		}]
	},
	'2006_ub17': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2006 UB<sub>17</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2006_ub17/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2007_tf15': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2007 TF<sub>15</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2007_tf15/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2007_un12': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2007 UN<sub>12</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2007_un12/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2007_vu6': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2007 VU<sub>6</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2007_vu6/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2008_bt2': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2008 BT<sub>2</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2008_bt2/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2008_cx118': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2008 CX<sub>118</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2008_cx118/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2008_ea9': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2008 EA<sub>9</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2008_ea9/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2008_el': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2008 EL',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2008_el/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2008_hu4': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2008 HU<sub>4</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2008_hu4/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2008_jl24': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2008 JL<sub>24</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2008_jl24/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 0.05385
		}]
	},
	'2008_kt': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2008 KT',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2008_kt/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2008_tc3': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.00205,
		label: '2008 TC<sub>3</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.00205, 0.00205, 0.00205]
		},
		controllers: [{
			type: 'dynamo',
			url: '2008_tc3/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 0.0269409
		}]
	},
	'2008_ts10': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2008 TS<sub>10</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2008_ts10/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2008_ua202': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.025,
		label: '2008 UA<sub>202</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.025, 0.025, 0.025]
		},
		controllers: [{
			type: 'dynamo',
			url: '2008_ua202/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2009_bd': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.0055,
		label: '2009 BD',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.0055, 0.0055, 0.0055]
		},
		controllers: [{
			type: 'dynamo',
			url: '2009_bd/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2009_os5': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2009 OS<sub>5</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2009_os5/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2009_rt1': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2009 RT<sub>1</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2009_rt1/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2009_yf': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2009 YF',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2009_yf/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2010_an61': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2010 AN<sub>61</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2010_an61/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2010_dj': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2010 DJ',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2010_dj/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2010_jw34': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2010 JW<sub>34</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2010_jw34/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2010_tg19': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2010 TG<sub>19</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2010_tg19/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2010_tn167': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2010 TN<sub>167</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2010_tn167/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2010_ub': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.005,
		label: '2010 UB',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.005, 0.005, 0.005]
		},
		controllers: [{
			type: 'dynamo',
			url: '2010_ub/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2063_bacchus': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.555,
		label: 'Bacchus',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.555, 0.265, 0.25]
		},
		controllers: [{
			type: 'dynamo',
			url: '2063_bacchus/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 14.904
		}]
	},
	'21_lutetia': {
		groups: ['asteroids', 'asteroid belt'],
		radius: 60.5,
		label: 'Lutetia',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [60.5, 50.5, 37.5]
		},
		controllers: [{
			type: 'dynamo',
			url: '21_lutetia/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 8.1655
		}]
	},
	'2101_adonis': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.2615,
		label: 'Adonis',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.2615, 0.2615, 0.2615]
		},
		controllers: [{
			type: 'dynamo',
			url: '2101_adonis/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'2102_tantalus': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.8245,
		label: 'Tantalus',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.8245, 0.8245, 0.8245]
		},
		controllers: [{
			type: 'dynamo',
			url: '2102_tantalus/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 2.384
		}]
	},
	'2135_aristaeus': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.5,
		label: 'Aristaeus',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.5, 0.5, 0.5]
		},
		controllers: [{
			type: 'dynamo',
			url: '2135_aristaeus/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'216_kleopatra': {
		groups: ['asteroids', 'asteroid belt'],
		radius: 138,
		label: 'Kleopatra',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [138, 47, 39]
		},
		controllers: [{
			type: 'dynamo',
			url: '216_kleopatra/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 5.385
		}]
	},
	'21900_orus': {
		groups: ['asteroids'],
		radius: 25.405,
		label: 'Orus',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: 25.405
		},
		controllers: [{
			type: 'dynamo',
			url: '21900_orus/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 13.45
		}]
	},
	'225088_2007_or10': {
		groups: ['dwarf planets', 'TNOs'],
		radius: 615,
		label: '2007 OR<sub>10</sub>',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [615, 615, 596.55]
		},
		controllers: [{
			type: 'dynamo',
			url: '225088_2007_or10/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 22.40
		}]
	},
	'2340_hathor': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.105,
		label: 'Hathor',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.105, 0.105, 0.105]
		},
		controllers: [{
			type: 'dynamo',
			url: '2340_hathor/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 3.350
		}]
	},
	'243_ida': {
		groups: ['asteroids', 'asteroid belt'],
		radius: 29.9,
		label: 'Ida',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [18.6 * 1.3, 25.4 * 1.3, 59.8 * 1.3],
			rotate: [
				{ y: 90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: '243_ida/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 4.634
		}]
	},
	'25143_itokawa': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.165,
		label: 'Itokawa',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/25143_itokawa/itokawa.gltf',
			rotate: [
				{ x: 90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: '25143_itokawa/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12.132
		}]
	},
	'253_mathilde': {
		groups: ['asteroids', 'asteroid belt'],
		radius: 33,
		label: 'Mathilde',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [33, 24, 23]
		},
		controllers: [{
			type: 'dynamo',
			url: '253_mathilde/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 417.7
		}]
	},
	'2867_steins': {
		groups: ['asteroids', 'asteroid belt'],
		radius: 3.415,
		label: 'Šteins',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [3.415, 2.85, 2.21]
		},
		controllers: [{
			type: 'dynamo',
			url: '2867_steins/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 6.049
		}]
	},
	'3_juno': {
		groups: ['asteroids', 'asteroid belt'],
		radius: 160,
		label: 'Juno',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [160, 133.5, 100]
		},
		controllers: [{
			type: 'dynamo',
			url: '3_juno/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 7.210
		}]
	},
	'3122_florence': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 2.2,
		label: 'Florence',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [2.2, 2.2, 2.2]
		},
		controllers: [{
			type: 'dynamo',
			url: '3122_florence/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 2.3581
		}]
	},
	'3200_phaethon': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 2.9,
		label: 'Phaethon',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [2.9, 2.9, 2.9]
		},
		controllers: [{
			type: 'dynamo',
			url: '3200_phaethon/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 3.604
		}]
	},
	'3362_khufu': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.35,
		label: 'Khufu',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.35, 0.35, 0.35]
		},
		controllers: [{
			type: 'dynamo',
			url: '3362_khufu/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'3548_eurybates': {
		groups: ['asteroids'],
		radius: 31.9425,
		label: 'Eurybates',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: 31.9425
		},
		controllers: [{
			type: 'dynamo',
			url: '3548_eurybates/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 8.711
		}]
	},
	'367943_duende': {
		groups: ['asteroids', 'NEOs'],
		radius: 0.020,
		label: 'Duende',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.01, 0.01, 0.02]
		},
		controllers: [{
			type: 'dynamo',
			url: '367943_duende/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 9.485
		}]
	},
	'37655_illapa': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.75,
		label: 'Illapa',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.75, 0.75, 0.75]
		},
		controllers: [{
			type: 'dynamo',
			url: '37655_illapa/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 2.6556
		}]
	},
	'4_vesta': {
		groups: ['asteroids', 'asteroid belt'],
		radius: 262.7,
		label: 'Vesta',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/4_vesta/4_vesta.gltf',
			rotate: [
				{ x: 90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: '4_vesta/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'4015_wilson-harrington': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 2.0,
		label: 'Wilson-Harrington',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [2, 2, 2]
		},
		controllers: [{
			type: 'dynamo',
			url: '4015_wilson-harrington/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 3.5736
		}]
	},
	'4179_toutatis': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 2.13,
		label: 'Toutatis',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [1.35, 1.015, 2.13]
		},
		controllers: [{
			type: 'dynamo',
			url: '4179_toutatis/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 176
		}]
	},
	'4183_cuno': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 1.8255,
		label: 'Cuno',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [1.8255, 1.8255, 1.89255]
		},
		controllers: [{
			type: 'dynamo',
			url: '4183_cuno/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 3.5595
		}]
	},
	'433_eros': {
		groups: ['asteroids', 'NEOs'],
		radius: 8.42,
		label: 'Eros',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/433_eros/433_eros.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: '433_eros/sun/orb'
		}, {
			type: 'dynamo',
			url: '433_eros/ori'
		}]
	},
	'4450_pan': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.5,
		label: 'Pan',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.5, 0.5, 0.5]
		},
		controllers: [{
			type: 'dynamo',
			url: '4450_pan/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 56.48
		}]
	},
	'4486_mithra': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 1.175,
		label: 'Mithra',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [1.175, 0.825, 0.72]
		},
		controllers: [{
			type: 'dynamo',
			url: '4486_mithra/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 67.5
		}]
	},
	'4769_castalia': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.9,
		label: 'Castalia',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.4, 0.4, 0.9]
		},
		controllers: [{
			type: 'dynamo',
			url: '4769_castalia/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 4.095
		}]
	},
	'486958_arrokoth': {
		groups: ['TNOs'],
		radius: 15.0,
		label: 'Arrokoth',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/486958_arrokoth/mu69.gltf',
			scale: [1, 1, 1],
			rotate: [
				{ x: -110 },
				{ y: 180 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: '486958_arrokoth/sun/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}, {
			type: 'spin',
			axis: new Pioneer.Vector3(-6.121453676996425e-10, 0.9396926211599973, 0.34202014229786787),
			periodInHours: 13
		}],
		postCreateFunction: (entity) => {
			const spin = entity.getControllerByClass(Pioneer.SpinController);
			if (spin !== null) {
				spin.setReferenceAngle(0);
				spin.setReferenceTime(599590968);
			}
		}
	},
	'5011_ptah': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.78,
		label: 'Ptah',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.78, 0.78, 0.78]
		},
		controllers: [{
			type: 'dynamo',
			url: '5011_ptah/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 12
		}]
	},
	'52246_donaldjohanson': {
		groups: ['asteroids'],
		radius: 1.9475,
		label: 'Donaldjohanson',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: 1.9475
		},
		controllers: [{
			type: 'dynamo',
			url: '52246_donaldjohanson/sun/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	'5535_annefrank': {
		groups: ['asteroids'],
		radius: 2.412,
		label: 'Annefrank',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: 2.412
		},
		controllers: [{
			type: 'dynamo',
			url: '5535_annefrank/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 15.12
		}]
	},
	'617_patroclus': {
		groups: ['asteroids', '617_patroclus_barycenter'],
		radius: 64,
		label: 'Patroclus',
		labelFadeEntity: 'sun',
		parents: [
			[Number.NEGATIVE_INFINITY, '617_patroclus_barycenter']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [64, 58, 49],
			shadowEntities: ['menoetius']
		},
		controllers: [{
			type: 'align',
			primary: {
				type: 'point',
				axis: Pioneer.Vector3.XAxis,
				target: 'menoetius'
			}
		}],
		postCreateFunction: (entity) => {
			// Add the controller for going around sun from earth to venus.
			const oeController = entity.addControllerByClass(Pioneer.OrbitalElementsController, undefined, entity.getControllerByClass(Pioneer.AlignController));
			const oe = new Pioneer.OrbitalElements();
			oe.epoch = 0;
			oe.eccentricity = 0;
			oe.semiMajorAxis = 664;
			oe.meanAngularMotion = 0.00001697791;
			oe.meanAnomalyAtEpoch = Math.PI;
			oe.setOrbitOrientationFromElements(1.97244894756, 0, 0);
			oeController.addOrbitalElements(-1e100, oe);
			oeController.addOrbitalElements(+1e100, oe);
		}
	},
	'617_patroclus_barycenter': {
		groups: ['asteroids'],
		radius: 664,
		label: 'Patroclus',
		labelFadeEntity: '617_patroclus',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		controllers: [{
			type: 'dynamo',
			url: '617_patroclus/sun/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}],
		postCreateFunction: (entity) => {
			entity.setCanOcclude(false);
		}
	},
	'6239_minos': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.237,
		label: 'Minos',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [0.237, 0.237, 0.237]
		},
		controllers: [{
			type: 'dynamo',
			url: '6239_minos/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 3.5558
		}]
	},
	'6489_golevka': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.265,
		label: 'Golevka',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.265, 0.265, 0.265]
		},
		controllers: [{
			type: 'dynamo',
			url: '6489_golevka/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 6.026
		}]
	},
	'65803_didymos': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.39,
		label: 'Didymos',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/65803_didymos/Didymos.gltf',
			rotate: [
				{ z: 180 },
				{ x: -90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: '65803_didymos/sun/orb'
		}, {
			type: 'fixed',
			orientation: new Pioneer.Quaternion(0, 1, 0, 0)
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 2.2593,
			relativeToTime: 0
		}]
	},
	'66391_moshup': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.766,
		label: 'Moshup',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.766, 0.7475, 0.6735]
		},
		controllers: [{
			type: 'dynamo',
			url: '66391_moshup/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 2.7645
		}]
	},
	// Needs spice
	// 'squannit': {
	// 	groups: ['asteroids', 'NEOs', 'PHAs'],
	// 	radius: 0.180,
	// 	label: 'Squannit',
	// 	parents: [
	// 		[Number.NEGATIVE_INFINITY, 'sun']
	// 	],
	// 	trail: {
	// 		length: 57600
	// 	},
	// 	model: {
	// 		url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
	// 		scale: [0.180, 0.180, 0.180]
	// 	},
	// 	dynamo: [{
	// 		url: 'squannit/66391_moshup/orb',
	// 		parent: '66391_moshup'
	// 	}],
	// 	spin: {
	// 		axis: Pioneer.Vector3.ZAxis,
	// 		periodInHours: 12
	// 	}
	// },
	'69230_hermes': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.425,
		label: 'Hermes',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [0.425, 0.425, 0.425]
		},
		controllers: [{
			type: 'dynamo',
			url: '69230_hermes/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 13.894
		}]
	},
	'90377_sedna': {
		groups: ['dwarf planets', 'TNOs'],
		radius: 497.5,
		label: 'Sedna',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [497.5, 497.5, 497.5]
		},
		controllers: [{
			type: 'dynamo',
			url: '90377_sedna/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 10.273
		}]
	},
	'951_gaspra': {
		groups: ['asteroids', 'asteroid belt'],
		radius: 9.1,
		label: 'Gaspra',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/951_gaspra/gaspra.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: '951_gaspra/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 7.042
		}]
	},
	'9969_braille': {
		groups: ['asteroids', 'asteroid belt'],
		radius: 1.05,
		label: 'Braille',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [1.05, 0.5, 0.5]
		},
		controllers: [{
			type: 'dynamo',
			url: '9969_braille/sun/orb'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 226.4
		}]
	},
	'99942_apophis': {
		groups: ['asteroids', 'NEOs', 'PHAs'],
		radius: 0.225,
		label: 'Apophis',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/99942_apophis/apophis.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: '99942_apophis/sun/orb'
		}, {
			type: 'spin',
			// Goldstone and Arecibo radar observations of (99942) Apophis in 2012–2013, Marina Brozovic et al, 2018
			// Converted 246.8°, -59.3° to XYZ.
			axis: new Pioneer.Vector3(0.20112425201023415, 0.46925803805904115, 0.8598522715968735),
			periodInHours: 30.4
		}]
	},
	'152830_dinkinesh': {
		groups: ['asteroids'],
		radius: 0.45,
		label: 'Dinkinesh',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: 0.45
		},
		controllers: [{
			type: 'dynamo',
			url: '152830_dinkinesh/sun/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	charon: {
		groups: ['134340_pluto', 'moons', '134340_pluto_barycenter'],
		radius: 603.5,
		label: 'Charon',
		parents: [
			[Number.NEGATIVE_INFINITY, '134340_pluto_barycenter']
		],
		trail: {
			length: undefined
		},
		spheroid: {
			equatorialRadius: 603.5,
			polarRadius: 603.5,
			planetographic: false
		},
		spheroidLOD: {
			textures: {
				color: {
					url: 'charon/color_$SIZE_$FACE.png',
					sizes: [4, 512, 2048]
				}
			}
		},
		controllers: [{
			type: 'dynamo',
			url: 'charon/134340_pluto_barycenter/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				axis: Pioneer.Vector3.XAxis,
				target: '134340_pluto'
			},
			secondary: {
				type: 'velocity',
				axis: Pioneer.Vector3.YAxis,
				target: '134340_pluto'
			}
		}]
	},
	dactyl: {
		groups: ['243_ida', 'moons'],
		radius: 0.7,
		label: 'Dactyl',
		parents: [
			[Number.NEGATIVE_INFINITY, '243_ida']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [0.8, 0.7, 0.6],
			rotate: [
				{ z: 90 }
			]
		},
		controllers: [{
			type: 'align',
			primary: {
				type: 'point',
				axis: Pioneer.Vector3.XAxis,
				target: '243_ida'
			}
		}],
		postCreateFunction: (entity) => {
			// Add the controller for going around sun from earth to venus.
			const oeController = entity.addControllerByClass(Pioneer.OrbitalElementsController, undefined, entity.getControllerByClass(Pioneer.AlignController));
			const oe = new Pioneer.OrbitalElements();
			oe.epoch = 0;
			oe.eccentricity = 0;
			oe.semiMajorAxis = 90;
			oe.meanAngularMotion = 8.726646e-5; // 20 hrs per orbit
			oe.meanAnomalyAtEpoch = 0;
			oe.orbitOrientation.set(0.8728453580255966, 0.1876084386162498, -0.08948587100888229, 0.4415159494547423);
			oeController.addOrbitalElements(-1e100, oe);
			oeController.addOrbitalElements(+1e100, oe);
		}
	},
	dimorphos: {
		groups: ['65803_didymos', 'moons'],
		radius: 0.085,
		label: 'Dimorphos',
		parents: [
			[Number.NEGATIVE_INFINITY, '65803_didymos']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/dimorphos/Dimorphos.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: 'dimorphos/65803_didymos/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				axis: Pioneer.Vector3.XAxis,
				target: '65803_didymos'
			},
			secondary: {
				type: 'align',
				axis: Pioneer.Vector3.ZAxis,
				target: '65803_didymos',
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	hiiaka: {
		groups: ['136108_haumea', 'moons'],
		radius: 160.0,
		label: 'Hi\'iaka',
		parents: [
			[Number.NEGATIVE_INFINITY, '136108_haumea']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [160, 160, 160]
		},
		controllers: [{
			type: 'align',
			primary: {
				type: 'point',
				axis: Pioneer.Vector3.XAxis,
				target: '136108_haumea'
			}
		}],
		postCreateFunction: (entity) => {
			// Add the controller for going around sun from earth to venus.
			const oeController = entity.addControllerByClass(Pioneer.OrbitalElementsController, undefined, entity.getControllerByType('align'));
			const oe = new Pioneer.OrbitalElements();
			oe.epoch = 0;
			oe.eccentricity = 0.0513;
			oe.semiMajorAxis = 49880;
			oe.meanAngularMotion = 1.48049e-6; // 49.12 days per orbit
			oe.meanAnomalyAtEpoch = 0;
			oe.setOrbitOrientationFromElements(2.20532822965, 0, 0);
			oeController.addOrbitalElements(-1e100, oe);
			oeController.addOrbitalElements(+1e100, oe);
		}
	},
	hydra: {
		groups: ['134340_pluto', 'moons', '134340_pluto_barycenter'],
		radius: 25.0,
		label: 'Hydra',
		parents: [
			[Number.NEGATIVE_INFINITY, '134340_pluto_barycenter']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [25, 25, 25],
			shadowEntities: ['134340_pluto']
		},
		controllers: [{
			type: 'dynamo',
			url: 'hydra/134340_pluto_barycenter/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	kerberos: {
		groups: ['134340_pluto', 'moons', '134340_pluto_barycenter'],
		radius: 8.25,
		label: 'Kerberos',
		parents: [
			[Number.NEGATIVE_INFINITY, '134340_pluto_barycenter']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [8.25, 8.25, 8.25],
			shadowEntities: ['134340_pluto']
		},
		controllers: [{
			type: 'dynamo',
			url: 'kerberos/134340_pluto_barycenter/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	menoetius: {
		groups: ['617_patroclus_barycenter', 'moons'],
		radius: 58,
		label: 'Menoetius',
		parents: [
			[Number.NEGATIVE_INFINITY, '617_patroclus_barycenter']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [58, 54, 45],
			shadowEntities: ['617_patroclus']
		},
		controllers: [{
			type: 'align',
			primary: {
				type: 'point',
				axis: Pioneer.Vector3.XAxis,
				target: '617_patroclus'
			}
		}],
		postCreateFunction: (entity) => {
			// Add the controller for going around sun from earth to venus.
			const oeController = entity.addControllerByClass(Pioneer.OrbitalElementsController, undefined, entity.getControllerByType('align'));
			const oe = new Pioneer.OrbitalElements();
			oe.epoch = 0;
			oe.eccentricity = 0;
			oe.semiMajorAxis = 664;
			oe.meanAngularMotion = 0.00001697791;
			oe.meanAnomalyAtEpoch = 0;
			oe.setOrbitOrientationFromElements(1.97244894756, 0, 0);
			oeController.addOrbitalElements(-1e100, oe);
			oeController.addOrbitalElements(+1e100, oe);
		}
	},
	namaka: {
		groups: ['136108_haumea', 'moons'],
		radius: 85.0,
		label: 'Nāmaka',
		parents: [
			[Number.NEGATIVE_INFINITY, '136108_haumea']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [85, 85, 85]
		},
		controllers: [{
			type: 'align',
			primary: {
				type: 'point',
				axis: Pioneer.Vector3.XAxis,
				target: '136108_haumea'
			}
		}],
		postCreateFunction: (entity) => {
			// Add the controller for going around sun from earth to venus.
			const oeController = entity.addControllerByClass(Pioneer.OrbitalElementsController, undefined, entity.getControllerByType('align'));
			const oe = new Pioneer.OrbitalElements();
			oe.epoch = 0;
			oe.eccentricity = 0.249;
			oe.semiMajorAxis = 25657;
			oe.meanAngularMotion = 3.9786e-6; // 18.2783 days per orbit
			oe.meanAnomalyAtEpoch = 0;
			oe.setOrbitOrientationFromElements(1.97244894756, 0, 0);
			oeController.addOrbitalElements(-1e100, oe);
			oeController.addOrbitalElements(+1e100, oe);
		}
	},
	nix: {
		groups: ['134340_pluto', 'moons', '134340_pluto_barycenter'],
		radius: 24.0,
		label: 'Nix',
		parents: [
			[Number.NEGATIVE_INFINITY, '134340_pluto_barycenter']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [24, 24, 24],
			shadowEntities: ['134340_pluto']
		},
		controllers: [{
			type: 'dynamo',
			url: 'nix/134340_pluto_barycenter/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	styx: {
		groups: ['134340_pluto', 'moons', '134340_pluto_barycenter'],
		radius: 5.5,
		label: 'Styx',
		parents: [
			[Number.NEGATIVE_INFINITY, '134340_pluto_barycenter']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [5.5, 5.5, 5.5],
			shadowEntities: ['134340_pluto']
		},
		controllers: [{
			type: 'dynamo',
			url: 'styx/134340_pluto_barycenter/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	}
});
