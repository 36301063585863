/** @module pioneer */
import {
	Vector3
} from '../internal';

export class CubeMap {
	/**
	 * Converts an xyz vector to a uvFace vector. An array of cube map vectors may be passed in.
	 * @param {Vector3} outUVFace
	 * @param {Vector3} xyz
	 * @param {Vector3[][]} [cubeMapFaceFrames]
	 */
	static xyzToUVFace(outUVFace, xyz, cubeMapFaceFrames = this._defaultCubeMapFaceFrames) {
		/** @type {Vector3[]} */
		let basis;
		for (let i = 0; i < 6; i++) {
			const cubeMapFaceFrame = cubeMapFaceFrames[i];
			const outVector = cubeMapFaceFrame[2];
			let value0 = 0;
			let value1 = 0;
			let value2 = 0;
			let neg = false;
			if (outVector === Vector3.XAxis || outVector === Vector3.XAxisNeg) {
				value0 = xyz.x;
				value1 = xyz.y;
				value2 = xyz.z;
				neg = (outVector === Vector3.XAxisNeg);
			}
			else if (outVector === Vector3.YAxis || outVector === Vector3.YAxisNeg) {
				value0 = xyz.y;
				value1 = xyz.z;
				value2 = xyz.x;
				neg = (outVector === Vector3.YAxisNeg);
			}
			else {
				value0 = xyz.z;
				value1 = xyz.x;
				value2 = xyz.y;
				neg = (outVector === Vector3.ZAxisNeg);
			}
			if (Math.abs(value0) >= Math.abs(value1) && Math.abs(value0) >= Math.abs(value2)) {
				if ((value0 >= 0.0 && !neg) || (value0 < 0.0 && neg)) {
					basis = cubeMapFaceFrame;
					outUVFace.z = i;
				}
			}
		}
		if (basis === undefined) {
			outUVFace.set(NaN, NaN, NaN);
			return;
		}

		// Convert into the uv basis from the xyz basis.
		let z = basis[2].x * xyz.x + basis[2].y * xyz.y + basis[2].z * xyz.z;
		if (z < 0.0) {
			z = 1.0;
		}
		outUVFace.x = (basis[0].x * xyz.x + basis[0].y * xyz.y + basis[0].z * xyz.z) / z;
		outUVFace.y = (basis[1].x * xyz.x + basis[1].y * xyz.y + basis[1].z * xyz.z) / z;

		// Convert from -1 to +1, to 0 to 1.
		outUVFace.x = 0.5 * (outUVFace.x + 1.0);
		outUVFace.y = 0.5 * (outUVFace.y + 1.0);
	}
}

/**
 * The default vectors used for a cube map face frame orientation. The first dimension is 0-5 for each face and the second dimension are the +u, +v, and out vectors, respectively.
 * @type {Vector3[][]}
 */
CubeMap._defaultCubeMapFaceFrames = [
	[Vector3.YAxis, Vector3.ZAxis, Vector3.XAxis],
	[Vector3.XAxisNeg, Vector3.ZAxis, Vector3.YAxis],
	[Vector3.YAxisNeg, Vector3.ZAxis, Vector3.XAxisNeg],
	[Vector3.XAxis, Vector3.ZAxis, Vector3.YAxisNeg],
	[Vector3.YAxis, Vector3.XAxisNeg, Vector3.ZAxis],
	[Vector3.YAxis, Vector3.XAxis, Vector3.ZAxisNeg]
];
