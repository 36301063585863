import { ShaderChunkLogDepth } from './log_depth';

export const SpriteParticlesShader = {
	uniforms: {
		modelViewMatrix: 'mat4',
		projectionMatrix: 'highp mat4',

		...ShaderChunkLogDepth.Uniforms
	},
	properties: {
		transparent: true,
		depthWrite: false,
		side: 'double',
		blending: 'additive'
	},
	vertex: {
		extensions: [
			'EXT_frag_depth'
		],
		code: `
			attribute vec3 position;
			attribute vec4 color;
			attribute vec3 offset;
			attribute float scale;

			uniform mat4 modelViewMatrix;
			uniform mat4 projectionMatrix;

			varying vec4 fColor;
			varying vec2 fPosition;

			${ShaderChunkLogDepth.VertexHead}

			void main() {
				vec4 viewPosition = modelViewMatrix * vec4(offset, 1.0) + vec4(position.x, position.y, -1.0, 0.0) * scale;
				gl_Position = projectionMatrix * viewPosition;
				fColor = color;
				fPosition = position.xy;

				${ShaderChunkLogDepth.Vertex}
			}
			`
	},
	fragment: {
		extensions: [
			'EXT_frag_depth'
		],
		code: `
			precision highp float;

			varying vec4 fColor;
			varying vec2 fPosition;

			${ShaderChunkLogDepth.FragmentHead}

			void main() {
				gl_FragColor = fColor * (1.0 - length(fPosition));

				${ShaderChunkLogDepth.Fragment}
			}
			`
	}
};
