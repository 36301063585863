const en = {
	animate_this_data: 'ANIMATE THIS DATA',
	about: 'ABOUT',
	about_this_dataset: 'ABOUT THIS DATASET',
	vital_signs: 'VITAL SIGNS',
	home: 'HOME',
	missions: 'MISSIONS',
	more: 'MORE',
	vital_signs_desc: 'The latest data, including global measurements of carbon dioxide, air temperature, soil moisture and more.',
	vital_signs_link: 'Datamaps Menu',
	missions_description: "View the current locations of some of NASA's Earth observing satellites, or select from a menu of available missions.",
	missions_link: 'Missions Menu',
	satellites_now_link: 'Satellites Now',
	more_link: 'About Earth Now',
	time_in_orbit: 'Time in orbit',
	satellite_time_rate: 'Satellite Time Rate',
	hr_per_sec: 'HR/SEC',
	min_per_sec: 'MIN/SEC',
	sec_per_sec: 'SEC/SEC',
	real_time: 'REAL TIME',
	latest: 'LATEST',
	dataset: 'Dataset',
	datasets: 'Datasets',
	latest_seven: 'LATEST 7',
	start_time: 'Start time',
	end_time: 'End Time',
	apply: 'APPLY',
	cancel: 'CANCEL',
	range: 'Range',
	launch_location: 'Launch Location',
	launch_vehicle: 'Launch Vehicle',
	toggle_units: 'Toggle Units',
	done: 'DONE',
	clear: 'CLEAR',
	all: 'ALL',
	atmosphere: 'Atmosphere',
	land: 'Land',
	sea: 'Sea',
	key_indicators: 'Key Indicators',
	video_tutorial: 'Video Tutorial',
	sc_aqua: 'Aqua',
	sc_aura: 'Aura',
	sc_gpm: 'GPM',
	sc_grace_fo1: 'GRACE-FO1',
	sc_grace_fo2: 'GRACE-FO2',
	sc_icesat_2: 'ICESat-2',
	sc_jason_3: 'Jason-3',
	sc_suomi_npp: 'Suomi_NPP',
	sc_oco_2: 'OCO-2',
	sc_raincube: 'RainCube',
	sc_smap: 'SMAP',
	sc_terra: 'Terra',
	sc_iss: 'ISS',
	sc_sentinel_6: 'Sentinel-6',
	//
	sc_cygnss_1: 'Cygnss-1',
	sc_cygnss_2: 'Cygnss-2',
	sc_cygnss_3: 'Cygnss-3',
	sc_cygnss_4: 'Cygnss-4',
	sc_cygnss_5: 'Cygnss-5',
	sc_cygnss_6: 'Cygnss-6',
	sc_cygnss_7: 'Cygnss-7',
	sc_cygnss_8: 'Cygnss-8',
	sc_cloudsat: 'CloudSat',
	sc_landsat_7: 'Landsat-7',
	sc_landsat_8: 'Landsat-8',
	sc_landsat_9: 'Landsat-9',
	sc_grifex: 'GRIFEX',
	sc_calipso: 'CALIPSO',
	//
	moon: 'Moon',
	sun: 'Sun',
	loading: 'Loading',
	no_datasets: 'No datasets currently available in Earth Now',
	more_like: 'MORE LIKE THIS',
	earth_now: 'EARTH NOW',
	choose_dates: 'Choose Dates',
	options: 'Options',
	info: 'Info',
	full_menu: 'Full menu',
	satellite_zoom: 'Satellite Zoom',
	lighting: 'Lighting',
	climate_home: 'Global Climate Change Home',
	light_on: 'ON',
	light_off: 'OFF',
	visible_earth: 'Visible Earth',
	more_datasets: 'More Datasets',
	events: 'EVENTS',
	poi: 'Points of Interests',
	gmap: 'Geologic Maps',
	settings: 'Settings',
	city_names: 'City Names',
	fahrenheit: '°F',
	celsius: '°C',
	latest_events: 'Latest Events',
	event_preview:'Event Preview',
	year: 'year',
	loading_video: 'Loading video',
	loading_model: 'Loaing model',
	loading_data: 'Loading data',
	all_years: 'All Years',
	category: 'category',
	time: 'time',
	media: 'media'
};

export default en;
