/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	THREE,
	ThreeJsHelper
} from '../internal';

/**
 * A basic sprite particle system with position, color, and scale attributes.
 */
export class SpriteParticles {
	/**
	 * The constructor.
	 * @param {BaseComponent} component
	 */
	constructor(component) {
		/**
		 * The component that contains this.
		 * @type {BaseComponent}
		 * @private
		 */
		this._component = component;

		/**
		 * Whether or not the particles are relative to the orientation of the entity.
		 * @type {boolean}
		 * @private
		 */
		this._relativeToEntityOrientation = true;

		/**
		 * The positions of the particles.
		 * @type {Float32Array}
		 * @private
		 */
		this._offsetArray = new Float32Array(0);

		/**
		 * The colors of the particles.
		 * @type {Float32Array}
		 * @private
		 */
		this._colorArray = new Float32Array(0);

		/**
		 * The scales of the particles.
		 * @type {Float32Array}
		 * @private
		 */
		this._scaleArray = new Float32Array(0);

		/**
		 * The ThreeJs instanced geometry.
		 * @type {THREE.InstancedBufferGeometry}
		 * @private
		 */
		this._threeJsGeometry = null;

		/**
		 * The ThreeJs material.
		 * @type {THREE.RawShaderMaterial}
		 * @private
		 */
		this._threeJsMaterial = null;

		/**
		 * The ThreeJs object.
		 * @type {THREE.Mesh<THREE.InstancedBufferGeometry, THREE.ShaderMaterial>}
		 * @private
		 */
		this._threeJsObject = null;

		// Setup the Three.js geometry.
		this._threeJsGeometry = new THREE.InstancedBufferGeometry();
		this._threeJsGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array([-1, -1, 0, 1, -1, 0, 1, 1, 0, -1, 1, 0]), 3));
		this._threeJsGeometry.setIndex(new THREE.BufferAttribute(new Uint16Array([0, 1, 2, 2, 3, 0]), 1));
		this._threeJsGeometry.setAttribute('offset', new THREE.InstancedBufferAttribute(this._offsetArray, 3));
		this._threeJsGeometry.setAttribute('color', new THREE.InstancedBufferAttribute(this._colorArray, 4));
		this._threeJsGeometry.setAttribute('scale', new THREE.InstancedBufferAttribute(this._scaleArray, 1));

		// Setup the Three.js material.
		this._threeJsMaterial = this._component.getEntity().getScene().getEngine().getMaterialManager().getPreloaded('sprite_particles');

		// Setup the Three.js object.
		this._threeJsObject = /** @type {THREE.Mesh<THREE.InstancedBufferGeometry, THREE.ShaderMaterial>} */ (ThreeJsHelper.createMeshObjectGivenGeometry(component, this._threeJsMaterial, this._threeJsGeometry));
	}

	/**
	 * Gets the Three.js object.
	 * @returns {THREE.Mesh<THREE.InstancedBufferGeometry, THREE.ShaderMaterial>}
	 */
	getThreeJsObject() {
		return this._threeJsObject;
	}

	/**
	 * Gets the Three.js object.
	 * @returns {THREE.ShaderMaterial}
	 */
	getThreeJsMaterial() {
		return this._threeJsMaterial;
	}

	/**
	 * Sets the positions of the particles.
	 * @param {Float32Array} particleOffsets
	 */
	setParticleOffsets(particleOffsets) {
		this._setAttribute('offset', particleOffsets, 3);
	}

	/**
	 * Sets the colors of the particles.
	 * @param {Float32Array} particleColors
	 */
	setParticleColors(particleColors) {
		this._setAttribute('color', particleColors, 4);
	}

	/**
	 * Sets the scales of the particles.
	 * @param {Float32Array} particleScales
	 */
	setParticleScales(particleScales) {
		this._setAttribute('scale', particleScales, 1);
	}

	/**
	 * Gets whether or not the particles are relative to the orientation of the entity. Defaults to true.
	 * @returns {boolean}
	 */
	getRelativeToEntityOrientation() {
		return this._relativeToEntityOrientation;
	}

	/**
	 * Sets whether or not the particles are relative to the orientation of the entity.
	 * @param {boolean} relativeToEntityOrientation
	 */
	setRelativeToEntityOrientation(relativeToEntityOrientation) {
		this._relativeToEntityOrientation = relativeToEntityOrientation;
	}

	/**
	 * Prepares the sprite particles for rendering.
	 * @param {CameraComponent} camera
	 */
	prepareForRender(camera) {
		// Set the position of the ThreeJs object.
		ThreeJsHelper.setPositionToEntity(this._threeJsObject, this._component.getEntity(), camera);

		// Set the orientation of the ThreeJs object if it is relative to the entity orientation.
		if (this._relativeToEntityOrientation) {
			ThreeJsHelper.setOrientationToEntity(this._threeJsObject, this._component.getEntity());
		}
	}

	/**
	 * Sets the values of an attribute, and adjusts the number of particles if needed.
	 * @param {string} name
	 * @param {Float32Array} array
	 * @param {number} itemSize
	 * @private
	 */
	_setAttribute(name, array, itemSize) {
		let attribute = this._threeJsGeometry.getAttribute(name);
		if (attribute instanceof THREE.InstancedBufferAttribute) {
			if (attribute.count !== array.length / itemSize) {
				this._offsetArray = new Float32Array(array.length / itemSize * 3);
				this._colorArray = new Float32Array(array.length / itemSize * 4);
				this._scaleArray = new Float32Array(array.length / itemSize * 1);
				this._threeJsGeometry.instanceCount = array.length / itemSize;
				this._threeJsGeometry.setAttribute('offset', new THREE.InstancedBufferAttribute(this._offsetArray, 3));
				this._threeJsGeometry.setAttribute('color', new THREE.InstancedBufferAttribute(this._colorArray, 4));
				this._threeJsGeometry.setAttribute('scale', new THREE.InstancedBufferAttribute(this._scaleArray, 1));
			}
		}
		attribute = this._threeJsGeometry.getAttribute(name);
		if (attribute instanceof THREE.InstancedBufferAttribute) {
			attribute.copyArray(array);
			attribute.needsUpdate = true;
		}
	}
}
