/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';

/** A controller that adds a factor of different positions. */
export class PositionSumController extends Pioneer.BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Pioneer.Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The list of entities and their factors.
		 * @type {Pioneer.FastMap<string, {ref: Pioneer.EntityRef, mult: number, add: number}>}
		 * @private
		 */
		this._entities = new Pioneer.FastMap();

		this.addModifiedState('position');
	}

	/**
	 * Adds an entity with mult and add factors.
	 * @param {string} entityName - The entity whose position to use.
	 * @param {number} mult - A multiply factor of the position.
	 * @param {number} add - An added offset in the direction of the position.
	 */
	addEntity(entityName, mult, add) {
		// Set the ref.
		const ref = new Pioneer.EntityRef(this.getEntity().getScene());
		ref.setName(entityName);

		// Add it to the list.
		this._entities.set(entityName, { ref, mult, add });

		// Mark that this is dependent on the position of the entity.
		this.addDependentState(entityName, 'position');
	}

	/**
	 * Removes an entity.
	 * @param {string} entityName
	 */
	removeEntity(entityName) {
		// Remove it from the list.
		this._entities.delete(entityName);

		// Mark that this is no longer dependent on the position of the entity.
		this.removeDependentState(entityName, 'position');
	}

	/**
	 * Sets the params of the entity, which has already been added.
	 * @param {string} entityName - The entity whose position to use.
	 * @param {number | undefined} mult - A multiply factor of the position.
	 * @param {number | undefined} add - An added offset in the direction of the position.
	 */
	setEntityParams(entityName, mult, add) {

		// Check if it already exists.
		if (!this._entities.has(entityName)) {
			throw new Error(`The entity ${entityName} has not been previously added to the controller.`);
		}

		// Update the params.
		const params = this._entities.get(entityName);
		if (mult !== undefined) {
			params.mult = mult;
		}
		if (add !== undefined) {
			params.add = add;
		}
	}

	/**
	 * Updates the position.
	 * @override
	 * @package
	 */
	__update() {
		const accumulatedPosition = Pioneer.Vector3.pool.get();
		const position = Pioneer.Vector3.pool.get();

		// Go through each entity,
		accumulatedPosition.set(0, 0, 0);
		for (let i = 0, l = this._entities.size; i < l; i++) {
			const entry = this._entities.getAt(i).value;

			// Get the entity.
			const entity = entry.ref.get();
			if (entity === null) {
				continue;
			}

			// Get the position of the entity relative to the current parent.
			entity.getPositionRelativeToEntity(position, Pioneer.Vector3.Zero, this.getEntity().getParent());

			// Accumulate the position with the factors.
			const magnitude = position.magnitude();
			accumulatedPosition.addMult(accumulatedPosition, position, entry.mult + (magnitude > 0 ? entry.add / magnitude : 0));
		}

		// Set the accumulated position.
		this.getEntity().setPosition(accumulatedPosition);

		Pioneer.Vector3.pool.release(accumulatedPosition);
		Pioneer.Vector3.pool.release(position);
	}
}
