/**
 * A place to store fixed global references.
 * This is useful for storing fixed references, so we can access them from anywhere.
 * There's no proxy here, so we can't subscribe to changes and mutation is not tracked.
 * These references should be set once and never changed.
 */

const globalRefs = {
	getManager: null,
	getTileManager: null,
	pioneer: null,
	router: null,
	setQuery: null,
	nextAutoplaySlideTimeout: null,
	autoplayCommandTimeout: null
};

const setGlobalRef = (refName, ref) => {
	if (globalRefs[refName] === undefined) {
		console.warn(`Reference ${refName} is not defined.`);
		return;
	}

	globalRefs[refName] = ref;
};

export default globalRefs;

export { setGlobalRef };
