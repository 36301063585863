/* eslint-disable react/jsx-no-useless-fragment */
import {
	createHashRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
	Navigate
} from 'react-router-dom';
import React from 'react';

// Import css
import '../www/assets/css/style.css';
import '../www/assets/css/layout.css';
import '../www/assets/css/labels.css';
import '../www/assets/css/vital_signs.css';
import '../www/assets/css/branding.css';
import '../www/assets/css/template_mobile.css';
import './app.css';

import { DEFAULT_VIDEO } from './data/videos_data';
import { setGlobalRef } from './managers/globalRefs';

// Master Components.
import ConfigMaster from './components/master/ConfigMaster';
import ValidationMaster from './components/master/ValidationMaster';
import DataMaster from './components/master/DataMaster';
import ManagerMaster from './components/master/ManagerMaster';
import PioneerMaster from './components/master/PioneerMaster';
import CameraMaster from './components/master/CameraMaster';
import UILayout from './layouts/UILayout';
import EventAndStateMaster from './components/master/EventAndStateMaster';
import SpoutMaster from './components/master/SpoutMaster';

// Layouts.
import EarthLayout from './layouts/EarthLayout';

// Pages.
import VitalSign, { DefaultVitalSign } from './pages/VitalSign';
import EventDetail from './pages/EventDetail';
import SatellitesNow from './pages/SatellitesNow';
import SpacecraftDetail from './pages/SpacecraftDetail';
//import SplashScreen from './pages/SplashScreen';
import EventYear, { DefaultEventYear } from './pages/EventYear';
import VideoDetail from './pages/VideoDetail';
import EventCategory from './pages/EventCategory';
import EventPreview from './pages/EventPreview';
import PoiDetail from './pages/PoiDetail';
import GmapDetail from './pages/GmapDetail';
import StellarDetail from './pages/StellarDetail';

import SplashScreen from './components/splash_screen';
/**
 * The Root is responsible for the following:
 *   - Determining the hierarchical reliance structure
 *   - For instance, Config is loaded first, then Pioneer, then the managers.
 *   - Validation is not reliant upon Config so can be initialised at the same time.
 *
 * The UI should only render once the managers are created (and getManager is available from the global state).
 * @returns {React.Component}
 */
const Root = () => (
	<ConfigMaster>
		<PioneerMaster>
			<ManagerMaster>
				<ValidationMaster />
				<DataMaster />
				<CameraMaster />
				<EarthLayout />
				<UILayout />
				<SpoutMaster />
			</ManagerMaster>
		</PioneerMaster>
		<EventAndStateMaster />
		<SplashScreen />
	</ConfigMaster>
);

/**
 * Build and structure routes with root, layout and page components.
 */
const router = createHashRouter(
	createRoutesFromElements(
		<Route path='/' element={<Root />}>
			<Route path='vital-signs' element={<VitalSign />}>
				<Route path='videos'>
					<Route index element={<Navigate replace to={DEFAULT_VIDEO} />} />
					<Route path=':videoParam' element={<VideoDetail />} />
				</Route>
				<Route path=':vitalSignParam'>
					<Route index element={<DefaultVitalSign />} />
					<Route path=':datasetParam' element={<></>} />
				</Route>
			</Route>
			<Route path='satellites'>
				<Route index element={<SatellitesNow />} />
				<Route path=':spacecraftParam' element={<SpacecraftDetail />} />
			</Route>
			<Route path='events'>
				<Route index element={<DefaultEventYear />} />
				<Route path=':eventYearParam' element={<EventYear />}>
					<Route index element={<EventCategory />} />
					<Route path=':eventCategoryParam' element={<EventCategory />}>
						<Route path=':eventParam' element={<EventDetail />} />
					</Route>
				</Route>
				<Route path='preview/:eventPreviewParam' element={<EventPreview />} />
			</Route>
			<Route path='poi'>
				<Route path=':poiParam' element={<PoiDetail />} />
			</Route>
			<Route path='gmap'>
				<Route path=':gmapParam' element={<GmapDetail />} />
			</Route>
			<Route path='stellar'>
				<Route path=':stellarParam' element={<StellarDetail />} />
			</Route>
			<Route path='*' element={<></>} />
		</Route>
	)
);

// Set router reference.
setGlobalRef('router', router);

/**
 * App.js is responsible for initializing the routing structure.
 * @returns {React.Component}
 */
function App() {
	return (
		<RouterProvider router={router} />
	);
}

export default App;
