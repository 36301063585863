/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	Color,
	ComponentRef,
	Entity,
	LatLonAlt,
	MaterialUtils,
	MathUtils,
	ShaderChunkLogDepth,
	ShaderFix,
	SpheroidComponent,
	THREE,
	ThreeJsHelper,
	Vector3
} from '../../internal';

/**
 * An atmosphere over a component with a spheroid.
 */
export class AtmosphereComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The density of the atmosphere at sea level.
		 * @type {number}
		 * @private
		 */
		this._density = 0.0;

		/**
		 * The scale height of the atmosphere.
		 * @type {number}
		 * @private
		*/
		this._scaleHeight = 1.0;

		/**
		 * The emissivity of the atmosphere. 0 means not emissive and 1 means 100% emissive.
		 * @type {number}
		 * @private
		 */
		this._emissivity = 0.0;

		/**
		 * The base color of the atmosphere.
		 * @type {Color}
		 * @private
		 */
		this._color = new Color();
		this._color.freeze();

		/**
		 * The brightness of the sun in the sky when looking through the atmosphere.
		 * @type {number}
		 * @private
		 */
		this._sunBrightness = 1.0;

		/**
		 * The sunset color of the atmosphere.
		 * @type {Color}
		 * @private
		 */
		this._sunsetColor = new Color();
		this._sunsetColor.freeze();

		/**
		 * The sunset intensity of the atmosphere.
		 * @type {number}
		 * @private
		 */
		this._sunsetIntensity = 0.0;

		/**
		 * A reference to the spheroid component.
		 * @type {ComponentRef<SpheroidComponent>}
		 * @private
		 */
		this._spheroidComponentRef = new ComponentRef(this.getEntity().getScene());
		this._spheroidComponentRef.setByType(this.getEntity().getName(), 'spheroid');
		this._spheroidComponentRef.setRefChangedCallback(this._spheroidRefChangedCallback.bind(this));

		// Bind the callbacks to this.
		this._spheroidChangedCallback = this._spheroidChangedCallback.bind(this);

		// It aligns with the entity's orientation.
		this.__setUsesEntityOrientation(true);
	}

	/**
	 * Gets the spheroid that this uses.
	 * @returns {SpheroidComponent}
	 */
	getSpheroid() {
		return this._spheroidComponentRef.get();
	}

	/**
	 * Gets the density of the atmosphere at sea level.
	 * @returns {number}
	 */
	getDensity() {
		return this._density;
	}

	/**
	 * Sets the density of the atmosphere at sea level.
	 * @param {number} density
	 */
	setDensity(density) {
		this._density = density;
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'density', density);
	}

	/**
	 * Gets the scale height of the atmosphere.
	 * @returns {number}
	 */
	getScaleHeight() {
		return this._scaleHeight;
	}

	/**
	 * Sets the scale height of the atmosphere.
	 * @param {number} scaleHeight
	 */
	setScaleHeight(scaleHeight) {
		this._scaleHeight = scaleHeight;
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'scaleHeight', scaleHeight);
	}

	/**
	 * Gets the emissivity of the atmosphere. 0 means not emissive and 1 means 100% emissive. Defaults to 0.
	 * @returns {number}
	 */
	getEmissivity() {
		return this._emissivity;
	}

	/**
	 * Sets the emissivity of the atmosphere.
	 * @param {number} emissivity
	 */
	setEmissivity(emissivity) {
		this._emissivity = emissivity;
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'emissivity', emissivity);
	}

	/**
	 * Gets the base color of the atmosphere. Default is white.
	 * @returns {Color}
	 */
	getColor() {
		return this._color;
	}

	/**
	 * Sets the base color of the atmosphere. Default is white.
	 * @param {Color} color
	 */
	setColor(color) {
		this._color.thaw();
		this._color.copy(color);
		this._color.freeze();
		ThreeJsHelper.setUniformColorRGB(this.getThreeJsMaterials()[0], 'color', color);
	}

	/**
	 * Gets the brightness of the sun in the sky when looking through the atmosphere.
	 * @returns {number}
	 */
	getSunBrightness() {
		return this._sunBrightness;
	}

	/**
	 * Sets the brightness of the sun in the sky when looking through the atmosphere.
	 * @param {number} sunBrightness
	 */
	setSunBrightness(sunBrightness) {
		this._sunBrightness = sunBrightness;
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'sunBrightness', sunBrightness);
	}

	/**
	 * Gets the sunset color of the atmosphere. Default is white.
	 * @returns {Color}
	 */
	getSunsetColor() {
		return this._sunsetColor;
	}

	/**
	 * Sets the sunset color of the atmosphere. Default is white.
	 * @param {Color} sunsetColor
	 */
	setSunsetColor(sunsetColor) {
		this._sunsetColor.thaw();
		this._sunsetColor.copy(sunsetColor);
		this._sunsetColor.freeze();
		ThreeJsHelper.setUniformColorRGB(this.getThreeJsMaterials()[0], 'sunsetColor', sunsetColor);
	}

	/**
	 * Gets the sunset intensity of the atmosphere. Default is 0.
	 * @returns {number}
	 */
	getSunsetIntensity() {
		return this._sunsetIntensity;
	}

	/**
	 * Sets the sunset intensity of the atmosphere. Default is 0.
	 * @param {number} sunsetIntensity
	 */
	setSunsetIntensity(sunsetIntensity) {
		this._sunsetIntensity = sunsetIntensity;
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'sunsetIntensity', sunsetIntensity);
	}

	/**
	 * Sets the reference to use for the spheroid component, by name or the type index.
	 * @param {string | number} nameOrTypeIndex
	 */
	setSpheroidReference(nameOrTypeIndex) {
		if (typeof nameOrTypeIndex === 'string') {
			this._spheroidComponentRef.setByName(this.getEntity().getName(), nameOrTypeIndex);
		}
		else {
			this._spheroidComponentRef.setByType(this.getEntity().getName(), 'spheroid', nameOrTypeIndex);
		}
	}

	/**
	 * Cleans up the component.
	 * @override
	 * @package
	 */
	__destroy() {
		// Remove the spheroid changed callback.
		const spheroidComponent = this._spheroidComponentRef.get();
		if (spheroidComponent !== null) {
			spheroidComponent.removeChangedCallback(this._spheroidChangedCallback);
		}

		super.__destroy();
	}

	/**
	 * Updates the camera-non-specific parts of the component.
	 * @override
	 * @internal
	 */
	__update() {
		// Update the spheroid component reference.
		this._spheroidComponentRef.update();
	}

	/**
	 * Prepare the component for rendering.
	 * @param {CameraComponent} camera
	 * @override
	 * @internal
	 */
	__prepareForRender(camera) {
		// Set the camera position uniform.
		const cameraPosition = Vector3.pool.get();
		cameraPosition.neg(this.getEntity().getCameraSpacePosition(camera));
		cameraPosition.rotateInverse(this.getEntity().getOrientation(), cameraPosition);
		ThreeJsHelper.setUniformVector3(this.getThreeJsMaterials()[0], 'cameraPosition', cameraPosition);
		Vector3.pool.release(cameraPosition);

		// Set the lightPosition and lightColor uniform.
		MaterialUtils.setLightSourceUniforms(this.getThreeJsMaterials(), this.getEntity(), camera);

		// Set the entity's orientation to get the lights into the entity-space.
		ThreeJsHelper.setUniformQuaternion(this.getThreeJsMaterials()[0], 'entityOrientation', this.getEntity().getOrientation());

		// Set the orientation to the entity's orientation.
		ThreeJsHelper.setOrientationToEntity(this.getThreeJsObjects()[0], this.getEntity());

		// Set the Three.js object position the entity's camera-space position.
		ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects()[0], this.getEntity(), camera);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	__loadResources() {
		// Create the material.
		const material = new THREE.RawShaderMaterial({
			uniforms: {
				lightPositions: new THREE.Uniform([new THREE.Vector3(1, 0, 0), new THREE.Vector3(1, 0, 0), new THREE.Vector3(1, 0, 0), new THREE.Vector3(1, 0, 0), new THREE.Vector3(1, 0, 0)]),
				lightColors: new THREE.Uniform([new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0)]),
				lightRadii: new THREE.Uniform([0, 0, 0, 0, 0]),
				numLights: new THREE.Uniform(0),

				density: new THREE.Uniform(this._density),
				scaleHeight: new THREE.Uniform(this._scaleHeight),
				emissivity: new THREE.Uniform(this._emissivity),
				equatorialRadius: new THREE.Uniform(1.0),
				polarRadius: new THREE.Uniform(1.0),
				cameraPosition: new THREE.Uniform(new THREE.Vector3(1, 0, 0)),
				entityOrientation: new THREE.Uniform(new THREE.Vector4(1, 0, 0, 0)),
				color: new THREE.Uniform(new THREE.Vector3(this._color.r, this._color.g, this._color.b)),
				sunBrightness: new THREE.Uniform(this._sunBrightness),
				sunsetColor: new THREE.Uniform(new THREE.Vector3(this._sunsetColor.r, this._sunsetColor.g, this._sunsetColor.b)),
				sunsetIntensity: new THREE.Uniform(this._sunsetIntensity),

				...ShaderChunkLogDepth.ThreeUniforms
			},
			vertexShader: vertexShader,
			fragmentShader: fragmentShader,
			transparent: true,
			depthWrite: false,
			blending: THREE.NormalBlending
		});
		ShaderFix.fix(material);
		this.getThreeJsMaterials().push(material);

		const object = ThreeJsHelper.createMeshObject(this, material, [{ name: 'position', dimensions: 3 }], false);
		this.getThreeJsObjects().push(object);

		// Create the mesh itself.
		const numLatVerts = 64;
		const numLonVerts = 128;
		const latStep = MathUtils.pi / (numLatVerts - 1);
		const lonStep = MathUtils.twoPi / numLonVerts;
		const numVerts = (numLonVerts + 1) * numLatVerts;
		const meshPositions = new Float32Array(numVerts * 3);
		const meshIndices = new Uint16Array(numLonVerts * (numLatVerts - 1) * 6);
		const xyz = Vector3.pool.get();
		const lla = LatLonAlt.pool.get();
		for (let latI = 0; latI < numLatVerts; latI++) {
			lla.lat = latI * latStep - MathUtils.halfPi;
			lla.alt = 0;
			const cosLat = Math.cos(lla.lat);
			const sinLat = Math.sin(lla.lat);
			for (let lonI = 0; lonI < numLonVerts + 1; lonI++) {
				lla.lon = lonI * lonStep - MathUtils.pi;

				const vertexI = latI * (numLonVerts + 1) + lonI;
				meshPositions[vertexI * 3 + 0] = cosLat * Math.cos(lla.lon);
				meshPositions[vertexI * 3 + 1] = cosLat * Math.sin(lla.lon);
				meshPositions[vertexI * 3 + 2] = sinLat;

				const triangleI = latI * numLonVerts + lonI;
				if (latI < numLatVerts - 1 && lonI < numLonVerts) {
					meshIndices[triangleI * 6 + 0] = (numLonVerts + 1) * (latI + 0) + (lonI + 0);
					meshIndices[triangleI * 6 + 1] = (numLonVerts + 1) * (latI + 1) + (lonI + 0);
					meshIndices[triangleI * 6 + 2] = (numLonVerts + 1) * (latI + 1) + (lonI + 1);
					meshIndices[triangleI * 6 + 3] = (numLonVerts + 1) * (latI + 0) + (lonI + 0);
					meshIndices[triangleI * 6 + 4] = (numLonVerts + 1) * (latI + 1) + (lonI + 1);
					meshIndices[triangleI * 6 + 5] = (numLonVerts + 1) * (latI + 0) + (lonI + 1);
				}
			}
		}
		LatLonAlt.pool.release(lla);
		Vector3.pool.release(xyz);
		ThreeJsHelper.setVertices(object.geometry, 'position', meshPositions);
		ThreeJsHelper.setIndices(object.geometry, meshIndices);

		// Make it render before other transparent objects.
		ThreeJsHelper.setRenderOrder(object, -1);

		// Make it used in the dynamic environment map.
		ThreeJsHelper.useInDynEnvMap(object, true);

		// Update from the spheroid properties.
		this._spheroidChangedCallback();

		return Promise.resolve();
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		ThreeJsHelper.destroyAllObjectsAndMaterials(this);
	}

	/**
	 * Callback called when the spheroid reference is found or lost.
	 * @param {SpheroidComponent} oldRef
	 * @param {SpheroidComponent} newRef
	 * @private
	 */
	_spheroidRefChangedCallback(oldRef, newRef) {
		if (oldRef !== null) {
			oldRef.removeChangedCallback(this._spheroidChangedCallback);
		}
		if (newRef !== null) {
			newRef.addChangedCallback(this._spheroidChangedCallback);
		}
		this._spheroidChangedCallback();
	}

	/**
	 * Callback to be called when the spheroid component changed.
	 * @private
	 */
	_spheroidChangedCallback() {
		// Set the radii uniforms.
		const spheroidComponent = this._spheroidComponentRef.get();
		const material = this.getThreeJsMaterials()[0];
		if (spheroidComponent !== null) {
			// Set the radii.
			this.__setRadius(Math.max(spheroidComponent.getEquatorialRadius(), spheroidComponent.getPolarRadius()));
			// Set the radii uniforms.
			if (material !== undefined) {
				ThreeJsHelper.setUniformNumber(material, 'equatorialRadius', spheroidComponent.getEquatorialRadius());
				ThreeJsHelper.setUniformNumber(material, 'polarRadius', spheroidComponent.getPolarRadius());
			}
		}
		else {
			this.__setRadius(0);
			if (material !== undefined) {
				ThreeJsHelper.setUniformNumber(material, 'equatorialRadius', 0);
				ThreeJsHelper.setUniformNumber(material, 'polarRadius', 0);
			}
		}
	}
}

const vertexShader = `
	attribute vec3 position;
	uniform mat4 projectionMatrix;
	uniform mat4 modelViewMatrix;

	uniform float scaleHeight;
	uniform float equatorialRadius;
	uniform float polarRadius;

	${ShaderChunkLogDepth.VertexHead}

	varying vec3 localPosition;

	void main() {
		float scaleHeightMultiplier = 20.0;
		localPosition = vec3(position.x * (equatorialRadius + scaleHeight * scaleHeightMultiplier), position.y * (equatorialRadius + scaleHeight * scaleHeightMultiplier), position.z * (polarRadius + scaleHeight * scaleHeightMultiplier));
		vec4 viewPosition = modelViewMatrix * vec4(localPosition, 1.);
		gl_Position = projectionMatrix * viewPosition;
		gl_Position.w = viewPosition.y;

		${ShaderChunkLogDepth.Vertex}
	}`;

const fragmentShader = `
	precision highp float;

	uniform vec3 lightPositions[5];
	uniform vec3 lightColors[5];
	uniform int numLights;

	uniform float density;
	uniform float scaleHeight;
	uniform float equatorialRadius;
	uniform float polarRadius;
	uniform vec3 cameraPosition;
	uniform vec4 entityOrientation;
	uniform vec3 color;
	uniform float emissivity;
	uniform float sunBrightness;
	uniform vec3 sunsetColor;
	uniform float sunsetIntensity;

	${ShaderChunkLogDepth.FragmentHead}

	varying vec3 localPosition;

	const int numIterations = 5;

	// Inverse rotate a vector by a quaternion.
	vec3 quatRotInv(vec4 q, vec3 v) {
		float tx = q.w * v.x - q.y * v.z + q.z * v.y;
		float ty = q.w * v.y - q.z * v.x + q.x * v.z;
		float tz = q.w * v.z - q.x * v.y + q.y * v.x;
		float tw = q.x * v.x + q.y * v.y + q.z * v.z;
		float x = tx * q.w + tw * q.x + ty * q.z - tz * q.y;
		float y = ty * q.w + tw * q.y + tz * q.x - tx * q.z;
		float z = tz * q.w + tw * q.z + tx * q.y - ty * q.x;
		return vec3(x, y, z);
	}

	// Given an origin and direction, computes the sampling start and end as distance from the origin in the direction.
	void getStartEndSamples(out float start, out float end, vec3 origin, vec3 direction, float maxDistance, float groundRadius, float atmosphereScaleHeight) {
		// Get the along the ray perpendicular to the sphere.
		float perpD = -dot(origin, direction);
		vec3 perp = origin + direction * perpD;

		// Figure out the sample distance.
		float atmosphereRadius = groundRadius + atmosphereScaleHeight * 6.0;
		float chordHalfLength = sqrt(max(0.0, atmosphereRadius * atmosphereRadius - dot(perp, perp)));

		// Figure out starting and ending sample points, and step distance.
		start = max(0.0, perpD - chordHalfLength);
		end = min(maxDistance, perpD + chordHalfLength);
	}

	// Gets the density of the atmosphere at a given position.
	float getDensity(vec3 position, float radius, float density, float atmosphereScaleHeight) {
		return density * exp(min(radius - length(position), 0.0) / atmosphereScaleHeight);
	}

	// Returns 0 if the ray does not intersect and 1.0 if the ray very intersects (with a gradient inbetween).
	float getDayLevel(vec3 origin, vec3 direction, float radius, float scaleHeight) {
		float blendHeight = scaleHeight * radius / 200.0;
		float perpD = -dot(origin, direction);
		float depth = radius - sqrt(dot(origin, origin) - sign(perpD) * perpD * perpD);
		if (depth < 0.0) { // day
			return 1.0 - max(0.0, 0.25 * depth / blendHeight + 0.25);
		}
		else { // night
			return 1.0 - min(1.0, 0.75 * depth / blendHeight + 0.25);
		}
	}

	float easeInOut(float x, float sharpness) {
		float b = sharpness;
		if (x < 0.5) {
			return max(0.0, (pow(b, 2.0 * x) - 1.0) / (2.0 * (b - 1.0)));
		}
		else {
			return min(1.0, 1.0 - (pow(b, 2.0 * (1.0 - x)) - 1.0) / (2.0 * (b - 1.0)));
		}
	}

	vec3 adjustOverbrightness(vec3 color) {
		float maxColor = max(color.r, max(color.g, color.b));
		if (maxColor > 1.0) {
			float f = (maxColor - 1.0) / maxColor;
			color.r = min(1.0, pow(color.r / maxColor, 1.0 / maxColor));
			color.g = min(1.0, pow(color.g / maxColor, 1.0 / maxColor));
			color.b = min(1.0, pow(color.b / maxColor, 1.0 / maxColor));
		}
		return color;
	}

	// Calculates a glow around the light direction (the star).
	float glow(float spread, float amount, float lightDotCamera) {
		return amount * spread / (1.0 + spread - lightDotCamera);
	}

	vec4 getEmissiveColor(float totalDensity, vec3 cameraPositionS, vec3 color, float emissivity) {

		// The color that will be added onto gl_FragColor.
		vec4 outColor;

		// Apply the total density to the transparency of the atmosphere.
		outColor.a = emissivity * clamp(totalDensity, 0.0, 1.0);

		// Multiply it all together with the source light color.
		outColor.rgb = emissivity * color * clamp(pow(15.0 * totalDensity / (density * equatorialRadius), 0.2), 0.75, 1.0);

		// Make it more opaque when lower down.
		outColor.a = mix(outColor.a, emissivity, getDensity(cameraPositionS, equatorialRadius + scaleHeight, 1.0, 2.0 * scaleHeight));

		// Clamp it to make it clean for the day/night transition.
		outColor.a = clamp(outColor.a, 0.0, 1.0);

		return outColor;
	}

	// Gets the color for an atmosphere for a light.
	vec4 getColor(float totalDensity, vec3 lightColor, vec3 lightPosition, float spheroidRatio, vec3 positionS, vec3 cameraPositionS, vec3 cameraToPositionUnit) {

		// The color starts out in full brightness (as if emissivity was 1.0).
		vec4 outColor = getEmissiveColor(totalDensity, cameraPositionS, lightColor * color, 1.0);

		// Make the alpha dependent on the brightness of the light.
		outColor.a *= length(lightColor) / sqrt(3.0);

		// Setup vectors.
		highp vec3 lightPositionS = quatRotInv(entityOrientation, lightPosition);
		lightPositionS.z *= spheroidRatio;
		highp vec3 lightToPosition = positionS - lightPositionS;
		highp vec3 lightToPositionUnit = normalize(lightToPosition / 1.0e8);

		// Get the day level, from 0 to 1, and apply it to the alpha. Lots of tricks to get it looking good on earth.
		vec3 dayRefUp = normalize(cameraPositionS - min(0.0, dot(cameraPositionS, cameraToPositionUnit)) * cameraToPositionUnit);
		float dayLevel = -dot(lightToPositionUnit, dayRefUp);
		outColor.rgb *= easeInOut(0.5 + 2.0 * dayLevel, 2.0);
		outColor.a *= easeInOut(1.0 + 2.0 * dayLevel, 2.0);

		// Brighten up the atmosphere when looking from space toward the sun.
		float lightDotCamera = max(0.0, -dot(lightToPositionUnit, cameraToPositionUnit));
		outColor.a = clamp(outColor.a * (1.0 + glow(0.004, 1.0, lightDotCamera)), 0.0, 1.0);

		// Add narrower sun glare.
		outColor.rgb *= lightColor * (1.0 + sunBrightness * outColor.a * glow(0.00004, 1.0, lightDotCamera));

		// Add broader sun glare.
		outColor.rgb *= lightColor * (1.0 + sunBrightness * outColor.a * glow(0.04, 0.125, lightDotCamera));

		// Apply the sunset.
		float lightDotHorizon = pow(clamp(1.0 - dot(lightToPositionUnit, dayRefUp), 0.0, 1.0), 2.0);
		float cameraDotHorizon = pow(clamp(1.0 - dot(cameraToPositionUnit, dayRefUp), 0.0, 1.0), 8.0);
		float sunsetAmount = sunsetIntensity * lightDotHorizon * cameraDotHorizon * glow(0.04, 0.5, lightDotCamera);
		outColor.rgb = mix(outColor.rgb, sunsetColor, clamp(sunsetAmount, 0.0, 1.0));

		return outColor;
	}

	void main(void) {
		// Convert everything into a sphere frame.
		float spheroidRatio = equatorialRadius / polarRadius;
		highp vec3 positionS = localPosition;
		highp vec3 cameraPositionS = cameraPosition;
		positionS.z *= spheroidRatio;
		cameraPositionS.z *= spheroidRatio;

		highp vec3 cameraToPosition = positionS - cameraPositionS;
		float cameraToPositionDist = length(cameraToPosition / 1.0e8) * 1.0e8;
		highp vec3 cameraToPositionUnit = cameraToPosition / cameraToPositionDist;

		// Get the start and end of the sampling from the camera to the position.
		float start;
		float end;
		getStartEndSamples(start, end, cameraPositionS, cameraToPositionUnit, 1.0e24, equatorialRadius, scaleHeight);
		float fracPerStep = 1.0 / float(numIterations - 1);
		float stepDist = fracPerStep * (end - start);

		// Do the sampling.
		float totalDensity = 0.0;
		float segmentStart = start;
		for (int j = 0; j < numIterations; j++) {
			// Get the distance that this segment covers.
			float segDist = stepDist;
			if (j == 0 || j == numIterations - 1) {
				segDist *= 0.5;
			}

			// Get the segment start that we're looking at.
			vec3 p = cameraPositionS + segmentStart * cameraToPositionUnit;

			// Get the density at that segment start. It'll be the density for the whole segment.
			float densityAtP = getDensity(p, equatorialRadius, density, scaleHeight);

			// Add it to the total density.
			totalDensity += densityAtP * segDist;

			// Next step.
			segmentStart += stepDist;
		}

		// Add emissivity lightness.
		gl_FragColor += getEmissiveColor(totalDensity, cameraPositionS, color, emissivity);

		// For each light,
		for (int i = 0; i < 5; i++) {
			if (i >= numLights) {
				break;
			}

			// If it's not a camera light,
			if (length(lightPositions[i]) > 0.0) {
				// Add on the color for the light.
				gl_FragColor += getColor(totalDensity, lightColors[i], lightPositions[i], spheroidRatio, positionS, cameraPositionS, cameraToPositionUnit);
			}
		}

		// Adjust for values that are greater than one.
		gl_FragColor.rgb = adjustOverbrightness(gl_FragColor.rgb);

		${ShaderChunkLogDepth.Fragment}
	}`;
