/** @module pioneer */
import {
	BaseController,
	Entity,
	Quaternion,
	Vector3
} from '../../internal';

/** A controller that sets a fixed position and/or orientation. */
export class FixedController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		// The position to be updated. If null, the position is not fixed.
		this._position = null;

		// The orientation to be updated. If null, the orientation is not fixed.
		this._orientation = null;
	}

	/**
	 * Gets the position to be fixed, or null if the position is not fixed. Don't modify this directly.
	 * @returns {Vector3}
	 */
	getPosition() {
		return this._position;
	}

	/**
	 * Sets the position. Use null to unfix the position.
	 * @param {Vector3} position
	 */
	setPosition(position) {
		if (position !== null) {
			if (this._position === null) {
				this._position = new Vector3();
			}
			this._position.thaw();
			this._position.copy(position);
			this._position.freeze();
		}
		else {
			this._position = null;
		}
		if (position !== null) {
			this.addModifiedState('position');
			this.addModifiedState('velocity');
		}
		else {
			this.removeModifiedState('position');
			this.removeModifiedState('velocity');
		}
	}

	/**
	 * Gets the orientation to be fixed, or null if the orientation is not fixed. Don't modify this directly.
	 * @returns {Quaternion}
	 */
	getOrientation() {
		return this._orientation;
	}

	/**
	 * Sets the orientation.
	 * @param {Quaternion} orientation
	 */
	setOrientation(orientation) {
		if (orientation !== null) {
			if (this._orientation === null) {
				this._orientation = new Quaternion();
			}
			this._orientation.thaw();
			this._orientation.copy(orientation);
			this._orientation.freeze();
		}
		else {
			this._orientation = null;
		}
		if (orientation !== null) {
			this.addModifiedState('orientation');
		}
		else {
			this.removeModifiedState('orientation');
		}
	}

	/**
	 * If the position is fixed, updates the position to the fixed position.
	 * @param {Vector3} position
	 * @param {number} _time
	 * @override
	 * @internal
	 */
	__updatePositionAtTime(position, _time) {
		if (this._position !== null) {
			position.copy(this._position);
		}
	}

	/**
	 * If the position is fixed, updates the velocity to zero.
	 * @param {Vector3} velocity
	 * @param {number} _time
	 * @override
	 * @internal
	 */
	__updateVelocityAtTime(velocity, _time) {
		if (this._position !== null) {
			velocity.set(0, 0, 0);
		}
	}

	/**
	 * If the orientation is fixed, updates the orientation to the fixed orientation.
	 * @param {Quaternion} orientation
	 * @param {number} _time
	 * @override
	 * @internal
	 */
	__updateOrientationAtTime(orientation, _time) {
		if (this._orientation !== null) {
			orientation.copy(this._orientation);
		}
	}

	/**
	 * Updates the position and orientation if they are fixed.
	 * @override
	 * @internal
	 */
	__update() {
		if (this._position !== null) {
			this.getEntity().setPosition(this._position);
			this.getEntity().setVelocity(Vector3.Zero);
		}
		if (this._orientation !== null) {
			this.getEntity().setOrientation(this._orientation);
		}
	}
}
