import { useState, useCallback, useLayoutEffect } from 'react';

import throttle from 'lodash.throttle';


/**
 * A hook to calculate an element's dimensions, based off: https://github.com/Swizec/useDimensions/blob/master/src/index.ts
 */

const getDimensionObject = node => {
	const rect = node.getBoundingClientRect();

	return {
		width: rect.width,
		height: rect.height
		// top: "x" in rect ? rect.x : rect.top,
		// left: "y" in rect ? rect.y : rect.left,
		// x: "x" in rect ? rect.x : rect.left,
		// y: "y" in rect ? rect.y : rect.top,
		// right: rect.right,
		// bottom: rect.bottom
	};
};

export default function useDimensions(liveMeasure = false) {
	const [dimensions, setDimensions] = useState({});
	const [node, setNode] = useState(null);

	const ref = useCallback(node => {
		setNode(node);
	}, []);

	useLayoutEffect(() => {
		if (node) {
			// const measure = () => window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)));
			const measure = throttle(() => {
				setDimensions(getDimensionObject(node));
			}, 200);
			measure();

			if (liveMeasure) {
				window.addEventListener('resize', measure);
				document.addEventListener('scroll', measure);

				return () => {
					window.removeEventListener('resize', measure);
					document.removeEventListener('scroll', measure);
				};
			}
		}
	}, [node, liveMeasure]);

	return [ref, dimensions, node];
}
