import React from 'react';
import Calendar from './calendar';
import HijriDateChooser from './hijri_date_chooser';
import moment from 'moment';
import ar from '../languages/ar';

import CloseButton from './close_button';

import { appStore, datasetStore, uiStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';

import '../../www/assets/css/date_picker.css';
import { cloneDate, getDateFromLocalDateStr, dateToUTCString, localDateToNoonUTC } from '../helpers/tools';

const PLACEHOLDER_DATE = 'yyyy-mm-dd';

class DatePicker extends React.Component {
	constructor(props) {
		super(props);

		/**
		 * startDate and endDate local states differ from the global states.
		 * Locally, they determine the current dates selections BEFORE the 'apply' button is pressed.
		 */
		this.state = {
			startDate: null,
			endDate: null
		};

		// Todo: Date can be formatted to full month names.
		this.monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December'
		];
		// Refs
		this._startCalendar = React.createRef();
		this._endCalendar = React.createRef();
		this.startInput = React.createRef();
		this.endInput = React.createRef();
		this.startToggle = React.createRef();
		this.endToggle = React.createRef();
		this.datePickerRef = React.createRef();

		// Binds
		this._latestToggle = this._latestToggle.bind(this);
		this._lastSevenToggle = this._lastSevenToggle.bind(this);
		this._onInputBlur = this._onInputBlur.bind(this);
		this._onInputClick = this._onInputClick.bind(this);
		this._applyButtonClicked = this._applyButtonClicked.bind(this);
		this.setStartDateFromCalendar = this.setStartDateFromCalendar.bind(this);
		this.setEndDateFromCalendar = this.setEndDateFromCalendar.bind(this);
		// this.startCalendarSelect = this.startCalendarSelect.bind(this);
		this.setStartDate = this.setStartDate.bind(this);
		this.setEndDate = this.setEndDate.bind(this);
		this.startMonthChanged = this.startMonthChanged.bind(this);
		this.endMonthChanged = this.endMonthChanged.bind(this);
		this.startYearChanged = this.startYearChanged.bind(this);
		this.endYearChanged = this.endYearChanged.bind(this);
		this.resetDates = this.resetDates.bind(this);
    	this.changeArDate = this.changeArDate.bind(this);
	}

	componentDidMount() {
		this.resetDates();

		// Subscribe to dates changes.
		this.dsUnsubscribe = datasetStore.subscribeTo('animationDates', this.resetDates);
		document.addEventListener('click', this.handleOutsideClick);
	}

	// This component doesn't appear to get unmounted, even though it show up conditionally.
	// Nonetheless, we shold follow best practices and call unsubscribe on unmount.
	componentWillUnmount() {
		typeof this.dsUnsubscribe === 'function' && this.dsUnsubscribe();
		document.removeEventListener('click', this.handleOutsideClick);
	}

  changeArDate(date, is_start) {
		const date_str = date.locale('en').format('YYYY-MM-DD');
		console.log('CHANGE AR ', date_str, is_start);
		if (is_start) {
			this.setState({ startDate: new Date(date_str) }, () => {
				this._startCalendar.current.setDate(date_str);
			});
		} else {
			this.setState({ endDate: new Date(date_str) }, () => {
				this._endCalendar.current.setDate(date_str);
			});
		}
	}

	getArabicCalendarInput(id, min, max, selected) {
		return (
			<HijriDateChooser
				input_name={`ar-${id}`} className='form-control' display_date_format='iYYYY/iMM/iDD' min={min}
				max={max} selected={selected} on_change={this.changeArDate}
			/>
		);
	}


	/**
	 * Notes for date consistency task.
	 * One of the main difficulties when dealing with Date objects is that they are mutable.
	 * For instance, if we set the state of a component to a certain startDate,
	 * we can simply call this.state.startDate.setMonth(3) and it will mutate the state without
	 * triggering any state reaction.
	 * This becomes especially problematic when we start passing around references tooo these dates
	 * to be used elsewhere. If the references are not copied, we can simply mutate the date from anywhere!
	 *
	 * To combat this, we need to consider strict rules in getters and setters (when to create new date objects)
	 * as well as how we store the dates in state (is it always worth coverting to ISO strings?)
	 */
	render() {
		const { startDate, endDate } = this.state;
		const { isMobile } = appStore.stateSnapshot;
		const { getManager } = globalRefs;

		const {
			isLatest,
			isLatestSeven,
			manifestLimits: { start: manifestStart, end: manifestEnd },
			isMonthly,
			getDatasetManifestData,
			animationStartDate,
			animationEndDate
		} = getManager('dataset');

		const { close: closeModal } = this.props;

		const { missingDates } = getDatasetManifestData() || {};

		/**
		 * Determine the earliest date to be able to select either in the 'Start' calendar or the 'Start' month dropdown.
		 * Determine the latest date to be able to select either in the 'End' calendar or the 'End' month dropdown.
		 * This may change when this component gets a new animation startDate and endDate.
		 */
		const validStartDate = startDate instanceof Date && !isNaN(startDate) ? startDate : manifestStart;
		const validEndDate = endDate instanceof Date && !isNaN(endDate) ? endDate : manifestEnd;

		const earliestDate = localDateToNoonUTC(validStartDate).valueOf() < manifestStart.valueOf() ? cloneDate(validStartDate) : cloneDate(manifestStart);
		const latestDate = localDateToNoonUTC(validEndDate).valueOf() > manifestEnd.valueOf() ? cloneDate(validEndDate) : cloneDate(manifestEnd);

		const pickerData = {
			startPicker: {
				minDate: earliestDate,
				maxDate: validEndDate,
				defaultDate: validStartDate,
				selectedDate: validStartDate
			},
			endPicker: {
				minDate: validStartDate,
				maxDate: latestDate,
				defaultDate: validEndDate,
				selectedDate: validEndDate
			}
		};

		if (validEndDate instanceof Date && latestDate instanceof Date && dateToUTCString(validEndDate) === dateToUTCString(latestDate)) {
			const selectedDate = cloneDate(validEndDate);
			selectedDate.setDate(validEndDate.getDate() - 6);
			pickerData.startPicker.maxDate = validEndDate;
			pickerData.startPicker.defaultDate = selectedDate;

			const { endPicker } = pickerData;
			endPicker.minDate = selectedDate;
		}

		/**
		 * By default, we're setting the dateInputElement to the two day inputs.
		 */
		let dateInputElement = (
			<div className={`row hijri`}>
				<div id='startContainer' className='col-6'>
					<div className='calendar-wrapper w-100'>
						<label>{ar.start_time}</label>
						{this.getArabicCalendarInput('startInput', earliestDate, this.isValidDate(endDate) ? endDate : latestDate, animationStartDate)}
						<div className='gregorian-calendar'>
							<input
							placeholder='yyyy-mm-dd'
							ref={this.startInput}
							readOnly
							id='startInput'
							onBlur={this._onInputBlur}
							onClick={this._onInputClick}
							onClose={this._onInputClose}
							/>
							<button type='button' ref={this.startToggle}>
							<div className='image' />
							</button>
						</div>
					</div>
        	</div>
				<div id='endContainer' className='col-6'>
					<div className='calendar-wrapper w-100'>
          				<label>{ar.end_time}</label>
						{this.getArabicCalendarInput('endInput', this.isValidDate(startDate) ? startDate : earliestDate, latestDate, animationEndDate)}
						<div className='gregorian-calendar'>
							<label>End time</label>
							<input
								placeholder='yyyy-mm-dd'
								ref={this.endInput}
								readOnly
								id='endInput'
								onBlur={this._onInputBlur}
								onClick={this._onInputClick}
								onClose={this._onInputClose}
							/>
							<button type='button' ref={this.endToggle}>
								<div className='image' />
							</button>
						</div>
					</div>
				</div>
			</div>
		);

		// Todo: Simplify this component (or new date picker system when it comes -> https://www.npmjs.com/package/react-day-picker)
		// If month, the dateInputElement becomes year and month dropdown.
		// if (!this.is_arabic && isMonthly) {
		// 	dateInputElement = (
		// 		<div className='row'>
		// 			<div id='startContainer' className='col-12'>
		// 				<div className='calendar-wrapper'>
		// 					<label>Start</label>
		// 					<div className='d-flex flex-row'>
		// 						<select className='no-select month-select' id='startMonthSelect' value={this.monthNames[validStartDate.getUTCMonth()]} onChange={this.startMonthChanged}>
		// 							{this.monthNames.map((monthName, index) => {
		// 								// Disable months earlier than earliest available date.
		// 								// Disable months later then current end date.
		// 								// Disable months that are missing in the dataset manifest.
		// 								const currDate = cloneDate(validStartDate);
		// 								currDate.setMonth(index);
		// 								currDate.setDate(1);
		// 								const currDateStr = dateToUTCString(currDate);

		// 								const disabled = (validStartDate.getUTCFullYear() === earliestDate.getUTCFullYear() && index < earliestDate.getUTCMonth() + 1)
		// 									|| (validStartDate.getUTCFullYear() === validEndDate.getUTCFullYear() && index >= (validEndDate.getUTCMonth() + 1))
		// 									|| missingDates.includes(currDateStr);


		// 								return (<option key={monthName} disabled={disabled}>{monthName}</option>);
		// 							})}
		// 						</select>
		// 						<select className='no-select year-select' id='startYearSelect' value={validStartDate.getUTCFullYear()} onChange={this.startYearChanged}>
		// 							{this.calculateYearList().map(year => {
		// 								if (year > (validEndDate.getUTCFullYear())) {
		// 									return (<option key={year} disabled>{year}</option>);
		// 								} else {
		// 									return (<option key={year}>{year}</option>);
		// 								}
		// 							})}
		// 						</select>
		// 					</div>
		// 				</div>
		// 			</div>
		// 			<div id='endContainer' className='col-12'>
		// 				<div className='calendar-wrapper'>
		// 					<label>End</label>
		// 					<div className='d-flex flex-row'>
		// 						<select className='no-select month-select' id='endMonthSelect' value={this.monthNames[validEndDate.getUTCMonth()]} onChange={this.endMonthChanged}>
		// 							{this.monthNames.map((monthName, index) => {
		// 								// Disable months later than last available date.
		// 								// Disable months earlier than selected start date.
		// 								// Disable months that are missing in the dataset manifest.
		// 								const currDate = cloneDate(validStartDate);
		// 								currDate.setMonth(index);
		// 								currDate.setDate(1);
		// 								const currDateStr = dateToUTCString(currDate);

		// 								const disabled = (validEndDate.getUTCFullYear() === latestDate.getUTCFullYear() && index > latestDate.getUTCMonth())
		// 									|| (validEndDate.getUTCFullYear() === validStartDate.getUTCFullYear() && index <= validStartDate.getUTCMonth())
		// 									|| missingDates.includes(currDateStr);

		// 								return (<option key={monthName} disabled={disabled}>{monthName}</option>);
		// 							})}
		// 						</select>
		// 						<select className='no-select year-select' id='endYearSelect' value={validEndDate.getUTCFullYear()} onChange={this.endYearChanged}>
		// 							{this.calculateYearList().map(year => {
		// 								if (year < (validStartDate.getUTCFullYear())) {
		// 									return (<option key={year} disabled>{year}</option>);
		// 								} else {
		// 									return (<option key={year}>{year}</option>);
		// 								}
		// 							})}
		// 						</select>
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	);
		// }

		const applyButtonElement = <button className='no-select' type='button' onClick={this._applyButtonClicked}>{ar.apply}</button>;

		const { startPicker, endPicker } = pickerData;

		return (
			<div id='date-picker' ref={this.datePickerRef}>
				<div className='container'>
					{!isMobile && <CloseButton size='small' onClick={closeModal} />}
					<div className='header'>
						<button className={'no-select toggle' + (isLatest ? ' selected' : '')} type='button' onClick={this._latestToggle}>
							{ar.latest}
							<br />
							{ar.dataset}
						</button>
						<button className={'no-select toggle' + (isLatestSeven ? ' selected' : '')} type='button' onClick={this._lastSevenToggle}>
							{ar.latest_seven}
							<br />
							{ar.datasets}
						</button>
					</div>
					{dateInputElement}
					{applyButtonElement}
					{!isMobile && <button className='no-select final-button' type='button' aria-label='cancel' onClick={closeModal}>{ar.cancel}</button>}
					<Calendar
						id='startPicker'
						ref={this._startCalendar}
						inputRef={this.startInput}
						toggleRef={this.startToggle}
						minDate={startPicker.minDate}
						maxDate={startPicker.maxDate}
						defaultDate={startPicker.defaultDate}
						selectedDate={startPicker.selectedDate}
						onSelect={this.startCalendarSelect}
					/>
					<Calendar
						id='endPicker'
						ref={this._endCalendar}
						inputRef={this.endInput}
						toggleRef={this.endToggle}
						minDate={endPicker.minDate}
						maxDate={endPicker.maxDate}
						defaultDate={endPicker.defaultDate}
						selectedDate={endPicker.selectedDate}
					/>
				</div>
			</div>
		);
	}

	_onInputBlur(event) {
		const { id } = event.currentTarget;
		if (id === 'startInput') {
			this.endInput.current.disabled = false;
			this.setStartDateFromCalendar();
		} else if (id === 'endInput') {
			this.startInput.current.disabled = false;
			this.setEndDateFromCalendar();
		}
	}

	_onInputBlurPoI(poi) {
		const { id } = poi.currentTarget;
		if (id === 'startInput') {
			this.endInput.current.disabled = false;
			this.setStartDateFromCalendar();
		} else if (id === 'endInput') {
			this.startInput.current.disabled = false;
			this.setEndDateFromCalendar();
		}
	}

	_onInputBlurGmap(gmap) {
		const { id } = gmap.currentTarget;
		if (id === 'startInput') {
			this.endInput.current.disabled = false;
			this.setStartDateFromCalendar();
		} else if (id === 'endInput') {
			this.startInput.current.disabled = false;
			this.setEndDateFromCalendar();
		}
	}

	_onInputClick(event) {
		const { id } = event.currentTarget;
		if (id === 'startInput') {
			this.endInput.current.disabled = true;
		} else if (id === 'endInput') {
			this.startInput.current.disabled = true;
		}
	}
_onInputClickPoI(poi) {
		const { id } = poi.currentTarget;
		if (id === 'startInput') {
			this.endInput.current.disabled = true;
		} else if (id === 'endInput') {
			this.startInput.current.disabled = true;
		}
	}

	_onInputClickGmap(gmap) {
		const { id } = gmap.currentTarget;
		if (id === 'startInput') {
			this.endInput.current.disabled = true;
		} else if (id === 'endInput') {
			this.startInput.current.disabled = true;
		}
	}

	_onInputClose(event) {
		const { id } = event.currentTarget;
		if (id === 'startInput') {
			this.endInput.current.disabled = false;
		} else if (id === 'endInput') {
			this.startInput.current.disabled = false;
		}
	}

	_onInputClosePoI(poi) {
		const { id } = poi.currentTarget;
		if (id === 'startInput') {
			this.endInput.current.disabled = false;
		} else if (id === 'endInput') {
			this.startInput.current.disabled = false;
		}
	}

	_onInputCloseGmap(gmap) {
		const { id } = gmap.currentTarget;
		if (id === 'startInput') {
			this.endInput.current.disabled = false;
		} else if (id === 'endInput') {
			this.startInput.current.disabled = false;
		}
	}

	_applyButtonClicked(evt) {
		evt.preventDefault();
		const { getManager } = globalRefs;
		const { startDate, endDate } = this.state;
		console.log("_applyButtonClicked",startDate, endDate)
		const startDateString = dateToUTCString(startDate);
		const endDateString = dateToUTCString(endDate);

		// Handle empty dates from clear button.
		if (startDateString === '') {
			this.startInput.current.value = PLACEHOLDER_DATE;
		}
		if (endDateString === '') {
			this.endInput.current.value = PLACEHOLDER_DATE;
		}
		if (startDateString === '' || endDateString === '') {
			return;
		}

		getManager('route').navigateToDates({
			start: startDateString,
			end: endDateString
		});

		uiStore.setGlobalState({ isDetailPanelOpen: false });

		const { close: closeModal } = this.props;
		if (closeModal) { closeModal(); }
	}

	handleOutsideClick = event => {
		if (!this.datePickerRef.current || (this.datePickerRef.current?.contains(event.target) || document.getElementById('calendar-toggle')?.contains(event.target)) || event.target?.closest('.pika-single')) {
			return;
		}
		const { close: closeModal } = this.props;
		if (closeModal) { closeModal(); }
	};

	setEndDateFromCalendar() {
		// Return if using placeholder date.
		if (this.endInput.current.value === PLACEHOLDER_DATE) {
			return;
		}

		const endDate = getDateFromLocalDateStr(this.endInput.current.value);
		this.setEndDate(endDate);
	}

	resetDates({ start, end } = {}) {
		const { animationDates } = datasetStore.stateSnapshot;

		const startDate = cloneDate(start || animationDates.start);
		const endDate = cloneDate(end || animationDates.end);

		this.setState({ startDate, endDate });
	}

	setEndDate(newEndDate) {
		this.setState({ endDate: newEndDate });
	}

	setStartDateFromCalendar() {
		// Return if using placeholder date.
		if (this.startInput.current.value === PLACEHOLDER_DATE) {
			return;
		}

		const startDate = getDateFromLocalDateStr(this.startInput.current.value);
		this.setStartDate(startDate);
	}

	setStartDate(newStartDate) {
		this.setState({ startDate: newStartDate });
	}

	startMonthChanged(e) {
		const { selectedIndex } = e.target;
		const { startDate } = this.state;

		// Return if not changed.
		if (selectedIndex === startDate.getUTCMonth()) { return; }

		// Create new date and set new month.
		const newStartDate = cloneDate(startDate);
		newStartDate.setUTCMonth(selectedIndex);

		this.setStartDate(newStartDate);
	}

	endMonthChanged(e) {
		const { selectedIndex } = e.target;
		const { endDate } = this.state;

		// Return if not changed.
		if (selectedIndex === endDate.getUTCMonth()) { return; }

		// Create new date and set new month.
		const newEndDate = cloneDate(endDate);
		newEndDate.setUTCMonth(selectedIndex);

		this.setEndDate(newEndDate);
	}

	startYearChanged(e) {
		const newYear = parseInt(e.target.value);
		const { startDate, endDate } = this.state;

		// Return if not changed.
		if (newYear === startDate.getUTCFullYear()) { return; }

		const { getManager } = globalRefs;
		const { start: earliestDate } = getManager('dataset').manifestLimits;

		// Create new date and set new year.
		const newStartDate = cloneDate(startDate);
		newStartDate.setUTCFullYear(newYear);

		// Month vars.
		const currentMonth = newStartDate.getUTCMonth();
		const endDateMonth = endDate.getUTCMonth();
		const earliestMonth = earliestDate.getUTCMonth();

		// If changing to the same as the end year or the earliest year, check and update months accordingly.
		if (newYear === endDate.getUTCFullYear()) {
			if (currentMonth > endDateMonth) {
				newStartDate.setUTCMonth(endDateMonth);
			}
		} else if (newYear === earliestDate.getUTCFullYear()) {
			if (currentMonth < earliestMonth) {
				newStartDate.setUTCMonth(earliestMonth);
			}
		}

		this.setStartDate(newStartDate);
	}

	endYearChanged(e) {
		const newYear = parseInt(e.target.value);
		const { startDate, endDate } = this.state;

		// Return if not changed.
		if (newYear === endDate.getUTCFullYear()) { return; }

		const { getManager } = globalRefs;
		const { end: latestDate } = getManager('dataset').manifestLimits;

		// Create new date and set new year.
		const newEndDate = cloneDate(endDate);
		newEndDate.setUTCFullYear(newYear);

		// Month vars.
		const currentMonth = newEndDate.getUTCMonth();
		const startDateMonth = startDate.getUTCMonth();
		const latestMonth = latestDate.getUTCMonth();

		if (newYear === startDate.getUTCFullYear()) {
			if (currentMonth < startDateMonth) {
				newEndDate.setUTCMonth(startDateMonth);
			}
		} else if (newYear === latestDate.getUTCFullYear()) {
			if (currentMonth > latestMonth) {
				newEndDate.setUTCMonth(latestMonth);
			}
		}

		this.setEndDate(newEndDate);
	}

	calculateStartYearList() {
		const { getManager } = globalRefs;
		const earliestYear = getManager('dataset').manifestLimits.start.getUTCFullYear();
		const latestYear = this.state.endDate.getUTCFullYear();
		const availableYearList = [];
		for (let i = earliestYear; i <= latestYear; i++) {
			availableYearList.push(i);
		}
		return availableYearList;
	}

	calculateYearList() {
		const { getManager } = globalRefs;
		const earliestYear = getManager('dataset').manifestLimits.start.getUTCFullYear();
		const latestYear = getManager('dataset').manifestLimits.end.getUTCFullYear();
		const availableYearList = [];
		for (let i = earliestYear; i <= latestYear; i++) {
			availableYearList.push(i);
		}
		return availableYearList;
	}

	calculateStartMonthList() {
		const availableMonths = [];
		return availableMonths;
	}

	_latestToggle() {
		const { getManager } = globalRefs;
		const { isLatest } = getManager('dataset');
		const { close: closeModal } = this.props;

		// If already latest, dont do anything.
		if (isLatest) {
			return;
		}

		// Close the date picker.
		closeModal();

		getManager('dataset').resetToLatest();
	}

	_lastSevenToggle() {
		const { getManager } = globalRefs;
		const { close: closeModal } = this.props;
		const { isLatestSeven } = getManager('dataset');

		// If already latest seven, dont do anything.
		if (isLatestSeven) {
			return;
		}

		// Close the date picker.
		closeModal();

		getManager('dataset').playLatestSeven();
	}

  isValidDate(date) {
		if (Object.prototype.toString.call(date) === '[object Date]') {
			// it is a date object
			if (isNaN(date.getTime())) {
				// date is not valid
				return false;
			} else {
				// date is valid
				return true;
			}
		} else {
		// not a date
			return false;
		}
	}
}

export default React.memo(DatePicker);
