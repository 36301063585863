import React from 'react';
import RightChevronSVG from '../../www/assets/images/chevron_right.svg';
import LeftChevronSVG from '../../www/assets/images/chevron_left.svg';

import '../../www/assets/css/footer_nav.css';
import { datasetStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';

class FooterNav extends React.PureComponent {
	constructor(props) {
		super(props);
		this.is_arabic = window.config && window.config.acceptLanguage && window.config.acceptLanguage == 'ar';

		this.state = {
			showBWDButton: false,
			showFWDButton: false,
			scrollLeft: this.is_arabic ? 9999 : 0,
			content: null
		};
		// Refs
		this._container = React.createRef();
		this._content = React.createRef();

		// Binds
		this._checkScrollButtons = this._checkScrollButtons.bind(this);
		this._scrollLeft = this._scrollLeft.bind(this);
		this._scrollRight = this._scrollRight.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this._checkScrollButtons);
		this.cdUnsubscribe = datasetStore.subscribeTo('currentDataset', () => this.scrollToItem());
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this._checkScrollButtons);
		typeof this.cdUnsubscribe === 'function' && this.cdUnsubscribe();
	}

	static getDerivedStateFromProps(nextProps, state) {
		const newState = Object.entries(nextProps).reduce((obj, [key, value]) => {
			if (key in state && state[key] !== nextProps[key]) {
				obj[key] = value;
			}
			return obj;
		}, {});


		return newState;
	}

	componentDidUpdate(prevProps, prevState) {
		const { content } = this.state;
		if (!content || !prevState?.content) { return; }
		// Force check scroll buttons on content change
		if (content.length !== prevState.content.length || content[0].innerHTML !== prevState.content[0].innerHTML) {
			this._checkScrollButtons();
		}
	}

	/**
	 * Scroll to the left by container's width. Stop at 0.
	 */
	_scrollLeft() {
		if (this._container.current === null) {
			return;
		}
		const containerWidth = this._container.current.getBoundingClientRect().width + 20;
		const contentWidth = this._content.current.getBoundingClientRect().width;

		// const scrollLeft = Math.max(0, this._container.current.scrollLeft - containerWidth);
		const scrollLeft = Math.max(this._container.current.scrollLeft - containerWidth, -1 * contentWidth);
		console.log('scroll left', scrollLeft);
		this._container.current.scrollLeft = scrollLeft;
		this.setState({ scrollLeft }, this._checkScrollButtons);
	}

	/**
	 * Scroll to the right by container's width. Stop at content's width.
	 */
	_scrollRight() {
		if (this._container.current === null) {
			return;
		}
		const containerWidth = this._container.current.getBoundingClientRect().width;
		const contentWidth = this._content.current.getBoundingClientRect().width;
		const scrollLeft = Math.max(0, this._container.current.scrollLeft - containerWidth);
		// const scrollLeft = Math.min(this._container.current.scrollLeft + containerWidth, contentWidth);
		this._container.current.scrollLeft = scrollLeft;
		this.setState({ scrollLeft }, this._checkScrollButtons);
	}

	// Todo: simplify this functionality by removing refs, use functional components, and useLayoutEffect to measure element widths, reduce number of state changes / re-renders - #3191
	// https://bobbyhadz.com/blog/react-get-width-of-element
	scrollToItem() {
		const { getManager } = globalRefs;
		const { vsIndex } = getManager('content');

		const itemWidth = this._content.current?.firstElementChild?.getBoundingClientRect().width;
		const scrollLeft = Math.min(0, (vsIndex - 5) * -1 * itemWidth); // why is this 5... for some reason the vsindex starts at 4 for the start item. -RK

		if (this._container.current) {
			this._container.current.scrollLeft = scrollLeft;
		}
		this.setState({ scrollLeft }, this._checkScrollButtons);
	}

	_checkScrollButtons() {
		const { scrollLeft } = this.state;
		const containerWidth = this._container.current.getBoundingClientRect().width;
		const contentWidth = this._content.current.getBoundingClientRect().width;

		this.setState({
			showBWDButton: scrollLeft < 0 && contentWidth > containerWidth, // handle left arrow
			showFWDbutton: Math.abs(scrollLeft) < containerWidth && contentWidth > containerWidth // handle right arrow
		});
	}

	render() {
		const { showFWDbutton, showBWDButton, content } = this.state;

		return (
			<div className='footer-nav container mx-0 pl-0'>
				<div className='list-container container' ref={this._container}>
					<ul className='list-content' ref={this._content}>
						{content}
					</ul>
				</div>
				<ul className='flex-row control'>
					<li className={'backward chevron ' + (showBWDButton ? 'active' : ' hidden')} onClick={this._scrollRight}>
						<button className='no-select' aria-label='left-arrow'>
							<LeftChevronSVG />
						</button>
					</li>
					<li className={'forward chevron ' + (showFWDbutton ? '' : ' hidden')} onClick={this._scrollLeft}>
						<button className='no-select' aria-label='right-arrow'>
							<RightChevronSVG />
						</button>
					</li>
				</ul>
			</div>
		);
	}
}

export default FooterNav;
