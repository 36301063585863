/** @module pioneer-scripts */
import { Entity } from '../entity';
import { EntityUtils } from './entity_utils';
import * as Pioneer from 'pioneer';

Entity.register({
	sc_3d_winds: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00200,
		extentsRadius: 0.00250,
		label: '3D-Winds',
		parents: [
			[347025366.1839032, 'earth']
		],
		trail: {
			length: 5573.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_3d_winds'
		}]
	},
	sc_ace: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0008,
		extentsRadius: 0.0023,
		label: 'ACE',
		parents: [
			[265550465.18489534, 'earth']
		],
		trail: {
			length: 31715490.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_ace/ace.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_ace',
			dataType: 'pos'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			}
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.YAxisNeg,
			periodInHours: 0.0033
		}]
	},
	sc_acrimsat: {
		groups: ['earth', 'spacecraft'],
		radius: 0.0015,
		label: 'ACRIMSAT',
		parents: [
			[-963797, 'earth'],
			[459950467, ''] // End of mission
		],
		trail: {
			length: 5933.5
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_acrimsat/acrimsat.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_acrimsat'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			}
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.YAxisNeg,
			periodInHours: 0.00238
		}]
	},
	sc_acs3: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.000143783,
		extentsRadius: 0.0073102,
		label: 'ACS3',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_acs3/acs3.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_acs3'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_acs3',
				axis: Pioneer.Vector3.YAxisNeg
			}
		}]
	},
	sc_aim: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00070,
		extentsRadius: 0.0014,
		label: 'AIM',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 5788.83
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_aim'
		}]
	},
	sc_aqua: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00400,
		extentsRadius: 0.015,
		label: 'Aqua',
		parents: [
			[73781738, 'earth']
		],
		trail: {
			length: 5933.5
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_aqua/Aqua.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_aqua'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_aqua',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_ascends: {
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'ASCENDS',
		parents: [
			[347025366.1839032, 'earth']
		],
		trail: {
			length: 5573.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_ascends'
		}]
	},
	sc_aura: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.003450,
		extentsRadius: 0.017,
		label: 'Aura',
		parents: [
			[143161358, 'earth']
		],
		trail: {
			length: 5933.5
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_aura/Aura.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_aura'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_aura',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_c_nofs: {
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'C/NOFS',
		parents: [
			[352800006.1854904, 'earth'],
			[502051928.18300515, '']
		],
		trail: {
			length: 5573.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_c_nofs'
		}]
	},
	sc_calipso: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.001650,
		extentsRadius: 0.005,
		label: 'CALIPSO',
		parents: [
			[199506047, 'earth'],
			[744120069, '']
		],
		trail: {
			length: 5933.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_calipso/calipso.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_calipso'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_calipso',
				axis: Pioneer.Vector3.YAxis
			}
		}]
	},
	sc_chandra: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0059,
		extentsRadius: 0.010,
		label: 'Chandra',
		parents: [
			[-12716935, 'earth']
		],
		trail: {
			length: 228505.5
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_chandra/chandra.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_chandra/earth/all',
			dataType: 'pos'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: new Pioneer.Vector3(-0.70710678118, -0.70710678118, 0)
			}
		}]
	},
	sc_clarreo: {
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'CLARREO',
		parents: [
			[347025366.1839032, 'earth']
		],
		trail: {
			length: 5573.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_clarreo'
		}]
	},
	sc_cloudsat: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0025,
		extentsRadius: 0.0025,
		label: 'CloudSat',
		parents: [
			[199491286, 'earth'],
			[756302469, '']
		],
		trail: {
			length: 5933.5
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_cloudsat/CloudSat.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_cloudsat'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_cloudsat',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_cluster_ii_fm5: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.001450,
		extentsRadius: 0.00435,
		label: 'Rumba',
		parents: [
			[19105314, 'earth']
		],
		trail: {
			length: 195541.5
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_cluster_ii/cluster_ii.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_cluster_ii_fm5'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_cluster_ii_fm5',
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_cluster_ii_fm6: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.001450,
		extentsRadius: 0.00435,
		label: 'Salsa',
		parents: [
			[17028089, 'earth']
		],
		trail: {
			length: 195351.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_cluster_ii/cluster_ii.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_cluster_ii_fm6'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_cluster_ii_fm6',
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_cluster_ii_fm7: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.001450,
		extentsRadius: 0.00435,
		label: 'Samba',
		parents: [
			[17028093, 'earth']
		],
		trail: {
			length: 195316.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_cluster_ii/cluster_ii.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_cluster_ii_fm7'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_cluster_ii_fm7',
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_cluster_ii_fm8: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.001450,
		extentsRadius: 0.00435,
		label: 'Tango',
		parents: [
			[19105052, 'earth']
		],
		trail: {
			length: 195388.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_cluster_ii/cluster_ii.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_cluster_ii_fm8'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_cluster_ii_fm8',
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_cygnss_1: {
		groups: ['earth', 'spacecraft'],
		radius: 0.0008,
		label: 'CYGNSS 1',
		parents: [
			[548415404.1851876, 'earth']
		],
		trail: {
			length: 5573.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_cygnss/CYGNSS.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_cygnss_1'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_cygnss_1',
				axis: Pioneer.Vector3.YAxisNeg
			}
		}]
	},
	sc_cygnss_2: {
		groups: ['earth', 'spacecraft'],
		radius: 0.0008,
		label: 'CYGNSS 2',
		parents: [
			[548415404.1851876, 'earth']
		],
		trail: {
			length: 5573.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_cygnss/CYGNSS.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_cygnss_2'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_cygnss_2',
				axis: Pioneer.Vector3.YAxisNeg
			}
		}]
	},
	sc_cygnss_3: {
		groups: ['earth', 'spacecraft'],
		radius: 0.0008,
		label: 'CYGNSS 3',
		parents: [
			[548415404.1851876, 'earth']
		],
		trail: {
			length: 5573.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_cygnss/CYGNSS.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_cygnss_3'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_cygnss_3',
				axis: Pioneer.Vector3.YAxisNeg
			}
		}]
	},
	sc_cygnss_4: {
		groups: ['earth', 'spacecraft'],
		radius: 0.0008,
		label: 'CYGNSS 4',
		parents: [
			[548415404.1851876, 'earth']
		],
		trail: {
			length: 5573.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_cygnss/CYGNSS.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_cygnss_4'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_cygnss_4',
				axis: Pioneer.Vector3.YAxisNeg
			}
		}]
	},
	sc_cygnss_5: {
		groups: ['earth', 'spacecraft'],
		radius: 0.0008,
		label: 'CYGNSS 5',
		parents: [
			[548415404.1851876, 'earth']
		],
		trail: {
			length: 5573.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_cygnss/CYGNSS.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_cygnss_5'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_cygnss_5',
				axis: Pioneer.Vector3.YAxisNeg
			}
		}]
	},
	sc_cygnss_6: {
		groups: ['earth', 'spacecraft'],
		radius: 0.0008,
		label: 'CYGNSS 6',
		parents: [
			[548415404.1851876, 'earth'],
			[722736069, '']
		],
		trail: {
			length: 5573.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_cygnss/CYGNSS.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_cygnss_6'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_cygnss_6',
				axis: Pioneer.Vector3.YAxisNeg
			}
		}]
	},
	sc_cygnss_7: {
		groups: ['earth', 'spacecraft'],
		radius: 0.0008,
		label: 'CYGNSS 7',
		parents: [
			[548415404.1851876, 'earth']
		],
		trail: {
			length: 5573.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_cygnss/CYGNSS.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_cygnss_7'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_cygnss_7',
				axis: Pioneer.Vector3.YAxisNeg
			}
		}]
	},
	sc_cygnss_8: {
		groups: ['earth', 'spacecraft'],
		radius: 0.0008,
		label: 'CYGNSS 8',
		parents: [
			[548415404.1851876, 'earth']
		],
		trail: {
			length: 5573.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_cygnss/CYGNSS.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_cygnss_8'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_cygnss_8',
				axis: Pioneer.Vector3.YAxisNeg
			}
		}]
	},
	sc_dscovr: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00090,
		extentsRadius: 0.0020,
		label: 'DSCOVR',
		parents: [
			[476711467.18497694, 'earth']
		],
		trail: {
			length: 60 * 60 * 24 * 365
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_dscovr/dscovr.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_dscovr/earth/all',
			dataType: 'pos'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.XAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_dscovr',
				axis: Pioneer.Vector3.ZAxisNeg
			}
		}]
	},
	sc_eo_1: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0030,
		extentsRadius: 0.0010,
		label: 'EO-1',
		parents: [
			[344589947.18311954, 'earth'],
			[544104069.186, '']
		],
		trail: {
			length: 5933.33
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_eo_1/eo-1.gltf',
			rotate: [
				{ y: 45 }
			],
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_eo_1'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_eo_1',
				axis: Pioneer.Vector3.XAxisNeg
			}
		}]
	},
	sc_euclid: {
		groups: ['earth', 'spacecraft', 'telescope'],
		radius: 0.0035,
		label: 'Euclid',
		parents: [
			[741498789, 'earth']
		],
		trail: {
			length: 31536000
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_euclid/euclid.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_euclid/earth'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'align',
				target: 'sun',
				axis: Pioneer.Vector3.ZAxis,
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_explorer_1: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0010,
		extentsRadius: 0.0010,
		label: 'Explorer 1',
		parents: [
			[-1322726967, 'earth'],
			[-939025103, '']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_explorer_1_v2/explorer_1.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_explorer_1/earth'
		}, {
			type: 'align',
			primary: {
				type: 'velocity',
				target: 'sc_explorer_1',
				axis: Pioneer.Vector3.YAxis
			}
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.YAxisNeg,
			periodInHours: 0.000022
		}]
	},
	sc_face: {
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'FACE',
		parents: [
			[347025366.1839032, 'earth'],
			[631022168.1838934, '']
		],
		trail: {
			length: 5933.33
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_face'
		}]
	},
	sc_fgrst: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00140,
		extentsRadius: 0.0040,
		label: 'FGRST',
		parents: [
			[344589947.18311954, 'earth'],
			[661006268.1833516, '']
		],
		trail: {
			length: 5733.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_fgrst'
		}]
	},
	sc_gacm: {
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'GACM',
		parents: [
			[347025366.1839032, 'earth']
		],
		trail: {
			length: 5733.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_gacm'
		}]
		// mission is cancelled
	},
	sc_galex: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0010,
		extentsRadius: 0.00140,
		label: 'GALEX',
		parents: [
			[352800006.1854904, 'earth'],
			[425718607.184, '']
		],
		trail: {
			length: 5733.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_galex'
		}]
	},
	sc_geo_cape: {
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'GEO-CAPE',
		parents: [
			[347025366.1839032, 'earth']
		],
		trail: {
			length: 5733.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_geo_cape'
		}]
		// mission is cancelled
	},
	sc_geotail: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0018,
		extentsRadius: 0.003,
		label: 'Geotail',
		parents: [
			[-234758765, 'earth'],
			[722891591, '']
		],
		trail: {
			length: 450372.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_geotail/geotail.gltf',
			shadowEntities: ['earth']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_geotail/earth/orb'
		}, {
			type: 'fixed',
			orientation: new Pioneer.Quaternion(0.662, 0.749, 0, 0)
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.YAxis,
			periodInHours: 0.00083333333,
			relativeToTime: 0
		}]
	},
	sc_goes_12: {
		groups: ['earth', 'spacecraft'],
		radius: 0.003,
		label: 'GOES 12',
		parents: [
			[344589947.18311954, 'earth'],
			[661006268.1833516, '']
		],
		trail: {
			length: 5733.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_goes_12'
		}]
	},
	sc_goes_13: {
		groups: ['earth', 'spacecraft'],
		radius: 0.003,
		label: 'GOES 13',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 5733.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_goes_13'
		}]
	},
	sc_goes_14: {
		groups: ['earth', 'spacecraft'],
		radius: 0.003,
		label: 'GOES 14',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 5733.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_goes_14'
		}]
	},
	sc_goes_15: {
		groups: ['earth', 'spacecraft'],
		radius: 0.003,
		label: 'GOES 15',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 5733.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_goes_15'
		}]
	},
	sc_gpm: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0020,
		extentsRadius: 0.007,
		label: 'GPM',
		parents: [
			[629484755.7856493, 'earth']
		],
		trail: {
			length: 5549.5
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_gpm/GPM.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_gpm'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_gpm',
				axis: Pioneer.Vector3.ZAxis
			}
		}],
		postCreateFunction: (entity) => {
			const spin = entity.addControllerByClass(Pioneer.SpinController);
			spin.setJoint('GPM_antenna');
			spin.setRate(Pioneer.MathUtils.degToRad(192));
			spin.setAxis(Pioneer.Vector3.YAxisNeg, true);
			spin.setClampedToRealTime(true);
		}
	},
	sc_grace_1: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0015,
		extentsRadius: 0.0019,
		label: 'GRACE-1',
		parents: [
			[69656411.096817, 'earth'],
			[562382552.2587994, '']
		],
		trail: {
			length: 5575.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_grace/grace.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_grace_1/earth/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sc_grace_2',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxis
			}
		}, {
			type: 'coverage',
			coverage: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
			update: (entity) => {
				const microwave = /** @type {Pioneer.ConnectedSpriteComponent} */(entity.getComponent('microwave'));
				const engine = entity.getScene().getEngine();
				const newOffset = Pioneer.MathUtils.wrap(microwave.getTextureYOffset() - Pioneer.MathUtils.clamp(engine.getTimeRate(), -1, +1) * engine.getDeltaTime(), 0, 1);
				microwave.setTextureYOffset(newOffset);
				const other = entity.getScene().getEntity('sc_grace_2');
				const shouldBeEnabled = other !== null && other.getPosition().distance(entity.getPosition()) <= 3929;
				if (shouldBeEnabled !== microwave.isEnabled()) {
					microwave.setEnabled(shouldBeEnabled);
				}
			}
		}],
		postCreateFunction: entity => {
			const microwave = entity.addComponentByClass(Pioneer.ConnectedSpriteComponent, 'microwave');
			microwave.setTextureUrl('$STATIC_ASSETS_URL/sprites/sine_wave.png');
			microwave.setEntity1('sc_grace_1');
			microwave.setEntity1Offset(new Pioneer.Vector3(0, -0.00165, 0));
			microwave.setEntity2('sc_grace_2');
			microwave.setEntity2Offset(new Pioneer.Vector3(0, -0.00165, 0));
			microwave.setWidths(0.0005, 0.0005);
			microwave.setColorMultiplier(new Pioneer.Color(0.5, 1.0, 1.0));
			microwave.setTextureRepeat(true);
			microwave.setTextureStretch(4);
		}
	},
	sc_grace_2: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0015,
		extentsRadius: 0.0019,
		label: 'GRACE-2',
		parents: [
			[69656411.096817, 'earth'],
			[562382552.2588, '']
		],
		trail: {
			length: 5575.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_grace/grace.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_grace_2/earth/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sc_grace_1',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_grace_fo1: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0015,
		extentsRadius: 0.0019,
		label: 'GRACE-FO 1',
		parents: [
			[580290547.185, 'earth']
		],
		trail: {
			length: 5575.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_grace_fo/graceFO.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_grace_fo1'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sc_grace_fo2',
				axis: Pioneer.Vector3.ZAxisNeg
			},
			secondary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			}
		}, {
			type: 'coverage',
			coverage: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
			update: (entity) => {
				const microwave = /** @type {Pioneer.ConnectedSpriteComponent} */(entity.getComponent('microwave'));
				const engine = entity.getScene().getEngine();
				const newOffset = Pioneer.MathUtils.wrap(microwave.getTextureYOffset() - Pioneer.MathUtils.clamp(engine.getTimeRate(), -1, +1) * engine.getDeltaTime(), 0, 1);
				microwave.setTextureYOffset(newOffset);
				const other = entity.getScene().getEntity('sc_grace_fo2');
				const shouldBeEnabled = other !== null && other.getPosition().distance(entity.getPosition()) <= 3929;
				if (shouldBeEnabled !== microwave.isEnabled()) {
					const laser1 = /** @type {Pioneer.ConnectedSpriteComponent} */(entity.getComponent('laser1'));
					const laser2 = /** @type {Pioneer.ConnectedSpriteComponent} */(entity.getComponent('laser2'));
					microwave.setEnabled(shouldBeEnabled);
					laser1.setEnabled(shouldBeEnabled);
					laser2.setEnabled(shouldBeEnabled);
				}
			}
		}],
		postCreateFunction: entity => {
			const microwave = entity.addComponentByClass(Pioneer.ConnectedSpriteComponent, 'microwave');
			microwave.setTextureUrl('$STATIC_ASSETS_URL/sprites/sine_wave.png');
			microwave.setEntity1('sc_grace_fo1');
			microwave.setEntity1Offset(new Pioneer.Vector3(0, 0, -0.00165));
			microwave.setEntity2('sc_grace_fo2');
			microwave.setEntity2Offset(new Pioneer.Vector3(0, 0, -0.00165));
			microwave.setWidths(0.0005, 0.0005);
			microwave.setColorMultiplier(new Pioneer.Color(0.5, 1.0, 1.0));
			microwave.setTextureRepeat(true);
			microwave.setTextureStretch(4);

			const laser1 = entity.addComponentByClass(Pioneer.ConnectedSpriteComponent, 'laser1');
			laser1.setTextureUrl('$STATIC_ASSETS_URL/sprites/line.png');
			laser1.setEntity1('sc_grace_fo1');
			laser1.setEntity1Offset(new Pioneer.Vector3(+0.0003, 0.00015, -0.00165));
			laser1.setEntity2('sc_grace_fo2');
			laser1.setEntity2Offset(new Pioneer.Vector3(-0.0003, 0.00015, -0.00165));
			laser1.setWidths(0.00002, 0.00002);
			laser1.setColorMultiplier(new Pioneer.Color(1, 0.24, 0.64));
			laser1.setTextureRepeat(false);

			const laser2 = entity.addComponentByClass(Pioneer.ConnectedSpriteComponent, 'laser2');
			laser2.setTextureUrl('$STATIC_ASSETS_URL/sprites/line.png');
			laser2.setEntity1('sc_grace_fo1');
			laser2.setEntity1Offset(new Pioneer.Vector3(-0.0003, 0.00015, -0.00165));
			laser2.setEntity2('sc_grace_fo2');
			laser2.setEntity2Offset(new Pioneer.Vector3(+0.0003, 0.00015, -0.00165));
			laser2.setWidths(0.00002, 0.00002);
			laser2.setColorMultiplier(new Pioneer.Color(1, 0.24, 0.64));
			laser2.setTextureRepeat(false);
		}
	},
	sc_grace_fo2: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0015,
		extentsRadius: 0.0019,
		label: 'GRACE-FO 2',
		parents: [
			[580290547.185, 'earth']
		],
		trail: {
			length: 5575.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_grace_fo/graceFO.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_grace_fo2'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sc_grace_fo1',
				axis: Pioneer.Vector3.ZAxisNeg
			},
			secondary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			}
		}]
	},
	sc_grifex: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.000150,
		extentsRadius: 0.00034,
		label: 'GRIFEX',
		parents: [
			[476542806.1849232, 'earth']
		],
		trail: {
			length: 5706.25
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_grifex/grifex.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_grifex'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_grifex',
				axis: Pioneer.Vector3.ZAxisNeg
			}
		}]
	},
	sc_hubble_space_telescope: {
		groups: ['earth', 'spacecraft', 'telescope'],
		occlusionRadius: 0.0066,
		extentsRadius: 0.0066,
		label: 'Hubble',
		parents: [
			[-305719099.92837775, 'earth']
		],
		trail: {
			length: 5748.5
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_hubble/Hubble.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_hubble_space_telescope/earth/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_hubble_space_telescope',
				axis: Pioneer.Vector3.YAxis
			}
		}]
	},
	sc_hyspiri: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.004,
		extentsRadius: 0.012,
		label: 'HyspIRI',
		parents: [
			[347025366.1839032, 'earth']
		],
		trail: {
			length: 5733.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_hyspiri/HyspIRI.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_hyspiri'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_hyspiri',
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_ibex: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0005,
		extentsRadius: 0.001,
		label: 'IBEX',
		parents: [
			[277718464.1823969, 'earth']
		],
		trail: {
			length: 797489.16
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_ibex/iBEX.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_ibex',
			dataType: 'pos'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			}
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.YAxis,
			periodInHours: 0.00416
		}]
	},
	sc_icesat_2: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00198,
		extentsRadius: 0.0065,
		label: 'ICESat-2',
		parents: [
			[590288589.182, 'earth']
		],
		trail: {
			length: 5653.2
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_icesatii/ICESat2.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_icesat_2'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_icesat_2',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_image: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00113,
		extentsRadius: 0.006,
		label: 'IMAGE',
		// Boom is 500 meters long!  Does not appear in explorer.
		parents: [
			[7288547.186, 'earth'],
			[188163587.1835355, '']
		],
		trail: {
			length: 55541
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_image/image.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_image'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_image',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_integral: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0025,
		extentsRadius: 0.005,
		label: 'INTEGRAL',
		parents: [
			[367498866.18276465, 'earth']
		],
		trail: {
			length: 229689.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_integral'
		}]
	},
	sc_ipex: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0001,
		extentsRadius: 0.00015,
		label: 'IPEX',
		parents: [
			[439586087.183, 'earth']
		],
		trail: {
			length: 5862.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_ipex/IPEX.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_ipex'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_ipex',
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_isas: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.001,
		extentsRadius: 0.0015,
		label: 'ISAS',
		parents: [
			[-186102945.06492478, 'earth'],
			[-183424845.06434903, '']
		],
		trail: {
			length: 92680.5
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_isas/isas.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_isas',
			dataType: 'pos'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_isas',
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_iss: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.008,
		extentsRadius: 0.072,
		label: 'ISS',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 5573.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_iss/ISS_stationary.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_iss'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_iss',
				axis: Pioneer.Vector3.ZAxis
			}
		}],
		postCreateFunction: (entity) => {
			const vectorForAlphaGimbal = new Pioneer.Vector3(0, 0.5, 0.5 * Math.sqrt(3.0));
			const vectorForBetaGimbalAxis = new Pioneer.Vector3(0, 0.5 * Math.sqrt(3.0), -0.5);
			const vectorForBetaGimbalPoint = new Pioneer.Vector3(0, 0.5, 0.5 * Math.sqrt(3.0));

			// Align the right solar panel alpha gimbal to the sun.
			let align = entity.addControllerByClass(Pioneer.AlignController);
			align.setJoint('20_P4_Truss');
			align.setPrimaryAxis(Pioneer.Vector3.XAxis);
			align.setSecondaryAxis(vectorForAlphaGimbal);
			align.setSecondaryAlignType('point');
			align.setSecondaryTargetEntity('sun');

			// Align the left solar panel alpha gimbal to the sun.
			align = entity.addControllerByClass(Pioneer.AlignController);
			align.setJoint('23_S4_Truss');
			align.setPrimaryAxis(Pioneer.Vector3.XAxis);
			align.setSecondaryAxis(vectorForAlphaGimbal);
			align.setSecondaryAlignType('point');
			align.setSecondaryTargetEntity('sun');

			// Align the right solar panel alpha gimbal to the sun.
			align = entity.addControllerByClass(Pioneer.AlignController);
			align.setJoint('20_P4_Truss_01');
			align.setPrimaryAxis(vectorForBetaGimbalAxis);
			align.setSecondaryAxis(vectorForBetaGimbalPoint);
			align.setSecondaryAlignType('point');
			align.setSecondaryTargetEntity('sun');

			// Align the right solar panel alpha gimbal to the sun.
			align = entity.addControllerByClass(Pioneer.AlignController);
			align.setJoint('20_P4_Truss_02');
			align.setPrimaryAxis(vectorForBetaGimbalAxis);
			align.setSecondaryAxis(vectorForBetaGimbalPoint);
			align.setSecondaryAlignType('point');
			align.setSecondaryTargetEntity('sun');

			// Align the right solar panel alpha gimbal to the sun.
			align = entity.addControllerByClass(Pioneer.AlignController);
			align.setJoint('23_S4_Truss_01');
			align.setPrimaryAxis(vectorForBetaGimbalAxis);
			align.setSecondaryAxis(vectorForBetaGimbalPoint);
			align.setSecondaryAlignType('point');
			align.setSecondaryTargetEntity('sun');

			// Align the right solar panel alpha gimbal to the sun.
			align = entity.addControllerByClass(Pioneer.AlignController);
			align.setJoint('23_S4_Truss_02');
			align.setPrimaryAxis(vectorForBetaGimbalAxis);
			align.setSecondaryAxis(vectorForBetaGimbalPoint);
			align.setSecondaryAlignType('point');
			align.setSecondaryTargetEntity('sun');

			// Align the right solar panel alpha gimbal to the sun.
			align = entity.addControllerByClass(Pioneer.AlignController);
			align.setJoint('32_S6_Truss_01');
			align.setPrimaryAxis(vectorForBetaGimbalAxis);
			align.setSecondaryAxis(vectorForBetaGimbalPoint);
			align.setSecondaryAlignType('point');
			align.setSecondaryTargetEntity('sun');

			// Align the right solar panel alpha gimbal to the sun.
			align = entity.addControllerByClass(Pioneer.AlignController);
			align.setJoint('32_S6_Truss_02');
			align.setPrimaryAxis(vectorForBetaGimbalAxis);
			align.setSecondaryAxis(vectorForBetaGimbalPoint);
			align.setSecondaryAlignType('point');
			align.setSecondaryTargetEntity('sun');

			// Align the right solar panel alpha gimbal to the sun.
			align = entity.addControllerByClass(Pioneer.AlignController);
			align.setJoint('08_P6_Truss_01');
			align.setPrimaryAxis(vectorForBetaGimbalAxis);
			align.setSecondaryAxis(vectorForBetaGimbalPoint);
			align.setSecondaryAlignType('point');
			align.setSecondaryTargetEntity('sun');

			// Align the right solar panel alpha gimbal to the sun.
			align = entity.addControllerByClass(Pioneer.AlignController);
			align.setJoint('08_P6_Truss_02');
			align.setPrimaryAxis(vectorForBetaGimbalAxis);
			align.setSecondaryAxis(vectorForBetaGimbalPoint);
			align.setSecondaryAlignType('point');
			align.setSecondaryTargetEntity('sun');

			// Make RapidScat antenna spin.
			const rapidScatSpin = entity.addControllerByClass(Pioneer.SpinController);
			rapidScatSpin.setJoint('RapidScat_dish');
			rapidScatSpin.setRate(Pioneer.MathUtils.degToRad(108));
			rapidScatSpin.setAxis(Pioneer.Vector3.ZAxis, true);
			rapidScatSpin.setClampedToRealTime(true);
			rapidScatSpin.setCoverage(new Pioneer.Interval(465418867.184, 524890952.183)); // Lost power at 2016-08-19T15:01:24.

			// Show ECOSTRESS only when mounted.
			EntityUtils.showSubObjectDuringInterval(entity, 'Ecostress', 584127348, Number.POSITIVE_INFINITY);

			// Show EMIT only when mounted.
			EntityUtils.showSubObjectDuringInterval(entity, 'EMIT', 711345507, Number.POSITIVE_INFINITY);

			// Show OCO-3 only when mounted.
			EntityUtils.showSubObjectDuringInterval(entity, 'OCO3', 610718469, Number.POSITIVE_INFINITY);

			// Show RapidScat only when mounted.
			EntityUtils.showSubObjectDuringInterval(entity, '45_RapidScat', 465418867.184, 575406069.184);
		}
	},
	sc_iss_ecostress: {
		groups: ['instrument', 'sc_iss'],
		occlusionRadius: 0.001,
		extentsRadius: 0.0012,
		label: 'ECOSTRESS',
		parents: [
			[584127348, 'sc_iss']
		],
		controllers: [{
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity,
			position: new Pioneer.Vector3(0.0200195, -0.00202631, 0.0161099),
			coverage: [584127348, Number.POSITIVE_INFINITY]
		}],
		postCreateFunction: (entity) => {
			const rotateByEntityOrientation = entity.addControllerByClass(Pioneer.RotateByEntityOrientationController);
			rotateByEntityOrientation.setCoverage(new Pioneer.Interval(584127348, Number.POSITIVE_INFINITY));
		}
	},
	sc_iss_emit: {
		groups: ['instrument', 'sc_iss'],
		occlusionRadius: 0.0008,
		extentsRadius: 0.0013,
		label: 'EMIT',
		parents: [
			[711345507, 'sc_iss']
		],
		controllers: [{
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity,
			position: new Pioneer.Vector3(0.024, -0.00225, 0.00325),
			coverage: [711345507, Number.POSITIVE_INFINITY]
		}],
		postCreateFunction: (entity) => {
			const rotateByEntityOrientation = entity.addControllerByClass(Pioneer.RotateByEntityOrientationController);
			rotateByEntityOrientation.setCoverage(new Pioneer.Interval(711345507, Number.POSITIVE_INFINITY));
		}
	},
	sc_iss_oco_3: {
		groups: ['instrument', 'sc_iss'],
		occlusionRadius: 0.0008,
		extentsRadius: 0.0012,
		label: 'OCO-3',
		parents: [
			[610718469, 'sc_iss']
		],
		controllers: [{
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity,
			position: new Pioneer.Vector3(0.0156458, -0.00195982, 0.0200232),
			coverage: [610718469, Number.POSITIVE_INFINITY]
		}],
		postCreateFunction: (entity) => {
			const rotateByEntityOrientation = entity.addControllerByClass(Pioneer.RotateByEntityOrientationController);
			rotateByEntityOrientation.setCoverage(new Pioneer.Interval(610718469, Number.POSITIVE_INFINITY));
		}
	},
	sc_iss_rapidscat: {
		groups: ['instrument', 'sc_iss'],
		occlusionRadius: 0.0008,
		extentsRadius: 0.0002,
		label: 'RapidScat',
		parents: [
			[465418867.184, 'sc_iss'],
			[575406069.184, '']
		],
		controllers: [{
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity,
			position: new Pioneer.Vector3(-0.0100725, -0.00133401, 0.0162145),
			coverage: [465418867.184, 575406069.184]
		}],
		postCreateFunction: (entity) => {
			const rotateByEntityOrientation = entity.addControllerByClass(Pioneer.RotateByEntityOrientationController);
			rotateByEntityOrientation.setCoverage(new Pioneer.Interval(465418867.184, 575406069.184));
		}
	},
	sc_ixpe: {
		groups: ['earth', 'spacecraft', 'telescope'],
		occlusionRadius: 0.0055,
		extentsRadius: 0.0026,
		label: 'IXPE',
		parents: [
			[692324766.6007013, 'earth']
		],
		trail: {
			length: 5400
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_ixpe/ixpe.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_ixpe'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	sc_jason_1: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0014,
		extentsRadius: 0.004,
		label: 'Jason-1',
		parents: [
			[352800006.1854904, 'earth'],
			[458074266.1839004, '']
		],
		trail: {
			length: 6744.5
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_ostm/ostm.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_jason_1',
			dataType: 'pos'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_jason_1',
				axis: Pioneer.Vector3.ZAxisNeg
			}
		}]
	},
	sc_jason_2: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0014,
		extentsRadius: 0.004,
		label: 'OSTM/Jason-2',
		parents: [
			[344589947.18311954, 'earth'],
			[623851269.182, '']
		],
		trail: {
			length: 6744.25
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_ostm/ostm.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_jason_2'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_jason_2',
				axis: Pioneer.Vector3.ZAxisNeg
			}
		}]
	},
	sc_jason_3: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0014,
		extentsRadius: 0.004,
		label: 'Jason-3',
		parents: [
			[506497796.18443054, 'earth']
		],
		trail: {
			length: 6721.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_ostm/ostm.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_jason_3'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_jason_3',
				axis: Pioneer.Vector3.ZAxisNeg
			}
		}]
	},
	sc_jwst: {
		groups: ['earth', 'spacecraft', 'telescope'],
		radius: 0.010,
		label: 'James Webb Space Telescope',
		parents: [
			[693708549.184, 'earth']
		],
		trail: {
			length: 31536000
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_jwst/webb_deployed.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_jwst/earth/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'align',
				target: 'sun',
				axis: Pioneer.Vector3.ZAxis,
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}, {
			type: 'coverage',
			coverage: [Number.NEGATIVE_INFINITY, 694937889.184],
			enter: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				model.setUrl('$STATIC_ASSETS_URL/models/sc_jwst/webb_stowed.gltf');
			},
			exit: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				model.setUrl('$STATIC_ASSETS_URL/models/sc_jwst/webb_deployed.gltf');
			}
		}]
	},
	sc_landsat_5: {
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'Landsat 5',
		parents: [
			[352800006.1854904, 'earth'],
			[473889066.18410677, '']
		],
		trail: {
			length: 5933.33
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_landsat_5'
		}]
	},
	sc_landsat_7: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.004,
		extentsRadius: 0.012,
		label: 'Landsat 7',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 5933.33
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_landsat_7/LandSat7.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_landsat_7'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_landsat_7',
				axis: Pioneer.Vector3.ZAxisNeg
			}
		}]
	},
	sc_landsat_8: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.004,
		extentsRadius: 0.0095,
		label: 'Landsat 8',
		parents: [
			[413877787.185, 'earth']
		],
		trail: {
			length: 5933.33
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_landsat_8/LandSat8.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_landsat_8'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_landsat_8',
				axis: Pioneer.Vector3.YAxis
			}
		}]
	},
	sc_landsat_9: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.004,
		extentsRadius: 0.015,
		label: 'Landsat 9',
		parents: [
			[686043940.723348, 'earth']
		],
		trail: {
			length: 5933.33
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_landsat_9/Landsat9.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_landsat_9'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_landsat_9',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_list: {
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'LIST',
		parents: [
			[347025366.1839032, 'earth']
		],
		trail: {
			length: 5933.33
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_list'
		}]
	},
	sc_m_cubed: {
		groups: ['earth', 'spacecraft'],
		radius: 0.0001,
		label: 'M-Cubed',
		parents: [
			[373032066.182, 'earth']
		],
		trail: {
			length: 5768.333333333333
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_m_cubed/mCubed.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_m_cubed'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_m_cubed',
				axis: Pioneer.Vector3.XAxisNeg
			}
		}]
	},
	sc_mcubed_2: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0001,
		extentsRadius: 0.0002,
		label: 'MCubed-2',
		parents: [
			[439560067.183, 'earth']
		],
		trail: {
			length: 5861.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_mcubed_2/M-Cubed2.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_mcubed_2'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.XAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_mcubed_2',
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_mms_1: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0017,
		extentsRadius: 0.013,
		label: 'MMS 1',
		parents: [
			[479519767.1855569, 'earth']
		],
		trail: {
			length: 85994.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_mms/MMS.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_mms_1'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.YAxisNeg,
			periodInHours: 0.00556
		}]
	},
	sc_mms_2: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0017,
		extentsRadius: 0.013,
		label: 'MMS 2',
		parents: [
			[479519767.1855569, 'earth']
		],
		trail: {
			length: 85962.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_mms/MMS.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_mms_2'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.YAxisNeg,
			periodInHours: 0.00556
		}]
	},
	sc_mms_3: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0017,
		extentsRadius: 0.013,
		label: 'MMS 3',
		parents: [
			[479519767.1855569, 'earth']
		],
		trail: {
			length: 86347.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_mms/MMS.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_mms_3'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.YAxisNeg,
			periodInHours: 0.00556
		}]
	},
	sc_mms_4: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0017,
		extentsRadius: 0.013,
		label: 'MMS 4',
		parents: [
			[479519767.1855569, 'earth']
		],
		trail: {
			length: 86024.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_mms/MMS.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_mms_4'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.YAxisNeg,
			periodInHours: 0.00556
		}]
	},
	sc_nisar: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.006,
		extentsRadius: 0.017,
		label: 'NISAR',
		parents: [
			[757339269, 'earth']
		],
		trail: {
			length: 5573.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_nisar/Nisar.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'orbitalElements',
			eccentricity: 0,
			semiMajorAxis: 7118,
			meanAngularMotion: 0.0011382582,
			meanAnomalyAtEpoch: 0,
			inclination: Math.PI / 180 * 98.5,
			longitudeOfAscendingNode: 0,
			argumentOfPeriapsis: 0,
			coverage: [757339269, Number.POSITIVE_INFINITY]
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_nisar',
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_noaa_14: {
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'NOAA 14',
		parents: [
			[344589947.18311954, 'earth'],
			[661006268.1833516, '']
		],
		trail: {
			length: 5933.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_noaa_14'
		}]
	},
	sc_noaa_15: {
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'NOAA 15',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 5933.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_noaa_15'
		}]
	},
	sc_noaa_16: {
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'NOAA 16',
		parents: [
			[344589947.18311954, 'earth'],
			[661006268.1833516, '']
		],
		trail: {
			length: 5933.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_noaa_16'
		}]
	},
	sc_noaa_17: {
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'NOAA 17',
		parents: [
			[344589947.18311954, 'earth'],
			[661006268.1833516, '']
		],
		trail: {
			length: 5933.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_noaa_17'
		}]
	},
	sc_noaa_18: {
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'NOAA 18',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 5933.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_noaa_18'
		}]
	},
	sc_noaa_19: {
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'NOAA 19',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 5933.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_noaa_19'
		}]
	},
	sc_nustar: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.006,
		extentsRadius: 0.006,
		label: 'NuSTAR',
		parents: [
			[392875303.185, 'earth']
		],
		trail: {
			length: 5798.66
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_nustar/nustar.gltf',
			shadowEntities: ['earth']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_nustar'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.ZAxisNeg
			},
			secondary: {
				type: 'align',
				axis: Pioneer.Vector3.XAxis,
				target: 'sun',
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_oco_2: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0021,
		extentsRadius: 0.004,
		label: 'OCO-2',
		parents: [
			[457567050.184, 'earth']
		],
		trail: {
			length: 5933.333333333333
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_oco_2/oco2.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_oco_2'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxisNeg
			},
			secondary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			}
		}]
	},
	sc_pace: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.002,
		extentsRadius: 0.004,
		label: 'PACE',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_pace/pace.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_pace'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_pace',
				axis: Pioneer.Vector3.XAxisNeg
			}
		}]
	},
	sc_path: {
		// mission cancelled
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'PATH',
		parents: [
			[347025366.1839032, 'earth']
		],
		trail: {
			length: 5933.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_path'
		}]
	},
	sc_polar: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0012,
		extentsRadius: 0.005,
		label: 'Polar',
		parents: [
			[-121608237.81469652, 'earth'],
			[262612565.1855274, '']
		],
		trail: {
			length: 66571.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_polar/polar.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_polar',
			dataType: 'pos'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_polar',
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_prefire_1: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.000127431,
		extentsRadius: 0.00049617,
		label: 'PREFIRE-1',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_prefire/prefire.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_prefire_1'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_prefire_1',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_prefire_2: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.000127431,
		extentsRadius: 0.00049617,
		label: 'PREFIRE-2',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_prefire/prefire.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_prefire_2'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_prefire_2',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_quikscat: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0016,
		extentsRadius: 0.0018,
		label: 'QuikSCAT',
		parents: [
			[344589947.18311954, 'earth'],
			[591710469.182, '']
		],
		trail: {
			length: 6061.33
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_quikscat/QuikSCAT.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_quikscat'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_quikscat',
				axis: Pioneer.Vector3.XAxisNeg
			}
		}]
	},
	sc_race: {
		groups: ['earth', 'spacecraft'],
		radius: 0.00015,
		label: 'RACE',
		parents: [
			[467726467.182, 'earth'],
			[467812867.182, '']
		],
		trail: {
			length: 5933.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_race'
		}]
	},
	sc_raincube: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00035,
		extentsRadius: 0.0005,
		label: 'RainCube',
		parents: [
			[608189026.1856545, 'earth'],
			[662040069.184, '']
		],
		trail: {
			length: 5743.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_raincube/Raincube.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_raincube'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_raincube',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_rax_2: {
		groups: ['earth', 'spacecraft'],
		radius: 0.0002,
		label: 'RAX-2',
		parents: [
			[373067348.182, 'earth'],
			[418824067.186, '']
		],
		trail: {
			length: 5743.0
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_rax_2'
		}]
	},
	sc_rbsp_a: {
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'Van Allen Probe A',
		parents: [
			[399585967.183, 'earth'],
			[624628869.182, '']
		],
		trail: {
			length: 32214.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_rbsp/rbsp.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_rbsp_a'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_rbsp_a',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_rbsp_b: {
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'Van Allen Probe B',
		parents: [
			[399585967.183, 'earth'],
			[616766469.184, '']
		],
		trail: {
			length: 32574.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_rbsp/rbsp.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_rbsp_b'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_rbsp_b',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_sac_d: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.003,
		extentsRadius: 0.0045,
		label: 'Aquarius',
		parents: [
			[360987666.185, 'earth'],
			[486907267.185, '']
		],
		trail: {
			length: 5884.25
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_sac_d/aquarius.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_sac_d'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.XAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_sac_d',
				axis: Pioneer.Vector3.YAxis
			}
		}]
	},
	sc_sclp: {
		groups: ['earth', 'spacecraft'],
		radius: 0.004,
		label: 'SCLP',
		parents: [
			[347025366.1839032, 'earth']
		],
		trail: {
			length: 5933
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_sclp'
		}]
	},
	sc_sdo: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0022,
		extentsRadius: 0.00315,
		label: 'SDO',
		parents: [
			[505918808.18474686, 'earth']
		],
		trail: {
			length: 86137.66
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_sdo/sdo.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_sdo'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_sdo',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_sentinel_6: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0026,
		extentsRadius: 0.0031,
		label: 'Sentinel-6 Michael Freilich',
		parents: [
			[659322339.7879795, 'earth']
		],
		trail: {
			length: 5933.5
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_sentinel_6/Sentinel6.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_sentinel_6'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_sentinel_6',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_smap: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.003,
		extentsRadius: 0.009,
		label: 'SMAP',
		parents: [
			[476542806.1849232, 'earth']
		],
		trail: {
			length: 5906.8
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_smap/SMAP.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_smap'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_smap',
				axis: Pioneer.Vector3.XAxis
			}
		}],
		postCreateFunction: (entity) => {
			const spin = entity.addControllerByClass(Pioneer.SpinController);
			spin.setJoint('arm_etc');
			spin.setRate(Pioneer.MathUtils.degToRad(87.6));
			spin.setAxis(Pioneer.Vector3.YAxis, true);
			spin.setClampedToRealTime(true);
		}
	},
	sc_soho: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00215,
		extentsRadius: 0.00425,
		label: 'SOHO',
		parents: [
			[265550465.18489534, 'earth']
		],
		trail: {
			length: 60 * 60 * 24 * 365
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_soho/soho.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_soho',
			dataType: 'pos'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_soho',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_sorce: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.001,
		extentsRadius: 0.0022,
		label: 'SORCE',
		parents: [
			[344589947.18311954, 'earth'],
			[635860869.185, '']
		],
		trail: {
			length: 5812.5
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_sorce/sorce.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_sorce'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_sorce',
				axis: Pioneer.Vector3.XAxisNeg
			}
		}]
	},
	sc_starling_1: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00023,
		extentsRadius: 0.00076,
		label: 'Starling-1',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_starling/starling.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_starling_1'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_starling_1',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_starling_2: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00023,
		extentsRadius: 0.00076,
		label: 'Starling-2',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_starling/starling.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_starling_2'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_starling_2',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_starling_3: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00023,
		extentsRadius: 0.00076,
		label: 'Starling-3',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_starling/starling.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_starling_3'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_starling_3',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_starling_4: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00023,
		extentsRadius: 0.00076,
		label: 'Starling-4',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_starling/starling.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_starling_4'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_starling_4',
				axis: Pioneer.Vector3.XAxis
			}
		}]
	},
	sc_sunrise_faye: {
		groups: ['earth', 'spacecraft'],
		radius: 0.0005,
		label: 'SunRISE Faye',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_sunrise/sunrise.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_sunrise_faye'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				target: 'sun',
				targetAxis: Pioneer.Vector3.ZAxis,
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_sunrise_jet: {
		groups: ['earth', 'spacecraft'],
		radius: 0.0005,
		label: 'SunRISE Jet',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_sunrise/sunrise.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_sunrise_jet'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				target: 'sun',
				targetAxis: Pioneer.Vector3.ZAxis,
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_sunrise_ein: {
		groups: ['earth', 'spacecraft'],
		radius: 0.0005,
		label: 'SunRISE Ein',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_sunrise/sunrise.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_sunrise_ein'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				target: 'sun',
				targetAxis: Pioneer.Vector3.ZAxis,
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_sunrise_edward: {
		groups: ['earth', 'spacecraft'],
		radius: 0.0005,
		label: 'SunRISE Edward',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_sunrise/sunrise.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_sunrise_edward'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				target: 'sun',
				targetAxis: Pioneer.Vector3.ZAxis,
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_sunrise_spike: {
		groups: ['earth', 'spacecraft'],
		radius: 0.0005,
		label: 'SunRISE Spike',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_sunrise/sunrise.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_sunrise_spike'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				target: 'sun',
				targetAxis: Pioneer.Vector3.ZAxis,
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_sunrise_bebop: {
		groups: ['earth', 'spacecraft'],
		radius: 0.0005,
		label: 'SunRISE Bebop',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_sunrise/sunrise.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_sunrise_bebop'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				target: 'sun',
				targetAxis: Pioneer.Vector3.ZAxis,
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_suomi_npp: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0022,
		extentsRadius: 0.008,
		label: 'Suomi NPP',
		parents: [
			[373067348.01, 'earth']
		],
		trail: {
			length: 5798.66
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_npp/NPP.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_suomi_npp'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.XAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_suomi_npp',
				axis: Pioneer.Vector3.YAxis
			}
		}]
	},
	sc_swift: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0028,
		extentsRadius: 0.0028,
		label: 'Swift',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 5933
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_swift'
		}]
	},
	sc_swot: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.005,
		extentsRadius: 0.009,
		label: 'SWOT',
		parents: [
			[347025366.1839032, 'earth']
		],
		trail: {
			length: 5933
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_swot_v2/swot.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_swot'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_swot',
				axis: Pioneer.Vector3.ZAxisNeg
			}
		}]
	},
	sc_tdrs_3: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0085,
		extentsRadius: 0.0085,
		label: 'TDRS 3',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 86137.33
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_tdrs/tdrs.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_tdrs_3'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				axis: Pioneer.Vector3.ZAxis,
				target: 'earth',
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_tdrs_5: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0085,
		extentsRadius: 0.0085,
		label: 'TDRS 5',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 86137.66
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_tdrs/tdrs.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_tdrs_5'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				axis: Pioneer.Vector3.ZAxis,
				target: 'earth',
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_tdrs_6: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0085,
		extentsRadius: 0.0085,
		label: 'TDRS 6',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 86137.66
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_tdrs/tdrs.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_tdrs_6'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				axis: Pioneer.Vector3.ZAxis,
				target: 'earth',
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_tdrs_7: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0085,
		extentsRadius: 0.0085,
		label: 'TDRS 7',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 86151.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_tdrs/tdrs.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_tdrs_7'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				axis: Pioneer.Vector3.ZAxis,
				target: 'earth',
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_tdrs_8: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0085,
		extentsRadius: 0.0085,
		label: 'TDRS 8',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 86151.2
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_tdrs/tdrs.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_tdrs_8'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				axis: Pioneer.Vector3.ZAxis,
				target: 'earth',
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_tdrs_9: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0085,
		extentsRadius: 0.0085,
		label: 'TDRS 9',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 86151.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_tdrs/tdrs.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_tdrs_9'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				axis: Pioneer.Vector3.ZAxis,
				target: 'earth',
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_tdrs_10: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0085,
		extentsRadius: 0.0085,
		label: 'TDRS 10',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 86151.2
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_tdrs/tdrs.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_tdrs_10'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				axis: Pioneer.Vector3.ZAxis,
				target: 'earth',
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_tdrs_11: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0085,
		extentsRadius: 0.0085,
		label: 'TDRS 11',
		parents: [
			[412868947.185, 'earth']
		],
		trail: {
			length: 86151.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_tdrs/tdrs.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_tdrs_11'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				axis: Pioneer.Vector3.ZAxis,
				target: 'earth',
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_tdrs_12: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0085,
		extentsRadius: 0.0085,
		label: 'TDRS 12',
		parents: [
			[443808067.185, 'earth']
		],
		trail: {
			length: 86151.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_tdrs/tdrs.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_tdrs_12'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				axis: Pioneer.Vector3.ZAxis,
				target: 'earth',
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_tdrs_13: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0085,
		extentsRadius: 0.0085,
		label: 'TDRS 13',
		parents: [
			[556338669.183, 'earth']
		],
		trail: {
			length: 86151.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_tdrs/tdrs.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_tdrs_13'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				axis: Pioneer.Vector3.ZAxis,
				target: 'earth',
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_tempo: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.003021525,
		extentsRadius: 0.00975015,
		label: 'TEMPO',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_tempo/tempo.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_tempo'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_tempo',
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_terra: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0034,
		extentsRadius: 0.012,
		label: 'Terra',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 5933.33
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_terra/Terra.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_terra'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_terra',
				axis: Pioneer.Vector3.XAxisNeg
			}
		}]
	},
	sc_tess: {
		groups: ['earth', 'spacecraft', 'telescope'],
		occlusionRadius: 0.002,
		extentsRadius: 0.003,
		label: 'TESS',
		parents: [
			[577366932.3626, 'earth']
		],
		trail: {
			length: 591840
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_tess/TESS.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_tess/earth/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	sc_themis_a: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.000400,
		extentsRadius: 0.00170,
		label: 'THEMIS A',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 98428.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_themis/themis.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_themis_a'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			}
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.YAxis,
			periodInHours: 0.0008333
		}]
	},
	sc_themis_d: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.000400,
		extentsRadius: 0.00170,
		label: 'THEMIS D',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 114807.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_themis/themis.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_themis_d'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			}
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.YAxis,
			periodInHours: 0.0008333
		}]
	},
	sc_themis_e: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.000400,
		extentsRadius: 0.00170,
		label: 'THEMIS E',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 105907.5
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_themis/themis.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_themis_e'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			}
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.YAxis,
			periodInHours: 0.0008333
		}]
	},
	sc_timed: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0015,
		extentsRadius: 0.005,
		label: 'TIMED',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 5933
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_timed'
		}]
	},
	sc_trmm: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.002550,
		extentsRadius: 0.0073,
		label: 'TRMM',
		parents: [
			[-66052736.817, 'earth'],
			[482328067.186, '']
		],
		trail: {
			length: 5545.6
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_trmm/TRMM.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'orbitalElements',
			eccentricity: 0.00111201719,
			semiMajorAxis: 6744.5,
			meanAngularMotion: 0.0011382582,
			meanAnomalyAtEpoch: 0,
			inclination: Math.PI / 180 * 30,
			longitudeOfAscendingNode: 0,
			argumentOfPeriapsis: 0,
			coverage: [-66052736.817, 482328067.186]
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_trmm',
				axis: Pioneer.Vector3.XAxisNeg
			}
		}]
	},
	sc_tropics_01: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00018,
		extentsRadius: 0.00024,
		label: 'TROPICS-01',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_tropics/tropics.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_tropics_01'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_tropics_01',
				axis: Pioneer.Vector3.ZAxis
			}
		}],
		postCreateFunction: (entity) => {
			// Rotate the Microwave Radiometer.
			const spin = entity.addControllerByClass(Pioneer.SpinController);
			spin.setJoint('microwave_radiometer');
			spin.setRate(Math.PI);
			spin.setAxis(Pioneer.Vector3.ZAxis, true);
			spin.setClampedToRealTime(true);
			// Align the solar panel to the sun.
			const align = entity.addControllerByClass(Pioneer.AlignController);
			align.setJoint('solar_rotate');
			align.setPrimaryAxis(Pioneer.Vector3.YAxis);
			align.setSecondaryAxis(Pioneer.Vector3.XAxis);
			align.setSecondaryAlignType('point');
			align.setSecondaryTargetEntity('sun');
		}
	},
	sc_tropics_03: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00018,
		extentsRadius: 0.00024,
		label: 'TROPICS-03',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_tropics/tropics.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_tropics_03'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_tropics_03',
				axis: Pioneer.Vector3.ZAxis
			}
		}],
		postCreateFunction: (entity) => {
			// Rotate the Microwave Radiometer.
			const spin = entity.addControllerByClass(Pioneer.SpinController);
			spin.setJoint('microwave_radiometer');
			spin.setRate(Math.PI);
			spin.setAxis(Pioneer.Vector3.ZAxis, true);
			spin.setClampedToRealTime(true);
			// Align the solar panel to the sun.
			const align = entity.addControllerByClass(Pioneer.AlignController);
			align.setJoint('solar_rotate');
			align.setPrimaryAxis(Pioneer.Vector3.YAxis);
			align.setSecondaryAxis(Pioneer.Vector3.XAxis);
			align.setSecondaryAlignType('point');
			align.setSecondaryTargetEntity('sun');
		}
	},
	sc_tropics_05: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00018,
		extentsRadius: 0.00024,
		label: 'TROPICS-05',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_tropics/tropics.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_tropics_05'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_tropics_05',
				axis: Pioneer.Vector3.ZAxis
			}
		}],
		postCreateFunction: (entity) => {
			// Rotate the Microwave Radiometer.
			const spin = entity.addControllerByClass(Pioneer.SpinController);
			spin.setJoint('microwave_radiometer');
			spin.setRate(Math.PI);
			spin.setAxis(Pioneer.Vector3.ZAxis, true);
			spin.setClampedToRealTime(true);
			// Align the solar panel to the sun.
			const align = entity.addControllerByClass(Pioneer.AlignController);
			align.setJoint('solar_rotate');
			align.setPrimaryAxis(Pioneer.Vector3.YAxis);
			align.setSecondaryAxis(Pioneer.Vector3.XAxis);
			align.setSecondaryAlignType('point');
			align.setSecondaryTargetEntity('sun');
		}
	},
	sc_tropics_06: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00018,
		extentsRadius: 0.00024,
		label: 'TROPICS-06',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_tropics/tropics.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_tropics_06'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_tropics_06',
				axis: Pioneer.Vector3.ZAxis
			}
		}],
		postCreateFunction: (entity) => {
			// Rotate the Microwave Radiometer.
			const spin = entity.addControllerByClass(Pioneer.SpinController);
			spin.setJoint('microwave_radiometer');
			spin.setRate(Math.PI);
			spin.setAxis(Pioneer.Vector3.ZAxis, true);
			spin.setClampedToRealTime(true);
			// Align the solar panel to the sun.
			const align = entity.addControllerByClass(Pioneer.AlignController);
			align.setJoint('solar_rotate');
			align.setPrimaryAxis(Pioneer.Vector3.YAxis);
			align.setSecondaryAxis(Pioneer.Vector3.XAxis);
			align.setSecondaryAlignType('point');
			align.setSecondaryTargetEntity('sun');
		}
	},
	sc_tropics_07: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00018,
		extentsRadius: 0.00024,
		label: 'TROPICS-07',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_tropics/tropics.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_tropics_07'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_tropics_07',
				axis: Pioneer.Vector3.ZAxis
			}
		}],
		postCreateFunction: (entity) => {
			// Rotate the Microwave Radiometer.
			const spin = entity.addControllerByClass(Pioneer.SpinController);
			spin.setJoint('microwave_radiometer');
			spin.setRate(Math.PI);
			spin.setAxis(Pioneer.Vector3.ZAxis, true);
			spin.setClampedToRealTime(true);
			// Align the solar panel to the sun.
			const align = entity.addControllerByClass(Pioneer.AlignController);
			align.setJoint('solar_rotate');
			align.setPrimaryAxis(Pioneer.Vector3.YAxis);
			align.setSecondaryAxis(Pioneer.Vector3.XAxis);
			align.setSecondaryAlignType('point');
			align.setSecondaryTargetEntity('sun');
		}
	},
	sc_uars: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00535,
		extentsRadius: 0.00535,
		label: 'UARS',
		parents: [
			[352800006.1854904, 'earth'],
			[370106644.184, '']
		],
		trail: {
			length: 5490.0
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_uars',
			dataType: 'pos'
		}]
	},
	sc_wind: {
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.00120,
		extentsRadius: 0.00750,
		label: 'WIND',
		parents: [
			[543931270, 'earth']
		],
		trail: {
			length: 16485927.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_wind/wind.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_wind/earth/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'velocity',
				target: 'sc_wind',
				axis: Pioneer.Vector3.XAxisNeg
			}
		}]
	},
	sc_wise: {
		// no model at all, 2D still in explorer
		groups: ['earth', 'spacecraft'],
		occlusionRadius: 0.0014200,
		extentsRadius: 0.001420,
		label: 'WISE',
		parents: [
			[344589947.18311954, 'earth']
		],
		trail: {
			length: 5715.4
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_wise'
		}]
	}
});
