import { useCallback, memo, useRef } from 'react';
import { useSnapshot } from 'valtio';

import { eventsStore, videoStore, spacecraftStore, poiStore, gmapStore, stellarStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';

import '../../www/assets/css/back_button.css';
import BackButtonSVG from '../../www/assets/images/arrow-back-1-1.svg';
import { useParams, useLocation } from 'react-router-dom';
import { getSpacecraftInstrumentData } from '../helpers/tools';


const BackButton = () => {
	const { eventYearParam } = useParams();
	const { currentEvent } = useSnapshot(eventsStore.state);
	const { currentVideo } = useSnapshot(videoStore.state);
	const { spacecraftId } = useSnapshot(spacecraftStore.state);
	const { currentPoi } = useSnapshot(poiStore.state);
	const { currentGmap } = useSnapshot(gmapStore.state);
	const { currentStellar } = useSnapshot(stellarStore.state);

	// Determine if this is the first location in the history.
	const { state } = useLocation();
	const { notFirstPage } = state ?? {};

	const isVisible = notFirstPage && Boolean(eventYearParam || currentEvent || spacecraftId || currentVideo || currentGmap || currentPoi || currentStellar);

	const { parentOfInstrument } = (spacecraftId && getSpacecraftInstrumentData(spacecraftId)) || {};

	/**
	 * By default, the back button will go back one in history (like the browser back button).
	 * We can put any custom exceptions here.
	 */
	const clickHandle = useCallback(() => {
		const { getManager } = globalRefs;
		const routeManager = getManager('route');
		if (parentOfInstrument) {
			// If we're on an instrument, go back to the parent.
			routeManager.navigateToSpacecraft(parentOfInstrument);
		}
		else if(Boolean(currentStellar)) {
			
		 routeManager.goHome();
			
	   }
		else {
			// Go back one in history.
			routeManager.goHome();
		}
	}, [parentOfInstrument]);


	return isVisible && (
		<button className='back-btn' onClick={clickHandle}>
			<BackButtonSVG />
 			رجوع
		</button>
	);
};


export default memo(BackButton);
