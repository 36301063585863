const DATA_READER_DATA = {
	graceMonthly: {
		units: 'سم',
		bottom: -25,
		numOffset: -1000,
		interval: 0.20408,
		variation: 50,
		scalingFactor: 1,
		formatString: '0'
	},
	airSurfaceDayTemp3Day: {
		units: 'ف°',
		bottom: -40,
		numOffset: -300,
		interval: 0.945,
		variation: 155,
		scalingFactor: 1,
		formatString: '0'
	},
	airSurfaceDayTempToday: {
		units: 'ف°',
		bottom: -40,
		numOffset: -300,
		interval: 0.945,
		variation: 155,
		scalingFactor: 1,
		formatString: '0'
	},
	airSurfaceNightTemp3Day: {
		units: 'ف°',
		bottom: -40,
		numOffset: -300,
		interval: 0.945,
		variation: 155,
		scalingFactor: 1,
		formatString: '0'
	},
	airSurfaceNightTempToday: {
		units: 'ف°',
		bottom: -40,
		numOffset: -300,
		interval: 0.945,
		variation: 155,
		scalingFactor: 1,
		formatString: '0'
	},
	dayTemp3Day: {
		units: 'ف°',
		bottom: -30,
		numOffset: -80,
		interval: 2,
		variation: 100,
		scalingFactor: 1,
		formatString: '0'
	},
	dayTempToday: {
		units: 'ف°',
		bottom: -30,
		numOffset: -80,
		interval: 2,
		variation: 100,
		scalingFactor: 1,
		formatString: '0'
	},
	nightTemp3Day: {
		units: 'ف°',
		bottom: -30,
		numOffset: -80,
		interval: 2,
		variation: 100,
		scalingFactor: 1,
		formatString: '0'
	},
	nightTempToday: {
		units: 'ف°',
		bottom: -30,
		numOffset: -80,
		interval: 2,
		variation: 100,
		scalingFactor: 1,
		formatString: '0'
	},
	mono3Day: {
		units: 'جزء في البليون',
		bottom: 0,
		numOffset: 0,
		interval: 3.2,
		variation: 160,
		scalingFactor: 1,
		formatString: '0'
	},
	monoToday: {
		units: 'جزء في البليون',
		bottom: 0,
		numOffset: 0,
		interval: 3.2,
		variation: 160,
		scalingFactor: 1,
		formatString: '0'
	},
	newVaporCol3Day: {
		units: 'ملليمتر',
		bottom: 0,
		numOffset: 0,
		interval: 5.83333,
		variation: 70,
		scalingFactor: 1,
		formatString: '0'
	},
	newVaporColToday: {
		units: 'ملليمتر',
		bottom: 0,
		numOffset: 0,
		interval: 5.83333,
		variation: 70,
		scalingFactor: 1,
		formatString: '0'
	},
	newVaporColMonthly: {
		units: 'ملليمتر',
		bottom: 0,
		numOffset: 0,
		interval: 5.83333,
		variation: 70,
		scalingFactor: 1,
		formatString: '0'
	},
	vaporUpper3Day: {
		units: 'ملليمتر',
		bottom: 0,
		numOffset: 0,
		interval: 5.83333,
		variation: 70,
		scalingFactor: 1,
		formatString: '0'
	},
	vaporUpperToday: {
		units: 'ملليمتر',
		bottom: 0,
		numOffset: 0,
		interval: 5.83333,
		variation: 70,
		scalingFactor: 1,
		formatString: '0'
	},
	smapSoilMoisture8Day: {
		units: 'سم٣/سم٣ ',
		bottom: 0.0,
		numOffset: 0,
		interval: 0.01,
		variation: 0.6,
		scalingFactor: 0.001,
		formatString: '0.00'
	},
	smapSoilMoistureMonthly: {
		units: 'سم٣/سم٣ ',
		bottom: 0.0,
		numOffset: 0,
		interval: 0.01,
		variation: 0.6,
		scalingFactor: 0.001,
		formatString: '0.00'
	},
	smapRootzoneWetnessDayToday: {
		units: 'م٣/م٣ ',
		bottom: 0.0,
		numOffset: 0,
		interval: 0.01,
		variation: 0.6,
		scalingFactor: 0.001,
		formatString: '0.00'
	},
	oco216Day: {
		units: 'جزء في المليون',
		bottom: 390,
		numOffset: 0,
		interval: 1,
		variation: 25,
		scalingFactor: 0.01,
		formatString: '0.00'
	},
	newCo2Monthly: {
		units: 'جزء في المليون',
		bottom: 360,
		numOffset: 0,
		interval: 0.3086,
		variation: 50,
		scalingFactor: 0.01,
		formatString: '0.00'
	},
	akikoMonthly: {
		units: 'ملليمتر',
		bottom: -176,
		numOffset: -1000,
		interval: 5.5,
		variation: 352,
		scalingFactor: 1,
		formatString: '0.00'
	},
	akiko10Day: {
		units: 'ملليمتر',
		bottom: -176,
		numOffset: -1000,
		interval: 5.5,
		variation: 352,
		scalingFactor: 1,
		formatString: '0.00'
	},
	smapSmSalinity8Day: { // Values for Land.  Conversion to Salinity in water happen in camera manager.
		units: 'سم٣/سم٣ ',
		bottom: 0,
		numOffset: 39,
		interval: 0.01,
		variation: 600,
		scalingFactor: 1,
		formatString: '0.00'
	},
	o37Day: {
		units: 'جزء من المليار /حجم',
		bottom: 1.6,
		numOffset: 0,
		interval: 0.01111,
		variation: 1.8,
		scalingFactor: 0.03906,
		formatString: '0.00'
	},
	o3Today: {
		units: 'جزء من المليار /حجم',
		bottom: 1.6,
		numOffset: 0,
		interval: 0.01111,
		variation: 1.8,
		scalingFactor: 0.03906,
		formatString: '0.00'
	}
};
export default DATA_READER_DATA;
