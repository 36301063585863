import { useCallback } from 'react';
import { useSnapshot } from 'valtio';

import ColorBar from './color_bar';
import Thumbnail from './thumbnail';

import { datasetStore, videoStore, spacecraftStore, appStore, eventsStore, uiStore, previewStore, poiStore,gmapStore,stellarStore} from '../managers/globalState';
import globalRefs from '../managers/globalRefs';

import ar from '../languages/ar';

const DatasetItem = ({ item, handleClick }) => {
	const { title, id, src, urlParam } = item;
	const itemTitle = Array.isArray(title) ? title.pop() : title;

	return (
		<li
			key={`key-${id}`}
			onClick={() => handleClick(urlParam)}
		>
			<Thumbnail key={src} alt={itemTitle} src={src} figure open />
		</li>
	);
};


const DetailPanelRight = () => {
	const { getManager } = globalRefs;
	const { isDetailPanelOpen } = useSnapshot(uiStore.state);
	const { isMobile } = useSnapshot(appStore.state);
	const { currentDataset, currentVS } = useSnapshot(datasetStore.state);
	const { currentEvent } = useSnapshot(eventsStore.state);
	const { currentPoi } = useSnapshot(poiStore.state);
	const { currentGmap } = useSnapshot(gmapStore.state);
	const { previewEventData } = useSnapshot(previewStore.state);
	const { spacecraftId, isSatellitesNow } = useSnapshot(spacecraftStore.state);
	const { isVideoTexture, currentVideo } = useSnapshot(videoStore.state);
	const {currentStellar}=useSnapshot(stellarStore.state)
	// Determine the thumbnail source.
	let cylThumbSrc = getManager('dataset').getCylThumbSrc();
	let altText = currentVS?.title;

	if (currentVideo && isVideoTexture) {
		//MERGE TODO: videoType === 'geoLocated'
		cylThumbSrc = `assets/images/graphics/${currentVideo.sphereMapThumbSrc}`;
		altText = currentVideo.title;
	}

	// Create handler for related dataset click.
	const onRelatedClick = useCallback(datasetParam => {
		const { getManager } = globalRefs;
		const { isMobile } = appStore.stateSnapshot;

		getManager('route').navigateToDataset({ datasetParam });
		isMobile && uiStore.setGlobalState({ isDetailPanelOpen: !isDetailPanelOpen });
	}, []);

	const showRelatedDatasetThumbs = Boolean(spacecraftId);
	const showDatasetThumb = currentDataset && !currentEvent && !isSatellitesNow && !previewEventData && !currentPoi && !currentGmap && !currentStellar;;

	// Spacecraft can have multiple related datasets.
	if (showRelatedDatasetThumbs) {
		const relatedDatasets = getManager('content').getRelatedDatasets(spacecraftId);

		return relatedDatasets.length
			? (
				<div className='right-content col-12 col-lg-5 col-xl-6'>
					
					<h3 className='section-title d-none d-lg-block'>مجموعة البيانات</h3>
					{ isMobile && (<div className='section-title'>مجموعة البيانات</div>) }
					<ul className='scrollable'>
						{ isDetailPanelOpen && relatedDatasets.map(item => <DatasetItem key={`key-${item.id}`} item={item} handleClick={onRelatedClick} />) }
					</ul>
				</div>

			)
			: null;
	}

	// We need to check for the currentDataset as it allow the component to be reactive to the currentDataset change.
	if (showDatasetThumb) {
		return (
			<div className='right-content'>
				<Thumbnail key={cylThumbSrc} alt={altText} src={cylThumbSrc} open={isDetailPanelOpen} />

				<ColorBar showDataRect={false} noContainer />
			</div>
		);
	}

	return null;
};

export default DetailPanelRight;
