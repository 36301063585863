/** @module pioneer */
import {
	Freezable,
	MathUtils,
	Pool,
	THREE
} from '../internal';

/** A 2-dimensional vector */
export class Vector2 extends Freezable {
	/**
	 * Pool for temporary variables.
	 * @returns {Pool<Vector2>}
	 */
	static get pool() {
		return _pool;
	}

	/**
	 * NaN vector
	 * @returns {Vector2}
	 */
	static get NaN() {
		return _nan;
	}

	/**
	 * Zero vector
	 * @returns {Vector2}
	 */
	static get Zero() {
		return _zero;
	}

	/**
	 * Unit x-axis vector
	 * @returns {Vector2}
	 */
	static get XAxis() {
		return _xAxis;
	}

	/**
	 * Unit y-axis vector
	 * @returns {Vector2}
	 */
	static get YAxis() {
		return _yAxis;
	}

	/**
	 * Constructor.
	 * @param {number} x
	 * @param {number} y
	 */
	constructor(x = 0, y = 0) {
		super();

		/**
		 * @type {number}
		 * @private
		 */
		this._x = x;
		/**
		 * @type {number}
		 * @private
		 */
		this._y = y;
	}

	/**
	 * Gets the x component.
	 * @returns {number}
	 */
	get x() {
		return this._x;
	}

	/**
	 * Sets the x component.
	 * @param {number} x
	 */
	set x(x) {
		this.throwIfFrozen();
		this._x = x;
	}

	/**
	 * Gets the y component.
	 * @returns {number}
	 */
	get y() {
		return this._y;
	}

	/**
	 * Sets the y component.
	 * @param {number} y
	 */
	set y(y) {
		this.throwIfFrozen();
		this._y = y;
	}

	/**
	 * Returns a nicely formed string.
	 * @override
	 * @returns {string}
	 */
	toString() {
		return '[' + this._x + ', ' + this._y + ']';
	}

	/**
	 * Returns true if this equals a.
	 * @param {Vector2} a
	 * @returns {boolean}
	 */
	equals(a) {
		return this._x === a._x && this._y === a._y;
	}

	/**
	 * Returns true if all components are zero.
	 * @returns {boolean}
	 */
	isZero() {
		return this._x === 0 && this._y === 0;
	}

	/**
	 * Returns true if any component of the vector is NaN.
	 * @returns {boolean}
	 */
	isNaN() {
		return (!(this._x <= 0) && !(this._x > 0)) || (!(this._y <= 0) && !(this._y > 0));
	}

	/**
	 * Sets this to a.
	 * @param {Vector2} a
	 */
	copy(a) {
		this.throwIfFrozen();
		this._x = a._x;
		this._y = a._y;
	}

	/**
	 * Sets this to a as a ThreeJs vector.
	 * @param {THREE.Vector2} a
	 */
	copyFromThreeJs(a) {
		this.throwIfFrozen();
		this._x = a.x;
		this._y = a.y;
	}

	/**
	 * Sets this to the parameters.
	 * @param {number} x
	 * @param {number} y
	 */
	set(x, y) {
		this.throwIfFrozen();
		this._x = x;
		this._y = y;
	}

	/**
	 * Sets this to the negative of a.
	 * @param {Vector2} a
	 */
	neg(a) {
		this.throwIfFrozen();
		this._x = -a._x;
		this._y = -a._y;
	}

	/**
	 * Sets this to a + b.
	 * @param {Vector2} a
	 * @param {Vector2} b
	 */
	add(a, b) {
		this.throwIfFrozen();
		this._x = a._x + b._x;
		this._y = a._y + b._y;
	}

	/**
	 * Sets this to a - b.
	 * @param {Vector2} a
	 * @param {Vector2} b
	 */
	sub(a, b) {
		this.throwIfFrozen();
		this._x = a._x - b._x;
		this._y = a._y - b._y;
	}

	/**
	 * Sets this to a * b, where b is a number.
	 * @param {Vector2} a
	 * @param {number} b
	 */
	mult(a, b) {
		this.throwIfFrozen();
		this._x = a._x * b;
		this._y = a._y * b;
	}

	/**
	 * Sets this to a + b * c, where c is a number.
	 * @param {Vector2} a
	 * @param {Vector2} b
	 * @param {number} c
	 */
	addMult(a, b, c) {
		this.throwIfFrozen();
		this._x = a._x + b._x * c;
		this._y = a._y + b._y * c;
	}

	/**
	 * Sets this to a / b, where b is a number.
	 * @param {Vector2} a
	 * @param {number} b
	 */
	div(a, b) {
		this.throwIfFrozen();
		this._x = a._x / b;
		this._y = a._y / b;
	}

	/**
	 * Sets this to a * b, component-wise multiplication.
	 * @param {Vector2} a
	 * @param {Vector2} b
	 */
	scale(a, b) {
		this.throwIfFrozen();
		this._x = a._x * b._x;
		this._y = a._y * b._y;
	}

	/**
	 * Sets this to a / b, component-wise division.
	 * @param {Vector2} a
	 * @param {Vector2} b
	 */
	scaleInv(a, b) {
		this.throwIfFrozen();
		this._x = a._x / b._x;
		this._y = a._y / b._y;
	}

	/**
	 * Returns the dot product of this and a.
	 * @param {Vector2} a
	 * @returns {number}
	 */
	dot(a) {
		return this._x * a._x + this._y * a._y;
	}

	/**
	 * Returns a 2D cross product, which can be used for signed angles between vectors.
	 * @param {Vector2} a
	 */
	cross(a) {
		return this._x * a._y - this._y * a._x;
	}

	/**
	 * Returns the squared length of this vector.
	 * @returns {number}
	 */
	magnitudeSqr() {
		return this._x * this._x + this._y * this._y;
	}

	/**
	 * Returns the length of this vector.
	 * @returns {number}
	 */
	magnitude() {
		return Math.sqrt(this.magnitudeSqr());
	}

	/**
	 * Sets this to a with a magnitude of 1.0.
	 * @param {Vector2} a
	 */
	normalize(a) {
		this.throwIfFrozen();
		const magnitude = a.magnitude();
		if (magnitude > 0) {
			this._x = a._x / magnitude;
			this._y = a._y / magnitude;
		}
	}

	/**
	 * Sets this to a with a given magnitude.
	 * @param {Vector2} a
	 * @param {number} magnitude
	 */
	setMagnitude(a, magnitude) {
		this.throwIfFrozen();
		this.normalize(a);
		this._x *= magnitude;
		this._y *= magnitude;
	}

	/**
	 * Returns the distance between this and a.
	 * @param {Vector2} a
	 * @returns {number}
	 */
	distance(a) {
		const x = this._x - a._x;
		const y = this._y - a._y;
		return Math.sqrt(x * x + y * y);
	}

	/**
	 * Returns the angle in radians between this and a. If this or a are zero, returns NaN.
	 * @param {Vector2} a
	 * @returns {number}
	 */
	angle(a) {
		const magnitudes = this.magnitude() * a.magnitude();
		if (magnitudes > 0) {
			return Math.acos(MathUtils.clamp(this.dot(a) / magnitudes, -1.0, 1.0));
		}
		else {
			return Number.NaN;
		}
	}

	/**
	 * Sets this to a, clamped between min and max, component-wise.
	 * @param {Vector2} a
	 * @param {Vector2} min
	 * @param {Vector2} max
	 */
	clamp(a, min, max) {
		this.throwIfFrozen();
		this._x = MathUtils.clamp(a._x, min._x, max._x);
		this._y = MathUtils.clamp(a._y, min._y, max._y);
	}

	/**
	 * Sets this to the lerp between a and b, where u is the lerp parameter, and it may be clamped between a and b.
	 * @param {Vector2} a - the value when u = 0
	 * @param {Vector2} b - the value when u = 1
	 * @param {number} u - the lerp factor
	 */
	lerp(a, b, u) {
		this.throwIfFrozen();
		this._x = MathUtils.lerp(a._x, b._x, u);
		this._y = MathUtils.lerp(a._y, b._y, u);
	}
}

/**
 * @type {Pool<Vector2>}
 */
const _pool = new Pool(Vector2);

const _zero = new Vector2();
_zero.freeze();

const _xAxis = new Vector2(1, 0);
_xAxis.freeze();

const _yAxis = new Vector2(0, 1);
_yAxis.freeze();

const _nan = new Vector2(Number.NaN, Number.NaN);
_nan.freeze();
