import React from 'react';
import { datasetStore, spacecraftStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';
import ar from '../languages/ar';
import '../../www/assets/css/date_label.css';
import { formatDate } from '../helpers/tools';

/**
 * @typedef Props
 * @prop {string} content
 */

// Todo: Simplify to functional component - task #3191.
class DateRange extends React.Component {
	componentDidMount() {
		this.dsUnsubscribe = datasetStore.subscribeAll(() => this.forceUpdate());
	}

	componentWillUnmount() {
		typeof this.dsUnsubscribe === 'function' && this.dsUnsubscribe();
	}

	render() {
		const { getManager } = globalRefs;

		const { hasAnimation, animations, isMonthly, getAnimationStartDate, getAnimationEndDate } = getManager('dataset');
		const { isSatellitesNow } = spacecraftStore.stateSnapshot;

		if (isSatellitesNow || !animations?.length) {
			return null;
		}

		const dateRangeStart = getAnimationStartDate();
		const dateRangeEnd = getAnimationEndDate();

		const localeOptions = {
			day: isMonthly ? undefined : '2-digit',
			month: '2-digit'
		};
		const animatingDateRangeString = `${ar["range"]}: ${dateRangeStart.toLocaleDateString('ar-SA', localeOptions)} - ${dateRangeEnd.toLocaleDateString('ar-SA', localeOptions)}`;

		return hasAnimation
			? (
				<div>
					<div id='date-range' className='date-label d-none d-lg-flex flex-lg-column'>
						<span className='animation-date-range'>{animatingDateRangeString}</span>
					</div>
					<div id='mobile-date-range' className='d-block d-lg-none'>
						<div>
							<span className='animation-date-range'>{animatingDateRangeString}</span>
						</div>
					</div>
				</div>
			)
			: null;
	}
}

export default DateRange;
