/** @module pioneer */
import {
	CameraComponent,
	CollectionItem,
	Color,
	Engine,
	Rect,
	THREE,
	Vector2,
	Vector3
} from './internal';

/**
 * A viewport is where a scene will be rendered through a camera.
 * When it is created, it creates a div element that can be styled, and which is contained in the viewports div.
 * @extends {CollectionItem<Engine>}
 */
export class Viewport extends CollectionItem {
	/**
	 * Constructs the viewport. Only called by the engine.
	 * @param {string} type - The type of the viewport (always 'viewport').
	 * @param {string} name - The name of the viewport.
	 * @param {Engine} engine - The engine.
	 */
	constructor(type, name, engine) {
		super(type, name, engine);

		/**
		 * Flag whether the viewport is enabled or not.
		 * @type {boolean}
		 * @private
		 */
		this._enabled = true;

		/**
		 * The camera being used to render.
		 * @type {CameraComponent}
		 * @private
		 */
		this._camera = null;

		/**
		 * The div that this viewport uses for its bounds.
		 * @type {HTMLDivElement}
		 * @private
		 */
		this._div = document.createElement('div');

		/**
		 * The bounds of the viewport. Calculated from the div.
		 * @type {Rect}
		 * @private
		 */
		this._bounds = new Rect(0, 0, 0, 0);
		this._bounds.freeze();

		/**
		 * The color of the viewport background.
		 * @type {Color}
		 * @private
		 */
		this._backgroundColor = new Color(0, 0, 0, 1);
		this._backgroundColor.freeze();

		/**
		 * A THREE.Color helper so that a new THREE.Color doesn't need to be generated every frame.
		 * @type {THREE.Color}
		 * @private
		 */
		this._threeJsBackgroundColor = new THREE.Color();

		// Configure and add the div.
		this._div.style.position = 'absolute';
		this._div.style.overflow = 'hidden';
		this._div.id = name ?? '';
		this._div.classList.add('viewport');
		if (!this._enabled) {
			this._div.style.display = 'none';
		}
		this.getEngine().getViewportDiv().appendChild(this._div);

		// Make sure the pixel bounds are valid on startup.
		const rootDiv = this.getEngine().getRootDiv();
		this._bounds.thaw();
		this._bounds.set(this._div.offsetLeft - rootDiv.offsetLeft, this._div.offsetTop - rootDiv.offsetTop, this._div.offsetWidth, this._div.offsetHeight);
		this._bounds.freeze();

		// Make this the active viewport if there is none yet.
		const input = this.getEngine().getInput();
		if (input.getActiveViewport() === null) {
			input.__setActiveViewport(this);
		}
	}

	/**
	 * Gets the engine.
	 * @returns {Engine}
	 */
	getEngine() {
		return this.__getCollectionParent();
	}

	/**
	 * Gets the div. This can be used to style the viewport.
	 * @returns {HTMLDivElement}
	 */
	getDiv() {
		return this._div;
	}

	/**
	 * Gets the pixel bounds within which this viewport renders, relative to the root div.
	 * @returns {Rect}
	 */
	getBounds() {
		return this._bounds;
	}

	/**
	 * Gets the background color.
	 * @returns {Color}
	 */
	getBackgroundColor() {
		return this._backgroundColor;
	}

	/**
	 * Sets the background color.
	 * @param {Color} backgroundColor - The color for the background.
	 */
	setBackgroundColor(backgroundColor) {
		this._backgroundColor.thaw();
		this._backgroundColor = backgroundColor;
		this._backgroundColor.freeze();
		this._threeJsBackgroundColor.setRGB(this._backgroundColor.r, this._backgroundColor.g, this._backgroundColor.b);
	}

	/**
	 * Returns the camera that will be used by this viewport.
	 * @returns {CameraComponent}
	 */
	getCamera() {
		return this._camera;
	}

	/**
	 * Sets the camera that will be used by this viewport.
	 * @param {CameraComponent} camera
	 */
	setCamera(camera) {
		this._camera = camera;
		if (this._camera !== null) {
			this._camera.__setViewport(this);
		}
	}

	/**
	 * Returns true if the viewport is enabled.
	 * @returns {boolean}
	 */
	isEnabled() {
		return this._enabled;
	}

	/**
	 * Sets whether the viewport is enabled or not.
	 * @param {boolean} enabled
	 */
	setEnabled(enabled) {
		this._enabled = enabled;
		if (!this._enabled) {
			this._div.style.display = 'none';
		}
		else {
			this._div.style.display = 'block';
		}
	}

	/**
	 * Gets a normal-space position from a pixel-space position. Sets the z coordinate to +1, which is equivalent to the near point of the camera.
	 * @param {Vector3} outNormalSpacePosition
	 * @param {Vector2} pixelSpacePosition
	 */
	getNormalSpacePositionFromPixelSpacePosition(outNormalSpacePosition, pixelSpacePosition) {
		outNormalSpacePosition.x = 2.0 * (pixelSpacePosition.x - this._bounds.origin.x) / this._bounds.size.x - 1.0;
		outNormalSpacePosition.y = 1.0 - 2.0 * (pixelSpacePosition.y - this._bounds.origin.y) / this._bounds.size.y;
		outNormalSpacePosition.z = -1;
	}

	/**
	 * Gets a pixel-space position from a normal-space position. Ignores the z coordinate.
	 * @param {Vector2} outPixelSpacePosition
	 * @param {Vector3} normalSpacePosition
	 */
	getPixelSpacePositionFromNormalSpacePosition(outPixelSpacePosition, normalSpacePosition) {
		if (-1.0 < normalSpacePosition.z && normalSpacePosition.z < 1.0) {
			outPixelSpacePosition.x = this._bounds.size.x * (normalSpacePosition.x + 1.0) / 2.0 + this._bounds.origin.x;
			outPixelSpacePosition.y = this._bounds.size.y * (1.0 - normalSpacePosition.y) / 2.0 + this._bounds.origin.y;
		}
		else {
			outPixelSpacePosition.copy(Vector2.NaN);
		}
	}

	/**
	 * Gets the pixel-space radius from a normal-space radius.
	 * @param {number} normalSpaceRadius
	 * @returns {number}
	 */
	getPixelSpaceRadiusFromNormalSpaceRadius(normalSpaceRadius) {
		return normalSpaceRadius * Math.max(this._bounds.size.x, this._bounds.size.y);
	}

	/**
	 * Gets the normal-space radius from a pixel-space radius.
	 * @param {number} pixelSpaceRadius
	 * @returns {number}
	 */
	getNormalSpaceRadiusFromPixelSpaceRadius(pixelSpaceRadius) {
		return pixelSpaceRadius / Math.max(this._bounds.size.x, this._bounds.size.y);
	}

	/**
	 * Gets the direction in camera-space of the cursor position, or NaN if the cursor is not over the viewport.
	 * @param {Vector3} outDirection
	 */
	getDirectionOfCursor(outDirection) {
		const camera = this.getCamera();
		if (camera === null) {
			outDirection.copy(Vector3.NaN);
			return;
		}
		// Get the picked position in normal space.
		const input = this.getEngine().getInput();
		this.getNormalSpacePositionFromPixelSpacePosition(outDirection, input.getCursorPosition());
		if (outDirection.x < -1 || outDirection.x > +1
			|| outDirection.y < -1 || outDirection.y > +1
			|| outDirection.z < -1 || outDirection.z > +1) {
			outDirection.copy(Vector3.NaN);
			return;
		}

		// Get the picked position in camera space.
		camera.getCameraSpacePositionFromNormalSpacePosition(outDirection, outDirection);

		// Turn it into a ray of length 1, going from the camera.
		outDirection.normalize(outDirection);
	}

	/**
	 * Removes the div from the viewports div.
	 * @override
	 * @internal
	 */
	__destroy() {
		super.__destroy();

		// Clean up the div.
		this._div.parentNode.removeChild(this._div);
	}

	/**
	 * Prepares the camera-dependent variables and those of its connected entities.
	 * @internal
	 */
	__updateViewportVariables() {
		if (this._enabled) {
			// Update the bounds.
			this._bounds.thaw();
			this._bounds.set(this._div.offsetLeft, this._div.offsetTop, this._div.offsetWidth, this._div.offsetHeight);
			this._bounds.freeze();

			// Update the camera variables for the camera.
			if (this._camera !== null) {
				this._camera.__updateCameraVariablesForConnectedScene();
			}
		}
	}

	/**
	 * Renders the camera in the viewport. Called by Engine.
	 * @internal
	 */
	__render() {
		if (!this._enabled) {
			return;
		}

		const renderer = this.getEngine().__getThreeJsRenderer();

		const positionFromBottomLeft = this.getEngine().getRootDiv().offsetHeight - this._div.offsetTop - this._div.offsetHeight;
		renderer.setViewport(this._bounds.origin.x, positionFromBottomLeft, this._bounds.size.x, this._bounds.size.y);
		renderer.setScissor(this._bounds.origin.x, positionFromBottomLeft, this._bounds.size.x, this._bounds.size.y);

		this._threeJsBackgroundColor.setRGB(this._backgroundColor.r, this._backgroundColor.g, this._backgroundColor.b);
		renderer.setClearColor(this._threeJsBackgroundColor, this._backgroundColor.a);

		// Render the camera (which renders its scene).
		if (this._camera !== null) {
			this._camera.__prepareForRender();

			this._camera.__render();
		}
		else {
			this.getEngine().__getThreeJsRenderer().clear();
		}
	}
}
