/**
 * The vitals data is a mixture of both fixed, and dynamic data.
 * All of the fixed data should reside and be adjusted here.
 * The dynamic data links could be logically determined, for instance, generating the correct download
 * links based upon the current day. But that can't be the case for when we have missing data.
 * If we have missing data for a specific day, we wont know that until it fails to download.
 * Creating graceful fallbacks after potentially multiple failed downloads would be tricky to accomplish
 * without some likely degradation of the user experience.
 * As a solution, the download links should be generated by a script which takes into account missing data
 * and replaces it with a reliable backup if needed.
 * In addition, the script can also provide further image compression/manipulation. For instance, downrez-ing
 * the cylindrical composite, as well as generating a spherical image from it.
 *
 * Previously this was done on Mooreboeck's servers and meant we had to use their API. But this mostly involved scraping
 * our own servers for data we already have.
 * The goal of this file and it accompanying script, is to:
 * - remove Mooreboeck and the use of their API
 * - increase fallback reliability
 * - reduce the amount of transferred data / load times
 */

const VITALS = [
	{
		value: 'satellites',
		title: 'الأقمار الصناعية الآن',
		description: 'وكالة ناسا لديها أكثر من أربع وعشرين قمراً صناعياً لدراسة الأرض. ومشروع الأرض الآن ”Earth Now“ يقدم العديد من الأقمار الصناعية التي تركز على العلامات الحيوية لكوكبنا. انقر على أي قمر صناعي لعرض نموذج ثلاثي الأبعاد له، أو شاهد أحدث البيانات في قائمة العلامات الحيوية.\n',
		sphereMapThumbSrc: 'sat_now.png',
		datasetGroups: []
	},
	{
		value: 'visible-earth',
		title: "الأرض المرئية",
		description: "هذه صورة مركبة لكيفية ظهور كوكب الأرض بالكامل من الفضاء يوم أمس.",
		sphereMapThumbSrc: 'suomi.png',
		datasetGroups: [
			{
				title: "سومي إن بي بي (Suomi NPP ) / فيرس (VIIRS) ",
				datasets: [
					{
						title: "سومي إن بي بي (Suomi NPP ) / فيرس (VIIRS) ",
						average: 'daily',
						externalId: 'viirsToday',
						urlParam: 'viirs-infrared-daily',
						menuTitle: "المتوسط اليومي",
						description: "أداة للتصوير و القياس الإشعاعي للأشعة تحت الحمراء المرئية وتختصر بفيرس (VIIRS) والتي تقوم بتجميع صور مرئية وصور للأشعة تحت الحمراء و القياسات الإشعاعية للأرض والغلاف الجوي والغلاف الجليدي والمحيطات. يتم استخدام بيانات فيرس (VIIRS) لقياس خصائص السُحب و العوالق الجوية (الهباء الجوي) ولون المحيط ودرجة حرارة سطح البحر والأرض وحركة الجليد ودرجة الحرارة والحرائق والانعكاسات الناشئة من الأرض (وضاءة الأرض). تُظهر هذه الخريطة أحدث الصوة المتراصة (موزاييك) للأرض على مدار 24 ساعة من الفضاء في ضوء مرئي ، والتي تم الحصول عليها عن طريق فيرس (VIIRS) على متن القمر الصناعي سومي إن بي بي Suomi NPP التابع لـ NASA/NOAA.",
						missionIds: ['sc_suomi_npp']
					}
				]
			},
			{
				title: "تيرا (Terra) / موديس (MODIS)",
				datasets: [
					{
						title: " الصوة المتراصة (موزاييك) اليومية تيرا/موديس",
						average: 'daily',
						externalId: 'modisToday',
						urlParam: 'terra-modis-spectroradiometer-daily',
						menuTitle: "المتوسط اليومي",
						description: "أجهزة موديس ترى الأرض في ٣٦ أطوال موجية مختلفة من الطيف، تتراوح من الضوء المرئي إلى الأشعة تحت الحمراء الحرارية. الصور المتراصة من الطول الموجي المرئي المعروضة هنا يمكن أن تكون مفيدة لرصد التغيرات في الأرض والغطاء السحابي والعواصف والأحداث الجوية واسعة النطاق ، مثل الانفجارات البركانية وحرائق الغابات.",
						missionIds: ['sc_terra']
					}
				]
			},
			{
				title: 'أكوا(Aqua) / موديس (MODIS)',
				datasets: [
					{
						title: "الصوة المتراصة (موزاييك) اليومية أكوا(Aqua) / موديس (MODIS)",
						average: 'daily',
						externalId: 'modisAquaToday',
						urlParam: 'aqua-modis-spectroradiometer-daily',
						menuTitle: "المتوسط اليومي",
						description: "تُظهر هذه الخريطة أحدث ١٥ شريط , من الصوة المتراصة (موزاييك) للأرض من الفضاء في ضوء مرئي,  والتي تم الحصول عليها بإستخدام جهاز مقياس الطيف التصويري المعدل (موديس) على متن القمر الصناعي اكوى التابع لناسا.",
						missionIds: ['sc_aqua']
					}
				]
			}
		]
	},
	{
		value: 'air-temperature',
		title: "درجة حرارة الهواء",
		description: 'تتأثر التغيرات في درجة حرارة الغلاف الجوي للأرض بتأثيرات الاحتباس الحراري، وبالتالي، من المهم مراقبتها. درجات الحرارة خلال النهار مدفوعة بنشاط من الشمس. درجة حرارة الغلاف الجوي ليست ثابتة وتختلف إقليمياً وعلى ارتفاعات مختلفة فوق الأرض. تساعد التدفئة غير المتكافئة في الغلاف الجوي على دفع دوران الهواء فوق الكوكب.',
		shortDescription: "تتأثر درجة حرارة الغلاف الجوي للأرض بتأثيرات الاحتباس الحراري ولهذا تتم مراقبتها.",		sphereMapThumbSrc: 'AIRS_Temp_Surface.png',
		datasetGroups: [
			{
				title: "الحرارة السطحية - أيرس (AIRS)",
				datasets: [
					{
						title:"متوسط درجة حرارة الهواء على السطح أثناء النهار - أيرس (AIRS)",
						average: 'three_day',
						externalId: 'airSurfaceDayTemp3Day',
						urlParam: 'airs-infrared-surface-3day',
						description: "تُظهر خريطة البيانات متوسط درجة الحرارة لمدة ثلاثة أيام خلال النهار على بعد حوالي 6 أقدام فوق سطح الأرض، تم قياسه بواسطة أداة ناسا الفضائية أيرس(AIRS). تشير المساحات البنفسجية والزرقاء إلى درجات الحرارة الباردة. بينما اللونين الأصفر والأحمر يدلان على المساحات الدافئة. يتم عرض درجات الحرارة بالفهرنهايت تلقائياً ، ولكن يمكن تغيير القراءة إلى درجة مئوية عن طريق النقر على مفتاح البيانات.",
						menuTitle: "متوسط 3 أيام",
						missionIds: ['sc_aqua'],
						temperatureData: true,
						singleHtmlColorBar: true,
						readoutData: {
							units: 'ف°',
							src: 'surfaceDayTemp_bar_copy.png',
							label: 'درجة',
							markers: [-40, -18, 0, 24, 46],
							markersSpacing: [0, 26, 47.5, 75, 100],
							preciseMarkers: false,
							bottom: -40,
							numOffset: -300,
							interval: 0.945,
							variation: 86,
							scalingFactor: 1
						}
					},
					{
						title: "متوسط درجة حرارة الهواء على السطح أثناء النهار - أيرس (AIRS)",
						average: 'daily',
						externalId: 'airSurfaceDayTempToday',
						urlParam: 'airs-infrared-surface-daily',
						description:"تُظهر خريطة البيانات درجات الحرارة خلال النهار على بعد حوالي ٦ أقدام فوق سطح الأرض ، تم قياسه بواسطة أداة ناسا الفضائية أيرس(AIRS). تشير المساحات البنفسجية والزرقاء إلى درجات الحرارة الباردة. بينما اللونين الأصفر والأحمر يدلان على المساحات الدافئة. يتم عرض درجات الحرارة بالفهرنهايت تلقائياً، ولكن يمكن تغيير القراءة إلى درجة مئوية عن طريق النقر على مفتاح البيانات.",
						menuTitle: "المتوسط اليومي",
						missionIds: ['sc_aqua'],
						temperatureData: true,
						singleHtmlColorBar: true,
						readoutData: {
							units: 'ف°',
							src: 'surfaceDayTemp_bar_copy.png',
							label: 'درجة',
							markers: [-40, -18, 0, 24, 46],
							markersSpacing: [0, 26, 47.5, 75, 100],
							preciseMarkers: false,
							bottom: -40,
							numOffset: -300,
							interval: 0.945,
							variation: 86,
							scalingFactor: 1
						}
					},
					{
						title: "متوسط درجة حرارة الهواء على السطح أثناء الليل - أيرس (AIRS)",
						average: 'three_day',
						externalId: 'airSurfaceNightTemp3Day',
						urlParam: 'airs-infrared-night-surface-3day',
						description: 'تُظهر خريطة البيانات هذه متوسطًا لدرجات الحرارة الليلية لمدة ثلاثة أيام على ارتفاع حوالي 6 أقدام فوق سطح الأرض، كما تم قياسها بواسطة أداة AIRS المحمولة في الفضاء التابعة لناسا. تشير المناطق الأرجوانية والزرقاء إلى درجات حرارة أكثر برودة؛ يشير الأصفر والأحمر إلى المناطق الدافئة. يمكن تغيير وحدات قراءة درجة حرارة الأرض عن طريق النقر أو النقر على مفتاح البيانات.',
						menuTitle: "متوسط ٣ أيام ليلاً",
						missionIds: ['sc_aqua'],
						temperatureData: true,
						singleHtmlColorBar: true,
						readoutData: {
							units: 'ف°',
							src: 'surfaceDayTemp_bar_copy.png',
							label: 'درجة',
							markers: [-40, -18, 0, 24, 46],
							markersSpacing: [0, 26, 47.5, 75, 100],
							preciseMarkers: false,
							bottom: -40,
							numOffset: -300,
							interval: 0.945,
							variation: 86,
							scalingFactor: 1
						}
					},
					{
						title: "متوسط درجة حرارة الهواء على السطح أثناء الليل - أيرس (AIRS)",
						average: 'daily',
						externalId: 'airSurfaceNightTempToday',
						urlParam: 'airs-infrared-night-surface-daily',
						description: 'تُظهر خريطة البيانات هذه درجات الحرارة ليلاً على ارتفاع حوالي 6 أقدام فوق سطح الأرض، كما تم قياسها بواسطة أداة AIRS المحمولة في الفضاء التابعة لناسا. تشير المناطق الأرجوانية والزرقاء إلى درجات حرارة أكثر برودة؛ يشير الأصفر والأحمر إلى المناطق الدافئة. يمكن تغيير وحدات قراءة درجة حرارة الأرض عن طريق النقر أو الضغط على مفتاح البيانات.',
						menuTitle: "المتوسط اليومي ليلا",
						missionIds: ['sc_aqua'],
						temperatureData: true,
						singleHtmlColorBar: true,
						readoutData: {
							units: 'ف°',
							src: 'surfaceDayTemp_bar_copy.png',
							label: 'درجة',
							markers: [-40, -18, 0, 24, 46],
							markersSpacing: [0, 26, 47.5, 75, 100],
							preciseMarkers: false,
							bottom: -40,
							numOffset: -300,
							interval: 0.945,
							variation: 86,
							scalingFactor: 1
						}
					}
				]
			},
			{
				title: "درجة حرارة الهواء - أيرس (AIRS) (١٠،٠٠٠ قدم)",
				datasets: [
					{
						title: "درجة حرارة الهواء أثناء النهار على ارتفاع ١٠,٠٠٠ قدم - أيرس (AIRS)",
						average: 'three_day',
						externalId: 'dayTemp3Day',
						urlParam: 'airs-infrared-10000ft-3day',
						description: "تُظهر هذه الخريطة درجات حرارة سطح الهواء أثتاء النهار على ارتفاع ١٠,٠٠٠ قدم, الذي تم قياسه بواسطة أداة ناسا الفضائية أيرس (AIRS) . تشير المناطق ذات اللون الأصفر الفاتح والأخضر والأزرق إلى درجات حرارة سطح الهواء الباردة. بينما اللونين البرتقالي والأحمر يدلان على المناطق الدافئة."						,
						menuTitle: "متوسط 3 أيام نهاراً",
						missionIds: ['sc_aqua'],
						temperatureData: true,
						singleHtmlColorBar: true,
						readoutData: {
							units: 'ف°',
							src: 'surfaceDayTemp_bar_copy.png',
							label: 'درجة',
							markers: [-40, -18, 0, 24, 46],
							markersSpacing: [0, 26, 47.5, 75, 100],
							preciseMarkers: false,
							bottom: -40,
							numOffset: -300,
							interval: 0.945,
							variation: 86,
							scalingFactor: 1
						}
					},
					{
						title: "(AIRS) درجة حرارة الهواء أثناء النهار عند 10,000 قدم - أيرس",
						average: 'daily',
						externalId: 'dayTempToday',
						urlParam: 'airs-infrared-10000ft-daily',
						description: "تُظهر هذه الخريطة درجات الحرارة السطحية اثناء النهار على ارتفاع 10,000 قدم، تم قياسه بواسطة أداة ناسا الفضائية أيرس. تشير المساحات ذات اللون الأصفر الفاتح والأخضر والأزرق الى درجات الحرارة السطحية الأكثر برودة ، بينما يشير اللونين البرتقالي والأحمر الى المناطق الأكثر دفئاً.",
						menuTitle: "المتوسط اليومي نهاراً",
						missionIds: ['sc_aqua'],
						temperatureData: true,
						singleHtmlColorBar: true,
						readoutData: {
							units: 'ف°',
							src: 'surfaceDayTemp_bar_copy.png',
							label: 'درجة',
							markers: [-40, -18, 0, 24, 46],
							markersSpacing: [0, 26, 47.5, 75, 100],
							preciseMarkers: false,
							bottom: -40,
							numOffset: -300,
							interval: 0.945,
							variation: 86,
							scalingFactor: 1
						}
					},
					{
						title: "(AIRS) متوسط درجة حرارة الهواء أثناء الليل عند 10,000 قدم - أيرس",
						average: 'three_day',
						externalId: 'nightTemp3Day',
						urlParam: 'airs-infrared-night-10000ft-3day',
						description: "تُظهر هذه الخريطة متوسط درجات حرارة سطح الهواء الليلي أثناء النهار على ارتفاع 10,000 قدم على مدار الأيام الثلاثة الماضية، تم قياسه بواسطة أداة ناسا الفضائية أيرس. تشير المساحات ذات اللون الأصفر الفاتح والأخضر والأزرق الى درجات الحرارة السطحية الأكثر برودة ، بينما يشير اللونين البرتقالي والأحمر الى المناطق الأكثر دفئاً.",
						menuTitle: "متوسط ٣ أيام ليلاً",
						missionIds: ['sc_aqua'],
						temperatureData: true,
						singleHtmlColorBar: true,
						readoutData: {
							units: 'ف°',
							src: 'surfaceDayTemp_bar_copy.png',
							label: 'درجة',
							markers: [-40, -18, 0, 24, 46],
							markersSpacing: [0, 26, 47.5, 75, 100],
							preciseMarkers: false,
							bottom: -40,
							numOffset: -300,
							interval: 0.945,
							variation: 86,
							scalingFactor: 1
						}
					},
					{
						title: "(AIRS) درجة حرارة الهواء أثناء الليل عند 10,000 قدم - أيرس",
						average: 'daily',
						externalId: 'nightTempToday',
						urlParam: 'airs-infrared-night-10000ft-daily',
						description: "تُظهر هذه الخريطة درجات الحرارة السطحية اثناء الليل على ارتفاع 10,000 قدم، تم قياسه بواسطة أداة ناسا الفضائية أيرس. تشير المساحات ذات اللون الأصفر الفاتح والأخضر والأزرق الى درجات الحرارة السطحية الأكثر برودة ، بينما يشير اللونين البرتقالي والأحمر الى المناطق الأكثر دفئاً.",
						menuTitle: "المتوسط اليومي ليلاً",
						missionIds: ['sc_aqua'],
						temperatureData: true,
						singleHtmlColorBar: true,
						readoutData: {
							units: 'ف°',
							src: 'surfaceDayTemp_bar_copy.png',
							label: 'درجة',
							markers: [-40, -18, 0, 24, 46],
							markersSpacing: [0, 26, 47.5, 75, 100],
							preciseMarkers: false,
							bottom: -40,
							numOffset: -300,
							interval: 0.945,
							variation: 86,
							scalingFactor: 1
						}
					}
				]
			}
		]
	},
	{
		value: 'carbon-dioxide',
		title: "ثاني أكسيد الكربون",
		description: 'ثاني أكسيد الكربون، أو CO₂، هو أحد الغازات الدفيئة المشتقة من الأنشطة البشرية والطبيعية التي تبطئ تسرب الحرارة إلى الفضاء. يمكن أن يساهم ارتفاع مستويات ثاني أكسيد الكربون في ظاهرة الاحتباس الحراري.',
		shortDescription: "ثاني أكسيد الكربون هو أحد غازات الدفيئة، المستمدة من الأنشطة البشرية والطبيعية والتي تعمل على احتجاز الحرارة في الغلاف الجوي لكوكب الأرض وعدم نفاذها إلى الفضاء الخارجي."		,
		sphereMapThumbSrc: 'OCO2_CO2.png',
		datasetGroups: [
			{
				title: "ثاني أكسيد الكربون أو سي أو-٢ (OCO-2)",
				datasets: [
					{
						title: "مجموع بيانات ثاني أكسيد الكربون لمدة ١٦ يوم (OCO-2)"						,
						average: 'sixteen_day',
						externalId: 'oco216Day',
						urlParam: 'oco-2-carbon-observatory-16day',
						description: "يقيس القمر الصناعي مرصد الكربون المداري-٢ (OCO-2) تراكيز ثاني أكسيد الكربون في كافة أرجاء العالم. وتساعد البيانات المستمدة من القمر الصناعي على معرفة مصادر الغازات الدفيئة في الغلاف الجوي وعمليات إزالتها. توضح خريطة البيانات متوسط توزيع ثاني أكسيد الكربون في الأرض خلال الشهر الماضي. حيث تشير المساحات الحمراء والصفراء إلى تركيز عالٍ من ثاني أكسيد الكربون بينما تشير المساحات الزرقاء إلى تركيز أقل. يتم قياس توزيع ثاني أكسيد الكربون في جزء من المليون وبالتالي فإن 1000 جزء من المليون سيكون مكافئًا لتركيز ثاني أكسيد الكربون بنسبة 0.1% في الغلاف الجوي. ",
						menuTitle: "متوسط ١٦ يوم",
						missionIds: ['sc_oco_2'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'جزء من المليون',
							src: 'oco2_bar_copy.png',
							label: 'التركيز في جزء من المليون',
							markers: [390, 400, 410, 420, 430],
							preciseMarkers: true,
							bottom: 390,
							numOffset: 0,
							interval: 1,
							variation: 40,
							scalingFactor: 0.01,
							dps: 2
						}
					}
				]
			},
			{
				title: "ثاني أكسيد الكربون - أيرس (AIRS)",
				datasets: [
					{
						title: " المتوسط الشهري لثاني أكسيد الكربون - أيرس (AIRS)",
						average: 'month',
						externalId: 'newCo2Monthly',
						urlParam: 'airs-infrared-co2-monthly',
						description: "تُظهر هذه الخريطة توزيع ثاني أكسيد الكربون الجوي خلال الشهر الماضي، كما تم قياسه بواسطة الجهاز المحمول من الفضاء أيرس (AIRS). تشير المناطق الحمراء والصفراء إلى تركيز أعلى لثاني أكسيد الكربون، بينما يشير اللون الأزرق إلى مناطق ذات تركيز أقل."						,
						menuTitle: "المتوسط الشهري",
						missionIds: ['sc_aqua'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'جزء من المليون',
							src: 'newCo2_bar_copy.png',
							label: 'التركيز في جزء من المليون',
							markers: [360, 370, 380, 390, 400, 410],
							preciseMarkers: true,
							bottom: 360,
							numOffset: 0,
							interval: 1,
							variation: 50,
							scalingFactor: 0.01,
							dps: 2
						}
					}
				]
			}
		]
	},
	{
		value: 'carbon-monoxide',
		title: "أول أكسيد الكربون",
		description: 'أول أكسيد الكربون، أو CO، هو غاز ينبعث من السيارات والبراكين وحرائق الغابات وأي حرق غير كامل للخشب أو الوقود الأحفوري الذي يتفاعل في غلافنا الجوي لتكوين ثاني أكسيد الكربون. ويؤثر ثاني أكسيد الكربون أيضًا على غاز الميثان وانخفاض مستويات الأوزون في الغلاف الجوي، مما يجعله يلعب دورًا حاسمًا في تلوث الهواء وتغير المناخ.',
		shortDescription: "غاز أول أكسيد الكربون هو غاز ينبعث من السيارات والبراكين وحرائق الغابات ومن عمليات الاحتراق غير التام للخشب أو الوقود الأحفوري، الذي بدوره يتفاعل مع الغلاف الجوي وينشئ ثاني أكسيد الكربون.",
		sphereMapThumbSrc: 'AIRS_CO.png',
		datasetGroups: [
			{
				title: "أول أكسيد الكربون - أيرس (AIRS)",
				datasets: [
					{
						title: "متوسط أول أكسيد الكربون على ارتفاع ١٨,٠٠٠ قدم - أيرس (AIRS)",
						average: 'three_day',
						externalId: 'mono3Day',
						urlParam: 'airs-infrared-18000ft-3day',
						description: "تُظهر خريطة البيانات متوسط توزيع أول أكسيد الكربون في الثلاثة أيام الماضية على ارتفاع ١٨,٠٠٠ قدم. تشير المساحات البرتقالية والحمراء إلى تركيز عالِ من أول أكسيد الكربون, بينما تشير الماسحات الزرقاء والبنفسجية إلى تركيز أقل. يتم تمثيل تركيز أول أكسيد الكربون بجزء من المليار من حيث الحجم (ppbv). جزء واحد من ppbv يعني أن لكل مليار من جزيء غاز في حجم معين يكون أحدهم غاز أول أكسيد الكربون (CO). تمثل المساحات السوداء المساحات التي لم يتم جمع البيانات لها أو لا تتوفر للأداة معلومات كافية عنها.",
						menuTitle: "متوسط 3 أيام",
						missionIds: ['sc_aqua'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'جزء من المليار',
							bottom: 0,
							src: 'mono_bar_copy.png',
							label: 'التركيز في جزء من المليار',
							markers: [0, 40, 80, 120, 160],
							preciseMarkers: true,
							numOffset: 0,
							interval: 3.2,
							variation: 160,
							scalingFactor: 1
						}
					},
					{
						title: "أول أكسيد الكربون على ارتفاع ١٨,٠٠٠ قدم - أيرس (AIRS) ",
						average: 'daily',
						externalId: 'monoToday',
						urlParam: 'airs-infrared-18000ft-daily',
						description: "تُظهر خريطة البيانات متوسط توزيع أول أكسيد الكربون لليلة الماضية على ارتفاع ١٨,٠٠٠ قدم. تشير المساحات البرتقالية والحمراء إلى تركيز عالِ من أول أكسيد الكربون, بينما تشير الماسحات الزرقاء والبنفسجية إلى تركيز أقل. يتم تمثيل تركيز أول أكسيد الكربون بجزء من المليار من حيث الحجم (ppbv). جزء واحد من ppbv يعني أن لكل مليار من جزيء غاز في حجم معين يكون أحدهم غاز أول أكسيد الكربون (CO). تمثل المساحات السوداء المساحات التي لم يتم جمع البيانات لها أو لا تتوفر للأداة معلومات كافية عنها.",
						menuTitle: "المتوسط اليومي",
						missionIds: ['sc_aqua'],
						singleHtmlColorBar: true,
						readoutData: {
							label: 'التركيز في جزء من المليار',
							bottom: 0,
							src: 'mono_bar_copy.png',
							units: 'جزء من المليار',
							markers: [0, 40, 80, 120, 160],
							preciseMarkers: true,
							numOffset: 0,
							interval: 3.2,
							variation: 160,
							scalingFactor: 1
						}
					}
				]
			}
		]
	},
	{
		value: 'chlorophyll',
		title: 'الكلوروفيل',
		description: 'الكلوروفيل هو صبغة خضراء توجد في النباتات والطحالب والبكتيريا الزرقاء. يمتص الكلوروفيل الضوء باللونين الأزرق والأحمر، ولكن ليس بنفس القدر باللون الأخضر، لذلك تظهر النباتات والطحالب التي تحتوي على الكلوروفيل باللون الأخضر. يعد الكلوروفيل ضروريًا في عملية التمثيل الضوئي، وهي الطريقة التي يجمع بها النبات الطاقة من ضوء الشمس',
		shortDescription: 'تُظهر هذه الخريطة وفرة الكلوروفيل بالقرب من السطح في مختلف أنحاء العالم، سواء في المحيطات أو في مناطق المياه العذبة. ويرتبط هذا ارتباطًا وثيقًا بالطحالب مثل العوالق النباتية، التي تزود العالم بنحو نصف الأكسجين',
		sphereMapThumbSrc: 'chlorophyll_thumb.png',
		datasetGroups: [
			{
				title: ' (PACE) الكلوروفيل  بيس',
				datasets: [
					{
						title: '(PACE) أداة لقياس لون المحيط - -الكلوروفيل بيس',
						average: 'daily',
						endpoint: 'assets/wmts_xml_chlorophyll',
						maxSet: '1km',
						layerId: 'OCI_PACE_Chlorophyll_a',
						externalId: 'chlorophyllToday',
						urlParam: 'chlorophyll-today',
						menuTitle: 'المتوسط اليومي',
						description: 'تستطيع أداة لون المحيط التابعة لبرنامج بيس اكتشاف شدة الضوء المتغيرة بالقرب من الطبقة المضاءة بأشعة الشمس من الماء بالقرب من سطح المحيط وعنده. إن وجود الكلوروفيل يجعل الضوء الذي يخرج من سطح الماء أخضر، والذي يستخدم كبديل لوجود الطحالب النباتية. تشكل العوالق النباتية قاعدة شبكات الغذاء البحرية والمياه العذبة، وهي ضرورية لدورة الكربون العالمية ',
						missionIds: ['sc_pace'],
						singleHtmlColorBar: true,
						temperatureData: false, // this flag sets the toggle unit button for C and F
						tilableTexture: true,
						colormap: 'https://gitc.earthdata.nasa.gov/colormaps/v1.3/MODIS_Chlorophyll.xml',
						readoutData: {
							bottom: 0,
							variation: 20,
							dps: 3,
							src: 'chlorophyll_bar.png',
							label: 'كلوروفيل مغ/ م٣',
							units: 'مغ/ م٣',
							markers: [0.01, 0.1, 1, 10, 20],
							preciseMarkers: true,
							markersSpacing: [0, 29, 60.6, 87.6, 100],
							scalingFactor: 1
						}
					}
				]
			}
		]
	},
	{
		value: 'precipitation',
		title: 'هطول الأمطار',
		description: 'هطول الأمطار هوَ أيُ منتجٍ لتكثيفِ بخارِ الماءِ في الغلافِ الجويِ الذي يسقطُ بسرعةِ منْ السحابةِ. من الأشكالَ الرئيسيةَ لهطولِ الأمطارِ تشملُ الرذاذَ، المطرُ، الصقيعُ، الثلجُ، البَرَد الدَقِيق والبردُ. ويحدثَ ذلكَ عندما يصبحُ جزءٌ محليٌ منْ الغلافِ الجويِ مشبعا ببخارِ الماءِ ويتكثفُ الماءُ. ',
		shortDescription: 'تتيح هذه الخريطة العالمية لهطول الأمطار للباحثين جعل نماذج الطقس أكثر دقة، وفهم الأمطار العادية والشديدة وتساقط الثلوج في جميع أنحاء العالم بشكل أفضل، وتعزيز التطبيقات الخاصة بالكوارث الحالية والمستقبلية، والأمراض، وإدارة الموارد، وإنتاج الطاقة، والأمن الغذائي.',
		sphereMapThumbSrc: 'precipitation-thumb.png',
		datasetGroups: [
			{
				title: 'هطول الأمطار (جالون لكل دقيقه)',
				datasets: [
					{
						title: 'هطول الأمطار (جالون لكل دقيقه)',
						average: 'daily',
						externalId: 'precipitationToday',
						urlParam: 'imerg-multi-satellite-retrievals-daily',
						description: 'تنتجَ مهمةَ قياسِ هطولِ الأمطارِ العالميةِ (GPM) منتجِ ناسا العالميِ الأكثرِ شمولاً للأمطارِ وتساقطِ الثلوجِ حتى الآنَ، والذي يطلقُ عليهِ الاسترجاعُ المتكاملُ متعددٌ الأقمارِ الصناعيةِ ل GPM (IMERG) ويتمّ حسابهُ باستخدامِ بياناتٍ منْ كوكبةٍ GPM للأقمارِ الصناعيةِ - وهيَ شبكةٌ منْ الأقمارِ الصناعيةِ الدوليةِ التي تضمُ وكالاتٍ شريكةً في أوروبا واليابانِ. توضحَ هذهِ البياناتِ إجماليَ هطولِ الأمطارِ اليوميِ في كلِ مكانٍ على الأرضِ، معَ ألوانٍ منفصلةٍ لهطولِ الأمطارِ والثلوجِ، موضحةً بالملليمترْ في اليومِ. وهذا يسمحُ لنا بقياسِ شدةِ أحداثِ هطولِ الأمطارِ المتطرفةِ وحالاتُ الجفافِ. أنَ الابتعادَ عنْ علمِ المناخِ هوَ وسيلةٌ أساسيةٌ لفهمِ أنماطِ الطقسِ المتغيرةِ لدينا.',
						menuTitle: 'Daily Average',
						missionIds: ['sc_gpm'],
						doubleBar: true,
						readoutData: {
							rain: {
								src: 'precipitation_rain_bar.png',
								label: 'هطول الأمطار (مليليتر/يوم)',
								units: 'ملل/يوم',
								markers: [560, 200, 60, 10, 2.5],
								scalingFactor: 0.01
							},
							snow: {
								src: 'precipitation_snow_bar.png',
								label: 'تساقط الثلوج (مليليتر/يوم)',
								units: 'ملل/يوم',
								markers: [560, 200, 60, 10, 2.5],
								scalingFactor: 0.01
							}
						}
					}
				]
			}
		]
	},
	{
		value: 'sea-level',
		title: "مستوى سطح البحر",
		description: 'يختلف مستوى سطح البحر على الأرض مع ارتفاع حرارة المحيطات وتوسعها. مع ارتفاع درجات الحرارة، يزداد ذوبان الجليد عند الارتفاعات/خطوط العرض الأعلى، مما يؤدي إلى ارتفاع مستويات سطح البحر. الحرارة التي تخزنها محيطاتنا يمكن أن يكون لها تأثير هائل على مناخنا. ولذلك، فهو يوفر للعلماء أداة لا تقدر بثمن لتتبع تغير المناخ العالمي.',
		shortDescription: "يتغير مستوى سطح الأرض بتغير حرارة وتمدد المحيطات. عند ازدياد درجات الحرارة، يزداد ذوبان الجليد على ارتفاعات أعلى، مما يؤدي إلى تغيرات في معدل ارتفاع منسوب مياه البحر."		,
		sphereMapThumbSrc: 'JAS_SLV.png',
		datasetGroups: [
			{
				title: ' اختلاف مستوى البحر - (Sentinel-6) ',
				datasets: [
					{
						title: '(Sentinel-6) - متوسط ​​التغير الشهري لمستوى سطح البحر ',
						average: 'month',
						externalId: 'akikoMonthly',
						urlParam: 'ocean-climate-measurements-monthly',
						dateRange: { start: '2022-04-01', end: '' },
						description: 'تُظهر هذه الخريطة مستوى سطح البحر لهذا الشهر كما تم قياسه بواسطة أقمار ناسا لمستوى سطح البحر: TOPEX/Poseidon، أو Jason-1، أو Jason-2، أو Jason-3، أو Sentinel-6 Michael Freilich. تسلط هذه الخريطة الضوء على دورات مناخ المحيطات، مثل ظاهرة النينيو والنينيا، من خلال إظهار ارتفاع المحيط باعتباره خروجًا عن الدورة الموسمية الطبيعية والارتفاع طويل المدى الناجم عن التدخل البشري في المناخ. تظهر المناطق الصفراء والحمراء والبيضاء مستويات سطح البحر أعلى من المعتاد، والمناطق الزرقاء والأرجوانية أقل من المعتاد. المناطق السوداء هي أماكن بدون ماء أو حيث تفتقد الأقمار الصناعية البيانات.',
						menuTitle: "المتوسط الشهري",
						missionIds: ['sc_sentinel_6'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'مليمتر',
							src: 'trm_bar_copy.png',
							label: 'مليمتر',
							markers: [-176, -88, 0, 88, 186],
							preciseMarkers: true,
							bottom: -176,
							numOffset: -1000,
							interval: 5.5,
							variation: 352,
							scalingFactor: 1,
							dps: 2
						}
					}
				],
				legacyDatasets: [
					{
						title: "متوسط ​​التغير الشهري لمستوى سطح البحر - جيسون-٣ (Jason-3)"						,
						externalId: 'akikoMonthly',
						dateRange: { start: '2016-10-01', end: '2022-03-31' },
						description: "توضح الخريطة متوسط تغير مستويات سطح البحر خلال الشهر الماضي. تشير المساحات الصفراء والحمراء إلى أن مستويات سطح البحر أعلى من المتوسط، في حين تشير المساحات الزرقاء والبيضاء إلى انخفاض مستويات سطح البحر عن المتوسط. يتم عرض تباين مستوى سطح البحر بوحدة مم (ميليميتر) عن متوسط مستوى سطح البحر. تمثل المساحات السوداء المساحات التي لم يتم جمع البيانات لها أو لا تتوفر للأداة معلومات كافية عنها.",
						missionIds: ['sc_jason_3']
					},
					{
						title: 'OSTM/Jason-2 - متوسط ​​التغير الشهري لمستوى سطح البحر',
						externalId: 'akikoMonthly',
						dateRange: { start: '', end: '2016-09-30' },
						description: "توضح الخريطة متوسط تغير مستويات سطح البحر خلال الشهر الماضي. تشير المساحات الصفراء والحمراء إلى أن مستويات سطح البحر أعلى من المتوسط، في حين تشير المساحات الزرقاء والبيضاء إلى انخفاض مستويات سطح البحر عن المتوسط. يتم عرض تباين مستوى سطح البحر بوحدة مم (ميليميتر) عن متوسط مستوى سطح البحر. تمثل المساحات السوداء المساحات التي لم يتم جمع البيانات لها أو لا تتوفر للأداة معلومات كافية عنها.",
						missionIds: ['sc_ostm']
					}
				]
			}
		]
	},
	{
		value: 'sea-surface-temp',
		title: 'درجة حرارة سطح البحر',
		description: 'تُعَد درجة حرارة سطح البحر سمة فيزيائية مهمة لمحيطات العالم. ومع امتصاص المحيطات للمزيد من الحرارة، ترتفع درجة حرارة سطح البحر، وتتغير أنماط الدورة المحيطية التي تنقل المياه الدافئة والباردة حول العالم. وتتأثر النظم البيئية البحرية مثل الشعاب المرجانية، وكذلك أنماط الهجرة والتكاثر للكائنات البحرية. ويؤدي ارتفاع درجة حرارة السطح إلى زيادة بخار الماء في الغلاف الجوي، مما يزيد من خطر هطول الأمطار الغزيرة والثلوج',
		shortDescription: 'تلعب درجة حرارة سطح البحر (درجة حرارة الماء عند سطح البحر، حيث يكون المحيط على اتصال مباشر بالغلاف الجوي) دورًا كبيرًا في المناخ والطقس. على سبيل المثال، تؤثر درجات حرارة المحيط على تطور الأعاصير المدارية (الأعاصير المدارية والعواصف المدارية)، والتي تستمد الطاقة من مياه المحيط الدافئة لتتشكل وتتكثف',
		sphereMapThumbSrc: 'sea_surface.png',
		datasetGroups: [
			{
				title: 'درجة حرارة سطح البحر',
				datasets: [
					{
						title: '(SUOMi-NPP) درجة حرارة سطح البحر أثناء النهار  سومي ان بي بي ',
						average: 'daily',
						endpoint: 'assets/wmts_xml_sst_day',
						maxSet: '1km',
						layerId: 'VIIRS_SNPP_L2_Sea_Surface_Temp_Day',
						externalId: 'seaSurfaceDayTempToday',
						urlParam: 'sea-surface-day-temp-today',
						menuTitle: 'متوسط ​​النهار اليومي',
						description: 'هذه المجموعة العالمية من البيانات مستمدة من جهاز (فيرس) الموجود على متن مركبة الفضاء سومي – ان بي بي، وهي تعرض درجة حرارة سطح البحر أثناء النهار. تشير البقع السوداء في المحيط إلى وجود غطاء سحابي، حيث لا يستطيع مستشعر الأشعة تحت الحمراء الرؤية عبر طبقات سميكة من السحب',
						missionIds: ['sc_suomi_npp'],
						singleHtmlColorBar: true,
						temperatureData: true, // this flag sets the toggle unit button for C and F
						tilableTexture: true,
						colormap: 'https://gibs.earthdata.nasa.gov/colormaps/v1.3/VIIRS_Sea_Surface_Temperature.xml',
						template: 'https://gitc.earthdata.nasa.gov/wmts/epsg3857/best/VIIRS_SNPP_L2_Sea_Surface_Temp_Day/default/{YYYY-MM-DD}/GoogleMapsCompatible_Level7/0/0/0.png',
						readoutData: {
							bottom: 0,
							variation: 32,
							src: 'sea_surface_day_temp_bar.png',
							label: 'حرارة (النهار، المستوى الثاني)',
							units: 'م°',
							markers: [0, 8, 16, 24, 32],
							scalingFactor: 1
						}
					},
					{
						title: '(Suomi NPP) درجة حرارة سطح البحر ليلاً سومي ان بي بي ',
						average: 'daily',
						endpoint: 'assets/wmts_xml_sst_night',
						maxSet: '1km',
						layerId: 'VIIRS_SNPP_L2_Sea_Surface_Temp_Night',
						externalId: 'seaSurfaceNightTempToday',
						urlParam: 'sea-surface-night-temp-today',
						menuTitle: 'متوسط الليل اليومي',
						description: 'هذه المجموعة العالمية من البيانات مستمدة من جهاز (فيرس) الموجود على متن مركبة الفضاء سومي – ان بي بي ، وهي تعرض درجة حرارة سطح البحر في الليل. تشير البقع السوداء في المحيط إلى وجود غطاء سحابي، حيث لا يستطيع مستشعر الأشعة تحت الحمراء الرؤية عبر طبقات سميكة من السحب',
						missionIds: ['sc_suomi_npp'],
						singleHtmlColorBar: true,
						temperatureData: true,
						tilableTexture: true,
						colormap: 'https://gibs.earthdata.nasa.gov/colormaps/v1.3/VIIRS_Sea_Surface_Temperature.xml',
						template: 'https://gitc.earthdata.nasa.gov/wmts/epsg3857/best/VIIRS_SNPP_L2_Sea_Surface_Temp_Night/default/{YYYY-MM-DD}/GoogleMapsCompatible_Level7/0/0/0.png',
						readoutData: {
							bottom: 0,
							variation: 32,
							src: 'sea_surface_night_temp_bar.png',
							label: 'حرارة (الليل، المستوى الثاني)',
							units: 'م°',
							markers: [0, 8, 16, 24, 32],
							scalingFactor: 1
						}
					}
				]
			}
		]
	},
	{
		value: 'soil-moisture',
		title: "رطوبة التربة",
		description: '"تعتبر رطوبة التربة عنصًرا مهمًا في نمو النبات و تؤثر على كيفية تبادل الحرارة بين الأرض والمياه في الغلاف الجوي."		',		sphereMapThumbSrc: 'SMAP_soil_moist.png',
		datasetGroups: [
			{
				title: "رطوبة التربة - سماب(SMAP)",
				datasets: [
					{
						title: " بيانات رطوبة التربة التراكبية لـ ٨ أيام - سماب (SMAP) ",
						average: 'eight_day',
						externalId: 'smapSoilMoisture8Day',
						urlParam: 'smap-saturation-8day',
						description: "تُظهر خريطة البيانات العالمية هذه متوسط تشبع رطوبة التربة لثمانية أيام، كما تم قياسه بواسطة القمر الصناعي سماب(SMAP). تتوافق الألوان الزرقاء والداكنة مع مستويات الرطوبة العالية، في حين تتوافق الألوان الصفراء والفاتحة مع رطوبة التربة المنخفضة. ",
						menuTitle: "متوسط ٨ أيام ",
						missionIds: ['sc_smap'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'سم٣/سم٣',
							src: 'smapSoilMoistureDay_bar_copy.png',
							label: 'تشبع رطوبة التربة في سم٣/سم٣ ',
							markers: [0, 0.2, 0.4, 0.6, 0.8],
							preciseMarkers: true,
							bottom: 0.0,
							numOffset: 0,
							interval: 0.01,
							variation: 0.8,
							scalingFactor: 0.001,
							dps: 2
						}
					},
					{
						title: " المتوسط الشهري لرطوبة التربة - سماب(SMAP)",
						average: 'month',
						externalId: 'smapSoilMoistureMonthly',
						urlParam: 'smap-saturation-monthly',
						description:"تُظهر خريطة البيانات العالمية تشبع رطوبة التربة على الأرض، كما تم قياسه بواسطة القمر الصناعي سماب (SMAP). تشير الألوان الزرقاء والداكنة إلى مستويات الرطوبة العالية، في حين تشير الألوان الصفراء والفاتحة إلى انخفاض رطوبة التربة."						,
						menuTitle: "المتوسط الشهري",
						missionIds: ['sc_smap'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'سم٣/سم٣',
							src: 'smapSoilMoistureDay_bar_copy.png',
							label: 'تشبع رطوبة التربة في سم٣/سم٣ ',
							markers: [0, 0.2, 0.4, 0.6, 0.8],
							preciseMarkers: true,
							bottom: 0.0,
							numOffset: 0,
							interval: 0.01,
							variation: 0.8,
							scalingFactor: 0.001,
							dps: 2
						}
					}
				]
			},
			{
				title: " رطوبة التربة والملوحة – سماب(SMAP)",
				datasets: [
					{
						title: "رطوبة التربة و ملوحة سطح البحر لمتوسط ٨ أيام - سماب (SMAP)",
						average: 'eight_day',
						externalId: 'smapSmSalinity8Day',
						urlParam: 'smap-saturation-salinity-8day',
						menuTitle: "متوسط ٨ أيام",
						description: "تُظهر خريطة البيانات العالمية متوسط ثمانية أيام من المحتوى المائي للتربة والمحتوى المالح لمياه البحر، كما تم قياسه بواسطة القمر الصناعي سماب (SMAP). على مناطق اليابسة، تشير الألوان الزرقاء و الباردة إلى مستويات رطوبة أعلى، بينما تشير الألوان الصفراء و الدافئة إلى انخفاض رطوبة التربة. على مناطق المحيط، تشير الألوان الزرقاء و الباردة إلى المياه العذبة، بينما تشير الألوان الصفراء والدافئة إلى المياه المالحة.",
						missionIds: ['sc_smap'],
						colorBarSrc: ['smapLandWater_bar.png'],
						doubleBar: true,
						readoutData: { // Values for Land.  Conversion to Salinity in water happen in camera manager.
							units: 'سم٣/سم٣',
							bottom: 0,
							numOffset: 39,
							interval: 0.01,
							variation: 600,
							scalingFactor: 1,
							dps: 2
						}
					}
				]
			},
			{
				title: " رطوبة التربة لمنطقة الجذر - سماب(SMAP)",
				datasets: [
					{
						title: " رطوبة التربة لمنطقة الجذر - سماب(SMAP)",
						average: 'daily',
						externalId: 'smapRootzoneWetnessDayToday',
						urlParam: 'smap-root-zone-daily',
						menuTitle: "المتوسط اليومي",
						description: "تشير هذه البيانات إلى كمية الرطوبة في قمة 1 متر من عمود التربة؛ وبعبارة أخرى، كمية الرطوبة المتوفرة للنباتات. عندما يحدث الجفاف، تقل إنتاجية المحاصيل بسبب انخفاض الرطوبة في هذه المنطقة. ",
						missionIds: ['sc_smap'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'م٣/م٣',
							src: 'smapRootzoneWetnessDay_bar_copy.png',
							label: 'الرطوبة في م٣/م٣',
							markers: [0, 0.2, 0.4, 0.6, 0.8],
							preciseMarkers: true,
							bottom: 0.0,
							numOffset: 0,
							interval: 0.01,
							variation: 0.8,
							scalingFactor: 0.001,
							dps: 2
						}
					}
				]
			},
			{
				title: 'إجمالي الإنتاج الأولي - سماب(SMAP)',
				datasets: [
					{
						title: 'إجمالي الإنتاج الأولي - سماب(SMAP)',
						average: 'daily',
						externalId: 'smapGppMeanDayToday',
						urlParam: 'smap-gross-primary-production-daily',
						menuTitle: "المتوسط اليومي",
						description: 'يمثل إجمالي الإنتاج الأولي (GPP) إجمالي امتصاص النظام البيئي اليومي المقدر لثاني أكسيد الكربون في الغلاف الجوي الناتج عن عملية التمثيل الضوئي للنبات. ويتم التعبير عن ذلك بجرامات الكربون لكل متر مربع يتم تبادلها يوميًا، حيث تمثل المناطق الخضراء الداكنة في الرسم معدلات نمو نباتية أعلى.',
						missionIds: ['sc_smap'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'غرام لكل م٢ في اليوم',
							src: 'smapGppMeanDay_bar_copy.png',
							label: 'غرام لكل م٢ في اليوم',
							markers: [0, 3, 6, 9, 12],
							preciseMarkers: true,
							bottom: 0,
							numOffset: 0,
							interval: 0.2,
							variation: 12,
							scalingFactor: 0.01,
							dps: 2
						}
					}
				]
			}
		]
	},
	{
		value: 'salinity',
		title: 'الملوحة',
		description: 'مستويات الملوحة مهمة لسببين. أولا، تؤثر الملوحة على كثافة مياه البحر (المياه المالحة أكثر كثافة من المياه العذبة)، وبالتالي على دوران تيارات المحيط، والتي بدورها تنظم النقل الحراري. ثانياً، ترتبط الملوحة ارتباطاً وثيقاً بدورة المياه على الأرض وبكمية المياه العذبة التي تخرج وتدخل إلى المحيطات من خلال التبخر وهطول الأمطار.',
		sphereMapThumbSrc: 'smapsalinity.png',
		hideFromFooter: false,
		datasetGroups: [
			{
				title: 'الملوحة - سماب(SMAP)',
				datasets: [
					{
						title: 'ملوحة سطح البحر لمتوسط ٨ أيام - سماب (SMAP)',
						average: 'eight_day',
						externalId: 'smapSalinity8Day',
						urlParam: 'smap-salinity-8day',
						menuTitle: "متوسط ٨ أيام",
						description: 'تُظهر خريطة البيانات العالمية هذه محتوى الملح في مياه البحر كما تم قياسه بواسطة القمر الصناعي SMAP التابع لناسا. تتوافق الألوان الزرقاء والباردة مع المياه العذبة، بينما تتوافق الألوان الصفراء والدافئة مع المياه الأكثر ملوحة.',
						missionIds: ['sc_smap'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'وحدة ملوحةعملية',
							src: 'smapSalinity_bar_copy.png',
							label: 'وحدة ملوحة عملية',
							markers: [30,32,34,36,38,40],
							preciseMarkers: true,
							bottom: 30,
							numOffset: 0,
							interval: 0.06,
							variation: 10,
							scalingFactor: 0.01
						}
					}
				]
			}
		]
	},
	{
		value: 'ozone',
		title: "الأوزون",
		description: 'الأوزون هو غاز يتواجد بشكل طبيعي في غلافنا الجوي ويمكن أن يكون مفيدًا أو ضارًا للكائنات الحية على الأرض. وفي غلافنا الجوي يشكل درعًا يحمي الحياة من أشعة الشمس فوق البنفسجية الضارة. بين شهري أغسطس وأكتوبر، يمكن رؤية منطقة مستنزفة بشدة فوق القارة القطبية الجنوبية، والمعروفة باسم ثقب الأوزون.',
		shortDescription: "الأوزون هو غاز يحدث بشكل طبيعي في غلافنا الجوي و يمكن أن يكون مفيدًا أو مضرًا للكائنات الحية على الأرض. "		,
		sphereMapThumbSrc: 'OMPS_Ozone.png',
		datasetGroups: [
			{
				title: "إجمالي الأوزون اليومي (OMPS)",
				datasets: [
					{
						title: "إجمالي الأوزون اليومي (OMPS)",
						average: 'daily',
						externalId: 'omiOzoneToday',
						urlParam: 'mls-stratosphere-daily',
						dateRange: { start: '2016-07-01', end: '' },
						menuTitle: "المتوسط اليومي",
						description: "تُظهر خريطة البيانات توزيع الأوزون لهذا اليوم اعتبارًا من منتصف الليلة الماضية بتوقيت غرينيتش. تمثل المناطق الوردية والبيضاء تركيزات أعلى من طبقة الأوزون ، بينما تمثل الألوان الغير واضحة تركيزات أقل من طبقة الأوزون. وحدة دوبسون هي الوحدة الأكثر شيوعًا لقياس تركيز الأوزون. وحدة دوبسون واحدة تساوي طبقة من الأوزون النقي بسمك 0.01 ملم عند درجة حرارة 0 درجة مئوية و 1 ضغط جوي (atm). تمثل المساحات السوداء المساحات التي لم يتم جمع البيانات لها أو لا تتوفر للأداة معلومات كافية عنها."						,
						missionIds: ['sc_suomi_npp'],
						singleHtmlColorBar: true,
						readoutData: {
							//units: 'ديسون',
							src: 'ozone_bar_copy.png',
							label: 'وحدة ديسون',
							markers: [600,1900,3200],
							preciseMarkers: true,
							// bottom: 600,
							// numOffset: 0,
							// interval: 0.01111,
							// variation: 2600,
							// scalingFactor: 0.03906,
							// dps: 2
						}
					}
				],
				legacyDatasets: [
					{
						title: "إجمالي الأوزون اليومي  (OMI)",
						externalId: 'omiOzoneToday',
						dateRange: { start: '', end: '2016-06-30' },
						description: 'توضح خريطة البيانات هذه توزيع الأوزون على الأرض. تمثل المناطق الوردية والبيضاء تركيزات أعلى من الأوزون، بينما تمثل الألوان الزرقاء تركيزات أقل. وحدة دوبسون هي الوحدة الأكثر شيوعًا لقياس تركيز الأوزون. ووحدة دوبسون الواحدة تساوي طبقة من الأوزون النقي بسمك 0.01 ملم عند درجة حرارة 0 درجة مئوية وضغط 1 جو. تمثل المناطق السوداء المناطق التي لم يتم جمع البيانات فيها بعد أو التي لا تحتوي الأداة على معلومات كافية فيها.',
						missionIds: ['sc_aura']
					}
				]
			},
			{
				title: "موجات الميكروويف في حافة الغلاف الجوي - الأوزون (Ozone)",
				datasets: [
					{
						title: "موجات الميكروويف في حافة الغلاف الجوي - الأوزون (Ozone)"						,
						average: 'seven_day',
						externalId: 'o37Day',
						urlParam: 'mls-stratosphere-7day',
						description:' توضح هذه البيانات مستوى الأوزون الموجود في الستراتوسفير عند مستوى ضغط 46 هيكتوباسكال (46 مليبار). وهذا يتوافق مع حوالي 20-22 كيلومترًا فوق سطح الأرض. كما يمثل هذا المستوى عندما يكون "ثقب الأوزون" قريب من ذروته. يظهر ثقب الأوزون كل عام خلال فصل الربيع في نصف الكرة الجنوبي (من أغسطس إلى أكتوبر) فوق القطب الجنوبي بسبب تفاعل معقد مع جزيئات أول أكسيد الكلور. كما تمتص طبقة الأوزون الستراتوسفيرية من 15 إلى 50 كم من ضوء الأشعة فوق البنفسجية المنبعث من الشمس، مما يحمي الحياة على سطح الأرض.',
						menuTitle: "متوسط 7 أيام",
						missionIds: ['sc_aura'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'جزء من المليار/حجم',
							src: 'o3_bar_microwave.png',
							label: 'جزء من المليار في الحجم',
							markers: [1.6,2.2,2.8,3.4],
							preciseMarkers: true,
							bottom: 1.6,
							numOffset: 0,
							interval: 0.01111,
							variation: 1.8,
							scalingFactor: 0.03906,
							dps: 2,
						}
					},
					{
						title: "موجات الميكروويف في حافة الغلاف الجوي - الأوزون (Ozone)",
						average: 'daily',
						externalId: 'o3Today',
						urlParam: 'mls-stratosphere-daily',
						description:' توضح هذه البيانات مستوى الأوزون الموجود في الستراتوسفير عند مستوى ضغط 46 هيكتوباسكال (46 مليبار). وهذا يتوافق مع حوالي 20-22 كيلومترًا فوق سطح الأرض. كما يمثل هذا المستوى عندما يكون "ثقب الأوزون" قريب من ذروته. يظهر ثقب الأوزون كل عام خلال فصل الربيع في نصف الكرة الجنوبي (من أغسطس إلى أكتوبر) فوق القطب الجنوبي بسبب تفاعل معقد مع جزيئات أول أكسيد الكلور. كما تمتص طبقة الأوزون الستراتوسفيرية من 15 إلى 50 كم من ضوء الأشعة فوق البنفسجية المنبعث من الشمس، مما يحمي الحياة على سطح الأرض.'						,
						menuTitle: "المتوسط اليومي",
						missionIds: ['sc_aura'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'جزء من المليار/حجم',
							bottom: 1.6,
							numOffset: 0,
							interval: 0.01111,
							variation: 1.8,
							scalingFactor: 0.03906,
							dps: 2,
							src: 'o3_bar_microwave.png',
							markers: [1.6,2.2,2.8,3.4],
							preciseMarkers: true,
							label: 'جزء من المليار في الحجم'
						}
					}
				]
			}
		]
	},
	{
		value: 'water-vapor',
		title: "بخار الماء",
		description: 'بخار الماء هو ماء متبخر، وهو أحد الغازات الدفيئة المتواجدة بكثرة في غلافنا الجوي والذي يمثل ثلثي ظاهرة الاحتباس الحراري. سوف تمتص التركيزات الأعلى من بخار الماء في الغلاف الجوي المزيد من الطاقة الحرارية المشعة من سطح الأرض وتحبسها. يعتمد تطور السحب وهطول الأمطار أيضًا على بخار الماء.',
		shortDescription: "بخار الماء هو الماء المتبخر، وهو من الغازات الدفيئة و الوفيرة في الغلاف الجوي و يمثل ثلثي تأثير الاحتباس الحراري."		,
		sphereMapThumbSrc: 'AIRS_water_vap.png',
		datasetGroups: [
			{
				title: "إجمالي متوسط ترسيب بخار الماء - أيرس (AIRS)",
				datasets: [
					{
						title: "إجمالي متوسط ترسيب بخار الماء - أيرس (AIRS)",
						average: 'three_day',
						externalId: 'newVaporCol3Day',
						urlParam: 'airs-atmospheric-precipitable-total-3day',
						description: "تظهر خريطة البيانات توزيع ترسيب بخار الماء، الذي تم قياسه من سطح الأرض إلى قمة الغلاف الجوي على مدار الأيام الثلاثة الماضية. تشير المساحات ذات اللون الأزرق إلى تركيزات أعلى من ترسيب بخار الماء، بينما تشير المساحات الداكنة إلى تركيزات أقل من البخار.يتم عرض بخار الماء من حيث الماء الموجود في الغلاف الجوي فوق مساحة السطح عند ترسب جميع بخار الماء."						,
						menuTitle: "العمود الكلي - متوسط 3 أيام",	
						missionIds: ['sc_aqua'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'مليمتر',
							src: 'vaporCol_bar_copy.png',
							label: 'مليمتر',
							markers: [0, 10, 20, 30, 40, 50, 60, 70],
							preciseMarkers: true,
							bottom: 0,
							numOffset: 0,
							interval: 5.83333,
							variation: 70,
							scalingFactor: 1
						}
					},
					{
						title: "إجمالي معدل ترسيب بخار الماء ",
						average: 'daily',
						externalId: 'newVaporColToday',
						urlParam: 'airs-atmospheric-precipitable-total-daily',
						description: "تظهر خريطة البيانات هذه توزيع ترسيب بخار الماء، الذي تم قياسه من سطح الأرض إلى قمة الغلاف الجوي اعتبارًا من منتصف الليل في لندن الليلة الماضية. تشير المساحات ذات اللون الأزرق إلى تركيزات أعلى من ترسيب بخار الماء، بينما تشير المساحات الداكنة إلى تركيزات أقل من البخار. يتم عرض ترسيب بخار الماء من حيث ملليمتر من الماء الموجود في الغلاف الجوي فوق مساحة السطح عند ترسب جميع بخار الماء.تمثل المساحات السوداء المساحات التي لم يتم جمع البيانات لها أو لا تتوفر للأداة معلومات كافية عنها."						,
						menuTitle: "العمود الكلي - المتوسط اليومي",
						missionIds: ['sc_aqua'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'مليمتر',
							src: 'vaporCol_bar_copy.png',
							label: 'مليمتر',
							markers: [0, 10, 20, 30, 40, 50, 60, 70],
							preciseMarkers: true,
							bottom: 0,
							numOffset: 0,
							interval: 5.83333,
							variation: 70,
							scalingFactor: 1
						}
					},
					{
						title: " المتوسط الشهري لمعدل ترسيب بخار الماء ",
						average: 'month',
						externalId: 'newVaporColMonthly',
						urlParam: 'airs-atmospheric-precipitable-total-monthly',
						description: "يعرف ترسيب بخار الماء بأنه عمق الماء في أحد أعمدة الغلاف الجوي في حالة ترسب الماء على صورة أمطار. تظهر الخريطة المتوسط الشهري لمعدل ترسيب بخار الماء, الذي تم قياسه بواسطة جهاز AIRS. تشير المساحات الزرقاء والخضراء الى تركيزات أعلى من ترسيب بخار الماء , بينما يشير اللون الأزرق إلى تركيزات أقل. "						,
						menuTitle: " العمود الكلي - المتوسط الشهري",
						missionIds: ['sc_aqua'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'مليمتر',
							src: 'vaporCol_bar_copy.png',
							label: 'مليمتر',
							markers: [0, 10, 20, 30, 40, 50, 60, 70],
							preciseMarkers: true,
							bottom: 0,
							numOffset: 0,
							interval: 5.83333,
							variation: 70,
							scalingFactor: 1
						}
					},
					{
						title: "متوسط ترسيب بخار الماء من ١٠,٠٠٠ الى ١٨,٠٠٠ قدم ",
						average: 'three_day',
						externalId: 'vaporUpper3Day',
						urlParam: 'airs-troposphere-precipitable-total-3day',
						description: "تُظهر خريطة البيانات هذه توزيع بخار الماء الذي تم قياسه من منتصف طبقة التروبوسفير (10000-18000 قدم) على مدار الأيام الثلاثة الماضية. تشير المساحات ذات اللون الأزرق إلى تركيزات أعلى من بخار الماء، بينما تشير المساحات الداكنة إلى تركيزات أقل من البخار. يتم عرض بخار الماء من حيث ملليمتر من الماء الموجود في الغلاف الجوي فوق مساحة السطح عند ترسب جميع بخار الماء. "						,
						menuTitle: "العمود العلوي - متوسط 3 أيام",
						missionIds: ['sc_aqua'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'مليمتر',
							src: 'vaporUpper_bar_copy.png',
							label: 'مليمتر',
							markers: [0, 5, 10, 15, 20],
							preciseMarkers: true,
							bottom: 0,
							numOffset: 0,
							interval: 1.66667,
							variation: 20,
							scalingFactor: 1
						}
					},
					{
						title: "متوسط ترسيب بخار الماء من ١٠,٠٠٠ الى ١٨,٠٠٠ قدم "						,
						average: 'daily',
						externalId: 'vaporUpperToday',
						urlParam: 'airs-troposphere-precipitable-total-daily',
						description: "تُظهر خريطة البيانات هذه توزيع بخار الماء الذي تم قياسه من منتصف طبقة التروبوسفير (10000-18000 قدم) اعتبارًا من منتصف الليل في لندن الليلة الماضية. تشير المساحات ذات اللون الأزرق إلى تركيزات أعلى من بخار الماء، بينما تشير المساحات الداكنة إلى تركيزات أقل من البخار. يتم عرض بخار الماء من حيث ملليمتر من الماء الموجود في الغلاف الجوي فوق مساحة السطح عند ترسب جميع بخار الماء. تمثل المساحات السوداء المساحات التي لم يتم جمع البيانات لها أو لا تتوفر للأداة معلومات كافية عنها."						,
						menuTitle: "العمود العلوي - المتوسط اليومي"	,
						missionIds: ['sc_aqua'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'مليمتر',
							src: 'vaporUpper_bar_copy.png',
							label: 'مليمتر',
							markers: [0, 5, 10, 15, 20],
							preciseMarkers: true,
							bottom: 0,
							numOffset: 0,
							interval: 1.66667,
							variation: 20,
							scalingFactor: 1
						}
					}
				]
			}
		]
	},
	{
		value: 'gravity-field-map',
		title: 'مخزون المياة',
		description: 'إن التغيرات في كيفية توزيع كتلة الماء داخل الغلاف الجوي للأرض بينه وبين، المحيطات، والمياه الجوفية، والصفائح الجليدية، تشكل مؤشرات أساسية للديناميكيات واسعة النطاق للكوكب. ومن خلال تتبع التغيرات الشهرية في كتلة سطح الأرض عبر الكوكب من خلال الجاذبية، توفر الأقمار الصناعية ملاحظات مفصلة للتغيرات في الصفائح الجليدية والأنهار الجليدية، وتخزين المياه بالقرب من السطح وتحت الأرض، فضلاً عن التغيرات في مستوى سطح البحر والتيارات المحيطية. وهذا يوفر رؤية عالمية متكاملة لكيفية تغير دورة المياه على الأرض وتوازن الطاقة - وهي قياسات لها تطبيقات مهمة للحياة اليومية',
		shortDescription: 'من خلال قياس التغيرات في كتلة سطح الأرض من خلال الجاذبية، توفر الأقمار الصناعية ملاحظات مفصلة عن التغيرات في الصفائح الجليدية والأنهار الجليدية، وتخزين المياه السطحية والجوفية، والتغيرات في مستوى سطح البحر والتيارات المحيطية',
		sphereMapThumbSrc: 'gravity_field.png',
		datasetGroups: [
			{
				title: '(GRACE-FO) خريطة تخزين المياه  قريس - اف او',
				datasets: [
					{
						title: ' (GRACE-FO) خريطة تخزين المياه  قريس - اف او',
						menuTitle: 'المتوسط الشهري',
						average: 'month',
						externalId: 'newGraceMonthly',
						urlParam: 'water-storage-monthly',
						dateRange: { start: '2017-07-01', end: '' },
						description: 'تشير المناطق الزرقاء على الخريطة إلى الأماكن التي زاد فيها تخزين المياه، في حين تشير المناطق الحمراء إلى الأماكن التي انخفض فيها خلال فترة زمنية تزيد عن 20 عامًا من القياسات',
						missionIds: ['sc_grace_fo1', 'sc_grace_fo2'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'سم',
							src: 'grace_bar_copy.png',
							label: 'سم',
							markers: [-25, -10, 0, 10, 25],
							preciseMarkers: true,
							bottom: -25,
							numOffset: -1000,
							interval: 0.20408,
							variation: 50,
							scalingFactor: 1
						}
					}
				],
				legacyDatasets: [
					{
						title: 'خريطة مجال الجاذبية للقمر قريس',
						menuTitle: 'المتوسط الشهري',
						externalId: 'newGraceMonthly',
						dateRange: { start: '', end: '2017-06-30' },
						description: ' تُظهر هذه الخريطة التغيرات الشهرية في مجال الجاذبية بالنسبة إلى المتوسط استنادًا إلى بيانات القمر قريس، تشير المناطق الزرقاء على الخريطة إلى المناطق التي أصبح فيها مجال جاذبية الكوكب أقوى قليلاً، بينما تشير المناطق الحمراء إلى المناطق التي أصبح فيها مجال الجاذبية أضعف قليلاً. يتم عرض مجال الجاذبية من حيث تغير ارتفاع الماء المكافئ بالسنتيمتر',
						missionIds: ['sc_grace']
					}
				]
			}
		]
	},
	{
		value: 'nitrous-oxide',
		title: 'أكسيد النيتروجين',
		description: 'وهو غاز دفيء قوي تنتجه الممارسات الزراعية، ويتم إطلاق أكسيد النيتروجين أثناء إنتاج واستخدام الأسمدة التجارية والعضوية. يأتي أكسيد النيتروجين أيضًا من حرق الوقود الأحفوري وحرق النباتات، وقد زاد بنسبة 18٪ في المائة عام الماضية.',
		sphereMapThumbSrc: 'nitrousoxide.png',
		datasetGroups: [
			{
				title: 'مسبار أطراف الميكرويف لأكسيد النيتروجين',
				datasets: [
					{
						title: 'مسبار أطراف الميكرويف لأكسيد النيتروجين',
						average: 'seven_day',
						externalId: 'n2O7Day',
						urlParam: 'mls-stratosphere-n2o-7day',
						description: 'عند الارتفاعات الموضحة هنا (حوالي 20 كم/12.4 ميل)، يعتبر N2O غازًا طويل العمر يُستخدم بشكل متكرر كتتبع لحركات الهواء في طبقة الستراتوسفير.',
						menuTitle: 'متوسط ٧ أيام',
						missionIds: ['sc_aura'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'جزء من المليار/حجم',
							src: 'n2O_bar_copy.png',
							label: 'جزء من المليار من حيث الحجم',
							markers: [20, 80, 140, 200, 260, 320],
							preciseMarkers: true,
							bottom: 20,
							numOffset: 0,
							interval: 14.2857,
							variation: 300,
							scalingFactor: 0.03906
						}
					},
					{
						title: 'مسبار أطراف الميكرويف لأكسيد النيتروجين',
						average: 'daily',
						externalId: 'n2OToday',
						urlParam: 'mls-stratosphere-n2o-daily',
						description: 'عند الارتفاعات الموضحة هنا (حوالي 20 كم/12.4 ميل)، يعتبر N2O غازًا طويل العمر يُستخدم بشكل متكرر كتتبع لحركات الهواء في طبقة الستراتوسفير.',
						menuTitle: 'المتوسط اليومي',
						missionIds: ['sc_aura'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'جزء من المليار/حجم',
							src: 'n2O_bar_copy.png',
							label: 'جزء من المليار من حيث الحجم',
							markers: [20, 80, 140, 200, 260, 320],
							preciseMarkers: true,
							bottom: 20,
							numOffset: 0,
							interval: 14.2857,
							variation: 300,
							scalingFactor: 0.03906
						}
					}
				]
			}
		]
	},
	{
		value: 'hydrochloric-acid',
		title: 'حمض الهيدروكلوريك',
		description: 'حمض الهيدروكلوريك (HCl) الموجود في طبقة الستراتوسفير (من 15 إلى 50 كيلومترًا أو 9.3 إلى 31 ميلًا من الارتفاع) هو غاز ينتج عن تحلل غازات مصدر التروبوسفير، وخاصة مركبات الكلوروفلوروكربون (CFCs). لا توجد مركبات الكربون الكلورية فلورية في الطبيعة، وهي مصنوعة بالكامل من قبل البشر.',
		sphereMapThumbSrc: 'hyrdochloricacid.png',
		hideFromFooter: false,
		datasetGroups: [
			{
				title: 'مسبار أطراف الميكروويف لحمض الهيدروكلوريك ',
				datasets: [
					{
						title: 'مسبار أطراف الميكروويف لحمض الهيدروكلوريك',
						average: 'seven_day',
						externalId: 'hCl7Day',
						urlParam: 'mls-stratosphere-hcl-7day',
						description: 'يتم تخزين معظم ذرات الكلور المنطلقة من مركبات الكربون الكلورية فلورية في خزان حمض الهيدروكلوريك هذا على ارتفاعات عالية (قرابة 50 كيلومترًا، أو 31 ميلًا)، ويوفر حمض الهيدروكلوريك الموجود في طبقة الستراتوسفير العليا مقياسًا لمحتوى الكلور الإجمالي في الغلاف الجوي. بالنسبة لمنطقة الارتفاع (حوالي 20 كيلومترًا، أو 12.4 ميلًا) الموضحة في هذه الخرائط، ينخفض حمض الهيدروكلوريك عمومًا فوق المناطق القطبية خلال فصل الشتاء (من ديسمبر إلى فبراير فوق القطب الشمالي، ومن يونيو إلى أغسطس فوق القارة القطبية الجنوبية) بسبب التفاعلات الكيميائية التي تحدث على سطح القطب الشمالي. السحب الستراتوسفيرية القطبية (PSCs)، حيث يتم إطلاق ذرات الكلور الأكثر تفاعلاً وتشارك في تدمير الأوزون.',
						menuTitle: 'متوسط ٧ أيام',
						missionIds: ['sc_aura'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'جزء من المليار/حجم',
							src: 'hCl_bar_copy.png',
							label: 'جزء من المليار من حيث الحجم',
							markers: [0, 0.3, 0.6, 0.9, 1.2, 1.5],
							preciseMarkers: true,
							bottom: 0,
							numOffset: 0,
							interval: 0.071429,
							variation: 1.5,
							scalingFactor: 0.003906
						}
					},
					{
						title: 'مسبار أطراف الميكروويف لحمض الهيدروكلوريك',
						average: 'daily',
						externalId: 'hClToday',
						urlParam: 'mls-stratosphere-hcl-daily',
						description: 'يتم تخزين معظم ذرات الكلور المنطلقة من مركبات الكربون الكلورية فلورية في خزان حمض الهيدروكلوريك هذا على ارتفاعات عالية (قرابة 50 كيلومترًا، أو 31 ميلًا)، ويوفر حمض الهيدروكلوريك الموجود في طبقة الستراتوسفير العليا مقياسًا لمحتوى الكلور الإجمالي في الغلاف الجوي. بالنسبة لمنطقة الارتفاع (حوالي 20 كيلومترًا، أو 12.4 ميلًا) الموضحة في هذه الخرائط، ينخفض حمض الهيدروكلوريك عمومًا فوق المناطق القطبية خلال فصل الشتاء (من ديسمبر إلى فبراير فوق القطب الشمالي، ومن يونيو إلى أغسطس فوق القارة القطبية الجنوبية) بسبب التفاعلات الكيميائية التي تحدث على سطح القطب الشمالي. السحب الستراتوسفيرية القطبية (PSCs)، حيث يتم إطلاق ذرات الكلور الأكثر تفاعلاً وتشارك في تدمير الأوزون.',
						menuTitle: 'المتوسط اليومي',
						missionIds: ['sc_aura'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'جزء من المليار/حجم',
							src: 'hCl_bar_copy.png',
							label: 'جزء من المليار من حيث الحجم',
							markers: [0, 0.3, 0.6, 0.9, 1.2, 1.5],
							preciseMarkers: true,
							bottom: 0,
							numOffset: 0,
							interval: 0.071429,
							variation: 1.5,
							scalingFactor: 0.003906
						}
					}
				]
			}
		]
	},
	{
		value: 'chlorine-monoxide',
		title: 'أول أكسيد الكلور',
		description: 'أول أكسيد الكلور (ClO) هو الشكل السائد للكلور التفاعلي في طبقة الستراتوسفير وهو العامل الرئيسي لتدمير الأوزون الستراتوسفيري.',
		sphereMapThumbSrc: 'chlorinemonoxide.png',
		hideFromFooter: false,
		datasetGroups: [
			{
				title: 'مسبار أطراف الميكروويف لأول أكسيد الكربون',
				datasets: [
					{
						title: 'مسبار أطراف الميكروويف لأول أكسيد الكربون',
						average: 'seven_day',
						externalId: 'clO7Day',
						urlParam: 'mls-stratosphere-clo-7day',
						description: 'تقع الخرائط الموضحة هنا على ارتفاع حوالي 20 كيلومترًا (12.4 ميلًا) في الغلاف الجوي. المصدر الرئيسي للكلور الستراتوسفيري هو مركبات الكلوروفلوروكربون، أو مركبات الكربون الكلورية فلورية، وهي مركبات كيميائية تتكون من الكلور والفلور والكربون المنبعثة من الأنشطة البشرية على السطح. بعد وصولها إلى طبقة الستراتوسفير العليا (في المقام الأول عن طريق ارتفاع مياه القاع إلى السطح في المناطق الاستوائية)، يتم تكسير مركبات الكربون الكلورية فلورية بواسطة الأشعة فوق البنفسجية عالية الطاقة، مما يؤدي إلى تحرير الكلور الذي يمكن تحويله لاحقًا إلى أشكال تفاعلية مثل ClO.',
						menuTitle: 'متوسط ٧ أيام',
						missionIds: ['sc_aura'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'جزء من المليار/حجم',
							src: 'clo_bar_copy.png',
							label: 'جزء من المليار من حيث الحجم',
							markers: [0, 0.2, 0.4, 0.6, 0.8, 1.0],
							preciseMarkers: true,
							bottom: 0,
							numOffset: 0,
							interval: 0.085714,
							variation: 1,
							scalingFactor: 0.003906
						}
					},
					{
						title: 'مسبار أطراف الميكروويف لأول أكسيد الكربون',
						average: 'daily',
						externalId: 'clOToday',
						urlParam: 'mls-stratosphere-clo-daily',
						description: 'تقع الخرائط الموضحة هنا على ارتفاع حوالي 20 كيلومترًا (12.4 ميلًا) في الغلاف الجوي. المصدر الرئيسي للكلور الستراتوسفيري هو مركبات الكلوروفلوروكربون، أو مركبات الكربون الكلورية فلورية، وهي مركبات كيميائية تتكون من الكلور والفلور والكربون المنبعثة من الأنشطة البشرية على السطح. بعد وصولها إلى طبقة الستراتوسفير العليا (في المقام الأول عن طريق ارتفاع مياه القاع إلى السطح في المناطق الاستوائية)، يتم تكسير مركبات الكربون الكلورية فلورية بواسطة الأشعة فوق البنفسجية عالية الطاقة، مما يؤدي إلى تحرير الكلور الذي يمكن تحويله لاحقًا إلى أشكال تفاعلية مثل ClO.',
						menuTitle: 'المتوسط اليومي',
						missionIds: ['sc_aura'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'جزء من المليار/حجم',
							src: 'clo_bar_copy.png',
							label: 'جزء من المليار من حيث الحجم',
							markers: [0, 0.2, 0.4, 0.6, 0.8, 1.0],
							preciseMarkers: true,
							bottom: 0,
							numOffset: 0,
							interval: 0.085714,
							variation: 1,
							scalingFactor: 0.003906
						}
					}
				]
			}
		]
	},
	{
		value: 'nitric-acid',
		title: 'حمض النيتريك',
		description: 'يلعب حمض النيتريك (HNO3) عدة أدوار محورية في عمليات التحكم في استنفاد الأوزون الستراتوسفيري. وهو مكون رئيسي في السحب الستراتوسفيرية القطبية (PSCs) التي تتشكل في درجات الحرارة المنخفضة للغاية في الشتاء القطبي، والتي تطلق بعد ذلك ذرات الكلور التفاعلية من جزيئات مثل حمض الهيدروكلوريك (HCl).',
		sphereMapThumbSrc: 'nitricacid.png',
		hideFromFooter: false,
		datasetGroups: [
			{
				title: 'مسبار أطراف الميكروويف لحمض النيتريك',
				datasets: [
					{
						title: 'مسبار أطراف الميكروويف لحمض النيتريك',
						average: 'seven_day',
						externalId: 'hNO37Day',
						urlParam: 'mls-stratosphere-hno3-7day',
						description: 'تظهر هذه الخرائط على ارتفاع 20 كيلومترًا (12.4 ميلًا) في الغلاف الجوي. توفر جزيئات PSC أسطحًا تحدث عليها التفاعلات الكيميائية، حيث تحول الكلور من أنواع الخزان (مثل حمض الهيدروكلوريك، أو HCl) إلى الأشكال شديدة التفاعل التي تدمر الأوزون (أول أكسيد الكلور، أو ClO).',
						menuTitle: 'متوسط ٧ أيام',
						missionIds: ['sc_aura'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'جزء من المليار/حجم',
							src: 'hno3_bar_copy.png',
							label: 'جزء من المليار من حيث الحجم',
							markers: [0, 3, 6, 9, 12],
							preciseMarkers: true,
							bottom: 0,
							numOffset: 0,
							interval: 0.571429,
							variation: 12,
							scalingFactor: 0.003906
						}
					},
					{
						title: 'مسبار أطراف الميكروويف لحمض النيتريك',
						average: 'daily',
						externalId: 'hNO3Today',
						urlParam: 'mls-stratosphere-hno3-daily',
						description: 'تظهر هذه الخرائط على ارتفاع 20 كيلومترًا (12.4 ميلًا) في الغلاف الجوي. توفر جزيئات PSC أسطحًا تحدث عليها التفاعلات الكيميائية، حيث تحول الكلور من أنواع الخزان مثل حمض الهيدروكلوريك (HCl) إلى الأشكال شديدة التفاعل التي تدمر الأوزون (أول أكسيد الكلور، أو ClO).',
						menuTitle: 'المتوسط اليومي',
						missionIds: ['sc_aura'],
						singleHtmlColorBar: true,
						readoutData: {
							units: 'جزء من المليار/حجم',
							src: 'hno3_bar_copy.png',
							label: 'جزء من المليار من حيث الحجم',
							markers: [0, 3, 6, 9, 12],
							preciseMarkers: true,
							bottom: 0,
							numOffset: 0,
							interval: 0.571429,
							variation: 12,
							scalingFactor: 0.003906
						}
					}
				]
			}
		]
	}
];


let currentId = 1;

// Assign unique ID's for vital data, groups and datasets.
const VITALS_DATA = VITALS.map(vital => ({
	id: currentId++,
	...vital,
	datasetGroups: vital.datasetGroups.map(group => ({
		id: currentId++,
		...group,
		datasets: group.datasets.map(dataset => ({
			id: currentId++,
			...dataset
		}))
	}))

}));


export default VITALS_DATA;
