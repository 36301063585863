/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	Color,
	Entity,
	Quaternion,
	Reader,
	ShaderChunkLogDepth,
	SpoutComponent,
	THREE,
	ThreeJsHelper,
	Vector2,
	Vector3
} from '../../internal';

const eclipJ2000ToJ200Rotation = new Quaternion(0.9791532214288992, 0.2031230389823101, 0, 0);

// Star used in the Starfield component.
class Star {
	constructor() {
		this.mag = 0;
		this.absMag = 0;
		this.color = new Color();
		this.position = new Vector3();
		this.particle = null;
	}
}

/**
 * The starfield component. Loads a star file.
 * @todo Document the star file format.
 */
export class StarfieldComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The database URL.
		 * @type {string}
		 * @private
		 */
		this._url = '';

		// Set the radius to the whole universe.
		this.__setRadius(1e24);
	}

	/**
	 * Gets the url of the star database.
	 * @returns {string}
	 */
	getUrl() {
		return this._url;
	}

	/**
	 * Sets the url.
	 * @param {string} url - the url to set
	 */
	setUrl(url) {
		this._url = url;
		this.resetResources();
	}

	/**
	 * Prepare the component for rendering.
	 * @param {CameraComponent} camera
	 * @override
	 * @internal
	 */
	__prepareForRender(camera) {
		// If the camera is a Spout camera, use Spout for the render size.
		const renderSize = Vector2.pool.get();
		if (camera.getType() === 'spout') {
			const spoutComponent = /** @type {SpoutComponent} */(camera);
			renderSize.set(spoutComponent.getRenderWidth(), spoutComponent.getRenderWidth() * 0.5);
		}
		// Otherwise use the viewport size.
		else {
			renderSize.copy(camera.getViewport().getBounds().size);
		}

		const resolutionFactor = Math.sqrt(Math.max(renderSize.x, renderSize.y) * window.devicePixelRatio) / 60;
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'resolutionFactor', resolutionFactor);
		Vector2.pool.release(renderSize);

		// Set the Three.js object position the entity's camera-space position.
		ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects()[0], this.getEntity(), camera);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	async __loadResources() {
		// Load the stars from the database.
		const stars = await this._loadStars();

		// Check if the component has since been destroyed.
		if (this.isDestroyed()) {
			return;
		}

		// Setup the Three.js material.
		const material = new THREE.ShaderMaterial({
			vertexShader: StarfieldComponent.vertexShader,
			fragmentShader: StarfieldComponent.fragmentShader,
			transparent: true,
			blending: THREE.AdditiveBlending,
			depthWrite: false,
			uniforms: {
				resolutionFactor: new THREE.Uniform(1.0),

				...ShaderChunkLogDepth.ThreeUniforms
			}
		});
		ThreeJsHelper.setupLogDepthBuffering(material);
		this.getThreeJsMaterials().push(material);

		// Setup the Three.js geometry.
		const threeJsGeometry = new THREE.BufferGeometry();
		threeJsGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(0), 3));
		threeJsGeometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array(0), 4));
		threeJsGeometry.setIndex(new THREE.BufferAttribute(new Uint16Array(0), 1));

		// Setup the Three.js object.
		const threeJsObject = new THREE.Points(threeJsGeometry, material);
		ThreeJsHelper.setupObject(this, threeJsObject);
		threeJsObject.renderOrder = -2; // Make it render before other transparent objects.
		this.getThreeJsObjects().push(threeJsObject);

		// Update the particle geometry from the star database.
		const meshPositions = new Float32Array(stars.length * 3);
		const meshColors = new Float32Array(stars.length * 4);
		const meshIndices = new Uint16Array(stars.length);
		for (let i = 0; i < stars.length; i++) {
			const star = stars[i];
			meshPositions[i * 3 + 0] = star.position.x;
			meshPositions[i * 3 + 1] = star.position.y;
			meshPositions[i * 3 + 2] = star.position.z;
			meshColors[i * 4 + 0] = star.color.r;
			meshColors[i * 4 + 1] = star.color.g;
			meshColors[i * 4 + 2] = star.color.b;
			meshColors[i * 4 + 3] = star.absMag; // The alpha channel is for the absolute magnitude, used in the shader.
			meshIndices[i] = i;
		}

		ThreeJsHelper.setVertices(threeJsGeometry, 'position', meshPositions);
		ThreeJsHelper.setVertices(threeJsGeometry, 'color', meshColors);
		ThreeJsHelper.setIndices(threeJsGeometry, meshIndices);
	}

	/**
	 * Unloads the resources.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		ThreeJsHelper.destroyAllObjectsAndMaterials(this);
	}

	/**
	 * Loads the star database.
	 * @returns {Promise<Star[]>}
	 * @private
	 */
	_loadStars() {
		// The promise that is used to tell when the animdef is loaded.
		return this.getEntity().getScene().getEngine().getDownloader().download(this._url, true, -this.getEntity().getLeastCameraDepth()).then((download) => {
			if (download.status === 'cancelled') {
				return Promise.resolve([]);
			}
			else if (download.status === 'failed') {
				return Promise.reject(new Error('Failed to load starfield component file "' + download.url + '": ' + download.errorMessage));
			}
			if (!(download.content instanceof ArrayBuffer)) {
				return Promise.reject(new Error('Failed to load starfield component file "' + download.url + '": Not a binary file.'));
			}
			const reader = new Reader(download.content);
			const numStars = reader.readInt32();
			/** @type {Star[]} */
			const stars = [];
			for (let i = 0; i < numStars; i++) {
				const star = new Star();
				star.mag = reader.readFloat32();
				star.absMag = reader.readFloat32();
				star.color.r = reader.readByte() / 255;
				star.color.g = reader.readByte() / 255;
				star.color.b = reader.readByte() / 255;
				star.color.div(star.color, star.color.max());
				star.position.y = -reader.readFloat32();
				star.position.z = reader.readFloat32();
				star.position.x = reader.readFloat32();
				star.position.rotate(eclipJ2000ToJ200Rotation, star.position);
				stars.push(star);
			}

			return stars;
		});
	}
}

StarfieldComponent.vertexShader = `
	#define PI 3.1415926538

	attribute vec4 color;
	varying vec4 fColor;

	uniform float resolutionFactor;

	${ShaderChunkLogDepth.VertexHead}

	// Returns the watts per km^2.
	float absoluteMagnitudeToFlux(float absoluteMagnitude, float distance) {
		float luminosityInWatts = 3.0128e28 * pow(10.0, absoluteMagnitude / -2.5);
		return luminosityInWatts / (4.0 * PI * distance * distance);
	}

	void main() {
		vec4 viewPosition = modelViewMatrix * vec4(position, 1.0);
		gl_Position = projectionMatrix * viewPosition;
		gl_Position.w = viewPosition.y;
		fColor = color;

		// Get the flux and brightness of the star at the camera's point.
		float absMag = color.a;
		float distance = length(viewPosition);
		float flux = absoluteMagnitudeToFlux(absMag, distance);
		float brightness = 2.0 * log(1.0 + flux * 1e4);

		// Adjust the color and size so that it is visually pleasing.
		fColor.a = clamp(brightness * resolutionFactor, 0.05, 1.0);
		gl_PointSize = clamp(brightness * 4.0 * resolutionFactor, 5.0, 24.0);

		// If it is too close, fade the star.
		fColor.a = mix(0.0, fColor.a, clamp((distance - 1.0e12) / 9.0e12, 0.0, 1.0));

		${ShaderChunkLogDepth.Vertex}
	}`;

StarfieldComponent.fragmentShader = `
	precision highp float;

	varying vec4 fColor;

	${ShaderChunkLogDepth.FragmentHead}

	void main(void) {
		float distanceFromEdge = clamp(1.0 - 2.0 * length(gl_PointCoord - vec2(0.5, 0.5)), 0.0, 1.0);
		float a = pow(distanceFromEdge, 5.0);
		gl_FragColor.rgb = fColor.rgb;
		gl_FragColor.a = fColor.a * a;

		${ShaderChunkLogDepth.Fragment}
	}`;
