/**
	We are using the library Valtio to create global state proxies -> https://github.com/pmndrs/valtio
	Some organization patterns -> https://github.com/pmndrs/valtio/wiki/How-to-organize-actions

	A GlobalState class that is instantiated by passing an initial state proxy. It provides methods to get, mutate and subscribe to the state.

	Reasons:
	- Can access state both from inside react components and outside in vanilla js (good for Pioneer callbacks)
	- Lightweight (2.3kB gzipped)
	- Better rendering efficiency with targetted state subscription capabilities

	Notes:
	- From within react components, we can use the useSnapshot hooks. Look inside events_overlay for an example.
	- From outside react components, it's slightly more imperative, we need to explicitly set the first state using a state snapshot,
	then subscribe to the state and attach a callback for when it changes. See the scene_manger for an example.

	Future work:
	Using the 'ref' method, it's possible to hold global references large nested object without tracking them.
	We are already storing the 'get' method to access managers, perhaps _scene and _pioneer would also be useful.
	It would prevent drilling them everywhere as arguments.
*/

import { proxy, subscribe, snapshot } from 'valtio/vanilla';
import { subscribeKey } from 'valtio/utils';

//merge questions lots of event based items removed from this list 
import {
	LIGHT_STATUS,
	CITY_NAME_STATUS,
	GROUND_TRACK_STATUS,
	EVENTS_SHOW_LATEST,
	POI_SHOW_ON_GLOBE,
	GMAP_SHOW_ON_GLOBE,
	POI_YEAR_INDEX,
	GMAP_YEAR_INDEX,
	POI_CATEGORY_INDEX,
	GMAP_CATEGORY_INDEX,
	POI_SHOW_LATEST,
	GMAP_SHOW_LATEST,
	POI_CATEGORIES,
  	GMAP_CATEGORIES,
	SPOUT_ENABLE,
	SPOUT_RENDER_WIDTH,
	SPOUT_PROJECT_TO_GLOBE,
	SPOUT_FONT_SIZE,
	SPOUT_LON_ANGLE_OFFSET,
	SPOUT_ALIGN_TO_NORTH_POLE
} from '../config/constants';

/**
 * @ignore
 * @typedef {import('valtio/vanilla').proxy} Proxy
 */


/**
 * Global state class.
 */
class GlobalState {
	/**
	 * Constructor.
	 * @param {Proxy} proxyState
	 */
	constructor(proxyState) {
		this._state = proxyState;
	}

	/**
	 * Subscribes to any state changes, calls callback passing state as arg.
	 * @param {function} callback
	 * @returns {function} unsubscribe function
	 */
	subscribeAll(callback, notifyInSync = false) {
		return subscribe(this._state, () => callback(snapshot(this._state)), notifyInSync);
	}

	/**
	 * Subscribe to a specific item (key) within this global state.
	 * When that item's value changes, pass it into, and call the callback
	 * @param {string} item
	 * @param {function} callback
	 * @returns {function} unsubscribe function
	 */
	subscribeTo(item, callback, notifyInSync = false) {
		return this._state[item] !== undefined ? subscribeKey(this._state, item, callback, notifyInSync) : false;
	}

	/**
	 * Sets the state.
	 * @param {object} name
	 */
	setGlobalState(stateObj) {
		Object.keys(stateObj).forEach(itemName => {
			if (this._state[itemName] !== undefined) {
				this._state[itemName] = stateObj[itemName];
			} else {
				console.error(`Cannot setGlobalState for ${itemName}. It does not exist.`);
			}
		});
	}

	/**
	 * Sets the state.
	 * @param {object} name
	 */
	/* setGlobalStatePoI(stateObj) {
		Object.keys(stateObj).forEach(itemName => {
			if (this._state[itemName] !== undefined) {
				this._state[itemName] = stateObj[itemName];
			} else {
				console.error(`Cannot setGlobalStatePoI for ${itemName}. It does not exist.`);
			}
		});
	}*/

	/**
	 * Returns snapshot of the current _state. Used outside react components.
	 */
	get stateSnapshot() {
		return snapshot(this._state);
	}

	/**
	 * Returns snapshot of the current _state. Used outside react components.
	 */
	/* get stateSnapshotPoI() {
		return snapshot(this._state);
	}*/

	/**
	 * Returns current _state. Used inside react components (in conjunction with useSnapshot hook)
	 */
	get state() {
		return this._state;
	}

	/**
	 * Returns current _state. Used inside react components (in conjunction with useSnapshot hook)
	 */
	/* get statePoI() {
		return this._state;
	}*/
}

/**
 * Define proxies with default states.
 */
const appProxy = proxy({
	isSceneReady: false,

	isMobile: null,
	isWindows: null,
	is2k: null,
	is4k: null,
	isLandscape: null,
	isTouch: null,
	canHover: null,

	queries: {},
	params: {},
	validURL: null
});

const uiProxy = proxy({
	isDetailPanelOpen: null,
	controlsMenu: null,
	showSplashScreen: true,
	overlayType: null,
	loading: null,
	validData: true,
	hideExternalLinks: false,
	hideFullScreenToggle: false,
	noKeyboard: false,
	/**
	 * @property {string} videoMobileBackTo can be 'vitals' or 'detail'
	 */
	videoMobileBackTo: null,
	modal: {
		isVisible: false,
		startRange: null,
		endRange: null,
		numMissingDatasets: null,
		onClose: null
	},
	isDateTooltipOpen: false
});

const eventsProxy = proxy({
	eventYears: null,
	events: {},
	latestEvents: null,
	currentEvent: null,
	showLatest: EVENTS_SHOW_LATEST,
	eventsVisibleOnGlobe: null,
	yearIndex: null,
	categoryIndex: 0,
	mediaIndex: 0,
	animatedDate: null
});

const previewProxy = proxy({
	previewEventData: null,
	previewError: null
});

const lightProxy = proxy({
	fill: LIGHT_STATUS
});

const layersProxy = proxy({
	blueMarble: false,
	cityNames: CITY_NAME_STATUS,
	groundTracks: GROUND_TRACK_STATUS,
	groundTrackStartTime: null
});

const timeProxy = proxy({
	isTimePlaying: null,
	timeRate: null,
	formattedTimeRate: {},
	isRealTime: false
});

const datasetProxy = proxy({
	datasetManifests: {},
	currentVS: null,
	allDatasets: {},
	currentDataset: null,
	datasetHasAnimation: null,
	isDatasetPaused: null,
	isDatasetLoading: null,
	currentIndex: null,
	animationDates: { start: null, end: null },
	isVisibleEarth: null,
	veTextureType: null,
	isTextureLoaded: null
});

const datareadProxy = proxy({
	colorRectStyle: null,
	readoutData: null,
	isCelcius: false,
	isColorBarShowing: null
});

const spacecraftProxy = proxy({
	/**
	 * @property {Array<object>} spacecraftList
	 * List of spacecraft downloaded from /earth/api/mission.json
	 */
	spacecraftList: null,

	/**
	 * @property {object} currentSpacecraft
	 * An item in the spacecraftList array. This can represent a single spacecraft or a constellation.
	 */
	currentSpacecraft: null,

	/**
	 * @property {string} spacecraftId formated as sc_{spacecraft}_{number}
	 * Derived from the spacecraftParam.
	 */
	spacecraftId: null,

	/**
	 * @property {Array<string>} spacecraftIds
	 * Array of spacecraftIds derived from the downloaded spacecraftList (referencing SPACECRAFT_CONSTELLATIONS)
	 */
	allSpacecraftIds: null,
	isZoomedOut: null,
	enabledSC: null, // true = all, [] = specific, false = none
	isSatellitesNow: null,
	spacecraftLoaded: null
});

const compareProxy = proxy({
	compareObjName: null
});

const videoProxy = proxy({
	isVideoPlaying: null,
	isVideoLoading: null,
	currentVideo: null,
	isVideoTexture: null
});

const kioskProxy = proxy({
	userInSession: false,
	isKioskResetting: null,
	sessionInterval: null,
	sessionStartTime: null,
	formattedSessionTime: null,
	inactivityTimeout: null,
	inAutoplay: false,
	autoplayCurrentSlide: null,
	autoplayTimeout: null,
	missionTimedOut: false,
	maxSessionTime: null,
	maxInactivityTime: null,
	forceRestart: null,
	showSessionEndMessage: true
});

const cameraProxy = proxy({
	isCameraTransitioning: null,
	globeLat: 20,
	globeLon: 50
});

const spoutProxy = proxy({
	spout: SPOUT_ENABLE,
	spoutRenderWidth: SPOUT_RENDER_WIDTH,
	spoutGlobe: SPOUT_PROJECT_TO_GLOBE,
	spoutFontSize: SPOUT_FONT_SIZE,
	spoutLonAngleOffset: SPOUT_LON_ANGLE_OFFSET,
	spoutAlignToNorthPole: SPOUT_ALIGN_TO_NORTH_POLE
});


const poiProxy = proxy({
	showOnGlobePoI: POI_SHOW_ON_GLOBE,
	showLatestPoI: POI_SHOW_LATEST,
	yearIndex: POI_YEAR_INDEX,
	categoryIndex: POI_CATEGORY_INDEX,
  poi_cats: POI_CATEGORIES,
  pois: {},
  poiYears: [],
  currentPoi: null
});

const gmapProxy = proxy({
	showOnGlobeGmap: GMAP_SHOW_ON_GLOBE,
	showLatestGmap: GMAP_SHOW_LATEST,
	yearIndex: GMAP_YEAR_INDEX,
	categoryIndex: GMAP_CATEGORY_INDEX,
  gmap_cats: GMAP_CATEGORIES,
  gmaps: {},
  gmapYears: [],
  currentGmap: null
});

const stellarProxy = proxy({
	currentStellar: null
});



/**
 * Export instantiated GlobalState classes as stores.
 */
export const appStore = new GlobalState(appProxy);
export const uiStore = new GlobalState(uiProxy);
export const eventsStore = new GlobalState(eventsProxy);
export const previewStore = new GlobalState(previewProxy);
export const poiStore = new GlobalState(poiProxy);
export const gmapStore = new GlobalState(gmapProxy);
export const lightStore = new GlobalState(lightProxy);
export const layersStore = new GlobalState(layersProxy);
export const timeStore = new GlobalState(timeProxy);
export const datasetStore = new GlobalState(datasetProxy);
export const datareadStore = new GlobalState(datareadProxy);
export const spacecraftStore = new GlobalState(spacecraftProxy);
export const compareStore = new GlobalState(compareProxy);
export const videoStore = new GlobalState(videoProxy);
export const kioskStore = new GlobalState(kioskProxy);
export const cameraStore = new GlobalState(cameraProxy);
export const spoutStore = new GlobalState(spoutProxy);
export const stellarStore = new GlobalState(stellarProxy);
