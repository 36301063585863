/** @module pioneer */
import {
	Interval,
	LatLonAlt,
	Vector3
} from '../internal';

/** A class of geometry routines
 * @hideconstructor */
export class Geometry {
	/**
	 * Given a line segment starting at p and going in the direction l (not unit), and a sphere of radius r at the origin, return the least lerp value such that 0 is at p and 1 is at p + l, that represents the an intersection between the line and the sphere. If there is no intersection NaN is returned.
	 * @param {Vector3} p - The starting point of the segment (returning 0 = p).
	 * @param {Vector3} l - The offset from the starting point (returning 1 = p + l).
	 * @param {number} r - The radius of the sphere at the origin.
	 * @returns {number}
	 */
	static getLineSphereIntersectionWithSphereAtOrigin(p, l, r) {
		const pp = p.dot(p);
		const pl = p.dot(l);
		const ll = l.dot(l);
		const underSquareRoot = pl * pl + (r * r - pp) * ll;
		if (underSquareRoot < 0) {
			return Number.NaN;
		}
		else {
			return (-pl - Math.sqrt(underSquareRoot)) / ll;
		}
	}

	/**
	 * Given a line segment starting at the origin and going toward l, and a sphere of radius r with its center at c, return the least lerp value such that 0 is at the origin and 1 is at l, that represents the an intersection between the line segment and the sphere. If there is no intersection NaN is returned.
	 * @param {Interval} out - The interval representing the near and far points of intersection.
	 * @param {Vector3} l - The end point of the line segment.
	 * @param {Vector3} c - The center of the sphere.
	 * @param {number} r - The radius of the sphere.
	 */
	static getLineSphereIntersectionWithLineStartAtOrigin(out, l, c, r) {
		const ll = l.dot(l);
		const lc = l.dot(c);
		const cc = c.dot(c);
		const underSquareRoot = lc * lc + (r * r - cc) * ll;
		if (underSquareRoot < 0) {
			out.min = out.max = Number.NaN;
		}
		else {
			const sqrt = Math.sqrt(underSquareRoot);
			out.min = (lc - sqrt) / ll;
			out.max = (lc + sqrt) / ll;
		}
	}

	/**
	 * Takes an XYZ and sets out to the equivalent LLA on a sphere.
	 * @param {LatLonAlt} outLLA - the LLA vector to be set
	 * @param {Vector3} xyz - the XYZ vector to convert
	 * @param {number} radius - the radius of the sphere
	 */
	static getLLAFromXYZOnSphere(outLLA, xyz, radius) {
		const xyLength = xyz.magnitudeXY();
		outLLA.lon = Math.atan2(xyz.y, xyz.x);
		outLLA.lat = Math.atan(xyz.z / xyLength);
		outLLA.alt = xyz.magnitude() - radius;
	}

	/**
	 * Takes an LLA and sets out to the equivalent XYZ on a sphere.
	 * @param {Vector3} outXYZ - the XYZ vector to be set
	 * @param {LatLonAlt} lla - the LLA vector to convert
	 * @param {number} radius - the radius of the sphere
	 */
	static getXYZFromLLAOnSphere(outXYZ, lla, radius) {
		outXYZ.x = (radius + lla.alt) * Math.cos(lla.lat) * Math.cos(lla.lon);
		outXYZ.y = (radius + lla.alt) * Math.cos(lla.lat) * Math.sin(lla.lon);
		outXYZ.z = (radius + lla.alt) * Math.sin(lla.lat);
	}
}
