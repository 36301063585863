import { LatLonAlt, MathUtils } from 'pioneer';
import { SceneHelpers } from 'pioneer-scripts';
import { Config } from '../config/config';
import { gmapStore } from '../managers/globalState';

import { GMAP_CATEGORIES, GMAP_LATEST_LIMIT } from '../config/constants';
import globalRefs from '../managers/globalRefs';
//import momenti from "moment-hijri";
import moment from 'moment-timezone';

const updateYear = newYearIndex => {
	gmapStore.setGlobalState({ yearIndex: newYearIndex });
};

const updateCategory = category => {
	gmapStore.setGlobalState({ categoryIndex: category });
};

/**
 * Loops through all the gmap and creates an array of all years spanning them.
 * @param {Array} allGmap
 * @returns {Array}
 */
const  getAllYearsGmap = allGmap => {
	let allYears = [['all','كل السنوات']];

	allGmap.map(gmap => {
		const currYear = gmap.date.substring(0, 4);
		let all = allYears.filter(v => {
			return v[0] == currYear
		})
		if(all.length == 0){
			const display = moment(gmap.date).format('YYYY');
			allYears.push([currYear,display]);
		}
	});

	return allYears.sort((a, b) => b - a);
};

const filterGmap = ( gmaps, gmapYears, yearIndex, gmapcategoryIndex) => {
  // console.log("filter!!!!!!!!!", gmaps, yearIndex, gmapcategoryIndex)
  return gmaps.filter(gmap => {
      if(GMAP_CATEGORIES.length > 0){
        const year = gmapYears[yearIndex][0];
        const category = GMAP_CATEGORIES[gmapcategoryIndex];
        const yearFilter = year === 'all' || gmap.date.substring(0, 4) === year;
        const categoryFilter = category[0] === 'all' || (gmap.imagecategory?.includes(category[0].replace(/ /g, '').toLocaleLowerCase()));
        return yearFilter && categoryFilter;
      }
  })};

  /**
 * Load the texture of the given event.
 * @param {object} eventData
 */
const loadGmap = async eventData => {
	const { pioneer } = globalRefs;
	const { image_src, north_boundary, south_boundary, east_boundary, west_boundary } = eventData || {};
	const lowerLeftLLA = new LatLonAlt();
	const upperRightLLA = new LatLonAlt();
	const earth = pioneer.get('main', 'earth');
	const src = `${Config.baseCe2s2Url}${image_src}`;
	const patchComponent = earth.getComponent('patch');
	const earthSpheroidComponent = earth.getComponentByType('spheroidLOD');
	// Hide data patch.
	patchComponent.setVisible(false);
	lowerLeftLLA.set(MathUtils.degToRad(parseFloat(south_boundary)), MathUtils.degToRad(parseFloat(west_boundary)), 0, true);
	upperRightLLA.set(MathUtils.degToRad(parseFloat(north_boundary)), MathUtils.degToRad(parseFloat(east_boundary)), 0, true);

	// Set patch texture.
	patchComponent.setMapping('cylinder');
	patchComponent.setTexture('color',  src);
	patchComponent.setBounds(lowerLeftLLA, upperRightLLA);

	// Enable the patch.
	patchComponent.forceTextureSize('color', 0);
	patchComponent.setEnabled(true);
	const material = patchComponent._threeJsMaterials[0]
	if(material){
		if(eventData.transparent){
			material.transparent = true
		} else {
			material.transparent = false;
		}
	}


	// Wait until patch is loaded.
	await SceneHelpers.waitTillEntitiesInPlace(pioneer.get('main'), ['earth']);
	await pioneer.waitUntilNextFrame();
	await earthSpheroidComponent.getLoadedPromise();
	await patchComponent.getLoadedPromise();

	// Show data patch.
	patchComponent.setVisible(true);
    patchComponent.setResourcesLoadedCallback(() => {
		console.log("GMAP patch loaded")
		if(eventData.transparent){
		  const material = patchComponent._threeJsMaterials[0]
		  if(material){
			material.transparent = true
		  }
		}
	  });
}

/**
	 * Enables the most recent event placemarks.
	 * @param {number} number
	 */
const getLimitedGmaps = (limit = GMAP_LATEST_LIMIT) => {
	const { gmaps } = gmapStore.stateSnapshot;

	return gmaps?.filter((event, i) => i < limit);
};


export {
	updateYear,
	updateCategory,
	getAllYearsGmap,
	loadGmap,
	getLimitedGmaps,
	filterGmap
};
