/** @module pioneer */
import {
	BaseController,
	Entity,
	EntityRef,
	MathUtils,
	Quaternion,
	Scene,
	Sort,
	Vector3
} from '../../internal';

export class OrbitKeyframeController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The positions that this entity will be in.
		 * @type {PositionKeyframe[]}
		 * @private
		 */
		this._positionKeyframes = [];

		/**
		 * The entities that will be focused on by the camera.
		 * @type {FocusKeyframe[]}
		 * @private
		 */
		this._focusKeyframes = [];

		/**
		 * The up directions that this entity will orient to.
		 * @type {UpKeyframe[]}
		 * @private
		 */
		this._upKeyframes = [];

		/**
		 * The time of the first update.
		 * @type {number}
		 * @private
		 */
		this._timeOfFirstUpdate = NaN;

		/**
		 * The direction of the first update.
		 * @type {Vector3}
		 * @private
		 */
		this._directionOfFirstUpdate = new Vector3();

		/**
		 * The resolve function that will be called when the keyframes complete.
		 * @type {function():void}
		 * @private
		 */
		this._resolvePromise = null;

		/**
		 * The reject function that will be called if the keyframes fail to complete.
		 * @type {function():void}
		 * @private
		 */
		this._rejectPromise = null;

		/**
		 * The promise that resolves or rejects at the end of the keyframes.
		 * @type {Promise<void>}
		 * @private
		 */
		this._endPromise = new Promise((resolve, reject) => {
			this._resolvePromise = resolve;
			this._rejectPromise = reject;
		});

		this.addModifiedState('position');
		this.addModifiedState('velocity');
		this.addModifiedState('orientation');
		this.addModifiedState('angularVelocity');
	}

	/**
	 * Sets a position keyframe. Use undefined to remove the keyframe.
	 * @param {number} time
	 * @param {Vector3 | undefined} position
	 * @param {string} relativeToEntity
	 */
	setPositionKeyframe(time, position, relativeToEntity) {
		if (position !== undefined) {
			const keyframe = new PositionKeyframe(this.getEntity().getScene());
			keyframe.time = time;
			keyframe.position.copy(position);
			keyframe.relativeTo.setName(relativeToEntity);
			Sort.add(keyframe, this._positionKeyframes, isLess);
		}
		else {
			const index = Sort.getIndex(time, this._positionKeyframes, isLessThanTime);
			if (index < this._positionKeyframes.length && time === this._positionKeyframes[index].time) {
				this._positionKeyframes.splice(index, 1);
			}
		}
	}

	/**
	 * Sets a focus keyframe. Use undefined to remove the keyframe.
	 * @param {number} time
	 * @param {string | undefined} focus
	 */
	setFocusKeyframe(time, focus) {
		if (focus !== undefined) {
			const keyframe = new FocusKeyframe(this.getEntity().getScene());
			keyframe.time = time;
			keyframe.focus.setName(focus);
			Sort.add(keyframe, this._focusKeyframes, isLess);
		}
		else {
			const index = Sort.getIndex(time, this._focusKeyframes, isLessThanTime);
			if (index < this._focusKeyframes.length && time === this._focusKeyframes[index].time) {
				this._focusKeyframes.splice(index, 1);
			}
		}
	}

	/**
	 * Sets a position keyframe. Use undefined to remove the keyframe.
	 * @param {number} time
	 * @param {Vector3 | undefined} up
	 */
	setUpKeyframe(time, up) {
		if (up !== undefined) {
			const keyframe = new UpKeyframe();
			keyframe.time = time;
			keyframe.up.copy(up);
			Sort.add(keyframe, this._upKeyframes, isLess);
		}
		else {
			const index = Sort.getIndex(time, this._upKeyframes, isLessThanTime);
			if (index < this._upKeyframes.length && time === this._upKeyframes[index].time) {
				this._upKeyframes.splice(index, 1);
			}
		}
	}

	/**
	 * Gets the promise that resolves at the end of the keyframes.
	 * @returns {Promise<void>}
	 */
	getEndPromise() {
		return this._endPromise;
	}

	/**
	 * Destroys the controller.
	 * @override
	 * @internal
	 */
	__destroy() {
		super.__destroy();

		if (this._rejectPromise !== null) {
			this._rejectPromise();
		}
	}

	/**
	 * Updates the controller.
	 * @override
	 * @internal
	 */
	__update() {
		// Get the entity, since it is used a lot.
		const entity = this.getEntity();

		// Get the time since the start.
		let time = 0;
		if (isNaN(this._timeOfFirstUpdate)) {
			// Get the parent.
			const parent = entity.getParent();
			if (parent === null) {
				return;
			}

			// Set the time and direction of the first update to now the y-axis of the entity, respectively.
			this._timeOfFirstUpdate = Date.now() / 1000;
			entity.getOrientation().getAxis(this._directionOfFirstUpdate, 1);

			// Add an initial position keyframe.
			const positionKeyframe = new PositionKeyframe(entity.getScene());
			positionKeyframe.time = 0;
			positionKeyframe.position.copy(entity.getPosition());
			positionKeyframe.relativeTo.setName(parent.getName());
			Sort.add(positionKeyframe, this._positionKeyframes, isLess);

			// Add an initial focus keyframe. The '' focus means use the first frame's direction (set above).
			const keyframe = new FocusKeyframe(entity.getScene());
			keyframe.time = 0;
			keyframe.focus.setName('');
			Sort.add(keyframe, this._focusKeyframes, isLess);

			// Add an initial up keyframe.
			const upKeyframe = new UpKeyframe();
			upKeyframe.time = 0;
			entity.getOrientation().getAxis(upKeyframe.up, 2);
			Sort.add(upKeyframe, this._upKeyframes, isLess);
		}
		else {
			time = Date.now() / 1000 - this._timeOfFirstUpdate;
		}

		// Apply the position keyframes.
		let doneWithPositionKeyframes = false;
		const positionKeyframeIndex = Sort.getIndex(time, this._positionKeyframes, isLessThanTime);
		if (positionKeyframeIndex > 0 && positionKeyframeIndex < this._positionKeyframes.length) {
			// Get the keyframes.
			const keyframe0 = this._positionKeyframes[positionKeyframeIndex - 1];
			const keyframe1 = this._positionKeyframes[positionKeyframeIndex];

			// Get the relative-to entities for the corresponding names.
			const relativeToEntity0 = keyframe0.relativeTo.get();
			const relativeToEntity1 = keyframe1.relativeTo.get();
			if (relativeToEntity0 !== null && relativeToEntity1 !== null) {
				// Get the lerp value.
				let u = (time - keyframe0.time) / (keyframe1.time - keyframe0.time);

				// Update the parent entity depending on if we're in the first or second half.
				let parentEntity = relativeToEntity0;
				if (u >= 0.5) {
					parentEntity = relativeToEntity1;
				}
				if (entity.getParent() !== parentEntity) {
					entity.setParent(parentEntity);
				}

				// Get the positions relative to the appropriate entity.
				const position0 = Vector3.pool.get();
				const position1 = Vector3.pool.get();
				relativeToEntity0.getPositionRelativeToEntity(position0, keyframe0.position, relativeToEntity1);
				relativeToEntity1.getPositionRelativeToEntity(position1, keyframe1.position, relativeToEntity0);

				// Get the u lerp value scaled so that is exponential, relating to the radius of the current parent.
				let dist00 = Math.max(0, keyframe0.position.magnitude() - relativeToEntity0.getOcclusionRadius());
				let dist10 = Math.max(0, position1.magnitude() - relativeToEntity0.getOcclusionRadius());
				let dist01 = Math.max(0, position0.magnitude() - relativeToEntity1.getOcclusionRadius());
				let dist11 = Math.max(0, keyframe1.position.magnitude() - relativeToEntity1.getOcclusionRadius());
				dist00 = Math.max(dist00, dist10 / 10000); // Make sure they aren't too far different.
				dist10 = Math.max(dist10, dist00 / 10000); // Otherwise the smaller distance starts out
				dist01 = Math.max(dist01, dist11 / 10000); //   way to slow.
				dist11 = Math.max(dist11, dist10 / 10000);
				if (dist00 !== dist10 && dist01 !== dist11) {
					const u0 = (Math.pow(dist10, u) * Math.pow(dist00, 1 - u) - dist00) / (dist10 - dist00);
					const u1 = (Math.pow(dist11, u) * Math.pow(dist01, 1 - u) - dist01) / (dist11 - dist01);
					u = MathUtils.lerp(u0, u1, u);
				}

				// Adjust the lerp value to ease-in-out.
				const sq = u * u;
				u = sq / (2 * (sq - u) + 1);

				// Get the positions relative to the current parent.
				relativeToEntity0.getPositionRelativeToEntity(position0, keyframe0.position, parentEntity);
				relativeToEntity1.getPositionRelativeToEntity(position1, keyframe1.position, parentEntity);

				// Set the position.
				const newPosition = Vector3.pool.get();
				if (relativeToEntity0 === relativeToEntity1) {
					newPosition.slerp(position0, position1, u);
				}
				else {
					newPosition.lerp(position0, position1, u);
				}
				entity.setPosition(newPosition);
				Vector3.pool.release(newPosition);
				Vector3.pool.release(position0);
				Vector3.pool.release(position1);
			}
		}
		// Flag that we're done with position keyframes.
		else if (positionKeyframeIndex === this._positionKeyframes.length) {
			// Get the last keyframe.
			const keyframe = this._positionKeyframes[this._positionKeyframes.length - 1];

			// Get the focus entity.
			const relativeToEntity = keyframe.relativeTo.get();

			// Update the parent entity depending on if we're in the first or second half.
			if (entity.getParent() !== relativeToEntity) {
				entity.setParent(relativeToEntity);
			}

			// Set the position.
			entity.setPosition(keyframe.position);

			// Flag that we're done with position keyframes.
			doneWithPositionKeyframes = true;
		}

		// Set the forward vector by default to the current parent.
		const forward = Vector3.pool.get();
		forward.setMagnitude(entity.getPosition(), -1);

		// Set the up vector by default to the current up.
		const up = Vector3.pool.get();
		entity.getOrientation().getAxis(up, 2);

		// Apply the focus keyframes. Apply the result to the forward vector if valid.
		let doneWithFocusKeyframes = false;
		const focusKeyframeIndex = Sort.getIndex(time, this._focusKeyframes, isLessThanTime);
		if (focusKeyframeIndex > 0 && focusKeyframeIndex < this._focusKeyframes.length) {
			// Get the keyframes.
			const keyframe0 = this._focusKeyframes[focusKeyframeIndex - 1];
			const keyframe1 = this._focusKeyframes[focusKeyframeIndex];

			// Get the focus entities for the corresponding names.
			const focusEntity0 = keyframe0.focus.get();
			const focusEntity1 = keyframe1.focus.get();
			const parentEntity = entity.getParent();

			if ((keyframe0.focus.getName() === '' || focusEntity0 !== null)
				&& (keyframe1.focus.getName() === '' || focusEntity1 !== null)
				&& parentEntity !== null) {
				// Get the u lerp value.
				let u = (time - keyframe0.time) / (keyframe1.time - keyframe0.time);

				// Adjust the lerp value to ease-in-out.
				const sq = u * u;
				u = sq / (2 * (sq - u) + 1);

				// Get the two focus directions.
				const direction0 = Vector3.pool.get();
				const direction1 = Vector3.pool.get();
				if (keyframe0.focus.getName() !== '') {
					parentEntity.getPositionRelativeToEntity(direction0, entity.getPosition(), focusEntity0);
					direction0.setMagnitude(direction0, -1);
				}
				else {
					direction0.copy(this._directionOfFirstUpdate);
				}
				if (keyframe1.focus.getName() !== '') {
					parentEntity.getPositionRelativeToEntity(direction1, entity.getPosition(), focusEntity1);
					direction1.setMagnitude(direction1, -1);
				}
				else {
					direction1.copy(this._directionOfFirstUpdate);
				}
				forward.slerp(direction0, direction1, u);
				Vector3.pool.release(direction0);
				Vector3.pool.release(direction1);
			}
		}
		else if (focusKeyframeIndex === this._focusKeyframes.length) {
			// Get the last keyframe.
			const keyframe = this._focusKeyframes[this._focusKeyframes.length - 1];

			// Get the focus entity.
			const focusEntity = keyframe.focus.get();
			const parentEntity = entity.getParent();

			// Set the forward vector.
			if ((keyframe.focus.getName() === '' || focusEntity !== null) && parentEntity !== null) {
				parentEntity.getPositionRelativeToEntity(forward, entity.getPosition(), focusEntity);
				forward.setMagnitude(forward, -1);
			}

			// Flag that we're done with focus keyframes.
			doneWithFocusKeyframes = true;
		}
		else {
			// Get te first keyframe.
			const keyframe = this._focusKeyframes[0];

			// Get the focus entity.
			const focusEntity = keyframe.focus.get();
			const parentEntity = entity.getParent();

			// Set the forward vector.
			if ((keyframe.focus.getName() === '' || focusEntity !== null) && parentEntity !== null) {
				if (keyframe.focus.getName() !== '') {
					parentEntity.getPositionRelativeToEntity(forward, entity.getPosition(), focusEntity);
					forward.setMagnitude(forward, -1);
				}
				else {
					forward.copy(this._directionOfFirstUpdate);
				}
			}
		}

		// Apply the focus keyframes. Apply the result to the forward vector if valid.
		let doneWithUpKeyframes = false;
		const upKeyframeIndex = Sort.getIndex(time, this._upKeyframes, isLessThanTime);
		if (upKeyframeIndex > 0 && upKeyframeIndex < this._upKeyframes.length) {
			// Get the keyframes.
			const keyframe0 = this._upKeyframes[upKeyframeIndex - 1];
			const keyframe1 = this._upKeyframes[upKeyframeIndex];

			// Get the u lerp value.
			let u = (time - keyframe0.time) / (keyframe1.time - keyframe0.time);

			// Adjust the lerp value to ease-in-out.
			const sq = u * u;
			u = sq / (2 * (sq - u) + 1);

			// Get the two up directions.
			up.slerp(keyframe0.up, keyframe1.up, u);
		}
		else if (upKeyframeIndex === this._upKeyframes.length) {
			// Get the last keyframe.
			const keyframe = this._upKeyframes[this._upKeyframes.length - 1];

			// Set the up vector.
			up.copy(keyframe.up);

			// Flag that we're done with up keyframes.
			doneWithUpKeyframes = true;
		}

		// Set the orientation from the forward and up vectors.
		const newOrientation = Quaternion.pool.get();
		up.setNormalTo(forward, up);
		newOrientation.setFromAxes(undefined, forward, up);
		entity.setOrientation(newOrientation);
		Quaternion.pool.release(newOrientation);
		Vector3.pool.release(forward);

		// If done with all of the keyframes, call the end promise.
		if (doneWithPositionKeyframes && doneWithFocusKeyframes && doneWithUpKeyframes) {
			this._rejectPromise = null;
			this._resolvePromise();
		}
	}
}

class Keyframe {
	constructor() {
		/**
		 * The time for the keyframe.
		 * @type {number}
		 */
		this.time = NaN;
	}
}

/**
 * A position keyframe.
 */
class PositionKeyframe extends Keyframe {
	/** @param {Scene} scene */
	constructor(scene) {
		super();

		/**
		 * The position for the keyframe.
		 * @type {Vector3}
		 */
		this.position = new Vector3();

		/**
		 * The reference to the relative-to entity.
		 * @type {EntityRef}
		 */
		this.relativeTo = new EntityRef(scene);
	}
}

/**
 * A focus keyframe.
 */
class FocusKeyframe extends Keyframe {
	/** @param {Scene} scene */
	constructor(scene) {
		super();

		/**
		 * The reference to the focus entity.
		 * @type {EntityRef}
		 */
		this.focus = new EntityRef(scene);
	}
}

/**
 * An up keyframe.
 */
class UpKeyframe extends Keyframe {
	constructor() {
		super();

		/**
		 * The up for the keyframe.
		 * @type {Vector3}
		 */
		this.up = new Vector3();
	}
}

/**
 * A helper function for the keyframe sorting.
 * @param {Keyframe} a
 * @param {Keyframe} b
 */
function isLess(a, b) {
	return a.time < b.time;
}

/**
 * A helper function for the keyframe sorting.
 * @param {Keyframe} a
 * @param {number} time
 */
function isLessThanTime(a, time) {
	return a.time < time;
}
