/** @module pioneer */
import {
	Freezable,
	MathUtils,
	Pool
} from '../internal';

/** An RGBA color. All values are from 0 to 1. */
export class Color extends Freezable {
	/**
	 * Pool for temporary variables.
	 * @returns {Pool<Color>}
	 */
	static get pool() {
		return _pool;
	}

	/**
	 * Black vector
	 * @returns {Color}
	 */
	static get Black() {
		return _black;
	}

	/**
	 * Clear vector
	 * @returns {Color}
	 */
	static get Clear() {
		return _clear;
	}

	/**
	 * Constructor. Defaults to white. Can use just RGB.
	 * @param {number} r - red component [0 to 1]
	 * @param {number} g - green component [0 to 1]
	 * @param {number} b - blue component [0 to 1]
	 * @param {number} a - alpha component [0 to 1]
	 */
	constructor(r = 1, g = 1, b = 1, a = 1) {
		super();

		/**
		 * red
		 * @type {number}
		 * @private
		 */
		this._r = r;

		/**
		 * green
		 * @type {number}
		 * @private
		 */
		this._g = g;

		/**
		 * blue
		 * @type {number}
		 * @private
		 */
		this._b = b;

		/**
		 * alpha
		 * @type {number}
		 * @private
		 */
		this._a = a;
	}

	/**
	 * Gets the red component.
	 * @returns {number}
	 */
	get r() {
		return this._r;
	}

	/**
	 * Sets the red component.
	 * @param {number} r
	 */
	set r(r) {
		this.throwIfFrozen();
		this._r = r;
	}

	/**
	 * Gets the green component.
	 * @returns {number}
	 */
	get g() {
		return this._g;
	}

	/**
	 * Sets the green component.
	 * @param {number} g
	 */
	set g(g) {
		this.throwIfFrozen();
		this._g = g;
	}

	/**
	 * Gets the blue component.
	 * @returns {number}
	 */
	get b() {
		return this._b;
	}

	/**
	 * Sets the blue component.
	 * @param {number} b
	 */
	set b(b) {
		this.throwIfFrozen();
		this._b = b;
	}

	/**
	 * Gets the alpha component.
	 * @returns {number}
	 */
	get a() {
		return this._a;
	}

	/**
	 * Sets the alpha component.
	 * @param {number} a
	 */
	set a(a) {
		this.throwIfFrozen();
		this._a = a;
	}

	/**
	 * Returns a nicely formed string.
	 * @override
	 * @returns {string}
	 */
	toString() {
		return '[' + this._r + ', ' + this._g + ', ' + this._b + ', ' + this._a + ']';
	}

	/**
	 * Sets this to a.
	 * @param {Color} a - the source color
	 */
	copy(a) {
		this.throwIfFrozen();
		this._r = a._r;
		this._g = a._g;
		this._b = a._b;
		this._a = a._a;
	}

	/**
	 * Sets this to the parameters. Can use just RGB.
	 * @param {number} r - red component [0 to 1]
	 * @param {number} g - green component [0 to 1]
	 * @param {number} b - blue component [0 to 1]
	 * @param {number} a - alpha component [0 to 1]
	 */
	set(r, g, b, a = 1) {
		this.throwIfFrozen();
		this._r = r;
		this._g = g;
		this._b = b;
		this._a = a;
	}

	/**
	 * Sets this to a + b.
	 * @param {Color} a
	 * @param {Color} b
	 */
	add(a, b) {
		this.throwIfFrozen();
		this._r = a._r + b._r;
		this._g = a._g + b._g;
		this._b = a._b + b._b;
		this._a = a._a + b._a;
	}

	/**
	 * Sets this to a - b.
	 * @param {Color} a
	 * @param {Color} b
	 */
	sub(a, b) {
		this.throwIfFrozen();
		this._r = a._r - b._r;
		this._g = a._g - b._g;
		this._b = a._b - b._b;
		this._a = a._a - b._a;
	}

	/**
	 * Sets this to a * b, where b is a number.
	 * @param {Color} a
	 * @param {number} b
	 */
	mult(a, b) {
		this.throwIfFrozen();
		this._r = a._r * b;
		this._g = a._g * b;
		this._b = a._b * b;
		this._a = a._a * b;
	}

	/**
	 * Sets this to a + b * c, where c is a number.
	 * @param {Color} a
	 * @param {Color} b
	 * @param {number} c
	 */
	addMult(a, b, c) {
		this.throwIfFrozen();
		this._r = a._r + b._r * c;
		this._g = a._g + b._g * c;
		this._b = a._b + b._b * c;
		this._a = a._a + b._a * c;
	}

	/**
	 * Sets this to a / b, where b is a number.
	 * @param {Color} a
	 * @param {number} b
	 */
	div(a, b) {
		this.throwIfFrozen();
		this._r = a._r / b;
		this._g = a._g / b;
		this._b = a._b / b;
		this._a = a._a / b;
	}

	/**
	 * Sets this to a * b, component-wise multiplication.
	 * @param {Color} a
	 * @param {Color} b
	 */
	scale(a, b) {
		this.throwIfFrozen();
		this._r = a._r * b._r;
		this._g = a._g * b._g;
		this._b = a._b * b._b;
		this._a = a._a * b._a;
	}

	/**
	 * Returns the value (average of r, g, b components).
	 * @returns {number}
	 */
	value() {
		return (this._r + this._g + this._b) / 3.0;
	}

	/**
	 * Returns the value of the least component (only r, g, b).
	 * @returns {number}
	 */
	min() {
		return Math.min(this._r, this._g, this._b);
	}

	/**
	 * Returns the value of the greatest component (only r, g, b).
	 * @returns {number}
	 */
	max() {
		return Math.max(this._r, this._g, this._b);
	}

	/**
	 * Sets this to the lerp between a and b.
	 * @param {Color} a - the color when u = 0
	 * @param {Color} b - the color when u = 1
	 * @param {number} u - the lerp parameter
	 */
	lerp(a, b, u) {
		this.throwIfFrozen();
		this._r = MathUtils.lerp(a._r, b._r, u);
		this._g = MathUtils.lerp(a._g, b._g, u);
		this._b = MathUtils.lerp(a._b, b._b, u);
		this._a = MathUtils.lerp(a._a, b._a, u);
	}
}

/**
 * @type {Pool<Color>}
 */
const _pool = new Pool(Color);

const _black = new Color(0, 0, 0, 1);
_black.freeze();

const _clear = new Color(0, 0, 0, 1);
_clear.freeze();
