/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';

/**
 * Helpful custom transition functions to use with the Transition controller.
 * @hideconstructor
 */
export class Transitions {
	/**
	 * A function which the transition controller will use that will jump from one position on a sphere to another position.
	 * It uses the z-axis for rotation. For how it works, see the bottom of the file.
	 * To use this function, do `transitionController.setTransitionFunction(jumpToLocationOnSphere.bind(undefined, 5, 1e4, true, earth))`.
	 * @param {number} jumpFactor - The height at which the camera will "bounce". A good number is 5.
	 * @param {number} radius - The "ground" radius to use when calculating the jump.
	 * @param {boolean} useNorthPole - The camera will use the north pole when rotating around its parent.
	 * @param {Pioneer.Entity} sphereEntity - The entity to use as the sphere and north pole reference. If undefined, the camera entity's parent is used.
	 * @param {Pioneer.Entity} cameraEntity - The camera entity.
	 * @param {Pioneer.Vector3} initialPosition - The initial position of the camera.
	 * @param {Pioneer.Vector3} finalPosition - The final position of the camera.
	 * @param {Pioneer.Quaternion} initialOrientation - The initial orientation of the camera.
	 * @param {Pioneer.Quaternion} finalOrientation - The final orientation of the camera.
	 * @param {number} u - The lerp parameter.
	 */
	static jumpToLocationOnSphere(jumpFactor, radius, useNorthPole, sphereEntity, cameraEntity, initialPosition, finalPosition, initialOrientation, finalOrientation, u) {
		// If the jump factor is zero, it won't work, so make it a very small number.
		if (jumpFactor <= 0) {
			jumpFactor = 0.001;
		}

		// Make the transition a little bit smoother.
		u = Transitions.easeInOut(u);

		// Get the radius of the parent of the camera entity.
		if (sphereEntity === undefined) {
			sphereEntity = cameraEntity.getParent();
		}

		// Make the initial and final position relative to the sphere entity.
		const initialPositionRel = Pioneer.Vector3.pool.get();
		const finalPositionRel = Pioneer.Vector3.pool.get();
		if (cameraEntity.getParent() !== null) {
			cameraEntity.getParent().getPositionRelativeToEntity(initialPositionRel, initialPosition, sphereEntity);
			cameraEntity.getParent().getPositionRelativeToEntity(finalPositionRel, finalPosition, sphereEntity);
		}
		else {
			initialPositionRel.copy(initialPosition);
			finalPositionRel.copy(finalPosition);
		}

		// Get the radial variables that will be used in the calculations.
		const r0 = initialPositionRel.magnitude() - radius; // The radial distance of the initial position.
		const r1 = finalPositionRel.magnitude() - radius; // The radial distance of the final position.

		// Get the axis that will be used in both north pole and no-up transitions.
		const axisVec = Pioneer.Vector3.pool.get();
		if (useNorthPole && sphereEntity) {
			sphereEntity.getOrientation().getAxis(axisVec, 2);
		}
		else {
			axisVec.cross(initialPositionRel, finalPositionRel);
		}
		axisVec.normalize(axisVec);
		if (axisVec.isZero()) {
			axisVec.set(0, 0, 1);
		}

		// Get the angular distance between the points.
		let a0 = 0;
		// Get the p0 and p1 coordinates as (lon, lat, alt), but as a Vector3.
		const p0 = Pioneer.Vector3.pool.get();
		const p1 = Pioneer.Vector3.pool.get();
		const frame = Pioneer.Quaternion.pool.get();
		frame.setFromAxis(axisVec, 2);
		p0.rotateInverse(frame, initialPositionRel);
		p1.rotateInverse(frame, finalPositionRel);
		// Get the lla0 and lla1 coordinates from the p0 and p1.
		const lla0 = Pioneer.LatLonAlt.pool.get();
		const lla1 = Pioneer.LatLonAlt.pool.get();
		Pioneer.Geometry.getLLAFromXYZOnSphere(lla0, p0, 0);
		Pioneer.Geometry.getLLAFromXYZOnSphere(lla1, p1, 0);
		Pioneer.Vector3.pool.release(p1);
		Pioneer.Vector3.pool.release(p0);
		// Get the a0 value that will be used in the _jumpOnCircle function.
		// It's a distance value based on the latitude and longitude.
		let lon0 = lla0.lon * Math.cos(lla0.lat);
		let lon1 = lla1.lon * Math.cos(lla1.lat);
		if (lon0 + Math.PI < lon1) {
			lon0 += 2.0 * Math.PI;
		}
		if (lon1 + Math.PI < lon0) {
			lon1 += 2.0 * Math.PI;
		}
		a0 = radius * jumpFactor * Math.sqrt((lon1 - lon0) * (lon1 - lon0) + (lla1.lat - lla0.lat) * (lla1.lat - lla0.lat));

		// Call the _jumpOnCircle function to get the a and r values used to set the new position.
		const p = Pioneer.Vector2.pool.get();
		Transitions._jumpOnCircle(p, a0, r0, r1, u);
		const a = p.x;
		const r = p.y;
		Pioneer.Vector2.pool.release(p);

		// Get the new position based on the new r and a.
		const newPosition = Pioneer.Vector3.pool.get();
		newPosition.normalize(initialPositionRel);
		newPosition.mult(newPosition, r + radius);
		const lla = Pioneer.LatLonAlt.pool.get();
		// Get a lerp value f of the angular distance.
		let f;
		if (a0 !== 0) {
			f = (a0 - a) / a0;
		}
		else {
			f = 0;
		}
		// Get the new lat, lon, alt position.
		lla.lat = Pioneer.MathUtils.lerp(lla0.lat, lla1.lat, f);
		lla.lon = Pioneer.MathUtils.lerpAngle(lla0.lon, lla1.lon, f);
		lla.alt = r;
		// Convert it to the x, y, z position.
		Pioneer.Geometry.getXYZFromLLAOnSphere(newPosition, lla, radius);
		newPosition.rotate(frame, newPosition);
		Pioneer.LatLonAlt.pool.release(lla);
		Pioneer.LatLonAlt.pool.release(lla0);
		Pioneer.LatLonAlt.pool.release(lla1);
		Pioneer.Quaternion.pool.release(frame);

		// Set the position.
		if (newPosition.isNaN()) {
			newPosition.copy(finalPositionRel);
		}
		if (cameraEntity.getParent() !== null) {
			sphereEntity.getPositionRelativeToEntity(newPosition, newPosition, cameraEntity.getParent());
		}
		cameraEntity.setPosition(newPosition);
		Pioneer.Vector3.pool.release(newPosition);
		Pioneer.Vector3.pool.release(axisVec);

		// Make the camera always look at the parent, up aligned with the north pole axis or camera axis.
		const position = Pioneer.Vector3.pool.get();
		sphereEntity.getPositionRelativeToEntity(position, Pioneer.Vector3.Zero, cameraEntity);
		position.normalize(position);
		const up = Pioneer.Vector3.pool.get();
		const orientation = Pioneer.Quaternion.pool.get();
		if (useNorthPole) {
			sphereEntity.getOrientation().getAxis(up, 2);
		}
		else {
			orientation.slerp(initialOrientation, finalOrientation, u);
			orientation.getAxis(up, 2);
		}
		up.setNormalTo(position, up);
		orientation.setFromAxes(undefined, position, up);
		cameraEntity.setOrientation(orientation);
		Pioneer.Vector3.pool.release(up);
		Pioneer.Quaternion.pool.release(orientation);
		Pioneer.Vector3.pool.release(position);
		Pioneer.Vector3.pool.release(initialPositionRel);
		Pioneer.Vector3.pool.release(finalPositionRel);
	}

	/**
	 * Does a quadratic ease-in and ease-out of the u parameter.
	 * @param {number} u
	 * @returns {number}
	 */
	static easeInOut(u) {
		const sq = u * u;
		return sq / (2 * (sq - u) + 1);
	}

	/**
	 * Given two coordinates p0 = (a0, r0) and p1 = (0, r1), and a lerp value u, return a new coordinate (a, r) along the jump path.
	 * @param {Pioneer.Vector2} out - The result vector, x = a, y = r.
	 * @param {number} a0 - The initial angular distance.
	 * @param {number} r0 - The initial radial distance.
	 * @param {number} r1 - The final radial distance.
	 * @param {number} u - The lerp value.
	 * @private
	 */
	static _jumpOnCircle(out, a0, r0, r1, u) {
		// If the angle is not 0...
		let r = 0; // The radial distance to set.
		let a = 0; // The angular distance to set.
		if (Math.abs(a0 / (r1 - r0)) > 1e-6) {
			// Get the center angular coordinate.
			const aC = (r1 - r0) / -a0 * (r1 + r0) / 2 + a0 / 2;
			// Get the angle between aC-a0 and aC-a1
			const anglep1pcp0pc = Math.sign(a0) * Math.acos((r0 * r1 + (a0 - aC) * (-aC)) / Math.sqrt(r0 * r0 + (a0 - aC) * (a0 - aC)) / Math.sqrt(r1 * r1 + aC * aC));
			// Get the cos and sin values of u, scaled so that 0 is at p0 and 1 is at p1.
			const cosU = Math.cos(u * anglep1pcp0pc);
			const sinU = Math.sin(u * anglep1pcp0pc);
			// Calculate the new p value, going along the circle.
			r = (a0 - aC) * sinU + r0 * cosU;
			a = aC + (a0 - aC) * cosU - r0 * sinU;
		}
		// If the angle is 0, just lerp the radial and angular distance.
		else {
			r = u * r1 + (1 - u) * r0;
			a = (1 - u) * a0;
		}
		// Make it exact at the end to fix precision errors.
		if (u === 1) {
			r = r1;
			a = 0;
		}
		out.set(a, r);
	}
}

/* Notes on how _jumpOnCircle works.

The radius of the parent of the camera is R.
The initial and final positions are mapped onto cartesian plane:
	The x-axis is the angular distance between them.
	The y-axis is the radial distances of the points.
	The initial position p0 is at coordinates (a0, r0).
	The final position p1 is at coordinates (0, r1).
A line segment l is drawn from p0 to p1, and the midpoint on that line is pM.
A line lP starting from pM and perpendicular to l is drawn.
The point at which lP intersects the x-axis is pC, with coordinates (aC, 0).
A circle O is formed, with center at pC and two points on the circle, p0 and p1.
The circle O is the path along which the camera will travel.
The jumpFactor * R of the entity is multiplied to stretch out the coordinates system horizontally.

*/
