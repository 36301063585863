import { useEffect, useState, useCallback } from 'react';
import { useSnapshot } from 'valtio';
import * as Pioneer from 'pioneer';

import { cameraStore, spacecraftStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';
import { getSpacecraftInstrumentData } from '../helpers/tools';
import { SPACECRAFT_WITH_INSTRUMENTS } from '../data/spacecraft_data_ar';
import { hideLoading, showLoading } from './loading';

const COLOR_HIGHLIGHTED = new Pioneer.Color(0.4, 0.85, 0.93);
const COLOR_DEFAULT = new Pioneer.Color(0, 0, 0);

const SpacecraftWithInstruments = () => {
	const { pioneer } = globalRefs;
	const [modelLoaded, setModelLoaded] = useState(false);
	const { spacecraftId, isZoomedOut } = useSnapshot(spacecraftStore.state);
	const { isCameraTransitioning } = useSnapshot(cameraStore.state);
	const { parentOfInstrument } = getSpacecraftInstrumentData(spacecraftId);

	const parentSpacecraftId = parentOfInstrument ?? spacecraftId;
	const instrumentList = SPACECRAFT_WITH_INSTRUMENTS[parentSpacecraftId];
	const parentModel = pioneer.get('main', parentSpacecraftId, 'model');

	const addEvents = modelLoaded && !isCameraTransitioning && !isZoomedOut;

	const setHighlight = useCallback((spacecraftToHighlight, highlightColor) => {
		const { pioneer } = globalRefs;
		const sceneCamera = pioneer.get('main', 'camera', 'camera');

		// Stylized instrument name (ex: 'OCO3' vs 'sc_iss_oco3') - for telling Pioneer which model to outline
		const { stylizedName } = instrumentList.find(({ instrumentId: id }) => id === spacecraftToHighlight) || {};
		if (stylizedName) {
			sceneCamera.setOutline(highlightColor, parentModel, stylizedName);
		}
	}, [instrumentList, parentModel]);

	useEffect(() => {
		if (!parentModel) {
			return;
		}

		// Show loading.
		showLoading('model');

		// Force load parent model.
		parentModel.setForceLoaded(true);
		parentModel.getLoadedPromise()
			.then(() => {
				hideLoading('model');
				setModelLoaded(true);

				setHighlight(spacecraftId, COLOR_HIGHLIGHTED);
			});
	}, [parentModel, spacecraftId, instrumentList, setHighlight]);

	useEffect(() => {
		const { pioneer, getManager } = globalRefs;
		const sceneCamera = pioneer.get('main', 'camera', 'camera');

		if (!addEvents) {
			return;
		}

		spacecraftId && setHighlight(spacecraftId, COLOR_HIGHLIGHTED);

		const onMouseOver = e => {
			const { instrumentId } = e.currentTarget.dataset;
			setHighlight(instrumentId, COLOR_HIGHLIGHTED);
		};

		const onMouseOut = e => {
			const { instrumentId } = e.currentTarget.dataset;
			setHighlight(instrumentId, COLOR_DEFAULT);
		};

		const onClick = e => {
			const { instrumentId } = e.currentTarget.dataset;

			instrumentId && getManager('route').navigateToSpacecraft(instrumentId);
		};

		instrumentList.forEach(({ instrumentId }) => {
			const divElement = pioneer.get('main', instrumentId)?.get('div').getDiv();

			if (!divElement) {
				return;
			}

			// Make sure current instrument is always highlighted.
			const currentInstrument = instrumentId === spacecraftId;
			if (currentInstrument) {
				divElement.classList.add('highlight');
			}

			divElement.setAttribute('data-instrument-id', instrumentId);
			divElement.addEventListener('mouseover', onMouseOver);
			divElement.addEventListener('mouseout', onMouseOut);

			divElement.classList.add('clickable');
			divElement.addEventListener('click', onClick);
		});

		return () => {
			instrumentList.forEach(({ instrumentId, stylizedName }) => {
				const divElement = pioneer.get('main', instrumentId)?.get('div').getDiv();

				if (!divElement) {
					return;
				}
				// Make sure all instruments are un-highlighted.
				sceneCamera.setOutline(COLOR_DEFAULT, parentModel, stylizedName);

				// Remove classes and event listeners.
				divElement.classList.remove('clickable', 'highlight');
				divElement.removeEventListener('mouseover', onMouseOver);
				divElement.removeEventListener('mouseout', onMouseOut);
				divElement.removeEventListener('click', onClick);
			});
		};
	}, [addEvents, spacecraftId, instrumentList, parentModel, setHighlight]);


	return null;
};

export default SpacecraftWithInstruments;
