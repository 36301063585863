/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';

/**
 * The Orbit Line component.
 * */
export class OrbitLineComponent extends Pioneer.BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Pioneer.Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The color of the line.
		 * @type {Pioneer.Color}
		 * @private
		 */
		this._color = new Pioneer.Color(1, 1, 1, 1);
		this._color.freeze();

		/**
		 * The alpha fade on the far side of the orbit.
		 * @type {number}
		 * @private
		 */
		this._farSideAlphaFade = 1;

		/**
		 * The width of the line.
		 * @type {number}
		 * @private
		 */
		this._lineWidth = 5;

		/**
		 * The glow width for the lines.
		 * @type {number}
		 * @private
		 */
		this._glowWidth = 0;

		/**
		 * The LineMesh object used to do the drawing.
		 * @type {Pioneer.LineMesh}
		 * @private
		 */
		this._lineMesh = null;

		/**
		 * The positions for the line mesh.
		 * @type {Pioneer.Vector3[]}
		 * @private
		 */
		this._positions = [];

		/**
		 * The colors for the line mesh.
		 * @type {Pioneer.Color[]}
		 * @private
		 */
		this._colors = [];

		/**
		 * The widths for the line mesh.
		 * @type {number[]}
		 * @private
		 */
		this._widths = [];

		/**
		 * An array that determines the pixel-space radii at which it has 0 and 1 alpha.
		 * @type {[number, number]}
		 * @private
		 */
		this._pixelSpaceRadiiAlphaFade = [5, 1];

		this.__setRadius(Number.POSITIVE_INFINITY);
	}

	/**
	 * Gets the color of the grid. Defaults to white.
	 * @returns {Pioneer.Color}
	 */
	getColor() {
		return this._color;
	}

	/**
	 * Sets the color of the grid. Defaults to white.
	 * @param {Pioneer.Color} color
	 */
	setColor(color) {
		this._color.thaw();
		this._color.copy(color);
		this._color.freeze();
		if (this._lineMesh !== null) {
			for (let i = 0, l = this._colors.length; i < l; i++) {
				this._colors[i].copy(this._color);
			}
			this._lineMesh.setColors(this._colors);
		}
	}

	/**
	 * Gets the alpha fade on the far side of the orbit.
	 * @returns {number}
	 */
	getFarSideAlphaFade() {
		return this._farSideAlphaFade;
	}

	/**
	 * Sets the alpha fade on the far side of the orbit. Defaults to 1.
	 * @param {number} alphaFade
	 */
	setFarSideAlphaFade(alphaFade) {
		this._farSideAlphaFade = alphaFade;
	}

	/**
	 * Gets the width of the line.
	 * @returns {number}
	 */
	getLineWidth() {
		return this._lineWidth;
	}

	/**
	 * Sets the width of the line. Defaults to 5.
	 * @param {number} lineWidth
	 */
	setLineWidth(lineWidth) {
		this._lineWidth = lineWidth;
		if (this._lineMesh !== null) {
			for (let i = 0, l = this._widths.length; i < l; i++) {
				this._widths[i] = this._lineWidth;
			}
			this._lineMesh.setWidths(this._widths);
		}
	}

	/**
	 * Sets the glow for the lines.
	 * @param {number} glowWidth
	 */
	setGlowWidth(glowWidth) {
		this._glowWidth = glowWidth;
		Pioneer.ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'glowWidth', this._glowWidth);
	}

	/**
	 * Sets values that determine the pixel-space radii at which it has 0 and 1 alpha. Defaults to 5, 1.
	 * @param {number} radiusFor0Alpha
	 * @param {number} radiusFor1Alpha
	 */
	setPixelSpaceRadiiAlphaFade(radiusFor0Alpha, radiusFor1Alpha) {
		this._pixelSpaceRadiiAlphaFade[0] = radiusFor0Alpha;
		this._pixelSpaceRadiiAlphaFade[1] = radiusFor1Alpha;
	}

	/**
	 * Prepare the component for rendering.
	 * @param {Pioneer.CameraComponent} camera
	 * @override
	 * @package
	 */
	__prepareForRender(camera) {

		// Update the mesh.
		this._updateMesh();

		// Update the pixel-space radius alpha fade.
		const pixelSpaceRadius = this.getEntity().getPixelSpaceExtentsRadius(camera);
		if (!isNaN(pixelSpaceRadius)) {
			const fadeU = (pixelSpaceRadius - this._pixelSpaceRadiiAlphaFade[0]) / (this._pixelSpaceRadiiAlphaFade[1] - this._pixelSpaceRadiiAlphaFade[0]);
			this._lineMesh.setAlphaMultiplier(Pioneer.MathUtils.lerp(0, 1, Pioneer.MathUtils.clamp01(fadeU)));
		}
		else {
			this._lineMesh.setAlphaMultiplier(1);
		}

		// Set the Three.js object position the entity's camera-space position.
		Pioneer.ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects(), this.getEntity(), camera);

		// Call the line mesh prepare for render.
		this._lineMesh.prepareForRender(camera);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	__loadResources() {
		this._lineMesh = new Pioneer.LineMesh(this);

		// Setup the arrays for the line mesh.
		for (let i = 0, l = 360 * 2; i < l; i++) {
			this._positions.push(new Pioneer.Vector3());
			this._colors.push(new Pioneer.Color());
			this._colors[i].copy(this._color);
			this._widths.push(this._lineWidth);
		}

		// Set the widths and colors for the line mesh.
		this._lineMesh.setColors(this._colors);
		this._lineMesh.setWidths(this._widths);
		this._lineMesh.setGlowWidth(this._glowWidth);

		return Promise.resolve();
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		// Clean up the arrays for the line mesh.
		this._positions = [];
		this._colors = [];
		this._widths = [];

		// Clean up the objects and line mesh.
		Pioneer.ThreeJsHelper.destroyAllObjectsAndMaterials(this);
		this._lineMesh = null;
	}

	/**
	 * Updates the mesh positions and colors.
	 * @private
	 */
	_updateMesh() {
		// Find the GM from the last covered dynamo orb controller.
		const entity = this.getEntity();
		const currentTime = entity.getScene().getEngine().getTime();
		let gm = 0;
		for (let i = entity.getNumControllers() - 1; i >= 0; i--) {
			const controller = entity.getControllerByClass(Pioneer.DynamoController, i);
			if (controller !== null && controller.getCoverage().contains(currentTime)) {
				if (controller.getPointType() === 'orb') {
					gm = controller.getHeaderValue('gravitationalParameter1') + controller.getHeaderValue('gravitationalParameter2');
					break;
				}
			}
		}

		// Get the orbital elements from the current position and velocity.
		orbitalElements.setFromPositionAndVelocity(entity.getPosition(), entity.getVelocity(), currentTime, gm);

		// Get the true and eccentric anomaly of the current position, used for the alpha fade.
		const entityPosition = entity.getPosition();
		const trueAnomalyAtCurrentTime = orbitalElements.getTrueAnomalyFromPosition(entityPosition);
		const eccentricAnomalyAtCurrentTime = orbitalElements.getEccentricAnomalyFromTrueAnomaly(trueAnomalyAtCurrentTime);

		// Calculate the new positions and colors.
		let lastMeanAnomalyNaN = false;
		for (let angle = 0; angle < 360; angle += 1) {
			// The indices for the vertices.
			const index0 = angle * 2;
			const index1 = (angle * 2 - 1 + this._positions.length) % this._positions.length;

			// Calculate the position given the angle, which is the true anomaly.
			let eccentricAnomaly = orbitalElements.getEccentricAnomalyFromTrueAnomaly(Pioneer.MathUtils.degToRad(angle - 180));
			const eccentricAnomalyNext = orbitalElements.getEccentricAnomalyFromTrueAnomaly(Pioneer.MathUtils.degToRad(angle + 1 - 180));
			if ((eccentricAnomaly < eccentricAnomalyAtCurrentTime && eccentricAnomalyAtCurrentTime < eccentricAnomalyNext)
				|| (eccentricAnomalyNext < eccentricAnomalyAtCurrentTime && eccentricAnomalyAtCurrentTime < eccentricAnomaly)) {
				eccentricAnomaly = eccentricAnomalyAtCurrentTime;
			}
			const meanAnomaly = orbitalElements.getMeanAnomalyFromEccentricAnomaly(eccentricAnomaly);
			const time = orbitalElements.epoch + (meanAnomaly - orbitalElements.meanAnomalyAtEpoch) / orbitalElements.meanAngularMotion;
			const position = this._positions[index0];
			orbitalElements.project(position, velocity, time);
			position.sub(position, entityPosition);
			this._positions[index1].copy(position);

			// If the mean anomaly is NaN, disconnect the segments around it.
			if (isNaN(meanAnomaly)) {
				lastMeanAnomalyNaN = true;
				const index2 = (index0 + 1) % this._positions.length;
				const index3 = (index1 - 1 + this._positions.length) % this._positions.length;
				this._positions[index0].set(0, 0, 0);
				this._positions[index1].set(0, 0, 0);
				this._positions[index2].set(0, 0, 0);
				this._positions[index3].set(0, 0, 0);
				this._colors[index0].a = 0;
				this._colors[index1].a = 0;
				this._colors[index2].a = 0;
				this._colors[index3].a = 0;
				continue;
			}

			// Get the alpha fade based on the current true anomaly of the entity.
			let alphaFadeU = 0.0;
			alphaFadeU = Pioneer.MathUtils.angle(eccentricAnomaly, eccentricAnomalyAtCurrentTime) / Math.PI;
			alphaFadeU = Pioneer.MathUtils.clamp01(alphaFadeU);
			const alphaFade = Pioneer.MathUtils.lerp(1.0, this._farSideAlphaFade, alphaFadeU);
			this._colors[index0].mult(this._color, alphaFade);
			if (!lastMeanAnomalyNaN) {
				this._colors[index1].mult(this._color, alphaFade);
			}

			// It got here, so mark the last mean anomaly as valid.
			lastMeanAnomalyNaN = false;
		}

		// If we're at the end of the loop and the eccentricity is para/hyperbolic, disconnect the loop.
		if (orbitalElements.eccentricity >= 1) {
			this._positions[this._positions.length - 2].copy(this._positions[this._positions.length - 3]);
			this._positions[this._positions.length - 1].copy(this._positions[this._positions.length - 3]);
			this._colors[this._colors.length - 2].a = 0;
			this._colors[this._colors.length - 1].a = 0;
		}

		// Set the line mesh positions.
		this._lineMesh.setPositions(this._positions);
		this._lineMesh.setColors(this._colors);
	}
}

const orbitalElements = new Pioneer.OrbitalElements();
const velocity = new Pioneer.Vector3();
