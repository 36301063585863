import React, { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';

import { eventsStore, datasetStore, videoStore, spacecraftStore, uiStore, appStore, poiStore, gmapStore,stellarStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';

import { cloneDate, formatDate, subtractDaysFromDate } from '../helpers/tools';

import '../../www/assets/css/date_label.css';
import DatasetTools from './dataset_tools';
import ar from '../languages/ar'

// Days back for each average type.
const DAYS_BACK_KEYMAP = {
	daily: 0,
	month: 30,
	ten_day: 9,
	seven_day: 6,
	eight_day: 7,
	three_day: 2,
	sixteen_day: 15
};


/**
 * Date label component.
 */
const DateLabel = ({ setIsDateLabelLoaded = null }) => {
	const { controlsMenu, isDetailPanelOpen, isDateTooltipOpen } = useSnapshot(uiStore.state);
	const { validData } = useSnapshot(uiStore.state);
	const { eventsVisibleOnGlobe, currentEvent } = useSnapshot(eventsStore.state);
	const { animationDates, currentDataset, currentIndex, datasetHasAnimation } = useSnapshot(datasetStore.state);
	const { isSatellitesNow, currentSpacecraft } = useSnapshot(spacecraftStore.state);
	const { isVideoTexture } = useSnapshot(videoStore.state);
	const { queries, isMobile } = useSnapshot(appStore.state);
	// Show legacy date label if hideUI is true, for EIC displays
	const { hideUI } = queries || {};
	const showUI = hideUI !== 'true';

	const { getManager, getTileManager } = globalRefs;
	const { animations } = getManager('dataset');
	const [animEndDate, setAnimEndDate] = useState(animations?.[currentIndex]);

	const { average } = currentDataset || {};
	const { currentPoi } = useSnapshot(poiStore.state);
	const { currentGmap } = useSnapshot(gmapStore.state);
	const { currentStellar } = useSnapshot(stellarStore.state);

	// Determine if we should show the date label.
	const showDateLabel = animEndDate && !isSatellitesNow && !currentSpacecraft && !eventsVisibleOnGlobe && !isVideoTexture && !currentEvent && !currentGmap && !currentPoi && !currentStellar;

	useEffect(() => {
		setIsDateLabelLoaded && setIsDateLabelLoaded(showDateLabel);
	}, [showDateLabel]);

	// Update label when dates change
	useEffect(() => {
		const dateSource = animations?.[currentIndex] ?? currentDataset?.latestTileset;
		const { dateObject: endDateObj } = dateSource || {};

		setAnimEndDate(endDateObj);

		// if not wmts, assume data is valid
		if (!getTileManager(currentDataset?.externalId)) {
			uiStore.setGlobalState({ validData: true });
		}
	}, [animations, animationDates, currentIndex, currentDataset]);

	useEffect(() => () => {
		uiStore.setGlobalState({ isDateTooltipOpen: false });
	}, [
		animations,
		controlsMenu,
		currentEvent,
		currentSpacecraft,
		isDetailPanelOpen,
		isSatellitesNow
	]);

	const handleTooltipExpand = e => {
		if (isMobile) {
			if (e && e.stopPropagation) { e.stopPropagation(); }
			uiStore.setGlobalState({ isDateTooltipOpen: !isDateTooltipOpen });
		}
	};

	if (!showDateLabel) {
		return null;
	}

	const endDate = cloneDate(animEndDate);
	const daysBack = DAYS_BACK_KEYMAP[average];
	const showRange = average !== 'daily' && average !== 'month';

	/**
	 * Use the spread operator inside the object to conditionally add the day: undefined.
	 * https://www.codemzy.com/blog/conditionally-add-property-to-object-javascript
	*/
	const endDateStr = formatDate(endDate, {
		month: showRange ? 'short' : 'long',
		...average === 'month' && { day: undefined }
	});

	// Dates are stored in UTC ISO format but we want to display them to users in their own timezone.
	// The formatDate tool helper does this for us.
	let beginDateStr = '';
	if (showRange) {
		const beginDate = subtractDaysFromDate(cloneDate(endDate), daysBack);
		beginDateStr = formatDate(beginDate, { month: 'short' });
	}


	const disableContents = controlsMenu === 'time' || controlsMenu === 'settings';
	const dateStr = (showRange ? beginDateStr + ' - ' : '') + endDateStr;

	//DANGER: returns null above if showDateLabel is false
	return (
		showUI
			? (
				<div id='date-label-container' onClick={handleTooltipExpand} className={`${disableContents ? 'disabled' : ''} ${datasetHasAnimation ? 'active' : ''} ${!isMobile && showRange ? 'expanded-width' : ''}`}>
					<div className='align-text-mobile'>
						<span className='date-observed'>
							{validData
								? (
									isMobile ? ar.observed : ar.data_observed
								)
								: (
									ar.data_unavailable
								)}
							{!(isMobile && showRange) && validData && (showRange ? '' : '')}
						</span>
						<div className='date-text outline'>
							{dateStr}
							{!datasetHasAnimation && <div className={`date-text-tooltip ${isMobile && isDateTooltipOpen ? 'expanded' : ''}`} />}
						</div>

					</div>
					{isMobile && isDateTooltipOpen && <div className='tooltip-content-mobile'>{ar.date_tooltip}</div>}

					{!isMobile && <DatasetTools />}
				</div>
			)
			: (
				<div id='legacy-date-label-container'>
					<div className='legacy-date-text'>
						{dateStr}
					</div>
				</div>
			)


	);
};

export default DateLabel;
