/** @module pioneer-scripts */
import { Entity } from '../entity';
import { SceneHelpers } from '../scene_helpers';
import * as Pioneer from 'pioneer';

Entity.register({
	observable_universe: {
		groups: ['stars'],
		radius: 5e23,
		systemRadius: 5e23,
		label: 'Observable Universe',
		parents: [],
		controllers: [{
			type: 'fixed',
			position: Pioneer.Vector3.Zero,
			orientation: Pioneer.Quaternion.Identity
		}],
		postCreateFunction: (entity) => {
			entity.setCanOcclude(false);
		}
	},
	milky_way: {
		groups: ['stars'],
		radius: 1e18,
		systemRadius: 1.3e+19,
		label: 'Milky Way',
		parents: [
			[Number.NEGATIVE_INFINITY, 'observable_universe']
		],
		controllers: [{
			type: 'fixed',
			position: Pioneer.Vector3.Zero,
			orientation: Pioneer.Quaternion.Identity
		}],
		postCreateFunction: (entity, extraOptions) => {
			entity.setCanOcclude(false);

			const fixedController = entity.getControllerByClass(Pioneer.FixedController);
			// Get it in the right orientation, using the galactic north and galactic center.
			// Galactic North Pole in J2000: 12h 51m 26.27549s, +27° 07′ 41.7043"
			// Galactic Center in J2000: 7h 45m 37.19910s, −28° 56′ 10.2207"
			// From Liu, Jia-Cheng & Zhu, Zi & Zhang, Hong. (2010). Reconsidering the Galactic coordinate system. Astronomy and Astrophysics. 526. 10.1051/0004-6361/201014961.
			const northPole = new Pioneer.Vector3();
			const northPoleRa = 192.85948120833 * Math.PI / 180;
			const northPoleDec = 27.12825119444 * Math.PI / 180;
			northPole.x = Math.cos(northPoleRa) * Math.cos(northPoleDec);
			northPole.y = Math.sin(northPoleRa) * Math.cos(northPoleDec);
			northPole.z = Math.sin(northPoleDec);
			const center = new Pioneer.Vector3();
			const centerRa = 266.40499625 * Math.PI / 180;
			const centerDec = -28.93617241667 * Math.PI / 180;
			center.x = Math.cos(centerRa) * Math.cos(centerDec);
			center.y = Math.sin(centerRa) * Math.cos(centerDec);
			center.z = Math.sin(centerDec);
			const orientation = new Pioneer.Quaternion();
			orientation.setFromAxes(center, undefined, northPole);
			fixedController.setOrientation(orientation);

			// Add milky way sprite, if it's in the options.
			if (extraOptions && extraOptions.milkyWaySprite) {
				const sprite = entity.addComponentByClass(Pioneer.SpriteComponent);
				sprite.setTextureUrl('$STATIC_ASSETS_URL/sprites/milky_way.png');
				sprite.setSize(new Pioneer.Vector2(12e17, 12e17));
				sprite.setTransparent(true);
				sprite.setBlending('normal');
				sprite.setFadeDistance(12e15);
			}

			// Spin of the milky way. Later I should change it to work with particles and use the galaxy rotation curve.
			const spinController = entity.addControllerByClass(Pioneer.SpinController);
			spinController.setAxis(new Pioneer.Vector3(-0.8676661356982597, -0.19807638974470915, 0.45598379452940485), false);
			spinController.setRate(-2.192686e-17);
			spinController.setReferenceAngle(0);
			spinController.setReferenceTime(0);
		}
	},
	sun: {
		groups: ['stars'],
		radius: 695500,
		systemRadius: 2.991957e+13,
		label: 'Sun',
		parents: [
			[Number.NEGATIVE_INFINITY, 'milky_way']
		],
		spheroid: {
			equatorialRadius: 695500,
			polarRadius: 695500,
			planetographic: false
		},
		spheroidLOD: {
			features: ['colorMapEmmissive'],
			textures: {
				color: {
					url: 'sun/color_$SIZE_$FACE.png',
					sizes: [4, 512]
				}
			}
		},
		controllers: [{
			type: 'fixed',
			position: Pioneer.Vector3.Zero,
			orientation: Pioneer.Quaternion.Identity
		}],
		postCreateFunction: (entity, extraOptions) => {
			// Add the light source for the sun.
			const lightSourceComponent = entity.addComponentByClass(Pioneer.LightSourceComponent);
			lightSourceComponent.setAbsoluteMagnitude(4.83);

			// Atmosphere glow when you're close up to the sun.
			const sunAtmosphere = entity.addComponentByClass(Pioneer.AtmosphereComponent);
			sunAtmosphere.setEmissivity(1.0);
			sunAtmosphere.setScaleHeight(2e5);
			sunAtmosphere.setDensity(8e-7);
			sunAtmosphere.setColor(new Pioneer.Color(255.0 / 255.0, 255.0 / 255.0, 64.0 / 255.0));

			// Sprite glow when you're far away from the sun.
			const sunGlowSprite = entity.addComponentByClass(Pioneer.SpriteComponent);
			sunGlowSprite.setBillboard(true);
			sunGlowSprite.setTextureUrl('$STATIC_ASSETS_URL/sprites/sun_glow.png');
			sunGlowSprite.setSize(new Pioneer.Vector2(100, 100));
			sunGlowSprite.setSizeUnits('pixels');
			sunGlowSprite.setTransparent(true);
			sunGlowSprite.setColorMultiplier(new Pioneer.Color(1.0, 1.0, 0.5));
			sunGlowSprite.setRenderOrder(-2); // Make it render in the same order as the stars.

			// Set the position in the milky way.
			// Distance from sun to galactic center: 7.98 kpc.
			// From Malkin, Zinovy. (2012). The current best estimate of the Galactocentric distance of the Sun based on comparison of different statistical techniques.
			const oeController = entity.addControllerByClass(Pioneer.OrbitalElementsController);
			const oe = new Pioneer.OrbitalElements();
			oe.epoch = 0;
			oe.eccentricity = 0;
			oe.semiMajorAxis = 246237071000000000.0;
			oe.meanAngularMotion = -2.192686e-17; // 230 million years per orbit.
			oe.meanAnomalyAtEpoch = Math.PI;
			// Use the orientation calculated from the milky way above.
			oe.orbitOrientation.set(-0.48894750765094835, -0.4832106839985283, 0.19625375824756275, 0.6992297419646486);
			oeController.addOrbitalElements(-1e100, oe);
			oeController.addOrbitalElements(+1e100, oe);

			// Add the stars and the galaxies, since they are sun-centric.
			if (extraOptions !== undefined && extraOptions.skybox === true) {
				const skyboxComponent = entity.addComponentByClass(Pioneer.SkyboxComponent);
				skyboxComponent.setTextureUrl('$STATIC_ASSETS_URL/textures/starmap_' + (extraOptions.skyboxResolution || 2048) + '.jpg');
			}
			if (extraOptions === undefined || (extraOptions.skybox === true && extraOptions.starfield === true) || extraOptions.starfield !== false) {
				const galaxyComponent = entity.addComponentByClass(Pioneer.StarfieldComponent);
				galaxyComponent.setUrl('$STATIC_ASSETS_URL/stars/galaxies.0.bin');
				for (let i = 0; i < 6; i++) {
					const starfieldComponent = entity.addComponentByClass(Pioneer.StarfieldComponent);
					starfieldComponent.setUrl('$STATIC_ASSETS_URL/stars/stars.' + i + '.bin');
				}
			}

			// Add the heliosphere, if specified.
			// Using "A Three-dimensional Map of the Heliosphere from IBEX", 2021 Reisenfeld et al. as a reference.
			if (extraOptions !== undefined && extraOptions.heliosphere) {
				const model = entity.addComponentByClass(Pioneer.ModelComponent);
				model.setUrl('$STATIC_ASSETS_URL/models/heliosphere/voyager_heliosphere.gltf');
				// Get it just the right size so Voyager 1 leaves it around 2012-08 and Voyager 2 leaves it around 2018-11.
				model.setScale(new Pioneer.Vector3(2.01e8, 1.9e8, 1.9e8));
				model.setForceLoaded(true);
				// Rotate so it's heading in the correct direction.
				const llaNose = new Pioneer.LatLonAlt(0, -105 * Math.PI / 180, 0); // Lat/lon of nose in J2000 Ecliptic.
				const xyzNose = new Pioneer.Vector3();
				Pioneer.Geometry.getXYZFromLLAOnSphere(xyzNose, llaNose, 1);
				xyzNose.rotate(SceneHelpers.getEclipJ2000ToJ2000Rotation(), xyzNose);
				xyzNose.normalize(xyzNose);
				const rotation = new Pioneer.Quaternion();
				rotation.setFromVectorFromTo(
					new Pioneer.Vector3(0.9937396508197329, 0.11171728072160429, 0.0008692392338424033),
					xyzNose);
				model.setRotation(rotation);
			}
		}
	},
	mercury: {
		groups: ['planets'],
		radius: 2439.4,
		systemRadius: 292764,
		label: 'Mercury',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined,
			color: [0.6, 0.6, 0.6, 0.7]
		},
		spheroid: {
			equatorialRadius: 2439.4,
			polarRadius: 2439.4,
			planetographic: false
		},
		spheroidLOD: {
			textures: {
				color: {
					url: 'mercury/color_$SIZE_$FACE.png',
					sizes: [4, 512, 4096]
				}
			}
		},
		controllers: [{
			type: 'dynamo',
			url: 'mercury/sun/orb'
		}, {
			type: 'dynamo',
			url: 'mercury/ori'
		}]
	},
	venus: {
		groups: ['planets'],
		radius: 6051.8,
		systemRadius: 726216,
		label: 'Venus',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined,
			color: [0.9, 0.80, 0.45, 0.7]
		},
		spheroid: {
			equatorialRadius: 6051.8,
			polarRadius: 6051.8,
			planetographic: false
		},
		spheroidLOD: {
			textures: {
				color: {
					url: 'venus/color_$SIZE_$FACE.png',
					sizes: [4, 512]
				}
			}
		},
		controllers: [{
			type: 'dynamo',
			url: 'venus/sun/orb'
		}, {
			type: 'dynamo',
			url: 'venus/ori'
		}],
		postCreateFunction: (entity) => {
			const atmosphereComponent = entity.addComponentByClass(Pioneer.AtmosphereComponent);
			atmosphereComponent.setScaleHeight(15.0);
			atmosphereComponent.setDensity(0.001);
			atmosphereComponent.setColor(new Pioneer.Color(213.0 / 255.0, 160.0 / 255.0, 94.0 / 255.0));
			atmosphereComponent.setSunBrightness(0.25);
		}
	},
	earth: {
		groups: ['planets'],
		radius: 6378.1,
		systemRadius: 765372,
		label: 'Earth',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined,
			color: [0.0, 0.6, 0.8, 0.7]
		},
		spheroid: {
			equatorialRadius: 6378.137,
			polarRadius: 6356.752,
			planetographic: true
		},
		spheroidLOD: {
			features: ['normalMap', 'specularMap', 'nightMap', 'decalMap', 'nightMapEmmissive', 'shadowEntities'],
			textures: {
				color: {
					url: 'earth/color_$SIZE_$FACE.png',
					sizes: [16, 512, 4096]
				},
				normal: {
					url: 'earth/normal_$SIZE_$FACE.png',
					sizes: [16, 512, 4096]
				},
				specular: {
					url: 'earth/specular_$SIZE_$FACE.png',
					sizes: [16, 512, 4096]
				},
				night: {
					url: 'earth/night_$SIZE_$FACE.png',
					sizes: [16, 512, 4096]
				},
				decal: {
					url: 'earth/cloud_$SIZE_$FACE.png',
					sizes: [16, 512, 4096]
				}
			},
			shadowEntities: ['moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'earth/sun/orb'
		}, {
			type: 'dynamo',
			url: 'earth/ori'
		}],
		postCreateFunction: (entity, extraOptions) => {
			if (extraOptions && extraOptions.clouds === false) {
				const spheroidLODComponent = entity.getComponentByClass(Pioneer.SpheroidLODComponent);
				spheroidLODComponent.setTexture('decal', '');
			}

			const atmosphereComponent = entity.addComponentByClass(Pioneer.AtmosphereComponent);
			atmosphereComponent.setScaleHeight(8.0);
			atmosphereComponent.setDensity(0.0015);
			atmosphereComponent.setColor(new Pioneer.Color(1.5 * 143.0 / 255.0, 1.5 * 178.0 / 255.0, 1.5 * 255.0 / 255.0));
			atmosphereComponent.setSunBrightness(2.0);
			atmosphereComponent.setSunsetColor(new Pioneer.Color(1, 0.5, 0));
			atmosphereComponent.setSunsetIntensity(1.2);
		}
	},
	mars: {
		groups: ['planets'],
		radius: 3396.2,
		systemRadius: 46922,
		label: 'Mars',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined,
			color: [0.89, 0.51, 0.35, 0.7]
		},
		spheroid: {
			equatorialRadius: 3396.19,
			polarRadius: 3376.2,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'mars/color_$SIZE_$FACE.png',
					sizes: [4, 512, 4096]
				}
				// normal: 'mars/normal_$SIZE_$FACE.png',
			},
			shadowEntities: ['phobos', 'deimos']
		},
		controllers: [{
			type: 'dynamo',
			url: 'mars/sun/orb'
		}, {
			type: 'dynamo',
			url: 'mars/ori'
		}],
		postCreateFunction: (entity) => {
			const atmosphereComponent = entity.addComponentByClass(Pioneer.AtmosphereComponent);
			atmosphereComponent.setScaleHeight(10.8);
			atmosphereComponent.setDensity(0.001);
			atmosphereComponent.setColor(new Pioneer.Color(225.0 / 255.0, 178.0 / 255.0, 112.0 / 255.0));
			atmosphereComponent.setSunBrightness(0.8);
			atmosphereComponent.setSunsetColor(new Pioneer.Color(10.0 / 255.0, 75.0 / 255.0, 140.0 / 255.0));
			atmosphereComponent.setSunsetIntensity(1.0);
		}
	},
	jupiter: {
		groups: ['planets'],
		radius: 71492,
		systemRadius: 3782501,
		label: 'Jupiter',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined,
			color: [0.95, 0.71, 0.64, 0.7]
		},
		spheroid: {
			equatorialRadius: 71492,
			polarRadius: 66854,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'jupiter/color_$SIZE_$FACE.jpg',
					sizes: [16, 512, 4096]
				}
			},
			shadowEntities: ['io', 'europa', 'ganymede', 'callisto']
		},
		controllers: [{
			type: 'dynamo',
			url: 'jupiter/sun/orb'
		}, {
			type: 'dynamo',
			url: 'jupiter/ori'
		}, {
			type: 'coverage',
			coverage: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
			update: (entity) => {
				if (entity.getOrientation().isNaN()) {
					return;
				}

				/*
				In order to update the spots, you can use these helpful functions.

				// Get the camera the view from earth.
				e = () => { c = app._pioneer.getViewport(0).getCamera(); p = new Pioneer.Vector3(); p.sub(app._scene.get('earth').getPosition(), app._scene.get('jupiter').getPosition()); p.mult(p, .99); c.getEntity().setPosition(p); c.setFieldOfView(0.0008); };

				// The code moves time backward by the light-time distance between earth and jupiter.
				// So if a photo was taken at time T, then the will move to T - ltej.
				// VERY useful for getting photos exact, and works perfectly for photos as far back as 1963 (and likely further).
				// It moves time backward by the light-time distance between earth and jupiter.
				// So if a photo was taken at time T, then the will move to T - ltej.
				// VERY useful for getting photos exact, and works perfectly for photos as far back as 1963 (and likely further).
				t = () => { p = new Pioneer.Vector3(); p.sub(app._scene.get('earth').getPosition(), app._scene.get('jupiter').getPosition()); app._pioneer.setTime(app._pioneer.getTime() - p.magnitude() / 299792.458); console.log(app._pioneer.getTime()); };

				// Rotate jupiter by the given angle to match.
				r = (angle) => { s = app._scene.get('jupiter', 'spheroidLOD'); s.setLongitudeRotation(Pioneer.MathUtils.degToRad(angle)); };

				Make sure to turn off the coverage controller below.
				Find a photo from earth of Jupiter (https://www.missionjuno.swri.edu/junocam/planning). Go to the time when the photo was taken.
				Run e();
				Run t();
				Now you'll be at the right time and position from earth to see the photo.
				Run r(angle) until the spot matches the right location.
				Add this angle to the Jupiter GRS spreadsheet column F. Do this for a number of photos.
				Also look up http://jupos.privat.t-online.de/index.htm for numbers to add to array above.
				*/

				// Get the right index for the current time.
				const time = entity.getScene().getEngine().getTime();
				const rotations = jupiterRotationData.rotations;
				const currentDriftRate = (rotations[rotations.length - 1][1] - rotations[rotations.length - 2][1]) / (rotations[rotations.length - 1][0] - rotations[rotations.length - 2][0]);
				let rate = currentDriftRate; // The rate of drift in degrees per second from the index point. It is set to the current rate of jupiter's rotation.
				let index = jupiterRotationData.hintIndex;
				if (time < rotations[index][0] || (index < rotations.length - 1 && rotations[index + 1][0] <= time)) {
					if (time >= rotations[rotations.length - 1][0]) { // After the last point.
						index = rotations.length - 1;
						// The rate just stays the current rate.
					}
					else if (time < rotations[0][0]) { // Before the first point.
						index = 0;
						rate = 0; // No rate, since not enough data.
					}
					else { // Somewhere in between points.
						for (let i = rotations.length - 2; i >= 0; i--) {
							if (rotations[i][0] <= time && time < rotations[i + 1][0]) {
								index = i;
								rate = (rotations[i + 1][1] - rotations[i][1]) / (rotations[i + 1][0] - rotations[i][0]);
							}
						}
					}
				}
				else if (index < rotations.length - 1) {
					rate = (rotations[index + 1][1] - rotations[index][1]) / (rotations[index + 1][0] - rotations[index][0]);
				}
				jupiterRotationData.hintIndex = index;
				// Get the angle in system II.
				const angle = rotations[index][1] + (time - rotations[index][0]) * rate;
				// Do the calculation from System II to System III and taking into account the texture offset.
				const actualAngle = Pioneer.MathUtils.wrap(jupiterRotationData.referenceAngleInSystemIII
					- (angle + jupiterRotationData.textureAngleOffset - jupiterRotationData.referenceAngleInSystemII)
					+ (time - jupiterRotationData.referenceTime) * jupiterRotationData.offsetFactor, 0, 360);
				// Apply the rotation.
				const spheroidLOD = entity.getComponentByClass(Pioneer.SpheroidLODComponent);
				if (spheroidLOD !== null) {
					spheroidLOD.setLongitudeRotation(Pioneer.MathUtils.degToRad(actualAngle));
				}
			}
		}],
		postCreateFunction: (entity) => {
			const atmosphereComponent = entity.addComponentByClass(Pioneer.AtmosphereComponent);
			atmosphereComponent.setScaleHeight(200.0);
			atmosphereComponent.setDensity(5.0e-5);
			atmosphereComponent.setColor(new Pioneer.Color(234.0 / 255.0, 202.0 / 255.0, 170.0 / 255.0));
			atmosphereComponent.setSunBrightness(0.25);
		}
	},
	saturn: {
		groups: ['planets'],
		radius: 60268,
		systemRadius: 7184413,
		label: 'Saturn',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		spheroid: {
			equatorialRadius: 60268,
			polarRadius: 54364,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities', 'shadowRings'],
			textures: {
				color: {
					url: 'saturn/color_$SIZE_$FACE.png',
					sizes: [4, 512]
				}
			},
			shadowEntities: ['iapetus', 'dione', 'rhea', 'tethys', 'titan', 'mimas', 'enceladus']
		},
		controllers: [{
			type: 'dynamo',
			url: 'saturn/sun/orb'
		}, {
			type: 'dynamo',
			url: 'saturn/ori'
		}],
		postCreateFunction: (entity) => {
			const ringsComponent = entity.addComponentByClass(Pioneer.RingsComponent);
			ringsComponent.setInnerRadius(74270.580913);
			ringsComponent.setOuterRadius(140478.924731);
			ringsComponent.setTopTextureUrl('$STATIC_ASSETS_URL/sprites/saturn_rings_top.png');
			ringsComponent.setBottomTextureUrl('$STATIC_ASSETS_URL/sprites/saturn_rings_bottom.png');
			ringsComponent.setFadeDistance(250);
			ringsComponent.setShadowEntities(['iapetus', 'dione', 'rhea', 'tethys', 'titan', 'mimas', 'enceladus']);

			const atmosphereComponent = entity.addComponentByClass(Pioneer.AtmosphereComponent);
			atmosphereComponent.setScaleHeight(200.0);
			atmosphereComponent.setDensity(5.0e-5);
			atmosphereComponent.setColor(new Pioneer.Color(234.0 / 255.0, 202.0 / 255.0, 151.0 / 255.0));
		}
	},
	uranus: {
		groups: ['planets'],
		radius: 25559,
		systemRadius: 1164893,
		label: 'Uranus',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined,
			color: [0.67, 0.92, 1.00, 0.7]
		},
		spheroid: {
			equatorialRadius: 25559,
			polarRadius: 24973,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'uranus/color_$SIZE_$FACE.png',
					sizes: [4, 256]
				}
			},
			shadowEntities: ['titania', 'oberon', 'umbriel', 'ariel', 'miranda']
		},
		controllers: [{
			type: 'dynamo',
			url: 'uranus/sun/orb'
		}, {
			type: 'dynamo',
			url: 'uranus/ori'
		}],
		postCreateFunction: (entity) => {
			const ringsComponent = entity.addComponentByClass(Pioneer.RingsComponent);
			ringsComponent.setInnerRadius(26840);
			ringsComponent.setOuterRadius(103000);
			ringsComponent.setTopTextureUrl('$STATIC_ASSETS_URL/sprites/uranus_rings.png');
			ringsComponent.setBottomTextureUrl('$STATIC_ASSETS_URL/sprites/uranus_rings.png');
			ringsComponent.setFadeDistance(250);
			ringsComponent.setShadowEntities(['miranda', 'ariel', 'umbriel', 'titania', 'oberon']);

			const atmosphereComponent = entity.addComponentByClass(Pioneer.AtmosphereComponent);
			atmosphereComponent.setScaleHeight(200.0);
			atmosphereComponent.setDensity(5.0e-5);
			atmosphereComponent.setColor(new Pioneer.Color(147.0 / 255.0, 183.0 / 255.0, 201.0 / 255.0));
			atmosphereComponent.setSunsetColor(new Pioneer.Color(164.0 / 255.0, 168.0 / 255.0, 102.0 / 255.0));
			atmosphereComponent.setSunsetIntensity(1.0);
		}
	},
	neptune: {
		groups: ['planets'],
		radius: 24764,
		systemRadius: 43213894,
		label: 'Neptune',
		parents: [
			[Number.NEGATIVE_INFINITY, 'sun']
		],
		trail: {
			length: undefined,
			color: [0.48, 0.69, 1.00, 0.7]
		},
		spheroid: {
			equatorialRadius: 24764,
			polarRadius: 24341,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'neptune/color_$SIZE_$FACE.png',
					sizes: [4, 256]
				}
			},
			shadowEntities: ['triton', 'proteus', 'despina', 'galatea', 'larissa']
		},
		controllers: [{
			type: 'dynamo',
			url: 'neptune/sun/orb'
		}, {
			type: 'dynamo',
			url: 'neptune/ori'
		}],
		postCreateFunction: (entity) => {
			const ringsComponent = entity.addComponentByClass(Pioneer.RingsComponent);
			ringsComponent.setInnerRadius(40900);
			ringsComponent.setOuterRadius(62964);
			ringsComponent.setTopTextureUrl('$STATIC_ASSETS_URL/sprites/neptune_rings.png');
			ringsComponent.setBottomTextureUrl('$STATIC_ASSETS_URL/sprites/neptune_rings.png');
			ringsComponent.setFadeDistance(250);

			const atmosphereComponent = entity.addComponentByClass(Pioneer.AtmosphereComponent);
			atmosphereComponent.setScaleHeight(200.0);
			atmosphereComponent.setDensity(5.0e-5);
			atmosphereComponent.setColor(new Pioneer.Color(138.0 / 255.0, 160.0 / 255.0, 255.0 / 255.0));
		}
	}
});

const jupiterRotationData = {
	hintIndex: 0, // A special variable to keep track of the current index in the rotations array below.
	textureAngleOffset: -60.38, // Get the red spot at 0 degrees longitude in System III.
	referenceTime: 617098056, // A known good photo from JunoCam planning on 2019-07-22T20:44
	referenceAngleInSystemII: 313.47, // The angle in System II for the photo.
	referenceAngleInSystemIII: 305, // The angle in System III for that photo.
	offsetFactor: -0.000003074, // The scaling factor that moves from System II to System III.
	rotations: [ // Data in System II from charts at http://jupos.org.
		[-1136116758, 15], // 1964-01-01
		[-1037534358, 28], // 1967-02-15
		[-938951958, 22], // 1970-04-01
		[-867931156, 0], // 1972-07-01
		[-812721554, 12], // 1974-04-01
		[-744292752, 47], // 1976-06-01
		[-639143949, 62], // 1979-10-01
		[-533822346, 48], // 1983-02-01
		[-483883145, 27], // 1984-09-01
		[-391953544, 15], // 1987-08-01
		[102427264, 85], // 2003-04-01
		[315576066, 138], // 2010-01-01
		[615211269, 312], // 2019-06-30
		[660052869, 347] // 2020-11-30
	]
};
