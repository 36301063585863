/* eslint-disable no-irregular-whitespace */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import '../../www/assets/css/close_button.css';
import CloseIcon from '../../www/assets/images/close-x.svg';

const CloseButton = ({ className, size = 'medium', corner = 'left', responsive = false, onClick }) => (
	<div
		className={clsx('close-btn', className, size, corner, { responsive })} onClick={onClick}
	>
		<CloseIcon />
	</div>
);

CloseButton.propTypes = {
	className: PropTypes.string,
	size: PropTypes.oneOf(['small', 'medium']),
	corner: PropTypes.oneOf(['right', 'left']),
	responsive: PropTypes.bool,
	onClick: PropTypes.func
};

export default CloseButton;
