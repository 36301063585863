/** @module pioneer-scripts */
import { Entity } from '../entity';

Entity.register({
	ariel: {
		groups: ['uranus', 'moons', 'major'],
		radius: 578.9,
		label: 'Ariel',
		parents: [
			[Number.NEGATIVE_INFINITY, 'uranus']
		],
		trail: {
			length: undefined,
			color: [0.67, 0.92, 1.00, 0.7]
		},
		spheroid: {
			equatorialRadius: 578.9,
			polarRadius: 578.9,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'ariel/color_$SIZE_$FACE.png',
					sizes: [4, 512]
				}
			},
			shadowEntities: ['uranus']
		},
		controllers: [{
			type: 'dynamo',
			url: 'ariel/uranus/orb'
		}, {
			type: 'dynamo',
			url: 'ariel/ori'
		}]
	},
	miranda: {
		groups: ['uranus', 'moons', 'major'],
		radius: 235.8,
		label: 'Miranda',
		parents: [
			[Number.NEGATIVE_INFINITY, 'uranus']
		],
		trail: {
			length: undefined,
			color: [0.67, 0.92, 1.00, 0.7]
		},
		spheroid: {
			equatorialRadius: 235.8,
			polarRadius: 235.8,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'miranda/color_$SIZE_$FACE.png',
					sizes: [4, 256]
				}
			},
			shadowEntities: ['uranus']
		},
		controllers: [{
			type: 'dynamo',
			url: 'miranda/uranus/orb'
		}, {
			type: 'dynamo',
			url: 'miranda/ori'
		}]
	},
	oberon: {
		groups: ['uranus', 'moons', 'major'],
		radius: 761.4,
		label: 'Oberon',
		parents: [
			[Number.NEGATIVE_INFINITY, 'uranus']
		],
		trail: {
			length: undefined,
			color: [0.67, 0.92, 1.00, 0.7]
		},
		spheroid: {
			equatorialRadius: 761.4,
			polarRadius: 761.4,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'oberon/color_$SIZE_$FACE.png',
					sizes: [4, 512]
				}
			},
			shadowEntities: ['uranus']
		},
		controllers: [{
			type: 'dynamo',
			url: 'oberon/uranus/orb'
		}, {
			type: 'dynamo',
			url: 'oberon/ori'
		}]
	},
	titania: {
		groups: ['uranus', 'moons', 'major'],
		radius: 788.4,
		label: 'Titania',
		parents: [
			[Number.NEGATIVE_INFINITY, 'uranus']
		],
		trail: {
			length: undefined,
			color: [0.67, 0.92, 1.00, 0.7]
		},
		spheroid: {
			equatorialRadius: 788.4,
			polarRadius: 788.4,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'titania/color_$SIZE_$FACE.png',
					sizes: [4, 512]
				}
			},
			shadowEntities: ['uranus']
		},
		controllers: [{
			type: 'dynamo',
			url: 'titania/uranus/orb'
		}, {
			type: 'dynamo',
			url: 'titania/ori'
		}]
	},
	umbriel: {
		groups: ['uranus', 'moons', 'major'],
		radius: 584.7,
		label: 'Umbriel',
		parents: [
			[Number.NEGATIVE_INFINITY, 'uranus']
		],
		trail: {
			length: undefined,
			color: [0.67, 0.92, 1.00, 0.7]
		},
		spheroid: {
			equatorialRadius: 584.7,
			polarRadius: 584.7,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'umbriel/color_$SIZE_$FACE.png',
					sizes: [4, 256]
				}
			},
			shadowEntities: ['uranus']
		},
		controllers: [{
			type: 'dynamo',
			url: 'umbriel/uranus/orb'
		}, {
			type: 'dynamo',
			url: 'umbriel/ori'
		}]
	}
});
