/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';

/**
 * A grid in the shape of a disc.
 */
export class DiscGridComponent extends Pioneer.BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Pioneer.Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The size of the grid.
		 * @type {number}
		 * @private
		 */
		this._size = 1;

		/**
		 * The color of the grid.
		 * @type {Pioneer.Color}
		 * @private
		 */
		this._color = new Pioneer.Color(1, 1, 1, 1);
		this._color.freeze();

		/**
		 * The width of the lines in pixels.
		 * @type {number}
		 * @private
		 */
		this._lineWidth = 1;

		/**
		 * The number of radial points to render.
		 * @type {number}
		 * @private
		 */
		this._numCircles = 10;

		/**
		 * The number of circular points to render.
		 * @type {number}
		 * @private
		 */
		this._numSpokes = 20;

		/**
		 * The power that each concentric circle radius is raised to.
		 * @type {number}
		 * @private
		 */
		this._radialPower = 1;

		/**
		 * A flag the determines whether or not the grid ignores the distance when determining visibility.
		 * @type {boolean}
		 * @private
		 */
		this._ignoreDistance = false;

		/**
		 * The LineMesh object used to do the drawing.
		 * @type {Pioneer.LineMesh}
		 * @private
		 */
		this._lineMesh = null;

		/**
		 * True if the mesh needs to be updated.
		 * @type {boolean}
		 * @private
		 */
		this._meshDirty = true;

		// Set the initial size.
		this.__setRadius(this._size);

		// It uses the entity's orientation.
		this.__setUsesEntityOrientation(true);
	}

	/**
	 * Gets the size of the grid. Defaults to 1.
	 * @returns {number}
	 */
	getSize() {
		return this._size;
	}

	/**
	 * Sets the size of the grid.
	 * @param {number} size
	 */
	setSize(size) {
		this._size = size;
		this.__setRadius(this._size);
		if (this._lineMesh !== null) {
			this._lineMesh.setScale(this._size);
		}
	}

	/**
	 * Gets the color of the grid. Defaults to white.
	 * @returns {Pioneer.Color}
	 */
	getColor() {
		return this._color;
	}

	/**
	 * Sets the color of the grid.
	 * @param {Pioneer.Color} color
	 */
	setColor(color) {
		this._color.thaw();
		this._color.copy(color);
		this._color.freeze();
		this._meshDirty = true;
	}

	/**
	 * Gets the width of the lines in pixels.
	 * @returns {number}
	 */
	getLineWidth() {
		return this._lineWidth;
	}

	/**
	 * Sets the width of the lines in pixels. Defaults to 1.
	 * @param {number} lineWidth
	 */
	setLineWidth(lineWidth) {
		this._lineWidth = lineWidth;
		if (this._lineMesh !== null) {
			this._lineMesh.setWidths(this._lineWidth);
		}
	}

	/**
	 * Gets the number of concentric circles to place. Defaults to 10.
	 * @returns {number}
	 */
	getNumCircles() {
		return this._numCircles;
	}

	/**
	 * Sets the number of concentric circles to place.
	 * @param {number} numCircles
	 */
	setNumCircles(numCircles) {
		this._numCircles = numCircles;
		this._meshDirty = true;
	}

	/**
	 * Gets the number of spokes to place. Defaults to 50.
	 * @returns {number}
	 */
	getNumSpokes() {
		return this._numSpokes;
	}

	/**
	 * Sets the number of spokes to place.
	 * @param {number} numSpokes
	 */
	setNumSpokes(numSpokes) {
		this._numSpokes = numSpokes;
		this._meshDirty = true;
	}

	/**
	 * Gets the power that each concentric circle is raised to compared to the next inner circle. Defaults to 1.
	 * @returns {number}
	 */
	getRadialPower() {
		return this._radialPower;
	}

	/**
	 * Sets the power that each concentric circle is raised to compared to the next inner circle.
	 * @param {number} radialPower
	 */
	setRadialPower(radialPower) {
		this._radialPower = radialPower;
		this._meshDirty = true;
	}

	/**
	 * Ignores the distance when determining whether it should show the grid or not. Defaults to false.
	 * @param {boolean} ignoreDistance
	 */
	setIgnoreDistance(ignoreDistance) {
		this._ignoreDistance = ignoreDistance;
	}

	/**
	 * Prepare the component for rendering.
	 * @param {Pioneer.CameraComponent} camera
	 * @override
	 * @package
	 */
	__prepareForRender(camera) {
		if (this._meshDirty) {
			this._updateMesh();
		}

		if (!this._ignoreDistance) {
			const normalizedSizeOfEntity = this.getEntity().getNormalSpaceExtentsRadius(camera);
			const alphaMultiplier = (0.02 - normalizedSizeOfEntity) / 0.02;
			this._lineMesh.setAlphaMultiplier(alphaMultiplier);
		}

		this._lineMesh.prepareForRender(camera);

		// Set the orientation to the entity's orientation.
		for (let i = 0, l = this.getThreeJsObjects().length; i < l; i++) {
			Pioneer.ThreeJsHelper.setOrientationToEntity(this.getThreeJsObjects()[i], this.getEntity());

			// Set the Three.js object position the entity's camera-space position.
			Pioneer.ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects()[i], this.getEntity(), camera);
		}
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	__loadResources() {
		// Create the line mesh.
		this._lineMesh = new Pioneer.LineMesh(this);

		// Update the vertices.
		this._updateMesh();

		// Set the scale.
		this._lineMesh.setScale(this._size);

		// Resolve immediately.
		return Promise.resolve();
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		Pioneer.ThreeJsHelper.destroyAllObjectsAndMaterials(this);
		this._lineMesh = null;
	}

	/**
	 * Updates the mesh.
	 * @private
	 */
	_updateMesh() {
		// Make the concentric circles.
		const positions = [];
		for (let i = 1; i <= this._numCircles; i++) {
			const circleSize = this._radialPower !== 1 ? Math.pow(this._radialPower, i - this._numCircles) : (i / this._numCircles);
			const numOfPointsOnCircle = 50;
			for (let j = 0; j < numOfPointsOnCircle; j++) {
				const angle1 = j / numOfPointsOnCircle * Pioneer.MathUtils.twoPi;
				const angle2 = ((j + 1) % numOfPointsOnCircle) / numOfPointsOnCircle * Pioneer.MathUtils.twoPi;
				positions.push(new Pioneer.Vector3(circleSize * Math.cos(angle1), circleSize * Math.sin(angle1), 0));
				positions.push(new Pioneer.Vector3(circleSize * Math.cos(angle2), circleSize * Math.sin(angle2), 0));
			}
		}
		for (let i = 0; i < this._numSpokes; i++) {
			const angle1 = i / this._numSpokes * Pioneer.MathUtils.twoPi;
			positions.push(Pioneer.Vector3.Zero);
			positions.push(new Pioneer.Vector3(Math.cos(angle1), Math.sin(angle1), 0));
		}
		const colors = [];
		for (let i = 0, l = positions.length; i < l; i++) {
			colors.push(this._color);
		}
		this._lineMesh.setPositions(positions);
		this._lineMesh.setColors(colors);
		this._lineMesh.setWidths(this._lineWidth);
		this._meshDirty = false;
	}
}
