/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	Color,
	Entity,
	LineMesh,
	ModelComponent,
	Quaternion,
	THREE,
	ThreeJsHelper,
	Vector3
} from '../../internal';

/**
 * Red, green, blue axes to help in understanding the orientation of an object.
 */
export class GizmoComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The size of the gizmo lines. If not set, defaults to two times the radius of the entity.
		 * @type {number | undefined}
		 * @private
		 */
		this._size = undefined;

		/**
		 * Whether or not the gizmo lines are relative to the orientation of the entity.
		 * @type {boolean}
		 * @private
		 */
		this._relativeToEntity = true;

		/**
		 * The joint for the model to show the gizmo. If empty, the entity itself is used.
		 * @type {string}
		 * @private
		 */
		this._joint = '';

		/**
		 * The joint's ThreeJs object.
		 * @type {THREE.Object3D}
		 * @private
		 */
		this._jointObject = null;

		/**
		 * The model for the joint.
		 * @type {ModelComponent}
		 * @private
		 */
		this._model = null;

		/**
		 * The line mesh.
		 * @type {LineMesh}
		 * @private
		 */
		this._lineMesh = null;

		// Set the radius to twice the entity's radius.
		this.__setRadius(entity.getExtentsRadius() * 2.0);

		// It uses the entity's orientation.
		this.__setUsesEntityOrientation(this._relativeToEntity);
	}

	/**
	 * Gets the size of the gizmo lines. If not set, defaults to two times the extents radius of the entity.
	 * @returns {number}
	 */
	getSize() {
		if (this._size !== undefined) {
			return this._size;
		}
		else {
			return Math.max(0.0001, this.getEntity().getExtentsRadius() * 2.0);
		}
	}

	/**
	 * Sets the size of the gizmo lines. If not set, defaults to two times the extents radius of the entity.
	 * @param {number} [size]
	 */
	setSize(size) {
		this._size = size;
		this.__setRadius(this.getSize());
	}

	/**
	 * Gets whether or not the gizmo lines are relative to the orientation of the entity. Defaults to true.
	 * @returns {boolean}
	 */
	isRelativeToEntity() {
		return this._relativeToEntity;
	}

	/**
	 * Sets whether or not the gizmo lines are relative to the orientation of the entity.
	 * @param {boolean} relativeToEntity
	 */
	setRelativeToEntity(relativeToEntity) {
		this._relativeToEntity = relativeToEntity;
		this.__setUsesEntityOrientation(this._relativeToEntity);
	}

	/**
	 * Sets the gizmo to be at the joint on the specified model. If no model is given, the first model in the entity is used.
	 * @param {string} joint
	 * @param {ModelComponent} [model]
	 */
	setJoint(joint, model) {
		this._joint = joint;
		if (!model) {
			const modelFromEntity = /** @type {ModelComponent} */(this.getEntity().get('model'));
			if (modelFromEntity !== null) {
				this._model = modelFromEntity;
			}
		}
		else {
			this._model = model;
		}
	}

	/**
	 * Prepare the component for rendering.
	 * @param {CameraComponent} camera
	 * @override
	 * @internal
	 */
	__prepareForRender(camera) {
		// If a joint is specified, setup the joint's ThreeJs object.
		if (this._joint !== '' && (this._jointObject === null || this._jointObject.name !== this._joint) && this._model !== null) {
			this._jointObject = this._model.getThreeJsObjectByName(this._joint);
		}
		// If the joint object is valid,
		if (this._jointObject !== null) {
			// Get the position of the joint within the entity by adding up the ancestor positions.
			const position = Vector3.pool.get();
			let jointAncestor = this._jointObject;
			GizmoComponent._tempThreeJsVector3.copy(jointAncestor.position);
			GizmoComponent._tempThreeJsQuaternion.copy(jointAncestor.quaternion);
			while (jointAncestor.parent !== null && jointAncestor.parent !== this._model.getThreeJsObjects()[0]) {
				jointAncestor = jointAncestor.parent;
				GizmoComponent._tempThreeJsVector3.add(jointAncestor.position);
				GizmoComponent._tempThreeJsQuaternion.multiplyQuaternions(jointAncestor.quaternion, GizmoComponent._tempThreeJsQuaternion);
			}
			position.copyFromThreeJs(GizmoComponent._tempThreeJsVector3);
			position.mult(position, 0.001); // Get it into the proper km scale.
			position.rotate(this._model.getRotation(), position); // Rotate it to be in the model component's frame.

			// Set the Three.js object position the entity's camera-space position.
			ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects()[0], this.getEntity(), camera, position, true);

			Vector3.pool.release(position);

			const orientation = Quaternion.pool.get();
			orientation.copyFromThreeJs(GizmoComponent._tempThreeJsQuaternion);
			orientation.mult(this._model.getRotation(), orientation);
			ThreeJsHelper.setOrientationToEntity(this.getThreeJsObjects()[0], this.getEntity(), orientation);
			Quaternion.pool.release(orientation);
		}
		else {
			if (this._relativeToEntity) {
				ThreeJsHelper.setOrientationToEntity(this.getThreeJsObjects()[0], this.getEntity());
			}
			else {
				ThreeJsHelper.setOrientation(this.getThreeJsObjects()[0], Quaternion.Identity);
			}

			// Set the Three.js object position the entity's camera-space position.
			ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects()[0], this.getEntity(), camera);
		}
		ThreeJsHelper.setScale(this.getThreeJsObjects()[0], this.getSize());

		this._lineMesh.prepareForRender(camera);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	__loadResources() {
		this._lineMesh = new LineMesh(this);
		const positions = [];
		positions.push(new Vector3(0, 0, 0));
		positions.push(new Vector3(1, 0, 0));
		positions.push(new Vector3(0, 0, 0));
		positions.push(new Vector3(0, 1, 0));
		positions.push(new Vector3(0, 0, 0));
		positions.push(new Vector3(0, 0, 1));
		this._lineMesh.setPositions(positions);
		const colors = [];
		colors.push(new Color(1, 0, 0));
		colors.push(new Color(1, 0, 0));
		colors.push(new Color(0, 1, 0));
		colors.push(new Color(0, 1, 0));
		colors.push(new Color(0, 0, 1));
		colors.push(new Color(0, 0, 1));
		this._lineMesh.setColors(colors);
		const widths = [];
		widths.push(2);
		widths.push(2);
		widths.push(2);
		widths.push(2);
		widths.push(2);
		widths.push(2);
		this._lineMesh.setWidths(widths);

		return Promise.resolve();
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		ThreeJsHelper.destroyAllObjectsAndMaterials(this);
		this._lineMesh = null;
	}
}

/**
 * A temporary ThreeJs Vector3.
 * @type {THREE.Vector3}
 */
GizmoComponent._tempThreeJsVector3 = new THREE.Vector3();
