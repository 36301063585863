import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * Render image after it is verifed and loaded. Now cleaned up on unmount.
 *
 * ToDo - update this with Suspense once updated to react 18 - also include caching.
 * https://css-tricks.com/pre-caching-image-with-react-suspense/
 * @param {string} src
 * @param {string} className
 * @param {string} alt
 */
const Image = ({ src, className = '', alt }) => {
	const [ready, setReady] = useState(false);
	const imgRef = useRef();

	useEffect(() => {
		const imgElCurrent = imgRef.current;

		const onImageLoaded = () => setReady(true);
		const onImageError = () => {
			console.error(`Could not load image: ${src}`);
			setReady(false);
		};

		if (imgElCurrent) {
			imgElCurrent.addEventListener('load', onImageLoaded);
			imgElCurrent.addEventListener('error', onImageError);


			return () => {
				imgElCurrent.removeEventListener('load', onImageLoaded);
				imgElCurrent.removeEventListener('error', onImageError);
			};
		}
	}, [imgRef, src]);

	return <img ref={imgRef} style={{ visibility: ready ? 'visible' : 'hidden' }} src={src} className={className} alt={alt} />;
};

Image.propTypes = {
	src: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired
};

export default Image;
