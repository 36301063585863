import '../lib/quadtree';
import app from '../app';
import { cameraStore, timeStore } from './globalState';
import globalRefs from './globalRefs';
import { touchState } from '../components/master/EventAndStateMaster';
import ENTITY_INFO from '../data/entity_info.json';

// Label manager algorithm modes
const ALGO_MODES = {
	QUADTREE: 0,
	BRUTEFORCE: 1
};

class LabelManager {
	/**
	 * @param {Collection} entities
	 * @param {Pioneer.Engine} pioneer
	 */
	constructor(pioneer) {
		// Set pioneer and scene.
		this._pioneer = pioneer;
		this._scene = pioneer.get('main');
		/**
		 * Method for finding conflicting labels
		 * @type {number}
		 */
		this._algorithm = this.Quadtree;

		// Store label information
		this._labels = {};

		// Store weights for all objects
		this._weights = {};

		/**
		 * Quadtree object
		 * @type {Quadtree}
		 * @private
		 */
		this._quadTree = new Quadtree({ // TODO: Quadtree is not defined nearby.
			x: 0,
			y: 0,
			width: window.innerWidth,
			height: window.innerHeight
		}, 2, 8);

		// Array to store collinding labels ordered by weight
		this._collidingLabels = [];

		// Manage multiple frame operations
		this._frameCount = 0;
		this._frameStep = 1;
		this._frameCycle = this._frameStep * 3;

		// Sets name for label classes
		this._activeClass = 'active';
		this._hiddenClass = 'hidden';

		// Sets default overlap percentage threshold to hide conflicting lables
		this._overlapThreshold = 0.25;

		// Custom class names
		this._customClass = {
			scientist: 'comparison',
			school_bus: 'comparison'
		};
	}

	init() {
		// Set algorithm
		this.setAlgorithm(ALGO_MODES.QUADTREE);
	}

	/**
	 * Builds the labels.
	 * What if we leave before? Shouldnt we build labels as soon as entities are created?
	 */
	buildLabels() {
		// Set entities.
		this._setEntities(this._scene._entities);
		// Create label array and populate labels.
		this._createLabelArray();
		this._populateLabels();

		// Add pioneer callback to update labels.
		this._pioneer.addCallback(() => {
			this.update();
		});
	}


	// Set type of algorithm to use
	setAlgorithm(algo) {
		this._algorithm = algo;
	}

	// Set list of all entities
	_setEntities(entities) {
		this._entities = entities;
	}

	/**
	 * Populate labels.
	 *
	 * TODO: We need to improve the safety of this function.
	 * We dont want memory leaks from multiple event listeners being added.
	 */
	_populateLabels() {
		const { getManager } = globalRefs;
		const contentManager = getManager('content');
		const timeManager = getManager('time');
		const cameraEntity = this._scene.get('camera');
		const camera = cameraEntity.getComponentByType('camera');

		// Add dynamic env map.
		const dynEnvMap = cameraEntity.addComponent('dynEnvMap');

		// Populate labels and dynamic environment map.
		for (let i = 0, l = this._scene.getNumEntities(); i < l; i++) {
			const entity = this._scene.getEntity(i);
			const divComponent = entity.get('div');
			if (divComponent !== null) {
				// Set active camera
				divComponent.setActiveCamera(camera);
				const div = divComponent.getDiv();
				const className = this._getClassName(entity.getName());
				div.className += ' no-select ' + className;
				if (contentManager.isClickable(entity.getName())) {
					div.className += ' ' + 'clickable';
				}
				const icon = document.createElement('span');
				icon.className = 'icon';
				const span = document.createElement('span');
				span.className = 'text';
				span.innerHTML = div.innerHTML;
				div.innerHTML = '';
				div.appendChild(icon);
				div.appendChild(span);

				const entityName = entity.getName();

				if (contentManager.isClickable(entityName)) {
					div.addEventListener('click', event => {
						event.preventDefault();
						// Ignore when camera is transitioning.
						const { isCameraTransitioning } = cameraStore.stateSnapshot;
						if (isCameraTransitioning) {
							return;
						}

						timeManager.playTimeOnLabelExit();
						const entity = ENTITY_INFO[entityName]
						if(entity && entity.stellar){
							getManager('route').navigateToStellarBody(entityName);
						} else {
							// Navigate to spacecraft.
							getManager('route').navigateToSpacecraft(entityName);
						}
					});
					div.addEventListener('touchend', event => {
						event.preventDefault();
						// Ignore when camera is transitioning.
						const { isCameraTransitioning } = cameraStore.stateSnapshot;
						const { startPos } = touchState;
						if (isCameraTransitioning) {
							return;
						}

						const endPos = {
							x: event.changedTouches[0].clientX,
							y: event.changedTouches[0].clientY
						};

						const DRAG_THRESHOLD = 10;
						const notDragging = startPos === null
							|| (Math.abs(startPos.x - endPos.x) < DRAG_THRESHOLD && Math.abs(startPos.y - endPos.y) < DRAG_THRESHOLD);
						// ie. make sure no drag from input, and touches length is 0.
						if (notDragging && event.touches.length === 0) {
							getManager('route').navigateToSpacecraft(entityName);
						}
					});
					div.addEventListener('mouseenter', event => {
						// Ignore when camera is transitioning.
						const { isCameraTransitioning } = cameraStore.stateSnapshot;
						const { isRealTime } = timeStore.stateSnapshot;
						if (isCameraTransitioning || isRealTime) {
							return;
						}

						timeManager.pauseTimeOnLabelEnter();
					});
					div.addEventListener('mouseleave', event => {
						// Ignore when camera is transitioning.
						const { isCameraTransitioning } = cameraStore.stateSnapshot;
						const { isRealTime } = timeStore.stateSnapshot;
						if (isCameraTransitioning || isRealTime) {
							return;
						}

						timeManager.playTimeOnLabelExit();
					});
					div.addEventListener('mousemove', event => {
						event.preventDefault();
					}, true);
					div.style.cursor = 'pointer';
				}

				const model = entity.getComponentByType('model');
				if (model !== null) {
					model.setDynamicEnvironmentMapComponent(dynEnvMap);
					model.setEnvironmentIntensity(0.1);
				}
			}
		}
	}

	_getClassName(id) {
		const { getManager } = globalRefs;
		const entityInfo = getManager('content').getEntityInfo(id);

		if (entityInfo === undefined || entityInfo.category === undefined) {
			return id;
		}

		// Add category
		let newClassName = entityInfo.category.toLowerCase().replace(/ /g, '-');
		switch (entityInfo.category) {
			case 'Moon': {
				// Add parent class and id
				const entity = this._scene.get(id) || null;
				if (entity !== null) {
					// Retrieve parent class if possible
					let parent = entity.getParent();
					while (parent !== null) {
						if (parent.getParent() !== null && parent.getParent().getName() === 'sun') {
							newClassName += ' parent-' + parent.getName() + ' ' + id;
						}
						parent = parent.getParent();
					}
				}
				break;
			}
			default:
				// Just add id
				newClassName += ' ' + id;
				break;
		}

		// Add custom class if it exists
		if (id in this._customClass) {
			newClassName += ' ' + this._customClass[id];
		}

		return newClassName;
	}

	// Set weights for labels
	setWeights(weights) {
		this._weights = weights;
	}

	// Get weight for a label
	getWeight(name) {
		if (Object.keys(this._weights).length <= 0) {
			return 0;
		}
		if (this._weights[name] !== undefined) {
			return this._weights[name].weight;
		}
		return 0;
	}

	// Get Z position in camera space
	getZ(name) {
		return this._labels[name].z;
	}

	// Called every frame
	update() {
		// Spread operations accross multiple frames
		if (this._frameCount === 0) {
			this._collidingLabels = [];
			this._buildQuadtree();
		} else if (this._frameCount === this._frameCycle / 3) {
			this._checkWithQuadtree();
		} else if (this._frameCount === 2 * this._frameCycle / 3) {
			this._fixCollisions();
		}

		this._frameCount++;
		this._frameCount %= this._frameCycle;
	}

	// Initialize label array
	_createLabelArray() {
		this._labels = {};
		for (let i = this._entities.size - 1; i >= 0; i--) {
			const entity = this._entities.get(i);
			if (entity.getComponentByType('div')) {
				this._labels[entity.getName()] = {
					name: entity.getName(),
					x: 0,
					y: 0,
					width: 0,
					height: 0,
					check: false,
					collisions: [],
					isHidden: false
				};
			}
		}
	}

	// Build a quadtree with all entity divs
	_buildQuadtree() {
		// Clear existing quadtree
		// var ctx = document.getElementById('canvas').getContext('2d');
		// ctx.clearRect(0, 0, 640, 480);
		this._quadTree.clear();

		// Build quad tree
		for (let i = this._entities.size - 1; i >= 0; i--) {
			const entity = this._entities.get(i);
			// If entity has div
			if (entity.getComponentByType('div')) {
				const div = entity.getComponentByType('div').getDiv();
				const rect = div.getBoundingClientRect();

				// Skip if div is not displayed
				if (div.style.display === 'none' || div.style.visibility === 'hidden') {
					this._labels[entity.getName()].check = false;
					continue;
				}
				// Skip if div is out of window
				if (this._isOutOfWindow(rect)) {
					this._labels[entity.getName()].check = false;
					continue;
				}

				// Set label info
				const entityLabel = this._labels[entity.getName()];

				if (entityLabel === undefined) {
					continue;
				}
				this._labels[entity.getName()].x = rect.x;
				this._labels[entity.getName()].y = rect.y;
				this._labels[entity.getName()].width = rect.width;
				this._labels[entity.getName()].height = rect.height;
				this._labels[entity.getName()].check = true;
				this._labels[entity.getName()].collisions = [];
				this._labels[entity.getName()].isHidden = false;
				const camera = this._pioneer.get('main', 'camera', 'camera');
				this._labels[entity.getName()].z = entity.getCameraSpacePosition(camera).magnitude();

				// Insert into quad tree
				this._quadTree.insert(this._labels[entity.getName()]);
			}
		}
	}

	// Check collisions with Quadtree method
	_checkWithQuadtree() {
		// Check collision for each label
		for (const key of Object.keys(this._labels)) {
			const label = this._labels[key];
			if (!label.check) {
				continue;
			}
			const candidates = this._quadTree.retrieve(label);

			// Loop through candidates
			for (let i = 0; i < candidates.length; i++) {
				const candidate = candidates[i];
				if (candidate.check && this._checkCollision(candidate, label) && label.name !== candidate.name) {
					if (this._overlap(candidate, label) >= this._overlapThreshold) {
						this._addCollision(candidate.name, label.name);
					}
				}
			}
		}
	}

	// Add a collision to the temporary array
	_addCollision(label1, label2) {
		if (this._collidingLabels.indexOf(label1) < 0) {
			this._collidingLabels.push(label1);
		}
		if (this._collidingLabels.indexOf(label2) < 0) {
			this._collidingLabels.push(label2);
		}
		this._labels[label1].collisions.push(label2);
	}

	// Solve collisions
	_fixCollisions() {
		// Order labels by weight or z order
		this._collidingLabels.sort((a, b) => {
			if (this.getWeight(b) - this.getWeight(a) !== 0) {
				return this.getWeight(b) - this.getWeight(a);
			} else {
				return this.getZ(a) - this.getZ(b);
			}
		});

		// Always add the selection as the higher seed in collisions
		// if visible
		const currentId = app.selector.getCurrentId();
		if (currentId in this._labels) {
			const entity = this._entities.get(currentId);
			if (entity.getComponentByType('div')) {
				const div = entity.getComponentByType('div').getDiv();
				if (div.style.display !== 'none') {
					this._collidingLabels.unshift(currentId);
				}
			}
		}

		// For each label ordered by weight and z order
		// determine if we need to show it or not
		for (let i = 0; i < this._collidingLabels.length; i++) {
			const label = this._collidingLabels[i];
			// Skip already hidden labels
			// to avoid waterfall effect
			if (this._labels[label].isHidden) {
				continue;
			} else {
				// Show label
				this._showLabel(label);
			}
			// Hide its collisions
			for (let j = 0; j < this._labels[label].collisions.length; j++) {
				const collision = this._labels[label].collisions[j];
				this._hideLabel(collision);
			}
		}

		// Restore labels that are not conflicting
		this._restoreLabels();
	}

	// Restore label visibility
	_restoreLabels() {
		for (const key of Object.keys(this._labels)) {
			const label = this._labels[key];
			if (!label.isHidden) {
				this._showLabel(label.name);
			}
			if (label.collisions.length === 0) {
				this._showLabel(label.name);
			}
		}

		// Always show current selection
		const currentId = app.selector.getCurrentId();
		if (currentId in this._labels) {
			this._showLabel(currentId);
		}
	}

	// Show a label
	_showLabel(label) {
		const object = this._pioneer.get('main', label);
		const div = object.getComponentByType('div').getDiv();
		div.classList.add(this._activeClass);
		div.classList.remove(this._hiddenClass);
		this._labels[label].isHidden = false;
	}

	// Hide a label
	_hideLabel(label) {
		const object = this._pioneer.get('main', label);
		const div = object.getComponentByType('div').getDiv();
		div.classList.add(this._hiddenClass);
		div.classList.remove(this._activeClass);
		this._labels[label].isHidden = true;
	}

	// Helper function to check if there is a collision between two rect
	_checkCollision(rect1, rect2) {
		if (rect1.x < rect2.x + rect2.width
			&& rect1.x + rect1.width > rect2.x
			&& rect1.y < rect2.y + rect2.height
			&& rect1.y + rect1.height > rect2.y) {
			return true;
		} else {
			return false;
		}
	}

	// Calculate overlap percentage on two rect
	_overlap(rect1, rect2) {
		const overlap = (Math.min(rect1.x + rect1.width, rect2.x + rect2.width) - Math.max(rect1.x, rect2.x))
						* (Math.min(rect1.y + rect1.height, rect2.y + rect2.height) - Math.max(rect1.y, rect2.y));
		const a1 = rect1.width * rect1.height;
		const a2 = rect2.width * rect2.height;
		const percentage = overlap / (a1 + a2 - overlap);
		return percentage;
	}

	// Helper function to check if rect is outside of window
	_isOutOfWindow(rect) {
		if (rect.x > window.innerWidth || rect.x + rect.width < 0 || rect.y > window.innerHeight || rect.y + rect.height < 0) {
			return true;
		} else {
			return false;
		}
	}
}


export default LabelManager;
