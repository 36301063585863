/* eslint-disable camelcase */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { useSnapshot } from 'valtio';

import { appStore, spacecraftStore, poiStore, gmapStore } from '../../managers/globalState';
import globalRefs from '../../managers/globalRefs';

import Overlay from '../overlays';
import GridList from '../grid_list';
import Filter from '../filter';

import SpacecraftSVG from '../../../www/assets/images/spacecraft.svg';
import ar from "../../languages/ar"
import '../../../www/assets/css/overlays/missions.css';

import {
	MISSION_CATEGORIES,
	MISSION_TIME_GROUPS,
	MISSION_TIME_GROUP_CATEGORIES,
	MISSION_TIMES,
	MISSIONS_TIME_GROUP_INDEX,
	MISSIONS_CATEGORY_INDEX
} from '../../config/constants';
import { Config } from '../../config/config';
import { getSpacecraftParamFromId, formattedTimeSinceDate } from '../../helpers/tools';


/**
 * Constant vars
 */
const THUMB_WIDTH = 217;
const THUMB_HEIGHT = 162;
const TITLE_TEXT = 'البعثات الفضائية';


/**
 * Individual MissionItem component to represent each mission in the grid
 */
const MissionItem = props => {
	const { title, constellationIds, time_in_orbit, date, futureMission, thumb, onItemClick, isMobile } = props;
	const thumbRef = useRef();

	const validImgUrl = thumb.startsWith('/');
	const imgSrcUrl = `${Config.baseCe2s2Url}${thumb}`;
	const spacecraftId = constellationIds[0]; // First spacecraft in constellation.

	const onClick = () => {
		const spacecraftParam = getSpacecraftParamFromId(spacecraftId);
		onItemClick(spacecraftParam);
	};

	/**
	 * Todo: calling getBoundingClientRect on every mission item on resize is not good for performance.
	 * There should be a simpler, pure CSS solution. See task #3209.
	 */

	useEffect(() => {
		const maintainAspectRatio = () => {
			const width = thumbRef?.current?.getBoundingClientRect().width;
			if (width) { thumbRef.current.style.height = `${THUMB_HEIGHT / THUMB_WIDTH * width}px`; }
		};
		window.addEventListener('resize', maintainAspectRatio);
		maintainAspectRatio();
		return () => {
			window.removeEventListener('resize', maintainAspectRatio);
		};
	}, []);

	const launchDate = new Date(date);
	const missionTime = futureMission ? `${MISSION_TIMES.FUTURE} ${date}` : `${MISSION_TIMES.CURRENT} ${date && !isNaN(launchDate) ? formattedTimeSinceDate(launchDate) : time_in_orbit}`;

	return validImgUrl && (
		<div className='mission-item' onClick={onClick}>
			<div className='mission-thumbnail' ref={thumbRef} style={!isMobile ? { width: `${THUMB_WIDTH}px`, height: `${THUMB_HEIGHT}px` } : null}>
				<img className='thumbnail' src={imgSrcUrl} alt={title} />
			</div>
			<h4 className='mission-title'>{`${title}\u00a0›`}</h4>
			<span className='mission-time'>{missionTime}</span>
		</div>
	);
};

/**
 * Missions Overlay component
 */
const MissionsOverlay = ({ closeOverlay }) => {
	// Refs.
	const mainRef = useRef();
	const scrollableNodeRef = React.createRef();

	// State snapshots.
	const { isMobile, isWindows } = useSnapshot(appStore.state);
	const { spacecraftList } = useSnapshot(spacecraftStore.state);

	// Time group state and update callback.
	const [timeIndex, setTimeIndex] = useState(MISSIONS_TIME_GROUP_INDEX);
	const updateTimeGroup = useCallback(newTimeIndex => {
		setTimeIndex(newTimeIndex);
	}, []);

	// Category state and update callback.
	const [categoryIndex, setCategoryIndex] = useState(MISSIONS_CATEGORY_INDEX);
	const updateCategory = useCallback(newCategoryIndex => {
		setCategoryIndex(newCategoryIndex);
	}, []);

	// Vars.
	const timeGroup = MISSION_TIME_GROUPS[timeIndex][0];
	const category = MISSION_CATEGORIES[categoryIndex];
  // console.log("missions", timeIndex, MISSIONS_TIME_GROUP_INDEX, MISSION_TIME_GROUPS)
	// data filtering

	const futureMission = timeGroup === 'Future';
	const isFiltered = timeGroup || categoryIndex !== MISSIONS_CATEGORY_INDEX;

	const filteredSpacecraftList = isFiltered
		? spacecraftList.filter(mission => {
			const timeGroupFilter = mission.categories.includes(MISSION_TIME_GROUP_CATEGORIES[timeGroup]);
			const categoryFilter = categoryIndex === MISSIONS_CATEGORY_INDEX || mission.categories.includes(category[0].toLocaleLowerCase());
			return timeGroupFilter && categoryFilter;
		})
		: spacecraftList;


	// navToMission callback
	const navToMission = useCallback(spacecraftParam => {
		const { getManager } = globalRefs;
		closeOverlay(true);

		getManager('route').navigateToSpacecraft(spacecraftParam);
	}, [closeOverlay]);

	const itemPadding = isMobile ? [20, 20] : [50, 40];
	const overlayComponents = (
		<>
			<Overlay.Header
				back
				onClose={closeOverlay}
				title={TITLE_TEXT}
				Icon={SpacecraftSVG}
			>
				<Filter type='dropdown' title={ar.time} allItems={MISSION_TIME_GROUPS.map(v => v[1])} selectedIndex={timeIndex} updateItem={updateTimeGroup} />
				<Filter type='dropdown' title={ar.category} allItems={MISSION_CATEGORIES.map(v => v[1])} selectedIndex={categoryIndex} updateItem={updateCategory} />
			</Overlay.Header>

			{ filteredSpacecraftList?.length ?
				(
					<div className='content-mask'>
						<GridList
							rowClassName='flex-grid-row'
							minPerRow={2}
							dataList={filteredSpacecraftList}
							itemSize={THUMB_WIDTH + itemPadding[0]}
							itemPadding={isMobile ? [20, 20] : [50, 40]}
							itemComponent={<MissionItem isMobile={isMobile} onItemClick={navToMission} futureMission={futureMission} />}
							scrollableRef={isWindows ? scrollableNodeRef : mainRef}
						/>
					</div>
				)
				: <Overlay.NoResults />}

		</>
	);
	// compose overlay
	return (
		<Overlay type='missions' ref={mainRef}>
			{isWindows ?
				<SimpleBar scrollableNodeProps={{ ref: scrollableNodeRef }} style={{ height: '100%', width: '100%' }}>
					{overlayComponents}
				</SimpleBar> :
				overlayComponents}
		</Overlay>
	);
};

export default MissionsOverlay;
