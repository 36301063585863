import * as Pioneer from 'pioneer';

/**
 * Functions for enabling and disabling special features on entities.
 */
export class Mapping {
	/**
	 * Gets all possible mapping types for a given entity.
	 * @param {string} entityName
	 * @returns string[]
	 */
	static getTypes(entityName) {
		return Mapping._types[entityName] ?? [];
	}

	/**
	 * Sets the entity to use the mapping given by the type.
	 * @param {Pioneer.Scene} scene
	 * @param {string} entityName
	 * @param {string} type
	 */
	static async set(scene, entityName, type) {
		// Enable the type and wait for it to be loaded.
		await Mapping.setEnabled(scene, entityName, type, true);
		// Disable all of the other non-matching types, including basic.
		for (const otherType of Mapping.getTypes(entityName)) {
			if (otherType !== type) {
				Mapping.setEnabled(scene, entityName, otherType, false);
			}
		}
		if (type !== 'basic') {
			Mapping.setEnabled(scene, entityName, 'basic', false);
		}
	}

	/**
	 * Enables or disables the given type. Used as a mapping from the 'type' to actual functions.
	 * @param {Pioneer.Scene} scene
	 * @param {string} entityName
	 * @param {string} type
	 * @param {boolean} enabled
	 * @private
	 */
	static async setEnabled(scene, entityName, type, enabled) {
		if (type === 'basic') {
			Mapping.setBasic(scene, entityName, enabled);
		}
		else if (type.startsWith('cmts')) {
			await Mapping.setCMTS(scene, entityName, type, enabled);
		}
		else {
			throw new Error(`Invalid type ${type}.`);
		}
	}

	/**
	 * Sets the entity to use the basic spheroidLOD.
	 * @param {Pioneer.Scene} scene
	 * @param {string} entityName
	 * @param {boolean} enabled
	 * @returns {Promise<void>}
	 * @private
	 */
	static async setBasic(scene, entityName, enabled) {
		const entity = scene.getEntity(entityName);
		if (entity === null) {
			throw new Error(`No entity named '${entityName}' exists.`);
		}
		const spheroidLOD = entity.getComponent('basic');
		if (spheroidLOD === null || spheroidLOD.getType() !== 'spheroidLOD') {
			throw new Error(`The entity '${entityName}' does not have a spheroidLOD named 'basic'.`);
		}
		spheroidLOD.setEnabled(enabled);
		if (enabled) {
			// Make it invisible, load it, and make it visible.
			spheroidLOD.setVisible(false);
			await spheroidLOD.getLoadedPromise();
			spheroidLOD.setVisible(true);
		}
	}

	/**
	 * Sets the entity to use CMTS.
	 * @param {Pioneer.Scene} scene
	 * @param {string} entityName
	 * @param {string} type
	 * @param {boolean} enabled
	 * @returns {Promise<void>}
	 * @private
	 */
	static async setCMTS(scene, entityName, type, enabled) {
		const entity = scene.getEntity(entityName);
		if (entity === null) {
			throw new Error(`No entity named '${entityName}' exists.`);
		}
		if (enabled && !entity.get('cmts')) {
			const cmts = entity.addComponentByClass(Pioneer.CMTSComponent, type);
			if (entityName === 'mars') {
				// Set the base urls.
				cmts.setBaseUrl('color', '$DYNAMIC_ASSETS_URL/cmts/1/' + entityName + '/color');
				cmts.setBaseUrl('height', '$DYNAMIC_ASSETS_URL/cmts/1/' + entityName + '/height');
			}
			else if (entityName === 'moon') {
				// Set the base urls.
				cmts.setBaseUrl('color', '$DYNAMIC_ASSETS_URL/cmts/' + entityName + '/color');
				cmts.setBaseUrl('normal', '$DYNAMIC_ASSETS_URL/cmts/' + entityName + '/normal');
				cmts.setBaseUrl('height', '$DYNAMIC_ASSETS_URL/cmts/' + entityName + '/height');
			}
			// Make it invisible, load it, and make it visible.
			cmts.setVisible(false);
			await cmts.getLoadedPromise();
			await cmts.getTilesLoadedPromise();
			cmts.setVisible(true);
		}
		else if (!enabled && entity.get('cmts')) {
			entity.removeComponent(entity.getComponentByType('cmts'));
		}
	}

	/**
	 * @type {Object<string, string[]>}
	 * @private
	 */
	static _types = {
		mars: ['cmts']
	};
}
