import * as THREE from 'three';

export class ShaderChunkLogDepth {
	/** Uniforms for the built-in shaders. */
	static Uniforms = {
		invertDepth: 'float',
		nearDistance: 'float',
		midDistance: 'float'
	};

	/** Code for the header of the vertex shader. */
	static VertexHead = `
		#ifdef L_EXT_frag_depth
			varying float depth;
		#else
			uniform float nearDistance;
			uniform float midDistance;
		#endif
	`;

	/** Code for the end of the vertex shader main() function. */
	static Vertex = `
		#ifdef L_EXT_frag_depth
			depth = gl_Position.w;
		#else
			float z = gl_Position.w;
			if (z < midDistance) {
				gl_Position.z = nearDistance * (z - midDistance) / (midDistance - nearDistance);
			}
			else {
				float logFactor = 0.01254291648; // 1 / log2(1e24 - midDistance + 1.0)
				gl_Position.z = log2(z - midDistance + 1.0) * logFactor;
				gl_Position.z *= gl_Position.w;
			}
		#endif
	`;

	/** Code for the header of the fragment shader. */
	static FragmentHead = `
		#ifdef L_EXT_frag_depth
			uniform float invertDepth;
			uniform float nearDistance;
			uniform float midDistance;
			varying float depth;
		#endif
	`;

	/** Code for the end of the fragment shader main() function. */
	static Fragment = `
		#ifdef L_EXT_frag_depth
			float logFactor = 0.01254291648; // 1 / log2(1 + 1e24)
			float nearFactor = 0.5 * (depth - nearDistance) / (midDistance - nearDistance);
			float farFactor = 0.5 * (1.0 + log2(1.0 + depth - midDistance) * logFactor);
			gl_FragDepthEXT = (1.0 - 2.0 * invertDepth) * (depth >= midDistance ? farFactor : nearFactor) + invertDepth;
		#endif
	`;

	/** Three.js uniform variables for custom materials.
	 *  Eventually I'd like to remove the need for this by having every material be in the shaders folder. */
	static ThreeUniforms = {
		invertDepth: new THREE.Uniform(0),
		nearDistance: new THREE.Uniform(0),
		midDistance: new THREE.Uniform(0)
	};
};
