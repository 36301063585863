/**
 * Utility Functions for Checking Device Properties
 * Check if resolution is mobile.
 * NOTE: For most mobile (phone and tablet) portrait, but not tablet landscape or big tablet (e.g. iPad Pro).
 * Todo: better to define breakpoints as isMobile is misleading - separate functions and event listeners - see ticket #3186
 */
const isMobile = () => window.matchMedia('(max-width: 991.98px)').matches;

const isWindows = () => navigator.userAgent.includes('Windows');

const is2k = () => window.matchMedia('(min-width: 2100px)').matches;

const is4k = () => window.matchMedia('(min-width: 3840px)').matches;

const isLandscape = () => window.matchMedia('(orientation: landscape)').matches && window.matchMedia('(max-height: 32em)').matches;

const isTouch = () => 'ontouchstart' in window || window.navigator.msMaxTouchPoints > 0;

const canHover = () => !(matchMedia('(hover: none)').matches);

export {
	isMobile,
	isWindows,
	is2k,
	is4k,
	isLandscape,
	isTouch,
	canHover
};
