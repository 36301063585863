import { Entity } from './entity';
import * as Pioneer from 'pioneer';

/**
 * Parenting helper functions.
 */
export class Parenting {

	/**
	 * Gets the parent of the entity at the given time.
	 * @param {string} entityName
	 * @param {number} time
	 * @returns {string}
	 */
	static getParentOfEntity(entityName, time) {
		const entityOptions = Entity.getEntityOptions(entityName);
		if (entityOptions === undefined) {
			throw new Error(`There are no entity options for ${entityName}.`);
		}
		const parents = entityOptions.parents;
		const index = Pioneer.Sort.getIndex(time, parents, isStartTimeLessThanTime);

		if (index < parents.length && time === parents[index][0]) {
			return parents[index][1];
		}
		else if (index > 0) {
			return parents[index - 1][1];
		}
		else {
			return '';
		}
	}

	/**
	 * Gets all of the ancestors of an entity for all times.
	 * @param {string} entityName - The name of the entity.
	 * @returns {Set<string>}
	 */
	static getAllAncestorsOfEntity(entityName) {
		const parents = new Set();
		this._getAllAncestorsOfEntityRecursed(entityName, parents);
		return parents;
	}

	/**
	 * Gets all of the ancestors of an entity for all times.
	 * @param {string} entityName - The name of the entity.
	 * @param {Set<string>} parents - The parents set that will be added to.
	 * @private
	 */
	static _getAllAncestorsOfEntityRecursed(entityName, parents) {
		const entityOptions = Entity.getEntityOptions(entityName);
		if (entityOptions === undefined || entityOptions.parents === undefined) {
			return;
		}
		const parentTable = entityOptions.parents;
		for (const parentEntry of parentTable) {
			const parentName = parentEntry[1];
			if (!parents.has(parentName)) {
				parents.add(parentName);
				this._getAllAncestorsOfEntityRecursed(parentName, parents);
			}
		}
	}
}

/**
 * Returns true if a < b.
 * @param {[number, string]} a
 * @param {number} b
 */
function isStartTimeLessThanTime(a, b) {
	return a[0] < b;
}
