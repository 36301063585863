/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	Color,
	Entity,
	THREE,
	ThreeJsHelper
} from '../../internal';

/**
 * Environmental map component. The entity should also have a camera component.
 */
export class DynamicEnvironmentMapComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The face width of the highest resolution render. Must be power of two.
		 * @type {number}
		 * @private
		 */
		this._faceSize = 64;

		/**
		 * The color of the invalid area. Used for finding meshes with bad normals or faces.
		 * @type {Color}
		 * @private
		 */
		this._invalidColor = new Color(0, 0, 0, 1);
		this._invalidColor.freeze();

		// STUFF FOR RENDERING FACES

		/**
		 * The Three.js renderer.
		 * @type {THREE.WebGLRenderer}
		 * @private
		 */
		this._threeJsRenderer = this.getEntity().getScene().getEngine().__getThreeJsRenderer();

		/**
		 * The three js cameras.
		 * @type {THREE.PerspectiveCamera[]}
		 * @private
		 */
		this._threeJsFaceCameras = [];

		/**
		 * This render targets.
		 * @type {THREE.WebGLRenderTarget[]}
		 * @private
		 */
		this._threeJsFaceRenderTargets = [];

		// STUFF FOR RENDERING FINAL TEXTURE

		/**
		 * The ThreeJS quad.
		 * @type {THREE.Mesh<THREE.BufferGeometry, THREE.RawShaderMaterial>}
		 * @private
		 */
		this._threeJsQuad = new THREE.Mesh();

		/**
		 * The ThreeJS scene.
		 * @type {THREE.Scene}
		 * @private
		 */
		this._threeJsScene = new THREE.Scene();

		/**
		 * The ThreeJS cube camera.
		 * @type {THREE.OrthographicCamera}
		 * @private
		 */
		this._threeJsCubeCamera = new THREE.OrthographicCamera(-1, 1, -1, 1, -1, 1);

		/**
		 * This render target is the texture that will be used as the environment map.
		 * @private
		 */
		this._envMapTexture = new THREE.WebGLRenderTarget(this._faceSize * 4, this._faceSize * 4, { minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter, wrapS: THREE.ClampToEdgeWrapping, wrapT: THREE.ClampToEdgeWrapping });

		// Setup the cameras.
		this._setupFaceCameras();

		// Setup the quad for the final render.
		this._setupQuad();
	}

	/**
	 * Gets the face size.
	 * @return {number}
	 */
	getFaceSize() {
		return this._faceSize;
	}

	/**
	 * Sets the face size. Defaults to 64.
	 * @param {number} faceSize
	 */
	setFaceSize(faceSize) {
		this._faceSize = faceSize;
		if (this._envMapTexture.width !== this._faceSize * 4) {
			this._envMapTexture.setSize(this._faceSize * 4, this._faceSize * 4);
			this._threeJsQuad.material.uniforms['faceSize'].value = this._faceSize;
		}
		for (let face = 0; face < 6; face++) {
			this._threeJsFaceRenderTargets[face].setSize(this._faceSize, this._faceSize);
		}
	}

	/**
	 * Gets the color of the invalid area. Used for finding meshes with bad normals or faces.
	 * @returns {Color}
	 */
	getInvalidColor() {
		return this._invalidColor;
	}

	/**
	 * Sets the color of the invalid area. Used for finding meshes with bad normals or faces.
	 * @param {Color} invalidColor
	 */
	setInvalidColor(invalidColor) {
		this._invalidColor.thaw();
		this._invalidColor.copy(invalidColor);
		this._invalidColor.freeze();
		if (this._threeJsQuad !== null) {
			ThreeJsHelper.setUniformColorRGB(this._threeJsQuad.material, 'invalidColor', this._invalidColor);
		}
	}

	/**
	 * Gets the environment map texture.
	 * @returns {THREE.Texture}
	 */
	getTexture() {
		return this._envMapTexture.texture;
	}

	// INTERNALS

	/**
	 * Cleans up the component.
	 * @override
	 * @internal
	 */
	__destroy() {
		ThreeJsHelper.destroyMaterial(this._threeJsQuad.material, true);
		ThreeJsHelper.destroyObject(this._threeJsQuad);
		ThreeJsHelper.destroyRenderTarget(this._envMapTexture);
		for (let i = 0; i < this._threeJsFaceRenderTargets.length; i++) {
			ThreeJsHelper.destroyRenderTarget(this._threeJsFaceRenderTargets[i]);
		}
		super.__destroy();
	}

	/**
	 * Renders the camera. Called by Viewport.
	 * @internal
	 */
	__render() {
		// Update the projection matrix.
		this._updateProjectionMatrix();

		// Set the camera's orientation for each of the face cameras.
		const sqrt2 = 0.7071067811865476;
		_tempThreeJsQuaternion.set(0, 0, -sqrt2, sqrt2);
		this._threeJsFaceCameras[0].setRotationFromQuaternion(_tempThreeJsQuaternion);
		_tempThreeJsQuaternion.set(0, 0, 0, 1);
		this._threeJsFaceCameras[1].setRotationFromQuaternion(_tempThreeJsQuaternion);
		_tempThreeJsQuaternion.set(0, 0, sqrt2, sqrt2);
		this._threeJsFaceCameras[2].setRotationFromQuaternion(_tempThreeJsQuaternion);
		_tempThreeJsQuaternion.set(0, 0, 1, 0);
		this._threeJsFaceCameras[3].setRotationFromQuaternion(_tempThreeJsQuaternion);
		_tempThreeJsQuaternion.set(0.5, -0.5, -0.5, 0.5);
		this._threeJsFaceCameras[4].setRotationFromQuaternion(_tempThreeJsQuaternion);
		_tempThreeJsQuaternion.set(-0.5, 0.5, -0.5, 0.5);
		this._threeJsFaceCameras[5].setRotationFromQuaternion(_tempThreeJsQuaternion);

		// Render each of the face cameras to the render targets.
		for (let face = 0; face < 6; face++) {
			this._threeJsRenderer.setRenderTarget(this._threeJsFaceRenderTargets[face]);
			this._threeJsRenderer.render(this.getEntity().getScene().getThreeJsScene(), this._threeJsFaceCameras[face]);
		}

		// Render to the environment map texture using the render targets.
		this._threeJsRenderer.setRenderTarget(this._envMapTexture);
		// this._threeJsRenderer.setRenderTarget(null);
		this._threeJsRenderer.render(this._threeJsScene, this._threeJsCubeCamera);
	}

	/**
	 * Sets up the cameras.
	 * @private
	 */
	_setupFaceCameras() {
		for (let face = 0; face < 6; face++) {
			this._threeJsFaceCameras.push(new THREE.PerspectiveCamera(90.0, 1.0, 0.1, 1000));
			const projectionMatrix = new THREE.Matrix4();
			projectionMatrix.set(
				1, 0, 0, 0,
				0, 0, 1, 0,
				0, 0, 0, 1,
				0, 1, 0, 0);
			this._threeJsFaceCameras[face].projectionMatrix = projectionMatrix;
			const projectionMatrixInverse = new THREE.Matrix4();
			projectionMatrixInverse.set(
				1, 0, 0, 0,
				0, 0, 0, 1,
				0, 1, 0, 0,
				0, 0, 1, 0);
			this._threeJsFaceCameras[face].projectionMatrixInverse = projectionMatrixInverse;
			this._threeJsFaceCameras[face].layers.set(1);

			const renderTarget = new THREE.WebGLRenderTarget(this._faceSize, this._faceSize, { minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter });
			this._threeJsFaceRenderTargets.push(renderTarget);
		}
	}

	/**
	 * Updates the projection.
	 * @private
	 */
	_updateProjectionMatrix() {
		const cameraComponent = /** @type {CameraComponent} */(this.getEntity().get('camera'));
		if (cameraComponent === null) {
			return;
		}
		const f1 = 1.0 - Number.EPSILON;
		const f2 = -cameraComponent.getAutoNearDistance() * (2.0 - Number.EPSILON);
		for (let face = 0; face < 6; face++) {
			this._threeJsFaceCameras[face].projectionMatrix.elements[6] = f1;
			this._threeJsFaceCameras[face].projectionMatrix.elements[14] = f2;
			this._threeJsFaceCameras[face].projectionMatrixInverse.elements[11] = 1 / f2;
			this._threeJsFaceCameras[face].projectionMatrixInverse.elements[15] = -f1 / f2;
		}
	}

	/**
	 * Sets the quad meshes up.
	 */
	_setupQuad() {
		// Setup geometry.
		const geometry = new THREE.BufferGeometry();
		const meshPositions = new Float32Array([-1, -1, 0, 1, -1, 0, 1, 1, 0, -1, 1, 0]);
		const meshIndices = new Uint16Array([0, 1, 2, 0, 2, 3]);
		geometry.setAttribute('position', new THREE.BufferAttribute(meshPositions, 3));
		geometry.setIndex(new THREE.BufferAttribute(meshIndices, 1));

		// Setup material uniforms.
		const uniforms = {
			textures: new THREE.Uniform([]),
			faceSize: new THREE.Uniform(this._faceSize),
			invalidColor: new THREE.Uniform(new THREE.Vector3(this._invalidColor.r, this._invalidColor.g, this._invalidColor.b))
		};
		for (let face = 0; face < 6; face++) {
			uniforms['textures'].value.push(this._threeJsFaceRenderTargets[face].texture);
		}

		// Setup material.
		const material = new THREE.RawShaderMaterial({
			uniforms: uniforms,
			vertexShader: `
				attribute vec3 position;
				varying vec2 xy;
				void main() {
					gl_Position = vec4(position, 1.0);
					xy = position.xy;
				}`,
			fragmentShader: `
				precision highp float;

				uniform sampler2D textures[6];
				uniform float faceSize;
				uniform vec3 invalidColor;

				varying vec2 xy;

				void adjustTextureCoordsForBorders(inout int textureIndex, inout vec2 xyInTexture, in float pixelSize) {
					// Get the pixells in pixel-space.
					vec2 xyInPixels = xyInTexture * pixelSize;

					// Flip the x since this code is for surface cubes, but we're inside out.
					xyInPixels.x = pixelSize - xyInPixels.x;

					// If it's a border, adjust the pixel it's reading the next pixel in one face over.
					// This allows for nice linear filtering to happen on the material side of things.
					if (xyInPixels.x < 0.6 || xyInPixels.x > pixelSize - 0.6 || xyInPixels.y < 0.6 || xyInPixels.y > pixelSize - 0.6) {
						if (0 <= textureIndex && textureIndex <= 3) { // One of the horizontal faces
							if (xyInPixels.x > pixelSize - 0.6) {
								xyInPixels.x = 1.5;
								textureIndex = (textureIndex + 1);
								if (textureIndex == 4) {
									textureIndex = 0;
								}
							}
							else if (xyInPixels.x < 0.6) {
								xyInPixels.x = pixelSize - 1.5;
								textureIndex = textureIndex - 1;
								if (textureIndex == -1) {
									textureIndex = 3;
								}
							}
						}
						if (textureIndex == 0) {
							if (xyInPixels.y < 0.6) {
								xyInPixels.y = pixelSize - 1.5;
								textureIndex = 5;
							}
							else if (xyInPixels.y > pixelSize - 0.6) {
								xyInPixels.y = 1.5;
								textureIndex = 4;
							}
						}
						if (textureIndex == 1) {
							if (xyInPixels.y < 0.6) {
								xyInPixels.y = pixelSize - xyInPixels.x;
								xyInPixels.x = pixelSize - 1.5;
								textureIndex = 5;
							}
							else if (xyInPixels.y > pixelSize - 0.6) {
								xyInPixels.y = xyInPixels.x;
								xyInPixels.x = pixelSize - 1.5;
								textureIndex = 4;
							}
						}
						if (textureIndex == 2) {
							if (xyInPixels.y < 0.6) {
								xyInPixels.x = pixelSize - xyInPixels.x;
								xyInPixels.y = 1.5;
								textureIndex = 5;
							}
							else if (xyInPixels.y > pixelSize - 0.6) {
								xyInPixels.x = pixelSize - xyInPixels.x;
								xyInPixels.y = pixelSize - 1.5;
								textureIndex = 4;
							}
						}
						if (textureIndex == 3) {
							if (xyInPixels.y < 0.6) {
								xyInPixels.y = xyInPixels.x;
								xyInPixels.x = 1.5;
								textureIndex = 5;
							}
							else if (xyInPixels.y > pixelSize - 0.6) {
								xyInPixels.y = pixelSize - xyInPixels.x;
								xyInPixels.x = 1.5;
								textureIndex = 4;
							}
						}
						if (textureIndex == 4) {
							if (xyInPixels.x < 0.6) {
								xyInPixels.x = pixelSize - xyInPixels.y;
								xyInPixels.y = pixelSize - 1.5;
								textureIndex = 3;
							}
							else if (xyInPixels.x > pixelSize - 0.6) {
								xyInPixels.x = xyInPixels.y;
								xyInPixels.y = pixelSize - 1.5;
								textureIndex = 1;
							}
							if (xyInPixels.y < 0.6) {
								xyInPixels.y = pixelSize - 1.5;
								textureIndex = 0;
							}
							else if (xyInPixels.y > pixelSize - 0.6) {
								xyInPixels.x = pixelSize - xyInPixels.x;
								xyInPixels.y = pixelSize - 1.5;
								textureIndex = 2;
							}
						}
						if (textureIndex == 5) {
							if (xyInPixels.x < 0.6) {
								xyInPixels.x = xyInPixels.y;
								xyInPixels.y = 1.5;
								textureIndex = 3;
							}
							else if (xyInPixels.x > pixelSize - 0.6) {
								xyInPixels.x = pixelSize - xyInPixels.y;
								xyInPixels.y = 1.5;
								textureIndex = 1;
							}
							if (xyInPixels.y < 0.6) {
								xyInPixels.x = pixelSize - xyInPixels.x;
								xyInPixels.y = 1.5;
								textureIndex = 2;
							}
							else if (xyInPixels.y > pixelSize - 0.6) {
								xyInPixels.y = 1.5;
								textureIndex = 0;
							}
						}
					}

					// Shrink all pixels so that they fit within the border.
					// The border pixels have already been modified so that they work with this equation.
					xyInPixels.x = ((pixelSize - 1.0) * xyInPixels.x - pixelSize) / (pixelSize - 3.0);
					xyInPixels.y = ((pixelSize - 1.0) * xyInPixels.y - pixelSize) / (pixelSize - 3.0);

					// Flip the x back.
					xyInPixels.x = pixelSize - xyInPixels.x;

					// Go back into unit-space.
					xyInTexture = xyInPixels / pixelSize;
				}

				void main() {
					// Make it pink everywhere else for easy debugging.
					vec3 color = invalidColor;
					// Get the mip level, size, and offset.
					float level = floor(1.0 - log2(1.0 - xy.y)); // 0 is base, then 1, etc.
					float mipSizeX = pow(2.0, -level); // 1, .5, .25, .125, etc.
					float mipOffsetY = 1.0 - pow(2.0, -level); // 0, .5, .75, .875, etc.
					// Get the xy within the mip level. Note the x value is * 3 for less computing further on.
					vec2 xyInMip;
					xyInMip.x = 0.5 * (xy.x + 1.0) / mipSizeX * 4.0;
					xyInMip.y = (xy.y + 1.0 - 2.0 * mipOffsetY) / mipSizeX;
					if (xyInMip.x <= 3.0) {
						int textureIndex = int(floor(xyInMip.y * 2.0) * 3.0 + floor(xyInMip.x));
						// Get the xy within the face/texture.
						vec2 xyInTexture;
						xyInTexture.x = 1.0 - xyInMip.x + floor(xyInMip.x);
						xyInTexture.y = 2.0 * (xyInMip.y - floor(xyInMip.y * 2.0) / 2.0);
						// Adjust the coordinates and face to account for borders.
						adjustTextureCoordsForBorders(textureIndex, xyInTexture, faceSize * mipSizeX);
						// Set the color based on the face (textureIndex) and the coords.
						if (textureIndex == 0) {
							color = texture2D(textures[0], xyInTexture).rgb;
						}
						else if (textureIndex == 1) {
							color = texture2D(textures[1], xyInTexture).rgb;
						}
						else if (textureIndex == 2) {
							color = texture2D(textures[2], xyInTexture).rgb;
						}
						else if (textureIndex == 3) {
							color = texture2D(textures[3], xyInTexture).rgb;
						}
						else if (textureIndex == 4) {
							color = texture2D(textures[4], xyInTexture).rgb;
						}
						else if (textureIndex == 5) {
							color = texture2D(textures[5], xyInTexture).rgb;
						}
					}
					gl_FragColor = vec4(color, 1.0);
				}`,
			depthTest: false,
			depthWrite: false
		});

		// Setup object.
		this._threeJsQuad = new THREE.Mesh(geometry, material);
		this._threeJsQuad.frustumCulled = false;
		this._threeJsScene.add(this._threeJsQuad);
	}
}

/**
 * A temporary ThreeJs Quaternion.
 * @type {THREE.Quaternion}
 * @private
 */
const _tempThreeJsQuaternion = new THREE.Quaternion();
