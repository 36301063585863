/** @module pioneer */
import {
	Freezable,
	MathUtils,
	Pool
} from '../internal';

/** A latitude, longitude, and altitude */
export class LatLonAlt extends Freezable {
	/**
	 * Pool for temporary variables.
	 * @returns {Pool<LatLonAlt>}
	 */
	static get pool() {
		return _pool;
	}

	/**
	 * Constructor.
	 * @param {number} lat - Latitude
	 * @param {number} lon - Longitude
	 * @param {number} alt - Altitude
	 */
	constructor(lat = 0, lon = 0, alt = 0) {
		super();

		/**
		 * Latitude
		 * @type {number}
		 * @private
		 */
		this._lat = lat;

		/**
		 * Longitude
		 * @type {number}
		 * @private
		 */
		this._lon = lon;

		/**
		 * Altitude
		 * @type {number}
		 * @private
		 */
		this._alt = alt;
	}

	/**
	 * Gets the latitude.
	 * @returns {number}
	 */
	get lat() {
		return this._lat;
	}

	/**
	 * Sets the latitude.
	 * @param {number} lat
	 */
	set lat(lat) {
		this.throwIfFrozen();
		this._lat = lat;
	}

	/**
	 * Gets the longitude.
	 * @returns {number}
	 */
	get lon() {
		return this._lon;
	}

	/**
	 * Sets the longitude.
	 * @param {number} lon
	 */
	set lon(lon) {
		this.throwIfFrozen();
		this._lon = lon;
	}

	/**
	 * Gets the altitude.
	 * @returns {number}
	 */
	get alt() {
		return this._alt;
	}

	/**
	 * Sets the altitude.
	 * @param {number} alt
	 */
	set alt(alt) {
		this.throwIfFrozen();
		this._alt = alt;
	}

	/**
	 * Returns a nicely formed string.
	 * @param {boolean} deg - whether or not to print in degrees or radians.
	 * @returns {string}
	 * @override
	 */
	toString(deg = false) {
		if (deg) {
			return '[' + MathUtils.radToDeg(this._lat) + ', ' + MathUtils.radToDeg(this._lon) + ', ' + this._alt + ']';
		}
		else {
			return '[' + this._lat + ', ' + this._lon + ', ' + this._alt + ']';
		}
	}

	/**
	 * Sets this to a.
	 * @param {LatLonAlt} a
	 */
	copy(a) {
		this.throwIfFrozen();
		this._lat = a._lat;
		this._lon = a._lon;
		this._alt = a._alt;
	}

	/**
	 * Sets this to the parameters.
	 * @param {number} lat
	 * @param {number} lon
	 * @param {number} alt
	 */
	set(lat, lon, alt) {
		this.throwIfFrozen();
		this._lat = lat;
		this._lon = lon;
		this._alt = alt;
	}
}

/**
 * @type {Pool<LatLonAlt>}
 */
const _pool = new Pool(LatLonAlt);
