import React from 'react';
import '../../www/assets/css/animation_date.css';
import methaneLegend from '../../www/assets/images/methane_legend.png';
import { left } from 'trim';
const animation_date = ({ date }) => {
    const short = new Date(date);
    const shortDate = short.toISOString().split('T')[0];

  return (
    <>
      <div className='animation-date-div'>
        {shortDate}
      </div>
      <div>
        <img src={methaneLegend} alt='methane legend' style={{
          position: 'absolute',
          top:'100px',
          left:"10px",
          width: "150px", 
          height: "150px",
         // border: "2px solid #000",
        }} />
      </div>
    </>
  );
};

export default animation_date;
