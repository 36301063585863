import { dateToUTCString, getDateFromUTCDateStr } from '../helpers/tools';
import { layersStore, timeStore, datasetStore } from './globalState';
import { TimeUtils } from 'pioneer';
import ar from '../languages/ar';


class TimeManager {
	constructor(pioneer) {
		// Set pioneer reference.
		this._pioneer = pioneer;

		/**
		 * Array of time rates.
		 * @type {Array<number>}
		 * @private
		 */
		this._rates = [
			-3600,
			-600,
			-300,
			-120,
			-60,
			-30,
			-10,
			-1,
			0,
			1,
			10,
			30,
			60,
			120,
			300,
			600,
			3600
		];

		/**
		 * Default time rate
		 * @type {number}
		 * @privvate
		 */
		this._defaultRate = 1;

		/**
		 * Current time rate index
		 * @type {number}
		 * @private
		 */
		this._currentIndex = this._rates.indexOf(this._defaultRate);

		/**
		 * Time rate stored when label hover is entered. Used if pausing then playing again.
		 * @type {number}
		 * @private
		 */
		this._timeRateAtLabelEnter = 0;

		/**
		 * Date limits for whole app
		 * @type {object<Date, Date>}
		 * @private
		 */
		this.dateLimits = {
			min: null,
			max: null
		};

		// Set pioneer reference.


		// Binds
		this.setRealTime = this.setRealTime.bind(this);
		this.setDefaultTime = this.setDefaultTime.bind(this);
		this.decreaseRate = this.decreaseRate.bind(this);
		this.increaseRate = this.increaseRate.bind(this);
	}

	/**
	 * Initialize the time manager.
	 */
	init() {
		const nowLocal = new Date();

		// Set date limits.
		this.setMin(new Date(Date.UTC(1970, 0, 1)));

		const todayStr = dateToUTCString(nowLocal);
		this.setMax(getDateFromUTCDateStr(todayStr));

		this.setTimeAndRate(false, true);
	}

	/**
	 * Triggered on label hover exit. Play time rate before label entered or 1 sec/sec if previous time rate is 0.
	 */
	playTimeOnLabelExit(setRealTime = false) {
		this.setRate(this._timeRateAtLabelEnter);
		timeStore.setGlobalState({ isRealTime: setRealTime });
	}

	/**
	 * Triggered on label hover enter. Pause time, sets this._timeRateAtLabelEnter var then sets time rate to 0
	 */
	pauseTimeOnLabelEnter() {
		this._timeRateAtLabelEnter = this.getTimeRate();
		this.setRate(0);
	}

	/**
	 * Gets current pioneer time and converts to Date
	 * @returns {Date}
	 */
	getDatetime() {
		const unixTime = TimeUtils.etToUnix(this._pioneer.getTime());

		return new Date(unixTime * 1000);
	}

	/**
	 * Converts to Et and sets pioneer time.
	 * @param {Date} datetime
	 */
	setTime(datetime) {
		const etTime = TimeUtils.unixToEt(datetime.valueOf() * 0.001);

		this._pioneer.setTime(etTime);
	}

	/**
	 * Get current time rate.
	 * @return {Number} Current time rate
	 */
	getTimeRate() {
		return this._pioneer.getTimeRate();
	}

	/**
	 * Returns the true real-time state, will return true when animating.
	 * isRealTime will be false during animation (better for switching to custom time).
	 * @returns {Boolean}
	 */
	calculateRealTime() {
		this.calculatedRealTime = this._rates[this._currentIndex] === 1 && Math.abs(this.getDatetime() - Date.now()) < 1000;
		return this.calculatedRealTime;
	}

	/**
	 * Check if rate is allowed in the this._rates.
	 * If so, set index, pioneer rate and global states.
	 * @param {Number} rate
	 */
	setRate(rate) {
		const index = this._rates.indexOf(rate);

		if (index !== -1) {
			this._currentIndex = index;
			this._pioneer.setTimeRate(rate);
			// Check animating to avoid going back to Live after label hover
			const realTime = this.calculateRealTime() && !datasetStore.stateSnapshot.datasetHasAnimation;

			timeStore.setGlobalState({
				isTimePlaying: rate !== 0,
				timeRate: rate,
				formattedTimeRate: this.getFormattedRate(rate),
				isRealTime: realTime
			});
		}
	}

	setTimeAndRate(realtime = false, resetTimeToNow = false, overrideGlobalState = false) {
		if (realtime) {
			// Set time to real time. Reset time to now if isSatellitesNow.
			this.setRealTime(resetTimeToNow);
		} else {
			// Set default time.
			this.setDefaultTime(resetTimeToNow);
		}

		// Override the calculated real time (When default rate is 1 but not on realtime).
		overrideGlobalState && timeStore.setGlobalState({ isRealTime: realtime });
	}

	/**
	 * Set time to now and rate to 1 sec/sec.
	 */
	setRealTime(resetTimeToNow = true) {
		// ground tracks disappearing has to do with this new date
		const now = new Date();
		resetTimeToNow && this.setTime(now);
		layersStore.setGlobalState({ groundTrackStartTime: now });
		this.setRate(1);

		layersStore.setGlobalState({ groundTrackStartTime: now });
	}

	/**
	 * Set time to now and rate to default rate.
	 */
	setDefaultTime(resetTimeToNow = true) {
		resetTimeToNow && this.setTime(new Date());
		this.setRate(this._defaultRate);
	}

	/**
	 * Get default time rate
	 */
	getDefaultTimeRate() {
		return this._defaultRate;
	}

	/**
	 * Decrease time rate if value is in the preset list.
	 */
	decreaseRate() {
		this.setIndex(this._currentIndex - 1);
	}

	/**
	 * Increase time rate if value is in the preset list.
	 */
	increaseRate() {
		this.setIndex(this._currentIndex + 1);
	}

	/**
	 * Set time rate index in the preset list.
	 * @param {int} rateIndex
	 */
	setIndex(rateIndex) {
		if (rateIndex < 0 || rateIndex > (this._rates.length - 1)) {
			console.warn(`Time Manager:: rateIndex: ${rateIndex} was out of bounds.`);
			return;
		}

		this.setRate(this._rates[rateIndex]);
	}

	/**
	 * Get display info for time rate.
	 * @returns {object} Rate and unit. E.g. `{ rate: 1, unit: ' sec/sec' }`
	 */
	getFormattedRate() {
		let rate = this._rates[this._currentIndex];
		const hrs = rate / 3600;
		const mins = rate / 60;
		let unit = '';
		if (hrs >= 1 || hrs <= -1) {
			rate = hrs;
			unit = ` ${ar.hr_per_sec}`;
		} else if (mins >= 1 || mins <= -1) {
			rate = mins;
			unit = ` ${ar.min_per_sec}`;
		} else {
			rate %= 60;
			unit = ` ${ar.sec_per_sec}`;
		}
		return { rate, unit };
	}

	/**
	 * @param {Date} date
	 */
	setMin(date) {
		this.dateLimits.min = date;
	}

	/**
	 * @param {Date} date
	 */
	setMax(date) {
		this.dateLimits.max = date;
	}

	/**
	 * Withing date limits.
	 */
	withinLimits(date) {
		// Make sure date is valid.
		if (isNaN(date.valueOf())) {
			return false;
		}

		// Get the rounded date.
		const dateStr = dateToUTCString(date);
		const roundedDate = getDateFromUTCDateStr(dateStr);
		const { min, max } = this.dateLimits;

		if (!min || !max) {
			return true;
		}

		return roundedDate >= min && roundedDate <= max;
	}
}

export default TimeManager;
