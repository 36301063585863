/* eslint-disable camelcase */
import { useCallback, useRef, createRef, useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { useSnapshot } from 'valtio';

import { eventsStore, appStore, uiStore } from '../../managers/globalState';
import globalRefs from '../../managers/globalRefs';

import Overlay from '../overlays';
import GridList from '../grid_list';
import Filter from '../filter';
import ThumbnailLoader from '../thumbnail_loader';

import GlobeIcon from '../../../www/assets/images/globe_icon.min.svg';
import EventSVG from '../../../www/assets/images/event-marker.svg';

import '../../../www/assets/css/overlays/events.css';

import { filterEvents, updateYearIndex, updateCategoryIndex, updateMediaIndex } from '../../helpers/processEvents';
import { EVENT_CATEGORIES, EVENT_MEDIA } from '../../config/constants';
import { Config } from '../../config/config';
import StaticIcon from '../../../www/assets/images/static_icon.svg';
import AnimatedIcon from '../../../www/assets/images/animated_icon.svg';
import ar from "../../languages/ar"

/**
 * Constant vars
 */
const THUMB_WIDTH = 159;
const THUMB_HEIGHT = 102;
const ITEM_PADDING = 30;
const TITLE_TEXT = ar.events;
const BUTTON_TEXT = ar.viewOnGlobe;

/**
 * ButtonText component
 */
const ButtonText = () => <span>{BUTTON_TEXT}</span>;

/**
 * Individual EventItem component to represent each event in the grid
 */
const EventItem = props => {
	// State to track hover
	const [isHovered, setIsHovered] = useState(false);
	const { title, urlParam, list_image_src, thumbAnimSrc, date, formatted_date, isAnimated, onItemClick } = props;

	const thumbPath = (isHovered && thumbAnimSrc) ? thumbAnimSrc : list_image_src;

	const srcUrl = `${Config.baseCe2s2Url}${thumbPath}`;

	const tipTitle = isAnimated ? 'Animated Event' : 'Static Event';

	const containerStyle = {
		width: `${THUMB_WIDTH}px`,
		height: `${THUMB_HEIGHT}px`
	};

	// Event handlers
	const handleMouseEnter = () => setIsHovered(true);
	const handleMouseLeave = () => setIsHovered(false);

	return (
		<figure className='event-item' onClick={() => onItemClick(urlParam)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<div className='image-time-container' style={containerStyle}>
				<img className={`thumbnail ${isHovered ? 'zoom' : ''}`} src={srcUrl} alt={title} />
				<div className='icon-date-container'>
					<div className='icon-group' aria-hidden='true' title={tipTitle} aria-label={tipTitle}>
						{isAnimated ? <AnimatedIcon /> : <StaticIcon />}
					</div>
					<time className='event-date' dateTime={date}>{formatted_date}</time>
				</div>
			</div>
			<figcaption className='event-title'>{title}</figcaption>
		</figure>
	);
};


/**
 * Events Overlay component
 */
const EventsOverlay = ({ closeOverlay }) => {
	const mainRef = useRef();
	const scrollableNodeRef = createRef();

	// get the global state
	const { yearIndex, categoryIndex, mediaIndex, events, eventYears } = useSnapshot(eventsStore.state);
	const { isWindows } = useSnapshot(appStore.state);

	// Store the category index locally as it shouldnt be set in the global state unless we View on Globe.
	const initialYearIndex = useRef(yearIndex);
	const initialCategoryIndex = useRef(categoryIndex);
	const initialMediaIndex	 = useRef(mediaIndex);

	// Filter events.
	const eventsYear = eventYears?.[yearIndex] || eventYears?.[0];
	const currentCategoryParam = categoryIndex !== null ? Array.from(EVENT_CATEGORIES.keys())[categoryIndex] : 'all';
	const currentFilter = Array.from(EVENT_MEDIA.keys())[mediaIndex];
	const allEventsFromYear = events?.[eventsYear];
	const filteredEvents = allEventsFromYear ? filterEvents(allEventsFromYear, categoryIndex, mediaIndex) : null;
	// console.log("filtered events: ", filteredEvents, allEventsFromYear,eventsYear)
	// navToEvent callback.
	const navToEvent = useCallback(eventParam => {
		closeOverlay(true);
		const { getManager } = globalRefs;
		console.log("navigate to: ", eventParam)
		getManager('route').navigateToEvent(eventParam);
	}, [closeOverlay]);

	// viewOnGlobe callback
	const viewOnGlobe = useCallback(() => {
		const { getManager } = globalRefs;
		// Close the overlay  pass false since no event was selected.
		closeOverlay(false);
		uiStore.setGlobalState({ isDetailPanelOpen: false });

		getManager('route').navigateToEventYear(eventsYear, currentCategoryParam, currentFilter);
	}, [closeOverlay, eventsYear, currentCategoryParam, currentFilter]);

	// on mount, update the dropdowns to the current year, but default category and media to all.
	useEffect(() => {
		updateYearIndex(yearIndex);
		updateCategoryIndex(0);
		updateMediaIndex(0);
		return () => {
			// on unmount, reset the dropdowns to the initial year and category.
			updateYearIndex(initialYearIndex.current);
			updateCategoryIndex(initialCategoryIndex.current);
			updateMediaIndex(initialMediaIndex.current);
		};
	}, []);


	const overlayComponents = (
		<>
			<Overlay.Header
				back
				onClose={closeOverlay}
				title={TITLE_TEXT}
				Icon={EventSVG}
			>
				<div className='events-header-content'>
					<div className='events-header-filters'>
						<Filter
							type='dropdown'
							title={ar.year}
							allItems={eventYears}
							selectedIndex={yearIndex}
							updateItem={updateYearIndex}
						/>
						<Filter
							type='dropdown'
							title={ar.category}
							allItems={Array.from(EVENT_CATEGORIES.values())}
							selectedIndex={categoryIndex}
							updateItem={updateCategoryIndex}
						/>
						<Filter
							type='dropdown'
							title={ar.media}
							allItems={Array.from(EVENT_MEDIA.values())}
							selectedIndex={mediaIndex}
							updateItem={updateMediaIndex}
						/>
					</div>
					<div className='globe-border'>
						<div className='button' onClick={viewOnGlobe}>
							<GlobeIcon />
							<ButtonText />
						</div>
					</div>
				</div>
			</Overlay.Header>

			{ filteredEvents === null ? <ThumbnailLoader loaderText={ar.loading} /> : null }
			{ filteredEvents?.length === 0 ? <Overlay.NoResults /> : null }
			{
				filteredEvents?.length
					? (
						<div className='content-mask'>
							<GridList
								rowClassName='flex-grid-row'
								minPerRow={2}
								dataList={filteredEvents}
								itemSize={THUMB_WIDTH + ITEM_PADDING * 2}
								itemPadding={[ITEM_PADDING, ITEM_PADDING]}
								itemComponent={<EventItem onItemClick={navToEvent} />}
								scrollableRef={isWindows ? scrollableNodeRef : mainRef}
							/>
						</div>
					)
					: null
			}

		</>
	);
	// compose overlay
	return (
		<Overlay type='events' ref={mainRef}>
			{isWindows ?
				<SimpleBar scrollableNodeProps={{ ref: scrollableNodeRef }} style={{ height: '100%' }}>
					{overlayComponents}
				</SimpleBar> :
				overlayComponents}
		</Overlay>
	);
};


export default EventsOverlay;
