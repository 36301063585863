/** @module pioneer */
import {
	AtmosphereComponent,
	BaseComponent,
	Cache,
	CameraComponent,
	ComponentRef,
	CubeMap,
	Entity,
	EntityRef,
	FastMap,
	Geometry,
	LatLonAlt,
	MaterialUtils,
	MathUtils,
	SpheroidComponent,
	THREE,
	ThreeJsHelper,
	Tile,
	Vector2,
	Vector3
} from '../../internal';

/**
 * The CMTS component.
 */
export class CMTSComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The ThreeJS scene.
		 * @type {THREE.Scene}
		 * @private
		 */
		this._threeJsScene = this.getEntity().getScene().getThreeJsScene();

		/**
		 * The end points for the texture names.
		 * @type {FastMap<string, { url: string, configuration: CMTSConfiguration }>}
		 * @private
		 */
		this._endPoints = new FastMap();

		/**
		 * The height scale if a height texture is used.
		 * @type {number}
		 * @private
		 */
		this._heightScale = 1;

		/**
		 * The number of end points still loading.
		 * @type {number}
		 * @private
		 */
		this._numEndPointsLoading = 0;

		/**
		 * The minimum level to which the tiles must split.
		 * @type {number}
		 * @private
		 */
		this._minLevel = Number.NEGATIVE_INFINITY;

		/**
		 * The maximum level to which the tiles can split.
		 * @type {number}
		 * @private
		 */
		this._maxLevel = Number.POSITIVE_INFINITY;

		/**
		 * A factor that determines when to split the tiles. Higher means more splits.
		 * @type {number}
		 * @private
		 */
		this._splitJoinThresholdFactor = 512.0;

		/**
		 * The tile size of the color configuration.
		 * @type {number}
		 * @private
		 */
		this._colorTileSize = 512;

		/**
		 * The entities used for shadows. Derived from the shadow entity names.
		 * @type {EntityRef[]}
		 * @private
		 */
		this._shadowEntities = [];

		/**
		 * The downloader for easier future access.
		 * @private
		 */
		this._engine = this.getEntity().getScene().getEngine();

		/**
		 * The positions of all active cameras.
		 * @type {Vector3[]}
		 * @private
		 */
		this._cameraPositions = [];

		/**
		 * The fields of view of all active cameras.
		 * @type {number[]}
		 * @private
		 */
		this._cameraFieldsOfView = [];

		/**
		 * The root tile.
		 * @type {CMTSTile[]}
		 * @private
		 */
		this._rootTiles = [null, null, null, null, null, null];

		/**
		 * A promise that resolves when all tiles are no longer transitioning.
		 * @type {Promise<void>}
		 * @private
		 */
		this._tilesLoadedPromise = null;

		/**
		 * The callback that gets called when all tiles are no longer transitioning.
		 * @type {() => any}
		 * @private
		 */
		this._transitionsCompleteCallback = null;

		/**
		 * A cache of textures, one for each tile.
		 * @type {Cache<Promise<THREE.Texture>>}
		 * @private
		 */
		this._textureCache = new Cache((textureUrl) => {
			return ThreeJsHelper.loadTexture(this, textureUrl, true, false);
		}, (texturePromise) => {
			texturePromise.then((texture) => {
				ThreeJsHelper.destroyTexture(texture);
			});
		});

		/**
		 * A counter that ensures that we don't do too many splits or joins at once.
		 * @type {number}
		 * @private
		 */
		this._numCurrentLoads = 0;

		/**
		 * Get the maximum number of loads that can happen at one time.
		 * @type {number}
		 * @private
		 */
		this._maxCurrentLoads = 10;

		/**
		 * A reference to the atmosphere component.
		 * @type {ComponentRef<AtmosphereComponent>}
		 * @private
		 */
		this._atmosphereComponentRef = new ComponentRef(this.getEntity().getScene());
		this._atmosphereComponentRef.setByType(this.getEntity().getName(), 'atmosphere');

		/**
		 * A reference to the spheroid component.
		 * @type {ComponentRef<SpheroidComponent>}
		 * @private
		 */
		this._spheroidComponentRef = new ComponentRef(this.getEntity().getScene());
		this._spheroidComponentRef.setByType(this.getEntity().getName(), 'spheroid');
		this._spheroidComponentRef.setRefChangedCallback(this._spheroidRefChangedCallback.bind(this));

		// Bind the callbacks to this.
		this._spheroidChangedCallback = this._spheroidChangedCallback.bind(this);

		// Lets the base component to check for valid orientation when determining whether this is visible.
		this.__setUsesEntityOrientation(true);
	}

	/**
	 * Sets a base url for the CMTS protocol for a given texture.
	 * @param {string} textureName
	 * @param {string} endPoint
	 */
	setBaseUrl(textureName, endPoint) {
		this.resetResources();

		// Set the end point.
		this._endPoints.set(textureName, { url: endPoint, configuration: null });
	}

	/**
	 * Gets the height scale if a height texture is used.
	 * @returns {number}
	 */
	getHeightScale() {
		return this._heightScale;
	}

	/**
	 * Sets the height scale if a height texture is used.
	 * @param {number} heightScale
	 */
	setHeightScale(heightScale) {
		this._heightScale = heightScale;
		this.resetResources();
	}

	/**
	 * Gets the minimum level to which the tiles must split.
	 * @returns {number}
	 */
	getMinLevel() {
		return this._minLevel;
	}

	/**
	 * Sets the minimum level to which the tiles must split. Defaults to negative infinity.
	 * @param {number} minLevel
	 */
	setMinLevel(minLevel) {
		this._minLevel = minLevel;
	}

	/**
	 * Gets the maximum level to which the tiles can split.
	 * @returns {number}
	 */
	getMaxLevel() {
		return this._maxLevel;
	}

	/**
	 * Sets the maximum level to which the tiles can split. Defaults to positive infinity.
	 * @param {number} maxLevel
	 */
	setMaxLevel(maxLevel) {
		this._maxLevel = maxLevel;
	}

	/**
	 * Gets the number of shadow entities. Can be used to enumerate the shadow entities.
	 * @returns {number}
	 */
	getNumShadowEntities() {
		return this._shadowEntities.length;
	}

	/**
	 * Returns the shadow entity or its name at the index.
	 * @param {number} index
	 * @returns {string | undefined}
	 */
	getShadowEntity(index) {
		return this._shadowEntities[index]?.getName();
	}

	/**
	 * Sets the shadow entities. Each element can be either the name of an entity or an entity itself.
	 * @param {string[]} shadowEntities
	 */
	setShadowEntities(shadowEntities) {
		this._shadowEntities = [];
		for (const shadowEntity of shadowEntities) {
			this._shadowEntities.push(new EntityRef(this.getEntity().getScene(), shadowEntity));
		}
		const shadowEntitiesEnabled = (shadowEntities.length > 0);
		for (let i = 0, l = this.getThreeJsMaterials().length; i < l; i++) {
			ThreeJsHelper.setDefine(this.getThreeJsMaterials()[i], 'shadowEntities', shadowEntitiesEnabled);
		}
	}

	/**
	 * Gets the frame-space position and height direction on the surface at the given frame-space position.
	 * Note that the height direction is not up with planetocentric coordinates.
	 * @param {Vector3} outPosition
	 * @param {Vector3} outHeightDir
	 * @param {Vector3} position
	 */
	getGroundPosition(outPosition, outHeightDir, position) {
		const spheroidComponent = this._spheroidComponentRef.get();
		if (spheroidComponent === null) {
			outPosition.copy(Vector3.NaN);
			return;
		}
		// Get the position on the surface of the spheroid.
		const lla = LatLonAlt.pool.get();
		spheroidComponent.llaFromXYZ(lla, position);
		lla.alt = 0;
		spheroidComponent.xyzFromLLA(outPosition, lla);
		// Get the height direction.
		spheroidComponent.upFromLLA(outHeightDir, lla);
		// If there is height data, add on the height.
		const heightEndPoint = this._endPoints.get('height');
		if (heightEndPoint !== undefined) {
			// Get the position is in "sphere" space and get the cmts coord uvFace.
			const uvFace = Vector3.pool.get();
			const posOnSphere = Vector3.pool.get();
			lla.alt = 1;
			Geometry.getXYZFromLLAOnSphere(posOnSphere, lla, 0);
			CubeMap.xyzToUVFace(uvFace, posOnSphere);
			// Get the corresponding root tile.
			let tile = this._rootTiles[uvFace.z];
			// Check if it is a valid tile.
			if (tile === undefined || tile === null) {
				Vector3.pool.release(posOnSphere);
				Vector3.pool.release(uvFace);
				LatLonAlt.pool.release(lla);
				return;
			}
			// Get the lowest level tile that the uv is within and has height data.
			while (tile.children.length > 0) {
				const levelFactor = 1 << (tile.getLevel() + 1);
				const xTile = Math.floor(uvFace.x * levelFactor - tile.getTileCoord().x * 2);
				const yTile = Math.floor(uvFace.y * levelFactor - tile.getTileCoord().y * 2);
				tile = tile.children[yTile * 2 + xTile];
			}
			while (!tile.hasHeightData() && tile.getParent() !== null) {
				tile = tile.getParent();
			}
			// Get the height and position within that tile.
			const height = tile.getHeight(uvFace);
			lla.alt = height;
			spheroidComponent.xyzFromLLA(outPosition, lla);
			// Cleanup.
			Vector3.pool.release(posOnSphere);
			Vector3.pool.release(uvFace);
		}
		LatLonAlt.pool.release(lla);
	}

	/**
	 * Returns true if tiles are all loaded.
	 * @returns {boolean}
	 */
	areTilesLoaded() {
		return this._tilesLoadedPromise === null;
	}

	/**
	 * Returns a promise when no more tiles are loading.
	 * @returns {Promise<void>}
	 */
	getTilesLoadedPromise() {
		return this._tilesLoadedPromise ?? Promise.resolve();
	}

	/**
	 * Sets the reference to use for the spheroid component, by name or the type index.
	 * @param {string | number} nameOrTypeIndex
	 */
	setSpheroidReference(nameOrTypeIndex) {
		if (typeof nameOrTypeIndex === 'string') {
			this._spheroidComponentRef.setByName(this.getEntity().getName(), nameOrTypeIndex);
		}
		else {
			this._spheroidComponentRef.setByType(this.getEntity().getName(), 'spheroid', nameOrTypeIndex);
		}
	}

	/**
	 * Cleans up the component.
	 * @override
	 * @internal
	 */
	__destroy() {
		// Remove the spheroid changed callback.
		const spheroidComponent = this._spheroidComponentRef.get();
		if (spheroidComponent !== null) {
			spheroidComponent.removeChangedCallback(this._spheroidChangedCallback);
		}

		super.__destroy();
	}

	/**
	 * Updates the component.
	 * @override
	 * @internal
	 */
	__update() {
		// Update the spheroid component reference.
		this._spheroidComponentRef.update();

		// If the end points are not loaded, there's nothing more to do.
		if (this.getLoadState() !== 'loaded' || this._spheroidComponentRef.get() === null) {
			return;
		}

		if (this._rootTiles[0] === null) {
			if (this._numEndPointsLoading === 0) {
				// Create the root tiles to start.
				for (let face = 0; face < 6; face++) {
					this._rootTiles[face] = new CMTSTile(this, null, face, 0, new Vector2(0, 0));
					this._rootTiles[face].forceLoad();
				}
			}
			else {
				return;
			}
		}

		// Set to true if any tile or configuration is still loading or unloading.
		let transitioning = false;

		// Get the positions of all cameras as lat, lon, alt in the frame of the spheroid.
		while (this._cameraPositions.length > this._engine.getNumViewports()) {
			this._cameraPositions.pop();
			this._cameraFieldsOfView.pop();
		}
		while (this._cameraPositions.length < this._engine.getNumViewports()) {
			this._cameraPositions.push(new Vector3());
			this._cameraFieldsOfView.push(1);
		}
		for (let i = 0, l = this._engine.getNumViewports(); i < l; i++) {
			const cameraPosition = this._cameraPositions[i];
			const cameraComponent = this._engine.getViewport(i).getCamera();
			cameraComponent.getEntity().getPositionRelativeToEntity(cameraPosition, Vector3.Zero, this.getEntity());
			cameraPosition.rotateInverse(this.getEntity().getOrientation(), cameraPosition);
			this._cameraFieldsOfView[i] = cameraComponent.getFieldOfView();
		}

		// Do the update on all of the tiles recursively.
		// If any tile is still transitioning, set transitioning to true.
		for (let face = 0; face < 6; face++) {
			transitioning = this._rootTiles[face].update() || transitioning;
		}

		// If there is no current promise (there were no tiles or configuration transitioning) and now there are,
		// Create the loaded promise and record its resolve callback.
		if (this._tilesLoadedPromise === null && transitioning) {
			this._tilesLoadedPromise = new Promise((resolve) => {
				this._transitionsCompleteCallback = resolve;
			});
		}

		// If the loaded promise callback exists and we're no longer transitioning,
		// Clear the loaded promise and callback and call the callback (the resolve function of the promise).
		if (this._tilesLoadedPromise !== null && !transitioning) {
			const callback = this._transitionsCompleteCallback;
			this._tilesLoadedPromise = null;
			this._transitionsCompleteCallback = null;
			callback();
		}
	}

	/**
	 * Prepares the component for render.
	 * @param {CameraComponent} camera
	 * @override
	 * @internal
	 */
	__prepareForRender(camera) {
		if (this._rootTiles[0] === null) {
			return;
		}

		// Set the orientation to the entity's orientation.
		ThreeJsHelper.setOrientationToEntity(this.getThreeJsObjects(), this.getEntity());

		// Prepare the root tiles for rendering.
		for (let face = 0; face < 6; face++) {
			this._rootTiles[face].prepareForRender(camera);
		}

		// Get the atmosphere.
		const atmosphere = this._atmosphereComponentRef.get();

		// Setup the regular uniforms.
		MaterialUtils.setUniforms(this.getThreeJsMaterials(), camera, this.getEntity(), this._shadowEntities, atmosphere, true);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void[]>}
	 * @override
	 * @protected
	 */
	__loadResources() {
		/** @type {Array<Promise<void>>} */
		const promises = [];
		for (let i = 0, l = this._endPoints.size; i < l; i++) {
			const entry = this._endPoints.getAt(i);
			const endPoint = entry.value.url;
			// Load the end point.
			this._numEndPointsLoading += 1;
			const promise = this.getEntity().getScene().getEngine().getDownloader().download(endPoint + '/configuration.json', false, -this.getEntity().getLeastCameraDepth()).then((download) => {
				if (download.status === 'failed') {
					throw new Error('Failed to download ' + endPoint + '/configuration.json');
				}
				if (download.status === 'completed' && typeof download.content === 'string') {
					const configuration = /** @type {CMTSConfiguration} */(JSON.parse(download.content));
					entry.value.configuration = configuration;
					if (entry.key === 'color') {
						this._colorTileSize = configuration.tile_size;
					}
					this._numEndPointsLoading -= 1;
				}
			});
			promises.push(promise);
		}

		// Return promise that resolves when all end points have been loaded.
		return Promise.all(promises);
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		for (let face = 0; face < 6; face++) {
			if (this._rootTiles[face] !== null) {
				this._rootTiles[face].destroy();
			}
		}
		this._rootTiles = [null, null, null, null, null, null];
	}

	/**
	 * Gets the number of loads currently happening.
	 * @returns {number}
	 * @internal
	 */
	__getNumCurrentLoads() {
		return this._numCurrentLoads;
	}

	/**
	 * Increments the number of loads currently happening. Used by CMTSTile.
	 * @internal
	 */
	__incNumCurrentLoads() {
		this._numCurrentLoads += 1;
	}

	/**
	 * Decrements the number of loads currently happening. Used by CMTSTile.
	 * @internal
	 */
	__decNumCurrentLoads() {
		this._numCurrentLoads -= 1;
	}

	/**
	 * Gets the maximum number of loads that can happen. Used by CMTSTile.
	 * @internal
	 */
	__getMaxCurrentLoads() {
		return this._maxCurrentLoads;
	}

	/**
	 * Gets the end points.
	 * @returns {FastMap<string, { url: string, configuration: CMTSConfiguration }>}
	 * @internal
	 */
	__getEndPoints() {
		return this._endPoints;
	}

	/**
	 * Returns true if thare are no end points loading.
	 * @returns {boolean}
	 * @internal
	 */
	__endPointsAreLoaded() {
		return this._numEndPointsLoading === 0;
	}

	/**
	 * Gets the texture cache. Used by CMTSTile.
	 * @returns {Cache<Promise<THREE.Texture>>}
	 * @internal
	 */
	__getTextureCache() {
		return this._textureCache;
	}

	/**
	 * Gets the camera positions. Used by CMTSTile.
	 * @returns {Vector3[]}
	 * @internal
	 */
	__getCameraPositions() {
		return this._cameraPositions;
	}

	/**
	 * Gets the camera fields of view. Used by CMTSTile.
	 * @returns {number[]}
	 * @internal
	 */
	__getCameraFieldsOfView() {
		return this._cameraFieldsOfView;
	}

	/**
	 * Gets the Three.js scene. Used by CMTSTile.
	 * @returns {THREE.Scene}
	 * @internal
	 */
	__getThreeJsScene() {
		return this._threeJsScene;
	}

	/**
	 * Gets the split/join threshold factor.
	 * @returns {number}
	 * @internal
	 */
	__getSplitJoinThresholdFactor() {
		return this._splitJoinThresholdFactor;
	}

	/**
	 * Gets the tile size of the color configuration.
	 * @returns {number}
	 * @internal
	 */
	__getColorTileSize() {
		return this._colorTileSize;
	}

	/*
	 * Gets the spheroid component this is using. Used by Tile.
	 * @returns {SpheroidComponent}
	 * @package
	 */
	__getSpheroidComponent() {
		return this._spheroidComponentRef.get();
	}

	/**
	 * Callback called when the spheroid reference is found or lost.
	 * @param {SpheroidComponent} oldRef
	 * @param {SpheroidComponent} newRef
	 * @private
	 */
	_spheroidRefChangedCallback(oldRef, newRef) {
		if (oldRef !== null) {
			oldRef.removeChangedCallback(this._spheroidChangedCallback);
		}
		if (newRef !== null) {
			newRef.addChangedCallback(this._spheroidChangedCallback);
		}
		this._spheroidChangedCallback();
	}

	/**
	 * Callback to be called when the spheroid component changed.
	 * @private
	 */
	_spheroidChangedCallback() {
		// Set the radii uniforms.
		const spheroidComponent = this._spheroidComponentRef.get();
		if (spheroidComponent !== null) {
			this.__setRadius(Math.max(spheroidComponent.getEquatorialRadius(), spheroidComponent.getPolarRadius()));
		}
		else {
			this.__setRadius(0);
		}
		this.resetResources();
	}
}

/**
 * A single tile with mesh, material, and bounds.
 * @extends Tile<CMTSTile>
 * @private
 */
class CMTSTile extends Tile {
	/**
	 * Constructor.
	 * @param {CMTSComponent} component
	 * @param {CMTSTile} parent
	 * @param {number} face
	 * @param {number} level
	 * @param {Vector2} tile
	 */
	constructor(component, parent, face, level, tile) {
		super(parent);

		/**
		 * The CMTS component.
		 * @type {CMTSComponent}
		 * @private
		 */
		this._component = component;

		/**
		 * The face.
		 * @type {number}
		 * @private
		 */
		this._face = face;

		/**
		 * The level.
		 * @type {number}
		 * @private
		 */
		this._level = level;

		/**
		 * The level exponential factor.
		 * @type {number}
		 * @private
		 */
		this._levelPow = Math.pow(2, -Math.max(0, level));

		/**
		 * The tile coordinates.
		 * @type {Vector2}
		 * @private
		 */
		this._tile = new Vector2();
		this._tile.copy(tile);

		/**
		 * True if this can't be split any more.
		 * @type {boolean}
		 * @private
		 */
		this._isLeaf = true;

		/**
		 * The center of the tile's surface, for distance checking.
		 * @type {Vector3}
		 * @private
		 */
		this._center = new Vector3();

		/**
		 * The center of the tile's surface, for tile offset checking.
		 * @type {LatLonAlt}
		 * @private
		 */
		this._centerLLA = new LatLonAlt();

		/**
		 * The "radius", the distance from the center to one of the corners, for distance checking.
		 * @type {number}
		 * @private
		 */
		this._radius = 0;

		/**
		 * The material used by the tile.
		 * @type {THREE.ShaderMaterial}
		 * @private
		 */
		this._threeJsMaterial = null;

		/**
		 * Gets the level to which the texture belongs for the given texture name.
		 * @type {FastMap<string, number>}
		 * @private
		 */
		this._textureLevels = new FastMap();

		/**
		 * Gets the texture promises that are acquired from the texture cache.
		 * @type {FastMap<string, Promise<THREE.Texture>>}
		 * @private
		 */
		this._texturePromises = new FastMap();

		/**
		 * A flag that says whether or not one of the non-positive textures is loading.
		 * @type {boolean}
		 * @private
		 */
		this._nonPositiveTextureLoading = false;

		/**
		 * The Three.js object of the tile.
		 * @type {THREE.Mesh<THREE.BufferGeometry, THREE.ShaderMaterial | THREE.ShaderMaterial[]>}
		 * @private
		 */
		this._threeJsObject = null;

		/**
		 * The height data if it exists.
		 * @type {ImageData}
		 * @private
		 */
		this._heightData = null;

		// Check if this tile is a leaf.
		for (let y = 0; y < 2; y++) {
			for (let x = 0; x < 2; x++) {
				for (let i = 0; i < this._component.__getEndPoints().size; i++) {
					const configuration = this._component.__getEndPoints().getAt(i).value.configuration;
					this._isLeaf = this._isLeaf && !CMTSTile._isInABoundary(configuration, this._face, this._level + 1, new Vector2(this._tile.x * 2 + x, this._tile.y * 2 + y));
				}
			}
		}

		// Get the spheroid component.
		const spheroidComponent = component.__getSpheroidComponent();

		// Calculate the center and centerLLA.
		CMTSTile.cmtsCoordToPosition(this._center, this._face, this._levelPow, this._tile.x + 0.5, this._tile.y + 0.5, spheroidComponent);
		spheroidComponent.llaFromXYZ(this._centerLLA, this._center);

		// Calculate the radius by getting the distance between the center and the origin point.
		const origin1 = new Vector3();
		const origin2 = new Vector3();
		const origin3 = new Vector3();
		const origin4 = new Vector3();
		CMTSTile.cmtsCoordToPosition(origin1, this._face, this._levelPow, this._tile.x, this._tile.y, spheroidComponent);
		CMTSTile.cmtsCoordToPosition(origin2, this._face, this._levelPow, this._tile.x + 1, this._tile.y, spheroidComponent);
		CMTSTile.cmtsCoordToPosition(origin3, this._face, this._levelPow, this._tile.x + 1, this._tile.y + 1, spheroidComponent);
		CMTSTile.cmtsCoordToPosition(origin4, this._face, this._levelPow, this._tile.x, this._tile.y + 1, spheroidComponent);
		origin1.sub(this._center, origin1);
		origin2.sub(this._center, origin2);
		origin3.sub(this._center, origin3);
		origin4.sub(this._center, origin4);
		this._radius = Math.max(origin1.magnitude(), origin2.magnitude(), origin3.magnitude(), origin4.magnitude());

		// Figure out which texture levels this uses. It may use a texture from a higher level if a texture doesn't exist at this level.
		const endPoints = this._component.__getEndPoints();
		for (let i = 0; i < endPoints.size; i++) {
			const textureName = endPoints.getAt(i).key;
			const endPoint = endPoints.getAt(i).value;

			if (CMTSTile._isInABoundary(endPoint.configuration, this._face, this._level, this._tile)) {
				// If we're at level 0, check for non-positive levels.
				const nonPositiveLevel = this._level === 0 ? this._getNonPositiveLevel(textureName) : 0;
				// Set the material's level for the texture name to this level.
				const textureLevel = this._level === 0 ? nonPositiveLevel : this._level;
				this._textureLevels.set(textureName, textureLevel);
			}
			else {
				let ancestor = this.getParent();
				while (ancestor !== null && ancestor._level !== ancestor._textureLevels.getAt(i).value) {
					ancestor = ancestor.getParent();
				}
				if (ancestor !== null) { // It found an ancestor, so use that texture for this tile.
					this._textureLevels.set(textureName, ancestor._textureLevels.getAt(i).value);
				}
				else { // There's no coverage for this tile all the way up to the top, so just set it to 0.
					this._textureLevels.set(textureName, 0);
				}
			}
		}
	}

	/**
	 * Gets the level.
	 * @returns {number}
	 */
	getLevel() {
		return this._level;
	}

	/**
	 * Gets the tile coords.
	 * @returns {Vector2}
	 */
	getTileCoord() {
		return this._tile;
	}

	/**
	 * Gets the height data, if any.
	 * @returns {boolean}
	 */
	hasHeightData() {
		return this._heightData !== null;
	}

	/**
	 * Gets the height on the tile given a tile coord.
	 * @param {Vector3} uvFace
	 * @returns {number}
	 */
	getHeight(uvFace) {

		// If no height data, just return 0 everywhere.
		if (this._heightData === null) {
			return 0;
		}

		// Get various factors and scalings for when the height data is for a different level than this tile.
		const levelFactor = Math.max(0, 1 << this._level);
		const levelTextureFactor = 1 << (this._level - Math.max(0, this._textureLevels.get('height')));
		const uvOffset = new Vector2(
			(this._tile.x - Math.floor(this._tile.x / levelTextureFactor) * levelTextureFactor) / levelTextureFactor,
			(this._tile.y - Math.floor(this._tile.y / levelTextureFactor) * levelTextureFactor) / levelTextureFactor);
		const uvScale = new Vector2(1 / levelTextureFactor, 1 / levelTextureFactor);

		// Get the fractional value within the tile.
		const x = uvFace.x * levelFactor - this._tile.x;
		const y = uvFace.y * levelFactor - this._tile.y;

		// Get the UV coords within the height data, given the texture level.
		const u = ((uvOffset.x + x * uvScale.x)) * (this._heightData.width - 4) / (this._heightData.width) + 2.0 / this._heightData.width;
		const v = 1 - (((uvOffset.y + y * uvScale.y)) * (this._heightData.width - 4) / this._heightData.width + 2.0 / this._heightData.width);

		// Get the pixel coords within the height data.
		const pixelX = u * this._heightData.width;
		const pixelY = v * this._heightData.width;

		// Get the height within that tile.
		const height = CMTSTile.getLinearInterpolatedHeightPixel(pixelX, pixelY, this._heightData.data, this._heightData.width);

		// Offset and scale the height according to the configuration.
		const configuration = this._component.__getEndPoints().get('height').configuration;
		const heightOffset = configuration['height_range'].min * this._component.getHeightScale();
		const heightScale = (configuration['height_range'].max - configuration['height_range'].min) * this._component.getHeightScale();
		return heightOffset + height * heightScale;
	}

	/**
	 * @param {CMTSTile} parent
	 * @param {number} row - 0 or 1
	 * @param {number} col - 0 or 1
	 * @returns {CMTSTile}
	 * @override
	 */
	createNewTile(parent, row, col) {
		const level = this._level + 1;
		const tile = new Vector2(parent._tile.x * 2 + col, parent._tile.y * 2 + row);
		return new CMTSTile(parent._component, this, this._face, level, tile);
	}

	/**
	 * Returns true if this tile should be split.
	 * @returns {boolean}
	 * @override
	 */
	checkSplit() {
		// Don't split if we're a leaf or at the maximum level of detail.
		if (this._isLeaf || this._level >= this._component.getMaxLevel()) {
			return false;
		}
		if (this._component.__getNumCurrentLoads() >= this._component.__getMaxCurrentLoads()) {
			return false;
		}
		// Always split if we're below the minimum level of detail.
		if (this._level < this._component.getMinLevel()) {
			return true;
		}
		// Get the pixel size, used to determine how much we should split.
		const tilePixelSize = this._component.__getColorTileSize();
		// Split if the nearest camera distance is less than the threshold.
		return this._getNearestDistance() < this._component.__getSplitJoinThresholdFactor() * this._radius / tilePixelSize;
	}

	/**
	 * Returns true if this tile should join its children.
	 * @returns {boolean}
	 * @override
	 */
	checkJoin() {
		// Always join is we're above the maximum level of detail.
		if (this._level >= this._component.getMaxLevel()) {
			return true;
		}
		if (this._component.__getNumCurrentLoads() >= this._component.__getMaxCurrentLoads()) {
			return false;
		}
		// Never join if we're at or below the minimum level of detail.
		if (this._level < this._component.getMinLevel()) {
			return false;
		}
		// Get the pixel size, used to determine how much we should split.
		const tilePixelSize = this._component.__getColorTileSize();
		// Check if the nearest camera distance is greater than the threshold.
		return this._getNearestDistance() > this._component.__getSplitJoinThresholdFactor() * this._radius / tilePixelSize * 4;
	}

	/**
	 * Asynchronously loads the tile so that it may be used.
	 * @returns {Promise<void | void[]>}
	 * @override
	 */
	async load() {
		// Increment the number of loads so we don't have too many concurrent loads.
		this._component.__incNumCurrentLoads();

		if (this._threeJsMaterial !== null) {
			this._component.__decNumCurrentLoads();
			throw new Error('Tile already has material.');
		}

		// Get a material from the component's materials cache.
		this._threeJsMaterial = MaterialUtils.get();
		this._component.getThreeJsMaterials().push(this._threeJsMaterial);
		this._threeJsMaterial.defines['shadowEntities'] = (this._component.getNumShadowEntities() > 0);
		// this._threeJsMaterial.wireframe = true;
		// this._threeJsMaterial.defines['baseColor'] = true;
		// this._threeJsMaterial.uniforms['color'].value.setRGB(0.25 + 0.75 * Math.random(), 0.25 + 0.75 * Math.random(), 0.25 + 0.75 * Math.random());

		const endPoints = this._component.__getEndPoints();
		// A list of promises that will be returned by load.
		const loadPromises = [];
		for (let i = 0; i < endPoints.size; i++) {
			const textureName = endPoints.getAt(i).key;
			const endPoint = endPoints.getAt(i).value;
			const textureLevel = this._textureLevels.get(textureName);
			if (!isFinite(textureLevel)) {
				continue;
			}
			const tileX = this._tile.x >> (this._level - textureLevel);
			const tileY = this._tile.y >> (this._level - textureLevel);
			// Form the URL from the end point and the tile params.
			const textureUrl = endPoint.url + '/' + this._face + '/' + textureLevel + '/' + tileX + '/' + tileY + '.' + endPoint.configuration.extension;
			// Do the loading of the texture.
			let texturePromise = this._component.__getTextureCache().get(textureUrl);
			this._texturePromises.set(textureName, texturePromise);
			loadPromises.push(texturePromise.then((texture) => {
				this._setTexture(textureName, texture);
			}).catch(async () => { // There was an error, so just set it to pink.
				this._component.__getTextureCache().release(texturePromise);
				texturePromise = this._component.__getTextureCache().get('pink');
				this._texturePromises.set(textureName, texturePromise);
				const texture = await texturePromise;
				this._setTexture(textureName, texture);
			}));
		}
		return Promise.all(loadPromises).finally(() => {
			this._component.__decNumCurrentLoads();
		});
	}

	/**
	 * Asynchronously unloads the tile.
	 * @returns {Promise<void>}
	 * @override
	 */
	async unload() {
		if (this._threeJsMaterial === null) {
			throw new Error('Tile has no material to unload.');
		}
		// Remove up the material from the materials list.
		const materials = this._component.getThreeJsMaterials();
		for (let i = 0, l = materials.length; i < l; i++) {
			if (materials[i] === this._threeJsMaterial) {
				materials.splice(i, 1);
				break;
			}
		}
		// Dispose of the material.
		ThreeJsHelper.destroyMaterial(this._threeJsMaterial, false);
		this._threeJsMaterial = null;
		// Clean up the texture.
		for (let i = 0; i < this._texturePromises.size; i++) {
			this._component.__getTextureCache().release(this._texturePromises.getAt(i).value);
		}
	}

	/**
	 * Asynchronously activates the tile.
	 * @returns {Promise<void>}
	 * @override
	 */
	async activate() {
		if (this._threeJsObject !== null || this._threeJsMaterial === null) {
			throw new Error('NULL');
		}
		// Setup the attributes, depending on the types of data and textures available.
		const attributes = [
			{ name: 'position', dimensions: 3 },
			{ name: 'normal', dimensions: 3 },
			{ name: 'uv', dimensions: 2 }
		];
		const hasNormalTexture = this._textureLevels.has('normal');
		if (hasNormalTexture) {
			attributes.push({ name: 'tangent', dimensions: 3 });
			attributes.push({ name: 'bitangent', dimensions: 3 });
		}
		for (let i = 0; i < this._textureLevels.size; i++) {
			const textureName = this._textureLevels.getAt(i).key;
			if (textureName !== 'color' && textureName !== 'height') {
				attributes.push({ name: textureName + 'UV', dimensions: 2 });
			}
		}
		// Create the Three.js object.
		this._threeJsObject = ThreeJsHelper.createMeshObject(this._component, this._threeJsMaterial, attributes, false);
		this._component.getThreeJsObjects().push(this._threeJsObject);
		ThreeJsHelper.useInDynEnvMap(this._threeJsObject, true);

		this._setupMesh();
	}

	/**
	 * Asynchronously deactivates the tile.
	 * @returns {Promise<void>}
	 * @override
	 */
	async deactivate() {
		// Remove the object from the objects list.
		const objects = this._component.getThreeJsObjects();
		for (let i = 0, l = objects.length; i < l; i++) {
			if (objects[i] === this._threeJsObject) {
				objects.splice(i, 1);
				break;
			}
		}
		// Destroy the object and its geometry.
		ThreeJsHelper.destroyObject(this._threeJsObject);
		this._threeJsObject = null;
	}

	/**
	 * Updates the tiles recursively. Returns true if the tile or any descendant is transitioning.
	 * @returns {boolean}
	 */
	update() {
		if (!this._component.__endPointsAreLoaded()) {
			return false;
		}
		let transitioning = this.check();
		for (let i = 0, l = this.children.length; i < l; i++) {
			transitioning = this.children[i].update() || transitioning;
		}

		// If we're at the root level and it's loaded, see if we need to change to a negative level texture.
		if (!transitioning && !this._nonPositiveTextureLoading && this._level === 0 && this.children.length === 0) {
			const endPoints = this._component.__getEndPoints();
			for (let i = 0; i < endPoints.size; i++) {
				const textureName = endPoints.getAt(i).key;
				const endPoint = endPoints.getAt(i).value;
				const textureLevel = this._getNonPositiveLevel(textureName);
				if (textureLevel !== this._textureLevels.get(textureName)) {
					this._textureLevels.set(textureName, textureLevel);
					if (!isFinite(textureLevel)) {
						continue;
					}
					this._nonPositiveTextureLoading = true;
					transitioning = true;
					// Form the URL from the end point and the tile params.
					const textureUrl = endPoint.url + '/' + this._face + '/' + textureLevel + '/0/0.' + endPoint.configuration.extension;
					// Save the previous texture promise.
					const prevTexturePromise = this._texturePromises.get(textureName);
					// Do the loading of the texture.
					let texturePromise = this._component.__getTextureCache().get(textureUrl);
					this._texturePromises.set(textureName, texturePromise);
					texturePromise.then((texture) => {
						this._setTexture(textureName, texture);
						this._setupMesh();
						this._nonPositiveTextureLoading = false;
					}).catch(async () => { // There was an error, so just set it to pink.
						this._component.__getTextureCache().release(texturePromise);
						texturePromise = this._component.__getTextureCache().get('pink');
						this._texturePromises.set(textureName, texturePromise);
						const texture = await texturePromise;
						this._setTexture(textureName, texture);
						this._nonPositiveTextureLoading = false;
					}).finally(() => {
						// Now that the new texture is loaded, release the previous texture.
						if (prevTexturePromise !== undefined) {
							this._component.__getTextureCache().release(prevTexturePromise);
						}
					});
				}
			}
		}
		transitioning = this._nonPositiveTextureLoading || transitioning;

		return transitioning;
	}

	_setupMesh() {
		// Get the pixel tab, used to make mesh tabs.
		const tilePixelSize = this._component.__getColorTileSize();

		// Since there is one set of UVs per texture name, we have to set them up here.
		/** @type {FastMap<string, Float32Array>} */
		const meshUVs = new FastMap();
		/** @type {FastMap<string, Vector2>} */
		const uvOffsets = new FastMap();
		/** @type {FastMap<string, Vector2>} */
		const uvScales = new FastMap();
		for (let i = 0; i < this._textureLevels.size; i++) {
			const textureName = this._textureLevels.getAt(i).key;
			// Calculate the uv bounds, since it may be using a material from a different level.
			const levelFactor = 1 << (this._level - Math.max(0, this._textureLevels.get(textureName)));
			uvOffsets.set(textureName, new Vector2(
				(this._tile.x - Math.floor(this._tile.x / levelFactor) * levelFactor) / levelFactor,
				(this._tile.y - Math.floor(this._tile.y / levelFactor) * levelFactor) / levelFactor));
			uvScales.set(textureName, new Vector2(1 / levelFactor, 1 / levelFactor));
		}
		const hasNormalTexture = this._textureLevels.has('normal');

		// Create the vertices, normals, uvs, and indices arrays.
		const numUVerts = this._heightData ? MathUtils.clamp(Math.ceil(this._heightData.width * uvScales.get('height').x) - 2, 5, 129) : (5 << MathUtils.clamp(2 - this._level / 2, 0, 2));
		const numVVerts = this._heightData ? MathUtils.clamp(Math.ceil(this._heightData.height * uvScales.get('height').y) - 2, 5, 129) : (5 << MathUtils.clamp(2 - this._level / 2, 0, 2));
		const numVerts = numUVerts * numVVerts;
		const meshPositions = new Float32Array(numVerts * 3);
		const meshNormals = new Float32Array(numVerts * 3);
		let meshTangents = null;
		let meshBitangents = null;
		if (hasNormalTexture) {
			meshTangents = new Float32Array(numVerts * 3);
			meshBitangents = new Float32Array(numVerts * 3);
		}
		const meshIndices = new Uint16Array((numUVerts - 1) * (numVVerts - 1) * 6);
		for (let i = 0; i < this._textureLevels.size; i++) {
			const textureName = this._textureLevels.getAt(i).key;
			// Create the uv array.
			meshUVs.set(textureName, new Float32Array(numVerts * 2));
		}

		// Get the height offset and scale, if it has a height map.
		let heightOffset = 0;
		let heightScale = 1;
		if (this._textureLevels.has('height')) {
			const configuration = this._component.__getEndPoints().get('height').configuration;
			heightOffset = configuration['height_range'].min * this._component.getHeightScale();
			heightScale = (configuration['height_range'].max - configuration['height_range'].min) * this._component.getHeightScale();
		}

		// Set the vertices, normals, uvs, and indices arrays.
		const tile = new Vector2();
		const tileClamped = new Vector2();
		const pos = new Vector3();
		const heightDir = new Vector3();
		const up = new Vector3();
		const tangent = new Vector3();
		const bitangent = new Vector3();
		const lla = new LatLonAlt();
		const spheroid = this._component.__getSpheroidComponent();
		for (let y = 0; y < numVVerts; y++) {
			for (let x = 0; x < numUVerts; x++) {
				const vertexI = y * numUVerts + x;
				// Get the tile coordinate of this place within the tile.
				tile.set(this._tile.x + (x - 1) / (numUVerts - 3), this._tile.y + (y - 1) / (numVVerts - 3));
				tileClamped.set(this._tile.x + MathUtils.clamp01((x - 1) / (numUVerts - 3)), this._tile.y + MathUtils.clamp01((y - 1) / (numVVerts - 3)));

				// Convert it to an XYZ.
				CMTSTile.cmtsCoordToPosition(pos, this._face, this._levelPow, tileClamped.x, tileClamped.y, spheroid);
				if (hasNormalTexture) {
					CMTSTile.cmtsCoordToTangent(tangent, this._face, this._levelPow, tile.x, tile.y, spheroid);
					CMTSTile.cmtsCoordToBitangent(bitangent, this._face, this._levelPow, tile.x, tile.y, spheroid);
				}

				// Convert it to an LLA and get up.
				spheroid.llaFromXYZ(lla, pos);
				spheroid.upFromLLA(up, lla);

				// Get the height direction. It is not the same as up if it is planetocentric.
				if (spheroid.isPlanetographic()) {
					heightDir.copy(up);
				}
				else {
					heightDir.normalize(pos);
				}

				if (this._heightData) { // If there's a height map, get the heightmap value and apply it to the position.
					const data = this._heightData.data;
					const uvOffset = uvOffsets.get('height');
					const uvScale = uvScales.get('height');
					const heightTileSize = this._heightData.width - 4;

					// Get the height position.
					CMTSTile.getHeightPos(pos, heightDir, x, y, data, uvOffset, numUVerts, numVVerts, uvScale, heightTileSize, heightOffset, heightScale);
					// Calculate the normal if there isn't a normal map.
					if (!this._textureLevels.has('normal')) {
						const posX1 = Vector3.pool.get();
						const posX2 = Vector3.pool.get();
						const posY1 = Vector3.pool.get();
						const posY2 = Vector3.pool.get();
						const posX1Y1 = Vector3.pool.get();
						const posX2Y2 = Vector3.pool.get();
						const temp1 = Vector3.pool.get();
						const temp2 = Vector3.pool.get();
						CMTSTile.cmtsCoordToPosition(posX1, this._face, this._levelPow, tile.x - 1 / (numUVerts - 3), tile.y, spheroid);
						CMTSTile.getHeightPos(posX1, heightDir, x - 1, y, data, uvOffset, numUVerts, numVVerts, uvScale, heightTileSize, heightOffset, heightScale);
						CMTSTile.cmtsCoordToPosition(posX2, this._face, this._levelPow, tile.x + 1 / (numUVerts - 3), tile.y, spheroid);
						CMTSTile.getHeightPos(posX2, heightDir, x + 1, y, data, uvOffset, numUVerts, numVVerts, uvScale, heightTileSize, heightOffset, heightScale);
						CMTSTile.cmtsCoordToPosition(posY1, this._face, this._levelPow, tile.x, tile.y - 1 / (numVVerts - 3), spheroid);
						CMTSTile.getHeightPos(posY1, heightDir, x, y - 1, data, uvOffset, numUVerts, numVVerts, uvScale, heightTileSize, heightOffset, heightScale);
						CMTSTile.cmtsCoordToPosition(posY2, this._face, this._levelPow, tile.x, tile.y + 1 / (numVVerts - 3), spheroid);
						CMTSTile.getHeightPos(posY2, heightDir, x, y + 1, data, uvOffset, numUVerts, numVVerts, uvScale, heightTileSize, heightOffset, heightScale);
						CMTSTile.cmtsCoordToPosition(posX1Y1, this._face, this._levelPow, tile.x - 1 / (numUVerts - 3), tile.y - 1 / (numVVerts - 3), spheroid);
						CMTSTile.getHeightPos(posX1Y1, heightDir, x - 1, y - 1, data, uvOffset, numUVerts, numVVerts, uvScale, heightTileSize, heightOffset, heightScale);
						CMTSTile.cmtsCoordToPosition(posX2Y2, this._face, this._levelPow, tile.x + 1 / (numUVerts - 3), tile.y + 1 / (numVVerts - 3), spheroid);
						CMTSTile.getHeightPos(posX2Y2, heightDir, x + 1, y + 1, data, uvOffset, numUVerts, numVVerts, uvScale, heightTileSize, heightOffset, heightScale);
						posX1.sub(posX2, posX1);
						posY1.sub(posY2, posY1);
						heightDir.cross(posX1, posY1);
						heightDir.normalize(heightDir);
						Vector3.pool.release(posX1);
						Vector3.pool.release(posX2);
						Vector3.pool.release(posY1);
						Vector3.pool.release(posY2);
						Vector3.pool.release(posX1Y1);
						Vector3.pool.release(posX2Y2);
						Vector3.pool.release(temp1);
						Vector3.pool.release(temp2);
					}
				}
				if (x === 0 || y === 0 || x === numUVerts - 1 || y === numVVerts - 1) {
					pos.setMagnitude(pos, pos.magnitude() - 2.0 * heightScale * this._levelPow);
				}

				pos.sub(pos, this._center);

				meshPositions[vertexI * 3 + 0] = pos.x;
				meshPositions[vertexI * 3 + 1] = pos.y;
				meshPositions[vertexI * 3 + 2] = pos.z;

				meshNormals[vertexI * 3 + 0] = heightDir.x;
				meshNormals[vertexI * 3 + 1] = heightDir.y;
				meshNormals[vertexI * 3 + 2] = heightDir.z;

				if (hasNormalTexture) {
					meshTangents[vertexI * 3 + 0] = tangent.x;
					meshTangents[vertexI * 3 + 1] = tangent.y;
					meshTangents[vertexI * 3 + 2] = tangent.z;

					meshBitangents[vertexI * 3 + 0] = bitangent.x;
					meshBitangents[vertexI * 3 + 1] = bitangent.y;
					meshBitangents[vertexI * 3 + 2] = bitangent.z;
				}

				for (let i = 0; i < this._textureLevels.size; i++) {
					const textureName = this._textureLevels.getAt(i).key;
					const uvOffset = uvOffsets.get(textureName);
					const uvScale = uvScales.get(textureName);
					const uvs = meshUVs.get(textureName);
					uvs[vertexI * 2 + 0] = ((uvOffset.x + (x - 1) / (numUVerts - 3) * uvScale.x)) * tilePixelSize / (tilePixelSize + 4) + 2.0 / (tilePixelSize + 4);
					uvs[vertexI * 2 + 1] = 1 - (((uvOffset.y + (y - 1) / (numVVerts - 3) * uvScale.y)) * tilePixelSize / (tilePixelSize + 4) + 2.0 / (tilePixelSize + 4));
				}

				if (x < numUVerts - 1 && y < numVVerts - 1) {
					const triangleI = y * (numUVerts - 1) + x;
					meshIndices[triangleI * 6 + 0] = numUVerts * (y + 0) + (x + 0);
					meshIndices[triangleI * 6 + 1] = numUVerts * (y + 0) + (x + 1);
					meshIndices[triangleI * 6 + 2] = numUVerts * (y + 1) + (x + 1);
					meshIndices[triangleI * 6 + 3] = numUVerts * (y + 0) + (x + 0);
					meshIndices[triangleI * 6 + 4] = numUVerts * (y + 1) + (x + 1);
					meshIndices[triangleI * 6 + 5] = numUVerts * (y + 1) + (x + 0);
				}
			}
		}

		ThreeJsHelper.setVertices(this._threeJsObject.geometry, 'position', meshPositions);
		ThreeJsHelper.setVertices(this._threeJsObject.geometry, 'normal', meshNormals);
		if (hasNormalTexture) {
			ThreeJsHelper.setVertices(this._threeJsObject.geometry, 'tangent', meshTangents);
			ThreeJsHelper.setVertices(this._threeJsObject.geometry, 'bitangent', meshBitangents);
		}
		for (let i = 0; i < this._textureLevels.size; i++) {
			const textureName = this._textureLevels.getAt(i).key;
			const uvs = meshUVs.get(textureName);
			if (textureName === 'height') {
				continue;
			}
			if (textureName === 'color') {
				ThreeJsHelper.setVertices(this._threeJsObject.geometry, 'uv', uvs);
			}
			else {
				ThreeJsHelper.setVertices(this._threeJsObject.geometry, textureName + 'UV', uvs);
			}
		}
		ThreeJsHelper.setIndices(this._threeJsObject.geometry, meshIndices);
	}

	/**
	 * Gets the non-positive level for root tiles.
	 * @param {string} textureName
	 * @returns {number}
	 * @private
	 */
	_getNonPositiveLevel(textureName) {
		const nearestDistance = this._getNearestDistance();
		if (!Number.isFinite(nearestDistance)) {
			return NaN;
		}
		const endPoint = this._component.__getEndPoints().get(textureName);
		const tilePixelSize = endPoint.configuration.tile_size;
		return MathUtils.clamp(4 - Math.floor(Math.log2(nearestDistance / this._radius / this._component.__getSplitJoinThresholdFactor() * tilePixelSize * 4)), endPoint.configuration.first_level, 0);
	}

	/**
	 * Gets the nearest distance of all cameras to see if we need to split or join this node.
	 * @private
	 */
	_getNearestDistance() {
		let nearestDistance = Number.POSITIVE_INFINITY;
		const cameraPositions = this._component.__getCameraPositions();
		const cameraFieldsOfView = this._component.__getCameraFieldsOfView();
		for (let i = 0, l = cameraPositions.length; i < l; i++) {
			const position = cameraPositions[i];
			const fieldOfView = cameraFieldsOfView[i];
			CMTSTile._pos.sub(position, this._center);
			const distance = Math.max(0, CMTSTile._pos.magnitude() - this._radius) * Math.tan(fieldOfView / 2);
			if (nearestDistance > distance) {
				nearestDistance = distance;
			}
		}
		return nearestDistance;
	}

	/**
	 * Converts this to a string.
	 * @returns {string}
	 * @override
	 */
	toString() {
		return this._face + '/' + this._level + '/' + this._tile.x + '/' + this._tile.y;
	}

	/**
	 * Prepares the tile for rendering.
	 * @param {CameraComponent} camera
	 */
	prepareForRender(camera) {
		// Check if the tile should be hidden.
		const entity = this._component.getEntity();
		if (this._threeJsObject !== null) {
			const cameraSpacePosition = entity.getCameraSpacePosition(camera);
			const centerInJ2000 = Vector3.pool.get();
			centerInJ2000.rotate(entity.getOrientation(), this._center);
			const angleBetweenCenterAndCamera = Math.acos(centerInJ2000.dot(cameraSpacePosition)
				/ centerInJ2000.magnitude() / cameraSpacePosition.magnitude());
			Vector3.pool.release(centerInJ2000);
			if (angleBetweenCenterAndCamera < Math.PI / 2 - (Math.PI / 4) * this._levelPow) {
				this._threeJsObject.visible = false;
				return;
			}
		}

		// Set the Three.js object position.
		ThreeJsHelper.setPositionToEntity(this._threeJsObject, this._component.getEntity(), camera, this._center, true);

		// Prepare the children for rendering.
		for (let i = 0, l = this.children.length; i < l; i++) {
			this.children[i].prepareForRender(camera);
		}
	}

	/**
	 * Sets the texture.
	 * @param {string} textureName
	 * @param {THREE.Texture} texture
	 * @private
	 */
	_setTexture(textureName, texture) {
		if (this._component.getLoadState() !== 'loaded') {
			return;
		}
		if (textureName === 'height') {
			const canvas = document.createElement('canvas');
			canvas.width = texture.image.width;
			canvas.height = texture.image.height;
			const context = canvas.getContext('2d', { desynchronized: true, alpha: false });
			context.drawImage(texture.image, 0, 0);
			this._heightData = context.getImageData(0, 0, texture.image.width, texture.image.height);
			// Recalculate the center using the height data.
			const spheroid = this._component.__getSpheroidComponent();
			CMTSTile.cmtsCoordToPosition(this._center, this._face, this._levelPow, this._tile.x + 0.5, this._tile.y + 0.5, spheroid);
			const up = Vector3.pool.get();
			const lla = LatLonAlt.pool.get();
			spheroid.llaFromXYZ(lla, this._center);
			spheroid.upFromLLA(up, lla);
			const height = CMTSTile.getLinearInterpolatedHeightPixel(this._heightData.width / 2, this._heightData.height / 2, this._heightData.data, this._heightData.width);
			const configuration = this._component.__getEndPoints().get('height').configuration;
			const heightOffset = configuration['height_range'].min * this._component.getHeightScale();
			const heightScale = (configuration['height_range'].max - configuration['height_range'].min) * this._component.getHeightScale();
			this._center.addMult(this._center, up, heightOffset + height * heightScale);
			Vector3.pool.release(up);
			LatLonAlt.pool.release(lla);
		}
		else {
			this._threeJsMaterial.uniforms[textureName + 'Texture'].value = texture;
			if (textureName === 'normal') {
				this._threeJsMaterial.defines['normalMap'] = true;
				this._threeJsMaterial.defines['normalUVs'] = true;
				this._threeJsMaterial.defines['hasBitangents'] = true;
				this._threeJsMaterial.uniforms['normalScale'].value.set(this._component.getHeightScale(), this._component.getHeightScale());
				this._threeJsMaterial.uniforms['specularIntensity'].value = 0;
				this._threeJsMaterial.uniforms['specularHardness'].value = 100;
			}
			else if (textureName === 'specular') {
				this._threeJsMaterial.defines['specularMap'] = true;
				this._threeJsMaterial.defines['specularUVs'] = true;
			}
			else if (textureName === 'night') {
				this._threeJsMaterial.defines['nightMap'] = true;
				this._threeJsMaterial.defines['nightUVs'] = true;
			}
			else if (textureName === 'decal') {
				this._threeJsMaterial.defines['decalMap'] = true;
				this._threeJsMaterial.defines['decalUVs'] = true;
			}
		}
	}

	/**
	 * Gets the greatest level available for the given tile.
	 * @param {CMTSConfiguration} configuration
	 * @param {number} face
	 * @param {number} level
	 * @param {Vector2} tile
	 * @returns {number}
	 */
	static _getGreatestLevel(configuration, face, level, tile) {
		let greatestLevel = Number.NEGATIVE_INFINITY;
		for (let i = 0, l = configuration.boundaries.length; i < l; i++) {
			const boundary = configuration.boundaries[i];
			if (boundary.face !== face) { // Different face.
				continue;
			}
			const levelDifference = boundary['last_level'] - level;
			if (boundary.min[0] <= (tile.x >> levelDifference) && (tile.x >> levelDifference) <= boundary.max[0]
				&& boundary.min[1] <= (tile.y >> levelDifference) && (tile.y >> levelDifference) <= boundary.max[1]
				&& greatestLevel < boundary['last_level']) {
				greatestLevel = boundary['last_level'];
			}
		}
		return greatestLevel;
	}

	/**
	 * Returns true if the tile coordinates are within a boundary.
	 * @param {CMTSConfiguration} configuration
	 * @param {number} face
	 * @param {number} level
	 * @param {Vector2} tile
	 * @returns {boolean}
	 */
	static _isInABoundary(configuration, face, level, tile) {
		let foundValidBoundary = false;
		for (let i = 0, l = configuration.boundaries.length; i < l; i++) {
			const boundary = configuration.boundaries[i];
			if (boundary.face !== face) { // Different face.
				continue;
			}
			if (boundary['last_level'] < level) { // Not a deep enough level to cover the child tile.
				continue;
			}
			const levelFactor = 1 << (boundary['last_level'] - level);
			if (tile.x < Math.floor(boundary.min[0] / levelFactor) || Math.floor(boundary.max[0] / levelFactor) < tile.x) {
				continue;
			}
			if (tile.y < Math.floor(boundary.min[1] / levelFactor) || Math.floor(boundary.max[1] / levelFactor) < tile.y) {
				continue;
			}
			foundValidBoundary = true;
		}
		return foundValidBoundary;
	}

	/**
	 * Adjust the position given the height on a tile.
	 * @param {Vector3} pos
	 * @param {Vector3} heightDir
	 * @param {number} x
	 * @param {number} y
	 * @param {Uint8ClampedArray} data
	 * @param {Vector2} uvOffset
	 * @param {number} numUVerts
	 * @param {number} numVVerts
	 * @param {Vector2} uvScale
	 * @param {number} heightTileSize
	 * @param {number} heightOffset
	 * @param {number} heightScale
	 */
	static getHeightPos(pos, heightDir, x, y, data, uvOffset, numUVerts, numVVerts, uvScale, heightTileSize, heightOffset, heightScale) {
		const u = ((uvOffset.x + (x - 1) / (numUVerts - 3) * uvScale.x)) * heightTileSize / (heightTileSize + 4) + 2.0 / (heightTileSize + 4);
		const v = 1 - (((uvOffset.y + (y - 1) / (numVVerts - 3) * uvScale.y)) * heightTileSize / (heightTileSize + 4) + 2.0 / (heightTileSize + 4));
		const pixelX = u * (heightTileSize + 4);
		const pixelY = v * (heightTileSize + 4);
		const height = this.getLinearInterpolatedHeightPixel(pixelX, pixelY, data, heightTileSize + 4);
		pos.addMult(pos, heightDir, heightOffset + height * heightScale);
	}

	/** Gets the linearly interpolated height from a pixel.
	 * @param {number} pixelX
	 * @param {number} pixelY
	 * @param {Uint8ClampedArray} data
	 * @param {number} size
	 * @returns {number} */
	static getLinearInterpolatedHeightPixel(pixelX, pixelY, data, size) {
		const pixelXInt = Math.floor(pixelX);
		const pixelYInt = Math.floor(pixelY);
		const pixelXFrac = pixelX - pixelXInt;
		const pixelYFrac = pixelY - pixelYInt;
		const u = Math.abs(pixelXFrac - 0.5);
		const v = Math.abs(pixelYFrac - 0.5);
		let height = this.getHeightFromPixel(pixelXInt, pixelYInt, data, size) * ((1 - u) * (1 - v));
		if (pixelXFrac >= 0.5) {
			height += this.getHeightFromPixel(pixelXInt + 1, pixelYInt, data, size) * (u * (1 - v));
			if (pixelYFrac < 0.5) {
				height += this.getHeightFromPixel(pixelXInt + 1, pixelYInt - 1, data, size) * (u * v);
			}
			else if (pixelYFrac >= 0.5) {
				height += this.getHeightFromPixel(pixelXInt + 1, pixelYInt + 1, data, size) * (u * v);
			}
		}
		else if (pixelXFrac < 0.5) {
			height += this.getHeightFromPixel(pixelXInt - 1, pixelYInt, data, size) * (u * (1 - v));
			if (pixelYFrac < 0.5) {
				height += this.getHeightFromPixel(pixelXInt - 1, pixelYInt - 1, data, size) * (u * v);
			}
			else if (pixelYFrac >= 0.5) {
				height += this.getHeightFromPixel(pixelXInt - 1, pixelYInt + 1, data, size) * (u * v);
			}
		}
		if (pixelYFrac >= 0.5) {
			height += this.getHeightFromPixel(pixelXInt, pixelYInt + 1, data, size) * ((1 - u) * v);
		}
		else if (pixelYFrac < 0.5) {
			height += this.getHeightFromPixel(pixelXInt, pixelYInt - 1, data, size) * ((1 - u) * v);
		}
		return height;
	}

	/** Gets the height from a pixel.
	 * @param {number} pixelX
	 * @param {number} pixelY
	 * @param {Uint8ClampedArray} data
	 * @param {number} size
	 * @returns {number} */
	static getHeightFromPixel(pixelX, pixelY, data, size) {
		pixelX = MathUtils.clamp(pixelX, 0, size - 1);
		pixelY = MathUtils.clamp(pixelY, 0, size - 1);
		const pixelIndex = (pixelY * size + pixelX) * 4; // 4 for the rgba channels.
		// We parse the color, combining it into a single 24-bit value.
		// Eventually a proposal will allow more efficient types of height data. https://github.com/WICG/canvas-color-space/blob/master/CanvasColorSpaceProposal.md
		return data[pixelIndex + 0] / 256 + data[pixelIndex + 1] / 65536 + data[pixelIndex + 2] / 16777216;
	}

	/**
	 * Converts a CMTS coordinate to an XYZ coordinate in the frame of the spheroid.
	 * @param {Vector3} out
	 * @param {number} face
	 * @param {number} levelPow
	 * @param {number} x
	 * @param {number} y
	 * @param {SpheroidComponent} spheroid
	 */
	static cmtsCoordToPosition(out, face, levelPow, x, y, spheroid) {
		let u = x * levelPow;
		let v = y * levelPow;

		// If the uv are out of bounds, get the correct uv and face.
		while (u < 0 || u > 1 || v < 0 || v > 1) {
			if (0 <= face && face <= 3) { // One of the horizontal faces
				if (u > 1) {
					u -= 1;
					face = (face + 1) % 4;
				}
				else if (u < 0) {
					u += 1;
					face = (face + 3) % 4; // same as - 1
				}
				else if (face === 0) {
					if (v < 0) {
						v += 1;
						face = 5;
					}
					else if (v > 1) {
						v -= 1;
						face = 4;
					}
				}
				else if (face === 1) {
					if (v < 0) {
						const t = u;
						u = v + 1;
						v = 1 - t;
						face = 5;
					}
					else if (v > 1) {
						const t = u;
						u = 2 - v;
						v = t;
						face = 4;
					}
				}
				else if (face === 2) {
					if (v < 0) {
						u = 1 - u;
						v = 0 - v;
						face = 5;
					}
					else if (v > 1) {
						u = 1 - u;
						v = 2 - v;
						face = 4;
					}
				}
				else if (face === 3) {
					if (v < 0) {
						const t = u;
						u = 0 - v;
						v = t;
						face = 5;
					}
					else if (v > 1) {
						const t = u;
						u = v - 1;
						v = 1 - t;
						face = 4;
					}
				}
			}
			else if (face === 4) {
				if (u < 0) {
					const t = u;
					u = 1 - v;
					v = t + 1;
					face = 3;
				}
				else if (u > 1) {
					const t = u;
					u = v;
					v = 2 - t;
					face = 1;
				}
				else if (v < 0) {
					v += 1;
					face = 0;
				}
				else if (v > 1) {
					u = 1 - u;
					v = 2 - v;
					face = 2;
				}
			}
			else if (face === 5) {
				if (u < 0) {
					const t = u;
					u = v;
					v = 0 - t;
					face = 3;
				}
				else if (u > 1) {
					const t = u;
					u = 1 - v;
					v = t - 1;
					face = 1;
				}
				else if (v < 0) {
					u = 1 - u;
					v = 0 - v;
					face = 2;
				}
				else if (v > 1) {
					v -= 1;
					face = 0;
				}
			}
		}

		const uT = 2 * u - 1;
		const vT = 2 * v - 1;

		const basis = this._basis[face];

		// Convert to XYZ vector as if it were a sphere.
		out.set(
			basis[0].x * uT + basis[1].x * vT + basis[2].x,
			basis[0].y * uT + basis[1].y * vT + basis[2].y,
			basis[0].z * uT + basis[1].z * vT + basis[2].z);
		out.normalize(out);

		// Get it as an LLA.
		Geometry.getLLAFromXYZOnSphere(this._lla, out, 0);
		this._lla.alt = 0;

		// Convert to a proper XYZ.
		spheroid.xyzFromLLA(out, this._lla);
	};

	/**
	 * Converts a CMTS coordinate to a tangent XYZ coordinate in the frame of the spheroid.
	 * @param {Vector3} out
	 * @param {number} face
	 * @param {number} levelPow
	 * @param {number} x
	 * @param {number} y
	 * @param {SpheroidComponent} spheroid
	 */
	static cmtsCoordToTangent(out, face, levelPow, x, y, spheroid) {
		this.cmtsCoordToPosition(out, face, levelPow, x, y, spheroid);
		const basis = this._basis[face];
		out.setNormalTo(out, basis[0]);
	};

	/**
	 * Converts a CMTS coordinate to a bitangent XYZ coordinate in the frame of the spheroid.
	 * @param {Vector3} out
	 * @param {number} face
	 * @param {number} levelPow
	 * @param {number} x
	 * @param {number} y
	 * @param {SpheroidComponent} spheroid
	 */
	static cmtsCoordToBitangent(out, face, levelPow, x, y, spheroid) {
		this.cmtsCoordToPosition(out, face, levelPow, x, y, spheroid);
		const basis = this._basis[face];
		out.setNormalTo(out, basis[1]);
	};
}

/**
 * The basis vectors for every face. The first corresponds to the U direction, then the V direction, and then out of the face.
 */
CMTSTile._basis = [
	[Vector3.YAxis, Vector3.ZAxis, Vector3.XAxis],
	[Vector3.XAxisNeg, Vector3.ZAxis, Vector3.YAxis],
	[Vector3.YAxisNeg, Vector3.ZAxis, Vector3.XAxisNeg],
	[Vector3.XAxis, Vector3.ZAxis, Vector3.YAxisNeg],
	[Vector3.YAxis, Vector3.XAxisNeg, Vector3.ZAxis],
	[Vector3.YAxis, Vector3.XAxis, Vector3.ZAxisNeg]
];

/**
 * A temporary lat/lon/alt.
 */
CMTSTile._lla = new LatLonAlt();

/**
 * A temporary vector3
 */
CMTSTile._pos = new Vector3();

/**
 * @typedef CMTSBoundary
 * @property {number} face
 * @property {number} last_level
 * @property {{ 0: number, 1: number }} min
 * @property {{ 0: number, 1: number }} max
 */

/**
 * @typedef CMTSRange {
 * @property {number} min
 * @property {number} max
 */

/**
 * @typedef CMTSConfiguration
 * @property {CMTSBoundary[]} boundaries
 * @property {number} first_level
 * @property {number} tile_size
 * @property {string} extension
 * @property {CMTSRange} height_range
 */
