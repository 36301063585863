/** @module pioneer */
import {
	BaseController,
	Entity,
	Quaternion,
	Vector3
} from '../../internal';

/**
 * A controller that translates the position of the entity.
 */
export class TranslateController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The translation.
		 * @type {Vector3}
		 * @private
		 */
		this._translation = new Vector3(0, 0, 0);
		this._translation.freeze();

		/**
		 * Whether the translation is relative to the orientation.
		 * @type {boolean}
		 * @private
		 */
		this._relativeToOrientation = false;

		// Let the base controller know that this changes the position.
		this.addModifiedState('position');
	}

	/**
	 * Gets the translation. Defaults to Vector3(0, 0, 0).
	 * @returns {Vector3}
	 */
	getTranslation() {
		return this._translation;
	}

	/**
	 * Sets the translation.
	 * @param {Vector3} translation
	 */
	setTranslation(translation) {
		this._translation.thaw();
		this._translation.copy(translation);
		this._translation.freeze();
	}

	/**
	 * Sets whether the translation is relative to the orientation.
	 * @param {boolean} relativeToOrientation
	 */
	setRelativeToOrientation(relativeToOrientation) {
		this._relativeToOrientation = relativeToOrientation;
	}

	/**
	 * Updates a position for the given time.
	 * @param {Vector3} position
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updatePositionAtTime(position, time) {
		const newPosition = Vector3.pool.get();
		if (this._relativeToOrientation) {
			const orientation = Quaternion.pool.get();
			this.getEntity().getOrientationAtTime(orientation, time);
			newPosition.rotate(orientation, this._translation);
			Quaternion.pool.release(orientation);
		}
		else {
			newPosition.copy(this._translation);
		}
		position.add(newPosition, position);
		Vector3.pool.release(newPosition);
	}

	/**
	 * Updates the controller.
	 * @override
	 * @internal
	 */
	__update() {
		const newPosition = Vector3.pool.get();
		if (this._relativeToOrientation) {
			newPosition.rotate(this.getEntity().getOrientation(), this._translation);
		}
		else {
			newPosition.copy(this._translation);
		}
		newPosition.add(newPosition, this.getEntity().getPosition());
		this.getEntity().setPosition(newPosition);
		Vector3.pool.release(newPosition);
	}
}
