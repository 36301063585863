/** @module pioneer */
import {
	Freezable,
	MathUtils,
	Pool,
	Vector3
} from '../internal';

/** An azimuth, elevation, and range. */
export class AER extends Freezable {
	/**
	 * Pool for temporary variables.
	 * @returns {Pool<AER>}
	 */
	static get pool() {
		return _pool;
	}

	/**
	 * Constructor.
	 * @param {number} azimuth
	 * @param {number} elevation
	 * @param {number} range
	 */
	constructor(azimuth = 0, elevation = 0, range = 0) {
		super();

		/**
		 * azimuth
		 * @type {number}
		 * @private
		 */
		this._azimuth = azimuth;
		/**
		 * elevation
		 * @type {number}
		 * @private
		 */
		this._elevation = elevation;
		/**
		 * range
		 * @type {number}
		 * @private
		 */
		this._range = range;
	}

	/**
	 * Gets the azimuth.
	 * @returns {number}
	 */
	get azimuth() {
		return this._azimuth;
	}

	/**
	 * Sets the azimuth.
	 * @param {number} azimuth
	 */
	set azimuth(azimuth) {
		this.throwIfFrozen();
		this._azimuth = azimuth;
	}

	/**
	 * Gets the elevation.
	 * @returns {number}
	 */
	get elevation() {
		return this._elevation;
	}

	/**
	 * Sets the elevation.
	 * @param {number} elevation
	 */
	set elevation(elevation) {
		this.throwIfFrozen();
		this._elevation = elevation;
	}

	/**
	 * Gets the range.
	 * @returns {number}
	 */
	get range() {
		return this._range;
	}

	/**
	 * Sets the range.
	 * @param {number} range
	 */
	set range(range) {
		this.throwIfFrozen();
		this._range = range;
	}

	/**
	 * Returns a nicely formed string.
	 * @param {boolean} deg - whether or not to print in degrees or radians.
	 * @returns {string}
	 * @override
	 */
	toString(deg = false) {
		if (deg) {
			return '[' + MathUtils.radToDeg(this._azimuth) + ', ' + MathUtils.radToDeg(this._elevation) + ', ' + this._range + ']';
		}
		else {
			return '[' + this._azimuth + ', ' + this._elevation + ', ' + this._range + ']';
		}
	}

	/**
	 * Sets this to a.
	 * @param {AER} a
	 */
	copy(a) {
		this.throwIfFrozen();
		this._azimuth = a._azimuth;
		this._elevation = a._elevation;
		this._range = a._range;
	}

	/**
	 * Sets this to the parameters.
	 * @param {number} azimuth
	 * @param {number} elevation
	 * @param {number} range
	 */
	set(azimuth, elevation, range) {
		this.throwIfFrozen();
		this._azimuth = azimuth;
		this._elevation = elevation;
		this._range = range;
	}

	/**
	 * Sets this from the xyz equivalent of azimuth (rotation about z-axis starting from x-axis), elevation (from x-y plane), and range.
	 * @param {Vector3} xyz
	 */
	setFromVector(xyz) {
		this.throwIfFrozen();
		this._range = xyz.magnitude();
		this._elevation = Math.asin(xyz.z / this._range);
		this._azimuth = Math.atan2(xyz.y, xyz.x);
	}
}

/** @type {Pool<AER>} */
const _pool = new Pool(AER);
