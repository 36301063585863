/** @module pioneer */
import {
	Capabilities,
	ShaderChunkLogDepth,
	THREE,
	ThreeJsHelper
} from '../internal';

export class MaterialUtilsStandard {
	/** Gets a PBR ShaderMaterial.
	 * @returns {THREE.ShaderMaterial}
	 */
	static get() {
		if (MaterialUtilsStandard._material === null) {
			MaterialUtilsStandard._material = new THREE.ShaderMaterial({
				uniforms: {
					// Lights
					ambientLightColor: new THREE.Uniform(new THREE.Color()),
					lightPositions: new THREE.Uniform([new THREE.Vector3(1, 0, 0), new THREE.Vector3(1, 0, 0), new THREE.Vector3(1, 0, 0), new THREE.Vector3(1, 0, 0), new THREE.Vector3(1, 0, 0)]),
					lightColors: new THREE.Uniform([new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0)]),
					lightRadii: new THREE.Uniform([0, 0, 0, 0, 0]),
					numLights: new THREE.Uniform(0),
					environmentIntensity: new THREE.Uniform(1.0),
					gammaCorrectionFactor: new THREE.Uniform(1.0),

					// External lighting and camera.
					entityPos: new THREE.Uniform(new THREE.Vector3()),

					// Shading.
					color: new THREE.Uniform(new THREE.Color(1, 1, 1)),
					metalness: new THREE.Uniform(0.0),
					roughness: new THREE.Uniform(0.0),
					emissiveColor: new THREE.Uniform(new THREE.Color(0, 0, 0)),
					alphaMultiplier: new THREE.Uniform(1.0),

					// Textures
					colorTexture: new THREE.Uniform(null),
					roughnessTexture: new THREE.Uniform(null),
					metalnessTexture: new THREE.Uniform(null),
					normalTexture: new THREE.Uniform(null),
					emissiveTexture: new THREE.Uniform(null),

					// Shadow Entities
					numShadowEntities: new THREE.Uniform(0),
					shadowEntityPositions: new THREE.Uniform([new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3()]),
					shadowEntityRadii: new THREE.Uniform([0, 0, 0, 0, 0, 0, 0]),
					shadowEntitySunsetIntensity: new THREE.Uniform([0, 0, 0, 0, 0, 0, 0]),
					shadowEntitySunsetColors: new THREE.Uniform([new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3()]),

					// Environmental cubemap
					envTexture: new THREE.Uniform(null),
					maxMipLevel: new THREE.Uniform(0),

					// Dynamic environment map
					dynEnvTexture: new THREE.Uniform(null),
					dynEnvFaceSize: new THREE.Uniform(0),

					normalScale: new THREE.Uniform(new THREE.Vector2()),

					...ShaderChunkLogDepth.ThreeUniforms
				},
				vertexShader: `
					#ifdef normalMap
						attribute vec4 tangent;
						varying vec4 localTangent;
						varying vec3 localNormal;
					#else
						varying vec3 modelNormal;
					#endif
					#if defined(normalMap) || defined(colorMap) || defined(roughnessMap) || defined(metalnessMap) || defined(emissiveMap)
						varying vec2 localUV;
					#endif
					varying vec3 cameraSpacePosition;

					${ShaderChunkLogDepth.VertexHead}

					void main() {
						#ifdef normalMap
							localTangent = tangent;
							localNormal = normal;
						#else
							modelNormal = normalize((modelMatrix * vec4(normal, 0.)).xyz);
						#endif
						#if defined(normalMap) || defined(colorMap) || defined(roughnessMap) || defined(metalnessMap) || defined(emissiveMap)
							localUV = uv;
						#endif
						cameraSpacePosition = (modelMatrix * vec4(position, 1.)).xyz;
						vec4 viewPosition = modelViewMatrix * vec4(position, 1.);
						gl_Position = projectionMatrix * viewPosition;

						${ShaderChunkLogDepth.Vertex}
					}`,
				fragmentShader: `
					precision highp float;

					#define DEFAULT_SPECULAR_COEFFICIENT 0.04
					#define EPSILON 1e-6
					#define PI 3.14159265359
					#define RECIPROCAL_PI 0.31830988618
					#define RECIPROCAL_PI2 0.15915494
					#ifndef saturate
						#define saturate(a) clamp( a, 0.0, 1.0 )
					#endif

					float pow2( float x ) { return x*x; }

					uniform mat4 modelMatrix;

					// Lights
					uniform vec3 ambientLightColor;
					uniform vec3 lightPositions[5];
					uniform vec3 lightColors[5];
					uniform float lightRadii[5];
					uniform int numLights;
					uniform float environmentIntensity;
					uniform float gammaCorrectionFactor;

					// External lighting and camera.
					uniform vec3 entityPos;

					// Textures.
					uniform sampler2D colorTexture;
					uniform sampler2D roughnessTexture;
					uniform sampler2D metalnessTexture;
					#ifdef normalMap
						uniform sampler2D normalTexture;
					#endif
					#ifdef emissiveMap
						uniform sampler2D emissiveTexture;
					#endif
					#ifdef dynEnvMap
						uniform sampler2D dynEnvTexture;
						uniform float dynEnvFaceSize;
					#elif defined( envMap )
						#ifdef envIsCube
							uniform samplerCube envTexture;
						#else
							uniform sampler2D envTexture;
						#endif
					#endif

					#ifdef normalMap
						uniform vec2 normalScale;
					#endif

					// Shadow Entities.
					#ifdef shadowEntities
						uniform int numShadowEntities;
						uniform vec3 shadowEntityPositions[7];
						uniform float shadowEntityRadii[7];
						uniform float shadowEntitySunsetIntensity[7];
						uniform vec3 shadowEntitySunsetColors[7];
					#endif

					// Scalars
					uniform vec3 color;
					uniform float roughness;
					uniform float metalness;
					uniform vec3 emissiveColor;
					uniform float alphaMultiplier;

					// The varying attributes.
					#ifdef normalMap
						varying vec4 localTangent;
						varying vec3 localNormal;
					#else
						varying vec3 modelNormal;
					#endif
					#if defined(normalMap) || defined(colorMap) || defined(roughnessMap) || defined(metalnessMap) || defined(emissiveMap)
						varying vec2 localUV;
					#endif
					varying vec3 cameraSpacePosition;

					${ShaderChunkLogDepth.FragmentHead}

					struct PhysicalMaterial {
						vec3 diffuseColor;
						vec3 specularColor;
						float specularRoughness;
					};
					struct IncidentLight {
						vec3 color;
						vec3 direction;
					};

					struct ReflectedLight {
						vec3 directDiffuse;
						vec3 directSpecular;
						vec3 indirectDiffuse;
						vec3 indirectSpecular;
					};

					struct GeometricContext {
						vec3 normal;
						vec3 viewDir;
					};

					#ifdef normalMap
						vec3 getNormalFromMap() {
							vec3 normal = normalize(localNormal);
							vec3 tangent = normalize(localTangent.xyz);
							vec3 bitangent = normalize(cross(normal, tangent));
							if (localTangent.w < 0.0) {
								bitangent *= -1.0;
							}
							mat3 transform = mat3(tangent, bitangent, normal);
							vec3 normalFromMap = texture2D(normalTexture, localUV).rgb * 2.0 - 1.0;
							normalFromMap.xy *= vec2(1, -1);
							normalFromMap.xy *= normalScale;
							return normalize(transform * normalFromMap);
						}
					#endif

					#ifdef shadowEntities
						vec3 applyRayleighScattering(vec3 color, float amount) {
							float value = (color.r + color.g + color.b);
							if (value > 0.0) {
								float rFactor = 1.0; // 6.3^-4 / 6.3^-4
								float gFactor = 1.602; // 5.6^-4 / 6.3^-4
								float bFactor = 3.228; // 4.7^-4 / 6.3^-4
								color.r *= pow(rFactor, -amount);
								color.g *= pow(gFactor, -amount);
								color.b *= pow(bFactor, -amount);
								color = value * color / (color.r + color.g + color.b);
							}
							return color;
						}

						vec3 getLightColorFromShadowEntities(vec3 lightColor, vec3 lightDir, vec3 lightPosition, float lightRadius, vec3 normal) {
							vec3 color = lightColor;
							for (int i = 0; i < 7; i++) {
								if (i >= numShadowEntities || lightRadius < 0.0) {
									break;
								}
								vec3 origin = cameraSpacePosition - shadowEntityPositions[i];
								vec3 axis = normalize(shadowEntityPositions[i] - lightPosition);
								float sd = dot(origin, axis);
								if (sd > 0.0) {
									float e = length(origin - sd * axis);
									float ld = dot(cameraSpacePosition - lightPosition, axis);
									float lr = lightRadius;
									float sr = shadowEntityRadii[i];
									float e0 = (ld * sr - sd * lr) / (ld - sd);
									float e1 = (ld * sr + sd * lr) / (ld - sd);
									float lightLevel = 0.0;
									if (e1 < 0.0 || sd < 0.0) { // light in front of shadow entity
										lightLevel = 1.0;
									}
									else if (e0 < e1) {
										e0 -= (e1 - e0) * saturate(shadowEntitySunsetIntensity[i] / 3.0);
										lightLevel = pow(saturate((e - e0) / (e1 - e0)), 0.5); // soft light
									}
									else {
										lightLevel = e < e0 ? 0.0 : 1.0; // 0 radius light.
									}
									color = lightLevel * mix(color, shadowEntitySunsetColors[i], (1.0 - lightLevel) * saturate(shadowEntitySunsetIntensity[i]));
								}
							}
							return color;
						}
					#endif

					vec3 inverseTransformDirection( in vec3 dir, in mat4 matrix ) {
						return normalize( ( vec4( dir, 0.0 ) * matrix ).xyz );
					}

					// These use optimizations found in https://cdn2.unrealengine.com/Resources/files/2013SiggraphPresentationsNotes-26915738.pdf.

					// The Smith-method geometry function. Calculates the ratio of incident light that is blocked by the microfacets to never reach the viewer.
					// alpha is the roughness^2
					// dotNL is the normal · the light vector.
					// dotNV is the normal · the view vector.
					float G_GGX_SmithCorrelated( float alpha, float dotNL, float dotNV ) {
						float a2 = pow2( alpha );
						// Get the light direction part of the geometry function.
						float gv = dotNL * sqrt( a2 + ( 1.0 - a2 ) * pow2( dotNV ) );
						// Get the view direction part of the geometry function.
						float gl = dotNV * sqrt( a2 + ( 1.0 - a2 ) * pow2( dotNL ) );
						// It would normally be be gv * gl, but this is using an optimization by Heitz (2014),
						// including the BRDF denominator, simplifying the results.
						return 0.5 / max( gv + gl, EPSILON );
					}

					// The Trowbridge-Reitz normal distribution function. Calculates the relative surface area microfacets exactly aligned to the halfway vector, how "smooth" the surface is.
					// alpha is the roughness^2.
					// dotNH is the normal · the halfway vector.
					float D_GGX( float alpha, float dotNH ) {
						float a2 = pow2( alpha );
						float denom = pow2( dotNH ) * ( a2 - 1.0 ) + 1.0;
						return RECIPROCAL_PI * a2 / pow2( denom );
					}

					// The Schlick approximation for the Fresnel equation.
					// Since metallic and non-metallic surfaces have different equations, this function combines the two by approximation.
					// specularColor is the specular color at normal incidence.
					// dotLH is the light direction · the halfway vector.
					vec3 F_Schlick( vec3 specularColor, float dotLH ) {
						float fresnel = exp2( ( -5.55473 * dotLH - 6.98316 ) * dotLH );
						return ( 1.0 - specularColor ) * fresnel + specularColor;
					}

					// The specular part of the main BRDF function that describes the weighting function for the sum of every incoming light.
					// It uses the Cook-Torrance model.
					vec3 BRDF_Specular_GGX( IncidentLight incidentLight, GeometricContext geometry, vec3 specularColor, float roughness ) {
						float alpha = pow2( roughness );
						vec3 halfDir = normalize( incidentLight.direction + geometry.viewDir );
						float dotNL = saturate( dot( geometry.normal, incidentLight.direction ) );
						float dotNV = saturate( dot( geometry.normal, geometry.viewDir ) );
						float dotNH = saturate( dot( geometry.normal, halfDir ) );
						float dotLH = saturate( dot( incidentLight.direction, halfDir ) );
						vec3 F = F_Schlick( specularColor, dotLH );
						float G = G_GGX_SmithCorrelated( alpha, dotNL, dotNV );
						float D = D_GGX( alpha, dotNH );
						return F * ( G * D );
					}

					// The diffuse part of the main BRDF function.
					vec3 BRDF_Diffuse_Lambert( vec3 diffuseColor ) {
						return RECIPROCAL_PI * diffuseColor;
					}

					float BlinnExponentToGGXRoughness( float blinnExponent ) {
						return sqrt( 2.0 / ( blinnExponent + 2.0 ) );
					}

					float GGXRoughnessToBlinnExponent( float ggxRoughness ) {
						return ( 2.0 / pow2( ggxRoughness + 0.0001 ) - 2.0 );
					}

					vec2 integrateSpecularBRDF( float dotNV, float roughness ) {
						const vec4 c0 = vec4( - 1, - 0.0275, - 0.572, 0.022 );
						const vec4 c1 = vec4( 1, 0.0425, 1.04, - 0.04 );
						vec4 r = roughness * c0 + c1;
						float a004 = min( r.x * r.x, exp2( - 9.28 * dotNV ) ) * r.x + r.y;
						return vec2( -1.04, 1.04 ) * a004 + r.zw;
					}

					vec3 BRDF_Specular_GGX_Environment( GeometricContext geometry, vec3 specularColor, float roughness ) {
						float dotNV = saturate( dot( geometry.normal, geometry.viewDir ) );
						vec2 brdf = integrateSpecularBRDF( dotNV, roughness );
						return specularColor * brdf.x + brdf.y;
					}

					void BRDF_Specular_Multiscattering_Environment( GeometricContext geometry, vec3 specularColor, float roughness, inout vec3 singleScatter, inout vec3 multiScatter ) {
						float dotNV = saturate( dot( geometry.normal, geometry.viewDir ) );
						vec3 F = F_Schlick( specularColor, dotNV );
						vec2 brdf = integrateSpecularBRDF( dotNV, roughness );
						vec3 FssEss = F * brdf.x + brdf.y;
						float Ess = brdf.x + brdf.y;
						float Ems = 1.0 - Ess;
						vec3 Favg = specularColor + ( 1.0 - specularColor ) * 0.047619;	vec3 Fms = FssEss * Favg / ( 1.0 - Ems * Favg );
						singleScatter += FssEss;
						multiScatter += Fms * Ems;
					}

					float opacity = 1.0;

					uniform float reflectivity;
					uniform int maxMipLevel;

					// Returns the radiance: the incoming light * the cos(light angle to the normal)
					vec3 getIncomingLight( IncidentLight directLight, GeometricContext geometry) {
						float dotNL = saturate( dot( geometry.normal, directLight.direction ) );
						return (dotNL * directLight.color);
					}

					float getSpecularMIPLevel( float blinnShininessExponent, int maxMIPLevel ) {
						float maxMIPLevelScalar = float( maxMIPLevel );
						float desiredMIPLevel = maxMIPLevelScalar + 0.79248 - 0.5 * log2( pow2( blinnShininessExponent ) + 1.0 );
						return clamp( desiredMIPLevel, 0.0, maxMIPLevelScalar );
					}

					#ifdef envIsCubeUV
						// These defines must match with PMREMGenerator
						#define cubeUV_maxMipLevel 8.0
						#define cubeUV_minMipLevel 4.0
						#define cubeUV_maxTileSize 256.0
						#define cubeUV_minTileSize 16.0
						// These shader functions convert between the UV coordinates of a single face of
						// a cubemap, the 0-5 integer index of a cube face, and the direction vector for
						// sampling a textureCube (not generally normalized ).
						float getFace( vec3 direction ) {
							vec3 absDirection = abs( direction );
							float face = - 1.0;
							if ( absDirection.x > absDirection.z ) {
								if ( absDirection.x > absDirection.y )
									face = direction.x > 0.0 ? 0.0 : 3.0;
								else
									face = direction.y > 0.0 ? 1.0 : 4.0;
							} else {
								if ( absDirection.z > absDirection.y )
									face = direction.z > 0.0 ? 2.0 : 5.0;
								else
									face = direction.y > 0.0 ? 1.0 : 4.0;
							}
							return face;
						}
						// RH coordinate system; PMREM face-indexing convention
						vec2 getUV( vec3 direction, float face ) {
							vec2 uv;
							if ( face == 0.0 ) {
								uv = vec2( direction.z, direction.y ) / abs( direction.x ); // pos x
							} else if ( face == 1.0 ) {
								uv = vec2( - direction.x, - direction.z ) / abs( direction.y ); // pos y
							} else if ( face == 2.0 ) {
								uv = vec2( - direction.x, direction.y ) / abs( direction.z ); // pos z
							} else if ( face == 3.0 ) {
								uv = vec2( - direction.z, direction.y ) / abs( direction.x ); // neg x
							} else if ( face == 4.0 ) {
								uv = vec2( - direction.x, direction.z ) / abs( direction.y ); // neg y
							} else {
								uv = vec2( direction.x, direction.y ) / abs( direction.z ); // neg z
							}
							return 0.5 * ( uv + 1.0 );
						}
						vec3 bilinearCubeUV( sampler2D environmentMap, vec3 direction, float mipInt ) {
							float face = getFace( direction );
							float filterInt = max( cubeUV_minMipLevel - mipInt, 0.0 );
							mipInt = max( mipInt, cubeUV_minMipLevel );
							float faceSize = exp2( mipInt );
							float texelSize = 1.0 / ( 3.0 * cubeUV_maxTileSize );
							vec2 uv = getUV( direction, face ) * ( faceSize - 1.0 );
							vec2 f = fract( uv );
							uv += 0.5 - f;
							if ( face > 2.0 ) {
								uv.y += faceSize;
								face -= 3.0;
							}
							uv.x += face * faceSize;
							if ( mipInt < cubeUV_maxMipLevel ) {
								uv.y += 2.0 * cubeUV_maxTileSize;
							}
							uv.y += filterInt * 2.0 * cubeUV_minTileSize;
							uv.x += 3.0 * max( 0.0, cubeUV_maxTileSize - 2.0 * faceSize );
							uv *= texelSize;
							vec3 tl = texture2D( environmentMap, uv ).rgb;
							uv.x += texelSize;
							vec3 tr = texture2D( environmentMap, uv ).rgb;
							uv.y += texelSize;
							vec3 br = texture2D( environmentMap, uv ).rgb;
							uv.x -= texelSize;
							vec3 bl = texture2D( environmentMap, uv ).rgb;
							vec3 tm = mix( tl, tr, f.x );
							vec3 bm = mix( bl, br, f.x );
							return mix( tm, bm, f.y );
						}
						#define r0 1.0
						#define v0 0.339
						#define m0 - 2.0
						#define r1 0.8
						#define v1 0.276
						#define m1 - 1.0
						#define r4 0.4
						#define v4 0.046
						#define m4 2.0
						#define r5 0.305
						#define v5 0.016
						#define m5 3.0
						#define r6 0.21
						#define v6 0.0038
						#define m6 4.0
						float roughnessToMip( float roughness ) {
							float mip = 0.0;
							if ( roughness >= r1 ) {
								mip = ( r0 - roughness ) * ( m1 - m0 ) / ( r0 - r1 ) + m0;
							} else if ( roughness >= r4 ) {
								mip = ( r1 - roughness ) * ( m4 - m1 ) / ( r1 - r4 ) + m1;
							} else if ( roughness >= r5 ) {
								mip = ( r4 - roughness ) * ( m5 - m4 ) / ( r4 - r5 ) + m4;
							} else if ( roughness >= r6 ) {
								mip = ( r5 - roughness ) * ( m6 - m5 ) / ( r5 - r6 ) + m5;
							} else {
								mip = - 2.0 * log2( 1.16 * roughness ); // 1.16 = 1.79^0.25
							}
							return mip;
						}
						vec4 textureCubeUV( sampler2D environmentMap, vec3 sampleDir, float roughness ) {
							float mip = clamp( roughnessToMip( roughness ), m0, cubeUV_maxMipLevel );
							float mipF = fract( mip );
							float mipInt = floor( mip );
							vec3 color0 = bilinearCubeUV( environmentMap, sampleDir, mipInt );
							if ( mipF == 0.0 ) {
								return vec4( color0, 1.0 );
							} else {
								vec3 color1 = bilinearCubeUV( environmentMap, sampleDir, mipInt + 1.0 );
								return vec4( mix( color0, color1, mipF ), 1.0 );
							}
						}
					#endif

					#ifdef dynEnvMap
						// Converts an XY in cylindrical space to a face (z) with coordinates within that face (xy).
						vec3 xyzToUvFace(vec3 xyz, float pixelSize) {
							// Figure out which basis we're using.
							vec3 basis[3];
							float face;
							if (xyz.x * xyz.x >= xyz.y * xyz.y && xyz.x * xyz.x >= xyz.z * xyz.z) {
								if (xyz.x >= 0.0) {
									basis[0] = vec3(0, 1, 0); basis[1] = vec3(0, 0, 1); basis[2] = vec3(1, 0, 0);
									face = 0.0;
								}
								else {
									basis[0] = vec3(0, -1, 0); basis[1] = vec3(0, 0, 1); basis[2] = vec3(-1, 0, 0);
									face = 2.0;
								}
							}
							else if (xyz.y * xyz.y >= xyz.x * xyz.x && xyz.y * xyz.y >= xyz.z * xyz.z) {
								if (xyz.y >= 0.0) {
									basis[0] = vec3(-1, 0, 0); basis[1] = vec3(0, 0, 1); basis[2] = vec3(0, 1, 0);
									face = 1.0;
								}
								else {
									basis[0] = vec3(1, 0, 0); basis[1] = vec3(0, 0, 1); basis[2] = vec3(0, -1, 0);
									face = 3.0;
								}
							}
							else {
								if (xyz.z >= 0.0) {
									basis[0] = vec3(0, 1, 0); basis[1] = vec3(-1, 0, 0); basis[2] = vec3(0, 0, 1);
									face = 4.0;
								}
								else {
									basis[0] = vec3(0, 1, 0); basis[1] = vec3(1, 0, 0); basis[2] = vec3(0, 0, -1);
									face = 5.0;
								}
							}

							// Convert into the uv basis from the xyz basis.
							float z = basis[2].x * xyz.x + basis[2].y * xyz.y + basis[2].z * xyz.z;
							if (z < 0.0) {
								z = 1.0;
							}
							vec2 uv = vec2(
								(basis[0].x * xyz.x + basis[0].y * xyz.y + basis[0].z * xyz.z) / z,
								(basis[1].x * xyz.x + basis[1].y * xyz.y + basis[1].z * xyz.z) / z);

							// Convert from -1 to +1, to 0 to 1.
							uv = 0.5 * (uv + vec2(1.0));

							// Convert to pixel-space.
							uv *= pixelSize;

							// Shrink to ignore 1 pixel borders.
							uv.x = (pixelSize - 2.0) / pixelSize * uv.x + 1.0;
							uv.y = (pixelSize - 2.0) / pixelSize * uv.y + 1.0;

							// Convert back to unit-space.
							uv /= pixelSize;

							return vec3(uv, face);
						}

						// Gets the dynamic environmental lighting given the outward direction.
						vec3 getEnvLight(vec3 direction, float roughness) {
							// Get the mip levels.
							float mipLevel = pow(roughness, 0.25) * (log2(dynEnvFaceSize) - 2.0);
							float mipLevel0 = floor(mipLevel);
							float mipLevel1 = floor(mipLevel) + 1.0;
							float mipLevelU = mipLevel - mipLevel0;
							float mipSizeX0 = pow(2.0, -mipLevel0);
							float mipOffsetY0 = 1.0 - pow(2.0, -mipLevel0);
							float mipSizeX1 = pow(2.0, -mipLevel1);
							float mipOffsetY1 = 1.0 - pow(2.0, -mipLevel1);
							// Get UV within a mip of cube faces.
							vec3 uvFace0 = xyzToUvFace(direction, dynEnvFaceSize * mipSizeX0);
							vec3 uvFace1 = xyzToUvFace(direction, dynEnvFaceSize * mipSizeX1);
							vec2 faceOffset = vec2(mod(uvFace0.z, 3.0) / 3.0, floor(uvFace0.z / 3.0) / 2.0);
							vec2 uvInMip0 = vec2(faceOffset.x + uvFace0.x / 3.0, faceOffset.y + uvFace0.y / 2.0);
							vec2 uvInMip1 = vec2(faceOffset.x + uvFace1.x / 3.0, faceOffset.y + uvFace1.y / 2.0);
							// Get the UVs within the textures.
							vec2 uv0 = vec2(uvInMip0.x * mipSizeX0 * 0.75, 0.5 * uvInMip0.y * mipSizeX0 + mipOffsetY0);
							vec2 uv1 = vec2(uvInMip1.x * mipSizeX1 * 0.75, 0.5 * uvInMip1.y * mipSizeX1 + mipOffsetY1);
							vec3 color0 = texture2D(dynEnvTexture, uv0).rgb;
							vec3 color1 = texture2D(dynEnvTexture, uv1).rgb;
							return mix(color0, color1, mipLevelU) * environmentIntensity * ((PI - 1.0) * roughness + 1.0);
						}
					#endif

					vec3 getLightProbeIndirectRadiance( GeometricContext geometry, float blinnShininessExponent, int maxMIPLevel ) {
						vec3 reflectVec = reflect( -geometry.viewDir, geometry.normal );
						#if defined( envMap )
							reflectVec = inverseTransformDirection( reflectVec, viewMatrix );
							float specularMIPLevel = getSpecularMIPLevel( blinnShininessExponent, maxMIPLevel );
							vec3 queryReflectVec = vec3( reflectVec.x, reflectVec.yz );
							#ifdef envIsCube
								#ifdef TEXTURE_LOD_EXT
									vec4 envMapColor = textureCubeLodEXT( envTexture, queryReflectVec, specularMIPLevel );
								#else
									vec4 envMapColor = textureCube( envTexture, queryReflectVec, specularMIPLevel );
								#endif
							#elif defined( envIsCubeUV )
								vec4 envMapColor = textureCubeUV( envTexture, queryReflectVec, BlinnExponentToGGXRoughness(blinnShininessExponent ));
							#else
								vec2 sampleUV;
								sampleUV.y = asin( clamp( reflectVec.y, - 1.0, 1.0 ) ) * RECIPROCAL_PI + 0.5;
								sampleUV.x = atan( reflectVec.z, reflectVec.x ) * RECIPROCAL_PI2 + 0.5;
								#ifdef TEXTURE_LOD_EXT
									vec4 envMapColor = texture2DLodEXT( envTexture, sampleUV, specularMIPLevel );
								#else
									vec4 envMapColor = texture2D( envTexture, sampleUV, specularMIPLevel );
								#endif
							#endif
							return envMapColor.rgb * environmentIntensity;
						#else
							return vec3(0, 0, 0);
						#endif
					}

					// Given the directLight, accumulates onto the reflectedLight the irradiance as the BRDF function.
					void RE_Direct_Physical( IncidentLight directLight, GeometricContext geometry, PhysicalMaterial material, inout ReflectedLight reflectedLight ) {
						vec3 irradiance = getIncomingLight( directLight, geometry );
						irradiance *= PI;
						float clearCoatDHR = 0.0;
						reflectedLight.directSpecular += ( 1.0 - clearCoatDHR ) * irradiance * BRDF_Specular_GGX( directLight, geometry, material.specularColor, material.specularRoughness );
						reflectedLight.directDiffuse += ( 1.0 - clearCoatDHR ) * irradiance * BRDF_Diffuse_Lambert( material.diffuseColor );
					}

					void RE_IndirectDiffuse_Physical( vec3 irradiance, GeometricContext geometry, PhysicalMaterial material, inout ReflectedLight reflectedLight ) {
						reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert( material.diffuseColor );
					}

					void RE_IndirectSpecular_Physical( vec3 radiance, vec3 irradiance, vec3 clearCoatRadiance, GeometricContext geometry, PhysicalMaterial material, inout ReflectedLight reflectedLight) {
						float clearCoatDHR = 0.0;
						float clearCoatInv = 1.0 - clearCoatDHR;
						reflectedLight.indirectSpecular += clearCoatInv * radiance * BRDF_Specular_GGX_Environment( geometry, material.specularColor, material.specularRoughness );
					}

					#define RE_Direct RE_Direct_Physical
					#define Material_BlinnShininessExponent( material ) GGXRoughnessToBlinnExponent( material.specularRoughness )
					#define RE_IndirectDiffuse RE_IndirectDiffuse_Physical
					#define RE_IndirectSpecular RE_IndirectSpecular_Physical

					void main(void) {
						vec4 diffuseColor = vec4( color, 1.0 );
						ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );

						#ifdef colorMap
							vec4 texelColor = texture2D(colorTexture, localUV);
							diffuseColor *= texelColor;
							opacity = texelColor.a;
						#endif

						// PBR variables
						float roughnessFactor;
						float metalnessFactor;

						roughnessFactor = roughness;
						#ifdef roughnessMap
							roughnessFactor *= texture2D( roughnessTexture, localUV ).g;
						#endif

						metalnessFactor = metalness;
						#ifdef metalnessMap
							metalnessFactor *= texture2D( metalnessTexture, localUV ).b;
						#endif

						PhysicalMaterial material;
						material.diffuseColor = diffuseColor.rgb * ( 1.0 - metalnessFactor );
						material.specularRoughness = clamp( roughnessFactor, 0.04, 1.0 );
						material.specularColor = mix( vec3( DEFAULT_SPECULAR_COEFFICIENT ), diffuseColor.rgb, metalnessFactor );

						GeometricContext geometry;
						geometry.viewDir = -normalize(cameraSpacePosition);
						#ifdef normalMap
							geometry.normal = normalize((modelMatrix * vec4(getNormalFromMap(), 0.)).xyz);
						#else
							geometry.normal = modelNormal;
						#endif

						// Env Light
						vec3 irradiance = ambientLightColor;
						#ifdef dynEnvMap
							irradiance += getEnvLight(geometry.normal, 1.0);
						#endif
						vec3 radiance = ambientLightColor / 2.0;
						#ifdef dynEnvMap
							radiance += getEnvLight(reflect(-geometry.viewDir, geometry.normal), material.specularRoughness);
						#endif

						// Add emissivity.
						vec3 totalEmissiveRadiance = emissiveColor;
						#ifdef emissiveMap
							totalEmissiveRadiance *= texture2D(emissiveTexture, localUV).rgb;
						#endif

						// Add direct radiance.
						vec3 totalDirectIrradiance = ambientLightColor / 2.0;
						#ifdef dynEnvMap
							totalDirectIrradiance += getEnvLight(reflect(-geometry.viewDir, geometry.normal), material.specularRoughness);
						#endif

						// For each light,
						for (int i = 0; i < 5; i++) {
							if (i >= numLights) {
								break;
							}

							IncidentLight directLight;
							directLight.color = lightColors[i];
							directLight.direction = -normalize(cameraSpacePosition - lightPositions[i]);
							#ifdef shadowEntities
								directLight.color = getLightColorFromShadowEntities(directLight.color, directLight.direction, lightPositions[i], lightRadii[i], geometry.normal);
							#endif

							RE_Direct(directLight, geometry, material, reflectedLight);

							radiance += getLightProbeIndirectRadiance(geometry, Material_BlinnShininessExponent(material), maxMipLevel);
							RE_IndirectDiffuse(irradiance, geometry, material, reflectedLight);
							RE_IndirectSpecular(radiance, irradiance, vec3(0.0), geometry, material, reflectedLight);

							// Modify env light by total incoming light.
							totalDirectIrradiance += getIncomingLight(directLight, geometry);
						}

						// Multiply in the direct irradiance.
						reflectedLight.indirectSpecular *= totalDirectIrradiance;

						// If there's ambience, remove the direct light components.
						reflectedLight.directDiffuse *= vec3(1, 1, 1) - ambientLightColor;
						reflectedLight.directSpecular *= vec3(1, 1, 1) - ambientLightColor;

						// Add the reflected light to the outgoing light.
						vec3 outgoingLight = totalEmissiveRadiance + reflectedLight.directDiffuse + reflectedLight.directSpecular + reflectedLight.indirectDiffuse + reflectedLight.indirectSpecular;

						// Set the frag color based on the total outgoing light.
						gl_FragColor = vec4( outgoingLight, opacity );

						// Gamma correction
						gl_FragColor = vec4( pow( abs(gl_FragColor.rgb), vec3( 1.0 / gammaCorrectionFactor ) ), gl_FragColor.a );

						// Convert to sRGB.
						gl_FragColor = LinearTosRGB(gl_FragColor);

						// Multiply the alphaMultiplier.
						gl_FragColor.a *= alphaMultiplier;

						${ShaderChunkLogDepth.Fragment}
					}`
			});
		}
		const newMaterial = MaterialUtilsStandard._material.clone();
		for (let i = 0; i < newMaterial.uniforms['shadowEntityPositions'].value.length; i++) {
			newMaterial.uniforms['shadowEntityPositions'].value[i] = MaterialUtilsStandard._material.uniforms['shadowEntityPositions'].value[i].clone();
		}
		for (let i = 0; i < newMaterial.uniforms['shadowEntitySunsetColors'].value.length; i++) {
			newMaterial.uniforms['shadowEntitySunsetColors'].value[i] = MaterialUtilsStandard._material.uniforms['shadowEntitySunsetColors'].value[i].clone();
		}
		ThreeJsHelper.setupLogDepthBuffering(newMaterial);
		if (Capabilities.hasGLExtension('EXT_shader_texture_lod')) {
			newMaterial.extensions.shaderTextureLOD = true;
		}
		newMaterial.extensions.derivatives = true;
		newMaterial.needsUpdate = true;
		return newMaterial;
	}
}

/** @type {THREE.ShaderMaterial} */
MaterialUtilsStandard._material = null;
