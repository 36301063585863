/** @module pioneer */
import {
	BaseController,
	Entity,
	Vector3
} from '../../internal';

/**
 * A controller that scales the position of the entity.
 */
export class ScaleController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		// The amount to scale the position.
		this._scale = 1.0;

		// Let the base controller know that this changes the position.
		this.addModifiedState('position');
	}

	/**
	 * Gets the scale factor. Defaults to 1.
	 * @return {number}
	 */
	getScale() {
		return this._scale;
	}

	/**
	 * Sets the scale factor.
	 * @param {number} scale
	 */
	setScale(scale) {
		this._scale = scale;
	}

	/**
	 * Updates the position based on time.
	 * @param {Vector3} position
	 * @param {number} _time
	 * @override
	 * @internal
	 */
	__updatePositionAtTime(position, _time) {
		position.mult(position, this._scale);
	}

	/**
	 * Updates the velocity based on time.
	 * @param {Vector3} velocity
	 * @param {number} _time
	 * @override
	 * @internal
	 */
	__updateVelocityAtTime(velocity, _time) {
		velocity.mult(velocity, this._scale);
	}

	/**
	 * Takes input and updates the target distance. Then updates the entity's position.
	 * @override
	 * @internal
	 */
	__update() {
		const newPosition = Vector3.pool.get();
		newPosition.mult(this.getEntity().getPosition(), this._scale);
		this.getEntity().setPosition(newPosition);
		Vector3.pool.release(newPosition);
	}
}
