/** @module pioneer */
import {
	MathUtils,
	Quaternion,
	Vector3
} from '../internal';

export class OrbitalElements {
	constructor() {
		/**
		 * The epoch.
		 * @type {number}
		 */
		this.epoch = 0;

		/**
		 * The eccentricity.
		 * @type {number}
		 */
		this.eccentricity = 0;

		/**
		 * The semi-major axis.
		 * @type {number}
		 */
		this.semiMajorAxis = 0;

		/**
		 * The mean angular motion.
		 * @type {number}
		 */
		this.meanAngularMotion = 0;

		/**
		 * The mean anomaly at the epoch.
		 * @type {number}
		 */
		this.meanAnomalyAtEpoch = 0;

		/**
		 * The orbit's oriention.
		 * The x-axis is along the eccentricity vector (periapsis) and the z-axis is along the angular momentum vector.
		 * @type {Quaternion}
		 */
		this.orbitOrientation = new Quaternion();
	}

	/**
	 * Copies an other orbital elements to this.
	 * @param {OrbitalElements} other
	 */
	copy(other) {
		this.epoch = other.epoch;
		this.eccentricity = other.eccentricity;
		this.semiMajorAxis = other.semiMajorAxis;
		this.meanAnomalyAtEpoch = other.meanAnomalyAtEpoch;
		this.meanAngularMotion = other.meanAngularMotion;
		this.orbitOrientation.copy(other.orbitOrientation);
	}

	/**
	 * Projects a position and velocity based on the orbital elements.
	 * @param {Vector3} outPosition
	 * @param {Vector3} outVelocity
	 * @param {number} meanAnomaly
	 */
	projectFromMeanAnomaly(outPosition, outVelocity, meanAnomaly) {
		if (this.eccentricity < 1.0) {
			// Get the eccentric anomaly.
			const eccentricAnomaly = this.getEccentricAnomalyFromMeanAnomaly(meanAnomaly);

			// Set the position and rotate it by the orbit orientation.
			const radius = this.semiMajorAxis * (1.0 - this.eccentricity * Math.cos(eccentricAnomaly));
			const velocityFactor = this.meanAngularMotion * this.semiMajorAxis * this.semiMajorAxis / radius;
			outPosition.x = this.semiMajorAxis * (Math.cos(eccentricAnomaly) - this.eccentricity);
			outPosition.y = this.semiMajorAxis * Math.sqrt(1.0 - this.eccentricity * this.eccentricity) * Math.sin(eccentricAnomaly);
			outVelocity.x = -velocityFactor * Math.sin(eccentricAnomaly);
			outVelocity.y = velocityFactor * Math.sqrt(1.0 - this.eccentricity * this.eccentricity) * Math.cos(eccentricAnomaly);
		}
		else {
			// Get the hyperbolic anomaly.
			const hyperbolicAnomaly = this.getEccentricAnomalyFromMeanAnomaly(meanAnomaly);

			// Set the position and rotate it by the orbit orientation.
			const radius = -this.semiMajorAxis * (1.0 - this.eccentricity * Math.cosh(hyperbolicAnomaly));
			const velocityFactor = this.meanAngularMotion * this.semiMajorAxis * this.semiMajorAxis / radius;
			outPosition.x = this.semiMajorAxis * (this.eccentricity - Math.cosh(hyperbolicAnomaly));
			outPosition.y = this.semiMajorAxis * Math.sqrt(this.eccentricity * this.eccentricity - 1.0) * Math.sinh(hyperbolicAnomaly);
			outVelocity.x = -velocityFactor * Math.sinh(hyperbolicAnomaly);
			outVelocity.y = velocityFactor * Math.sqrt(this.eccentricity * this.eccentricity - 1.0) * Math.cosh(hyperbolicAnomaly);
		}
		outPosition.z = 0.0;
		outVelocity.z = 0.0;

		// Rotate the vectors by the orbit orientation.
		outPosition.rotate(this.orbitOrientation, outPosition);
		outVelocity.rotate(this.orbitOrientation, outVelocity);
	}

	/**
	 * Projects a position and velocity based on the orbital elements.
	 * @param {Vector3} outPosition
	 * @param {Vector3} outVelocity
	 * @param {number} time
	 */
	project(outPosition, outVelocity, time) {
		// Get the mean anomaly for the time.
		let meanAnomaly = this.meanAnomalyAtEpoch + this.meanAngularMotion * (time - this.epoch);
		if (this.eccentricity < 1.0) {
			meanAnomaly = MathUtils.wrap(meanAnomaly, -MathUtils.pi, +MathUtils.pi);
		}
		this.projectFromMeanAnomaly(outPosition, outVelocity, meanAnomaly);
	}

	/**
	 * Gets the true anomaly given a position.
	 * @param {Vector3} position
	 * @returns {number}
	 */
	getTrueAnomalyFromPosition(position) {
		const positionInPlane = Vector3.pool.get();
		positionInPlane.rotateInverse(this.orbitOrientation, position);
		const trueAnomaly = Math.atan2(positionInPlane.y, positionInPlane.x);
		Vector3.pool.release(positionInPlane);
		return trueAnomaly;
	}

	/**
	 * Gets the mean anomaly from the true anomaly.
	 * @param {number} trueAnomaly
	 * @returns {number}
	 */
	getMeanAnomalyFromTrueAnomaly(trueAnomaly) {
		// The eccentric anomaly, mean anomaly, and mean angular motion from the true anomaly, for either ellipses or hyperbolas.
		let eccentricAnomaly = 0.0;
		let meanAnomaly = 0.0;
		if (this.eccentricity < 1.0) {
			eccentricAnomaly = 2.0 * Math.atan(Math.tan(trueAnomaly / 2.0) * Math.sqrt((1 - this.eccentricity) / (1 + this.eccentricity)));
			meanAnomaly = eccentricAnomaly - this.eccentricity * Math.sin(eccentricAnomaly);
		}
		else {
			eccentricAnomaly = 2.0 * Math.atanh(Math.tan(trueAnomaly / 2.0) * Math.sqrt((this.eccentricity - 1) / (1 + this.eccentricity)));
			meanAnomaly = this.eccentricity * Math.sinh(eccentricAnomaly) - eccentricAnomaly;
		}
		return meanAnomaly;
	}

	/**
	 * Gets the eccentric/hyperbolic anomaly from the mean anomaly.
	 * @param {number} meanAnomaly
	 * @returns {number}
	 */
	getEccentricAnomalyFromMeanAnomaly(meanAnomaly) {
		if (this.eccentricity < 1.0) {
			// Use Newton's method to find the eccentric anomaly.
			let eccentricAnomaly = meanAnomaly;
			if (this.eccentricity >= 0.8) {
				eccentricAnomaly = Math.sign(meanAnomaly) * MathUtils.pi; // use a more optimal start point for near parabolic orbits
			}
			for (let i = 0; i < 20; i++) {
				const difference = (eccentricAnomaly - this.eccentricity * Math.sin(eccentricAnomaly) - meanAnomaly) / (this.eccentricity * Math.cos(eccentricAnomaly) - 1.0);
				eccentricAnomaly += difference;
				if (Math.abs(difference) < 0.00174532925) { // .1 degrees
					break;
				}
			}
			return eccentricAnomaly;
		}
		else {
			// Use Newton's method to find the hyperbolic anomaly.
			let hyperbolicAnomaly = Math.log(2.0 * Math.abs(meanAnomaly) / Math.E + 1.8);
			if (this.eccentricity <= 1.2) {
				hyperbolicAnomaly = Math.log(2.0 * Math.abs(meanAnomaly) / Math.E + 1.8); // use a more optimal start point for near parabolic orbits
			}
			if (meanAnomaly < 0.0) {
				hyperbolicAnomaly *= -1;
			}
			for (let i = 0; i < 20; i++) {
				const difference = (hyperbolicAnomaly - this.eccentricity * Math.sinh(hyperbolicAnomaly) + meanAnomaly) / (this.eccentricity * Math.cosh(hyperbolicAnomaly) - 1.0);
				hyperbolicAnomaly += difference;
				if (Math.abs(difference) < 0.00174532925) { // .1 degrees
					break;
				}
			}
			return hyperbolicAnomaly;
		}
	}

	/**
	 * Gets the mean anomaly from the eccentric or hyperbolic anomaly.
	 * @param {number} eccentricAnomaly
	 * @returns {number}
	 */
	getMeanAnomalyFromEccentricAnomaly(eccentricAnomaly) {
		if (this.eccentricity < 1.0) {
			const meanAnomaly = eccentricAnomaly - this.eccentricity * Math.sin(eccentricAnomaly);
			return MathUtils.wrap(meanAnomaly, -Math.PI, +Math.PI);
		}
		else {
			return this.eccentricity * Math.sinh(eccentricAnomaly) - eccentricAnomaly;
		}
	}

	/**
	 * Gets the true anomaly from the eccentric or hyperbolic anomaly.
	 * @param {number} eccentricAnomaly
	 * @returns {number}
	 */
	getTrueAnomalyFromEccentricAnomaly(eccentricAnomaly) {
		if (this.eccentricity < 1.0) {
			return 2.0 * Math.atan(Math.sqrt((1 + this.eccentricity) / (1 - this.eccentricity)) * Math.tan(eccentricAnomaly / 2.0));
		}
		else {
			return 2.0 * Math.atan(Math.sqrt((1 + this.eccentricity) / (this.eccentricity - 1)) * Math.tanh(eccentricAnomaly / 2.0));
		}
	}

	/**
	 * Gets the eccentric/hyperbolic anomaly from the true anomaly.
	 * @param {number} trueAnomaly
	 * @returns {number}
	 */
	getEccentricAnomalyFromTrueAnomaly(trueAnomaly) {
		// The eccentric anomaly, mean anomaly, and mean angular motion from the true anomaly, for either ellipses or hyperbolas.
		if (this.eccentricity < 1.0) {
			const eccentricAnomaly = 2.0 * Math.atan2(Math.tan(trueAnomaly / 2.0), Math.sqrt((1 + this.eccentricity) / (1 - this.eccentricity)));
			return MathUtils.wrap(eccentricAnomaly, -Math.PI, +Math.PI);
		}
		else {
			return 2.0 * Math.atanh(Math.sqrt((this.eccentricity - 1.0) / (this.eccentricity + 1.0)) * Math.tan(trueAnomaly / 2.0));
		}
	}

	/**
	 * Gets the inclination in radians.
	 * @returns {number}
	 */
	getInclination() {
		const h = Vector3.pool.get();
		// Get the angular momentum.
		this.orbitOrientation.getAxis(h, 2);
		// Get the inclination as angle between angular momentum and z-axis.
		const inclination = h.angle(Vector3.ZAxis);
		Vector3.pool.release(h);
		return inclination;
	}

	/**
	 * Gets the longitude of the ascending node in radians.
	 * @returns {number}
	 */
	getLongitudeOfAscendingNode() {
		const h = Vector3.pool.get();
		// Get the angular momentum.
		this.orbitOrientation.getAxis(h, 2);
		// Get the longitude of ascending node as perpendicular to the angular momentum projected onto the x-y plane.
		const longitudeOfAscendingNode = Math.atan2(h.x, -h.y);
		Vector3.pool.release(h);
		return MathUtils.wrap(longitudeOfAscendingNode, 0, MathUtils.twoPi);
	}

	/**
	 * Gets the argument of the periapsis in radians.
	 * @returns {number}
	 */
	getArgumentOfPeriapsis() {
		const h = Vector3.pool.get();
		const e = Vector3.pool.get();
		// Get the angular momentum.
		this.orbitOrientation.getAxis(h, 2);
		// Get the ascending node.
		h.set(-h.y, h.x, 0);
		// Get the eccentricity vector.
		this.orbitOrientation.getAxis(e, 0);
		const argumentOfPeriapsis = h.angle(e);
		Vector3.pool.release(e);
		Vector3.pool.release(h);
		return MathUtils.wrap(argumentOfPeriapsis, 0, MathUtils.twoPi);
	}

	/**
	 * Gets the period in seconds.
	 * @returns {number}
	 */
	getPeriod() {
		return MathUtils.twoPi / this.meanAngularMotion;
	}

	/**
	 * Gets the periapsis.
	 * @returns {number}
	 */
	getPeriapsis() {
		return this.semiMajorAxis * (1 - this.eccentricity);
	}

	/**
	 * Gets the apoapsis.
	 * @returns {number}
	 */
	getApoapsis() {
		return this.semiMajorAxis * (1 + this.eccentricity);
	}

	/**
	 * Given an inclination, longitude of ascending node, and argument of periapsis, all in radians,
	 *   get the orbit orientation as a quaternion.
	 * @param {number} inclination
	 * @param {number} longitudeOfAscendingNode
	 * @param {number} argumentOfPeriapsis
	 */
	setOrbitOrientationFromElements(inclination, longitudeOfAscendingNode, argumentOfPeriapsis) {
		const n = Vector3.pool.get();
		const h = Vector3.pool.get();
		const periapsisRotation = Quaternion.pool.get();
		n.set(Math.cos(longitudeOfAscendingNode), Math.sin(longitudeOfAscendingNode), 0);
		h.set(Math.sin(longitudeOfAscendingNode) * Math.sin(inclination), -Math.cos(longitudeOfAscendingNode) * Math.sin(inclination), Math.cos(inclination));
		this.orbitOrientation.setFromAxes(n, undefined, h);
		periapsisRotation.setFromAxisAngle(h, argumentOfPeriapsis);
		this.orbitOrientation.mult(periapsisRotation, this.orbitOrientation);
		Quaternion.pool.release(periapsisRotation);
		Vector3.pool.release(h);
		Vector3.pool.release(n);
	}

	/**
	 * Sets orbital elements from a position, velocity, time, and a gravitational constant GM.
	 * @param {Vector3} position
	 * @param {Vector3} velocity
	 * @param {number} time
	 * @param {number} gm
	 */
	setFromPositionAndVelocity(position, velocity, time, gm) {
		const gmInv = 1 / gm;

		// Set the epoch.
		this.epoch = time;

		// Get the specific angular momentum.
		const specificAngularMomentum = Vector3.pool.get();
		specificAngularMomentum.cross(position, velocity);

		// Get the eccentricity vector and eccentricity.
		const eccentricityVec = Vector3.pool.get();
		const positionNormalized = Vector3.pool.get();
		positionNormalized.normalize(position);
		eccentricityVec.cross(velocity, specificAngularMomentum);
		eccentricityVec.mult(eccentricityVec, gmInv);
		eccentricityVec.sub(eccentricityVec, positionNormalized);
		this.eccentricity = eccentricityVec.magnitude();

		// The semi-major axis.
		const semiLatusRectum = specificAngularMomentum.dot(specificAngularMomentum) * gmInv;
		this.semiMajorAxis = semiLatusRectum / (1 - this.eccentricity * this.eccentricity);
		if (this.eccentricity > 1) {
			this.semiMajorAxis *= -1;
		}

		// The true anomaly.
		let trueAnomaly = Math.acos(MathUtils.clamp(eccentricityVec.dot(positionNormalized) / this.eccentricity, -1, 1));
		if (position.dot(velocity) < 0) {
			trueAnomaly *= -1;
		}
		if (trueAnomaly < -Math.PI) {
			trueAnomaly += MathUtils.twoPi;
		}
		if (trueAnomaly >= Math.PI) {
			trueAnomaly -= MathUtils.twoPi;
		}

		// The orbit orientation quaternion.
		eccentricityVec.normalize(eccentricityVec);
		specificAngularMomentum.normalize(specificAngularMomentum);
		this.orbitOrientation.setFromAxes(eccentricityVec, undefined, specificAngularMomentum);
		Vector3.pool.release(positionNormalized);
		Vector3.pool.release(specificAngularMomentum);
		Vector3.pool.release(eccentricityVec);

		// The eccentric anomaly, mean anomaly, and mean angular motion from the true anomaly, for either ellipses or hyperbolas.
		const eccentricAnomaly = this.getEccentricAnomalyFromTrueAnomaly(trueAnomaly);
		this.meanAnomalyAtEpoch = this.getMeanAnomalyFromEccentricAnomaly(eccentricAnomaly);
		this.meanAngularMotion = Math.sqrt(gm / (this.semiMajorAxis * this.semiMajorAxis * this.semiMajorAxis));
	}
}
