import { useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';

import {
	isMobile,
	isWindows,
	is2k,
	is4k,
	isLandscape,
	isTouch,
	canHover
} from '../../helpers/deviceProps';
import { appStore, kioskStore, eventsStore, datasetStore, spacecraftStore, spoutStore, uiStore, videoStore, layersStore, cameraStore, previewStore, poiStore, gmapStore } from '../../managers/globalState';
import globalRefs, { setGlobalRef } from '../../managers/globalRefs';
import { resetCamera } from './CameraMaster';
import { getLimitedLatestEvents } from '../../helpers/processEvents';

export const touchState = { scrollable: null, startPos: null };

// Resize handler.
const onResize = () => {
	// Update global state.
	appStore.setGlobalState({
		isMobile: isMobile(),
		isWindows: isWindows(),
		is2k: is2k(),
		is4k: is4k(),
		isLandscape: isLandscape(),
		isTouch: isTouch(),
		canHover: canHover()
	});

	// Update body classes.
	document.body.classList.toggle('touch', isTouch());

	// Scroll back at the top
	if (isMobile) {
		window.scroll(0, 0);
	}
};

/**
 * Touch start event handler.
 * Determine if element is scrollable and set state.
 * @param {Event} event
 */
const onTouchStart = event => {
	const scrollable = event.target?.closest('.scrollable') !== null;

	// Store touch start position.
	const startPos = {
		x: event.touches[0].clientX,
		y: event.touches[0].clientY
	};

	touchState.scrollable = scrollable;
	touchState.startPos = startPos;
};

/**
 * Touch move event handler.
 * Prevent default if element is not scrollable.
 * @param {Event} event
 */
const onTouchMove = event => {
	// Prevent pinch zoom on the page
	const { scrollable } = touchState;
	if (!scrollable) {
		event.preventDefault();
	}
};

/**
 * Touch end event handler.
 * Reset scrollable state.
 * @param {Event} event
 */
const onTouchEnd = event => {
	touchState.scrollable = null;
};

/**
 * EventAndStateMaster component.
 * Responsible for adding and removing event listeners.
 * Responsible for directly translating query changes into global state.
 */
const EventAndStateMaster = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { isVisibleEarth } = useSnapshot(datasetStore.state);
	const { isSatellitesNow, currentSpacecraft, spacecraftLoaded, isZoomedOut } = useSnapshot(spacecraftStore.state);
	const { loading } = useSnapshot(uiStore.state);
	const { latestEvents, currentEvent } = useSnapshot(eventsStore.state);
	const { previewEventData } = useSnapshot(previewStore.state);
	const { currentVideo } = useSnapshot(videoStore.state);
	const { currentGmap } = useSnapshot(gmapStore.state);
	const { currentPoi } = useSnapshot(poiStore.state);

	const newQueries = useMemo(() => Object.fromEntries(searchParams.entries()), [searchParams]);
	const { hideUI, animating, hideExternalLinks, hideFullScreenToggle, lighting, noKeyboard, globeLat, globeLon } = newQueries;

	const { datasetParam, spacecraftParam, eventParam } = useParams();

	// Determine if we're at a limited (top 3) latest event.
	const limitedLatestEvents = getLimitedLatestEvents();
	const atLimitedEvent = limitedLatestEvents?.some(({ id }) => id === currentEvent?.id);

	const showLatest = atLimitedEvent
		|| (latestEvents && (isVisibleEarth || isSatellitesNow) && !currentSpacecraft && !currentEvent && !currentVideo && !currentGmap && !currentPoi && !previewEventData && hideUI !== 'true');

	// Put a reference to the setSearchParams function in refs global state.
	useEffect(() => {
		setGlobalRef('setQuery', setSearchParams);
	}, [setSearchParams]);

	// Update queries object in global state when searchParams updates.
	useEffect(() => {
		appStore.setGlobalState({ queries: newQueries });
	}, [newQueries]);

	// useEffect for when hideUI/animating queries change to reset ui store values / camera.
	useEffect(() => {
		if (hideUI === 'true') {
			uiStore.setGlobalState({
				isDetailPanelOpen: false,
				controlsMenu: null,
				overlayType: null,
				loading: null
			});
		}

		uiStore.setGlobalState({
			hideExternalLinks: hideExternalLinks || '',
			hideFullScreenToggle: hideFullScreenToggle || '',
			noKeyboard: noKeyboard || ''
		});

		cameraStore.setGlobalState({ globeLat: globeLat ?? null, globeLon: globeLon ?? null });

		// Reset camera (if getManager exists as this component is not inside the ManagerMaster)
		const { getManager } = globalRefs;
		getManager && resetCamera();
	}, [
		hideUI,
		animating,
		hideExternalLinks,
		hideFullScreenToggle,
		lighting,
		noKeyboard,
		globeLat,
		globeLon
	]);

	// useEffect on mount (only done once) and we don't need any dependencies.
	useEffect(() => {
		// Determine whether we should force a restart.
		const { forceRestart } = newQueries;
		kioskStore.setGlobalState({ forceRestart: forceRestart === 'true' });
	});

	// Enable spout via URL param
	// TODO: Spout query are all static and should only be set once on mount (empty dependency array).
	useEffect(() => {
		const { spout: spoutIsEnabledFromURL } = newQueries;
		const spoutIsEnabled = spoutIsEnabledFromURL === 'true';

		// Set global state with URL value
		if (spoutIsEnabled) {
			for (const key in spoutStore.stateSnapshot) {
				if (newQueries[key] && newQueries[key] !== '') {
					// Convert to boolean for global state
					if (newQueries[key] === 'true' || newQueries[key] === 'false') {
						spoutStore.setGlobalState({ [key]: newQueries[key] === 'true' });
					} else {
						// Convert to number for global state
						spoutStore.setGlobalState({ [key]: Number(newQueries[key]) });
					}
				}
			}
		}
	}, [newQueries]);

	// Show/hide latest event placemarks.
	useEffect(() => {
		eventsStore.setGlobalState({ showLatest });
		poiStore.setGlobalState({ showLatestPoI: showLatest })
		gmapStore.setGlobalState({ showLatestGmap: showLatest })
	}, [showLatest]);

	// Hide the detail panel when spacecraft or event changes.
	useEffect(() => {
		uiStore.setGlobalState({ isDetailPanelOpen: false });
	}, [spacecraftParam, eventParam]);

	// Reset groud tracks when spacecraft or dataset changes.
	useEffect(() => {
		layersStore.setGlobalState({ groundTracks: false });
	}, [spacecraftParam, datasetParam]);

	// Reset the compare object on spacecraft change & zoom in / out.
	useEffect(() => {
		const { getManager } = globalRefs;
		// Reset compare object when zooming out.
		return () => {
			getManager?.('comparison').setCompareEntity('None');
		};
	}, [isZoomedOut, currentSpacecraft]);


	useEffect(() => {
		// Add event listeners.

		// Resize.
		window.addEventListener('resize', onResize);
		window.addEventListener('orientationchange', onResize);
		onResize();

		// Touch (no need to check if touch is supported as state may change while this component is mounted)
		// window vs document reference: https://developer.mozilla.org/en-US/docs/Web/Events
		document.addEventListener('touchstart', onTouchStart);
		document.addEventListener('touchmove', onTouchMove, { passive: false });
		document.addEventListener('touchend', onTouchEnd);


		return () => {
			// Remove event listeners.
			window.removeEventListener('resize', onResize);
			window.removeEventListener('orientationchange', onResize);

			document.removeEventListener('touchstart', onTouchStart);
			document.removeEventListener('touchmove', onTouchMove);
			document.removeEventListener('touchend', onTouchEnd);
		};
	}, []);


	// useEffect to set isSceneReady if spacecraft are loaded and loading is false.
	useEffect(() => {
		if (spacecraftLoaded && loading === false) {
			appStore.setGlobalState({ isSceneReady: true });
		}
	}, [spacecraftLoaded, loading]);


	return null;
};

export default EventAndStateMaster;
