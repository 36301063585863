import { useEffect, useState } from 'react';
import { getWebpOrPngExt } from '../helpers/tools';
import ThumbnailLoader from './thumbnail_loader';

// TODO once all of the thumbnails are converted to webp, this component or updateExtension async function can be removed
const Thumbnail = ({ src, alt, open, figure = false }) => {
	const [thumbIsLoaded, setThumbIsLoaded] = useState(false);
	const [imageSource, setImageSource] = useState(src);

	const updateExtension = async () => {
		const ext = await getWebpOrPngExt(imageSource.replace('.png', '.webp'));
		setThumbIsLoaded(true);

		if (ext === 'webp') {
			setImageSource(imageSource.replace('.png', '.webp'));
		}
	};

	useEffect(() => {
		updateExtension();
	}, [imageSource]);

	if (!open) {
		return null;
	}

	const style = {
		display: thumbIsLoaded ? 'block' : 'none'
	};

	if (figure) {
		return (
			<>
				{ !thumbIsLoaded && <ThumbnailLoader /> }
				<figure>
					<img style={style} src={imageSource} alt={alt} />
					<figcaption>
						{alt}
						&nbsp;»
					</figcaption>
				</figure>
			</>
		);
	}

	const className = 'img-fluid no-drag mt-4 mt-lg-0 mx-lg-auto';
	return (
		<div className='thumb-container'>
			<img className={className} alt={alt} style={style} src={imageSource} />
			{ !thumbIsLoaded && <ThumbnailLoader /> }
		</div>
	);
};

export default Thumbnail;
