/** @module pioneer-scripts */
import { Entity } from '../entity';
import * as Pioneer from 'pioneer';

Entity.register({
	sc_mars_2020: {
		groups: ['mars', 'spacecraft', 'landers'],
		occlusionRadius: 0.0015,
		extentsRadius: 0.003,
		label: 'Mars 2020',
		parents: [
			[649385564, 'earth'],
			[649595376, 'sun'],
			[666932224, 'mars'],
			[666952859, 'sc_mars_2020_landing_site']
		],
		trail: {
			length: 10000000.0,
			lengthCoverages: [
				[10000000, 649385564, 666932224],
				[3000, 666932224, 666953098.003000],
				[9676800, 666953098.003000 + 9676800, Number.POSITIVE_INFINITY]
			],
			updatePointPositions: true
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_mars_2020/cruise_whole/msl_cruise_stage.gltf',
			rotate: [
				{ x: -90 }
			],
			shadowEntities: ['mars']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_mars_2020/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_mars_2020/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_mars_2020/mars/pos'
		}, {
			type: 'dynamo',
			url: 'sc_mars_2020/surface/lin'
		}, {
			type: 'custom',
			func: (entity) => { // This is needed because M2020_LANDING_SITE is slightly off of M2020_DIMU_A.
				const controller = entity.addControllerByClass(Pioneer.TranslateController);
				controller.setTranslation(new Pioneer.Vector3(-0.125148, 0.026867, -0.023641));
				return controller;
			},
			coverage: [666952859, 666953098.003000]
		}, {
			type: 'dynamo',
			url: 'sc_mars_2020/quat'
		}, {
			type: 'dynamo',
			url: 'sc_mars_2020/surface/quat_v2'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 0.00833333333,
			relativeToTime: 649385564,
			coverage: [649385564, 666952859]
		}, {
			type: 'rotateByEntityOrientation', // Rotate the position for IAU_MARS dynamo.
			entityForOrientation: 'mars',
			coverage: [666952859, Number.POSITIVE_INFINITY]
		}, {
			type: 'custom',
			func: (entity) => {
				const groundClamp = entity.addControllerByClass(Pioneer.GroundClampController);
				groundClamp.setDistanceFromGround(0.001);
				groundClamp.setGroundComponentRef('mars', 'cmts');
				groundClamp.setUp(Pioneer.Vector3.ZAxisNeg);
				groundClamp.setCoverage(new Pioneer.Interval(666953086.614, Number.POSITIVE_INFINITY));
				return groundClamp;
			}
		}, {
			type: 'coverage', // Make it change to Perseverance when it lands.
			coverage: [666953086.614, Number.POSITIVE_INFINITY],
			enter: (entity) => {
				const div = entity.getComponentByClass(Pioneer.DivComponent);
				div.getDiv().innerHTML.replace('Mars 2020', 'Perseverance');
			},
			exit: (entity) => {
				const div = entity.getComponentByClass(Pioneer.DivComponent);
				div.getDiv().innerHTML.replace('Perseverance', 'Mars 2020');
			}
		}, {
			type: 'coverage', // Make the first part of the rover surface trail start at landing.
			coverage: [666953098.003000, 666953098.003000 + 9676800],
			enter: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setStartTime(666953098.003000);
					trail.setRelativeStartTime(false);
				}
			},
			exit: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setRelativeStartTime(true);
				}
			}
		}, {
			type: 'coverage', // Coverage for making trail relative to entity.
			coverage: [666932224, Number.POSITIVE_INFINITY],
			enter: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setRelativeToEntityOrientation(true);
				}
			},
			exit: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setRelativeToEntityOrientation(false);
				}
			}
		}, {
			type: 'coverage', // Change the model to the rover at the right time.
			coverage: [666953086.614, Number.POSITIVE_INFINITY],
			enter: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				model.setUrl('$STATIC_ASSETS_URL/models/sc_mars_2020/rover/perseverance.gltf');
				model.setRotation(new Pioneer.Quaternion(0.5, -0.5, 0.5, -0.5));
				model.setTranslation(new Pioneer.Vector3(0, 0, 0.001));
			},
			exit: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				model.setUrl('$STATIC_ASSETS_URL/models/sc_mars_2020/cruise_whole/msl_cruise_stage.gltf');
				model.setRotation(new Pioneer.Quaternion(0.7071067811865478, -0.7071067811865472, 0, 0));
				model.setTranslation(new Pioneer.Vector3(0, 0, 0));
			}
		}, {
			type: 'coverage', // Make the trail not fade when on the ground.
			coverage: [666953098.003000, Number.POSITIVE_INFINITY],
			enter: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setIgnoreDistance(true);
				}
			},
			exit: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setIgnoreDistance(false);
				}
			}
		}, { // Remove the cruise pieces when starting the EDL.
			type: 'coverage',
			coverage: [666952458, 666953086.614],
			enter: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				if (model !== null) {
					model.setHiddenObject('solar_panels', true);
					model.setHiddenObject('frame_etc', true);
				}
			},
			exit: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				if (model !== null) {
					model.setHiddenObject('solar_panels', false);
					model.setHiddenObject('frame_etc', false);
				}
			}
		}]
	},
	sc_mars_2020_landing_site: {
		groups: ['mars', 'sc_mars_2020', 'sites'],
		radius: 0.001,
		systemRadius: 200,
		label: 'Mars 2020 Landing Site',
		parents: [
			[649385563.6433017, 'mars']
		],
		controllers: [{
			type: 'fixed',
			llaOnSpheroid: new Pioneer.LatLonAlt(0.32191947313922714, 1.351772964399859, -2.2364351843552868),
			coverage: [649385563.6433017, Number.POSITIVE_INFINITY]
		}]
	},
	sc_mars_science_laboratory: {
		groups: ['mars', 'spacecraft', 'landers'],
		occlusionRadius: 0.001515,
		extentsRadius: 0.004,
		label: 'Mars Science Laboratory',
		parents: [
			[375594733, 'earth'],
			[376039259, 'sun'],
			[397477501, 'mars'],
			[397502386.832, 'sc_mars_science_laboratory_landing_site']
		],
		trail: {
			length: 10000000,
			lengthCoverages: [
				[10000000, 375594733, 397501373],
				[3000, 397501373, 397502386.832 + 9676800],
				[9676800, 397502386.832 + 9676800, Number.POSITIVE_INFINITY]
			],
			updatePointPositions: true
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_mars_science_laboratory/cruise/msl_cruisestage.gltf',
			rotate: [
				{ x: -90 }
			],
			shadowEntities: ['mars']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_mars_science_laboratory/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_mars_science_laboratory/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_mars_science_laboratory/mars/orb'
		}, {
			type: 'dynamo',
			url: 'sc_mars_science_laboratory/edl/pos'
		}, {
			type: 'custom',
			func: (entity) => { // This is needed because MSL_LANDING_SITE is slightly off of MSL_SITE_1.
				const controller = entity.addControllerByClass(Pioneer.TranslateController);
				controller.setTranslation(new Pioneer.Vector3(0.002583, 0.002995, 0.001937));
				return controller;
			},
			coverage: [397501373, 397502386.832]
		}, {
			type: 'dynamo',
			url: 'sc_mars_science_laboratory/surface/lin_v2'
		}, {
			type: 'dynamo',
			url: 'sc_mars_science_laboratory/quat'
		}, {
			type: 'dynamo',
			url: 'sc_mars_science_laboratory/surface/quat_v2'
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.ZAxis,
			periodInHours: 0.00833333333,
			relativeToTime: 375594733,
			coverage: [375594733, 397501866]
		}, {
			type: 'rotateByEntityOrientation', // Rotate the position for IAU_MARS dynamo.
			entityForOrientation: 'mars',
			rotatingOrientation: false,
			coverage: [397501373, Number.POSITIVE_INFINITY]
		}, {
			type: 'rotateByEntityOrientation', // Rotate the orientation for IAU_MARS dynamo.
			entityForOrientation: 'mars',
			rotatingPosition: false,
			coverage: [397502386.832, Number.POSITIVE_INFINITY]
		}, {
			type: 'custom',
			func: (entity) => {
				// Add a ground clamp when the rover is on the ground. The dymamo gets pretty close, but this is closer.
				const groundClamp = entity.addControllerByClass(Pioneer.GroundClampController);
				groundClamp.setDistanceFromGround(0.00098);
				groundClamp.setGroundComponentRef('mars', 'cmts');
				groundClamp.setUp(Pioneer.Vector3.ZAxisNeg);
				groundClamp.setCoverage(new Pioneer.Interval(397502386.832, Number.POSITIVE_INFINITY));
				return groundClamp;
			}
		}, {
			type: 'coverage', // Make it change to Curiosity when it lands.
			coverage: [397502386.832, Number.POSITIVE_INFINITY],
			enter: (entity) => {
				const div = entity.getComponentByClass(Pioneer.DivComponent);
				div.getDiv().innerHTML.replace('Mars Science Laboratory', 'Curiosity');
			},
			exit: (entity) => {
				const div = entity.getComponentByClass(Pioneer.DivComponent);
				div.getDiv().innerHTML.replace('Curiosity', 'Mars Science Laboratory');
			}
		}, { // Coverage for making trail relative to entity.
			type: 'coverage',
			coverage: [397501373, Number.POSITIVE_INFINITY],
			enter: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setRelativeToEntityOrientation(true);
				}
			},
			exit: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setRelativeToEntityOrientation(false);
				}
			}
		}, { // Change the model to the rover at the right time.
			type: 'coverage',
			coverage: [397502324, Number.POSITIVE_INFINITY],
			enter: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				if (model !== null) {
					model.setUrl('$STATIC_ASSETS_URL/models/sc_mars_science_laboratory/rover/curiosity_static.gltf');
					model.setRotation(new Pioneer.Quaternion(0.5, -0.5, 0.5, -0.5));
					model.setTranslation(new Pioneer.Vector3(0, 0, 0.001));
				}
			},
			exit: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				if (model !== null) {
					model.setUrl('$STATIC_ASSETS_URL/models/sc_mars_science_laboratory/cruise/msl_cruisestage.gltf');
					model.setRotation(new Pioneer.Quaternion(0.7071067811865478, -0.7071067811865472, 0, 0));
					model.setTranslation(new Pioneer.Vector3(0, 0, 0));
				}
			}
		}, {
			type: 'coverage', // Make the trail not fade when on the ground.
			coverage: [397502386.832, Number.POSITIVE_INFINITY],
			enter: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setIgnoreDistance(true);
				}

			},
			exit: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setIgnoreDistance(false);
				}
			}
		}, { // Remove the cruise pieces when starting the EDL.
			type: 'coverage',
			coverage: [397501758, 397502386.832],
			enter: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				if (model !== null) {
					model.setHiddenObject('solar_panels', true);
					model.setHiddenObject('frame_etc', true);
				}
			},
			exit: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				if (model !== null) {
					model.setHiddenObject('solar_panels', false);
					model.setHiddenObject('frame_etc', false);
				}
			}
		}]
	},
	sc_mars_science_laboratory_landing_site: {
		groups: ['mars', 'sc_mars_science_laboratory', 'sites'],
		occlusionRadius: 0.001515,
		extentsRadius: 0.004,
		systemRadius: 200,
		label: 'Curiosity Landing Site',
		parents: [
			[375594732.3829685, 'mars']
		],
		controllers: [{
			type: 'fixed',
			llaOnSpheroid: new Pioneer.LatLonAlt(-4.589466996319 / 180 * Math.PI, 137.441632996891 / 180 * Math.PI, -4.927711819685555),
			coverage: [375594732.3829685, Number.POSITIVE_INFINITY]
		}]
	},
	sc_mars_exploration_rover_1: {
		groups: ['mars', 'spacecraft', 'landers'],
		occlusionRadius: 0.0013,
		extentsRadius: 0.0026,
		label: 'Opportunity',
		parents: [
			[110911022.184, 'earth'],
			[111234172, 'sun'],
			[128262836, 'mars'],
			[128278419, 'sc_mars_exploration_rover_1_landing_site'],
			[581920316.8566707, '']
		],
		trail: {
			length: 6720.0,
			lengthCoverages: [
				[10000000, Number.NEGATIVE_INFINITY, 128262836],
				[6720.0, 128262836, 128278523],
				[9676800, 128278523 + 9676800, 581920316.856]
			],
			updatePointPositions: true
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_mars_exploration_rover/cruise/mpf_mera_merb_cruise.gltf',
			rotate: [
				{ x: 90 }
			],
			shadowEntities: ['mars']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_mars_exploration_rover_1/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_mars_exploration_rover_1/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_mars_exploration_rover_1/mars/pos'
		}, {
			type: 'dynamo',
			url: 'sc_mars_exploration_rover_1/edl/pos'
		}, {
			type: 'dynamo',
			url: 'sc_mars_exploration_rover_1/surface/lin'
		}, {
			type: 'custom',
			func: (entity) => {
				// Get it into the MER-1_TOPO frame.
				const rotate = entity.addControllerByClass(Pioneer.RotateController);
				const rot90 = new Pioneer.Quaternion();
				rot90.setFromAxes(Pioneer.Vector3.YAxis, Pioneer.Vector3.XAxisNeg, undefined);
				rotate.setRotation(rot90);
				rotate.setRotatingOrientation(false);
				return rotate;
			},
			coverage: [128278419, 581920316.856]
		}, {
			type: 'rotateByEntityOrientation',
			rotatingOrientation: false,
			coverage: [128278419, 581920316.856]
		}, {
			type: 'custom',
			func: (entity) => {
				// Add a ground clamp when the rover is on the ground. The dymamo gets pretty close, but this is closer.
				const groundClamp = entity.addControllerByClass(Pioneer.GroundClampController);
				groundClamp.setGroundComponentRef('mars', 'cmts');
				groundClamp.setUp(Pioneer.Vector3.ZAxisNeg);
				return groundClamp;
			},
			coverage: [128278523, 581920316.856]
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'align',
				target: 'mars',
				axis: Pioneer.Vector3.YAxis,
				targetAxis: Pioneer.Vector3.ZAxis
			},
			coverage: [Number.NEGATIVE_INFINITY, 128262836]
		}, {
			type: 'align',
			primary: {
				type: 'velocity',
				target: 'sc_mars_exploration_rover_1',
				axis: Pioneer.Vector3.ZAxisNeg
			},
			secondary: {
				type: 'align',
				target: 'mars',
				axis: Pioneer.Vector3.YAxis,
				targetAxis: Pioneer.Vector3.ZAxis
			},
			coverage: [128262836, 128278523.336]
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity,
			coverage: [128278523, 581920316.856]
		}, {
			type: 'rotateByEntityOrientation',
			rotatingPosition: false,
			coverage: [128278523, 581920316.856]
		}, {
			type: 'coverage',
			coverage: [128278419, 581920316.856],
			enter: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setRelativeToEntityOrientation(true);
					trail.setIgnoreDistance(true);
				}
			},
			exit: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setRelativeToEntityOrientation(false);
					trail.setIgnoreDistance(false);
				}
			}
		}, {
			type: 'coverage', // Make the first part of the rover surface trail start at landing.
			coverage: [128278523, 128278523 + 9676800],
			enter: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setStartTime(128278523);
					trail.setRelativeStartTime(false);
				}
			},
			exit: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setRelativeStartTime(true);
				}
			}
		}, {
			type: 'coverage',
			coverage: [128278523, 581920316.856],
			enter: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				if (model !== null) {
					model.setUrl('$STATIC_ASSETS_URL/models/sc_mars_exploration_rover/rover/mer_static.gltf');
				}
			},
			exit: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				if (model !== null) {
					model.setUrl('$STATIC_ASSETS_URL/models/sc_mars_exploration_rover/cruise/mpf_mera_merb_cruise.gltf');
				}
			}
		}]
	},
	sc_mars_exploration_rover_1_landing_site: {
		groups: ['mars', 'sc_mars_exploration_rover_1', 'sites'],
		radius: 0.001,
		systemRadius: 200,
		label: 'Opportunity Landing Site',
		parents: [
			[110911022.184, 'mars']
		],
		controllers: [{
			type: 'fixed',
			llaOnSpheroid: new Pioneer.LatLonAlt(-0.034003934569818886, -0.09644392740547784, 0),
			coverage: [110911022.184, Number.POSITIVE_INFINITY]
		}]
	},
	sc_mars_exploration_rover_2: {
		groups: ['mars', 'spacecraft', 'landers'],
		occlusionRadius: 0.0013,
		extentsRadius: 0.0026,
		label: 'Spirit',
		parents: [
			[108541883.184, 'earth'],
			[108887371, 'sun'],
			[126444477, 'mars'],
			[126462105, 'sc_mars_exploration_rover_2_landing_site'],
			[322567479.3896215, '']
		],
		trail: {
			length: 6720.0,
			lengthCoverages: [
				[10000000, Number.NEGATIVE_INFINITY, 126444477],
				[6720.0, 126444477, 126462396],
				[9676800, 126462396 + 9676800, 322567479.389620]
			],
			updatePointPositions: true
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_mars_exploration_rover/cruise/mpf_mera_merb_cruise.gltf',
			rotate: [
				{ x: 90 }
			],
			shadowEntities: ['mars']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_mars_exploration_rover_2/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_mars_exploration_rover_2/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_mars_exploration_rover_2/mars/pos'
		}, {
			type: 'dynamo',
			url: 'sc_mars_exploration_rover_2/edl/pos'
		}, {
			type: 'dynamo',
			url: 'sc_mars_exploration_rover_2/surface/lin'
		}, {
			type: 'custom',
			func: (entity) => {
				// Get it into the MER-2_TOPO frame.
				const rotate = entity.addControllerByClass(Pioneer.RotateController);
				const rot90 = new Pioneer.Quaternion();
				rot90.setFromAxes(Pioneer.Vector3.YAxis, Pioneer.Vector3.XAxisNeg, undefined);
				rotate.setRotation(rot90);
				rotate.setRotatingOrientation(false);
				return rotate;
			},
			coverage: [126462105, 322567479.389620]
		}, {
			type: 'rotateByEntityOrientation', // Get surface dynamo into J2000 frame.
			rotatingOrientation: false,
			coverage: [126462105, 322567479.389620]
		}, {
			type: 'custom',
			func: (entity) => {
				// Add a ground clamp when the rover is on the ground. The dymamo gets pretty close, but this is closer.
				const groundClamp = entity.addControllerByClass(Pioneer.GroundClampController);
				groundClamp.setGroundComponentRef('mars', 'cmts');
				groundClamp.setUp(Pioneer.Vector3.ZAxisNeg);
				return groundClamp;
			},
			coverage: [126462396, 322567479.389620]
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'align',
				target: 'mars',
				axis: Pioneer.Vector3.YAxis,
				targetAxis: Pioneer.Vector3.ZAxis
			},
			coverage: [Number.NEGATIVE_INFINITY, 126444477]
		}, {
			type: 'align',
			primary: {
				type: 'velocity',
				target: 'sc_mars_exploration_rover_2',
				axis: Pioneer.Vector3.ZAxisNeg
			},
			secondary: {
				type: 'align',
				target: 'mars',
				axis: Pioneer.Vector3.YAxis,
				targetAxis: Pioneer.Vector3.ZAxis
			},
			coverage: [126444477, 126462396]
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity,
			coverage: [126462396, 322567479.389620]
		}, {
			type: 'rotateByEntityOrientation',
			rotatingPosition: false,
			coverage: [126462396, 322567479.389620]
		}, {
			type: 'coverage',
			coverage: [126462105, 322567479.389620],
			enter: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setRelativeToEntityOrientation(true);
					trail.setIgnoreDistance(true);
				}
			},
			exit: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setRelativeToEntityOrientation(false);
					trail.setIgnoreDistance(false);
				}
			}
		}, {
			type: 'coverage', // Make the first part of the rover surface trail start at landing.
			coverage: [126462396, 126462396 + 9676800],
			enter: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setStartTime(126462396);
					trail.setRelativeStartTime(false);
				}
			},
			exit: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setRelativeStartTime(true);
				}
			}
		}, {
			type: 'coverage',
			coverage: [126462396, 322567479.389620],
			enter: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				if (model !== null) {
					model.setUrl('$STATIC_ASSETS_URL/models/sc_mars_exploration_rover/rover/mer_static.gltf');
				}
			},
			exit: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				if (model !== null) {
					model.setUrl('$STATIC_ASSETS_URL/models/sc_mars_exploration_rover/cruise/mpf_mera_merb_cruise.gltf');
				}
			}
		}]
	},
	sc_mars_exploration_rover_2_landing_site: {
		groups: ['mars', 'sc_mars_exploration_rover_2', 'sites'],
		radius: 0.001,
		systemRadius: 200,
		label: 'Spirit Landing Site',
		parents: [
			[108541883.184, 'mars']
		],
		controllers: [{
			type: 'fixed',
			llaOnSpheroid: new Pioneer.LatLonAlt(-0.25432749290990797, 3.062677242395019, -2.614394925059969),
			coverage: [108541883.184, Number.POSITIVE_INFINITY]
		}]
	},
	sc_insight: {
		groups: ['mars', 'spacecraft', 'landers'],
		radius: 0.00306,
		label: 'InSight',
		parents: [
			[578795968.9654216, 'earth'],
			[579182469.185, 'sun'],
			[596376069.183, 'mars']
		],
		trail: {
			length: 32137022.16,
			lengthCoverages: [
				[32137022.16, Number.NEGATIVE_INFINITY, 596376069.183],
				[14400, 596376069.183, 596533471.284 - 3600],
				[3600, 596533471.284 - 3600, 596533602],
				[0, 596533602, Number.POSITIVE_INFINITY]
			]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_insight/lander/insight.gltf',
			rotate: [
				{ y: 90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_insight/earth/pos'
		}, {
			type: 'dynamo',
			url: 'sc_insight/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_insight/mars/orb'
		}, {
			type: 'dynamo',
			url: 'sc_insight_edl/mars/pos'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'mars',
				axis: Pioneer.Vector3.ZAxisNeg
			},
			secondary: {
				type: 'align',
				target: 'mars',
				axis: Pioneer.Vector3.YAxis,
				targetAxis: Pioneer.Vector3.ZAxis
			},
			coverage: [578795968.9654216, 596533602]
		}, {
			type: 'dynamo',
			url: 'sc_insight/ori'
		}, {
			type: 'dynamo',
			url: 'sc_insight_edl/ori'
		}, {
			type: 'fixed',
			llaOnSpheroid: new Pioneer.LatLonAlt(0.07881611091588075, 2.3734709256393973, -2.996371903616364),
			llaOnSpheroidEntity: 'mars',
			coverage: [596533602, Number.POSITIVE_INFINITY]
		}, {
			type: 'coverage',
			coverage: [596533471.284 - 3600, 596533602],
			enter: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setRelativeToEntityOrientation(true);
				}
			},
			exit: (entity) => {
				const trail = entity.getComponentByClass(Pioneer.TrailComponent);
				if (trail !== null) {
					trail.setRelativeToEntityOrientation(false);
				}
			}
		}, {
			type: 'coverage',
			coverage: [578795968.9654216, 596533602],
			enter: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				if (model !== null) {
					model.setUrl('$STATIC_ASSETS_URL/models/sc_insight/cruise/model.gltf');
					model.setRotation(new Pioneer.Quaternion(Math.sqrt(0.5), 0, Math.sqrt(0.5), 0));
					model.setTranslation(Pioneer.Vector3.Zero);
				}
			}
		}, {
			type: 'coverage',
			coverage: [596533602, Number.POSITIVE_INFINITY],
			enter: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				if (model !== null) {
					model.setUrl('$STATIC_ASSETS_URL/models/sc_insight/lander/insight.gltf');
					model.setRotation(new Pioneer.Quaternion(Math.sqrt(0.5), Math.sqrt(0.5), 0, 0));
					model.setTranslation(new Pioneer.Vector3(0, 0, 0.00085));
				}
			}
		}]
	},
	sc_insight_landing_site: {
		groups: ['mars', 'sc_insight', 'sites'],
		radius: 0.001,
		systemRadius: 200,
		label: 'InSight Landing Site',
		parents: [
			[578795968.9654216, 'mars'],
			[596533602, '']
		],
		controllers: [{
			type: 'fixed',
			llaOnSpheroid: new Pioneer.LatLonAlt(0.07881611091588075, 2.3734709256393973, -2.996371903616364),
			coverage: [578795968.9654216, 596533602]
		}]
	},
	sc_marco_a: {
		groups: ['mars', 'spacecraft'],
		radius: 0.00044375,
		label: 'MarCO A',
		parents: [
			[578796051, 'earth'],
			[579182469.185, 'sun'],
			[596376069.183, 'mars'],
			[596552080, 'sun'],
			[631152000, '']
		],
		trail: {
			length: 6720.0,
			lengthCoverages: [
				[10000000, Number.NEGATIVE_INFINITY, 579182469.185],
				[6720.0, 579182469.185, 596552080],
				[10000000, 596552080, Number.POSITIVE_INFINITY]
			]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_marco/model.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_marco_a/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_marco_a/sun/1/orb'
		}, {
			type: 'dynamo',
			url: 'sc_marco_a/mars/orb'
		}, {
			type: 'dynamo',
			url: 'sc_marco_a/sun/2/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			}
		}]
	},
	sc_marco_b: {
		groups: ['mars', 'spacecraft'],
		radius: 0.00044375,
		label: 'MarCO B',
		parents: [
			[578796051, 'earth'],
			[579182469.185, 'sun'],
			[596376069.183, 'mars'],
			[596552080, 'sun'],
			[631152000, '']
		],
		trail: {
			length: 6720.0,
			lengthCoverages: [
				[10000000, Number.NEGATIVE_INFINITY, 579182469.185],
				[6720.0, 579182469.185, 596552080],
				[10000000, 596552080, Number.POSITIVE_INFINITY]
			]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_marco/model.gltf'
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_marco_b/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_marco_b/sun/1/orb'
		}, {
			type: 'dynamo',
			url: 'sc_marco_b/mars/orb'
		}, {
			type: 'dynamo',
			url: 'sc_marco_b/sun/2/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			}
		}]
	},
	sc_mars_odyssey: {
		groups: ['mars', 'spacecraft'],
		occlusionRadius: 0.0013,
		extentsRadius: 0.004,
		label: 'Mars Odyssey',
		parents: [
			[39932700, 'earth'],
			[40233664, 'sun'],
			[57128464, 'mars']
		],
		trail: {
			length: 6727.0,
			lengthCoverages: [
				[10000000, Number.NEGATIVE_INFINITY, 57128464],
				[6727.0, 57128464, Number.POSITIVE_INFINITY]
			]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_mars_odyssey/mars_odyssey.gltf',
			environmentMap: {
				cubemap: '$STATIC_ASSETS_URL/env_maps/park_gray/$FACE.jpg'
			},
			shadowEntities: ['mars', 'deimos', 'phobos']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_mars_odyssey/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_mars_odyssey/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_mars_odyssey/mars/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'mars',
				axis: new Pioneer.Vector3(-0.95630475596, 0.29237170472, 0)
			},
			secondary: {
				type: 'velocity',
				target: 'sc_mars_odyssey',
				axis: new Pioneer.Vector3(0.29237170472, 0.95630475596, 0)
			}
		}, {
			type: 'dynamo',
			url: 'sc_mars_odyssey/ori'
		}]
	},
	sc_mars_reconnaissance_orbiter: {
		groups: ['mars', 'spacecraft'],
		radius: 0.0068,
		label: 'Mars Reconnaissance Orbiter',
		parents: [
			[177122516, 'earth'],
			[177429664, 'sun'],
			[195285665, 'mars']
		],
		trail: {
			length: 6720.0,
			lengthCoverages: [
				[10000000, Number.NEGATIVE_INFINITY, 195285665],
				[6720.0, 195285665, Number.POSITIVE_INFINITY]
			]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_mars_reconnaissance_orbiter/MRO.gltf',
			environmentMap: {
				cubemap: '$STATIC_ASSETS_URL/env_maps/park_gray/$FACE.jpg'
			},
			shadowEntities: ['mars', 'deimos', 'phobos']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_mars_reconnaissance_orbiter/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_mars_reconnaissance_orbiter/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_mars_reconnaissance_orbiter/mars/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'mars',
				axis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'velocity',
				target: 'sc_mars_reconnaissance_orbiter',
				axis: Pioneer.Vector3.XAxis
			}
		}, {
			type: 'dynamo',
			url: 'sc_mars_reconnaissance_orbiter/ori'
		}]
	},
	sc_maven: {
		groups: ['mars', 'spacecraft'],
		radius: 0.0057,
		label: 'MAVEN',
		parents: [
			[438074509.3428109, 'earth'],
			[438296467, 'sun'],
			[464590867, 'mars']
		],
		trail: {
			length: 16139.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_maven/Maven.gltf',
			rotate: [
				{ x: 90 },
				{ z: 90 }
			],
			environmentMap: {
				cubemap: '$STATIC_ASSETS_URL/env_maps/park_gray/$FACE.jpg'
			},
			shadowEntities: ['mars', 'deimos', 'phobos']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_maven/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_maven/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_maven/mars/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.ZAxis
			}
		}, {
			type: 'dynamo',
			url: 'sc_maven/ori'
		}]
	},
	sc_mars_express: {
		groups: ['mars', 'spacecraft'],
		radius: 0.006,
		label: 'Mars Express',
		parents: [
			[107853140.59600002, 'earth'],
			[108232264, 'sun'],
			[125539264, 'mars']
		],
		trail: {
			length: 25000.0,
			lengthCoverages: [
				[10000000, Number.NEGATIVE_INFINITY, 126749131],
				[12720.0, 126749131, Number.POSITIVE_INFINITY]
			]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_mars_express/mars_express.gltf',
			rotate: [
				{ x: 90 },
				{ z: -180 }
			],
			environmentMap: {
				cubemap: '$STATIC_ASSETS_URL/env_maps/park_gray/$FACE.jpg'
			},
			shadowEntities: ['mars', 'deimos', 'phobos']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_mars_express/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_mars_express/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_mars_express/mars/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'mars',
				axis: Pioneer.Vector3.XAxisNeg
			}
		}, {
			type: 'dynamo',
			url: 'sc_mars_express/ori'
		}]
	},
	sc_phoenix: {
		groups: ['mars', 'spacecraft'],
		radius: 0.0027,
		label: 'Phoenix',
		parents: [
			[239496427, 'earth'],
			[239618121, 'sun'],
			[265008306, 'mars'],
			[265030318, 'sc_phoenix_landing_site'],
			[278942465, '']
		],
		trail: {
			length: 45411186.0,
			lengthCoverages: [
				[45411186, Number.NEGATIVE_INFINITY, 265008306],
				[50000, 265008306, 265030318],
				[500, 265030318, 265030769],
				[0, 265030769, 278942465]
			]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_phoenix/cruise/phoenix_cruise.gltf',
			rotate: [
				{ z: 90 }
			]
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_phoenix/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_phoenix/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_phoenix/mars/orb'
		}, {
			type: 'dynamo',
			url: 'sc_phoenix/phx_topo/pos'
		}, {
			type: 'dynamo',
			url: 'sc_phoenix/ori'
		}, {
			type: 'fixed',
			position: new Pioneer.Vector3(0.027059368126568832, 0.013878235928918032, -0.04367634407940447),
			coverage: [265030769, 278942465]
		}, {
			type: 'rotateByEntityOrientation',
			rotatingOrientation: false,
			coverage: [265030769, 278942465]
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.ZAxisNeg
			}
		}, {
			type: 'coverage',
			coverage: [265030324 - 7 * 60, Number.POSITIVE_INFINITY],
			enter: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				model.setUrl('$STATIC_ASSETS_URL/models/sc_phoenix/edl/phoenix_edl.gltf');
			},
			exit: (entity) => {
				const model = entity.getComponentByClass(Pioneer.ModelComponent);
				model.setUrl('$STATIC_ASSETS_URL/models/sc_phoenix/cruise/phoenix_cruise.gltf');
			}
		}],
		postCreateFunction: (entity) => {
			entity.addParentChangedCallback((entity, _, newParent) => {
				if (newParent !== null) {
					const parentName = newParent.getName();
					const trail = entity.getComponentByClass(Pioneer.TrailComponent);
					if (parentName === 'sc_phoenix_landing_site') {
						trail.setRelativeToEntity('mars');
						trail.setRelativeToEntityOrientation(true);
					}
					else {
						trail.setRelativeToEntity('');
						trail.setRelativeToEntityOrientation(false);
					}
				}
			});
		}
	},
	sc_phoenix_landing_site: {
		groups: ['mars', 'sc_phoenix', 'sites'],
		radius: 0.001,
		systemRadius: 200,
		label: 'Phoenix Landing Site',
		parents: [
			[239496427, 'mars']
		],
		controllers: [{
			type: 'fixed',
			llaOnSpheroid: new Pioneer.LatLonAlt(1.1906355815662266, -2.1947740491302206, -2.5912352775421823),
			coverage: [239496427, Number.POSITIVE_INFINITY]
		}]
	},
	sc_trace_gas_orbiter: {
		groups: ['mars', 'spacecraft'],
		radius: 0.00875915,
		label: 'Trace Gas Orbiter',
		parents: [
			[511257268, 'earth'],
			[511941668, 'sun'],
			[530107268, 'mars']
		],
		trail: {
			length: 6720.0,
			lengthCoverages: [
				[10000000, Number.NEGATIVE_INFINITY, 530160041],
				[6720.0, 530160041, Number.POSITIVE_INFINITY]
			]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_trace_gas_orbiter/TGO.gltf',
			rotate: [
				{ x: -90 },
				{ y: -90 }
			],
			environmentMap: {
				cubemap: '$STATIC_ASSETS_URL/env_maps/park_gray/$FACE.jpg'
			},
			shadowEntities: ['mars', 'deimos', 'phobos']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_trace_gas_orbiter/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_trace_gas_orbiter/sun/orb'
		}, {
			type: 'dynamo',
			url: 'sc_trace_gas_orbiter/mars/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'mars',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.XAxisNeg
			}
		}, {
			type: 'dynamo',
			url: 'sc_trace_gas_orbiter/ori'
		}],
		postCreateFunction: (entity) => {
			// Align the right solar panel to the sun.
			let align = entity.addControllerByClass(Pioneer.AlignController);
			align.setJoint('right_array_1');
			align.setPrimaryAxis(Pioneer.Vector3.XAxis);
			align.setSecondaryAxis(Pioneer.Vector3.YAxis);
			align.setSecondaryAlignType('point');
			align.setSecondaryTargetEntity('sun');
			// Align the left solar panel to the sun.
			align = entity.addControllerByClass(Pioneer.AlignController);
			align.setJoint('left_array_1');
			align.setPrimaryAxis(Pioneer.Vector3.XAxis);
			align.setSecondaryAxis(Pioneer.Vector3.YAxis);
			align.setSecondaryAlignType('point');
			align.setSecondaryTargetEntity('sun');
		}
	},
	sc_mars_orbiter_mission: {
		groups: ['mars', 'spacecraft'],
		radius: 0.00275,
		label: 'Mars Orbiter Mission',
		parents: [
			[623211069.1823474, 'mars']
		],
		trail: {
			length: 234146.0
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_mars_orbiter_mission/mars',
			dataType: 'pos'
		}]
	},
	sc_mars_global_surveyor: {
		groups: ['mars', 'spacecraft'],
		occlusionRadius: 0.003,
		extentsRadius: 0.005,
		label: 'Mars Global Surveyor',
		parents: [
			[-72699545, 'mars'],
			[215697664.184, '']
		],
		trail: {
			length: 7068.67
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_mars_global_surveyor/mars_global_surveyor.gltf',
			shadowEntities: ['mars']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_mars_global_surveyor_mission',
			dataType: 'pos'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'mars',
				axis: Pioneer.Vector3.XAxisNeg
			}
		}]
	},
	sc_mars_climate_orbiter: {
		groups: ['mars', 'spacecraft'],
		radius: 0.0011,
		label: 'Mars Climate Orbiter',
		parents: [
			[-33318000, 'sun'],
			[-8650375.816, '']
		],
		trail: {
			length: 24587405.0 * 3.0
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_mars_climate_orbiter',
			dataType: 'pos'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	sc_mars_pathfinder: {
		groups: ['mars', 'spacecraft'],
		radius: 0.00033,
		label: 'Mars Pathfinder',
		parents: [
			[-91704541, 'sun'],
			[-78692880, '']
		],
		trail: {
			length: 12874372.0 * 4.0
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_mars_pathfinder',
			dataType: 'pos'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	sc_mars_pathfinder_landing_site: {
		groups: ['mars', 'sc_mars_pathfinder', 'sites'],
		radius: 0.001,
		systemRadius: 200,
		label: 'Mars Pathfinder Landing Site',
		parents: [
			[-97045250.817, 'mars']
		],
		controllers: [{
			type: 'fixed',
			llaOnSpheroid: new Pioneer.LatLonAlt(19.13 * Math.PI / 180, -33.22 * Math.PI / 180, 0),
			coverage: [-97045250.817, Number.POSITIVE_INFINITY]
		}]
	},
	sc_mars_polar_lander: {
		groups: ['mars', 'spacecraft'],
		radius: 0.0018,
		label: 'Mars Polar Lander',
		parents: [
			[-31298400, 'sun'],
			[-2476735.816, '']
		],
		trail: {
			length: 41109006.0
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_mars_polar_lander',
			dataType: 'pos'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	sc_viking_1_orbiter: {
		groups: ['mars', 'spacecraft'],
		radius: 0.0047,
		label: 'Viking 1 Orbiter',
		parents: [
			[-742490410, 'mars'],
			[-663249600, '']
		],
		trail: {
			length: 88649.0
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_viking_1_orbiter',
			dataType: 'pos'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	sc_viking_1_lander_landing_site: {
		groups: ['mars', 'sc_viking_1_lander', 'sites'],
		radius: 0.001,
		systemRadius: 200,
		label: 'Viking 1 Lander Landing Site',
		parents: [
			[-768926233.817, 'mars']
		],
		controllers: [{
			type: 'fixed',
			llaOnSpheroid: new Pioneer.LatLonAlt(22.27 * Math.PI / 180, 312.05 * Math.PI / 180, 0),
			coverage: [-768926233.817, Number.POSITIVE_INFINITY]
		}]
	},
	sc_viking_2_orbiter: {
		groups: ['mars', 'spacecraft'],
		radius: 0.0047,
		label: 'Viking 2 Orbiter',
		parents: [
			[-738460186, 'mars'],
			[-676517400, '']
		],
		trail: {
			length: 98694.0
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_viking_2_orbiter',
			dataType: 'pos'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	sc_viking_2_lander_landing_site: {
		groups: ['mars', 'sc_viking_2_lander', 'sites'],
		radius: 0.001,
		systemRadius: 200,
		label: 'Viking 2 Lander Landing Site',
		parents: [
			[-767208013.818, 'mars']
		],
		controllers: [{
			type: 'fixed',
			llaOnSpheroid: new Pioneer.LatLonAlt(47.64 * Math.PI / 180, -225.71 * Math.PI / 180, 0),
			coverage: [-767208013.818, Number.POSITIVE_INFINITY]
		}]
	}
});
