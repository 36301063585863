/** @module pioneer */
import {
	BaseController,
	Entity,
	Interval,
	LatLonAlt,
	MathUtils,
	SpheroidComponent,
	Vector3
} from '../../internal';

/** A zoom camera controller.
 */
export class ZoomController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		// The sensitivity for zooming.
		this._zoomSensitivity = 0.05;

		/**
		 * The smoothness of the zooming. Zero means no smoothness.
		 * @type {number}
		 * @private
		 */
		this._zoomSmoothness = 0.8;

		/**
		 * The current value applied every frame to the movement.
		 * @type {number}
		 * @private
		 */
		this._zoomSmoothedValue = 1.0;

		/**
		 * The distance that it will be clamped to.
		 * @type {Interval}
		 * @private
		 */
		this._distanceClamp = new Interval(0.001, Number.POSITIVE_INFINITY);
		this._distanceClamp.freeze();

		/**
		 * If true, it will use the distance to the radius of the spheroid instead of the entity position distance.
		 * @type {boolean}
		 * @private
		 */
		this._useSpheroidRadiusForDistance = false;

		// Let the base controller know that this changes the position.
		this.addModifiedState('position');
	}

	/**
	 * Gets the zoom sensitivity. Defaults to 0.01.
	 * @returns {number}
	 */
	getZoomSensitivity() {
		return this._zoomSensitivity;
	}

	/**
	 * Sets the zoom sensitivity.
	 * @param {number} zoomSensitivity
	 */
	setZoomSensitivity(zoomSensitivity) {
		this._zoomSensitivity = zoomSensitivity;
	}

	/**
	 * Gets the zoom smoothness. Defaults to 0.8.
	 * @returns {number}
	 */
	getZoomSmoothness() {
		return this._zoomSmoothness;
	}

	/**
	 * Sets the zoom smoothness, between 0 and 1.
	 * @param {number} zoomSmoothness
	 */
	setZoomSmoothness(zoomSmoothness) {
		this._zoomSmoothness = zoomSmoothness;
	}

	/**
	 * Gets the interval to which it will be clamped (frozen).
	 * @returns {Interval}
	 */
	getDistanceClamp() {
		return this._distanceClamp;
	}

	/**
	 * Sets the distance that it will be clamped to.
	 * @param {Interval} distanceClamp - the value to set
	 */
	setDistanceClamp(distanceClamp) {
		this._distanceClamp.thaw();
		this._distanceClamp.copy(distanceClamp);
		this._distanceClamp.freeze();
	}

	/**
	 * Returns true if it will use the distance to the radius of the spheroid instead of the entity position distance.
	 * @returns {boolean}
	 */
	getUseSpheroidRadiusForDistance() {
		return this._useSpheroidRadiusForDistance;
	}

	/**
	 * Sets if it will use the distance to the radius of the spheroid instead of the entity position distance.
	 * @param {boolean} enabled
	 */
	setUseSpheroidRadiusForDistance(enabled) {
		this._useSpheroidRadiusForDistance = enabled;
	}

	/**
	 * Takes input and updates the target distance. Then updates the entity's position.
	 * @override
	 * @internal
	 */
	__update() {
		const input = this.getEntity().getScene().getEngine().getInput();

		// Update the target distance.
		let zoomChange = 1.0;
		const viewport = input.getActiveViewport();
		if (viewport !== null) {
			const camera = viewport.getCamera();
			if (camera !== null && camera.getEntity() === this.getEntity()) {
				let zoomMultiplier = 1;
				if (input.isKeyPressed('x')) {
					zoomMultiplier = 0.05;
				}
				if (input.isShiftPressed()) {
					zoomMultiplier = 5;
				}

				// Do zoom/scroll movement.
				const zoomOffset = input.getZoomedOffset();
				if (zoomOffset !== 0) {
					zoomChange *= Math.pow(2, zoomOffset * this._zoomSensitivity * zoomMultiplier);
				}

				// Do key movement.
				if (input.isKeyPressed('w')) {
					zoomChange /= Math.pow(2, this._zoomSensitivity * zoomMultiplier);
				}
				if (input.isKeyPressed('s')) {
					zoomChange *= Math.pow(2, this._zoomSensitivity * zoomMultiplier);
				}
			}
		}

		// Apply smoothing.
		this._zoomSmoothedValue = MathUtils.clamp(MathUtils.lerp(zoomChange, this._zoomSmoothedValue, this._zoomSmoothness), 0.8, 1.25);
		if (Math.abs(1.0 - this._zoomSmoothedValue) < 0.0000001) {
			this._zoomSmoothedValue = 1.0;
		}

		// Get the current distance from the position.
		let currentDistance = 1;
		const lla = LatLonAlt.pool.get();
		if (this._useSpheroidRadiusForDistance && this.getEntity().getParent() !== null) {
			const spheroid = /** @type {SpheroidComponent} */(this.getEntity().getParent().getComponentByType('spheroid'));
			if (spheroid !== null) {
				const positionOriented = Vector3.pool.get();
				positionOriented.rotateInverse(this.getEntity().getParent().getOrientation(), this.getEntity().getPosition());
				spheroid.llaFromXYZ(lla, positionOriented);
				currentDistance = lla.alt;
				Vector3.pool.release(positionOriented);
			}
			else {
				currentDistance = this.getEntity().getPosition().magnitude() - this.getEntity().getParent().getOcclusionRadius();
			}
		}
		else {
			currentDistance = this.getEntity().getPosition().magnitude();
		}
		if (Number.isNaN(currentDistance)) {
			currentDistance = 1;
		}

		// Update the current distance and apply clamping.
		currentDistance *= this._zoomSmoothedValue;
		if (currentDistance < this._distanceClamp.min) {
			currentDistance = this._distanceClamp.min;
			this._zoomSmoothedValue = 1.0;
		}
		if (currentDistance > this._distanceClamp.max) {
			currentDistance = this._distanceClamp.max;
			this._zoomSmoothedValue = 1.0;
		}

		// Set the position from the current distance.
		const newPosition = Vector3.pool.get();
		let newMagnitude = currentDistance;
		if (this._useSpheroidRadiusForDistance && this.getEntity().getParent() !== null) {
			const spheroid = /** @type {SpheroidComponent} */(this.getEntity().getParent().getComponentByType('spheroid'));
			if (spheroid !== null) {
				lla.alt = currentDistance;
				spheroid.xyzFromLLA(newPosition, lla);
				newMagnitude = newPosition.magnitude();
			}
			else {
				newMagnitude = currentDistance + this.getEntity().getParent().getOcclusionRadius();
			}
		}
		newPosition.normalize(this.getEntity().getPosition());
		newPosition.mult(newPosition, newMagnitude);
		this.getEntity().setPosition(newPosition);
		Vector3.pool.release(newPosition);
		LatLonAlt.pool.release(lla);
	}
}
