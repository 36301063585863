import { useParams, Navigate, Outlet } from 'react-router-dom';

import VITALS_DATA from '../data/vitals_data';

/**
 * DefaultVitalSign is responsible for redirecting to the default dataset when a vital sign is selected.
 * @returns {React.Component}
 */
const DefaultVitalSign = () => {
	const { vitalSignParam } = useParams();
	const vitalData = VITALS_DATA.find(({ value }) => value === vitalSignParam);
	const { urlParam: datasetParam } = vitalData?.datasetGroups[0]?.datasets?.[0] || {};

	return datasetParam && <Navigate replace to={datasetParam} />;
};


const VitalSign = () => <Outlet />;

export default VitalSign;

export { DefaultVitalSign };
