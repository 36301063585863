/* eslint-disable camelcase */
import VIDEOS_DATA, { TYPE_TITLES } from '../data/videos_data';
import * as Pioneer from 'pioneer';
import { SceneHelpers } from 'pioneer-scripts';
import ar from '../languages/ar';
import { Config } from '../config/config';


import { videoStore, eventsStore, poiStore, gmapStore } from './globalState';
import { hideLoading, showLoading } from '../components/loading';
import globalRefs from './globalRefs';
import {
	VITAL_CATEGORIES
} from '../config/constants';
/**
 * A manager to control the loading and playback of videos.
 *
 * This manager should NOT:
 *  - control the state of the videos, eg. playing / paused. That should be done in the react component.
 *  - store any element references.
 *
 * There is currently one exception to the above rules. In the case of a global and geolocated videos, we need to use and
 * pass a video HTML element directly to the threejs texture. Controlling this in here rather than react is
 * currently the best solution although it probably should be revisited in the future.

 * This manager SHOULD:
 * - be a bridge between the React component, and the pioneer/scene/router methods that need to be called on load / unload.
 */
class VideoManager {
	/**
	 * Constructor
	 * @param {object} app A reference to app.js
	 */
	constructor(pioneer) {
		// Set pioneer and scene.
		this._pioneer = pioneer;
		this._scene = pioneer.get('main');

		this._videoTexture = null;

		this.toggleVideoPlayPause = this.toggleVideoPlayPause.bind(this);
		this.getVideo = this.getVideo.bind(this);
		this.getVideoDropdownEntries = this.getVideoDropdownEntries.bind(this);
	}

	init() {
		/**
		 * Subscribe to isVideoLoading state.
		 */
		videoStore.subscribeTo('isVideoLoading', isVideoLoading => {
			if (isVideoLoading === true) {
				showLoading('video');
			} else if (isVideoLoading === false) {
				// Hide loading
				hideLoading('video');
			}
		});
	}

	/**
	 * Get list of related videos
	 * @param {string} query
	 * @returns {Array}
	 */
	getRelatedVideos(query) {
		return VIDEOS_DATA.filter(({ related }) => related === query);
	}


	/**
	 * Returns an array of objects to add to a dropdown box in mobile details overlay and dataset tools dropdown
	 * @param {string} vitalValue
	 * @returns {Array}
	 */
	getVideoDropdownEntries(vitalValue) {
		const relatedVideos = this.getRelatedVideos(vitalValue);

		return relatedVideos.map(({ title, type, urlParam }) => {
			const { dropdown } = TYPE_TITLES[type] || {};
			const dropdownTitle = dropdown ? `${title} (${dropdown})` : title;

			return {
				id: urlParam,
				menuTitle: dropdownTitle,
				category: type,
				urlParam,
				isVideo: true
			};
		});
	}

	/**
 	* Get the video from the ID.
 	* @param {string} videoIdOrTitle
 	* @returns
 	*/
	getVideo(videoIdOrTitle) {
		if (videoIdOrTitle === null) {
			return null;
		}

		const video = VIDEOS_DATA.find(({ urlParam, title }) => urlParam === videoIdOrTitle || title === videoIdOrTitle);
		return video ?? null;
	}

	/**
	 * Gets the left panel content for a video and returns an object with the content.
	 * @param {object} video
	 * @returns {object}
	 */
	getVideoLeftPanelContent(video) {
		if (video === null) {
			return null;
		}
		const data = {
			category: VITAL_CATEGORIES[1][1],
			title: video.title,
			subtitle: video.dateRange,
			descriptions: [{ title: ar.about_this_video, text: video.description, subtitle: video.dateRange }]
		};
		return data;
	}

	/**
	 * Get the video data from the VIDEOS_DATA and call the appropriate load function.
	 * @param {object} videoData
	 */
	async loadVideo(videoData) {
		const { type } = videoData;

		// Set the global state.
		videoStore.setGlobalState({ isVideoLoading: true });
    console.log("loadVideo", type)
		// Check if we need to load a global video.
		if (type === 'global') {
			await this.loadGlobalVideo(videoData);
		} else if (type === 'geoLocated') {
			await this.loadGeolocatedVideo(videoData);
		}

		videoStore.setGlobalState({ isVideoLoading: false });
	}

	/**
	 * Unloads the video.
	 */
	unloadVideo() {
		videoStore.setGlobalState({
			currentVideo: null,
			isVideoTexture: null,
			isVideoPlaying: null
		});
		// Disable event markers
    //TODO merge questions this is removed on theirs
		// eventsStore.setGlobalState({ showOnGlobe: false, showLatest: false });
		// poiStore.setGlobalState({ showOnGlobePoI: false, showLatestPoI: false });
		// gmapStore.setGlobalState({ showOnGlobeGmap: false, showLatestGmap: false });
	}

	/**
	 * Loads a video as texture on the earth.
	 *
	 * Future work: ***
	 * 1) Be able to pass autoplay and loop into the setTexture
	 * @param {object} currentVideo
	 * @returns {Promise}
	 */
	loadGlobalVideo({ src, offset }) {
		const { getManager } = globalRefs;
		const earth = this._scene.getEntity('earth');
		const spheroidLOD = earth.getComponentByType('spheroidLOD');

		// Apply bounds offsets if they exist.
		const { lat = 0, lon = 0 } = offset ?? {};
		const southRadians = -Math.PI * 0.5 + lat;
		const westRadians = -Math.PI + lon;
		const northRadians = Math.PI * 0.5 + lat;
		const eastRadians = Math.PI + lon;
		const lowerLeft = new Pioneer.LatLonAlt(southRadians, westRadians, 0);
		const upperRight = new Pioneer.LatLonAlt(northRadians, eastRadians, 0);

		spheroidLOD.setBounds(lowerLeft, upperRight);

		// Disable all features.
		const { earthSpheroidFeatures } = getManager('content');
		earthSpheroidFeatures.forEach(feature => {
			spheroidLOD.setFeature(feature, false);
		});

		// The earth spheroid mapping is always cube, except here.
		spheroidLOD.setMapping('cylinder');
    const full_url = src.indexOf("/rails/active_storage") == 0 ? `${Config.baseCe2s2Url}${src}` : src
		spheroidLOD.setTexture('color', full_url, [512]);
		spheroidLOD.setEnabled(true);

		return SceneHelpers.waitTillEntitiesInPlace(this._scene, ['earth'])
			.then(() => this._pioneer.waitUntilNextFrame())
			.then(() => spheroidLOD.getLoadedPromise())
			.then(() => {
				// Set the video texture.
				this._videoTexture = spheroidLOD.getThreeJsMaterials()[0]?.uniforms.colorTexture.value.image;
				// Make sure video is looping.
				if (this._videoTexture) {
					this._videoTexture.loop = true;
				}

				// Play video.
				this.playVideo();
			});
	}

	/**
	 * Loads a video as texture on a data patch.
	 * @param {object} videoData
	 */
	loadGeolocatedVideo(videoData) {
    console.log("loadGeolocatedVideo ", videoData)
		const { boundaries: { north, south, east, west } = {}, src } = videoData;

		if (north === null || south === null || east === null || west === null) {
			console.error('Geolocated video did not contian location data!');
			return;
		}

		const lowerLeft = new Pioneer.LatLonAlt(Pioneer.MathUtils.degToRad(parseFloat(north)), Pioneer.MathUtils.degToRad(parseFloat(east)), 0, true);
		const upperRight = new Pioneer.LatLonAlt(Pioneer.MathUtils.degToRad(parseFloat(south)), Pioneer.MathUtils.degToRad(parseFloat(west)), 0, true);

		const earth = this._scene.getEntity('earth');
		const patchComponent = earth.getComponent('patch');

		patchComponent.setVisible(false);

		// Set patch component location and texture.
		patchComponent.setMapping('cylinder');
    const full_url = src.indexOf("/rails/active_storage") == 0 ? `${Config.baseCe2s2Url}${src}` : src

		patchComponent.setTexture('color', full_url, [512]);
		patchComponent.setBounds(lowerLeft, upperRight);
		patchComponent.setEnabled(true);

		return SceneHelpers.waitTillEntitiesInPlace(this._scene, ['earth'])
			.then(() => this._pioneer.waitUntilNextFrame())
			.then(() => patchComponent.getLoadedPromise())
			.then(() => {
				// Show the patch component.
				patchComponent.setVisible(true);

				// Set the video texture.
				this._videoTexture = patchComponent.getThreeJsMaterials()[0]?.uniforms.colorTexture.value.image;

				// Make sure video is looping.
				if (this._videoTexture) {
					this._videoTexture.loop = true;
				}

				// Play video.
				this.playVideo();
			});
	}

	/**
	 * Toggles the play/pause state of a global or geoLocated video.
	 * Modal videos use the HTML video element controls.
	 */
	toggleVideoPlayPause() {
		const { isVideoPlaying } = videoStore.stateSnapshot;
		const { currentTime } = this._videoTexture ?? {};
		// Check to see if the video is loaded into the texture.
		if (currentTime !== 0 && !currentTime) {
			return;
		}

		if (isVideoPlaying) {
			this.pauseVideo();
		} else {
			this.playVideo();
		}
	}

	playVideo(video = this._videoTexture) {
		if (video) {
			const onPlay = () => {
				videoStore.setGlobalState({ isVideoPlaying: true });
				video.removeEventListener('play', onPlay);
			};

			video.addEventListener('play', onPlay);

			video.play?.();
		}
	}

	pauseVideo(video = this._videoTexture) {
		if (video) {
			const onPause = () => {
				videoStore.setGlobalState({ isVideoPlaying: false });
				video.removeEventListener('pause', onPause);
			};

			video.addEventListener('pause', onPause);

			video.pause?.();
		}
	}
}

export default VideoManager;
