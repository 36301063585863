/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';
import { Capabilities, TileMatrixSet, Layer } from './wmts';

/**
 * @callback TransitionsCompleteCallback
 * @returns {void}
 */

/**
 * The WMTS component.
 * */
export class WMTSComponent extends Pioneer.BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Pioneer.Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The WMTS Capabilities
		 * @type {Capabilities}
		 * @private
		 */
		this._capabilities = null;

		/**
		 * The WMTS end point.
		 * @type {string}
		 * @private
		 */
		this._endPoint = '';

		/**
		 * The WMTS layer identifier
		 * @type {string}
		 * @private
		 */
		this._layerIdentifier = '';

		/**
		 * The WMTS tile matrix set identifier
		 * @type {string}
		 * @private
		 */
		this._tileMatrixSetIdentifier = '';

		/**
		 * The style identifier.
		 * @type {string}
		 * @private
		 */
		this._style = '';

		/**
		 * Dimension values used to get the right tile.
		 * @type {Map<string, string>}
		 * @private
		 */
		this._dimensionValues = new Map();

		/**
		 * The minimum level to which the tiles must split.
		 * @type {number}
		 * @private
		 */
		this._minLevel = Number.NEGATIVE_INFINITY;

		/**
		 * The maximum level to which the tiles can split.
		 * @type {number}
		 * @private
		 */
		this._maxLevel = Number.POSITIVE_INFINITY;

		/**
		 * A factor that determines when to split the tiles. Higher means more splits.
		 * @type {number}
		 * @private
		 */
		this._splitJoinThresholdFactor = 512;

		/**
		 * The layer.
		 * @type {Layer}
		 * @private
		 */
		this._layer = null;

		/**
		 * The tile matrix set.
		 * @type {TileMatrixSet}
		 * @private
		 */
		this._tileMatrixSet = null;

		/**
		 * The url to be used by the tiles for the images.
		 * @type {string}
		 * @private
		 */
		this._tileUrl = '';

		/**
		 * The entities used for shadows. Derived from the shadow entity names.
		 * @type {Pioneer.EntityRef[]}
		 * @private
		 */
		this._shadowEntities = [];

		/**
		 * The downloader for easier future access.
		 * @private
		 */
		this._engine = this.getEntity().getScene().getEngine();

		/**
		 * The positions of all active cameras.
		 * @type {Pioneer.Vector3[]}
		 * @private
		 */
		this._cameraPositions = [];

		/**
		 * The root tile.
		 * @type {WMTSTile}
		 * @private
		 */
		this._rootTile = null;

		/**
		 * The pixel size of each tile. Starts out as undefined and then on the first image load is set.
		 * @type {number}
		 * @private
		 */
		this._tilePixelSize = undefined;

		/**
		 * A promise that resolves when all tiles are no longer transitioning.
		 * @type {Promise<void>}
		 * @private
		 */
		this._tilesLoadedPromise = null;

		/**
		 * The callback that gets called when all tiles are no longer transitioning.
		 * @type {TransitionsCompleteCallback}
		 * @private
		 */
		this._transitionsCompleteCallback = null;

		/**
		 * A counter that ensures that we don't do too many splits or joins in a single frame.
		 * @type {number}
		 * @private
		 */
		this._loadsThisFrame = 0;

		/**
		 * A reference to the atmosphere component.
		 * @type {Pioneer.ComponentRef<Pioneer.AtmosphereComponent>}
		 * @private
		 */
		this._atmosphereComponentRef = new Pioneer.ComponentRef(this.getEntity().getScene());
		this._atmosphereComponentRef.setByType(this.getEntity().getName(), 'atmosphere');

		/**
		 * A reference to the spheroid component.
		 * @type {Pioneer.ComponentRef<Pioneer.SpheroidComponent>}
		 * @private
		 */
		this._spheroidComponentRef = new Pioneer.ComponentRef(this.getEntity().getScene());
		this._spheroidComponentRef.setByType(this.getEntity().getName(), 'spheroid');
		this._spheroidComponentRef.setRefChangedCallback(this._spheroidRefChangedCallback.bind(this));

		// Bind the callbacks to this.
		this._spheroidChangedCallback = this._spheroidChangedCallback.bind(this);

		// Lets the base component to check for valid orientation when determining whether this is visible.
		this.__setUsesEntityOrientation(true);
	}

	/**
	 * Sets the end point for the WMTS protocol.
	 * @param {string} endPoint
	 */
	async setEndPoint(endPoint) {
		this._endPoint = endPoint;
		this.resetResources();
	}

	/**
	 * Sets the layer identifier.
	 * @param {string} identifier
	 */
	setLayer(identifier) {
		this._layerIdentifier = identifier;
		this.resetResources();
	}

	/**
	 * Sets the tile matrix set identifier.
	 * @param {string} identifier
	 */
	setTileMatrixSet(identifier) {
		this._tileMatrixSetIdentifier = identifier;
		this.resetResources();
	}

	/**
	 * Sets the style of the layer.
	 * @param {string} identifier
	 */
	setStyle(identifier) {
		this._style = identifier;
		this.resetResources();
	}

	/**
	 * Sets the value of a dimension in the layer.
	 * @param {string} dimension
	 * @param {string} value
	 */
	setDimensionValue(dimension, value) {
		this._dimensionValues.set(dimension, value);
		this.resetResources();
	}

	/**
	 * Gets the list of layers as a map from titles to identifiers.
	 * @returns {Map<string, string>}
	 */
	getLayers() {
		if (this._capabilities !== null) {
			return this._capabilities.layers;
		}
		else {
			return new Map();
		}
	}

	/**
	 * Gets the minimum level to which the tiles must split.
	 * @returns {number}
	 */
	getMinLevel() {
		return this._minLevel;
	}

	/**
	 * Sets the minimum level to which the tiles must split. Defaults to negative infinity.
	 * @param {number} minLevel
	 */
	setMinLevel(minLevel) {
		this._minLevel = minLevel;
	}

	/**
	 * Gets the maximum level to which the tiles can split.
	 * @returns {number}
	 */
	getMaxLevel() {
		return this._maxLevel;
	}

	/**
	 * Sets the maximum level to which the tiles can split. Defaults to positive infinity.
	 * @param {number} maxLevel
	 */
	setMaxLevel(maxLevel) {
		this._maxLevel = maxLevel;
	}

	/**
	 * Returns a promise when no more tiles are loading.
	 * @returns {Promise<void>}
	 */
	getTilesLoadedPromise() {
		return this._tilesLoadedPromise || Promise.resolve();
	}

	/**
	 * Sets the reference to use for the spheroid component, by name or the type index.
	 * @param {string | number} nameOrTypeIndex
	 */
	setSpheroidReference(nameOrTypeIndex) {
		if (typeof nameOrTypeIndex === 'string') {
			this._spheroidComponentRef.setByName(this.getEntity().getName(), nameOrTypeIndex);
		}
		else {
			this._spheroidComponentRef.setByType(this.getEntity().getName(), 'spheroid', nameOrTypeIndex);
		}
	}

	/**
	 * Gets the layer.
	 * @returns {Layer}
	 * @internal
	 */
	__getLayer() {
		return this._layer;
	}

	/**
	 * Gets the tile matrix set.
	 * @returns {TileMatrixSet}
	 * @internal
	 */
	__getTileMatrixSet() {
		return this._tileMatrixSet;
	}

	/**
	 * Gets the url to be used by the tiles.
	 * @returns {string}
	 * @internal
	 */
	__getTileUrl() {
		return this._tileUrl;
	}

	/**
	 * Gets the layer bounds or null if there is no layer.
	 * @returns {Pioneer.Rect}
	 * @internal
	 */
	__getLayerBounds() {
		if (this._layer !== null) {
			return this._layer.boundingBox;
		}
		else {
			return null;
		}
	}

	/**
	 * Gets the max level.
	 * @returns {number}
	 * @internal
	 */
	__getMaxLevel() {
		return this._maxLevel;
	}

	/**
	 * Gets the min level.
	 * @returns {number}
	 * @internal
	 */
	__getMinLevel() {
		return this._minLevel;
	}

	/**
	 * Gets the split-join factor.
	 * @returns {number}
	 * @internal
	 */
	__getSplitJoinFactor() {
		return this._splitJoinThresholdFactor;
	}

	/**
	 * Gets the pixel size of each tile. Starts out as undefined and then on the first image load is set.
	 * @returns {number}
	 */
	__getTilePixelSize() {
		return this._tilePixelSize;
	}

	/**
	 * Sets the pixel size of each tile. Starts out as undefined and then on the first image load is set.
	 * @param {number} tilePixelSize
	 */
	__setTilePixelSize(tilePixelSize) {
		this._tilePixelSize = tilePixelSize;
	}

	/**
	 * Gets the number of loads this frame.
	 * @returns {number}
	 */
	__getLoadsThisFrame() {
		return this._loadsThisFrame;
	}

	/**
	 * Increments the number of loads this frame.
	 * @internal
	 */
	__incLoadsThisFrame() {
		this._loadsThisFrame += 1;
	}

	/**
	 * Gets the current camera positions.
	 * @returns {Pioneer.Vector3[]}
	 * @internal
	 */
	__getCameraPositions() {
		return this._cameraPositions;
	}

	/**
	 * Gets the spheroid component this is using. Used by Tile.
	 * @returns {Pioneer.SpheroidComponent}
	 * @internal
	 */
	__getSpheroidComponent() {
		return this._spheroidComponentRef.get();
	}

	/**
	 * Cleans up the component.
	 * @override
	 * @internal
	 */
	__destroy() {
		// Remove the spheroid changed callback.
		const spheroidComponent = this._spheroidComponentRef.get();
		if (spheroidComponent !== null) {
			spheroidComponent.removeChangedCallback(this._spheroidChangedCallback);
		}

		super.__destroy();
	}

	/**
	 * Updates the component.
	 * @override
	 * @internal
	 */
	__update() {
		// Update the references.
		this._spheroidComponentRef.update();

		// If there's no root tile, do nothing else.
		if (this._rootTile === null) {
			return;
		}

		// Get the positions of all cameras.
		while (this._cameraPositions.length > this._engine.getNumViewports()) {
			this._cameraPositions.pop();
		}
		while (this._cameraPositions.length < this._engine.getNumViewports()) {
			this._cameraPositions.push(new Pioneer.Vector3());
		}
		const cameraPosition = Pioneer.Vector3.pool.get();
		for (let i = 0, l = this._engine.getNumViewports(); i < l; i++) {
			cameraPosition.neg(this.getEntity().getCameraSpacePosition(this._engine.getViewport(i).getCamera()));
			this._cameraPositions[i].rotateInverse(this.getEntity().getOrientation(), cameraPosition);
		}
		Pioneer.Vector3.pool.release(cameraPosition);

		this._loadsThisFrame = 0;

		// Do the update on all of the tiles recursively.
		// If any tile is still transitioning, set transitioning to true.
		const transitioning = this._rootTile.update();

		// If there is no current promise (there were no tiles) and now there are,
		// Create the loaded promise and record its resolve callback.
		if (this._tilesLoadedPromise === null && transitioning) {
			this._tilesLoadedPromise = new Promise((resolve) => {
				this._transitionsCompleteCallback = resolve;
			});
		}

		// If the loaded promise callback exists and we're no longer transitioning,
		// Clear the loaded promise and callback and call the callback (the resolve function of the promise).
		if (this._tilesLoadedPromise !== null && !transitioning) {
			const callback = this._transitionsCompleteCallback;
			this._tilesLoadedPromise = null;
			this._transitionsCompleteCallback = null;
			callback();
		}
	}

	/**
	 * Prepares the component for render.
	 * @param {Pioneer.CameraComponent} camera
	 * @override
	 * @internal
	 */
	__prepareForRender(camera) {
		// Set the orientation to the entity's orientation.
		Pioneer.ThreeJsHelper.setOrientationToEntity(this.getThreeJsObjects(), this.getEntity());

		// Set the position to the entity's camera position.
		Pioneer.ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects(), this.getEntity(), camera);

		// Get the atmosphere.
		const atmosphere = this._atmosphereComponentRef.get();

		// Setup the regular uniforms.
		Pioneer.MaterialUtils.setUniforms(this.getThreeJsMaterials(), camera, this.getEntity(), this._shadowEntities, atmosphere, true);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void | void[]>}
	 * @override
	 * @protected
	 */
	async __loadResources() {
		if (this._endPoint !== '') {
			// Load the capabilities end point XML.
			this._capabilities = new Capabilities(this._endPoint, this._engine, -this.getEntity().getLeastCameraDepth());
			await this._capabilities.readyPromise;

			// If no layer is specified and there is only one choice, choose that one.
			let layerIdentifier = this._layerIdentifier;
			if (layerIdentifier === '') {
				const layers = this._capabilities.layers;
				if (layers.size === 1) {
					for (const [, identifier] of layers) {
						layerIdentifier = identifier;
					}
				}
			}
			// If there's still no layer specified, nothing to do.
			if (layerIdentifier === '') {
				return;
			}
			// Load the layer.
			this._layer = this._capabilities.getLayer(layerIdentifier);
			if (this._layer === null) {
				throw new Error('Invalid layer: "' + layerIdentifier + '".');
			}

			// If no tile matrix set is specified and there is only one choice, choose that one.
			let tileMatrixSetIdentifier = this._tileMatrixSetIdentifier;
			if (tileMatrixSetIdentifier === '') {
				const tileMatrixSets = this._layer.tileMatrixSets;
				if (tileMatrixSets.size === 1) {
					for (const identifier of tileMatrixSets) {
						tileMatrixSetIdentifier = identifier;
					}
				}
			}
			// Load the tile matrix set.
			this._tileMatrixSet = this._capabilities.getTileMatrixSet(tileMatrixSetIdentifier);
			if (this._tileMatrixSet === null) {
				throw new Error('Invalid tile matrix set: "' + tileMatrixSetIdentifier + '".');
			}
			await this._tileMatrixSet.readyPromise;

			// Get the root tile bounds.
			const rootTileBounds = new Pioneer.Rect();
			rootTileBounds.copy(this._tileMatrixSet.boundingBox);
			// Make sure the level -1 tile matrix can be 2x2 when split.
			if (this._tileMatrixSet.getNumTiles(0).x === 1) {
				rootTileBounds.size.x *= 2.0;
			}
			if (this._tileMatrixSet.getNumTiles(0).y === 1) {
				rootTileBounds.origin.y -= rootTileBounds.size.y;
				rootTileBounds.size.y *= 2.0;
			}

			// If no style is specified, get the default one.
			let style = this._style;
			if (style === '') {
				style = this._layer.defaultStyle;
			}
			// Add default dimension values.
			for (const [identifier, defaultValue] of this._layer.dimensions) {
				if (!this._dimensionValues.has(identifier)) {
					this._dimensionValues.set(identifier, defaultValue);
				}
			}

			// The tile url to be used by the tiles.
			this._tileUrl = this._layer.url;
			this._tileUrl = this._tileUrl.replace('{TileMatrixSet}', this._tileMatrixSet.identifier);
			this._tileUrl = this._tileUrl.replace('{Style}', style);
			this._dimensionValues.forEach((value, dimension) => {
				this._tileUrl = this._tileUrl.replace('{' + dimension + '}', value);
			});

			this._rootTile = new WMTSTile(this, null, -1, 0, 0, rootTileBounds);
		}
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		this._capabilities = null;
		this._layer = null;
		this._tileMatrixSet = null;
		this._tileUrl = '';
		this._rootTile.destroy();
		this._rootTile = null;
	}

	/**
	 * Callback called when the spheroid reference is found or lost.
	 * @param {Pioneer.SpheroidComponent} oldRef
	 * @param {Pioneer.SpheroidComponent} newRef
	 * @private
	 */
	_spheroidRefChangedCallback(oldRef, newRef) {
		if (oldRef !== null) {
			oldRef.removeChangedCallback(this._spheroidChangedCallback);
		}
		if (newRef !== null) {
			newRef.addChangedCallback(this._spheroidChangedCallback);
		}
		this._spheroidChangedCallback();
	}

	/**
	 * Callback to be called when the spheroid component changed.
	 * @private
	 */
	_spheroidChangedCallback() {
		// Set the radii uniforms.
		const spheroidComponent = this._spheroidComponentRef.get();
		if (spheroidComponent !== null) {
			this.__setRadius(Math.max(spheroidComponent.getEquatorialRadius(), spheroidComponent.getPolarRadius()));
		}
		else {
			this.__setRadius(0);
		}
		this.resetResources();
	}
}

/**
 * A single tile with mesh, material, and bounds.
 * @extends Pioneer.Tile<WMTSTile>
 * @private
 */
class WMTSTile extends Pioneer.Tile {
	/**
	 * Constructor.
	 * @param {WMTSComponent} component
	 * @param {WMTSTile} parent
	 * @param {number} level
	 * @param {number} row
	 * @param {number} col
	 * @param {Pioneer.Rect} tileBounds
	 */
	constructor(component, parent, level, row, col, tileBounds) {
		super(parent);

		/**
		 * The WMTS component.
		 * @type {WMTSComponent}
		 * @private
		 */
		this._component = component;

		/**
		 * The TileMatrix level starting from -1.
		 * @type {number}
		 * @private
		 */
		this._level = level;

		/**
		 * The row in the TileMatrix of the tile.
		 * @type {number}
		 * @private
		 */
		this._row = row;

		/**
		 * The column in the TileMatrix of the tile.
		 * @type {number}
		 * @private
		 */
		this._col = col;

		/**
		 * The bounds of the tile in CRS coordinates.
		 * @type {Pioneer.Rect}
		 * @private
		 */
		this._tileBounds = new Pioneer.Rect();
		this._tileBounds.copy(tileBounds);

		/**
		 * The "center", the average between the origin and end of the tile bounds.
		 * @type {Pioneer.Vector3}
		 * @private
		 */
		this._center = new Pioneer.Vector3();

		/**
		 * The "radius", the distance from the center to one of the corners.
		 * @type {number}
		 * @private
		 */
		this._radius = 0;

		/**
		 * The material used by the tile, from a pool of materials.
		 * @type {Pioneer.THREE.ShaderMaterial}
		 * @private
		 */
		this._threeJsMaterial = null;

		/**
		 * The Three.js object of the tile.
		 * @type {Pioneer.THREE.Mesh<Pioneer.THREE.BufferGeometry, Pioneer.THREE.ShaderMaterial | Pioneer.THREE.ShaderMaterial[]>}
		 * @private
		 */
		this._threeJsObject = null;

		// Calculate the center and diagonal length of a box that includes
		// the start and end points and the average of the two.
		const spheroid = component.__getSpheroidComponent();
		const tileMatrixSet = this._component.__getTileMatrixSet();
		// Get the origin as XYZ.
		const originXYZ = new Pioneer.Vector3();
		tileMatrixSet.crsUnitsToXYZ(originXYZ, this._tileBounds.origin, spheroid);
		// Get the end as XYZ.
		const end = new Pioneer.Vector2();
		end.add(this._tileBounds.origin, this._tileBounds.size);
		const endXYZ = new Pioneer.Vector3();
		tileMatrixSet.crsUnitsToXYZ(endXYZ, end, spheroid);
		// Get the average of the start and end points.
		const tileBoundsCenter = new Pioneer.Vector2();
		tileBoundsCenter.addMult(this._tileBounds.origin, this._tileBounds.size, 0.5);
		tileMatrixSet.crsUnitsToXYZ(this._center, tileBoundsCenter, spheroid);
		// Get the min and max points.
		const min = new Pioneer.Vector3();
		const max = new Pioneer.Vector3();
		min.x = Math.min(originXYZ.x, endXYZ.x, this._center.x);
		min.y = Math.min(originXYZ.y, endXYZ.y, this._center.y);
		min.z = Math.min(originXYZ.z, endXYZ.z, this._center.z);
		max.x = Math.max(originXYZ.x, endXYZ.x, this._center.x);
		max.y = Math.max(originXYZ.y, endXYZ.y, this._center.y);
		max.z = Math.max(originXYZ.z, endXYZ.z, this._center.z);
		// Get the center of the box.
		this._center.add(min, max);
		this._center.mult(this._center, 0.5);
		// Get the radius.
		const diagonal = new Pioneer.Vector3();
		diagonal.sub(this._center, min);
		this._radius = diagonal.magnitude();
	}

	/**
	 * @param {WMTSTile} parent
	 * @param {number} row - 0 or 1
	 * @param {number} col - 0 or 1
	 * @returns {WMTSTile}
	 * @override
	 */
	createNewTile(parent, row, col) {
		// Get new tile params.
		const newLevel = parent._level + 1;
		const newRow = parent._row * 2 + row;
		const newCol = parent._col * 2 + col;

		// Check if it's within the tile bounds.
		const numTiles = parent._component.__getTileMatrixSet().getNumTiles(parent._level + 1);
		if (newRow >= numTiles.y || newCol >= numTiles.x) {
			return null;
		}

		// Calculate the new CRS tile bounds.
		const tileBounds = new Pioneer.Rect();
		tileBounds.origin.set(parent._tileBounds.origin.x + col * 0.5 * parent._tileBounds.size.x, parent._tileBounds.origin.y + (1 - row) * 0.5 * parent._tileBounds.size.y);
		tileBounds.size.set(0.5 * parent._tileBounds.size.x, 0.5 * parent._tileBounds.size.y);

		return new WMTSTile(parent._component, parent, newLevel, newRow, newCol, tileBounds);
	}

	/**
	 * Returns true if this tile should be split.
	 * @returns {boolean}
	 * @override
	 */
	checkSplit() {
		// Don't split if there are no more tiles in the tile matrix set.
		if (this._level >= this._component.__getTileMatrixSet().numLevels - 1) {
			return false;
		}
		// Don't split if the whole tile is not in the layer.
		if (!this._tileBounds.intersects(this._component.__getLayerBounds())) {
			return false;
		}
		// Don't split if we're at the maximum level of detail.
		if (this._level >= this._component.__getMaxLevel()) {
			return false;
		}
		// Don't split if we've already done a lot this frame.
		if (this._component.__getLoadsThisFrame() >= 1) {
			return false;
		}
		// Always split if we're below the minimum level of detail.
		if (this._level < this._component.__getMinLevel()) {
			return true;
		}
		// Always split if we're at the invisible root node.
		if (this._level === -1) {
			return true;
		}
		// Split if the nearest camera distance is less than the threshold.
		let tilePixelSize = this._component.__getTilePixelSize();
		if (tilePixelSize === undefined && this._threeJsMaterial !== null) {
			tilePixelSize = this._threeJsMaterial.uniforms['colorTexture'].value.image.width;
			if (tilePixelSize === 1) { // It is using a single pixel color, so don't go further.
				return false;
			}
			this._component.__setTilePixelSize(tilePixelSize);
		}
		return this._getNearestDistance() < this._component.__getSplitJoinFactor() * this._radius / tilePixelSize;
	}

	/**
	 * Returns true if this tile should join its children.
	 * @returns {boolean}
	 * @override
	 */
	checkJoin() {
		// If we're at the root node, never join.
		if (this._level === -1) {
			return false;
		}
		// Don't join if we've already done a lot this frame.
		if (this._component.__getLoadsThisFrame() >= 1) {
			return false;
		}
		// Always join is we're above the maximum level of detail.
		if (this._level > this._component.__getMaxLevel()) {
			return true;
		}
		// Never join if we're at or below the minimum level of detail.
		if (this._level <= this._component.__getMinLevel()) {
			return false;
		}
		// Check if the nearest camera distance is greater than the threshold.
		let tilePixelSize = this._component.__getTilePixelSize();
		if (tilePixelSize === undefined && this._threeJsMaterial !== null) {
			tilePixelSize = this._threeJsMaterial.uniforms['colorTexture'].value.image.width;
			this._component.__setTilePixelSize(tilePixelSize);
		}
		return this._getNearestDistance() > this._component.__getSplitJoinFactor() * this._radius * 4 / tilePixelSize;
	}

	/**
	 * Asynchronously loads the tile so that it may be used.
	 * @returns {Promise<void | void[]>}
	 * @override
	 */
	async load() {
		if (this._level === -1) {
			return Promise.resolve();
		}

		this._component.__incLoadsThisFrame();

		if (this._threeJsMaterial !== null) {
			throw new Error('Tile already has material.');
		}

		// Check if it's within the layer bounds.
		if (this._tileBounds.intersects(this._component.__getLayerBounds())) {
			// Get a material from the component's materials cache.
			this._threeJsMaterial = Pioneer.MaterialUtils.get();
			this._component.getThreeJsMaterials().push(this._threeJsMaterial);
			// this._threeJsMaterial.wireframe = true;
			// this._threeJsMaterial.defines['baseColor'] = true;
			// this._threeJsMaterial.uniforms['color'].value.setRGB(0.25 + 0.75 * Math.random(), 0.25 + 0.75 * Math.random(), 0.25 + 0.75 * Math.random());

			let url = this._component.__getTileUrl();
			url = url.replace('{TileMatrix}', '' + this._level);
			url = url.replace('{TileRow}', '' + this._row);
			url = url.replace('{TileCol}', '' + this._col);

			// Download the texture.
			await Pioneer.ThreeJsHelper.loadTextureIntoUniform(this._component, this._threeJsMaterial.uniforms['colorTexture'], url, false, false);
		}
	}

	/**
	 * Asynchronously unloads the tile.
	 * @returns {Promise<void>}
	 * @override
	 */
	async unload() {
		if (this._level === -1) {
			return;
		}
		if (this._threeJsMaterial !== null) {
			// Remove up the material from the materials list.
			const materials = this._component.getThreeJsMaterials();
			for (let i = 0, l = materials.length; i < l; i++) {
				if (materials[i] === this._threeJsMaterial) {
					materials.splice(i, 1);
					break;
				}
			}
			if (this._threeJsMaterial.uniforms['colorTexture'].value) {
				Pioneer.ThreeJsHelper.destroyTexture(this._threeJsMaterial.uniforms['colorTexture'].value);
			}
			// Dispose of the material.
			Pioneer.ThreeJsHelper.destroyMaterial(this._threeJsMaterial, false);
			this._threeJsMaterial = null;
		}
		this._threeJsMaterial = null;
	}

	/**
	 * Asynchronously activates the tile.
	 * @returns {Promise<void>}
	 * @override
	 */
	async activate() {
		if (this._level === -1) {
			return;
		}
		// Create the Three.js object.
		this._threeJsObject = Pioneer.ThreeJsHelper.createMeshObject(this._component, this._threeJsMaterial, [
			{ name: 'position', dimensions: 3 },
			{ name: 'normal', dimensions: 3 },
			{ name: 'uv', dimensions: 2 }
		], false);
		this._component.getThreeJsObjects().push(this._threeJsObject);
		Pioneer.ThreeJsHelper.useInDynEnvMap(this._threeJsObject, true);

		// Create the vertices, normals, uvs, and indices arrays.
		const numVVerts = 21;
		const numUVerts = 21;
		const numVerts = numUVerts * numVVerts;
		const meshPositions = new Float32Array(numVerts * 3);
		const meshNormals = new Float32Array(numVerts * 3);
		const meshUVs = new Float32Array(numVerts * 2);
		const meshIndices = new Uint16Array(numUVerts * (numVVerts - 1) * 6);

		// Get the bounding box.
		const boundingBox = this._component.__getLayer().boundingBox;

		// Set the vertices, normals, uvs, and indices arrays.
		const crsUnits = new Pioneer.Vector2();
		const clampedCRSUnits = new Pioneer.Vector2();
		const xyz = new Pioneer.Vector3();
		for (let y = 0; y < numVVerts; y++) {
			let yF = 1.0 - y / (numVVerts - 1);
			crsUnits.y = Pioneer.MathUtils.lerp(this._tileBounds.origin.y, this._tileBounds.origin.y + this._tileBounds.size.y, yF);
			clampedCRSUnits.y = Pioneer.MathUtils.clamp(crsUnits.y, boundingBox.origin.y, boundingBox.origin.y + boundingBox.size.y);
			yF += (clampedCRSUnits.y - crsUnits.y) / this._tileBounds.size.y;
			for (let x = 0; x < numUVerts; x++) {
				let xF = x / (numUVerts - 1);
				crsUnits.x = Pioneer.MathUtils.lerp(this._tileBounds.origin.x, this._tileBounds.origin.x + this._tileBounds.size.x, xF);
				clampedCRSUnits.x = Pioneer.MathUtils.clamp(crsUnits.x, boundingBox.origin.x, boundingBox.origin.x + boundingBox.size.x);
				xF += (clampedCRSUnits.x - crsUnits.x) / this._tileBounds.size.x;
				// Set the latitude and longitude coordinate.
				this._component.__getTileMatrixSet().crsUnitsToXYZ(xyz, clampedCRSUnits, this._component.__getSpheroidComponent());

				const vertexI = y * numUVerts + x;
				meshPositions[vertexI * 3 + 0] = xyz.x;
				meshPositions[vertexI * 3 + 1] = xyz.y;
				meshPositions[vertexI * 3 + 2] = xyz.z;
				xyz.normalize(xyz);
				meshNormals[vertexI * 3 + 0] = xyz.x;
				meshNormals[vertexI * 3 + 1] = xyz.y;
				meshNormals[vertexI * 3 + 2] = xyz.z;
				meshUVs[vertexI * 2 + 0] = xF;
				meshUVs[vertexI * 2 + 1] = 1.0 - yF;

				const triangleI = y * numUVerts + x;
				if (x < numUVerts - 1 && y < numVVerts - 1) {
					meshIndices[triangleI * 6 + 0] = numUVerts * (y + 0) + (x + 0);
					meshIndices[triangleI * 6 + 1] = numUVerts * (y + 1) + (x + 0);
					meshIndices[triangleI * 6 + 2] = numUVerts * (y + 1) + (x + 1);
					meshIndices[triangleI * 6 + 3] = numUVerts * (y + 0) + (x + 0);
					meshIndices[triangleI * 6 + 4] = numUVerts * (y + 1) + (x + 1);
					meshIndices[triangleI * 6 + 5] = numUVerts * (y + 0) + (x + 1);
				}
			}
		}

		Pioneer.ThreeJsHelper.setVertices(this._threeJsObject.geometry, 'position', meshPositions);
		Pioneer.ThreeJsHelper.setVertices(this._threeJsObject.geometry, 'normal', meshNormals);
		Pioneer.ThreeJsHelper.setVertices(this._threeJsObject.geometry, 'uv', meshUVs);
		Pioneer.ThreeJsHelper.setIndices(this._threeJsObject.geometry, meshIndices);
	}

	/**
	 * Asynchronously deactivates the tile.
	 * @returns {Promise<void>}
	 * @override
	 */
	async deactivate() {
		if (this._level === -1) {
			return;
		}
		// Remove the object from the objects list.
		const objects = this._component.getThreeJsObjects();
		for (let i = 0, l = objects.length; i < l; i++) {
			if (objects[i] === this._threeJsObject) {
				objects.splice(i, 1);
				break;
			}
		}
		// Destroy the object and its geometry.
		Pioneer.ThreeJsHelper.destroyObject(this._threeJsObject);
		this._threeJsObject = null;
	}

	/**
	 * Updates the tiles recursively. Returns true if the tile or any descendant is transitioning.
	 * @returns {boolean}
	 */
	update() {
		let transitioning = this.check();
		for (let i = 0, l = this.children.length; i < l; i++) {
			transitioning = this.children[i].update() || transitioning;
		}
		return transitioning;
	}

	_getNearestDistance() {
		// Calculate the nearest distance to see if we need to split or join this node.
		let nearestDistance = Number.POSITIVE_INFINITY;
		const diff = Pioneer.Vector3.pool.get();
		for (let i = 0, l = this._component.__getCameraPositions().length; i < l; i++) {
			const position = this._component.__getCameraPositions()[i];
			diff.sub(this._center, position);
			const distance = Math.max(0, diff.magnitude() - this._radius);
			if (nearestDistance > distance) {
				nearestDistance = distance;
			}
		}
		Pioneer.Vector3.pool.release(diff);
		return nearestDistance;
	}

	/**
	 * Converts the tile to a string.
	 * @returns {string}
	 * @override
	 */
	toString() {
		return this._level + '-' + this._row + '-' + this._col;
	}
}
