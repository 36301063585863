/** @module pioneer */
import {
	BaseController,
	CameraComponent,
	Entity,
	MathUtils,
	Quaternion,
	Vector3
} from '../../internal';

/**
 * A camera controller that looks around the user with an optional axis.
 */
export class LookController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The sensitivity for dragging.
		 * @type {number}
		 * @private
		 */
		this._dragSensitivity = 0.01;

		/**
		 * The smoothness of the dragging. Zero means no smoothness.
		 * @type {number}
		 * @private
		 */
		this._dragSmoothness = 0.8;

		/**
		 * The current value applied every frame to the yaw axis rotation.
		 * @type {number}
		 * @private
		 */
		this._yawAngleSmoothedValue = 0.0;

		/**
		 * The current value applied every frame to the pitch axis rotation.
		 * @type {number}
		 * @private
		 */
		this._pitchAngleSmoothedValue = 0.0;

		/**
		 * The axis around which to yaw. It can be 'none', 'x-axis', 'y-axis', 'z-axis', or 'position'.
		 * @type {string}
		 * @private
		 */
		this._yawAxisType = 'none';

		/**
		 * The yaw axis reference entity.
		 * @type {Entity}
		 * @private
		 */
		this._yawAxisEntity = null;

		// Let the base controller know that this changes the orientation.
		this.addModifiedState('orientation');
	}

	/**
	 * Gets the drag sensitivity. Defaults to 0.01.
	 * @returns {number}
	 */
	getDragSensitivity() {
		return this._dragSensitivity;
	}

	/**
	 * Sets the drag sensitivity.
	 * @param {number} dragSensitivity
	 */
	setDragSensitivity(dragSensitivity) {
		this._dragSensitivity = dragSensitivity;
	}

	/**
	 * Gets the drag smoothness. Defaults to 0.8.
	 * @returns {number}
	 */
	getDragSmoothness() {
		return this._dragSmoothness;
	}

	/**
	 * Sets the drag smoothness, between 0 and 1.
	 * @param {number} dragSmoothness
	 */
	setDragSmoothness(dragSmoothness) {
		this._dragSmoothness = dragSmoothness;
	}

	/**
	 * Gets the axis around which to yaw.
	 * @returns {string}
	 */
	getYawAxisType() {
		return this._yawAxisType;
	}

	/**
	 * Sets the axis around which to yaw. It can be 'none', 'x-axis', 'y-axis', 'z-axis', or 'position'.
	 * @param {string} yawAxisType
	 */
	setYawAxisType(yawAxisType) {
		if (this._yawAxisType === yawAxisType) {
			return;
		}
		if (this._yawAxisEntity !== null) {
			if (['x-axis', 'y-axis', 'z-axis'].includes(this._yawAxisType)) {
				this.removeDependentState(this._yawAxisEntity.getName(), 'orientation');
			}
			else if (this._yawAxisType === 'position') {
				this.removeDependentState(this._yawAxisEntity.getName(), 'position');
			}
		}
		this._yawAxisType = yawAxisType;
		if (this._yawAxisEntity !== null) {
			if (['x-axis', 'y-axis', 'z-axis'].includes(this._yawAxisType)) {
				this.addDependentState(this._yawAxisEntity.getName(), 'orientation');
			}
			else if (this._yawAxisType === 'position') {
				this.addDependentState(this._yawAxisEntity.getName(), 'position');
			}
		}
	}

	/**
	 * Gets the yaw axis reference entity. Defaults to this entity's parent.
	 * @returns {Entity}
	 */
	getYawAxisEntity() {
		return this._yawAxisEntity;
	}

	/**
	 * Sets the yaw axis reference entity.
	 * @param {Entity} yawAxisEntity
	 */
	setYawAxisEntity(yawAxisEntity) {
		if (this._yawAxisEntity === yawAxisEntity) {
			return;
		}
		if (this._yawAxisEntity !== null) {
			if (['x-axis', 'y-axis', 'z-axis'].includes(this._yawAxisType)) {
				this.removeDependentState(this._yawAxisEntity.getName(), 'orientation');
			}
			else if (this._yawAxisType === 'position') {
				this.removeDependentState(this._yawAxisEntity.getName(), 'position');
			}
		}
		this._yawAxisEntity = yawAxisEntity;
		if (this._yawAxisEntity !== null) {
			if (['x-axis', 'y-axis', 'z-axis'].includes(this._yawAxisType)) {
				this.addDependentState(this._yawAxisEntity.getName(), 'orientation');
			}
			else if (this._yawAxisType === 'position') {
				this.addDependentState(this._yawAxisEntity.getName(), 'position');
			}
		}
	}

	/**
	 * Updates the entity's position and orientation.
	 * @override
	 * @internal
	 */
	__update() {
		// If the yaw axis entity is null, set it to the parent.
		if (this._yawAxisEntity === null) {
			this._yawAxisEntity = this.getEntity().getParent();
			if (this._yawAxisEntity !== null) {
				if (['x-axis', 'y-axis', 'z-axis'].includes(this._yawAxisType)) {
					this.addDependentState(this._yawAxisEntity.getName(), 'orientation');
				}
				else if (this._yawAxisType === 'position') {
					this.addDependentState(this._yawAxisEntity.getName(), 'position');
				}
			}
		}

		// Set the position and orientation if they have never been set before.
		if (this.getEntity().getOrientation().isNaN()) {
			this.getEntity().setOrientation(Quaternion.Identity);
		}

		// Get the look multiplier for if 'x' is pressed.
		const input = this.getEntity().getScene().getEngine().getInput();
		let lookMultiplier = 1;
		if (input.isKeyPressed('x')) {
			lookMultiplier = 0.05;
		}

		// Factor in the field of view of the camera.
		const camera = /** @type {CameraComponent} */(this.getEntity().getComponentByType('camera'));
		if (camera !== null) {
			lookMultiplier *= Math.min(1, camera.getFieldOfView());
		}

		// Get the yaw and pitch from input.
		let yawAngle = 0;
		let pitchAngle = 0;
		const viewport = input.getActiveViewport();
		if (viewport !== null) {
			const camera = viewport.getCamera();
			if (camera !== null && camera.getEntity() === this.getEntity()) {
				// Add mouse movement.
				const draggedOffset = input.getDraggedOffset();
				yawAngle = -draggedOffset.x * this._dragSensitivity * lookMultiplier;
				pitchAngle = -draggedOffset.y * this._dragSensitivity * lookMultiplier;
			}
		}

		// Apply smoothing.
		this._yawAngleSmoothedValue = MathUtils.lerp(yawAngle, this._yawAngleSmoothedValue, this._dragSmoothness);
		this._pitchAngleSmoothedValue = MathUtils.lerp(pitchAngle, this._pitchAngleSmoothedValue, this._dragSmoothness);
		if (Math.abs(this._yawAngleSmoothedValue) < 0.0001 * lookMultiplier) {
			this._yawAngleSmoothedValue = 0;
		}
		if (Math.abs(this._pitchAngleSmoothedValue) < 0.0001 * lookMultiplier) {
			this._pitchAngleSmoothedValue = 0;
		}

		// Get the yaw axis.
		const yawAxis = Vector3.pool.get();
		if (this._yawAxisType === 'x-axis' && this._yawAxisEntity !== null) { // Use the x-axis of the reference entity.
			this._yawAxisEntity.getOrientation().getAxis(yawAxis, 0);
		}
		else if (this._yawAxisType === 'y-axis' && this._yawAxisEntity !== null) { // Use the y-axis of the reference entity.
			this._yawAxisEntity.getOrientation().getAxis(yawAxis, 1);
		}
		else if (this._yawAxisType === 'z-axis' && this._yawAxisEntity !== null) { // Use the z-axis of the reference entity.
			this._yawAxisEntity.getOrientation().getAxis(yawAxis, 2);
		}
		else if (this._yawAxisType === 'position' && this._yawAxisEntity !== null) { // Use the position of the reference entity.
			yawAxis.normalize(this._yawAxisEntity.getPosition());
		}
		else {
			this.getEntity().getOrientation().getAxis(yawAxis, 2); // Use the entity's z-axis.
		}

		// If the yaw axis isn't ready, just work as if the yaw axis type is 'none'.
		if (yawAxis.isNaN()) {
			this.getEntity().getOrientation().getAxis(yawAxis, 2);
		}

		// Get the current orientation.
		const orientation = Quaternion.pool.get();
		orientation.copy(this.getEntity().getOrientation());

		// Rotate the orientation so that its z-axis is upright.
		const rotation = Quaternion.pool.get();
		const zAxis = Vector3.pool.get();
		const yAxis = Vector3.pool.get();
		this.getEntity().getOrientation().getAxis(yAxis, 1);
		this.getEntity().getOrientation().getAxis(zAxis, 2);
		const angle = zAxis.angleAroundAxis(yawAxis, yAxis);
		rotation.setFromAxisAngle(yAxis, angle);
		rotation.normalize(rotation);
		orientation.mult(rotation, orientation);
		Vector3.pool.release(zAxis);
		Vector3.pool.release(yAxis);

		// Get the pitch axis.
		const pitchAxis = Vector3.pool.get();
		orientation.getAxis(pitchAxis, 0);

		// Rotate the target orientation by the pitch axis.
		rotation.setFromAxisAngle(pitchAxis, this._pitchAngleSmoothedValue);
		orientation.mult(rotation, orientation);
		Vector3.pool.release(pitchAxis);

		// Rotate the target orientation by the yaw axis.
		rotation.setFromAxisAngle(yawAxis, this._yawAngleSmoothedValue);
		orientation.mult(rotation, orientation);
		Vector3.pool.release(yawAxis);
		Quaternion.pool.release(rotation);

		// Set the current position.
		this.getEntity().setOrientation(orientation);
		Quaternion.pool.release(orientation);
	}
}
