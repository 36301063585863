/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	Capabilities,
	Color,
	Entity,
	Quaternion,
	THREE,
	Vector2,
	Vector3
} from '../internal';

/**
 * A set of helper functions for Three.js objects and materials.
 * `attributes` takes an array of {name: string, dimensions: number} objects.
 */
export class ThreeJsHelper {
	// CREATE & DESTROY

	/**
	 * Creates a Three.js BufferGeometry with given attributes and whether or not they are interleaved.
	 * @param {Object[]} attributes
	 * @param {string} attributes[].name
	 * @param {number} attributes[].dimensions
	 * @param {boolean} interleavedAttributes
	 * @returns {THREE.BufferGeometry}
	 */
	static createGeometry(attributes, interleavedAttributes) {
		// Create the Three.js goemetry.
		const geometry = new THREE.BufferGeometry();

		// Setup the attributes.
		if (interleavedAttributes) {
			// Get stride.
			let stride = 0;
			for (let i = 0; i < attributes.length; i++) {
				stride += attributes[i].dimensions;
			}
			// Create the buffers.
			const interleavedBuffer = new THREE.InterleavedBuffer(new Float32Array(0), stride);
			let offset = 0;
			for (let i = 0; i < attributes.length; i++) {
				geometry.setAttribute(attributes[i].name, new THREE.InterleavedBufferAttribute(interleavedBuffer, attributes[i].dimensions, offset));
				offset += attributes[i].dimensions;
			}
		}
		else {
			for (let i = 0; i < attributes.length; i++) {
				geometry.setAttribute(attributes[i].name, new THREE.BufferAttribute(new Float32Array(0), attributes[i].dimensions));
			}
		}
		geometry.setIndex(new THREE.BufferAttribute(new Uint16Array(0), 1));

		// Return the geometry.
		return geometry;
	}

	/**
	 * Sets up the Three.js object, assuming it's the root of its hierarchy, and adds it to the scene.
	 * @param {BaseComponent} component
	 * @param {THREE.Object3D} object
	 */
	static setupObject(component, object) {
		// Set the name of the object.
		object.name = component + '.' + Math.floor(Math.random() * 10000);

		// This turns off recalculating the world matrix every single frame.
		object.matrixAutoUpdate = false;

		// Set the object to never be culled by Three.js.
		object.frustumCulled = false;

		// Set the object to be initially invisible.
		object.visible = false;

		// Add it to the scene.
		component.getEntity().getScene().getThreeJsScene().add(object);
	}

	/**
	 * Creates a Three.js Mesh object with the given material and geometry, and calls setupObject().
	 * @param {BaseComponent} component
	 * @param {THREE.ShaderMaterial | THREE.ShaderMaterial[]} material
	 * @param {THREE.BufferGeometry} geometry
	 * @returns {THREE.Mesh<THREE.BufferGeometry, THREE.ShaderMaterial | THREE.ShaderMaterial[]>}
	 */
	static createMeshObjectGivenGeometry(component, material, geometry) {
		// Create the Three.js object.
		const object = new THREE.Mesh(geometry, material);

		// Setup the mesh.
		this.setupObject(component, object);

		// Return the mesh.
		return object;
	}

	/**
	 * Creates a Three.js Mesh object with BufferGeometry with given attributes, and adds it to the scene.
	 * @param {BaseComponent} component
	 * @param {THREE.ShaderMaterial | THREE.ShaderMaterial[]} material
	 * @param {{ name: string, dimensions: number }[]} attributes
	 * @param {boolean} interleavedAttributes
	 * @returns {THREE.Mesh<THREE.BufferGeometry, THREE.ShaderMaterial | THREE.ShaderMaterial[]>}
	 */
	static createMeshObject(component, material, attributes, interleavedAttributes) {
		// Create the Three.js goemetry.
		const geometry = this.createGeometry(attributes, interleavedAttributes);

		// Create the object with no material.
		const object = this.createMeshObjectGivenGeometry(component, material, geometry);

		// Return the mesh.
		return object;
	}

	/**
	 * Destroys a Three.js geometry.
	 * @param {THREE.BufferGeometry} geometry
	 */
	static destroyGeometry(geometry) {
		geometry.dispose();
	}

	/**
	 * Disposes of geometry and removes the object from the Three.js scene. Also applies to children. Does not affect materials or textures.
	 * @param {THREE.Object3D} object
	 */
	static destroyObject(object) {
		while (object.children.length > 0) {
			this.destroyObject(object.children[object.children.length - 1]);
		}
		if (object.parent !== null) {
			object.parent.remove(object);
		}
		if (object instanceof THREE.Mesh) {
			this.destroyGeometry(object.geometry);
		}
	}

	/**
	 * Destroys a Three.js texture. Disposes of it, revoking any created object url.
	 * @param {THREE.Texture} texture
	 */
	static destroyTexture(texture) {
		if (texture.source.data instanceof HTMLImageElement || texture.source.data instanceof HTMLVideoElement) {
			URL.revokeObjectURL(texture.source.data.src);
		}
		texture.dispose();
	}

	/**
	 * Destroys a Three.js material. Disposes of it and any textures.
	 * @param {THREE.ShaderMaterial} material
	 * @param {boolean} destroyTextures
	 */
	static destroyMaterial(material, destroyTextures) {
		if (destroyTextures) {
			const uniforms = material.uniforms;
			for (const uniform in uniforms) {
				if (Object.prototype.hasOwnProperty.call(uniforms, uniform)) {
					const value = uniforms[uniform].value;
					if (value instanceof THREE.Texture) {
						this.destroyTexture(value);
					}
				}
			}
		}
		material.dispose();
	}

	/**
	 * Destroys a Three.js render target.
	 * @param {THREE.WebGLRenderTarget} renderTarget
	 */
	static destroyRenderTarget(renderTarget) {
		renderTarget.dispose();
	}

	/**
	 * Destroys all Three.js objects, geometries, materials, and textures in the component.
	 * @param {BaseComponent} component
	 */
	static destroyAllObjectsAndMaterials(component) {
		const objects = component.getThreeJsObjects();
		const materials = component.getThreeJsMaterials();
		for (let i = 0; i < objects.length; i++) {
			this.destroyObject(objects[i]);
		}
		for (let i = 0; i < materials.length; i++) {
			this.destroyMaterial(materials[i], true);
		}
	}

	// POSITION & ORIENTATION

	/**
	 * Sets the object's position.
	 * @param {THREE.Object3D | THREE.Object3D[]} objects
	 * @param {Vector3} position
	 */
	static setPosition(objects, position) {
		if (!objects) {
			return;
		}
		if (Array.isArray(objects)) {
			for (let i = 0, l = objects.length; i < l; i++) {
				objects[i].position.set(position.x, position.y, position.z);
			}
		}
		else {
			objects.position.set(position.x, position.y, position.z);
		}
	}

	/**
	 * Sets the object's position to be the entity's camera-space position, along with an optional offset.
	 * @param {THREE.Object3D | THREE.Object3D[]} objects
	 * @param {Entity} entity
	 * @param {CameraComponent} camera
	 * @param {Vector3} [offset]
	 * @param {boolean} [offsetIsInEntityFrame]
	 */
	static setPositionToEntity(objects, entity, camera, offset, offsetIsInEntityFrame) {
		if (!objects) {
			return;
		}
		const cameraSpacePosition = entity.getCameraSpacePosition(camera);
		this._tempThreeJsVector3.set(cameraSpacePosition.x, cameraSpacePosition.y, cameraSpacePosition.z);
		if (offset) {
			const newOffset = Vector3.pool.get();
			if (offsetIsInEntityFrame) {
				newOffset.rotate(entity.getOrientation(), offset);
			}
			else {
				newOffset.copy(offset);
			}
			this._tempThreeJsVector3.x += newOffset.x;
			this._tempThreeJsVector3.y += newOffset.y;
			this._tempThreeJsVector3.z += newOffset.z;
			Vector3.pool.release(newOffset);
		}
		if (Array.isArray(objects)) {
			for (let i = 0, l = objects.length; i < l; i++) {
				objects[i].position.copy(this._tempThreeJsVector3);
			}
		}
		else {
			objects.position.copy(this._tempThreeJsVector3);
		}
	}

	/**
	 * Sets the object's scale.
	 * @param {THREE.Object3D | THREE.Object3D[]} objects
	 * @param {number|Vector3} scale
	 */
	static setScale(objects, scale) {
		if (!objects) {
			return;
		}
		if (Array.isArray(objects)) {
			for (let i = 0, l = objects.length; i < l; i++) {
				if (typeof scale === 'number') {
					objects[i].scale.set(scale, scale, scale);
				}
				else {
					objects[i].scale.set(scale.x, scale.y, scale.z);
				}
			}
		}
		else {
			if (typeof scale === 'number') {
				objects.scale.set(scale, scale, scale);
			}
			else {
				objects.scale.set(scale.x, scale.y, scale.z);
			}
		}
	}

	/**
	 * Sets the object's orientation.
	 * @param {THREE.Object3D | THREE.Object3D[]} objects
	 * @param {Quaternion} orientation
	 */
	static setOrientation(objects, orientation) {
		if (!objects) {
			return;
		}
		BaseComponent._tempThreeJsQuaternion.set(orientation.x, orientation.y, orientation.z, orientation.w);
		if (Array.isArray(objects)) {
			for (let i = 0, l = objects.length; i < l; i++) {
				objects[i].setRotationFromQuaternion(BaseComponent._tempThreeJsQuaternion);
			}
		}
		else {
			objects.setRotationFromQuaternion(BaseComponent._tempThreeJsQuaternion);
		}
	}

	/**
	 * Sets the object to have its axes so that its z-axis faces the camera, and x-axis is aligned with the camera's right.
	 * @param {THREE.Object3D | THREE.Object3D[]} objects
	 * @param {Entity} entity
	 * @param {CameraComponent} camera
	 */
	static setOrientationToBillboard(objects, entity, camera) {
		if (!objects) {
			return;
		}
		const orientation = Quaternion.pool.get();
		const forward = Vector3.pool.get();
		const right = Vector3.pool.get();
		forward.normalize(entity.getCameraSpacePosition(camera));
		camera.getEntity().getOrientation().getAxis(right, 0);
		right.setNormalTo(forward, right);
		orientation.setFromAxes(right, undefined, forward);
		BaseComponent._tempThreeJsQuaternion.set(orientation.x, orientation.y, orientation.z, orientation.w);
		if (Array.isArray(objects)) {
			for (let i = 0, l = objects.length; i < l; i++) {
				objects[i].setRotationFromQuaternion(BaseComponent._tempThreeJsQuaternion);
			}
		}
		else {
			objects.setRotationFromQuaternion(BaseComponent._tempThreeJsQuaternion);
		}
		Quaternion.pool.release(orientation);
		Vector3.pool.release(forward);
		Vector3.pool.release(right);
	}

	/**
	 * Sets the object's orientation to be the same as the entity, with optional addition rotation applied first.
	 * @param {THREE.Object3D | THREE.Object3D[]} objects
	 * @param {Entity} entity
	 * @param {Quaternion} [rotation]
	 */
	static setOrientationToEntity(objects, entity, rotation) {
		if (!objects) {
			return;
		}
		const entityOrientation = entity.getOrientation();
		if (rotation === undefined) {
			BaseComponent._tempThreeJsQuaternion.set(entityOrientation.x, entityOrientation.y, entityOrientation.z, entityOrientation.w);
		}
		else {
			const orientation = Quaternion.pool.get();
			orientation.mult(entityOrientation, rotation);
			BaseComponent._tempThreeJsQuaternion.set(orientation.x, orientation.y, orientation.z, orientation.w);
			Quaternion.pool.release(orientation);
		}
		if (Array.isArray(objects)) {
			for (let i = 0, l = objects.length; i < l; i++) {
				objects[i].setRotationFromQuaternion(BaseComponent._tempThreeJsQuaternion);
			}
		}
		else {
			objects.setRotationFromQuaternion(BaseComponent._tempThreeJsQuaternion);
		}
	}

	// OTHER OBJECT PROPERTIES

	/**
	 * Sets whether or not to use the component in the dynamic environment map. Defaults to false if it isn't used.
	 * @param {THREE.Object3D} object
	 * @param {boolean} enable
	 */
	static useInDynEnvMap(object, enable) {
		if (enable) {
			object.layers.enable(1);
		}
		else {
			object.layers.disable(1);
		}
	}

	// GEOMETRY

	/**
	 * Sets the vertices of the given attribute.
	 * @param {THREE.BufferGeometry} geometry
	 * @param {string} name
	 * @param {Float32Array} vertices
	 */
	static setVertices(geometry, name, vertices) {
		const attribute = geometry.getAttribute(name);
		if (attribute instanceof THREE.BufferAttribute) {
			if (attribute.array.length === vertices.length) {
				attribute.copyArray(vertices);
				attribute.needsUpdate = true;
			}
			else {
				geometry.setAttribute(name, new THREE.BufferAttribute(vertices, geometry.getAttribute(name).itemSize));
			}
		}
	}

	/**
	 * Sets the vertices of the given attribute.
	 * @param {THREE.BufferGeometry} geometry
	 * @param {Uint16Array} indices
	 */
	static setIndices(geometry, indices) {
		const attribute = geometry.getIndex();
		if (attribute.array.length === indices.length) {
			attribute.copyArray(indices);
			attribute.needsUpdate = true;
		}
		else {
			geometry.setIndex(new THREE.BufferAttribute(indices, 1));
		}
	}

	/**
	 * Sets the tangent attribute computed from the normal and uv attributes.
	 * @param {THREE.BufferGeometry} geometry
	 */
	static computeTangents(geometry) {
		geometry.computeTangents();
	}

	/**
	 * Sets the render order of the objects.
	 * @param {THREE.Object3D} object
	 * @param {number} renderOrder
	 */
	static setRenderOrder(object, renderOrder) {
		if (!object) {
			return;
		}
		object.renderOrder = renderOrder;
	}

	// MATERIALS & TEXTURES

	/**
	 * Sets up log-depth buffering for the material.
	 * They should already have the proper defines in their vertex and fragment code.
	 * @param {THREE.ShaderMaterial} material
	 */
	static setupLogDepthBuffering(material) {
		if (Capabilities.hasFragDepth()) {
			material.defines['L_EXT_frag_depth'] = true;
			material.extensions.fragDepth = true;
		}
		else {
			delete material.defines['L_EXT_frag_depth'];
		}
		material.needsUpdate = true;
	}

	/**
	 * Loads a texture.
	 * @param {BaseComponent} component
	 * @param {string} url
	 * @param {boolean} useMipMaps
	 * @param {boolean} useCompression
	 * @returns {Promise<THREE.Texture>}
	 */
	static loadTexture(component, url, useMipMaps, useCompression) {
		const engine = component.getEntity().getScene().getEngine();
		const textureLoader = useCompression ? engine.getTextureLoaderCompressed() : engine.getTextureLoader();
		return new Promise((resolve, reject) => {
			textureLoader.load(url, (texture) => {
				resolve(texture);
			}, undefined, (message) => {
				reject(new Error(`Failed to load ${url}: ${message}`));
			}, -component.getEntity().getLeastCameraDepth(), useMipMaps);
		});
	}

	/**
	 * Loads a texture from a canvas element. The canvas needs to be power of 2.
	 * @param {HTMLCanvasElement} canvas
	 * @returns {THREE.Texture}
	 */
	static loadTextureFromCanvas(canvas) {
		const texture = new THREE.CanvasTexture(canvas);
		texture.magFilter = THREE.NearestFilter;
		texture.minFilter = THREE.NearestFilter;
		texture.flipY = false;
		texture.needsUpdate = true;
		return texture;
	}

	/**
	 * Loads a texture.
	 * @param {BaseComponent} component
	 * @param {THREE.IUniform<THREE.Texture>} uniform
	 * @param {string} url
	 * @param {boolean} useMipMaps
	 * @param {boolean} useCompression
	 * @returns {Promise<void>}
	 */
	static async loadTextureIntoUniform(component, uniform, url, useMipMaps, useCompression) {
		const texture = await this.loadTexture(component, url, useMipMaps, useCompression);
		if (uniform.value) {
			ThreeJsHelper.destroyTexture(uniform.value);
		}
		uniform.value = texture;
	}

	/**
	 * Sets whether or not the material is an overlay. If true, it will be rendered on top of the scene with no scene occlusion.
	 * @param {THREE.ShaderMaterial} material
	 * @param {boolean} overlay
	 */
	static setOverlay(material, overlay) {
		if (!material) {
			return;
		}
		material.depthFunc = (overlay ? THREE.AlwaysDepth : THREE.LessEqualDepth);
	}

	/**
	 * Sets the uniform on the material.
	 * @param {THREE.ShaderMaterial} material
	 * @param {boolean} enabled
	 */
	static setTransparent(material, enabled) {
		if (!material) {
			return;
		}
		material.transparent = enabled;
		material.depthWrite = !enabled;
	}

	/**
	 * Sets blending mode. Defaults to 'normal'.
	 * @param {THREE.ShaderMaterial} material
	 * @param {string} blending - one of 'normal', 'additive', 'subtractive', 'multliply', 'custom', or 'none'
	 */
	static setBlending(material, blending) {
		if (!material) {
			return;
		}
		switch (blending) {
			case 'normal':
				material.blending = THREE.NormalBlending;
				break;
			case 'additive':
				material.blending = THREE.AdditiveBlending;
				break;
			case 'subtractive':
				material.blending = THREE.SubtractiveBlending;
				break;
			case 'multiply':
				material.blending = THREE.MultiplyBlending;
				break;
			case 'custom':
				material.blending = THREE.CustomBlending;
				break;
			default:
				material.blending = THREE.NoBlending;
				break;
		}
		material.needsUpdate = true;
	}

	/**
	 * Sets the define on the material.
	 * @param {THREE.ShaderMaterial} material
	 * @param {string} name
	 * @param {boolean} enabled
	 */
	static setDefine(material, name, enabled) {
		if (enabled && !material.defines[name]) {
			material.defines[name] = true;
			material.needsUpdate = true;
		}
		else if (!enabled && material.defines[name] === true) {
			delete material.defines[name];
			material.needsUpdate = true;
		}
	}

	// UNIFORMS

	/**
	 * Sets the uniform on the material.
	 * @param {THREE.ShaderMaterial} material
	 * @param {string} name
	 * @param {number} value
	 */
	static setUniformNumber(material, name, value) {
		if (!material) {
			return;
		}
		material.uniforms[name].value = value;
	}

	/**
	 * Sets the uniform on the material.
	 * @param {THREE.ShaderMaterial} material
	 * @param {string} name
	 * @param {Vector2} value
	 */
	static setUniformVector2(material, name, value) {
		if (!material) {
			return;
		}
		material.uniforms[name].value.set(value.x, value.y);
	}

	/**
	 * Sets the uniform on the material.
	 * @param {THREE.ShaderMaterial} material
	 * @param {string} name
	 * @param {Vector3} value
	 */
	static setUniformVector3(material, name, value) {
		if (!material) {
			return;
		}
		material.uniforms[name].value.set(value.x, value.y, value.z);
	}

	/**
	 * Sets the uniform on the material.
	 * @param {THREE.ShaderMaterial} material
	 * @param {string} name
	 * @param {Color} value
	 */
	static setUniformColorRGB(material, name, value) {
		if (!material) {
			return;
		}
		material.uniforms[name].value.set(value.r, value.g, value.b);
	}

	/**
	 * Sets the uniform on the material.
	 * The alphaMultipier is multiplied onto the alpha component (defaults to 1).
	 * @param {THREE.ShaderMaterial} material
	 * @param {string} name
	 * @param {Color} value
	 * @param {number} [alphaMultiplier=1]
	 */
	static setUniformColorRGBA(material, name, value, alphaMultiplier = 1) {
		if (!material) {
			return;
		}
		material.uniforms[name].value.set(value.r, value.g, value.b, value.a * alphaMultiplier);
	}

	/**
	 * Sets the uniform on the material.
	 * @param {THREE.ShaderMaterial} material
	 * @param {string} name
	 * @param {Quaternion} value
	 */
	static setUniformQuaternion(material, name, value) {
		if (!material) {
			return;
		}
		material.uniforms[name].value.set(value.x, value.y, value.z, value.w);
	}

	/**
	 * Sets the uniform on the material.
	 * @param {THREE.ShaderMaterial} material
	 * @param {string} uniformName
	 * @param {THREE.Texture} value
	 */
	static setUniformTexture(material, uniformName, value) {
		if (!material) {
			return;
		}
		if (material.uniforms[uniformName].value) {
			ThreeJsHelper.destroyTexture(material.uniforms[uniformName].value);
		}
		material.uniforms[uniformName].value = value;
	}
}

/**
 * A temporary Three.js Vector3.
 * @type {THREE.Vector3}
 */
ThreeJsHelper._tempThreeJsVector3 = new THREE.Vector3();
