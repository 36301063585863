/** @module pioneer */
import {
	FastIterable
} from '../internal';

/**
 * A set of values, with the same functions as the standard Set object, except for iteration.
 * It has getAt(index) and size for iteration.
 * It's O(n) and garbage for removes, O(1) and no garbage for adds and iteration.
 * @template ValueType
 * @extends {FastIterable<ValueType>}
 */
export class FastSet extends FastIterable {
	/**
	 * Constructor.
	 * @param {Iterable<ValueType>} [iterable]
	 */
	constructor(iterable) {
		super();

		/**
		 * The list of values.
		 * @type {Array<ValueType>}
		 * @private
		 */
		this._values = [];

		/**
		 * A mapping from values to indices.
		 * @type {Map<ValueType, number>}
		 * @private
		 */
		this._valueMap = new Map();

		// Apply the iterable if it is supplied.
		if (iterable !== undefined) {
			for (const key of iterable) {
				this._values.push(key);
				this._valueMap.set(key, this._values.length - 1);
			}
		}
	}

	/**
	 * Returns true if the value is in the set.
	 * @param {ValueType} value
	 * @returns {boolean}
	 */
	has(value) {
		return this._valueMap.has(value);
	}

	/**
	 * Adds the value.
	 * @param {ValueType} value
	 * @returns {FastSet<ValueType>}
	 */
	add(value) {
		const index = this._valueMap.get(value);
		if (index === undefined) {
			this._values.push(value);
			this._valueMap.set(value, this._values.length - 1);
		}
		return this;
	}

	/**
	 * Deletes the value. Returns true if the value existed.
	 * @param {ValueType} value
	 * @returns {boolean}
	 */
	delete(value) {
		const index = this._valueMap.get(value);
		if (index !== undefined) {
			this._values.splice(index, 1);
			this._valueMap.delete(value);
			// Every index higher than the one deleted gets decremented by one. O(n) and generates garbage.
			for (const pair of this._valueMap) {
				if (pair[1] > index) {
					this._valueMap.set(pair[0], pair[1] - 1);
				}
			}
			return true;
		}
		else {
			return false;
		}
	}

	/**
	 * Deletes all of the keys.
	 */
	clear() {
		this._values = [];
		this._valueMap.clear();
	}

	/**
	 * Gets the value of the given index.
	 * @param {number} index
	 * @returns {ValueType}
	 * @override
	 */
	getAt(index) {
		return this._values[index];
	}

	/**
	 * Gets the number of values.
	 * @returns {number}
	 * @override
	 */
	get size() {
		return this._values.length;
	}
}
