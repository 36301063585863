/** @module pioneer */

export class Sort {
	/**
	 * Gets the least index whose value is greater than or equal to the value.
	 * 1.5 on [0, 1, 2, 3] will return 2, 5.5 will return 4, and -4 will return 0.
	 * O(log n).
	 * @template ArrayType
	 * @template ValueType
	 * @param {ValueType} value
	 * @param {ArrayType[]} array
	 * @param {CompareFunction<ArrayType, ValueType>} [isLess]
	 * @returns {number}
	 */
	static getIndex(value, array, isLess) {
		let low = 0;
		let high = array.length;
		if (isLess === undefined) {
			while (low < high) {
				const mid = (low + high) >>> 1;
				// @ts-ignore
				if (array[mid] < value) {
					low = mid + 1;
				}
				else {
					high = mid;
				}
			}
		}
		else {
			while (low < high) {
				const mid = (low + high) >>> 1;
				if (isLess(array[mid], value)) {
					low = mid + 1;
				}
				else {
					high = mid;
				}
			}
		}
		return low;
	}

	/**
	 * Adds the value into a sorted array.
	 * @template ArrayType
	 * @param {ArrayType} value
	 * @param {ArrayType[]} array
	 * @param {CompareFunction<ArrayType, ArrayType>} [isLess]
	 * @param {CompareFunction<ArrayType, ArrayType>} [isEqual]
	 */
	static add(value, array, isLess, isEqual) {
		let index = this.getIndex(value, array, isLess);
		if (isEqual === undefined) {
			while (index < array.length && array[index] === value) {
				index += 1;
			}
		}
		else {
			while (index < array.length && isEqual(array[index], value)) {
				index += 1;
			}
		}
		array.splice(index, 0, value);
	}

	/**
	 * Removes the value from a sorted array. Returns true if the value was found.
	 * @template ArrayType
	 * @template ValueType
	 * @param {ValueType} value
	 * @param {ArrayType[]} array
	 * @param {CompareFunction<ArrayType, ValueType>} [isLess]
	 * @param {CompareFunction<ArrayType, ValueType>} [isEqual]
	 */
	static remove(value, array, isLess, isEqual) {
		const index = this.getIndex(value, array, isLess);
		let isFound = false;
		if (index < array.length) {
			// @ts-ignore
			if (isEqual === undefined && array[index] === value) {
				isFound = true;
			}
			else if (isEqual !== undefined && isEqual(array[index], value)) {
				isFound = true;
			}
		}
		if (isFound) {
			array.splice(index, 1);
		}
		return isFound;
	}
}

/**
 * An compare function that uses two types.
 * @template LHSType
 * @template RHSType
 * @callback CompareFunction
 * @param {LHSType} a
 * @param {RHSType} b
 * @returns {boolean}
 */
