/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';

/**
 * Helpful functions for placing markers on a spheroid.
 * @hideconstructor
 */
export class Placemarks {

	/**
	 * Adds a placemark on an entity and returns it.
	 * @param {string} name - The name of the placemark entity.
	 * @param {string} label - The label to be displayed.
	 * @param {Pioneer.BaseComponent} ground - The ground component to which to clamp.
	 * @param {number} latInDeg - The latitude in degrees.
	 * @param {number} lonInDeg - The longitude in degrees.
	 * @param {number} heightAboveGround - The height above the ground in km.
	 * @returns {Pioneer.Entity}
	 */
	static addPlacemark(name, label, ground, latInDeg, lonInDeg, heightAboveGround) {

		// Create the entity.
		const parent = ground.getEntity();
		const placemark = parent.getScene().addEntity(name);
		placemark.setParent(parent);
		placemark.setCanOcclude(false);
		placemark.setExtentsRadius(0.001);

		// Create the label.
		const divComponent = placemark.addComponentByClass(Pioneer.DivComponent);
		const div = divComponent.getDiv();
		div.innerHTML = label;
		div.classList.add('pioneer-label-div');

		// Add the controllers to keep it in place and relative to the ground.
		placemark.addControllerByClass(Pioneer.FixedController);
		placemark.addControllerByClass(Pioneer.RotateByEntityOrientationController);
		placemark.addControllerByClass(Pioneer.GroundClampController);

		// Update the controllers to set the location.
		this.updateLLA(placemark, ground, latInDeg, lonInDeg, heightAboveGround);

		return placemark;
	}

	/**
	 * Updates a placemark with a new location.
	 * @param {Pioneer.Entity} placemark - The placemark to change.
	 * @param {Pioneer.BaseComponent} ground - The ground component to which to clamp.
	 * @param {number} latInDeg - The latitude in degrees.
	 * @param {number} lonInDeg - The longitude in degrees.
	 * @param {number} heightAboveGround - The height above the ground in km.
	 */
	static updateLLA(placemark, ground, latInDeg, lonInDeg, heightAboveGround) {

		// Get the needed components and controllers.
		const parent = placemark.getParent();
		const fixed = placemark.getControllerByClass(Pioneer.FixedController);
		const groundClamp = placemark.getControllerByClass(Pioneer.GroundClampController);
		if (fixed === null || groundClamp === null) {
			throw new Error('There must be fixed and ground clamp controllers.');
		}
		const spheroid = parent.getComponentByClass(Pioneer.SpheroidComponent);
		if (spheroid === null) {
			throw new Error('There must be a spheroid for the placemark\'s parent.');
		}
		if (ground.getEntity() !== parent) {
			throw new Error('The ground must belong to the placemark\'s parent.');
		}

		// Update the position using the given lat and lon using the spheroid.
		const lla = Pioneer.LatLonAlt.pool.get();
		const markPos = Pioneer.Vector3.pool.get();
		lla.set(Pioneer.MathUtils.degToRad(latInDeg), Pioneer.MathUtils.degToRad(lonInDeg), 0);
		spheroid.xyzFromLLA(markPos, lla);
		fixed.setPosition(markPos);
		Pioneer.Vector3.pool.release(markPos);

		// Update the orientation so that it uses the east and north of the location on the spheroid.
		const markOri = Pioneer.Quaternion.pool.get();
		const east = Pioneer.Vector3.pool.get();
		const north = Pioneer.Vector3.pool.get();
		spheroid.eastFromLLA(east, lla);
		spheroid.northFromLLA(north, lla);
		markOri.setFromAxes(east, north, undefined);
		fixed.setOrientation(markOri);
		Pioneer.Vector3.pool.release(east);
		Pioneer.Vector3.pool.release(north);
		Pioneer.Quaternion.pool.release(markOri);
		Pioneer.LatLonAlt.pool.release(lla);

		// Update the height above the ground.
		groundClamp.setGroundComponentRef(parent.getName(), ground.getType(), ground.getTypeIndex());
		groundClamp.setDistanceFromGround(heightAboveGround);
	}
};
