/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';

/**
 * The Celestial Grid component.
 * */
export class CelestialGridComponent extends Pioneer.BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Pioneer.Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The number of degrees per line.
		 * @type {number}
		 * @private
		 */
		this._degreesPerLine = 10;

		/**
		 * The color of the grid.
		 * @type {Pioneer.Color}
		 * @private
		 */
		this._color = new Pioneer.Color(1, 1, 1, 1);
		this._color.freeze();

		/**
		 * The LineMesh object used to do the drawing.
		 * @type {Pioneer.LineMesh}
		 * @private
		 */
		this._lineMesh = null;

		/**
		 * True if the mesh needs to be updated.
		 * @type {boolean}
		 * @private
		 */
		this._meshDirty = true;
	}

	/**
	 * Sets the radius of the grid.
	 * @param {number} radius
	 */
	setRadius(radius) {
		this.__setRadius(radius);
		Pioneer.ThreeJsHelper.setScale(this.getThreeJsObjects(), this.getRadius());
	}

	/**
	 * Gets the color of the grid. Defaults to white.
	 * @returns {Pioneer.Color}
	 */
	getColor() {
		return this._color;
	}

	/**
	 * Sets the color of the grid.
	 * @param {Pioneer.Color} color
	 */
	setColor(color) {
		this._color.thaw();
		this._color.copy(color);
		this._color.freeze();
		this._meshDirty = true;
	}

	/**
	 * Gets the number of degrees per line. Defaults to 10.
	 * @returns {number}
	 */
	getDegreesPerLine() {
		return this._degreesPerLine;
	}

	/**
	 * Sets the number of degrees per line.
	 * @param {number} degreesPerLine
	 */
	setDegreesPerLine(degreesPerLine) {
		this._degreesPerLine = degreesPerLine;
		this._meshDirty = true;
	}

	/**
	 * Prepare the component for rendering.
	 * @param {Pioneer.CameraComponent} camera
	 * @override
	 * @package
	 */
	__prepareForRender(camera) {
		if (this._meshDirty) {
			this._updateMesh();
		}

		// Set the Three.js object position the entity's camera-space position.
		Pioneer.ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects(), this.getEntity(), camera);

		// Call the line mesh prepare for render.
		this._lineMesh.prepareForRender(camera);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	__loadResources() {
		this._lineMesh = new Pioneer.LineMesh(this);
		this._updateMesh();

		return Promise.resolve();
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		Pioneer.ThreeJsHelper.destroyAllObjectsAndMaterials(this);
		this._lineMesh = null;
	}

	/**
	 * Updates the mesh positions and colors.
	 * @private
	 */
	_updateMesh() {
		const positions = [];
		for (let lon0 = 0; lon0 < 360; lon0 += this._degreesPerLine) {
			const lon1 = Math.min(lon0 + this._degreesPerLine, 360);
			const lon0Rad = Pioneer.MathUtils.degToRad(lon0);
			const lon1Rad = Pioneer.MathUtils.degToRad(lon1);
			for (let lat0 = -90; lat0 < 90; lat0 += this._degreesPerLine) {
				const lat1 = Math.min(lat0 + this._degreesPerLine, 90);
				const lat0Rad = Pioneer.MathUtils.degToRad(lat0);
				const lat1Rad = Pioneer.MathUtils.degToRad(lat1);
				// Make a horizontal line.
				if (-90 < lat0 && lat0 < 90) {
					positions.push(new Pioneer.Vector3(Math.sin(lon0Rad) * Math.cos(lat0Rad), Math.cos(lon0Rad) * Math.cos(lat0Rad), Math.sin(lat0Rad)));
					positions.push(new Pioneer.Vector3(Math.sin(lon1Rad) * Math.cos(lat0Rad), Math.cos(lon1Rad) * Math.cos(lat0Rad), Math.sin(lat0Rad)));
				}
				// Make a vertical line.
				positions.push(new Pioneer.Vector3(Math.sin(lon0Rad) * Math.cos(lat0Rad), Math.cos(lon0Rad) * Math.cos(lat0Rad), Math.sin(lat0Rad)));
				positions.push(new Pioneer.Vector3(Math.sin(lon0Rad) * Math.cos(lat1Rad), Math.cos(lon0Rad) * Math.cos(lat1Rad), Math.sin(lat1Rad)));
			}
		}
		const colors = [];
		for (let i = 0, l = positions.length; i < l; i++) {
			colors.push(this._color);
		}
		const widths = [];
		for (let i = 0, l = positions.length; i < l; i++) {
			widths.push(2);
		}
		this._lineMesh.setPositions(positions);
		this._lineMesh.setColors(colors);
		this._lineMesh.setWidths(widths);

		// Set the Three.js object orientation to the identity.
		Pioneer.ThreeJsHelper.setOrientation(this.getThreeJsObjects(), Pioneer.Quaternion.Identity);

		// Set the Three.js object scale to be the radius of the component.
		Pioneer.ThreeJsHelper.setScale(this.getThreeJsObjects(), this.getRadius());

		this._meshDirty = false;
	}
}
