/** @module pioneer */
import {
	BaseController,
	Entity,
	EntityRef,
	MathUtils,
	Quaternion,
	SetParentController,
	Vector3
} from '../../internal';

/**
 * @callback TransitionFunction
 * @param {Entity} entity
 * @param {Vector3} initialPosition
 * @param {Vector3} finalPosition
 * @param {Quaternion} initialOrientation
 * @param {Quaternion} finalOrientation
 * @param {number} u
 */

/**
 * A transition controller.
 */
export class TransitionController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * @type {Vector3}
		 * @private
		 */
		this._initialPosition = new Vector3();
		this._initialPosition.copy(entity.getPosition());

		/**
		 * @type {Quaternion}
		 * @private
		 */
		this._initialOrientation = new Quaternion();
		this._initialOrientation.copy(entity.getOrientation());

		/**
		 * @type {EntityRef}
		 * @private
		 */
		this._initialParent = new EntityRef(this.getEntity().getScene());

		/**
		 * @type {number}
		 * @private
		 */
		this._transitionStart = Number.NaN;

		/**
		 * @type {number}
		 * @private
		 */
		this._transitionTime = 1;

		/**
		 * @type {TransitionFunction}
		 * @private
		 */
		this._transitionFunction = this._lerpTransitionFunction;

		/**
		 * The resolve function that will be called when the transition completes.
		 * @type {function():void}
		 * @private
		 */
		this._resolvePromise = null;

		/**
		 * The reject function that will be called if the endPromise rejects.
		 * @type {function(string):void}
		 * @private
		 */
		this._rejectPromise = null;

		/**
		 * The promise that resolves at the end of the transition.
		 * @type {Promise<void>}
		 * @private
		 */
		this._endPromise = new Promise((resolve, reject) => {
			this._resolvePromise = resolve;
			this._rejectPromise = reject;
		});

		// Set the initial parent to the current parent.
		const parent = this.getEntity().getParent();
		if (parent !== null) {
			this._initialParent.setName(parent.getName());
		}

		// If there is another transition controller already, destroy it.
		const existingController = this.getEntity().getControllerByType('transition');
		if (existingController !== null) {
			this.getEntity().removeController(existingController);
		}

		// Add a beginning setParent controller, since the controllers before this one expect to be in the destination parent frame.
		const setParentController = /** @type {SetParentController} */(this.getEntity().addController('setParent', 'transitionSetParent', this.getEntity().getController(0)));
		if (parent !== null) {
			setParentController.setParent(parent.getName());
		}
	}

	/**
	 * Gets the transition time in seconds.
	 * @returns {number}
	 */
	getTransitionTime() {
		return this._transitionTime;
	}

	/**
	 * Sets the transition time in seconds.
	 * @param {number} transitionTime
	 */
	setTransitionTime(transitionTime) {
		this._transitionTime = transitionTime;
	}

	/**
	 * Sets the transition function.
	 * @param {TransitionFunction} transitionFunction
	 */
	setTransitionFunction(transitionFunction) {
		this._transitionFunction = transitionFunction;
	}

	/**
	 * Gets the parent to transition to.
	 * @returns {string}
	 */
	getParent() {
		const setParentController = /** @type {SetParentController} */(this.getEntity().getController('transitionSetParent'));
		if (setParentController !== null) {
			return setParentController.getName();
		}
		else {
			const parent = this.getEntity().getParent();
			if (parent !== null) {
				return parent.getName();
			}
			return null;
		}
	}

	/**
	 * Sets the parent to transition to.
	 * @param {string} parent
	 */
	setParent(parent) {
		const setParentController = /** @type {SetParentController} */(this.getEntity().getController('transitionSetParent'));
		if (setParentController !== null) {
			setParentController.setParent(parent);
		}
	}

	/**
	 * Gets the promise that resolves at the end of the transition.
	 * On a failure, the reject function takes a string describing what went wrong.
	 * @returns {Promise<void>}
	 */
	getEndPromise() {
		return this._endPromise;
	}

	/**
	 * Destroys the controller resources.
	 * @override
	 * @internal
	 */
	__destroy() {
		super.__destroy();

		// Remove the setParent controller.
		const setParentController = this.getEntity().getController('transitionSetParent');
		if (setParentController !== null) {
			this.getEntity().removeController(setParentController);
		}

		// Reject if the transition controller completed early.
		if (this._transitionTime !== 0) {
			this._rejectPromise('Transition controller was destroyed before completing.');
		}
	}

	/**
	 * Updates the controller.
	 * @override
	 * @internal
	 */
	__update() {
		// When this update runs, the previous controllers in the entity controller list have updated themselves,
		// including the first setParent controller created by this. Now we just interpolate between the
		// initialPosition/Orientation variables and the entity's updated position and orientation.

		// Get the initial parent and final parent.
		const initialParent = this._initialParent.get();
		const finalParent = this.getEntity().getParent();

		// There's no final parent any more, so we error.
		if (finalParent === null) {
			this._transitionTime = 0;
			this.getEntity().removeController(this);
			this._rejectPromise('The final parent was destroyed or disabled before the transition could complete.');
		}

		// Set the start time for the transition on the first update.
		if (Number.isNaN(this._transitionStart)) {
			this._transitionStart = Date.now() / 1000.0;
		}

		// If we're before the half-way point or just went over it this frame.
		const setParentController = /** @type {SetParentController} */(this.getEntity().getController('transitionSetParent'));
		if (setParentController !== null) {
			// If there's no initial parent,
			if (initialParent === null) {
				this.getEntity().getScene().getEngine().addCallback(() => {
					this._transitionTime = 0;
					this.getEntity().removeController(this);
					if (this._initialParent.getName() !== '') { // There's supposed to be an initial parent, but no more.
						this._rejectPromise(`The initial parent "${this._initialParent.getName()}" was destroyed or disabled before the first half of the transition could complete.`);
					}
					else { // Never was an initial parent, so just finish the transition.
						this._resolvePromise();
					}
				}, false);
				return;
			}
			// If we're over the half-way point, remove the setParent controller and adjust the frame of the initial position.
			if (Date.now() / 1000.0 - this._transitionStart >= this._transitionTime / 2.0 || initialParent === finalParent) {
				// Remove the setParent controller.
				this.getEntity().removeController(setParentController);
				// Change the initial position to be in the frame of the final parent.
				if (initialParent !== finalParent && initialParent !== null && finalParent !== null) {
					initialParent.getPositionRelativeToEntity(this._initialPosition, this._initialPosition, finalParent);
				}
			}
			// We're before the half-way point and at a different parent, so switch to the initial parent.
			else {
				this.getEntity().setParent(initialParent);
			}
		}

		// Get the final position and orientation.
		const finalPosition = this.getEntity().getPosition();
		const finalOrientation = this.getEntity().getOrientation();

		// Do the transition.
		let u = MathUtils.clamp01((Date.now() / 1000.0 - this._transitionStart) / this._transitionTime);
		if (Number.isNaN(u)) {
			u = 1.0;
		}
		this._transitionFunction(this.getEntity(), this._initialPosition, finalPosition, this._initialOrientation, finalOrientation, u);

		// If we've passed the transition time, clean it all up.
		if (Date.now() / 1000.0 - this._transitionStart >= this._transitionTime) {
			this.getEntity().getScene().getEngine().addCallback(() => {
				this._transitionTime = 0;
				this.getEntity().removeController(this);
				this._resolvePromise();
			}, false);
		}
	}

	/**
	 * A default function that lerps between positions and slerps between orientations.
	 * @param {Entity} entity
	 * @param {Vector3} initialPosition
	 * @param {Vector3} finalPosition
	 * @param {Quaternion} initialOrientation
	 * @param {Quaternion} finalOrientation
	 * @param {number} u
	 */
	_lerpTransitionFunction(entity, initialPosition, finalPosition, initialOrientation, finalOrientation, u) {
		const position = Vector3.pool.get();
		const orientation = Quaternion.pool.get();
		position.lerp(initialPosition, finalPosition, u);
		orientation.slerp(initialOrientation, finalOrientation, u);

		// Set the new position and orientation.
		entity.setPosition(position);
		entity.setOrientation(orientation);
		Vector3.pool.release(position);
		Quaternion.pool.release(orientation);
	}
}
