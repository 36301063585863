import { ThreeJsHelper } from 'pioneer';

import { Config } from '../config/config';
import { MAX_WMTS_FRAMES, MAX_DEEP_CHECKS, WMTS_MAX_LEVEL_AUTOPLAY, WMTS_MAX_LEVEL } from '../config/constants';
import { calcPrefixFromDate, cloneDate, dateToUTCString, getLatestDates, getDateFromUTCDateStr, subtractDaysFromDate, localDateToNoonUTC, getWebpOrPngExt } from '../helpers/tools';
import { cameraStore, datasetStore, kioskStore, layersStore, uiStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';
import { SceneHelpers } from 'pioneer-scripts';
import { hideLoading, showLoading } from '../components/loading';
import { resetModal } from '../components/modal';


/**
 * Threshold for max blank area in the image
 */
const MAX_BLANK_AREA_PCT = 5;

/**
 * Storage for valid image states by date and wmts id
 */
const WMTS_IMAGE_VALID_STATE = {};

/**
 * WMTS server state for snpp and noaa20
 */
const WMTS_SERVER_STATE = {};

// DS: finding the most optimal image size.
// 2048 x 1024: 39% empty - 100% accuracy - takes 67s
// 1024 x 512: 37% empty - 95% accuracy - takes 19s
// 512 x 256: 35% empty - 89% accuracy - takes 4s
// 256 x 128: 34% empty - 85% accuracy - takes 2.5s - most optimal
// 128 x 64: 23% empty - 58% accuracy - takes 2.2s
// 64 x 32: 22% empty - 57% accuracy - takes 2s

const WIDTH = 256;
const HEIGHT = 128;
const FALLBACKS = [
	// SNPP version
	{
		type: 'wmts',
		id: 'viirs_snpp',
		title: "سومي إن بي بي (Suomi NPP ) / فيرس (VIIRS) ",
		endpoint: 'assets/wmts_xml_snpp',
		layer: 'VIIRS_SNPP_CorrectedReflectance_TrueColor',
		imageCheck: 'https://gitc.earthdata.nasa.gov/wmts/epsg4326/best/VIIRS_SNPP_CorrectedReflectance_TrueColor/default/',
		template: `https://wvs.earthdata.nasa.gov/api/v1/snapshot?REQUEST=GetSnapshot&LAYERS=VIIRS_SNPP_CorrectedReflectance_TrueColor&CRS=EPSG:4326&TIME={YYYY-MM-DD}&WRAP=DAY&BBOX=-90,-180,90,180&FORMAT=image/jpeg&WIDTH=${WIDTH}&HEIGHT=${HEIGHT}&AUTOSCALE=TRUE`
	},
	// NOAA20 version
	{
		type: 'wmts',
		id: 'viirs_noaa20',
		title: 'NOAA-20 / VIIRS Daily Mosaics',
		endpoint: 'assets/wmts_xml_noaa',
		layer: 'VIIRS_NOAA20_CorrectedReflectance_TrueColor',
		imageCheck: 'https://gitc.earthdata.nasa.gov/wmts/epsg4326/best/VIIRS_NOAA20_CorrectedReflectance_TrueColor/default/',
		template: `https://wvs.earthdata.nasa.gov/api/v1/snapshot?REQUEST=GetSnapshot&LAYERS=VIIRS_NOAA20_CorrectedReflectance_TrueColor&CRS=EPSG:4326&TIME={YYYY-MM-DD}&WRAP=DAY&BBOX=-90,-180,90,180&FORMAT=image/jpeg&WIDTH=${WIDTH}&HEIGHT=${HEIGHT}&AUTOSCALE=TRUE`
	},
	// cubemap fallback
	{
		type: 'cube'
	}
];


class VisibleEarthManager {
	/**
	 * Constructor
	 * @param {object} pioneer Pioneer engine
	 * @param {object} scene Pioneer main scene
	 * @private
	 */
	constructor(pioneer) {
		this._pioneer = pioneer;
		this._scene = pioneer.get('main');

		this._tileMatrixSet = '250m';

		this.numOfFrames = 7;

		this.currentData = null;

		this._readyToShow = null;

		this._loadingCurrentData = null;

		this._allowHiRes = true;

		this.earth = this._scene.get('earth');

		this.todayRounded = getDateFromUTCDateStr((new Date()).toISOString());

		this.updateWMTSMaxLevel = this.updateWMTSMaxLevel.bind(this);
	}

	/**
	 * Init
	 */
	init() {
		// Subscribe to currentIndex changes.
		datasetStore.subscribeTo('currentIndex', currentIndex => {
			const { isVisibleEarth } = datasetStore.stateSnapshot;
			if (isVisibleEarth) {
				this.updateVETextures(currentIndex ?? 0);
				this.setTitle();
			}
		}, true);

		// Subscribe is blueMarble state.
		layersStore.subscribeTo('blueMarble', blueMarble => {
			const showClouds = false;
			this.setBlueMarble(blueMarble, showClouds);

			if (!blueMarble) {
				this.updateVETextures(datasetStore.stateSnapshot.currentIndex ?? 0);
			}
		});

		/**
		 * Subscribe to loading.
		 * If we're loading an event or a 3d model, we should prevent WMTS hires to improve loading proirity.
		 */
		uiStore.subscribeTo('loading', loading => {
			// const wmts = this.earth.get('wmts');
			const eventOrModel = loading === 'event' || loading === 'model';
			if (eventOrModel) {
				this._allowHiRes = false;
				this.updateWMTSMaxLevel();
			} else if (!loading && !this._allowHiRes) {
				this._allowHiRes = true;
				this.updateWMTSMaxLevel();
			}
		});

		// updateWMTSMaxLevel at end of camera transitions.
		cameraStore.subscribeTo('isCameraTransitioning', isCameraTransitioning => isCameraTransitioning === false && this.updateWMTSMaxLevel());
	}

	/**
	 * Downloads an image and checks the blank area percentage lies within the percent threshold (MAX_BLANK_AREA_PCT).
	 * If error, or blank area percentage is too high, we don't store anything and try again next time.
	 * If valid, store as true so we don't have to check next time (until we navigate away / destroy the VE textures)
	 * @param {object} params
	 * @returns {Promise<boolean>}
	 */
	validateImageData({ url, currDate, id }) {
		const currDateStr = dateToUTCString(currDate);

		if (WMTS_IMAGE_VALID_STATE[currDateStr]?.[id]) {
			return Promise.resolve(true);
		}

		return (new Promise((resolve, reject) => {
			// Create a new image element.
			const img = new Image();

			// Create load and error handlers.
			const onLoad = () => {
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');

				canvas.width = img.width;
				canvas.height = img.height;

				ctx.drawImage(img, 0, 0);

				// getImageData method can potentially throw a security error
				// This can happen if the image is loaded from a different origin and the server doesn't provide the appropriate CORS headers.
				let rgbaData;
				try {
					rgbaData = ctx.getImageData(0, 0, img.width, img.height);
				} catch (e) {
					return reject(e);
				}


				const top = HEIGHT - Math.max(0, 10 - 19 * Math.cos((currDate.getMonth() + 1) / 6 * Math.PI));
				const bottom = Math.max(0, 10 + 15 * Math.cos((currDate.getMonth() + 1) / 6 * Math.PI));
				const xs = Math.round(bottom) || 0;
				const ys = Math.round(top) || img.height;

				let numOfEmptyPixels = 0;
				let blankAreaPct = 0;

				// This FOR loop goes through single dimension image data array with rgba values which is why we jump 4 elements (r, g, b and a) in the loop
				for (let i = xs * img.width * 4; i < img.width * ys * 4; i += 4) {
					if (rgbaData.data[i + 0] === 0 && rgbaData.data[i + 1] === 0 && rgbaData.data[i + 2] === 0) {
						numOfEmptyPixels += 1;
					} else if (rgbaData.data[i + 0] === undefined || rgbaData.data[i + 1] === undefined || rgbaData.data[i + 2] === undefined) {
						numOfEmptyPixels += 1;
					}

					blankAreaPct = numOfEmptyPixels * 100 / ((ys - xs) * img.width);

					if (blankAreaPct > MAX_BLANK_AREA_PCT) {
						return reject(new Error('Exceeded blank area threshold'));
					}
				}

				// If we get here, we can assume the image is valid.
				if (!WMTS_IMAGE_VALID_STATE[currDateStr]) {
					WMTS_IMAGE_VALID_STATE[currDateStr] = {};
				}
				WMTS_IMAGE_VALID_STATE[currDateStr][id] = true;
				resolve(true);
			};

			// Add load and error event listeners.
			img.addEventListener('load', onLoad);
			img.addEventListener('error', e => reject(e));

			// Set crossOrigin and src.
			img.crossOrigin = 'Anonymous';

			img.src = url;
		}))
			.catch(error => {
				console.warn(`Error validating ${id} on date ${currDateStr}. Error:`, error);
				return false;
			});
	}

	setupWMTS(key, data) {
		const { date, layer, endpoint, minLevel } = data;

		// insufficient data
		if (!date || !layer || !endpoint || !key) {
			return null;
		}

		const existingComponent = this.earth.getComponent(key);
		const wmts = existingComponent || this.earth.addComponent('wmts', key);

		// Set layer (important to do this first), then endpoint and tile matrix set, dimension value and min level.
		wmts.setLayer(layer);
		wmts.setEndPoint(endpoint);
		wmts.setTileMatrixSet(this._tileMatrixSet);
		wmts.setDimensionValue('Time', date);
		wmts.setMinLevel(minLevel);

		wmts.setVisible(false);


		return wmts;
	}

	setupCubemap(lodName, cubeTexturePath) {
		const existingComponent = this.earth.getComponent(lodName);
		const setTexture = !existingComponent || existingComponent?.getTextureUrl('color') !== cubeTexturePath;

		// Determine the LOD component.
		const lod = existingComponent || this.earth.addComponent('spheroidLOD', lodName);

		// Set the texture.
		setTexture && lod.setTexture('color', cubeTexturePath);

		// Make sure it's enabled but not visible.
		lod.setEnabled(true);
		lod.setVisible(false);

		// Set layout and mapping if not already set.
		if (!existingComponent) {
			lod.setCubeMapLayout([
				['+z', '+x', '+y'],
				['+z', '+x', '-y'],
				['-y', '+x', '-z'],
				['-y', '+x', '+z'],
				['-y', '+z', '-x'],
				['-y', '+z', '+x']
			]);
			lod.setMapping('cube');
		}


		return lod;
	}

	preload() {
		for (let i = 0; i < this.currentData?.length; i += 1) {
			const { type, url: cubeTexturePath } = this.currentData[i];

			if (type === 'wmts') {
				this.setupWMTS(`viirs${i}`, this.currentData[i]);
			} else {
				this.setupCubemap(`cube${i}`, cubeTexturePath);
			}
		}
	}

	/**
	 * Checks the SNPP and NOAA WMTS servers by attempting to download a small image.
	 */
	checkWMTSServer() {
		// These are the default images to check if server is down
		const { imageCheck: urlCheckSNPP } = FALLBACKS.find(({ id }) => id === 'viirs_snpp');
		const { imageCheck: urlCheckNOAA } = FALLBACKS.find(({ id }) => id === 'viirs_noaa20');

		// Don't check again if previously good.
		if (WMTS_SERVER_STATE[urlCheckSNPP] || WMTS_SERVER_STATE[urlCheckNOAA]) {
			return Promise.resolve(true);
		}

		const TIMEOUT = 5000;
		const controller = new AbortController();
		const { signal } = controller;

		let timeoutId;

		const checkImage = url => fetch(url, { signal })
			.then(response => {
				clearTimeout(timeoutId);
				if (!response.ok) {
					throw new Error(response.status);
				}
				const contentType = response.headers.get('content-type');
				if (!contentType || !contentType.startsWith('image/')) {
					throw new Error('Fetched resource is not an image.');
				}
				WMTS_SERVER_STATE[url] = true;
				return true;
			})
			.catch(error => {
				WMTS_SERVER_STATE[url] = false;
				throw error;
			});

		const imageCheckSNPP = checkImage(urlCheckSNPP);
		const imageCheckNOAA = checkImage(urlCheckNOAA);

		const timeout = new Promise((resolve, reject) => {
			timeoutId = setTimeout(() => {
				controller.abort();
				reject(new Error('Request timed out'));
			}, TIMEOUT);
		});

		return Promise.race([imageCheckSNPP, imageCheckNOAA, timeout])
			.catch(error => {
				console.warn('WMTS server appears to be down, falling back to cubemaps. Error:', error);
				return false;
			});
	}

	/**
	 * Get array of available days with dates, types, layers and endpoints
	 */
	async setCurrentData() {
		const { getManager } = globalRefs;
		const { currentDataset } = datasetStore.stateSnapshot;
		const { externalId, title: fallbackTitle } = currentDataset || {};

		/**
		 * The cubemap animations array is calculated in the dataset manager.
		 * By default, these are viirsToday. However, it can also be modisToday or modisAquaToday.
		 * ModisToday and modisAquaToday are specifically chosen datasets by the user and should not be replaced by WMTS.
		 *
		 * It has taken into account start, end and missingDates from the cubemap manifest file.
		 * It is not used for the 1st and 2nd WMTS fallbacks (if date range is 7 days or less).
		 *
		 * If the date range is more than MAX_WMTS_FRAMES (7), we automatically use the viirs cubemaps.
		 *
		 * If less than than the MAX_WMTS_FRAMES (7),
		 * we will deep check the 1st and 2nd WMTS fallbacks (which wont have manifest data restrictions applied)
		 * If there is any missing dates for the WMTS, the viirs cubemaps should fill in the gaps.
		 */
		const {
			animations,
			animationEndDate,
			hasAnimation,
			setIsLatest,
			setIsLatestSeven,
			calcNumFrames,
			getDatasetManifestData,
			setAnimationProps
		} = getManager('dataset');

		/**
		 * There's not a consistently reliable way to check whether the endDate is the latest.
		 * This is fundamentally due to not having a NOAA-20 manifest data file.
		 * For example:
		 * 1) The last Suomi data was 3 days ago
		 * 2) A user enters the end date in the query of 2 days ago.
		 * 3) With this input, we dont do a deep check for 1 day ago,
		 * so we dont know if query input of 2 days ago is the 'latest'
		 *
		 * The only way we know for the 'latest' for certain is if our end date is today.
		 */
		let isLatest = animationEndDate === null;

		// Get number of frames.
		this.numOfFrames = calcNumFrames();

		// Declare framesData var.
		const framesData = [];

		// Whether we found WMTS.
		let foundFirstWMTS = false;

		// Whether we should prioritize the cubemap (modisToday or modisAquaToday) or num of frames is > MAX_WMTS_FRAMES.
		const prioritizeWMTS = !(externalId === 'modisToday' || externalId === 'modisAquaToday' || this.numOfFrames > MAX_WMTS_FRAMES);

		// Go through WMTS and fallbacks if prioritizing WMTS and server is up.
		if (prioritizeWMTS) {
			this.gitcServerIsUp = await this.checkWMTSServer();

			if (this.gitcServerIsUp) {
				const queryEndDate = animationEndDate && cloneDate(animationEndDate);

				const endDate = queryEndDate && !isNaN(queryEndDate.valueOf()) ?
					queryEndDate
					: cloneDate(this.todayRounded);

				// Is endDate today? Update isLatest
				isLatest = localDateToNoonUTC(endDate).valueOf() === localDateToNoonUTC(this.todayRounded).valueOf();

				for (const i of [...Array(MAX_DEEP_CHECKS).keys()]) {
					// End the loop if we reach our num of days requirement.
					if (framesData.length === this.numOfFrames) {
						break;
					}

					const currDate = cloneDate(endDate);
					i > 0 && subtractDaysFromDate(currDate, i);
					const currDateStr = dateToUTCString(currDate);

					for (const fallback of FALLBACKS) {
						const { id, type, template, title, endpoint, layer, minLevel = 2 } = fallback;
						if (type === 'wmts') {
							const url = template.replace('{YYYY-MM-DD}', currDateStr);
							const imageIsValid = await this.validateImageData({ url, currDate, id });

							// If current WMTS is valid and does not have too many empty pixels, we use it and stop checking further
							if (imageIsValid) {
								const info = {
									type,
									title,
									endpoint,
									layer,
									minLevel,
									date: currDateStr,
									dateObject: currDate
								};

								if (!foundFirstWMTS) {
									foundFirstWMTS = true;
									const wmts = this.setupWMTS('viirs0', info);
									wmts.setVisible(true);
								}

								framesData.push(info);
								break;
							}
						} else {
							// Check existing dataset animation data (viirs cubemap)
							const { tilePathPrefix, title: datasetTitle } = animations.find(({ date }) => date === currDate.toISOString()) || {};

							if (tilePathPrefix) {
								const prefix = `${Config.dynamicAssetsUrl}/earth/data/${tilePathPrefix}`;
								const ext = await getWebpOrPngExt(`${prefix}0.webp`);

								framesData.push({
									type,
									title: datasetTitle,
									date: currDateStr,
									dateObject: currDate,
									url: `${prefix}$FACE.${ext}`
								});
								// No break necessary if we're already at the last fallback
							}
						}
					}
				}
			}
		}

		if (framesData.length < this.numOfFrames) {
			const [,, cubeMapFallback] = FALLBACKS;
			const { type } = cubeMapFallback;

			// If there is no framesData, it means we're not using WMTS at all (either non-viirs, or a wider date range).
			if (framesData.length === 0) {
				for (let i = 0; i < animations.length; i += 1) {
					const { date, tilePathPrefix, title: datasetTitle } = animations[i];
					const currDate = getDateFromUTCDateStr(date);
					const prefix = `${Config.dynamicAssetsUrl}/earth/data/${tilePathPrefix}`;
					const ext = await getWebpOrPngExt(`${prefix}0.webp`);

					framesData.push({
						type,
						date: dateToUTCString(currDate),
						title: datasetTitle,
						url: `${prefix}$FACE.${ext}`,
						dateObject: currDate
					});
				}
			} else {
				// We have some frames missing, despite checking <MAX_DEEP_CHECKS> times.
				// Let's get the remaining dates using currentDataset (it will be viirs) cubemap data.
				const { frequency, startDate: earliestDate, endDate: latestDate, missingDates: manifestMissingDates } = getDatasetManifestData() || {};

				// Here we can include the existing framesData into the missingDates arg for getLatestDates.
				const missingDates = [...manifestMissingDates, ...framesData.map(({ date }) => date)];
				const numMissingFrames = this.numOfFrames - framesData.length;

				const requiredDates = getLatestDates({
					frequency,
					startDate: getDateFromUTCDateStr(earliestDate),
					endDate: getDateFromUTCDateStr(latestDate),
					missingDates
				}, numMissingFrames);


				requiredDates.forEach(async dateObject => {
					const prefix = `${Config.dynamicAssetsUrl}/earth/data/viirsToday/${calcPrefixFromDate(dateObject)}`;
					const ext = await getWebpOrPngExt(`${prefix}0.webp`);

					framesData.push({
						type,
						date: dateToUTCString(dateObject),
						title: fallbackTitle,
						url: `${prefix}$FACE.${ext}`,
						dateObject
					});
				});
			}
		}

		// Sort data by ascending date, ie. starting with earliest.
		this.currentData = framesData.sort((a, b) => {
			if (b.dateObject.valueOf() < a.dateObject.valueOf()) {
				return 1;
			}
			if (b.dateObject.valueOf() > a.dateObject.valueOf()) {
				return -1;
			}
			return 0;
		});


		// Update current index and animation props.
		if (this.currentData?.length) {
			this.preload();

			// Update dataset animation props and latest bools.
			setIsLatest(!hasAnimation && isLatest);
			setIsLatestSeven(hasAnimation && isLatest && this.currentData.length === 7);

			setAnimationProps({ animations: this.currentData });
		} else {
			// fallback on blue marble
			layersStore.setGlobalState({ blueMarble: true });
		}
	}

	// async enableWMTS() {
	// 	const promises = [];
	// 	for (let i = 0; i < this.currentData?.length; i++) {
	// 		const { type } = this.currentData[i];
	// 		const wmts = this.earth.getComponent(`viirs${i}`);

	// 		if (type === 'wmts' && wmts) {
	// 			wmts.setEnabled(true);
	// 			wmts.setVisible(false);

	// 			await this._pioneer.waitUntilNextFrame();
	// 			promises.push(wmts.getTilesLoadedPromise());
	// 		}
	// 	}

	// 	return promises;
	// }

	/**
	 * Update the visible earth texture.
	 * We have 3 possible textures:
	 * 1. WMTS
	 * 2. Cubemap (virrs, terra modis, aqua modis)
	 * 3. Blue marble fallback
	 *
	 * Important things to consider when changing the textures:
	 * - Disable and enable will trigger the texture to reload.
	 * - Once each texture is loaded, we should avoid disabling and re-enabling it again.
	 *
	 * @param {number} currentIndex
	 * @returns
	 */
	updateVETextures(currentIndex) {
		if (!this.currentData?.[currentIndex]) {
			return;
		}

		// Set isTextureLoaded global state to false.
		datasetStore.setGlobalState({ isTextureLoaded: false });

		const { type } = this.currentData[currentIndex];
		const componentName = type === 'wmts' ? `viirs${currentIndex}` : `cube${currentIndex}`;
		const component = this.earth.getComponent(componentName);

		// Enable loaded component.
		component?.setEnabled(true);

		// Hide previous textures recursively.
		if (type === 'cube') {
			this.hideWMTS();
		} else {
			this.hideCubeMaps();
		}

		// Hide all except current index.
		for (let i = 0; i < this.numOfFrames; i += 1) {
			const wmts = this.earth?.getComponent(`viirs${i}`);
			const cubeMap = this.earth?.getComponent(`cube${i}`);

			const wmtsVisible = i === currentIndex && type === 'wmts';
			const cubeMapVisible = i === currentIndex && type === 'cube';

			wmts?.setVisible(wmtsVisible);
			cubeMap?.setVisible(cubeMapVisible);
		}

		SceneHelpers.waitTillEntitiesInPlace(this._scene, ['earth'])
			.then(() => this._pioneer.waitUntilNextFrame())
			.then(() => {
				if (component?.getType() === 'wmts') {
					return component?.getTilesLoadedPromise();
				}
				return component?.getLoadedPromise();
			})
			.then(() => {
				// Set isTextureLoaded global state to false.
				datasetStore.setGlobalState({ isTextureLoaded: true });
			});
	}

	/**
	 * Displays blue marble textures.
	 * @param {boolean} showClouds
	 */
	setBlueMarble(enabled, showClouds = false) {
		const { getManager, pioneer } = globalRefs;
		const spheroidLOD = this.earth.get('spheroidLOD');

		if (!enabled) {
			spheroidLOD.setEnabled(false);
			return;
		}

		// Enable spheroidLOD features.
		const { earthSpheroidFeatures } = getManager('content');

		const featuresToEnable = ['nightMap', 'nightMapEmmissive'];
		showClouds && featuresToEnable.push('decalMap');

		// Enable spheroidLOD features.
		earthSpheroidFeatures.forEach(feature => {
			const enabled = featuresToEnable.includes(feature);
			spheroidLOD.setFeature(feature, enabled);
		});

		// Set correct cube layout.
		spheroidLOD.setCubeMapLayout([
			['+y', '+z', '+x'],
			['-x', '+z', '+y'],
			['-y', '+z', '-x'],
			['+x', '+z', '-y'],
			['+y', '-x', '+z'],
			['+y', '+x', '-z']
		]);

		// Set cube mapping.
		spheroidLOD.setMapping('cube');

		// Set textures.
		const minTextureSize = 512;
		const maxTextureSize = pioneer.getConfig().getValue('maxTextureSize');

		const firstTextureSize = [minTextureSize];
		spheroidLOD.setTexture('color', '$STATIC_ASSETS_URL/maps/earth/color_$SIZE_$FACE.png', firstTextureSize);
		// spheroidLOD.setTexture('normal', '$STATIC_ASSETS_URL/maps/earth/normal_$SIZE_$FACE.png', firstTextureSize);
		spheroidLOD.setTexture('night', '$STATIC_ASSETS_URL/maps/earth/night_$SIZE_$FACE.png', firstTextureSize);
		// Conditional cloud texture.
		// showClouds && spheroidLOD?.setTexture('decal', '$STATIC_ASSETS_URL/maps/earth/cloud_$SIZE_$FACE.png', firstTextureSize);

		// Set feature to enable.
		spheroidLOD.setEnabled(true);

		showLoading('texture');

		SceneHelpers.waitTillEntitiesInPlace(this._scene, ['earth'])
			.then(() => this._pioneer.waitUntilNextFrame())
			.then(() => spheroidLOD.getLoadedPromise())
			.then(() => {
				// Hide cubemaps and WMTS.
				this.hideCubeMaps();
				this.hideWMTS();

				// Hide texture loading.
				hideLoading('texture');

				// If we're still on the blue marble and there is a larger maxTextureSize, we can load it.
				const { blueMarble } = layersStore.stateSnapshot;
				if (blueMarble && maxTextureSize > minTextureSize) {
					const secondTextureSize = [maxTextureSize];
					spheroidLOD.setTexture('color', '$STATIC_ASSETS_URL/maps/earth/color_$SIZE_$FACE.png', secondTextureSize);
					// spheroidLOD.setTexture('normal', '$STATIC_ASSETS_URL/maps/earth/normal_$SIZE_$FACE.png', secondTextureSize);
					spheroidLOD.setTexture('night', '$STATIC_ASSETS_URL/maps/earth/night_$SIZE_$FACE.png', secondTextureSize);
					// Conditional cloud texture.
					// showClouds && spheroidLOD?.setTexture('decal', '$STATIC_ASSETS_URL/maps/earth/cloud_$SIZE_$FACE.png', secondTextureSize);
				}
			});
	}

	/**
	 * Updates WMTS max level on a dataset store update
	 */
	updateWMTSMaxLevel() {
		const { currentIndex, isDatasetPaused, datasetHasAnimation } = datasetStore.stateSnapshot;
		const { inAutoplay } = kioskStore.stateSnapshot;
		const wmtsCurrent = this.earth?.getComponent(`viirs${currentIndex}`);

		if (!wmtsCurrent) {
			return;
		}

		const hiRes = this._allowHiRes && (!datasetHasAnimation || isDatasetPaused);
		const hiResLevel = inAutoplay ? WMTS_MAX_LEVEL_AUTOPLAY : WMTS_MAX_LEVEL;
		const level = hiRes ? hiResLevel : wmtsCurrent.getMinLevel();

		wmtsCurrent.setMaxLevel(level);
	}

	setTitle() {
		const { currentIndex, currentDataset } = datasetStore.stateSnapshot;
		const { title } = this.currentData?.[currentIndex] || {};
		if (!title || !currentDataset || currentDataset.title === title) {
			return;
		}

		const updatedDataset = JSON.parse(JSON.stringify(currentDataset));
		updatedDataset.title = this.currentData[currentIndex]?.title;
		updatedDataset.noCameraReset = true;

		datasetStore.setGlobalState({ currentDataset: updatedDataset });
	}

	async load() {
		// Set readyToShow to true.
		this._readyToShow = true;

		// If we are already loading, we can destroy the previous textures.
		if (this._loadingCurrentData === true) {
			this.destroy();
		}

		this._loadingCurrentData = true;

		// Reset the modal.
		resetModal();

		// Disable earth's basic spheroidLOD.
		this.earth.getComponentByType('spheroidLOD')?.setEnabled(false);

		// Show texture loading.
		showLoading('texture');

		// Set current data.
		await SceneHelpers.waitTillEntitiesInPlace(this._scene, ['earth']);
		await this.setCurrentData();

		this._loadingCurrentData = false;

		if (!this._readyToShow) {
			this._readyToShow = true;
			return false;
		}

		return true;
	}

	hideCubeMaps() {
		let i = 0;
		while (this.earth.getComponent(`cube${i}`) !== null) {
			this.earth.getComponent(`cube${i}`).setVisible(false);
			i++;
		}
	}

	hideWMTS() {
		let i = 0;
		while (this.earth.getComponent(`viirs${i}`) !== null) {
			this.earth.getComponent(`viirs${i}`).setVisible(false);
			i++;
		}
	}

	destroy() {
		let i = 0;
		while (this.earth.getComponent(`viirs${i}`) !== null) {
			this.earth.removeComponent(`viirs${i}`);
			i++;
		}

		i = 0;
		while (this.earth.getComponent(`cube${i}`) !== null) {
			this.earth.removeComponent(`cube${i}`);
			i++;
		}

		// Clean up any orphaned tiles.
		this.cleanupTiles();

		hideLoading('texture');

		this._readyToShow = false;

		// Reset server state store.
		Object.keys(WMTS_SERVER_STATE).forEach(url => {
			WMTS_SERVER_STATE[url] = null;
		});
	}

	/**
	 * Patch for the orphaned tiles bug.
	 * Manually removing threejs meshes that are leaked by pioneer.
	 */
	cleanupTiles() {
		const orphanedTiles = this._scene?.getThreeJsScene()?.children?.filter(obj => obj.name.includes('wmts') && obj.visible === true);
		if (orphanedTiles?.length) {
			orphanedTiles.forEach(tile => {
				ThreeJsHelper.destroyObject(tile);
			});
			console.warn(`Cleaned up ${orphanedTiles.length} orphaned tiles.`);
		}
	}
}

export default VisibleEarthManager;
