/** @module pioneer */

/**
 * A class that can be used to find out the capabilities of the current device.
 * @hideconstructor
 */
export class Capabilities {
	/**
	 * Returns true if the context is WebGL 2.0.
	 * @returns {boolean}
	 */
	static isWebGL2() {
		return typeof WebGL2RenderingContext !== 'undefined' && _context instanceof WebGL2RenderingContext;
	}

	/**
	 * Returns true if the context has the gl_fragDepth output variable.
	 * @returns {boolean}
	 */
	static hasFragDepth() {
		return this.isWebGL2() || this.hasGLExtension('EXT_frag_depth');
	}

	/**
	 * Returns true if the device has the GL extension enabled.
	 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/WebGL_API/Using_Extensions}
	 * @param {string} extensionName - The extension name.
	 * @returns {boolean}
	 */
	static hasGLExtension(extensionName) {
		if (_context === null) {
			return false;
		}
		let value = _cache.get(extensionName);
		if (value === undefined) {
			value = _context.getExtension(extensionName) !== null;
			_cache.set(extensionName, value);
		}
		return value;
	}

	/**
	 * Gets the supported compressed texture extension.
	 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/WebGL_API/Compressed_texture_formats}
	 * @returns {string}
	 * @internal
	 */
	static __getCompressedTextureExtension() {
		return _compressedTextureExtension;
	}

	/**
	 * Sets the rendering context for use in other functions. Called by Engine.
	 * @param {WebGLRenderingContext} context - The WebGL context.
	 * @internal
	 */
	static __setContext(context) {
		_context = context;

		// Check for support of compressed texture formats.
		_compressedTextureFormats.forEach((value, key) => {
			if (this.hasGLExtension('WEBGL_compressed_texture_' + key) === true
				|| this.hasGLExtension('WEBKIT_WEBGL_compressed_texture_' + key) === true) {
				if (_compressedTextureExtension === '') {
					_compressedTextureExtension = value;
				}
			}
		});
	}
}

/**
 * A cache of capabilities for performance.
 * @type {Map<string, boolean>}
 */
const _cache = new Map();

/**
 * The rendering context for use in other functions.
 * @type {WebGLRenderingContext | null}
 */
let _context = null;

/**
 * The supported compressed texture extension.
 * @type {string}
 */
let _compressedTextureExtension = '';

/**
 * A mapping from compressed texture formats to their corresponding extensions.
 * @type {Map<string, string>}
 */
const _compressedTextureFormats = new Map([
	['astc', 'astc'],
	['s3tc', 'dxt'],
	['pvrtc', 'pvrtc'],
	['etc', 'etc2'],
	['etc1', 'etc1']]);
