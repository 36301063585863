/* eslint-disable camelcase */
import { useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useSnapshot } from 'valtio';


import { eventsStore, videoStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';
import { loadGeoLocatedStatic } from '../helpers/processEvents';
import { hideLoading, showLoading } from '../components/loading';

const EventDetail = () => {
	const { eventYearParam, eventParam } = useParams();
	const { events } = useSnapshot(eventsStore.state);
	const isLoading = useRef(null);

	const allEventsInYear = events?.[eventYearParam];
	const currentEvent = allEventsInYear?.find(({ urlParam }) => urlParam === eventParam);

	// useEffect for when event changes.
	useEffect(() => {
		// Set variables.
		const { getManager, pioneer } = globalRefs;
		const { urlParam, isAnimated, type } = currentEvent || {};
		const alreadyLoading = isLoading.current === urlParam;

		if (!currentEvent || alreadyLoading) {
			return;
		}

		// Set isLoading.
		isLoading.current = urlParam;

		// Show loading.
		const loadType = isAnimated ? 'video' : 'event';
		showLoading(loadType);

		// Set current event in global state.
		eventsStore.setGlobalState({ currentEvent });
		// Set isVideoTexture in global state.
		videoStore.setGlobalState({ isVideoTexture: isAnimated });

		const patchComponent = pioneer.get('main', 'earth').getComponent('patch');

		// Load the video if it is animated or static if not.
		(isAnimated ? getManager('video').loadVideo(currentEvent) : loadGeoLocatedStatic(currentEvent))
			// NOTE: Currently assuming global static events are not possible.
			.then(() => {
				// Check if event is still loading.
				if (isLoading.current) {
					// Hide loading.
					hideLoading(loadType);
				}

				// Set isLoading to false.
				isLoading.current = false;
			}).catch(e => e);

		// On currentVideo change / unmount disable the earth patch.
		return () => {
			if (type === 'geoLocated') {
				patchComponent?.setEnabled?.(false);
			}
		};
	}, [currentEvent]);

	// useEffect to set current event to null and disable patch when leaving event detail view.
	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			const { pioneer, getManager } = globalRefs;
			const earth = pioneer.get('main', 'earth');
			const patchComponent = earth.getComponent('patch');

			// Set isLoading to null and hide loading.
			isLoading.current = null;
			hideLoading('event');
			hideLoading('video');

			// Disable the patch.
			patchComponent.setEnabled(false);

			// Reset global states.
			eventsStore.setGlobalState({ currentEvent: null });
			videoStore.setGlobalState({ isVideoTexture: null });

			// Unload video on unmount.
			getManager('video').unloadVideo();
		};
	}, []);


	return null;
};

export default EventDetail;
