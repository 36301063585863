import React from 'react';
import { useSnapshot } from 'valtio';

import DetailPanelLeft from './detail_panel_left';
import DetailPanelRight from './detail_panel_right';

import '../../www/assets/css/details_panel.css';

import { uiStore } from '../managers/globalState';

const DetailPanel = () => {
	const { isDetailPanelOpen } = useSnapshot(uiStore.state);

	return (
		<div id='detail-panel' className={isDetailPanelOpen ? 'w-100 active scrollable' : 'disabled'}>
			<div className='container mx-0'>
				<div className='detail-content-container'>
					<DetailPanelLeft />
					<DetailPanelRight />
				</div>
			</div>
		</div>
	);
};


export default DetailPanel;
