/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';

/**
 * A simple date and time class. Month starts at 1.
 * This is needed because the Date object (and moment.js) doesn't handle leap seconds.
 * Note that month starts at 1, not 0.
 */
export class DateTime {
	/**
	 * Constructor.
	 * @param {number} [year=1]
	 * @param {number} [month=1]
	 * @param {number} [day=1]
	 * @param {number} [hour=0]
	 * @param {number} [minute=0]
	 * @param {number} [second=0]
	 * @param {number} [millisecond=0]
	 */
	constructor(year = 1, month = 1, day = 1, hour = 0, minute = 0, second = 0, millisecond = 0) {
		/**
		 * Year starting at 1 A.D.
		 * @type {number}
		 */
		this.year = year;

		/**
		 * Month starting at 1.
		 * @type {number}
		 */
		this.month = month;

		/**
		 * Day starting at 1.
		 * @type {number}
		 */
		this.day = day;

		/**
		 * The 24-hour.
		 * @type {number}
		 */
		this.hour = hour;

		/**
		 * The minute.
		 * @type {number}
		 */
		this.minute = minute;

		/**
		 * The second. Can go up to 60 for leap seconds.
		 * @type {number}
		 */
		this.second = second;

		/**
		 * The millisecond. Can be fractional.
		 * @type {number}
		 */
		this.millisecond = millisecond;
	}

	/**
	 * Copies a dateTime to this.
	 * @param {DateTime} dateTime
	 */
	copy(dateTime) {
		this.year = dateTime.year;
		this.month = dateTime.month;
		this.day = dateTime.day;
		this.hour = dateTime.hour;
		this.minute = dateTime.minute;
		this.second = dateTime.second;
		this.millisecond = dateTime.millisecond;
	}

	/**
	 * Sets this from an ET time.
	 * @param {number} et
	 */
	fromET(et) {
		const unixTime = Pioneer.TimeUtils.etToUnix(et);
		_date.setTime(unixTime * 1000.0);
		this.year = _date.getUTCFullYear();
		this.month = _date.getUTCMonth() + 1;
		this.day = _date.getUTCDate();
		this.hour = _date.getUTCHours();
		this.minute = _date.getUTCMinutes();
		this.second = _date.getUTCSeconds();
		this.millisecond = _date.getUTCMilliseconds();
		for (let i = 0, l = Pioneer.TimeUtils.leapSeconds.length; i < l; i++) {
			if (Math.floor(unixTime) === Pioneer.TimeUtils.leapSeconds[i] && unixTime === Pioneer.TimeUtils.etToUnix(et - 1)) {
				this.second += 1;
				break;
			}
		}
	}

	/**
	 * Gets an ET time from this.
	 * @returns {number}
	 */
	toET() {
		_date.setUTCFullYear(this.year);
		_date.setUTCMonth(this.month - 1);
		_date.setUTCDate(this.day);
		_date.setUTCHours(this.hour);
		_date.setUTCMinutes(this.minute);
		_date.setUTCSeconds(this.second);
		_date.setUTCMilliseconds(this.millisecond);
		const unixTime = _date.getTime() / 1000.0;
		let etTime = Pioneer.TimeUtils.unixToEt(unixTime);
		if (this.second === 60) {
			for (let i = 0, l = Pioneer.TimeUtils.leapSeconds.length; i < l; i++) {
				if (Math.floor(unixTime) === Pioneer.TimeUtils.leapSeconds[i] + 1) {
					etTime -= 1;
					break;
				}
			}
		}
		return etTime;
	}

	/**
	 * Sets this from an ordinal date (day of year) and time.
	 * @param {number} year
	 * @param {number} doy
	 * @param {number} [hour=0]
	 * @param {number} [minute=0]
	 * @param {number} [second=0]
	 * @param {number} [millisecond=0]
	 */
	fromDOY(year, doy, hour = 0, minute = 0, second = 0, millisecond = 0) {
		let passedDays = 0;
		this.year = year;
		this.month = 1;
		this.hour = hour;
		this.minute = minute;
		this.second = second;
		this.millisecond = millisecond;
		let daysInMonth = this.getDaysInMonth();
		while (doy > passedDays + daysInMonth && this.month < 12) {
			passedDays += daysInMonth;
			this.month += 1;
			daysInMonth = this.getDaysInMonth();
		}
		this.day = doy - passedDays;
	}

	/**
	 * Gets the day of year for this.
	 * @returns {number}
	 */
	toDOY() {
		const originalMonth = this.month;
		let doy = 0;
		for (this.month = 1; this.month < originalMonth; this.month++) {
			doy += this.getDaysInMonth();
		}
		this.month = originalMonth;
		doy += this.day;
		return doy;
	}

	/**
	 * Returns true if this is a leap year.
	 * @returns {boolean}
	 */
	isLeapYear() {
		return ((this.year % 4 === 0) && (this.year % 100 !== 0)) || (this.year % 400 === 0);
	}

	/**
	 * Returns the number of days in the month for this.
	 * @returns {number}
	 */
	getDaysInMonth() {
		let daysInMonth = _daysInMonth[this.month - 1];
		if (this.month === 2 && this.isLeapYear()) {
			daysInMonth += 1;
		}
		return daysInMonth;
	}

	/**
	 * Parses text in the form of YYYY-MM-DD HH:MM:SS.ssssss, with the time and milliseconds optional.
	 * @param {string} text
	 */
	parse(text) {
		this.year = parseInt(text.substring(0, 4));
		if (text.length > 5) {
			this.month = parseInt(text.substring(5, 7));
		}
		else {
			this.month = 1;
		}
		if (text.length > 8) {
			this.day = parseInt(text.substring(8, 10));
		}
		else {
			this.day = 1;
		}
		if (text.length > 11) {
			this.hour = parseInt(text.substring(11, 13));
			this.minute = parseInt(text.substring(14, 16));
		}
		else {
			this.hour = 0;
			this.minute = 0;
		}
		if (text.length > 17) {
			this.second = parseInt(text.substring(17, 19));
		}
		else {
			this.second = 0;
		}
		if (text.length > 20) {
			this.millisecond = parseInt(text.substring(20)) * Math.pow(10, 23 - text.length);
		}
		else {
			this.millisecond = 0;
		}
	}

	/**
	 * Parses text in the form of YYYY-DDD HH:MM:SS.ssssss.
	 * @param {string} text
	 */
	parseDOY(text) {
		const year = parseInt(text.substring(0, 4));
		let doy = 1;
		if (text.length > 5) {
			doy = parseInt(text.substring(5, 8));
		}
		this.fromDOY(year, doy);
		if (text.length > 9) {
			this.hour = parseInt(text.substring(9, 11));
			this.minute = parseInt(text.substring(12, 14));
		}
		else {
			this.hour = 0;
			this.minute = 0;
		}
		if (text.length > 15) {
			this.second = parseInt(text.substring(15, 17));
		}
		else {
			this.second = 0;
		}
		if (text.length > 18) {
			this.millisecond = parseInt(text.substring(18)) * Math.pow(10, 21 - text.length);
		}
		else {
			this.millisecond = 0;
		}
	}

	/**
	 * Converts this to a string of the form YYYY-MM-DD HH:MM:SS.ssssss.
	 * @returns {string}
	 */
	toString() {
		return `${this.year.toString().padStart(4, '0')}-${this.month.toString().padStart(2, '0')}-${this.day.toString().padStart(2, '0')} ${this.hour.toString().padStart(2, '0')}:${this.minute.toString().padStart(2, '0')}:${this.second.toString().padStart(2, '0')}.${Math.floor(this.millisecond * 1e3).toString().padStart(6, '0')}`;
	}

	/**
	 * Converts this to a string of the form YYYY-DDD.
	 * @returns {string}
	 */
	toStringDOY() {
		return `${this.year.toString().padStart(4, '0')}-${this.toDOY().toString().padStart(3, '0')}`;
	}
};

/**
 * The number of days in a non-leap year month.
 * @type {number[]}
 * @private
 */
const _daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

/**
 * Temporary date that can be reused within functions.
 * @type {Date}
 * @private
 */
const _date = new Date();
