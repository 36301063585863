/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useSnapshot } from 'valtio';

import Filter from './filter';

import globalRefs from '../managers/globalRefs';
import { eventsStore } from '../managers/globalState';
import { EVENT_CATEGORIES, EVENT_MEDIA } from '../config/constants';

import '../../www/assets/css/event_dropdowns.css';

const EventDropdowns = () => {
	const { getManager } = globalRefs;
	const routeManager = getManager('route');
	// Get the year and category index global state.
	const { yearIndex, categoryIndex, mediaIndex, eventsVisibleOnGlobe, eventYears } = useSnapshot(eventsStore.state);

	const currentYear = eventYears?.[yearIndex];
	const currentCategory = categoryIndex !== null ? Array.from(EVENT_CATEGORIES.keys())[categoryIndex] : 'all';
	const currentFilter = mediaIndex !== null ? Array.from(EVENT_MEDIA.keys())[mediaIndex] : 'all';

	// When changing the year or the category, it's important to maintain the existing category or year respectively.
	const onYearChange = newYearIndex => {
		const newYear = eventYears[newYearIndex];

		// Navigate to the new event year.
		newYear && routeManager.navigateToEventYear(newYear, currentCategory, currentFilter);
	};

	const onCategoryChange = newCategoryIndex => {
		const newCategoryParam = Array.from(EVENT_CATEGORIES.keys())[newCategoryIndex];

		// Navigate to the new event category.
		newCategoryParam && routeManager.navigateToEventYear(currentYear, newCategoryParam, currentFilter);
	};

	const onMediaChange = newMediaIndex => {
		const newFilterQuery = Array.from(EVENT_MEDIA.keys())[newMediaIndex];

		// Navigate to the new filter query
		newFilterQuery && routeManager.navigateToEventYear(currentYear, currentCategory, newFilterQuery);
	};

	const showDropdown = eventsVisibleOnGlobe && eventYears && yearIndex !== null;

	return showDropdown && (
		<div className='event-dropdowns-container event-dropdowns-offset'>
			<Filter
				type='dropdown'
				title='السنة'
				allItems={eventYears}
				selectedIndex={yearIndex}
				updateItem={onYearChange}
			/>
			<Filter
				type='dropdown'
				title='الفئة'
				allItems={Array.from(EVENT_CATEGORIES.values())}
				selectedIndex={categoryIndex}
				updateItem={onCategoryChange}
			/>
			<Filter
				type='dropdown'
				title='الوسائط'
				allItems={Array.from(EVENT_MEDIA.values())}
				selectedIndex={mediaIndex}
				updateItem={onMediaChange}
			/>
		</div>
	);
};


export default EventDropdowns;
