/** @module pioneer */
import {
	BaseController,
	Entity,
	MathUtils,
	ModelComponent,
	Quaternion,
	THREE,
	Vector3
} from '../../internal';

/**
 * A controller that rotates an entity by an axis at a certain rate.
 */
export class SpinController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The axis around which to rotate the entity.
		 * @type {Vector3}
		 * @private
		 */
		this._axis = new Vector3(0, 0, 1);
		this._axis.freeze();

		/**
		 * The flag that determines whether the axis is relative to the entity's orientation.
		 * @type {boolean}
		 * @private
		 */
		this._axisRelativeToEntity = true;

		/**
		 * The rate in radians per second to rotate the entity.
		 * @type {number}
		 * @private
		 */
		this._rate = 0;

		/**
		 * The reference angle in radians, the phase, of the entity at the reference time.
		 * @type {number}
		 * @private
		 */
		this._referenceAngle = 0;

		/**
		 * The reference time when the entity is at the reference phase angle.
		 * @type {number}
		 * @private
		 */
		this._referenceTime = undefined;

		/**
		 * A flag that determines whether to clamp the rate at the real-time rate.
		 * @type {boolean}
		 * @private
		 */
		this._clampedToRealTime = false;

		/**
		 * A flag that says whether real-time or Pioneer time is used.
		 * @type {boolean}
		 * @private
		 */
		this._usingRealTime = false;

		/**
		 * A flag that says whether the position is also rotating.
		 * @type {boolean}
		 * @private
		 */
		this._rotatingPosition = false;

		/**
		 * The joint for the model to spin. If empty, the entity itself is used.
		 * @type {string}
		 * @private
		 */
		this._joint = '';

		/**
		 * The joint's ThreeJs object.
		 * @type {THREE.Object3D}
		 * @private
		 */
		this._jointObject = null;

		/**
		 * The model for the joint.
		 * @type {ModelComponent}
		 * @private
		 */
		this._model = null;

		/**
		 * A recording of the last time of Pioneer.
		 * @type {number}
		 * @private
		 */
		this._lastTime = entity.getScene().getEngine().getTime();

		// Let the base controller know that this changes the orientation.
		this.addModifiedState('orientation');
	}

	/**
	 * Gets the axis around which to rotate the entity. It defaults to the z-axis.
	 * @returns {Vector3}
	 */
	getAxis() {
		return this._axis;
	}

	/**
	 * Gets the flag that determines whether the axis is relative to the entity's orientation. Defaults to true.
	 * @returns {boolean}
	 */
	isAxisRelativeToEntity() {
		return this._axisRelativeToEntity;
	}

	/**
	 * Sets the axis around which to rotate the entity.
	 * @param {Vector3} axis - The axis.
	 * @param {boolean} relativeToEntity - If true, the axis is relative to the entity's orientation.
	 */
	setAxis(axis, relativeToEntity) {
		this._axis.thaw();
		this._axis.copy(axis);
		this._axis.freeze();
		this._axisRelativeToEntity = relativeToEntity;
	}

	/**
	 * Gets the rate in radians per second to rotate the entity. Defaults to 0.
	 * @returns {number}
	 */
	getRate() {
		return this._rate;
	}

	/**
	 * Sets the rate in radians per second to rotate the entity.
	 * @param {number} rate
	 */
	setRate(rate) {
		this._rate = rate;
	}

	/**
	 * Gets the reference angle in radians, the phase, of the entity at the reference time. Defaults to 0.
	 * @returns {number}
	 */
	getReferenceAngle() {
		return this._referenceAngle;
	}

	/**
	 * Sets the reference angle in radians, the phase, of the entity at the reference time.
	 * @param {number} referenceAngle
	 */
	setReferenceAngle(referenceAngle) {
		this._referenceAngle = referenceAngle;
	}

	/**
	 * Gets the reference time when the entity is at the reference phase angle. If this is undefined, the previous frame's time is used. Defaults to undefined.
	 * @returns {number}
	 */
	getReferenceTime() {
		return this._referenceTime;
	}

	/**
	 * Sets the reference time when the entity is at the reference phase angle.
	 * @param {number} referenceTime
	 */
	setReferenceTime(referenceTime) {
		this._referenceTime = referenceTime;
	}

	/**
	 * Gets whether to clamp the rate at the real-time rate. Defaults to false. Ignored when there is a reference time.
	 * @returns {boolean}
	 */
	isClampedToRealTime() {
		return this._clampedToRealTime;
	}

	/**
	 * Sets whether to clamp the rate at the real-time rate.
	 * @param {boolean} clampedToRealTime
	*/
	setClampedToRealTime(clampedToRealTime) {
		this._clampedToRealTime = clampedToRealTime;
	}

	/**
	 * Gets the flag that says whether real-time or Pioneer time is used.
	 * @returns {boolean}
	 */
	isUsingRealTime() {
		return this._usingRealTime;
	}

	/**
	 * Sets the flag that says whether real-time or Pioneer time is used.
	 * @param {boolean} usingRealTime
	*/
	setUsingRealTime(usingRealTime) {
		this._usingRealTime = usingRealTime;
	}

	/**
	 * Gets the flag that says whether the position is also rotating.
	 * @returns {boolean}
	 */
	isRotatingPosition() {
		return this._rotatingPosition;
	}

	/**
	 * Sets the flag that says whether the position is also rotating.
	 * @param {boolean} rotatingPosition
	*/
	setRotatingPosition(rotatingPosition) {
		this._rotatingPosition = rotatingPosition;
		if (rotatingPosition) {
			this.addModifiedState('position');
		}
		else {
			this.removeModifiedState('position');
		}
	}

	/**
	 * Sets the spin to be at the joint on the specified model. If no model is given, the first model in the entity is used.
	 * @param {string} joint
	 * @param {ModelComponent} [model]
	 */
	setJoint(joint, model) {
		this._joint = joint;
		if (!model) {
			const modelFromEntity = /** @type {ModelComponent} */(this.getEntity().get('model'));
			if (modelFromEntity !== null) {
				this._model = modelFromEntity;
			}
		}
		else {
			this._model = model;
		}
		if (this._joint !== '') {
			this.removeModifiedState('orientation');
		}
		else {
			this.addModifiedState('orientation');
		}
	}

	/**
	 * If the position is fixed, updates the position to the fixed position.
	 * @param {Vector3} position
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updatePositionAtTime(position, time) {
		if (this._rotatingPosition) {
			// Get the deltaTime, the time since the last update, possibly clamped.
			let deltaTime = 0;
			if (!this._usingRealTime) { // Using real time.
				if (this._referenceTime !== undefined) {
					deltaTime = time - this._referenceTime;
					deltaTime -= this._referenceAngle / this._rate;
				}
			}

			// Calculate the rotation quaternion from the deltaTime.
			const rotation = Quaternion.pool.get();
			rotation.setFromAxisAngle(this._axis, this._rate * deltaTime);

			// If the joint object is valid,
			if (this._jointObject === null) {
				// Apply the rotation to the new orientation.
				if (this._axisRelativeToEntity) {
					const orientation = Quaternion.pool.get();
					this.getEntity().getOrientationAtTime(orientation, time);
					rotation.mult(orientation, rotation);
					rotation.multInverseR(rotation, orientation);
					Quaternion.pool.release(orientation);
				}

				// Set the position if flagged.
				position.rotate(rotation, position);
			}
			Quaternion.pool.release(rotation);
		}
	}

	/**
	 * If the orientation is fixed, updates the orientation to the fixed orientation.
	 * @param {Quaternion} orientation
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updateOrientationAtTime(orientation, time) {
		// Make sure there is a valid orientation to start.
		if (orientation.isNaN()) {
			orientation.copy(Quaternion.Identity);
		}

		// Get the deltaTime, the time since the last update, possibly clamped.
		let deltaTime = 0;
		if (!this._usingRealTime) { // Using real time.
			if (this._referenceTime !== undefined) {
				deltaTime = time - this._referenceTime;
				deltaTime -= this._referenceAngle / this._rate;
			}
		}

		// Calculate the rotation quaternion from the deltaTime.
		const rotation = Quaternion.pool.get();
		rotation.setFromAxisAngle(this._axis, this._rate * deltaTime);

		// If the joint object is valid,
		if (this._jointObject === null) {
			// Apply the rotation to the new orientation.
			if (this._axisRelativeToEntity) {
				rotation.mult(orientation, rotation);
				rotation.multInverseR(rotation, orientation);
			}
			orientation.mult(rotation, orientation);
			orientation.normalize(orientation);
		}
		Quaternion.pool.release(rotation);
	}

	/**
	 * Updates the controller.
	 * @override
	 * @internal
	 */
	__update() {
		// Make sure there is a valid orientation to start.
		if (this.getEntity().getOrientation().isNaN()) {
			this.getEntity().setOrientation(Quaternion.Identity);
		}

		// Get the deltaTime, the time since the last update, possibly clamped.
		const engine = this.getEntity().getScene().getEngine();
		let deltaTime = 0;
		if (this._usingRealTime) { // Using real time.
			deltaTime = engine.getDeltaTime();
		}
		else { // Using Pioneer time.
			if (this._referenceTime !== undefined) {
				deltaTime = engine.getTime() - this._referenceTime;
				deltaTime -= this._referenceAngle / this._rate;
			}
			else {
				deltaTime = engine.getTime() - this._lastTime;
				if (this._clampedToRealTime) {
					const deltaRealTime = engine.getDeltaTime();
					deltaTime = MathUtils.clamp(deltaTime, -deltaRealTime, deltaRealTime);
				}
			}
		}

		// Calculate the rotation quaternion from the deltaTime.
		const rotation = Quaternion.pool.get();
		rotation.setFromAxisAngle(this._axis, this._rate * deltaTime);

		// If a joint is specified, setup the joint's ThreeJs object.
		if (this._jointObject !== null && this._model.getThreeJsObjects()[0] !== null) {
			this._jointObject = null;
		}
		if (this._joint !== '' && (this._jointObject === null || this._jointObject.name !== this._joint) && this._model !== null) {
			const subObject = this._model.getThreeJsObjectByName(this._joint);
			if (subObject !== null) {
				this._jointObject = subObject;
			}
		}
		// If the joint object is valid,
		const entityOrientation = this.getEntity().getOrientation();
		if (this._jointObject !== null) {
			if (!this._axisRelativeToEntity) {
				rotation.multInverseL(entityOrientation, rotation);
				rotation.mult(rotation, entityOrientation);
			}
			_tempThreeJsQuaternion.set(rotation.x, rotation.y, rotation.z, rotation.w);
			this._jointObject.quaternion.multiplyQuaternions(_tempThreeJsQuaternion, this._jointObject.quaternion);
			if (this._rotatingPosition) {
				this._jointObject.position.applyQuaternion(_tempThreeJsQuaternion);
			}
		}
		else {
			// Apply the rotation to the new orientation.
			const newOrientation = Quaternion.pool.get();
			if (this._axisRelativeToEntity) {
				rotation.mult(entityOrientation, rotation);
				rotation.multInverseR(rotation, entityOrientation);
			}
			newOrientation.mult(rotation, entityOrientation);
			newOrientation.normalize(newOrientation);

			// Set the orientation and angular velocity.
			const newAngularVelocity = Vector3.pool.get();
			newAngularVelocity.mult(this._axis, this._rate);
			if (this._axisRelativeToEntity) {
				newAngularVelocity.rotate(newOrientation, newAngularVelocity);
			}
			this.getEntity().setOrientation(newOrientation);
			this.getEntity().setAngularVelocity(newAngularVelocity);
			Vector3.pool.release(newAngularVelocity);
			Quaternion.pool.release(newOrientation);

			// Set the position if flagged.
			if (this._rotatingPosition) {
				const newPosition = Vector3.pool.get();
				newPosition.rotate(rotation, this.getEntity().getPosition());
				this.getEntity().setPosition(newPosition);
				Vector3.pool.release(newPosition);
			}
		}
		Quaternion.pool.release(rotation);

		this._lastTime = engine.getTime();
	}
}

/**
 * A temporary ThreeJs Quaternion.
 * @type {THREE.Quaternion}
 */
const _tempThreeJsQuaternion = new THREE.Quaternion();
