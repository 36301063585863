/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';

/**
 * A component that renders a colored disc in the x-y plane with an inner and outer radius.
 */
export class AnnulusComponent extends Pioneer.BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Pioneer.Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The inner-radius of the circular-shaped plane.
		 * @type {number}
		 * @private
		 */
		this._sizeInner = 1;

		/**
		 * The outer-radius of the circular-shaped plane.
		 * @type {number}
		 * @private
		 */
		this._sizeOuter = 1;

		/**
		 * A flag the determines whether or not the plane ignores the distance when determining visibility.
		 * @type {boolean}
		 * @private
		 */
		this._ignoreDistance = false;

		/**
		 * The calculated transition slope at which the plane will fade out. This is calculated based on the value provided to setMinDistance.
		 * @type {number}
		 * @private
		 */
		this._minDistanceM = 0.0;

		/**
		 * The calculated transition offset at which the plane will fade out. This is calculated based on the value provided to setMinDistance.
		 * @type {number}
		 * @private
		 */
		this._minDistanceB = 0.0;

		/**
		 * The calculated transition slope at which the plane will fade out. This is calculated based on the value provided to setMaxDistance.
		 * @type {number}
		 * @private
		 */
		this._maxDistanceM = Number.POSITIVE_INFINITY;

		/**
		 * The calculated transition offset at which the plane will fade out. This is calculated based on the value provided to setMaxDistance.
		 * @type {number}
		 * @private
		 */
		this._maxDistanceB = Number.POSITIVE_INFINITY;

		/**
		 * The color of the annulus.
		 * @type {Pioneer.Color}
		 * @private
		 */
		this._color = new Pioneer.Color(1, 1, 1, 1);
		this._color.freeze();

		/**
		 * The alpha multiplier determined by fading.
		 * @type {number}
		 * @private
		 */
		this._alphaMultiplier = 1.0;

		/**
		 * The number of points on the circle.
		 * @type {number}
		 * @private
		 */
		this._numPointsOnCircle = 200;

		/**
		 * True if the mesh needs to be updated.
		 * @type {boolean}
		 * @private
		 */
		this._meshDirty = true;

		this.__setRadius(this._sizeOuter);
		this.__setUsesEntityOrientation(true);
	}

	/**
	 * Gets the size of the inner radius. Defaults to 1.
	 * @returns {number}
	 */
	getInnerRadius() {
		return this._sizeInner;
	}

	/**
	 * Sets the size of the inner radius.
	 * @param {number} size
	 */
	setInnerRadius(size) {
		this._sizeInner = size;
		this._meshDirty = true;
	}

	/**
	 * Gets the size of the outer radius.
	 * @returns {number}
	 */
	getOuterRadius() {
		return this._sizeOuter;
	}

	/**
	 * Sets the size of the outer radius
	 * @param {number} size
	 */
	setOuterRadius(size) {
		this._sizeOuter = size;
		this._meshDirty = true;
		this.__setRadius(size);
	}

	/**
	 * Sets the component to either consider the distance (false) or ignore the distance (true).
	 * @param {boolean} ignoreDistance
	 */
	setIgnoreDistance(ignoreDistance) {
		this._ignoreDistance = ignoreDistance;
		this._alphaMultiplier = 1.0;
		Pioneer.ThreeJsHelper.setUniformColorRGBA(this.getThreeJsMaterials()[0], 'color', this._color, this._alphaMultiplier);
	}

	/**
	 * Sets the minimum distance at which the component should be fully visible.
	 * @param {number} distance
	 */
	setMinDistance(distance) {
		this._minDistanceM = 1.0 / (distance - distance / 1.1);
		this._minDistanceB = -1.0 * this._minDistanceM * distance / 1.1;
	}

	/**
	 * Sets the maximum distance at which the component should be fully visible.
	 * @param {number} distance
	 */
	setMaxDistance(distance) {
		this._maxDistanceM = -1.0 / (distance * 1.1 - distance);
		this._maxDistanceB = -1.0 * this._maxDistanceM * distance * 1.1;
	}

	/**
	 * Gets the color of the annulus. Defaults to white.
	 * @returns {Pioneer.Color}
	 */
	getColor() {
		return this._color;
	}

	/**
	 * Sets the color of the annulus. The alpha value should be provided at the component's maximum visibility.
	 * @param {Pioneer.Color} color
	 */
	setColor(color) {
		this._color.thaw();
		this._color.copy(color);
		this._color.freeze();
		Pioneer.ThreeJsHelper.setUniformColorRGBA(this.getThreeJsMaterials()[0], 'color', this._color, this._alphaMultiplier);
	}

	/**
	 * Prepare the component for rendering.
	 * @param {Pioneer.CameraComponent} camera
	 * @override
	 * @package
	 */
	__prepareForRender(camera) {
		// Set the orientation to the entity's orientation.
		Pioneer.ThreeJsHelper.setOrientationToEntity(this.getThreeJsObjects()[0], this.getEntity());

		// Set the Three.js object position the entity's camera-space position.
		Pioneer.ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects()[0], this.getEntity(), camera);

		if (this._meshDirty) {
			this._updateMesh();
		}

		if (!this._ignoreDistance) {
			const cameraSpacePosition = this.getEntity().getCameraSpacePosition(camera);
			const distanceToObject = cameraSpacePosition.magnitude();
			const minimumDistanceTransition = this._minDistanceM * distanceToObject + this._minDistanceB;
			const maximumDistanceTransition = this._maxDistanceM * distanceToObject + this._maxDistanceB;
			this._alphaMultiplier = Pioneer.MathUtils.clamp01(Math.min(minimumDistanceTransition, maximumDistanceTransition));
			Pioneer.ThreeJsHelper.setUniformColorRGBA(this.getThreeJsMaterials()[0], 'color', this._color, this._alphaMultiplier);
		}
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	__loadResources() {
		// Create the material.
		const material = this.getEntity().getScene().getEngine().getMaterialManager().getPreloaded('basic_alpha');
		this.getThreeJsMaterials().push(material);
		// Set the properties.
		Pioneer.ThreeJsHelper.setUniformColorRGBA(material, 'color', this._color, this._alphaMultiplier);
		// Create the mesh object.
		const object = Pioneer.ThreeJsHelper.createMeshObject(this, material, [{ name: 'position', dimensions: 3 }], false);
		this.getThreeJsObjects().push(object);
		// Return it as loaded.
		return Promise.resolve();
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		Pioneer.ThreeJsHelper.destroyAllObjectsAndMaterials(this);
	}

	/**
	 * Updates the mesh.
	 * @private
	 */
	_updateMesh() {
		const positions = new Float32Array(this._numPointsOnCircle * 4 * 3);
		const indices = new Uint16Array(this._numPointsOnCircle * 6);

		// Make the concentric circles.
		let positionsIndex = 0;
		let indicesIndex = 0;
		const position0 = new Pioneer.Vector3();
		const position1 = new Pioneer.Vector3();

		const circleSize = this._sizeOuter;
		const circleInnerRatio = this._sizeInner / this._sizeOuter;

		for (let j = 0; j < this._numPointsOnCircle; j++) {
			const angle1 = j / this._numPointsOnCircle * Pioneer.MathUtils.twoPi;
			const angle2 = ((j + 1) % this._numPointsOnCircle) / this._numPointsOnCircle * Pioneer.MathUtils.twoPi;

			position0.set(circleSize * Math.cos(angle1), circleSize * Math.sin(angle1), 0);
			position1.set(circleSize * Math.cos(angle2), circleSize * Math.sin(angle2), 0);

			positions[positionsIndex + 0] = position0.x * circleInnerRatio;	// XYZ set for origin (inner)
			positions[positionsIndex + 1] = position0.y * circleInnerRatio;
			positions[positionsIndex + 2] = position0.z * circleInnerRatio;

			positions[positionsIndex + 3] = position0.x;
			positions[positionsIndex + 4] = position0.y;
			positions[positionsIndex + 5] = position0.z; // CW Edge (left)

			positions[positionsIndex + 6] = position1.x;
			positions[positionsIndex + 7] = position1.y;
			positions[positionsIndex + 8] = position1.z; // CCW Edge (right)

			positions[positionsIndex + 9] = position1.x * circleInnerRatio;
			positions[positionsIndex + 10] = position1.y * circleInnerRatio;
			positions[positionsIndex + 11] = position1.z * circleInnerRatio; // CCW Edge (Inner)

			indices[indicesIndex + 0] = positionsIndex / 3 + 0;
			indices[indicesIndex + 1] = positionsIndex / 3 + 1;
			indices[indicesIndex + 2] = positionsIndex / 3 + 2;
			indices[indicesIndex + 3] = positionsIndex / 3 + 2;
			indices[indicesIndex + 4] = positionsIndex / 3 + 3;
			indices[indicesIndex + 5] = positionsIndex / 3 + 0;

			positionsIndex += 12;
			indicesIndex += 6;
		}
		const geometry = /** @type {Pioneer.THREE.Mesh} */(this.getThreeJsObjects()[0]).geometry;
		Pioneer.ThreeJsHelper.setVertices(geometry, 'position', positions);
		Pioneer.ThreeJsHelper.setIndices(geometry, indices);
		this._meshDirty = false;
	}
}
