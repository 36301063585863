/** @module pioneer */
import {
	Entity,
	EntityItem,
	Interval,
	Quaternion,
	Vector3
} from '../../internal';

/**
 * The base class for all controllers.
 */
export class BaseController extends EntityItem {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The interval over which the controller is valid.
		 * @type {Interval}
		 * @private
		 */
		this._coverage = new Interval(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY);
		this._coverage.freeze();

		/**
		 * The modified states.
		 * @type {Set<string>}
		 * @private
		 */
		this._modifiedStates = new Set();

		/**
		 * The dependent states. In the format of '<entity name>.<state>'.
		 * @type {Set<string>}
		 * @private
		 */
		this._dependentStates = new Set();

		// Add this to the controller dependency graph.
		this.getEntity().getScene().getControllerDependencyGraph().addItem(this);
	}

	/**
	 * Destroys the controller resources.
	 * @override
	 * @internal
	 */
	__destroy() {
		this.getEntity().getScene().getControllerDependencyGraph().removeItem(this);
		super.__destroy();
	}

	// STATES AND DEPENDENCIES

	/**
	 * Adds a modified state to the controller.
	 * @param {string} modifiedState
	 * @protected
	 */
	addModifiedState(modifiedState) {
		if (!this._modifiedStates.has(modifiedState)) {
			this._modifiedStates.add(modifiedState);
			this.getEntity().getScene().getControllerDependencyGraph().needsSorting();
			if (modifiedState === 'position' || modifiedState === 'orientation') {
				this.getEntity().__updateCoverage();
			}
		}
	}

	/**
	 * Removes a modified state.
	 * @param {string} modifiedState
	 * @protected
	 */
	removeModifiedState(modifiedState) {
		if (this._modifiedStates.delete(modifiedState)) {
			this.getEntity().getScene().getControllerDependencyGraph().needsSorting();
			if (modifiedState === 'position' || modifiedState === 'orientation') {
				this.getEntity().__updateCoverage();
			}
		}
	}

	/**
	 * Returns true if this has the modified state.
	 * @param {string} modifiedState
	 * @returns {boolean}
	 */
	hasModifiedState(modifiedState) {
		return this._modifiedStates.has(modifiedState);
	}

	/**
	 * Adds a dependent state.
	 * @param {string} entityName
	 * @param {string} dependentState
	 * @protected
	 */
	addDependentState(entityName, dependentState) {
		if (!this._dependentStates.has(entityName + '.' + dependentState)) {
			this._dependentStates.add(entityName + '.' + dependentState);
			this.getEntity().getScene().getControllerDependencyGraph().needsSorting();
		}
	}

	/**
	 * Removes a dependent state.
	 * @param {string} entityName
	 * @param {string} dependentState
	 * @protected
	 */
	removeDependentState(entityName, dependentState) {
		if (this._dependentStates.delete(entityName + '.' + dependentState)) {
			this.getEntity().getScene().getControllerDependencyGraph().needsSorting();
		}
	}

	/**
	 * Returns true if this has the dependent state.
	 * @param {string} entityName
	 * @param {string} dependentState
	 * @returns {boolean}
	 */
	hasDependentState(entityName, dependentState) {
		return this._dependentStates.has(entityName + '.' + dependentState);
	}

	/**
	 * Gets an iterator to the modified states.
	 * @returns {Set<string>}
	 * @internal
	 */
	get __modifiedStates() {
		return this._modifiedStates;
	}

	/**
	 * Gets the time interval over which the controller is valid.
	 * @returns {Interval}
	 */
	getCoverage() {
		return this._coverage;
	}

	/**
	 * Sets the time interval over which the controller is valid.
	 * @param {Interval} coverage
	 */
	setCoverage(coverage) {
		this._coverage.thaw();
		this._coverage.copy(coverage);
		this._coverage.freeze();
		this.getEntity().__updateCoverage();
	}

	/**
	 * Updates the given position for the given time.
	 * @param {Vector3} _position - The position to update.
	 * @param {number} _time - The time to check
	 */
	__updatePositionAtTime(_position, _time) { }

	/**
	 * Updates the given velocity for the given time.
	 * @param {Vector3} _velocity - The velocity to update.
	 * @param {number} _time - The time to check
	 */
	__updateVelocityAtTime(_velocity, _time) { }

	/**
	 * Updates given orientation for the given time.
	 * @param {Quaternion} _orientation - The orientation to update.
	 * @param {number} _time - The time to check.
	 */
	__updateOrientationAtTime(_orientation, _time) { }

	/**
	 * Converts the entity item to a nice string.
	 * @returns {string}
	 * @override
	 */
	toString() {
		let typeIndex = 0;
		// Search the controllers for the type.
		for (let i = 0, l = this.getEntity().getNumControllers(); i < l; i++) {
			const controller = this.getEntity().getController(i);
			if (this === controller) {
				break;
			}
			if (this.getType() === controller.getType()) {
				typeIndex += 1;
			}
		}
		return this.getEntity().getName() + '.' + this.getType() + '.' + typeIndex;
	}
}
