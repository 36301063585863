/** A static config for use by the whole application. */
export class Config {
	constructor() {
		/**
		 * Whether config is requested or not.
		 * @type {Boolean}
		 * @private
		 */
		this._requested = false;
	}

	/**
	 * Loads the config.
	 * @returns {Promise<void>}
	 */
	static async load() {
		if (this._requested) {
			return;
		}


		// Fetch config to attach to window (doesnt feel like we should be doing this)
		if (window.config === undefined) {
			try {
				this._requested = true;
				// eslint-disable-next-line require-atomic-updates
				window.config = await (await fetch('./config.json')).json();
			} catch (e) {
				console.warn('Could not find a configuration "config.json" file. You can copy the config.example.json over and use it.');
				console.warn(e.message);
			}
		}

		this.staticAssetsUrl = this._getConfigUrl('staticAssetsUrl', true);
		this.dynamicAssetsUrl = this._getConfigUrl('dynamicAssetsUrl', true);
		this.baseClimateUrl = this._getConfigUrl('baseClimateUrl', true);
		this.baseScienceUrl = this._getConfigUrl('baseScienceUrl', true);
		this.baseCe2s2Url = this._getConfigUrl('baseCe2s2Url', true);
		this.globeLat = this._getConfigNumber('globeLat', false) ?? this.globeLat;
		this.globeLon = this._getConfigNumber('globeLon', false) ?? this.globeLon;
	}

	/**
	 * Gets the config value given the key.
	 * @param {string} key
	 * @param {boolean} required
	 * @returns {string}
	 * @private
	 */
	static _getConfigUrl(key, required) {
		// Get the value.
		const value = window.config[key];

		// If the variable isn't found, throw an error.
		if (required && value === undefined) {
			throw new Error(`Cannot find a window.config value for "${key}". Please be sure to enter one in the config.json file. Check config.example.json for an example.`);
		}
		if (value !== undefined && typeof value !== 'string') {
			throw new Error(`The window.config value for "${key}" must be a string. Check config.example.json for an example.`);
		}

		// Remove any trailing '/' on the end so we don't get any double slashes throughout the app.
		return value.endsWith('/') ? value.slice(0, -1) : value;
	}

	/**
	 * Gets the config value given the key.
	 * @param {string} key
	 * @param {boolean} required
	 * @returns {boolean}
	 * @private
	 */
	static _getConfigBoolean(key, required) {
		// Get the value.
		const value = window.config[key];

		// If the variable isn't found, throw an error.
		if (required && value === undefined) {
			throw new Error(`Cannot find a window.config value for "${key}". Please be sure to enter one in the config.json file. Check config.example.json for an example.`);
		}
		if (value !== undefined && typeof value !== 'boolean') {
			throw new Error(`The window.config value for "${key}" must be a boolean. Check config.example.json for an example.`);
		}

		return value;
	}

	/**
	 * Gets the config value given the key.
	 * @param {string} key
	 * @param {boolean} required
	 * @returns {number}
	 * @private
	 */
	static _getConfigNumber(key, required) {
		// Get the value.
		const value = window.config[key];

		// If the variable isn't found, throw an error.
		if (required && value === undefined) {
			throw new Error(`Cannot find a window.config value for "${key}". Please be sure to enter one in the config.json file. Check config.example.json for an example.`);
		}
		if (value !== undefined && typeof value !== 'number') {
			throw new Error(`The window.config value for "${key}" must be a number. Check config.example.json for an example.`);
		}

		return value;
	}

	/**
	 * The URL to Pioneer Assets
	 * @type {string}
	 */
	static staticAssetsUrl = '';

	/**
	 * The URL to the dynamic assets.
	 * @type {string}
	 */
	static dynamicAssetsUrl = '';

	/**
	 * The URL to the Climate site.
	 * @type {string}
	 */
	static baseClimateUrl = '';

	/**
	 * The URL to the Science site.
	 * @type {string}
	 */
	static baseScienceUrl = '';

	/**
	 * The URL to the Science site.
	 * @type {string}
	 */
	static baseCe2s2Url = '';

	/**
	 * The latitide and longitude to focus on for globe orientation
	 * @type {number}
	 */
	// North is positive, South is negative
	static globeLat = 17;

	// East is positive, West is negative
	static globeLon = -88;
}
