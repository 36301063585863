/** @module pioneer */
import {
	BaseController,
	CameraComponent,
	Entity,
	LatLonAlt,
	Quaternion,
	SpheroidComponent,
	Vector3
} from '../../internal';

/**
 * A camera controller that flies around freely.
 */
export class FreeFlyController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The sensitivity for dragging.
		 * @type {number}
		 * @private
		 */
		this._dragSensitivity = 0.01;

		/**
		 * The smoothness of the dragging. Zero means no smoothness.
		 * @type {number}
		 * @private
		 */
		this._dragSmoothness = 0.8;

		/**
		 * The current value applied every frame to the movement.
		 * @type {Vector3}
		 * @private
		 */
		this._moveSmoothedValue = new Vector3(0.0, 0.0, 0.0);

		/**
		 * The flag that determines whether the camera changes parent to whatever it is closer to.
		 * @type {boolean}
		 * @private
		 */
		this._changeParentToNearestEntity = true;

		/**
		 * The vector that runs every frame emulating user input.
		 * @type {Vector3}
		 * @private
		 */
		this._forcedMoving = new Vector3();

		this._isMoving = false;

		// Let the base controller know that this changes the position.
		this.addModifiedState('position');

		this.addDependentState('parent', 'orientation');
		this.addDependentState('parent', 'radius');
	}

	/**
	 * Gets the drag sensitivity. Defaults to 0.01.
	 * @returns {number}
	 */
	getDragSensitivity() {
		return this._dragSensitivity;
	}

	/**
	 * Sets the drag sensitivity.
	 * @param {number} dragSensitivity
	 */
	setDragSensitivity(dragSensitivity) {
		this._dragSensitivity = dragSensitivity;
	}

	/**
	 * Gets the drag smoothness. Defaults to 0.8.
	 * @returns {number}
	 */
	getDragSmoothness() {
		return this._dragSmoothness;
	}

	/**
	 * Sets the drag smoothness, between 0 and 1.
	 * @param {number} dragSmoothness
	 */
	setDragSmoothness(dragSmoothness) {
		this._dragSmoothness = dragSmoothness;
	}

	/**
	 * Gets the flag that determines whether the camera changes parent to whatever it is closer to. Defaults to true.
	 * @returns {boolean}
	 */
	getChangeParentToNearestEntity() {
		return this._changeParentToNearestEntity;
	}

	/**
	 * Sets the flag that determines whether the camera changes parent to whatever it is closer to. This requires this entity to have a camera component for faster checking.
	 * @param {boolean} changeParentToNearestEntity
	 */
	setChangeParentToNearestEntity(changeParentToNearestEntity) {
		this._changeParentToNearestEntity = changeParentToNearestEntity;
	}

	/**
	 * Gets the vector that runs every frame emulating user input. Defaults to Vector3.Zero.
	 * @returns {Vector3}
	 */
	getForcedMoving() {
		return this._forcedMoving;
	}

	/**
	 * Sets the vector that runs every frame emulating user input.
	 * @param {Vector3} forcedMoving
	 */
	setForcedMoving(forcedMoving) {
		this._forcedMoving = forcedMoving;
	}

	/**
	 * Updates the entity's position.
	 * @override
	 * @internal
	 */
	__update() {
		// If it has no parent, don't do anything.
		if (this.getEntity().getParent() === null) {
			return;
		}

		// Update the parent, if needed. Only do it if there was any input.
		if (this._changeParentToNearestEntity && (this.getEntity().getParent() === null || this._isMoving)) {
			const scene = this.getEntity().getScene();
			const cameraComponent = /** @type {CameraComponent} */(this.getEntity().get('camera'));
			let nearestEntity = null;
			let nearestDistance = Number.POSITIVE_INFINITY;
			for (let i = 0, l = scene.getNumEntities(); i < l; i++) {
				const entity = scene.getEntity(i);
				if (entity === this.getEntity() || !entity.canOcclude()) {
					continue;
				}
				const distance = entity.getCameraSpacePosition(cameraComponent).magnitude() - entity.getOcclusionRadius();
				if (nearestDistance > distance) {
					nearestDistance = distance;
					nearestEntity = entity;
				}
			}

			// Switch parents. Since it's happening in the update function.
			if (nearestEntity !== null && nearestEntity !== this.getEntity().getParent()) {
				this.getEntity().setParent(nearestEntity);
			}
		}

		// Set the position and orientation if they have never been set before.
		if (this.getEntity().getOrientation().isNaN()) {
			this.getEntity().setOrientation(Quaternion.Identity);
		}
		if (this.getEntity().getPosition().isNaN()) {
			this.getEntity().setPosition(new Vector3(0, -1, 0));
		}

		// Get the move value from the controls.
		const move = Vector3.pool.get();
		move.set(0.0, 0.0, 0.0);
		const input = this.getEntity().getScene().getEngine().getInput();
		const viewport = input.getActiveViewport();
		if (viewport !== null) {
			const camera = viewport.getCamera();
			if (camera !== null && camera.getEntity() === this.getEntity()) {
				// Get the multipliers.
				let moveMultiplier = 1;
				if (input.isKeyPressed('x')) {
					moveMultiplier = 0.05;
				}
				if (input.isShiftPressed()) {
					moveMultiplier = 5;
				}

				// Add zoom movement.
				const zoomOffset = input.getZoomedOffset();
				if (zoomOffset !== 0) {
					move.y += -zoomOffset * this._dragSensitivity * moveMultiplier;
				}

				// Add key movement.
				if (input.isKeyPressed('w')) {
					move.y += this._dragSensitivity * moveMultiplier;
				}
				if (input.isKeyPressed('s')) {
					move.y -= this._dragSensitivity * moveMultiplier;
				}
				if (input.isKeyPressed('d')) {
					move.x += this._dragSensitivity * moveMultiplier;
				}
				if (input.isKeyPressed('a')) {
					move.x -= this._dragSensitivity * moveMultiplier;
				}
				if (input.isKeyPressed('e')) {
					move.z += this._dragSensitivity * moveMultiplier;
				}
				if (input.isKeyPressed('q')) {
					move.z -= this._dragSensitivity * moveMultiplier;
				}
			}
		}

		// Add the forced moving.
		move.add(move, this._forcedMoving);

		this._isMoving = (move.magnitudeSqr() > 0);

		// Apply smoothing.
		this._moveSmoothedValue.lerp(move, this._moveSmoothedValue, this._dragSmoothness);
		if (!this._isMoving && this._moveSmoothedValue.magnitudeSqr() < 0.0000001) {
			this._moveSmoothedValue.set(0.0, 0.0, 0.0);
		}
		Vector3.pool.release(move);

		// Get the distance to the current parent.
		let distance = Number.POSITIVE_INFINITY;
		const spheroid = /** @type {SpheroidComponent} */(this.getEntity().getParent().getComponentByType('spheroid'));
		if (spheroid !== null && !this.getEntity().getParent().getOrientation().isNaN()) {
			const position = Vector3.pool.get();
			position.rotateInverse(this.getEntity().getParent().getOrientation(), this.getEntity().getPosition());
			const lla = LatLonAlt.pool.get();
			spheroid.llaFromXYZ(lla, position);
			distance = Math.min(distance, lla.alt);
			LatLonAlt.pool.release(lla);
			Vector3.pool.release(position);
		}
		else {
			distance = Math.min(distance, this.getEntity().getPosition().magnitude() - this.getEntity().getParent().getOcclusionRadius());
		}
		distance = Math.max(0.1, distance);

		// Do the move.
		const oldPosition = this.getEntity().getPosition();
		const newPosition = Vector3.pool.get();
		const rotatedMove = Vector3.pool.get();
		rotatedMove.rotate(this.getEntity().getOrientation(), this._moveSmoothedValue);
		newPosition.addMult(oldPosition, rotatedMove, distance);
		this.getEntity().setPosition(newPosition);
		Vector3.pool.release(rotatedMove);
		Vector3.pool.release(newPosition);
	}
}
