import * as Pioneer from 'pioneer';

/**
 * A collection of utilities for entity creation.
 */
export class EntityUtils {
	/**
	 * Adds a geyser to an entity.
	 * @param {Pioneer.Entity} entity
	 * @param {number} size
	 * @param {number} alpha
	 * @param {number} particleNumber
	 * @param {number} speed
	 * @param {number} spread
	 * @param {number[]} position
	 * @param {number[] | undefined} direction
	 */
	static addGeyser(entity, size, alpha, particleNumber, speed, spread, position, direction) {
		const particleSpray = entity.addComponentByClass(Pioneer.ParticleSprayComponent);
		particleSpray.setNumberOfParticles(particleNumber);
		particleSpray.setSizeOfParticles(0.25 * size * spread);
		particleSpray.setSpeedOfParticles(speed * size);
		particleSpray.setColorOfParticles(new Pioneer.Color(1, 1, 1, 0.5 * alpha));
		particleSpray.setSpread(1 * spread);
		particleSpray.setParticleSpacingRandom(false);
		particleSpray.setLength(2.5 * size);
		particleSpray.setOriginOffset(new Pioneer.Vector3(position[0], position[1], position[2]));
		particleSpray.setDirection(new Pioneer.Vector3(direction[0], direction[1], direction[2]));
	}

	/**
	 * Shows the subobject of an entity only during the given interval.
	 * @param {Pioneer.Entity} entity
	 * @param {string} subObjectName
	 * @param {number} startTime
	 * @param {number} endTime
	 */
	static showSubObjectDuringInterval(entity, subObjectName, startTime, endTime) {
		const model = entity.getComponentByClass(Pioneer.ModelComponent);
		model.setHiddenObject(subObjectName, true);
		const coverageController = entity.addControllerByClass(Pioneer.CoverageController);
		coverageController.setCoverage(new Pioneer.Interval(startTime, endTime));
		coverageController.setEnterFunction((entity) => {
			const model = entity.getComponentByClass(Pioneer.ModelComponent);
			model.setHiddenObject(subObjectName, false);
		});
		coverageController.setExitFunction((entity) => {
			const model = entity.getComponentByClass(Pioneer.ModelComponent);
			model.setHiddenObject(subObjectName, true);
		});
	}
}
