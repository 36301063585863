/** @module pioneer-scripts */
import { Entity } from '../entity';
import * as Pioneer from 'pioneer';

Entity.register({
	sc_magellan: {
		groups: ['venus', 'spacecraft'],
		occlusionRadius: 0.003200,
		extentsRadius: 0.006000,
		label: 'Magellan',
		parents: [
			[-336388283.36, 'sun'],
			[-296448521.457, 'venus'],
			[-164631538.81763855, '']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_magellan/magellan.gltf',
			shadowEntities: ['venus']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_magellan/venus/orb'
		}, {
			type: 'custom',
			func: (entity) => {
				// Add the controller for going around sun from earth to venus.
				const oeCruiseController = entity.addControllerByClass(Pioneer.OrbitalElementsController);
				const oeCruise = new Pioneer.OrbitalElements();
				oeCruise.epoch = -336394683.816;
				oeCruise.eccentricity = 0.1791852824104108;
				oeCruise.semiMajorAxis = 128280596.63956015;
				oeCruise.meanAngularMotion = 2.5073465306679634e-7;
				oeCruise.meanAnomalyAtEpoch = 2.9229288382327625;
				oeCruise.orbitOrientation.set(0.8728453580255966, 0.1876084386162498, -0.08948587100888229, 0.4415159494547423);
				oeCruiseController.addOrbitalElements(-336388283.360, oeCruise);
				oeCruiseController.addOrbitalElements(-296448521.457, oeCruise);
				return oeCruiseController;
			}
		}, {
			type: 'custom',
			func: (entity) => {
				// Add the controller for the venus orbital insertion and first few orbits that aren't covered by spice.
				const oeVOIController = entity.addControllerByClass(Pioneer.OrbitalElementsController);
				// Orbital insertion with hyperbolic eccentricity.
				const oeVOI = new Pioneer.OrbitalElements();
				oeVOI.epoch = -296448521.457;
				oeVOI.eccentricity = 1.272922970547487;
				oeVOI.semiMajorAxis = 23358.593430196037;
				oeVOI.meanAngularMotion = 0.00015965302516479147;
				oeVOI.meanAnomalyAtEpoch = -6.138103356108873;
				oeVOI.orbitOrientation.set(-0.49933963770188916, 0.11817755769547109, 0.7262027627540558, -0.45751889408554064);
				oeVOIController.addOrbitalElements(-296448521.457, oeVOI);
				oeVOIController.addOrbitalElements(-296410230.351, oeVOI);
				// Extra orbits from Aug 10 to Aug 16 to connect to the start of the spice/dynamo.
				const oeVOI2 = new Pioneer.OrbitalElements();
				oeVOI2.epoch = -295884037.7511;
				oeVOI2.eccentricity = 0.39186818222106645;
				oeVOI2.semiMajorAxis = 10434.012201911135;
				oeVOI2.meanAngularMotion = 0.000534773863104956;
				oeVOI2.meanAnomalyAtEpoch = -1.5165416917234864;
				oeVOI2.orbitOrientation.set(-0.4665778554219434, 0.18802595119032475, 0.7217932088991018, -0.47535871728198204);
				oeVOIController.addOrbitalElements(-296410230.351, oeVOI2);
				oeVOIController.addOrbitalElements(-295884037.7511, oeVOI2);
				return oeVOIController;
			}
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				axis: new Pioneer.Vector3(-Math.sqrt(0.5), Math.sqrt(0.5), 0),
				target: 'venus',
				targetAxis: Pioneer.Vector3.ZAxisNeg
			}
		}]
	},
	sc_venus_express: {
		groups: ['venus', 'spacecraft'],
		occlusionRadius: 0.0009,
		extentsRadius: 0.0040,
		label: 'Venus Express',
		parents: [
			[184784702, 'earth'],
			[185369766, 'sun'],
			[197902579, 'venus'],
			[473341201, '']
		],
		trail: {
			length: 779592.165087551
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_venus_express/earth'
		}, {
			type: 'dynamo',
			url: 'sc_venus_express/sun'
		}, {
			type: 'dynamo',
			url: 'sc_venus_express/venus'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}, {
			type: 'dynamo',
			url: 'sc_venus_express/quat'
		}]
	}
});
