/** @module pioneer-scripts */
import { Entity } from '../entity';
import { EntityUtils } from './entity_utils';
import * as Pioneer from 'pioneer';

Entity.register({
	dione: {
		groups: ['saturn', 'moons', 'inner', 'major'],
		radius: 561.7,
		label: 'Dione',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		spheroid: {
			equatorialRadius: 561.7,
			polarRadius: 561.7,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'dione/color_$SIZE_$FACE.png',
					sizes: [4, 512, 2048]
				}
			},
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'dione/saturn/orb'
		}, {
			type: 'dynamo',
			url: 'dione/ori'
		}]
	},
	enceladus: {
		groups: ['saturn', 'moons', 'inner', 'major'],
		radius: 252.1,
		label: 'Enceladus',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		spheroid: {
			equatorialRadius: 252.1,
			polarRadius: 252.1,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'enceladus/color_$SIZE_$FACE.png',
					sizes: [4, 512, 2048]
				}
			},
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'enceladus/saturn/orb'
		}, {
			type: 'dynamo',
			url: 'enceladus/ori'
		}],
		postCreateFunction: (entity) => {
			// A
			EntityUtils.addGeyser(entity, 100, 0.1, 20, 0.002, 4, [-25.20 * 0.8, -69.23 * 0.8, -240.99 * 0.8], [-0.0999, -0.2747, -0.9563]);
			EntityUtils.addGeyser(entity, 100, 0.1, 20, 0.002, 4, [-61.73 * 0.8, -24.61 * 0.8, -243.08 * 0.8], [-0.2449, -0.0976, -0.9646]);
			EntityUtils.addGeyser(entity, 100, 0.1, 20, 0.002, 4, [-35.82 * 0.8, -46.87 * 0.8, -245.00 * 0.8], [-0.1421, -0.1859, -0.9722]);

			// B
			EntityUtils.addGeyser(entity, 100, 0.1, 20, 0.002, 4, [30.37 * 0.7, -17.54 * 0.7, -249.55 * 0.7], [0.1205, -0.0696, -0.9902]);
			EntityUtils.addGeyser(entity, 100, 0.1, 20, 0.002, 4, [0.00 * 0.7, 2.20 * 0.7, -251.99 * 0.7], [0, 0.0087, -0.9999]);
			EntityUtils.addGeyser(entity, 100, 0.1, 20, 0.002, 4, [-30.94 * 0.7, 30.94 * 0.7, -248.17 * 0.7], [-0.1227, 0.1227, -0.9848]);
			EntityUtils.addGeyser(entity, 100, 0.1, 20, 0.002, 4, [45.99 * 0.7, -34.55 * 0.7, -245.35 * 0.7], [0.1824, -0.1371, -0.9736]);

			// C
			EntityUtils.addGeyser(entity, 100, 0.1, 20, 0.002, 4, [-51.91 * 0.9, 0.68 * 0.9, -246.59 * 0.9], [-0.2059, 0.0026, -0.9785]);
			EntityUtils.addGeyser(entity, 100, 0.1, 20, 0.002, 4, [-31.81 * 0.9, -13.36 * 0.9, -249.63 * 0.9], [-0.1262, -0.0530, -0.9905]);
			EntityUtils.addGeyser(entity, 100, 0.1, 20, 0.002, 4, [-5.49 * 0.9, -29.77 * 0.9, -250.18 * 0.9], [-0.0217, -0.1181, -0.9927]);
			EntityUtils.addGeyser(entity, 100, 0.1, 20, 0.002, 4, [16.54 * 0.9, -47.1 * 0.9, -247.01 * 0.9], [0.0656, -0.1869, -0.9801]);

			// D
			EntityUtils.addGeyser(entity, 100, 0.1, 20, 0.002, 4, [-16.47 * 0.8, 60.14 * 0.8, -244.16 * 0.8], [-0.0653, 0.2386, -0.9689]);
			EntityUtils.addGeyser(entity, 100, 0.1, 20, 0.002, 4, [51.52 * 0.8, 23.97 * 0.8, -245.51 * 0.8], [0.2044, 0.0951, -0.9742]);
			EntityUtils.addGeyser(entity, 100, 0.1, 20, 0.002, 4, [28.13 * 0.8, 33.52 * 0.8, -248.17 * 0.8], [0.1116, 0.1330, -0.9848]);
		}
	},
	hyperion: {
		groups: ['saturn', 'moons', 'outer', 'major'],
		radius: 135.0,
		label: 'Hyperion',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/hyperion/hyperion.gltf',
			scale: [1, 1, 1]
		},
		controllers: [{
			type: 'dynamo',
			url: 'hyperion/saturn/orb'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	iapetus: {
		groups: ['saturn', 'moons', 'outer', 'major'],
		radius: 735.6,
		label: 'Iapetus',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		spheroid: {
			equatorialRadius: 735.6,
			polarRadius: 735.6,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'iapetus/color_$SIZE_$FACE.png',
					sizes: [4, 512, 2048]
				}
			},
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'iapetus/saturn/orb'
		}, {
			type: 'dynamo',
			url: 'iapetus/ori'
		}]
	},
	mimas: {
		groups: ['saturn', 'moons', 'inner', 'major'],
		radius: 198.3,
		label: 'Mimas',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/mimas/mimas.gltf',
			scale: [1, 1, 1],
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'mimas/saturn/orb'
		}, {
			type: 'dynamo',
			url: 'mimas/ori'
		}]
	},
	rhea: {
		groups: ['saturn', 'moons', 'outer', 'major'],
		radius: 764.3,
		label: 'Rhea',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		spheroid: {
			equatorialRadius: 764.3,
			polarRadius: 764.3,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'rhea/color_$SIZE_$FACE.png',
					sizes: [4, 512, 2048]
				}
			},
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'rhea/saturn/orb'
		}, {
			type: 'dynamo',
			url: 'rhea/ori'
		}]
	},
	tethys: {
		groups: ['saturn', 'moons', 'inner', 'major'],
		radius: 538.4,
		label: 'Tethys',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		spheroid: {
			equatorialRadius: 538.4,
			polarRadius: 527.5,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'tethys/color_$SIZE_$FACE.png',
					sizes: [4, 512, 2048]
				}
			},
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'tethys/saturn/orb'
		}, {
			type: 'dynamo',
			url: 'tethys/ori'
		}]
	},
	titan: {
		groups: ['saturn', 'moons', 'outer', 'major'],
		radius: 2575.0,
		label: 'Titan',
		parents: [
			[Number.NEGATIVE_INFINITY, 'saturn']
		],
		trail: {
			length: undefined,
			color: [0.72, 0.65, 0.52, 0.7]
		},
		spheroid: {
			equatorialRadius: 2575.0,
			polarRadius: 2575.0,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'titan/color_$SIZE_$FACE.png',
					sizes: [4, 512, 1024]
				}
			},
			shadowEntities: ['saturn']
		},
		controllers: [{
			type: 'dynamo',
			url: 'titan/saturn/orb'
		}, {
			type: 'dynamo',
			url: 'titan/ori'
		}],
		postCreateFunction: (entity) => {
			const atmosphereComponent = entity.addComponentByClass(Pioneer.AtmosphereComponent);
			atmosphereComponent.setScaleHeight(75.0);
			atmosphereComponent.setDensity(0.0005);
			atmosphereComponent.setColor(new Pioneer.Color(179.0 / 255.0, 145.0 / 255.0, 53.0 / 255.0));
			atmosphereComponent.setSunsetColor(new Pioneer.Color(0.0 / 255.0, 71.0 / 255.0, 204.0 / 255.0));
			atmosphereComponent.setSunsetIntensity(0.5);
		}
	}
});
