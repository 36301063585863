/** @module pioneer */
import {
	// eslint-disable-next-line no-unused-vars
	BaseController,
	BaseRef,
	Scene
} from '../internal';

/**
 * A reference to a controller.
 * @template {BaseController} Type
 * @extends BaseRef<Type>
 */
export class ControllerRef extends BaseRef {
	/**
	 * Constructor.
	 * @param {Scene} scene
	 */
	constructor(scene) {
		super(scene);

		/**
		 * The name of the entity.
		 * @type {string}
		 * @private
		 */
		this._entityName = '';

		/**
		 * The name of the controller.
		 * @type {string}
		 * @private
		 */
		this._controllerName = '';

		/**
		 * The type of the controller.
		 * @type {string}
		 * @private
		 */
		this._controllerType = '';

		/**
		 * The index of the type of the controller.
		 * @type {number}
		 * @private
		 */
		this._controllerTypeIndex = 0;
	}

	/**
	 * Gets the entity name.
	 * @returns {string}
	 */
	getEntityName() {
		return this._entityName;
	}

	/**
	 * Gets the controller name, if used.
	 * @returns {string}
	 */
	getControllerName() {
		return this._controllerName;
	}

	/**
	 * Gets the controller type, if used.
	 * @returns {string}
	 */
	getControllerType() {
		return this._controllerType;
	}

	/**
	 * Gets the controller type index, if the controller type is used.
	 * @returns {number}
	 */
	getControllerTypeIndex() {
		return this._controllerTypeIndex;
	}

	/**
	 * Sets the name of the reference controller.
	 * @param {string} entityName
	 * @param {string} controllerName
	 */
	setByName(entityName, controllerName) {
		this._entityName = entityName;
		this._controllerName = controllerName;
		this._controllerType = '';
	}

	/**
	 * Sets the type and index of the reference controller.
	 * @param {string} entityName
	 * @param {string} controllerType
	 * @param {number} [controllerTypeIndex]
	 */
	setByType(entityName, controllerType, controllerTypeIndex = 0) {
		this._entityName = entityName;
		this._controllerType = controllerType;
		this._controllerTypeIndex = controllerTypeIndex;
		this._controllerName = '';
	}

	/**
	 * Updates the reference.
	 * @override
	 */
	update() {
		// If there's no entity name or controllerName/Type set, make sure the ref is null.
		if (this._entityName === '' || (this._controllerName === '' && this._controllerType === '')) {
			this._setRef(null);
		}
		// If there is an entity name and a controllerName set, make sure the ref is valid and correct.
		else if (this._controllerName !== '') {
			// Make sure the entity is correct.
			const entity = this._scene.getEntity(this._entityName);
			if (entity !== null && entity.isEnabled()) {
				// Make sure the controller is correct.
				const ref = /** @type {Type} */(entity.getController(this._controllerName));
				// Set the ref.
				this._setRef(ref);
			}
			else {
				this._setRef(null);
			}
		}
		// If there is an entity name and a controllerType set, make sure the ref is valid and correct.
		else {
			// Make sure the entity is correct.
			const entity = this._scene.getEntity(this._entityName);
			if (entity !== null && entity.isEnabled()) {
				// Make sure the controller is correct.
				const ref = /** @type {Type} */(entity.getControllerByType(this._controllerType, this._controllerTypeIndex));
				// Set the ref.
				this._setRef(ref);
			}
			else {
				this._setRef(null);
			}
		}
	}
}
