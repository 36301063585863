import * as Pioneer from 'pioneer';
import { SceneHelpers } from 'pioneer-scripts';
import { cameraStore, compareStore, spacecraftStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';
import ar from '../languages/ar';

class ComparisonManager {
	constructor(pioneer) {
		this._pioneer = pioneer;
		this._scene = pioneer.get('main');

		/**
		 * Compare obj data with each obj's forward-facing axis
		 * @type {object}
		 * @private
		 */

		this._compareObjForwardAxis = {
			scientist: {
				regularForwardAxis: Pioneer.Vector3.YAxisNeg
			},
			school_bus: {
				regularForwardAxis: Pioneer.Vector3.XAxisNeg,
				sc_tempo: Pioneer.Vector3.YAxis
			},
			astronaut: {
				regularForwardAxis: Pioneer.Vector3.YAxisNeg
			}
			,
			camel: {
				regularForwardAxis: Pioneer.Vector3.YAxisNeg
			}
		};

		/**
		 * Custom compare obj positions
		 * Vectors with values in km
		 * @type {object}
		 * @private
		 */
		this._customComparePos = {
			sc_iss: new Pioneer.Vector3(0, 0.02, 0),
			sc_oco_2: new Pioneer.Vector3(0, 0.005, 0),
			sc_tempo: new Pioneer.Vector3(0, 0, -0.01)
		};

		/**
		 * List of items to compare spacecraft to.
		 * @type {object}
		 * @public
		 */
		this.compareList = [ar.Scientist, ar.School_Bus, ar.Astronaut,ar.Camel,  ar.none];

		/**
		 * Name of the compare object to be set once the transition is complete.
		 * @type {string}
		 * @private
		 */
		this._futureCompareObjName = null;

		/**
		 * Unsubscribe function for the camera transition.
		 * @type {function}
		 * @private
		 */
		this._transitionUnsubscribe = null;

		this.setCompareEntity = this.setCompareEntity.bind(this);
	}

	/**
	 * Reset or setup comparison object.
	 * Set the global state once the comparison object is set and in place.
	 * @param {string} compareObjName
	 */
	setCompareEntity(compareObjName) {
		const { getManager } = globalRefs;
		const { isCameraTransitioning } = cameraStore.stateSnapshot;
		const compareObjId = getManager('content').getCustomEntityId(compareObjName);
		const { spacecraftId } = spacecraftStore.stateSnapshot;

		// Always reset comparison object.
		this.reset();

		// If no comparison object is selected, set global state to null and return.
		if (compareObjName === ar.none || compareObjId === null || !spacecraftId) {
			compareStore.setGlobalState({ compareObjName: null });
			return false;
		}

		/**
		 * If we're currently transitioning, store the compare object name locally
		 * and call it once the transition is complete.
		 * The stored _futureCompareObjName can be replaced as many times as needed.
		 */
		if (isCameraTransitioning) {
			this._futureCompareObjName = compareObjName;

			if (!this._transitionUnsubscribe) {
				this._transitionUnsubscribe = cameraStore.subscribeTo('isCameraTransitioning', isCameraTransitioning => {
					if (isCameraTransitioning === false) {
						// Unsubscribe from the camera transition.
						this._transitionUnsubscribe();
						// Set the comparison object.
						this.setCompareEntity(this._futureCompareObjName);

						// Reset stored values.
						this._transitionUnsubscribe = null;
						this._futureCompareObjName = null;
					}
				});
			}
			return true;
		}

		return this.compare(spacecraftId, compareObjId).then(() => {
			compareStore.setGlobalState({ compareObjName });
		}).catch(_ => null);
	}

	/**
	 * Remove compare controller, disable comparison object and set global state compareEntity to null.
	 */
	reset() {
		const { getManager } = globalRefs;
		const { compareObjName } = compareStore.stateSnapshot;

		const compareId = getManager('content').getCustomEntityId(compareObjName);
		const compareEntity = this._scene.get(compareId);

		if (compareEntity !== null) {
			compareEntity.removeController('compare');
			compareEntity.setEnabled(false);
		}
	}

	/**
	 * Compare object spacecraft with object comparisonObj
	 * @param {string} spacecraftId - the id of the target object being compared (usually a spacecraft)
	 * @param {string} compareObjId - the name of the comparison object
	 */
	compare(spacecraftId, compareObjId) {
		const spacecraftEntity = this._scene.get(spacecraftId);
		const compareEntity = this._scene.get(compareObjId);

		if (!spacecraftEntity || !compareEntity) {
			throw new Error(`Cannot compare ${spacecraftId} with ${compareObjId}. One or both entities do not exist.`);
		}

		// Add parenting table entry for compare object.
		compareEntity.addParentingTableEntry(0, spacecraftId);

		// Calculate compare object position.
		const comparePos = this._calcComparePosition(spacecraftEntity, compareEntity);

		// Set compare controllers with calculated values.
		this._setCompareController(spacecraftEntity, compareEntity, comparePos);

		// Return promise that resolves when spacecraft and compare object are in place.
		return SceneHelpers.waitTillEntitiesInPlace(this._scene, [spacecraftId, compareObjId]);
	}

	/**
	 * Calculate position of the compare object
	 * @param {Entity} spacecraftEntity
	 * @param {Entity} compareEntity
	 */
	_calcComparePosition(spacecraftEntity, compareEntity) {
		// Calculate radii.
		const scRadius = spacecraftEntity.getExtentsRadius();
		const compareRadius = compareEntity.getExtentsRadius();

		// Compare object's position and distance multiplier
		const comparePos = new Pioneer.Vector3();
		let distanceMult = 0;

		// Since s/c are orbiters, use s/c position as 'up'
		const up = new Pioneer.Vector3();
		up.copy(spacecraftEntity.getPosition());

		let compareDist = 0;
		if (spacecraftEntity in this._customComparePos) {
			// Custom compare object position
			comparePos.copy(this._customComparePos[spacecraftEntity]);
			compareDist = comparePos.magnitude();
		} else {
			// Calculate default compare object position to be on the left of the s/c
			comparePos.cross(up, spacecraftEntity.getVelocity());

			// Convert from J2000 frame to s/c frame, rotate compare position into s/c orientation
			comparePos.rotateInverse(spacecraftEntity.getOrientation(), comparePos);

			distanceMult = 1.2;
			compareDist = (compareRadius + scRadius) * distanceMult;
		}

		// Set compare obj distance from s/c
		comparePos.setMagnitude(comparePos,	compareDist);

		return comparePos;
	}

	/**
	 * Set compare controllers with calculated values
	 * @param {Entity} spacecraftEntity
	 * @param {Entity} compareEntity
	 * @param {Vector3} comparePos - Custom position of compare object
	 */
	_setCompareController(spacecraftEntity, compareEntity, comparePos) {
		const spacecraftId = spacecraftEntity.getName();
		const compareObjId = compareEntity.getName();
		const compareObjAxesData = this._compareObjForwardAxis[compareObjId];

		// Reset controllers
		compareEntity.clearControllers();

		// Set compare controller's properties
		const compareController = compareEntity.addController('fixed', 'compare');
		compareController.setPosition(comparePos);
		compareController.getEntity().setParent(spacecraftEntity);

		// Align compare obj so it 'flies' in the same direction as the spacecraft
		const align = compareEntity.addController('align');

		let forwardAxis = compareObjAxesData.regularForwardAxis;
		const useCustomCompareObjAxis = Object.keys(compareObjAxesData).includes(spacecraftId);

		if (useCustomCompareObjAxis) {
			forwardAxis = compareObjAxesData[spacecraftId];
		}

		// OCO-2 'sashays' in its orbit, set custom align type for compare object
		if (spacecraftId === 'sc_oco_2') {
			align.setPrimaryAlignType('align');
		} else {
			align.setPrimaryAlignType('velocity');
		}

		align.setPrimaryAxis(forwardAxis);
		align.setPrimaryTargetEntity(spacecraftId);

		// Align compare object to always be 'upright'
		align.setSecondaryAlignType('position');
		align.setSecondaryAxis(Pioneer.Vector3.ZAxis);
		align.setSecondaryTargetEntity(spacecraftId);

		const rotate = compareEntity.addController('rotateByEntityOrientation');
		rotate.setEntityForOrientation(spacecraftId);
		rotate.setRotatingPosition(true);
		// Set it to not use spacecraft orientation
		rotate.setRotatingOrientation(false);

		compareEntity.setEnabled(true);
	}
}

export default ComparisonManager;
