/* eslint-disable camelcase */
import * as Pioneer from 'pioneer';
import { Cameras, SceneHelpers, Transitions, Placemarks } from 'pioneer-scripts';
import { setColorRectStyle } from '../components/color_bar';
import { getNearestDataIndex, setPrecipitationColorRectStyle } from '../components/color_bar_html';
import { setReadoutData } from '../components/data_readout';
import { formatNumber, getSpacecraftInstrumentData } from '../helpers/tools';
import { datasetStore, datareadStore, appStore, lightStore, poiStore, gmapStore,stellarStore } from './globalState';
import globalRefs from './globalRefs';
import { hideLoading, showLoading } from '../components/loading';
import { SPACECRAFT_GEOSTATIONARY } from '../data/spacecraft_data_ar'
import ENTITY_INFO from '../data/entity_info.json';
/**
* A default function that lerps between positions and slerps between orientations.
* @param {Entity} entity
* @param {Vector3} initialPosition
* @param {Vector3} finalPosition
* @param {Quaternion} initialOrientation
* @param {Quaternion} finalOrientation
* @param {number} u
*/
const easeTransition = (entity, initialPosition, finalPosition, initialOrientation, finalOrientation, u) => {
	const position = Pioneer.Vector3.pool.get();
	const orientation = Pioneer.Quaternion.pool.get();

	u = Transitions.easeInOut(u);

	position.lerp(initialPosition, finalPosition, u);
	orientation.slerp(initialOrientation, finalOrientation, u);

	// Set the new position and orientation.
	entity.setPosition(position);
	entity.setOrientation(orientation);
	Pioneer.Vector3.pool.release(position);
	Pioneer.Quaternion.pool.release(orientation);
};

import ar from '../languages/ar';
import en from '../languages/en';

class CameraManager {
	constructor(pioneer) {
		// Set pioneer and scene.
		this._pioneer = pioneer;
		this._scene = pioneer.get('main');

		/**
		 * @type {Pioneer.Entity}
		 * @private
		 */
		this._cameraEntity = this._scene.get('camera');

		/**
		 * The entities that are unselectable.
		 * @type {string[]}
		 * @private
		 */
		this._unselectableEntities = [];
		this._previousEntity = null;
		this._currentTarget = null;
		this._isCelsius = false;
		this._transitionComplete = false;

		this.setPickController = this.setPickController.bind(this);
	}

	setIsCelcius(isCelsius) {
		this._isCelsius = isCelsius === 0;
	}

	setIsCelcius(isCelsius) {
		this._isCelsius = isCelsius === 0;
	}

	init() {
		// Set unselectable entities.
		this.setUnselectableEntities(['sun', 'earth', 'moon']);

		// Get starting camera position
		this.startingCamPos = new Pioneer.Vector3();
		SceneHelpers.waitTillEntitiesInPlace(this._scene, ['earth']).then(() => {
			this._cameraEntity.getPositionRelativeToEntity(this.startingCamPos, Pioneer.Vector3.Zero, this._scene.get('earth'));
		});
	}

	/**
	 * Sets which entities are unselectable.
	 * @param {string[]} list
	 */
	setUnselectableEntities(list) {
		this._unselectableEntities = list;
	}

	/**
	 * Flies the camera to the entity with the given options, and then adds a select controller.
	 * @param {string} name
	 */
	async goToEntity(name, param) {
		// Check that the entity is not undefined or we are not already going to the entity.
		if (name === undefined || this._currentTarget === name) {
			return;
		}

		this._currentTarget = name;
        const _e= ENTITY_INFO[name];
		
		// Turn off the always-loading of the previous entity.
		this._previousEntity = this._cameraEntity.getParent();
		if (this._previousEntity !== null) {
			const model = this._previousEntity.get('model');
			if (model !== null) {
				model.setForceLoaded(false);
			}
		}

		// Unset the forced midDistance setting of the camera.
		this._cameraEntity.getComponentByClass(Pioneer.CameraComponent).setMidDistance(undefined);


		// Start loading the entity's resources.
		const entity = this._scene.get(name);
		const model = entity?.get('model');
		if (model) {
			model.setForceLoaded(true);

			// Show loading.
			showLoading('model');

			await model.getLoadedPromise();

			// Hide loading.
			hideLoading('model');
		}

		// Check if the target has changed while loading
		if (this._currentTarget !== name) {
			return;
		}

		// Use different goTo functions depending on the type of entity.
		try {
			console.log("CameraManager::goToEntity", name, entity)
			if (name === 'earth') {
				await this._goToEarth(param);
			} else if (name.startsWith('sc_')) {
				await this._goToSpacecraft(name);
			}
			//TODO change this to use entity stellar
			
			else if (_e &&_e.stellar) {	
				
			 await this._goToPlanet(param,name);
			}
		} catch (e) {
			console.warn(e);
		} finally {
			// Reset the current target.
			this._currentTarget = null;
		}
	}

	/**
	 * Goes to a spacecraft and its comparison.
	 * @param {Entity} spacecraftEntity
	 * @param {Entity} compareEntity
	 */
	async goToComparison(spacecraftEntity, compareEntity) {
		const distance = Cameras.getDistanceToFitEntities(this._cameraEntity, this._cameraEntity.getOrientation(), spacecraftEntity, [compareEntity]) * 1.75;

		await Cameras.goToEntity(this._cameraEntity, spacecraftEntity, {
			distance,
			fixedToParent: true,
			orbiter: true,
			transitionFunction: easeTransition
		});

		this._cameraEntity.getControllerByClass(Pioneer.OrbitController).setPitchAngleLimits(new Pioneer.Interval(-Math.PI / 2 + 0.01, Math.PI / 2 - 0.01));

		const zoomController = this._cameraEntity.get('zoom');
		const minZoom = spacecraftEntity.getOcclusionRadius() * 1.5;
		const maxZoom = (spacecraftEntity.getExtentsRadius() + compareEntity?.getExtentsRadius()) * 20;
		zoomController.setDistanceClamp(new Pioneer.Interval(minZoom, maxZoom));
		//this._setupPickControllerPoI();
	}

	/**
	 * Goes to the planets.
	 * @private
	*/
	
	
	async _goToPlanet(param = {},name) {
		const { time } = param;
		const { queries, is2k, is4k } = appStore.stateSnapshot;
		const { hideUI } = queries;
	    console.log("inside go to planet--name ",name);
		// Make sure the entity is ready.
		await SceneHelpers.waitTillEntitiesInPlace(this._scene, new Set(['sun', name]));
		// const cameraEntity = this._scene.get('camera');
		const planet = this._scene.get(name);

		const sun = this._scene.get('sun');
		const cameraOrientation = new Pioneer.Quaternion();
		const dest = new Pioneer.Vector3();
		planet.getPositionRelativeToEntity(dest, Pioneer.Vector3.Zero, sun);
		dest.neg(dest);
		dest.normalize(dest);
		const planetUp = new Pioneer.Vector3();
		planet.getOrientation().getAxis(planetUp, 2);
		cameraOrientation.setFromAxes(undefined, dest, planetUp);
		let distance = Cameras.getDistanceToFitEntities(this._cameraEntity, cameraOrientation, planet, [planet]);
		const duration = this._cameraEntity.getParent() !== null ? 2.0 : 0.0;
        console.log("planet ?", distance, planet)  //neptune = 152113.10176137617, pluto = NaN
		//TODO this distance should probably be different for differnt sized planets, super wrong for small planets  mercury
		distance *= (hideUI === 'true' && is2k) || is4k ? 1.6 : 1.3; // Add some margin

		const position = new Pioneer.Vector3();
		
    this._cameraEntity.getPositionRelativeToEntity(position, Pioneer.Vector3.Zero, planet);
		
	
		if (this._previousEntity !== null) {
			// Look at the entity.
			const destination = new Pioneer.Vector3();
			this._cameraEntity.getPositionRelativeToEntity(destination, Pioneer.Vector3.Zero, planet);
	
			await Cameras.goToEntity(this._cameraEntity, planet, {
				duration: duration / 6.0,
				up: true,
				distance: destination.magnitude()
			});
		}
    console.log("dest", dest, distance, position)
		dest.mult(dest, distance);
    let options
    if(isNaN(distance)){
      options = {
        up: true,
        duration: 1,
        distance: 10000
      }
    } else {
      options = {
        up: true,
        fixedToParent: true,
        destination: dest,
        transitionFunction: Transitions.jumpToLocationOnSphere.bind(undefined, 5, planet.getExtentsRadius(), true, planet)
      };
    }
	
		if (time >= 0) {
			options.duration = time;
		}
	
		if (position.magnitude() > 0) {
			position.normalize(position);
			position.mult(position, distance);
			options.destination = position;
		}
	
		// check options here
		await Cameras.goToEntity(this._cameraEntity, planet, options);
	
		// Add zoom limits to the camera. //TODO:Remove this if we want to be like the solar system.
		const zoomController = this._scene.get('camera', 'zoom');
		if (zoomController instanceof Pioneer.ZoomController) {
			zoomController.setUseSpheroidRadiusForDistance(true);
			zoomController.setDistanceClamp(new Pioneer.Interval(100, planet.getExtentsRadius() * 50));
		}
	
		// Spin controller.
		const align = this._cameraEntity.getControllerByType('align');
		const spin = this._cameraEntity.addController('spin', undefined, align);
		if (spin instanceof Pioneer.SpinController) {
			spin.setAxis(Pioneer.Vector3.ZAxis, true);
			spin.setRate(0.01);
			spin.setUsingRealTime(true);
			spin.setRotatingPosition(true);
		}
		// Tap controller.
		const tap = this._cameraEntity.addController('tap');
		if (tap instanceof Pioneer.TapController) {
			tap.setTapCallback(() => {
				this._cameraEntity.removeController(spin);
				this._cameraEntity.removeController(tap);
			});
		}
	
		// Make the camera smoother when zoomed in.
		const orbitController = this._scene.get('camera', 'orbit');
		if (orbitController instanceof Pioneer.OrbitController) {
			orbitController.slowWhenCloseToParent(true);
		}
	}
	ChangePlanetTexture_HD=(planetName,isHD)=>{
		showLoading("texture");
		const planet= this._scene.get(planetName)
		const planetSpheroid = planet.get('spheroidLOD');
		if(isHD)
		{planetSpheroid.setTexture('color', planetSpheroid.getTextureUrl('color'), [4096])
		}
	    else
		{planetSpheroid.setTexture('color', planetSpheroid.getTextureUrl('color'), [512])
		}
		new Promise(resolve => planetSpheroid.getLoadedPromise().then(() => 
			{
		setTimeout(() => {
	
			resolve()
			hideLoading("texture");
		  }, 500);
		}
		));	
		};
	ResetPlanetTexture=(planetName)=>{
		if(!planetName)
			return;
		const planet= this._scene.get(planetName)
		const planetSpheroid = planet.get('spheroidLOD');
		const texture_arr =["neptune", "uranus"].includes(planetName)?256 :512  ;
		if (planet.id != "venus")
		{planetSpheroid.setTexture('color', planetSpheroid.getTextureUrl('color'), [texture_arr]);
		}
	    else
		{
		planetSpheroid.setTexture('color', '$STATIC_ASSETS_URL/maps/venus/surface_$SIZE_$FACE.png', [texture_arr]);
		 }
	};
	/**
	 * Goes to the earth.
	 * @private
	 */
	async _goToEarth(param = {}) {
		const { latitude, longitude, time, isGeostationary, resetCameraAngle } = param;
		const { queries, is2k, is4k } = appStore.stateSnapshot;
		const { hideUI } = queries;

		// Make sure the entity is ready.
		await SceneHelpers.waitTillEntitiesInPlace(this._scene, new Set(['earth', 'sun']));

		const earth = this._scene.get('earth');
		const sun = this._scene.get('sun');

		const cameraOrientation = new Pioneer.Quaternion();
		const dest = new Pioneer.Vector3();
		earth.getPositionRelativeToEntity(dest, Pioneer.Vector3.Zero, sun);
		dest.neg(dest);
		dest.normalize(dest);
		const planetUp = new Pioneer.Vector3();
		earth.getOrientation().getAxis(planetUp, 2);
		cameraOrientation.setFromAxes(undefined, dest, planetUp);
		let distance = Cameras.getDistanceToFitEntities(this._cameraEntity, cameraOrientation, earth, [earth]);
		const duration = this._cameraEntity.getParent() !== null ? 2.0 : 0.0;

		// Zoom camera out if 2k and hideUI
		distance *= (hideUI === 'true' && is2k) || is4k ? 1.6 : 1.3; // Add some margin

		const position = resetCameraAngle === true ? this.startingCamPos : new Pioneer.Vector3();

		if (latitude && longitude) {
			const latlon = new Pioneer.LatLonAlt(Pioneer.MathUtils.degToRad(latitude), Pioneer.MathUtils.degToRad(longitude), 0);
			SceneHelpers.llaToXYZ(this.startingCamPos, earth, latlon, false);
		} else if (resetCameraAngle === false) {
			this._cameraEntity.getPositionRelativeToEntity(position, Pioneer.Vector3.Zero, earth);
		}

		if (isGeostationary) {
			distance *= 5;
			position.addMult(position.y, position.z, 0.66);
		}

		if (this._previousEntity !== null) {
			// Look at the entity.
			const destination = new Pioneer.Vector3();
			this._cameraEntity.getPositionRelativeToEntity(destination, Pioneer.Vector3.Zero, earth);
			await Cameras.goToEntity(this._cameraEntity, earth, {
				duration: duration / 6.0,
				up: true,
				distance: destination.magnitude()
			});
		}

		dest.mult(dest, distance);
		const options = {
			up: true,
			fixedToParent: false,
			destination: dest,
			transitionFunction: Transitions.jumpToLocationOnSphere.bind(undefined, 5, earth.getExtentsRadius(), true, earth)
		};

		if (time >= 0) {
			options.duration = time;
		}

		if (position.magnitude() > 0) {
			position.normalize(position);
			position.mult(position, distance);
			options.destination = position;
		}

		// check options here
		await Cameras.goToEntity(this._cameraEntity, earth, options);

		// Add zoom limits to the camera.
		const zoomController = this._scene.get('camera', 'zoom');
		if (zoomController instanceof Pioneer.ZoomController) {
			zoomController.setUseSpheroidRadiusForDistance(true);
			zoomController.setDistanceClamp(new Pioneer.Interval(100, earth.getExtentsRadius() * 50));
		}

		// Spin controller.
		const align = this._cameraEntity.getControllerByType('align');
		const spin = this._cameraEntity.addController('spin', undefined, align);
		if (spin instanceof Pioneer.SpinController) {
			spin.setAxis(Pioneer.Vector3.ZAxis, true);
			spin.setRate(-0.01);
			spin.setUsingRealTime(true);
			spin.setRotatingPosition(true);
		}
		// Tap controller.
		const tap = this._cameraEntity.addController('tap');
		if (tap instanceof Pioneer.TapController) {
			tap.setTapCallback(() => {
				this._cameraEntity.removeController(spin);
				this._cameraEntity.removeController(tap);
			});
		}

		// Make the camera smoother when zoomed in.
		const orbitController = this._scene.get('camera', 'orbit');
		if (orbitController instanceof Pioneer.OrbitController) {
			orbitController.slowWhenCloseToParent(true);
		}
	}

	/**
	 * Goes to a spacecraft.
	 * @param {string} name
	 * @private
	 */
	async _goToSpacecraft(name) {
		// Make sure the entity is ready.
		await SceneHelpers.waitTillEntitiesInPlace(this._scene, new Set([name, 'earth']));

		const entity = this._scene.get(name);
		let distance = entity.getExtentsRadius() * 3;
		const duration = this._cameraEntity.getParent() !== null ? 1.5 : 0.0;
		const isGeostationary = SPACECRAFT_GEOSTATIONARY.includes(name);
		const prevEntityName = this._previousEntity?.getName();

		// Get instrument data.
		const { parentOfInstrument, childInstruments } = getSpacecraftInstrumentData(name) || {};
		const { parentOfInstrument: prevParentOfInstrument, childInstruments: prevChildInstruments } = getSpacecraftInstrumentData(prevEntityName) || {};

		const instrumentToSiblingInstrument = prevParentOfInstrument && prevParentOfInstrument === parentOfInstrument;
		const instrumentToItsParent = prevParentOfInstrument === name && childInstruments?.find(({ instrumentId }) => instrumentId === prevEntityName);
		const parentToItsInstrument = prevChildInstruments?.find(({ instrumentId }) => instrumentId === name) && parentOfInstrument === prevEntityName;

		const fromEarth = this._previousEntity.getName() === 'earth';
		const betweenInstruments = (instrumentToSiblingInstrument || instrumentToItsParent || parentToItsInstrument);

		let orbiter = true;


		// Determine radii
		const minRadius = 1.2 * entity.getOcclusionRadius();
		let maxRadius = 100 * entity.getExtentsRadius();
		if (parentOfInstrument) {
			orbiter = false;
			maxRadius = 3 * entity.getParent().getExtentsRadius();
			distance *= 2;
		}

		distance = Pioneer.MathUtils.clamp(distance, minRadius, maxRadius);
		const destination = new Pioneer.Vector3();
		destination.mult(entity.getPosition(), distance * 20 / entity.getPosition().magnitude());
		// if there's a previous entity
		// and it's not the new entity we're at !(ISS -> ISS)
		if (prevEntityName !== name && !betweenInstruments && !isGeostationary) {
			if (fromEarth) {
				// From Earth
				await Cameras.goToEntity(this._cameraEntity, entity, {
					destination,
					fixedToParent: true,
					up: false,
					duration,
					transitionFunction: Transitions.jumpToLocationOnSphere.bind(undefined, 5, this._previousEntity.getExtentsRadius(), true, entity)
				});
			} else {
				// SC to SC needs to go to Earth first
				await this._goToEarth();
			}
		}

		// Position the camera to have a nice view of the entity.
		const destinationX = new Pioneer.Vector3();
		const destinationY = new Pioneer.Vector3();
		const destinationZ = new Pioneer.Vector3();
		destinationZ.normalize(entity.getPosition());
		destinationY.setNormalTo(destinationZ, entity.getVelocity());
		destinationY.neg(destinationY);
		destinationX.cross(destinationZ, destinationY);

		if (isGeostationary) {
			destination.addMult(destinationZ, destinationX, 0.05);
			destination.mult(destination, distance / destination.magnitude() * 2.5);
			destination.rotateInverse(entity.getOrientation(), destination);
		} else {
			destination.addMult(destinationY, destinationZ, 0.66);
			destination.addMult(destination, destinationX, 0.66);
			destination.mult(destination, distance / destination.magnitude());
			destination.rotateInverse(entity.getOrientation(), destination);
			destinationZ.rotateInverse(entity.getOrientation(), destinationZ);
		}

		if (name === 'sc_iss_emit') {
			// EMIT hole view - set magnitude of final destination
			destination.setMagnitude(Pioneer.Vector3.YAxisNeg, distance);
		}

		await Cameras.goToEntity(this._cameraEntity, entity, {
			duration,
			destination,
			distance,
			...!betweenInstruments && { destinationUp: destinationZ },
			destinationInFocusFrame: true,
			orbiter,
			fixedToParent: true,
			transitionFunction: instrumentToSiblingInstrument ? easeTransition : Transitions.jumpToLocationOnSphere.bind(undefined, 0, entity.getExtentsRadius(), false, entity)
		});

		// Zoom clamping
		this._cameraEntity.get('zoom').setDistanceClamp(new Pioneer.Interval(minRadius, maxRadius));

		this._cameraEntity.get('orbit').setPitchAngleLimits(new Pioneer.Interval(-Math.PI / 2 + 0.01, Math.PI / 2 - 0.01));
	}


	/**
	 * Goes to a video patch.
	 * @param {object} videoOrEvent
	 */
	async goToPatch(videoOrEvent) {
		// console.log(videoOrEvent); //Todo: methane plume event
		const { urlParam, id, boundaries: { north, south, east, west }, distance_from_event, zoom_factor} = videoOrEvent;
		const earth = this._scene.get('earth');
		const placemarkId = `event_patch_${urlParam || id}`;
		let placemarkEntity = this._scene.get(placemarkId);
		const lat = (south + north) / 2;
		const lon = (west + east) / 2;
		if (!placemarkEntity) {
			placemarkEntity = Placemarks.addPlacemark(placemarkId, '', earth.getComponentByClass(Pioneer.SpheroidComponent), lat, lon, 0);
		}
		let zoomFactor = zoom_factor || 1.0;
		let distanceFromEventFixed = distance_from_event || 20;

    console.log("goToPatch", distanceFromEventFixed, zoomFactor)
		await this.goToDataPatchWithZoom(north, south, east, west, placemarkEntity, distanceFromEventFixed, zoomFactor);
	}

	//TODO Merge VideoPatchPOI

	/**
	 * Goes to a data patch.
	 * @param {any} poi
	 */
	 async goToPoiPatch(poi) {
		const earth = this._scene.get('earth');
		let placemarkEntityPoI = this._scene.get('poi_' + poi.id);
		if (!placemarkEntityPoI) {
			const lat = Number(poi.center.split(',')[0]);
			const lon = Number(poi.center.split(',')[1]);
			placemarkEntityPoI = Placemarks.addPlacemark('poi_' + poi.id, '', earth.getComponentByClass(Pioneer.SpheroidComponent), lat, lon, 0);
		} 

		//To check if there is a Key named "distance_from_poi" a key added to poi.json file
		// camera zoom
		//  let distanceFromPoiFixed = 0;
		//  if(poi.hasOwnProperty("distance_from_poi"))
		//  {
		const distanceFromPoiFixed = poi.distance_from_poi;
		await this.goToDataPatchWithZoom(poi.north_boundary, poi.south_boundary, poi.east_boundary, poi.west_boundary, placemarkEntityPoI, distanceFromPoiFixed, poi.zoom_factor);
	}

	/**
	 * Goes to a data patch.
	 * @param {any} gmap
	 */
	 async goToGmapPatch(gmap) {
		const earth = this._scene.get('earth');
		let placemarkEntityGmap = this._scene.get('gmap_' + gmap.id);
		if (!placemarkEntityGmap) {
			const lat = Number(gmap.center.split(',')[0]);
			const lon = Number(gmap.center.split(',')[1]);
			placemarkEntityGmap = Placemarks.addPlacemark('gmap_' + gmap.id, '', earth.getComponentByClass(Pioneer.SpheroidComponent), lat, lon, 0);
		}


		const distanceFromGmapFixed = gmap.distance_from_gmap;
		await this.goToDataPatchWithZoom(gmap.north_boundary, gmap.south_boundary, gmap.east_boundary, gmap.west_boundary, placemarkEntityGmap, distanceFromGmapFixed, gmap.zoom_factor);
	}

	/**
	 * Goes to a data patch either event or video.
	 * @param {*} northBoundary
	 * @param {*} southBoundary
	 * @param {*} patchEntity
	 */
	async goToDataPatch(northBoundary, southBoundary, eastBoundary, westBoundary, placemarkEntity,distanceFromEventFixed,zoomFactorEvent) {
		placemarkEntity.setEnabled(true);

		// Disabling the element is hacky and should be fixed with better state management / element organization.
		placemarkEntity.get('div').setEnabled(false);
	
		const camera = this._scene.get('camera', 'camera');
		if (!(camera instanceof Pioneer.CameraComponent)) {
			throw new Error();
		}

		lightStore.setGlobalState({ fill: true });

		const earth = this._scene.get('earth');

		// set the mid distance for mobile powerscaling
		camera.setMidDistance(120000);

		if (this._cameraEntity.getParent() !== null) {
			// Enable the eventPlacemark so the camera can transtion
			this._cameraEntity.getParent().setEnabled(true);
		}

		// Get some good lat and lon distances so that event looks good on the screen.
		const latAngularDistance = Pioneer.MathUtils.degToRad(northBoundary - southBoundary);
		const lonAngularDistance = Pioneer.MathUtils.degToRad(eastBoundary - westBoundary + (eastBoundary < westBoundary ? 360 : 0));
		
		const latFOVFactor = zoomFactorEvent / Math.atan(this._scene.get('camera', 'camera').getVerticalFieldOfView() / 2.0);
		const lonFOVFactor = zoomFactorEvent / Math.atan(this._scene.get('camera', 'camera').getHorizontalFieldOfView() / 2.0);
		
		const latDistance = latAngularDistance * earth.getExtentsRadius() * latFOVFactor;
		const lonDistance = lonAngularDistance * earth.getExtentsRadius() * lonFOVFactor;
		const distanceFromEvent = Math.sqrt(latDistance * latDistance + lonDistance * lonDistance) * 0.5;
		
		// Wait until the placemark and the earth has a valid position and orientation.
		await SceneHelpers.waitTillEntitiesInPlace(this._scene, ['earth', placemarkEntity.getName()]);

		// Position camera destination to be above the event.
		const eventPosition = placemarkEntity.getPosition();
		const destination = new Pioneer.Vector3();
		destination.normalize(eventPosition);
		destination.mult(destination, distanceFromEvent);

		await Cameras.goToEntity(this._cameraEntity, placemarkEntity, {
			destination,
			fixedToParent: true,
			up: false,
			duration: 3,
			transitionFunction: Transitions.jumpToLocationOnSphere.bind(0, 5, earth.getExtentsRadius(), true, earth)
		});

		// Make the camera align to the placemark's north-east orientation.
		const alignController = this._scene.get('camera', 'align');
		if (!(alignController instanceof Pioneer.AlignController)) {
			throw new Error();
		}
		alignController.setSecondaryAlignType('align');
		alignController.setSecondaryAxis(Pioneer.Vector3.ZAxis);
		alignController.setSecondaryTargetEntity(placemarkEntity.getName());
		alignController.setSecondaryTargetAxis(Pioneer.Vector3.YAxis);
		// Make the camera orbit the placemark with nice limits on its axes.
		const orbitController = this._scene.get('camera', 'orbit');
		if (!(orbitController instanceof Pioneer.OrbitController)) {
			throw new Error();
		}
		orbitController.setYawAxisType('y-axis');
		orbitController.setPitchAxisType('z-axis');
		orbitController.setYawAngleLimits(new Pioneer.Interval(-Math.PI / 4, Number(Math.PI) / 4));
		orbitController.setPitchAngleLimits(new Pioneer.Interval(-Math.PI / 4, Number(Math.PI) / 4));
		// Add zoom limits to the camera.
		const zoomController = this._scene.get('camera', 'zoom');
		if (!(zoomController instanceof Pioneer.ZoomController)) {
			throw new Error();
		}
		zoomController.setDistanceClamp(new Pioneer.Interval(distanceFromEventFixed, earth.getExtentsRadius() * 3));
	}

  /**
	 * Goes to a data patch either poi or video.
	 * @param {*} northBoundary
	 * @param {*} southBoundary
	 * @param {*} patchEntity
	 */
	 async goToDataPatchWithZoom(northBoundary, southBoundary, eastBoundary, westBoundary, placemarkEntity,distanceFromPoiFixed,zoomFactorPoi) {
		placemarkEntity.setEnabled(true);

		// Disabling the element is hacky and should be fixed with better state management / element organization.
		placemarkEntity.get('div').setEnabled(false);
	
		const camera = this._scene.get('camera', 'camera');
		if (!(camera instanceof Pioneer.CameraComponent)) {
			throw new Error();
		}

		lightStore.setGlobalState({ fill: true });

		const earth = this._scene.get('earth');

		// set the mid distance for mobile powerscaling
		camera.setMidDistance(120000);

		if (this._cameraEntity.getParent() !== null) {
			// Enable the eventPlacemark so the camera can transtion
			this._cameraEntity.getParent().setEnabled(true);
		}

		// Get some good lat and lon distances so that event looks good on the screen.
		const latAngularDistance = Pioneer.MathUtils.degToRad(northBoundary - southBoundary);
		const lonAngularDistance = Pioneer.MathUtils.degToRad(eastBoundary - westBoundary + (eastBoundary < westBoundary ? 360 : 0));
		const latFOVFactor = zoomFactorPoi / Math.atan(this._scene.get('camera', 'camera').getVerticalFieldOfView() / 2.0);
		const lonFOVFactor = zoomFactorPoi / Math.atan(this._scene.get('camera', 'camera').getHorizontalFieldOfView() / 2.0);
		const latDistance = latAngularDistance * earth.getExtentsRadius() * latFOVFactor;
		const lonDistance = lonAngularDistance * earth.getExtentsRadius() * lonFOVFactor;
		const distanceFromEvent = Math.sqrt(latDistance * latDistance + lonDistance * lonDistance) * 0.5;
		
		// Wait until the placemark and the earth has a valid position and orientation.
		await SceneHelpers.waitTillEntitiesInPlace(this._scene, ['earth', placemarkEntity.getName()]);

		// Position camera destination to be above the event.
		const eventPosition = placemarkEntity.getPosition();
		const destination = new Pioneer.Vector3();
		destination.normalize(eventPosition);
		destination.mult(destination, distanceFromEvent);

		await Cameras.goToEntity(this._cameraEntity, placemarkEntity, {
			destination,
			fixedToParent: true,
			up: false,
			duration: 3,
			transitionFunction: Transitions.jumpToLocationOnSphere.bind(0, 5, earth.getExtentsRadius(), true, earth)
		});

		// Make the camera align to the placemark's north-east orientation.
		const alignController = this._scene.get('camera', 'align');
		if (!(alignController instanceof Pioneer.AlignController)) {
			throw new Error();
		}
		alignController.setSecondaryAlignType('align');
		alignController.setSecondaryAxis(Pioneer.Vector3.ZAxis);
		alignController.setSecondaryTargetEntity(placemarkEntity.getName());
		alignController.setSecondaryTargetAxis(Pioneer.Vector3.YAxis);
		// Make the camera orbit the placemark with nice limits on its axes.
		const orbitController = this._scene.get('camera', 'orbit');
		if (!(orbitController instanceof Pioneer.OrbitController)) {
			throw new Error();
		}
		orbitController.setYawAxisType('y-axis');
		orbitController.setPitchAxisType('z-axis');
		orbitController.setYawAngleLimits(new Pioneer.Interval(-Math.PI / 4, Number(Math.PI) / 4));
		orbitController.setPitchAngleLimits(new Pioneer.Interval(-Math.PI / 4, Number(Math.PI) / 4));
		// Add zoom limits to the camera.
		const zoomController = this._scene.get('camera', 'zoom');
		if (!(zoomController instanceof Pioneer.ZoomController)) {
			throw new Error();
		}
		zoomController.setDistanceClamp(new Pioneer.Interval(distanceFromPoiFixed, earth.getExtentsRadius() * 3));
	}

	 /**
	 * Goes to a data patch either gmap or video.
	 * @param {*} northBoundary
	 * @param {*} southBoundary
	 * @param {*} patchEntity
	 */
	//   async goToDataPatchGmap(northBoundary, southBoundary, eastBoundary, westBoundary, placemarkEntityGmap,distanceFromGmapFixed,zoomFactorGmap) {
	// 	placemarkEntityGmap.setEnabled(true);
	// 	// Disabling the element is hacky and should be fixed with better state management / element organization.
	// 	placemarkEntityGmap.get('div').setEnabled(false);
	// 	const camera = this._scene.get('camera', 'camera');
	// 	if (!(camera instanceof Pioneer.CameraComponent)) {
	// 		throw new Error();
	// 	}

	// 	const cameraEntity = this._scene.get('camera');
	// 	const earth = this._scene.get('earth');
	// 	earth.get('atmosphere').setEnabled(false);

	// 	// set the mid distance for mobile powerscaling
	// 	camera.setMidDistance(120000);
	// 	// Get some good lat and lon distances so that event looks good on the screen.
	// 	const latAngularDistance = Pioneer.MathUtils.degToRad(northBoundary - southBoundary);
	// 	const lonAngularDistance = Pioneer.MathUtils.degToRad(eastBoundary - westBoundary + (eastBoundary < westBoundary ? 360 : 0));
		
	// 	const latFOVFactor = zoomFactorGmap / Math.atan(this._scene.get('camera', 'camera').getVerticalFieldOfView() / 2.0);
	// 	const lonFOVFactor = zoomFactorGmap / Math.atan(this._scene.get('camera', 'camera').getHorizontalFieldOfView() / 2.0);
	   
	// 	const latDistance = latAngularDistance * earth.getExtentsRadius() * latFOVFactor;
	// 	const lonDistance = lonAngularDistance * earth.getExtentsRadius() * lonFOVFactor;
	// 	const distanceFromGmap = Math.sqrt(latDistance * latDistance + lonDistance * lonDistance) * 0.5;
	// 	// Wait until the placemark and the earth has a valid position and orientation.
	// 	await SceneHelpers.waitTillEntitiesInPlace(this._scene, new Set([placemarkEntityGmap.getName(), 'earth']));
	// 	// Position camera destination to be above the gmap.
	// 	const gmapPosition = placemarkEntityGmap.getPosition();
	// 	const destination = new Pioneer.Vector3();
	// 	destination.normalize(gmapPosition);
	// 	destination.mult(destination, distanceFromGmap);
	// 	await Cameras.goToEntity(cameraEntity, placemarkEntityGmap, {
	// 		destination: destination,
	// 		fixedToParent: true,
	// 		up: false,
	// 		duration: 3,
	// 		transitionFunction: Transitions.jumpToLocationOnSphere.bind(0, 5, earth.getExtentsRadius(), true, earth)
	// 	});
	// 	// Make the camera align to the placemark's north-east orientation.
	// 	const alignController = this._scene.get('camera', 'align');
	// 	if (!(alignController instanceof Pioneer.AlignController)) {
	// 		throw new Error();
	// 	}
	// 	alignController.setSecondaryAlignType('align');
	// 	alignController.setSecondaryAxis(Pioneer.Vector3.ZAxis);
	// 	alignController.setSecondaryTargetEntity(placemarkEntityGmap);
	// 	alignController.setSecondaryTargetAxis(Pioneer.Vector3.YAxis);
	// 	// Make the camera orbit the placemark with nice limits on its axes.
	// 	const orbitController = this._scene.get('camera', 'orbit');
	// 	if (!(orbitController instanceof Pioneer.OrbitController)) {
	// 		throw new Error();
	// 	}
	// 	orbitController.setYawAxisType('y-axis');
	// 	orbitController.setPitchAxisType('z-axis');
	// 	orbitController.setYawAngleLimits(new Pioneer.Interval(-Math.PI / 4, Number(Math.PI) / 4));
	// 	orbitController.setPitchAngleLimits(new Pioneer.Interval(-Math.PI / 4, Number(Math.PI) / 4));
	// 	// Add zoom limits to the camera.
	// 	const zoomController = this._scene.get('camera', 'zoom');
	// 	if (!(zoomController instanceof Pioneer.ZoomController)) {
	// 		throw new Error();
	// 	}

		
	// 		zoomController.setDistanceClamp(new Pioneer.Interval(distanceFromGmapFixed, earth.getExtentsRadius() * 3));
	// }

	/**
	 * Offsets Pioneer Viewport Left by percent.
	 * Used to offset globe during desktop charts and modal videos.
	 * Additionally it offsets the dateString and colorbar elements to match the globe.
	 * @param {number} percent
	 */
	offsetPioneerViewportLeft(percent) {
		const root = document.getElementById('root');

		// Set CSS variable on root element.
		root.style.setProperty('--viewport-offset', percent.toString());
	}
	/**
	 * Sets the pick controller.
	 * @private
	 */
	setPickController() {
		const pickController = this._cameraEntity.getController('pick') ?? this._cameraEntity.addController('pick');
		const earth = this._scene.get('earth');
		pickController.setPickedEntity(earth);
		pickController.setTriggerOnHover(false);

		pickController.setCallback(position => this._calcDataFromPosition(position));
	}

	/**
	 * Unsets the pick controller.
	 */
	unsetPickController() {
		const pickController = this._cameraEntity.getController('pick');
		pickController?.setCallback(null);
	}

	/**
	 * - Inputs an xy position vector,
	 * - Applies latlong conversions to allow it to sample the value from the same spot on the 2d canvas map
	 * - Uses readoutData values to calculate data values and thumb percentage
	 * - Sets them if valid
	 * @param {Vector2} position
	 */
	_calcDataFromPosition(position) {
		const { getManager, getTileManager, pioneer } = globalRefs;
		const { datasetHasAnimation, isDatasetPaused, currentDataset } = datasetStore.stateSnapshot;
		const { isCelcius } = datareadStore.stateSnapshot;
		const { readoutData, externalId } = currentDataset || {};

		const allowSample = (!datasetHasAnimation || isDatasetPaused) && readoutData && (position?.x && position.y);

		// Return if sample not allowed.
		if (!allowSample) {
			return;
		}

		const earth = this._scene.get('earth');
		const soilMoistAndSal = currentDataset.externalId === 'smapSmSalinity8Day';
		const precipitation = currentDataset.externalId === 'precipitationToday';

		// Format latitude and longitude
		const xy = Pioneer.Vector2.pool.get();
		console.log('x, y', xy)
		const lla = Pioneer.LatLonAlt.pool.get();
		console.log('lla', lla)
		const positionInFrame = Pioneer.Vector3.pool.get();
		console.log('positionInFrame', positionInFrame)
		positionInFrame.rotateInverse(earth.getOrientation(), position);
		earth.get('spheroid').llaFromXYZ(lla, positionInFrame);

		xy.x = (Pioneer.MathUtils.radToDeg(lla.lon) + 180.0) / 360 * 1440;
		console.log('x',xy.x )

		console.log('lla.lon', Pioneer.MathUtils.radToDeg(lla.lon), lla.lon)
		xy.y = (1 - (Pioneer.MathUtils.radToDeg(lla.lat) + 90.0) / 180) * 720;


		const [dataR = 1, dataG, dataB] = getManager('dataset').getValueFromCanvas(xy.x, xy.y) || [];
		const noData = dataR === 1;

		// If the first data item is 1, we've sampled a black area. Release the vector and return early.
		if (noData && !getTileManager(externalId)) {
			Pioneer.LatLonAlt.pool.release(lla);
			Pioneer.Vector2.pool.release(xy);
			Pioneer.Vector3.pool.release(positionInFrame);
			return;
		}

		// Get stored cursor position.
		const cursorPosition = pioneer.getInput().getCursorPosition();

		// Unique case: If precipitation data, apply individual calculation and rect style.
		if (precipitation) {
			const { rain, snow } = readoutData;
			const { scalingFactor: rainScale, units: rainUnits } = rain;
			const { scalingFactor: snowScale, units: snowUnits } = snow;


			// Specific precipitation equations contact: Jeff Hall.
			const readValue = dataG + 256 * dataB;
			const isSnow = dataR === 2;

			const scale = isSnow ? snowScale : rainScale;
			const units = isSnow ? snowUnits : rainUnits;

			const dataValue = readValue * scale;

			// Format the value with the unitss for setReadoutData
			const valueString = `${formatNumber(dataValue, 2)} ${units}`;

			// add latlon values for every vital sign value -zahrah
			let latlonx = Pioneer.MathUtils.radToDeg(lla.lat)
			let latlony = Pioneer.MathUtils.radToDeg(lla.lon)
			let decimalPlace = 2
			const valueLatLonStringx = `${Math.abs(latlonx).toFixed(decimalPlace)} ° ${latlonx > 0 ? 'N' : 'S'}`;
			const valueLatLonStringy = `${Math.abs(latlony).toFixed(decimalPlace)} ° ${latlony > 0 ? 'E' : 'W'}`;
			const valueLatLonText = "" + valueLatLonStringx + "" + ", " + valueLatLonStringy

			// add latlon values readout -zahrah
			setReadoutData(valueString, valueLatLonText, cursorPosition);
			//console.log("readout", valueString, valueLatLonText, this._app.getCursorPosition())
			
			// Get the nearest dataLookup index and pass to setPrecipitationColorRectStyle.
			const nearestIndex = getNearestDataIndex(dataValue);
			setPrecipitationColorRectStyle(nearestIndex, isSnow, this._pioneer);

			Pioneer.LatLonAlt.pool.release(lla);
			Pioneer.Vector2.pool.release(xy);
			Pioneer.Vector3.pool.release(positionInFrame);

			return;
		}

		// Get readout data vars.
		const { bottom, variation, numOffset, scalingFactor, units, dps } = readoutData;

		// Explorer equation - value = (int)(color.g * 255 * 255) + (int)(color.b * 255)) * vD.scalingFactor + vD.numOffset
		// Declare vars.
		let readValue = ((dataB + (256 * dataG)) * scalingFactor) + numOffset;
		let percentScale = null;
		let dataUnits = units;
		let decimalPlaces = dps ?? 1;
		let isDoubleColorBar = false;
		let isUpper = true;

		if (getTileManager(externalId)) {
			readValue = getTileManager(externalId)?.calculateDataFromColor();
			if (readValue === null) {
				return;
			}
		}

		// Calculate % value before celsius conversion.
		if (!isNaN(readValue)) {
			percentScale = Pioneer.MathUtils.clamp((readValue - bottom) / (variation), 0, 1); // Values can exceed colorbar scale (GFO, SSHA, etc.)
		}

		if (soilMoistAndSal) {
			if (readValue >= 1001) { // NaN returns false
				// WATER - salinity
				dataUnits = 'وحدة ملوحة عملية'; // is there somewhere that explains this?
				readValue *= 0.01;
				decimalPlaces = 1;
				percentScale = Pioneer.MathUtils.clamp((readValue - 30) / 10, 0, 1); // Range of 30-40 PSU
				isUpper = false;
			} else {
				// Land cm3/cm3
				readValue *= 0.001;
				percentScale = Pioneer.MathUtils.clamp((readValue - 0) / 0.6, 0, 1); // Range of 0.0 - 0.6 cm3/cm3
				isUpper = true;
			}

			// Trigger offsets for double color bar
			isDoubleColorBar = true;
		} else if (dataUnits === 'م°' && !isCelcius) {
			// Convert to Fahrenheit to match color bar.
			dataUnits = 'ف°';
			// Fahrenheit = Celsius * 1.8 + 32
			readValue = readValue * 1.8 + 32;
		} else if (dataUnits === 'ف°' && isCelcius) {
			// Convert to Celsius to match color bar.
			//units = '°C';   // to change to arabic celsius
			dataUnits = 'م°';
			// Celsius = (Fahrenheit - 32) / 1.8
			readValue = (readValue - 32) / 1.8;
		}

		// Validation.
		if (percentScale !== null && !isNaN(percentScale) && readValue !== null && !isNaN(readValue)) {
			const valueString = `${formatNumber(readValue, decimalPlaces)}`;
			const dataUnit =  `${dataUnits}`
			// add latlon values for every vital sign value -zahrah
			let latlonx = Pioneer.MathUtils.radToDeg(lla.lat)
			let latlony = Pioneer.MathUtils.radToDeg(lla.lon)
			let decimalPlace = 2
			const valueLatLonStringx = `${Math.abs(latlonx).toFixed(decimalPlace)} ° ${latlonx > 0 ? 'N' : 'S'}`;
			const valueLatLonStringy = `${Math.abs(latlony).toFixed(decimalPlace)} ° ${latlony > 0 ? 'E' : 'W'}`;
			const valueLatLonText = "" + valueLatLonStringx + "" + ", " + valueLatLonStringy
			// Set colorbar value position and readout reading.
      // subtract from 1 to invert for rtl colorbar !! super important !!
			setColorRectStyle(1 - percentScale, isDoubleColorBar, isUpper);
			// add latlon values readout -zahrah
			// setReadoutData(valueString, valueLatLonText, cursorPosition);
			setReadoutData(valueString, valueLatLonText, cursorPosition, dataUnit);
			//console.log("readout", valueString, valueLatLonText, this._app.getCursorPosition())
		}

		Pioneer.LatLonAlt.pool.release(lla);
		Pioneer.Vector2.pool.release(xy);
		Pioneer.Vector3.pool.release(positionInFrame);
	}
}

export default CameraManager;
