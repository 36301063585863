/* eslint-disable camelcase */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable array-element-newline */
import { useEffect } from 'react';
import { useParams, redirect } from 'react-router-dom';
import { useSnapshot } from 'valtio';

import { Config } from '../../config/config';
import { HOME_PAGE } from '../../config/constants';

import { SPACECRAFT_DATA } from '../../data/spacecraft_data_ar';

import { calcPrefixFromDate, getDateFromUTCDateStr, getLatestDates, getDatasetFromVitalsData, getUrlTime, getDatasetIdFromParam, hasAssociatedSpacecraftId, getSpacecraftConstellation, getRelatedIdsFromVideoParam } from '../../helpers/tools';
import { datasetStore, eventsStore, spacecraftStore, poiStore, gmapStore } from '../../managers/globalState';
import { getEventParamFromEvent, getYearFromOldEventParam, parseEvent, sortEvents, updateYearIndex } from '../../helpers/processEvents';
import { getAllYearsPoI } from '../../helpers/processPoI';
import { getAllYearsGmap } from '../../helpers/processGmap';
import { hideLoading, showLoading } from '../loading';


/**
 * Fetches the spacecraft data.
 * @param {number} urlTime
 */
const fetchSpacecraftData = urlTime => {
  // Show loading state.
  showLoading('spacecraft-data');

  // fetch(`${Config.dynamicAssetsUrl}/earth/api/mission.json?r=${urlTime}`)
  fetch(`${Config.baseCe2s2Url}/api/v1/missions/?category=200&per_page=-1&r=${urlTime}`)
    .then(response => response.json())
    .then(({ items: spacecraftList }) => {
      // Add spacecraft data to spacecraft list if it doesn't exist.
      if (Array.isArray(spacecraftList)) {
        for (const spacecraftName in SPACECRAFT_DATA) {
          // eslint-disable-next-line camelcase
          const existingMission = spacecraftList.find(({ external_id }) => external_id === spacecraftName);

          if (existingMission === undefined) {
            spacecraftList.push(SPACECRAFT_DATA[spacecraftName]);
          }
        }
      }

      // Build a list of all spacecraft ids.
      const allSpacecraftIds = [];

      // Add our spacecraftId and instrument props to the spacecraft list.
      const filteredSpacecraftList = spacecraftList.filter(spacecraft => {
        const { external_id: spacecraftApiName } = spacecraft;
        // console.log("SC? ", spacecraftApiName)
        const hasAssociatedId = hasAssociatedSpacecraftId(spacecraftApiName);

        if (!hasAssociatedId) {
          console.warn(`Spacecraft ${spacecraftApiName} does not have an associated id. Please check the spacecraft ID map.`);
          return false;
        }
        // Add constellationIds.
        const constellationIds = getSpacecraftConstellation(spacecraftApiName);
        spacecraft.constellationIds = constellationIds;
        allSpacecraftIds.push(...constellationIds);

        return true;
      });

      // Make sure we have a unique list of spacecraft ids.
      const uniqueSpacecraftIds = [...new Set(allSpacecraftIds)];

      // Set spacecraft list in global state.
      spacecraftStore.setGlobalState({
        spacecraftList: filteredSpacecraftList,
        allSpacecraftIds: uniqueSpacecraftIds
      });

      // Set loading state.
      hideLoading('spacecraft-data');
    })
    .catch(error => {
      hideLoading('spacecraft-data');
      console.warn('Error fetching spacecraft data: ', error);
      redirect(HOME_PAGE);
    });
};

/**
 * Fetches events for a given year.
 * @param {string} year
 * @param {number} urlTime
 * @returns {Promise<object>}
 */
const fetchEventsByYear = (year, urlTime = getUrlTime()) => {
  const { events } = eventsStore.stateSnapshot;

  // Return events if already fetched.
  if (events[year]) {
    return events[year];
  }
  // console.log("fetchEventsByYear", year, urlTime)

  return fetch(`${Config.baseCe2s2Url}/api/v1/earth_now_events/?year=${year}:date&order=date:desc&per_page=-1&condition_1=:title_ar:notnull&r=${urlTime}`)
    .then(response => response.json())
    .then(data => data.items)
    .catch(error => {
      hideLoading('events-data');
      console.warn('Error fetching event years: ', error);
      redirect(HOME_PAGE);
    });
};

/**
 * Fetches event years.
 * @param {number} urlTime
 */
const fetchEventYears = urlTime => {
  // Show loading state.
  showLoading('events-data');

  //events 959254.0263083334 (14) ['11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
  fetch(`${Config.dynamicAssetsUrl}/earth/eo/events_meta.json?r=${urlTime}`)
    .then(response => response.json())
    .then(eventYearsResponse => {
      // Sort event years in descending order and convert to full year strings.
      const eventYears = eventYearsResponse.sort((a, b) => b - a).map(yr => `20${yr}`);

      // Set event years in global state.
      eventsStore.setGlobalState({ eventYears });
      // console.log("events", urlTime, eventYearsResponse, eventYears)
      //events 959254.0889811111 
      // (14) ['24', '23', '22', '21', '20', '19', '18', '17', '16', '15', '14', '13', '12', '11']
      // (14) ['2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011']
      // Set loading state.
      hideLoading('events-data');
    })
    .catch(error => {
      hideLoading('events-data');
      console.warn('Error fetching event years: ', error);
      redirect(HOME_PAGE);
    });
};

/**
 * Fetches the manifest data for current dataset.
 * @param {string} datasetId
 * @returns {Promise<Array<object>>}
 */
const fetchManifestByDatasetId = datasetId => {
  const datasetPath = `${Config.dynamicAssetsUrl}/earth/data/${datasetId}`;
  return fetch(`${datasetPath}/dataset_manifest.json`, { cache: 'no-store' })
    .then(response => response.json());
};

const parseManifest = manifest => {
  const { datasetName, ...otherProps } = manifest;

  return {
    [datasetName]: { ...otherProps }
  };
};


// Subscribe to yearIndex to trigger fetching of events data.
const subscribeToYearIndex = () => eventsStore.subscribeTo('yearIndex', yearIndex => {
  const { events, eventYears } = eventsStore.stateSnapshot;
  const year = eventYears[yearIndex];

  // Return if events already fetched.
  if (!year || events[year]) {
    return;
  }

  // Set loading state.
  showLoading('events-data');

  // Fetch events for year, add urlParam, then set in global state.
  fetchEventsByYear(year)
    .then(eventsYearData => {
      // Parse the events data (sort, add urlParam, add year etc.)
      const eventsYearDataSorted = sortEvents(eventsYearData);
      const existingEventUrls = [];
      // console.log("fetchEventsByYear", eventsYearData, eventsYearDataSorted)

      // Parse each event and add urlParam.
      const eventsYearDataParsed = eventsYearDataSorted.map(event => {
        const parsedEvent = parseEvent(event);

        const eventUrl = getEventParamFromEvent(parsedEvent, existingEventUrls);
        // const eventUrl = parsedEvent.external_id
        existingEventUrls.push(eventUrl);
        parsedEvent.urlParam = eventUrl;
        return parsedEvent;
      }).filter(Boolean);

      const { events: eventsMostRecent } = eventsStore.stateSnapshot;

      eventsStore.setGlobalState({
        events: { ...eventsMostRecent, [year]: eventsYearDataParsed },
        ...yearIndex === 0 && { latestEvents: eventsYearDataParsed }
      });
    })
    .then(() => {
      // Set loading state.
      hideLoading('events-data');
    })
    .catch(error => {
      hideLoading('events-data');
      console.warn(`Error fetching events for year: ${year} - ${error}`);
      redirect(HOME_PAGE);
    });
}, true);

/**
 * Hydrates dynamic data links for vital sign datasets, using manifest data.
 * Puts hydrated dataset(s) into allDatasets object in global state.
 * Appends the following to each dataset:
 * - startDate
 * - endDate
 * - latestTileset
 * @param {object} datasetManifests
 *
 * Called as a callback once manifest data has been downloaded, parsed, and put into global state
 */
const hydrateDatasets = datasetManifests => {
  /**
   * The manifest script determines the 'endDate' by checking the date of the most recent dataset tiles.
   * There are cases where this is some time in the past, anything from a few days to some months.
   * The dates, therefore, between today's date, and the 'endDate' would count as missing dates.
   * These are not included in the dataset_manifest to save on size but can be easily and quickly calculated below.
   *
   * In addition, we can determine whether a satellite is returning up-to-date datasets by comparing the
   * 'generated' date value with the current UTC time.
   */

  // Get allDatasets from global state.
  const { allDatasets } = datasetStore.stateSnapshot;

  const newlyhydratedDatasets = Object.keys(datasetManifests).map(datasetId => {
    // check if already hydrated.
    if (allDatasets[datasetId]) {
      return false;
    }

    const { dataset } = getDatasetFromVitalsData(datasetId);
    const { frequency, startDate: earliestDate, endDate: latestDate, missingDates } = datasetManifests[datasetId];
    const hydratedDataset = { ...dataset };

    const startDate = getDateFromUTCDateStr(earliestDate);
    const endDate = getDateFromUTCDateStr(latestDate);
    // Add start and end dates (todo: does this need to be in ISO format if we're not storing it?) - #3116.
    hydratedDataset.startDate = startDate.toISOString();
    hydratedDataset.endDate = endDate.toISOString();

    const latestDates = getLatestDates({ frequency, startDate, endDate, missingDates }, 1);

    // Create tilesets list from the latest available dates.
    hydratedDataset.latestTileset = latestDates.map(date => ({
      date: date.toISOString(),
      dateObject: date,
      tilePathPrefix: `${datasetId}/${calcPrefixFromDate(date)}`,
      cylThumbFile: `${datasetId}/${datasetId}_thumbnail.png`
    }))[0];

    return [datasetId, hydratedDataset];
  }).filter(Boolean);

  // Update the global state with newly hydrated data.
  if (newlyhydratedDatasets.length) {
    datasetStore.setGlobalState({
      allDatasets: {
        ...allDatasets,
        ...Object.fromEntries(newlyhydratedDatasets)
      }
    });
  }
};

/**
 * Fetches the spacecraft data.
 * @param {number} urlTime
 */
const fetchPois = urlTime => {
  // Show loading state.
  showLoading('poi-data');

  // fetch(`${Config.dynamicAssetsUrl}/earth/api/mission.json?r=${urlTime}`)
  fetch(`${Config.baseCe2s2Url}/api/v1/pois/?per_page=-1&r=${urlTime}`)
    .then(response => response.json())
    .then(({ items: pois }) => {
      const poiYears = getAllYearsPoI(pois)
      // Set pois list in global state.
      poiStore.setGlobalState({
        pois,
        poiYears,
        yearIndex: 0, categoryIndex: 0
      });

      // Set loading state.
      hideLoading('poi-data');
    })
    .catch(error => {
      hideLoading('poi-data');
      console.warn('Error fetching poi data: ', error);
      redirect(HOME_PAGE);
    });
};

/**
 * Fetches the gmap data.
 * @param { number } urlTime
  */
const fetchGmaps = urlTime => {
  // Show loading state.
  showLoading('gmap-data');

  // fetch(`${Config.dynamicAssetsUrl}/earth/api/mission.json?r=${urlTime}`)
  fetch(`${Config.baseCe2s2Url}/api/v1/gmaps/?per_page=-1&r=${urlTime}`)
    .then(response => response.json())
    .then(({ items: gmaps }) => {
      const gmapYears = getAllYearsGmap(gmaps)
      // Set pois list in global state.
      gmapStore.setGlobalState({
        gmaps,
        gmapYears,
        yearIndex: 0, categoryIndex: 0
      });

      // Set loading state.
      hideLoading('gmap-data');
    })
    .catch(error => {
      hideLoading('gmap-data');
      console.warn('Error fetching gmap data: ', error);
      redirect(HOME_PAGE);
    });
};

/**
 * ManifestFetcher component
 * This component is responsible for fetching the manifest data for the current dataset.
 * @returns {null}
 */
const ManifestFetcher = () => {
  const { isVisibleEarth } = useSnapshot(datasetStore.state);
  const { datasetParam, videoParam } = useParams();

  const { relatedDatasetId } = getRelatedIdsFromVideoParam(videoParam) || {};
  const datasetId = relatedDatasetId ?? getDatasetIdFromParam(datasetParam, isVisibleEarth);

  // On mount, subscribe to datasetManifests.
  useEffect(() => {
    const datasetManifestsUnsubscribe = datasetStore.subscribeTo('datasetManifests', datasetManifests => {
      hydrateDatasets(datasetManifests);
    });

    return () => {
      datasetManifestsUnsubscribe();
    };
  }, []);

  // useEffect to fetch manifest data for current dataset (as passed in the URL)
  useEffect(() => {
    // Check whether manifest data already exists in global state.
    const { datasetManifests } = datasetStore.stateSnapshot;
    if (!datasetId || datasetManifests[datasetId]) {
      return;
    }

    // Set loading state.
    showLoading('manifest-data');

    // Fetch manifest data.
    fetchManifestByDatasetId(datasetId)
      .then(manifest => {
        const parsedManifest = parseManifest(manifest);

        const { datasetManifests } = datasetStore.stateSnapshot;

        // Set dataset manifests in global state.
        datasetStore.setGlobalState({ datasetManifests: { ...datasetManifests, ...parsedManifest } });
      })
      .then(() => {
        // Set loading state.
        hideLoading('manifest-data');
      })
      .catch(error => {
        hideLoading('manifest-data');
        console.warn('Error fetching manifest data: ', error);
      });
  }, [datasetId]);

  return null;
};


/**
 * EventsFetcher component
 * This component is responsible for fetching the events years, year-specific events and specific event data.
 * @returns {null}
 */
const EventsFetcher = () => {
  const { eventYearParam, eventParam, '*': invalidParam } = useParams();

  // useEffect for idempotent first fetch of event years list.
  useEffect(() => {
    // Fetch event years once.
    fetchEventYears(getUrlTime());

    // Subscribe to yearIndex which will trigger fetching of events data by year.
    const yearIndexUnsubscribe = subscribeToYearIndex();

    // Clean up with unsubscribe.
    return () => {
      yearIndexUnsubscribe();
    };
  }, []);


  // useEffect to fetch events data for current event (as passed in the URL)
  const { eventYears, latestEvents } = useSnapshot(eventsStore.state);

  // Determine if we have an old event
  const { oldEventYear } = getYearFromOldEventParam(invalidParam) || {};

  useEffect(() => {
    if (!eventYears) {
      return;
    }

    const latestYearIndex = 0;
    const latestYear = eventYears[latestYearIndex];
    const currentEventYear = (eventYears.includes(oldEventYear) && oldEventYear)
      || (eventYears.includes(eventYearParam) && eventYearParam)
      || latestYear;

    // If we dont yet have latestEvents set, set latest year index (triggering yearIndex subscription fetch)
    if (!latestEvents) {
      updateYearIndex(latestYearIndex);
    }

    // Set current year index.
    const currentYearIndex = eventYears.indexOf(currentEventYear);
    if (currentYearIndex > -1 && currentYearIndex !== latestYearIndex) {
      updateYearIndex(currentYearIndex);
    }
  }, [oldEventYear, eventYearParam, eventParam, eventYears, latestEvents]);

  return null;
};

/**
 * SpacecraftFetcher component
 * This component is responsible for fetching all the spacecraft data.
 * @returns {null}
 */
const SpacecraftFetcher = () => {
  // useEffect for fetching of mission list.
  useEffect(() => {
    // Fetch spacecraft data.
    fetchSpacecraftData(getUrlTime());
  }, []);

  return null;
};


/**
 * PoiFetcher component
 * This component is responsible for fetching all the poi data.
 * @returns {null}
 */
const PoiFetcher = () => {
  // useEffect for fetching of mission list.
  useEffect(() => {
    // Fetch spacecraft data.
    fetchPois(getUrlTime());
  }, []);

  return null;
};


/**
 * GmapFetcher component
 * This component is responsible for fetching all the poi data.
 * @returns {null}
 */
const GmapFetcher = () => {
  // useEffect for fetching of mission list.
  useEffect(() => {
    // Fetch spacecraft data.
    fetchGmaps(getUrlTime());
  }, []);

  return null;
};


/**
 * DataMaster component
 * The goal of this component is to fetch data from the server and store it in the global state.
 * This component is responsible for fetching the following:
 * - Manifest data for current dataset
 * - Events years list and year-specific events
 * - Spacecraft data
 *
 * We can start all of the fetches asychronously.
 * @returns {React.Component}
 */
const DataMaster = () => (
  <>
    <ManifestFetcher />
    <EventsFetcher />
    <SpacecraftFetcher />
    <PoiFetcher />
    <GmapFetcher />
  </>
);

export default DataMaster;
