import { useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';

import { appStore, eventsStore } from '../managers/globalState';
import { updateMediaIndex, updateYearIndex } from '../helpers/processEvents';
import { EVENT_MEDIA } from '../config/constants';


const DefaultEventYear = () => {
	const { eventYears } = useSnapshot(eventsStore.state);
	return eventYears?.length && <Navigate replace to={eventYears[0]} />;
};

const EventYear = () => {
	const { eventYears } = useSnapshot(eventsStore.state);
	const { queries } = useSnapshot(appStore.state);
	const { filter } = queries;

	const { eventYearParam, eventParam } = useParams();

	// useEffect to set events visible on globe.
	useEffect(() => {
		// Make sure showOnGlobe global state is set to true if we're viewing all events
		eventsStore.setGlobalState({ eventsVisibleOnGlobe: !eventParam });

		return () => {
			eventsStore.setGlobalState({ eventsVisibleOnGlobe: false });
		};
	}, [eventParam]);

	// useEffect to synchronize yearIndex with eventYearParam if necessary.
	useEffect(() => {
		if (!eventYears?.length) {
			return;
		}

		const { yearIndex } = eventsStore.stateSnapshot;
		const currentYear = eventYears[yearIndex];

		if (currentYear !== eventYearParam) {
			const newYearIndex = eventYears.indexOf(eventYearParam);
			updateYearIndex(newYearIndex);
		}
	}, [eventYearParam, eventYears]);

	// useEffect to synchronize mediaIndex with filter query param if necessary.
	useEffect(() => {
		const mediaIndex = filter ? Array.from(EVENT_MEDIA.keys()).indexOf(filter) : 0;
		updateMediaIndex(mediaIndex);
	}, [filter]);

	return <Outlet />;
};

export default EventYear;

export { DefaultEventYear };
