import React from 'react';
import CE2S2SVG from '../../www/assets/images/ce2.svg';
import ck from '../../www/assets/images/logo 1.png';
import '../../www/assets/css/splash_screen.css';

class SplashScreen extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			open: true
		};
	}


	closeSplash(e) {
		const that = this;
		this.setState({ open: false }, () => {
			if (that.props.onClose) {
				that.props.onClose(e);
			}
		});
	}

	render() {
		if (this.state.open) {
			return (
				<div id='splash-screen' className='splash' onClick={e => { this.closeSplash(); }}>
					<section className='splash_content'>
						<header>
							<div className='brands'>
								<div className='brand'>
									<img src={ck} />
								</div>
							</div>
						</header>
						<div className='splash_text'>
							<h1 className='splash_title'>سافر مع وكالة ناسا واستكشف الأرض والعلامات الحيوية لكوكبنا في رحلة حقيقية وغامرة.
								جميع بيانات الأقمار الصناعية المستخدمة في هذا التطبيق مقدمة من قبل وكالة ناسا الفضائية
							</h1>
							<div className="button_container"><div className='button'>أدخل</div></div>
							<p className='splash_description'>
								"تم العمل على النسخة العربية بالتعاون مع مركز تميز أبحاث الأرض والفضاء المشترك بين مدينة الملك عبدالعزيز للعلوم والتقنية (KACST) و معهد كالفورنيا للتقنية (Caltech)َ"
							</p>
						</div>
						<div className="bottom_brand">
							<CE2S2SVG />
						</div>
					</section>
					<div className='background_image' style={{ backgroundImage: 'url(assets/images/egyptian_dustplume.jpg)' }} />
				</div>
			);
		} else {
			return null;
		}
	}
}

export default SplashScreen;
