/** @module pioneer */
import {
	FastIterable,
	FastMap
} from '../internal';

/**
 * A generic cache.
 * @template Type
 * @extends {FastIterable<Type>}
 */
export class Cache extends FastIterable {
	/**
	 * Constructor.
	 * @param {(key: string) => Type} itemConstructor
	 * @param {(item: Type) => any} itemDestructor
	 */
	constructor(itemConstructor = null, itemDestructor = null) {
		super();

		/**
		 * The keys to entries.
		 * @type {FastMap<string, { item: Type, count: number }>}
		 * @private
		 */
		this._keysToEntries = new FastMap();

		/**
		 * The items to keys.
		 * @type {Map<Type, string>}
		 * @private
		 */
		this._itemToKeys = new Map();

		/**
		 * The item constructor.
		 * @type {(key: string) => Type}
		 * @private
		 */
		this._itemConstructor = itemConstructor;

		/**
		 * The item destructor.
		 * @type {(item: Type) => any}
		 * @private
		 */
		this._itemDestructor = itemDestructor;
	}

	/**
	 * Gets the item with the key. If it doesn't yet exist, it creates it.
	 * @param {string} key
	 * @returns {Type}
	 */
	get(key) {
		const entry = this._keysToEntries.get(key);
		if (entry === undefined) {
			const newEntry = {
				item: this._itemConstructor(key),
				count: 1
			};
			this._keysToEntries.set(key, newEntry);
			this._itemToKeys.set(newEntry.item, key);
			return newEntry.item;
		}
		else {
			entry.count += 1;
			return entry.item;
		}
	}

	/**
	 * Releases the item after a call to get.
	 * @param {Type} item
	 */
	release(item) {
		const key = this._itemToKeys.get(item);
		if (key !== undefined) {
			const entry = this._keysToEntries.get(key);
			entry.count -= 1;
			if (entry.count === 0) {
				this._itemDestructor(entry.item);
				this._keysToEntries.delete(key);
				this._itemToKeys.delete(item);
			}
		}
	}

	/**
	 * Gets the item of the given index.
	 * @param {number} index
	 * @returns {Type}
	 * @override
	 */
	getAt(index) {
		return this._keysToEntries.getAt(index).value.item;
	}

	/**
	 * Gets the number of key-value pairs.
	 * @returns {number}
	 * @override
	 */
	get size() {
		return this._keysToEntries.size;
	}
}
