import React from 'react';
import PropTypes from 'prop-types';

/**
 * ImageWithCaption component
 */
const ImageWithCaption = ({ url, alt, captionText }) => (
	<div className='container img-container'>
		<img className='img-fluid pb-2 no-drag' src={url} alt={alt} />
		<br />
		<small className='caption'>{captionText}</small>
	</div>
);

ImageWithCaption.propTypes = {
	url: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	captionText: PropTypes.string.isRequired
};


export default ImageWithCaption;
