/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	Color,
	Entity,
	MathUtils,
	Quaternion,
	ShaderChunkLogDepth,
	ShaderFix,
	THREE,
	ThreeJsHelper,
	Vector3
} from '../../internal';

/**
 * A bunch of sprite particles in a stream.
 */
export class ParticleSprayComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The length of the particle stream.
		 * @type {number}
		 * @private
		 */
		this._length = 1;

		/**
		 * The spread in degrees of the particle stream.
		 * @type {number}
		 * @private
		 */
		this._spread = 30;

		/**
		 * The number of particles.
		 * @type {number}
		 * @private
		 */
		this._numberOfParticles = 0;

		/**
		 * The speed in units/sec of the particles flowing out.
		 * @type {number}
		 * @private
		 */
		this._speedOfParticles = 0.1;

		/**
		 * The size of each particle.
		 * @type {number}
		 * @private
		 */
		this._sizeOfParticles = 0.1;

		/**
		 * The flag that says whether the spacing between particles is random. If not, the particles are evenly spaced. Certain affects look better with even spacing.
		 * @type {boolean}
		 * @private
		 */
		this._particleSpacingRandom = true;

		/**
		 * The color of the particles.
		 * @type {Color}
		 * @private
		 */
		this._colorOfParticles = new Color();
		this._colorOfParticles.freeze();

		/**
		 * The offset of the particle origin. If relativeToEntityOrientation is true, this is also relative to the entity orientation.
		 * @type {Vector3}
		 */
		this._originOffset = new Vector3();
		this._originOffset.freeze();

		/**
		 * The direction of the particle stream. If relativeToEntityOrientation is true, this is also relative to the entity orientation.
		 * @type {Vector3}
		 * @private
		 */
		this._direction = new Vector3();
		this._direction.freeze();

		/**
		 * The flag that says whether or not the offset origin and direction are relative to the entity orientation.
		 * @type {boolean}
		 * @private
		 */
		this._relativeToEntityOrientation = true;

		// Set the radius of the component.
		this.__setRadius(this._length);
	}

	/**
	 * Gets the length of the particle stream.
	 * @returns {number}
	 */
	getLength() {
		return this._length;
	}

	/**
	 * Sets the length of the particle stream. Defaults to 1.
	 * @param {number} length
	 */
	setLength(length) {
		this._length = length;
		this.__setRadius(this._length);
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'length', this._length);
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'totalTime', this._length / this._speedOfParticles);
	}

	/**
	 * Gets the spread in degrees of the particle stream.
	 * @returns {number}
	 */
	getSpread() {
		return this._spread;
	}

	/**
	 * Sets the spread in degrees of the particle stream. Defaults to 30.
	 * @param {number} spread
	 */
	setSpread(spread) {
		this._spread = spread;
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'spread', MathUtils.degToRad(this._spread));
	}

	/**
	 * Gets the number of particles.
	 * @returns {number}
	 */
	getNumberOfParticles() {
		return this._numberOfParticles;
	}

	/**
	 * Sets the number of particles. Defaults to 0.
	 * @param {number} numberOfParticles
	 */
	setNumberOfParticles(numberOfParticles) {
		this._numberOfParticles = numberOfParticles;
		this.resetResources();
	}

	/**
	 * Gets the speed in units/sec of the particles flowing out.
	 * @returns {number}
	 */
	getSpeedOfParticles() {
		return this._speedOfParticles;
	}

	/**
	 * Sets the speed in km/sec of the particles flowing out. Defaults to 0.1.
	 * @param {number} speedOfParticles
	 */
	setSpeedOfParticles(speedOfParticles) {
		this._speedOfParticles = speedOfParticles;
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'totalTime', this._length / this._speedOfParticles);
	}

	/**
	 * Gets the size in km of the particles flowing out.
	 * @returns {number}
	 */
	getSizeOfParticles() {
		return this._sizeOfParticles;
	}

	/**
	 * Sets the size in km of the particles flowing out. Defaults to 1.0.
	 * @param {number} sizeOfParticles
	 */
	setSizeOfParticles(sizeOfParticles) {
		this._sizeOfParticles = sizeOfParticles;
		this.resetResources();
	}

	/**
	 * Gets the flag that says whether the spacing between particles is random. If not, the particles are evenly spaced. Certain affects look better with even spacing.
	 * @returns {boolean}
	 */
	getParticleSpacingRandom() {
		return this._particleSpacingRandom;
	}

	/**
	 * Sets the flag that says whether the spacing between particles is random. If not, the particles are evenly spaced. Certain affects look better with even spacing. Defaults to true.
	 * @param {boolean} particleSpacingRandom
	 */
	setParticleSpacingRandom(particleSpacingRandom) {
		this._particleSpacingRandom = particleSpacingRandom;
		this.resetResources();
	}

	/**
	 * Gets the color of the particles.
	 * @return {Color}
	 */
	getColorOfParticles() {
		return this._colorOfParticles;
	}

	/**
	 * Sets the color of the particles. Defaults to white.
	 * @param {Color} color
	 */
	setColorOfParticles(color) {
		this._colorOfParticles.thaw();
		this._colorOfParticles.copy(color);
		this._colorOfParticles.freeze();
		ThreeJsHelper.setUniformColorRGBA(this.getThreeJsMaterials()[0], 'globalColor', this._colorOfParticles);
	}

	/**
	 * Gets the offset of the particle origin. If relativeToEntityOrientation is true, this is also relative to the entity orientation.
	 * @returns {Vector3}
	 */
	getOriginOffset() {
		return this._originOffset;
	}

	/**
	 * Sets the offset of the particle origin. If relativeToEntityOrientation is true, this is also relative to the entity orientation. Defaults to zero.
	 * @param {Vector3} originOffset
	 */
	setOriginOffset(originOffset) {
		this._originOffset.thaw();
		this._originOffset.copy(originOffset);
		this._originOffset.freeze();
		ThreeJsHelper.setUniformVector3(this.getThreeJsMaterials()[0], 'originOffset', this._originOffset);
	}

	/**
	 * Gets the direction of the particle stream. If relativeToEntityOrientation is true, this is also relative to the entity orientation.
	 * @returns {Vector3}
	 */
	getDirection() {
		return this._direction;
	}

	/**
	 * Sets the direction of the particle stream. If relativeToEntityOrientation is true, this is also relative to the entity orientation. Defaults to the x-axis.
	 * @param {Vector3} direction
	 */
	setDirection(direction) {
		this._direction.thaw();
		this._direction.normalize(direction);
		this._direction.freeze();
		ThreeJsHelper.setUniformVector3(this.getThreeJsMaterials()[0], 'direction', this._direction);

		// Get the perpendicular direction for orienting the particles.
		const directionPerp = Vector3.pool.get();
		directionPerp.cross(this._direction, Vector3.XAxis);
		if (directionPerp.isZero()) {
			directionPerp.cross(this._direction, Vector3.YAxis);
		}
		directionPerp.normalize(directionPerp);
		ThreeJsHelper.setUniformVector3(this.getThreeJsMaterials()[0], 'directionPerp', directionPerp);
		Vector3.pool.release(directionPerp);
	}

	/**
	 * Gets the flag that says whether or not the offset origin and direction are relative to the entity orientation.
	 * @returns {boolean}
	 */
	getRelativeToEntityOrientation() {
		return this._relativeToEntityOrientation;
	}

	/**
	 * Sets the flag that says whether or not the offset origin and direction are relative to the entity orientation. Defaults to true.
	 * @param {boolean} relativeToEntityOrientation
	 */
	setRelativeToEntityOrientation(relativeToEntityOrientation) {
		this._relativeToEntityOrientation = relativeToEntityOrientation;
	}

	/**
	 * Prepares the component for rendering.
	 * @param {CameraComponent} camera
	 * @override
	 * @internal
	 */
	__prepareForRender(camera) {
		const time = MathUtils.wrap(this.getEntity().getScene().getEngine().getTime(), 0, this._length / this._speedOfParticles);
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'time', time);

		if (this._relativeToEntityOrientation) {
			const originOffset = Vector3.pool.get();
			const direction = Vector3.pool.get();
			originOffset.rotate(this.getEntity().getOrientation(), this._originOffset);
			direction.rotate(this.getEntity().getOrientation(), this._direction);
			ThreeJsHelper.setUniformVector3(this.getThreeJsMaterials()[0], 'originOffset', originOffset);
			ThreeJsHelper.setUniformVector3(this.getThreeJsMaterials()[0], 'direction', direction);

			// Get the perpendicular direction for orienting the particles.
			const directionPerp = Vector3.pool.get();
			directionPerp.cross(direction, Vector3.XAxis);
			if (directionPerp.isZero()) {
				directionPerp.cross(direction, Vector3.YAxis);
			}
			directionPerp.normalize(directionPerp);
			ThreeJsHelper.setUniformVector3(this.getThreeJsMaterials()[0], 'directionPerp', directionPerp);
			Vector3.pool.release(directionPerp);

			Vector3.pool.release(originOffset);
			Vector3.pool.release(direction);
		}

		// Set the Three.js object position the entity's camera-space position.
		ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects()[0], this.getEntity(), camera);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	async __loadResources() {
		// Setup the Three.js material.
		const threeJsMaterial = new THREE.RawShaderMaterial({
			uniforms: {
				spread: new THREE.Uniform(0),
				originOffset: new THREE.Uniform(new THREE.Vector3()),
				direction: new THREE.Uniform(new THREE.Vector3()),
				directionPerp: new THREE.Uniform(new THREE.Vector3()),
				length: new THREE.Uniform(0),
				time: new THREE.Uniform(0),
				totalTime: new THREE.Uniform(0),
				globalColor: new THREE.Uniform(new THREE.Vector4(1, 1, 1, 1)),

				...ShaderChunkLogDepth.ThreeUniforms
			},
			vertexShader: ParticleSprayComponent.vertexShader,
			fragmentShader: ParticleSprayComponent.fragmentShader,
			transparent: true,
			depthWrite: false,
			blending: THREE.AdditiveBlending,
			side: THREE.DoubleSide
		});
		ShaderFix.fix(threeJsMaterial);
		this.getThreeJsMaterials().push(threeJsMaterial);

		// Setup the attribute arrays.
		const positionArray = new Float32Array([-1, -1, 0, 1, -1, 0, 1, 1, 0, -1, 1, 0]);
		const paramsArray = new Float32Array(3 * this._numberOfParticles);
		const colorArray = new Float32Array(4 * this._numberOfParticles);
		const sizeArray = new Float32Array(1 * this._numberOfParticles);
		const indexArray = new Uint16Array([0, 1, 2, 2, 3, 0]);

		// Setup the Three.js geometry.
		const threeJsGeometry = new THREE.InstancedBufferGeometry();
		threeJsGeometry.setAttribute('position', new THREE.BufferAttribute(positionArray, 3));
		threeJsGeometry.setAttribute('params', new THREE.InstancedBufferAttribute(paramsArray, 3));
		threeJsGeometry.setAttribute('color', new THREE.InstancedBufferAttribute(colorArray, 4));
		threeJsGeometry.setAttribute('size', new THREE.InstancedBufferAttribute(sizeArray, 1));
		threeJsGeometry.setIndex(new THREE.BufferAttribute(indexArray, 1));
		threeJsGeometry.instanceCount = this._numberOfParticles;

		// Setup the Three.js object.
		const threeJsObject = /** @type {THREE.Mesh<THREE.InstancedBufferGeometry, THREE.ShaderMaterial>} */ (ThreeJsHelper.createMeshObjectGivenGeometry(this, threeJsMaterial, threeJsGeometry));
		this.getThreeJsObjects().push(threeJsObject);

		// Set its orientation to the identity. The origin offset and direction vectors will be rotated if they are relative ot the entity.
		ThreeJsHelper.setOrientation(this.getThreeJsObjects()[0], Quaternion.Identity);

		// Initialize the particles.
		this._initializeParticles();

		// Setup uniforms.
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'length', this._length);
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'spread', MathUtils.degToRad(this._spread));
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'totalTime', this._length / this._speedOfParticles);
		ThreeJsHelper.setUniformVector3(this.getThreeJsMaterials()[0], 'originOffset', this._originOffset);
		ThreeJsHelper.setUniformVector3(this.getThreeJsMaterials()[0], 'direction', this._direction);
		ThreeJsHelper.setUniformColorRGBA(this.getThreeJsMaterials()[0], 'globalColor', this._colorOfParticles);

		// Get the perpendicular direction for orienting the particles.
		const directionPerp = Vector3.pool.get();
		directionPerp.cross(this._direction, Vector3.XAxis);
		if (directionPerp.isZero()) {
			directionPerp.cross(this._direction, Vector3.YAxis);
		}
		directionPerp.normalize(directionPerp);
		ThreeJsHelper.setUniformVector3(this.getThreeJsMaterials()[0], 'directionPerp', directionPerp);
		Vector3.pool.release(directionPerp);
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		ThreeJsHelper.destroyAllObjectsAndMaterials(this);
	}

	/**
	 * Initializes the particles. Resets them if they were already initialized.
	 * @private
	 */
	_initializeParticles() {
		// Get the attributes and arrays.
		const paramsAttribute = /** @type THREE.Mesh */(this.getThreeJsObjects()[0]).geometry.attributes['params'];
		const colorAttribute = /** @type THREE.Mesh */(this.getThreeJsObjects()[0]).geometry.attributes['color'];
		const sizeAttribute = /** @type THREE.Mesh */(this.getThreeJsObjects()[0]).geometry.attributes['size'];
		const paramsArray = /** @type {Float32Array} */(paramsAttribute.array);
		const colorArray = /** @type {Float32Array} */(colorAttribute.array);
		const sizeArray = /** @type {Float32Array} */(sizeAttribute.array);

		// Setup the particles.
		for (let i = 0, l = this._numberOfParticles; i < l; i++) {
			paramsArray[i * 3 + 0] = Math.random() * 2 - 1;
			paramsArray[i * 3 + 1] = Math.random() * 2 - 1;
			if (this._particleSpacingRandom) {
				paramsArray[i * 3 + 2] = Math.random();
			}
			else {
				paramsArray[i * 3 + 2] = i / this._numberOfParticles;
			}
			colorArray[i * 4 + 0] = 1;
			colorArray[i * 4 + 1] = 1;
			colorArray[i * 4 + 2] = 1;
			colorArray[i * 4 + 3] = 1;
			sizeArray[i * 1 + 0] = this._sizeOfParticles;
		}

		// Flag to update the attributes.
		paramsAttribute.needsUpdate = true;
		colorAttribute.needsUpdate = true;
		sizeAttribute.needsUpdate = true;
	}
}

ParticleSprayComponent.vertexShader = `
	attribute vec3 position;
	attribute vec3 params; // x, y are in plane made by direction if z were 1, z is 0 to 1 where the particle is in the total length at time = 0
	attribute vec4 color;
	attribute float size;

	uniform float spread;
	uniform vec3 originOffset; // in model-space
	uniform vec3 direction; // in model-space
	uniform vec3 directionPerp; // in model-space
	uniform float length;
	uniform float time;
	uniform float totalTime;

	uniform mat4 modelViewMatrix;
	uniform mat4 projectionMatrix;

	varying vec2 vPosition;
	varying vec4 vColor;

	${ShaderChunkLogDepth.VertexHead}

	void main() {
		float u = mod(params.z + time / totalTime, 1.0);
		float sinSpread = sin(spread);
		float uSpread = sinSpread > 0.0 ? u : (1.0 - u);
		float sizeAtU = size * max(0.1, uSpread);
		vec3 directionPerp2 = cross(direction, directionPerp);
		vec3 modelPosition = originOffset + (directionPerp * params.x * sinSpread * uSpread + directionPerp2 * params.y * sinSpread * uSpread + direction * u) * length;
		vec4 viewPosition = vec4(position.x * sizeAtU, 0, position.y * sizeAtU, 1) + modelViewMatrix * vec4(modelPosition, 1.0);
		gl_Position = projectionMatrix * viewPosition;
		gl_Position.w = viewPosition.y;

		// Set the varying variables.
		vPosition = position.xy;
		vColor = color * (1.0 - u);

		${ShaderChunkLogDepth.Vertex}
	}`;

ParticleSprayComponent.fragmentShader = `
	precision highp float;

	uniform vec4 globalColor;

	varying vec2 vPosition;
	varying vec4 vColor;

	${ShaderChunkLogDepth.FragmentHead}

	void main(void) {
		// Set the color to be a circle tinted by the color and globalColor.
		gl_FragColor = globalColor * vColor * max(0.0, 1.0 - dot(vPosition, vPosition));

		${ShaderChunkLogDepth.Fragment}
	}`;
