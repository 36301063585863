/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	Color,
	Entity,
	MathUtils,
	Quaternion,
	Rect,
	ShaderChunkLogDepth,
	SpoutComponent,
	THREE,
	ThreeJsHelper,
	Vector2,
	Vector3
} from '../../internal';

/**
 * Label component.
 */
export class LabelComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The text to be displayed.
		 * @type {string}
		 * @private
		 */
		this._text = '<PLACEHOLDER>';

		/**
		 * The font face.
		 * @type {string}
		 * @private
		 */
		this._fontFamily = 'Arial';

		/**
		 * The font size in pixels.
		 * @type {number}
		 * @private
		 */
		this._fontSize = 16;

		/**
		 * The color of the text.
		 * @type {Color}
		 * @private
		 */
		this._color = new Color();
		this._color.freeze();

		/**
		 * A flag the determines whether or not the label ignores the distance when determining visibility.
		 * @type {boolean}
		 * @private
		 */
		this._ignoreDistance = false;

		/**
		 * The alignment of the text.
		 * @type {Vector2}
		 * @private
		 */
		this._alignment = new Vector2(0.0, 0.5);
		this._alignment.freeze();

		/**
		 * The pixel offset of the text.
		 * @type {Vector2}
		 * @private
		 */
		this._pixelOffset = new Vector2(0, 0);
		this._pixelOffset.freeze();

		/**
		 * The pixel-space size of the text.
		 * @type {Vector2}
		 * @private
		 */
		this._pixelSize = new Vector2();

		/**
		 * The pixel device ratio. Saved here when text is created.
		 * @type {number}
		 * @private
		 */
		this._devicePixelRatio = 1;

		/**
		 * The canvas to draw text on.
		 * @type {HTMLCanvasElement}
		 * @private
		 */
		this._canvas = null;

		/**
		 * The normal-space bounds.
		 * @type {Map<CameraComponent, Rect>}
		 * @private
		 */
		this._normalSpaceBounds = new Map();

		// Set the font family and font size from the config, if it exists.
		const fontFamily = entity.getScene().getEngine().getConfig().getValue('fontFamily');
		if (typeof fontFamily === 'string') {
			this._fontFamily = fontFamily;
		}
		const fontSize = entity.getScene().getEngine().getConfig().getValue('fontSize');
		if (typeof fontSize === 'number') {
			this._fontSize = fontSize;
		}

		// Set the radius to infinity, since it will always show, regardless of distance.
		this.__setRadius(Number.POSITIVE_INFINITY);
	}

	/**
	 * Returns the text.
	 * @returns {string}
	 */
	getText() {
		return this._text;
	}

	/**
	 * Sets the text. Defaults to ''.
	 * @param {string} text
	 */
	setText(text) {
		this._text = text;
		this._updateText();
	}

	/**
	 * Gets the font face.
	 * @returns {string}
	 */
	getFontFamily() {
		return this._fontFamily;
	}

	/**
	 * Sets the font face. Defaults to Arial.
	 * @param {string} fontFamily
	 */
	setFontFamily(fontFamily) {
		this._fontFamily = fontFamily;
		this._updateText();
	}

	/**
	 * Gets the font size in pixels.
	 * @returns {number}
	 */
	getFontSize() {
		return this._fontSize;
	}

	/**
	 * Sets the font size in pixels. Defaults to 16.
	 * @param {number} fontSize
	 */
	setFontSize(fontSize) {
		this._fontSize = fontSize;
		this._updateText();
	}

	/**
	 * Gets the text color.
	 * @returns {Color}
	 */
	getColor() {
		return this._color;
	}

	/**
	 * Sets the text color. Defaults to white.
	 * @param {Color} color - the color to set
	 */
	setColor(color) {
		this._color.thaw();
		this._color.copy(color);
		this._color.freeze();
	}

	/**
	 * Ignores the distance when determining whether it should show the label or not. Defaults to false.
	 * @param {boolean} enable
	 */
	setIgnoreDistance(enable) {
		this._ignoreDistance = enable;
	}

	/**
	 * Gets the label alignment.
	 * @returns {Vector2}
	 */
	getAlignment() {
		return this._alignment;
	}

	/**
	 * Sets the alignment. Defaults to the left aligned to the entity and vertically centered (0, .5). Each component should only be between 0 and 1.
	 * @param {Vector2} alignment - the alignment to set
	 */
	setAlignment(alignment) {
		this._alignment.thaw();
		this._alignment.copy(alignment);
		this._alignment.freeze();
		this._updateText();
	}

	/**
	 * Gets the pixel offset.
	 * @returns {Vector2}
	 */
	getPixelOffset() {
		return this._pixelOffset;
	}

	/**
	 * Sets the pixel offset.
	 * @param {Vector2} pixelOffset
	 */
	setPixelOffset(pixelOffset) {
		this._pixelOffset.thaw();
		this._pixelOffset.copy(pixelOffset);
		this._pixelOffset.freeze();
	}

	/**
	 * Gets the normal-space bounds of the label for the given camera. Used by the selection controller for selecting labels.
	 * @param {CameraComponent} camera
	 * @returns {Rect}
	 */
	getNormalSpaceBounds(camera) {
		return this._normalSpaceBounds.get(camera);
	}

	/**
	 * Prepares the component for rendering.
	 * @param {CameraComponent} camera
	 * @override
	 * @internal
	 */
	__prepareForRender(camera) {
		const cameraSpacePosition = this.getEntity().getCameraSpacePosition(camera);

		ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects()[0], this.getEntity(), camera);
		ThreeJsHelper.setOrientation(this.getThreeJsObjects()[0], Quaternion.Identity);

		// If the camera is a Spout camera, use Spout for the render size.
		let renderSize = 0;
		if (camera.getType() === 'spout') {
			const spoutComponent = /** @type {SpoutComponent} */(camera);
			ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'flipX', spoutComponent.getForGlobe() ? -1 : 1);
			renderSize = spoutComponent.getRenderWidth();
			renderSize /= 4;
		}
		// Otherwise use the viewport size.
		else {
			ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'flipX', 1);
			const size = camera.getViewport().getBounds().size;
			renderSize = Math.max(size.x, size.y);
		}

		// Set the uniforms.
		const pixelOffset = Vector2.pool.get();
		const pixelSize = Vector2.pool.get();
		const renderUp = Vector3.pool.get();
		const renderRight = Vector3.pool.get();
		pixelOffset.set(
			(this._pixelOffset.x - this._alignment.x * this._canvas.width),
			(this._pixelOffset.y - this._alignment.y * this._canvas.height));
		pixelSize.set(this._canvas.width, this._canvas.height);
		camera.getEntity().getOrientation().getAxis(renderUp, 2);
		camera.getEntity().getOrientation().getAxis(renderRight, 0);
		ThreeJsHelper.setUniformVector2(this.getThreeJsMaterials()[0], 'pixelOffset', pixelOffset);
		ThreeJsHelper.setUniformVector2(this.getThreeJsMaterials()[0], 'pixelSize', pixelSize);
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'renderSize', renderSize);
		ThreeJsHelper.setUniformVector3(this.getThreeJsMaterials()[0], 'renderUp', renderUp);
		ThreeJsHelper.setUniformVector3(this.getThreeJsMaterials()[0], 'renderRight', renderRight);

		// Update the normal-space bounds.
		if (camera.getType() !== 'spout') {
			// Get the normalized bounds.
			if (!this._normalSpaceBounds.has(camera)) {
				this._normalSpaceBounds.set(camera, new Rect());
			}

			const normalSpaceBounds = this._normalSpaceBounds.get(camera);
			normalSpaceBounds.thaw();
			const normalSpacePosition = this.getEntity().getNormalSpacePosition(camera);
			normalSpaceBounds.origin.x = normalSpacePosition.x + 2.0 * (this._pixelOffset.x - this._alignment.x * this._pixelSize.x) / renderSize;
			normalSpaceBounds.origin.y = normalSpacePosition.y + 2.0 * (this._pixelOffset.y - this._alignment.y * this._pixelSize.y) / renderSize;
			normalSpaceBounds.size.x = 2.0 * this._pixelSize.x / renderSize;
			normalSpaceBounds.size.y = 2.0 * this._pixelSize.y / renderSize;
			normalSpaceBounds.freeze();
		}
		Vector2.pool.release(pixelSize);
		Vector2.pool.release(pixelOffset);
		Vector3.pool.release(renderUp);
		Vector3.pool.release(renderRight);

		// Fade the label when the camera is close.
		let alphaMultiplier = 1.0;
		const normalizedRadiusOfEntity = this.getEntity().getNormalSpaceExtentsRadius(camera);
		if (!this._ignoreDistance) {
			alphaMultiplier *= MathUtils.clamp01((0.02 - normalizedRadiusOfEntity) / 0.02);
		}

		// Fade the label when the entity is visually close to its parent and its parent also has a label.
		if (this.getEntity().getParent() !== null) {
			const normalizedEntityDistanceFromParent = camera.getNormalSpaceRadiusFromRadius(this.getEntity().getPosition().magnitude(), cameraSpacePosition.magnitude());
			const normalizedParentRadius = this.getEntity().getParent().getNormalSpaceExtentsRadius(camera);
			if (!this._ignoreDistance && normalizedParentRadius < 0.02) {
				alphaMultiplier *= MathUtils.clamp01((normalizedEntityDistanceFromParent - 0.02) / 0.02);
			}
		}

		// If it is occluded, hide label. Check parent always, too.
		if (this.getEntity().getParent() !== null && this.getEntity().getParent().isOccludingPosition(camera, cameraSpacePosition)) {
			alphaMultiplier = 0;
		}
		else if (camera.isPositionOccluded(cameraSpacePosition)) {
			alphaMultiplier = 0;
		}

		// Set the color of the sprite and include the alpha multiplier.
		const color = Color.pool.get();
		color.copy(this._color);
		color.a *= alphaMultiplier;
		ThreeJsHelper.setUniformColorRGBA(this.getThreeJsMaterials()[0], 'colorMultiplier', color);
		Color.pool.release(color);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	async __loadResources() {
		// Create the Three.js object.
		if (LabelComponent._useCount === 0) {
			// Create the shared geometry as a square 0, 0 to 1, 1.
			LabelComponent._threeJsGeometry = ThreeJsHelper.createGeometry([{ name: 'position', dimensions: 3 }, { name: 'uv', dimensions: 2 }], false);
			ThreeJsHelper.setVertices(LabelComponent._threeJsGeometry, 'position', new Float32Array([0, 0, 0, 1, 0, 0, 1, 1, 0, 0, 1, 0]));
			ThreeJsHelper.setVertices(LabelComponent._threeJsGeometry, 'uv', new Float32Array([0, 1, 1, 1, 1, 0, 0, 0]));
			ThreeJsHelper.setIndices(LabelComponent._threeJsGeometry, new Uint16Array([0, 1, 2, 2, 3, 0]));

			// Create the shared material.
			LabelComponent._threeJsMaterial = new THREE.ShaderMaterial({
				uniforms: {
					colorMultiplier: new THREE.Uniform(new THREE.Vector4(1.0, 1.0, 1.0, 1.0)),
					colorTexture: new THREE.Uniform(null),
					pixelOffset: new THREE.Uniform(new THREE.Vector2(0, 0)),
					pixelSize: new THREE.Uniform(new THREE.Vector2(1, 1)),
					renderSize: new THREE.Uniform(1),
					renderUp: new THREE.Uniform(new THREE.Vector3(0, 1, 0)),
					renderRight: new THREE.Uniform(new THREE.Vector3(1, 0, 0)),
					flipX: new THREE.Uniform(1),

					...ShaderChunkLogDepth.ThreeUniforms
				},
				vertexShader: `
					uniform vec2 pixelOffset;
					uniform vec2 pixelSize;
					uniform float renderSize;
					uniform vec3 renderUp;
					uniform vec3 renderRight;
					uniform float flipX;
					varying vec2 fUV;

					${ShaderChunkLogDepth.VertexHead}

					void main() {
						// Get a frame for the label to be on the x-y axis.
						vec3 forward = (modelMatrix * vec4(0, 0, 0, 1.)).xyz;
						float distance = length(forward);
						forward = normalize(forward);
						vec3 up = normalize(renderUp);
						vec3 right = normalize(cross(forward, up));

						// Setup the up and right vectors.
						up *= (position.y * pixelSize.y + pixelOffset.y) / renderSize * distance;
						right *= (position.x * pixelSize.x + pixelOffset.x) / renderSize * distance * flipX;

						// Do the transforms.
						vec4 viewPosition = modelViewMatrix * vec4(up + right, 1.);
						gl_Position = projectionMatrix * viewPosition;

						fUV = uv;

						${ShaderChunkLogDepth.Vertex}
					}`,
				fragmentShader: `
					precision highp float;

					uniform vec4 colorMultiplier;
					uniform sampler2D colorTexture;
					varying vec2 fUV;

					${ShaderChunkLogDepth.FragmentHead}

					void main(void) {
						gl_FragColor = texture2D(colorTexture, fUV);
						gl_FragColor *= colorMultiplier;

						${ShaderChunkLogDepth.Fragment}
					}`,
				side: THREE.DoubleSide
			});
			ThreeJsHelper.setupLogDepthBuffering(LabelComponent._threeJsMaterial);
		}
		LabelComponent._useCount += 1;

		// Create the material.
		const material = LabelComponent._threeJsMaterial.clone();
		this.getThreeJsMaterials().push(material);
		ThreeJsHelper.setTransparent(material, true);
		ThreeJsHelper.setOverlay(material, true);
		ThreeJsHelper.setUniformColorRGBA(material, 'colorMultiplier', this._color);

		// Create the object.
		const object = ThreeJsHelper.createMeshObjectGivenGeometry(this, material, LabelComponent._threeJsGeometry);
		this.getThreeJsObjects().push(object);

		// Create the canvas.
		this._canvas = document.createElement('canvas');
		this._canvas.width = 1;
		this._canvas.height = 1;

		// Update the text.
		this._updateText();
	}

	/**
	 * Unloads the resources.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		LabelComponent._useCount -= 1;
		if (LabelComponent._useCount === 0) {
			ThreeJsHelper.destroyGeometry(LabelComponent._threeJsGeometry);
			ThreeJsHelper.destroyMaterial(LabelComponent._threeJsMaterial, true);
		}
		const object = this.getThreeJsObjects()[0];
		if (object.parent !== null) {
			object.parent.remove(object);
		}
		ThreeJsHelper.destroyMaterial(this.getThreeJsMaterials()[0], true);
		this._canvas = null;
	}

	_updateText() {
		if (this._canvas === null) {
			return;
		}

		const context = this._canvas.getContext('2d');
		this._devicePixelRatio = window.devicePixelRatio;

		// Get the dimensions of the text.
		context.font = this._fontSize + 'px ' + this._fontFamily;
		const metrics = context.measureText(this._text);
		this._pixelSize.set(metrics.width * this._devicePixelRatio, this._fontSize * this._devicePixelRatio);

		// Get the texture size. It needs to be a power of 2.
		const textureWidth = MathUtils.ceilPow2(this._pixelSize.x);
		const textureHeight = MathUtils.ceilPow2(this._pixelSize.y);

		if (textureWidth !== this._canvas.width || textureHeight !== this._canvas.height) {
			this._canvas.width = textureWidth;
			this._canvas.height = textureHeight;
		}

		context.clearRect(0, 0, this._canvas.width, this._canvas.height);
		this._canvas.style.width = (textureWidth / this._devicePixelRatio) + 'px';
		this._canvas.style.height = (textureHeight / this._devicePixelRatio) + 'px';
		context.font = this._pixelSize.y + 'px ' + this._fontFamily; // need to do this again due to browser bug
		context.fillStyle = 'rgba(255, 255, 255, 255)';
		context.fillText(this._text, (this._canvas.width - this._pixelSize.x) * MathUtils.clamp01(this._alignment.x), (this._canvas.height - this._pixelSize.y * 0.1875) - (this._canvas.height - this._pixelSize.y) * MathUtils.clamp01(this._alignment.y));

		const material = this.getThreeJsMaterials()[0];
		ThreeJsHelper.setUniformTexture(material, 'colorTexture', ThreeJsHelper.loadTextureFromCanvas(this._canvas));
	}
}

/**
 * A global shared material, copied by labels.
 * @type {THREE.ShaderMaterial}
 */
LabelComponent._threeJsMaterial = null;

/**
 * A global shared geometry unit square with a corner at (0, 0), copied by labels.
 * @type {THREE.BufferGeometry}
 */
LabelComponent._threeJsGeometry = null;

/**
 * The count for the number of labels used.
 * @type {number}
 */
LabelComponent._useCount = 0;
