/** @module pioneer */
import {
	AER,
	BaseComponent,
	Entity,
	FastSet,
	Geometry,
	LatLonAlt,
	MathUtils,
	Quaternion,
	Vector3
} from '../../internal';

/**
 * The spheroid component.
 */
export class SpheroidComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The equatorial radius.
		 * @type {number}
		 * @private
		 */
		this._equatorialRadius = 1;

		/**
		  * The polar radius.
		  * @type {number}
		  * @private
		  */
		this._polarRadius = 1;

		/**
		 * The flag that if true is planetographic, otherwise is planetocentric.
		 * @type {boolean}
		 * @private
		 */
		this._planetographic = false;

		/**
		 * The flag that determines if the changedCallbacks need to be called on the next update.
		 * @type {boolean}
		 * @private
		 */
		this._changed = true;

		/**
		 * A set of callbacks to call when the spheroid properties change.
		 * @type {FastSet<() => any>}
		 * @private
		 */
		this._changedCallbacks = new FastSet();
	}

	/**
	 * Gets the equatorial radius.
	 * @returns {number}
	 */
	getEquatorialRadius() {
		return this._equatorialRadius;
	}

	/**
	 * Sets the equatorial radius.
	 * @param {number} equatorialRadius
	 */
	setEquatorialRadius(equatorialRadius) {
		this._equatorialRadius = equatorialRadius;
		this._changed = true;
	}

	/**
	 * Gets the polar radius.
	 * @returns {number}
	 */
	getPolarRadius() {
		return this._polarRadius;
	}

	/**
	 * Sets the polar radius.
	 * @param {number} polarRadius
	 */
	setPolarRadius(polarRadius) {
		this._polarRadius = polarRadius;
		this._changed = true;
	}

	/**
	 * Gets the flag that if true is planetographic, otherwise is planetocentric.
	 * @returns {boolean}
	 */
	isPlanetographic() {
		return this._planetographic;
	}

	/**
	 * Sets flag that if true is planetographic, otherwise is planetocentric. Defaults to true.
	 * @param {boolean} planetographic
	 */
	setPlanetographic(planetographic) {
		this._planetographic = planetographic;
		this._changed = true;
	}

	/**
	 * Adds a callback to be called when this changes.
	 * @param {() => any} changedCallback
	 */
	addChangedCallback(changedCallback) {
		this._changedCallbacks.add(changedCallback);
	}

	/**
	 * Removes a callback to be called when this changes.
	 * @param {() => any} changedCallback
	 */
	removeChangedCallback(changedCallback) {
		this._changedCallbacks.delete(changedCallback);
	}

	/**
	 * Updates the component.
	 * @override
	 * @package
	 */
	__update() {
		// If one of the properties have changed, call the callbacks to notify other components or controllers.
		if (this._changed) {
			for (let i = 0, l = this._changedCallbacks.size; i < l; i++) {
				this._changedCallbacks.getAt(i)();
			}
			this._changed = false;
		}
	}

	/**
	 * Takes an LLA and sets out to the equivalent XYZ.
	 * @param {Vector3} out - the XYZ vector to be set
	 * @param {LatLonAlt} lla - the LLA vector to convert
	 */
	xyzFromLLA(out, lla) {
		const cosLat = Math.cos(lla.lat);
		const sinLat = Math.sin(lla.lat);
		if (this._planetographic) {
			const eSq = 1.0 - (this._polarRadius * this._polarRadius) / (this._equatorialRadius * this._equatorialRadius);
			const radiusOfCurvature = this._equatorialRadius / Math.sqrt(1.0 - eSq * sinLat * sinLat);
			out.x = (radiusOfCurvature + lla.alt) * cosLat * Math.cos(lla.lon);
			out.y = (radiusOfCurvature + lla.alt) * cosLat * Math.sin(lla.lon);
			out.z = ((1.0 - eSq) * radiusOfCurvature + lla.alt) * sinLat;
		}
		else {
			const a = this._equatorialRadius;
			const b = this._polarRadius;
			const radius = a * b / Math.sqrt(b * b * cosLat * cosLat + a * a * sinLat * sinLat);
			out.x = (radius + lla.alt) * cosLat * Math.cos(lla.lon);
			out.y = (radius + lla.alt) * cosLat * Math.sin(lla.lon);
			out.z = (radius + lla.alt) * sinLat;
		}
	}

	/**
	 * Gets the radius at the given XYZ.
	 * @param {Vector3} xyz - the xyz vector to use
	 * @param {number} [numIterations] - the number of iterations to use. Defaults to 5.
	 * @returns {number}
	 */
	radiusFromXYZ(xyz, numIterations = 5) {
		if (this._planetographic) {
			// Using http://mathforum.org/library/drmath/view/51834.html as a reference. The standard algorithm from the Astronomical Almanac.
			const r = xyz.magnitudeXY();
			const eSq = 1.0 - (this._polarRadius * this._polarRadius) / (this._equatorialRadius * this._equatorialRadius);
			let lat = Math.atan(xyz.z / ((1.0 - eSq) * r));
			let radius = 0;
			for (let i = 0; i < numIterations; i++) {
				const sinLat = Math.sin(lat);
				radius = this._equatorialRadius / Math.sqrt(1.0 - eSq * sinLat * sinLat);
				lat = Math.atan((xyz.z + radius * eSq * sinLat) / r);
			}
			return radius;
		}
		else {
			const f = (xyz.z * xyz.z) / (xyz.x * xyz.x + xyz.y * xyz.y);
			const cosLatSq = 1 / (1 + f);
			const sinLatSq = f * cosLatSq;
			const a = this._equatorialRadius;
			const b = this._polarRadius;
			return a * b / Math.sqrt(b * b * cosLatSq + a * a * sinLatSq);
		}
	}

	/**
	 * Takes an XYZ and sets out to the equivalent LLA.
	 * @param {LatLonAlt} out - the LLA vector to be set
	 * @param {Vector3} xyz - the XYZ vector to convert
	 * @param {number} [numIterations] - the number of iterations to use. Defaults to 5.
	 */
	llaFromXYZ(out, xyz, numIterations = 5) {
		if (this._planetographic) {
			out.lon = Math.atan2(xyz.y, xyz.x);

			// Using http://mathforum.org/library/drmath/view/51834.html as a reference. The standard algorithm from the Astronomical Almanac.
			const r = xyz.magnitudeXY();
			const eSq = 1.0 - (this._polarRadius * this._polarRadius) / (this._equatorialRadius * this._equatorialRadius);
			out.lat = Math.atan(xyz.z / ((1.0 - eSq) * r));
			let C = 0;
			for (let i = 0; i < numIterations; i++) {
				const sinLat = Math.sin(out.lat);
				C = 1.0 / Math.sqrt(1.0 - eSq * sinLat * sinLat);
				out.lat = Math.atan((xyz.z + this._equatorialRadius * C * eSq * sinLat) / r);
			}
			out.alt = r / Math.cos(out.lat) - this._equatorialRadius * C;
		}
		else {
			const xyLength = xyz.magnitudeXY();
			out.lon = Math.atan2(xyz.y, xyz.x);
			out.lat = Math.atan(xyz.z / xyLength);
			const cosLat = Math.cos(out.lat);
			const sinLat = Math.sin(out.lat);
			const a = this._equatorialRadius;
			const b = this._polarRadius;
			const radius = a * b / Math.sqrt(b * b * cosLat * cosLat + a * a * sinLat * sinLat);
			out.alt = xyz.magnitude() - radius;
		}
	}

	/**
	 * Sets out to lla but with the planetographic flag toggled, adjusting the latitude and altiude so that they represent the same location.
	 * @param {LatLonAlt} out - the LLA vector to be set
	 * @param {LatLonAlt} lla
	 */
	llaToggleGraphicCentric(out, lla) {
		const xyz = Vector3.pool.get();
		this.xyzFromLLA(xyz, lla);
		this._planetographic = !this._planetographic;
		this.llaFromXYZ(out, xyz);
		this._planetographic = !this._planetographic;
		Vector3.pool.release(xyz);
	}

	/**
	 * Returns the up vector from the LLA.
	 * @param {Vector3} out - the up vector to be set
	 * @param {LatLonAlt} lla - the LLA to use
	 */
	upFromLLA(out, lla) {
		const llaPlanetographic = LatLonAlt.pool.get();
		if (this._planetographic) {
			llaPlanetographic.copy(lla);
		}
		else {
			this.llaToggleGraphicCentric(llaPlanetographic, lla);
		}
		out.x = Math.cos(llaPlanetographic.lat) * Math.cos(llaPlanetographic.lon);
		out.y = Math.cos(llaPlanetographic.lat) * Math.sin(llaPlanetographic.lon);
		out.z = Math.sin(llaPlanetographic.lat);
		LatLonAlt.pool.release(llaPlanetographic);
	}

	/**
	 * Returns the east vector from the LLA.
	 * @param {Vector3} out - the east vector to be set
	 * @param {LatLonAlt} lla - the LLA to use
	 */
	eastFromLLA(out, lla) {
		out.x = -Math.sin(lla.lon);
		out.y = Math.cos(lla.lon);
		out.z = 0;
	}

	/**
	 * Returns the north vector from the LLA.
	 * @param {Vector3} out - the north vector to be set
	 * @param {LatLonAlt} lla - the LLA to use
	 */
	northFromLLA(out, lla) {
		const llaPlanetographic = LatLonAlt.pool.get();
		if (this._planetographic) {
			llaPlanetographic.copy(lla);
		}
		else {
			this.llaToggleGraphicCentric(llaPlanetographic, lla);
		}
		out.x = -Math.sin(llaPlanetographic.lat) * Math.cos(llaPlanetographic.lon);
		out.y = -Math.sin(llaPlanetographic.lat) * Math.sin(llaPlanetographic.lon);
		out.z = Math.cos(llaPlanetographic.lat);
		LatLonAlt.pool.release(llaPlanetographic);
	}

	/**
	 * Returns an orientation representing +x as east, +y as north, and +z as up from a LLA.
	 * @param {Quaternion} out - the orientation to be set
	 * @param {LatLonAlt} lla - the LLA to use
	 */
	orientationFromLLA(out, lla) {
		const llaPlanetographic = LatLonAlt.pool.get();
		if (this._planetographic) {
			llaPlanetographic.copy(lla);
		}
		else {
			this.llaToggleGraphicCentric(llaPlanetographic, lla);
		}
		const sLat = Math.sin(llaPlanetographic.lat);
		const sLon = Math.sin(llaPlanetographic.lon);
		const cLon = Math.cos(llaPlanetographic.lon);
		out.w = 0.5 * Math.sqrt((1.0 + sLat) * (1.0 - sLon));
		out.x = 0.5 * Math.sqrt((1.0 - sLat) * (1.0 - sLon));
		out.y = 0.5 * Math.sqrt((1.0 - sLat) * (1.0 + sLon)) * Math.sign(cLon);
		out.z = 0.5 * Math.sqrt((1.0 + sLat) * (1.0 + sLon)) * Math.sign(cLon);
		LatLonAlt.pool.release(llaPlanetographic);
	}

	/**
	 * Sets out to the AER of an XYZ from the viewpoint of an observer at a LLA.
	 * @param {AER} out - the AER vector to be set
	 * @param {Vector3} xyz - the XYZ point to observe
	 * @param {LatLonAlt} lla - the LLA from which to observe
	 */
	aerFromXYZRelToLLA(out, xyz, lla) {
		// get the relative xyz at lla, as v
		const v = Vector3.pool.get();
		this.xyzFromLLA(v, lla);
		const llaPlanetographic = LatLonAlt.pool.get();
		if (!this._planetographic) {
			this.llaFromXYZ(llaPlanetographic, v);
		}
		else {
			llaPlanetographic.copy(lla);
		}
		v.sub(xyz, v);
		out.range = v.magnitude(); // the range

		// normalize it
		v.mult(v, 1.0 / out.range);

		// get the angle between horizon and v, using up. this is the elevation
		const up = Vector3.pool.get();
		this.upFromLLA(up, llaPlanetographic);
		const vDotUp = v.dot(up);
		out.elevation = MathUtils.halfPi - Math.acos(vDotUp); // get angle from horizontal

		// make v be just the tangent part
		v.addMult(v, up, -vDotUp);
		Vector3.pool.release(up);

		// get the azimuth using the north/east directions
		const north = Vector3.pool.get();
		this.northFromLLA(north, llaPlanetographic);
		const east = Vector3.pool.get();
		this.eastFromLLA(east, llaPlanetographic);
		out.azimuth = Math.atan2(v.dot(east), v.dot(north));
		Vector3.pool.release(north);
		Vector3.pool.release(east);
		LatLonAlt.pool.release(llaPlanetographic);
		Vector3.pool.release(v);
	}

	/**
	 * Gets the location at the point of intersection of the ray and the spheroid. Origin and direction are in frame space.
	 * @param {Vector3} outPosition
	 * @param {Vector3} origin
	 * @param {Vector3} direction
	 */
	getRayIntersection(outPosition, origin, direction) {
		const spheroidRatio = this._equatorialRadius / this._polarRadius;
		const originAsSphere = Vector3.pool.get();
		const directionAsSphere = Vector3.pool.get();
		originAsSphere.set(origin.x, origin.y, origin.z * spheroidRatio);
		directionAsSphere.set(direction.x, direction.y, direction.z * spheroidRatio);
		const intersectionDistance = Geometry.getLineSphereIntersectionWithSphereAtOrigin(originAsSphere, directionAsSphere, this._equatorialRadius);
		outPosition.addMult(originAsSphere, directionAsSphere, intersectionDistance);
		Vector3.pool.release(originAsSphere);
		Vector3.pool.release(directionAsSphere);
		outPosition.z /= spheroidRatio;
	}

	/**
	 * Gets the frame-space position and up on the surface at the given frame-space position.
	 * Note that the height direction is not up with planetocentric coordinates.
	 * @param {Vector3} outPosition
	 * @param {Vector3} outHeightDir
	 * @param {Vector3} position
	 */
	getGroundPosition(outPosition, outHeightDir, position) {
		// Get the position on the surface of the spheroid.
		const lla = LatLonAlt.pool.get();
		this.llaFromXYZ(lla, position);
		lla.alt = 0;
		this.xyzFromLLA(outPosition, lla);
		const up = Vector3.pool.get();
		this.upFromLLA(outHeightDir, lla);
		LatLonAlt.pool.release(lla);
		Vector3.pool.release(up);
	}
}
