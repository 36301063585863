/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import useGridDimensions from '../hooks/useGridDimensions';
import useScrolledToEnd from '../hooks/useScrolledToEnd';
import usePrevious from '../hooks/usePrevious';

import '../../www/assets/css/grid_list.css';

// if you look at the flex-grid-item class css, the ITEM_SIZE value should be the max-height plus the margin
// a better solution would be to use dynamic styled components as you would keep the variables in one place
const ITEM_SIZE = 200;
const ITEM_PADDING = [30, 30];
// time for row to fade in in ms
const ROW_FADE_TIME = 300;

/**
 * GridItem component
 */
const GridItem = ({ children, itemSize, itemPadding, animDelay, nested }) => {
	const gridItemProps = {
		className: `flex-grid-item fadein ${nested ? 'nested' : ''}`,
		style: {
			animationDelay: `${animDelay}ms`,
			flexBasis: `${itemSize}px`,
			// maxHeight: `${itemSize}px`,
			padding: `${itemPadding[1] * 0.5}px ${itemPadding[0] * 0.5}px`
		}
	};

	return (
		<div {...gridItemProps}>
			{ children }
		</div>
	);
};

/**
 * GridRow prop types
 */
GridItem.propTypes = {
	children: PropTypes.node,
	itemSize: PropTypes.number.isRequired,
	itemPadding: PropTypes.array.isRequired,
	animDelay: PropTypes.number.isRequired
};


/**
 * GridRow component
 */
const GridRow = ({ children, className }) => (
	<div className={className}>
		{ children }
	</div>
);

/**
 * GridRow prop types
 */
GridRow.propTypes = {
	className: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
};

/**
 * GridList component
 */
const GridList = ({
	dataList,
	rowClassName = 'flex-grid-row',
	itemComponent,
	minPerRow = 1,
	maxPerRow = 1000,
	itemSize = ITEM_SIZE,
	itemPadding = ITEM_PADDING,
	scrollableRef,
	nested = false
}) => {
	// num of chunks state
	const [numChunks, setNumChunks] = useState(1);
	// the filter number increments whenever the user selects a new filter
	// this is important for the fadein animation as it forces a re-render for grid items that may not have changed
	const [filterNum, setFilterNum] = useState(1);

	// store ref previous data
	const prevDataList = usePrevious(dataList);

	// custom dynamic hook to calculate number of items per row and chunk size
	const [availableItemsPerRow, chunkSize] = useGridDimensions(itemSize);

	// image display limit calculations
	const limitIndex = numChunks * chunkSize;
	const limitData = limitIndex < dataList.length;
	const displayedData = limitData ? dataList.filter((e, i) => i < limitIndex) : dataList;

	const maxItemsPerRow = Math.min(availableItemsPerRow, displayedData.length, maxPerRow);
	const itemsPerRow = Math.max(maxItemsPerRow, minPerRow);
	const maxItemsArray = [...Array(itemsPerRow).keys()];

	// scrolled to bottom detection
	const contentRef = useRef();
	// prefer the scrollableRef prop in case we intend to scroll inside a parent
	const scrolledToEnd = useScrolledToEnd(scrollableRef || contentRef);

	// increase chunks, and therefore display limit
	useEffect(() => {
		if (scrolledToEnd && limitData) {
			setNumChunks(currNumChunks => currNumChunks + 1);
		}
	}, [scrolledToEnd, limitData]);

	// scroll to the top and reset numChunks and filterNum when the filteredData changes
	useEffect(() => {
		contentRef.current.scrollTo(0, 0);
		setNumChunks(1);
		setFilterNum(prevFilterNum => prevFilterNum + 1);
	}, [dataList]);

	// generate the grid row component (returned null values are effectively filtered)
	const gridRowList = displayedData.map((_, i) => {
		const itemNum = i + 1;
		const endOfRow = (itemNum % itemsPerRow) === 0;
		const endOfData = itemNum === displayedData.length;

		if (endOfRow || endOfData) {
			const sliceStart = itemNum - (endOfRow ? itemsPerRow : (displayedData.length % itemsPerRow));
			const items = displayedData.slice(sliceStart, itemNum);
			const stagger = ROW_FADE_TIME / items.length;

			return (
				<GridRow key={`gridrow-${filterNum}-${itemNum}`} className={rowClassName}>
					{maxItemsArray.map(i => (
						<GridItem
							key={`griditem-${filterNum}-${i + 1}`}
							itemSize={itemSize}
							itemPadding={itemPadding}
							animDelay={i * stagger}
							nested
						>
							{ items[i] && React.cloneElement(itemComponent, { ...items[i] }) }
						</GridItem>
					))}
				</GridRow>
			);
		}
		return null;
	});

	// because we have a useEffect running when the dataList changes, when changing state from within the useEffect
	// the component will want to re-render.
	// we can therefore avoid rendering when prevDataList and dataList are mismatched.
	const renderGrid = gridRowList.length && prevDataList === dataList;
	const gridClasses = `flex-grid ${nested ? 'nested' : ''} scrollable ${!limitData ? 'alldata' : ''}`;

	return (
		<div className={nested ? '' : 'grid-content'} ref={contentRef}>
			<div className={gridClasses} ref={contentRef}>
				{ renderGrid ? gridRowList : null }
			</div>
		</div>
	);
};

/**
 * GridList prop types
 */


GridList.propTypes = {
	dataList: PropTypes.arrayOf(PropTypes.object).isRequired,
	itemComponent: PropTypes.node.isRequired,
	rowClassName: PropTypes.string,
	minPerRow: PropTypes.number,
	maxPerRow: PropTypes.number,
	itemSize: PropTypes.number,
	itemPadding: PropTypes.array
};

export default GridList;
