/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';
import { Entity } from './entity';

/**
 * Helpful utilities for scenes.
 * @hideconstructor
 */
export class SceneHelpers {
	/**
	 * The transformation from EclipJ2000 (a SPICE frame) to J2000 coordinates. This is the tilt from the equator to the ecliptic.
	 * @returns {Pioneer.Quaternion}
	 */
	static getEclipJ2000ToJ2000Rotation() {
		return this.eclipJ2000ToJ2000Rotation;
	}

	/**
	 * This returns a promise that resolves when all of the entities in `entityNames` have non-NaN positions and orientations.
	 * For entities that aren't covered in the current time, they count as immediately valid.
	 * @param {Pioneer.Scene} scene - the scene where the entities are
	 * @param {string[]} entityNames - the set of names of entities to be checked
	 * @param {number} [time] - an optional time to use
	 * @param {number} [timeout = 5.0] - the number of seconds to wait until the promise is rejected
	 * @param {number} [frequency = 0.030] - the number of seconds to wait before checking the positions and orientations again
	 * @returns {Promise<void>}
	 */
	static async waitTillEntitiesInPlace(scene, entityNames, time = undefined, timeout = 5.0, frequency = 0.030) {
		// Get the set of entities from the set of entity names.
		const entities = /** @type {Set<Pioneer.Entity>} */(new Set());
		for (const entityName of entityNames) {
			const entity = scene.getEntity(entityName);
			if (entity === null) {
				throw new Error('Entity "' + entityName + '" not added yet. Use Entity.create to add the entity.');
			}
			entities.add(entity);
		}

		// Wait on the promises of all controllers of every entity.
		const controllerPromises = [];
		for (const entity of entities) {
			for (let i = 0; i < entity.getNumControllers(); i++) {
				controllerPromises.push(entity.getController(i).getLoadedPromise());
			}
		}
		await Promise.all(controllerPromises);

		// If there was no time, set it to the current time.
		if (time === undefined) {
			time = scene.getEngine().getTime();
		}

		// Check the entities' positions and orientations every `frequency` seconds.
		return new Promise((resolve, reject) => {
			let timeSoFar = 0;
			const intervalCheck = setInterval(() => {
				// Check each entity in the list to see if is either not covered or has valid position and orientation.
				// If so, remove it from the list.
				const position = Pioneer.Vector3.pool.get();
				const orientation = Pioneer.Quaternion.pool.get();
				for (const entity of entities) {
					const isInCoverage = entity.getPositionCoverage().contains(time);
					entity.getPositionAtTime(position, time);
					entity.getOrientationAtTime(orientation, time);
					const inPlace = !position.isNaN() && !orientation.isNaN();
					if (!isInCoverage || inPlace) {
						entities.delete(entity);
					}
				}
				Pioneer.Quaternion.pool.release(orientation);
				Pioneer.Vector3.pool.release(position);
				// If there are no more entities in the list, resolve.
				if (entities.size === 0) {
					clearInterval(intervalCheck);
					resolve();
				}
				// If we've hit the timeout, reject.
				timeSoFar += frequency;
				if (timeSoFar >= timeout) {
					clearInterval(intervalCheck);
					let entitiesAsString = '';
					for (const entity of entities) {
						if (entitiesAsString !== '') {
							entitiesAsString += ', ';
						}
						entitiesAsString += '\'' + entity.getName() + '\'';
					}
					reject(new Error('Timed out (' + timeout + ' seconds) while waiting for entities to be in place. The remaining entities were [' + entitiesAsString + '].'));
				}
			}, frequency * 1000.0);
		}).then(() => scene.getEngine().waitUntilNextFrame());
	}

	/**
	 * Converts a lat, lon, alt into an xyz, with the xyz in either the standard J2000 or entity frame.
	 * @param {Pioneer.Vector3} out
	 * @param {Pioneer.Entity} entity
	 * @param {Pioneer.LatLonAlt} lla
	 * @param {boolean} inEntityFrame
	 */
	static llaToXYZ(out, entity, lla, inEntityFrame) {
		const spheroid = entity.getComponentByClass(Pioneer.SpheroidComponent);
		if (spheroid !== null) {
			spheroid.xyzFromLLA(out, lla);
			if (!inEntityFrame) {
				out.rotate(entity.getOrientation(), out);
			}
		}
	}

	/**
	 * Converts an xyz to a lat, lon, alt, with the xyz in either the standard J2000 or entity frame.
	 * @param {Pioneer.LatLonAlt} out
	 * @param {Pioneer.Entity} entity
	 * @param {Pioneer.Vector3} xyz
	 * @param {boolean} inEntityFrame
	 */
	static xyzToLLA(out, entity, xyz, inEntityFrame) {
		const spheroid = entity.getComponentByClass(Pioneer.SpheroidComponent);
		if (spheroid !== null) {
			const xyzInFrame = Pioneer.Vector3.pool.get();
			if (inEntityFrame) {
				xyzInFrame.copy(xyz);
			}
			else {
				xyzInFrame.rotateInverse(entity.getOrientation(), xyz);
			}
			spheroid.llaFromXYZ(out, xyzInFrame);
			Pioneer.Vector3.pool.release(xyzInFrame);
		}
	}

	/**
	 * Gets all of the other entities that the named entity is dependent upon, including all ancestors.
	 * @param {string} entityName - The name of the entity.
	 * @returns {Set<string>}
	 */
	static getDependentEntities(entityName) {
		const others = /** @type {Set<string>} */(new Set());
		this._getDependentEntitiesRecursed(entityName, others);
		others.delete(entityName);
		return others;
	}

	/**
	 * Recursive function for getDependentEntities().
	 * @param {string} entityName - The name of the entity.
	 * @param {Set<string>} others - The parents set that will be added to.
	 * @private
	 */
	static _getDependentEntitiesRecursed(entityName, others) {
		const entityOptions = Entity.getEntityOptions(entityName);
		if (entityOptions === undefined) {
			return;
		}
		const otherEntityNames = /** @type {Set<string>} */(new Set());
		// Get all parents.
		const parentTable = entityOptions.parents;
		for (const parentEntry of parentTable) {
			if (parentEntry[1] !== '') {
				otherEntityNames.add(parentEntry[1]);
			}
		}
		// Check for other relations.
		if (entityOptions.lightSource !== undefined) {
			otherEntityNames.add(entityOptions.lightSource);
		}
		if (entityOptions.trail !== undefined && entityOptions.trail.relativeTo !== undefined) {
			otherEntityNames.add(entityOptions.trail.relativeTo);
		}
		for (let i = 0, l = entityOptions.controllers.length; i < l; i++) {
			const controllerOptions = entityOptions.controllers[i];
			if (controllerOptions.type === 'align') {
				if (controllerOptions.primary.target !== undefined) {
					otherEntityNames.add(controllerOptions.primary.target);
				}
				if (controllerOptions.secondary !== undefined && controllerOptions.secondary.target !== undefined) {
					otherEntityNames.add(controllerOptions.secondary.target);
				}
			}
		}
		if (entityOptions.labelFadeEntity) {
			otherEntityNames.add(entityOptions.labelFadeEntity);
		}
		// Go through any other dependent entities.
		if (entityOptions.dependents) {
			for (let i = 0, l = entityOptions.dependents.length; i < l; i++) {
				otherEntityNames.add(entityOptions.dependents[i]);
			}
		}
		// Go through each entity found and recursively get its dependencies.
		for (const otherEntityName of otherEntityNames) {
			if (!others.has(otherEntityName)) {
				others.add(otherEntityName);
				this._getDependentEntitiesRecursed(otherEntityName, others);
			}
		}
	}
}

SceneHelpers.eclipJ2000ToJ2000Rotation = new Pioneer.Quaternion(0.9791532214288992, 0.2031230389823101, 0, 0);
SceneHelpers.eclipJ2000ToJ2000Rotation.freeze();
