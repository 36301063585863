/** @module pioneer-scripts */
import { Entity } from '../entity';
import * as Pioneer from 'pioneer';

Entity.register({
	rose_bowl: {
		groups: ['comparison'],
		radius: 0.15,
		label: 'Rose Bowl',
		parents: [],
		model: {
			url: '$STATIC_ASSETS_URL/models/comparison/rose_bowl/rose_bowl.gltf',
			rotate: [
				{ x: 90 },
				{ z: -90 }
			]
		},
		controllers: [{
			type: 'fixed',
			position: Pioneer.Vector3.Zero,
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	school_bus: {
		groups: ['comparison'],
		radius: 0.0065,
		label: 'School bus',
		parents: [],
		model: {
			url: '$STATIC_ASSETS_URL/models/comparison/school_bus/school_bus.gltf',
			rotate: [
				{ x: 90 },
				{ z: -90 }
			]
		},
		controllers: [{
			type: 'fixed',
			position: Pioneer.Vector3.Zero,
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	scientist: {
		groups: ['comparison'],
		radius: 0.000835,
		label: 'Scientist',
		parents: [],
		model: {
			url: '$STATIC_ASSETS_URL/models/comparison/scientist/scientist.gltf',
			rotate: [
				{ x: 90 }
			]
		},
		controllers: [{
			type: 'fixed',
			position: Pioneer.Vector3.Zero,
			orientation: Pioneer.Quaternion.Identity
		}]
	}
});
