import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';

import { appStore, datasetStore, spacecraftStore, uiStore, timeStore, previewStore, eventsStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';
import { getDatasetIdFromParam, getRelatedIdsFromVideoParam } from '../helpers/tools';
import useSamePageRefreshCount from '../hooks/useSamePageRefreshCount';

const EarthLayout = () => {
	// State
	const { getManager } = globalRefs;
	const { allDatasets } = useSnapshot(datasetStore.state);
	const { previewEventData } = useSnapshot(previewStore.state);
	const { currentEvent } = useSnapshot(eventsStore.state);
	const { isSatellitesNow, spacecraftId } = useSnapshot(spacecraftStore.state);
	const { queries } = useSnapshot(appStore.state);
	const { isRealTime } = useSnapshot(timeStore.state);

	// Hooks
	const params = useParams();
	const samePageRefreshCount = useSamePageRefreshCount();

	// Vars
	const { vitalSignParam, datasetParam, videoParam } = params;
	const { start, end, animating } = queries;
	const { calculatedRealTime } = getManager('time');
	const realtimeRate = Boolean(isSatellitesNow || spacecraftId || calculatedRealTime) && animating !== 'true';

	// Determine if we should not load earth texture yet (as this trigger the VE manager to start downloading a bunch of textures)
	const { type: videoType } = getManager('video').getVideo(videoParam) || {};
	const { relatedVitalSignId, relatedDatasetId } = getRelatedIdsFromVideoParam(videoParam) || {};
	const loadingEvent = params.eventParam && !currentEvent;
	const globalVideo = videoType === 'global' || previewEventData?.type === 'global' || currentEvent?.type === 'global';
	const dontLoadVisibleEarth = loadingEvent || globalVideo;

	const vitalSignId = relatedVitalSignId ?? vitalSignParam ?? 'visible-earth';
	const isVisibleEarth = vitalSignId === 'visible-earth';
	const datasetId = relatedDatasetId ?? getDatasetIdFromParam(datasetParam, isVisibleEarth);
	const hydratedDataset = allDatasets[datasetId];

	// useEffect for setting isVisibleEarth in global state.
	useEffect(() => {
		datasetStore.setGlobalState({ isVisibleEarth });

		return () => {
			datasetStore.setGlobalState({ isVisibleEarth: false });
		};
	}, [isVisibleEarth]);

	const updateTimeAndRate = () => {
		const { getManager } = globalRefs;
		if (isRealTime !== realtimeRate) {
			getManager('time').setTimeAndRate(realtimeRate, realtimeRate, true);
		}
	};

	// useEffect for setting default time and rate.
	useEffect(() => {
		updateTimeAndRate();
	}, [realtimeRate]);

	// useEffect for setting default time and rate on same page refresh.
	useEffect(() => {
		if (samePageRefreshCount > 0) {
			updateTimeAndRate();
		}
	}, [samePageRefreshCount]);

	// useEffect for when vitalsign changes
	useEffect(() => {
		const { getManager } = globalRefs;

		if (!isSatellitesNow) {
			// Set vital sign in the content manager.
			getManager('content').setVitalSign(vitalSignId);
		}
	}, [vitalSignId, isSatellitesNow]);

	// Lets try to abort any previous texture loads as early as possible.
	useEffect(() => {
		const { getManager } = globalRefs;
		getManager('dataset').textureLoadAbort?.();
	}, [datasetId]);

	// useEffect for when dataset or animation-specific queries change
	useEffect(() => {
		const { getManager } = globalRefs;
		const { isVisibleEarth } = datasetStore.stateSnapshot;

		const datasetManager = getManager('dataset');

		// If dataset is hydrated, set it in the dataset manager and in the scene manager.
		if (hydratedDataset) {
			// Set the dataset in the dataset manager.
			datasetManager.setDataset(hydratedDataset, { start, end, animating });

			// Set animation props.
			datasetManager.setAnimationProps({ startDateStr: start, endDateStr: end });

			// Set currentDataset global state.
			datasetStore.setGlobalState({ currentDataset: hydratedDataset });

			// Hide the splash screen.
			uiStore.setGlobalState({ showSplashScreen: false });

			if (isVisibleEarth && !dontLoadVisibleEarth) {
				// Attempt to load visible earth WMTS.
				getManager('visibleEarth').load()
					.then(readyToShow => {
						readyToShow && getManager('dataset').setDisplay();
					});
			} else {
				getManager('visibleEarth').destroy();
				!globalVideo && getManager('dataset').setDisplay();
			}
		}

		// Return called before the component re-calls this useEffect (also on unmount).
		return () => {
			// Stop and reset animation.
			datasetManager.stopAnimation();
			datasetManager.resetAnimation();

			// Reset dataset in the dataset manager.
			datasetManager.resetDataset();

			// Set currentDataset global state.
			datasetStore.setGlobalState({ currentDataset: null });
		};
	}, [hydratedDataset, start, end, animating, dontLoadVisibleEarth]);


	return <Outlet />;
};

export default EarthLayout;
