/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	Color,
	Entity,
	EntityRef,
	THREE,
	ThreeJsHelper,
	Vector2,
	Vector3
} from '../../internal';

/**
 * A 2D sprite in the X-Y plane relative to an entity.
 */
export class ConnectedSpriteComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The entity 1 to connect to.
		 * @type {EntityRef}
		 * @private
		 */
		this._entity1 = new EntityRef(this.getEntity().getScene());

		/**
		 * The offset of entity 1 in entity 1's frame.
		 * @type {Vector3}
		 * @private
		 */
		this._entity1Offset = new Vector3();
		this._entity1Offset.freeze();

		/**
		 * The entity 2 to connect to.
		 * @type {EntityRef}
		 * @private
		 */
		this._entity2 = new EntityRef(this.getEntity().getScene());

		/**
		 * The offset of entity 2 in entity 2's frame.
		 * @type {Vector3}
		 * @private
		 */
		this._entity2Offset = new Vector3();
		this._entity2Offset.freeze();

		/**
		 * The width of the sprite along the axis perpendicular to the line between the two entities at entity 1.
		 * @type {number}
		 * @private
		 */
		this._width1 = 1.0;

		/**
		 * The width of the sprite along the axis perpendicular to the line between the two entities at entity 2.
		 * @type {number}
		 * @private
		 */
		this._width2 = 1.0;

		/**
		 * The units of the width. It can be 'px' or 'km'.
		 * @type {string}
		 * @private
		 */
		this._widthUnits = 'km';

		/**
		 * The url for the texture.
		 * @type {string}
		 * @private
		 */
		this._textureUrl = '';

		/**
		 * The aspect ratio of the texture.
		 * @type {number}
		 * @private
		 */
		this._textureAspectRatio = 1;

		/**
		 * The flag for whether to repeat or stretch the texture.
		 * @type {boolean}
		 * @private
		 */
		this._textureRepeat = true;

		/**
		 * The stretch factor for textures.
		 * @type {number}
		 * @private
		 */
		this._textureStretch = 1;

		/**
		 * The y offset from 0 to 1 of the texture.
		 * @type {number}
		 * @private
		 */
		this._textureYOffset = 0;

		/**
		 * The u offset from 0 to 1 of the start of the line. 0 is at Entity1, 1 is at Entity2.
		 * @type {number}
		 * @private
		 */
		this._uOffsetStart = 0;

		/**
		 * The u offset from 0 to 1 of the end of the line. 0 is at Entity1, 1 is at Entity2.
		 * @type {number}
		 * @private
		 */
		this._uOffsetEnd = 1;

		/**
		 * Each pixel in the texture is multiplied by this value.
		 * @type {Color}
		 * @private
		 */
		this._colorMultiplier = new Color(1, 1, 1, 1);
		this._colorMultiplier.freeze();

		/**
		 * A flag that determines if the sprite has blending mode.
		 * @type {string}
		 * @private
		 */
		this._blending = 'normal';
	}

	/**
	 * Gets the entity 1 to connect to.
	 * @returns {string}
	 */
	getEntity1() {
		return this._entity1.getName();
	}

	/**
	 * Sets the entity 1 to connect to. It defaults to null.
	 * @param {string} entityName
	 */
	setEntity1(entityName) {
		this._entity1.setName(entityName);
	}

	/**
	 * Gets the offset of entity 1 in entity 1's frame.
	 * @returns {Vector3}
	 */
	getEntity1Offset() {
		return this._entity1Offset;
	}

	/**
	 * Sets the offset of entity 1 in entity 1's frame. It defaults to zero.
	 * @param {Vector3} offset
	 */
	setEntity1Offset(offset) {
		this._entity1Offset.thaw();
		this._entity1Offset.copy(offset);
		this._entity1Offset.freeze();
	}

	/**
	 * Gets the entity 2 to connect to.
	 * @returns {string}
	 */
	getEntity2() {
		return this._entity2.getName();
	}

	/**
	 * Sets the entity 2 to connect to. It defaults to null.
	 * @param {string} entityName
	 */
	setEntity2(entityName) {
		this._entity2.setName(entityName);
	}

	/**
	 * Gets the offset of entity 2 in entity 2's frame.
	 * @returns {Vector3}
	 */
	getEntity2Offset() {
		return this._entity2Offset;
	}

	/**
	 * Sets the offset of entity 2 in entity 2's frame. It defaults to zero.
	 * @param {Vector3} offset
	 */
	setEntity2Offset(offset) {
		this._entity2Offset.thaw();
		this._entity2Offset.copy(offset);
		this._entity2Offset.freeze();
	}

	/**
	 * Gets the width of the sprite along the axis perpendicular to the line between the two entities at entity 1.
	 * @return {number}
	 */
	getWidth1() {
		return this._width1;
	}

	/**
	 * Gets the width of the sprite along the axis perpendicular to the line between the two entities at entity 2.
	 * @return {number}
	 */
	getWidth2() {
		return this._width2;
	}

	/**
	 * Sets the widths of the sprite along the axis perpendicular to the line between the two entities. It defaults to 1 for both.
	 * @param {number} width1 - The width near entity1.
	 * @param {number} width2 - The width near entity2.
	 */
	setWidths(width1, width2) {
		this._width1 = width1;
		this._width2 = width2;
	}

	/**
	 * Gets the units of the width. It can be 'px' or 'km'.
	 * @returns {string}
	 */
	getWidthUnits() {
		return this._widthUnits;
	}

	/**
	 * Sets the units of the width. It can be 'px' or 'km'. Defaults to 'km'.
	 * @param {string} widthUnits
	 */
	setWidthUnits(widthUnits) {
		this._widthUnits = widthUnits;
		if (this.getLoadState() === 'loaded') {
			const material = this.getThreeJsMaterials()[0];
			material.defines['PIXEL_BASED'] = (widthUnits === 'px');
			material.needsUpdate = true;
		}
	}

	/**
	 * Gets the url of the texture.
	 * @returns {string}
	 */
	getTextureUrl() {
		return this._textureUrl;
	}

	/**
	 * Sets the url of the texture. It defaults '', meaning no texture.
	 * @param {string} url
	 */
	setTextureUrl(url) {
		this._textureUrl = url;
		this.resetResources();
	}

	/**
	 * Gets the flag for whether to repeat or stretch the texture.
	 * @returns {boolean}
	 */
	getTextureRepeat() {
		return this._textureRepeat;
	}

	/**
	 * Sets the flag for whether to repeat or stretch the texture. Defaults to true.
	 * @param {boolean} repeat
	 */
	setTextureRepeat(repeat) {
		this._textureRepeat = repeat;
	}

	/**
	 * Gets the stretch factor for textures.
	 * @returns {number}
	 */
	getTextureStretch() {
		return this._textureStretch;
	}

	/**
	 * Sets the stretch factor for textures. Defaults to 1.
	 * @param {number} stretch
	 */
	setTextureStretch(stretch) {
		this._textureStretch = stretch;
	}

	/**
	 * Gets the y offset from 0 to 1 of the texture.
	 * @returns {number}
	 */
	getTextureYOffset() {
		return this._textureYOffset;
	}

	/**
	 * Sets the y offset from 0 to 1 of the texture. Defaults to 0.
	 * @param {number} offset
	 */
	setTextureYOffset(offset) {
		this._textureYOffset = offset;
	}

	/**
	 * Gets the u offset from 0 to 1 of the start of the line. 0 is at Entity1, 1 is at Entity2.
	 * @returns {number}
	 */
	getUOffsetStart() {
		return this._uOffsetStart;
	}

	/**
	 * Sets the u offset from 0 to 1 of the start of the line. 0 is at Entity1, 1 is at Entity2. Defaults to 0.
	 * @param {number} uOffsetStart
	 */
	setUOffsetStart(uOffsetStart) {
		this._uOffsetStart = uOffsetStart;
	}

	/**
	 * Gets the u offset from 0 to 1 of the end of the line. 0 is at Entity1, 1 is at Entity2.
	 * @returns {number}
	 */
	getUOffsetEnd() {
		return this._uOffsetEnd;
	}

	/**
	 * Sets the u offset from 0 to 1 of the end of the line. 0 is at Entity1, 1 is at Entity2. Defaults to 1.
	 * @param {number} uOffsetEnd
	 */
	setUOffsetEnd(uOffsetEnd) {
		this._uOffsetEnd = uOffsetEnd;
	}

	/**
	 * Gets the color multiplier of the sprite. Each pixel in the texture is multiplied by this color.
	 * @returns {Color}
	 */
	getColorMultiplier() {
		return this._colorMultiplier;
	}

	/**
	 * Sets the color multiplier of the sprite. Each pixel in the texture is multiplied by this color. It defaults to (1, 1, 1, 1).
	 * @param {Color} colorMultiplier
	 */
	setColorMultiplier(colorMultiplier) {
		this._colorMultiplier.thaw();
		this._colorMultiplier.copy(colorMultiplier);
		this._colorMultiplier.freeze();
		ThreeJsHelper.setUniformColorRGBA(this.getThreeJsMaterials()[0], 'color', this._colorMultiplier, 1.0);
	}

	/**
	 * Sets blending mode. It defaults to 'normal'.
	 * @param {string} blending - one of 'normal', 'additive', 'subtractive', 'multliply', 'custom', or 'none'
	 */
	setBlending(blending) {
		this._blending = blending;
		ThreeJsHelper.setBlending(this.getThreeJsMaterials()[0], blending);
	}

	/**
	 * Updates the camera-independent parts of the component.
	 * @override
	 * @internal
	 */
	__update() {
		const entity1 = this._entity1.get();
		const entity2 = this._entity2.get();
		if (entity1 === null || entity2 === null) {
			return;
		}

		// Update the radius of the component.
		const offset = Vector3.pool.get();
		entity1.getPositionRelativeToEntity(offset, Vector3.Zero, entity2);
		this.__setRadius(offset.magnitude() + entity1.getExtentsRadius() + entity2.getExtentsRadius());
		Vector3.pool.release(offset);
	}

	/**
	 * Prepares the component for rendering.
	 * @param {CameraComponent} camera
	 * @override
	 * @internal
	 */
	__prepareForRender(camera) {
		// If either entity is not there, don't show the line.
		const entity1 = this._entity1.get();
		const entity2 = this._entity2.get();
		if (entity1 === null || entity2 === null) {
			this.getThreeJsObjects()[0].visible = false;
			return;
		}

		// Get the two positions in camera space.
		const cameraSpacePosition1 = Vector3.pool.get();
		const cameraSpacePosition2 = Vector3.pool.get();
		const cameraSpacePosition1Temp = Vector3.pool.get();
		cameraSpacePosition1.rotate(entity1.getOrientation(), this._entity1Offset);
		cameraSpacePosition1.add(entity1.getCameraSpacePosition(camera), cameraSpacePosition1);
		cameraSpacePosition2.rotate(entity2.getOrientation(), this._entity2Offset);
		cameraSpacePosition2.add(entity2.getCameraSpacePosition(camera), cameraSpacePosition2);
		cameraSpacePosition1Temp.copy(cameraSpacePosition1);
		cameraSpacePosition1.lerp(cameraSpacePosition1, cameraSpacePosition2, this._uOffsetStart);
		cameraSpacePosition2.lerp(cameraSpacePosition1Temp, cameraSpacePosition2, this._uOffsetEnd);

		// Make entity1 always the closer of the two. Indicate if they were flipped.
		let entity1DistanceToCamera = cameraSpacePosition1.magnitude();
		let entity2DistanceToCamera = cameraSpacePosition2.magnitude();
		let entitiesFlipped = false;
		if (entity1DistanceToCamera > entity2DistanceToCamera) {
			cameraSpacePosition1Temp.copy(cameraSpacePosition1);
			cameraSpacePosition1.copy(cameraSpacePosition2);
			cameraSpacePosition2.copy(cameraSpacePosition1Temp);
			entity1DistanceToCamera = cameraSpacePosition1.magnitude();
			entity2DistanceToCamera = cameraSpacePosition2.magnitude();
			entitiesFlipped = true;
		}
		Vector3.pool.release(cameraSpacePosition1Temp);

		// If we're in pixel mode, make sure the camera-space positions don't get too far from the normal clipping box or
		// visual bugs can appear. This clips their x and y to the normal-space bounds.
		const normalSpacePosition1 = Vector3.pool.get();
		const normalSpacePosition2 = Vector3.pool.get();
		camera.getNormalSpacePositionFromCameraSpacePosition(normalSpacePosition1, cameraSpacePosition1);
		camera.getNormalSpacePositionFromCameraSpacePosition(normalSpacePosition2, cameraSpacePosition2);

		// Get the vertical and horizontal axes. The vertical is the one connecting the entities.
		const vAxis = Vector3.pool.get();
		const hAxis = Vector3.pool.get();
		vAxis.sub(cameraSpacePosition2, cameraSpacePosition1);
		hAxis.copy(cameraSpacePosition1);
		hAxis.cross(vAxis, hAxis);
		hAxis.normalize(hAxis);

		// Make the vAxis shorter so that we don't have such large numbers.
		vAxis.setMagnitude(vAxis, Math.min(vAxis.magnitude(), entity1DistanceToCamera * 10));
		cameraSpacePosition2.add(cameraSpacePosition1, vAxis);
		camera.getNormalSpacePositionFromCameraSpacePosition(normalSpacePosition2, cameraSpacePosition2);
		entity2DistanceToCamera = cameraSpacePosition2.magnitude();

		// Get the widths at each entity and the 'midpoint' value.
		let width1 = entitiesFlipped ? this._width2 : this._width1;
		let width2 = entitiesFlipped ? this._width1 : this._width2;

		// If we're in pixel mode, the widths need to be adjusted from pixel-space to camera-space.
		if (this._widthUnits === 'px') {
			const forward = Vector3.pool.get();
			camera.getEntity().getOrientation().getAxis(forward, 1);
			width1 = camera.getViewport().getNormalSpaceRadiusFromPixelSpaceRadius(width1);
			width2 = camera.getViewport().getNormalSpaceRadiusFromPixelSpaceRadius(width2);
			width1 = camera.getRadiusFromNormalSpaceRadius(width1, cameraSpacePosition1.dot(forward));
			width2 = camera.getRadiusFromNormalSpaceRadius(width2, cameraSpacePosition2.dot(forward));
			Vector3.pool.release(forward);
		}

		// Get the amount to repeat the texture.
		let repeatAmount = 1 / this._textureStretch;
		if (this._widthUnits === 'px') {
			if (this._textureRepeat) {
				const pixelSpacePosition1 = Vector2.pool.get();
				const pixelSpacePosition2 = Vector2.pool.get();
				camera.getViewport().getPixelSpacePositionFromNormalSpacePosition(pixelSpacePosition1, normalSpacePosition1);
				camera.getViewport().getPixelSpacePositionFromNormalSpacePosition(pixelSpacePosition2, normalSpacePosition2);
				const pixelDiff = Vector2.pool.get();
				pixelDiff.sub(pixelSpacePosition1, pixelSpacePosition2);
				if (pixelDiff.isNaN()) {
					const bounds = camera.getViewport().getBounds();
					pixelDiff.x = bounds.size.x * (normalSpacePosition2.x - normalSpacePosition1.x) / 2.0;
					pixelDiff.y = bounds.size.y * (normalSpacePosition2.y - normalSpacePosition1.y) / 2.0;
				}
				repeatAmount *= pixelDiff.magnitude() / Math.max(this._width1, this._width2) * this._textureAspectRatio;

				Vector2.pool.release(pixelDiff);
				Vector2.pool.release(pixelSpacePosition1);
				Vector2.pool.release(pixelSpacePosition2);
			}
		}
		else {
			if (this._textureRepeat) {
				repeatAmount *= vAxis.magnitude() / Math.max(this._width1, this._width2) * this._textureAspectRatio;
			}
		}
		Vector3.pool.release(normalSpacePosition1);
		Vector3.pool.release(normalSpacePosition2);

		// Set the camera position of the Three.js object.
		ThreeJsHelper.setPosition(this.getThreeJsObjects(), cameraSpacePosition1);

		// Update the uniforms.
		ThreeJsHelper.setUniformVector3(this.getThreeJsMaterials()[0], 'vAxis', vAxis);
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'width1', width1);
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'width2', width2);
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'textureYOffset', this._textureYOffset * (entitiesFlipped ? -1 : 1));
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'repeatAmount', repeatAmount);

		// Release the temporaries.
		Vector3.pool.release(cameraSpacePosition1);
		Vector3.pool.release(cameraSpacePosition2);
		Vector3.pool.release(vAxis);
		Vector3.pool.release(hAxis);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	async __loadResources() {
		// Load the texture.
		const texture = await ThreeJsHelper.loadTexture(this, this._textureUrl, true, false);
		texture.wrapT = THREE.RepeatWrapping;
		this._textureAspectRatio = texture.image.width / texture.image.height;

		// Check if the component has since stopped loading.
		if (this.getLoadState() !== 'loading') {
			ThreeJsHelper.destroyTexture(texture);
			return;
		}

		// Create the material.
		const threeJsMaterial = this.getEntity().getScene().getEngine().getMaterialManager().getPreloaded('connected_sprite');
		this.getThreeJsMaterials().push(threeJsMaterial);
		ThreeJsHelper.setBlending(this.getThreeJsMaterials()[0], this._blending);
		ThreeJsHelper.setDefine(threeJsMaterial, 'PIXEL_BASED', (this._widthUnits === 'px'));
		ThreeJsHelper.setUniformColorRGBA(threeJsMaterial, 'color', this._colorMultiplier, 1.0);
		ThreeJsHelper.setUniformTexture(threeJsMaterial, 'colorTexture', texture);

		// Create the object.
		const threeJsObject = ThreeJsHelper.createMeshObject(this, threeJsMaterial, [{ name: 'position', dimensions: 3 }], false);
		this.getThreeJsObjects().push(threeJsObject);

		// Setup the vertices and indices.
		ThreeJsHelper.setVertices(threeJsObject.geometry, 'position', new Float32Array([-1, -1, 0, 1, -1, 0, -1, -1, 1, 1, -1, 1]));
		ThreeJsHelper.setIndices(threeJsObject.geometry, new Uint16Array([0, 2, 3, 3, 1, 0]));
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		ThreeJsHelper.destroyAllObjectsAndMaterials(this);
	}
}
