/**
 * Code to create placemarks for earth
 * @module
 *
 * @copyright Copyright 2009-2018, by the California Institute of Technology. ALL RIGHTS RESERVED.
 * United States Government Sponsorship acknowledged. Any commercial use must be
 * negotiated with the Office of Technology Transfer at the California Institute of Technology.
 */

module.exports = {
	Yerevan: {
		text: 'Yerevan',
		latLng: [40.183333, 44.516667]
	},
	Sydney: {
		text: 'Sydney',
		latLng: [-33.867139, 151.207114]
	},
	'La Paz': {
		text: 'La Paz',
		latLng: [-16.49901, -68.146248]
	},
	Bangkok: {
		text: 'Bangkok',
		latLng: [13.7234186, 100.4762319]
	},
	Houston: {
		text: 'Houston',
		latLng: [29.7632836, -95.3632715]
	},
	Vancouver: {
		text: 'Vancouver',
		latLng: [49.263588, -123.138565]
	},
	Berlin: {
		text: 'Berlin',
		latLng: [52.5234051, 13.4113999]
	},
	Bogota: {
		text: 'Bogota',
		latLng: [4.609866, -74.08205]
	},
	Manila: {
		text: 'Manila',
		latLng: [14.6010326, 120.9761599]
	},
	Lagos: {
		text: 'Lagos',
		latLng: [6.441158, 3.417977]
	},
	Tehran: {
		text: 'Tehran',
		latLng: [35.696216, 51.422945]
	},
	Istanbul: {
		text: 'Istanbul',
		latLng: [41.012379, 28.975926]
	},
	Marrakesh: {
		text: 'Marrakesh',
		latLng: [31.631321, -8.012478]
	},
	Ulaanbaatar: {
		text: 'Ulaanbaatar',
		latLng: [47.921378, 106.90554]
	},
	'New York': {
		text: 'New York',
		latLng: [40.7142691, -74.0059729]
	},
	'Los Angeles': {
		text: 'Los Angeles',
		latLng: [34.0522342, -118.2436849]
	},
	Beijing: {
		text: 'Beijing',
		latLng: [39.904667, 116.408198]
	},
	Tokyo: {
		text: 'Tokyo',
		latLng: [35.6894875, 139.6917064]
	},
	Johannesburg: {
		text: 'Johannesburg',
		latLng: [-26.201452, 28.045488]
	},
	Delhi: {
		text: 'Delhi',
		latLng: [28.635308, 77.22496]
	},
	Moscow: {
		text: 'Moscow',
		latLng: [55.755786, 37.617633]
	},
	Paris: {
		text: 'Paris',
		latLng: [48.8566667, 2.3509871]
	},
	'Rio De Janeiro': {
		text: 'Rio De Janeiro',
		latLng: [-22.9035393, -43.2095869]
	},
	'Mexico City': {
		text: 'Mexico City',
		latLng: [19.4270499, -99.1275711]
	},
	London: {
		text: 'London',
		latLng: [51.5001524, -0.1262362]
	},
	Cairo: {
		text: 'Cairo',
		latLng: [30.064742, 31.249509]
	},
	Mumbai: {
		text: 'Mumbai',
		latLng: [19.017656, 72.856178]
	},
	Karachi: {
		text: 'Karachi',
		latLng: [24.893379, 67.028061]
	},
	Honolulu: {
		text: 'Honolulu',
		latLng: [21.3069444, -157.8583333]
	},
	Anchorage: {
		text: 'Anchorage',
		latLng: [61.2180556, -149.9002778]
	},
	Shanghai: {
		text: 'Shanghai',
		latLng: [31.230708, 121.472916]
	},
	Miami: {
		text: 'Miami',
		latLng: [25.7742657, -80.1936589]
	},
	'Kansas City': {
		text: 'Kansas City',
		latLng: [39.0997265, -94.5785667]
	},
	'Buenos Aires': {
		text: 'Buenos Aires',
		latLng: [-34.6084175, -58.3731613]
	},
	Oslo: {
		text: 'Oslo',
		latLng: [59.9138204, 10.7387413]
	},
	Singapore: {
		text: 'Singapore',
		latLng: [1.352083, 103.819836]
	},
	Lisbon: {
		text: 'Lisbon',
		latLng: [38.7071631, -9.135517]
	},
	Rome: {
		text: 'Rome',
		latLng: [41.8954656, 12.4823243]
	},
	Perth: {
		text: 'Perth',
		latLng: [-31.9554, 115.85859]
	},
	Darwin: {
		text: 'Darwin',
		latLng: [-12.461334, 130.841904]
	},
	Christchurch: {
		text: 'Christchurch',
		latLng: [-43.531637, 172.636645]
	},
	Guam: {
		text: 'Guam',
		latLng: [13.444304, 144.793731]
	}
};

