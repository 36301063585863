/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';

/**
 * A controller that spins an entity at keyframed rates along a given axis.
 */
export class KeyframeSpinController extends Pioneer.BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Pioneer.Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The keyframes. The components are [time, rate, starting angle].
		 * @type {[number, number, number][]}
		 * @private
		 */
		this._keyframes = [];

		/**
		 * The frame-space axis to use for the spinning.
		 * @type {Pioneer.Vector3}
		 * @private
		 */
		this._axis = new Pioneer.Vector3(1, 0, 0);

		/**
		 * The starting angle at keyframe 0.
		 * @type {number}
		 * @private
		 */
		this._startingAngle = 0;

		this.addModifiedState('orientation');
	}

	/**
	 * Sets the keyframes. Each keyframe is a [time, rate]. The rate uses the right-hand rotation along the specified axis.
	 * @param {[number, number][]} keyframes
	 */
	setKeyframes(keyframes) {
		this._keyframes = [];
		let lastAngle = 0;
		for (let i = 0, l = keyframes.length; i < l; i++) {
			if (i > 0) {
				// Get the accumulated angle over the previous duration, taking into account changing spin rates.
				lastAngle = Pioneer.MathUtils.wrap(lastAngle + (keyframes[i][0] - keyframes[i - 1][0]) * (keyframes[i - 1][1] + keyframes[i][1]) * 0.5, 0, 2 * Math.PI);
			}
			this._keyframes.push([keyframes[i][0], keyframes[i][1], lastAngle]);
		}
		this._keyframes.sort((a, b) => a[0] - b[0]);
	}

	/**
	 * Sets the frame-space axis to use for the spinning.
	 * @param {Pioneer.Vector3} axis
	 */
	setAxis(axis) {
		this._axis.copy(axis);
	}

	/**
	 * Sets the starting angle at keyframe 0.
	 * @param {number} angle
	 */
	setStartingAngle(angle) {
		this._startingAngle = angle;
	}

	/**
	 * Updates given orientation for the given time.
	 * @param {Pioneer.Quaternion} orientation - The orientation to update.
	 * @param {number} time - The time to check.
	 * @override
	 */
	__updateOrientationAtTime(orientation, time) {
		if (orientation.isNaN()) {
			orientation.copy(Pioneer.Quaternion.Identity);
		}
		this._getNewOrientation(orientation, time, orientation);
	}

	/**
	 * Updates the controller.
	 * @override
	 */
	__update() {
		if (this._keyframes.length === 0) {
			return;
		}
		const entity = this.getEntity();
		const time = entity.getScene().getEngine().getTime();
		if (entity.getOrientation().isNaN()) {
			entity.setOrientation(Pioneer.Quaternion.Identity);
		}
		const newOrientation = Pioneer.Quaternion.pool.get();
		this._getNewOrientation(newOrientation, time, entity.getOrientation());
		entity.setOrientation(newOrientation);
		Pioneer.Quaternion.pool.release(newOrientation);
	}

	/**
	 * Gets a new orientation, given the time and an existing orientation.
	 * @param {Pioneer.Quaternion} newOrientation
	 * @param {number} time
	 * @param {Pioneer.Quaternion} oldOrientation
	 * @private
	 */
	_getNewOrientation(newOrientation, time, oldOrientation) {
		const index = Pioneer.Sort.getIndex(time, this._keyframes, (a, time) => a[0] < time);
		let prevIndex = 0;
		let nextIndex = 0;
		if (index === this._keyframes.length) { // After last keyframe time.
			prevIndex = this._keyframes.length - 1;
			nextIndex = this._keyframes.length - 1;
		}
		else if (index > 0) {
			prevIndex = index - 1;
			nextIndex = index;
		}
		const prevKeyframe = this._keyframes[prevIndex];
		const nextKeyframe = this._keyframes[nextIndex];
		const u = (nextKeyframe[0] > prevKeyframe[0])
			? (time - prevKeyframe[0]) / (nextKeyframe[0] - prevKeyframe[0])
			: 0;
		const angle = Pioneer.MathUtils.wrap(this._startingAngle + prevKeyframe[2] + (time - prevKeyframe[0]) * (prevKeyframe[1] + u * nextKeyframe[1]) / (1 + u), 0, 2 * Math.PI);
		const axis = Pioneer.Vector3.pool.get();
		axis.rotate(oldOrientation, this._axis);
		const rotation = Pioneer.Quaternion.pool.get();
		rotation.setFromAxisAngle(axis, angle);
		newOrientation.mult(rotation, oldOrientation);
		Pioneer.Vector3.pool.release(axis);
		Pioneer.Quaternion.pool.release(rotation);
	}
}
