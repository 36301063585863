/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	Entity,
	LatLonAlt,
	MaterialUtils,
	MathUtils,
	THREE,
	ThreeJsHelper,
	Vector3
} from '../../internal';

/** The skybox component. */
export class SkyboxComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The number of latitudinal lines. The longitudinal lines will be double it.
		 * @type {number}
		 * @private
		 */
		this._numLatVerts = 20;

		/**
		 * The texture URL.
		 * @type {string}
		 * @private
		 */
		this._textureUrl = '';

		// Set the radius to the whole universe.
		this.__setRadius(1e24);

		// It uses the orientatin of the entity.
		this.__setUsesEntityOrientation(true);
	}

	/**
	 * Gets the texture url.
	 * @returns {string}
	 */
	getTextureUrl() {
		return this._textureUrl;
	}

	/**
	 * Sets the texture url.
	 * @param {string} url
	 */
	setTextureUrl(url) {
		this._textureUrl = url;
		this.resetResources();
	}

	/**
	 * Prepare the component for rendering.
	 * @param {CameraComponent} camera
	 * @override
	 * @internal
	 */
	__prepareForRender(camera) {
		// Set the orientation to the entity's orientation.
		ThreeJsHelper.setOrientationToEntity(this.getThreeJsObjects()[0], this.getEntity());

		// Set the Three.js object position the entity's camera-space position.
		ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects()[0], this.getEntity(), camera);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	async __loadResources() {
		// Load the texture.
		const texture = await ThreeJsHelper.loadTexture(this, this._textureUrl, false, false);

		// Check if the component has since stopped loading.
		if (this.getLoadState() !== 'loading') {
			ThreeJsHelper.destroyTexture(texture);
			return;
		}

		// Create Three.js material.
		const material = MaterialUtils.get();
		this.getThreeJsMaterials().push(material);
		material.defines['colorMapEmmissive'] = true;
		material.needsUpdate = true;
		material.uniforms['colorTexture'].value = texture;

		// Create the Three.js object.
		const object = ThreeJsHelper.createMeshObject(this, material, [{ name: 'position', dimensions: 3 }, { name: 'uv', dimensions: 2 }], false);
		this.getThreeJsObjects().push(object);

		// Make it used in the dynamic environment map.
		ThreeJsHelper.useInDynEnvMap(object, true);

		// Setup the geometry.
		const latStep = MathUtils.pi / (this._numLatVerts - 1);
		const lonStep = MathUtils.pi / this._numLatVerts;
		const numVerts = (this._numLatVerts * 2 + 1) * this._numLatVerts;

		const meshPositions = new Float32Array(numVerts * 3);
		const meshUVs = new Float32Array(numVerts * 2);
		const meshIndices = new Uint16Array(this._numLatVerts * (this._numLatVerts - 1) * 12);

		const xyz = Vector3.pool.get();
		const lla = LatLonAlt.pool.get();
		for (let latI = 0; latI < this._numLatVerts; latI++) {
			for (let lonI = 0; lonI < this._numLatVerts * 2 + 1; lonI++) {
				// Calculate the lla.
				lla.lat = latI * latStep - MathUtils.halfPi;
				lla.lon = lonI * lonStep - MathUtils.pi;
				lla.alt = 0;

				// Calculate the xyz from the lla.
				xyz.x = 5e23 * Math.cos(lla.lat) * Math.cos(lla.lon);
				xyz.y = 5e23 * Math.cos(lla.lat) * Math.sin(lla.lon);
				xyz.z = 5e23 * Math.sin(lla.lat);

				const vertexI = latI * (this._numLatVerts * 2 + 1) + lonI;
				meshPositions[vertexI * 3 + 0] = xyz.x;
				meshPositions[vertexI * 3 + 1] = xyz.y;
				meshPositions[vertexI * 3 + 2] = xyz.z;
				meshUVs[vertexI * 2 + 0] = 0.5 - lla.lon / MathUtils.twoPi;
				meshUVs[vertexI * 2 + 1] = 0.5 - lla.lat / MathUtils.pi;

				const triangleI = latI * this._numLatVerts * 2 + lonI;
				if (latI < this._numLatVerts - 1 && lonI < this._numLatVerts * 2) {
					meshIndices[triangleI * 6 + 0] = (this._numLatVerts * 2 + 1) * (latI + 0) + (lonI + 0);
					meshIndices[triangleI * 6 + 1] = (this._numLatVerts * 2 + 1) * (latI + 1) + (lonI + 0);
					meshIndices[triangleI * 6 + 2] = (this._numLatVerts * 2 + 1) * (latI + 1) + (lonI + 1);
					meshIndices[triangleI * 6 + 3] = (this._numLatVerts * 2 + 1) * (latI + 0) + (lonI + 0);
					meshIndices[triangleI * 6 + 4] = (this._numLatVerts * 2 + 1) * (latI + 1) + (lonI + 1);
					meshIndices[triangleI * 6 + 5] = (this._numLatVerts * 2 + 1) * (latI + 0) + (lonI + 1);
				}
			}
		}
		LatLonAlt.pool.release(lla);
		Vector3.pool.release(xyz);
		const geometry = /** @type {THREE.Mesh} */(this.getThreeJsObjects()[0]).geometry;
		ThreeJsHelper.setVertices(geometry, 'position', meshPositions);
		ThreeJsHelper.setVertices(geometry, 'uv', meshUVs);
		ThreeJsHelper.setIndices(geometry, meshIndices);
	}

	/**
	 * Unloads the resources.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		ThreeJsHelper.destroyAllObjectsAndMaterials(this);
	}
}
