/** @module pioneer-scripts */
import { Entity } from '../entity';
import * as Pioneer from 'pioneer';

Entity.register({
	sc_apollo_15: {
		groups: ['moon', 'spacecraft'],
		radius: 0.004,
		label: 'Apollo 15',
		parents: [
			[-897044358.3260887, 'moon'],
			[-896822958.3195117, '']
		],
		trail: {
			length: 6855.0
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_apollo_15',
			dataType: 'pos'
		}, {
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}]
	},
	sc_artemis_1: {
		groups: ['moon', 'spacecraft'],
		radius: 0.005,
		label: 'Artemis I',
		parents: [
			[721860361, 'earth'],
			[724052473, '']
		],
		trail: {
			length: undefined
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_artemis_1/artemis_cmsm.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_artemis_1/earth/pos'
		}, {
			type: 'align',
			primary: {
				type: 'velocity',
				target: 'sc_artemis_1',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				target: 'moon',
				axis: Pioneer.Vector3.ZAxis,
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_capstone: {
		groups: ['moon', 'spacecraft'],
		radius: 0.0005,
		label: 'CAPSTONE',
		parents: [
			[710192447.9999855, 'earth'],
			[721583059, 'moon']
		],
		trail: {
			length: undefined,
			lengthCoverages: [
				[4000000, Number.NEGATIVE_INFINITY, 721583059],
				[1210909, 721583059, Number.POSITIVE_INFINITY]
			]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_capstone/capstone.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_capstone/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_capstone/moon/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.ZAxisNeg
			},
			secondary: {
				type: 'align',
				target: 'moon',
				axis: Pioneer.Vector3.XAxis,
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_clementine: {
		groups: ['moon', 'spacecraft'],
		radius: 0.002,
		label: 'Clementine',
		parents: [
			[-187185539.81536362, 'moon'],
			[-178496939.81459716, '']
		],
		trail: {
			length: 5408.5
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_clementine/clementine.gltf',
			shadowEntities: ['moon']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_clementine/moon/all',
			dataType: 'pos'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'earth',
				axis: Pioneer.Vector3.YAxisNeg
			},
			secondary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.ZAxisNeg
			}
		}]
	},
	sc_grail_a: {
		groups: ['moon', 'spacecraft'],
		radius: 0.001,
		label: 'Ebb',
		parents: [
			[368763000, 'moon'],
			[414201667.18513304, '']
		],
		trail: {
			length: 41115.75
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_grail/grail_b.gltf',
			shadowEntities: ['moon']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_grail_a/moon/all',
			dataType: 'pos'
		}, {
			type: 'align',
			primary: {
				type: 'velocity',
				target: 'sc_grail_a',
				axis: Pioneer.Vector3.XAxisNeg
			},
			secondary: {
				type: 'point',
				target: 'moon',
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_grail_b: {
		groups: ['moon', 'spacecraft'],
		radius: 0.001,
		label: 'Flow',
		parents: [
			[368763000, 'moon'],
			[414201667.18513304, '']
		],
		trail: {
			length: 41239.8
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_grail/grail_b.gltf',
			shadowEntities: ['moon']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_grail_b/moon/all',
			dataType: 'pos'
		}, {
			type: 'align',
			primary: {
				type: 'velocity',
				target: 'sc_grail_b',
				axis: Pioneer.Vector3.XAxisNeg
			},
			secondary: {
				type: 'point',
				target: 'moon',
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_ladee: {
		groups: ['moon', 'spacecraft'],
		radius: 0.0012,
		label: 'LADEE',
		parents: [
			[431798467.18253195, 'moon'],
			[451355187.44558257, '']
		],
		trail: {
			length: 7889.57
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_ladee/ladee.gltf',
			shadowEntities: ['moon']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_ladee/moon/all',
			dataType: 'pos'
		}, {
			type: 'align',
			primary: {
				type: 'velocity',
				target: 'sc_ladee',
				axis: Pioneer.Vector3.XAxisNeg
			},
			secondary: {
				type: 'point',
				target: 'moon',
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_lcross: {
		groups: ['moon', 'spacecraft'],
		occlusionRadius: 0.00130,
		extentsRadius: 0.0022,
		label: 'LCROSS',
		parents: [
			[298635466.0304444, 'moon'],
			[308360200.75, '']
		],
		trail: {
			length: 3598022.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_lcross/lcross.gltf',
			rotate: [
				{ z: -90 }
			],
			shadowEntities: ['moon']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_lcross/moon/all',
			dataType: 'pos',
			coverage: [Number.NEGATIVE_INFINITY, 308360200.750]
		}, {
			type: 'align',
			primary: {
				type: 'velocity',
				target: 'sc_lcross',
				axis: Pioneer.Vector3.XAxisNeg
			},
			secondary: {
				type: 'point',
				target: 'moon',
				axis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_lcross_impact_site: {
		groups: ['moon', 'sc_lcross', 'sites'],
		radius: 0.001,
		systemRadius: 200,
		label: 'LCROSS Impact Site',
		parents: [
			[298635466.0304444, 'moon']
		],
		controllers: [{
			type: 'fixed',
			llaOnSpheroid: new Pioneer.LatLonAlt(-1.4782008103461848, -0.8648271467797672, 0),
			coverage: [298635466.0304444, Number.POSITIVE_INFINITY]
		}]
	},
	sc_lunar_flashlight: {
		groups: ['moon', 'spacecraft'],
		occlusionRadius: 0.00025,
		extentsRadius: 0.0005,
		label: 'Lunar Flashlight',
		parents: [
			[724019554, 'earth'],
			[737164869, '']
		],
		trail: {
			length: 10000000
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_lunar_flashlight/lunar_flashlight.gltf',
			shadowEntities: ['earth']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_lunar_flashlight/earth_v3'
		}, {
			type: 'align',
			primary: {
				type: 'align',
				target: 'moon',
				axis: Pioneer.Vector3.ZAxisNeg,
				targetAxis: Pioneer.Vector3.ZAxis
			},
			secondary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			}
		}]
	},
	sc_lunar_icecube: {
		groups: ['moon', 'spacecraft'],
		occlusionRadius: 0.0002,
		extentsRadius: 0.0012,
		label: 'Lunar IceCube',
		parents: [
			[721864426, 'earth'],
			[734111601, 'moon']
		],
		trail: {
			length: undefined,
			lengthCoverages: [
				[3000000, Number.NEGATIVE_INFINITY, 734111601],
				[441920, 734111601, Number.POSITIVE_INFINITY]
			]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_lunar_icecube/lunar_icecube.gltf',
			shadowEntities: ['earth', 'moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_lunar_icecube/earth/pos'
		}, {
			type: 'dynamo',
			url: 'sc_lunar_icecube/moon/orb'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			},
			secondary: {
				type: 'align',
				target: 'sun',
				axis: Pioneer.Vector3.ZAxis,
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}]
	},
	sc_lunar_prospector: {
		groups: ['moon', 'spacecraft'],
		occlusionRadius: 0.00065300,
		extentsRadius: 0.0022,
		label: 'Lunar Prospector',
		parents: [
			[-62206800, 'moon'],
			[-13402020, '']
		],
		trail: {
			length: 7260.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_lunar_prospector/lunar_prospector.gltf',
			shadowEntities: ['moon']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_lunar_prospector/moon/all',
			dataType: 'pos'
		}, {
			type: 'align',
			primary: {
				type: 'align',
				target: 'moon',
				axis: Pioneer.Vector3.YAxisNeg,
				targetAxis: Pioneer.Vector3.ZAxis
			}
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.YAxisNeg,
			periodInHours: 0.008333
		}]
	},
	sc_lunar_reconnaissance_orbiter: {
		groups: ['moon', 'spacecraft'],
		occlusionRadius: 0.00272,
		extentsRadius: 0.0038,
		label: 'Lunar Reconnaissance Orbiter',
		parents: [
			[298635426.1844444, 'earth'],
			[298929666, 'moon']
		],
		trail: {
			length: 7106.66,
			lengthCoverages: [
				[1000000, Number.NEGATIVE_INFINITY, 299040641],
				[7106.66, 299040641, Number.POSITIVE_INFINITY]
			]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_lunar_reconnaissance_orbiter/LRO.gltf',
			rotate: [
				{ z: -90 }
			],
			shadowEntities: ['moon']
		},
		controllers: [{
			type: 'dynamo',
			url: 'sc_lunar_reconnaissance_orbiter/earth/orb'
		}, {
			type: 'dynamo',
			url: 'sc_lunar_reconnaissance_orbiter/moon/orb'
		}, {
			type: 'align',
			primary: {
				type: 'velocity',
				target: 'sc_lunar_reconnaissance_orbiter',
				axis: Pioneer.Vector3.XAxisNeg
			},
			secondary: {
				type: 'point',
				target: 'moon',
				axis: Pioneer.Vector3.ZAxis
			}
		}, {
			type: 'dynamo',
			url: 'sc_lunar_reconnaissance_orbiter/ori'
		}]
	},
	sc_smart_1: {
		// no model at all; 2D still
		groups: ['moon', 'spacecraft'],
		occlusionRadius: 0.0015,
		extentsRadius: 0.006530,
		label: 'SMART-1',
		parents: [
			[117977163.35299999, 'moon'],
			[210585600, '']
		],
		trail: {
			length: 273091.0
		},
		controllers: [{
			type: 'fixed',
			orientation: Pioneer.Quaternion.Identity
		}, {
			type: 'animdata',
			url: 'sc_smart_1/moon/all',
			dataType: 'pos'
		}]
	},
	sc_themis_b: {
		groups: ['moon', 'spacecraft'],
		occlusionRadius: 0.000400,
		extentsRadius: 0.00170,
		label: 'ARTEMIS P1',
		parents: [
			[366638466.1829504, 'moon'],
			[694267209.1839211, '']
		],
		trail: {
			length: 94838.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_themis/themis.gltf',
			shadowEntities: ['moon']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_themis_b/moon/all',
			dataType: 'pos'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			}
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.YAxis,
			periodInHours: 0.0008333
		}]
	},
	sc_themis_c: {
		groups: ['moon', 'spacecraft'],
		occlusionRadius: 0.000400,
		extentsRadius: 0.00170,
		label: 'ARTEMIS P2',
		parents: [
			[366638466.1829504, 'moon'],
			[694267209.1839211, '']
		],
		trail: {
			length: 204186.0
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/sc_themis/themis.gltf',
			shadowEntities: ['moon']
		},
		controllers: [{
			type: 'animdata',
			url: 'sc_themis_c/moon/all',
			dataType: 'pos'
		}, {
			type: 'align',
			primary: {
				type: 'point',
				target: 'sun',
				axis: Pioneer.Vector3.YAxis
			}
		}, {
			type: 'spin',
			axis: Pioneer.Vector3.YAxis,
			periodInHours: 0.0008333
		}]
	}
});
