/** @module pioneer */
import {
	FastIterable
} from '../internal';

/**
 * An entry in the map.
 * @template KeyType
 * @template ValueType
 */
export class FastMapEntry {
	/**
	 * Constructor.
	 * @param {KeyType} key
	 * @param {ValueType} value
	 */
	constructor(key, value) {
		/**
		 * The key.
		 * @type {KeyType}
		 */
		this.key = key;

		/**
		 * The value.
		 * @type {ValueType}
		 */
		this.value = value;
	}
}

/**
 * A map of key-value pairs with the same functions as the standard Map object, except for iteration.
 * It has getAt(index) and size for iteration.
 * It's O(n) and garbage for removes, O(1) and no garbage for adds, gets, sets, and iteration.
 * @template KeyType
 * @template ValueType
 * @extends {FastIterable<FastMapEntry<KeyType, ValueType>>}
 */
export class FastMap extends FastIterable {
	/**
	 * Constructor.
	 * @param {Iterable<FastMapEntry<KeyType, ValueType>>} [iterable]
	 */
	constructor(iterable) {
		super();

		/**
		 * The list of key-value pairs.
		 * @type {Array<FastMapEntry<KeyType, ValueType>>}
		 * @private
		 */
		this._entries = [];

		/**
		 * A mapping from keys to indices.
		 * @type {Map<KeyType, number>}
		 * @private
		 */
		this._keyMap = new Map();

		// Apply the iterable if it is supplied.
		if (iterable !== undefined) {
			for (const entry of iterable) {
				this._entries.push(new FastMapEntry(entry.key, entry.value));
				this._keyMap.set(entry.key, this._entries.length - 1);
			}
		}
	}

	/**
	 * Returns true if the key is in the map.
	 * @param {KeyType} key
	 * @returns {boolean}
	 */
	has(key) {
		return this._keyMap.has(key);
	}

	/**
	 * Gets the value given a key.
	 * @param {KeyType} key
	 * @returns {ValueType}
	 */
	get(key) {
		const index = this._keyMap.get(key);
		if (index !== undefined) {
			return this._entries[index].value;
		}
		return undefined;
	}

	/**
	 * Sets the value for the given key.
	 * @param {KeyType} key
	 * @param {ValueType} value
	 */
	set(key, value) {
		const index = this._keyMap.get(key);
		if (index !== undefined) {
			this._entries[index].value = value;
		}
		else {
			this._entries.push(new FastMapEntry(key, value));
			this._keyMap.set(key, this._entries.length - 1);
		}
	}

	/**
	 * Deletes the value for the given key. Returns true if the key existed.
	 * @param {KeyType} key
	 * @returns {boolean}
	 */
	delete(key) {
		const index = this._keyMap.get(key);
		if (index !== undefined) {
			this._entries.splice(index, 1);
			this._keyMap.delete(key);
			// Every index higher than the one deleted gets decremented by one. O(n) and generates garbage.
			for (const pair of this._keyMap) {
				if (pair[1] > index) {
					this._keyMap.set(pair[0], pair[1] - 1);
				}
			}
			return true;
		}
		else {
			return false;
		}
	}

	/**
	 * Deletes all of the key-value pairs.
	 */
	clear() {
		this._entries = [];
		this._keyMap.clear();
	}

	/**
	 * Gets the key-value pair of the given index.
	 * @param {number} index
	 * @returns {FastMapEntry<KeyType, ValueType>}
	 * @override
	 */
	getAt(index) {
		return this._entries[index];
	}

	/**
	 * Gets the number of key-value pairs.
	 * @returns {number}
	 * @override
	 */
	get size() {
		return this._entries.length;
	}
}
