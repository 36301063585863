/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	ComponentRef,
	Entity,
	EntityRef,
	MaterialUtils,
	MathUtils,
	Quaternion,
	ShaderChunkLogDepth,
	SpheroidComponent,
	THREE,
	ThreeJsHelper,
	Vector3
} from '../../internal';

/**
 * Rings, such as for Saturn.
 */
export class RingsComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The url for the top texture.
		 * @type {string}
		 * @private
		 */
		this._topTextureUrl = '';

		/**
		 * The url for the bottom texture.
		 * @type {string}
		 * @private
		 */
		this._bottomTextureUrl = '';

		/**
		 * The inner radius.
		 * @type {number}
		 * @private
		 */
		this._innerRadius = 0;

		/**
		 * The outer radius.
		 * @type {number}
		 * @private
		 */
		this._outerRadius = 0;

		/**
		 * The distance at which the ring begins to fade (50% less than this number and it is completely gone).
		 * @type {number}
		 * @private
		 */
		this._fadeDistance = 0;

		/**
		 * The entities uses for shadows. Derived from the shadow entity names.
		 * @type {EntityRef[]}
		 * @private
		 */
		this._shadowEntities = [];

		/**
		 * A reference to the spheroid component.
		 * @type {ComponentRef<SpheroidComponent>}
		 * @private
		 */
		this._spheroidComponentRef = new ComponentRef(this.getEntity().getScene());
		this._spheroidComponentRef.setByType(this.getEntity().getName(), 'spheroid');
		this._spheroidComponentRef.setRefChangedCallback(this._spheroidRefChangedCallback.bind(this));

		// Bind the callbacks to this.
		this._spheroidChangedCallback = this._spheroidChangedCallback.bind(this);

		// Lets the base component to check for valid orientation when determining whether this is visible.
		this.__setUsesEntityOrientation(true);
	}

	/**
	 * Gets the top texture url.
	 * @returns {string}
	 */
	getTopTextureUrl() {
		return this._topTextureUrl;
	}

	/**
	 * Sets the top texture url.
	 * @param {string} url
	 */
	setTopTextureUrl(url) {
		this._topTextureUrl = url;
		if (this.getLoadState() === 'loaded') {
			ThreeJsHelper.loadTextureIntoUniform(this, this.getThreeJsMaterials()[0].uniforms['topTexture'], this._topTextureUrl, true, false);
		}
	}

	/**
	 * Gets the bottom texture url.
	 * @returns {string}
	 */
	getBottomTextureUrl() {
		return this._bottomTextureUrl;
	}

	/**
	 * Sets the bottom texture url.
	 * @param {string} url
	 */
	setBottomTextureUrl(url) {
		this._bottomTextureUrl = url;
		if (this.getLoadState() === 'loaded') {
			ThreeJsHelper.loadTextureIntoUniform(this, this.getThreeJsMaterials()[0].uniforms['bottomTexture'], this._bottomTextureUrl, true, false);
		}
	}

	/**
	 * Gets the inner radius.
	 * @returns {number}
	 */
	getInnerRadius() {
		return this._innerRadius;
	}

	/**
	 * Sets the inner radius.
	 * @param {number} radius
	 */
	setInnerRadius(radius) {
		this._innerRadius = radius;
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'innerRadius', this._innerRadius);
	}

	/**
	 * Gets the outer radius.
	 * @returns {number}
	 */
	getOuterRadius() {
		return this._outerRadius;
	}

	/**
	 * Sets the outer radius.
	 * @param {number} radius
	 */
	setOuterRadius(radius) {
		this._outerRadius = radius;
		this.__setRadius(this._outerRadius);
		ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'outerRadius', this._outerRadius);
	}

	/**
	 * Gets the top texture used for the rings. The alpha channel is used by the main material for shadows.
	 * @returns {THREE.Texture}
	 */
	getTopTexture() {
		const material = this.getThreeJsMaterials()[0];
		if (material !== undefined) {
			return material.uniforms['topTexture'].value;
		}
		return null;
	}

	/**
	 * Gets the distance at which the ring begins to fade (50% less than this number and it is completely gone).
	 * @returns {number}
	 */
	getFadeDistance() {
		return this._fadeDistance;
	}

	/**
	 * Sets the distance at which the ring begins to fade (50% less than this number and it is completely gone). Defaults to 0.
	 * @param {number} fadeDistance
	 */
	setFadeDistance(fadeDistance) {
		this._fadeDistance = fadeDistance;
	}

	/**
	 * Returns the shadow entity or its name at the index.
	 * @param {number} index
	 * @returns {string | undefined}
	 */
	getShadowEntity(index) {
		return this._shadowEntities[index]?.getName();
	}

	/**
	 * Sets the shadow entities. Each element can be either the name of an entity or an entity itself.
	 * @param {string[]} shadowEntities
	 */
	setShadowEntities(shadowEntities) {
		this._shadowEntities = [];
		for (const shadowEntity of shadowEntities) {
			this._shadowEntities.push(new EntityRef(this.getEntity().getScene(), shadowEntity));
		}
		const shadowEntitiesEnabled = (shadowEntities.length > 0);
		for (let i = 0, l = this.getThreeJsMaterials().length; i < l; i++) {
			ThreeJsHelper.setDefine(this.getThreeJsMaterials()[i], 'shadowEntities', shadowEntitiesEnabled);
		}
	}

	/**
	 * Cleans up the component.
	 * @override
	 * @package
	 */
	__destroy() {
		// Remove the spheroid changed callback.
		const spheroidComponent = this._spheroidComponentRef.get();
		if (spheroidComponent !== null) {
			spheroidComponent.removeChangedCallback(this._spheroidChangedCallback);
		}

		super.__destroy();
	}

	/**
	 * Updates the camera-non-specific parts of the component.
	 * @override
	 * @package
	 */
	__update() {
		// Update the spheroid component reference.
		this._spheroidComponentRef.update();
	}

	/**
	 * Prepare the component for rendering.
	 * @param {CameraComponent} camera
	 * @override
	 * @internal
	 */
	__prepareForRender(camera) {
		// Set the alpha fade distance multiplier.
		if (this._fadeDistance > 0) {
			const posInSpriteFrame = Vector3.pool.get();
			const orientation = Quaternion.pool.get();
			const threeJsOrientation = this.getThreeJsObjects()[0].quaternion;
			orientation.copyFromThreeJs(threeJsOrientation);
			posInSpriteFrame.rotateInverse(this.getEntity().getOrientation(), this.getEntity().getCameraSpacePosition(camera));
			ThreeJsHelper.setUniformNumber(this.getThreeJsMaterials()[0], 'alphaFadeMultiplier',
				MathUtils.lerp(0, 1, MathUtils.clamp01(2 * (Math.abs(posInSpriteFrame.z) / this._fadeDistance - 1) + 1)));
			Quaternion.pool.release(orientation);
			Vector3.pool.release(posInSpriteFrame);
		}

		// Set the lightPosition and lightColor uniform.
		MaterialUtils.setLightSourceUniforms(this.getThreeJsMaterials(), this.getEntity(), camera);

		// Setup the regular uniforms.
		MaterialUtils.setUniforms(this.getThreeJsMaterials(), camera, this.getEntity(), this._shadowEntities, null, false);

		// Set the orientation to the entity's orientation.
		ThreeJsHelper.setOrientationToEntity(this.getThreeJsObjects()[0], this.getEntity());

		// Set the Three.js object position the entity's camera-space position.
		ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects()[0], this.getEntity(), camera);
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	async __loadResources() {
		// Load the textures.
		const topPromise = ThreeJsHelper.loadTexture(this, this._topTextureUrl, true, false);
		const bottomPromise = ThreeJsHelper.loadTexture(this, this._bottomTextureUrl, true, false);

		const [topTexture, bottomTexture] = await Promise.all([topPromise, bottomPromise]);

		// Check if the component has since been destroyed.
		if (this.isDestroyed()) {
			ThreeJsHelper.destroyTexture(topTexture);
			ThreeJsHelper.destroyTexture(bottomTexture);
			return;
		}

		// Create the material.
		const material = new THREE.ShaderMaterial({
			uniforms: {
				ambientLightColor: new THREE.Uniform(new THREE.Color()),
				lightPositions: new THREE.Uniform([new THREE.Vector3(1, 0, 0), new THREE.Vector3(1, 0, 0), new THREE.Vector3(1, 0, 0), new THREE.Vector3(1, 0, 0), new THREE.Vector3(1, 0, 0)]),
				lightColors: new THREE.Uniform([new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0)]),
				lightRadii: new THREE.Uniform([0, 0, 0, 0, 0]),
				numLights: new THREE.Uniform(0),

				entityPos: new THREE.Uniform(new THREE.Vector3()),
				innerRadius: new THREE.Uniform(this._innerRadius),
				outerRadius: new THREE.Uniform(this._outerRadius),
				topTexture: new THREE.Uniform(topTexture),
				bottomTexture: new THREE.Uniform(bottomTexture),
				alphaFadeMultiplier: new THREE.Uniform(1),
				spheroidEquatorialRadius: new THREE.Uniform(0),
				spheroidPolarRadius: new THREE.Uniform(0),

				// Shadow Entities
				numShadowEntities: new THREE.Uniform(0),
				shadowEntityPositions: new THREE.Uniform([new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3()]),
				shadowEntityRadii: new THREE.Uniform([0, 0, 0, 0, 0, 0, 0]),
				shadowEntitySunsetIntensity: new THREE.Uniform([0, 0, 0, 0, 0, 0, 0]),
				shadowEntitySunsetColors: new THREE.Uniform([new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3()]),

				...ShaderChunkLogDepth.ThreeUniforms
			},
			vertexShader: RingsComponent.vertexShader,
			fragmentShader: RingsComponent.fragmentShader,
			transparent: true,
			depthWrite: false,
			blending: THREE.NormalBlending,
			side: THREE.DoubleSide
		});
		ThreeJsHelper.setupLogDepthBuffering(material);
		ThreeJsHelper.setDefine(material, 'shadowEntities', this._shadowEntities.length > 0);
		this.getThreeJsMaterials().push(material);

		const object = ThreeJsHelper.createMeshObject(this, material, [
			{ name: 'position', dimensions: 3 },
			{ name: 'normal', dimensions: 3 }], false);

		const numSegments = 10;
		const positions = new Float32Array(3 * numSegments * numSegments);
		const normals = new Float32Array(3 * numSegments * numSegments);
		const indices = new Uint16Array(6 * (numSegments - 1) * (numSegments - 1));
		for (let j = 0; j < numSegments; j++) {
			for (let i = 0; i < numSegments; i++) {
				const vertexIndex = i + j * numSegments;
				positions[vertexIndex * 3 + 0] = (i / (numSegments - 1)) * 2.0 - 1.0;
				positions[vertexIndex * 3 + 1] = (j / (numSegments - 1)) * 2.0 - 1.0;
				positions[vertexIndex * 3 + 2] = 0.0;
				normals[vertexIndex * 3 + 0] = 0.0;
				normals[vertexIndex * 3 + 1] = 0.0;
				normals[vertexIndex * 3 + 2] = 1.0;
				if (i + 1 < numSegments && j + 1 < numSegments) {
					indices[(i + j * (numSegments - 1)) * 6 + 0] = (i + 0) + (j + 0) * numSegments;
					indices[(i + j * (numSegments - 1)) * 6 + 1] = (i + 0) + (j + 1) * numSegments;
					indices[(i + j * (numSegments - 1)) * 6 + 2] = (i + 1) + (j + 0) * numSegments;
					indices[(i + j * (numSegments - 1)) * 6 + 3] = (i + 1) + (j + 0) * numSegments;
					indices[(i + j * (numSegments - 1)) * 6 + 4] = (i + 0) + (j + 1) * numSegments;
					indices[(i + j * (numSegments - 1)) * 6 + 5] = (i + 1) + (j + 1) * numSegments;
				}
			}
		}
		ThreeJsHelper.setVertices(object.geometry, 'position', positions);
		ThreeJsHelper.setVertices(object.geometry, 'normal', normals);
		ThreeJsHelper.setIndices(object.geometry, indices);
		object.material = material;
		this.getThreeJsObjects().push(object);

		// Update from the spheroid properties.
		this._spheroidChangedCallback();
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		ThreeJsHelper.destroyAllObjectsAndMaterials(this);
	}

	/**
	 * Callback called when the spheroid reference is found or lost.
	 * @param {SpheroidComponent} oldRef
	 * @param {SpheroidComponent} newRef
	 * @private
	 */
	_spheroidRefChangedCallback(oldRef, newRef) {
		if (oldRef !== null) {
			oldRef.removeChangedCallback(this._spheroidChangedCallback);
		}
		if (newRef !== null) {
			newRef.addChangedCallback(this._spheroidChangedCallback);
		}
		this._spheroidChangedCallback();
	}

	/**
	 * Callback to be called when the spheroid component changed.
	 * @private
	 */
	_spheroidChangedCallback() {
		// Set the radii uniforms.
		const material = this.getThreeJsMaterials()[0];
		if (material !== null) {
			const spheroidComponent = this._spheroidComponentRef.get();
			if (spheroidComponent !== null) {
				ThreeJsHelper.setUniformNumber(material, 'spheroidEquatorialRadius', spheroidComponent.getEquatorialRadius());
				ThreeJsHelper.setUniformNumber(material, 'spheroidPolarRadius', spheroidComponent.getPolarRadius());
			}
			else {
				ThreeJsHelper.setUniformNumber(material, 'spheroidEquatorialRadius', 0);
				ThreeJsHelper.setUniformNumber(material, 'spheroidPolarRadius', 0);
			}
		}
	}
}

RingsComponent.vertexShader = `
	uniform float outerRadius;

	varying vec3 localPosition;
	varying vec3 cameraSpacePosition;
	varying vec3 modelNormal;

	${ShaderChunkLogDepth.VertexHead}

	void main() {
		localPosition = position * outerRadius;
		cameraSpacePosition = (modelMatrix * vec4(localPosition, 1.)).xyz;
		modelNormal = (modelMatrix * vec4(normal, 0.)).xyz;
		vec4 viewPosition = viewMatrix * vec4(cameraSpacePosition, 1.);
		gl_Position = projectionMatrix * viewPosition;

		${ShaderChunkLogDepth.Vertex}
	}`;

RingsComponent.fragmentShader = `
	precision highp float;

	#ifndef saturate
		#define saturate(a) clamp(a, 0.0, 1.0)
	#endif

	// Lights
	uniform vec3 ambientLightColor;
	uniform vec3 lightPositions[5];
	uniform vec3 lightColors[5];
	uniform float lightRadii[5];
	uniform int numLights;

	uniform float innerRadius;
	uniform float outerRadius;
	uniform sampler2D topTexture;
	uniform sampler2D bottomTexture;
	uniform float alphaFadeMultiplier;
	uniform float spheroidEquatorialRadius;
	uniform float spheroidPolarRadius;
	uniform vec3 entityPos;

	// Shadow Entities.
	#ifdef shadowEntities
		uniform int numShadowEntities;
		uniform vec3 shadowEntityPositions[7];
		uniform float shadowEntityRadii[7];
		uniform float shadowEntitySunsetIntensity[7];
		uniform vec3 shadowEntitySunsetColors[7];
	#endif

	// The varying attributes.
	varying vec3 localPosition;
	varying vec3 cameraSpacePosition;
	varying vec3 modelNormal;

	${ShaderChunkLogDepth.FragmentHead}

	float spheroidShadow(vec3 lightDir, float lightCosAngle, float spheroidScaling, vec3 normal, vec3 ringPos) {
		vec3 sunDirScaled = normalize(lightDir - (spheroidScaling - 1.0) * lightCosAngle * normal);
		float pDotLScaled = dot(ringPos, sunDirScaled);
		if(dot(ringPos, ringPos) - pDotLScaled * pDotLScaled < spheroidEquatorialRadius * spheroidEquatorialRadius && pDotLScaled > 0.0) {
			return 0.0;
		}
		else {
			return 1.0;
		}
	}

	#ifdef shadowEntities
		vec3 applyRayleighScattering(vec3 color, float amount) {
			float value = (color.r + color.g + color.b);
			if (value > 0.0) {
				float rFactor = 1.0; // 6.3^-4 / 6.3^-4
				float gFactor = 1.602; // 5.6^-4 / 6.3^-4
				float bFactor = 3.228; // 4.7^-4 / 6.3^-4
				color.r *= pow(rFactor, -amount);
				color.g *= pow(gFactor, -amount);
				color.b *= pow(bFactor, -amount);
				color = value * color / (color.r + color.g + color.b);
			}
			return color;
		}

		vec3 getLightColorFromShadowEntities(vec3 lightColor, vec3 lightDir, vec3 lightPosition, float lightRadius, vec3 normal) {
			vec3 color = lightColor;
			for (int i = 0; i < 7; i++) {
				if (i >= numShadowEntities) {
					break;
				}
				vec3 origin = cameraSpacePosition - shadowEntityPositions[i];
				vec3 axis = normalize(shadowEntityPositions[i] - lightPosition);
				float sd = dot(origin, axis);
				if (sd > 0.0) {
					float e = length(origin - sd * axis);
					float ld = dot(cameraSpacePosition - lightPosition, axis);
					float lr = lightRadius;
					float sr = shadowEntityRadii[i];
					float e0 = (ld * sr - sd * lr) / (ld - sd);
					float e1 = (ld * sr + sd * lr) / (ld - sd);
					float lightLevel = 0.0;
					if (e1 < 0.0 || sd < 0.0) { // light in front of shadow entity
						lightLevel = 1.0;
					}
					else if (e0 < e1) {
						e0 /= max(1.0, shadowEntitySunsetIntensity[i] * 2.0);
						lightLevel = (e - e0) / (e1 - e0);
					}
					else {
						lightLevel = e < e0 ? 0.0 : 1.0; // 0 radius light.
					}
					color = saturate(lightLevel) * applyRayleighScattering(color, saturate(1.5 - lightLevel) * saturate(shadowEntitySunsetIntensity[i]));
				}
}
			return color;
		}
	#endif

	void main(void) {
		float spheroidScaling = spheroidEquatorialRadius / spheroidPolarRadius;
		vec3 positionDir = normalize(cameraSpacePosition);
		vec3 ringPos = cameraSpacePosition - entityPos;
		vec3 normal = normalize(modelNormal);
		float cameraCosAngle = -dot(positionDir, normal);

		// Calculate the UVs.
		vec2 uv;
		uv.x = (length(localPosition) - innerRadius) / (outerRadius - innerRadius);
		if (uv.x < 0.0 || uv.x > 1.0) {
			gl_FragColor = vec4(0, 0, 0, 0);
			return;
		}
		uv.y = 0.0;

		// Get the pixels at those uvs.
		vec4 topPixel = texture2D(topTexture, uv);
		vec4 bottomPixel = texture2D(bottomTexture, uv);

		// Get the initial diffuse light.
		vec3 diffuseLight = ambientLightColor;
		
		// For each light,
		for (int i = 0; i < 5; i++) {
			if (i >= numLights) {
				break;
			}

			vec3 lightDir = normalize(cameraSpacePosition - lightPositions[i]);
			float lightCosAngle = -dot(lightDir, normal);

			vec3 incomingLight = lightColors[i];

			#ifdef shadowEntities
				incomingLight = getLightColorFromShadowEntities(incomingLight, lightDir, lightPositions[i], lightRadii[i], normal);
			#endif

			float cameraDirDotLight = dot(positionDir, lightDir);
			float bottomTopRatio = (1.0 + 0.2 * cameraDirDotLight) * sign(cameraCosAngle) * lightCosAngle;
			float shadow = spheroidShadow(lightDir, lightCosAngle, spheroidScaling, normal, ringPos);
			vec3 bottomColor = saturate(incomingLight * (1.0 - bottomTopRatio) * shadow);
			vec3 topColor = 2.0 * saturate(incomingLight * bottomTopRatio * shadow);

			vec3 color = mix(bottomPixel.rgb * bottomColor, topPixel.rgb * topColor, bottomTopRatio);
			gl_FragColor.rgb += color;
		}

		gl_FragColor.a = topPixel.a;
		gl_FragColor.a *= alphaFadeMultiplier;

		${ShaderChunkLogDepth.Fragment}
	}`;
