/** @module pioneer */
import {
	BaseController,
	Entity,
	EntityRef,
	Interval,
	Quaternion,
	Sort,
	Vector3
} from '../../internal';

/**
 * A controller animates the position and orientation of an entity via keyframes.
 */
export class KeyframeController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The position keyframes.
		 * @type {Array<{0: number, 1: PositionKeyframe}>}
		 * @private
		 */
		this._positionKeyframes = [];

		/**
		 * The orientation keyframes.
		 * @type {Array<{0: number, 1: OrientationKeyframe}>}
		 * @private
		 */
		this._orientationKeyframes = [];

		/**
		 * The flag that if true says that the times given are relative to the first update in real time,
		 * and if false says that the times are in ET time.
		 * @type {boolean}
		 * @private
		 */
		this._timesAreRealTime = false;

		/**
		 * The time of the first update, used when _timesAreRealTime is true.
		 * @type {number}
		 * @private
		 */
		this._timeOfFirstUpdate = NaN;

		// Set the coverage to nothing, since there are no keyframes.
		this.setCoverage(new Interval(Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY));

		// Helper vectors and quaternions for calculations.
		this._position0 = new Vector3();
		this._position1 = new Vector3();
		this._orientation0 = new Quaternion();
		this._orientation1 = new Quaternion();
		this._newPosition = new Vector3();
		this._newVelocity = new Vector3();
		this._newOrientation = new Quaternion();
		this._tangent0 = new Vector3();
		this._tangent1 = new Vector3();
		this._tempA = new Vector3();
		this._tempB = new Vector3();
	}

	/**
	 * Adds a position keyframe.
	 * If positionOrEntity is an entity, the entity's position at the given time is used instead.
	 * @param {number} time
	 * @param {Vector3} position
	 * @param {string} [relativeToEntityPosition]
	 * @param {number} [relativeToEntityPositionTime]
	 * @param {string} [relativeToEntityOrientation]
	 * @param {number} [relativeToEntityOrientationTime]
	 */
	addPositionKeyframe(time, position, relativeToEntityPosition, relativeToEntityPositionTime, relativeToEntityOrientation, relativeToEntityOrientationTime) {
		/** @type {{0: number, 1: PositionKeyframe}} */
		const entry = [time, {
			position,
			relativeToEntityPosition: relativeToEntityPosition ? new EntityRef(this.getEntity().getScene(), relativeToEntityPosition) : undefined,
			relativeToEntityPositionTime,
			relativeToEntityOrientation: relativeToEntityOrientation ? new EntityRef(this.getEntity().getScene(), relativeToEntityOrientation) : undefined,
			relativeToEntityOrientationTime
		}];
		Sort.add(entry, this._positionKeyframes, isLessAdd);
		if (this._positionKeyframes.length === 1) {
			this.addModifiedState('position');
			this.addModifiedState('velocity');
		}
		this._updateCoverage();
	}

	/**
	 * Removes a position keyframe. Returns true if the key was removed.
	 * @param {number} time
	 */
	removePositionKeyframe(time) {
		const found = Sort.remove(time, this._positionKeyframes, isLess, isEqual);
		if (found) {
			if (this._positionKeyframes.length === 0) {
				this.removeModifiedState('position');
				this.removeModifiedState('velocity');
			}
			this._updateCoverage();
		}
		return found;
	}

	/**
	 * Adds an orientation keyframe.
	 * If orientationOrEntity is an entity, the entity's orientation at the given time is used instead.
	 * @param {number} time
	 * @param {Quaternion} orientation
	 * @param {string} [relativeToEntityOrientation]
	 * @param {number} [relativeToEntityOrientationTime]
	 */
	addOrientationKeyframe(time, orientation, relativeToEntityOrientation, relativeToEntityOrientationTime) {
		/** @type {{0: number, 1: OrientationKeyframe}} */
		const entry = [time, {
			orientation,
			relativeToEntityOrientation: relativeToEntityOrientation ? new EntityRef(this.getEntity().getScene(), relativeToEntityOrientation) : undefined,
			relativeToEntityOrientationTime
		}];
		Sort.add(entry, this._orientationKeyframes, isLessAdd);
		if (this._orientationKeyframes.length === 1) {
			this.addModifiedState('orientation');
		}
		this._updateCoverage();
	}

	/**
	 * Removes an orientation keyframe. Returns true if the key was removed.
	 * @param {number} time
	 */
	removeOrientationKeyframe(time) {
		const found = Sort.remove(time, this._orientationKeyframes, isLess, isEqual);
		if (found) {
			if (this._orientationKeyframes.length === 0) {
				this.removeModifiedState('orientation');
			}
			this._updateCoverage();
		}
		return found;
	}

	/**
	 * Gets the flag that if true says that the times given are relative to the first update in real time,
	 * and if false says that the times are in ET time.
	 * @returns {boolean}
	 */
	areTimesRealTime() {
		return this._timesAreRealTime;
	}

	/**
	 * Sets the flag that if true says that the times given are relative to the first update in real time,
	 * and if false says that the times are in ET time.
	 * @param {boolean} timesAreRealTime
	 */
	setTimesAreRealTime(timesAreRealTime) {
		this._timesAreRealTime = timesAreRealTime;
		this._updateCoverage();
	}

	/**
	 * Sets the position to the keyframed position at the given time.
	 * @param {Vector3} position
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updatePositionAtTime(position, time) {
		this._getPositionAtTime(position, time);
	}

	/**
	 * Sets the velocity to the keyframed velocity at the given time.
	 * @param {Vector3} velocity
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updateVelocityAtTime(velocity, time) {
		this._getVelocityAtTime(velocity, time);
	}

	/**
	 * Sets the orientation to the keyframed orientation at the given time.
	 * @param {Quaternion} orientation
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updateOrientationAtTime(orientation, time) {
		this._getOrientationAtTime(orientation, time);
	}

	/**
	 * Updates the position and orientation from the keyframes.
	 * @override
	 * @internal
	 */
	__update() {
		const engine = this.getEntity().getScene().getEngine();
		let time = 0;
		if (this._timesAreRealTime) {
			if (isNaN(this._timeOfFirstUpdate)) {
				this._timeOfFirstUpdate = Date.now() / 1000;
			}
			else {
				time = Date.now() / 1000 - this._timeOfFirstUpdate;
			}
		}
		else {
			time = engine.getTime();
		}
		if (this._getPositionAtTime(this._newPosition, time)) {
			this.getEntity().setPosition(this._newPosition);
		}
		if (this._getVelocityAtTime(this._newVelocity, time)) {
			this.getEntity().setVelocity(this._newVelocity);
		}
		if (this._getOrientationAtTime(this._newOrientation, time)) {
			this.getEntity().setOrientation(this._newOrientation);
		}
	}

	/**
	 * Sets outPosition to the position at the given time. Returns true if they were set.
	 * @param {Vector3} outPosition
	 * @param {number} time
	 * @returns {boolean}
	 * @private
	 */
	_getPositionAtTime(outPosition, time) {
		const index = Sort.getIndex(time, this._positionKeyframes, isLess);
		if (index < this._positionKeyframes.length) { // Any time before or at the ending time.
			if (index === 0) { // Any time before or at the beginning time.
				if (this._positionKeyframes[0][0] === time) { // It's right at the beginning time.
					this._getPositionOfKeyframe(outPosition, this._positionKeyframes[0]);
					return true;
				}
			}
			else { // Any time after the beginning time.
				const index0 = index - 1;
				const index1 = index;
				const time0 = this._positionKeyframes[index0][0];
				const time1 = this._positionKeyframes[index1][0];
				this._getPositionOfKeyframe(this._position0, this._positionKeyframes[index0]);
				this._getPositionOfKeyframe(this._position1, this._positionKeyframes[index1]);
				if (index0 > 0) {
					const timeA = this._positionKeyframes[index0 - 1][0];
					this._getPositionOfKeyframe(this._tempA, this._positionKeyframes[index0 - 1]);
					this._tempA.sub(this._position0, this._tempA);
					this._tempB.sub(this._position1, this._position0);
					this._tempB.mult(this._tempB, 0.5);
					this._tangent0.addMult(this._tempB, this._tempA, 0.5 * (time1 - time0) / (time0 - timeA));
				}
				else {
					this._tangent0.sub(this._position1, this._position0);
				}
				if (index1 < this._positionKeyframes.length - 1) {
					const timeB = this._positionKeyframes[index1 + 1][0];
					this._getPositionOfKeyframe(this._tempB, this._positionKeyframes[index1 + 1]);
					this._tempA.sub(this._position1, this._position0);
					this._tempB.sub(this._tempB, this._position1);
					this._tempA.mult(this._tempA, 0.5);
					this._tangent1.addMult(this._tempA, this._tempB, 0.5 * (time1 - time0) / (timeB - time1));
				}
				else {
					this._tangent1.sub(this._position1, this._position0);
				}
				const u = (time - time0) / (time1 - time0);
				this._cubicHermiteSpline(outPosition, this._position0, this._position1, this._tangent0, this._tangent1, u);
				return true;
			}
		}
		return false;
	}

	/**
	 * Sets outVelocity to the velocity at the given time. Returns true if they were set.
	 * @param {Vector3} outVelocity
	 * @param {number} time
	 * @returns {boolean}
	 * @private
	 */
	_getVelocityAtTime(outVelocity, time) {
		const index = Sort.getIndex(time, this._positionKeyframes, isLess);
		if (index < this._positionKeyframes.length) { // Any time before or at the ending time.
			if (index === 0) { // Any time before or at the beginning time.
				if (this._positionKeyframes[0][0] === time) { // It's right at the beginning time.
					if (this._positionKeyframes.length > 1) {
						const time0 = this._positionKeyframes[0][0];
						const time1 = this._positionKeyframes[1][0];
						this._getPositionOfKeyframe(this._position0, this._positionKeyframes[0]);
						this._getPositionOfKeyframe(this._position1, this._positionKeyframes[1]);
						outVelocity.sub(this._position1, this._position0);
						outVelocity.div(outVelocity, time1 - time0);
					}
					else {
						outVelocity.set(0, 0, 0);
					}
					return true;
				}
			}
			else { // Any time after the beginning time.
				const index0 = index - 1;
				const index1 = index;
				const time0 = this._positionKeyframes[index0][0];
				const time1 = this._positionKeyframes[index1][0];
				this._getPositionOfKeyframe(this._position0, this._positionKeyframes[index0]);
				this._getPositionOfKeyframe(this._position1, this._positionKeyframes[index1]);
				if (index0 > 0) {
					const timeA = this._positionKeyframes[index0 - 1][0];
					this._getPositionOfKeyframe(this._tempA, this._positionKeyframes[index0 - 1]);
					this._tempA.sub(this._position0, this._tempA);
					this._tempB.sub(this._position1, this._position0);
					this._tempB.mult(this._tempB, 0.5);
					this._tangent0.addMult(this._tempB, this._tempA, 0.5 * (time1 - time0) / (time0 - timeA));
				}
				else {
					this._tangent0.sub(this._position1, this._position0);
				}
				if (index1 < this._positionKeyframes.length - 1) {
					const timeB = this._positionKeyframes[index1 + 1][0];
					this._getPositionOfKeyframe(this._tempB, this._positionKeyframes[index1 + 1]);
					this._tempA.sub(this._position1, this._position0);
					this._tempB.sub(this._tempB, this._position1);
					this._tempA.mult(this._tempA, 0.5);
					this._tangent1.addMult(this._tempA, this._tempB, 0.5 * (time1 - time0) / (timeB - time1));
				}
				else {
					this._tangent1.sub(this._position1, this._position0);
				}
				const u = (time - time0) / (time1 - time0);
				this._cubicHermiteSplineDerivative(outVelocity, this._position0, this._position1, this._tangent0, this._tangent1, u);
				outVelocity.div(outVelocity, time1 - time0);
				return true;
			}
		}
		return false;
	}

	/**
	 * Sets outOrientation to the orientation at the given time. Returns true if it was set.
	 * @param {Quaternion} outOrientation
	 * @param {number} time
	 * @returns {boolean}
	 * @private
	 */
	_getOrientationAtTime(outOrientation, time) {
		const index = Sort.getIndex(time, this._orientationKeyframes, isLess);
		if (index < this._orientationKeyframes.length) { // Any time before or at the ending time.
			if (index === 0) { // Any time before or at the beginning time.
				if (this._orientationKeyframes[0][0] === time) { // It's right at the beginning time.
					this._getOrientationOfKeyframe(outOrientation, this._orientationKeyframes[0]);
					return true;
				}
			}
			else { // Any time after the beginning time.
				const index0 = index - 1;
				const index1 = index;
				const time0 = this._orientationKeyframes[index0][0];
				this._getOrientationOfKeyframe(this._orientation0, this._orientationKeyframes[index0]);
				const time1 = this._orientationKeyframes[index1][0];
				this._getOrientationOfKeyframe(this._orientation1, this._orientationKeyframes[index1]);
				outOrientation.slerp(this._orientation0, this._orientation1, (time - time0) / (time1 - time0));
				return true;
			}
		}
		return false;
	}

	/**
	 * Gets the position from a keyframe value.
	 * @param {Vector3} outPosition
	 * @param {{0: number, 1: PositionKeyframe}} keyframe
	 * @private
	 */
	_getPositionOfKeyframe(outPosition, keyframe) {
		const positionKeyframe = keyframe[1];
		outPosition.copy(positionKeyframe.position);
		if (positionKeyframe.relativeToEntityOrientation !== undefined) {
			const time = keyframe[1].relativeToEntityOrientationTime !== undefined ? keyframe[1].relativeToEntityOrientationTime : keyframe[0];
			const otherEntity = positionKeyframe.relativeToEntityOrientation.get();
			if (otherEntity !== null) {
				const orientation = Quaternion.pool.get();
				otherEntity.getOrientationAtTime(orientation, time);
				outPosition.rotate(orientation, outPosition);
				Quaternion.pool.release(orientation);
			}
			else {
				outPosition.copy(Vector3.NaN);
			}
		}
		if (positionKeyframe.relativeToEntityPosition !== undefined) {
			const time = keyframe[1].relativeToEntityPositionTime !== undefined ? keyframe[1].relativeToEntityPositionTime : keyframe[0];
			const relativeToEntityPosition = Vector3.pool.get();
			const otherEntity = positionKeyframe.relativeToEntityPosition.get();
			if (otherEntity !== null) {
				otherEntity.getPositionRelativeToEntity(relativeToEntityPosition, Vector3.Zero, this.getEntity().getParent(), time);
				outPosition.add(relativeToEntityPosition, outPosition);
				Vector3.pool.release(relativeToEntityPosition);
			}
			else {
				outPosition.copy(Vector3.NaN);
			}
		}
	}

	/**
	 * Gets the orientation from a keyframe value.
	 * @param {Quaternion} outOrientation
	 * @param {{0: number, 1: OrientationKeyframe}} keyframe
	 * @private
	 */
	_getOrientationOfKeyframe(outOrientation, keyframe) {
		const orientationKeyframe = keyframe[1];
		if (orientationKeyframe.relativeToEntityOrientation !== undefined) {
			const time = keyframe[1].relativeToEntityOrientationTime !== undefined ? keyframe[1].relativeToEntityOrientationTime : keyframe[0];
			const otherEntity = orientationKeyframe.relativeToEntityOrientation.get();
			if (otherEntity !== null) {
				otherEntity.getOrientationAtTime(outOrientation, time);
				outOrientation.mult(outOrientation, orientationKeyframe.orientation);
			}
			else {
				outOrientation.copy(Quaternion.NaN);
			}
		}
		else {
			outOrientation.copy(orientationKeyframe.orientation);
		}
	}

	/**
	 * Updates the coverage.
	 * @private
	 */
	_updateCoverage() {
		if (this._timesAreRealTime) {
			this.setCoverage(Interval.Infinite);
		}
		else {
			const coverage = new Interval(Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY);
			if (this._positionKeyframes.length > 0) {
				coverage.min = Math.min(coverage.min, this._positionKeyframes[0][0]);
				coverage.max = Math.max(coverage.max, this._positionKeyframes[this._positionKeyframes.length - 1][0]);
			}
			if (this._orientationKeyframes.length > 0) {
				coverage.min = Math.min(coverage.min, this._orientationKeyframes[0][0]);
				coverage.max = Math.max(coverage.max, this._orientationKeyframes[this._orientationKeyframes.length - 1][0]);
			}
			this.setCoverage(coverage);
		}
	}

	/**
	 * Sets outP to the cubic hermite spline of the given parameters.
	 * @param {Vector3} outP
	 * @param {Vector3} p0
	 * @param {Vector3} p1
	 * @param {Vector3} t0
	 * @param {Vector3} t1
	 * @param {number} u
	 */
	_cubicHermiteSpline(outP, p0, p1, t0, t1, u) {
		const u2 = u * u;
		const u3 = u * u2;
		const c0 = 2 * u3 - 3 * u2 + 1;
		const c1 = u3 - 2 * u2 + u;
		const c2 = -2 * u3 + 3 * u2;
		const c3 = u3 - u2;
		outP.x = c0 * p0.x + c1 * t0.x + c2 * p1.x + c3 * t1.x;
		outP.y = c0 * p0.y + c1 * t0.y + c2 * p1.y + c3 * t1.y;
		outP.z = c0 * p0.z + c1 * t0.z + c2 * p1.z + c3 * t1.z;
	}

	/**
	 * Sets outP to the derivative of the cubic hermite spline of the given parameters.
	 * @param {Vector3} outV
	 * @param {Vector3} p0
	 * @param {Vector3} p1
	 * @param {Vector3} t0
	 * @param {Vector3} t1
	 * @param {number} u
	 */
	_cubicHermiteSplineDerivative(outV, p0, p1, t0, t1, u) {
		const u2 = u * u;
		const c0 = 6 * u2 - 6 * u;
		const c1 = 3 * u2 - 4 * u + 1;
		const c2 = -6 * u2 + 6 * u;
		const c3 = 3 * u2 - 2 * u;
		outV.x = c0 * p0.x + c1 * t0.x + c2 * p1.x + c3 * t1.x;
		outV.y = c0 * p0.y + c1 * t0.y + c2 * p1.y + c3 * t1.y;
		outV.z = c0 * p0.z + c1 * t0.z + c2 * p1.z + c3 * t1.z;
	}
}

/**
 * @typedef PositionKeyframe
 * @property {Vector3} position
 * @property {EntityRef} [relativeToEntityPosition]
 * @property {number} [relativeToEntityPositionTime]
 * @property {EntityRef} [relativeToEntityOrientation]
 * @property {number} [relativeToEntityOrientationTime]
 */

/**
 * @typedef OrientationKeyframe
 * @property {Quaternion} orientation
 * @property {EntityRef} [relativeToEntityOrientation]
 * @property {number} [relativeToEntityOrientationTime]
 */

/**
 * An isLess function that uses two keyframes.
 * @callback IsLessAddFunction
 * @param {{0: number, 1: PositionKeyframe | OrientationKeyframe}} a
 * @param {{0: number, 1: PositionKeyframe | OrientationKeyframe}} b
 * @returns {boolean}
 */

/**
 * An isLess function that uses a keyframe and a time.
 * @callback IsLessFunction
 * @param {{0: number, 1: PositionKeyframe | OrientationKeyframe}} a
 * @param {number} b
 * @returns {boolean}
 */

/**
 * An isLess function that uses a keyframe and a time.
 * @callback IsEqualFunction
 * @param {{0: number, 1: PositionKeyframe | OrientationKeyframe}} a
 * @param {number} b
 * @returns {boolean}
 */

/**
 * A helper function for the keyframe sorting.
 * @type {IsLessAddFunction}
 */
const isLessAdd = (a, b) => (a[0] < b[0]);

/**
 * A helper function for the keyframe sorting.
 * @type {IsLessFunction}
 */
const isLess = (a, b) => (a[0] < b);

/**
 * A helper function for the keyframe sorting.
 * @type {IsEqualFunction}
 */
const isEqual = (a, b) => (a[0] === b);
