/** @module pioneer */
import {
	ShaderChunkLogDepth,
	THREE,
	ThreeJsHelper
} from '../internal';

/** Material utilities. */
export class MaterialUtilsPhong {
	/** Gets a Phong ShaderMaterial.
	 * @returns {THREE.ShaderMaterial}
	 */
	static get() {
		if (MaterialUtilsPhong._material === null) {
			MaterialUtilsPhong._material = new THREE.ShaderMaterial({
				uniforms: {
					// External lighting and camera.
					entityPos: new THREE.Uniform(new THREE.Vector3()),

					// Lights
					ambientLightColor: new THREE.Uniform(new THREE.Color()),
					lightPositions: new THREE.Uniform([new THREE.Vector3(1, 0, 0), new THREE.Vector3(1, 0, 0), new THREE.Vector3(1, 0, 0), new THREE.Vector3(1, 0, 0), new THREE.Vector3(1, 0, 0)]),
					lightColors: new THREE.Uniform([new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0)]),
					lightRadii: new THREE.Uniform([0, 0, 0, 0, 0]),
					numLights: new THREE.Uniform(0),

					// Shading.
					color: new THREE.Uniform(new THREE.Color(1, 1, 1)),
					specularColor: new THREE.Uniform(new THREE.Color(1, 1, 1)),
					specularIntensity: new THREE.Uniform(0),
					specularHardness: new THREE.Uniform(50.0),

					// Textures.
					colorTexture: new THREE.Uniform(null),
					normalTexture: new THREE.Uniform(null),
					specularTexture: new THREE.Uniform(null),
					nightTexture: new THREE.Uniform(null),
					decalTexture: new THREE.Uniform(null),

					normalScale: new THREE.Uniform(new THREE.Vector2()),

					// Shadow Entities
					numShadowEntities: new THREE.Uniform(0),
					shadowEntityPositions: new THREE.Uniform([new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3()]),
					shadowEntityRadii: new THREE.Uniform([0, 0, 0, 0, 0, 0, 0]),
					shadowEntitySunsetIntensity: new THREE.Uniform([0, 0, 0, 0, 0, 0, 0]),
					shadowEntitySunsetColors: new THREE.Uniform([new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3()]),

					// Shadow Rings
					shadowRingsInnerRadius: new THREE.Uniform(0),
					shadowRingsOuterRadius: new THREE.Uniform(0),
					shadowRingsTexture: new THREE.Uniform(null),
					shadowRingsNormal: new THREE.Uniform(new THREE.Vector3()),

					// Atmosphere
					atmospherePosition: new THREE.Uniform(new THREE.Vector3(0, 0, 0)),
					atmosphereOrientation: new THREE.Uniform(new THREE.Vector4(1, 0, 0, 0)),
					atmosphereEquatorialRadius: new THREE.Uniform(1),
					atmospherePolarRadius: new THREE.Uniform(1),
					atmosphereDensity: new THREE.Uniform(0),
					atmosphereScaleHeight: new THREE.Uniform(1),
					atmosphereEmissivity: new THREE.Uniform(0),
					atmosphereColor: new THREE.Uniform(new THREE.Vector3(0, 0, 0)),
					atmosphereSunBrightness: new THREE.Uniform(1),
					atmosphereSunsetColor: new THREE.Uniform(new THREE.Vector3(0, 0, 0)),
					atmosphereSunsetIntensity: new THREE.Uniform(0),
					atmosphereGroundIsSpheroid: new THREE.Uniform(0),

					...ShaderChunkLogDepth.ThreeUniforms
				},
				vertexShader: `
					#ifdef normalMap
						attribute vec4 tangent;
						varying vec4 viewTangent;
					#endif
					#ifdef normalUVs
						attribute vec2 normalUV;
						varying vec2 vNormalUV;
					#endif
					#ifdef specularUVs
						attribute vec2 specularUV;
						varying vec2 vSpecularUV;
					#endif
					#ifdef nightUVs
						attribute vec2 nightUV;
						varying vec2 vNightUV;
					#endif
					#ifdef decalUVs
						attribute vec2 decalUV;
						varying vec2 vDecalUV;
					#endif
					varying vec2 vColorUV;
					varying vec3 cameraSpacePosition;
					varying vec3 cameraSpaceNormal;

					${ShaderChunkLogDepth.VertexHead}

					void main() {
						#ifdef normalMap
							viewTangent = vec4((modelMatrix * vec4(tangent.xyz, 0.0)).xyz, tangent.w);
						#endif
						vColorUV = uv;
						#ifdef normalUVs
							vNormalUV = normalUV;
						#endif
						#ifdef specularUVs
							vSpecularUV = specularUV;
						#endif
						#ifdef nightUVs
							vNightUV = nightUV;
						#endif
						#ifdef decalUVs
							vDecalUV = decalUV;
						#endif
						cameraSpacePosition = (modelMatrix * vec4(position, 1.)).xyz;
						cameraSpaceNormal = (modelMatrix * vec4(normal, 0.)).xyz;
						gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);

						${ShaderChunkLogDepth.Vertex}
					}`,
				fragmentShader: `
					precision highp float;

					#ifndef saturate
						#define saturate(a) clamp(a, 0.0, 1.0)
					#endif
					uniform mat3 normalMatrix;

					// External lighting and camera.
					uniform vec3 entityPos;

					// Lights
					uniform vec3 ambientLightColor;
					uniform vec3 lightPositions[5];
					uniform vec3 lightColors[5];
					uniform float lightRadii[5];
					uniform int numLights;

					// Shading.
					uniform vec3 color;
					uniform vec3 specularColor;
					uniform float specularIntensity;
					uniform float specularHardness;

					// Shadow Entities.
					#ifdef shadowEntities
						uniform int numShadowEntities;
						uniform vec3 shadowEntityPositions[7];
						uniform float shadowEntityRadii[7];
						uniform float shadowEntitySunsetIntensity[7];
						uniform vec3 shadowEntitySunsetColors[7];
					#endif

					#ifdef shadowRings
						uniform float shadowRingsInnerRadius;
						uniform float shadowRingsOuterRadius;
						uniform sampler2D shadowRingsTexture;
						uniform vec3 shadowRingsNormal;
					#endif

					// Textures.
					uniform sampler2D colorTexture;
					#ifdef normalMap
						uniform sampler2D normalTexture;
					#endif
					#ifdef specularMap
						uniform sampler2D specularTexture;
					#endif
					#ifdef nightMap
						uniform sampler2D nightTexture;
					#endif
					#ifdef decalMap
						uniform sampler2D decalTexture;
					#endif

					// Modifications on the textures.
					#ifdef normalMap
						uniform vec2 normalScale;
					#endif

					// The varying attributes.
					#ifdef normalMap
						varying vec4 viewTangent;
					#endif
					#ifdef normalUVs
						varying vec2 vNormalUV;
					#endif
					#ifdef specularUVs
						varying vec2 vSpecularUV;
					#endif
					#ifdef nightUVs
						varying vec2 vNightUV;
					#endif
					#ifdef decalUVs
						varying vec2 vDecalUV;
					#endif
					varying vec2 vColorUV;
					varying vec3 cameraSpacePosition;
					varying vec3 cameraSpaceNormal;

					${ShaderChunkLogDepth.FragmentHead}

					#ifdef normalMap
						vec3 getNormalFromMap() {
							vec3 normal = normalize(cameraSpaceNormal);
							vec3 tangent = normalize(viewTangent.xyz);
							vec3 bitangent = normalize(cross(normal, tangent));
							if (viewTangent.w < 0.0) {
								bitangent *= -1.0;
							}
							mat3 transform = mat3(tangent, bitangent, normal);
							#ifdef normalUVs
								vec2 uv = vNormalUV;
							#else
								vec2 uv = vColorUV;
							#endif
							vec3 normalFromMap = texture2D(normalTexture, uv).rgb * 2.0 - 1.0;
							normalFromMap.xy *= vec2(1, -1);
							normalFromMap.xy *= normalScale;
							return normalize(transform * normalFromMap);
						}
					#endif

					#ifdef colorTextureEnvironment
						vec4 getColorFromEnvironmentMap(sampler2D environmentTexture, vec3 positionDir, vec3 normal) {
							vec3 r = reflect(positionDir, normal);
							float m = 2. * sqrt(r.x * r.x + r.y * r.y + (r.z + 1.) * (r.z + 1.));
							vec2 uv = r.xy / m + .5;
							return vec4(texture2D(environmentTexture, uv).rgb, 1.);
						}
					#endif

					#ifdef shadowEntities
						vec3 applyRayleighScattering(vec3 color, float amount) {
							float value = (color.r + color.g + color.b);
							if (value > 0.0) {
								float rFactor = 1.0; // 6.3^-4 / 6.3^-4
								float gFactor = 1.602; // 5.6^-4 / 6.3^-4
								float bFactor = 3.228; // 4.7^-4 / 6.3^-4
								color.r *= pow(rFactor, -amount);
								color.g *= pow(gFactor, -amount);
								color.b *= pow(bFactor, -amount);
							}
							return color;
						}

						vec3 getLightColorFromShadowEntities(vec3 lightColor, vec3 lightDir, vec3 lightPosition, float lightRadius, vec3 normal) {
							vec3 color = lightColor;
							for (int i = 0; i < 7; i++) {
								if (i >= numShadowEntities || lightRadius < 0.0) {
									break;
								}
								vec3 origin = cameraSpacePosition - shadowEntityPositions[i];
								vec3 axis = normalize(shadowEntityPositions[i] - lightPosition);
								float sd = dot(origin, axis);
								if (sd > 0.0) {
									float e = length(origin - sd * axis);
									float ld = dot(cameraSpacePosition - lightPosition, axis);
									float lr = lightRadius;
									float sr = shadowEntityRadii[i];
									float e0 = (ld * sr - sd * lr) / (ld - sd);
									float e1 = (ld * sr + sd * lr) / (ld - sd);
									float lightLevel = 0.0;
									if (e1 < 0.0 || sd < 0.0) { // light in front of shadow entity
										lightLevel = 1.0;
									}
									else if (e0 < e1) {
										e0 /= max(1.0, shadowEntitySunsetIntensity[i] * 2.0);
										lightLevel = (e - e0) / (e1 - e0);
									}
									else {
										lightLevel = e < e0 ? 0.0 : 1.0; // 0 radius light.
									}
									color = saturate(lightLevel) * applyRayleighScattering(color, saturate(1.5 - lightLevel) * saturate(shadowEntitySunsetIntensity[i]));
								}
							}
							return color;
						}
					#endif

					#ifdef shadowRings
						vec3 getLightColorFromShadowRings(vec3 lightColor, vec3 lightDir) {
							vec3 position = cameraSpacePosition - entityPos;
							float d = dot(position, shadowRingsNormal) / dot(lightDir, shadowRingsNormal);
							highp vec3 pointOnDisc = -d * lightDir + position;
							float lengthOnDisc = length(pointOnDisc - dot(pointOnDisc, shadowRingsNormal) * shadowRingsNormal);
							float u = (lengthOnDisc - shadowRingsInnerRadius) / (shadowRingsOuterRadius - shadowRingsInnerRadius);
							float shadow = 1.0 - texture2D(shadowRingsTexture, vec2(u, 0.0), 0.0).a;
							if (shadowRingsInnerRadius <= lengthOnDisc && lengthOnDisc <= shadowRingsOuterRadius && d > 0.0) {
								return lightColor * saturate(shadow);
							}
							else {
								return lightColor;
							}
						}
					#endif

					// ATMOSPHERE

					#ifdef atmosphere
						uniform vec3 atmospherePosition;
						uniform vec4 atmosphereOrientation;
						uniform float atmosphereEquatorialRadius;
						uniform float atmospherePolarRadius;
						uniform float atmosphereDensity;
						uniform float atmosphereScaleHeight;
						uniform vec3 atmosphereColor;
						uniform float atmosphereEmissivity;
						uniform float atmosphereSunBrightness;
						uniform vec3 atmosphereSunsetColor;
						uniform float atmosphereSunsetIntensity;
						uniform float atmosphereGroundIsSpheroid;

						const int atmosphereNumIterations = 5;

						// Inverse rotate a vector by a quaternion.
						vec3 quatRotInv(vec4 q, vec3 v) {
							float tx = q.w * v.x - q.y * v.z + q.z * v.y;
							float ty = q.w * v.y - q.z * v.x + q.x * v.z;
							float tz = q.w * v.z - q.x * v.y + q.y * v.x;
							float tw = q.x * v.x + q.y * v.y + q.z * v.z;
							float x = tx * q.w + tw * q.x + ty * q.z - tz * q.y;
							float y = ty * q.w + tw * q.y + tz * q.x - tx * q.z;
							float z = tz * q.w + tw * q.z + tx * q.y - ty * q.x;
							return vec3(x, y, z);
						}

						// Given an origin and direction, computes the sampling start and end as distance from the origin in the direction.
						void getStartEndSamples(out float start, out float end, vec3 origin, vec3 direction, float maxDistance, float groundRadius, float atmosphereScaleHeight) {
							// Get the along the ray perpendicular to the sphere.
							float perpD = -dot(origin, direction);
							vec3 perp = origin + direction * perpD;

							// Figure out the sample distance.
							float atmosphereRadius = groundRadius + atmosphereScaleHeight * 6.0;
							float chordHalfLength = sqrt(max(0.0, atmosphereRadius * atmosphereRadius - dot(perp, perp)));

							// Figure out starting and ending sample points.
							start = max(0.0, perpD - chordHalfLength);
							end = min(maxDistance, perpD + chordHalfLength);
						}

						// Gets the density of the atmosphere at a given position.
						float getDensity(vec3 position, float radius, float density, float atmosphereScaleHeight) {
							return density * exp((radius - length(position)) / atmosphereScaleHeight);
						}

						// Returns 0 if the ray does not intersect and 1.0 if the ray very intersects (with a gradient inbetween).
						float getDayLevel(vec3 origin, vec3 direction, float radius, float scaleHeight) {
							float blendHeight = scaleHeight * radius / 200.0;
							float perpD = -dot(origin, direction);
							float depth = radius - sqrt(dot(origin, origin) - sign(perpD) * perpD * perpD);
							if (depth < 0.0) { // day
								return 1.0 - max(0.0, 0.25 * depth / blendHeight + 0.25);
							}
							else { // night
								return 1.0 - min(1.0, 0.75 * depth / blendHeight + 0.25);
							}
						}

						// Adjusts the color if one of the RGB values is greater than 1.0.
						vec3 adjustOverbrightness(vec3 color) {
							float maxColor = max(color.r, max(color.g, color.b));
							if (maxColor > 1.0) {
								float f = (maxColor - 1.0) / maxColor;
								color.r = min(1.0, pow(color.r / maxColor, 1.0 / maxColor));
								color.g = min(1.0, pow(color.g / maxColor, 1.0 / maxColor));
								color.b = min(1.0, pow(color.b / maxColor, 1.0 / maxColor));
							}
							return color;
						}

						float easeInOut(float x, float sharpness) {
							float b = sharpness;
							if (x < 0.5) {
								return max(0.0, (pow(b, 2.0 * x) - 1.0) / (2.0 * (b - 1.0)));
							}
							else {
								return min(1.0, 1.0 - (pow(b, 2.0 * (1.0 - x)) - 1.0) / (2.0 * (b - 1.0)));
							}
						}

						// Calculates a glow around the light direction.
						float glow(float spread, float amount, float lightDotCamera) {
							return amount * spread / (1.0 + spread - lightDotCamera);
						}

						struct AtmosphereInfo {
							float spheroidRatio;
							highp vec3 position;
							highp vec3 cameraPosition;
							highp vec3 cameraToPosition;
							float cameraToPositionDist;
							highp vec3 cameraToPositionUnit;
							float start;
							float end;
							float totalDensity;
						};

						// Get atmosphere info that is independent of any light.
						AtmosphereInfo getAtmosphereInfo() {

							AtmosphereInfo atmosphereInfo;

							// Get position and camera in the atmosphere frame.
							atmosphereInfo.position = quatRotInv(atmosphereOrientation, cameraSpacePosition - atmospherePosition);
							atmosphereInfo.cameraPosition = quatRotInv(atmosphereOrientation, -atmospherePosition);

							// Convert everything into a sphere frame.
							atmosphereInfo.spheroidRatio = atmosphereEquatorialRadius / atmospherePolarRadius;
							atmosphereInfo.position.z *= atmosphereInfo.spheroidRatio;
							atmosphereInfo.cameraPosition.z *= atmosphereInfo.spheroidRatio;

							// Make sure the position is right on the ground.
							atmosphereInfo.position = normalize(atmosphereInfo.position / 1.0e8) * atmosphereEquatorialRadius;

							// Get some shortcut vectors.
							atmosphereInfo.cameraToPosition = atmosphereInfo.position - atmosphereInfo.cameraPosition;
							atmosphereInfo.cameraToPositionDist = length(atmosphereInfo.cameraToPosition / 1.0e8) * 1.0e8;
							atmosphereInfo.cameraToPositionUnit = atmosphereInfo.cameraToPosition / atmosphereInfo.cameraToPositionDist;

							// Get the start and end of the sampling from the camera to the position.
							getStartEndSamples(atmosphereInfo.start, atmosphereInfo.end, atmosphereInfo.cameraPosition, atmosphereInfo.cameraToPositionUnit, atmosphereInfo.cameraToPositionDist, atmosphereEquatorialRadius, atmosphereScaleHeight);
							float step = 1.0 / float(atmosphereNumIterations - 1);
							float stepDist = step * (atmosphereInfo.end - atmosphereInfo.start);

							// Do the sampling.
							atmosphereInfo.totalDensity = 0.0;
							float segmentStart = atmosphereInfo.start;
							for (int j = 0; j < atmosphereNumIterations; j++) {
								// Get the distance that this segment covers.
								float segDist = stepDist;
								if (j == 0 || j == atmosphereNumIterations - 1) {
									segDist *= 0.5;
								}

								// Get the segment start that we're looking at.
								vec3 p = atmosphereInfo.cameraPosition + segmentStart * atmosphereInfo.cameraToPositionUnit;

								// Get the density at that segment start. It'll be the density for the whole segment.
								float densityAtP = getDensity(p, atmosphereEquatorialRadius, atmosphereDensity, atmosphereScaleHeight);

								// Add it to the total density.
								atmosphereInfo.totalDensity += densityAtP * segDist;

								// Next step.
								segmentStart += stepDist;
							}

							return atmosphereInfo;
						}

						vec4 getAtmosphereEmissiveColor(AtmosphereInfo atmosphereInfo, vec3 color, float emissivity) {

							// Scale the total density with the emissivity.
							atmosphereInfo.totalDensity *= emissivity;

							// Apply the total density to the transparency of the atmosphere.
							vec4 outColor = vec4(0.0);
							outColor.a = clamp(pow(3.0 * atmosphereInfo.totalDensity, 0.3), 0.0, 1.0);

							// Multiply it all together with the source light color.
							outColor.rgb = emissivity * color * clamp(pow(15.0 * atmosphereInfo.totalDensity / (atmosphereDensity * atmosphereEquatorialRadius), 0.2), 0.75, 1.0);

							// Make it more opaque when lower down.
							outColor.a *= 1.0 + 0.5 * getDensity(atmosphereInfo.cameraPosition, atmosphereEquatorialRadius, 1.0, atmosphereScaleHeight);

							// Clamp it to make it clean.
							outColor.a = clamp(outColor.a, 0.0, 1.0);

							// Return the color.
							return outColor;
						}

						// Adjust the incoming light for the atmosphere.
						vec4 getAtmosphereColor(AtmosphereInfo atmosphereInfo, vec3 incomingLight, vec3 lightPosition) {

							// The color starts out in full brightness (as if emissivity was 1.0).
							vec4 outColor = getAtmosphereEmissiveColor(atmosphereInfo, incomingLight * atmosphereColor, 1.0);

							// Get the light position in the sphere entity-space.
							lightPosition = quatRotInv(atmosphereOrientation, lightPosition);
							lightPosition.z *= atmosphereInfo.spheroidRatio;
							highp vec3 lightToPosition = atmosphereInfo.position - lightPosition;
							highp vec3 lightToPositionUnit = normalize(lightToPosition / 1.0e8);

							// Get the day level, from 0 to 1, and apply it to the alpha.
							float ambientLightIntensity = min(1.0, length(ambientLightColor));
							vec3 dayRefUp = normalize(atmosphereInfo.cameraPosition + atmosphereInfo.end * atmosphereInfo.cameraToPositionUnit);
							float dayLevel = -dot(lightToPositionUnit, dayRefUp);
							float lightIntensity = mix(dayLevel, 0.0, ambientLightIntensity);
							outColor.a *= easeInOut(0.25 * 700.0 * atmosphereDensity + 1.0 * lightIntensity, 2.0);

							// Add broader sun glare.
							float lightDotCamera = max(0.0, -dot(lightToPositionUnit, atmosphereInfo.cameraToPositionUnit));
							outColor.rgb *= incomingLight * (1.0 + atmosphereSunBrightness * outColor.a * glow(0.04, 0.125, lightDotCamera));

							// Apply the sunset.
							float sunsetAmount = mix(atmosphereSunsetIntensity * easeInOut(0.5 * (1.0 - abs(dayLevel)), 4.0), 0.0, ambientLightIntensity);
							outColor.rgb *= mix(vec3(1.0), atmosphereSunsetColor, clamp(sunsetAmount, 0.0, 1.0));

							// Adjust for values that are greater than one.
							outColor.rgb = adjustOverbrightness(outColor.rgb);

							return outColor;
						}
					#endif

					void main(void) {
						// Get the camera direction to the position.
						vec3 positionDir = normalize(cameraSpacePosition);

						// Calculate the normal.
						#ifdef normalMap
							vec3 normal = getNormalFromMap();
						#else
							vec3 normal = normalize(cameraSpaceNormal);
						#endif

						// The diffuse light.
						vec3 diffuseLight = ambientLightColor;
						vec3 specularLight = vec3(0, 0, 0);

						// Atmosphere emissive shading.
						#ifdef atmosphere
							AtmosphereInfo atmosphereInfo = getAtmosphereInfo();
							vec4 atmosphereColor = getAtmosphereEmissiveColor(atmosphereInfo, atmosphereColor, atmosphereEmissivity);
						#endif

						// For each light,
						for (int i = 0; i < 5; i++) {
							if (i >= numLights) {
								break;
							}

							// Get lighting angles.
							vec3 lightDir = normalize(cameraSpacePosition - lightPositions[i]);
							float lightCosAngle = -dot(lightDir, normal);

							// Make the shadows a bit sharper, depending on atmospheres.
							float sharpness = 3.0;
							#ifdef atmosphere
								sharpness /= 1.0 + 700.0 * atmosphereDensity;
							#endif
							lightCosAngle = 2.0 * (1.0 + exp(-sharpness)) / (1.0 + exp(-sharpness * lightCosAngle)) - 1.0;

							// Get the incoming light after shadows.
							vec3 incomingLight = lightColors[i];
							#if !defined(colorMapEmmissive) | !defined(nightMapEmmissive) | !defined(decalMapEmmissive)
								#ifdef shadowEntities
									incomingLight = getLightColorFromShadowEntities(incomingLight, lightDir, lightPositions[i], lightRadii[i], normal);
								#endif
								#ifdef shadowRings
									incomingLight = getLightColorFromShadowRings(incomingLight, lightDir);
								#endif
							#endif

							// Diffuse shading.
							diffuseLight += incomingLight * saturate(lightCosAngle);

							// Specular shading.
							vec3 reflectedLightDir = reflect(lightDir, normal);
							vec3 halfVector = normalize(-lightDir - positionDir);
							float phongHighlight = 0.25 * pow(saturate(-dot(reflectedLightDir, positionDir)), specularHardness / 2.0);
							float blinnHighlight = 4.0 * pow(saturate(dot(halfVector, normal)), specularHardness);
							float specularAngle = phongHighlight + pow(1.0 - saturate(-dot(positionDir, normal)), specularHardness / 12.0) * blinnHighlight;
							specularLight += saturate(lightCosAngle * 20.0) * specularAngle * incomingLight;	

							// Atmosphere shading.
							#ifdef atmosphere
								if (length(lightPositions[i]) > 0.0) { // don't use a camera light
									atmosphereColor += getAtmosphereColor(atmosphereInfo, incomingLight, lightPositions[i]);
								}
							#endif
						}
						diffuseLight = saturate(diffuseLight);

						// If there's ambience, remove the direct light components.
						specularLight *= vec3(1, 1, 1) - ambientLightColor;

						// Main Color map.
						vec4 colorPixel = vec4(1, 0, 1, 1);
						#ifdef colorTextureEnvironment
							colorPixel = getColorFromEnvironmentMap(colorTexture, positionDir, normal);
						#else
							colorPixel = texture2D(colorTexture, vColorUV) * vec4(color, 1);
						#endif
						#ifdef baseColor
							colorPixel = vec4(color, 1);
						#endif

						// Apply diffuse shading.
						#ifndef colorMapEmmissive
							colorPixel *= vec4(diffuseLight, 1.0);
						#endif
						gl_FragColor = colorPixel;

						// Specular Map
						vec3 specularPixel = specularColor * specularIntensity;
						#ifdef specularMap
							#ifdef specularUVs
								vec2 specularUV = vSpecularUV;
							#else
								vec2 specularUV = vColorUV;
							#endif
							specularPixel = specularColor * texture2D(specularTexture, specularUV).r;
						#endif

						// Apply specular Shading
						gl_FragColor.rgb += specularLight * specularPixel;

						// Night-Side Map
						#ifdef nightMap
							float ambientLightIntensity = min(1.0, length(ambientLightColor));
							#ifdef nightUVs
								vec2 nightUV = vNightUV;
							#else
								vec2 nightUV = vColorUV;
							#endif
							vec4 nightPixel = texture2D(nightTexture, nightUV);
							#ifndef nightMapEmmissive
								nightPixel *= vec4(diffuseLight, 1.0);
							#endif
							gl_FragColor = mix(gl_FragColor, nightPixel, 1.0 - min(1.0, length(ambientLightColor + diffuseLight)));//(1.0 - ambientLightIntensity) * saturate(0.5 - length(diffuseLight)));
						#endif

						// Decal Map
						#ifdef decalMap
							#ifdef decalUVs
								vec2 decalUV = vDecalUV;
							#else
								vec2 decalUV = vColorUV;
							#endif
							vec4 decalPixel = texture2D(decalTexture, decalUV);
							#ifndef decalMapEmmissive
								decalPixel *= vec4(diffuseLight, 1.0);
							#endif
							gl_FragColor.rgb = mix(gl_FragColor.rgb, decalPixel.rgb, decalPixel.a);
						#endif

						// Atmosphere
						#ifdef atmosphere
							gl_FragColor.rgb = mix(gl_FragColor.rgb, atmosphereColor.rgb, clamp(atmosphereColor.a, 0.0, 1.0));
						#endif

						${ShaderChunkLogDepth.Fragment}
					}`
			});
		}
		const newMaterial = MaterialUtilsPhong._material.clone();
		for (let i = 0; i < newMaterial.uniforms['shadowEntityPositions'].value.length; i++) {
			newMaterial.uniforms['shadowEntityPositions'].value[i] = MaterialUtilsPhong._material.uniforms['shadowEntityPositions'].value[i].clone();
		}
		for (let i = 0; i < newMaterial.uniforms['shadowEntitySunsetColors'].value.length; i++) {
			newMaterial.uniforms['shadowEntitySunsetColors'].value[i] = MaterialUtilsPhong._material.uniforms['shadowEntitySunsetColors'].value[i].clone();
		}
		ThreeJsHelper.setupLogDepthBuffering(newMaterial);
		newMaterial.extensions.derivatives = true;
		newMaterial.needsUpdate = true;
		return newMaterial;
	}
}

/**
 * @type {THREE.ShaderMaterial}
*/
MaterialUtilsPhong._material = null;
