/** @module pioneer */
import {
	BaseComponent,
	BaseController,
	CameraComponent,
	Collection,
	CollectionItem,
	FastMap,
	Geometry,
	Interval,
	Quaternion,
	Scene,
	Sort,
	Types,
	Vector2,
	Vector3
} from '../internal';

/**
 * The main class that defines an object in space. It has position, orientation, components, controllers, and size.
 * @extends {CollectionItem<Scene>}
 */
export class Entity extends CollectionItem {
	/**
	 * Constructor.
	 * @param {string} type - the type of the entity (always 'entity');
	 * @param {string} name - the name of the entity
	 * @param {Scene} scene - the scene
	 */
	constructor(type, name, scene) {
		super(type, name, scene);

		/**
		 * Flag that the user can use to enable or disable the entity (and its children).
		 * @type {boolean}
		 * @private
		 */
		this._enabled = true;

		/**
		 * The flag that says whether any ancestor is disabled, making this disabled.
		 * @type {boolean}
		 * @private
		 */
		this._disabledByAncestor = false;

		/**
		 * Flag that indicates whether the entity has been destroyed.
		 * @type {boolean}
		 * @private
		 */
		this._destroyed = false;

		/**
		 * The parent of the entity.
		 * @type {Entity}
		 * @private
		 */
		this._parent = null;

		/**
		 * The parent of the entity at the beginning of the last frame.
		 * @type {Entity}
		 * @private
		 */
		this._lastParent = null;

		/**
		 * The children of the entity.
		 * @type {Entity[]}
		 * @private
		 */
		this._children = [];

		/**
		 * The time starts for parents as `[start time, parent name]`, sorted by start times.
		 * @type {[number, string][]}
		 * @private
		 */
		this._parentingTable = [];

		/**
		 * A list of callbacks to call when the parent changes.
		 * @type {((entity: Entity, oldParent: Entity, newParent: Entity) => void)[]}
		 * @private
		 */
		this._parentChangedCallbacks = [];

		/**
		 * A list of callbacks to call when the child changes.
		 * @type {((entity: Entity, child: Entity, added: boolean) => void)[]}
		 * @private
		 */
		this._childChangedCallbacks = [];

		/**
		 * The entity state.
		 * @type {EntityState}
		 * @private
		 */
		this._state = new EntityState();

		/**
		 * The entity state from the previous frame.
		 * @type {EntityState}
		 * @private
		 */
		this._lastState = new EntityState();

		/**
		 * The position of the entity relative to the camera's position.
		 * @type {FastMap<CameraComponent, Vector3>}
		 * @private
		 */
		this._cameraSpacePosition = new FastMap();

		/**
		 * The position of the entity in normal-space.
		 * @type {FastMap<CameraComponent, Vector3>}
		 * @private
		 */
		this._normalSpacePosition = new FastMap();

		/**
		 * The position of the entity in pixel-space.
		 * @type {FastMap<CameraComponent, Vector2>}
		 * @private
		 */
		this._pixelSpacePosition = new FastMap();

		/**
		 * The normal-space extents radius of the entity.
		 * @type {FastMap<CameraComponent, number>}
		 * @private
		 */
		this._normalSpaceExtentsRadius = new FastMap();

		/**
		 * The pixel-space extents radius of the entity.
		 * @type {FastMap<CameraComponent, number>}
		 * @private
		 */
		this._pixelSpaceExtentsRadius = new FastMap();

		/**
		 * The greatest pixel-space extents radius of the entity in any camera.
		 * @type {number}
		 * @private
		 */
		this._greatestPixelSpaceExtentsRadius = 0.0;

		/**
		 * The depth in the scene graph away from each rendering camera. 0 means the camera itself.
		 * @type {FastMap<CameraComponent, number>}
		 * @private
		 */
		this._cameraDepths = new FastMap();

		/**
		 * The minimum scene depth from all cameras.
		 * @type {number}
		 * @private
		 */
		this._leastCameraDepth = Number.MAX_SAFE_INTEGER;

		/**
		 * The collection of components.
		 * @type {Collection<BaseComponent, Entity>}
		 * @private
		*/
		this._components = new Collection(this, Types.Components);

		/**
		 * The collection of controllers.
		 * @type {Collection<BaseController, Entity>}
		 * @private
		*/
		this._controllers = new Collection(this, Types.Controllers);

		/**
		 * The radius of the entity.
		 * @type {number}
		 * @private
		 */
		this._occlusionRadius = 0;

		/**
		 * The radius of the entity.
		 * @type {number}
		 * @private
		 */
		this._extentsRadius = 0;

		/**
		 * A user-set flag that determines whether things like labels get hidden behind the entity.
		 * @type {boolean}
		 * @private
		 */
		this._canOcclude = true;

		/**
		 * The position coverage of the controllers.
		 * @type {Interval}
		 * @private
		 */
		this._positionCoverage = new Interval(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY);
		this._positionCoverage.freeze();

		/**
		 * The orientation coverage of the controllers.
		 * @type {Interval}
		 * @private
		 */
		this._orientationCoverage = new Interval(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY);
		this._orientationCoverage.freeze();

		/**
		 * A flag that is true if the entity's position coverage contains the current time.
		 * @type {boolean}
		 * @private
		 */
		this._isInPositionCoverage = false;

		/**
		 * A flag that is true if the entity's orientation coverage contains the current time.
		 * @type {boolean}
		 * @private
		 */
		this._isInOrientationCoverage = false;
	}

	// NAME, SCENE, ENABLED, VISIBILITY

	/**
	 * Returns the scene.
	 * @returns {Scene}
	 */
	getScene() {
		return this.__getCollectionParent();
	}

	/**
	 * Returns true if the entity is enabled.
	 * @returns {boolean}
	 */
	isEnabled() {
		return this._enabled && !this._disabledByAncestor;
	}

	/**
	 * Gets the flag that says whether any ancestor is disabled, making this disabled.
	 * @returns {boolean}
	 */
	isDisabledByAncestor() {
		return this._disabledByAncestor;
	}

	/**
	 * Sets whether the entity is enabled or not. Affects visibility and updating
	 * of all components and controllers. Does not include children.
	 * @param {boolean} enabled
	 */
	setEnabled(enabled) {
		// If the flag has changed.
		if (this._enabled !== enabled) {
			// Update the flag.
			this._enabled = enabled;
			// Update the components and children's disabledByAncestor flags.
			this._updateEnabled();
		}
	}

	/**
	 * Updates the enabled flag after its been set.
	 * @private
	 */
	_updateEnabled() {
		// Update the component load states, which depends on the entity's enabled flag.
		for (let i = 0; i < this._components.size; i++) {
			this._components.get(i).__updateLoadState();
		}
		// Update the children.
		for (let i = 0, l = this._children.length; i < l; i++) {
			this._children[i]._updateDisabledByAncestor();
		}
	}

	/**
	 * Updates the disabledByAncestor flag.
	 * @private
	 */
	_updateDisabledByAncestor() {
		// Check if any ancestor is disabled.
		let newDisabledByAncestor = false;
		let ancestor = this._parent;
		while (ancestor !== null) {
			if (!ancestor._enabled || ancestor._disabledByAncestor) {
				newDisabledByAncestor = true;
				break;
			}
			ancestor = ancestor._parent;
		}
		// If the disabledByAncestor flag has changed, update it and its children.
		if (this._disabledByAncestor !== newDisabledByAncestor) {
			// Update the flag.
			this._disabledByAncestor = newDisabledByAncestor;
			// Update the components and children's disabledByAncestor flags.
			this._updateEnabled();
		}
	}

	/**
	 * Checks if the entity item has been destroyed.
	 * @returns {boolean}
	 */
	isDestroyed() {
		return this._destroyed;
	}

	// POSITION, ORIENTATION, ETC

	/**
	 * Gets the position (frozen).
	 * @returns {Vector3}
	 */
	getPosition() {
		return this._state.position;
	}

	/**
	 * Sets the position. Called by controllers when they set the position of the entity.
	 * @param {Vector3} position - the value to set
	 */
	setPosition(position) {
		this._state.position.thaw();
		this._state.position.copy(position);
		this._state.position.freeze();
	}

	/**
	 * Gets the velocity (frozen).
	 * @returns {Vector3}
	 */
	getVelocity() {
		return this._state.velocity;
	}

	/**
	 * Sets the velocity. Called by controllers when they set the velocity of the entity.
	 * @param {Vector3} velocity - the value to set
	 */
	setVelocity(velocity) {
		this._state.velocity.thaw();
		this._state.velocity.copy(velocity);
		this._state.velocity.freeze();
	}

	/**
	 * Gets the orientation (frozen).
	 * @returns {Quaternion}
	 */
	getOrientation() {
		return this._state.orientation;
	}

	/**
	 * Sets the orientation. Called by controllers when they set the orientation of the entity.
	 * @param {Quaternion} orientation - the value to set
	 */
	setOrientation(orientation) {
		this._state.orientation.thaw();
		this._state.orientation.copy(orientation);
		this._state.orientation.freeze();
	}

	/**
	 * Gets the rotational velocity (frozen).
	 * @returns {Vector3}
	 */
	getAngularVelocity() {
		return this._state.angularVelocity;
	}

	/**
	 * Sets the rotational velocity. Called by controllers when they set the rotational velocity of the entity.
	 * @param {Vector3} angularVelocity - the value to set
	 */
	setAngularVelocity(angularVelocity) {
		this._state.angularVelocity.thaw();
		this._state.angularVelocity.copy(angularVelocity);
		this._state.angularVelocity.freeze();
	}

	/**
	 * Gets the position on the previous frame.
	 * @returns {Vector3}
	 */
	getLastPosition() {
		return this._lastState.position;
	}

	/**
	 * Gets the velocity on the previous frame.
	 * @returns {Vector3}
	 */
	getLastVelocity() {
		return this._lastState.velocity;
	}

	/**
	 * Gets the orientation on the previous frame.
	 * @returns {Quaternion}
	 */
	getLastOrientation() {
		return this._lastState.orientation;
	}

	/**
	 * Gets the angular velocity on the previous frame.
	 * @returns {Vector3}
	 */
	getLastAngularVelocity() {
		return this._lastState.angularVelocity;
	}

	/**
	 * Gets the position given in this frame relative to the given entity.
	 * @param {Vector3} outPosition - the result
	 * @param {Vector3} positionInThisFrame - the position in this frame
	 * @param {Entity} entity - the entity. If null, defaults to the root.
	 * @param {number} [time] - an optional time to use.
	 */
	getPositionRelativeToEntity(outPosition, positionInThisFrame, entity, time) {
		/**
		 * @type {Entity}
		 */
		let e = this;
		outPosition.copy(positionInThisFrame);
		if (time === undefined) {
			const lca = this.getLowestCommonAncestor(entity);
			if (lca === null) {
				outPosition.copy(Vector3.NaN);
				return;
			}
			outPosition.add(outPosition, e.getPosition());
			while (e !== lca) {
				e = e.getParent();
				outPosition.add(outPosition, e.getPosition());
			}
			if (entity !== null) {
				e = entity;
				outPosition.sub(outPosition, e.getPosition());
				while (e !== lca) {
					e = e.getParent();
					outPosition.sub(outPosition, e.getPosition());
				}
			}
		}
		else { // A specific time was requested.
			const scene = this.getScene();
			const ePosition = Vector3.pool.get();
			const lca = this.getLowestCommonAncestorAtTime(entity, time);
			if (lca !== null) {
				e.getPositionAtTime(ePosition, time);
				outPosition.add(outPosition, ePosition);
				while (e !== lca) {
					const eName = e.getParentAtTime(time);
					e = scene.getEntity(eName);
					if (e === null) {
						outPosition.copy(Vector3.NaN);
						return;
					}
					e.getPositionAtTime(ePosition, time);
					outPosition.add(outPosition, ePosition);
				}
				if (entity !== null) {
					e = entity;
					e.getPositionAtTime(ePosition, time);
					outPosition.sub(outPosition, ePosition);
					while (e !== lca) {
						const eName = e.getParentAtTime(time);
						e = scene.getEntity(eName);
						if (e === null) {
							outPosition.copy(Vector3.NaN);
							return;
						}
						e.getPositionAtTime(ePosition, time);
						outPosition.sub(outPosition, ePosition);
					}
				}
			}
			else {
				outPosition.copy(Vector3.NaN);
			}
			Vector3.pool.release(ePosition);
		}
	}

	/**
	 * Gets the velocity given in this frame relative to the given entity.
	 * @param {Vector3} outVelocity - the result
	 * @param {Vector3} velocityInThisFrame - the velocity in this frame
	 * @param {Entity} entity - the entity. If null, defaults to the root.
	 * @param {number} [time] - an optional time to use.
	 */
	getVelocityRelativeToEntity(outVelocity, velocityInThisFrame, entity, time) {
		/**
		 * @type {Entity}
		 */
		let e = this;
		outVelocity.copy(velocityInThisFrame);
		if (time === undefined) {
			const lca = this.getLowestCommonAncestor(entity);
			if (lca === null) {
				outVelocity.copy(Vector3.NaN);
				return;
			}
			outVelocity.add(outVelocity, e.getVelocity());
			while (e !== lca) {
				e = e.getParent();
				outVelocity.add(outVelocity, e.getVelocity());
			}
			if (entity !== null) {
				e = entity;
				outVelocity.sub(outVelocity, e.getVelocity());
				while (e !== lca) {
					e = e.getParent();
					outVelocity.sub(outVelocity, e.getVelocity());
				}
			}
		}
		else { // A specific time was requested.
			const scene = this.getScene();
			const eVelocity = Vector3.pool.get();
			const lca = this.getLowestCommonAncestorAtTime(entity, time);
			if (lca !== null) {
				e.getVelocityAtTime(eVelocity, time);
				outVelocity.add(outVelocity, eVelocity);
				while (e !== lca) {
					const eName = e.getParentAtTime(time);
					e = scene.getEntity(eName);
					if (e === null) {
						outVelocity.copy(Vector3.NaN);
						return;
					}
					e.getVelocityAtTime(eVelocity, time);
					outVelocity.add(outVelocity, eVelocity);
				}
				if (entity !== null) {
					e = entity;
					e.getVelocityAtTime(eVelocity, time);
					outVelocity.sub(outVelocity, eVelocity);
					while (e !== lca) {
						const eName = e.getParentAtTime(time);
						e = scene.getEntity(eName);
						if (e === null) {
							outVelocity.copy(Vector3.NaN);
							return;
						}
						e.getVelocityAtTime(eVelocity, time);
						outVelocity.sub(outVelocity, eVelocity);
					}
				}
			}
			else {
				outVelocity.copy(Vector3.NaN);
			}
			Vector3.pool.release(eVelocity);
		}
	}

	/**
	 * Sets outPosition to the entity position at the given time. If the time is omitted, the current time is used.
	 * @param {Vector3} outPosition
	 * @param {number} [time]
	 */
	getPositionAtTime(outPosition, time) {
		if (!this.isEnabled()) {
			outPosition.set(Number.NaN, Number.NaN, Number.NaN);
		}
		else {
			if (time === undefined || this._controllers.size === 0) {
				outPosition.copy(this._state.position);
			}
			else {
				outPosition.set(Number.NaN, Number.NaN, Number.NaN);
			}
			for (let i = 0; i < this._controllers.size; i++) {
				const controller = this._controllers.get(i);
				if (controller.hasModifiedState('position') && controller.getCoverage().contains(time) && controller.isEnabled()) {
					controller.__updatePositionAtTime(outPosition, time);
				}
			}
		}
	}

	/**
	 * Sets outVelocity to the entity velocity at the given time. If the time is omitted, the current time is used.
	 * @param {Vector3} outVelocity
	 * @param {number} [time]
	 */
	getVelocityAtTime(outVelocity, time) {
		if (!this.isEnabled()) {
			outVelocity.set(Number.NaN, Number.NaN, Number.NaN);
		}
		else {
			if (time === undefined || this._controllers.size === 0) {
				outVelocity.copy(this._state.velocity);
			}
			else {
				outVelocity.set(Number.NaN, Number.NaN, Number.NaN);
			}
			for (let i = 0; i < this._controllers.size; i++) {
				const controller = this._controllers.get(i);
				if (controller.hasModifiedState('velocity') && controller.getCoverage().contains(time) && controller.isEnabled()) {
					controller.__updateVelocityAtTime(outVelocity, time);
				}
			}
		}
	}

	/**
	 * Sets outOrientation to the entity orientation at the given time. If the time is omitted, the current time is used.
	 * @param {Quaternion} outOrientation - the orientation to be set
	 * @param {number} [time] - the time to check
	 */
	getOrientationAtTime(outOrientation, time) {
		if (!this.isEnabled()) {
			outOrientation.set(Number.NaN, Number.NaN, Number.NaN, Number.NaN);
		}
		else {
			if (time === undefined || this._controllers.size === 0) {
				outOrientation.copy(this._state.orientation);
			}
			else {
				outOrientation.set(Number.NaN, Number.NaN, Number.NaN, Number.NaN);
			}
			for (let i = 0; i < this._controllers.size; i++) {
				const controller = this._controllers.get(i);
				if (controller.hasModifiedState('orientation') && controller.getCoverage().contains(time) && controller.isEnabled()) {
					controller.__updateOrientationAtTime(outOrientation, time);
				}
			}
		}
	}

	// RADII

	/**
	 * Gets the occlusion radius of the entity. For non-spherical entities, this is a rough approximation of the bulk of the entity.
	 * @returns {number}
	 */
	getOcclusionRadius() {
		return this._occlusionRadius;
	}

	/**
	 * Gets the extents radius of the entity. For non-spherical entities, this is how far any part of the entity extends.
	 * @returns {number}
	 */
	getExtentsRadius() {
		return this._extentsRadius;
	}

	/**
	 * Sets the radius of the entity. For non-spherical entities, this is a rough approximation of the bulk of the entity.
	 * @param {number} radius - the value to set
	 */
	setOcclusionRadius(radius) {
		this._occlusionRadius = radius;
	}

	/**
	 * Sets the radius of the entity. For non-spherical entities, this is how far any part of the entity extends.
	 * @param {number} radius - the value to set
	 */
	setExtentsRadius(radius) {
		this._extentsRadius = radius;
	}

	// COVERAGE

	/**
	 * Gets the position coverage of the entity's controllers. If there are no position coverages, it returns an infinite coverage.
	 * @returns {Interval}
	 */
	getPositionCoverage() {
		return this._positionCoverage;
	}

	/**
	 * Gets the orientation coverage of the entity's controllers. If there are no orientation coverages, it returns an infinite coverage.
	 * @returns {Interval}
	 */
	getOrientationCoverage() {
		return this._orientationCoverage;
	}

	/**
	 * Gets the flag that is true if the entity's position coverage contains the current time.
	 * @returns {boolean}
	 */
	isInPositionCoverage() {
		return this._isInPositionCoverage;
	}

	/**
	 * Gets flag that is true if the entity's orientation coverage contains the current time.
	 * @returns {boolean}
	 */
	isInOrientationCoverage() {
		return this._isInOrientationCoverage;
	}

	/**
	 * Copies the current state to the last state.
	 * @internal
	 */
	__updateLastState() {
		this._lastState.position.thaw();
		this._lastState.position.copy(this._state.position);
		this._lastState.position.freeze();

		this._lastState.velocity.thaw();
		this._lastState.velocity.copy(this._state.velocity);
		this._lastState.velocity.freeze();

		this._lastState.orientation.thaw();
		this._lastState.orientation.copy(this._state.orientation);
		this._lastState.orientation.freeze();

		this._lastState.angularVelocity.thaw();
		this._lastState.angularVelocity.copy(this._state.angularVelocity);
		this._lastState.angularVelocity.freeze();
	}

	/**
	 * Updates the position and orientation coverage of the entity from the controllers.
	 * @internal
	 */
	__updateCoverage() {

		// Thaw the coverages.
		this._positionCoverage.thaw();
		this._orientationCoverage.thaw();

		// Initially set them to be no coverage.
		this._positionCoverage.set(Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY);
		this._orientationCoverage.set(Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY);

		// Go through each controller and union the coverages for position and orientation.
		let numPositionControllers = 0;
		let numOrientationControllers = 0;
		for (let i = 0; i < this._controllers.size; i++) {
			const controller = this._controllers.get(i);
			if (controller.isEnabled()) {
				if (controller.hasModifiedState('position')) {
					this._positionCoverage.union(this._positionCoverage, controller.getCoverage());
					numPositionControllers++;
				}
				if (controller.hasModifiedState('orientation')) {
					this._orientationCoverage.union(this._orientationCoverage, controller.getCoverage());
					numOrientationControllers++;
				}
			}
		}

		// If there are no coverages, set them to infinity.
		if (numPositionControllers === 0) {
			this._positionCoverage.set(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY);
		}
		if (numOrientationControllers === 0) {
			this._orientationCoverage.set(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY);
		}

		// Also limit to the position coverage parenting table coverages.
		if (this._parentingTable.length > 0) {
			const firstEntry = this._parentingTable[0];
			const lastEntry = this._parentingTable[this._parentingTable.length - 1];
			this._positionCoverage.min = Math.max(this._positionCoverage.min, firstEntry[1] !== '' ? firstEntry[0] : Number.POSITIVE_INFINITY);
			this._positionCoverage.max = Math.min(this._positionCoverage.max, lastEntry[1] !== '' ? Number.POSITIVE_INFINITY : lastEntry[0]);
		}

		// Refreeze the coverages.
		this._positionCoverage.freeze();
		this._orientationCoverage.freeze();
	}

	// OCCLUSION

	/**
	 * Returns whether the entity can occlude objects in the occlusion system. Returns true by default.
	 * @returns {boolean}
	 */
	canOcclude() {
		return this._canOcclude;
	}

	/**
	 * Sets whether the entity can occlude objects in the occlusion system.
	 * @param {boolean} canOcclude
	 */
	setCanOcclude(canOcclude) {
		this._canOcclude = canOcclude;
	}

	/**
	 * Returns true if the entity is occluding the camera-space position.
	 * @param {CameraComponent} camera
	 * @param {Vector3} cameraSpacePosition
	 * @returns {boolean}
	 */
	isOccludingPosition(camera, cameraSpacePosition) {
		if (!this._canOcclude || !this.isInPositionCoverage()) {
			return false;
		}
		const occludingRadius = Math.min(this._occlusionRadius, this.getCameraSpacePosition(camera).magnitude());
		const interval = Interval.pool.get();
		Geometry.getLineSphereIntersectionWithLineStartAtOrigin(interval, cameraSpacePosition, this.getCameraSpacePosition(camera), occludingRadius);
		const occluding = interval.min < interval.max && (0 <= interval.min + interval.max) && (interval.min + interval.max < 2);
		Interval.pool.release(interval);
		return occluding;
	}

	// PARENTING

	/**
	 * Gets the parent of the entity.
	 * If the parent has been set but is not yet in the scene, it returns null.
	 * @returns {Entity}
	 */
	getParent() {
		return this._parent;
	}

	/**
	 * Gets the parent name of the entity at the given time.
	 * @param {number} time
	 * @returns {string}
	 */
	getParentAtTime(time) {
		const index = Sort.getIndex(time, this._parentingTable, isStartTimeLessThanTime);
		if (index < this._parentingTable.length && time === this._parentingTable[index][0]) {
			return this._parentingTable[index][1];
		}
		else if (index > 0) {
			return this._parentingTable[index - 1][1];
		}
		else {
			if (this._parentingTable.length > 0) {
				return '';
			}
			else { // There's no parenting table, so just choose the current parent, or '' if there's none.
				return this._parent !== null ? this._parent.getName() : '';
			}
		}
	}

	/**
	 * Sets the parent of the entity.
	 * @param {Entity} parent - the new parent or its name
	 */
	setParent(parent) {
		// If it already set, just return.
		if (parent === this._parent) {
			return;
		}

		// Make sure the position stays the same relative to the new parent.
		const position = Vector3.pool.get();
		this.getPositionRelativeToEntity(position, Vector3.Zero, parent);
		if (!position.isNaN()) {
			this.setPosition(position);
		}
		Vector3.pool.release(position);

		// Remove the old parent's child link.
		if (this._parent !== null) {
			for (let i = 0; i < this._parent._children.length; i++) {
				const child = this._parent._children[i];
				if (child === this) {
					this._parent._children.splice(i, 1);
				}
			}
		}

		// Change to the new parent, saving the old parent for the callbacks.
		const oldParent = this._parent;
		this._parent = parent;

		// Add the new parent's child link.
		if (this._parent !== null) {
			this._parent._children.push(this);
		}

		// Clear the camera references of the entity and all of its descendants components,
		// since with a new parent, it might have a new set of connected cameras.
		// The prepareForRender and setCameraDependentVariables functions will enable them again in any connected camera.
		this._clearCameraReferences();

		// Update the disabledByAncestors flag.
		this._updateDisabledByAncestor();

		// Call any parent changed callbacks.
		for (let i = 0, l = this._parentChangedCallbacks.length; i < l; i++) {
			this._parentChangedCallbacks[i](this, oldParent, this._parent);
		}

		// Call any child changed callbacks for the old and new parents.
		if (oldParent !== null) {
			for (let i = 0, l = oldParent._childChangedCallbacks.length; i < l; i++) {
				oldParent._childChangedCallbacks[i](oldParent, this, false);
			}
		}
		if (this._parent !== null) {
			for (let i = 0, l = this._parent._childChangedCallbacks.length; i < l; i++) {
				this._parent._childChangedCallbacks[i](this._parent, this, true);
			}
		}
	}

	/**
	 * Clears the camera references for when an entity changes its parent and may be visible in a new camera or no camera.
	 * The variables will repopulate with any camera that calls the prepareForRender and updateCameraVariables functions.
	 * @private
	 */
	_clearCameraReferences() {
		// Clear all of the camera dependent variables to be repopulated again.
		this._cameraSpacePosition.clear();
		this._normalSpacePosition.clear();
		this._pixelSpacePosition.clear();
		this._normalSpaceExtentsRadius.clear();
		this._pixelSpaceExtentsRadius.clear();
		this._cameraDepths.clear();

		// Do the same for components, and also make them invisible until a camera tries to render them again in prepareForRender.
		for (let i = 0, l = this._components.size; i < l; i++) {
			const component = this._components.get(i);
			component.__clearCameraDependentsBase();
		}

		// Do this for all of the descendants as well.
		for (let i = 0, l = this._children.length; i < l; i++) {
			this._children[i]._clearCameraReferences();
		}
	}

	/**
	 * Gets the number of child entities. Good for iterating over child entities.
	 * @return {number}
	 */
	getNumChildren() {
		return this._children.length;
	}

	/**
	 * Gets the child entity at index i. Good for iterating over child entities.
	 * @param {number} i - the index
	 * @returns {Entity}
	 */
	getChild(i) {
		return this._children[i];
	}

	/**
	 * Gets the lowest common ancestor between this and another entity.
	 * @param {Entity} entity
	 * @returns {Entity}
	 */
	getLowestCommonAncestor(entity) {
		if (entity === null) {
			return null;
		}
		let entityThis = /** @type {Entity} */(this);
		let entityOther = entity;
		let levelThis = 0;
		let levelOther = 0;
		// Get the levels of the two entities relative to the root.
		while (entityThis.getParent() !== null) {
			levelThis += 1;
			entityThis = entityThis.getParent();
		}
		while (entityOther.getParent() !== null) {
			levelOther += 1;
			entityOther = entityOther.getParent();
		}
		// Whichever has the greatest level depth, move up to have the same level as the least level depth.
		entityThis = this;
		entityOther = entity;
		while (levelThis > levelOther) {
			levelThis -= 1;
			entityThis = entityThis.getParent();
		}
		while (levelOther > levelThis) {
			levelOther -= 1;
			entityOther = entityOther.getParent();
		}
		// Move up the ancestors for both until a common ancestor is found.
		while (entityThis !== entityOther && entityThis !== null) {
			entityThis = entityThis.getParent();
			entityOther = entityOther.getParent();
		}
		return entityThis; // If they didn't have a common ancestor, this will correctly be null.
	}

	/**
	 * Gets the lowest common ancestor between this and another entity at a given time.
	 * @param {Entity} entity
	 * @param {number} time
	 * @returns {Entity}
	 */
	getLowestCommonAncestorAtTime(entity, time) {
		if (entity === null) {
			return null;
		}
		let entityThis = /** @type {Entity} */(this);
		let entityOther = entity;
		let levelThis = 0;
		let levelOther = 0;
		const scene = this.getScene();
		// Get the levels of the two entities relative to the root.
		while (true) {
			const parentName = entityThis.getParentAtTime(time);
			if (parentName === '') {
				break;
			}
			const parent = scene.getEntity(parentName);
			if (parent === null) {
				return null;
			}
			levelThis += 1;
			entityThis = parent;
		}
		while (true) {
			const parentName = entityOther.getParentAtTime(time);
			if (parentName === '') {
				break;
			}
			const parent = scene.getEntity(parentName);
			if (parent === null) {
				return null;
			}
			levelOther += 1;
			entityOther = parent;
		}
		// Whichever has the greatest level depth, move up to have the same level as the least level depth.
		entityThis = this;
		entityOther = entity;
		while (levelThis > levelOther) {
			levelThis -= 1;
			const parentName = entityThis.getParentAtTime(time);
			const parent = scene.getEntity(parentName);
			if (parent === null) {
				return null;
			}
			entityThis = parent;
		}
		while (levelOther > levelThis) {
			levelOther -= 1;
			const parentName = entityOther.getParentAtTime(time);
			const parent = scene.getEntity(parentName);
			if (parent === null) {
				return null;
			}
			entityOther = parent;
		}
		// Move up the ancestors for both until a common ancestor is found.
		while (entityThis !== entityOther && entityThis !== null) {
			const parentThisName = entityThis.getParentAtTime(time);
			const parentThis = scene.getEntity(parentThisName);
			if (parentThis === null) {
				return null;
			}
			entityThis = parentThis;
			const parentOtherName = entityOther.getParentAtTime(time);
			const parentOther = scene.getEntity(parentOtherName);
			if (parentOther === null) {
				return null;
			}
			entityOther = parentOther;
		}
		return entityThis; // If they didn't have a common ancestor, this will correctly be null.
	}

	/**
	 * Gets the number of parenting table entries. Can be used for a `for` loop.
	 * @returns {number}
	 */
	getNumParentingTableEntries() {
		return this._parentingTable.length;
	}

	/**
	 * Gets a parenting table entry.
	 * @param {number} index
	 * @returns {readonly [number, string]}
	 */
	getParentingTableEntry(index) {
		return this._parentingTable[index];
	}

	/**
	 * Adds an entry to the parenting table.
	 * @param {number} startTime - The starting time of the interval. The next entry will denote the ending time.
	 * @param {string} parentName - The name of the parent for the interval.
	 */
	addParentingTableEntry(startTime, parentName) {
		Sort.add([startTime, parentName], this._parentingTable, isStartTimeLess, isStartTimeEqual);
		this.__updateCoverage();
	}

	/**
	 * Removes an entry from the parenting table.
	 * @param {number} index
	 */
	removeParentingTableEntry(index) {
		this._parentingTable.splice(index);
		this.__updateCoverage();
	}

	/**
	* Clears all entries from the parenting table.
	*/
	clearParentingTableEntries() {
		this._parentingTable = [];
		this.__updateCoverage();
	}

	/**
	 * Adds a parent changed callback.
	 * @param {(entity: Entity, oldParent: Entity, newParent: Entity) => void} callback
	 */
	addParentChangedCallback(callback) {
		this._parentChangedCallbacks.push(callback);
	}

	/**
	 * Removes a parent changed callback.
	 * @param {(entity: Entity, oldParent: Entity, newParent: Entity) => void} callback
	 */
	removeParentChangedCallback(callback) {
		const index = this._parentChangedCallbacks.indexOf(callback);
		if (index !== -1) {
			this._parentChangedCallbacks.splice(index, 1);
		}
	}

	/**
	 * Adds a child changed callback.
	 * @param {(entity: Entity, child: Entity, added: boolean) => void} callback
	 */
	addChildChangedCallback(callback) {
		this._childChangedCallbacks.push(callback);
	}

	/**
	 * Removes a child changed callback.
	 * @param {(entity: Entity, child: Entity, added: boolean) => void} callback
	 */
	removeChildChangedCallback(callback) {
		const index = this._childChangedCallbacks.indexOf(callback);
		if (index !== -1) {
			this._childChangedCallbacks.splice(index, 1);
		}
	}

	// COMPONENTS

	/**
	 * Gets the number of components.
	 * @returns {number}
	 */
	getNumComponents() {
		return this._components.size;
	}

	/**
	 * Gets the component from either the name or the index. It returns null if the component is not found.
	 * @param {string|number} nameOrIndex
	 * @returns {BaseComponent | null}
	 */
	getComponent(nameOrIndex) {
		return this._components.get(nameOrIndex);
	}

	/**
	 * Gets the index'th component of the given type. The index is base 0. Returns null if none is found.
	 * @param {string} type
	 * @param {number} [index=0]
	 * @returns {BaseComponent | null}
	 */
	getComponentByType(type, index = 0) {
		return this._components.getByType(type, index);
	}

	/**
	 * Gets the index'th component of the given type. The index is base 0. Returns null if none is found.
 	 * @template {BaseComponent} Class
	 * @param {import('../utils/collection').TypeConstructor<Class, Entity>} ComponentClass
	 * @param {number} [index=0]
	 * @returns {Class | null}
	 */
	getComponentByClass(ComponentClass, index = 0) {
		return this._components.getByClass(ComponentClass, index);
	}

	/**
	 * Adds a component.
	 * @param {string} type - type of the component
	 * @param {string} [name=''] - name of the component (optional)
	 * @param {BaseComponent} [beforeComponent=undefined] - insert before this component (optional)
	 * @returns {BaseComponent}
	 */
	addComponent(type, name, beforeComponent) {
		return this._components.add(type, name, beforeComponent);
	}

	/**
	 * Create an item using with the given name and return it.
	 * @template {BaseComponent} Class
	 * @param {import('../utils/collection').TypeConstructor<Class, Entity>} ClassConstructor
	 * @param {string} [name=''] - the name of the item to be created
	 * @param {BaseComponent} [beforeComponent] - insert the item before this item
	 * @returns {Class}
	 */
	addComponentByClass(ClassConstructor, name, beforeComponent) {
		return this._components.addByClass(ClassConstructor, name, beforeComponent);
	}

	/**
	 * Removes a component.
	 * @param {BaseComponent|string|number} componentOrNameOrIndex - the component, name, or index to remove.
	 */
	removeComponent(componentOrNameOrIndex) {
		this._components.remove(componentOrNameOrIndex);
	}

	/**
	 * Removes all components.
	 */
	clearComponents() {
		this._components.clear();
	}

	// CONTROLLERS

	/**
	 * Gets the number of controllers.
	 * @returns {number}
	 */
	getNumControllers() {
		return this._controllers.size;
	}

	/**
	 * Gets the controller from either the name or the index. It returns null if the controller is not found.
	 * @param {string|number} nameOrIndex
	 * @returns {BaseController | null}
	 */
	getController(nameOrIndex) {
		return this._controllers.get(nameOrIndex);
	}

	/**
	 * Gets the index'th controller of the given type. The index is base 0. Returns null if none is found.
	 * @param {string} type
	 * @param {number} [index=0]
	 * @returns {BaseController | null}
	 */
	getControllerByType(type, index = 0) {
		return this._controllers.getByType(type, index);
	}

	/**
	 * Gets the index'th controller of the given type. The index is base 0. Returns null if none is found.
 	 * @template {BaseController} Class
	 * @param {import('../utils/collection').TypeConstructor<Class, Entity>} ControllerClass
	 * @param {number} [index=0]
	 * @returns {Class | null}
	 */
	getControllerByClass(ControllerClass, index = 0) {
		return this._controllers.getByClass(ControllerClass, index);
	}

	/**
	 * Adds a controller.
	 * @param {string} type - type of the controller
	 * @param {string} [name=''] - name of the controller (optional)
	 * @param {BaseController} [beforeController=undefined] - insert before this controller (optional)
	 * @returns {BaseController}
	 */
	addController(type, name, beforeController) {
		const controller = this._controllers.add(type, name, beforeController);
		this.__updateCoverage();
		return controller;
	}

	/**
	 * Create an item using with the given name and return it.
	 * @template {BaseController} Class
	 * @param {import('../utils/collection').TypeConstructor<Class, Entity>} ClassConstructor
	 * @param {string} [name=''] - the name of the item to be created
	 * @param {BaseController} [beforeController] - insert the item before this item
	 * @returns {Class}
	 */
	addControllerByClass(ClassConstructor, name, beforeController) {
		return this._controllers.addByClass(ClassConstructor, name, beforeController);
	}

	/**
	 * Removes a controller.
	 * @param {BaseController|string|number} controllerOrNameOrIndex - the controller, name, or index to remove.
	 */
	removeController(controllerOrNameOrIndex) {
		this._controllers.remove(controllerOrNameOrIndex);
		this.__updateCoverage();
	}

	/**
	 * Removes all controllers.
	 */
	clearControllers() {
		this._controllers.clear();
		this.__updateCoverage();
	}

	// CAMERA DEPENDENT POSITION AND RADII

	/**
	 * Gets the camera-space position relative to the camera (frozen). Returns Vector.NaN if there is no position for that camera.
	 * @param {CameraComponent} camera
	 * @returns {Vector3}
	 */
	getCameraSpacePosition(camera) {
		const pos = this._cameraSpacePosition.get(camera);
		if (pos !== undefined) {
			return pos;
		}
		return Vector3.NaN;
	}

	/**
	 * Gets the normal-space position relative to the camera (frozen). Returns Vector.NaN if there is no position for that camera.
	 * @param {CameraComponent} camera
	 * @returns {Vector3}
	 */
	getNormalSpacePosition(camera) {
		const pos = this._normalSpacePosition.get(camera);
		if (pos !== undefined) {
			return pos;
		}
		return Vector3.NaN;
	}

	/**
	 * Gets the pixel-space position (frozen). Returns Vector.NaN if there is no position for that camera.
	 * @param {CameraComponent} camera
	 * @returns {Vector2}
	 */
	getPixelSpacePosition(camera) {
		const pos = this._pixelSpacePosition.get(camera);
		if (pos !== undefined) {
			return pos;
		}
		return Vector2.NaN;
	}

	/**
	 * Gets the normal-space occlusion radius. Returns Number.NaN if there is no occlusion radius for that camera.
	 * @param {CameraComponent} camera
	 * @returns {number}
	 */
	getNormalSpaceOcclusionRadius(camera) {
		if (this._extentsRadius !== 0) {
			// Since normal-space radii are proportional to entity-space radii,
			// we can just use the normal-space extents radius and multiply the factor.
			return this.getNormalSpaceExtentsRadius(camera) * this._occlusionRadius / this._extentsRadius;
		}
		else {
			return 0;
		}
	}

	/**
	 * Gets the normal-space extents radius. Returns Number.NaN if there is no extents radius for that camera.
	 * @param {CameraComponent} camera
	 * @returns {number}
	 */
	getNormalSpaceExtentsRadius(camera) {
		const radius = this._normalSpaceExtentsRadius.get(camera);
		if (radius !== undefined) {
			return radius;
		}
		else {
			return Number.NaN;
		}
	}

	/**
	 * Gets the pixel-space occlusion radius. Returns Number.NaN if there is no occlusion radius for that camera.
	 * @param {CameraComponent} camera
	 * @returns {number}
	 */
	getPixelSpaceOcclusionRadius(camera) {
		if (this._extentsRadius !== 0) {
			// Since pixel-space radii are proportional to entity-space radii,
			// we can just use the pixel-space extents radius and multiply the factor.
			return this.getPixelSpaceExtentsRadius(camera) * this._occlusionRadius / this._extentsRadius;
		}
		else {
			return 0;
		}
	}

	/**
	 * Gets the pixel-space extents radius. Returns Number.NaN if there is no extents radius for that camera.
	 * @param {CameraComponent} camera
	 * @returns {number}
	 */
	getPixelSpaceExtentsRadius(camera) {
		const radius = this._pixelSpaceExtentsRadius.get(camera);
		if (radius !== undefined) {
			return radius;
		}
		else {
			return Number.NaN;
		}
	}

	/**
	 * Gets the greatest pixel-space occlusion radius in all cameras.
	 * @returns {number}
	 */
	getGreatestPixelSpaceOcclusionRadius() {
		if (this._extentsRadius !== 0) {
			// Since pixel-space radii are proportional to entity-space radii,
			// we can just use the pixel-space extents radius and multiply the factor.
			return this.getGreatestPixelSpaceExtentsRadius() * this._occlusionRadius / this._extentsRadius;
		}
		else {
			return 0;
		}
	}

	/**
	 * Gets the greatest pixel-space extents radius in all cameras.
	 * @returns {number}
	 */
	getGreatestPixelSpaceExtentsRadius() {
		return this._greatestPixelSpaceExtentsRadius;
	}

	/**
	 * Gets the least camera depth in the scene.
	 * @returns {number}
	 */
	getLeastCameraDepth() {
		return this._leastCameraDepth;
	}

	/**
	 * Sets the camera dependent variables.
	 * @param {CameraComponent} camera
	 * @param {Vector3} newCameraSpacePosition
	 * @param {number} cameraDepth
	 * @internal
	 */
	__setCameraDependentVariables(camera, newCameraSpacePosition, cameraDepth) {
		// Get the viewport using the camera, and if none, do nothing.
		const viewport = camera.getViewport();
		if (viewport === null) {
			return;
		}

		// Do the camera-space position.
		let cameraSpacePosition = this._cameraSpacePosition.get(camera);
		if (cameraSpacePosition === undefined) {
			cameraSpacePosition = new Vector3();
			this._cameraSpacePosition.set(camera, cameraSpacePosition);
		}
		else {
			cameraSpacePosition.thaw();
		}
		cameraSpacePosition.copy(newCameraSpacePosition);
		cameraSpacePosition.freeze();

		// Do the normal-space position.
		let normalSpacePosition = this._normalSpacePosition.get(camera);
		if (normalSpacePosition === undefined) {
			normalSpacePosition = new Vector3();
			this._normalSpacePosition.set(camera, normalSpacePosition);
		}
		else {
			normalSpacePosition.thaw();
		}
		camera.getNormalSpacePositionFromCameraSpacePosition(normalSpacePosition, cameraSpacePosition);
		normalSpacePosition.freeze();

		// Do the pixel-space position.
		let pixelSpacePosition = this._pixelSpacePosition.get(camera);
		if (pixelSpacePosition === undefined) {
			pixelSpacePosition = new Vector2();
			this._pixelSpacePosition.set(camera, pixelSpacePosition);
		}
		else {
			pixelSpacePosition.thaw();
		}
		viewport.getPixelSpacePositionFromNormalSpacePosition(pixelSpacePosition, normalSpacePosition);
		pixelSpacePosition.freeze();

		// Do the normal-space extents radius.
		const normalSpaceExtentsRadius = camera.getNormalSpaceRadiusFromRadius(this._extentsRadius, cameraSpacePosition.magnitude());
		this._normalSpaceExtentsRadius.set(camera, normalSpaceExtentsRadius);

		// Do the pixel-space extents radius.
		const pixelSpaceExtentsRadius = viewport.getPixelSpaceRadiusFromNormalSpaceRadius(normalSpaceExtentsRadius);
		this._pixelSpaceExtentsRadius.set(camera, pixelSpaceExtentsRadius);

		// Do the camera depth.
		this._cameraDepths.set(camera, cameraDepth);
	}

	/**
	 * Removes the camera from any camera-dependent variables. Called during camera clean up.
	 * @param {CameraComponent} camera
	 * @internal	*/
	__removeCameraDependents(camera) {
		this._cameraSpacePosition.delete(camera);
		this._normalSpacePosition.delete(camera);
		this._pixelSpacePosition.delete(camera);
		this._normalSpaceExtentsRadius.delete(camera);
		this._pixelSpaceExtentsRadius.delete(camera);
		this._cameraDepths.delete(camera);
		for (let i = 0; i < this._components.size; i++) {
			this._components.get(i).__removeCameraDependentsBase(camera);
		}
	}

	// GET

	/**
	 * Gets the component or controller described in the parameters. It is a shortcut function to make things easier on the user. It returns undefined if it is not found.
	 * @param {string} componentOrControllerType - the type of the component or controller [optional]
	 * @param {number} [componentOrControllerTypeIndex=0] - the index of the type, in case there are more than one of the same type [optional]
	 * @returns {BaseComponent|BaseController}
	 */
	get(componentOrControllerType, componentOrControllerTypeIndex = 0) {
		const component = this._components.getByType(componentOrControllerType, componentOrControllerTypeIndex);
		if (component !== null) {
			return component;
		}
		else {
			return this._controllers.getByType(componentOrControllerType, componentOrControllerTypeIndex);
		}
	}

	// LOADING

	/**
	 * Returns a new promise that resolves when every component is loaded.
	 * @returns {Promise<void>}
	 */
	getLoadedPromise() {
		const promises = [];
		if (this.isEnabled()) {
			for (let i = 0; i < this._controllers.size; i++) {
				promises.push(this._controllers.get(i).getLoadedPromise());
			}
			for (let i = 0; i < this._components.size; i++) {
				promises.push(this._components.get(i).getLoadedPromise());
			}
		}
		return Promise.all(promises).then();
	}

	/**
	 * Converts the entity to a nice string.
	 * @override
	 * @returns {string}
	 */
	toString() {
		return this.getName();
	}

	// CLEANUP

	/**
	 * Destroys the entity resources.
	 * @override
	 * @internal
	 */
	__destroy() {
		// Set the destroyed flag to true.
		this._destroyed = true;

		// Call super.
		super.__destroy();

		// Disconnect all of the children from this.
		for (let i = 0; i < this._children.length; i++) {
			this._children[i].setParent(null);
		}
		// Disconnect this from its parent.
		if (this._parent !== null) {
			this.setParent(null);
		}
		// Destroy the controllers.
		this._controllers.__destroy();
		// Destroy the components.
		this._components.__destroy();
	}

	// MAIN LOOP FUNCTIONS

	/**
	 * Updates the parents of the entity via the controllers.
	 * @param {number} currentTime
	 * @internal
	 */
	__updateParent(currentTime) {
		const parentName = this.getParentAtTime(currentTime);
		if (parentName !== '') {
			const parent = this.getScene().getEntity(parentName);
			if (parent !== this._parent) {
				this.setParent(parent);
			}
		}
		else if (this._parent !== null) {
			this.setParent(null);
		}

		if (this._parent !== this._lastParent) {
			// Notify the controller dependency graph that it needs resorting.
			this.getScene().getControllerDependencyGraph().needsSorting();
			this._lastParent = this._parent;
		}
	}

	/**
	 * Updates the flags that are true if the entity's coverages contain the current time.
	 * @param {number} currentTime
	 * @internal
	 */
	__updateIsInCoverages(currentTime) {
		this._isInPositionCoverage = this._positionCoverage.contains(currentTime);
		this._isInOrientationCoverage = this._orientationCoverage.contains(currentTime);

		// Update the component load states, which depends on the position and orientation coverage.
		for (let i = 0; i < this._components.size; i++) {
			this._components.get(i).__updateLoadState();
		}
	}

	/**
	 * Updates the greatest pixel-space radii and the camera-non-specific parts of the components.
	 * @internal
	 */
	__updateVisuals() {
		// Update the greatest pixel extents radius for use by components and controllers.
		this._greatestPixelSpaceExtentsRadius = 0.0;
		for (let i = 0, l = this._pixelSpaceExtentsRadius.size; i < l; i++) {
			const pixelSpaceExtentsRadius = this._pixelSpaceExtentsRadius.getAt(i).value;
			if (this._greatestPixelSpaceExtentsRadius < pixelSpaceExtentsRadius) {
				this._greatestPixelSpaceExtentsRadius = pixelSpaceExtentsRadius;
			}
		}

		// Update the least camera depth.
		this._leastCameraDepth = Number.MAX_SAFE_INTEGER;
		for (let i = 0, l = this._cameraDepths.size; i < l; i++) {
			const cameraDepth = this._cameraDepths.getAt(i).value;
			this._leastCameraDepth = Math.min(cameraDepth, this._leastCameraDepth);
		}

		// Updates the camera-independent parts of the components.
		for (let i = 0, l = this._components.size; i < l; i++) {
			const component = this._components.get(i);
			if (component.isEnabled()) {
				component.__updateBase();
			}
		}
	}

	/**
	 * It traverses the scene graph, starting with the camera, setting the camera-space positions, and updating the occlusion.
	 * @param {CameraComponent} camera - the camera we're rendering
	 * @param {Entity} comingFrom - the entity we just came from
	 * @param {boolean} comingFromChild - true if comingFrom is a child of this
	 * @param {number} cameraDepth - the distance in the scene from the camera
	 * @internal
	 */
	__updateCameraVariables(camera, comingFrom, comingFromChild, cameraDepth) {
		if (this.isEnabled()) {
			// Update this camera-space position relative to the child that we're coming from.
			const cameraSpacePosition = Vector3.pool.get();
			if (this === camera.getEntity()) {
				// We're the camera, so we're always at the camera-space origin.
				cameraSpacePosition.set(0, 0, 0);
			}
			else if (comingFromChild) {
				// We're moving up the scene graph toward the root, so we get the child's world position and add the negative position.
				cameraSpacePosition.sub(comingFrom.getCameraSpacePosition(camera), comingFrom.getPosition());
			}
			else {
				// We're moving down the scene graph, so we just add on the local to the parent world position.
				cameraSpacePosition.add(comingFrom.getCameraSpacePosition(camera), this._state.position);
			}
			this.__setCameraDependentVariables(camera, cameraSpacePosition, cameraDepth);

			// Add the entity to the occluding entities if its radius is large enough.
			if (this._canOcclude && this.getPixelSpaceOcclusionRadius(camera) >= 1) {
				camera.__addToOccludingEntities(this);
			}
			Vector3.pool.release(cameraSpacePosition);

			// Updates the camera-dependent parts of the components.
			for (let i = 0, l = this._components.size; i < l; i++) {
				const component = this._components.get(i);
				if (component !== camera && component.isEnabled()) {
					component.__updateCameraVariablesBase(camera);
				}
			}
		}

		// Update the parent if we're still going up. Update the parent position.
		if (this._parent !== null && (comingFromChild || this === camera.getEntity())) {
			this._parent.__updateCameraVariables(camera, this, true, cameraDepth + 1);
		}

		// Update the children.
		for (let i = 0, l = this._children.length; i < l; i++) {
			const child = this._children[i];
			if (child === comingFrom) {
				continue; // don't go back down a child that we've already updated.
			}
			child.__updateCameraVariables(camera, this, false, cameraDepth + 1);
		}
	}

	/**
	 * Prepares all of its components for rendering.
	 * @param {CameraComponent} camera - the camera we're rendering
	 * @internal
	 */
	__prepareForRender(camera) {
		// Prepare the components for render.
		for (let i = 0, l = this._components.size; i < l; i++) {
			const component = this._components.get(i);
			if (!('__render' in component)) {
				component.__prepareForRenderBase(camera);
			}
		}
	}
}

class EntityState {
	constructor() {
		/**
		 * The position of the entity relative to its parent's position.
		 * @type {Vector3}
		 */
		this.position = new Vector3(Number.NaN, Number.NaN, Number.NaN);
		this.position.freeze();

		/**
		* The velocity of the entity.
		* @type {Vector3}
		*/
		this.velocity = new Vector3(Number.NaN, Number.NaN, Number.NaN);
		this.velocity.freeze();

		/**
		* The orientation of the entity. Not relative to parent.
		* @type {Quaternion}
		*/
		this.orientation = new Quaternion(Number.NaN, Number.NaN, Number.NaN, Number.NaN);
		this.orientation.freeze();

		/**
		* The rotational velocity of the entity. Not relative to parent.
		* @type {Vector3}
		*/
		this.angularVelocity = new Vector3(Number.NaN, Number.NaN, Number.NaN);
		this.angularVelocity.freeze();
	}
}

// Functions for the start time to parent list sorting.

/**
 * Returns true if a < b.
 * @param {[number, string]} a
 * @param {[number, string]} b
 */
function isStartTimeLess(a, b) {
	return a[0] < b[0];
}

/**
 * Returns true if a === b.
 * @param {[number, string]} a
 * @param {[number, string]} b
 */
function isStartTimeEqual(a, b) {
	return a[0] === b[0];
}

/**
 * Returns true if a < b.
 * @param {[number, string]} a
 * @param {number} b
 */
function isStartTimeLessThanTime(a, b) {
	return a[0] < b;
}
