import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import globalRefs from '../managers/globalRefs';
import { formatDate, formatTime } from '../helpers/tools';

const DateTimeDisplay = ({ inline = false, updateInterval = 100 }) => {
	const [formattedDate, setFormattedDate] = useState(null);
	const [formattedTime, setFormattedTime] = useState(null);

	useEffect(() => {
		const updateDatetime = () => {
			// Get the current datetime from
			const { getManager } = globalRefs;
			const localDate = getManager('time').getDatetime();

			// Set the formatted date and time.
			setFormattedDate(formatDate(localDate));
			setFormattedTime(formatTime(localDate));
		};

		const interval = setInterval(updateDatetime, updateInterval);

		return () => {
			clearInterval(interval);
		};
	}, [updateInterval]);

	return (
		<div className='current-time'>
			{!inline && <div className='current-time-title'>الوقت الحالي</div>}
			<div className={`time-display ${inline ? 'satellite-now-font' : ''}`}>
				{formattedDate
					&& (
						<>
							<div className='current-date'>{formattedDate}</div>
							{formattedTime && <div className='time-separator'>·</div>}
						</>
					)}
				{formattedTime && <div className='current-time'>{formattedTime}</div>}
			</div>
		</div>
	);
};

DateTimeDisplay.propTypes = {
	inline: PropTypes.bool,
	updateInterval: PropTypes.number
};

export default DateTimeDisplay;
