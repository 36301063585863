/** @module pioneer-scripts */
import { Entity } from '../entity';

Entity.register({
	adrastea: {
		groups: ['jupiter', 'moons', 'amalthea', 'inner', 'regular'],
		radius: 8.2,
		label: 'Adrastea',
		parents: [
			[Number.NEGATIVE_INFINITY, 'jupiter']
		],
		trail: {
			length: undefined,
			color: [0.95, 0.71, 0.64, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_1/generic_asteroid_1.gltf',
			scale: [8.2, 8.2, 8.2],
			shadowEntities: ['jupiter']
		},
		controllers: [{
			type: 'dynamo',
			url: 'adrastea/jupiter/orb'
		}, {
			type: 'dynamo',
			url: 'adrastea/ori'
		}]
	},
	amalthea: {
		groups: ['jupiter', 'moons', 'amalthea', 'inner', 'regular'],
		radius: 83.5,
		label: 'Amalthea',
		parents: [
			[Number.NEGATIVE_INFINITY, 'jupiter']
		],
		trail: {
			length: undefined,
			color: [0.95, 0.71, 0.64, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [83.5, 83.5, 83.5],
			shadowEntities: ['jupiter']
		},
		controllers: [{
			type: 'dynamo',
			url: 'amalthea/jupiter/orb'
		}, {
			type: 'dynamo',
			url: 'amalthea/ori'
		}]
	},
	callisto: {
		groups: ['jupiter', 'moons', 'main', 'galilean', 'regular'],
		radius: 2410.3,
		label: 'Callisto',
		parents: [
			[Number.NEGATIVE_INFINITY, 'jupiter']
		],
		trail: {
			length: undefined,
			color: [0.95, 0.71, 0.64, 0.7]
		},
		spheroid: {
			equatorialRadius: 2410.3,
			polarRadius: 2410.3,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'callisto/color_$SIZE_$FACE.png',
					sizes: [4, 512]
				}
			},
			shadowEntities: ['jupiter', 'io', 'europa', 'ganymede']
		},
		controllers: [{
			type: 'dynamo',
			url: 'callisto/jupiter/orb'
		}, {
			type: 'dynamo',
			url: 'callisto/ori'
		}]
	},
	europa: {
		groups: ['jupiter', 'moons', 'main', 'galilean', 'regular'],
		radius: 1569.0,
		label: 'Europa',
		parents: [
			[Number.NEGATIVE_INFINITY, 'jupiter']
		],
		trail: {
			length: undefined,
			color: [0.95, 0.71, 0.64, 0.7]
		},
		spheroid: {
			equatorialRadius: 1569.0,
			polarRadius: 1569.0,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'europa/color_$SIZE_$FACE.png',
					sizes: [4, 512, 1024]
				}
			},
			shadowEntities: ['jupiter', 'io', 'callisto', 'ganymede']
		},
		controllers: [{
			type: 'dynamo',
			url: 'europa/jupiter/orb'
		}, {
			type: 'dynamo',
			url: 'europa/ori'
		}]
	},
	ganymede: {
		groups: ['jupiter', 'moons', 'main', 'galilean', 'regular'],
		radius: 2634.1,
		label: 'Ganymede',
		parents: [
			[Number.NEGATIVE_INFINITY, 'jupiter']
		],
		trail: {
			length: undefined,
			color: [0.95, 0.71, 0.64, 0.7]
		},
		spheroid: {
			equatorialRadius: 2634.1,
			polarRadius: 2634.1,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'ganymede/color_$SIZE_$FACE.png',
					sizes: [4, 512]
				}
			},
			shadowEntities: ['jupiter', 'io', 'europa', 'callisto']
		},
		controllers: [{
			type: 'dynamo',
			url: 'ganymede/jupiter/orb'
		}, {
			type: 'dynamo',
			url: 'ganymede/ori'
		}]
	},
	io: {
		groups: ['jupiter', 'moons', 'main', 'galilean', 'regular'],
		radius: 1821.3,
		label: 'Io',
		parents: [
			[Number.NEGATIVE_INFINITY, 'jupiter']
		],
		trail: {
			length: undefined,
			color: [0.95, 0.71, 0.64, 0.7]
		},
		spheroid: {
			equatorialRadius: 1821.3,
			polarRadius: 1821.3,
			planetographic: false
		},
		spheroidLOD: {
			features: ['shadowEntities'],
			textures: {
				color: {
					url: 'io/color_$SIZE_$FACE.png',
					sizes: [4, 512, 1024]
				}
				// I believe there is a heightmap version as well, but the min-to-max radius is not noticable
			},
			shadowEntities: ['jupiter', 'europa', 'ganymede', 'callisto']
		},
		controllers: [{
			type: 'dynamo',
			url: 'io/jupiter/orb'
		}, {
			type: 'dynamo',
			url: 'io/ori'
		}]
	},
	metis: {
		groups: ['jupiter', 'moons', 'amalthea', 'inner', 'regular'],
		radius: 21.5,
		label: 'Metis',
		parents: [
			[Number.NEGATIVE_INFINITY, 'jupiter']
		],
		trail: {
			length: undefined,
			color: [0.95, 0.71, 0.64, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_2/generic_asteroid_2.gltf',
			scale: [21.5, 21.5, 21.5],
			shadowEntities: ['jupiter']
		},
		controllers: [{
			type: 'dynamo',
			url: 'metis/jupiter/orb'
		}, {
			type: 'dynamo',
			url: 'metis/ori'
		}]
	},
	thebe: {
		groups: ['jupiter', 'moons', 'amalthea', 'inner', 'regular'],
		radius: 49.0,
		label: 'Thebe',
		parents: [
			[Number.NEGATIVE_INFINITY, 'jupiter']
		],
		trail: {
			length: undefined,
			color: [0.95, 0.71, 0.64, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/generic/asteroid_3/generic_asteroid_3.gltf',
			scale: [49, 49, 49],
			shadowEntities: ['jupiter']
		},
		controllers: [{
			type: 'dynamo',
			url: 'thebe/jupiter/orb'
		}, {
			type: 'dynamo',
			url: 'thebe/ori'
		}]
	}
});
