/** @module pioneer */
import {
	Freezable,
	Pool
} from '../internal';

/** An interval with a minimum and maximum. */
export class Interval extends Freezable {
	/**
	 * Pool for temporary variables.
	 * @returns {Pool<Interval>}
	 */
	static get pool() {
		return _pool;
	}

	/**
	 * Infinite Interval
	 * @returns {Interval}
	 */
	static get Infinite() {
		return _infinite;
	}

	/**
	 * Constructor.
	 * @param {number} min - the minimum of the interval
	 * @param {number} max - the maximum of the interval
	 */
	constructor(min = 0, max = 0) {
		super();

		/**
		 * minumum
		 * @type {number}
		 * @private
		 */
		this._min = min;

		/**
		 * maximum
		 * @type {number}
		 * @private
		 */
		this._max = max;
	}

	/**
	 * Gets the minimum.
	 * @returns {number}
	 */
	get min() {
		return this._min;
	}

	/**
	 * Sets the minimum.
	 * @param {number} min
	 */
	set min(min) {
		this.throwIfFrozen();
		this._min = min;
	}

	/**
	 * Gets the maximum.
	 * @returns {number}
	 */
	get max() {
		return this._max;
	}

	/**
	 * Sets the maximum
	 * @param {number} max
	 */
	set max(max) {
		this.throwIfFrozen();
		this._max = max;
	}

	/**
	 * Returns a nicely formed string.
	 * @returns {string}
	 * @override
	 */
	toString() {
		return '[' + this._min + ', ' + this._max + ']';
	}

	/**
	 * Sets this to a.
	 * @param {Interval} a - the source interval
	 */
	copy(a) {
		this.throwIfFrozen();
		this._min = a._min;
		this._max = a._max;
	}

	/**
	 * Sets this to the parameters.
	 * @param {number} min - the minimum to set
	 * @param {number} max - the maximum to set
	 */
	set(min, max) {
		this.throwIfFrozen();
		this._min = min;
		this._max = max;
	}

	/**
	 * Returns the length (max - min). If this represents a integer interval, you'll need to add one.
	 * @returns {number}
	 */
	length() {
		return this._max - this._min;
	}

	/**
	 * Returns the value clamped to within this.
	 * @param {number} value - the value to clamp
	 * @returns {number}
	 */
	clamp(value) {
		return Math.min(Math.max(this._min, value), this._max);
	}

	/**
	 * Expands this to contain the value.
	 * @param {number} value
	 */
	expandTo(value) {
		this.throwIfFrozen();
		this._min = Math.min(this._min, value);
		this._max = Math.max(this._max, value);
	}

	/**
	 * Returns true if value is within this.
	 * @param {number} value - the value to check
	 * @returns {boolean}
	 */
	contains(value) {
		return this._min <= value && value < this._max;
	}

	/**
	 * Returns true if this intersects (not just touches) the other interval.
	 * @param {Interval} interval - the interval to check
	 * @returns {boolean}
	 */
	intersects(interval) {
		return this._min < interval._max && interval._min < this._max;
	}

	/**
	 * Sets this to the intersection of intervals a and b.
	 * @param {Interval} a
	 * @param {Interval} b
	*/
	intersection(a, b) {
		this.throwIfFrozen();
		this._min = Math.max(a._min, b._min);
		this._max = Math.min(a._max, b._max);
		if (this._max < this._min) {
			this._max = this._min;
		}
	}

	/**
	 * Sets this to the union of intervals a and b.
	 * @param {Interval} a
	 * @param {Interval} b
	 */
	union(a, b) {
		this.throwIfFrozen();
		this._min = Math.min(a._min, b._min);
		this._max = Math.max(a._max, b._max);
	}
}

/**
 * @type {Pool<Interval>}
 */
const _pool = new Pool(Interval);

/**
 * Infinite Interval
 * @type {Interval}
 */
const _infinite = new Interval(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY);
_infinite.freeze();
