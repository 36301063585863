import React from 'react';
import CloseButton from './close_button';

import EarthIconSVG from '../../www/assets/images/earth_icon.svg';
import ArrowRight from '../../www/assets/images/arrow-nav-chevron.svg';
import NasaSVG from '../../www/assets/images/nasa.svg';
import MagnifyingGlassSVG from '../../www/assets/images/magnifying_glass.svg';
import ExternalLinkSVG from '../../www/assets/images/external_link.svg';

import { kioskStore, appStore, uiStore, poiStore, gmapStore, videoStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';

import '../../www/assets/css/header.css';
import { Config } from '../config/config';
import ar from '../languages/ar';
import en from '../languages/en';

/**
 * MAIN MENU ITEMS
 * All main menu item details and submenus should be able to be changed in this object.
 *
 *
 * Notes:
 * Classes should always have Cls  in the var name, and Def if they are default values
 * Submenu details are in the childItems array
 * slug is the part of the URL after the slash, eg '/evidence' in https://climate.nasa.gov/evidence
 * Bootstrap class details can be found here: https://hackerthemes.com/bootstrap-cheatsheet/
 *
 */
const mainMenuItems = [
	{
		text: 'Back to Eyes on the Earth',
		align: 'center',
		itemCls: 'close-item',
		link: {
			clickFn: 'mainMenuToggle',
			linkCls: 'back-to'
		}
	},
	{
		text: 'GLOBAL CLIMATE CHANGE HOME',
		align: 'center',
		itemCls: 'close-item',
		link: {
			slug: '',
			linkCls: 'hover-style'
		}
	},
	{
		text: 'FACTS',
		align: 'left',
		itemCls: 'mt-0',
		itemId: 'facts',
		link: {
			slug: 'evidence',
			linkCls: 'hover-style'
		},
		childItems: [
			{
				text: 'Causes',
				slug: 'causes'
			},
			{
				text: 'Effects',
				slug: 'effects'
			},
			{
				text: 'Scientific Consensus',
				slug: 'scientific-consensus'
			},
			{
				text: 'Vital Signs',
				slug: 'vital-signs/carbon-dioxide'
			},
			{
				text: 'Questions (FAQ)',
				slug: 'faq'
			}
		]
	},
	{
		text: 'ARTICLES',
		align: 'left',
		itemId: 'articles',
		link: {
			slug: 'news',
			linkCls: 'hover-style'
		},
		childItems: [
			{
				text: 'Blog',
				slug: 'blog'
			}
		]
	},
	{
		text: 'SOLUTIONS',
		align: 'left',
		itemId: 'solutions',
		link: {
			slug: 'solutions/resources',
			linkCls: 'hover-style'
		},
		childItems: [
			{
				text: 'Government Resources',
				slug: 'solutions/resources'
			}
		]
	},
	{
		text: 'EXPLORE',
		align: 'left',
		itemId: 'explore',
		link: {
			slug: 'climate_resource_center/interactives',
			linkCls: 'hover-style'
		},
		childItems: [
			{
				text: 'Images of Change',
				slug: 'images-of-change'
			},
			{
				text: 'Earth Minute Videos',
				slug: 'climate_resource_center/earthminute'
			},
			{
				text: 'Interactives',
				slug: 'climate_resource_center/interactives'
			},
			{
				text: 'Beautiful Earth Gallery',
				slug: 'beautiful-earth'
			},
			{
				text: 'Earth 360',
				slug: 'climate_resource_center/earth-360'
			}
		]
	},
	{
		text: 'MULTIMEDIA',
		align: 'left',
		itemId: 'multimedia',
		link: {
			slug: 'resources/graphics-and-multimedia',
			linkCls: 'hover-style'
		},
		childItems: [
			{
				text: 'For Educators',
				slug: 'for-educators'
			},
			{
				text: 'For Media',
				slug: 'for-media'
			},
			{
				text: 'Climate Kids',
				slug: '',
				base: 'http://climatekids.nasa.gov',
				external: true
			},
			{
				text: 'Global Warming vs. Climate Change',
				slug: 'global-warming-vs-climate-change'
			},
			{
				text: 'En español',
				slug: 'en-espanol'
			}
		]
	},
	{
		text: 'NASA SCIENCE',
		align: 'left',
		itemId: 'nasa-science',
		link: {
			slug: '',
			base: 'science',
			linkCls: 'hover-style'
		},
		childItems: [
			{
				text: 'Earth System Science',
				slug: 'nasa_science/science'
			},
			{
				text: 'Earth Science Missions',
				slug: 'nasa_science/missions'
			},
			{
				text: 'History',
				slug: 'nasa_science/history'
			}
		]
	}
];

const mainMenuDefaults = {
	classes: {
		// menu item classes
		containerDefCls: 'main-menu-overlay scrollable',
		contentDefCls: 'main-menu-content',
		listDefCls: 'container mb-0 set-margin',
		itemDefCls: 'list-style',
		linkDefCls: 'pb-4',

		// submenu item classes
		inputDefCls: 'main-menu-toggle',
		labelDefCls: 'arrow',
		subListDefCls: 'section pb-4',
		subItemDefCls: 'sub-style',
		externalDefCls: 'ml-1 external_link_icon'
	},
	target: '_top'
};

const socialItems = [
	{
		title: 'Follow on Twitter',
		href: '//twitter.com/NASAClimate',
		linkCls: 'button_twitter_follow icon pl-2',
		imgCls: 'icon no-drag',
		imgAlt: 'twitter',
		imgSrc: 'assets/images/twitter_icon@2x.png'
	},
	{
		title: 'Follow on Facebook',
		href: 'http://www.facebook.com/NASAClimateChange',
		linkCls: 'button_facebook_follow icon pl-2',
		imgCls: 'icon no-drag',
		imgAlt: 'facebook',
		imgSrc: 'assets/images/facebook_icon@2x.png'
	},
	{
		title: 'Follow on Instagram',
		href: 'http://instagram.com/nasaclimatechange',
		linkCls: 'button_instagram_follow icon pl-2',
		imgCls: 'icon no-drag',
		imgAlt: 'instagram',
		imgSrc: 'assets/images/instagram_icon@2x.png'
	},
	{
		title: 'Follow on RSS',
		href: 'https://climate.nasa.gov/news/rss.xml',
		linkCls: 'button_rss_follow icon pl-2',
		imgCls: 'icon no-drag',
		imgAlt: 'rss',
		imgSrc: 'assets/images/rss_icon@2x.png'
	}
];

/**
 * Function for building URLs - puts together the base and the slug
 * The base will default to the climate base URL unless specified
 * There will only be no base if anything falsey is passed as the base arg
 * @param {string} slug the part after the slash
 * @param {Record<string, string>} baseUrls
 * @param {string} base the main part of the url
 * @returns {string} the full href link
 */
const getHrefFromSlug = (slug, base = 'climate') => {
	if (slug == null) { // purposely using double equals -> https://stackoverflow.com/questions/2647867/how-can-i-determine-if-a-variable-is-undefined-or-null
		return null;
	}

	let baseUrl = base;
	if (base === 'climate') {
		baseUrl = Config.baseClimateUrl;
	} else if (base === 'science') {
		baseUrl = Config.baseScienceUrl;
	}

	return `${baseUrl}/${slug}`;
};

/**
 * There are several ways to determine if an anchor tag should be external, ie. target='_blank' opening in new tab
 * Does the config say external: true/false, then always prioritize (NOTE: currently, only external: true will show the external icon)
 * Otherwise, does the route of the current URL match the base of the href?
 *     eg. We're on climate.nasa.gov/earth-now, linking to climate.nasa.gov/resources, then no
 *     eg. We're on eyes.nasa.gov/apps/earth, linking to climate.nasa.gov/resources, then yes
 * Otherwise, default.
 * @param {string} href
 * @param {boolean} external
 * @returns {string|null}
 */
const getTargetFromHref = (href, external) => {
	if (typeof href !== 'string') {
		return null;
	}

	const { location, document } = window;

	const referrer = location.ancestorOrigins?.[location.ancestorOrigins.length - 1] || document.referrer || location.origin;
	const referrerStripped = referrer.endsWith('/') ? referrer.slice(0, -1) : referrer;

	const isExternal = external ?? !(referrerStripped && href?.startsWith(referrerStripped));
	return isExternal ? '_blank' : mainMenuDefaults.target;
};

class Header extends React.Component {
	// no constructor approach to setting initial state
	state = {
		mobileMenu: false,
		cityNamesVisible: false,
		mainMenu: false,
		search: ''
	};

	// lifecycle methods
	componentDidMount() {
		this.baseUrls = {
			climate: window.config.apiUrl,
			science: 'https://science.nasa.gov'
		};

		window.addEventListener('OpenMobileMenu', this.openMenu);

		this.appUnsubscribe = appStore.subscribeAll(() => this.forceUpdate());
	}

	componentWillUnmount() {
		typeof this.appUnsubscribe === 'function' && this.appUnsubscribe();

		window.removeEventListener('OpenMobileMenu', this.openMenu);
	}

	openMenu = () => {
		this.setState({ mobileMenu: true }, () => {
			const classList = document.getElementById('main-dropdown').className;
			// Todo: React isnt aware of the DOM or it's state. - #4221
			// We can either use refs, or conditional classes in the virtual DOM.
			// Discussion here -> https://stackoverflow.com/questions/37273876/reactjs-this-refs-vs-document-getelementbyid
			document.getElementById('main-dropdown').className = `${classList} show`;
		});
	};

	// event handlers
	mobileMenuToggle = () => {
		this.setState(state => ({ mobileMenu: !state.mobileMenu }));
	};

	mainMenuToggle = () => {
		window.scroll(0, 0);
		this.setState(state => ({ mainMenu: !state.mainMenu }));
	};

	searchInput = e => {
		this.setState({ search: e.target.value });
	};

	onFormSubmit = e => {
		e.preventDefault();
		const searchQuery = 'https://climate.nasa.gov/search/?q=' + this.state.search;
		const win = window.open(searchQuery, '_blank');
		win.focus();
	};

	toggleCityNamesState = () => {
		// make sure we also close the mobileMenu
		this.setState(state => ({ cityNamesVisible: !state.cityNamesVisible, mobileMenu: false }));
	};

	toggleEventsState = () => {
		this.setState(state => ({ eventsVisible: !state.eventsVisible, mobileMenu: false }));
	};

	togglePoIState = () => {
		this.setState(state => ({ poiVisible: !state.poiVisible, mobileMenu: false }));
	}

	toggleGmapState = () => {
		this.setState(state => ({ gmapVisible: !state.gmapVisible, mobileMenu: false }));
	}

	resetHomeScreen = () => {
		// const { getManager } = globalRefs;
		// const { maxSessionTime, maxInactivityTime } = kioskStore.stateSnapshot;
		// const kioskIsEnabled = maxSessionTime !== null || maxInactivityTime !== null;

		// getManager('time').setRealTime();
		// kioskIsEnabled ? getManager('route').kioskReset() : getManager('route').goHomeRetainURLQueries();
		// const navigated = getManager('route').navigate('vitalsign?vitalsign=visible_earth&altid=0&animating=f&start=&end=&showOnGlobe=f');
		// poiStore.setGlobalState({ showOnGlobePoI: false });
		// gmapStore.setGlobalState({ showOnGlobeGmap: false });

		// if (!navigated && scene.get('camera').getParent().getName() === 'earth') {
		// 	get('camera').goToEntity(
		// 		'earth',
		// 		{
		// 			latitude: 2.75,
		// 			longitude: 29
		// 		}
		// 	);
		// }
		const { getManager } = globalRefs;
		const { maxSessionTime, maxInactivityTime } = kioskStore.stateSnapshot;
		const kioskIsEnabled = maxSessionTime !== null || maxInactivityTime !== null;

		getManager('time').setRealTime();
		kioskIsEnabled ? getManager('route').kioskReset() : getManager('route').goHomeRetainURLQueries();
	
	};

	openOverlay = type => () => {
		uiStore.setGlobalState({ overlayType: type });
		this.setState({ mobileMenu: false });
	};

	onClickHeaderLogos = e => {
		const { hideExternalLinks } = uiStore.stateSnapshot;

		// To have custom on click events for <a> tag if hiding external links
		e.preventDefault();

		if (hideExternalLinks === 'true') {
			// If hiding external links, direct links to Visible Earth home instead of external sites
			this.resetHomeScreen();
		} else {
			window.open(e.currentTarget.href);
			return false;
		}
	};

	// Restore working dropdowns on main menu when handling multiple clicks
	handleMenuItemCollapse = e => {
		const { currentTarget } = e;
		const { inputDefCls, subListDefCls } = mainMenuDefaults.classes;
		currentTarget.previousElementSibling.className = inputDefCls;
		currentTarget.nextElementSibling.className = subListDefCls;
	};

	// render helpers to iprove readability and allow easier modification
	renderNavBar() {
		const { mobileMenu } = this.state;
		const { isMobile } = appStore.stateSnapshot;
		const { hideExternalLinks: hideExternalLinksFromURL } = uiStore.stateSnapshot;
		const classes = 'navbar navbar-expand-lg navbar-light container-fluid no-gutters';
		this.language = ar;
		const hideMainMenu = hideExternalLinksFromURL === 'true';

		return (
			<nav className={classes}>
				<div className='navbar-brand'>
					<div className='logo'>
						<img src='assets/images/logo 1.png' />
					</div>
				</div>
				<div className='buttons'>
					<button className='earth-button' onClick={this.resetHomeScreen}>
						<div className={!mobileMenu ? 'earth-icon' : 'main-earth'} onClick={this.resetHomeScreen}><EarthIconSVG /></div>
					</button>
					<button className='navbar-toggler no-select' type='button' data-toggle='collapse' data-target='#main-dropdown' aria-controls='main-dropdown' aria-expanded='false' aria-label='Toggle navigation'>
						<span className={!mobileMenu ? 'navbar-toggler-icon' : 'main-earth'} onClick={this.mobileMenuToggle} />
						<CloseButton className={mobileMenu ? 'header-close' : 'main-earth'} responsive onClick={this.mobileMenuToggle} />
					</button>
				</div>
				<div className={`scrollable navbar-collapse justify-content-lg-end pr-lg-4 mt-lg-0 ${!mobileMenu ? 'collapse hide' : 'collapse'}`} id='main-dropdown'>
					<div className={isMobile ? 'navbar-mobile navbar-nav' : 'navbar-nav d-flex d-lg-inline-flex flex-column flex-lg-row align-items-start align-items-lg-center align-content-center'}>
						<div id='home-item' className='menu-item d-none d-lg-inline-block' onClick={this.resetHomeScreen}>
							<a className='nav-item d-none d-lg-flex align-items-center pt-0'>
								<div className='earth-icon main-earth ml-auto'><EarthIconSVG /></div>
								<span id='home-label' className='pl-lg-1 outline'>{this.language.visible_earth}</span>
							</a>
						</div>
						<div className='dropdown menu-item'>
							<a id='vital-link' className='nav-item dropdown-toggle outline' onClick={this.openOverlay('vitals')}>
								{this.language.vital_signs}
								{isMobile && <ArrowRight className='menu-item-svg' />}
							</a>
						</div>
						<div className='dropdown menu-item'>
							<a id='missions-link' className='nav-item dropdown-toggle outline' onClick={this.openOverlay('missions')}>
								{this.language.missions}
								{isMobile && <ArrowRight className='menu-item-svg' />}
							</a>
						</div>
						<div className='dropdown menu-item'>
							<a id='events-link' className='nav-item dropdown-toggle outline' onClick={this.openOverlay('events')}>
								{this.language.events}
								{isMobile && <ArrowRight className='menu-item-svg' />}
							</a>
						</div>
						<div className='dropdown menu-item'>
							<a id='poi-link' className='nav-item dropdown-toggle outline' onClick={this.openOverlay('poi')}>
								{this.language.poi}
								{isMobile && <ArrowRight className='menu-item-svg' />}
							</a>
						</div>
						<div className='dropdown menu-item'>
							<a id='gmap-link' className='nav-item dropdown-toggle outline' onClick={this.openOverlay('gmap')}>
								{this.language.gmap}
								{isMobile && <ArrowRight className='menu-item-svg' />}
							</a>
						</div>
						<div className='dropdown menu-item'>
							<a id='about-link' className='nav-item dropdown-toggle outline' onClick={this.openOverlay('about')}>
								{this.language.about}
								{isMobile && <ArrowRight className='menu-item-svg' />}
							</a>
						</div>
						{
							<div className='dropdown menu-item'>
								{
									isMobile ?
										<a id='mobile-about' className='nav-item outline' onClick={this.openOverlay('about')}>{this.language.more_link}</a>
										:
										<a id='mobile-more' className='nav-item outline' onClick={this.openOverlay('about')}>{this.language.about}</a>
								}
							</div>
						}

						{
							isMobile
								? (
									<>
										<div className='dropdown menu-item'>
											<a id='vital-link' className='nav-item dropdown-toggle outline' onClick={this.openOverlay('settings')}>
												{this.language.settings}
												<ArrowRight className='menu-item-svg' />
											</a>
										</div>
										{/* <div className='dropdown menu-item'>
											<a href='https://climate.nasa.gov/' target='_blank' rel='noopener' className='link'>Global Climate Change Home</a>
										</div> */}
									</>
								)
								: (!hideMainMenu
									&& (
										<div className='menu-item last' id='main'>
											<a className='nav-item' onClick={this.mainMenuToggle}>
												<span className='d-lg-none outline'>{ar.Main_Menu}</span>
												<div className='main-icon d-none d-lg-block' />
											</a>
										</div>
									)
								)
						}
					</div>
				</div>
			</nav>
		);
	}

	renderMainMenuSubItems(childItems, parentId) {
		const { inputDefCls, labelDefCls, subListDefCls, subItemDefCls, externalDefCls } = mainMenuDefaults.classes;
		const listId = `${parentId}-list`;
		const collapseId = `${parentId}-collapse`;

		return (
			<>
				<input className={inputDefCls} id={collapseId} type='checkbox' data-toggle='collapse' data-target={`#${listId}`} aria-expanded='true' aria-controls={listId} />
				<label className={labelDefCls} htmlFor={collapseId} onClick={this.handleMenuItemCollapse} />
				<ul id={listId} className={subListDefCls}>
					{childItems.map((childItem, index) => {
						const { text, base, slug, external } = childItem;
						const href = getHrefFromSlug(slug, base);
						const target = getTargetFromHref(href, external);
						const key = `${text.split(' ')[0].toLowerCase()}-submenuitem-${index}`;

						return (
							<li key={key} className={subItemDefCls}>
								<a href={href} target={target}>{text}</a>
								{external && (<span className={externalDefCls}><ExternalLinkSVG /></span>)}
							</li>
						);
					})}
				</ul>
			</>
		);
	}

	renderMainMenuItem(menuItem, index) {
		const { itemDefCls, linkDefCls } = mainMenuDefaults.classes;
		const { text, align, itemCls, itemId, link: { base, slug, linkCls, clickFn, external }, childItems } = menuItem;
		const itemAllCls = `${itemDefCls} ${itemCls || ''} text-${align}`;
		const linkAllCls = `${linkDefCls} ${linkCls || ''}`;
		const key = `${text.split(' ')[0].toLowerCase()}-menuitem-${index}`;
		const onClick = clickFn && typeof this[clickFn] === 'function' ? this[clickFn] : null;
		const href = getHrefFromSlug(slug, base);
		const target = getTargetFromHref(href, external);

		const anchorProps = {
			className: linkAllCls,
			...href && { href },
			...target && { target },
			...onClick && { onClick }
		};

		return (
			<li key={key} className={itemAllCls} id={itemId || null}>
				<a {...anchorProps}>{text}</a>
				{ childItems && this.renderMainMenuSubItems(childItems, itemId)}
			</li>
		);
	}

	renderSocialItem(socialItem) {
		const { title, href, linkCls, imgCls, imgAlt, imgSrc } = socialItem;
		const key = `${title}-socialitem`;
		return (
			<a key={key} className={linkCls} href={href} title={title} target='_blank' rel='noopener'>
				<img className={imgCls} alt={imgAlt} src={imgSrc} />
			</a>
		);
	}

	renderSocial() {
		const socialCls = 'search_and_social_nav follow_nav';
		return (
			<div className={socialCls}>
				<div className='nav_title'><span>FOLLOW GLOBAL CLIMATE CHANGE</span></div>
				<div className='follow_bar py-4'>
					{socialItems.map(socialItem => this.renderSocialItem(socialItem))}
				</div>
			</div>
		);
	}

	renderSearchForm() {
		const { search } = this.state;
		return (
			<div className='pb-5 mb-5 pl-4'>
				<form onSubmit={this.onFormSubmit}>
					<input className='search-bar' placeholder='search' type='text' value={search} onChange={this.searchInput} />
					<div className='magnifying-icon' type='button' onClick={this.onFormSubmit}>
						<MagnifyingGlassSVG />
					</div>
				</form>
			</div>
		);
	}

	renderMainMenu() {
		const { containerDefCls, contentDefCls, listDefCls } = mainMenuDefaults.classes;

		return (
			<div className={containerDefCls}>
				<div className='partialHeader'>
					<CloseButton onClick={this.mainMenuToggle} />
				</div>
				<div className={contentDefCls}>
					<ul className={listDefCls}>
						{mainMenuItems.map((menuItem, index) => this.renderMainMenuItem(menuItem, index))}
					</ul>
					{this.renderSocial()}
					{this.renderSearchForm()}
				</div>
			</div>
		);
	}


	render() {
		const { mobileMenu, mainMenu } = this.state;
		const headerCls = mobileMenu ? 'mobile-menu-overlay' : '';

		return (
			<div id='top-panel'>
				<div id='header' className={headerCls} style={{ maxHeight: window.innerHeight }}>
					{this.renderNavBar()}
					{mainMenu ? this.renderMainMenu() : null}
				</div>
			</div>
		);
	}
}


export default Header;
