/* eslint-disable camelcase */
import { useCallback, useRef, createRef, useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { useSnapshot } from 'valtio';

import { poiStore, appStore, uiStore } from '../../managers/globalState';
import globalRefs from '../../managers/globalRefs';

import Overlay from '../overlays';
import GridList from '../grid_list';
import Filter from '../filter';
import ThumbnailLoader from '../thumbnail_loader';

import GlobeIcon from '../../../www/assets/images/globe_icon.min.svg';
import EventSVG from '../../../www/assets/images/event-marker.svg';

import '../../../www/assets/css/overlays/events.css';

// import '../../../www/assets/css/overlays/poi.css';

import {
	updateYear,
	updateCategory,
	filterPoI
} from '../../helpers/processPoI';
import { POI_CATEGORIES, EVENT_MEDIA } from '../../config/constants';
import { Config } from '../../config/config';
import StaticIcon from '../../../www/assets/images/static_icon.svg';
import AnimatedIcon from '../../../www/assets/images/animated_icon.svg';
import ar from "../../languages/ar"

/**
 * Constant vars
 */
// const API_BASE_URL = window.config.apiUrl ? window.config.apiUrl : 'https://climate.nasa.gov';

const THUMB_WIDTH = 159;
const THUMB_HEIGHT = 102;
const ITEM_PADDING = 30;
const TITLE_TEXT = 'أبرز المعالم';
const BUTTON_TEXT = 'عرض على الكرة الأرضية';

/**
 * ButtonText component
 */
const ButtonText = () => <span>{BUTTON_TEXT}</span>;

/**
 * Individual EventItem component to represent each event in the grid
 */
const PoiItem = props => {
	// State to track hover
	const [isHovered, setIsHovered] = useState(false);
	const { title, external_id, thumbSrc, list_image_src,thumbAnimSrc, date, formatted_date, isAnimated, onItemClick } = props;

	const thumbPath = (isHovered && thumbAnimSrc) ? thumbAnimSrc : thumbSrc;
	let srcUrl = `${Config.baseCe2s2Url}${list_image_src}`;
	
	const tipTitle = isAnimated ? 'Animated Event' : 'Static Event';

	const containerStyle = {
		width: `${THUMB_WIDTH}px`,
		height: `${THUMB_HEIGHT}px`
	};

	// Event handlers
	const handleMouseEnter = () => setIsHovered(true);
	const handleMouseLeave = () => setIsHovered(false);

	return (
		<figure className='event-item' onClick={() => onItemClick(external_id)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<div className='image-time-container' style={containerStyle}>
				<img className={`thumbnail ${isHovered ? 'zoom' : ''}`} src={srcUrl} alt={title} />
				<div className='icon-date-container'>
					<div className='icon-group' aria-hidden='true' title={tipTitle} aria-label={tipTitle}>
						{isAnimated ? <AnimatedIcon /> : <StaticIcon />}
					</div>
					<time className='event-date' dateTime={date}>{formatted_date}</time>
				</div>
			</div>
			<figcaption className='event-title'>{title}</figcaption>
		</figure>
	);
};


/**
 * Events Overlay component
 */
const PoIOverlay = ({ closeOverlay }) => {
	const mainRef = useRef();
	const scrollableNodeRef = createRef();

	// get the global state
	const { yearIndex, categoryIndex, pois,poi_cats, poiYears } = useSnapshot(poiStore.state);
	const { isWindows } = useSnapshot(appStore.state);

	// Store the category index locally as it shouldnt be set in the global state unless we View on Globe.
	const initialYearIndex = useRef(yearIndex);
	const initialCategoryIndex = useRef(categoryIndex);

	// Filter pois.
	const currentCategoryParam = categoryIndex !== null ? POI_CATEGORIES[categoryIndex][0] : 'all';

	const filteredData = filterPoI(pois,poiYears, yearIndex, categoryIndex);

	// navToEvent callback.
	const navToPoi = useCallback(eventParam => {
		closeOverlay(true);
		const { getManager } = globalRefs;
		getManager('route').navigateToPoi(eventParam);
	}, [closeOverlay]);

	// viewOnGlobe callback
	const viewOnGlobe = useCallback(() => {
		const { getManager } = globalRefs;
		// Close the overlay  pass false since no event was selected.
		closeOverlay(false);
		uiStore.setGlobalState({ isDetailPanelOpen: false });
		console.log("TODO navigate to poi year?")
		// getManager('route').navigateToEventYear(poiYears, currentCategoryParam, currentFilter);
	}, [closeOverlay, poiYears, currentCategoryParam]);

	// on mount, update the dropdowns to the current year, but default category and media to all.
	useEffect(() => {
		updateYear(yearIndex);
		updateCategory(0);
		return () => {
			// on unmount, reset the dropdowns to the initial year and category.
			updateYear(initialYearIndex.current);
			updateCategory(initialCategoryIndex.current);
		};
	}, []);


	const overlayComponents = (
		<>
			<Overlay.Header
				back
				onClose={closeOverlay}
				title={TITLE_TEXT}
				Icon={EventSVG}
			>
				<div className='events-header-content'>
					<div className='events-header-filters'>
						<Filter
							type='dropdown'
							title={ar.year}
							style={{ padding: 10 }}
							allItems={poiYears.map(v => v[0])}
							selectedIndex={yearIndex}
							updateItem={updateYear}
						/>
						<Filter
							type='dropdown'
							style={{ padding: 10 }}
							title={ar.category}
							allItems={POI_CATEGORIES.map(v => v[1])}
							selectedIndex={categoryIndex}
							updateItem={updateCategory}
						/>
			
					</div>
					{/* <div className='globe-border'>
						<div className='button' onClick={viewOnGlobe}>
							<GlobeIcon />
							<ButtonText />
						</div>
					</div> */}
				</div>
			</Overlay.Header>

			{ filteredData === null ? <ThumbnailLoader loaderText={ar.loading} /> : null }
			{ filteredData?.length === 0 ? <Overlay.NoResults /> : null }
			{
				filteredData?.length
					? (
						<div className='content-mask'>
							<GridList
								rowClassName='flex-grid-row'
								minPerRow={2}
								dataList={filteredData}
								itemSize={THUMB_WIDTH + ITEM_PADDING * 2}  
								itemPadding={[ITEM_PADDING, ITEM_PADDING]}
								itemComponent={<PoiItem onItemClick={navToPoi} />}
								scrollableRef={isWindows ? scrollableNodeRef : mainRef}
							/>
						</div>
					)
					: null
			}

		</>
	);
	// compose overlay
	return (
		<Overlay type='events' ref={mainRef}>
			{isWindows ?
				<SimpleBar scrollableNodeProps={{ ref: scrollableNodeRef }} style={{ height: '100%' }}>
					{overlayComponents}
				</SimpleBar> :
				overlayComponents}
		</Overlay>
	);
};


export default PoIOverlay;
