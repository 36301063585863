/** @module pioneer */
import {
	CameraComponent,
	Entity,
	THREE
} from '../../internal';

/**
 * Spout component. The entity should also have a camera component.
 */
export class SpoutComponent extends CameraComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The render width.
		 * @type {number}
		 * @private
		 */
		this._renderWidth = 2048;

		/**
		 * The distance to which the globe cameras should render.
		 * @private
		 */
		this._globeDistance = 1;

		// STUFF FOR RENDERING FACES

		/**
		 * The three js cameras.
		 * @type {THREE.PerspectiveCamera[]}
		 * @private
		 */
		this._threeJsFaceCameras = [];

		/**
		 * This render targets.
		 * @type {THREE.WebGLRenderTarget[]}
		 * @private
		 */
		this._threeJsFaceRenderTargets = [];

		// STUFF FOR RENDERING FINAL TEXTURE

		/**
		 * The ThreeJS quad.
		 * @type {THREE.Mesh}
		 * @private
		 */
		this._threeJsQuad = new THREE.Mesh();

		/**
		 * The ThreeJS scene.
		 * @type {THREE.Scene}
		 * @private
		 */
		this._threeJsSpoutScene = new THREE.Scene();

		/**
		 * The ThreeJS cube camera.
		 * @type {THREE.OrthographicCamera}
		 * @private
		 */
		this._threeJsCubeCamera = new THREE.OrthographicCamera(-1, 1, -1, 1, -1, 1);

		/**
		 * This render target is used to mark the start of the shared texture render block.
		 * @private
		 */
		this._tagStart = new THREE.WebGLRenderTarget(2, 3, { minFilter: THREE.LinearFilter, magFilter: THREE.NearestFilter });

		/**
		 * This render target is used to mark the end of the shared texture render block.
		 * @private
		 */
		this._tagEnd = new THREE.WebGLRenderTarget(3, 2, { minFilter: THREE.LinearFilter, magFilter: THREE.NearestFilter });

		/**
		 * This render target is used to signal that all the shared textures are rendered and can be sent to Spout.
		 * @private
		 */
		this._tagSend = new THREE.WebGLRenderTarget(3, 3, { minFilter: THREE.LinearFilter, magFilter: THREE.NearestFilter });

		/**
		 * This render target is the texture that will be read by Spout.
		 * @private
		 */
		this._spoutTexture = new THREE.WebGLRenderTarget(this._renderWidth, this._renderWidth / 2, { minFilter: THREE.LinearFilter, magFilter: THREE.NearestFilter });

		// Setup the cameras.
		this._setupFaceCameras();

		// Setup the quad for the final render.
		this._setupQuad();
	}

	/**
	 * Gets the render width.
	 * @returns {number}
	 */
	getRenderWidth() {
		return this._renderWidth;
	}

	/**
	 * Sets the render width.
	 * @param {number} renderWidth
	 */
	setRenderWidth(renderWidth) {
		this._renderWidth = renderWidth;
		if (this._spoutTexture.width !== this._renderWidth) {
			this._spoutTexture.setSize(this._renderWidth, this._renderWidth / 2);
		}
		for (let face = 0; face < 6; face++) {
			this._threeJsFaceRenderTargets[face].setSize(this._renderWidth / 4, this._renderWidth / 4);
		}
	}

	/**
	 * Gets if this is for a globe projection.
	 * @return {boolean}
	 */
	getForGlobe() {
		return this.getInvertDepth() === 1;
	}

	/**
	 * Sets if this is for a globe projection.
	 * @param {boolean} forGlobe
	 * @param {number} globeDistance - The distance to which the camera should render.
	 */
	setForGlobe(forGlobe, globeDistance) {
		this.setInvertDepth(forGlobe ? 1 : 0);
		this._globeDistance = globeDistance;
	}

	// INTERNALS

	/**
	 * Renders the camera. Called by Viewport.
	 * @override
	 * @internal
	 */
	__render() {
		// Set the near and mid distances manually to work with the globe.
		if (this.getForGlobe()) {
			this.setNearDistance(Math.max(0.1, this.getEntity().getParent().getOcclusionRadius() * 0.5));
			this.setMidDistance(this.getAutoNearDistance());
		}

		// Update the projection matrix.
		this._updateProjectionMatrices();

		// Set the camera's orientation for each of the face cameras.
		const sqrt2 = 0.7071067811865476;
		const ori = this.getEntity().getOrientation();
		_tempThreeJsQuaternion.set(ori.x, ori.y, ori.z, ori.w);
		this._threeJsFaceCameras[0].setRotationFromQuaternion(_tempThreeJsQuaternion);
		_tempThreeJsQuaternion.set(sqrt2 * (ori.x - ori.y), sqrt2 * (ori.x + ori.y), sqrt2 * (-ori.w + ori.z), sqrt2 * (ori.w + ori.z));
		this._threeJsFaceCameras[1].setRotationFromQuaternion(_tempThreeJsQuaternion);
		_tempThreeJsQuaternion.set(ori.y, -ori.x, ori.w, -ori.z);
		this._threeJsFaceCameras[2].setRotationFromQuaternion(_tempThreeJsQuaternion);
		_tempThreeJsQuaternion.set(sqrt2 * (ori.x + ori.y), sqrt2 * (-ori.x + ori.y), sqrt2 * (ori.w + ori.z), sqrt2 * (ori.w - ori.z));
		this._threeJsFaceCameras[3].setRotationFromQuaternion(_tempThreeJsQuaternion);
		_tempThreeJsQuaternion.set(sqrt2 * (ori.w + ori.x), sqrt2 * (ori.y + ori.z), sqrt2 * (-ori.y + ori.z), sqrt2 * (ori.w - ori.x));
		this._threeJsFaceCameras[4].setRotationFromQuaternion(_tempThreeJsQuaternion);
		_tempThreeJsQuaternion.set(sqrt2 * (-ori.w + ori.x), sqrt2 * (ori.y - ori.z), sqrt2 * (ori.y + ori.z), sqrt2 * (ori.w + ori.x));
		this._threeJsFaceCameras[5].setRotationFromQuaternion(_tempThreeJsQuaternion);

		// If we're using the globe, flip all of the face culling, since depth is inverted.
		if (this.getForGlobe()) {
			this._threeJsRenderer.state.setCullFace(THREE.CullFaceFront);
		}

		// Render each of the face cameras to the render targets.
		for (let face = 0; face < 6; face++) {
			this._threeJsRenderer.setRenderTarget(this._threeJsFaceRenderTargets[face]);
			this._threeJsRenderer.render(this.getEntity().getScene().getThreeJsScene(), this._threeJsFaceCameras[face]);
		}

		// If we're using the globe, revert all of the face culling.
		if (this.getForGlobe()) {
			this._threeJsRenderer.state.setCullFace(THREE.CullFaceBack);
		}

		// All of the render targets cleared between 'tagStart' and 'tagEnd', will be recorded as Spout textures.
		this._threeJsRenderer.setRenderTarget(this._tagStart);
		this._threeJsRenderer.clearColor();
		this._threeJsRenderer.setRenderTarget(this._spoutTexture);
		this._threeJsRenderer.clearColor();
		this._threeJsRenderer.setRenderTarget(this._tagEnd);
		this._threeJsRenderer.clearColor();

		// Render to the Spout texture using the render targets.
		this._threeJsRenderer.setRenderTarget(this._spoutTexture);
		// this._threeJsRenderer.setRenderTarget(null); // Uncomment this to make it render to the viewport for debugging.
		this._threeJsRenderer.render(this._threeJsSpoutScene, this._threeJsCubeCamera);

		// Signal that all of the recorded textures have been rendered and can be sent to Spout.
		this._threeJsRenderer.setRenderTarget(this._tagSend);
		this._threeJsRenderer.clearColor();

		// Make the render target back to default.
		this._threeJsRenderer.setRenderTarget(null);
	}

	/**
	 * Sets up the cameras.
	 * @private
	 */
	_setupFaceCameras() {
		for (let face = 0; face < 6; face++) {
			this._threeJsFaceCameras.push(new THREE.PerspectiveCamera(90.0, 1.0, 0.1, 1000));
			const projectionMatrix = new THREE.Matrix4();
			projectionMatrix.set(
				1, 0, 0, 0,
				0, 0, 1, 0,
				0, 0, 0, 1,
				0, 1, 0, 0);
			this._threeJsFaceCameras[face].projectionMatrix = projectionMatrix;
			this._threeJsFaceCameras[face].projectionMatrixInverse.copy(projectionMatrix).invert();

			// Setup the render target for the camera.
			const renderTarget = new THREE.WebGLRenderTarget(this._renderWidth / 4, this._renderWidth / 4, { minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter });
			this._threeJsFaceRenderTargets.push(renderTarget);
		}
	}

	/**
	 * Updates the projection.
	 * @private
	 */
	_updateProjectionMatrices() {
		const n = this.getAutoNearDistance();
		let f1 = Number.EPSILON - 1.0;
		let f2 = n * (2.0 - Number.EPSILON);
		if (this.getInvertDepth() === 1) {
			const f = this._globeDistance;
			f1 = (n + f) * (1 - Number.EPSILON) / (n - f);
			f2 = -2 * n * f * (1 - Number.EPSILON) / (n - f);
		}
		for (let face = 0; face < 6; face++) {
			this._threeJsFaceCameras[face].projectionMatrix.elements[6] = f1;
			this._threeJsFaceCameras[face].projectionMatrix.elements[14] = f2;
		}
	}

	/**
	 * Sets the quad meshes up.
	 */
	_setupQuad() {
		// Setup geometry.
		const geometry = new THREE.BufferGeometry();
		const meshPositions = new Float32Array([-1, -1, 1, -1, 1, 1, -1, 1]);
		const meshIndices = new Uint16Array([0, 1, 2, 0, 2, 3]);
		geometry.setAttribute('position', new THREE.BufferAttribute(meshPositions, 2));
		geometry.setIndex(new THREE.BufferAttribute(meshIndices, 1));

		// Setup material uniforms.
		const uniforms = {
			textures: new THREE.Uniform([])
		};
		for (let face = 0; face < 6; face++) {
			uniforms['textures'].value.push(this._threeJsFaceRenderTargets[face].texture);
		}

		// Setup material.
		const material = new THREE.RawShaderMaterial({
			uniforms: uniforms,
			vertexShader: `
				attribute vec2 position;
				varying vec2 xy;
				void main() {
					gl_Position = vec4(position.x, position.y, 0.0, 1.0);
					xy = position;
				}`,
			fragmentShader: `
				precision highp float;

				uniform sampler2D textures[6];

				varying vec2 xy;
				const float PI = 3.1415926535897932384626433832795;

				vec3 xyToUvFace(vec2 xy) {
					vec3 xyz = vec3(
						cos(xy.y * PI / 2.0) * cos(xy.x * PI),
						cos(xy.y * PI / 2.0) * sin(xy.x * PI),
						sin(-xy.y * PI / 2.0));

					vec3 basis[3];
					float face;
					if (xyz.x * xyz.x >= xyz.y * xyz.y && xyz.x * xyz.x >= xyz.z * xyz.z) {
						if (xyz.x >= 0.0) {
							basis[0] = vec3(0, 1, 0); basis[1] = vec3(0, 0, 1); basis[2] = vec3(1, 0, 0);
							face = 0.0;
						}
						else {
							basis[0] = vec3(0, -1, 0); basis[1] = vec3(0, 0, 1); basis[2] = vec3(-1, 0, 0);
							face = 2.0;
						}
					}
					else if (xyz.y * xyz.y >= xyz.x * xyz.x && xyz.y * xyz.y >= xyz.z * xyz.z) {
						if (xyz.y >= 0.0) {
							basis[0] = vec3(-1, 0, 0); basis[1] = vec3(0, 0, 1); basis[2] = vec3(0, 1, 0);
							face = 1.0;
						}
						else {
							basis[0] = vec3(1, 0, 0); basis[1] = vec3(0, 0, 1); basis[2] = vec3(0, -1, 0);
							face = 3.0;
						}
					}
					else {
						if (xyz.z >= 0.0) {
							basis[0] = vec3(0, 1, 0); basis[1] = vec3(-1, 0, 0); basis[2] = vec3(0, 0, 1);
							face = 4.0;
						}
						else {
							basis[0] = vec3(0, 1, 0); basis[1] = vec3(1, 0, 0); basis[2] = vec3(0, 0, -1);
							face = 5.0;
						}
					}

					vec3 uv = vec3(
						basis[0].x * xyz.x + basis[0].y * xyz.y + basis[0].z * xyz.z,
						basis[1].x * xyz.x + basis[1].y * xyz.y + basis[1].z * xyz.z,
						basis[2].x * xyz.x + basis[2].y * xyz.y + basis[2].z * xyz.z);
					uv.x /= uv.z;
					uv.y /= uv.z;

					return vec3(0.5 * (uv.x + 1.0), 0.5 * (uv.y + 1.0), face);
				}

				void main() {
					vec3 uvFace = xyToUvFace(xy);

					vec4 pixel;
					int face = int(uvFace.z);

					if (face == 0) {
						pixel = texture2D(textures[0], vec2(uvFace.x, uvFace.y));
					}
					else if (face == 1) {
						pixel = texture2D(textures[1], vec2(uvFace.x, uvFace.y));
					}
					else if (face == 2) {
						pixel = texture2D(textures[2], vec2(uvFace.x, uvFace.y));
					}
					else if (face == 3) {
						pixel = texture2D(textures[3], vec2(uvFace.x, uvFace.y));
					}
					else if (face == 4) {
						pixel = texture2D(textures[4], vec2(uvFace.x, uvFace.y));
					}
					else if (face == 5) {
						pixel = texture2D(textures[5], vec2(uvFace.x, uvFace.y));
					}

					gl_FragColor = pixel;
				}`,
			depthTest: false,
			depthWrite: false,
			side: THREE.DoubleSide
		});

		// Setup object.
		this._threeJsQuad = new THREE.Mesh(geometry, material);
		this._threeJsQuad.frustumCulled = false;
		this._threeJsSpoutScene.add(this._threeJsQuad);
	}
}

/**
 * A temporary ThreeJs Quaternion.
 * @type {THREE.Quaternion}
 * @private
 */
const _tempThreeJsQuaternion = new THREE.Quaternion();
