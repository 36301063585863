import { SceneHelpers } from 'pioneer-scripts';

import { setReadoutData } from '../components/data_readout';
import { Config } from '../config/config';
import { MAX_WMTS_FRAMES } from '../config/constants';
import { calcPrefixFromDate, cloneDate, getDateFromLocalDateStr, dateToUTCString, dayDifference, getLatestDates, getDateFromUTCDateStr, monthDifference, localDateToNoonUTC, getWebpOrPngExt } from '../helpers/tools';
import { datasetStore, uiStore, spacecraftStore } from './globalState';
import globalRefs from './globalRefs';
import { showLoading } from '../components/loading';
import DATA_READER_DATA from '../data/value_definitions_ar'; // importing the arabic units

class DatasetManager {
	constructor(pioneer) {
		// Set pioneer and scene.
		this._pioneer = pioneer;
		this._scene = pioneer.get('main');

		/**
		 * The current dataset.
		 * @type {object}
		 * @private
		 */
		this._dataset = null;

		/**
		 * Whether the dataset is updated monthly.
		 * @type {boolean}
		 * @public
		 */
		this.isMonthly = false;

		/**
		 * The start and end date limits for the dataset (from the manifest file)
		 * @type {object}
		 * @property {Date} start
		 * @property {Date} end
		 * @public
		 */
		this.manifestLimits = {
			start: null,
			end: null
		};

		/**
		 * List of animation objects
		 * @type {Array<object>}
		 * @public
		 */
		this.animations = [];

		/**
		 * Whether we are animating through datasets.
		 * Currently not an ideal name as it remains true when the animation is paused.
		 * Better would be something like showingRange
		 * @type {boolean}
		 * @public
		 */
		this.hasAnimation = null;

		/**
		 * Whether we are displaying the latest animation dataset
		 * @type {boolean}
		 * @public
		 */
		this.isLatest = null;

		/**
		 * Whether we are displaying the latest seven animation datasets
		 * @type {boolean}
		 * @public
		 */
		this.isLatestSeven = null;

		/**
		 * Whether the animation loop currently animating.
		 * @type {boolean}
		 * @public
		 */
		this.isAnimating = null;

		/**
		 * Whether the animation loop is currently paused.
		 * @type {boolean}
		 * @public
		 */
		this.isPaused = null;

		/**
		 * Dataset's readout information
		 * @type {object}
		 * @public
		 */
		this.readoutData = null;

		/**
		 * Animation start date
		 * @type {Date}
		 * @public
		 */
		this.animationStartDate = null;

		/**
		 * Animation end date
		 * @type {Date}
		 * @public
		 */
		this.animationEndDate = null;

		/**
		 * Stored, clearable animation interval
		 * @type {number}
		 * @private
		 */
		this._animationInterval = null;

		/**
		 * Interval between animating dataset in milliseconds
		 * @type {number}
		 * @private
		 */
		this._animIntervalTimeMs = 1000;


		/**
		 * Whether to show the texture loader.
		 * @type {boolean}
		 * @private
		 */
		this._isFirstLoad = null;


		/**
		 * Texture load abort function
		 * @type {function}
		 * @public
		 */
		this.textureLoadAbort = null;


		/**
		 * Create 1x1 pixel array buffer
		 */
		const pixelData = 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
		// Convert the Base64 string to a binary string.
		const binaryString = atob(pixelData);
		const len = binaryString.length;
		// Use Buffer to convert the binary string to a Uint8Array.
		this._pixelArray = new Uint8Array(len);
		for (let i = 0; i < len; i++) {
			this._pixelArray[i] = binaryString.charCodeAt(i);
		}


		/**
		 * Binds
		 */
		this.getDatasetManifestData = this.getDatasetManifestData.bind(this);
		this.calcNumFrames = this.calcNumFrames.bind(this);
		this.setAnimationProps = this.setAnimationProps.bind(this);
		this.setIsLatest = this.setIsLatest.bind(this);
		this.setIsLatestSeven = this.setIsLatestSeven.bind(this);
		this.getAnimationStartDate = this.getAnimationStartDate.bind(this);
		this.getAnimationEndDate = this.getAnimationEndDate.bind(this);
		this.prevDataset = this.prevDataset.bind(this);
		this.nextDataset = this.nextDataset.bind(this);
		this.setPaused = this.setPaused.bind(this);
		this.setDisplay = this.setDisplay.bind(this);
		this.setAnimationProps = this.setAnimationProps.bind(this);
		this.stopAnimation = this.stopAnimation.bind(this);
		this.resetAnimation = this.resetAnimation.bind(this);
	}

	/**
	 * Init.
	 */
	init() {
		// Subscribe to the currentIndex, updating textures when it changes.
		datasetStore.subscribeTo('currentIndex', this.updateTextures.bind(this), true);
	}

	/**
	 * Set whether we init or kill the animation.
	 */
	setDisplay() {
		if (this.hasAnimation) {
			this.initAnimation();
		} else {
			// Set to latest index.
			this.setToLatestIndex();
		}
	}

	/**
	 * Sets the start and/or end limits for the current dataset
	 */
	_setManifestLimits() {
		const { startDate, endDate } = this._dataset;
		if (!startDate || !endDate) { return; }

		const start = getDateFromUTCDateStr(startDate);
		const end = getDateFromUTCDateStr(endDate);

		this.manifestLimits.start = localDateToNoonUTC(start);
		this.manifestLimits.end = localDateToNoonUTC(end);
	}

	/**
	 * Sets the current dataset and various related variables.
	 * @param {object} dataset
	 * @param {object} queries
	 * @param {string} queries.start YYYY-MM-DD format
	 * @param {string} queries.end YYYY-MM-DD format
	 * @param {string} queries.animating
	 */
	setDataset(dataset, { start, end, animating }) {
		const { isSatellitesNow } = spacecraftStore.stateSnapshot;
		const { average, readoutData } = dataset;

		// These should have already been checked for validity.
		this._dataset = dataset;

		// Set additional variables.
		this.title = this._dataset.title;
		this.externalId = this._dataset.externalId;
		this.isMonthly = average === 'month';
		this.readoutData = readoutData;

		// Update dataset manifest limits.
		this._setManifestLimits();

		// Set is first load.
		this._setIsFirstLoad(true);

		// Set hasAnimation
		const hasAnimation = !isSatellitesNow && animating === 'true';
		this.setHasAnimation(hasAnimation);

		// Set isLatest and isLatestSeven.
		const noPassedDates = !start && !end;

		const isLatest = !hasAnimation && noPassedDates;
		const isLatestSeven = hasAnimation && noPassedDates;

		this.setIsLatest(isLatest);
		this.setIsLatestSeven(isLatestSeven);
	}

	/**
	 * Sets the textures for the current dataset.
	 */
	updateTextures(currentIndex) {
		// With visible earth the textures are set by the visible earth manager.
		const { isVisibleEarth, currentDataset } = datasetStore.stateSnapshot;

		// Determine the tileset path.
		const tilesetPath = this.getTilesetPath(currentIndex);

		const { getTileManager } = globalRefs;

		if (isVisibleEarth || currentIndex === null || !tilesetPath) {
			return;
		}

		const { externalId } = currentDataset || {};
		if (getTileManager(externalId)) {
			getTileManager(externalId).updateIndividualWMTS(true, currentIndex);
			return;
		}

		// Set isTextureLoaded global state to false.
		datasetStore.setGlobalState({ isTextureLoaded: false });

		// Determine whether we should show the texture loader.
		this._isFirstLoad && showLoading('texture');

		// Load the canvas texture.
		const { loadedPromise: canvasPromise, abort: canvasAbort } = this.loadCanvas(tilesetPath);

		// Load the spheroid texture.
		this.loadSpheroidTexture(tilesetPath)
			.then(({ loadedPromise: spheroidPromise, abort: spheroidAbort }) => {
				// Set the textureLoadAbort function.
				this.textureLoadAbort = () => {
					canvasAbort();
					spheroidAbort();
					// Reset textureLoadAbort to null.
					this.resetTextureLoadAbort();
				};
				// Return the loaded promises.
				return Promise.all([canvasPromise, spheroidPromise]);
			})
			.then(() => {
				// Set isFirstLoad to false.
				this._setIsFirstLoad(false);

				// Reset textureLoadAbort to null.
				this.resetTextureLoadAbort();

				// Set isTextureLoaded to true.
				datasetStore.setGlobalState({ isTextureLoaded: true });
			});
	}

	/**
	 * Loads a texture onto the spheroidLOD component.
	 * Returns a promise which resolves once texture has loaded.
	 * @param {string} texturePath
	 * @returns {Promise}
	 */
	async loadSpheroidTexture(texturePath) {
		const { getManager } = globalRefs;
		const earth = this._scene.get('earth');
		const spheroidLOD = earth.get('spheroidLOD');

		/**
		 * Some things we oonly update on the first load.
		 * Otherwise we get black in between texture loads.
		 */
		if (this._isFirstLoad) {
			// Disable all spheroidLOD features.
			const { earthSpheroidFeatures } = getManager('content');
			earthSpheroidFeatures.forEach(feature => {
				spheroidLOD.setFeature(feature, false);
			});

			// Set correct cube layout.
			spheroidLOD.setCubeMapLayout([
				['+z', '+x', '+y'],
				['+z', '+x', '-y'],
				['-y', '+x', '-z'],
				['-y', '+x', '+z'],
				['-y', '+z', '-x'],
				['-y', '+z', '+x']
			]);

			// Set cube mapping.
			spheroidLOD.setMapping('cube');
		}

		// Set color texture.
		const ext = await getWebpOrPngExt(`${texturePath}0.webp`);
		spheroidLOD.setTexture('color', `${texturePath}$FACE.${ext}`, [512]);

		// Create a function that allows us to abort the texture url downloads.
		const textureLODs = spheroidLOD._textureLODs.get('color');
		const texUrls = textureLODs?.map(({ _url }) => _url);

		// Get the downloader cancel function.
		const downloader = this._pioneer.getTextureLoader()._downloader;

		// This is a manual abort which replaces the image with a single pixel instead of actually cancelling.
		// This is because the current cancelling causes an error to be thrown.
		const abort = () => {
			texUrls.forEach(url => {
				const downloadData = downloader._currentDownloads.get(url);
				// If we don't have downloadData, we don't need to do anything.
				if (!downloadData) {
					return;
				}
				downloadData.download.content = this._pixelArray.buffer;
				downloadData.download.mimeType = 'image/png';
				downloadData.resolve(downloadData.download);
			});

			// Reset the image srcs.
			textureLODs.forEach(textureLOD => {
				textureLOD.getLoadedPromise().then(() => {
					const { value: texture } = textureLOD.getUniform() || {};
					if (texture?.image) {
						texture.image.onerror = null;
						downloader.cancel(textureLOD._url);
					}
				});
			});
		};

		// Set feature to enable.
		spheroidLOD.setEnabled(true);

		// Wait for spheroidLOD to start loading before returning the loaded promise.
		await SceneHelpers.waitTillEntitiesInPlace(this._scene, ['earth'])
			.then(() => this._pioneer.waitUntilNextFrame());

		const loadedPromise = new Promise(resolve => spheroidLOD.getLoadedPromise().then(() => resolve()));

		return { loadedPromise, abort };
	}

	/**
	 * Loads the data image into a canvas for data lookup.
	 * Returns a promise which resolves once the image has loaded.
	 * @param {string} pathname
	 */
	loadCanvas(pathname) {
		const url = `${pathname}6.png`;
		const { _downloader: downloader } = this._pioneer.getTextureLoader();
		const cancel = downloader.cancel.bind(downloader);

		const loadedPromise = new Promise(resolve => {
			downloader.download(url, true)
				.then(download => {
					const dataImage = new Image();
					dataImage.crossOrigin = 'anonymous';

					dataImage.src = URL.createObjectURL(new Blob([download.content], { type: download.mimeType }));
					// dataImage.onerror = reject;
					dataImage.onload = () => {
						const canvas = document.getElementById('data-canvas'); // TODO: Fix in #4221.
						this._canvasContext = canvas?.getContext('2d', { willReadFrequently: true });
						this._canvasContext?.drawImage(dataImage, 0, 304, 1440, 720, 0, 0, 1440, 720);
						resolve();
					};
				});
		});

		// Here we can use the traditional cancel because we're doing a manual download
		// so we control the error handling more directly.
		const abort = () => cancel(url);

		return { loadedPromise, abort };
	}

	/**
	 * Sets the hasAnimation local, and global state boolean.
	 * @param {boolean} hasAnimation
	 */
	setHasAnimation(hasAnimation) {
		// Set local hasAnimation
		this.hasAnimation = hasAnimation;

		// Set global hasAnimation and, if true, isDatasetLoading
		datasetStore.setGlobalState({
			datasetHasAnimation: hasAnimation,
			...hasAnimation && { isDatasetLoading: true }
		});
	}

	/**
	 * Sets the isPaused local state boolean.
	 * @param {boolean} animating
	 */
	setPaused(paused) {
		this.isPaused = paused;

		datasetStore.setGlobalState({ isDatasetPaused: this.isPaused });
	}

	/**
	 * Sets the isLatest local state boolean.
	 * @param {boolean} isLatest
	 */
	setIsLatest(isLatest) {
		this.isLatest = isLatest;
	}

	/**
	 * Sets the isLatestSeven local state boolean.
	 * @param {boolean} isLatestSeven
	 */
	setIsLatestSeven(isLatestSeven) {
		this.isLatestSeven = isLatestSeven;
	}

	/**
	 * Sets the isFirstLoad local state boolean.
	 * @param {boolean} isFirstLoad
	 */
	_setIsFirstLoad(isFirstLoad) {
		this._isFirstLoad = isFirstLoad;
	}

	/**
	 * Determines the start and end dates, as well as the this.animation array.
	 * @param {object} params
	 * @param {string} params.startDateStr YYYY-MM-DD format
	 * @param {string} params.endDateStr YYYY-MM-DD format
	 * @param {Array<object>} params.animations options animations array
	 */
	setAnimationProps({ startDateStr, endDateStr, animations } = {}) {
		/**
		 * Determine whether to set start and end animation dates if passed.
		 * Either if passed from the query, or wehave a passed animations array (from VE manager)
		 */
		const setDates = (startDateStr && endDateStr) || animations?.length;

		if (setDates) {
			this.animationStartDate = animations ? getDateFromUTCDateStr(animations[0].date) : getDateFromLocalDateStr(startDateStr);
			this.animationEndDate = animations ? getDateFromUTCDateStr(animations[animations.length > 0 ? animations.length - 1 : 0].date) : getDateFromLocalDateStr(endDateStr);
		}

		// Create animation array from available datasets.
		this.animations = animations ?? this.createAnimationArray() ?? [];

		// Set global store animation start and end dates.
		datasetStore.setGlobalState({
			animationDates: {
				start: this.getAnimationStartDate(),
				end: this.getAnimationEndDate()
			}
		});
	}

	/**
	 * Returns this.animationStartDate if it's not null, otherwise checks the animation array
	 * and returns the date of the first item.
	 */
	getAnimationStartDate() {
		return (this.animationStartDate && cloneDate(this.animationStartDate))
		|| (this.animations[0]?.date && getDateFromUTCDateStr(this.animations[0].date));
	}

	/**
	 * Returns this.animationEndDate if it's not null, otherwise checks the animation array
	 * and returns the date of the last item.
	 */
	getAnimationEndDate() {
		return (this.animationEndDate && cloneDate(this.animationEndDate))
		|| (this.animations[this.animations.length - 1]?.date && getDateFromUTCDateStr(this.animations[this.animations.length - 1].date));
	}

	/**
	 * Sets local and global currentIndex.
	 * @param {number} index
	 */
	setCurrentIndex(index) {
		// If we dont have any animations, set index to null.
		const currentIndex = (this.animations.length && index !== null) ? index % this.animations.length : null;
		datasetStore.setGlobalState({ currentIndex });

		const { currentDataset } = datasetStore.stateSnapshot;
		const { externalId } = currentDataset || {};
		const { getTileManager } = globalRefs;

		if (currentIndex === null) {
			getTileManager(externalId)?.updateIndividualWMTS(true, this.animations.length - 1);
		} else {
			getTileManager(externalId)?.updateIndividualWMTS(true, currentIndex);
		}
	}

	/**
	 * Resets all dataset props to null.
	 */
	resetDataset() {
		// Remove data readout when changing vital signs
		setReadoutData(null);

		// Reset variables.
		this.hasAnimation = null;

		this._dataset = null;

		this.isMonthly = null;
		this.readoutData = null;

		this.animationStartDate = null;
		this.animationEndDate = null;

		this.manifestLimits = {
			start: null,
			end: null
		};
	}

	/**
	 * Constructs the latest tileset path
	 * @param {number} index
	 * @returns
	 */
	getTilesetPath(index = -1) {
		const { tilePathPrefix: animationTilePathPrefix } = this.animations?.[index] || {};
		const { tilePathPrefix: latestTilePathPrefix } = this._dataset?.latestTileset || {};


		if (animationTilePathPrefix) {
			return `${Config.dynamicAssetsUrl}/earth/data/${animationTilePathPrefix}`;
		}
		if (latestTilePathPrefix) {
			return `${Config.dynamicAssetsUrl}/earth/data/${latestTilePathPrefix}`;
		}
		return null;
	}

	/**
	 * Wait for enabling of WMTS if we're using it.
	 * Display missing date warnings if necessary.
	 * Starting (or ending) animation.
	 * Setting to latest index if no animation.
	 */
	initAnimation() {
		const { isVisibleEarth } = datasetStore.stateSnapshot;

		// Set the first index.
		this.setCurrentIndex(0);

		// Show modal if we have missing datasets.
		const numFrames = this.calcNumFrames();
		const startDate = this.getAnimationStartDate();
		const endDate = this.getAnimationEndDate();

		/**
		 * There are two ways to determine whether we have missing datasets.
		 *
		 * 1) If we're on visible earth and the number of frames is within the MAX_WMTS_FRAMES (currently 7),
		 * it's possible that the number of day between the first and the last animation dates ends up
		 * being wider than 7 frames, ie. some frames are blank/missing.
		 *
		 * 2) If we are on cubemap datasets, the manifest data will have determined whether we have missing dates,
		 * in which case the number of animations will simply be less than the requested number of frames.
		 */
		let numMissingDatasets = 0;

		// Unique case for visible earth's wmts checks.
		if (isVisibleEarth && numFrames <= MAX_WMTS_FRAMES) {
			const frameDiff = dayDifference(startDate, endDate) + 1;
			if (frameDiff > numFrames) {
				numMissingDatasets = frameDiff - numFrames;
			}
		} else if (this.animations.length < numFrames) {
			numMissingDatasets = numFrames - this.animations.length;
		}

		if (numMissingDatasets === numFrames) {
			// There are no datasets available.
			uiStore.setGlobalState({
				modal: {
					isVisible: true,
					startRange: startDate,
					endRange: endDate,
					numMissingDatasets: null,
					onClose: () => {
						this.resetToLatest();
					}
				}
			});
		} else if (numMissingDatasets > 0) {
			uiStore.setGlobalState({
				modal: {
					isVisible: true,
					startRange: startDate,
					endRange: endDate,
					numMissingDatasets,
					onClose: () => {
						this.startAnimation();
					}
				}
			});
		} else {
			this.startAnimation();
		}

		// Set global isDatasetLoading to false.
		datasetStore.setGlobalState({ isDatasetLoading: false });
	}

	/**
	 * Calculates the number of dataset frames depending on animtion start and end dates
	 * @returns {number}
	 */
	calcNumFrames() {
		// If no animation we only want 1 frame.
		if (!this.hasAnimation) {
			return 1;
		}

		// If latest 7, return 7.
		if (this.isLatestSeven) {
			return 7;
		}

		const startDate = this.getAnimationStartDate();
		const endDate = this.getAnimationEndDate();

		// Otherwise calculate the frame difference between start and end dates. Add 1 to include the dates themselves.
		return 1 + (this.isMonthly ?
			monthDifference(startDate, endDate) :
			dayDifference(startDate, endDate));
	}

	/**
	 * Determines the this.animations array.
	 * Is it calculated by inputted start and end dates?
	 * Is it calculated from the last 7 available datasets?
	 * What happens if we are missing datasets and how do we tell the user of such horrors?
	 * @returns {Array}
	 */
	createAnimationArray() {
		// We wont have any animations for satellitesNow.
		const { datasetManifests } = datasetStore.stateSnapshot;
		const { isSatellitesNow } = spacecraftStore.stateSnapshot;

		if (isSatellitesNow) {
			return [];
		}

		const { frequency, startDate: manifestStart, endDate: manifestEnd, missingDates, availableDates, type } = datasetManifests[this.externalId];

		const numFrames = this.calcNumFrames();

		/**
		 * Between animation and manifest dates, we need to determine:
		 * - the latest start date
		 * - the earliest end date
		 */
		if (!manifestStart || !manifestEnd) { return; }
		let latestStartDate = getDateFromUTCDateStr(manifestStart);
		let earliestEndDate = getDateFromUTCDateStr(manifestEnd);

		if (this.animationStartDate && localDateToNoonUTC(latestStartDate).valueOf() < localDateToNoonUTC(this.animationStartDate).valueOf()) {
			latestStartDate = this.animationStartDate;
		}
		if (this.animationEndDate && localDateToNoonUTC(earliestEndDate).valueOf() > localDateToNoonUTC(this.animationEndDate).valueOf()) {
			earliestEndDate = this.animationEndDate;
		}

		const latestDates = getLatestDates({
			frequency,
			startDate: latestStartDate,
			endDate: earliestEndDate,
			missingDates
		}, numFrames);

		const result = latestDates.map(currDate => {
			if (type === 'wmts' && availableDates[dateToUTCString(currDate)]) {
				const { layerId } = availableDates[dateToUTCString(currDate)];

				return {
					type,
					layerId,
					dateObject: currDate,
					date: currDate.toISOString()
				};
			}

			return {
				date: currDate.toISOString(),
				dateObject: currDate,
				tilePathPrefix: `${this.externalId}/${calcPrefixFromDate(currDate)}`,
				externalId: this.externalId,
				title: this.title
			};
		});

		/**
		 * At this point we may have to set isLatest and  isLatestSeven again.
		 * This is because there are 2 ways to have the latest:
		 * - No dates are entered
		 * - Correct date range is entered that may also be the latest seven!
		 *
		 * Now is the point to check, dependent on result length and earliest end date matching the manifest end date.
		 * The VE manager handles this for wmts.
		 */
		const endDateMatchesManifest = localDateToNoonUTC(earliestEndDate).valueOf() === localDateToNoonUTC(getDateFromUTCDateStr(manifestEnd)).valueOf();

		const isLatest = endDateMatchesManifest && !this.hasAnimation && result.length === 1;
		const isLatestSeven = endDateMatchesManifest && this.hasAnimation && result.length === 7;

		this.setIsLatest(isLatest);
		this.setIsLatestSeven(isLatestSeven);

		return result;
	}

	setToLatestIndex() {
		this.setCurrentIndex(this.animations.length - 1);
	}

	prevDataset(pauseAnimation = true) {
		// Pause animation if necessary.
		pauseAnimation && this.setPaused(true);

		const { currentIndex } = datasetStore.stateSnapshot;
		const newIndex = currentIndex > 0 ? currentIndex - 1 : this.animations.length - 1;
		this.setCurrentIndex(newIndex);
	}

	nextDataset(pauseAnimation = true) {
		// Pause animation if necessary.
		pauseAnimation && this.setPaused(true);

		const { currentIndex } = datasetStore.stateSnapshot;
		const newIndex = currentIndex < this.animations.length - 1 ? currentIndex + 1 : 0;
		this.setCurrentIndex(newIndex);
	}

	// Makes sure global isDatasetLoading and paused are set to false, resets the animation back to its first frame.
	resetAnimation() {
		// Set global isDatasetLoading to null.
		datasetStore.setGlobalState({ isDatasetLoading: null });

		this.setPaused(false);
		this.setCurrentIndex(null);
	}

	/**
	 * Starts the animation interval. Only called by initAnimation
	 * @returns
	 */
	startAnimation() {
		// For safety, to prevent multiple intervals, stop animation if already running.
		this.stopAnimation();

		// Start animation interval.
		this._animationInterval = setInterval(() => {
			const { isTextureLoaded, currentDataset } = datasetStore.stateSnapshot;
			const { externalId } = currentDataset || {};

			// continue if dataset has wmts manager and it's not paused
			const { getTileManager } = globalRefs;
			if (getTileManager(externalId)) {
				if (this.isPaused) {
					return;
				}
			} else if (this.isPaused || !isTextureLoaded) {
				return;
			}

			const pauseAnimation = false;
			this.nextDataset(pauseAnimation);
		}, this._animIntervalTimeMs);

		// Set local isAnimating to true.
		this.isAnimating = true;
	}

	/**
	 * Simply clears the animation interval.
	 */
	stopAnimation() {
		clearInterval(this._animationInterval);
		this._animationInterval = null;
		this.isAnimating = false;
	}


	/**
	 * Sets the url query to trigger playing of the latest seven datasets for current vital sign.
	 */
	playLatestSeven() {
		const { getManager } = globalRefs;

		// Remove any start or end dates. Set animating to true.
		const queryOptions = {
			removeQueries: ['start', 'end'],
			newQueries: {
				animating: 'true'
			}
		};

		// Navigate to default animation.
		getManager('route').navigate(undefined, queryOptions);
	}

	/**
	 * Sets the url query to stop animation and trigger the latest dataset.
	 */
	resetToLatest() {
		const { getManager } = globalRefs;
		const removeQueries = ['start', 'end', 'animating'];

		getManager('route').navigate(undefined, { removeQueries });
		getManager('time').setRealTime();
	}

	/**
	 * Assemble thumbnail for a given dataset
	 * @param {object} dataset - dataset for the thumbnail
	 * @returns {string}
	 */
	getCylThumbSrc(dataset = this._dataset) {
		// if passed dataset is valid, use extrental id from it if available
		const { externalId = this.externalId } = dataset || {};

		const cylThumbFile = externalId ? `${externalId}/${externalId}_thumbnail.png` : dataset?.latestTileset;

		return cylThumbFile && `${Config.dynamicAssetsUrl}/earth/data/${cylThumbFile}`;
	}

	/**
	 * Returns current dataset's manifest data.
	 * @returns {object}
	 */
	getDatasetManifestData() {
		const { datasetManifests } = datasetStore.stateSnapshot;
		return this.externalId && datasetManifests?.[this.externalId];
	}

	getValueFromCanvas(x, y) {
		return this._canvasContext?.getImageData(x, y, 1, 1).data;
	}

	resetTextureLoadAbort() {
		this.textureLoadAbort = null;
	}
}

export default DatasetManager;
