/** @module pioneer */
import {
	BaseController,
	Downloader,
	Entity,
	Interval,
	MathUtils,
	OrbitalElements,
	Quaternion,
	Reader,
	Vector3
} from '../../internal';

/**
 * A base point from which all other point types derive.
*/
class Point {
	/**
	 * Load the point from the reader.
	 * @param {Reader} reader
	 */
	load(reader) {
		/**
		 * @type {number}
		 */
		this.time = reader.readFloat64();
	}

	/**
	 * Sets this to the calculated point in between two points.
	 * @param {[Point, Point]} _points
	 * @param {number} _time
	 * @param {Object<string, number>} _header
	 * @param {boolean} _incremental
	 * @abstract
	 */
	calculate(_points, _time, _header, _incremental) { }

	/**
	 * Apply the point to the entity.
	 * @param {Entity} _entity
	 * @abstract
	 */
	apply(_entity) { }

	/**
	 * Sets the entity's point to NaN to clear out any data.
	 * @param {Entity} _entity
	 * @abstract
	 */
	setNaN(_entity) { }

	/**
	 * Reads the header information for this type from the reader.
	 * @param {Reader} _reader
	 * @returns {Object<string, number>}
	 */
	static readHeader(_reader) {
		return {};
	}
}

class PosPoint extends Point {
	constructor() {
		super();
		this.position = new Vector3();
		this.velocity = new Vector3();
	}

	/**
	 * Load the point from the reader.
	 * @param {Reader} reader
	 * @override
	 */
	load(reader) {
		super.load(reader);

		this.position = new Vector3(reader.readFloat64(), reader.readFloat64(), reader.readFloat64());
		this.velocity = new Vector3(reader.readFloat64(), reader.readFloat64(), reader.readFloat64());
	}

	/**
	 * Sets this to the calculated point in between two points.
	 * @param {[PosPoint, PosPoint]} points
	 * @param {number} time
	 * @param {Object<string, number>} _header
	 * @param {boolean} _incremental
	 * @override
	 */
	calculate(points, time, _header, _incremental) {
		this.time = time;
		const totalT = points[1].time - points[0].time;
		const u = (time - points[0].time) / totalT;
		const oneSubU = 1.0 - u;
		const oneSubUSq = oneSubU * oneSubU;
		const uSq = u * u;
		const a = (1.0 + 2.0 * u) * oneSubUSq;
		const b = u * oneSubUSq;
		const c = uSq * (3.0 - 2.0 * u);
		const d = uSq * -oneSubU;
		this.position.mult(points[0].position, a);
		this.position.addMult(this.position, points[0].velocity, totalT * b);
		this.position.addMult(this.position, points[1].position, c);
		this.position.addMult(this.position, points[1].velocity, totalT * d);
		const sixUSqSubU = 6.0 * (uSq - u);
		const aV = sixUSqSubU;
		const bV = 3.0 * uSq - 4.0 * u + 1.0;
		const cV = -sixUSqSubU;
		const dV = 3.0 * uSq - 2.0 * u;
		this.velocity.mult(points[0].position, aV / totalT);
		this.velocity.addMult(this.velocity, points[0].velocity, bV);
		this.velocity.addMult(this.velocity, points[1].position, cV / totalT);
		this.velocity.addMult(this.velocity, points[1].velocity, dV);
	}

	/**
	 * Apply the point to the entity.
	 * @param {Entity} entity
	 * @override
	 */
	apply(entity) {
		entity.setPosition(this.position);
		entity.setVelocity(this.velocity);
	}

	/**
	 * Sets the entity's point to NaN to clear out any data.
	 * @param {Entity} entity
	 * @override
	 */
	setNaN(entity) {
		entity.setPosition(Vector3.NaN);
		entity.setVelocity(Vector3.NaN);
	}

	/**
	 * Reads the header information for this type from the reader.
	 * @param {Reader} _reader
	 * @returns {Object<string, number>}
	 * @override
	 */
	static readHeader(_reader) {
		return {};
	}
}

class LinPoint extends Point {
	constructor() {
		super();
		this.position = new Vector3();
		this.velocity = new Vector3();
	}

	/**
	 * Load the point from the reader.
	 * @param {Reader} reader
	 * @override
	 */
	load(reader) {
		super.load(reader);
		this.position.set(reader.readFloat64(), reader.readFloat64(), reader.readFloat64());
		this.velocity.copy(Vector3.Zero);
	}

	/**
	 * Sets this to the calculated point in between two points.
	 * @param {[LinPoint, LinPoint]} points
	 * @param {number} time
	 * @param {Object<string, number>} _header
	 * @param {boolean} _incremental
	 * @override
	 */
	calculate(points, time, _header, _incremental) {
		this.time = time;
		if (points[1].time > points[0].time) {
			const u = MathUtils.clamp01((time - points[0].time) / (points[1].time - points[0].time));
			this.position.lerp(points[0].position, points[1].position, u);
			this.velocity.sub(points[1].position, points[0].position);
			this.velocity.div(this.velocity, points[1].time - points[0].time);
		}
		else {
			this.position.copy(points[1].position);
			this.velocity.copy(Vector3.Zero);
		}
	}

	/**
	 * Apply the point to the entity.
	 * @param {Entity} entity
	 * @override
	 */
	apply(entity) {
		entity.setPosition(this.position);
		entity.setVelocity(this.velocity);
	}

	/**
	 * Sets the entity's point to NaN to clear out any data.
	 * @param {Entity} entity
	 * @override
	 */
	setNaN(entity) {
		entity.setPosition(Vector3.NaN);
		entity.setVelocity(Vector3.NaN);
	}

	/**
	 * Reads the header information for this type from the reader.
	 * @param {Reader} _reader
	 * @returns {Object<string, number>}
	 * @override
	 */
	static readHeader(_reader) {
		return {};
	}
}

class OriPoint extends Point {
	constructor() {
		super();
		this.orientation = new Quaternion();
		this.angularVelocity = new Vector3();
	}

	/**
	 * Load the point from the reader.
	 * @param {Reader} reader
	 * @override
	 */
	load(reader) {
		super.load(reader);

		this.orientation = new Quaternion(reader.readFloat64(), reader.readFloat64(), reader.readFloat64(), reader.readFloat64());
		this.angularVelocity = new Vector3(reader.readFloat64(), reader.readFloat64(), reader.readFloat64());
	}

	/**
	 * Sets this to the calculated point in between two points.
	 * @param {[OriPoint, OriPoint]} points
	 * @param {number} time
	 * @param {Object<string, number>} _header
	 * @param {boolean} incremental
	 * @override
	 */
	calculate(points, time, _header, incremental) {
		this.time = time;
		const orientation0 = Quaternion.pool.get();
		const orientation1 = Quaternion.pool.get();
		const u = (time - points[0].time) / (points[1].time - points[0].time);
		points[0]._project(orientation0, time, incremental);
		points[1]._project(orientation1, time, incremental);
		this.orientation.slerp(orientation0, orientation1, u);
		Quaternion.pool.release(orientation0);
		Quaternion.pool.release(orientation1);
		this.angularVelocity.slerp(points[0].angularVelocity, points[1].angularVelocity, u);
		this.angularVelocity.neg(this.angularVelocity);
		this.angularVelocity.rotate(this.orientation, this.angularVelocity);
	}

	/**
	 * Apply the point to the entity.
	 * @param {Entity} entity
	 * @override
	 */
	apply(entity) {
		entity.setOrientation(this.orientation);
		entity.setAngularVelocity(this.angularVelocity);
	}

	/**
	 * Apply the point, projected in time, to the orientation, if it applies.
	 * @param {Quaternion} orientation
	 * @param {number} time
	 * @param {boolean} incremental
	 */
	_project(orientation, time, incremental) {
		const rotationAxis = Vector3.pool.get();
		const rotation = Quaternion.pool.get();
		rotationAxis.normalize(this.angularVelocity);
		rotation.setFromAxisAngle(rotationAxis, this.angularVelocity.magnitude() * (time - this.time));
		Vector3.pool.release(rotationAxis);
		if (incremental) {
			rotation.multInverseR(this.orientation, rotation);
			orientation.mult(orientation, rotation);
		}
		else {
			orientation.multInverseR(this.orientation, rotation);
		}
		Quaternion.pool.release(rotation);
	}

	/**
	 * Sets the entity's point to NaN to clear out any data.
	 * @param {Entity} entity
	 * @override
	 */
	setNaN(entity) {
		entity.setOrientation(Quaternion.NaN);
		entity.setAngularVelocity(Vector3.NaN);
	}

	/**
	 * Reads the header information for this type from the reader.
	 * @param {Reader} _reader
	 * @returns {Object<string, number>}
	 * @override
	 */
	static readHeader(_reader) {
		return {};
	}
}

class QuatPoint extends Point {
	constructor() {
		super();
		this.orientation = new Quaternion();
		this.angularVelocity = new Vector3();
	}

	/**
	 * Load the point from the reader.
	 * @param {Reader} reader
	 * @override
	 */
	load(reader) {
		super.load(reader);

		this.orientation = new Quaternion(reader.readFloat64(), reader.readFloat64(), reader.readFloat64(), reader.readFloat64());
	}

	/**
	 * Sets this to the calculated point in between two points.
	 * @param {[QuatPoint, QuatPoint]} points
	 * @param {number} time
	 * @param {Object<string, number>} _header
	 * @param {boolean} _incremental
	 * @override
	 */
	calculate(points, time, _header, _incremental) {
		this.time = time;
		const u = MathUtils.clamp01((time - points[0].time) / (points[1].time - points[0].time));
		this.orientation.slerp(points[0].orientation, points[1].orientation, u);
	}

	/**
	 * Apply the point to the entity.
	 * @param {Entity} entity
	 * @override
	 */
	apply(entity) {
		entity.setOrientation(this.orientation);
		entity.setAngularVelocity(Vector3.Zero);
	}

	/**
	 * Sets the entity's point to NaN to clear out any data.
	 * @param {Entity} entity
	 * @override
	 */
	setNaN(entity) {
		entity.setOrientation(Quaternion.NaN);
		entity.setAngularVelocity(Vector3.NaN);
	}

	/**
	 * Reads the header information for this type from the reader.
	 * @param {Reader} _reader
	 * @returns {Object<string, number>}
	 * @override
	 */
	static readHeader(_reader) {
		return {};
	}
}

class OrbPoint extends Point {
	constructor() {
		super();
		this.oe = new OrbitalElements();
		this.oe.orbitOrientation.freeze();

		this.position = new Vector3();
		this.velocity = new Vector3();
	}

	/**
	 * Load the point from the reader.
	 * @param {Reader} reader
	 * @override
	 */
	load(reader) {
		super.load(reader);
		this.oe.epoch = this.time;

		this.oe.semiMajorAxis = reader.readFloat64();
		this.oe.eccentricity = reader.readFloat64();
		this.oe.meanAngularMotion = reader.readFloat64();
		this.oe.meanAnomalyAtEpoch = reader.readFloat64();
		this.oe.orbitOrientation.thaw();
		this.oe.orbitOrientation.set(reader.readFloat64(), reader.readFloat64(), reader.readFloat64(), reader.readFloat64());
		this.oe.orbitOrientation.freeze();
	}

	/**
	 * Sets this to the calculated point in between two points.
	 * @param {[OrbPoint, OrbPoint]} points
	 * @param {number} time
	 * @param {Object<string, number>} header
	 * @param {boolean} incremental
	 * @override
	 */
	calculate(points, time, header, incremental) {
		this.time = time;
		this.oe.epoch = time;
		const position0 = Vector3.pool.get();
		const position1 = Vector3.pool.get();
		const velocity0 = Vector3.pool.get();
		const velocity1 = Vector3.pool.get();
		const u = MathUtils.clamp01((time - points[0].time) / (points[1].time - points[0].time));
		points[0]._project(position0, velocity0, time, header, incremental);
		points[1]._project(position1, velocity1, time, header, incremental);
		this.position.lerp(position0, position1, u);
		this.velocity.lerp(velocity0, velocity1, u);
		Vector3.pool.release(position0);
		Vector3.pool.release(position1);
		Vector3.pool.release(velocity0);
		Vector3.pool.release(velocity1);
	}

	/**
	 * Apply the point to the entity.
	 * @param {Entity} entity
	 * @override
	 */
	apply(entity) {
		entity.setPosition(this.position);
		entity.setVelocity(this.velocity);
	}

	/**
	 * Apply the point, projected in time, to the position, if it applies.
	 * @param {Vector3} position
	 * @param {Vector3} velocity
	 * @param {number} time
	 * @param {Object<string, number>} header
	 * @param {boolean} incremental
	 */
	_project(position, velocity, time, header, incremental) {
		const newPosition = Vector3.pool.get();
		const newVelocity = Vector3.pool.get();

		// Project the position and velocity.
		this.oe.project(newPosition, newVelocity, time);

		// Get and multiply by the body factor so that the orbit works relative to the other object and not the focus.
		let bodyFactor = 1;
		if (header['body'] === 1) {
			bodyFactor = header['gravitationalParameter2'] / (header['gravitationalParameter1'] + header['gravitationalParameter2']);
		}
		else if (header['body'] === 2) {
			bodyFactor = -header['gravitationalParameter1'] / (header['gravitationalParameter1'] + header['gravitationalParameter2']);
		}
		newPosition.mult(newPosition, bodyFactor);
		newVelocity.mult(newVelocity, bodyFactor);

		// Rotate the vectors by the orbit orientation.
		if (incremental) {
			position.add(newPosition, position);
		}
		else {
			position.copy(newPosition);
		}
		velocity.copy(newVelocity);
		Vector3.pool.release(newPosition);
		Vector3.pool.release(newVelocity);
	}

	/**
	 * Sets the entity's point to NaN to clear out any data.
	 * @param {Entity} entity
	 * @override
	 */
	setNaN(entity) {
		entity.setPosition(Vector3.NaN);
		entity.setVelocity(Vector3.NaN);
	}

	/**
	 * Reads the header information for this type from the reader.
	 * @param {Reader} reader
	 * @returns {Object<string, number>}
	 * @override
	 */
	static readHeader(reader) {
		return {
			gravitationalParameter1: reader.readFloat64(),
			gravitationalParameter2: reader.readFloat64()
		};
	}
}

class PointSet {
	/**
	 * @param {typeof Point} PointClass
	 * @param {number} version
	 * @param {number} numberOfDigits
	 * @param {string} name
	 */
	constructor(PointClass, version, numberOfDigits, name) {
		/**
		 * The type of data.
		 * @type {typeof Point}
		 */
		this._PointClass = PointClass;

		/**
		 * The version of the dynamo type.
		 * @type {number}
		 * @private
		 */
		this._version = version;

		/**
		 * The number of digits for every index file's level.
		 * @type {number}
		 * @private
		 */
		this._numberOfDigits = numberOfDigits;

		/**
		 * The name to be used when loading the point set.
		 * @type {string}
		 */
		this._name = name;

		/**
		 * The array of points sets.
		 * @type {PointSet[]}
		 */
		this._pointSets = [];

		/**
		 * The array of points.
		 * @type {Point[]}
		 */
		this._points = [];

		/**
		 * The hint index used for getting the point or point set index.
		 * @type {number}
		 * @private
		 */
		this._hintIndex = 0;

		/**
		 * The interval which covers the points.
		 * @type {Interval}
		 */
		this._interval = new Interval(Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY);

		/**
		 * State that determines whether the point set data is not loaded, loading, or loaded.
		 * @type {number}
		 */
		this._loadedState = PointSet.State.NOT_LOADED;

		/**
		 * The timestamp when this point set was loaded.
		 * @type {number}
		 */
		this._accessedTime = Number.POSITIVE_INFINITY;
	}

	/**
	 * Returns the name of the point set, used in downloading the file.
	 * @returns {string}
	 */
	getName() {
		return this._name;
	}

	/**
	 * Returns the loaded state of the point set.
	 * @returns {number}
	 */
	getLoadedState() {
		return this._loadedState;
	}

	/**
	 * Returns the loaded state of the point set.
	 * @returns {number}
	 */
	getLoadedTime() {
		return this._accessedTime;
	}

	/**
	 * Returns true if the point set has point sets.
	 * @returns {boolean}
	 */
	hasPointSets() {
		return this._pointSets.length !== 0;
	}

	/**
	 * Returns true if the point set has points.
	 * @returns {boolean}
	 */
	hasPoints() {
		return this._points.length !== 0;
	}

	/**
	 * Loads the data from a reader.
	 * @param {Reader} reader
	 */
	load(reader) {
		// Point set if it is in the definition file.
		let containsPoints = true;
		if (this._version === 2 || (this._version === 1 && this._name === 'def')) {
			containsPoints = (reader.readByte() === 1);
		}

		if (containsPoints) {
			// Read the data points.
			const numPoints = reader.readInt32();
			for (let i = 0; i < numPoints; i++) {
				const point = new this._PointClass();
				point.load(reader);
				this._points.push(point);
			}
			// Adjust the interval to match the data points.
			if (this._points.length > 0) {
				this._interval.min = this._points[0].time;
				this._interval.max = this._points[this._points.length - 1].time;
			}
		}
		else {
			// Read the data sets.
			const numPointSets = reader.readInt32();
			if (this._version === 1) {
				this._numberOfDigits = Math.ceil(Math.log10(numPointSets));
			}
			for (let i = 0; i < numPointSets; i++) {
				let newName = '';
				if (this._name === 'def') {
					newName = (i + '').padStart(this._numberOfDigits, '0');
				}
				else {
					newName = this._name + '_' + (i + '').padStart(this._numberOfDigits, '0');
				}
				const pointSet = new PointSet(this._PointClass, this._version, this._numberOfDigits, newName);
				pointSet._interval.min = reader.readFloat64();
				if (this._version === 1) {
					pointSet._interval.max = reader.readFloat64();
				}
				else {
					if (i > 0) {
						this._pointSets[i - 1]._interval.max = pointSet._interval.min;
					}
				}
				this._pointSets.push(pointSet);
			}
			if (this._version === 2) {
				this._pointSets.splice(this._pointSets.length - 1, 1);
			}
			// Adjust the interval to match the data points.
			if (this._pointSets.length > 0) {
				this._interval.min = this._pointSets[0]._interval.min;
				this._interval.max = this._pointSets[this._pointSets.length - 1]._interval.max;
			}
		}

		// Set the loaded flag to true and set the time loaded.
		this._loadedState = PointSet.State.LOADED;
		this._accessedTime = Date.now();
	}

	/**
	 * Loads the point set from a url.
	 * @param {Downloader} downloader
	 * @param {string} baseUrl
	 * @param {number} downloadPriority
	 */
	loadFromUrl(downloader, baseUrl, downloadPriority) {
		this._loadedState = PointSet.State.LOADING;
		downloader.download(baseUrl + '/' + this._name + '.dyn', true, downloadPriority).then(async (download) => {
			if (download.status === 'cancelled') {
				return Promise.resolve();
			}
			else if (download.status === 'failed') {
				this._loadedState = PointSet.State.FAILED;
				return Promise.resolve();
			}
			if (!(download.content instanceof ArrayBuffer)) {
				return Promise.reject(new Error('Failed to load dynamo controller file "' + download.url + '": Not a binary file.'));
			}
			const reader = new Reader(download.content);
			this.load(reader);
		});
	}

	/**
	 * Unloads one point set that hasn't been accessed recently. Returns true if a point set was unloaded.
	 * @returns {boolean}
	 */
	unloadOldPointSet() {
		// Remove the oldest point set.
		for (let i = 0, l = this._pointSets.length; i < l; i++) {
			const pointSet = this._pointSets[i];
			if (pointSet._loadedState === PointSet.State.LOADED) {
				if (Date.now() - pointSet._accessedTime > 15000) { // If this point set hasn't been accessed in 15 seconds, expire it.
					pointSet.unload();
					return true;
				}
				else {
					if (pointSet.unloadOldPointSet()) {
						return true;
					}
				}
			}
		}
		return false;
	}

	/**
	 * Unloads the data.
	 */
	unload() {
		this._loadedState = PointSet.State.NOT_LOADED;
		this._accessedTime = Number.POSITIVE_INFINITY;
		this._pointSets = [];
		this._points = [];
	}

	/**
	 * Gets the point set index for the given time, using a starting index as a hint to make things faster.
	 * @param {number} time
	 * @returns {PointSet}
	 */
	getPointSet(time) {
		this._accessedTime = Date.now();
		if (this._pointSets.length === 0) {
			return null;
		}
		// Use hint index to see if we can quickly find the right point set.
		if (this._pointSets[this._hintIndex]._interval.contains(time)) {
			// do nothing
		}
		else if (this._hintIndex - 1 >= 0 && this._pointSets[this._hintIndex - 1]._interval.contains(time)) {
			this._hintIndex -= 1;
		}
		else if (this._hintIndex + 1 < this._pointSets.length && this._pointSets[this._hintIndex + 1]._interval.contains(time)) {
			this._hintIndex += 1;
		}
		// Do a binary search to find the right point set index.
		else {
			let min = 0;
			let max = this._pointSets.length - 1;
			let index = 0;
			while (min !== max) {
				index = Math.ceil((min + max) / 2);
				if (time < this._pointSets[index]._interval.min) {
					max = index - 1;
				}
				else {
					min = index;
				}
			}
			this._hintIndex = min;
		}
		return this._pointSets[this._hintIndex];
	}

	/**
	 * Gets the point index for the given time, using a starting index as a hint to make things faster.
	 * @param {[Point | null, Point | null]} points
	 * @param {number} time
	 */
	getPoints(points, time) {
		this._accessedTime = Date.now();
		if (this._points.length === 0) {
			points[0] = null;
			points[1] = null;
			return;
		}
		// Use hint index to see if we can quickly find the right point.
		if (this._hintIndex + 1 < this._points.length && this._points[this._hintIndex].time <= time && time < this._points[this._hintIndex + 1].time) {
			// do nothing
		}
		else if (this._hintIndex - 1 >= 0 && this._points[this._hintIndex - 1].time <= time && time < this._points[this._hintIndex].time) {
			this._hintIndex -= 1;
		}
		else if (this._hintIndex + 2 < this._points.length && this._points[this._hintIndex + 1].time <= time && time < this._points[this._hintIndex + 2].time) {
			this._hintIndex += 1;
		}
		// Do a binary search to find the right point index.
		else {
			let min = 0;
			let max = this._points.length - 2;
			let index = 0;
			while (min !== max) {
				index = Math.ceil((min + max) / 2);
				if (time < this._points[index].time) {
					max = index - 1;
				}
				else {
					min = index;
				}
			}
			this._hintIndex = min;
		}
		points[0] = this._points[this._hintIndex];
		points[1] = this._points[this._hintIndex + 1];
	}
}

PointSet.State = {
	NOT_LOADED: 0,
	LOADING: 1,
	LOADED: 2,
	FAILED: 3
};

/**
 * A controller that animates via the Dynamo file format.
 */
export class DynamoController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The base url within which the Dynamo files are contained.
		 * @type {string}
		 * @private
		 */
		this._baseUrl = '';

		/**
		 * The flag that says whether all data is loaded for the current time.
		 * @type {boolean}
		 * @private
		 */
		this._dataLoadedAtCurrentTime = false;

		/**
		 * The time offset that is applied to the dynamo when reading data.
		 * @type {number}
		 * @private
		 */
		this._timeOffset = 0;

		/**
		 * The version of the dynamo type.
		 * @type {number}
		 * @private
		 */
		this._version = 0;

		/**
		 * The point type.
		 * @type {string}
		 * @private
		 */
		this._pointType = '';

		/**
		 * The class of the point type.
		 * @type {typeof Point}
		 * @private
		 */
		this._PointClass = null;

		/**
		 * The number of digits for every index file's level.
		 * @type {number}
		 * @private
		 */
		this._numberOfDigits = 0;

		/**
		 * Point-specific header information for use in calculations.
		 * @type {Object<string, number>}
		 * @private
		 */
		this._header = {
			body: 0
		};

		/**
		 * A flag that determines whether the controller incrementally changes the position each frame instead of setting the position.
		 * @type {boolean}
		 * @private
		 */
		this._incremental = false;

		/**
		 * The root point set in the definition file.
		 * @type {PointSet}
		 * @private
		 */
		this._pointSet = null;

		/**
		 * A reference to the downloader for downloading files.
		 * @type {Downloader}
		 * @private
		 */
		this._downloader = entity.getScene().getEngine().getDownloader();

		/**
		 * Temporary point for calculations.
		 * @type {[Point | null, Point | null]}
		 * @private
		 */
		this._points = [null, null];

		/**
		 * The last known good points used in the update function.
		 * @type {[Point | null, Point | null]}
		 * @private
		 */
		this._lastPoints = [null, null];

		/**
		 * The last time used in the update function.
		 * @type {number}
		 * @private
		 */
		this._lastTime = Number.NaN;

		/**
		 * Temporary point for calculations.
		 * @type {Point}
		 * @private
		 */
		this._pCalc = null;

		/**
		 * The user coverage that limits the coverage coming from the data.
		 * @type {Interval}
		 * @private
		 */
		this._userCoverage = new Interval(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY);
		this._userCoverage.freeze();
	}

	/**
	 * Gets the point type. It can be 'pos', 'lin', 'orb', 'ori', or 'quat'.
	 * @returns {string}
	 */
	getPointType() {
		return this._pointType;
	}

	/**
	 * Gets the value of a header key.
	 * @param {string} key
	 * @returns {number}
	 */
	getHeaderValue(key) {
		return this._header[key];
	}

	/**
	 * Sets a key and value in the header information that the controller may use when updating.
	 * @param {string} key
	 * @param {number} value
	 */
	setHeaderValue(key, value) {
		this._header[key] = value;
	}

	/**
	 * Returns true if the controller incrementally changes the position each frame instead of setting the position. Defaults to false.
	 * @returns {boolean}
	 */
	isIncremental() {
		return this._incremental;
	}

	/**
	 * Sets whether the controller incrementally changes the position each frame instead of setting the position.
	 * @param {boolean} incremental
	 */
	setIncremental(incremental) {
		this._incremental = incremental;
	}

	/**
	 * Gets the base url within which the Dynamo files are contained.
	 * @returns {string}
	 */
	getBaseUrl() {
		return this._baseUrl;
	}

	/**
	 * Sets the base url within which the Dynamo files are contained.
	 * @param {string} baseUrl
	 */
	setBaseUrl(baseUrl) {
		// Clear out previous dynamo.
		if (this._baseUrl !== '') {
			this._downloader.cancel(this._baseUrl + '/def.dyn');
			this._pointSet = null;
			this._PointClass = null;
			this._userCoverage.thaw();
			this._userCoverage = new Interval(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY);
			this._userCoverage.freeze();
			super.setCoverage(new Interval(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY));
			this.removeModifiedState('position');
			this.removeModifiedState('velocity');
			this.removeModifiedState('orientation');
			this.removeModifiedState('angularVelocity');
			this._dataLoadedAtCurrentTime = false;
		}

		this._baseUrl = baseUrl;
		if (this._baseUrl.endsWith('/')) {
			this._baseUrl = this._baseUrl.slice(0, -1);
		}

		// Load the def. If it fails, output the message.
		// Can't throw upward because it doesn't return a promise.
		this._loadDef().catch((e) => {
			console.error(e.message);
		});
	}

	/**
	 * Gets a promise when the initial dynamo (the def file) is loaded.
	 * @returns {Promise<void>}
	 * @override
	 */
	getLoadedPromise() {
		return new Promise((resolve) => {
			const engine = this.getEntity().getScene().getEngine();
			const callback = () => {
				const time = engine.getTime();
				if (this.isDestroyed() || !this.isEnabled() || !this.getEntity().isEnabled() || this._baseUrl === '' || (this._pointSet !== null && !this.getCoverage().contains(time)) || this._dataLoadedAtCurrentTime) {
					engine.removeCallback(callback);
					resolve();
				}
			};
			engine.addCallback(callback, true);
		});
	}

	/**
	 * Gets the time offset that is applied to the dynamo when reading data.
	 * @returns {number}
	 */
	getTimeOffset() {
		return this._timeOffset;
	}

	/**
	 * Sets the time offset that is applied to the dynamo when reading data.
	 * @param {number} timeOffset
	 */
	setTimeOffset(timeOffset) {
		const oldTimeOffset = this._timeOffset;
		this._timeOffset = timeOffset;
		// Adjust the coverage offset to reflect new offset.
		const newCoverage = new Interval();
		newCoverage.copy(this.getCoverage());
		newCoverage.min += this._timeOffset - oldTimeOffset;
		newCoverage.max += this._timeOffset - oldTimeOffset;
		super.setCoverage(newCoverage);
		// It doesn't change the point set intervals.
	}

	/**
	 * Sets the time interval over which the controller is valid.
	 * @param {Interval} coverage
	 * @override
	 */
	setCoverage(coverage) {
		this._userCoverage.thaw();
		this._userCoverage.copy(coverage);
		this._userCoverage.freeze();
		const finalCoverage = Interval.pool.get();
		if (this._pointSet !== null) {
			finalCoverage.intersection(this._userCoverage, this._pointSet._interval);
		}
		else {
			finalCoverage.copy(new Interval(Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY));
		}
		finalCoverage.min += this._timeOffset;
		finalCoverage.max += this._timeOffset;
		super.setCoverage(finalCoverage);
		Interval.pool.release(finalCoverage);
	}

	/**
	 * Gets the orbital elements at the given time.
	 * @param {OrbitalElements} orbitalElements
	 * @param {number} time
	 */
	getOrbitalElements(orbitalElements, time) {
		if (this._PointClass === OrbPoint && this._pointSet !== null) {
			this._getPointsAtTime(this._points, time);
			if (this._points[0] !== null) {
				this._lastPoints[0] = this._points[0];
				this._lastPoints[1] = this._points[1];
			}
			if (this._lastPoints[0] !== null) {
				const oe0 = /** @type {OrbPoint} */(this._lastPoints[0]).oe;
				const oe1 = /** @type {OrbPoint} */(this._lastPoints[1]).oe;
				const u = MathUtils.clamp01((time - this._lastPoints[0].time) / (this._lastPoints[1].time - this._lastPoints[0].time));
				orbitalElements.eccentricity = MathUtils.lerp(oe0.eccentricity, oe1.eccentricity, u);
				orbitalElements.semiMajorAxis = MathUtils.lerp(oe0.semiMajorAxis, oe1.semiMajorAxis, u);
				orbitalElements.epoch = MathUtils.lerp(oe0.epoch, oe1.epoch, u);
				orbitalElements.meanAngularMotion = MathUtils.lerp(oe0.meanAngularMotion, oe1.meanAngularMotion, u);
				orbitalElements.meanAnomalyAtEpoch = MathUtils.lerpAngle(oe0.meanAnomalyAtEpoch, oe1.meanAnomalyAtEpoch, u);
				orbitalElements.orbitOrientation.slerp(oe0.orbitOrientation, oe1.orbitOrientation, u);
			}
		}
	}

	/**
	 * Gets the eccentricity of the orbit at the given time, assuming it is the 'orb' type.
	 * @param {number} time
	 * @returns {number}
	 */
	getEccentricity(time) {
		if (this._PointClass === OrbPoint && this._pointSet !== null) {
			this._getPointsAtTime(this._points, time);
			if (this._points[0] !== null) {
				this._lastPoints[0] = this._points[0];
				this._lastPoints[1] = this._points[1];
			}
			if (this._lastPoints[0] !== null) {
				const u = MathUtils.clamp01((time - this._lastPoints[0].time) / (this._lastPoints[1].time - this._lastPoints[0].time));
				return MathUtils.lerp(/** @type {OrbPoint} */(this._lastPoints[0]).oe.eccentricity, /** @type {OrbPoint} */(this._lastPoints[1]).oe.eccentricity, u);
			}
		}
		return Number.NaN;
	}

	/**
	 * Gets the semi-major axis of the orbit at the given time, assuming it is the 'orb' type.
	 * @param {number} time
	 * @returns {number}
	 */
	getSemiMajorAxis(time) {
		if (this._PointClass === OrbPoint && this._pointSet !== null) {
			this._getPointsAtTime(this._points, time);
			if (this._points[0] !== null) {
				this._lastPoints[0] = this._points[0];
				this._lastPoints[1] = this._points[1];
			}
			if (this._lastPoints[0] !== null) {
				const u = MathUtils.clamp01((time - this._lastPoints[0].time) / (this._lastPoints[1].time - this._lastPoints[0].time));
				return MathUtils.lerp(/** @type {OrbPoint} */(this._lastPoints[0]).oe.semiMajorAxis, /** @type {OrbPoint} */(this._lastPoints[1]).oe.semiMajorAxis, u);
			}
		}
		return Number.NaN;
	}

	/**
	 * Gets the orbit orientation of the orbit at the given time, assuming it is the 'orb' type. The x-axis points toward the periapsis and the z-axis points in the direction of the angular momentum.
	 * @param {Quaternion} outOrbitOrientation
	 * @param {number} time
	 */
	getOrbitOrientation(outOrbitOrientation, time) {
		if (this._PointClass === OrbPoint && this._pointSet !== null) {
			this._getPointsAtTime(this._points, time);
			if (this._points[0] !== null) {
				this._lastPoints[0] = this._points[0];
				this._lastPoints[1] = this._points[1];
			}
			if (this._lastPoints[0] !== null) {
				const u = MathUtils.clamp01((time - this._lastPoints[0].time) / (this._lastPoints[1].time - this._lastPoints[0].time));
				outOrbitOrientation.slerp(/** @type {OrbPoint} */(this._lastPoints[0]).oe.orbitOrientation, /** @type {OrbPoint} */(this._lastPoints[1]).oe.orbitOrientation, u);
			}
		}
	}

	/**
	 * Destroys any resources with the dynamo.
	 * @override
	 * @internal
	 */
	__destroy() {
		// Cancel any download.
		if (this._baseUrl !== '') {
			this._downloader.cancel(this._baseUrl + '/def.dyn');
		}
		super.__destroy();
	}

	/**
	 * If the type has positions, updates the position.
	 * @param {Vector3} position
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updatePositionAtTime(position, time) {
		if (this._pointSet === null) { // No def file loaded yet, so the time may or may not be covered, so set it to NaN.
			position.copy(Vector3.NaN);
			return;
		}
		if (/** @type {PosPoint | LinPoint | OrbPoint} */(this._pCalc).position === undefined || !this.getCoverage().contains(time)) {
			return; // It's not a position type or it's not covered.
		}
		if (this._pCalc.time !== time) {
			this._getPointsAtTime(this._points, time);
			if (this._points[0] === null) { // No valid data yet, so set to NaN.
				position.copy(Vector3.NaN);
				return;
			}
			this._pCalc.calculate(this._points, time - this._timeOffset, this._header, this._incremental);
		}
		position.copy(/** @type {PosPoint | LinPoint | OrbPoint} */(this._pCalc).position);
	}

	/**
	 * If the type has positions, updates the velocity.
	 * @param {Vector3} velocity
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updateVelocityAtTime(velocity, time) {
		if (this._pointSet === null) { // No def file loaded yet, so the time may or may not be covered, so set it to NaN.
			velocity.copy(Vector3.NaN);
			return;
		}
		else if (/** @type {PosPoint | LinPoint | OrbPoint} */(this._pCalc).velocity === undefined || !this.getCoverage().contains(time)) {
			return; // It's not a velocity type or it's not covered.
		}
		if (this._pCalc.time !== time) {
			this._getPointsAtTime(this._points, time);
			if (this._points[0] === null) { // No valid data yet, so set to NaN.
				velocity.copy(Vector3.NaN);
				return;
			}
			this._pCalc.calculate(this._points, time - this._timeOffset, this._header, this._incremental);
		}
		velocity.copy(/** @type {PosPoint | LinPoint | OrbPoint} */(this._pCalc).velocity);
	}

	/**
	 * If the type has orientations, updates the orientation.
	 * @param {Quaternion} orientation
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updateOrientationAtTime(orientation, time) {
		if (this._pointSet === null) { // No def file loaded yet, so the time may or may not be covered, so set it to NaN.
			orientation.copy(Quaternion.NaN);
			return;
		}
		if (/** @type {OriPoint | QuatPoint} */(this._pCalc).orientation === undefined || !this.getCoverage().contains(time)) {
			return; // It's not an orientation type or it's not covered.
		}
		if (this._pCalc.time !== time) {
			this._getPointsAtTime(this._points, time);
			if (this._points[0] === null) { // No valid data yet, so set to NaN.
				orientation.copy(Quaternion.NaN);
				return;
			}
			this._pCalc.calculate(this._points, time - this._timeOffset, this._header, this._incremental);
		}
		orientation.copy(/** @type {OriPoint | QuatPoint} */(this._pCalc).orientation);
	}

	/**
	 * Updates the controller.
	 * @override
	 * @internal
	 */
	__update() {
		const entity = this.getEntity();
		const engine = entity.getScene().getEngine();
		const time = engine.getTime();
		if (this._pointSet !== null) {
			this._pointSet.unloadOldPointSet();
			this._getPointsAtTime(this._points, time);
			if (this._points[0] !== null) {
				// Apply the data.
				this._pCalc.calculate(this._points, time - this._timeOffset, this._header, this._incremental);
				this._pCalc.apply(entity);
				// Record these as the last known good values.
				this._lastPoints[0] = this._points[0];
				this._lastPoints[1] = this._points[1];
				this._dataLoadedAtCurrentTime = true;
			}
			// Data for the current time is still loading.
			else {
				// If we've got good previous data, apply it, otherwise do nothing.
				if (this._lastPoints[0] !== null && this._lastPoints[1] !== null) {
					// Apply the last known good data.
					this._pCalc.calculate(this._lastPoints, time - this._timeOffset, this._header, this._incremental);
					this._pCalc.apply(entity);
				}
				this._dataLoadedAtCurrentTime = false;
			}
		}
		else { // No def.dyn file has yet been loaded.
			this.getEntity().setPosition(Vector3.NaN);
			this.getEntity().setVelocity(Vector3.NaN);
			this.getEntity().setOrientation(Quaternion.NaN);
			this.getEntity().setAngularVelocity(Vector3.NaN);
			this._dataLoadedAtCurrentTime = false;
		}
		this._lastTime = time;
	}

	/**
	 * Loads a def file.
	 * @returns {Promise<void>}
	 * @private
	 */
	async _loadDef() {
		// Save the url here since it may change in the async process.
		const url = this._baseUrl;

		/**
		 * The promise that is used to tell when the dyndef is loaded.
		 * @type {Promise<void>}
		 */
		return this._downloader.download(this._baseUrl + '/def.dyn', true, -this.getEntity().getLeastCameraDepth()).then(async (download) => {
			if (download.status === 'cancelled') {
				return Promise.resolve();
			}
			else if (download.status === 'failed') {
				return Promise.reject(new Error('Failed to load dynamo controller file "' + download.url + '": ' + download.errorMessage));
			}
			if (!(download.content instanceof ArrayBuffer)) {
				return Promise.reject(new Error('Failed to load dynamo controller file "' + download.url + '": Not a binary file.'));
			}

			// Open the reader.
			const reader = new Reader(download.content);

			// Version
			this._version = reader.readInt16();
			if (this._version !== 1 && this._version !== 2) {
				throw new Error(download.url + ' is not Dynamo version 1 or 2');
			}

			// Type
			this._pointType = reader.readString();
			if (this._pointType === 'pos') {
				this._PointClass = PosPoint;
			}
			else if (this._pointType === 'lin') {
				this._PointClass = LinPoint;
			}
			else if (this._pointType === 'ori') {
				this._PointClass = OriPoint;
			}
			else if (this._pointType === 'quat') {
				this._PointClass = QuatPoint;
			}
			else if (this._pointType === 'orb') {
				this._PointClass = OrbPoint;
			}

			// Set the calculation point.
			this._pCalc = new this._PointClass();

			// Let the base controller know that this changes the position or orientation.
			if (this._PointClass === PosPoint || this._PointClass === LinPoint || this._PointClass === OrbPoint) {
				this.addModifiedState('position');
				this.addModifiedState('velocity');
				this.removeModifiedState('orientation');
				this.removeModifiedState('angularVelocity');
			}
			else if (this._PointClass === OriPoint || this._PointClass === QuatPoint) {
				this.addModifiedState('orientation');
				this.addModifiedState('angularVelocity');
				this.removeModifiedState('position');
				this.removeModifiedState('velocity');
			}

			// Read the number of digits for each level of the file names.
			if (this._version === 2) {
				this._numberOfDigits = reader.readByte();
			}

			// Header
			this._header = Object.assign(this._header, this._PointClass.readHeader(reader));

			this._pointSet = new PointSet(this._PointClass, this._version, this._numberOfDigits, 'def');
			this._pointSet.load(reader);

			// Update the coverage.
			const finalCoverage = Interval.pool.get();
			finalCoverage.intersection(this._userCoverage, this._pointSet._interval);
			finalCoverage.min += this._timeOffset;
			finalCoverage.max += this._timeOffset;
			super.setCoverage(finalCoverage);
			Interval.pool.release(finalCoverage);
		}).catch((error) => {
			if (error instanceof Error) {
				error.message = `While loading dynamo "${url}/def.dyn": ${error.message}`;
			}
			throw error;
		});
	}

	/**
	 * Gets the points p0 and p1 at the given time.
	 * @param {[Point | null, Point | null]} points
	 * @param {number} time
	 * @private
	 */
	_getPointsAtTime(points, time) {
		let pointSet = this._pointSet;
		while (pointSet !== null) {
			// This point set has points, so return them.
			if (pointSet.hasPoints()) {
				pointSet.getPoints(points, time - this._timeOffset);
				return;
			}
			// It is loaded, but doesn't have points, so go one deeper.
			else if (pointSet.getLoadedState() === PointSet.State.LOADED) {
				pointSet = pointSet.getPointSet(time - this._timeOffset);
				continue;
			}
			// It isn't loaded so start the loading and return null.
			else if (pointSet.getLoadedState() === PointSet.State.NOT_LOADED) {
				pointSet.loadFromUrl(this._downloader, this._baseUrl, -this.getEntity().getLeastCameraDepth());
				break;
			}
			// If it failed, it might be due to an updated def.dyn file. Reset everything
			// by setting the base URL, which takes care of it, and return null.
			else if (pointSet.getLoadedState() === PointSet.State.FAILED) {
				this.setBaseUrl(this._baseUrl);
				break;
			}
			break;
		}
		points[0] = null;
		points[1] = null;
	}
}

DynamoController.maxLoadedPointSetsPerController = 5;
