/** @module pioneer-scripts */
import { Entity } from '../entity';

Entity.register({
	phobos: {
		groups: ['mars', 'moons'],
		radius: 14.0,
		label: 'Phobos',
		parents: [
			[Number.NEGATIVE_INFINITY, 'mars']
		],
		trail: {
			length: undefined,
			color: [0.89, 0.51, 0.35, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/phobos/phobos.gltf',
			scale: [1, 1, 1],
			rotate: [
				{ z: 90 },
				{ y: 90 }
			],
			shadowEntities: ['mars']
		},
		controllers: [{
			type: 'dynamo',
			url: 'phobos/mars/orb'
		}, {
			type: 'dynamo',
			url: 'phobos/ori'
		}]
	},
	deimos: {
		groups: ['mars', 'moons'],
		radius: 8.70951,
		label: 'Deimos',
		parents: [
			[Number.NEGATIVE_INFINITY, 'mars']
		],
		trail: {
			length: undefined,
			color: [0.89, 0.51, 0.35, 0.7]
		},
		model: {
			url: '$STATIC_ASSETS_URL/models/deimos/deimos.gltf',
			scale: [1, 1, 1],
			rotate: [
				{ y: -180 }
			],
			shadowEntities: ['mars']
		},
		controllers: [{
			type: 'dynamo',
			url: 'deimos/mars/orb'
		}, {
			type: 'dynamo',
			url: 'deimos/ori'
		}]
	}
});
