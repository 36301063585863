import { useSnapshot } from 'valtio';

import '../../www/assets/css/modal.css';
import { uiStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';
import ar from '../languages/ar';


const resetModal = () => {
	uiStore.setGlobalState({
		modal: {
			isVisible: false,
			startRange: null,
			endRange: null,
			numMissingDatasets: null,
			onClose: null
		}
	});
};


const Modal = () => {
	const { isVisible, startRange, endRange, numMissingDatasets, onClose } = useSnapshot(uiStore.state).modal;
	const { getManager } = globalRefs;
	const { isMonthly } = getManager('dataset');

	const modalDateOptions = {
		month: 'short',
		year: 'numeric',
		...!isMonthly && { day: 'numeric' }
	};

	const start = typeof startRange === 'string' ? startRange : startRange?.toLocaleDateString('ar-SA', modalDateOptions);
	const end = typeof endRange === 'string' ? endRange : endRange?.toLocaleDateString('ar-SA', modalDateOptions);
	const message = numMissingDatasets ?
		`هنالك ${numMissingDatasets === 1 ? 'مجموعة واحدة من البيانات مفقودة' : numMissingDatasets === 2 ? 'مجموعتين من البيانات المفقودة' : ` ${numMissingDatasets} مجموعات من البيانات مفقودة`} 
		خلال المدة الزمنية الموضحة أدناه:` :
		'لا توجد مجموعات بيانات متاحة خلال المدة المحددة';

	const dismissModal = e => {
		e.stopPropagation();
		typeof onClose === 'function' && onClose();
		resetModal();
	};

	return isVisible ?
		(
			<div className='modal-container' role='dialog' aria-hidden='true' onClick={dismissModal}>
				<div className='modal-dialog modal-dialog-centered' role='document'>
					<div className='modal-content'>
						<h2>{ar.Dataset_Information}</h2>
						<div className='modal-body'>
							<p className='modal-text'>{message}</p>
							<p className='modal-text'>
								{start}
								{' '}
								to
								{' '}
								{end}
							</p>
							{numMissingDatasets > 0 && <p className='modal-text'> ستتخطى الرسوم المتحركة تلك الفترة المفقودة تلقائياً</p>}
						</div>
						<div className='modal-footer d-flex justify-content-center'>
							<button type='button' className='btn ok-button' onClick={dismissModal}>{ar.ok}</button>
						</div>
					</div>
				</div>
			</div>
		)
		: null;
};

export default Modal;

export { resetModal };
