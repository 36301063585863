/** @module pioneer */
import * as THREE from 'three';
import {
	Capabilities
} from '../internal';

export class ShaderFix {
	/**
	 * Fixes up shader code.
	 * Also converts shader code from GLSL 1 to 3, if WebGL is being used.
	 * @param {THREE.RawShaderMaterial} rawShaderMaterial
	 * */
	static fix(rawShaderMaterial) {
		const webGL2 = Capabilities.isWebGL2();
		const fragDepth = Capabilities.hasFragDepth();
		let prependVertex = '';
		let prependFragment = '';
		if (webGL2) {
			prependVertex += `
				#define attribute in
				#define varying out
				#define texture2D texture
				precision highp float;
				precision highp int;
				#define L_EXT_frag_depth true
				`;
			prependFragment += `
				#define varying in
				out highp vec4 pc_fragColor;
				#define gl_FragColor pc_fragColor
				#define gl_FragDepthEXT gl_FragDepth
				#define texture2D texture
				#define textureCube texture
				#define texture2DProj textureProj
				#define texture2DLodEXT textureLod
				#define texture2DProjLodEXT textureProjLod
				#define textureCubeLodEXT textureLod
				#define texture2DGradEXT textureGrad
				#define texture2DProjGradEXT textureProjGrad
				#define textureCubeGradEXT textureGrad
				#define L_EXT_frag_depth true
				precision highp float;
				precision highp int;
				`;
			rawShaderMaterial.glslVersion = THREE.GLSL3;
		}
		else {
			if (fragDepth) {
				prependVertex += `
					#extension GL_EXT_frag_depth : enable
					#define L_EXT_frag_depth true
					`;
				prependFragment += `
					#extension GL_EXT_frag_depth : enable
					#define L_EXT_frag_depth true
					`;
			}
			prependVertex += `
				precision highp float;
				precision highp int;
				`;
			prependFragment += `
				precision highp float;
				precision highp int;
				`;
			rawShaderMaterial.glslVersion = THREE.GLSL1;
		}
		rawShaderMaterial.vertexShader = prependVertex + rawShaderMaterial.vertexShader;
		rawShaderMaterial.fragmentShader = prependFragment + rawShaderMaterial.fragmentShader;
	}
}
