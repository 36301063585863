/* eslint-disable camelcase */
import { useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useSnapshot } from 'valtio';


import { gmapStore } from '../managers/globalState';
import GeologyMapLegend from '../components/geology_map_legend';
import globalRefs from '../managers/globalRefs';
import { loadGmap } from '../helpers/processGmap';
import { hideLoading, showLoading } from '../components/loading';

const GmapDetail = () => {
  const { gmapParam } = useParams();
  const { gmaps } = useSnapshot(gmapStore.state);
  const isLoading = useRef(null);
  const currentGmap = gmaps && gmaps.length > 0 ? gmaps.find(({ external_id }) => external_id === gmapParam) : null;
  // console.log("GmapDetail: loading", gmapParam, currentGmap)

  // useEffect for when event changes.
  useEffect(() => {
    // Set variables.
    const { pioneer } = globalRefs;
    const { external_id, type } = currentGmap || {};
    // console.log("GmapDetail: use effect", external_id)

    const alreadyLoading = isLoading.current === external_id;

    if (!currentGmap || alreadyLoading) {
      return;
    }

    // Set isLoading.
    isLoading.current = external_id;

    // Show loading.
    const loadType = 'event';
    showLoading(loadType);

    // Set current event in global state.
    gmapStore.setGlobalState({ currentGmap });


    const patchComponent = pioneer.get('main', 'earth').getComponent('patch');

    // Load the video if it is animated or static if not.
    loadGmap(currentGmap)
      // NOTE: Currently assuming global static events are not possible.
      .then(() => {
        console.log("loaded", external_id)
        // Check if event is still loading.
        if (isLoading.current) {
          // Hide loading.
          hideLoading(loadType);
        }

        // Set isLoading to false.
        isLoading.current = false;
      }).catch(e => e);

    // On currentVideo change / unmount disable the earth patch.
    return () => {
      if (type === 'geoLocated') {
        patchComponent?.setEnabled?.(false);
      }
    };
  }, [currentGmap]);

  // useEffect to set current event to null and disable patch when leaving event detail view.
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      const { pioneer, getManager } = globalRefs;
      const earth = pioneer.get('main', 'earth');
      const patchComponent = earth.getComponent('patch');

      // Set isLoading to null and hide loading.
      isLoading.current = null;
      hideLoading('event');

      // Disable the patch.
      patchComponent.setEnabled(false);

      // Reset global states.
      gmapStore.setGlobalState({ currentGmap: null });

    };
  }, []);

  useEffect(() => {
    // Get cursor position for data readout display.
    window.addEventListener('click', event => {
      let x = event.pageX;
      let y = event.pageY;
      console.log('xy', x, y)

      // setReadoutData(null);
      // setColorRectStyle(null);
    });


    // returned function will be called on component unmount 
    return () => {
      window.removeEventListener('click', () => { })
    }
  }, [])


  return <GeologyMapLegend current={currentGmap} />;
};

export default GmapDetail;
