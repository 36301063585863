import { ShaderChunkLogDepth } from './log_depth';

export const BasicAlphaShader = {
	uniforms: {
		modelViewMatrix: 'mat4',
		projectionMatrix: 'highp mat4',

		color: 'vec4',

		...ShaderChunkLogDepth.Uniforms
	},
	properties: {
		transparent: true,
		depthWrite: false,
		side: 'double',
		blending: 'normal'
	},
	vertex: {
		extensions: [
			'EXT_frag_depth'
		],
		code: `
			attribute vec3 position;
			uniform mat4 modelViewMatrix;
			uniform mat4 projectionMatrix;

			${ShaderChunkLogDepth.VertexHead}

			void main() {
				gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

				${ShaderChunkLogDepth.Vertex}
			}
			`
	},
	fragment: {
		extensions: [
			'EXT_frag_depth'
		],
		code: `
			precision highp float;

			uniform vec4 color;

			${ShaderChunkLogDepth.FragmentHead}
	
			void main() {
				gl_FragColor = color;

				${ShaderChunkLogDepth.Fragment}
			}
			`
	}
};
