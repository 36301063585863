import { useEffect, useRef, useState } from 'react';
import { Config } from '../../config/config';
import AppVersion from '../../app_version';
import { getSecMinHours } from '../../helpers/tools';
/**
 * ConfigMaster
 * Make sure the config is successfully fetched before rendering the children.
 */
const ConfigMaster = ({ children }) => {
	const isFetched = useRef(false);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		if (!isFetched.current) {
			// Fetch config.
			Config.load()
				.then(() => setIsLoaded(true));

			isFetched.current = true;

			const startDate = new Date();

			// Set window ref to version.
			window.getVersion = () => AppVersion;

			// Get running time, formatted from ms to DD:HH:MM:SS.
			window.getRunTime = () => {
				const endDate = new Date();
				const { sec, min, hour } = getSecMinHours(endDate - startDate);
				return {
					start: startDate.toLocaleString(),
					now: endDate.toLocaleString(),
					runTime: `${hour} hours, ${min} minutes, ${sec} seconds`
				};
			};
		}
	}, []);

	// Return children if isLoaded is true.
	return isLoaded ? children : null;
};

export default ConfigMaster;
