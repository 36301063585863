/* eslint-disable valtio/state-snapshot-rule */
import React, { useEffect, useCallback, useRef } from 'react';
import { useSnapshot } from 'valtio';

import '../../www/assets/css/color_bar_html.css';
import DataValueRect from '../../www/assets/images/data_value_rect.svg';

import { datareadStore, datasetStore } from '../managers/globalState';
import { setReadoutData } from './data_readout';

/**
 * The color values from our color bar  are a one-time calculation, even after unmount and remount
 * so we can store them outside the component
 */
const colorValues = {};

const extractColorData = img => {
	const { currentDataset } = datasetStore.stateSnapshot;
	const { externalId } = currentDataset || {};

	if (colorValues[externalId]) {
		return colorValues[externalId];
	}

	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	canvas.width = img.width;
	canvas.height = 1;

	ctx.drawImage(img, 0, 0);

	const { data } = ctx.getImageData(0, 0, canvas.width, canvas.height);

	colorValues[externalId] = [];

	for (let i = 0; i < data.length; i += 4) {
		colorValues[externalId].push(
			[data[i], data[i + 1], data[i + 2]]
		);
	}
};

/**
 * Jeff Hall built an equation to generate a list of values that correctly represented
 * it's matching index to the list of extracted color data values for the precipiitation color bar.
 * Rather than directly copying the values as an array, this function is the javascript equivalent.
 * It runs once on app load, so can be stored outside the components.
 * @param {Boolean} expander
 * @returns {Array<number>}
 */
const generatePrecipitationValues = (expander = 10000) => {
	const gamma = 0.360;
	const minval = 2.5;
	const maxval = 560;
	const ncolors = 236;

	const values = [];

	// Sample in linear increments.
	for (let i = 0; i < ncolors; i++) {
		const val = i * ((maxval - minval) / (ncolors - 1));
		let minDiff = Infinity;
		let minIndex = 0;

		for (let j = 0; j < expander; j++) {
			const diff = Math.abs(Math.pow(j / (expander - 1), gamma) * (maxval - minval) - val);
			if (diff < minDiff) {
				minDiff = diff;
				minIndex = j;
			}
		}

		values[i] = Number((minIndex / (expander - 1) * (maxval - minval) + minval).toFixed(2));
	}

	values.shift();

	return values;
};

// Generate precipitation values (takes ~250ms with expander at 10000)
const dataLookup = generatePrecipitationValues();

/**
 * Using the input value, geet the nearest data value and its index from the dataLookup array.
 * Note: Asssumes dataLookup is numerically sorted.
 * @param {number} dataValue
 * @returns {number}
 */
const getNearestDataIndex = dataValue => {
	let nearestIndex = 0;

	for (const i in dataLookup) {
		if (dataValue > dataLookup[i]) {
			nearestIndex = i;
		} else {
			return nearestIndex;
		}
	}

	return dataLookup.length - 1;
};


/**
 * Calculates the color reactangle style / positioning, specific for precipitation.
 * @param {number|null} nearestIndex
 * @param {boolean} isSnow
 * @param {Pioneer.Engine} pioneer
 */
const setPrecipitationColorRectStyle = (nearestIndex, isSnow, pioneer) => {
	const isValid = nearestIndex !== null;
	let colorRectStyle = null;

	if (isValid) {
		const verticalOffsetValue = isSnow ? 78 : 16;

		const leftValue = nearestIndex;

		colorRectStyle = {
			display: 'block',
			left: `${leftValue.toString()}px`,
			top: `${verticalOffsetValue.toString()}px`
		};
	}

	// Once the rect style is set, we can set an onUpdate callback to detect any cam moves
	// to reset style and dataread.
	const onUpdate = () => {
		const input = pioneer.getInput();
		const { x, y } = input.getDraggedOffset();
		const zoom = input.getZoomedOffset();
		const camMoved = x !== 0 || y !== 0 || zoom !== 0;

		// Reset rect style, readout and remove callback.
		if (camMoved) {
			datareadStore.setGlobalState({ colorRectStyle: null });
			setReadoutData(null);
			pioneer.removeCallback(onUpdate);
		}
	};

	// Add a pioneer callback.
	pioneer.addCallback(onUpdate, true);

	// Set global store.
	datareadStore.setGlobalState({ colorRectStyle });
};

const ColorBarHtml = ({ readoutKey }) => {
	const imgRef = useRef();
	const { currentDataset } = useSnapshot(datasetStore.state);

	const { readoutData } = currentDataset || {};
	const { src, label, markers } = (readoutKey ? readoutData[readoutKey] : readoutData) || {};
	const colorBarSrc = `assets/images/arabic_colorbars/${src}`;

	const width = 235;
	const height = 8;

	useEffect(() => {
		// Store ref to variable so we can reference it in the cleanup.
		const imgRefCurrent = imgRef.current;

		if (imgRefCurrent) {
			const getColorData = () => extractColorData(imgRefCurrent);
			imgRefCurrent.addEventListener('load', getColorData);

			return () => imgRefCurrent.removeEventListener('load', getColorData);
		}
	}, [imgRef]);

	return (
		<figure className='color-bar-html-container'>
			<figcaption>{label}</figcaption>
			<img ref={imgRef} src={colorBarSrc} alt={label} width={`${width}px`} height={`${height}px`} />
			<div className='scale'>{markers?.map((marker, i) => <span key={i}>{marker}</span>)}</div>
		</figure>

	);
};

const SingleHtmlColorBar = ({ readoutKey, showDataRect = true }) => {
	const imgRef = useRef();
	const { currentDataset } = useSnapshot(datasetStore.state);
	const { readoutData: readoutState, isCelcius } = useSnapshot(datareadStore.state);
	const { value } = readoutState || {};

	const { readoutData, temperatureData } = currentDataset || {};

	const { src, label, markers, bottom, variation, units, preciseMarkers, markersSpacing } = (readoutKey ? readoutData[readoutKey] : readoutData) || {};
	const colorBarSrc = `assets/images/arabic_colorbars/${src}`;

	const width = 235;
	const height = 8;

	useEffect(() => {
		// Store ref to variable so we can reference it in the cleanup.
		const imgRefCurrent = imgRef.current;

		if (imgRefCurrent) {
			const getColorData = () => extractColorData(imgRefCurrent);
			imgRefCurrent.addEventListener('load', getColorData);

			return () => imgRefCurrent.removeEventListener('load', getColorData);
		}
	}, [imgRef]);

	const onClick = useCallback(() => {
		const newIsCelcius = !isCelcius;

		datareadStore.setGlobalState({ isCelcius: newIsCelcius });
	}, [isCelcius]);

	function getPercentageQuadratic(value, markers, markersSpacing = []) {
		if (markers.length === 0 || value === markers[0]) {
			return 0;
		}
		if (value === markers[markers.length - 1]) {
			return 100;
		}
		for (let i = 0; i < markers.length - 1; i++) {
			if (value >= markers[i] && value < markers[i + 1]) {
				const percentage = Math.sqrt((value - markers[i]) / (markers[i + 1] - markers[i]));
				return markersSpacing.length > 0
					? percentage * (markersSpacing[i + 1] - markersSpacing[i]) + markersSpacing[i]
					: ((percentage + i) * 100 / (markers.length - 1));
			}
		}
		return 0;
	}

  function clamp(value, min, max) {
    return Math.max(min, Math.min(value, max));
  }

  function percentBetweenValues(x, min, max){
   return ((x - min) / (max - min) * 100)
  }

function extractArabic(text) {
  // Regular expression to match Arabic characters
  const arabicRegex = /[\u0600-\u06FF\u0750-\u077F]+/g;
  return text.match(arabicRegex) || [];
}


	let leftOffset = 0;
  const digits = value.match(/[\d\.-]+/g)
  let numberValue = 0.0
  if(digits && digits.length > 0){
    numberValue = parseFloat(digits.join(''));
  } else {
    // console.error("Unexpected number for colorbar", value )
  }
  leftOffset = percentBetweenValues(numberValue, bottom, bottom + variation);
	if (currentDataset.externalId === 'chlorophyllToday') {
		leftOffset = getPercentageQuadratic(numberValue, markers, markersSpacing);
	} else if (value.indexOf( 'ف°' ) > -1 ) { //F
			let variationF = variation * 1.8 + 32;
			const bottomF = bottom * 1.8 + 32;
      if(markers){
        const top = markers[markers.length - 1]
        const topF = top * 1.8 + 32;
        variationF = topF - bottomF
      }
			// leftOffset = (numberValue - bottomF) * 100 / (variationF - bottomF);
      leftOffset = percentBetweenValues(numberValue, bottomF, bottomF + variationF)
	}
  const leftOffsetString = `${clamp(leftOffset, 0, 98.5)}%`
  console.log({value, numberValue, bottom, leftOffset, variation, isCelcius,units})

	return (
		<figure className='color-bar-html-container'>
			<figcaption>{`${label} ${temperatureData ? isCelcius ? 'مئوية' : 'فهرنهايت' : ''}` }</figcaption>
			<img ref={imgRef} src={colorBarSrc} alt={label} width={`${width}px`} height={`${height}px`} style={{ marginTop: '5px' }} />
			<div className='single-bar-scale'>
				<span className='placeholder'>placeholder</span>
				{markers?.map((marker, i) => {
					let value = marker;
					if (!isCelcius && temperatureData) {
						value = (marker * 9 / 5) + 32;
					}
					const spacing = markersSpacing ? `${markersSpacing[i]}%` : `${i / (markers.length - 1) * 100}%`;
					return <span key={i} style={{ left: spacing }}>{preciseMarkers ? value : Math.round(value)}</span>;
				})}
			</div>
			{ Boolean(value) && showDataRect && <span className='value-rectangular' style={{ left: leftOffsetString }}><DataValueRect /></span> }
			{
				temperatureData && (
					<div className='color-bar'>
						<button className='toggle-units' onClick={onClick}>تبديل الوحدات</button>
					</div>
				)
			}
		</figure>
	);
};

const PrecipitationColorBar = ({ showDataRect = true }) => {
	const { colorRectStyle } = useSnapshot(datareadStore.state);
	const { currentDataset } = useSnapshot(datasetStore.state);

	const { rain, snow } = currentDataset?.readoutData || {};

	if (!rain || !snow) {
		return null;
	}

	return (
		<div className='precipitation-color-bar-container'>
			<ColorBarHtml readoutKey='rain' />
			{ showDataRect && <span style={colorRectStyle}><DataValueRect /></span> }
			<ColorBarHtml readoutKey='snow' />
		</div>
	);
};

export default ColorBarHtml;
export { dataLookup, getNearestDataIndex, setPrecipitationColorRectStyle, PrecipitationColorBar, SingleHtmlColorBar };
