import React, { useCallback, useEffect } from 'react';
import { useSnapshot } from 'valtio';

import { appStore, lightStore, eventsStore } from '../managers/globalState';
import { LIGHT_STATUS } from '../config/constants';

import '../../www/assets/css/light_toggle.css';
import CameraLightButtonOnSVG from '../../www/assets/images/camera_light_button_on.svg';
import CameraLightButtonOffSVG from '../../www/assets/images/camera_light_button_off.svg';

const LightToggle = () => {
	const { queries: { lighting: lightingFromURL } } = appStore.stateSnapshot;
	const { fill } = useSnapshot(lightStore.state);
	const { currentEvent } = useSnapshot(eventsStore.state);

	const isButtonVisible = !currentEvent;

	// Handle lighting URL value
	useEffect(() => {
		// Light on by default for events, otherwise use URL param or LIGHT_STATUS
		const forceLightOn = currentEvent !== null;
		const lightState = forceLightOn || (lightingFromURL ? lightingFromURL === 'flood' : LIGHT_STATUS);

		lightStore.setGlobalState({ fill: lightState });
	}, [lightingFromURL, currentEvent]);

	// Toggle light fill.
	const onClick = useCallback(() => {
		lightStore.setGlobalState({ fill: !fill });
	}, [fill]);

	const classes = `light-toggle no-select ${fill ? '' : ' secondary'}`;
	let text;
	const is_arabic = window.config && window.config.acceptLanguage && window.config.acceptLanguage == 'ar';
	if(is_arabic){
		text = fill ? 'على' : 'بعيدا';
	} else {
		text = fill ? 'ON' : 'OFF';
	}

	return (isButtonVisible && (
		<button type='button' aria-label='light-toggle' className={classes} value='light' onClick={onClick} title={text}>
			{fill ? <CameraLightButtonOffSVG /> : <CameraLightButtonOnSVG />}
		</button>
	)

	);
};

export default LightToggle;
