import Pikaday from 'pikaday';
import React from 'react';

import '../../www/assets/css/calendar.css';
import { cloneDate, dateToUTCString, localDateToString } from '../helpers/tools';
import globalRefs from '../managers/globalRefs';
import { datasetStore } from '../managers/globalState';
import ar from '../languages/ar';


class Calendar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isVisible: true
		};

		this._doneButton = null;
		this._el = null;
		this._toggleCalendar = this._toggleCalendar.bind(this);
		this._outOfFocusHandler = this._outOfFocusHandler.bind(this);
		this._closeCalendar = this._closeCalendar.bind(this);
	}

	/**
	 * This component needs rebuilding. There are a lot of issues with it including memory leaks.
	 */
	componentDidMount() {
		if (!this.state.isVisible) {
			return;
		}
		const { inputRef, minDate, maxDate, defaultDate, onSelect, onOpen } = this.props;

		const placeholder = 'yyyy-mm-dd';
		const showDoneContainer = document.createElement('div');
		const clearDoneContainer = document.createElement('span');
		showDoneContainer.className = 'pika-new';
		this._doneButton = document.createElement('button');
		this._doneButton.type = 'button';
		this._doneButton.innerHTML = ar.done;
		this._doneButton.addEventListener('click', () => {
			this._closeCalendar();
		});
		this._doneButton.addEventListener('touchend', () => {
			this._closeCalendar();
		});
		this._clearButton = document.createElement('button');
		this._clearButton.type = 'button';
		this._clearButton.innerHTML = ar.clear;
		this._clearButton.addEventListener('click', () => {
			this.setDate(null);
			inputRef.current.value = placeholder;
			this._closeCalendar();
		});
		this._clearButton.addEventListener('touchend', () => {
			this.setDate(null);
			inputRef.current.value = placeholder;
			this._closeCalendar();
		});
		showDoneContainer.appendChild(clearDoneContainer);
		clearDoneContainer.appendChild(this._clearButton);
		clearDoneContainer.appendChild(this._doneButton);
		clearDoneContainer.classList.add('clear-done-container');

		document.addEventListener('click', this._outOfFocusHandler);
		this._calendar = new Pikaday({
			field: inputRef.current,
			position: 'top left',
			format: 'YYYY-MM-DD',
			yearRange: [minDate.getFullYear(), maxDate.getFullYear()],
			toString: date => localDateToString(date),
			minDate,
			maxDate,
			// Disabling of grey dates using manifest.
			disableDayFn: date => {
				// The date comes through as a local date midnight.
				// We shouldn't convert to UTC or we could change the day. The missing dates are always in UTC.
				// This is unique and weird to Pikaday.
				const { getManager } = globalRefs;
				const { isVisibleEarth } = datasetStore.stateSnapshot;
				const { getDatasetManifestData } = getManager('dataset');

				// No greying for visible earth for now (until we're generating NOAA manifests).
				if (isVisibleEarth) {
					return false;
				}

				const { missingDates } = getDatasetManifestData() || {};
				const dateStr = localDateToString(date);

				return date > maxDate || missingDates?.includes(dateStr);
			},
			defaultDate,
			setDefaultDate: true,
			i18n: {
				previousMonth: 'Previous Month',
				nextMonth: 'Next Month',
				months: [
					'January',
					'February',
					'March',
					'April',
					'May',
					'June',
					'July',
					'August',
					'September',
					'October',
					'November',
					'December'
				],
				weekdays: [
					'Sunday',
					'Monday',
					'Tuesday',
					'Wednesday',
					'Thursday',
					'Friday',
					'Saturday'
				],
				weekdaysShort: [
					'Su',
					'Mo',
					'Tu',
					'We',
					'Th',
					'Fr',
					'Sa'
				]
			},
			onOpen,
			onSelect,
			onDraw: () => {
				if (this._calendar) {
					const table = this._calendar.el.children[0].children[1];
					table.parentNode.insertBefore(showDoneContainer, table.nextSibling);
				}
			}
		});
		this.setDate(defaultDate);
	}

	componentWillUnmount() {
		this._calendar?.destroy();

		document.removeEventListener('click', this._outOfFocusHandler);
	}

	componentDidUpdate(prevProps) {
		const { minDate, maxDate, selectedDate } = this.props;

		// Check if the min date has changed. maxDate not updated so minDate can access all dates.
		if (dateToUTCString(prevProps.minDate) !== dateToUTCString(minDate)) {
			this.setMinDate(minDate, maxDate);
		}

		// Check if the selectedDate has changed.
		if (dateToUTCString(prevProps.selectedDate) !== dateToUTCString(selectedDate)) {
			this.setDate(selectedDate);
		}
	}

	render() {
		return (
			<div className='pika-new' />
		);
	}

	// Any date being set on this._calendar need to be cloned as Pikaday seems happy to mutate them.
	setDate(date) {
		this._calendar?.setDate(cloneDate(date));
	}

	// When minDate is set, update end date's range and update end date itself if minDate > endDate
	setMinDate(minDate, maxDate) {
		minDate = cloneDate(minDate);
		if (this._calendar) {
			this._calendar.config({ yearRange: [minDate.getFullYear(), maxDate.getFullYear()], minDate });
			this._calendar.gotoDate(minDate);
			if (minDate > this._calendar.getDate()) {
				const endOfMonth = new Date(minDate.getFullYear(), minDate.getMonth() + 1, 0);
				if (endOfMonth < this._calendar.getDate()) {
					this._calendar.setDate(endOfMonth);
				}
			}
		}
	}

	toString() {
		return this._calendar.toString();
	}

	clearCalendar() {
		this._calendar.setDate(null);
	}

	_toggleCalendar() {
		this._isVisible ? this._calendar.hide() : this._calendar.show();
		this._isVisible = !this._isVisible;
	}

	_closeCalendar() {
		this._calendar.hide();
		this.isVisible = false;
	}

	_outOfFocusHandler(event) {
		this.setState({ isVisible: false });
	}
}

export default Calendar;
