/** @module pioneer */
import * as Pioneer from '../internal';

/**
 * The class that holds the different mappings from component and controller names to their corresponding constructors.
 */
class Types {
}

/**
 * @typedef {typeof Pioneer.BaseComponent} BaseComponentType
 */

/**
 * The mapping of component type names to component constructors.
 * @type {Map<string, BaseComponentType>}
 * @internal
 */
Types.Components = new Map();
Types.Components.set('atmosphere', Pioneer.AtmosphereComponent);
Types.Components.set('camera', Pioneer.CameraComponent);
Types.Components.set('cmts', Pioneer.CMTSComponent);
Types.Components.set('cometTail', Pioneer.CometTailComponent);
Types.Components.set('connectedSprite', Pioneer.ConnectedSpriteComponent);
Types.Components.set('div', Pioneer.DivComponent);
Types.Components.set('dynEnvMap', Pioneer.DynamicEnvironmentMapComponent);
Types.Components.set('gizmo', Pioneer.GizmoComponent);
Types.Components.set('label', Pioneer.LabelComponent);
Types.Components.set('lightSource', Pioneer.LightSourceComponent);
Types.Components.set('model', Pioneer.ModelComponent);
Types.Components.set('orbitalParticles', Pioneer.OrbitalParticlesComponent);
Types.Components.set('particleSpray', Pioneer.ParticleSprayComponent);
Types.Components.set('rings', Pioneer.RingsComponent);
Types.Components.set('skybox', Pioneer.SkyboxComponent);
Types.Components.set('spheroid', Pioneer.SpheroidComponent);
Types.Components.set('spheroidLOD', Pioneer.SpheroidLODComponent);
Types.Components.set('spout', Pioneer.SpoutComponent);
Types.Components.set('sprite', Pioneer.SpriteComponent);
Types.Components.set('starfield', Pioneer.StarfieldComponent);
Types.Components.set('trail', Pioneer.TrailComponent);

/**
 * @typedef {typeof Pioneer.BaseController} BaseControllerType
 */

/**
 * The mapping of controller type names to controller constructors.
 * @type {Map<string, BaseControllerType>}
 * @internal
 */
Types.Controllers = new Map();
Types.Controllers.set('align', Pioneer.AlignController);
Types.Controllers.set('animdata', Pioneer.AnimdataController);
Types.Controllers.set('coverage', Pioneer.CoverageController);
Types.Controllers.set('dynamo', Pioneer.DynamoController);
Types.Controllers.set('fixed', Pioneer.FixedController);
Types.Controllers.set('fixedToParent', Pioneer.FixedToParentController);
Types.Controllers.set('freeFly', Pioneer.FreeFlyController);
Types.Controllers.set('groundClamp', Pioneer.GroundClampController);
Types.Controllers.set('keyframe', Pioneer.KeyframeController);
Types.Controllers.set('look', Pioneer.LookController);
Types.Controllers.set('modelAnimate', Pioneer.ModelAnimateController);
Types.Controllers.set('orbit', Pioneer.OrbitController);
Types.Controllers.set('orbitKeyframe', Pioneer.OrbitKeyframeController);
Types.Controllers.set('orbitalElements', Pioneer.OrbitalElementsController);
Types.Controllers.set('pick', Pioneer.PickController);
Types.Controllers.set('roll', Pioneer.RollController);
Types.Controllers.set('rotate', Pioneer.RotateController);
Types.Controllers.set('rotateByEntityOrientation', Pioneer.RotateByEntityOrientationController);
Types.Controllers.set('scale', Pioneer.ScaleController);
Types.Controllers.set('select', Pioneer.SelectController);
Types.Controllers.set('setParent', Pioneer.SetParentController);
Types.Controllers.set('spin', Pioneer.SpinController);
Types.Controllers.set('tap', Pioneer.TapController);
Types.Controllers.set('transition', Pioneer.TransitionController);
Types.Controllers.set('translate', Pioneer.TranslateController);
Types.Controllers.set('zoom', Pioneer.ZoomController);

export { Types };
