import React, { useEffect, useState } from 'react';

import Overlay from '../overlays';
import { videoStore } from '../../managers/globalState';

import { TYPE_TITLES } from '../../data/videos_data';

import '../../../www/assets/css/chart_overlay.css';


const ChartOverlay = ({ closeOverlay }) => {
	const { currentVideo } = videoStore.stateSnapshot;
	const { src, type, altText } = currentVideo || {};
	const typeTitle = TYPE_TITLES[type];

	const [imageSize, setImageSize] = useState([0, 0]);
	const [windowSize, setWindowSize] = useState([0, 0]);

	useEffect(() => {
		const img = document.createElement('img');
		img.src = src;
		img.onload = () => {
			setImageSize([img.width, img.height]);
		};

		const updateSize = () => {
			const padding = 25;

			setWindowSize([window.innerWidth - 3 * padding, window.innerHeight - 3 * padding]);
		};
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	const style = {};
	if (imageSize[0] * imageSize[1] * window.innerWidth * window.innerHeight > 0) {
		const headerBar = document.getElementById('header-bar'); // TODO: Fix in #4221
		const headerHeight = headerBar?.clientHeight ? headerBar.clientHeight : 75;

		if ((windowSize[1] - headerHeight) / imageSize[1] * imageSize[0] < windowSize[0]) {
			style.width = Math.round((windowSize[1] - headerHeight) / imageSize[1] * imageSize[0]);
			style.height = Math.round(windowSize[1] - headerHeight);
		} else if (windowSize[0] / imageSize[0] * imageSize[1] < windowSize[1] - headerHeight) {
			style.width = Math.round(windowSize[0]);
			style.height = Math.round(windowSize[0] / imageSize[0] * imageSize[1]);
		}
	}

	return currentVideo && (
		<Overlay type='chart'>
			<Overlay.Header
				onClose={closeOverlay}
				title={typeTitle.dropdown}
			>
				{currentVideo.title}
			</Overlay.Header>

			<div className='content-mask chart-container'>
				<img src={src} style={style} alt={altText} />
			</div>

		</Overlay>
	);
};

export default ChartOverlay;
