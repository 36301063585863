/** @module pioneer-scripts */
import { SceneHelpers } from '../scene_helpers';
import * as Pioneer from 'pioneer';

/**
 * Constellations.
 */
export class ConstellationsComponent extends Pioneer.BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Pioneer.Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The url used for the database.
		 * @type {string}
		 * @private
		 */
		this._url = '';

		/**
		 * The database. They match the ordering of the labelEntities.
		 * @type {DatabaseEntry[]}
		 */
		this._database = [];

		/**
		 * The number of vertices in each line mesh.
		 * @type {number[]}
		 * @private
		 */
		this._numVertices = [];

		/**
		 * The label entities.
		 * @type {Pioneer.Entity[]}
		 * @private
		 */
		this._labelEntities = [];

		/**
		 * The color of the lines.
		 * @type {Pioneer.Color}
		 * @private
		 */
		this._color = new Pioneer.Color();

		/**
		 * The width of the lines.
		 * @type {number}
		 * @private
		 */
		this._lineWidth = 2;

		/**
		 * The glow width for the lines.
		 * @type {number}
		 * @private
		 */
		this._glowWidth = 0;

		/**
		 * The index of the constellation to be highlighted.
		 * @type {number | undefined}
		 * @private
		 */
		this._highlightedIndex = undefined;

		/**
		 * The color of the highlight.
		 * @type {Pioneer.Color}
		 * @private
		 */
		this._highlightColor = new Pioneer.Color();

		/**
		 * The width of of the highlight lines.
		 * @type {number}
		 * @private
		 */
		this._highlightWidth = 0;

		/**
		 * The line meshes.
		 * @type {Pioneer.LineMesh[]}
		 * @private
		 */
		this._lineMeshes = [];

		// Set the radius to infinite, since it extends to all of the stars in the constellations.
		this.__setRadius(Number.POSITIVE_INFINITY);
	}

	/**
	 * Sets the color of the lines.
	 * @param {Pioneer.Color} color
	 */
	setColor(color) {
		this._color.copy(color);

		// Set the color for every line mesh.
		for (let i = 0; i < this._lineMeshes.length; i++) {
			const colors = [];
			for (let j = 0; j < this._numVertices[i]; j++) {
				colors.push(this._color);
			}
			this._lineMeshes[i].setColors(colors);
		}
	}

	/**
	 * Sets the width of the lines.
	 * @param {number} lineWidth
	 */
	setLineWidth(lineWidth) {
		this._lineWidth = lineWidth;

		// Set the width of the line meshes.
		for (let i = 0; i < this._lineMeshes.length; i++) {
			this._lineMeshes[i].setWidths(lineWidth);
		}
	}

	/**
	 * Sets the glow for the lines.
	 * @param {number} glowWidth
	 */
	setGlowWidth(glowWidth) {
		this._glowWidth = glowWidth;
		for (let i = 0, l = this._lineMeshes.length; i < l; i++) {
			this._lineMeshes[i].setGlowWidth(this._glowWidth);
		}
	}

	/**
	 * Sets the database.
	 * @param {string} url
	 */
	setUrl(url) {
		this._url = url;
		this.resetResources();
	}

	/**
	 * Generates and returns a list of the constellation names. Each label entity is named "constellation_label_<name>".
	 * @returns {string[]}
	 */
	getNames() {
		const names = [];
		for (let i = 0, l = this._database.length; i < l; i++) {
			names.push(this._database[i].name);
		}
		return names;
	}

	/**
	 * Sets the contellation at the index to be highlighted. Use undefined for the index to clear the highlighting.
	 * @param {number | undefined} hightlightedIndex
	 * @param {Pioneer.Color} highlightColor
	 * @param {number} highlightWidth
	 */
	setHighlight(hightlightedIndex, highlightColor, highlightWidth) {
		// Unhighlight the previous lineMesh.
		if (this._lineMeshes.length > 0 && this._highlightedIndex !== undefined) {
			const colors = [];
			for (let i = 0, l = this._numVertices[this._highlightedIndex]; i < l; i++) {
				colors.push(this._color);
			}
			this._lineMeshes[this._highlightedIndex].setColors(colors);
			this._lineMeshes[this._highlightedIndex].setWidths(this._lineWidth);
		}

		// Set the highlight vars.
		this._highlightedIndex = hightlightedIndex;
		this._highlightColor.copy(highlightColor);
		this._highlightWidth = highlightWidth;

		// Highlight the new lineMesh.
		if (this._lineMeshes.length > 0 && this._highlightedIndex !== undefined) {
			const colors = [];
			for (let i = 0, l = this._numVertices[this._highlightedIndex]; i < l; i++) {
				colors.push(this._highlightColor);
			}
			this._lineMeshes[this._highlightedIndex].setColors(colors);
			this._lineMeshes[this._highlightedIndex].setWidths(this._highlightWidth);
		}
	}

	/**
	 * Gets the index of the constellation nearest to the pixel-space position. If there is none, returns undefined
	 * @param {Pioneer.Vector2} pixelSpacePosition
	 * @param {Pioneer.CameraComponent} camera
	 */
	getNearestConstellationIndex(pixelSpacePosition, camera) {
		/** @type {number | undefined} */
		let nearestIndex;
		let nearestDistance = Number.POSITIVE_INFINITY;
		for (let i = 0, l = this._labelEntities.length; i < l; i++) {
			const dist = pixelSpacePosition.distance(this._labelEntities[i].getPixelSpacePosition(camera));
			if (dist < nearestDistance) {
				nearestDistance = dist;
				nearestIndex = i;
			}
		}
		return nearestIndex;
	}

	/**
	 * Prepare the component for rendering.
	 * @param {Pioneer.CameraComponent} camera
	 * @override
	 * @package
	 */
	__prepareForRender(camera) {
		// Set the Three.js object position the entity's camera-space position.
		Pioneer.ThreeJsHelper.setPositionToEntity(this.getThreeJsObjects(), this.getEntity(), camera);

		// Call the line meshes prepare for render.
		for (let i = 0; i < this._lineMeshes.length; i++) {
			this._lineMeshes[i].prepareForRender(camera);
		}
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	async __loadResources() {
		this._labelEntities = [];
		this._lineMeshes = [];
		this._numVertices = [];

		const result = await this.getEntity().getScene().getEngine().getDownloader().download(this._url, true, -this.getEntity().getLeastCameraDepth());
		if (result.status === 'completed' && result.content instanceof ArrayBuffer) {
			const reader = new Pioneer.Reader(result.content);

			while (!reader.isAtEnd()) {
				const entry = /** @type {DatabaseEntry} */({
					name: '',
					stars: [],
					segments: [],
					color: []
				});

				entry.name = reader.readString(reader.readByte());

				entry.color = [
					reader.readByte(),
					reader.readByte(),
					reader.readByte(),
					reader.readByte()
				];

				const starCount = reader.readByte();
				for (let i = 0; i < starCount; i++) {
					entry.stars.push([
						reader.readFloat32(),
						reader.readFloat32(),
						reader.readFloat32()
					]);
				}

				const segmentCount = reader.readByte() * 2;
				for (let i = 0; i < segmentCount; i++) {
					entry.segments.push(reader.readByte());
				}

				this._database.push(entry);
			}
		}

		// Create the lines.
		for (let i = 0; i < this._database.length; i++) {
			const stars = this._database[i].stars;
			const segments = this._database[i].segments;
			const positions = [];
			const colors = [];
			const widths = [];

			for (let j = 0; j < segments.length; j++) {
				const starIndex = segments[j];
				positions.push(new Pioneer.Vector3(stars[starIndex][2], -stars[starIndex][0], stars[starIndex][1]));
				colors.push(i === this._highlightedIndex ? this._highlightColor : this._color);
				widths.push(i === this._highlightedIndex ? this._highlightWidth : this._lineWidth);
			}
			const lineMesh = new Pioneer.LineMesh(this);
			lineMesh.setPositions(positions);
			lineMesh.setColors(colors);
			lineMesh.setWidths(widths);
			lineMesh.setGlowWidth(this._glowWidth);
			this._lineMeshes.push(lineMesh);
			this._numVertices.push(segments.length);
		}
		Pioneer.ThreeJsHelper.setOrientation(this.getThreeJsObjects(), SceneHelpers.getEclipJ2000ToJ2000Rotation());

		// Create the labels as child entities with div components.
		for (let i = 0; i < this._database.length; i++) {
			const stars = this._database[i].stars;
			// Calculate the min and max bounds for the center.
			const minBounds = new Pioneer.Vector3(Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY);
			const maxBounds = new Pioneer.Vector3(Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY);
			const distance = Math.sqrt(stars[0][0] * stars[0][0] + stars[0][1] * stars[0][1] + stars[0][2] * stars[0][2]);
			for (let j = 0; j < stars.length; j++) {
				const star = stars[j];
				const position = new Pioneer.Vector3();
				position.set(star[2], -star[0], star[1]);
				position.rotate(SceneHelpers.getEclipJ2000ToJ2000Rotation(), position);
				position.normalize(position);
				position.mult(position, distance);
				if (minBounds.x > position.x) {
					minBounds.x = position.x;
				}
				if (maxBounds.x < position.x) {
					maxBounds.x = position.x;
				}
				if (minBounds.y > position.y) {
					minBounds.y = position.y;
				}
				if (maxBounds.y < position.y) {
					maxBounds.y = position.y;
				}
				if (minBounds.z > position.z) {
					minBounds.z = position.z;
				}
				if (maxBounds.z < position.z) {
					maxBounds.z = position.z;
				}
			}
			// Get the center point of the constellation.
			const center = new Pioneer.Vector3();
			center.add(minBounds, maxBounds);
			center.mult(center, 0.5);
			// Create a new entity that's a child of this entity at the center.
			const labelEntity = this.getEntity().getScene().addEntity('constellation_label_' + this._database[i].name);
			labelEntity.setParent(this.getEntity());
			labelEntity.setOrientation(Pioneer.Quaternion.Identity);
			labelEntity.setPosition(center);
			// Add a div component to that entity with the name of the constellation.
			const divComponent = labelEntity.addComponentByClass(Pioneer.DivComponent);
			divComponent.setAlignment(new Pioneer.Vector2(0.5, 0.5));
			const div = divComponent.getDiv();
			div.classList.add('pioneer-constellation-label');
			div.innerHTML = this._database[i].name;
			// Add it to the list of label entities.
			this._labelEntities.push(labelEntity);
		}
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		// Clean up the label entities.
		for (let i = 0; i < this._labelEntities.length; i++) {
			this.getEntity().getScene().removeEntity(this._labelEntities[i]);
		}
		// Clean up the objects and materials created by the line meshes.
		Pioneer.ThreeJsHelper.destroyAllObjectsAndMaterials(this);
		// Make the line meshes null.
		this._lineMeshes = [];
		// Clear the database.
		this._database = [];
	}
}

/**
 * @typedef {object} DatabaseEntry
 * @property {string} name - the name of the constellation
 * @property {number[][]} stars - the list of star positions, each entry being a 3-array XYZ in J2000Eclipse coordinates.
 * @property {number[]} segments - the list of segments, each pair being a line, and each indexing an entry in the stars list.
 * @property {number[]} color - a 3-array of RGB values from 0 to 255.
 */
