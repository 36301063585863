import { createSearchParams } from 'react-router-dom';

import globalRefs from './globalRefs';
import { appStore } from '../managers/globalState';
import { EVENTS_PAGE, HOME_PAGE, SPACECRAFT_PAGE, VIDEOS_PAGE, VITAL_SIGNS_PAGE,GMAP_PAGE,POI_PAGE,STELLAR_PAGE } from '../config/constants';

import { getDefaultDatasetParamFromVital, getSpacecraftParamFromId, getVitalParamFromDatasetParam } from '../helpers/tools';
import { getCategoryFromEventParam, getYearFromEventParam } from '../helpers/processEvents';


class RouteManager {
	/**
	 * Constructor.
	 */
	constructor() {
		const { router } = globalRefs;
		this._router = router;

		this._initialKioskReset = true;
	}

	/**
	 * Navigate to a path with optional query modifications.
	 * @param {string} pathname
	 * @param {object} queryOptions
	 * @param {Array|Boolean} [queryOptions.removeQueries=[]] - Queries to remove
	 * @param {Array} [queryOptions.newQueries] - Queries to add
	 */
	navigate(pathname, { removeQueries = [], newQueries = {} } = {}) {
		const { queries: currentQueries = {} } = appStore.stateSnapshot;

		// Create updated queries object.
		const updatedQueries = {
			...removeQueries !== 'all' && currentQueries,
			...newQueries
		};

		// Remove queries from updated queries if removeQueries is an array
		Array.isArray(removeQueries) && removeQueries.forEach(query => delete updatedQueries[query]);
		// Generate search string from updated queries.
		const search = createSearchParams(updatedQueries)?.toString() || '';

		// If we dont have a pathname but we have a search, use the setQuery function to update the query.
		if (!pathname && search) {
			const { setQuery } = globalRefs;
			setQuery(updatedQueries);
			return;
		}

		const routeObj = { pathname, search	};

		// Navigate to the new path.
		this._router.navigate(routeObj, { state: { notFirstPage: true } })
			.then(() => {
				// Update the parent url.
				this.updateParentUrl();
			});
	}

	getVarsFromPathname(pathname) {
		const { datasetParam, vitalSignParam, eventParam, videoParam, spacecraftParam } = pathname.split('/').filter(Boolean);
		return {
			datasetParam,
			vitalSignParam,
			eventParam,
			videoParam,
			spacecraftParam,
			poiParam,
			stellarParam
		};
	}

	/**
	 * Navigate to a dataset given either the vitalSignParam, datasetParam, or both.
	 * @param {object} params
	 * @param {string} params.vitalSignParam
	 * @param {string} params.datasetParam
	 */
	navigateToDataset({ vitalSignParam, datasetParam } = {}) {
		if (!vitalSignParam && !datasetParam) {
			return;
		}

		const vital = vitalSignParam ?? getVitalParamFromDatasetParam(datasetParam);
		const dataset = `/${datasetParam ?? getDefaultDatasetParamFromVital(vital)}`;

		if (!vital) {
			return;
		}

		let pathname = `${VITAL_SIGNS_PAGE}/${vital}${dataset}`;

		// satellites exception.
		if (vitalSignParam === 'satellites') {
			pathname = SPACECRAFT_PAGE;
		}

		const options = {
			removeQueries: ['animating', 'start', 'end', 'filter']
		};

		this.navigate(pathname, options);
	}

	navigateToDates({ start, end }) {
		const options = {
			newQueries: {
				animating: 'true',
				start,
				end
			}
		};

		this.navigate(undefined, options);
	}

	/**
	 * Navigate to a spacecraft given the spacecraftId or spacecraftParam.
	 * @param {string} spacecraftIdOrParam
	 */
	navigateToSpacecraft(spacecraftIdOrParam) {
		const spacecraftParam = getSpacecraftParamFromId(spacecraftIdOrParam);
		console.log("RouteManager::navigateToSpacecraft")
		const pathname = `${SPACECRAFT_PAGE}/${spacecraftParam}`;
		const options = {
			removeQueries: ['animating', 'start', 'end', 'filter']
		};

		this.navigate(pathname, options);
	}

	/**
	 * Navigate to the satellites now page.
	 */
	navigateToSatellites() {
		const pathname = SPACECRAFT_PAGE;
		const options = {
			removeQueries: ['animating', 'start', 'end', 'filter']
		};

		this.navigate(pathname, options);
	}

	/**
	 * Navigate to an event given the eventParam.
	 * @param {string} eventParam
	 */
	navigateToEvent(eventParam) {
		// Find the event year from the eventParam.
		const eventYear = getYearFromEventParam(eventParam);
		const category = getCategoryFromEventParam(eventParam);

		if (!eventYear && !category) {
			return;
		}

		const pathname = `${EVENTS_PAGE}/${eventYear}/${category}/${eventParam}`;
		const options = {
			removeQueries: ['animating', 'start', 'end', 'filter']
		};

		this.navigate(pathname, options);
	}

	navigateToGmap(eventParam) {
		const pathname = `${GMAP_PAGE}/${eventParam}`;

		const options = {
			removeQueries: ['animating', 'start', 'end', 'filter']
		};

		this.navigate(pathname, options);
	}
	navigateToPoi(eventParam) {

		const pathname = `${POI_PAGE}/${eventParam}`;

		const options = {
			removeQueries: ['animating', 'start', 'end', 'filter']
		};

		this.navigate(pathname, options);
	}
	

	navigateToStellarBody(stellarParam) {

		const pathname = `${STELLAR_PAGE}/${stellarParam}`;

		const options = {
			removeQueries: ['animating', 'start', 'end', 'filter']
		};

		this.navigate(pathname, options);
	}


	/**
	 * Navigate to a specific event year.
	 * @param {string} eventYear
	 * @param {string} [categoryParam='all'] optional category parameter
	 * @param {object} [filter] optional filter
	 */
	navigateToEventYear(eventYear, categoryParam = 'all', filter = 'all') {
		const category = categoryParam === 'all' ? '' : `/${categoryParam}`;
		const pathname = `${EVENTS_PAGE}/${eventYear}${category}`;

		// Determine queries to remove.
		const removeQueries = ['animating', 'start', 'end'];
		filter === 'all' && removeQueries.push('filter');

		const newQueries = filter !== 'all' && { filter };

		const options = {
			removeQueries,
			newQueries
		};

		this.navigate(pathname, options);
	}

	/**
	 * Navigate to a video given the videoParam
	 * @param {string} videoParam
	 */
	navigateToVideo(videoParam) {
		const pathname = `${VIDEOS_PAGE}/${videoParam}`;
		const options = {
			removeQueries: ['animating', 'start', 'end', 'filter']
		};

		this.navigate(pathname, options);
	}

	/**
	 * Go back in history.
	 */
	goBack() {
		this._router.navigate(-1)
			.then(() => {
				// Update the parent url.
				this.updateParentUrl();
			});
	}

	/**
	 * Navigate home.
	 */
	goHome() {
		this.navigate(HOME_PAGE, { removeQueries: 'all' });
	}

	goHomeRetainURLQueries() {
		const { queries } = appStore.stateSnapshot;

		// Only retain queries that are entered, avoid animating when changing vital sign
		const { animating, start, end, ...retainedQueries } = queries;

		this.navigate(HOME_PAGE, {
			removeQueries: 'all',
			newQueries: retainedQueries
		});
	}

	/**
	 * Update the parent url (specifically for the climate.nasa.gov site)
	 */
	updateParentUrl() {
		window.parent.postMessage(`{"message": "setState", "url": "${window.location.hash}"}`, '*');
	}

	/**
	 * A kiosk reset makes sure we return to the home page.
	 * Additionally we check the global state to see kioskIsEnabled.
	 * We can then make sure the queries are set correctly.
	 */
	kioskReset() {
		const { queries } = appStore.stateSnapshot;
		const { animating, start, end, ...retainedQueries } = queries;

		// TODO: These queries will be static which may simplify this method enough to remove it.
		const { maxSessionTime: maxSessionTimeFromURL, maxInactivityTime: maxInactivityTimeFromURL } = queries;

		const newQueries = {
			...retainedQueries,
			...maxSessionTimeFromURL && { maxSessionTime: maxSessionTimeFromURL },
			...maxInactivityTimeFromURL && { maxInactivityTime: maxInactivityTimeFromURL }
		};

		// If this is the first kiosk reset, we dont want to redirect to the home page.
		const page = this._initialKioskReset ? undefined : HOME_PAGE;

		// Set the query options.
		const queryOptions = {
			// If this is the first kiosk reset, we want to keep all initial queries.
			...!this._initialKioskReset && { removeQueries: 'all' },
			newQueries
		};

		// Set initial kiosk reset to false.
		if (this._initialKioskReset) {
			this._initialKioskReset = false;
		}

		this.navigate(page, queryOptions);
	}
}

export default RouteManager;
