/** @module pioneer */
import {
	// eslint-disable-next-line no-unused-vars
	BaseComponent,
	BaseRef,
	Scene
} from '../internal';

/**
 * A reference to a component.
 * @template {BaseComponent} Type
 * @extends BaseRef<Type>
 */
export class ComponentRef extends BaseRef {
	/**
	 * Constructor.
	 * @param {Scene} scene
	 */
	constructor(scene) {
		super(scene);

		/**
		 * The name of the entity.
		 * @type {string}
		 * @private
		 */
		this._entityName = '';

		/**
		 * The name of the component.
		 * @type {string}
		 * @private
		 */
		this._componentName = '';

		/**
		 * The type of the component.
		 * @type {string}
		 * @private
		 */
		this._componentType = '';

		/**
		 * The index of the type of the component.
		 * @type {number}
		 * @private
		 */
		this._componentTypeIndex = 0;
	}

	/**
	 * Gets the entity name.
	 * @returns {string}
	 */
	getEntityName() {
		return this._entityName;
	}

	/**
	 * Gets the component name, if used.
	 * @returns {string}
	 */
	getComponentName() {
		return this._componentName;
	}

	/**
	 * Gets the component type, if used.
	 * @returns {string}
	 */
	getComponentType() {
		return this._componentType;
	}

	/**
	 * Gets the component type index, if the component type is used.
	 * @returns {number}
	 */
	getComponentTypeIndex() {
		return this._componentTypeIndex;
	}

	/**
	 * Sets the name of the reference component.
	 * @param {string} entityName
	 * @param {string} componentName
	 */
	setByName(entityName, componentName) {
		this._entityName = entityName;
		this._componentName = componentName;
		this._componentType = '';
	}

	/**
	 * Sets the type and index of the reference component.
	 * @param {string} entityName
	 * @param {string} componentType
	 * @param {number} [componentTypeIndex]
	 */
	setByType(entityName, componentType, componentTypeIndex = 0) {
		this._entityName = entityName;
		this._componentType = componentType;
		this._componentTypeIndex = componentTypeIndex;
		this._componentName = '';
	}

	/**
	 * Updates the reference.
	 * @override
	 */
	update() {
		// If there's no entity name or componentName/Type set, make sure the ref is null.
		if (this._entityName === '' || (this._componentName === '' && this._componentType === '')) {
			this._setRef(null);
		}
		// If there is an entity name and a componentName set, make sure the ref is valid and correct.
		else if (this._componentName !== '') {
			// Make sure the entity is correct.
			const entity = this._scene.getEntity(this._entityName);
			if (entity !== null && entity.isEnabled()) {
				// Make sure the component is correct.
				const ref = /** @type {Type} */(entity.getComponent(this._componentName));
				// Set the ref.
				this._setRef(ref);
			}
			else {
				this._setRef(null);
			}
		}
		// If there is an entity name and a componentType set, make sure the ref is valid and correct.
		else {
			// Make sure the entity is correct.
			const entity = this._scene.getEntity(this._entityName);
			if (entity !== null && entity.isEnabled()) {
				// Make sure the component is correct.
				const ref = /** @type {Type} */(entity.getComponentByType(this._componentType, this._componentTypeIndex));
				// Set the ref.
				this._setRef(ref);
			}
			else {
				this._setRef(null);
			}
		}
	}
}
