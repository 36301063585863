import React, { useCallback, useState,useEffect,useRef } from 'react';
import PropTypes from 'prop-types';
import { useSnapshot } from 'valtio';
import { eventsStore, videoStore, datareadStore, appStore, spacecraftStore, uiStore, gmapStore, poiStore ,stellarStore } from '../managers/globalState';
import { EVENT_CATEGORIES } from '../config/constants';
import { hideLoading, showLoading } from './loading';
import DateTimeDisplay from './date_time_display';
import DateLabel from './date_label';
import globalRefs from '../managers/globalRefs';
const LeftContent = ({
	title = null,
	subtitle,
	category,
	hideTitle = false,
	hideButton = false
}) => {
	// Get the content manager using the global state 'get' reference.
	const { isDetailPanelOpen } = useSnapshot(uiStore.state);
	const { eventsVisibleOnGlobe, yearIndex, categoryIndex, eventYears, currentEvent } = useSnapshot(eventsStore.state);
	const { currentSpacecraft, isSatellitesNow } = useSnapshot(spacecraftStore.state);
	const { currentVideo } = useSnapshot(videoStore.state);
	const { isMobile } = useSnapshot(appStore.state);
	const { currentPoi } = useSnapshot(poiStore.state);
	const { currentGmap } = useSnapshot(gmapStore.state);

	const { isColorBarShowing } = useSnapshot(datareadStore.state);
    const {currentStellar}=useSnapshot(stellarStore.state);
    const {id } = currentStellar || {};
	const showHDButton= ["jupiter", "mercury", "moon", "mars","134340_pluto"].includes(id) ;
	const mobileExpandIcon = 'assets/images/' + (isDetailPanelOpen ? 'icon_minus.svg' : 'icon_plus.svg');
	const expandIcon = 'assets/images/' + (isDetailPanelOpen ? 'chevron_down.svg' : 'chevron_up.svg');
	const { getManager } = globalRefs;

	const cameraManager = getManager('camera');
	const [isHD, setIsHD] = useState(false);
    const [showRedCircle, setShowRedCircle] = useState(false);
	const handleToggleHD = () => {
		//showLoading("texture");
        setIsHD((prev) => !prev);
        setShowRedCircle((prev) => !prev); 
        cameraManager.ChangePlanetTexture_HD(id, !isHD)
		
		//hideLoading("texture");
        
		
    }
	useEffect(() => {
        setIsHD(false); 
        setShowRedCircle(false);
		cameraManager.ResetPlanetTexture(id);
    }, [currentStellar]);
	 

	const [isDateLabelLoaded, setIsDateLabelLoaded] = useState(true);
	// Panel toggle.
	const toggleDetailPanel = useCallback(() => {
		uiStore.setGlobalState({ isDetailPanelOpen: !isDetailPanelOpen });
	}, [isDetailPanelOpen]);

	const showEventTitle = currentEvent || currentSpacecraft;
	const showDateAndTime = !isDetailPanelOpen && (isMobile && (isSatellitesNow || currentSpacecraft)) && !currentVideo;

	if (eventsVisibleOnGlobe && !showEventTitle && eventYears) {
		const event = Array.from(EVENT_CATEGORIES.values())[categoryIndex] ?? 'event';
		return (
			<>
				{!isMobile && (
					<div className='section-title'>EVENTS</div>
				)}
				<div className='title-container'>
					{!hideTitle && <span className='title outline'>{`${event} - ${eventYears[yearIndex]}`}</span>}
				</div>
				{showDateAndTime && (
					<div className='title-container'>
						<DateTimeDisplay />
					</div>
				)}
			</>
		);
	}

	return (
		<>
			{!isMobile && (
				<div className=' section-title'>
					{category}
					{showHDButton&& (
    <button id="hd-button"  className="hd-button" onClick={handleToggleHD} > HD 
	{showRedCircle && <span className="red-circle"></span>}
	</button>
	)}
				</div>
				
			)}
			{!(isMobile && isDetailPanelOpen) && (
				<div
					className={`${!isMobile ? 'clickable' : ''} title-container${!isColorBarShowing || isDetailPanelOpen ? '-no-colorbar hide-title-container' : ''} ${isDateLabelLoaded && !isSatellitesNow ? 'mobile-title-container' : ''}`}
					onClick={!isMobile ? toggleDetailPanel : null}
				>
					{!hideTitle && title && <span className={`${isMobile ? 'clickable' : ''} title outline`} onClick={isMobile ? toggleDetailPanel : null}>{title}</span>}
					{isMobile
						? (
							!hideButton && (
								<div className='clickable icon' onClick={toggleDetailPanel}>
									<img id='plus' src={mobileExpandIcon} alt='Expand icon' />
								</div>
							)
						)
						: (
							<span className='icon'>
								<img id='chevron' src={expandIcon} alt='Expand icon' />
							</span>
						)}
					{isMobile && <DateLabel setIsDateLabelLoaded={setIsDateLabelLoaded} />}
				</div>
			)}
			{
				showDateAndTime && (
					<div className='title-container'>
						{
							currentSpacecraft ?
								(
									<div className='date-time outline'>
										{subtitle}
									</div>
								)
								: <DateTimeDisplay inline />
						}

					</div>
				)
			}
			{(currentEvent || (!isMobile) || (currentSpacecraft && isDetailPanelOpen)) && (
				<div className='subtitle outline'>
					{subtitle}
				</div>
			)}
		</>
	);
};

LeftContent.propTypes = {
	hideTitle: PropTypes.bool,
	hideButton: PropTypes.bool,
	category: PropTypes.string.isRequired,
	title: PropTypes.string,
	subtitle: PropTypes.string
};

export default LeftContent;
