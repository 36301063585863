import React from 'react';
import Tab from './tab';

import '../../www/assets/css/tabs.css';

class Tabs extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeIndex: props.activeIndex
		};

		this._handleSelection = this._handleSelection.bind(this);
	}

	_handleSelection(index) {
		this.setState({ activeIndex: index });
	}

	render() {
		const { children } = this.props;
		const { activeIndex } = this.state;

		return (
			<div className='tabs'>
				<ol className='tab-list'>
					{children.map((child, index) => {
						if (!child) {
							return null;
						}
						const { label } = child.props;

						return (
							<Tab
								activeIndex={activeIndex}
								key={index}
								index={index}
								label={label}
								onClick={this._handleSelection}
							/>
						);
					})}
				</ol>
				<div className='tab-content row'>
					{children.map((child, index) => {
						if (!child || index !== activeIndex) {
							return null;
						}
						return child.props.children;
					})}
				</div>
			</div>
		);
	}
}

export default Tabs;
