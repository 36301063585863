import React from 'react';
import PropTypes from 'prop-types';

import '../../www/assets/css/switch_rounded.css';


const SwitchRounded = ({ title, checked, onChange }) => (
	<div className={`switch ${checked ? 'checked' : 'unchecked'}`}>
		{ title && <span className='switch-title'>{title}</span> }
		<label className='switch-label'>
			<input
				className='switch-input'
				type='checkbox'
				checked={checked}
				onChange={onChange}
			/>
			<span className='switch-slider switch-round' />
		</label>
	</div>
);

SwitchRounded.propTypes = {
	title: PropTypes.string,
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired
};

export default SwitchRounded;
