/** @module pioneer */

/**
 * A set of values, with the same functions as the standard Set object, except for iteration.
 * It has getAt(index) and size for iteration.
 * It's O(n) and garbage for removes, O(1) and no garbage for adds and iteration.
 * @template ValueType
 */
export class FastIterable {
	/**
	 * Gets the value of the given index.
	 * @param {number} _index
	 * @returns {ValueType}
	 */
	getAt(_index) {
		throw new Error();
	}

	/**
	 * Gets the number of values.
	 * @returns {number}
	 */
	get size() {
		throw new Error();
	}
}
