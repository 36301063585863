/** @module pioneer */

/**
 * A binary data reader.
 */
export class Reader {
	/**
	 * Constructor.
	 * @param {ArrayBuffer | SharedArrayBuffer} data
	 */
	constructor(data) {
		this._dataView = new DataView(data);
		this._offset = 0;
	}

	/**
	 * Returns true if the offset is at the end of the data.
	 * @returns {boolean}
	 */
	isAtEnd() {
		return this._offset >= this._dataView.byteLength;
	}

	/**
	 * Reads an 8-bit unsigned integer.
	 * @returns {number}
	 */
	readByte() {
		const result = this._dataView.getUint8(this._offset);
		this._offset += 1;
		return result;
	}

	/**
	 * Reads a 32-bit floating-point number.
	 * @returns {number}
	 */
	readFloat32() {
		const result = this._dataView.getFloat32(this._offset, true);
		this._offset += 4;
		return result;
	}

	/**
	 * Reads a 64-bit floating-point number.
	 * @returns {number}
	 */
	readFloat64() {
		const result = this._dataView.getFloat64(this._offset, true);
		this._offset += 8;
		return result;
	}

	/**
	* Reads a 8-bit unsigned integer.
	* @returns {number}
	*/
	readUInt8() {
		const result = this._dataView.getUint8(this._offset);
		this._offset += 1;
		return result;
	}

	/**
	* Reads a 16-bit unsigned integer.
	* @returns {number}
	*/
	readUInt16() {
		const result = this._dataView.getUint16(this._offset, true);
		this._offset += 2;
		return result;
	}

	/**
	* Reads a 32-bit unsigned integer.
	* @returns {number}
	*/
	readUInt32() {
		const result = this._dataView.getUint32(this._offset, true);
		this._offset += 4;
		return result;
	}

	/**
	* Reads a 64-bit unsigned integer.
	* @returns {number}
	*/
	readUInt64() {
		const result = Number(this._dataView.getBigUint64(this._offset, true));
		this._offset += 8;
		return Number.isSafeInteger(result) ? result : NaN;
	}

	/**
	* Reads a 8-bit integer.
	* @returns {number}
	*/
	readInt8() {
		const result = this._dataView.getInt8(this._offset);
		this._offset += 1;
		return result;
	}

	/**
	 * Reads a 16-bit integer.
	 * @returns {number}
	 */
	readInt16() {
		const result = this._dataView.getInt16(this._offset, true);
		this._offset += 2;
		return result;
	}

	/**
	 * Reads a 32-bit integer.
	 * @returns {number}
	 */
	readInt32() {
		const result = this._dataView.getInt32(this._offset, true);
		this._offset += 4;
		return result;
	}

	/**
	 * Reads a 64-bit integer.
	 * @returns {number}
	 */
	readInt64() {
		const result = Number(this._dataView.getBigInt64(this._offset, true));
		this._offset += 8;
		return Number.isSafeInteger(result) ? result : NaN;
	}

	/**
	 * Reads a single line of text, ending with '\n'.
	 * @returns {string}
	 */
	readLine() {
		const byteArray = [];
		while (true) {
			const byte = this.readByte();
			const byteAsString = String.fromCharCode(byte);
			if (byteAsString === '\r') {
				continue;
			}
			if (byteAsString === '\n') {
				break;
			}
			byteArray.push(byte);
		}
		if (typeof TextEncoder !== 'undefined') {
			const utf8Decoder = new TextDecoder();
			const array = new Uint8Array(byteArray);
			return utf8Decoder.decode(array);
		}
		else {
			return this._utf8ArrayToStr(byteArray);
		}
	}

	/**
	 * Reads a null terminated UTF-8 string or a given number of bytes as a string.
	 * @param {number} [numBytes]
	 * @returns {string}
	 */
	readString(numBytes) {
		const byteArray = [];
		while (true) {
			const byte = this.readByte();
			if (numBytes === undefined && byte === 0) {
				break;
			}
			byteArray.push(byte);
			if (numBytes !== undefined && byteArray.length === numBytes) {
				break;
			}
		}
		if (typeof TextEncoder !== 'undefined') {
			const utf8Decoder = new TextDecoder();
			const array = new Uint8Array(byteArray);
			return utf8Decoder.decode(array);
		}
		else {
			return this._utf8ArrayToStr(byteArray);
		}
	}

	/**
	 * Converts a UTF-8 array to a string. Taken, verified, and formatted from https://stackoverflow.com/a/59339612.
	 * @param {number[]} byteArray
	 * @returns {string}
	 */
	_utf8ArrayToStr(byteArray) {
		let c0 = 0;
		let c1 = 0;
		let c2 = 0;
		let c3 = 0;
		let out = '';
		let i = 0;
		const len = byteArray.length;
		while (i < len) {
			c0 = byteArray[i];
			i += 1;
			switch (c0 >> 4) {
				case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
					// 0xxxxxxx
					out += String.fromCharCode(c0);
					break;
				case 12: case 13:
					// 110xxxxx 10xxxxxx
					c1 = byteArray[i] | 0;
					i += 1;
					out += String.fromCharCode(((c0 & 0x1F) << 6) | (c1 & 0x3F));
					break;
				case 14:
					// 1110xxxx 10xxxxxx 10xxxxxx
					c1 = byteArray[i] | 0;
					i += 1;
					c2 = byteArray[i] | 0;
					i += 1;
					out += String.fromCharCode(((c0 & 0x0F) << 12)
						| ((c1 & 0x3F) << 6)
						| ((c2 & 0x3F) << 0));
					break;
				case 15:
					// 11110xxx 10xxxxxx 10xxxxxx 10xxxxxx
					c1 = byteArray[i] | 0;
					i += 1;
					c2 = byteArray[i] | 0;
					i += 1;
					c3 = byteArray[i] | 0;
					i += 1;
					out += String.fromCharCode(((c0 & 0x07) << 18)
						| ((c1 & 0x3F) << 12)
						| ((c2 & 0x3F) << 6)
						| ((c3 & 0x3F) << 0));
					break;
			}
		}

		return out;
	}
}
