/** @module pioneer */

/**
 * An FPS calculator. All you need to do is run <code>update()</code> every frame, and it will produce an FPS.
 */
export class FPS {
	constructor() {
		/**
		 * The number of samples to take when calculating the FPS.
		 * @type {number}
		 * @private
		 */
		this._numberOfSamples = 100;

		/**
		 * The buffer of samples.
		 * @type {Array<number>}
		 * @private
		 */
		this._samples = new Array(this._numberOfSamples);

		/**
		 * The current index in the buffer of samples.
		 * @type {number}
		 * @private
		 */
		this._index = 0;
	}

	/**
	 * Gets the number of samples to take when calculating the FPS.
	 * @returns {number}
	 */
	getNumberOfSamples() {
		return this._numberOfSamples;
	}

	/**
	 * Sets the number of samples to take when calculating the FPS. This will reset the sample buffer. The default is 100.
	 * @param {number} numberOfSamples - The number of samples in the buffer.
	 */
	setNumberOfSamples(numberOfSamples) {
		this._numberOfSamples = numberOfSamples;
		this._samples = new Array(this._numberOfSamples);
	}

	/**
	 * Gets the FPS given the current samples.
	 * @returns {number}
	 */
	getFPS() {
		let samplesTotal = 0;
		for (let i = 0; i < this._samples.length; i++) {
			samplesTotal += this._samples[i];
		}
		return this._samples.length / samplesTotal;
	}

	/**
	 * Updates the samples with the latest delta time.
	 * @param {number} deltaTime - The change in time between two frames.
	 */
	update(deltaTime) {
		this._samples[this._index] = deltaTime; // Used for frame-rate smoothing.
		this._index = (this._index + 1) % this._samples.length;
	}
}
