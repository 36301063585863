/** @module pioneer */

/**
 * A class of time routines.
 * @hideconstructor
 */
export class TimeUtils {
	/**
	 * Returns the ET time right now.
	 * @returns {number}
	 */
	static now() {
		return TimeUtils.unixToEt(Date.now() / 1000.0);
	}

	/**
	 * Converts an ET time to a Unix time.
	 * @param {number} et - the ET time to convert
	 * @returns {number}
	 */
	static etToUnix(et) {
		let leapSecondsOffset = 0;
		for (let i = 0; i < _leapSeconds.length; i++) {
			if (et + 946727957.816 + leapSecondsOffset >= _leapSeconds[i] + 1) {
				leapSecondsOffset -= 1;
			}
		}
		return et + 946727957.816 + leapSecondsOffset;
	}

	/**
	 * Converts a Unix time to an ET time.
	 * @param {number} unix - the Unix time to convert
	 * @returns {number}
	 */
	static unixToEt(unix) {
		let leapSecondsOffset = 0;
		for (let i = 0; i < _leapSeconds.length; i++) {
			if (unix >= _leapSeconds[i] + 1) {
				leapSecondsOffset += 1;
			}
		}
		return unix - 946727957.816 + leapSecondsOffset;
	}

	/**
	 * Returns the leap seconds array, which is the list of Unix times in seconds right before the leap second.
	 * @returns {number[]}
	 */
	static get leapSeconds() {
		return _leapSeconds;
	}
}

/**
 * The array of leap seconds, which is the list of Unix times in seconds right before the leap second.
 * @type {number[]}
 */
const _leapSeconds = [
	Date.UTC(1972, 6 - 1, 30, 23, 59, 59) / 1000.0,
	Date.UTC(1972, 12 - 1, 31, 23, 59, 59) / 1000.0,
	Date.UTC(1973, 12 - 1, 31, 23, 59, 59) / 1000.0,
	Date.UTC(1974, 12 - 1, 31, 23, 59, 59) / 1000.0,
	Date.UTC(1975, 12 - 1, 31, 23, 59, 59) / 1000.0,
	Date.UTC(1976, 12 - 1, 31, 23, 59, 59) / 1000.0,
	Date.UTC(1977, 12 - 1, 31, 23, 59, 59) / 1000.0,
	Date.UTC(1978, 12 - 1, 31, 23, 59, 59) / 1000.0,
	Date.UTC(1979, 12 - 1, 31, 23, 59, 59) / 1000.0,
	Date.UTC(1981, 6 - 1, 30, 23, 59, 59) / 1000.0,
	Date.UTC(1982, 6 - 1, 30, 23, 59, 59) / 1000.0,
	Date.UTC(1983, 6 - 1, 30, 23, 59, 59) / 1000.0,
	Date.UTC(1985, 6 - 1, 30, 23, 59, 59) / 1000.0,
	Date.UTC(1987, 12 - 1, 31, 23, 59, 59) / 1000.0,
	Date.UTC(1989, 12 - 1, 31, 23, 59, 59) / 1000.0,
	Date.UTC(1990, 12 - 1, 31, 23, 59, 59) / 1000.0,
	Date.UTC(1992, 6 - 1, 30, 23, 59, 59) / 1000.0,
	Date.UTC(1993, 6 - 1, 30, 23, 59, 59) / 1000.0,
	Date.UTC(1994, 6 - 1, 30, 23, 59, 59) / 1000.0,
	Date.UTC(1995, 12 - 1, 31, 23, 59, 59) / 1000.0,
	Date.UTC(1997, 6 - 1, 30, 23, 59, 59) / 1000.0,
	Date.UTC(1998, 12 - 1, 31, 23, 59, 59) / 1000.0,
	Date.UTC(2005, 12 - 1, 31, 23, 59, 59) / 1000.0,
	Date.UTC(2008, 12 - 1, 31, 23, 59, 59) / 1000.0,
	Date.UTC(2012, 6 - 1, 30, 23, 59, 59) / 1000.0,
	Date.UTC(2015, 6 - 1, 30, 23, 59, 59) / 1000.0,
	Date.UTC(2016, 12 - 1, 31, 23, 59, 59) / 1000.0
];
