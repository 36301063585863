/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useSnapshot } from 'valtio';
import PropTypes from 'prop-types';

import CloseButton from '../close_button';

import BackIcon from '../../../www/assets/images/arrow-nav-chevron.svg';

import '../../../www/assets/css/overlays/base_overlay.css';

import { appStore } from '../../managers/globalState';
import ar from '../../languages/ar';

/**
 * This file contains all sub components, both branch components (with children), and leaf components (without children).
*/

/**
 * Overlay header component
 * includes the default close button
 * @param {function} onClose
 */
const OverlayHeader = ({ children = null, onClose, back = false, title, Icon = null }) => {
	const { isMobile } = useSnapshot(appStore.state);
	if (isMobile) {
		return (
			<div id='header-bar' className='header-bar'>
				<div
					className='header-back' onClick={() => {
						onClose();

						const event = new CustomEvent('OpenMobileMenu');
						window.dispatchEvent(event);
					}}
				>
					{back && <BackIcon className='svg' />}
				</div>

				{
					title && (
						<div className='title-container'>
							{Icon && <Icon className='title-icon' />}
							<h2 className='title-text'>{title}</h2>
						</div>
					)
				}
				<CloseButton responsive onClick={onClose} />
				<div className='middle'>
					{children}
				</div>
			</div>
		);
	}

	return (
		<div id='header-bar' className='header-bar'>
			{
				title && (
					<div className='title-container'>
						{Icon && <Icon className='title-icon' />}
						<h2 className='title-text'>{title}</h2>
					</div>
				)
			}
			<div className='middle'>
				{children}
			</div>
			<CloseButton responsive onClick={onClose} />
		</div>
	);
};

OverlayHeader.propTypes = {
	back: PropTypes.bool,
	isMobile: PropTypes.bool,
	Icon: PropTypes.func,
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired
};

const OverlayNoResults = () => <p className='no-results'>{ar["No_result"]}</p>;
const OverlayComingSoon=() =><p className='no-results'>{ar["coming_soon"]}</p>;
export { OverlayHeader, OverlayNoResults,OverlayComingSoon };
