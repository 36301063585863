/** @module pioneer */
import {
	BaseController,
	Entity,
	Quaternion,
	Vector3
} from '../../internal';

/** A controller that rotates the position and orientation by the parent's orientation last frame change.
 *  Great for objects that are connected to their parents or landers.
 *  It needs to added as the last controller to work. */
export class FixedToParentController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The parent's last orientation.
		 * @type {Quaternion}
		 * @private
		 */
		this._lastParentOrientation = new Quaternion();

		// Start off with the correct last orientation.
		const parent = this.getEntity().getParent();
		if (parent !== null) {
			this._lastParentOrientation.copy(parent.getOrientation());
		}

		// Let the base controller know that this changes the orientation.
		this.addModifiedState('position');
		this.addModifiedState('orientation');

		// Add the dependency on the parent's orientation.
		this.addDependentState('parent', 'orientation');
	}

	/**
	 * Updates the controller.
	 * @override
	 * @internal
	 */
	__update() {
		const parent = this.getEntity().getParent();
		if (parent !== null) {
			const rotation = Quaternion.pool.get();
			rotation.inverse(this._lastParentOrientation);
			rotation.mult(parent.getOrientation(), rotation);
			const position = Vector3.pool.get();
			const orientation = Quaternion.pool.get();
			position.rotate(rotation, this.getEntity().getPosition());
			this.getEntity().setPosition(position);
			orientation.mult(rotation, this.getEntity().getOrientation());
			this.getEntity().setOrientation(orientation);
			Quaternion.pool.release(orientation);
			Vector3.pool.release(position);
			Quaternion.pool.release(rotation);
			this._lastParentOrientation.copy(parent.getOrientation());
		}
	}
}
