/** @module pioneer */
import {
	CameraComponent,
	Entity,
	EntityItem,
	FastMap,
	THREE
} from '../../internal';

/**
 * The base class for all components.
 * */
export class BaseComponent extends EntityItem {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The radius of the component.
		 * @type {number}
		 * @private
		 */
		this._radius = 0.5;

		/**
		 * The pixel-space radius of the component for each camera.
		 * @type {FastMap<CameraComponent, number>}
		 * @private
		 */
		this._pixelSpaceRadiusPerCamera = new FastMap();

		/**
		 * The greatest pixel-space radius of the component in any camera.
		 * @type {number}
		 * @private
		 */
		this._greatestPixelSpaceRadius = 0.0;

		/**
		 * The load state of the component. It can be 'unloaded', 'loading', or 'loaded'.
		 * @type {'unloaded' | 'loading' | 'loaded'}
		 * @private
		 */
		this._loadState = 'unloaded';

		/**
		 * A flag that determines if the component should always be loaded, except if disabled.
		 * @type {boolean}
		 * @private
		 */
		this._forceLoaded = false;

		/**
		 * A callback that is called right after the mesh has been created and materials have been loaded.
		 * @type {() => any}
		 * @private
		 */
		this._resourcesLoadedCallback = null;

		/**
		 * A callback that is called right after the mesh is destroyed.
		 * @type {() => any}
		 * @private
		 */
		this._resourcesUnloadedCallback = null;

		/**
		 * A promise that resolves when the resources are loaded.
		 * @type {Promise<void>}
		 * @private
		 */
		this._loadedPromise = Promise.resolve();

		/**
		 * Camera exclusion list.
		 * @type {Set<CameraComponent>}
		 * @private
		 */
		this._excludedCameras = new Set();

		/**
		 * Whether or not the component is visible. It will still be enabled and run, just not render.
		 * @type {boolean}
		 * @private
		 */
		this._visible = true;

		/**
		 * The Three.js objects.
		 * @type {THREE.Object3D[]}
		 * @private
		 */
		this._threeJsObjects = [];

		/**
		 * The Three.js materials.
		 * @type {THREE.ShaderMaterial[]}
		 * @private
		 */
		this._threeJsMaterials = [];

		/**
		 * The flag that if true sets the root Three.js objects to have the same orientation as the entity.
		 * @type {boolean}
		 * @private
		 */
		this._usesEntityOrientation = false;
	}

	/**
	 * Cleans up the component.
	 * @override
	 * @internal
	 */
	__destroy() {
		// Unload any resources used by the component.
		if (this._loadState === 'loaded') {
			this._unloadResources();
		}
		// Call the super.
		super.__destroy();
	}

	// ENABLED & DISABLED.

	/**
	 * Sets whether the component is enabled by the user. If false, __update and __prepareForRender will not run.
	 * Defaults to true.
	 * @param {boolean} enabled
	 * @override
	 */
	setEnabled(enabled) {
		super.setEnabled(enabled);
		this.__updateLoadState();
	}

	// RADIUS.

	/**
	 * Gets the radius of the component. No part of the component should be outside this.
	 * @returns {number}
	 */
	getRadius() {
		return this._radius;
	}

	/**
	 * Sets the radius of the component. No part of the component should be outside this. Defaults to 0.5;
	 * @param {number} radius
	 * @protected
	 */
	__setRadius(radius) {
		this._radius = radius;
	}

	/**
	 * Gets the pixel-space radius in a specific camera.
	 * @param {CameraComponent} camera
	 * @returns {number | undefined}
	 */
	getPixelSpaceRadiusInCamera(camera) {
		return this._pixelSpaceRadiusPerCamera.get(camera);
	}

	/**
	 * Gets the greatest pixel-space radius in all cameras.
	 * @returns {number}
	 */
	getGreatestPixelSpaceRadius() {
		return this._greatestPixelSpaceRadius;
	}

	// ACCESSING THREE.JS OBJECTS.

	/**
	 * Gets the Three.js objects. This includes any child objects.
	 * @returns {THREE.Object3D[]}
	 */
	getThreeJsObjects() {
		return this._threeJsObjects;
	}

	/**
	 * Gets the Three.js materials.
	 * @returns {THREE.ShaderMaterial[]}
	 */
	getThreeJsMaterials() {
		return this._threeJsMaterials;
	}

	/**
	 * Gets the first Three.js root or descendent that matches the name, or null if not found.
	 * @param {string} name
	 * @returns {THREE.Object3D | null}
	 */
	getThreeJsObjectByName(name) {
		for (let i = 0; i < this._threeJsObjects.length; i++) {
			const object = this._threeJsObjects[i];
			if (object.name === name) {
				return object;
			}
		}
		return null;
	}

	// LOADING AND UNLOADING OF RESOURCES.

	/**
	 * Returns the load state. It can be 'unloaded', 'loading', or 'loaded'.
	 * @returns {'unloaded' | 'loading' | 'loaded'}
	 */
	getLoadState() {
		return this._loadState;
	}

	/**
	 * Sets whether or not the component is always loaded, except if disabled. Defaults to false.
	 * @param {boolean} enabled
	 */
	setForceLoaded(enabled) {
		this._forceLoaded = enabled;
		if (this._forceLoaded && this._loadState === 'unloaded') {
			this._loadResources();
		}
	}

	/**
	 * Sets whether the component is visible. The __update function will run, but not the __prepareForRender function.
	 * Only called by Entity and BaseComponent.
	 * @internal
	 */
	__updateLoadState() {
		const entity = this.getEntity();
		const isEnabled = entity.isEnabled() && this.isEnabled();
		const isInTime = entity.isInPositionCoverage() && (entity.isInOrientationCoverage() || !this._usesEntityOrientation);

		// Create or destroy the rendering object based on the pixel-space radius.
		if ((isEnabled && (this._forceLoaded || (isInTime && this._greatestPixelSpaceRadius > 0.5))) && this._loadState === 'unloaded') {
			this._loadResources();
		}
		else if ((!isEnabled || (!this._forceLoaded && (!isInTime || this._greatestPixelSpaceRadius < 0.25))) && this._loadState === 'loaded') {
			this._unloadResources();
		}
	}

	/**
	 * Reloads the resources of the component. They will be loaded, if necessary, on the next __update().
	 */
	resetResources() {
		// If it was loading, set it to unloaded so any __loadResources function will stop.
		if (this._loadState === 'loading') {
			this._loadState = 'unloaded';
		}
		// Unload any loaded resources.
		else if (this._loadState === 'loaded') {
			this._unloadResources();
		}
	}

	/**
	 * Sets the callback that is called right after the resources have been loaded.
	 * @param {() => any} callback
	 */
	setResourcesLoadedCallback(callback) {
		this._resourcesLoadedCallback = callback;
	}

	/**
	 * Sets the callback that is called right after the resources are unloaded.
	 * @param {() => any} callback
	 */
	setResourcesUnloadedCallback(callback) {
		this._resourcesUnloadedCallback = callback;
	}

	/**
	 * Loads the Three.js objects, materials, and any other resources the component might need.
	 * @private
	 */
	_loadResources() {
		// Set the load state to loading.
		this._loadState = 'loading';
		// Load the resources via the user function and set the loaded promise.
		this._loadedPromise = this.__loadResources().then(() => {
			// If it has since been unloaded, unload and return.
			if (this._loadState !== 'loading') {
				this._unloadResources();
				return;
			}
			// Set the load state to loaded.
			this._loadState = 'loaded';
			// Call the callback.
			if (this._resourcesLoadedCallback !== null) {
				this._resourcesLoadedCallback();
			}
		});
	}

	/**
	 * Unloads any of the resources loaded by the component.
	 * @private
	 */
	_unloadResources() {
		// Unload the resources via the user function.
		this.__unloadResources();
		// Set the load state to unloaded.
		this._loadState = 'unloaded';
		// Clear the arrays.
		this._threeJsObjects = [];
		this._threeJsMaterials = [];
		// Call the callback.
		if (this._resourcesUnloadedCallback !== null) {
			this._resourcesUnloadedCallback();
		}
	}

	/**
	 * Loads the Three.js objects, materials, and any other resources the component might need.
	 * It should be implemented by subclasses and never called directly.
	 * It should populate the Three.js objects and materials arrays.
	 * @returns {Promise<void | void[]>}
	 * @protected
	 */
	__loadResources() {
		return Promise.resolve();
	}

	/**
	 * Unloads any of the resources loaded by the component.
	 * It should be implemented by subclasses and never called directly.
	 * It does not need to clear the Three.js objects and materials arrays.
	 * @protected
	 */
	__unloadResources() {
	}

	/**
	 * Gets a promise that resolves when the texture is loaded.
	 * @override
	 * @returns {Promise<void>}
	 */
	getLoadedPromise() {
		return this._loadedPromise;
	}

	// UPDATING

	/**
	 * Updates the camera-dependent parts of the component.
	 * @param {CameraComponent} camera - the camera being used in the render
	 * @abstract
	 */
	__updateCameraVariablesBase(camera) {
		// Get the viewport using the camera.
		const viewport = camera.getViewport();

		// Get the normal-space extents radius.
		const normalSpaceRadius = camera.getNormalSpaceRadiusFromRadius(this._radius, this.getEntity().getCameraSpacePosition(camera).magnitude());

		// Get the pixel-space extents radius.
		const pixelSpaceRadius = viewport.getPixelSpaceRadiusFromNormalSpaceRadius(normalSpaceRadius);
		this._pixelSpaceRadiusPerCamera.set(camera, pixelSpaceRadius);

		// Call the component's updateCameraVariables function.
		this.__updateCameraVariables(camera);
	}

	/**
	 * Updates the component.
	 * @internal
	 */
	__updateBase() {
		// Update the greatest pixel-space radius.
		this._greatestPixelSpaceRadius = 0.0;
		for (let i = 0, l = this._pixelSpaceRadiusPerCamera.size; i < l; i++) {
			const pixelSpaceRadius = this._pixelSpaceRadiusPerCamera.getAt(i).value;
			if (this._greatestPixelSpaceRadius < pixelSpaceRadius) {
				this._greatestPixelSpaceRadius = pixelSpaceRadius;
			}
		}

		this.__updateLoadState();

		// Call the component's update function.
		this.__update();
	}

	/**
	 * Updates the camera-dependent parts of the component. Only called by the Entity.
	 * @param {CameraComponent} camera - the camera being used in the render
	 * @abstract
	 */
	__prepareForRenderBase(camera) {
		// If the component has been loaded.
		if (this._loadState === 'loaded') {
			// If the component is not excluding this camera,
			if (!this._excludedCameras.has(camera) && this._visible) {
				// Make all objects visible.
				for (let i = 0; i < this._threeJsObjects.length; i++) {
					this._threeJsObjects[i].visible = true;
				}

				// Call the component's prepareForRender.
				this.__prepareForRender(camera);

				// Set the log-depth buffer uniforms, if they exist.
				for (let i = 0; i < this._threeJsMaterials.length; i++) {
					const uniforms = this._threeJsMaterials[i].uniforms;
					if (uniforms['invertDepth'] !== undefined) {
						uniforms['invertDepth'].value = camera.getInvertDepth();
						uniforms['nearDistance'].value = camera.getAutoNearDistance();
						uniforms['midDistance'].value = camera.getAutoMidDistance();
					}
				}

				// Update the matrix for the objects, since it isn't done automatically in Three.js (unset by a flag).
				for (let i = 0; i < this._threeJsObjects.length; i++) {
					this._threeJsObjects[i].updateMatrix();
				}
			}
			// If the component is excluded in this camera.
			else {
				// Make all objects invisible.
				for (let i = 0; i < this._threeJsObjects.length; i++) {
					this._threeJsObjects[i].visible = false;
				}
			}
		}
	}

	/**
	 * Updates the camera-dependent parts of the component.
	 * @param {CameraComponent} _camera - the camera being used in the render
	 * @abstract
	 */
	__updateCameraVariables(_camera) {
	}

	/**
	 * Updates the camera-dependent parts of the component. Implemented by the component.
	 * @param {CameraComponent} _camera
	 * @abstract
	 */
	__prepareForRender(_camera) {
	}

	/**
	 * Gets the flag that if true, the component uses the entity's orientation.
	 * @returns {boolean}
	 */
	getUsesEntityOrientation() {
		return this._usesEntityOrientation;
	}

	/**
	 * Sets the flag that if true sets the root Three.js objects to have the same orientation as the entity. Defaults to false.
	 * @param {boolean} enabled
	 * @protected
	 */
	__setUsesEntityOrientation(enabled) {
		this._usesEntityOrientation = enabled;
		this.__updateLoadState();
	}

	// VISIBILITY OF THREE.JS OBJECTS.

	/**
	 * Returns true if the component is currently excluded from the camera.
	 * @param {CameraComponent} camera
	 * @returns {boolean}
	 */
	isExcludedFromCamera(camera) {
		return this._excludedCameras.has(camera);
	}

	/**
	 * Sets whether the component is excluded from the camera. The default is false.
	 * @param {CameraComponent} camera
	 * @param {boolean} excluded
	 */
	setExcludedFromCamera(camera, excluded) {
		if (!excluded && this._excludedCameras.has(camera)) {
			this._excludedCameras.delete(camera);
		}
		else if (excluded && !this._excludedCameras.has(camera)) {
			this.__removeCameraDependents(camera);
			this._excludedCameras.add(camera);
		}
	}

	/**
	 * Returns true if the component is visible. It will still be enabled and run, just not render.
	 * @returns {boolean}
	 */
	isVisible() {
		return this._visible;
	}

	/**
	 * Sets whether or not the component is visible. It will still be enabled and run, just not render. Defaults to true.
	 * @param {boolean} visible
	 */
	setVisible(visible) {
		this._visible = visible;
	}

	/**
	 * Removes the camera from any camera-dependent variables. Called during camera clean up.
	 * @param {CameraComponent} camera
	 */
	__removeCameraDependentsBase(camera) {
		// Remove any camera-specific variables.
		this._pixelSpaceRadiusPerCamera.delete(camera);

		// Call the user-implemented function.
		this.__removeCameraDependents(camera);
	}

	/**
	 * Clears the camera from any camera-dependent variables. Called by entity when clearing its camera references.
	 * @internal
	 */
	__clearCameraDependentsBase() {
		// Clear any camera-specific variables.
		this._pixelSpaceRadiusPerCamera.clear();

		// Call the user-implemented function.
		this.__clearCameraDependents();
	}

	/**
	 * Removes the camera from any camera-dependent variables. Called during camera clean up.
	 * @param {CameraComponent} _camera
	 */
	__removeCameraDependents(_camera) {
	}

	/**
	 * Clears the camera from any camera-dependent variables. Called by entity when clearing its camera references.
	 * @internal
	 */
	__clearCameraDependents() {
	}

	// MISCELLANEOUS

	/**
	 * Converts the entity item to a nice string.
	 * @override
	 * @returns {string}
	 */
	toString() {
		let typeIndex = 0;
		// Search the components for the type.
		for (let i = 0, l = this.getEntity().getNumComponents(); i < l; i++) {
			const component = this.getEntity().getComponent(i);
			if (this === component) {
				break;
			}
			if (this.getType() === component.getType()) {
				typeIndex += 1;
			}
		}
		return this.getEntity().getName() + '.' + this.getType() + '.' + typeIndex;
	}
}

/**
 * A temporary Three.js Quaternion.
 * @type {THREE.Quaternion}
 */
BaseComponent._tempThreeJsQuaternion = new THREE.Quaternion();
