import { useSnapshot } from 'valtio';

import { kioskStore, uiStore } from '../managers/globalState';

import '../../www/assets/css/loading.css';
import { getParams } from '../helpers/tools';
import ar from '../languages/ar';

const baseLoadingPriorities = [true, false, null];

const defaultLoadingPriorities = [
	'model',
	'texture',
	'event',
	'video',
	'manifest-data',
	'events-data',
	'spacecraft-data',
	...baseLoadingPriorities
];

/**
 * The default loading priorities cover most scenarios.
 * However, depending on what we're showing, we can re-order the loading text display priorities.
 */
const getLoadingPriorities = () => {
	const { videoParam, eventParam, eventYear } = getParams();

	if (videoParam) {
		return ['video'];
	} else if (eventParam || eventYear) {
		return ['video', 'event', 'events-data'];
	}

	return defaultLoadingPriorities;
};

// Show/hide loading functions.
/**
 * Show loading.
 * @param {string|boolean} loading
 */
const showLoading = (loading = true) => {
	const { loading: currentLoading } = uiStore.stateSnapshot;

	const loadingPriorities = getLoadingPriorities();
	const currentPriorityIndex = loadingPriorities.indexOf(currentLoading);

	const newPriorityIndex = loadingPriorities.indexOf(loading);
	const isPriority = newPriorityIndex !== -1;
	const higherPriority = isPriority && (newPriorityIndex < currentPriorityIndex || !currentLoading);

	if (higherPriority) {
		uiStore.setGlobalState({ loading });
	}
};

/**
 * Hide loading will only hide if the loading message matches the current loading message.
 * @param {string|boolean} loading
 */
const hideLoading = loading => {
	const { loading: currentLoading } = uiStore.stateSnapshot;
	if (currentLoading === loading) {
		uiStore.setGlobalState({ loading: false });
	}
};

const getLoadingText = (input, forceVisible) => {
	if (typeof input !== 'string' || forceVisible) {
		return ar.loading;
	}

	switch (input) {
		case 'manifest-data':
		case 'events-data':
		case 'spacecraft-data':
			return ar.loading_data;
		case 'texture':
			return ar.loading_data;
		case 'event':
			return ar.loading_data;
		case 'model':
			return ar.loading_model;
		case 'video':
			return ar.loading_video;

		default:
			return ar.loading;
	}
};

/**
 * Loading component.
 * It would be nice to use this with suspense but that's a little tricky with multiple
 * loading sources.
 */
const Loading = ({ forceVisible = false }) => {
	const { loading } = useSnapshot(uiStore.state);
	const { maxSessionTime, maxInactivityTime } = useSnapshot(kioskStore.state);
	const kioskIsEnabled = maxSessionTime !== null || maxInactivityTime !== null;
	const loadingText = getLoadingText(loading, forceVisible);

	const showLoading = Boolean(forceVisible || loading);
	const offsetUp = kioskIsEnabled;

	return (
		<div id='loading-icon' className={`${showLoading ? '' : 'hidden'}${offsetUp ? ' offset-up' : ''}`}>
			<div className='cube-icon'>
				<div className='bg face' />
				<div className='front face' />
				<div className='left face' />
				<div className='top face' />
				<div className='right face' />
				<div className='bottom face' />
			</div>

			<div className='text'>{loadingText}</div>
		</div>
	);
};

export default Loading;

export { showLoading, hideLoading };
