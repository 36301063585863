/* eslint-disable camelcase */
import { useCallback, useRef, createRef, useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { useSnapshot } from 'valtio';

import { gmapStore, appStore, uiStore } from '../../managers/globalState';
import globalRefs from '../../managers/globalRefs';

import Overlay from '../overlays';
import GridList from '../grid_list';
import Filter from '../filter';
import ThumbnailLoader from '../thumbnail_loader';

import GlobeIcon from '../../../www/assets/images/globe_icon.min.svg';
import EventSVG from '../../../www/assets/images/event-marker.svg';
import SandImg  from '../../../www/assets/images/sand_movement.png';
import '../../../www/assets/css/overlays/events.css';


import {
	updateYear,
	updateCategory,
	filterGmap
} from '../../helpers/processGmap';
// import { GMAP_CATEGORIES, DYNAMIC_ASSETS_URL } from '../../config/constants';

import { Config } from '../../config/config';
// import StaticIcon from '../../../www/assets/images/static_icon.svg';
// import AnimatedIcon from '../../../www/assets/images/animated_icon.svg';
import ar from "../../languages/ar"

/**
 * Constant vars
 */
// const API_BASE_URL = window.config.apiUrl ? window.config.apiUrl : 'https://climate.nasa.gov';

const THUMB_WIDTH = 159;
const THUMB_HEIGHT = 102;
const ITEM_PADDING = 30;
const TITLE_TEXT = ar['Ce2s2_Projects'];
// const BUTTON_TEXT = 'عرض على الكرة الأرضية';
const PROJECT_TABS = [ ar['Earth_Deformation'],ar['Sand_Movement'],ar['Methane']];
/**
 * ButtonText component
 */
// const ButtonText = () => <span>{BUTTON_TEXT}</span>;
const sand_movement_des=ar['sand_movement_des'];
/**
 * Individual EventItem component to represent each event in the grid
 */
const GmapItem = props => {
	const [isHovered, setIsHovered] = useState(false);

	const { title, external_id, list_image_src,date, formatted_date, onItemClick } = props;
	let srcUrl = `${Config.baseCe2s2Url}${list_image_src}`;  	
	const onClick = useCallback(() => {
		onItemClick(external_id);
	}, [onItemClick, external_id]);

	const containerStyle = {
		width: `${THUMB_WIDTH}px`,
		height: `${THUMB_HEIGHT}px`
	};
	const tipTitle = ''

	// Event handlers
	const handleMouseEnter = () => setIsHovered(true);
	const handleMouseLeave = () => setIsHovered(false);

	return (
		<figure className='event-item' onClick={() => onItemClick(external_id)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<div className='image-time-container' style={containerStyle}>
				<img className={`thumbnail ${isHovered ? 'zoom' : ''}`} src={srcUrl} alt={title} />
				<div className='icon-date-container'>
					<div className='icon-group' aria-hidden='true' title={tipTitle} aria-label={tipTitle}>
						{/* <StaticIcon /> */}
					</div>
					<time className='event-date' dateTime={date}>{formatted_date}</time>
				</div>
			</div>
			<figcaption className='event-title'>{title}</figcaption>
		</figure>
	);
};


/**
 * Gmap Overlay component
 */

const GmapOverlay = ({ closeOverlay }) => {
	const mainRef = useRef();
	const scrollableNodeRef = createRef();

	// get the global state
	const { yearIndex, categoryIndex, gmaps, gmap_cats,gmapYears } = useSnapshot(gmapStore.state);
	const { isWindows } = useSnapshot(appStore.state);
    const [isEnlarged, setIsEnlarged] = useState(false);

	const handleImageClick = () => {
		setIsEnlarged((prev) => !prev);
		
	};
	// Store the category index locally as it shouldnt be set in the global state unless we View on Globe.
	const initialYearIndex = useRef(yearIndex);
	const initialCategoryIndex = useRef(categoryIndex);
    //    // category state and update
	   const Project_category_index=0;
	   const [categoryPIndex, setPCategoryIndex] = useState(Project_category_index);
	   const updateProjectCategory = useCallback(e => {
	   const newCategory = e.currentTarget.getAttribute('data-filter');
	   console.log("Indexxxx",newCategory)
	   const newCategoryIndex = PROJECT_TABS.indexOf(newCategory);
	   if (newCategoryIndex !== categoryPIndex) {
		   setPCategoryIndex(newCategoryIndex);
	   }
	   }, [categoryPIndex]);
	// Filter gmaps.
	//const currentCategoryParam = categoryIndex !== null ? GMAP_CATEGORIES[categoryIndex][0] : 'all';

	//const filteredData = filterGmap(gmaps,gmapYears, yearIndex, categoryIndex);
    //TODO:Fix this use switch
	//const filteredData = categoryPIndex== 0 ? filterGmap(gmaps,gmapYears, yearIndex, categoryIndex): [];
	let filteredData = [];

	switch (categoryPIndex) {
	case 0:
		filteredData = filterGmap(gmaps, gmapYears, yearIndex, categoryIndex);
		break;
	case 1:
		
		filteredData=[
			{
				id: 1,
				image: SandImg, 
				description: sand_movement_des
			  }
		];
		break;
	case 2:
		filteredData = []
		
		break;
	}
	// navToEvent callback.
	const navToGmap = useCallback(eventParam => {
		closeOverlay(true);
		const { getManager } = globalRefs;
		getManager('route').navigateToGmap(eventParam);
	}, [closeOverlay]);

	// viewOnGlobe callback
	// const viewOnGlobe = useCallback(() => {
	// 	const { getManager } = globalRefs;
	// 	// Close the overlay  pass false since no event was selected.
	// 	closeOverlay(false);
	// 	uiStore.setGlobalState({ isDetailPanelOpen: false });
	// 	console.log("TODO navigate to gmap year?")
	// 	// getManager('route').navigateToEventYear(poiYears, currentCategoryParam, currentFilter);
	// }, [closeOverlay, gmapYears, currentCategoryParam]);

	// on mount, update the dropdowns to the current year, but default category and media to all.
	useEffect(() => {
		updateYear(yearIndex);
		updateCategory(0);
		return () => {
			// on unmount, reset the dropdowns to the initial year and category.
			updateYear(initialYearIndex.current);
			updateCategory(initialCategoryIndex.current);
		};
	}, []);


	const overlayComponents = (
		<>
			<Overlay.Header
				back
				onClose={closeOverlay}
				title={TITLE_TEXT}
				Icon={EventSVG}
			>
				<div className='events-header-content'>
					{/* <div className='events-header-filters'>
						<Filter
							type='dropdown'
							title='Year'
							style={{ padding: 10 }}
							allItems={gmapYears.map(v => v[0])}
							selectedIndex={yearIndex}
							updateItem={updateYear}
						/>
						<Filter
							type='dropdown'
							style={{ padding: 10 }}
							title='Category'
							allItems={GMAP_CATEGORIES.map(v => v[1])}
							selectedIndex={categoryIndex}
							updateItem={updateCategory}
						/>
			
					</div>
					<div className='globe-border'>
						<div className='button' onClick={viewOnGlobe}>
							<GlobeIcon />
							<ButtonText />
						</div>
					</div> */}
					<div className='events-header-filters'>
				
				<Filter
			   type='categories'
			   allItems={PROJECT_TABS}
			   selectedIndex={categoryPIndex}
			   updateItem={updateProjectCategory}
		   />
			   </div>
				</div>
			</Overlay.Header>

			{ filteredData === null ? <ThumbnailLoader loaderText='loading events' /> : null }
			{ filteredData?.length === 0 ? <Overlay.ComingSoon /> : null }
			{
				filteredData?.length
					? (
						<div className='content-mask'>
							{categoryPIndex === 0  ? (
          // Display GridList when index is 0
          <GridList
            rowClassName='flex-grid-row'
            minPerRow={2}
            dataList={filteredData}
            itemSize={THUMB_WIDTH + ITEM_PADDING * 2}
            itemPadding={[ITEM_PADDING, ITEM_PADDING]}
            itemComponent={<GmapItem onItemClick={navToGmap} />}
            scrollableRef={isWindows ? scrollableNodeRef : mainRef}
          />
        ) : categoryPIndex === 1 ? (
          // Display image and description 
         <div className="image-description-container">
      {filteredData.map((item) => (
        <div key={item.id} className="image-description-item" >
          <img src={item.image} alt={item.id}  style={{ maxWidth: isEnlarged ? "90%" : "30%"}}
		onClick={handleImageClick} />
          <p>{item.description}</p>
        </div>
      ))}
    </div>
        ) : null}
      </div>
    )
					: null
			}

		</>
	);
	// compose overlay
	return (
		<Overlay type='events' ref={mainRef}>
			{isWindows ?
				<SimpleBar scrollableNodeProps={{ ref: scrollableNodeRef }} style={{ height: '100%' }}>
					{overlayComponents}
				</SimpleBar> :
				overlayComponents}
		</Overlay>
	);
};


export default GmapOverlay;
