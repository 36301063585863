/** @module pioneer */
import {
	BaseComponent,
	CameraComponent,
	Entity,
	EntityRef,
	MathUtils,
	Vector2,
	Vector3
} from '../../internal';

/**
 * Div component. Creates an HTML div element located at position of the entity, using absolute positioning.
*/
export class DivComponent extends BaseComponent {
	/**
	 * Constructor.
	 * @param {string} type - the type of the component
	 * @param {string} name - the name of the component
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The camera in which the div is active.
		 * @type {CameraComponent}
		 * @private
		*/
		this._activeCamera = null;

		/**
		 * The flag that determines whether or not the div ignores the distance to the camera when determining visibility.
		 * @type {boolean}
		 * @private
		 */
		this._fadeWhenCloseToCamera = true;

		/**
		 * The entity that, if defined, when the div gets close to, fades away. If set to '', it defaults to the parent.
		 * @type {EntityRef}
		 * @private
		 */
		this._fadeWhenCloseToEntity = new EntityRef(this.getEntity().getScene());

		/**
		 * The HTML div element. It will be contained in the labels container div, which will be after the canvas element.
		 * @type {HTMLDivElement}
		 * @private
		 */
		this._div = document.createElement('div');
		this._div.style.position = 'absolute';
		this._div.style.left = '0';
		this._div.style.top = '0';
		this._div.style.transform = 'translate(0%, 0%);';

		/**
		 * The alignment of the text.
		 * @type {Vector2}
		 * @private
		 */
		this._alignment = new Vector2(0.0, 0.5);
		this._alignment.freeze();

		/**
		 * The size of the div in px. Used instead of offsetWidth/offsetHeight every frame so as not to trigger reflow.
		 * @type {Vector2}
		 * @private
		 */
		this._sizeInPx = new Vector2(0, 0);

		/**
		 * The current html, used to see if the sizeInPx needs to be updated.
		 * @type {string}
		 * @private
		 */
		this._currentHTML = '';

		// Set the radius to infinity, since it will always be visible.
		this.__setRadius(Number.POSITIVE_INFINITY);
	}

	/**
	 * Gets the div.
	 * @returns {HTMLDivElement}
	 */
	getDiv() {
		return this._div;
	}

	/**
	 * Gets the camera in which the div is active.
	 * @returns {CameraComponent}
	 */
	getActiveCamera() {
		return this._activeCamera;
	}

	/**
	 * Sets the camea in which the div is active. Defaults to the first viewport when created.
	 * @param {CameraComponent} activeCamera
	 */
	setActiveCamera(activeCamera) {
		this._activeCamera = activeCamera;
		this.resetResources();
	}

	/**
	 * Gets the flag that determines whether or not the div ignores the distance to the camera when determining visibility.
	 * @returns {boolean}
	 */
	getFadeWhenCloseToCamera() {
		return this._fadeWhenCloseToCamera;
	}

	/**
	 * Sets the flag that determines whether or not the div ignores the distance to the camera when determining visibility. Defaults to true.
	 * @param {boolean} enable
	 */
	setFadeWhenCloseToCamera(enable) {
		this._fadeWhenCloseToCamera = enable;
	}

	/**
	 * Gets the entity name that, if defined, when the div gets close to, fades away.
	 * @returns {string}
	 */
	getFadeWhenCloseToEntity() {
		return this._fadeWhenCloseToEntity.getName();
	}

	/**
	 * Sets the entity name that, if defined, when the div gets close to, fades away. If set to '', it defaults to the parent. Defaults to ''.
	 * @param {string} entityName
	 */
	setFadeWhenCloseToEntity(entityName) {
		this._fadeWhenCloseToEntity.setName(entityName);
	}

	/**
	 * Gets the div alignment. Defaults to the left aligned to the entity and vertically centered.
	 * @returns {Vector2}
	 */
	getAlignment() {
		return this._alignment;
	}

	/**
	 * Sets the alignment. Defaults to Vector2(0.0, 0.5).
	 * @param {Vector2} alignment - the alignment to set
	 */
	setAlignment(alignment) {
		this._alignment.thaw();
		this._alignment.copy(alignment);
		this._alignment.freeze();
	}

	/**
	 * Loads the resources needed by the component.
	 * @returns {Promise<void>}
	 * @override
	 * @protected
	 */
	__loadResources() {
		return Promise.resolve();
	}

	/**
	 * Unloads any resources used by the component.
	 * @override
	 * @protected
	 */
	__unloadResources() {
		// Detach the div from its parent.
		if (this._div.parentNode !== null) {
			this._div.parentNode.removeChild(this._div);
		}
	}

	/**
	 * Prepares the component for render.
	 * @param {CameraComponent} camera
	 * @override
	 * @internal
	 */
	__prepareForRender(camera) {
		// If there's no active camera, set the first viewport as the default one.
		if (this._activeCamera === null) {
			const firstViewport = this.getEntity().getScene().getEngine().getViewport(0);
			if (firstViewport !== null) {
				this._activeCamera = firstViewport.getCamera();
			}
		}
		// Check to see that there's a camera and viewport set up.
		if (this._activeCamera !== camera || this._activeCamera === null || this._activeCamera.getViewport() === null) {
			return;
		}
		// Attach the div to the viewport's div, if it isn't already done.
		if (this._activeCamera.getViewport().getDiv() !== this._div.parentNode) {
			this._activeCamera.getViewport().getDiv().appendChild(this._div);
		}

		let alphaMultiplier = 1;

		// Fade the label when too close to the object.
		if (alphaMultiplier > 0 && this._fadeWhenCloseToCamera) {
			const normalizedSizeOfEntity = this.getEntity().getNormalSpaceExtentsRadius(camera);
			alphaMultiplier *= MathUtils.clamp01((0.02 - normalizedSizeOfEntity) / 0.02);
		}

		// Fade the div when the entity div is visually close to the closest parent div.
		const cameraSpacePosition = this.getEntity().getCameraSpacePosition(camera);
		if (alphaMultiplier > 0) {
			let closeToEntity = /** @type {Entity} */(null);
			if (this._fadeWhenCloseToEntity.getName() === '') {
				closeToEntity = this.getEntity().getParent();
			}
			else {
				closeToEntity = this._fadeWhenCloseToEntity.get();
			}
			if (closeToEntity !== null) {
				const normalSpaceDifferenceFromParent = Vector3.pool.get();
				normalSpaceDifferenceFromParent.sub(this.getEntity().getNormalSpacePosition(camera), closeToEntity.getNormalSpacePosition(camera));
				const normalizedEntityDistanceFromParent = normalSpaceDifferenceFromParent.magnitude();
				Vector3.pool.release(normalSpaceDifferenceFromParent);
				const normalizedParentRadius = closeToEntity.getNormalSpaceExtentsRadius(camera);
				if (normalizedParentRadius < 0.02) {
					alphaMultiplier *= MathUtils.clamp01((normalizedEntityDistanceFromParent - 0.02) / 0.02);
				}
			}
		}

		// If it is occluded, hide the div. Check parent always, too.
		if (alphaMultiplier > 0) {
			if (this.getEntity().getParent() !== null && this.getEntity().getParent().isOccludingPosition(camera, cameraSpacePosition)) {
				alphaMultiplier = 0;
			}
			else if (camera.isPositionOccluded(cameraSpacePosition)) {
				alphaMultiplier = 0;
			}
		}

		// If the div is behind the camera, make it invisible.
		if (alphaMultiplier > 0) {
			const cameraForward = Vector3.pool.get();
			camera.getEntity().getOrientation().getAxis(cameraForward, 1);
			if (cameraForward.dot(this.getEntity().getCameraSpacePosition(camera)) <= 0) {
				alphaMultiplier = 0;
			}
			Vector3.pool.release(cameraForward);
		}

		// Set the opacity and pointerEvents from the above conditions.
		if (this._div.style.opacity !== '' + alphaMultiplier) {
			this._div.style.opacity = '' + alphaMultiplier;
			if (alphaMultiplier === 0) {
				this._div.style.pointerEvents = 'none';
			}
			else {
				this._div.style.pointerEvents = '';
			}
		}

		if (alphaMultiplier > 0) {
			// Update the pixel size if needed.
			if (this._currentHTML !== this._div.innerHTML) {
				this._sizeInPx.set(this._div.offsetWidth, this._div.offsetHeight);
				this._currentHTML = this._div.innerHTML;
			}

			// Set the position of the div.
			const renderBounds = camera.getViewport().getBounds();
			const pixelSpacePosition = this.getEntity().getPixelSpacePosition(camera);
			const left = pixelSpacePosition.x - this._sizeInPx.x * this._alignment.x - renderBounds.origin.x + (renderBounds.size.x % 2 === 0 ? -0.5 : 0);
			const top = pixelSpacePosition.y - this._sizeInPx.y * this._alignment.y - renderBounds.origin.y + (renderBounds.size.y % 2 === 0 ? -0.5 : 0);
			this._div.style.transform = `translate(${left}px, ${top}px)`;
		}
	}
}
