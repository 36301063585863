/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { MathUtils } from 'pioneer';

import '../../www/assets/css/event_preview.css';

import { videoStore, previewStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';

import { testAnimatedEventGlobal, testAnimatedGeoLocated, testStaticGeoLocated } from '../data/event_preview_test';
import { PREVIEW_PATH_LIVE } from '../config/constants';
import { loadGeoLocatedStatic, parseEvent, parseEventSlug } from '../helpers/processEvents';
import { hideLoading, showLoading } from '../components/loading';

/**
 * This component is designed to display a preview of an event once it's been uploaded to the CMS preview system.
 * The route for previewing an event:
 * /events/preview/:eventPreviewSlug
 * The eventPreviewSlug is a hyphenated string that represents the path to the event preview json data (currently located on BH3)
 * For instance, dev-preview-eo-24-0701 will load the data from the following path:
 * /dev/preview/eo/24/0701.json
 * The base path is hard coded as a constant, currently:
 * const PREVIEW_PATH_LIVE = 'https://blackhawk3.jpl.nasa.gov/assets/dynamic/earth_events';
 *
 * Test Mode:
 * To test the event preview, you can use the following test urls:
 * - events/preview/test-global
 * - events/preview/test-geoLocated
 *
 * Currently, we're previewing event videos, but this could be expanded to include still images, or other types of events.
 * There are 2 types of video:
 * - global: a video that is displayed on the earth.
 *   - can have a lat and/or lon offsets
 * - geoLocated: a video that is displayed on the earth at a specific location.
 *   - requires NW, NE, SW, SE latlon values
 */


// Helpers

/**
 * Fetcher for the preview data.
 * @param {string} url
 * @returns {Promise}
 */
const fetchPreviewData = url => fetch(url)
	.then(response => {
		if (!response.ok) {
			throw new Error('Network response was not valid.');
		}
		return response.json();
	})
	.then(data => data);


/**
 * Component to load the preview data given the previewEventSlug.
 * @param {string} previewEventSlug
 * @returns {React.Component}
 */
const PreviewDataLoader = ({ previewEventSlug }) => {
	useEffect(() => {
		// Use the current video loader.
		videoStore.setGlobalState({ isVideoLoading: true });

		// Check if we are in test mode.
		const isTestMode = previewEventSlug?.startsWith('test-');

		if (isTestMode) {
			// Determine which test data to use.
			let previewData = testAnimatedEventGlobal;

			if (previewEventSlug === 'test-static') {
				previewData = testStaticGeoLocated;
			} else if (previewEventSlug === 'test-geoLocated') {
				previewData = testAnimatedGeoLocated;
			}

			const parsedPreviewData = parseEvent(previewData, { isPreview: true, isTest: true });

			// Set the global state.
			previewStore.setGlobalState({ previewEventData: parsedPreviewData, previewError: null });
		} else {
			// Parse the event path.
			const eventFile = parseEventSlug(previewEventSlug);
			const eventFolder = eventFile.split('/').slice(0, -1).join('/');
			const dataPath = `${PREVIEW_PATH_LIVE}/${eventFile}.json`;

			// Fetch the preview data.
			fetchPreviewData(dataPath)
				.then(previewData => {
					// Parse the preview data.
					const parsedPreviewData = parseEvent(previewData, { isPreview: true, previewEventFolder: eventFolder });

					// Set the global state.
					previewStore.setGlobalState({ previewEventData: parsedPreviewData, previewError: null });
				})
				.catch(previewError => {
					// Set the previewError state.
					previewStore.setGlobalState({ previewEventData: null, previewError });

					// Set isLoading to false.
					videoStore.setGlobalState({ isVideoLoading: false });
				});
		}
	}, [previewEventSlug]);

	return null;
};

/**
 * Displays event preview error.
 */
const PreviewError = () => {
	const { previewError } = useSnapshot(previewStore.state);

	return previewError && (
		<div className='preview-error'>
			<h2>Event Preview Data Error:</h2>
			<h3>{previewError.message}</h3>
		</div>
	);
};

/**
 * Loads the event once it has preview data.
 */
const PreviewLoader = () => {
	const { previewEventData } = useSnapshot(previewStore.state);
	// Loading the same event multiple times can cause errors. Therefore we use a ref to track if we're loading.
	const isLoading = useRef(null);

	useEffect(() => {
		const { getManager, pioneer } = globalRefs;
		const { id, isAnimated, type } = previewEventData || {};
		const alreadyLoading = isLoading.current === id;

		// If there is no video data, return.
		if (!previewEventData || alreadyLoading) {
			return;
		}

		// Set isLoading.
		isLoading.current = id;

		// Show loading.
		const loadType = isAnimated ? 'video' : 'event';
		showLoading(loadType);

		// Set isVideoTexture in global state.
		videoStore.setGlobalState({ isVideoTexture: isAnimated });

		const patchComponent = pioneer.get('main', 'earth').getComponent('patch');

		// NOTE: Currently assuming global static events are not possible.
		(isAnimated ? getManager('video').loadVideo(previewEventData) : loadGeoLocatedStatic(previewEventData))
			.then(() => {
				// Hide loading.
				hideLoading(loadType);

				// Set isLoading to false.
				isLoading.current = false;
			})
			.catch(e => e);

		// On currentVideo change / unmount disable the earth patch.
		return () => {
			if (type === 'geoLocated') {
				patchComponent?.setEnabled?.(false);
			}
		};
	}, [previewEventData]);


	// useEffect to unload and disable patch when unmounting
	useEffect(() => () => {
		// Disable the patch.
		const { pioneer } = globalRefs;
		const earth = pioneer.get('main', 'earth');
		const patchComponent = earth.getComponent('patch');
		patchComponent.setEnabled(false);

		// Set isLoading to null and hide loading.
		isLoading.current = null;
		hideLoading('event');
		hideLoading('video');

		// Reset the previewEventData to unload.
		previewStore.setGlobalState({ previewEventData: null });

		// Reset isVideoTexture in global state.
		videoStore.setGlobalState({ isVideoTexture: null });
	}, []);

	return null;
};


const EventPreview = () => {
	const { eventPreviewParam } = useParams();

	return (
		eventPreviewParam && (
			<div className='event-preview-wrapper'>
				<PreviewDataLoader previewEventSlug={eventPreviewParam} />
				<PreviewError />
				<PreviewLoader />
			</div>
		)

	);
};

export default EventPreview;
