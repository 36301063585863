import React from 'react';
import { useSnapshot } from 'valtio';
import '../../www/assets/css/speed_button.css';
import { timeStore } from '../managers/globalState';
import PauseSolidSVG from '../../www/assets/images/pause_solid.svg';

const SpeedButton = ({ enabled }) => {
	const { timeRate, isRealTime } = useSnapshot(timeStore.state);

	const buttonContent = isRealTime
		? <span className='speed-label live'>مباشر</span>
		: timeRate === 0
			? (
				<>
					<PauseSolidSVG />
					<span className='speed-label paused'>إيقاف مؤقت</span>
				</>
			)
			: (
				<>
					<span className='speed-value'>
						{timeRate}
						x
					</span>
					<span className='speed-label'>السرعة</span>
				</>
			);

	return (
		<button className={`speed-square-button ${enabled ? 'active' : ''}`}>{buttonContent}</button>
	);
};

export default SpeedButton;
