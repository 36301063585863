/** @module pioneer */
import {
	BaseController,
	Entity,
	Geometry,
	Interval,
	SpheroidComponent,
	Vector3
} from '../../internal';

/**
 * A pick controller. It lets the user pick a location on a given entity.
 */
export class PickController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The entity to be picked.
		 * @type {Entity}
		 * @private
		 */
		this._pickedEntity = null;

		/**
		 * The callback to be called when the user picks a location on the entity.
		 * @type {(position: Vector3) => void}
		 * @private
		 */
		this._callback = null;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._triggerOnHover = false;

		/**
		 * @type {Vector3}
		 * @private
		 */
		this._pickedPosition = new Vector3();
		this._pickedPosition.freeze();
	}

	/**
	 * Gets the entity to be picked.
	 * @returns {Entity}
	 */
	getPickedEntity() {
		return this._pickedEntity;
	}

	/**
	 * Sets the entity to be picked.
	 * @param {Entity} entity
	 */
	setPickedEntity(entity) {
		this._pickedEntity = entity;
	}

	/**
	 * Returns the callback to be called when the user picks a position on the entity. The position is in the entity-frame of the picked entity.
	 * @returns {(position: Vector3) => void}
	 */
	getCallback() {
		return this._callback;
	}

	/**
	 * Sets the callback to be called when the user picks a position on the entity. The position is in the entity-frame of the picked entity.
	 * @param {(position: Vector3) => void} callback
	 */
	setCallback(callback) {
		this._callback = callback;
	}

	/**
	 * Gets whether the callback is triggered when hovering or just selecting.
	 * @returns {boolean}
	 */
	getTriggerOnHover() {
		return this._triggerOnHover;
	}

	/**
	 * Sets whether the callback is triggered when hovering or just selecting.
	 * @param {boolean} triggerOnHover
	 */
	setTriggerOnHover(triggerOnHover) {
		this._triggerOnHover = triggerOnHover;
	}

	/**
	 * Gets the last picked position. The position is in the entity-frame of the picked entity.
	 * @returns {Vector3}
	 */
	getPickedPosition() {
		return this._pickedPosition;
	}

	/**
	 * Takes input and calls the callback if there is any selection.
	 * @override
	 * @internal
	 */
	__update() {
		const input = this.getEntity().getScene().getEngine().getInput();

		if ((input.isSelected() || this._triggerOnHover) && this._callback !== null && this._pickedEntity !== null) {
			const viewport = input.getActiveViewport();
			if (viewport !== null) {
				const camera = viewport.getCamera();
				if (camera !== null && camera.getEntity() === this.getEntity()) {
					const pickedPosition = Vector3.pool.get();
					// Get the picked position in normal space.
					viewport.getNormalSpacePositionFromPixelSpacePosition(pickedPosition, input.getCursorPosition());

					// Get the picked position in camera space.
					camera.getCameraSpacePositionFromNormalSpacePosition(pickedPosition, pickedPosition);

					// Turn it into a ray of length 1, going from the camera.
					pickedPosition.normalize(pickedPosition);

					// If there is a spheroid, we want to intersect with that. If not, we'll just use a sphere.
					const interval = Interval.pool.get();
					const spheroid = /** @type {SpheroidComponent} */(this.getEntity().getParent().getComponentByType('spheroid'));
					if (spheroid !== null) {
						// We scale up the ray and entity position and rotate them into the picked entity's orientation frame.
						const ratio = spheroid.getEquatorialRadius() / spheroid.getPolarRadius();
						pickedPosition.rotateInverse(this._pickedEntity.getOrientation(), pickedPosition);
						pickedPosition.z *= ratio;
						const entityPosition = Vector3.pool.get();
						entityPosition.copy(this._pickedEntity.getCameraSpacePosition(camera));
						entityPosition.rotateInverse(this._pickedEntity.getOrientation(), entityPosition);
						entityPosition.z *= ratio;
						Geometry.getLineSphereIntersectionWithLineStartAtOrigin(interval, pickedPosition, entityPosition, spheroid.getEquatorialRadius());
						pickedPosition.z /= ratio;
						pickedPosition.rotate(this._pickedEntity.getOrientation(), pickedPosition);
						Vector3.pool.release(entityPosition);
					}
					else {
						const entityPosition = this._pickedEntity.getCameraSpacePosition(camera);
						Geometry.getLineSphereIntersectionWithLineStartAtOrigin(interval, pickedPosition, entityPosition, this._pickedEntity.getOcclusionRadius());
					}
					if (!Number.isNaN(interval.min)) {
						// Convert the picked position from camera space to entity space.
						this._pickedPosition.thaw();
						this._pickedPosition.mult(pickedPosition, interval.min);
						camera.getEntity().getPositionRelativeToEntity(this._pickedPosition, this._pickedPosition, this._pickedEntity);
						this._pickedPosition.freeze();

						// Call the callback.
						this.getEntity().getScene().getEngine().addCallback(this._callback.bind(undefined, this._pickedPosition), false);
					}
					Interval.pool.release(interval);
					Vector3.pool.release(pickedPosition);
				}
			}
		}
	}
}
