import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * This hook can be used to determine whether we've navigated to the same page
 * by utilizing the pathname, search and key properties of the location object.
 * The key will always be different when navigating to the same page.
 * @returns {boolean}
 */
function useSamePageRefreshCount() {
	const location = useLocation();
	const prevLocationRef = useRef();
	const [samePageRefreshCount, setSamePageRefreshCount] = useState(0);

	useEffect(() => {
		const { pathname: prevPathname, search: prevSearch, key: prevKey } = prevLocationRef.current || {};
		const sameRoute = prevPathname === location.pathname && prevSearch === location.search;
		const diffKey = prevKey !== location.key;

		// Check if we're navigating to the page we're already on.
		if (sameRoute && diffKey) {
			setSamePageRefreshCount(samePageRefreshCount + 1);
		} else {
			setSamePageRefreshCount(0);
		}

		// Update the ref to the current location for the next render.
		prevLocationRef.current = location;
	}, [location]);


	return samePageRefreshCount;
}

export default useSamePageRefreshCount;
