/** @module pioneer */
import {
	BaseController,
	Entity,
	Interval,
	MathUtils,
	OrbitalElements,
	Sort,
	Vector3
} from '../../internal';

/**
 * A controller animates the position and orientation of an entity via orbital elements.
 */
export class OrbitalElementsController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The position keyframes.
		 * @type {OrbitalElementsKeyFrame[]}
		 * @private
		 */
		this._orbitalElementsKeyFrames = [];

		// Set the coverage to nothing, since there are no orbital elements.
		this.setCoverage(new Interval(Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY));

		// Modifies the position.
		this.addModifiedState('position');
		this.addModifiedState('velocity');
	}

	/**
	 * Gets the number of orbital elements.
	 * @returns {number}
	 */
	getNumOrbitalElements() {
		return this._orbitalElementsKeyFrames.length;
	}

	/**
	 * Gets an orbital element at the index.
	 * @param {number} index
	 * @returns {OrbitalElementsKeyFrame}
	 */
	getOrbitalElements(index) {
		return this._orbitalElementsKeyFrames[index];
	}

	/**
	 * Adds an orbital element.
	 * @param {number} time
	 * @param {OrbitalElements} orbitalElements
	 */
	addOrbitalElements(time, orbitalElements) {
		Sort.add({ time: time, oe: orbitalElements }, this._orbitalElementsKeyFrames, isLessAdd, isEqualAdd);
		this._updateCoverage();
	}

	/**
	 * Removes an orbital element at the index.
	 * @param {number} index
	 */
	removeOrbitalElements(index) {
		if (index < 0 || this._orbitalElementsKeyFrames.length <= index) {
			throw new Error(`Invalid index for ${this}.removeOrbitalElements`);
		}
		this._orbitalElementsKeyFrames.splice(index, 1);
		this._updateCoverage();
	}

	/**
	 * Sets the position to the keyframed position at the given time.
	 * @param {Vector3} position
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updatePositionAtTime(position, time) {
		this._getPositionAtTime(position, time);
	}

	/**
	 * Sets the velocity to the keyframed velocity at the given time.
	 * @param {Vector3} velocity
	 * @param {number} time
	 * @override
	 * @internal
	 */
	__updateVelocityAtTime(velocity, time) {
		this._getVelocityAtTime(velocity, time);
	}

	/**
	 * Updates the position and orientation from the keyframes.
	 * @override
	 * @internal
	 */
	__update() {
		const engine = this.getEntity().getScene().getEngine();
		const time = engine.getTime();
		if (this._getPositionAtTime(_tempPosition, time)) {
			this.getEntity().setPosition(_tempPosition);
		}
		if (this._getVelocityAtTime(_tempVelocity, time)) {
			this.getEntity().setVelocity(_tempVelocity);
		}
	}

	/**
	 * Sets outPosition to the position at the given time. Returns true if they were set.
	 * @param {Vector3} outPosition
	 * @param {number} time
	 * @returns {boolean}
	 * @private
	 */
	_getPositionAtTime(outPosition, time) {
		const index = Sort.getIndex(time, this._orbitalElementsKeyFrames, isLess);
		const keyFrame0 = this._orbitalElementsKeyFrames[Math.max(index - 1, 0)];
		const keyFrame1 = this._orbitalElementsKeyFrames[Math.min(index, this._orbitalElementsKeyFrames.length - 1)];
		const position0 = Vector3.pool.get();
		const position1 = Vector3.pool.get();
		keyFrame0.oe.project(position0, _tempVelocity, time);
		keyFrame1.oe.project(position1, _tempVelocity, time);
		const u = MathUtils.clamp01(keyFrame1.time !== keyFrame0.time ? ((time - keyFrame0.time) / (keyFrame1.time - keyFrame0.time)) : 0);
		outPosition.slerp(position0, position1, u);
		Vector3.pool.release(position0);
		Vector3.pool.release(position1);
		return true;
	}

	/**
	 * Sets outVelocity to the velocity at the given time. Returns true if they were set.
	 * @param {Vector3} outVelocity
	 * @param {number} time
	 * @returns {boolean}
	 * @private
	 */
	_getVelocityAtTime(outVelocity, time) {
		const index = Sort.getIndex(time, this._orbitalElementsKeyFrames, isLess);
		const keyFrame0 = this._orbitalElementsKeyFrames[Math.max(index - 1, 0)];
		const keyFrame1 = this._orbitalElementsKeyFrames[Math.min(index, this._orbitalElementsKeyFrames.length - 1)];
		const velocity0 = Vector3.pool.get();
		const velocity1 = Vector3.pool.get();
		keyFrame0.oe.project(_tempPosition, velocity0, time);
		keyFrame1.oe.project(_tempPosition, velocity1, time);
		const u = MathUtils.clamp01(keyFrame1.time !== keyFrame0.time ? ((time - keyFrame0.time) / (keyFrame1.time - keyFrame0.time)) : 0);
		outVelocity.slerp(velocity0, velocity1, u);
		Vector3.pool.release(velocity0);
		Vector3.pool.release(velocity1);
		return true;
	}

	/**
	 * Updates the coverage.
	 * @private
	 */
	_updateCoverage() {
		const coverage = new Interval(Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY);
		if (this._orbitalElementsKeyFrames.length > 1) {
			coverage.min = Math.min(coverage.min, this._orbitalElementsKeyFrames[0].time);
			coverage.max = Math.max(coverage.max, this._orbitalElementsKeyFrames[this._orbitalElementsKeyFrames.length - 1].time);
		}
		this.setCoverage(coverage);
	}
}

/**
 * Orbital elements key frame as a time-orbital elements pair.
 */
export class OrbitalElementsKeyFrame {
	constructor() {
		/**
		 * The time for the key frame.
		 * @type {number}
		 */
		this.time = 0;

		/**
		 * The orbital elements.
		 * @type {OrbitalElements}
		 */
		this.oe = new OrbitalElements();
	}
};

/**
 * @callback CompareAdd
 * @param {OrbitalElementsKeyFrame} a
 * @param {OrbitalElementsKeyFrame} b
 * @returns {boolean}
 */

/**
 * @callback Compare
 * @param {OrbitalElementsKeyFrame} a
 * @param {number} b
 * @returns {boolean}
 */

/**
 * A helper function for sorting.
 * @type {CompareAdd}
 */
const isLessAdd = (a, b) => (a.time < b.time);

/**
 * A helper function for sorting.
 * @type {Compare}
 */
const isLess = (a, time) => (a.time < time);

/**
 * A helper function for sorting.
 * @type {CompareAdd}
 */
const isEqualAdd = (a, b) => (a.time === b.time);

/**
 * Helper vector for calculations.
 * @type {Vector3}
 */
const _tempPosition = new Vector3();

/**
 * Helper vector for calculations.
 * @type {Vector3}
 */
const _tempVelocity = new Vector3();
