/** @module pioneer-scripts */
import { Entity } from '../entity';

Entity.register({
	moon: {
		groups: ['earth', 'moons'],
		radius: 1737.4,
		label: 'Moon',
		parents: [
			[Number.NEGATIVE_INFINITY, 'earth']
		],
		trail: {
			length: undefined,
			color: [0.0, 0.6, 0.8, 0.7]
		},
		spheroid: {
			equatorialRadius: 1737.4,
			polarRadius: 1737.4,
			planetographic: true
		},
		spheroidLOD: {
			features: ['normalMap', 'shadowEntities'],
			textures: {
				color: {
					url: 'moon/color_$SIZE_$FACE.png',
					sizes: [16, 512, 4096]
				},
				normal: {
					url: 'moon/normal_$SIZE_$FACE.png',
					sizes: [16, 512, 2048]
				}
			},
			shadowEntities: ['earth']
		},
		controllers: [{
			type: 'dynamo',
			url: 'moon/earth/orb'
		}, {
			type: 'dynamo',
			url: 'moon/ori'
		}]
	}
});
