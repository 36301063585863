/* eslint-disable camelcase */
import { useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useSnapshot } from 'valtio';
import { stellarStore } from '../managers/globalState';

import ENTITY_INFO from '../data/entity_info.json';
import globalRefs from '../managers/globalRefs';
import { hideLoading, showLoading } from '../components/loading';

const StellarDetail = () => {
	const { stellarParam } = useParams();
	const isLoading = useRef(null);
	console.log("StellarDetail: loading", stellarParam)
	const currentStellar = ENTITY_INFO[stellarParam]
	// useEffect for when event changes.
	useEffect(() => {
		// Set variables.
		const { pioneer } = globalRefs;
		const alreadyLoading = isLoading.current === stellarParam;

		if (!currentStellar || alreadyLoading) {
			return;
		}

		// Set isLoading.
		isLoading.current = stellarParam;

		// Show loading.
		const loadType = 'stellar';
		showLoading(loadType);

		console.log("StellarDetail", currentStellar)
		// Set current event in global state.
		stellarStore.setGlobalState({ currentStellar });

		// // Load the video if it is animated or static if not.

		// 	// NOTE: Currently assuming global static events are not possible.
		// 	.then(() => {
		// 	console.log("loaded", stellarParam)
		// 	// Check if event is still loading.
		// 	if (isLoading.current) {
		// 		// Hide loading.
		// 		hideLoading(loadType);
		// 	}

		// 	// Set isLoading to false.
		// 	isLoading.current = false;
		// }).catch(e => e);

		// // On currentVideo change / unmount disable the earth patch.
		// return () => {
		// 	if (type === 'geoLocated') {
		// 		patchComponent?.setEnabled?.(false);
		// 	}
		// };

		return () => {

		};
	}, [currentStellar]);

	// useEffect to set current event to null and disable patch when leaving event detail view.
	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			const { pioneer, getManager } = globalRefs;
			// Set isLoading to null and hide loading.
			isLoading.current = null;
			hideLoading('stellar');

			// Disable the patch.
			// patchComponent.setEnabled(false);

			// Reset global states.
			stellarStore.setGlobalState({ currentStellar: null });

		};
	}, []);


	return null;
};

export default StellarDetail;
