/* eslint-disable react/jsx-closing-bracket-location */
import React from 'react';

import DatasetTools from './dataset_tools';
import TimeRateControls from './time_rate_controls';
import VitalSigns from './vital_signs';
import Controls from './controls_bar';
import DetailPanel from './detail_panel';
import SCZoomButton from './sc_zoom_button';
import DropUp from './drop_up';
import DateRange from './date_range';
import ar from '../languages/ar';
import { spacecraftStore, videoStore, datasetStore, appStore, eventsStore, compareStore ,stellarStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';

import '../../www/assets/css/footer.css';
import { useSnapshot } from 'valtio';
import { getSpacecraftInstrumentData } from '../helpers/tools';

const Footer = () => {
	const { getManager } = globalRefs;
	const { isMobile } = useSnapshot(appStore.state);
	const { datasetHasAnimation } = useSnapshot(datasetStore.state);
	const { eventsVisibleOnGlobe, currentEvent } = useSnapshot(eventsStore.state);
	const { spacecraftId, isZoomedOut, isSatellitesNow } = useSnapshot(spacecraftStore.state);
	const { compareObjName } = useSnapshot(compareStore.state);
	const { currentVideo } = useSnapshot(videoStore.state);

	const { parentOfInstrument: isInstrument } = getSpacecraftInstrumentData(spacecraftId) || {};

	const showCompareDropdown = spacecraftId !== null && !isZoomedOut && !isInstrument;
	const { compareList: dropUpList } = getManager('comparison');
	const showDatasetTools = !currentVideo && !eventsVisibleOnGlobe && !isSatellitesNow && !currentEvent && !spacecraftId;
	const {currentStellar}=useSnapshot(stellarStore.state);
	// TODO: Split VSFooter into Vital Sign manager and Footer UI
	return (
		<div id='bottom-panel'>
			<div className='container-fluid px-0'>
				<div id='above-flexbox' className='container-fluid px-0 above-footer'>
					<DetailPanel />

					{
						!isMobile && spacecraftId
						&& (
							<div className='spacecraft-options'>
								<SCZoomButton isMobile={isMobile} />
								{
									showCompareDropdown
									&& (
										<div className='compare-dropdown-container'>
											<DropUp itemList={dropUpList} activeItem={compareObjName ?? ar.none} onItemClick={getManager('comparison').setCompareEntity} customPlaceholder='مقارنة الحجم' customResetIndex={2} />
										</div>
									)
								}
							</div>
						)

					}

					{ !isMobile && <TimeRateControls /> }

					<div className='dataset-container'>
						{ showDatasetTools && isMobile && <DatasetTools />}
						{ datasetHasAnimation && isMobile && <DateRange /> }
					</div>

				</div>
				<div className='flexbox-container d-none d-lg-flex'>
				{currentStellar ? null : <VitalSigns />}
					
					{!isMobile && !currentStellar   && <Controls />}
				</div>
			</div>
		</div>
	);
};

export default Footer;
