import React, { useEffect } from 'react';
import { useSnapshot } from 'valtio';

import '../../www/assets/css/data_readout.css';

import { datareadStore, videoStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';
import { setColorRectStyle } from './color_bar';


/**
 * Sets the position and value in the global dataread store
 * @param {string|null} readValue
 * @param {string|null} readLatLonValue
 * @param {Vector2} position
 */
const setReadoutData = (readValue, readLatLonValue, position,dataUnit) => {
	const isValid = readValue !== null && readLatLonValue !== null && position?.x && position.y && dataUnit !== null;
	
	let left = -200;
	let top = -200;
	let value = '';
	let valueLatLon = ''
	let dataUnitValue = null;

	if (isValid) {
		left = position.x + 10;
		top = position.y - 10;
		value = readValue;
		valueLatLon = readLatLonValue;
		dataUnitValue = dataUnit;
	}

	// Set global store.
	datareadStore.setGlobalState({
		readoutData: {
			position: { left, top },
			value,
			valueLatLon,
			dataUnitValue
		}
	});
};

// Touchmove event handler.
const onTouchMove = () => {
	const { pioneer } = globalRefs;
	const { x, y } = pioneer.getInput()?.getDraggedOffset() || {};
	const dragOffset = Math.max(Math.abs(x), Math.abs(y));
	if (dragOffset > 5) {
		// Clear data readout and colorbar cursor on drag.
		setReadoutData(null);
		setColorRectStyle(null);
	}
};

// Click event handler.
const onClick = event => {
	// Clear data readout and colorbar cursor on drag.
	setReadoutData(null);
	setColorRectStyle(null);
};


const DataReadout = () => {
	const { readoutData } = useSnapshot(datareadStore.state);
	const { position, value, valueLatLon,dataUnitValue } = readoutData || {};
	const { currentVideo } = useSnapshot(videoStore.state);
	const { type } = currentVideo || {};

	const style = {
		position: 'absolute',
		...position,

	};

	// Use effect to add/remove event listeners.
	useEffect(() => {
		// Add event listeners.
		document.addEventListener('click', onClick);
		// Touch (no need to check if touch is supported as state may change while this component is mounted)
		document.addEventListener('touchmove', onTouchMove);


		return () => {
			// Remove event listeners.
			document.removeEventListener('click', onClick);
			document.removeEventListener('touchmove', onTouchMove, { passive: false });
		};
	}, []);
	// add latlon positions -zahrah
	const styleLatLon = {
		position: 'absolute',
		direction: 'ltr',
		left: position && position.left ? position.left : null,
		top: position && position.top ? position.top + 30 : null
	}
	// console.log('position', position)
	return (
		<div id='data-canvas-container' className='data-canvas-container'>
			{ type !== 'global' && <div id='data-cursor-readout' className='data-cursor' style={style}>{'\u202D' + value + '\u202C'} {dataUnitValue}</div> }
			{ type !== 'global' && <div id='data-cursor-readout' className='data-cursor' style={styleLatLon}>{valueLatLon}</div> }
			<canvas id='data-canvas' className='hidden' width='1440' height='720' />
		</div>
		

	);
};

export default DataReadout;
export { setReadoutData };
