/** @module pioneer */
import {
	BaseController,
	Entity,
	LabelComponent,
	Vector2,
	Vector3
} from '../../internal';

/** A selection controller. */
export class SelectController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * @type {(entity: Entity) => any}
		 * @private
		 */
		this._callback = null;
	}

	/**
	 * Returns the callback to be called when the user selects an entity.
	 * @returns {(entity: Entity) => any}
	 */
	getCallback() {
		return this._callback;
	}

	/**
	 * Sets the callback to be called when the user selects an entity. If the user selects but does not select an entity, the param passed is null.
	 * @param {(entity: Entity) => any} callback
	 */
	setCallback(callback) {
		this._callback = callback;
	}

	/**
	 * Takes input and calls the callback if there is any selection.
	 * @override
	 * @internal
	 */
	__update() {
		const input = this.getEntity().getScene().getEngine().getInput();

		if (input.isSelected() && this._callback !== null) {
			const viewport = input.getActiveViewport();
			if (viewport !== null) {
				const camera = viewport.getCamera();
				if (camera !== null && camera.getEntity() === this.getEntity()) {
					// Get the selected position in normal space.
					const entityPosition = Vector3.pool.get();
					viewport.getNormalSpacePositionFromPixelSpacePosition(entityPosition, input.getSelectedPosition());

					// Search every entity for label that contains the normal-space position. Choose the nearest one.
					let intersectedEntity = null;
					let intersectedEntityDistance = 0;
					const entityPosition2D = Vector2.pool.get();
					entityPosition2D.set(entityPosition.x, entityPosition.y);
					const numEntities = this.getEntity().getScene().getNumEntities();
					for (let entityI = 0; entityI < numEntities; entityI++) {
						const entity = this.getEntity().getScene().getEntity(entityI);
						if (entity.isEnabled()) {
							const label = /** @type {LabelComponent} */(entity.getComponentByType('label'));
							if (label !== null && label.getLoadState() === 'loaded') {
								const labelBounds = label.getNormalSpaceBounds(camera);
								if (labelBounds !== undefined && labelBounds.contains(entityPosition2D)) {
									const entityDistance = entity.getCameraSpacePosition(camera).magnitude();
									if (intersectedEntity === null || intersectedEntityDistance > entityDistance) {
										intersectedEntity = entity;
										intersectedEntityDistance = entityDistance;
									}
								}
							}
						}
					}
					Vector2.pool.release(entityPosition2D);

					// If it didn't find a label selection, check the entities themselves.
					if (intersectedEntity === null) {
						camera.getCameraSpacePositionFromNormalSpacePosition(entityPosition, entityPosition);
						entityPosition.mult(entityPosition, 1 / entityPosition.magnitude());

						// Get a ray in camera coordinates to search for intersecting entities.
						intersectedEntity = camera.getNearestIntersectingEntity(entityPosition);
					}
					Vector3.pool.release(entityPosition);

					// Call the callback whether something was selected or not.
					this.getEntity().getScene().getEngine().addCallback(this._callback.bind(null, intersectedEntity), false);
				}
			}
		}
	}
}
