/** @module pioneer */

/**
 * A class of math routines to augment the built-in Math class.
 * @hideconstructor
 */
export class MathUtils {
	/**
	 * Returns PI.
	 * @returns {number}
	 */
	static get pi() {
		return 3.141592653589793;
	}

	/**
	 * Returns PI * 2.
	 * @returns {number}
	 */
	static get twoPi() {
		return 6.283185307179586;
	}

	/**
	 * Returns PI / 2.
	 * @returns {number}
	 */
	static get halfPi() {
		return 1.5707963267948966;
	}

	/**
	 * Returns the nearest angle between a0 and a1.
	 * @param {number} a0
	 * @param {number} a1
	 * @returns {number}
	 */
	static angle(a0, a1) {
		a0 = this.wrap(a0, 0, 2 * Math.PI);
		a1 = this.wrap(a1, 0, 2 * Math.PI);
		if (a1 - a0 > Math.PI) {
			return a0 - a1 + 2 * Math.PI;
		}
		if (a0 - a1 > Math.PI) {
			return a1 - a0 + 2 * Math.PI;
		}
		return Math.abs(a1 - a0);
	}

	/**
	 * Clamps a between a0 and a1.
	 * @param {number} a - the value to clamp
	 * @param {number} a0 - the minimum
	 * @param {number} a1 - the maximum
	 * @returns {number}
	 */
	static clamp(a, a0, a1) {
		return Math.min(Math.max(a0, a), a1);
	}

	/**
	 * Clamps a between 0 and 1.
	 * @param {number} a - the value to clamp
	 * @returns {number}
	 */
	static clamp01(a) {
		return Math.min(Math.max(0, a), 1);
	}

	/**
	 * Returns the point a between a0 and a1 as if they were a cycle.
	 * @param {number} a - the value to wrap
	 * @param {number} a0 - the start of the cycle
	 * @param {number} a1 - the end of the cycle
	 * @returns {number}
	 */
	static wrap(a, a0, a1) {
		let phase = (a - a0) % (a1 - a0) + a0;
		if (phase < a0) {
			phase += a1 - a0;
		}
		return phase;
	}

	/**
	 * Linearly interpolates between a0 and a1.
	 * @param {number} a0 - the value when u = 0
	 * @param {number} a1 - the value when u = 1
	 * @param {number} u - the lerp factor
	 * @returns {number}
	 */
	static lerp(a0, a1, u) {
		return (1.0 - u) * a0 + u * a1;
	}

	/**
	 * Linearly interpolates between two angles in radians a0 and a1.
	 * @param {number} a0 - the value when u = 0
	 * @param {number} a1 - the value when u = 1
	 * @param {number} u - the lerp factor
	 * @returns {number}
	 */
	static lerpAngle(a0, a1, u) {
		a0 = this.wrap(a0, -Math.PI, +Math.PI);
		a1 = this.wrap(a1, -Math.PI, +Math.PI);
		if (a1 - a0 > Math.PI) {
			a0 += 2 * Math.PI;
		}
		if (a0 - a1 > Math.PI) {
			a1 += 2 * Math.PI;
		}
		return this.wrap(this.lerp(a0, a1, u), -Math.PI, +Math.PI);
	}

	/**
	 * Converts radians to degrees.
	 * @param {number} a - the value in radians
	 * @returns {number}
	 */
	static radToDeg(a) {
		return a * 57.29577951308232;
	}

	/**
	 * Converts degrees to radians.
	 * @param {number} a - the value in degrees
	 * @returns {number}
	 */
	static degToRad(a) {
		return a * 0.01745329251994329;
	}

	/**
	 * Returns the next higher power of two, or a if it is a power of 2.
	 * @param {number} a - the value to use
	 * @returns {number}
	 */
	static ceilPow2(a) {
		return Math.pow(2, Math.ceil(Math.log(a) / Math.log(2)));
	}
}
