/** @module pioneer-scripts */
import * as Pioneer from 'pioneer';

/**
 * @callback TransitionFunction
 * @param {Pioneer.Entity} entity
 * @param {Pioneer.Vector3} initialPosition
 * @param {Pioneer.Vector3} finalPosition
 * @param {Pioneer.Quaternion} initialOrientation
 * @param {Pioneer.Quaternion} finalOrientation
 * @param {number} u
 */

/**
 * Helpful functions for viewports and cameras.
 * @hideconstructor
 */
export class Cameras {
	/**
	 * Creates a full size (100% width and height) viewport and a camera.
	 * @param {Pioneer.Scene} scene - the scene in which to create the camera
	 * @param {string} [viewportName = 'main'] - the name of the viewport
	 * @param {string} [cameraEntityName = 'camera'] - the name of the camera entity
	 */
	static createFullSizeViewportAndCamera(scene, viewportName = 'main', cameraEntityName = 'camera') {
		const viewport = scene.getEngine().addViewport(viewportName);
		viewport.getDiv().style.width = '100%';
		viewport.getDiv().style.height = '100%';
		const cameraEntity = scene.addEntity(cameraEntityName);
		const camera = cameraEntity.addComponentByClass(Pioneer.CameraComponent);
		viewport.setCamera(camera);
	}

	/** Makes the camera align to the focus entity.
	 * @param {Pioneer.Entity} cameraEntity - the camera entity
	 * @param {Pioneer.Entity} focusEntity - the entity around which the camera entity will orbit
	 * @param {Object} options - the options used to setup the camera
	 * @param {boolean} [options.up = true] - If true, the camera's up will be aligned with the entity.
	 * @param {boolean} [options.orbiter = false] - If true, the camera's up will be the away from the focus entity's parent, and if false, it will be the z-axis (usually the north pole).
	 */
	static focusOnEntity(cameraEntity, focusEntity, { up = true, orbiter = false }) {
		const alignController = cameraEntity.addControllerByClass(Pioneer.AlignController);
		alignController.setPrimaryAlignType('point');
		alignController.setPrimaryAxis(Pioneer.Vector3.YAxis);
		alignController.setPrimaryTargetEntity(focusEntity.getName());
		if (up) {
			if (orbiter) {
				alignController.setSecondaryAlignType('position');
				alignController.setSecondaryAxis(Pioneer.Vector3.ZAxis);
				alignController.setSecondaryTargetEntity(focusEntity.getName());
			}
			else {
				alignController.setSecondaryAlignType('align');
				alignController.setSecondaryAxis(Pioneer.Vector3.ZAxis);
				alignController.setSecondaryTargetEntity(focusEntity.getName());
				alignController.setSecondaryTargetAxis(Pioneer.Vector3.ZAxis);
			}
		}
	}

	/**
	 * Makes the camera look at an entity.
	 * @param {Pioneer.Entity} cameraEntity - the camera entity
	 * @param {Pioneer.Entity} focusEntity - the entity that the camera entity will look at
	 * @param {Object} options - the options used to setup the camera
	 * @param {number} [options.duration = 0.5] - seconds to do the transition
	 * @param {Pioneer.Vector3} [options.finalUp] - the final up vector, defaults to undefined, which means no up alignment
	 * @param {Pioneer.Entity} [options.finalUpRelativeEntity] - the final up vector is relative to this entity, defaults to the focus entity
	 * @param {boolean} [options.finalUpPosition] - true if the final up vector is the position of the finalUpRelativeEntity relative to its parent
	 */
	static async lookAtEntity(cameraEntity, focusEntity, { duration = 0.5, finalUp = undefined, finalUpRelativeEntity = undefined, finalUpPosition = false }) {
		// If the camera isn't yet anywhere, there's nowhere to look.
		if (cameraEntity.getPosition().isNaN()) {
			return;
		}

		// Get the final forward vector.
		const forward = new Pioneer.Vector3();
		focusEntity.getPositionRelativeToEntity(forward, Pioneer.Vector3.Zero, cameraEntity);
		forward.normalize(forward);

		// Remove any other orientation modifying controllers.
		for (let i = 0; i < cameraEntity.getNumControllers(); i++) {
			if (cameraEntity.getController(i).hasModifiedState('orientation')) {
				cameraEntity.removeController(i);
				i--;
			}
		}

		// Add and setup the align controller.
		const alignController = cameraEntity.addControllerByClass(Pioneer.AlignController);
		alignController.setPrimaryAlignType('point');
		alignController.setPrimaryAxis(Pioneer.Vector3.YAxis);
		alignController.setPrimaryTargetEntity(focusEntity.getName());
		if (finalUp !== undefined) {
			if (finalUpRelativeEntity === undefined) {
				finalUpRelativeEntity = focusEntity;
			}
			if (finalUpPosition) {
				alignController.setSecondaryAlignType('position');
				alignController.setSecondaryAxis(Pioneer.Vector3.ZAxis);
				alignController.setSecondaryTargetEntity(finalUpRelativeEntity.getName());
			}
			else {
				alignController.setSecondaryAlignType('align');
				alignController.setSecondaryAxis(Pioneer.Vector3.ZAxis);
				alignController.setSecondaryTargetEntity(finalUpRelativeEntity.getName());
				alignController.setSecondaryTargetAxis(finalUp);
			}
		}

		// Add and setup the transition controller.
		const transitionController = cameraEntity.addControllerByClass(Pioneer.TransitionController);
		transitionController.setTransitionTime(duration);
		await transitionController.getEndPromise();
	}

	/**
	 * Makes the camera go to an object.
	 * @param {Pioneer.Entity} cameraEntity - the camera entity
	 * @param {Pioneer.Entity} focusEntity - the entity that the camera will orbit around, starting on the sunny side
	 * @param {Object} options - the options used to setup the camera
	 * @param {boolean} [options.up = true] - align the entity to either the north pole of the entity or if it is an orbiter, the position of the orbiter.
	 * @param {boolean} [options.orbiter = false] - if true, the camera's up will be the away from the focus entity's parent.
	 * @param {boolean} [options.fixedToParent = false] - if true, the camera will be fixed to the parent and will not drift if the parent rotates.
	 * @param {number} [options.duration = 0.5] - seconds to do the transition
	 * @param {number} [options.distance] - how far away from the focus entity the camera should be (default is 5 times focusEntity's radius)
	 * @param {boolean} [options.zoom = true] - if true, sets a zoom controller
	 * @param {Pioneer.Vector3} [options.destination] - the location relative to the focus entity to transition to; if undefined it goes to the nearest spot from the camera's current position; this overrides distance
	 * @param {Pioneer.Vector3} [options.destinationUp] - the up direction that the camera will transition to; if undefined it will use the current up of the camera projected into the forward plane
	 * @param {boolean} [options.destinationInFocusFrame = false] - if true, the destination and destinationUp are in the orientation frame of the focus entity
	 * @param {TransitionFunction} [options.transitionFunction] - a manual transition function to use
	 * @returns {Promise<void>}
	 */
	static async goToEntity(cameraEntity, focusEntity, { up = true, orbiter = false, fixedToParent = false, duration = 1.0, distance = undefined, zoom = true, destination = undefined, destinationUp = undefined, destinationInFocusFrame = false, transitionFunction = undefined }) {
		if (!distance) {
			distance = focusEntity.getExtentsRadius() * 5.0;
		}

		// If the camera isn't yet anywhere, set it to a nice location.
		if (cameraEntity.getPosition().isNaN() || cameraEntity.getOrientation().isNaN()) {
			cameraEntity.setParent(focusEntity);
			if (destination === undefined) {
				cameraEntity.setPosition(new Pioneer.Vector3(0, -distance, 0));
			}
			else {
				cameraEntity.setPosition(destination);
			}
			cameraEntity.setOrientation(Pioneer.Quaternion.Identity);
			duration = 0;
		}

		// Get the destination position for the end of the transition if there was none set.
		if (destination === undefined) {
			destination = new Pioneer.Vector3();
			cameraEntity.getPositionRelativeToEntity(destination, Pioneer.Vector3.Zero, focusEntity);
			destination.normalize(destination);
			destination.mult(destination, distance);
			if (destination.isNaN()) {
				destination.set(0, -distance, 0);
			}
		}

		// Set the destination position for the end of the transition.
		cameraEntity.clearControllers();
		const fixedController = cameraEntity.addControllerByClass(Pioneer.FixedController);
		fixedController.setPosition(destination);

		// Set the destination orientation for the end of the transition.
		if (destinationUp !== undefined) {
			const destinationForward = new Pioneer.Vector3();
			const destUp = new Pioneer.Vector3();
			destinationForward.neg(destination);
			destinationForward.normalize(destinationForward);
			destUp.setNormalTo(destinationForward, destinationUp);
			const orientation = new Pioneer.Quaternion();
			orientation.setFromAxes(undefined, destinationForward, destUp);
			fixedController.setOrientation(orientation);
		}

		if (destinationInFocusFrame) {
			const rotateByEntityOrientationController = cameraEntity.addControllerByClass(Pioneer.RotateByEntityOrientationController);
			if (destinationUp === undefined) {
				rotateByEntityOrientationController.setRotatingOrientation(false);
			}
		}

		this.focusOnEntity(cameraEntity, focusEntity, { up, orbiter });

		// Setup the transition.
		const transitionController = cameraEntity.addControllerByClass(Pioneer.TransitionController);
		transitionController.setTransitionTime(duration);
		transitionController.setParent(focusEntity.getName());
		if (transitionFunction) {
			transitionController.setTransitionFunction(transitionFunction);
		}
		await transitionController.getEndPromise();

		cameraEntity.clearControllers();
		const orbitController = cameraEntity.addControllerByClass(Pioneer.OrbitController);
		if (up) {
			if (orbiter) {
				orbitController.setYawAxisType('position');
			}
			else {
				orbitController.setYawAxisType('z-axis');
			}
		}
		else {
			cameraEntity.addController('roll');
		}

		if (fixedToParent) {
			cameraEntity.addController('fixedToParent');
		}

		if (zoom) {
			cameraEntity.addController('zoom');
		}

		this.focusOnEntity(cameraEntity, focusEntity, { up, orbiter });
	}

	/**
	 * Adds pick controller that calls the callback with the XYZ (ECEF) and the LatLonAlt of the location picked on the entity.
	 * @param {Pioneer.Entity} cameraEntity
	 * @param {Pioneer.Entity} pickedEntity
	 * @param {(xyz: Pioneer.Vector3, lla: Pioneer.LatLonAlt) => void} callback
	 */
	static pickOnEntity(cameraEntity, pickedEntity, callback) {
		const pickController = cameraEntity.addControllerByClass(Pioneer.PickController);
		pickController.setPickedEntity(pickedEntity);
		pickController.setCallback((position) => {
			const spheroidComponent = pickedEntity.getComponentByClass(Pioneer.SpheroidComponent);
			if (spheroidComponent !== null) {
				const positionInSpheroidFrame = Pioneer.Vector3.pool.get();
				positionInSpheroidFrame.rotateInverse(pickedEntity.getOrientation(), position);
				const lla = Pioneer.LatLonAlt.pool.get();
				spheroidComponent.llaFromXYZ(lla, positionInSpheroidFrame);
				callback(positionInSpheroidFrame, lla);
				Pioneer.LatLonAlt.pool.release(lla);
				Pioneer.Vector3.pool.release(positionInSpheroidFrame);
			}
		});
	}

	/**
	 * Gets the distance that the camera entity should be so that all of the entities are in view.
	 * @param {Pioneer.Entity} cameraEntity - the camera entity
	 * @param {Pioneer.Quaternion} cameraOrientation - the orientation that the camera will have, assuming it will be centered on the focus entity
	 * @param {Pioneer.Entity} focusEntity - the entity that the child will be a child of and pointed at
	 * @param {Pioneer.Entity[]} entities - the list of entities to keep in view
	 * @returns {number}
	 */
	static getDistanceToFitEntities(cameraEntity, cameraOrientation, focusEntity, entities) {
		const cameraComponent = cameraEntity.getComponentByClass(Pioneer.CameraComponent);
		if (cameraComponent === null) {
			return NaN;
		}

		let distance = 0;
		const positionOfEntity = Pioneer.Vector3.pool.get();
		const sinHalfHorizontalFov = Math.sin(cameraComponent.getHorizontalFieldOfView() / 2.0);
		const sinHalfVerticalFov = Math.sin(cameraComponent.getVerticalFieldOfView() / 2.0);
		const tanHalfHorizontalFov = Math.tan(cameraComponent.getHorizontalFieldOfView() / 2.0);
		const tanHalfVerticalFov = Math.tan(cameraComponent.getVerticalFieldOfView() / 2.0);

		for (let i = 0; i < entities.length; i++) {
			const entity = entities[i];
			let ringsRadius = 0;
			const ringsComponent = entity.getComponentByClass(Pioneer.RingsComponent);
			if (ringsComponent !== null) {
				ringsRadius = ringsComponent.getOuterRadius();
			}

			// Get the position of the entity in the camera's rotated frame.
			entity.getPositionRelativeToEntity(positionOfEntity, Pioneer.Vector3.Zero, focusEntity);
			positionOfEntity.rotateInverse(cameraOrientation, positionOfEntity);

			// Get the distances for the horizontal and vertical fovs.
			distance = Math.max(distance, Math.abs(positionOfEntity.x) / tanHalfHorizontalFov + Math.max(entity.getExtentsRadius(), ringsRadius) / sinHalfHorizontalFov - positionOfEntity.y);
			distance = Math.max(distance, Math.abs(positionOfEntity.z) / tanHalfVerticalFov + Math.max(entity.getExtentsRadius(), ringsRadius) / sinHalfVerticalFov - positionOfEntity.y);
		}
		Pioneer.Vector3.pool.release(positionOfEntity);
		return distance;
	}
}
