/* eslint-disable camelcase */
import { useEffect } from 'react';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';

import { appStore, eventsStore, spacecraftStore, kioskStore } from '../../managers/globalState';
import globalRefs from '../../managers/globalRefs';

import { getCategoryFromEventParam, getEventFromEventParam, getYearFromOldEventParam } from '../../helpers/processEvents';
import { getDatasetFromVitalsData, getDatasetIdFromParam, getSpacecraftIdFromParam, getSpacecraftParamFromId, isListedSpacecraft } from '../../helpers/tools';

import VITALS_DATA from '../../data/vitals_data';
import { VITAL_SIGNS_PAGE, EVENTS_PAGE, HOME_PAGE, SPACECRAFT_PAGE, EVENT_CATEGORIES, GMAP_PAGE, POI_PAGE } from '../../config/constants';
import VIDEOS_DATA from '../../data/videos_data';


const getPathFromOldRoute = (invalidParams, searchParams) => {
  // Create the updated queries.
  const invalidQueries = Object.fromEntries(searchParams.entries());
  const { animating, start, end, hideUI, trailWidth } = invalidQueries;
  const updatedQueries = {
    ...animating === 't' && { animating: 'true' },
    ...start && { start },
    ...end && { end },
    ...hideUI && { hideUI },
    ...trailWidth && { trailWidth }
  };

  const newQueryStr = createSearchParams(updatedQueries).toString();

  const oldVitalStr = 'vitalsign';
  const oldMissionStr = 'spacecraft';

  // Determine if it's the old vital sign route.
  const oldVitalSign = invalidParams.includes(oldVitalStr);

  // Determine if it's the old mission route.
  const oldMission = invalidParams.includes(oldMissionStr);

  // Check if it's the old event route.
  const { oldEventName, oldEventYear } = getYearFromOldEventParam(invalidParams) || {};

  return new Promise(resolve => {
    // Resolve to home after 5 second timeout.
    const homeTimeout = setTimeout(() => {
      resolve(HOME_PAGE);
    }, 5000);
    // Make sure to cancel timeout if we resolve elsewhere.
    const clearResolve = pathname => {
      clearTimeout(homeTimeout);
      resolve({ pathname, newQueryStr });
    };

    // If old event route, subscribe to the event store and wait for the event data to be loaded.
    // This event chunk is a little complex for my liking.
    if (oldEventYear) {
      // We could already have some event data, ie. page already loaded.
      const { eventYears, events } = eventsStore.stateSnapshot;

      // Returns new category and event name from old event name.
      const getEventPath = (oldEvent, yearEvents) => {
        const { urlParam: newEventName } = yearEvents.find(({ id }) => id === oldEvent) || {};
        const category = newEventName && getCategoryFromEventParam(newEventName);

        return category ? `/${category}/${newEventName}` : '';
      };

      if (eventYears && !eventYears.includes(oldEventYear)) {
        clearResolve(EVENTS_PAGE);
      } else if (events?.[oldEventYear]) {
        // If we find the old event name in the events data, and it's accompanying category redirect to the new event urlParam.
        clearResolve(`${EVENTS_PAGE}/${oldEventYear}${getEventPath(oldEventName, events[oldEventYear])}`);
      } else {
        // If we dont have the right event data, subscribe and wait for it.
        // The data master should be fetching the oldEventYear data ~line350 DataMaster.
        const unsubscribe = eventsStore.subscribeAll(({ events }) => {
          if (events?.[oldEventYear]) {
            // If we find the old event name in the events data, and it's accompanying category redirect to the new event urlParam.
            unsubscribe();
            clearResolve(`${EVENTS_PAGE}/${oldEventYear}${getEventPath(oldEventName, events[oldEventYear])}`);
          }
        });
      }
    } else if (oldVitalSign) {
      // If old vital sign route, check if the vital sign is valid.
      const { videoId, vitalsign, altid = 0, altView = 0 } = invalidQueries;

      const videoIndex = videoId && parseInt(videoId) - 1;
      const { urlParam: videoParam } = VIDEOS_DATA[videoIndex] || {};

      const vitalSignParam = vitalsign?.replaceAll('_', '-');
      const vitalSign = VITALS_DATA.find(({ value }) => value === vitalSignParam);

      if (videoParam) {
        // Check if we're on a video.
        clearResolve(`${VITAL_SIGNS_PAGE}/videos/${videoParam}`);
      } else if (vitalSign) {
        // Check if there's a valid vital sign.
        const { value: vitalSignParam } = vitalSign;
        const { urlParam: datasetParam } = vitalSign?.datasetGroups?.[altid]?.datasets[altView] || {};

        clearResolve(`${VITAL_SIGNS_PAGE}${vitalSignParam ? `/${vitalSignParam}` : ''}${datasetParam ? `/${datasetParam}` : ''}`);
      } else {
        // If no vital sign, redirect to vital signs page.
        clearResolve(VITAL_SIGNS_PAGE);
      }
    } else if (oldMission) {
      // If old mission route, check if the mission is valid.
      const { spacecraft } = invalidQueries;

      const spacecraftParam = isListedSpacecraft(spacecraft) && getSpacecraftParamFromId(spacecraft);

      const pathname = `${SPACECRAFT_PAGE}${spacecraftParam ? `/${spacecraftParam}` : ''}`;
      clearResolve(pathname);
    } else {
      clearResolve(HOME_PAGE);
    }
  });
};

/**
 * ValidationMaster component
 * This component is responsible for validating the dynamic params - vitalSignParam, datasetParam, spacecraftParam, eventParam.
 * If they are not valid, we then need to redirect to the correct page.
 */
const ValidationMaster = () => {
  const { pioneer } = globalRefs;
  const { events, eventYears, currentEvent } = useSnapshot(eventsStore.state);
  const { allSpacecraftIds, currentSpacecraft } = useSnapshot(spacecraftStore.state);
  const { queries } = useSnapshot(appStore.state);

  const navigate = useNavigate();
  // Get params and search params from url.
  const params = useParams();
  const { vitalSignParam: vitalSignId, datasetParam, spacecraftParam, eventYearParam, eventCategoryParam, eventParam, videoParam, gmapParam, poiParam, stellarParam, '*': invalidParam } = params;
  const [searchParams] = useSearchParams();

  // Determine if any of the params require validation.
  const validateParams = Boolean(vitalSignId || datasetParam || spacecraftParam || eventYearParam || eventParam || videoParam, invalidParam);

  const datasetId = getDatasetIdFromParam(datasetParam);
  const spacecraftId = getSpacecraftIdFromParam(spacecraftParam);
  // console.log("validate params", stellarParam)

  // Determine if vitalSignId is valid.
  const noVitalSignToValidate = vitalSignId === undefined;
  const validVitalSignId = noVitalSignToValidate || VITALS_DATA.find(({ value }) => value === vitalSignId);

  // Determine if datasetId is valid.
  const noDatasetIdToValidate = datasetParam === undefined;
  const validDatasetId = noDatasetIdToValidate || (getDatasetFromVitalsData(datasetId)?.dataset !== undefined);

  // Determine if spacecraftId is valid - it needs to be in the donwloaded spacecraft list and available in pioneer.
  const awaitingSpacecraftValidation = spacecraftId && !allSpacecraftIds;
  const entity = pioneer.get('main', spacecraftId);
  const validSpacecraftId = spacecraftId === null
    || awaitingSpacecraftValidation
    || (allSpacecraftIds.includes(spacecraftId) && !entity?.isDestroyed());

  // Determine if videoParam is valid.
  const noVideoParamToValidate = videoParam === undefined;
  const validVideoParam = noVideoParamToValidate || VIDEOS_DATA.find(({ urlParam }) => urlParam === videoParam);

  // Determine if eventYear is valid.
  const noEventYearToValidate = eventYearParam === undefined;
  const awaitingEventYears = eventYears === null;
  const validEventYear = noEventYearToValidate || awaitingEventYears || eventYears.includes(eventYearParam);

  // Determine if eventParam is valid.
  const eventYearData = events?.[eventYearParam]; // Accessing the event year will make sure this component rerenders when the events global state updates.
  const validateEvent = Boolean(eventParam && eventYearData);
  const awaitingEventValidation = eventParam && !eventYearData;
  const validEvent = eventParam === undefined
    || awaitingEventValidation
    || (validateEvent && getEventFromEventParam(eventParam));

  // Determine is eventCategory is valid.
  const noEventCategoryToValidate = eventCategoryParam === undefined;
  const awaitingEventYear = !eventYearData;
  const validEventCategory = noEventCategoryToValidate || awaitingEventYear || EVENT_CATEGORIES.get(eventCategoryParam) !== undefined;

  console

  // const validURL = Boolean(!invalidParam && validVitalSignId && validDatasetId && validSpacecraftId &&
  // validEventYear && validEventCategory && validEvent && validVideoParam);
  const validURL = true
  // If any of the params are invalid, redirect to the correct page.
  useEffect(() => {
    if (!validateParams) {
      return;
    }
    if (!validVitalSignId || !validDatasetId) {
      console.warn('Invalid URL dataset. Returning to Home.');
      navigate(HOME_PAGE, { replace: true });
    }

    if (!validVideoParam) {
      console.warn('Invalid URL videoParam. Returning to Home.');
      navigate(HOME_PAGE, { replace: true });
    }

    if (!validSpacecraftId) {
      console.warn('Invalid URL spacecraftId. Returning to Satellites Now.');
      navigate(SPACECRAFT_PAGE, { replace: true });
    }

    if (!validEventYear) {
      console.warn('Invalid URL event year. Returning to this year\'s events.');
      navigate(EVENTS_PAGE, { replace: true });
    }

    if (!validEventCategory) {
      // We can assume the event year is valid as an invalid year would have been caught in the previous if statement.
      let warning = 'Invalid URL event category. Returning to this year\'s events.';
      let eventRedirect = `${EVENTS_PAGE}/${eventYearParam}`;

      /**
       * The old style of event routing used the format -> /events/<year>/<eventParam>
       * We now use -> /events/<year>/<category>/<eventParam>
       * That means an invalid event category could be a valid eventParam.
       * In this case, we should redirect to the eventParam.
       */
      const oldRoutingEvent = getEventFromEventParam(eventCategoryParam);
      if (oldRoutingEvent) {
        const category = getCategoryFromEventParam(eventCategoryParam);
        eventRedirect = `${EVENTS_PAGE}/${eventYearParam}/${category}/${eventCategoryParam}`;
        warning = 'Invalid URL event category. Redirecting to event.';
      }

      console.warn(warning);
      navigate(eventRedirect, { replace: true });
    }

    if (!validEvent) {
      console.warn('Invalid URL event. Returning to all events.');
      const eventRedirect = validEventYear ? `${EVENTS_PAGE}/${eventYearParam}` : EVENTS_PAGE;
      navigate(eventRedirect, { replace: true });
    }
  }, [
    validateParams,
    navigate,
    validVitalSignId,
    validDatasetId,
    validVideoParam,
    validSpacecraftId,
    validEventYear,
    validEventCategory,
    eventYearParam,
    validEvent,
    invalidParam
  ]);

  // useEffect to validate kiosk timers
  useEffect(() => {
    const { setQuery } = globalRefs;
    const { maxSessionTime: sessionTimeFromURL, maxInactivityTime: inactivityTimeFromURL } = queries;
    // Determine if incoming url param is a valid kiosk session or inactivity time parameter, i.e., not negative, not blank, not a random string
    const isValidKioskTimerParam = param => param !== '' && !isNaN(Number(param)) && Number(param) > -1;
    const getTime = time => (isValidKioskTimerParam(time) ? Number(time) : undefined);

    const SESSION_TIME = getTime(sessionTimeFromURL);
    const INACTIVITY_TIME = getTime(inactivityTimeFromURL);
    const bothTimersInURL = isValidKioskTimerParam(sessionTimeFromURL) && isValidKioskTimerParam(inactivityTimeFromURL);

    const newQueries = { ...queries };
    if (isValidKioskTimerParam(SESSION_TIME)) {
      newQueries.maxSessionTime = SESSION_TIME;
    }
    if (isValidKioskTimerParam(INACTIVITY_TIME)) {
      newQueries.maxInactivityTime = INACTIVITY_TIME;
    }

    if (bothTimersInURL) {
      if (Math.abs(SESSION_TIME - INACTIVITY_TIME) <= 0.05 && SESSION_TIME > 0 && INACTIVITY_TIME > 0) {
        // Add some time to inactivity time if they're too close in value
        newQueries.maxSessionTime = INACTIVITY_TIME === 0 ? 0 : SESSION_TIME;
        newQueries.maxInactivityTime = SESSION_TIME + 0.25;
      }
      if (SESSION_TIME >= INACTIVITY_TIME) {
        // Zero both out if inactivity is 0
        newQueries.maxSessionTime = INACTIVITY_TIME === 0 ? 0 : SESSION_TIME;
        newQueries.maxInactivityTime = INACTIVITY_TIME === 0 ? 0 : SESSION_TIME + 1;
      }
    }

    setQuery({ ...newQueries });

    const { maxSessionTime: updatedSessionTime, maxInactivityTime: updatedInactivityTime } = newQueries;
    isValidKioskTimerParam(updatedSessionTime) && kioskStore.setGlobalState({ maxSessionTime: updatedSessionTime });
    isValidKioskTimerParam(updatedInactivityTime) && kioskStore.setGlobalState({ maxInactivityTime: updatedInactivityTime });
  }, [queries]);

  // useEffect to validate calendar dates and animating.
  useEffect(() => {
    const { getManager, setQuery } = globalRefs;
    const timeManager = getManager('time');
    const { start, end, ...retainedQueries } = queries;
    const animation = queries.animating === 'true';
    let validViewForAnimation = true;
    let isValidStart = true;
    let isValidEnd = true;
    let startAfterEnd = false;

    // If animating, make sure we're not on spacecraft/event
    if (animation) {
      validViewForAnimation = !currentEvent && !currentSpacecraft;
    }

    // If start and end, make sure they're valid and within limits
    if (start || end) {
      const startDate = new Date(start);
      const endDate = new Date(end);

      isValidStart = !isNaN(startDate.valueOf()) && timeManager.withinLimits(startDate);
      isValidEnd = !isNaN(endDate.valueOf()) && timeManager.withinLimits(endDate);

      startAfterEnd = startDate > endDate;
    }

    // Reset to latest (ie. remove queries) if invalid.
    if ((!isValidStart && !isValidEnd) || !validViewForAnimation) {
      console.warn('Invalid URL date. Resetting to the latest.');
      getManager('dataset').resetToLatest();
    } else if (startAfterEnd || !isValidEnd) {
      // Set end date = start date if invalid end date
      setQuery({ ...retainedQueries, start, end: start });
    } else if (startAfterEnd || !isValidStart) {
      // Set start date = end date if invalid start date
      setQuery({ ...retainedQueries, start: end, end });
    }
  }, [queries, currentSpacecraft, currentEvent]);

  // useEffect to redirect if old routing pattern is used.
  useEffect(() => {
    if (invalidParam) {
      console.warn('Invalid URL detected. Redirecting to correct URL.');

      getPathFromOldRoute(invalidParam, searchParams)
        .then(({ pathname, newQueryStr: search }) => {
          // Redirect to new pathname.
          navigate({ pathname, search }, { replace: true });
        })
        .catch(error => {
          // Redirect to home on error.
          console.error('Error redirecting to new pathname: ', error);
          navigate(HOME_PAGE, { replace: true });
        });
    }
  }, [invalidParam, searchParams, navigate]);

  // useEffect to set global validURL state.
  useEffect(() => {
    // eslint-disable-next-line object-shorthand
    appStore.setGlobalState({ validURL: validURL });
  }, [validURL]);

  return null;
};

export default ValidationMaster;
