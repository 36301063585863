/** @module pioneer */
import {
	BaseController,
	Entity,
	EntityRef,
	ModelComponent,
	Quaternion,
	THREE,
	Vector3
} from '../../internal';

/**
 * A controller that aligns an entity with another entity on arbitrary axes.
 */
export class AlignController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The align type for primary alignment. It can be 'none', 'align', 'velocity', 'point', or 'position'.
		 * @type {string}
		 * @private
		 */
		this._primaryAlignType = 'none';

		/**
		 * The axis of this entity that will be used for primary alignment.
		 * @type {Vector3}
		 * @private
		 */
		this._primaryAxis = new Vector3(1, 0, 0);
		this._primaryAxis.freeze();

		/**
		 * The target entity for primary alignment.
		 * @type {EntityRef}
		 * @private
		 */
		this._primaryTargetEntity = new EntityRef(this.getEntity().getScene());
		this._primaryTargetEntity.setRefChangedCallback((oldRef, newRef) => {
			this._removeDependentStates(oldRef, this._primaryAlignType);
			this._addDependentStates(newRef, this._primaryAlignType);
		});

		/**
		 * The axis of the target entity with which this entity will align or point for primary alignment.
		 * @type {Vector3}
		 * @private
		 */
		this._primaryTargetAxis = new Vector3(1, 0, 0);
		this._primaryTargetAxis.freeze();

		/**
		 * The align type for secondary alignment. It can be 'none', 'align', 'velocity', 'point', or 'position'.
		 * @type {string}
		 * @private
		 */
		this._secondaryAlignType = 'none';

		/**
		 * The axis of this entity that will align with the target entity's axis for secondary alignment.
		 * @type {Vector3}
		 * @private
		 */
		this._secondaryAxis = new Vector3(0, 1, 0);
		this._secondaryAxis.freeze();

		/**
		 * The target entity for secondary alignment.
		 * @type {EntityRef}
		 * @private
		 */
		this._secondaryTargetEntity = new EntityRef(this.getEntity().getScene());
		this._secondaryTargetEntity.setRefChangedCallback((oldRef, newRef) => {
			this._removeDependentStates(oldRef, this._secondaryAlignType);
			this._addDependentStates(newRef, this._secondaryAlignType);
		});

		/**
		 * The axis of the target entity with which this entity will align for secondary alignment.
		 * @type {Vector3}
		 * @private
		 */
		this._secondaryTargetAxis = new Vector3(0, 1, 0);
		this._secondaryTargetAxis.freeze();

		/**
		 * The joint for the model to align. If empty, the entity itself is used.
		 * @type {string}
		 * @private
		 */
		this._joint = '';

		/**
		 * The joint's ThreeJs object.
		 * @type {THREE.Object3D}
		 * @private
		 */
		this._jointObject = null;

		/**
		 * The model for the joint.
		 * @type {ModelComponent}
		 * @private
		 */
		this._model = null;

		/**
		 * The id of the root of the model. Used to tell if the model's Three.js object has been destroyed and reloaded.
		 * @type {number}
		 * @private
		 */
		this._modelRootId = 0;

		// Let the base controller know that this changes the orientation.
		this.addModifiedState('orientation');
	}

	/**
	 * Gets the align type for the primary alignment.
	 * @returns {string}
	 */
	getPrimaryAlignType() {
		return this._primaryAlignType;
	}

	/**
	 * Sets the align type for the primary alignment. It can be 'none', 'align', 'velocity', or 'point'.
	 * 'none' means no alignment
	 * 'align' aligns the entity with the target entity's axis
	 * 'velocity' aligns the entity with the target entity's velocity
	 * 'point' points the entity at the target entity.
	 * 'position' aligns the entity with the target entity's local position
	 * @param {string} alignType
	 */
	setPrimaryAlignType(alignType) {
		if (this._primaryAlignType === alignType) {
			return;
		}
		this._removeDependentStates(this._primaryTargetEntity.get(), this._primaryAlignType);
		this._primaryAlignType = alignType;
		this._addDependentStates(this._primaryTargetEntity.get(), this._primaryAlignType);
	}

	/**
	 * Gets the axis of this entity that will align with the target entity's axis for the primary alignment
	 * @returns {Vector3}
	 */
	getPrimaryAxis() {
		return this._primaryAxis;
	}

	/**
	 * Sets the axis of this entity that will be used in the primary alignment. The axis must be normalized.
	 * @param {Vector3} axis
	 */
	setPrimaryAxis(axis) {
		this._primaryAxis.thaw();
		this._primaryAxis.copy(axis);
		this._primaryAxis.freeze();
	}

	/**
	 * Gets the target entity name with which this entity will align or point for primary alignment.
	 * @returns {string}
	 */
	getPrimaryTargetEntity() {
		return this._primaryTargetEntity.getName();
	}

	/**
	 * Sets the target entity name with which this entity will align or point for primary alignment.
	 * @param {string} targetEntityName
	 */
	setPrimaryTargetEntity(targetEntityName) {
		this._primaryTargetEntity.setName(targetEntityName);
	}

	/**
	 * Gets the axis of the target entity with which this entity will align for primary alignment. The axis is in the target entity's oriented frame.
	 * @returns {Vector3}
	 */
	getPrimaryTargetAxis() {
		return this._primaryTargetAxis;
	}

	/**
	 * Sets the axis of the target entity with which this entity will align for primary alignment. The axis is in the target entity's oriented frame. The axis must be normalized.
	 * @param {Vector3} targetAxis
	 */
	setPrimaryTargetAxis(targetAxis) {
		this._primaryTargetAxis.thaw();
		this._primaryTargetAxis.copy(targetAxis);
		this._primaryTargetAxis.freeze();
	}

	/**
	 * Gets the align type for the secondary alignment.
	 * @returns {string}
	 */
	getSecondaryAlignType() {
		return this._secondaryAlignType;
	}

	/**
	 * Sets the align type for the secondary alignment. It can be 'none', 'align', 'velocity', 'point', or 'position'.
	 * 'none' means no alignment
	 * 'align' aligns the entity with the target entity's axis
	 * 'velocity' aligns the entity with the target entity's velocity
	 * 'point' points the entity at the target entity
	 * 'position' aligns the entity with the target entity's local position
	 * @param {string} alignType
	 */
	setSecondaryAlignType(alignType) {
		if (this._secondaryAlignType === alignType) {
			return;
		}
		this._removeDependentStates(this._secondaryTargetEntity.get(), this._secondaryAlignType);
		this._secondaryAlignType = alignType;
		this._addDependentStates(this._secondaryTargetEntity.get(), this._secondaryAlignType);
	}

	/**
	 * Gets the axis of this entity that will align with the target entity's axis for the secondary alignment
	 * @returns {Vector3}
	 */
	getSecondaryAxis() {
		return this._secondaryAxis;
	}

	/**
	 * Sets the axis of this entity that will be used in the secondary alignment. The axis must be normalized.
	 * @param {Vector3} axis
	 */
	setSecondaryAxis(axis) {
		this._secondaryAxis.thaw();
		this._secondaryAxis.copy(axis);
		this._secondaryAxis.freeze();
	}

	/**
	 * Gets the target entity name with which this entity will align or point for secondary alignment.
	 * @returns {string}
	 */
	getSecondaryTargetEntity() {
		return this._secondaryTargetEntity.getName();
	}

	/**
	 * Sets the target entity name with which this entity will align or point for secondary alignment.
	 * @param {string} targetEntityName
	 */
	setSecondaryTargetEntity(targetEntityName) {
		this._secondaryTargetEntity.setName(targetEntityName);
	}

	/**
	 * Gets the axis of the target entity with which this entity will align for secondary alignment. The axis is in the target entity's oriented frame.
	 * @returns {Vector3}
	 */
	getSecondaryTargetAxis() {
		return this._secondaryTargetAxis;
	}

	/**
	 * Sets the axis of the target entity with which this entity will align for secondary alignment. The axis is in the target entity's oriented frame. The axis must be normalized.
	 * @param {Vector3} targetAxis
	 */
	setSecondaryTargetAxis(targetAxis) {
		this._secondaryTargetAxis.thaw();
		this._secondaryTargetAxis.copy(targetAxis);
		this._secondaryTargetAxis.freeze();
	}

	/**
	 * Sets the alignment to be at the joint on the specified model. If no model is given, the first model in the entity is used.
	 * @param {string} joint
	 * @param {ModelComponent} [model]
	 */
	setJoint(joint, model) {
		this._joint = joint;
		if (!model) {
			const modelFromEntity = /** @type {ModelComponent} */(this.getEntity().get('model'));
			if (modelFromEntity !== null) {
				this._model = modelFromEntity;
			}
		}
		else {
			this._model = model;
		}
		if (this._joint !== '') {
			this.addDependentState(this.getEntity().getName(), 'orientation');
			this.removeModifiedState('orientation');
		}
		else {
			this.removeDependentState(this.getEntity().getName(), 'orientation');
			this.addModifiedState('orientation');
		}
	}

	/**
	 * Sets the orientation at the given time.
	 * @param {Quaternion} orientation
	 * @param {number} [time]
	 * @override
	 * @internal
	 */
	__updateOrientationAtTime(orientation, time) {
		if (this._joint !== '') {
			return;
		}
		this._getOrientation(orientation, time);
	}

	/**
	 * Updates the orientation.
	 * @override
	 * @internal
	 */
	__update() {
		const newOrientation = Quaternion.pool.get();
		if (this._jointObject !== null) {
			newOrientation.copy(this.getEntity().getOrientation());
		}
		else {
			newOrientation.copy(this.getEntity().getOrientation());
		}
		this._getOrientation(newOrientation);

		// Set the orientation.
		if (this._jointObject !== null) {
			// Get the orientation back into the ThreeJS object coordinates.
			this._jointObject.quaternion.set(newOrientation.x, newOrientation.y, newOrientation.z, newOrientation.w);
		}
		else {
			this.getEntity().setOrientation(newOrientation);
		}

		Quaternion.pool.release(newOrientation);
	}

	/**
	 * Gets the orientation of the entity or joint at the given time.
	 * The orientation should be initialized to the existing orientation of the entity.
	 * @param {Quaternion} orientation
	 * @param {number} [time]
	 * @private
	 */
	_getOrientation(orientation, time) {
		// If a joint is specified, setup the joint's ThreeJs object.
		if (this._joint !== '' && this._model !== null) {
			const root = this._model.getThreeJsObjects()[0];
			if (root !== undefined && (this._jointObject === null || this._jointObject.name !== this._joint || root.id !== this._modelRootId)) {
				const subObject = this._model.getThreeJsObjectByName(this._joint);
				if (subObject !== null) {
					this._jointObject = subObject;
					this._modelRootId = root.id;
				}
			}
			// No joint object yet when there should be, so do nothing.
			if (this._jointObject === null) {
				return;
			}
		}

		// Get the temporaries.
		const targetAxis = Vector3.pool.get();

		// Get the primary axis from local to world coordinates.
		const primaryAxisGlobal = Vector3.pool.get();
		const primaryRotation = Quaternion.pool.get();
		const localToWorld = Quaternion.pool.get();
		if (this._jointObject !== null) {
			let jointAncestor = this._jointObject;
			AlignController._tempThreeJsQuaternion.set(0, 0, 0, 1);
			while (jointAncestor.parent !== null && jointAncestor.parent !== this._model.getThreeJsObjects()[0]) {
				jointAncestor = jointAncestor.parent;
				AlignController._tempThreeJsQuaternion.multiplyQuaternions(jointAncestor.quaternion, AlignController._tempThreeJsQuaternion);
			}
			localToWorld.copyFromThreeJs(AlignController._tempThreeJsQuaternion);
			localToWorld.mult(this._model.getRotation(), localToWorld);
			localToWorld.mult(orientation, localToWorld);
		}
		else {
			localToWorld.copy(orientation);
		}
		if (localToWorld.isNaN()) {
			localToWorld.copy(Quaternion.Identity);
		}
		primaryAxisGlobal.rotate(localToWorld, this._primaryAxis);

		// Get the primary axis.
		this._getAxis(targetAxis, this._primaryAlignType, this._primaryTargetEntity, this._primaryTargetAxis, primaryAxisGlobal, time);

		if (targetAxis.isNaN()) {
			targetAxis.set(1, 0, 0);
		}

		// Get the rotation needed to align it, and apply it to the new orientation.
		primaryRotation.setFromVectorFromTo(primaryAxisGlobal, targetAxis);
		orientation.mult(primaryRotation, localToWorld);
		if (orientation.isNaN()) {
			orientation.copy(Quaternion.Identity);
		}
		Vector3.pool.release(primaryAxisGlobal);
		Quaternion.pool.release(primaryRotation);

		// Nothing to do if there is no secondary target entity.
		if (this._secondaryAlignType !== 'none' && this._secondaryTargetEntity !== null) {
			// Get the secondary axis.
			this._getAxis(targetAxis, this._secondaryAlignType, this._secondaryTargetEntity, this._secondaryTargetAxis, primaryAxisGlobal, time);

			// Get the rotation required to align this entity. The rotation is around the primary target axis.
			if (!targetAxis.isNaN()) {
				const secondaryRotation = Quaternion.pool.get();
				const primaryAxisOriented = Vector3.pool.get();
				const secondaryAxisOriented = Vector3.pool.get();
				primaryAxisOriented.rotate(orientation, this._primaryAxis);
				secondaryAxisOriented.rotate(orientation, this._secondaryAxis);
				const angle = secondaryAxisOriented.angleAroundAxis(targetAxis, primaryAxisOriented);
				secondaryRotation.setFromAxisAngle(primaryAxisOriented, angle);
				orientation.mult(secondaryRotation, orientation);
				Quaternion.pool.release(secondaryRotation);
				Vector3.pool.release(primaryAxisOriented);
				Vector3.pool.release(secondaryAxisOriented);
			}
		}

		// Set the orientation.
		orientation.normalize(orientation);
		if (this._jointObject !== null) {
			// Get the orientation back into the ThreeJS object coordinates.
			orientation.multInverseL(localToWorld, orientation);
		}

		// Release the temporaries.
		Quaternion.pool.release(localToWorld);
		Vector3.pool.release(targetAxis);
	}

	/**
	 * Gets the axis to aligning.
	 * @param {Vector3} outAxis
	 * @param {string} alignType
	 * @param {EntityRef} targetEntityRef
	 * @param {Vector3} targetAxis
	 * @param {Vector3} axisGlobal
	 * @param {number} time
	*/
	_getAxis(outAxis, alignType, targetEntityRef, targetAxis, axisGlobal, time) {
		const targetEntity = targetEntityRef.get();
		if (alignType === 'align' && targetEntity !== null) {
			// Get the primary target entity axis in the global frame.
			const primaryOrientation = Quaternion.pool.get();
			targetEntity.getOrientationAtTime(primaryOrientation, time);
			outAxis.rotate(primaryOrientation, targetAxis);
			Quaternion.pool.release(primaryOrientation);
		}
		else if (alignType === 'velocity' && targetEntity !== null) {
			// Get the primary target entity velocity.
			targetEntity.getVelocityAtTime(outAxis, time);
			outAxis.normalize(outAxis);
		}
		else if (alignType === 'point' && targetEntity !== null) {
			// Get the position of the primary target entity in this entity's frame.
			targetEntity.getPositionRelativeToEntity(outAxis, Vector3.Zero, this.getEntity(), time);
			outAxis.normalize(outAxis);
		}
		else if (alignType === 'position' && targetEntity !== null) {
			// Get the position of the primary target entity in this entity's frame.
			outAxis.normalize(targetEntity.getPosition());
		}
		else {
			outAxis.copy(axisGlobal);
		}
	}

	/**
	 * Removes the dependent states based on the align entities.
	 * @param {Entity} entity
	 * @param {string} alignType
	 */
	_removeDependentStates(entity, alignType) {
		if (entity !== null) {
			if (alignType === 'align') {
				this.removeDependentState(entity.getName(), 'orientation');
			}
			else if (alignType === 'velocity') {
				this.removeDependentState(entity.getName(), 'velocity');
			}
			else if (alignType === 'point') {
				this.removeDependentState(this.getEntity().getName(), 'position');
				this.removeDependentState(entity.getName(), 'position');
			}
			else if (alignType === 'position') {
				this.removeDependentState(entity.getName(), 'position');
			}
		}
	}

	/**
	 * Adds the dependent states based on the align entities.
	 * @param {Entity} entity
	 * @param {string} alignType
	 */
	_addDependentStates(entity, alignType) {
		if (entity !== null) {
			if (alignType === 'align') {
				this.addDependentState(entity.getName(), 'orientation');
			}
			else if (alignType === 'velocity') {
				this.addDependentState(entity.getName(), 'velocity');
			}
			else if (alignType === 'point') {
				this.addDependentState(this.getEntity().getName(), 'position');
				this.addDependentState(entity.getName(), 'position');
			}
			else if (alignType === 'position') {
				this.addDependentState(entity.getName(), 'position');
			}
		}
	}
}

/**
 * A temporary ThreeJs Quaternion.
 * @type {THREE.Quaternion}
 */
AlignController._tempThreeJsQuaternion = new THREE.Quaternion();
