/* eslint-disable camelcase */
import { useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useSnapshot } from 'valtio';


import { poiStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';
import { loadPoi } from '../helpers/processPoI';
import { hideLoading, showLoading } from '../components/loading';

const PoiDetail = () => {
	const { poiParam } = useParams();
	const { pois } = useSnapshot(poiStore.state);
	const isLoading = useRef(null);

	const currentPoi = pois && pois.length > 0 ? pois.find(({ external_id }) => external_id === poiParam) : null;

	// useEffect for when event changes.
	useEffect(() => {
		// Set variables.
		const { pioneer } = globalRefs;
		const { external_id, type } = currentPoi || {};
		const alreadyLoading = isLoading.current === external_id;

		if (!currentPoi || alreadyLoading) {
			return;
		}

		// Set isLoading.
		isLoading.current = external_id;

		// Show loading.
		const loadType = 'event';
		showLoading(loadType);

		// Set current event in global state.
		poiStore.setGlobalState({ currentPoi });


		const patchComponent = pioneer.get('main', 'earth').getComponent('patch');

		// Load the video if it is animated or static if not.
		loadPoi(currentPoi)
			// NOTE: Currently assuming global static events are not possible.
			.then(() => {
				// Check if event is still loading.
				if (isLoading.current) {
					// Hide loading.
					hideLoading(loadType);
				}

				// Set isLoading to false.
				isLoading.current = false;
			}).catch(e => e);

		// On currentVideo change / unmount disable the earth patch.
		return () => {
			if (type === 'geoLocated') {
				patchComponent?.setEnabled?.(false);
			}
		};
	}, [currentPoi]);

	// useEffect to set current event to null and disable patch when leaving event detail view.
	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			const { pioneer, getManager } = globalRefs;
			const earth = pioneer.get('main', 'earth');
			const patchComponent = earth.getComponent('patch');

			// Set isLoading to null and hide loading.
			isLoading.current = null;
			hideLoading('event');

			// Disable the patch.
			patchComponent.setEnabled(false);

			// Reset global states.
			poiStore.setGlobalState({ currentPoi: null });

		};
	}, []);


	return null;
};

export default PoiDetail;
