import { LatLonAlt, MathUtils } from 'pioneer';
import { SceneHelpers } from 'pioneer-scripts';

import { poiStore } from '../managers/globalState';
import { Config } from '../config/config';

import { POI_CATEGORIES, POI_LATEST_LIMIT} from '../config/constants';
import globalRefs from '../managers/globalRefs';

//import momenti from "moment-hijri";
import moment from 'moment-timezone';
import ar from '../languages/ar';

const updateYear = newYearIndex => {
	poiStore.setGlobalState({ yearIndex: newYearIndex });
};

const updateCategory = category => {
	poiStore.setGlobalState({ categoryIndex: category });
};

/**
 * Loops through all the poi and creates an array of all years spanning them.
 * @param {Array} allPoI
 * @returns {Array}
 */
const  getAllYearsPoI = allPoI => {
	let allYears = [[ar.all_years]];

	allPoI.map(poi => {
		const currYear = poi.date.substring(0, 4);
		let all = allYears.filter(v => {
			return v[0] == currYear
		})
		if(all.length == 0){
			const display = moment(poi.date).locale('en'). format('YYYY');
			allYears.push([currYear,display]);
		}
	});

	return allYears.sort((a, b) => b - a);
};

const filterPoI = ( pois, poiYears, yearIndex, poicategoryIndex) => {
	let ret_val =  pois.filter(poi => {
		if(POI_CATEGORIES.length > 0){
			const year = poiYears[yearIndex][0];
			const category = POI_CATEGORIES[poicategoryIndex];
			const yearFilter = year === ar.all_years || poi.date.substring(0, 4) === year;
			const categoryFilter = category[0] === 'all' || (poi.imagecategory?.includes(category[0].replace(/ /g, '').toLocaleLowerCase()));
			return yearFilter && categoryFilter;
		}
	})

	return ret_val;
};

/**
 * Load the texture of the given event.
 * @param {object} eventData
 */
const loadPoi = async eventData => {
	const { pioneer } = globalRefs;
	const { image_src, north_boundary, south_boundary, east_boundary, west_boundary } = eventData || {};
	const lowerLeftLLA = new LatLonAlt();
	const upperRightLLA = new LatLonAlt();
	const earth = pioneer.get('main', 'earth');
	const src = `${Config.baseCe2s2Url}${image_src}`
	const patchComponent = earth.getComponent('patch');
	const earthSpheroidComponent = earth.getComponentByType('spheroidLOD');
	// Hide data patch.
	patchComponent.setVisible(false);

	// Set patch texture.
	patchComponent.setMapping('cylinder');
	patchComponent.setTexture('color', src );

	const valueForBoundary = 0;
	const northSouthPoiDown = 0.111;

	lowerLeftLLA.set(MathUtils.degToRad(parseFloat(south_boundary - northSouthPoiDown - valueForBoundary)), MathUtils.degToRad(parseFloat(west_boundary - valueForBoundary)), 0, true);
	upperRightLLA.set(MathUtils.degToRad(parseFloat(north_boundary - northSouthPoiDown + valueForBoundary)), MathUtils.degToRad(parseFloat(east_boundary + valueForBoundary)), 0, true);
    patchComponent.setBounds(lowerLeftLLA, upperRightLLA);

	// Enable the patch.
	patchComponent.forceTextureSize('color', 0);
	patchComponent.setEnabled(true);

	const material = patchComponent._threeJsMaterials[0]
	if(material){
		if(eventData.transparent){
			material.transparent = true
		} else {
			material.transparent = false;
		}
	}

	// Wait until patch is loaded.
	await SceneHelpers.waitTillEntitiesInPlace(pioneer.get('main'), ['earth']);
	await pioneer.waitUntilNextFrame();
	await earthSpheroidComponent.getLoadedPromise();
	await patchComponent.getLoadedPromise();

	// Show data patch.
	patchComponent.setVisible(true);
};

/**
	 * Enables the most recent event placemarks.
	 * @param {number} number
	 */
const getLimitedPois = (limit = POI_LATEST_LIMIT) => {
	const { pois } = poiStore.stateSnapshot;

	return pois && pois.length > 0 ? pois.filter((event, i) => i < limit) : [];
};


export {
	updateYear,
	updateCategory,
	getAllYearsPoI,
	loadPoi,
	filterPoI,
	getLimitedPois
};
