/** @module pioneer */
import {
	BaseController,
	Entity
} from '../../internal';

/**
 * An tap controller calls a callback when the user taps the view.
 */
export class TapController extends BaseController {
	/**
	 * Constructor.
	 * @param {string} type - the type of the controller
	 * @param {string} name - the name of the controller
	 * @param {Entity} entity - the parent entity
	 */
	constructor(type, name, entity) {
		super(type, name, entity);

		/**
		 * The callback to call when the user taps.
		 * @type {() => any}
		 * @private
		 */
		this._tapCallback = null;
	}

	/**
	 * Gets the callback to call when the user taps.
	 * @returns {() => any}
	 */
	getTapCallback() {
		return this._tapCallback;
	}

	/**
	 * Sets a callback to call when the user taps.
	 * @param {() => any} callback
	 */
	setTapCallback(callback) {
		this._tapCallback = callback;
	}

	/**
	 * Updates the entity's position and orientation.
	 * @override
	 * @internal
	 */
	__update() {
		// Get the input system.
		const input = this.getEntity().getScene().getEngine().getInput();
		// If the user clicked/tapped and didn't drag.
		if (input.isSelected() || !input.getDraggedOffset().isZero()) {
			// Call the callback.
			if (this._tapCallback !== null) {
				this._tapCallback();
			}
		}
	}
}
