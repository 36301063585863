/* eslint-disable react/jsx-no-bind */
import React from 'react';

import { appStore, uiStore, videoStore, previewStore, eventsStore } from '../managers/globalState';
import globalRefs from '../managers/globalRefs';

import CloseButtonSVG from '../../www/assets/images/close-x.svg';
import PauseButtonSVG from '../../www/assets/images/pause_solid.svg';
import PlayButtonSVG from '../../www/assets/images/play_solid.svg';
import LeftButtonSVG from '../../www/assets/images/chevron_left.svg';

import '../../www/assets/css/video_controls.css';


class VideoControls extends React.PureComponent {
/**
 * @param {HTMLDivElement} div
 */
	constructor(props) {
		super(props);

		this.props = props;
		this._pauseIcon = <PauseButtonSVG className='play-pause-icon' />;
		this._playIcon = <PlayButtonSVG className='play-pause-icon' />;
		const { getManager } = globalRefs;
		this.videoManager = getManager('video');

		this.state = {
			playPauseIcon: null
		};

		this.close = this.close.bind(this);
		this.back = this.back.bind(this);
		this.togglePlayPause = this.togglePlayPause.bind(this);
		this.openChartOverlay = this.openChartOverlay.bind(this);
	}

	componentDidMount() {
		this.videoUnsubscribe = videoStore.subscribeAll(({ isVideoPlaying }) => {
			const playPauseIcon = isVideoPlaying ? this._pauseIcon : this._playIcon;
			this.setState({ playPauseIcon });
			this.forceUpdate();
		});
		this.eventPreviewUnsubscribe = previewStore.subscribeTo('previewEventData', () => this.forceUpdate());
	}

	componentWillUnmount() {
		typeof this.videoUnsubscribe === 'function' && this.videoUnsubscribe();
		typeof this.eventPreviewUnsubscribe === 'function' && this.eventPreviewUnsubscribe();
	}

	back() {
		this.close();

		const { videoMobileBackTo } = uiStore.stateSnapshot;

		// Make sure we re-open the correct overlay/panel.
		if (videoMobileBackTo === 'vitals') {
			uiStore.setGlobalState({ overlayType: 'vitals' });
		} else if (videoMobileBackTo === 'detail') {
			uiStore.setGlobalState({ isDetailPanelOpen: true });
		}
	}

	close() {
		const { getManager } = globalRefs;
		const { currentVideo } = videoStore.stateSnapshot;
		const { related } = currentVideo || {};

		if (related) {
			getManager('route').navigateToDataset({ vitalSignParam: related });
		} else {
			getManager('route').goBack();
		}
	}

	resetPlayIcon() {
		this.setState({ playPauseIcon: this._playIcon });
	}

	togglePlayPause() {
		// Pause/Play video
		this.videoManager.toggleVideoPlayPause();
	}

	/**
 * Open full screen overlay featuring the chart that was clicked.
 */
	openChartOverlay() {
		const { isMobile } = this.props;
		if (isMobile) {
			return;
		}

		uiStore.setGlobalState({ overlayType: 'chart' });
	}

	render() {
		const { isMobile } = appStore.stateSnapshot;
		const { currentVideo, isVideoTexture } = videoStore.stateSnapshot;
		const { currentEvent } = eventsStore.stateSnapshot;
		const { previewEventData } = previewStore.stateSnapshot;
		const data = currentVideo
			|| (previewEventData?.isAnimated && previewEventData)
			|| (currentEvent?.isAnimated && currentEvent);

		if (!data) {
			return null;
		}

		const { type, mobileDateRange, src, altText, description, title: videoTitle } = data || {};
		const { playPauseIcon } = this.state;

		let globalMobileDateRange = '';
		if (isVideoTexture && mobileDateRange) {
			globalMobileDateRange = 'Range: ' + mobileDateRange;
		}

		if (!isVideoTexture) {
			return (
				<div className='video-overlay-container scrollable'>
					{!isMobile
				&& (
					<div className='close-button clickable' onClick={this.close}>
						<CloseButtonSVG className='close-icon' />
					</div>
				)}
					{isMobile
				&& (
					<div className='modal-mobile-header'>
						<div className='mobile-back-button clickable' onClick={this.back}>
							<LeftButtonSVG className='left-icon' />
						</div>
						<h2 className='mobile-title'>
							{videoTitle}
							{' '}
						</h2>
						<div className='mobile-close-button clickable' onClick={this.close}>
							<CloseButtonSVG className='close-icon' />
						</div>
					</div>
				)}
					<div className='video-overlay scrollable'>
						{ type === 'modal' && (
							<video
								className='video clickable'
								controls
								src={src}
								autoPlay
								loop
								muted
								width='100%'
								// Consider adding backup video types if we ever use something other than mp4 files.
								type='video/mp4'
							/>
						)}
						{ type === 'static' && (
							<img
								className='video-static clickable'
								src={src}
								alt={altText}
								width='100%'
								onClick={this.openChartOverlay}
							/>
						)}
						{ !isMobile && (
							<h2 className='video-title'>
								{videoTitle}
							</h2>
						)}
						<p className='video-description content scrollable'>
							{ description ?? null }
						</p>
					</div>
				</div>
			);
		}

		return (
			<>
				{isMobile
		&& (
			<div id='mobile-video-overlay-container-show' className='global-video-overlay-container'>
				{ playPauseIcon && (
					<span className='global-video-date-button-container'>
						<button className='play-pause-button clickable' onClick={this.togglePlayPause}>
							{playPauseIcon}
						</button>
						<span className='global-mobile-date-range'>{globalMobileDateRange}</span>
					</span>
				)}
			</div>
		)}
				{!isMobile && playPauseIcon
			&& (
				<button className='play-pause-button clickable' onClick={this.togglePlayPause}>
					{playPauseIcon}
				</button>
			)}
			</>
		);
	}
}

export default VideoControls;
